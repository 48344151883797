import React, { Component } from 'react';
import { Table } from "antd";
import { connect } from "react-redux";
import { getSalesLinesByDocTypeAndDocNo, getLoggedInUser, getPostedCreditNoteLinesByDocNo } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { SALES_INVOICES, SALES_ORDERS, SALES_RETURNS } from "../../../const/Permissions";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTableMobile extends Component {
  getColumnsDefault = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderLeftColumn(item)
      },
      {
        title: '',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '40%',
        render: (Description, item) => this.renderRightColumn(item)
      }
    ];

  getColumnsCreditNotes = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        render: (Description, item) => (item.LotNo ? item.LotNo + ' - ' : '') + item.No + ' - ' + Description
      },
      {
        title: 'Amount',
        dataIndex: 'LineAmount',
        key: 'LineAmount',
        align: 'right',
        width: 100,
        render: LineAmount => format(LineAmount, currencyFormat)
      }
    ];

  getColumns = () => {
    if (this.props.order.DocType === 'CREDIT MEMO') {
      return this.getColumnsCreditNotes();
    } else {
      return this.getColumnsDefault();
    }
  }

  renderLeftColumn = item => {
    return (
      <div>
        <div>{item.LotNo}</div>
        <div>{item.No}</div>
        <div>{item.Description}</div>
      </div>
    )
  }

  renderRightColumn = item => {
    return (
      <div>
        <div>{item.Quantity}</div>
        <div>{format(item.UnitPrice, currencyFormat)}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 7 }}>{format(item.DisPercentage, currencyFormat)}%</div>
          <div style={{ fontWeight: 'bold' }}>{format(item.DisAmount, currencyFormat)}</div>
        </div>

        <div style={{ fontWeight: 'bold' }}>{format(item.LineAmount, currencyFormat)}</div>
      </div>
    )
  }

  renderFooter = () => {
    const { salesLines = [] } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {salesLines.length}</p>
      </div>
    )
  }

  render() {
    const { height = 100, order, history, salesLines = [], match, loggedInUser: { permissions = [] } } = this.props;
    const { orderId, docType } = match.params;
    const decodeId = decodeURIComponent(orderId);
    const enableUpdate = permissions.includes(SALES_ORDERS.UPDATE) || permissions.includes(SALES_INVOICES.UPDATE) || permissions.includes(SALES_RETURNS.UPDATE);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={salesLines}
          size={'small'}
          onRowClick={item => {
            enableUpdate && history.push({ pathname: '/sales/' + docType + '/' + orderId + '/salesLines/' + item.LineNo });
          }}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId } = ownProps.match.params;
  const decodeId = decodeURIComponent(orderId);

  return {
    salesLines: getPostedCreditNoteLinesByDocNo(state, decodeId),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTableMobile));