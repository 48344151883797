import { Input, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CUSTOMER_FREQUENCY } from '../../../../const/Permissions';
import { StyledButton } from '../../common/Buttons';

const { Text } = Typography;
export class DataTable extends Component {
    state = {
        pagination: {
            pageSize: 20
        }
    };

    columns = [
        {
            title: 'Code',
            width: 25,
            dataIndex: 'CustCode',
            render: (record) => <Text style={{ fontSize: 14 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Name',
            width: 30,
            dataIndex: 'CustName',
            render: (record) => <Text style={{ fontSize: 14 }}>{record == "" ? "-" : record}</Text>
            // fixed: 'left',
        },
        {
            title: 'Contact Name',
            dataIndex: 'ContactName',
            width: 30,
            render: (record) => <Text style={{ fontSize: 14 }}>{record == "" ? "-" : record}</Text>
            // fixed: 'left',
        },
        {
            title: 'Category',
            dataIndex: 'OutletCategory',
            width: 30,
            render: (record) => <Text style={{ fontSize: 14 }}>{record == "" ? "-" : record}</Text>
            // fixed: 'left',
        },
        {
            title: 'Status',
            dataIndex: 'CustStatus',
            width: 30,
            render: (record) => <Text style={{ fontSize: 14 }}>{record == "" ? "-" : record}</Text>
            // fixed: 'left',
        },
        {
            title: 'Visit Freq.',
            dataIndex: 'VisitFrequency',
            width: 20,
            render: (record, row) => (
                <>
                    {
                        this.props.permissions.includes(CUSTOMER_FREQUENCY.UPDATE) ? (
                            <Input value={record} placeholder="Visit Freq." onChange={(event) => this.handleInputChange(event.target.value, row)} />
                        ) : (
                            <Text style={{ fontSize: 14 }}>{record}</Text>
                        )
                    }
                </>
            )
        },
        {
            key: 'action',
            // fixed: this.props.permissions.includes(CUSTOMER_FREQUENCY.UPDATE) ? "right" : "none",
            width: 25,
            render: (record) => (this.props.permissions.includes(CUSTOMER_FREQUENCY.UPDATE) &&
                (<div style={{ paddingRight: 15 }}>
                    <StyledButton color="#106bc7" label="Update" width={80} onClick={() => this.handleUpdate(record)} />
                </div>
                )
            )
        },
    ];

    handleUpdate = async (record) => {
        await this.props.handleUpdate(record);
    }

    handleInputChange = (value, row) => {
        const updatedData = this.props.tableData.records.map((record) => {
            if (record.CustCode == row.CustCode) {
                record.VisitFrequency = value;
            }
            return record;
        });
        this.props.setTableData({
            ...this.props.tableData,
            records: updatedData
        });
    }

    renderFooter = () => {
        return (
            <div style={{ padding: "10px 10px" }}>
                Customer Count: {this.props.tableData?.recordTotal ?? 0}
            </div>
        );
    }

    onSelectChange = (selectedRowKeys) => {
        this.props.setSelectedRowKeys(selectedRowKeys);
    }

    render() {
        return (
            <div style={{ padding: 10, marginTop: 5 }}>
                <Table
                    size='small'
                    columns={this.columns}
                    rowKey={record => record.CustCode}
                    dataSource={this.props.tableData.records}
                    pagination={this.state.pagination}
                    loading={this.props.loading}
                    footer={() => this.renderFooter()}
                    rowSelection={{
                        columnWidth: 20,
                        selectedRowKeys: this.props.selectedRowKeys,
                        onChange: this.onSelectChange
                    }}
                    scroll={{ x: 1000 }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);