import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchItems, updateAllItems } from '../../../redux/actions/items';
import { fetchLotWiseItems } from '../../../redux/actions/lotwiseItems';
import { fetchSalesLines, updateSalesLine, deleteSalesLine } from '../../../redux/actions/salesLines';
import { getAuthData, getItem, getSalesLine, DOC_TYPE_ID_BY_DEFINITION, getLotWiseItem } from "../../../redux/reducers";
import Details from "./Details";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { getOfflineData, removeOfflineItem, saveOfflineData } from '../../../localStorage';
import { fetchInvoiceSalesLine } from '../../../redux/actions/salesInvoiceLines'

class Container extends Component {
  state = {
    salesLine: undefined,
    returnTotalQtyError: false,
    items: [],
    isSystemOffline: false,
    invoiceSaleLine: {}
  };

  async componentDidMount() {
    await this.loadSalesLines();
    this.loadInventoryItem(this.props);
    if (getOfflineData('state').isSystemOffline) {
      this.setState({ isSystemOffline: true });
    }
    const res = await fetchInvoiceSalesLine(this.state.salesLine);
    this.setState({ invoiceSaleLine: res });
  }

  loadSalesLines = async () => {
    const { docType, orderId } = this.props.match.params;
    await this.props.fetchSalesLines({ DocNo: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  componentWillReceiveProps = nextProps => {
    this.loadInventoryItem(nextProps);
  }

  inventoryItemRequestSent = false;
  loadInventoryItem = async props => {
    const { salesLine, fetchItems, fetchLotWiseItems } = props;
    const { docType } = this.props.match.params;
    if (this.inventoryItemRequestSent || !salesLine) return;

    this.setState({ salesLine });
    this.inventoryItemRequestSent = true;
    const { CreatedUserID, No, LocCode, LotNo } = salesLine;

    if (docType === 'ReturnOrder') {
      fetchLotWiseItems({ UserCode: CreatedUserID, LotNo: LotNo, ItemNo: No, AllItems: 'yes' })
    } else {
      const allItems = await fetchItems({ UserCode: CreatedUserID, ItemNo: No, LocCode, LotNo });
      this.setState({ items: allItems });
    }
  }

  handleUpdateOrder = () => this.handleRequest(this.props.updateSalesLine, 'Line updated successfully.', 'update');

  handleRemoveSalesLine = () => this.handleRequest(this.props.deleteSalesLine, 'Line removed successfully.', 'remove');

  handleRequest = async (requestCall, successMessage, action) => {
    const { authData, deleteSalesLine, history, toastManager, updateSalesLine, updateAllItems } = this.props;
    const { docType } = this.props.match.params;
    this.setState({ loading: true });
    let salesLineObject = undefined

    if (docType === 'ReturnOrder') {
      salesLineObject = { ...this.state.salesLine, UserCode: authData.userId, ReturnInvDocNo: this.state.salesLine.ReturnInvDocNo, ReturnInvLineNo: this.state.salesLine.ReturnInvLineNo }
    } else {
      salesLineObject = { ...this.state.salesLine, UserCode: authData.userId }
    }

    if (action == "update" && parseInt(salesLineObject.Quantity) == 0) {
      requestCall = this.props.deleteSalesLine;
    }

    saveOfflineData('isOnPageLoad', false);

    requestCall(salesLineObject).then(async ({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        if (this.state.isSystemOffline) {

          if (docType !== 'ReturnOrder') {
            var originalSalesLineQuantity = getOfflineData('originalSalesLine').Quantity;
            const item = this.state.items.find(function (el) {
              return el.LotNo == salesLineObject.LotNo;
            });

            if (action == "update") {
              item.Inventory = (parseInt(item.Inventory.replace(/,/g, ''))
                + parseInt(originalSalesLineQuantity.replace(/,/g, ''))
                - parseInt(salesLineObject.Quantity)).toLocaleString();
            } else {
              item.Inventory = (parseInt(item.Inventory.replace(/,/g, '')) + parseInt(salesLineObject.Quantity)).toLocaleString();
            }
            removeOfflineItem('originalSalesLine');
            removeOfflineItem('isOnPageLoad');
            await updateAllItems(this.state.items);
          }
        }
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = async () => {
    if (this.state.salesLine.DocType === 'Return Order') await this.handleQTYValidation(this.props.salesLine.Quantity);
    this.setState({ salesLine: { ...this.props.salesLine } })
  };

  handleQTYValidation = async (value) => {
    this.setState({ returnTotalQtyError: false });

    if (this.state.invoiceSaleLine) {
      if (parseInt(this.state.invoiceSaleLine.Quantity) < parseInt(value)) {
        this.setState({ returnTotalQtyError: true });
      }
    }
  }

  handleUpdateNumberField = async (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    if (key === 'DisPercentage') {
      if (value < 0) value *= -1;
      if (value > 100) value = 100;
    }

    if (this.state.salesLine.DocType === 'Return Order') await this.handleQTYValidation(value)

    this.setState({ salesLine: { ...this.state.salesLine, [key]: '' + value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  render() {
    const { match, resource, order, inventoryItem, salesLine: salesLineDatabase } = this.props;
    const { salesLine, loading, returnTotalQtyError } = this.state;
    const { docType } = this.props.match.params;

    return (
      <Spin spinning={loading || !salesLine || !inventoryItem} tip={"Loading item"}>
        {(inventoryItem && salesLine) ?
          <Details
            inventoryItem={inventoryItem}
            onUpdateNumberField={this.handleUpdateNumberField}
            salesLineItem={salesLine}
            salesLineItemDatabase={salesLineDatabase}
            onUpdateOrder={this.handleUpdateOrder}
            onRemoveSalesLine={this.handleRemoveSalesLine}
            onResetSalesLine={this.handleResetSalesLine}
            resource={resource}
            order={order}
            newOrderItem={match && match.params.lineId === 'new'}
            docType={docType}
            returnTotalQtyError={returnTotalQtyError}
          /> :
          <div style={{ width: '100%', height: 200 }} />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { docType, orderId, lineId } = ownProps.match.params;
  const salesLine = getSalesLine(state, docType, decodeURIComponent(orderId), lineId);
  let inventoryItem;

  if (getOfflineData('isOnPageLoad') != false) {
    saveOfflineData('originalSalesLine', salesLine);
  }

  if (salesLine) {
    const { No, LotNo, LocCode } = salesLine;
    let itemId = No + '-' + LotNo + '-' + LocCode;
    if (docType === 'ReturnOrder') {
      itemId = No + '-' + LotNo;
      inventoryItem = itemId && getLotWiseItem(state, itemId);
    } else {
      inventoryItem = getItem(state, itemId);
    }
  }
  return { authData: getAuthData(state), inventoryItem, salesLine };
};


export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchItems,
        fetchLotWiseItems,
        fetchSalesLines,
        updateSalesLine,
        deleteSalesLine,
        updateAllItems
      }
    )(Container)
  )
);