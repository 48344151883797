import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Row, Table } from "antd";
import { fetchSalesUserHierarchy } from '../../../redux/actions/users'
import { fetchCustomers } from "../../../redux/actions/customers";
import { getSalesUserHierarchy, getAuthData, getCustomers, getRouteTotal } from "../../../redux/reducers";
import RouteCard from "./RouteCard";
import RouteCardHeader from "./RouteCardHeader";
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import TableFooter from "../../common/TableFooter";
import { getSearchKey } from "../../../utils/Search";

class RotuesTable extends Component {
  componentDidMount = () => {
    const { fetchSalesUserHierarchy, authData } = this.props;
    fetchSalesUserHierarchy(authData);
    // TODO: Fix filter with UserTag
    fetchCustomers(authData);
  }

  renderColumns = () => {
    return [{
      render: route => <RouteCard route={route} />
    }]
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 75;
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  getUserTreeData = users => {
    return Object.values(users).map(user => ({
      value: user.code,
      title: user.name + ' (' + user.type + ')',
      children: this.getUserTreeData(user.descendants)
    }));
  }

  renderHeader = () => {
    return (
      <div className="promate-table-header">
        <RouteCardHeader />
      </div>
    )
  }

  renderFooter = () => {
    const { total, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
      />
    );
  }

  render() {
    const { routes } = this.props

    return (
      <Col>
        <Table
          rowKey={'id'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(routes) }}
          columns={this.renderColumns()}
          dataSource={routes}
          size={'middle'}
          pagination={false}
          bordered={false}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(routes)}
        />
      </Col>
    )
  }
}

const mapStateToProps = state => {
  return {
    salesUserHierarchy: getSalesUserHierarchy(state),
    customers: getCustomers(state),
    authData: getAuthData(state),
    total: getRouteTotal(state, getSearchKey()),
  };
};

RotuesTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { fetchSalesUserHierarchy, fetchCustomers })(RotuesTable);