import { combineReducers } from 'redux';
import { FETCH_GL_ACCOUNTS } from '../actions/glAccounts';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_GL_ACCOUNTS:
			const newState = {};
			action.accounts.forEach(account => newState[account.No] = account);
			return newState;
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_GL_ACCOUNTS:
			return action.accounts.map(account => account.No);
		default:
			return state;
	}
};

const inventory = combineReducers({
	byId,
	ids,
});

export default inventory;

export const getAll = state => {
	return state.ids.map(id => state.byId[id]).filter(item => !!item)
}