import React, { Component } from 'react';
import { Row, Card, Col, Tag } from "antd";

class CustomerHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      case 'BLOCKED':
        return 'red';
      default:
        return 'orange';
    }
  }



  render() {
    const { customerId, customer } = this.props;
    const status = (!customer.Status || (customer.Status && customer.Status === '')) ? 'Active' : customer.Status
    return (
      <div className='promate-form-header'>
        <Col lg={8} md={8} sm={24} xs={24}>
          {customerId && <tr>
            <td>Customer Code</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{customerId}</td>
          </tr>}
          <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td><Tag color={customerId === "new" ? this.getStatusColor('UNVERIFIED') : this.getStatusColor(status)}>{customerId === "new" ? 'UNVERIFIED' : status}</Tag></td>
          </tr>
        </Col>
      </div>
    )
  }
}

export default CustomerHeader;