import React, { Component } from 'react';
import Logo from '../../../../assets/logo.png';

export default class SalesOrder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const call = this.props.call;
        const headers = this.props.orders.salesHeaders.filter((header) => (header.ActiveCallNo == this.props.call.CallNo));
        return (
            <>
                <div style={{ padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start" }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px', paddingLeft: '16px', alignItems: 'center', background: '#a0cc4b', height: '30px' }}>
                            <p style={{ fontWeight: 'bold', color: 'black' }}>Sales Order</p>
                        </div>
                        <img style={{ height: '50px' }} src={Logo} />
                    </div>

                    {/* Start Call Details Section */}
                    <p style={{ fontWeight: 'bold', color: 'black', textDecoration: 'underline', marginTop: '10px', marginBottom: '0' }}>Call Details:</p>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Start Reason</span>
                                <span style={{ fontSize: '12px' }}>{call.StartReasonDescrip}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Customer No</span>
                                <span style={{ fontSize: '12px' }}>{call.CustNo}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Customer Name</span>
                                <span style={{ fontSize: '12px' }}>{call.CustName}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Remarks</span>
                                <span style={{ fontSize: '12px' }}>{(call.Remarks != '' && call.Remarks != undefined) ? call.Remarks : '-'}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Start Time</span>
                                <span style={{ fontSize: '12px' }}>{call.StartDateTime}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>End Time</span>
                                <span style={{ fontSize: '12px' }}>{call.EndDateTime}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>End Status</span>
                                <span style={{ fontSize: '12px' }}>{call.EndStatusDescrip}</span>
                            </div>
                        </div>
                    </div>

                    {/* Start Sale Details Section */}
                    {
                        headers.map((header, index) => (
                            <div key={'sales-order-thead-' + index}>
                                <p style={{ fontWeight: 'bold', color: 'black', textDecoration: 'underline', marginTop: '15px', marginBottom: '0' }}>{index + 1}: Sales Order Details</p>
                                <div style={{ marginTop: '10px' }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Sales Order No</span>
                                                <span style={{ fontSize: '12px' }}>{header.SalesHeaderNo}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Order Type</span>
                                                <span style={{ fontSize: '12px' }}>{header.SOType}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Order Status</span>
                                                <span style={{ fontSize: '12px' }}>{header.OrderStatus}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Sales Order Date</span>
                                                <span style={{ fontSize: '12px' }}>{header.OrderDate}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Customer Code</span>
                                                <span style={{ fontSize: '12px' }}>{header.SellToCusNo}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Customer Name</span>
                                                <span style={{ fontSize: '12px' }}>{header.SellToCusName}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Location</span>
                                                <span style={{ fontSize: '12px' }}>{header.LocCode}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px', flexDirection: 'column', height: '80px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Base Discount (%)</span>
                                                <span style={{ fontSize: '12px' }}>{header.DisPersentage}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Discount Reason</span>
                                                <span style={{ fontSize: '12px' }}>{header.DiscountReasonCode}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Payment Method</span>
                                                <span style={{ fontSize: '12px' }}>{header.PayMethodCode}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Payment Term</span>
                                                <span style={{ fontSize: '12px' }}>{header.PayTermCode}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Remarks</span>
                                                <span style={{ fontSize: '12px' }}>{(header.Remarks != '' && header.Remarks != undefined) ? header.Remarks : '-'}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px', color: 'black' }}>Discount</span>
                                                <span style={{ fontSize: '12px', color: 'black' }}>{Number(header.DisAmount).toFixed(2)}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px', color: 'black' }}>Total Amount</span>
                                                <span style={{ fontSize: '12px', color: 'black' }}>{Number(header.Amount).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <table style={{
                                        width: '100%',
                                        marginTop: '20px'
                                    }}>
                                        <thead>
                                            <tr style={{ border: '0.25px solid gray' }}>
                                                <td style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>No</td>
                                                <td colSpan={2} style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>Item Code</td>
                                                <td colSpan={5} style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>Item Description</td>
                                                <td style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>Quantity</td>
                                                <td style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>Unit Price</td>
                                                <td style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>Discount Price</td>
                                                <td style={{
                                                    padding: '5px',
                                                    borderRight: '0.5px solid gray',
                                                    borderCollapse: 'collapse'
                                                }}>Total</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.orders.salesLines.filter((line) => (line.DocNo == header.SalesHeaderNo)).length == 0 ? <tr style={{ border: '1px solid gray' }}>
                                                    <td colSpan={12} style={{ padding: '20px', textAlign: 'center' }}> No Items </td>
                                                </tr> : <></>
                                            }
                                            {
                                                this.props.orders.salesLines.filter((line) => (line.DocNo == header.SalesHeaderNo)).map((line, index) => (
                                                    <tr key={'sales-order-tr-' + index} style={{ border: '0.25px solid gray' }}>
                                                        <td style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{index + 1}</td>
                                                        <td colSpan={2} style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{line.No}</td>
                                                        <td colSpan={5} style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{line.Description}</td>
                                                        <td style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{line.Quantity}</td>
                                                        <td style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{line.UnitPrice}</td>
                                                        <td style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{line.DisAmount}</td>
                                                        <td style={{
                                                            padding: '5px',
                                                            borderRight: '0.5px solid gray',
                                                            borderCollapse: 'collapse'
                                                        }}>{line.Amount}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                    {this.props.orders.signatures.find((sign) => (header.No == sign.orderId)) ?
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                            <div style={{ border: '1px solid gray' }}>
                                                <div style={{ padding: '10px' }}>
                                                    <img style={{ height: '70px' }} src={this.props.orders.signatures.find((sign) => (header.No == sign.orderId)).image} />
                                                </div>
                                                <div style={{ borderTop: '0.5px solid gray', textAlign: 'center', padding: '2px 0' }}>
                                                    <p style={{ margin: '0' }}>Customer Signature</p>
                                                </div>
                                            </div>

                                        </div> : <></>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                {!this.props.isLastElement ? <div className="page-break"></div> : <></>}
            </>
        );
    }
}