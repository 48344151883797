function openIndexDB() {
    try {
        var request = indexedDB.open('PROMATE', 1);
        return request;

    } catch (e) {
        console.error('Something goes wrong', e);
    }
}

export const testIndexDB = () => {
    try {
        if (!window.indexedDB) {
            console.log(`Your browser doesn't support IndexedDB`);
            return;
        } else {
            console.log(`Your browser support IndexedDB`);
        }
    } catch (e) {
        console.error('Something goes wrong', e);
    }
}

export const createObjectStoreIndexDB = () => {
    return new Promise((resolve, reject) => {

        const req = openIndexDB();
        req.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore('Common', {
                //autoIncrement: true
            });
            db.createObjectStore('Customers', {
                //autoIncrement: true
            });
            db.createObjectStore('salesOrders', {
                //autoIncrement: true
            });

            db.createObjectStore('Calls', {
                //autoIncrement: true
            });
            db.createObjectStore('Images', {

            });
            db.createObjectStore('TripSchedules', {
                //autoIncrement: true
            });
            db.createObjectStore('salesInvoices', {
                //autoIncrement: true
            });
            db.createObjectStore('inventory', {
                //autoIncrement: true
            });
            resolve("Done")
        };
    })


}

export const insertDataIndexDB = (object, key, data) => {
    return new Promise((resolve, reject) => {
        const req = openIndexDB();
        req.onsuccess = (event) => {

            const db = event.target.result;
            // create a new transaction
            const txn = db.transaction(object, 'readwrite');

            // get the Contacts object store
            const store = txn.objectStore(object);

            const query = store.put(data, key);

            // handle success case
            query.onsuccess = function (event) {
                resolve(event);
            };

            // handle the error case
            query.onerror = function (event) {
                console.log("errorCode");
                console.log(event.target.errorCode);
                reject(event);
            }

            // close the database once the 
            // transaction completes
            txn.oncomplete = function () {
                db.close();
            };
        }
    });
}

// export const readObjectFromIndexDB = (object,key) =>{
//     return new Promise((resolve, reject) => {
//         const req = openIndexDB();

//     req.onsuccess = (event)=> {
//         const db = event.target.result;
//         const txn = db.transaction(object, 'readonly');
//         const store = txn.objectStore(object);
//         const query = store.get(key);

//         query.onsuccess = (event) => {
//             if (!event.target.result) {
//                 console.log(`The contact with ${key} not found`);

//             } else {
//                 resolve(event.target.result);
//             }
//         };

//         query.onerror = (event) => {
//             reject(event.target.errorCode)
//         }

//         txn.oncomplete = function () {
//             db.close();
//         };
//     }
//     });
//   }


export const readObjectFromIndexDB = (object, key) => {
    return new Promise((resolve, reject) => {
        const req = openIndexDB();
        req.onsuccess = (event) => {
            const db = event.target.result;
            const txn = db.transaction(object, 'readonly');
            const store = txn.objectStore(object);
            try {
                var query = store.get(key);
                query.onsuccess = (event) => {
                    if (!event.target.result) {
                        console.log(`The object with ${key} not found`);
                        reject(event.target.result)
                    } else {
                        resolve(event.target.result);
                    }
                };

                query.onerror = (event) => {
                    reject(event.target.errorCode)
                }

                txn.oncomplete = function () {
                    db.close();
                };

            } catch (error) {
                reject(event.target.errorCode)
            }
        }
    });
}

export const deleteIndexDB = () => {
    try {
        const req = indexedDB.deleteDatabase("PROMATE");
        req.onsuccess = function () {
            console.log("Deleted database successfully");
        };
        req.onerror = function () {
            console.log("Couldn't delete database");
        };
        req.onblocked = function () {
            console.log("Couldn't delete database due to the operation being blocked");
        };
    } catch (e) {
        console.error('Something goes wrong', e);
    }
}





