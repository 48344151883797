import { combineReducers } from 'redux';
import { FETCH_COLLECTIONS, FETCH_COLLECTIONS_BY_CUSTOMER, FETCH_COLLECTION, DELETE_COLLECTION } from '../actions/collection';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS: {
      const newState = {};
      action.collections.forEach(collection => newState[collection.collectionNo] = collection);
      return newState;
    }

    case FETCH_COLLECTIONS_BY_CUSTOMER: {
      const newState = { ...state };
      action.collections.forEach(collection => state[collection.collectionNo] = collection);
      return newState;
    }

    case FETCH_COLLECTION: {
      const newState = { ...state };
      newState[action.collection.collectionNo] = action.collection
      return newState;
    }

    case DELETE_COLLECTION: {
      const { [action.collection.collectionNo]: collectionToDelete, ...rest } = state;
      return rest;
    }

    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS: {
      const newState = [];
      const collectionIds = action.collections.map(collection => collection.collectionNo)

      collectionIds.forEach(id => {
        if (!newState.includes(id)) newState.push(id)
      })
      return newState;
    }

    case FETCH_COLLECTIONS_BY_CUSTOMER: {
      const newState = [...state]
      const collectionIds = action.collections.map(collection => collection.collectionNo)

      collectionIds.forEach(id => {
        if (!newState.includes(id)) newState.push(id)
      })
      return newState;
    }

    case FETCH_COLLECTION: {
      const newState = [...state]
      const collectionId = action.collection.collectionNo

      if (!newState.includes(collectionId)) newState.push(collectionId)
      return newState;
    }

    case DELETE_COLLECTION: {
      return state.filter(id => id !== action.collection.collectionNo);
    }

    default:
      return state;
  }
};

export const byCustomer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS_BY_CUSTOMER: {
      const newState = { ...state }
      const { customerId, collections } = action;
      const existingIdsbyCustomer = newState[customerId] || []
      const collectionIds = collections.map(collection => collection.collectionNo)

      collectionIds.forEach(id => {
        if (!existingIdsbyCustomer.includes(id)) existingIdsbyCustomer.push(id)
      })

      newState[customerId] = existingIdsbyCustomer
      return newState;
    }

    case DELETE_COLLECTION: {
      const newState = {};
      Object.keys(state).forEach(key => {
        const customerCollections = state[key] || [];
        newState[key] = customerCollections.filter(id => id !== action.collection.collectionNo);
      });
      return newState;
    }

    default:
      return state;
  }
};

const collections = combineReducers({
  byId,
  ids,
  byCustomer
});

export const getCollections = state => {
  const collections = state.ids.map(id => state.byId[id]).filter(collection => !!collection);
  return collections;
}

export const getCollectionsByCustomer = (state, customerId) => {
  const collectionIdsByCustomer = state.byCustomer[customerId]
  const collections = collectionIdsByCustomer && collectionIdsByCustomer.length > 0 ? collectionIdsByCustomer.map(id => state.byId[id]).filter(collection => !!collection) : []

  return collections;
}

export const getCollection = (state, collectionNo) => state.byId[collectionNo]

export default collections;