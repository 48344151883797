import React, { Component, createContext, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAuthenticated, getLoggedInUser } from './redux/reducers';
import Home from './components/Home';
import Login from './components/Login';
import Inventory from './components/Inventory';
import Orders from "./components/Orders";
import OrderScreen from "./components/Orders/OrderScreen";
import Customers from './components/Customers/CustomerList';
import CustomerEditView from './components/Customers/CustomerView';
import CustomerDetailView from './components/Customers/CustomerView/Details';
import Invoices from "./components/Invoices";
import ItemView from './components/Inventory/ItemView';
import ItemViewPurchaseLine from './components/LotWiseItemsInventory/ItemViewPurchaseLine';
import ItemViewSalesLine from './components/LotWiseItemsInventory/ItemViewSalesLine';
import Collections from "./components/Collections"
import CollectionScreen from './components/Collections/CollectionScreen';
import OrderExport from "./components/Orders/OrderExport";
import OrderConfirmScreen from './components/Orders/ConfirmedOrderScreen';
import CollectionConfirmScreen from "./components/Collections/ConfirmCollectionScreen";
import InvoiceScreen from "./components/Invoices/InvoiceScreen";
import NetworkDetector from './components/common/NetworkDetector'
import Distributors from './components/Distributors/DistributorList';
import DistributorDetailView from './components/Distributors/DistributorView/Details';
import SalesOrders from "./components/SalesOrders";
import PostedCreditNotes from './components/PostedCreditNotes';
import SalesOrderScreen from "./components/SalesOrders/OrderScreen";
import SalesOrderConfirmScreen from './components/SalesOrders/ConfirmedOrderScreen';
import Payments from "./components/Payments";
import PaymentScreen from "./components/Payments/PaymentScreen";
import ConfirmPaymentScreen from "./components/Payments/ConfirmPaymentScreen";
import Receipt from "./components/Receipt";
import ReceiptScreen from "./components/Receipt/ReceiptScreen";
import ConfirmReceiptScreen from "./components/Receipt/ConfirmReceiptScreen";
import SupplierReturn from "./components/SupplierReturn";
import SuplierReturntScreen from "./components/SupplierReturn/SuplierReturntScreen";
import ConfirmSupplierReturnScreen from "./components/SupplierReturn/ConfirmSupplierReturnScreen";
import CreditNotes from "./components/CreditNotes";
import CreditNoteScreen from "./components/CreditNotes/CreditNoteScreen";
import PostedCreditNoteScreen from "./components/PostedCreditNotes/PostedCreditNoteScreen";
import ConfirmedCreditNoteScreen from "./components/CreditNotes/ConfirmedCreditNoteScreen";
import SalesInvoices from './components/SalesInvoices';
import SalesInvoiceScreen from './components/SalesInvoices/SalesInvoiceScreen';
import SalesInvoiceConfirmScreen from './components/SalesInvoices/ConfirmedSalesInvoiceScreen';
import GRN from './components/GRN';
import GRNScreen from './components/GRN/GRNScreen';
import GRNConfirmScreen from './components/GRN/ConfirmedGRNScreen';
import SalesRepresentative from './components/SalesRepresentative/SalesRepsList';
import SalesRepView from './components/SalesRepresentative/SalesRepView';
import ReceiptSetoffScreen from "./components/Receipt/SetoffScreen";
import CreditNoteSetoffScreen from "./components/CreditNotes/SetoffScreen";
// import Expenses from './components/Expenses/ExpensesList';
// import ExpenseView from './components/Expenses/ExpenseView';
import ExpenseCategroyView from './components/ExpensesCategory/ExpenseCategoryView';
import expenseCategories from './components/ExpensesCategory/ExpenseCategoryList';
import Calls from './components/Calls/CallsList';
import CallView from './components/Calls/CallView';
import Warehouses from "./components/Warehouse";
import WarehouseView from "./components/Warehouse/WarehouseView";
import TransactionHistory from "./components/Warehouse/WarehouseView/TransactionHistory";
import WarehouseLocations from "./components/Warehouse/Locations";
import WarehouseLocationView from "./components/Warehouse/Locations/LocationView";
import LiveLocationMap from "./components/Warehouse/WarehouseView/LiveLocationMap";

import Routes from "./components/Routes/RoutesList";
import RouteView from './components/Routes/RouteView';

import SettlementView from './components/Settlment/View';
import StockTake from './components/StockTake';
import StockTakeScreen from './components/StockTake/StockTakeScreen';
import StockTranfer from './components/StockTranfer';
import StockTransferScreen from './components/StockTranfer/StockTransferScreen';
import ConfirmedStockTransferScreen from './components/StockTranfer/ConfirmedStockTransferScreen';
import ConfirmedStockTakeScreen from './components/StockTake/ConfirmedStockTakeScreen';

import StockTransfers from './components/StockTransferNew';
import StockTransferScreenNew from './components/StockTransferNew/StockTransferScreen';
import ConfirmStockTransferScreen from './components/StockTransferNew/ConfirmStockTransferScreen';

import SalesReturns from "./components/SalesReturn";
import SalesReturnScreen from "./components/SalesReturn/SalesReturnScreen";
import ConfirmedSalesReturnScreen from "./components/SalesReturn/ConfirmedOrderScreen";
import ResetPassword from './components/ResetPassword';
import OTPRequestPage from './components/OTPRequestPage';

import InventoryItemListView from './components/InventoryItemListView';
import ItemStock from './components/ItemStock';
import TripScheduler from "./components/TripScheduler";
import { ADD_CUSTOMER_ROUTE_TARGET, ADD_ROUTE_TARGET, CALLS, CUSTOMER_FREQUENCY, CUSTOMERS, PURCHASING_ORDERS, ROUTE_CUSTOMER_TARGET, ROUTE_TARGET, ROUTES } from './const/Permissions';
import GlItemListView from './components/GlItemListView';

import SalesReturnRecipts from './components/SalesReturnRecipts';
import SalesReturnReciptScreen from './components/SalesReturnRecipts/SalesReturnReciptScreen';
import ConfirmedSalesReturnReciptScreen from './components/SalesReturnRecipts/ConfirmedSalesReturnReciptScreen';

import DistributorPayments from './components/DistributorPayments';
import DistributorPaymentScreen from './components/DistributorPayments/DistributorPaymentScreen';
import PurchaseOrderItemView from './components/PurchasingOrders/PurchaeOrderLineItemView';
import PurchasingOrderOrderScreen from "./components/PurchasingOrders/OrderScreen";
import PurchasingOrders from "./components/PurchasingOrders";
import PurchasingOrderConfirmScreen from "./components/PurchasingOrders/ConfirmedOrderScreen";
import LotWiseItemsInventory from './components/LotWiseItemsInventory';

import PurchasingInvoices from './components/PurchasingInvoices';
import PurchasingInvoiceScreen from './components/PurchasingInvoices/PurchasingInvoiceScreen';
import InvoiceExport from './components/PurchasingInvoices/InvoiceExport';

import PurchasingCreditInvoices from './components/PurchaseCreditInvoices';
import PurchaseCreditInvoiceScreen from './components/PurchaseCreditInvoices/PurchaseCreditInvoiceScreen';

import StockTakeItemView from './components/StockTake/StockTakeLineItemView';
import Expenses from './components/Expenses';
import ExpenseScreen from './components/Expenses/ExpenseScreen';
import ConfirmedExpenseScreen from './components/Expenses/ConfirmedExpenseScreen';

import OfflineOrderReport from './components/SalesOrders/OrderScreen/OfflineOrderReport';
import FailedOrders from './components/Failed/FailedOrders';
import FailedOrdersTable from './components/Failed/FailedOrdersTable';
import FailedSalesOrders from './components/Failed/index';
import DailyReport from './components/CSRDailySummaryReport/dailyReport';

import SalesInvoicesScreen from './components/SalesReturn/SalesInvoicesScreen'

// For Traget Scheduler
import TargetScheduler from './components/TargetScheduler';
import RouteCustomerTarget from './components/TargetScheduler/RouteCustomerTarget/';
import CustomerFrequency from './components/TargetScheduler/CustomerFrequency/';
import GenerateReport from './components/TargetScheduler/GenerateReport/';
import RouteTarget from './components/TargetScheduler/RouteTarget/';
import AddRouteTarget from './components/TargetScheduler/RouteTarget/AddRouteTarget';
import AddRouteCustomerTarget from './components/TargetScheduler/RouteCustomerTarget/AddRouteCustomerTarget';

// For Other Failed Orders
import OtherFailedSalesOrders from './components/Failed/headers';
import FailedSalesOrderLines from './components/Failed/lines';

const AuthContext = createContext();

class Root extends Component {

  render = () => {
    const { authenticated, user: { permissions = [] } } = this.props;
    return (
      <AuthContext.Provider value={authenticated}>
        <Switch>
          {permissions.some(permission => Object.values(CUSTOMERS).includes(permission)) && [
            <PrivateRoute path='/customers/' exact component={Customers} />,
            <PrivateRoute path='/distributors/:distributorId/customers/' exact component={Customers} />,
            <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/' exact component={Customers} />,
            <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers' exact component={Customers} />,
            <PrivateRoute path='/customers/new' exact component={CustomerEditView} />,
            <PrivateRoute path='/customers/:customerId' exact component={CustomerDetailView} />,
            <PrivateRoute path='/customers/:customerId/edit' exact component={CustomerEditView} />,
            <PrivateRoute path='/distributors/:distributorId/customers/:customerId' exact component={CustomerEditView} />,
            <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/:customerId' exact component={CustomerEditView} />,
            <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers/:customerId' exact component={CustomerEditView} />,
            <PrivateRoute path='/routes/:routeId/customers/:customerId' exact component={CustomerEditView} />
          ]}

          {permissions.some(permission => Object.values(ROUTES).includes(permission)) && [
            <PrivateRoute path='/routes' exact component={Routes} />,
            <PrivateRoute path='/routes/:routeId' exact component={RouteView} />
          ]}

          {permissions.some(permission => Object.values(CALLS).includes(permission)) && [
            <PrivateRoute path='/distributors/:distributorId/calls' exact component={Calls} />,
            <PrivateRoute path='/salesRepresentatives/:salesPersonId/calls' exact component={Calls} />,
            <PrivateRoute path='/calls/' exact component={Calls} />,
            <PrivateRoute path='/customers/:customerId/calls' exact component={Calls} />,
            <PrivateRoute path='/distributors/:distributorId/calls' exact component={Calls} />,
            <PrivateRoute path='/distributors/:distributorId/calls/:callId' exact component={CallView} />,
            <PrivateRoute path='/salesRepresentatives/:salesPersonId/calls/:callId' exact component={CallView} />,
            <PrivateRoute path='/calls/:callId' exact component={CallView} />,
            <PrivateRoute path='/customers/:customerId/calls/:callId' exact component={CallView} />,
            <PrivateRoute path='/distributors/:distributorId/calls/:callId' exact component={CallView} />
          ]}


          <PrivateRoute path='/' exact component={Home} />
          <PrivateRoute path='/failedOrders' exact component={FailedOrders} />
          <PrivateRoute path='/dailyReport' exact component={DailyReport} />
          {/* <PrivateRoute path='/failedOrdersTable' exact component={FailedOrdersTable} /> */}
          <PrivateRoute path='/inventory' exact component={Inventory} />
          <PrivateRoute path='/inventoryLotWiseItems' exact component={LotWiseItemsInventory} />
          <PrivateRoute path='/inventoryItemList' exact component={InventoryItemListView} />
          <PrivateRoute path='/stock' exact component={ItemStock} />
          {/* <PrivateRoute path='/order/:orderId/salesLines/:lineId' exact component={ItemView} /> */}

          <PrivateRoute path='/orders/' exact component={Orders} />
          <PrivateRoute path='/orders/:orderId' exact component={OrderScreen} />
          <PrivateRoute path='/orders/:orderId/confirm' exact component={OrderConfirmScreen} />
          <PrivateRoute path='/orders/new' exact component={OrderScreen} />
          <PrivateRoute path='/orderExport/:orderId' exact component={OrderExport} />
          <PrivateRoute path='/customers/:customerId/orders' exact component={Orders} />



          <PrivateRoute path='/invoices/' exact component={Invoices} />
          <PrivateRoute path='/customers/:customerId/invoices' exact component={SalesInvoices} />
          <PrivateRoute path='/collections/' exact component={Collections} />
          <PrivateRoute path='/collections/:collectionId/confirm' exact component={CollectionConfirmScreen} />
          <PrivateRoute path='/collections/:collectionId' exact component={CollectionScreen} />

          <PrivateRoute path='/customers/:customerId/collections' exact component={Collections} />
          <PrivateRoute path='/customers/:customerId/collections/:collectionId' exact component={CollectionScreen} />

          {/* Distributors */}
          <PrivateRoute path='/distributors/' exact component={Distributors} />
          <PrivateRoute path='/distributors/:distributorId' exact component={DistributorDetailView} />
          <PrivateRoute path='/distributors/:distributorId/salesInvoices/:invoiceNo' exact component={SalesInvoiceScreen} />
          <PrivateRoute path='/distributors/:distributorId/salesInvoices' exact component={SalesInvoices} />
          <PrivateRoute path='/distributors/:distributorId/salesOrders/:orderId' exact component={SalesOrderScreen} />
          <PrivateRoute path='/distributors/:distributorId/salesOrders' exact component={SalesOrders} />
          <PrivateRoute path='/distributors/:distributorId/collections/' exact component={Collections} />
          <PrivateRoute path='/distributors/:distributorId/collections/:collectionId' exact component={CollectionScreen} />
          <PrivateRoute path='/distributors/:distributorId/customers/:customerId/collections/' exact component={Collections} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/:customerId/collections/' exact component={Collections} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/collections/' exact component={Collections} />
          <PrivateRoute path='/distributors/:distributorId/sales/:docType' exact component={SalesOrders} />


          <PrivateRoute path='/invoiceExport/:invoiceId' exact component={InvoiceExport} />
          <PrivateRoute path='/distributors/:distributorId/purchasingInvoices' exact component={PurchasingInvoices} />
          <PrivateRoute path='/distributors/:distributorId/purchasingInvoices/:invoiceNo' exact component={PurchasingInvoiceScreen} />

          <PrivateRoute path='/sales/:docType/' exact component={SalesOrders} />
          <PrivateRoute path='/sales/:docType/:orderId' exact component={SalesOrderScreen} />
          <PrivateRoute path='/sales/:docType/:orderId/glItemList' exact component={GlItemListView} />
          <PrivateRoute path='/sales/:docType/:orderId/salesLines/:lineId' exact component={ItemViewSalesLine} />
          <PrivateRoute path='/sales/:docType/:orderId/salesLines/:lineId/items/:itemId' exact component={ItemViewSalesLine} />
          <PrivateRoute path='/sales/:docType/:orderId/confirm' exact component={SalesOrderConfirmScreen} />
          <PrivateRoute path='/customers/:customerId/sales/:docType/:orderId' exact component={SalesOrderScreen} />
          <PrivateRoute path='/customers/:customerId/sales/:docType' exact component={SalesOrders} />

          <PrivateRoute path='/postedCreditNotes/:docType' exact component={PostedCreditNotes} />
          <PrivateRoute path='/customers/:customerId/postedCreditNotes/:docType' exact component={PostedCreditNotes} />
          <PrivateRoute path='/postedCreditNotes/:docType/:orderId' exact component={PostedCreditNoteScreen} />
          <PrivateRoute path='/postedCreditNotes/:docType/:orderId/setoff' exact component={ReceiptSetoffScreen} />
          <PrivateRoute path='/customers/:customerId/postedCreditNotes/:docType/:orderId/setoff' exact component={ReceiptSetoffScreen} />

          <PrivateRoute path='/distributors/:distributorId/invoices' exact component={SalesInvoices} />
          <PrivateRoute path='/distributors/:distributorId/postedCreditNotes/:docType' exact component={PostedCreditNotes} />

          <PrivateRoute path='/distributors/:distributorId/purchasingInvoices' exact component={PurchasingInvoices} />

          <PrivateRoute path='/distributors/:distributorId/customers/:customerId/salesOrders/' exact component={SalesOrders} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/:customerId/salesOrders/' exact component={SalesOrders} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/salesOrders/' exact component={SalesOrders} />

          <PrivateRoute path='/distributors/:distributorId/customers/:customerId/salesInvoices/' exact component={SalesInvoices} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/:customerId/salesInvoices/' exact component={SalesInvoices} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/salesInvoices/' exact component={SalesInvoices} />

          <PrivateRoute path='/distributors/:distributorId/customers/:customerId/receipts/' exact component={Receipt} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/:customerId/receipts/' exact component={Receipt} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/receipts/' exact component={Receipt} />

          <PrivateRoute path='/distributors/:distributorId/customers/:customerId/creditNotes/' exact component={CreditNotes} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/customers/:customerId/creditNotes/' exact component={CreditNotes} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId/creditNotes/' exact component={CreditNotes} />

          <PrivateRoute path='/distributors/:distributorId/receipts' exact component={Receipt} />

          <PrivateRoute path='/distributors/:distributorId/expenses' exact component={Expenses} />
          <PrivateRoute path='/distributors/:distributorId/expenses/:expenseId' exact component={ExpenseScreen} />
          <PrivateRoute path='/expense/:expenseId/confirm' exact component={ConfirmedExpenseScreen} />

          <PrivateRoute path='/distributors/:distributorId/payments/' exact component={Payments} />
          <PrivateRoute path='/distributors/:distributorId/payments/:paymentId' exact component={PaymentScreen} />
          <PrivateRoute path='/distributors/:distributorId/receipts/:receiptId' exact component={ReceiptScreen} />
          <PrivateRoute path='/distributors/:distributorId/receipts' exact component={Receipt} />
          <PrivateRoute path='/distributors/:distributorId/supplierReturns/' exact component={SupplierReturn} />
          <PrivateRoute path='/distributors/:distributorId/creditNotes' exact component={CreditNotes} />
          <PrivateRoute path='/distributors/:distributorId/creditNotes/:creditNoteId' exact component={CreditNoteScreen} />
          <PrivateRoute path='/distributors/:distributorId/grns/:grnNo' exact component={GRNScreen} />
          <PrivateRoute path='/distributors/:distributorId/grns/' exact component={GRN} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives/:salesPersonId' exact component={SalesRepView} />
          <PrivateRoute path='/distributors/:distributorId/salesRepresentatives' exact component={SalesRepresentative} />
          <PrivateRoute path='/distributors/:distributorId/expenseCategories' exact component={expenseCategories} />
          <PrivateRoute path='/distributors/:distributorId/expenseCategories/:expenseCategoryId' exact component={ExpenseCategroyView} />
          <PrivateRoute path='/distributors/:distributorId/warehouses' exact component={Warehouses} />
          <PrivateRoute path='/distributors/:distributorId/stockTransfers' exact component={StockTranfer} />
          <PrivateRoute path='/distributors/:distributorId/stockTake' exact component={StockTake} />
          <PrivateRoute path='/distributors/:distributorId/stockTransfers/:transferId' exact component={StockTransferScreen} />
          <PrivateRoute path='/distributors/:distributorId/stockTake/:stockTakeId' exact component={StockTakeScreen} />


          <PrivateRoute path='/invoices/:invoiceNo' exact component={InvoiceScreen} />
          <PrivateRoute path='/invoiceExport/:invoiceId' exact component={InvoiceExport} />

          <PrivateRoute path='/purchasingInvoices/' exact component={PurchasingInvoices} />
          <PrivateRoute path='/purchasingInvoices/:invoiceNo' exact component={PurchasingInvoiceScreen} />

          <PrivateRoute path='/sales/:docType/' exact component={SalesOrders} />
          <PrivateRoute path='/sales/:docType/:orderId' exact component={SalesOrderScreen} />
          <PrivateRoute path='/sales/:docType/:orderId/glItemList' exact component={GlItemListView} />
          <PrivateRoute path='/sales/:docType/:orderId/salesLines/:lineId' exact component={ItemViewSalesLine} />
          <PrivateRoute path='/sales/:docType/:orderId/salesLines/:lineId/items/:itemId' exact component={ItemViewSalesLine} />
          <PrivateRoute path='/sales/:docType/:orderId/confirm' exact component={SalesOrderConfirmScreen} />
          <PrivateRoute path='/customers/:customerId/sales/:docType/:orderId' exact component={SalesOrderScreen} />
          <PrivateRoute path='/customers/:customerId/sales/:docType' exact component={SalesOrders} />

          <PrivateRoute path='/postedCreditNotes/:docType' exact component={PostedCreditNotes} />
          <PrivateRoute path='/customers/:customerId/postedCreditNotes/:docType' exact component={PostedCreditNotes} />
          <PrivateRoute path='/postedCreditNotes/:docType/:orderId' exact component={PostedCreditNoteScreen} />
          <PrivateRoute path='/postedCreditNotes/:docType/:orderId/setoff' exact component={ReceiptSetoffScreen} />
          <PrivateRoute path='/customers/:customerId/postedCreditNotes/:docType/:orderId/setoff' exact component={ReceiptSetoffScreen} />

          <PrivateRoute path='/payments/' exact component={Payments} />
          <PrivateRoute path='/payments/:paymentId' exact component={PaymentScreen} />
          <PrivateRoute path='/customers/:customerId/payments/:paymentId' exact component={PaymentScreen} />
          <PrivateRoute path='/payments/:paymentId/confirm' exact component={ConfirmPaymentScreen} />


          <PrivateRoute path='/receipts/' exact component={Receipt} />
          <PrivateRoute path='/receipts/:receiptId' exact component={ReceiptScreen} />
          <PrivateRoute path='/customers/:customerId/receipts/:receiptId' exact component={ReceiptScreen} />
          <PrivateRoute path='/receipts/:receiptId/confirm' exact component={ConfirmReceiptScreen} />
          <PrivateRoute path='/customers/:customerId/receipts' exact component={Receipt} />
          <PrivateRoute path='/receipts/:receiptId/setoff' exact component={ReceiptSetoffScreen} />
          <PrivateRoute path='/customers/:customerId/receipts/:receiptId/setoff' exact component={ReceiptSetoffScreen} />
          <PrivateRoute path='/receipts/:receiptId/setoff' exact component={ReceiptSetoffScreen} />
          <PrivateRoute path='/invoicesettlement' exact component={ReceiptSetoffScreen} />
          <PrivateRoute path='/customers/:customerId/invoicesettlement' exact component={ReceiptSetoffScreen} />

          <PrivateRoute path='/distributorPayments/' exact component={DistributorPayments} />
          <PrivateRoute path='/distributorPayments/:distributorPaymentId' exact component={DistributorPaymentScreen} />
          <PrivateRoute path='/distributors/:distributorId/distributorPayments/' exact component={DistributorPayments} />
          <PrivateRoute path='/distributors/:distributorId/distributorPayments/:distributorPaymentId' exact component={DistributorPaymentScreen} />

          {/* <PrivateRoute path='/supplierReturns/' exact component={SupplierReturn} />
          <PrivateRoute path='/customers/:customerId/supplierReturns/:supplierReturnId' exact component={SuplierReturntScreen} />
          <PrivateRoute path='/supplierReturns/:supplierReturnId' exact component={SuplierReturntScreen} />
          <PrivateRoute path='/supplierReturns/:supplierReturnId/confirm' exact component={ConfirmSupplierReturnScreen} />
          <PrivateRoute path='/supplierReturns/:supplierReturnId/salesLines/:lineId' exact component={ItemView} />
           */}
          <PrivateRoute path='/creditNotes/' exact component={CreditNotes} />
          <PrivateRoute path='/creditNotes/:creditNoteId' exact component={CreditNoteScreen} />
          <PrivateRoute path='/customers/:customerId/creditNotes/:creditNoteId' exact component={CreditNoteScreen} />
          <PrivateRoute path='/creditNotes/:creditNoteId/salesLines/:lineId' exact component={ItemViewSalesLine} />
          <PrivateRoute path='/creditNotes/:creditNoteId/confirm' exact component={ConfirmedCreditNoteScreen} />
          <PrivateRoute path='/customers/:customerId/creditNotes' exact component={CreditNotes} />

          <PrivateRoute path='/creditNotes/:creditNoteId/setoff' exact component={CreditNoteSetoffScreen} />

          <PrivateRoute path='/salesInvoices/:orderId/salesLines/:lineId' exact component={ItemViewSalesLine} />
          <PrivateRoute path='/salesInvoices/' exact component={SalesInvoices} />
          <PrivateRoute path='/salesInvoices/:invoiceNo' exact component={SalesInvoiceScreen} />
          <PrivateRoute path='/salesInvoices/:invoiceNo/confirm' exact component={SalesInvoiceConfirmScreen} />

          <PrivateRoute path='/customers/:customerId/salesInvoices/:invoiceNo' exact component={SalesInvoiceScreen} />
          <PrivateRoute path='/customers/:customerId/salesInvoices/' exact component={SalesInvoices} />
          <PrivateRoute path='/customers/:customerId/settlements/:settlementId' exact component={SettlementView} />

          <PrivateRoute path='/grns' exact component={GRN} />
          <PrivateRoute path='/grns/:grnNo' exact component={GRNScreen} />

          <PrivateRoute path='/grns/:grnNo/confirm' exact component={GRNConfirmScreen} />

          <PrivateRoute path='/salesRepresentatives' exact component={SalesRepresentative} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId' exact component={SalesRepView} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/warehouses' exact component={Warehouses} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/edit' exact component={SalesRepView} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/inventory' exact component={Inventory} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/salesOrders' exact component={SalesOrders} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/creditNotes' exact component={CreditNotes} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/supplierReturns/' exact component={SupplierReturn} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/salesInvoices' exact component={SalesInvoices} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/collections' exact component={Collections} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/creditNotes/:creditNoteId' exact component={CreditNoteScreen} />

          <PrivateRoute path='/salesRepresentatives/:salesPersonId/receipts' exact component={Receipt} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/expenseCategories' exact component={expenseCategories} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/expenseCategories/:expenseCategoryId' exact component={ExpenseCategroyView} />
          {/* <PrivateRoute path='/salesRepresentatives/:salesPersonId/expenses/:expenseId' exact component={ExpenseScreen} /> */}
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/expenses' exact component={Expenses} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers/:customerId/salesOrders' exact component={SalesOrders} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers/:customerId/collections' exact component={Collections} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers/:customerId/salesInvoices' exact component={SalesInvoices} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers/:customerId/receipts' exact component={Receipt} />
          <PrivateRoute path='/salesRepresentatives/:salesPersonId/customers/:customerId/creditNotes' exact component={CreditNotes} />

          <PrivateRoute path='/expenses/' exact component={Expenses} />
          <PrivateRoute path='/expenses/:expenseId' exact component={ExpenseScreen} />

          <PrivateRoute path='/expenseCategories/' exact component={expenseCategories} />
          <PrivateRoute path='/expenseCategories/:expenseCategoryId' exact component={ExpenseCategroyView} />

          <PrivateRoute path='/expenseCategories/:expenseCategoryId' exact component={ExpenseCategroyView} />

          <PrivateRoute path='/warehouses/' exact component={Warehouses} />
          <PrivateRoute path='/warehouses/:warehouseId' exact component={WarehouseView} />
          <PrivateRoute path='/warehouses/:warehouseId/edit' exact component={WarehouseView} />
          <PrivateRoute path='/warehouses/:warehouseId/history' exact component={TransactionHistory} />
          <PrivateRoute path='/warehouses/:warehouseId/locations' exact component={WarehouseLocations} />
          <PrivateRoute path='/warehouses/:warehouseId/locations/:locationId' exact component={WarehouseLocationView} />
          <PrivateRoute path='/warehouses/:warehouseId/locations/:locationId/edit' exact component={WarehouseLocationView} />
          <PrivateRoute path='/warehouses/:warehouseId/locations/:locationId/inventory' exact component={Inventory} />
          <PrivateRoute path='/warehouses/:warehouseId/liveMap' exact component={LiveLocationMap} />

          <PrivateRoute path='/grns/:grnNo/salesLines/:lineId' exact component={ItemView} />

          <PrivateRoute path='/settlements/:settlementId' exact component={SettlementView} />

          {/* <PrivateRoute path='/warehouses/:warehouseId/stockTake' exact component={StockTake} />
          <PrivateRoute path='/warehouses/:warehouseId/stockTake/:stockTakeId' exact component={StockTakeScreen} /> */}

          <PrivateRoute path='/stockTake' exact component={StockTake} />
          <PrivateRoute path='/stockTake/:stockTakeId' exact component={StockTakeScreen} />
          <PrivateRoute path='/stockTake/:stockTakeId/confirm' exact component={ConfirmedStockTakeScreen} />
          <PrivateRoute path='/stockTake/:stockTakeId/lines/:lineId' exact component={StockTakeItemView} />

          <PrivateRoute path='/stockTransfers/' exact component={StockTransfers} />
          <PrivateRoute path='/stockTransfers/:transferId' exact component={StockTransferScreenNew} />
          <PrivateRoute path='/stockTransfers/:transferId/confirm' exact component={ConfirmStockTransferScreen} />

          <PrivateRoute path='/warehouses/:warehouseId/stockTransfers' exact component={StockTranfer} />
          <PrivateRoute path='/warehouses/:warehouseId/stockTransfers/:transferId' exact component={StockTransferScreen} />

          <PrivateRoute path='/salesOrders/:orderId/salesInvoices/:invoiceNo' exact component={SalesInvoiceScreen} />

          <PrivateRoute path='/salesReturns/:id' exact component={SalesReturnScreen} />
          <PrivateRoute path='/salesReturns' exact component={SalesReturns} />
          <PrivateRoute path='/salesReturns/:orderId/salesLines/:lineId' exact component={ItemView} />
          <PrivateRoute path='/salesReturns/:salesReturnId/creditNotes/:creditNoteId' exact component={CreditNoteScreen} />
          <PrivateRoute path='/salesReturns/:orderId/confirm' exact component={ConfirmedSalesReturnScreen} />
          <PrivateRoute path='/salesReturns/:salesReturnId/grns/:grnNo' exact component={GRNScreen} />

          <PrivateRoute path='/salesReturnRecipts/:docType' exact component={PostedCreditNotes} />
          <PrivateRoute path='/customers/:customerId/salesReturnRecipts/:docType' exact component={PostedCreditNotes} />
          <PrivateRoute path='/salesReturnRecipts/:docType/:orderId' exact component={PostedCreditNoteScreen} />


          <PrivateRoute path='/purchasing/:docType/' exact component={PurchasingOrders} />
          <PrivateRoute path='/distributors/:distributorId/purchasing/:docType' exact component={PurchasingOrders} />

          <PrivateRoute path='/purchasing/:docType/:orderId' exact component={PurchasingOrderOrderScreen} />
          <PrivateRoute path='/distributors/:distributorId/purchasing/:docType/:orderId/invoice/:invoiceNo' exact component={PurchasingOrderOrderScreen} />
          <PrivateRoute path='/distributors/:distributorId/purchasing/:docType/:orderId' exact component={PurchasingOrderOrderScreen} />

          <PrivateRoute path='/purchasing/:docType/:orderId/lines/:lineId' exact component={PurchaseOrderItemView} />
          <PrivateRoute path='/purchasing/:docType/:orderId/confirm' exact component={PurchasingOrderConfirmScreen} />
          <PrivateRoute path='/purchasing/:docType/:orderId/purchaseLines/:lineId' exact component={ItemViewPurchaseLine} />

          <PrivateRoute path='/tripScheduler' exact component={TripScheduler} />

          <PrivateRoute path='/distributors/:distributorId/purchasingCreditInvoices' exact component={PurchasingCreditInvoices} />
          <PrivateRoute path='/distributors/:distributorId/purchasingCreditInvoices/:invoiceNo' exact component={PurchaseCreditInvoiceScreen} />
          <PrivateRoute path='/purchasingCreditInvoices/' exact component={PurchasingCreditInvoices} />
          <PrivateRoute path='/purchasingCreditInvoices/:invoiceNo' exact component={PurchaseCreditInvoiceScreen} />

          <Route path='/login' exact render={props => authenticated ? <Redirect to='/' /> : <Login {...props} />} />
          <Route path='/requestPasswordResetOTP' exact component={OTPRequestPage} />
          <Route path='/resetPassword' exact component={ResetPassword} />

          <Route path='/sales/:orderId/offlineOrderReport' exact component={OfflineOrderReport} />
          <PrivateRoute path='/sales/:docType/:orderId/offlineOrderReport' exact component={OfflineOrderReport} />

          {/* Invoice List for Sales Return*/}
          <PrivateRoute path='/salesInvoicesList/:orderId/:customerNo' exact component={SalesInvoicesScreen} />

          {/* Target Scheduler related routes */}
          <PrivateRoute path="/targetScheduler" exact component={TargetScheduler} />
          <PrivateRoute path="/targetScheduler/generateReport" exact component={GenerateReport} />
          <PrivateRoute path="/targetScheduler/routeTarget" exact component={RouteTarget} />
          <PrivateRoute path="/targetScheduler/routeTarget/add" exact component={AddRouteTarget} />
          <PrivateRoute path="/targetScheduler/routeCustomerTarget" exact component={RouteCustomerTarget} />
          <PrivateRoute path="/targetScheduler/routeCustomerTarget/add" exact component={AddRouteCustomerTarget} />
          <PrivateRoute path="/targetScheduler/customerFrequency" exact component={CustomerFrequency} />

          {/* Other FailedOrders */}
          {/* <PrivateRoute path='/failedSalesOrders' exact component={FailedSalesOrders} /> */}
          <PrivateRoute path='/failedSalesOrders' exact component={OtherFailedSalesOrders} />
          <PrivateRoute path='/failedSalesOrders/:id' exact component={FailedSalesOrderLines} />
        </Switch>
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: isAuthenticated(state),
    user: getLoggedInUser(state),
  };
}

export default connect(mapStateToProps)(Root);

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => authenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />}
    />
  )
}
