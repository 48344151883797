import { combineReducers } from 'redux';
import { FETCH_POSTED_CREDIT_NOTES, DELETE_SALES_HEADER, START_FETCH_POSTED_CREDIT_NOTES, FINISH_FETCH_POSTED_CREDIT_NOTES, FETCH_SALES_CREDIT_MEMO_REASONS } from '../actions/postedCreditNotes';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES:
      const newState = { ...state };
      action.creditNotes.forEach(creditNote => newState[creditNote.No] = creditNote);
      return newState;
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES: {
      const newState = [...state]
      action.creditNotes.forEach(creditNote => {
        if (newState.includes(creditNote.No)) newState.push(creditNote.No);
      });

      return newState;
    }
    default:
      return state;
  }
};

export const byType = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES: {
      const newState = { ...state };
      action.creditNotes.forEach(creditNote => {
        const key = creditNote.DocType.replaceAll(' ', '');
        const idsByType = newState[key] || [];
        if (!idsByType.includes(creditNote.No)) {
          idsByType.push(creditNote.No);
          newState[key] = idsByType;
        }
      });

      return newState;
    }
    default:
      return state;
  }
};

export const byTypeAndNo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES: {
      const newState = { ...state };
      action.creditNotes.forEach(creditNote => {
        const key = creditNote.DocType.replaceAll(' ', '') + '-' + creditNote.No
        const idsByTypeAndNo = newState[key] || [];
        if (!idsByTypeAndNo.includes(creditNote.No)) {
          idsByTypeAndNo.push(creditNote.No);
          newState[key] = idsByTypeAndNo;
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.creditNotes.map(header => header.No)
      };
    }
    default:
      return state;
  }
};

export const total = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.total
      };

    default:
      return state;
  }
}

export const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSTED_CREDIT_NOTES:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.metadata
      };

    default:
      return state;
  }
}

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_POSTED_CREDIT_NOTES:
      return true;

    case FINISH_FETCH_POSTED_CREDIT_NOTES:
      return false;

    default:
      return state;
  }
}

const creditNotes = combineReducers({
  byId,
  ids,
  byType,
  byTypeAndNo,
  bySearchKey,
  total,
  metadata,
  fetching
});

export default creditNotes;

export const getPostedCreditNotesByType = (state, type) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const getPostedCreditNotesByTypeAndNo = (state, type, no) => {
  const key = type + '-' + no;
  const ids = state.byTypeAndNo[key] || [];

  return ids && ids.length > 0 && state.byId[ids[0]];
}

export const getPostedCreditNotesByNo = (state, no) => {
  return state.byId[no];
}

export const getPostedCreditNotesByTypeAndCustomer = (state, type, id) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).filter(creditNote => (creditNote.DocType.replaceAll(' ', '') === type && creditNote.SellToCusNo === id)).sort(sortDecByStartDate);
}

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;