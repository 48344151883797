import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchItems } from '../../../redux/actions/items';
import { getAuthData, getItem, DOC_TYPE_ID_BY_DEFINITION, getItemByItemNo, getSalesInvoiceLine, getSalesInvoiceLineMapByDocTypeAndItemNo } from "../../../redux/reducers";
import InventryItemDetails from "./InventryItemDetails";
import { fetchSalesLines } from '../../../redux/actions/salesInvoiceLines';
import { addSalesLine } from '../../../redux/actions/salesLines';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { FaLifeRing } from 'react-icons/fa';
import { returnTrue } from 'react-currency-format/lib/utils';
import uuid from "uuid/v1";


class Container extends Component {
  state = {
    loading: false,
    invoiceLines: [],
    returnTotalQty: 0,
    returnTotalQtyError: undefined,
    selectedRowKeys: []
  };
  componentDidMount() {
    this.loadInventoryItem(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.loadInventoryItem(nextProps);
  }

  inventoryItemRequestSent = false;
  loadInventoryItem = props => {
    if (this.inventoryItemRequestSent) return;
    const { fetchItems, location = {}, authData, order } = props;
    const itemId = location && location.state && location.state.itemId;
    const lotNo = location && location.state && location.state.LotNo;
    this.inventoryItemRequestSent = true;
    this.setState({ loading: true })
    fetchItems({ UserCode: authData.userId, AllItems: "Yes", LocCode: "" })
    this.props.fetchSalesLines({ DocType: 'Invoice', No: itemId, LotNo: lotNo, SellToCusNo: order.SellToCusNo }).then(response => {
      //console.log(response)
      this.setState({ invoiceLines: response.data })
      this.setState({ loading: false })
    });
  }

  handleUpdateOrder = () => {
    const { invoiceLines, returnTotalQtyError, returnTotalQty, } = this.state;
    const { docType, orderId, lineId } = this.props.match.params;
    const { toastManager, authData, history, itemId, order, inventoryItem } = this.props;

    if (parseInt(returnTotalQty) <= 0) return;
    if (returnTotalQtyError) return;

    let defaultReturnLine = undefined;

    const totalLineReturnQuantity = this.getTotalLineReturnQuantity(invoiceLines);
    if (parseInt(returnTotalQty) > totalLineReturnQuantity) {
      defaultReturnLine = {
        Quantity: parseInt(returnTotalQty) - totalLineReturnQuantity,
        LineSessionID: uuid(),
        DocNo: decodeURIComponent(orderId),
        DocType: '5',
        Type: '2',
        No: itemId,
        MapLocation: order.MapLocation,
        UnitPrice: inventoryItem.UnitPrice,
        UserCode: authData.userId,
        LotNo: inventoryItem.LotNo,
        MarketPrice: inventoryItem.MarketPrice,
        //ReturnInvDocNo: decodeURIComponent(orderId),
        //ReturnInvLineNo: Math.floor(1000 + Math.random() * 9000),
        isDefaultLine: true
      }
    }

    const invoiceLinesWithError = invoiceLines ? invoiceLines.filter(line => line.ReturnQty && line.ReturnQtyError && line.Selected) : [];
    if (invoiceLinesWithError.length > 0) return;

    let returnLines = [];
    returnLines = invoiceLines ? invoiceLines.filter(line => line.ReturnQty && !line.ReturnQtyError && line.Selected) : [];
    if (defaultReturnLine) returnLines.push(defaultReturnLine);

    returnLines.forEach((line, index) => {
      this.setState({ loading: true });
      const lineObject =
      {
        ...line,
        LineSessionID: uuid(),
        Type: '2',
        Quantity: line.ReturnQty,
        DocNo: decodeURIComponent(orderId),
        UserCode: authData.userId, DocType: '5',
        ReturnInvDocNo: line.DocNo,
        ReturnInvLineNo: line.LineNo
      }

      this.props.addSalesLine(line.isDefaultLine ? line : lineObject)
        .then(({ error }) => {
          if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
          else {
            toastManager.add('Line added successfully.', { autoDismiss: true, appearance: 'success' });
          }
        }).finally(() => {
          if (index + 1 === returnLines.length) {
            history.goBack();
          }
        });
    });
    //this.handleRequest(this.props.addSalesLine, 'Line updated successfully.');
  }


  handleUpdateOrderQty = (value) => {
    //console.log(value)
    this.setState({
      returnTotalQty: value,
      returnTotalQtyError: undefined
    }, () =>
      this.validateTotalReturnQunaity()
    )

    //this.handleRequest(this.props.updateSalesLine, 'Line updated successfully.');
  }

  handleRemoveSalesLine = () => this.handleRequest(this.props.deleteSalesLine, 'Line removed successfully.');

  handleRequest = (requestCall, successMessage) => {
    const { authData, deleteSalesLine, history, toastManager } = this.props;
    this.setState({ loading: true });
    requestCall({ ...this.state.salesLine, UserCode: authData.userId }).then(({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = () => this.setState({ salesLine: { ...this.props.salesLine } });

  handleUpdateNumberField = (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    this.setState({ salesLine: { ...this.state.salesLine, [key]: value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  handleTableInputTextChange = (field, value, record, errorField) => {
    const { invoiceLines, returnTotalQty } = this.state;

    this.setState({ returnTotalQtyError: undefined })

    const updatedArray = invoiceLines.map(line => {
      const updateLine = line;
      if (record.DocNo === line.DocNo) {
        updateLine['ReturnQty'] = value;

        const { Quantity = '0', QuantityReturned = '0' } = line;
        const quantity = parseFloat(Quantity.replaceAll(',', ''));
        const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));

        if (quantity - quantityReturned < parseFloat(value)) {
          updateLine['ReturnQtyError'] = 'Invalid quantity'
        } else {
          updateLine['ReturnQtyError'] = undefined
        }
      }
      return updateLine;
    })

    this.setState({ invoiceLines: updatedArray }, () => this.validateTotalReturnQunaity(updatedArray))
  }


  handleSelectRowChange = selectedRowKeys => {
    const { invoiceLines } = this.state;

    const updatedArray = invoiceLines.map((line, index) => {
      const updateLine = line;
      const filteredIndexArray = selectedRowKeys.filter(i => i === line.LineSessionID + "_" + line.LotNo + '_' + line.DocNo);
      if (filteredIndexArray && filteredIndexArray.length > 0) {
        const { Quantity = '0', QuantityReturned = '0' } = line;
        const quantity = parseFloat(Quantity.replaceAll(',', ''));
        const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));

        updateLine['Selected'] = true
        updateLine['ReturnQty'] = line.ReturnQty ? line.ReturnQty : (quantity - quantityReturned);
      } else {
        updateLine['Selected'] = false
        updateLine['ReturnQty'] = undefined
      }

      return updateLine;
    })

    this.setState({ invoiceLines: updatedArray, selectedRowKeys: selectedRowKeys }, () => this.validateTotalReturnQunaity())
  }

  validateTotalReturnQunaity = () => {
    const { returnTotalQty, invoiceLines } = this.state;

    const totalReturnFromLines = this.getTotalLineReturnQuantity(invoiceLines);

    if (!returnTotalQty || parseInt(returnTotalQty) <= 0) {
      this.setState({ returnTotalQtyError: 'Total return quantity should be grater than zero.' })
    } else if (totalReturnFromLines > parseInt(returnTotalQty)) {
      //this.setState({ returnTotalQtyError: 'Total Return quntity must be grater than ' + totalReturnFromLines })
      this.setState({ returnTotalQtyError: 'You cannot exceed the return Qty' })
    } else {
      this.setState({ returnTotalQtyError: undefined })
    }
  }

  getTotalLineReturnQuantity = (lines) => {
    let totalReturnFromLines = 0
    const updatedArray = lines ? lines : []
    for (var i = 0; i < updatedArray.length; i++) {
      if (!updatedArray[i].ReturnQtyError && updatedArray[i].ReturnQty && updatedArray[i].Selected) {
        totalReturnFromLines = totalReturnFromLines + parseInt(updatedArray[i].ReturnQty)
      }
    }

    return totalReturnFromLines;
  }

  render() {
    const { location = {}, match, resource, order, inventoryItem } = this.props;
    const { state = {} } = location
    const { loading, invoiceLines, returnTotalQty, returnTotalQtyError, selectedRowKeys } = this.state;
    const { docType } = this.props.match.params;

    const invoiceLinesToShow = invoiceLines.filter(line => {
      const { Quantity = '0', QuantityReturned = '0' } = line;
      const quantity = parseFloat(Quantity.replaceAll(',', ''));
      const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));
      return quantity - quantityReturned > 0;
    })

    return (
      <Spin spinning={loading || !inventoryItem} tip={"Loading item"}>
        {(inventoryItem) ?
          <InventryItemDetails
            inventoryItem={inventoryItem}
            onUpdateNumberField={this.handleUpdateNumberField}
            onUpdateOrder={this.handleUpdateOrder}
            onUpdateOrderQty={this.handleUpdateOrderQty}
            onRemoveSalesLine={this.handleRemoveSalesLine}
            onResetSalesLine={this.handleResetSalesLine}
            resource={resource}
            handleTableInputTextChange={this.handleTableInputTextChange}
            order={order}
            newOrderItem={match && match.params.lineId === 'new'}
            docType={docType}
            invoiceLines={invoiceLinesToShow}
            returnTotalQty={returnTotalQty}
            returnTotalQtyError={returnTotalQtyError}
            onSelectRowChange={this.handleSelectRowChange}
            selectedRowKeys={selectedRowKeys}
          /> :
          <div style={{ width: '100%', height: 200 }} />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location = {} } = ownProps
  //const { state = {} } = location
  const { docType, orderId, lineId } = ownProps.match.params;
  let inventoryItem;
  const itemId = location && location.state && location.state.itemId;
  const order = location && location.state && location.state.order;
  inventoryItem = getItemByItemNo(state, itemId)
  const invoiceLines = getSalesInvoiceLineMapByDocTypeAndItemNo(state, 'Invoice', itemId);
  return { authData: getAuthData(state), inventoryItem, itemId, invoiceLines, order };
};



export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchItems,
        fetchSalesLines,
        addSalesLine
        //updateSalesLine,
        //deleteSalesLine
      }
    )(Container)
  )
);