const ITEM_COUNT = 100;
let data = [];

export const statuses = ["BLOCK", "UNBLOCK"];
export const blockedReasons = [{ description: "Block Reson 1", type: "Type 1" }, { description: "Block Reson 2", type: "Type 2" }, { description: "Block Reson 3", type: "Type 1" }];

export const generateDemoLocations = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateDemoLocation(i));
  }
}

const generateDemoLocation = index => {

  const item = {
    locationNo: "location" + index,
    name: "Promate Location " + index,
    description: "Promate Location ",
    status: statuses[getRandomNumber(0, statuses.length)],
    blockedReasons
  }

  return item;
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const getDemoLocations = () => {
  return data;
}

export const getDemoLocation = id => {
  return data.find(item => item.locationNo === id);
}