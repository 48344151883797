import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { getDemoInvoicesByCount, generateDemoOrders } from '../../SalesInvoices/dummyInvoice'
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class InvoiceList extends Component {

  componentDidMount = () => generateDemoOrders()

  getColumns = () => {
    const columns = [{
      title: 'Invoice ID',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      render: text => <p>{text}</p>,
    }, {
      title: 'Customer ID',
      dataIndex: 'billToCustomerCode',
      key: 'billToCustomerCode',
      render: text => <p>{text}</p>,
    }, {
      title: 'Customer Name',
      dataIndex: 'billToCustomerName',
      key: 'billToCustomerName',
      render: text => <p>{text}</p>,
    }, {
      title: 'Invoice Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: text => <p>{text && moment(text).format(dateFormatView)}</p>,
    }, {
      title: 'Invoice Type',
      dataIndex: 'orderType',
      key: 'orderType',
      render: text => <p>{text}</p>,
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: text => text && <Tag>{text}</Tag>,
    }, {
      title: 'Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    }, {
      title: 'Outstanding',
      dataIndex: 'balanceToSettle',
      key: 'balanceToSettle',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    }]

    return columns;
  }

  getRowSelection = () => {
    const { selectedRowKeys, selectedRows } = this.props;
    return {
      selectedRows,
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.handleOnClickSelectInvoices(selectedRowKeys, selectedRows)
      }
    };
  }

  render() {
    return (
      <Table
        rowSelection={this.getRowSelection()}
        columns={this.getColumns()}
        dataSource={getDemoInvoicesByCount()}
        pagination={false}
        size={'small'}
        scroll={{ x: 800, y: 470 }} />
    )
  }
}

export default InvoiceList;