import React, { Component } from "react";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { dateFormatView } from "../../const/Formats";

class SalesReturnCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history } = this.props;
    const { orderNo, orderDate, billToCustomerCode, billToCustomerName, status, amountIncludingVat, returnCategory, salespersonCode } = order;
    return (
      <Row onClick={() => history.push("/salesReturns/" + orderNo)}>
        <Row style={styles.alignRow}>
          <Col xs={10} sm={3} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>#{orderNo ? orderNo.replace(/---/g, '/') : ''}</span>
          </Col>
          <Col xs={0} sm={6} md={3} lg={3} style={{ textAlign: 'center', paddingLeft: 0 }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>
          <Col xs={4} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={0} md={4} lg={3} style={{ textAlign: 'left' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}> {billToCustomerCode}</p>
          </Col>
          <Col xs={0} sm={0} md={4} lg={3} style={{ textAlign: 'left' }}>
            <span>{billToCustomerName}</span>
          </Col>

          <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'left' }}>
            <span>{salespersonCode}</span>
          </Col>

          <Col xs={0} sm={0} md={4} lg={3} style={{ textAlign: 'left' }}>
            <span style={{ fontSize: 12 }}>{returnCategory}</span>
          </Col>
          <Col xs={0} sm={6} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 11 }}>{salespersonCode}</span>
          </Col>
          <Col xs={10} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={amountIncludingVat || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>
          {/* <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(orderDate).format(dateFormat)}</span>
          </Col> */}
          <Col xs={4} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}> {billToCustomerCode}</p>
          </Col>
          <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{billToCustomerName}</span>
          </Col>


        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={14} sm={9} md={0} lg={0} style={styles.bottomColumnStyles}>
            <span>{returnCategory}</span>
          </Col>
          <Col xs={0} sm={4} md={0} lg={0} style={{ textAlign: 'left' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}> {billToCustomerCode}</p>
          </Col>
          <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{salespersonCode}</span>
          </Col>
          <Col xs={0} sm={6} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{billToCustomerName}</span>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 5
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}
export default withRouter(SalesReturnCard);