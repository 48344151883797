import { combineReducers } from 'redux';
import { FETCH_TERRITORIES, START_FETCH_TERRITORIES, FINISH_FETCH_TERRITORIES } from '../actions/territories';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TERRITORIES:
      const newState = {};
      action.territories.forEach(terr => newState[terr.TerrCode] = terr);
      return newState;
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_TERRITORIES:
      return action.territories.map(terr => terr.TerrCode);
    default:
      return state;
  }
};

// export const byUserId = (state = [], action) => {
//   switch (action.type) {
//     case FETCH_FILTERED_ROUTES: {
//       const newState = {};
//       const ids = newState[action.UserCode] || [];
//       action.routes.forEach(route => {
//         ids.push(route.RouteCode);
//       });
//       newState[action.UserCode] = ids;
//       return newState;
//     }
//     default:
//       return state;
//   }
// };

export const fetchingTerrirtories = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_TERRITORIES:
      return true;

    case FINISH_FETCH_TERRITORIES:
      return false;

    default:
      return state;
  }
};

const territories = combineReducers({
  byId,
  ids,
  fetchingTerrirtories
});

export default territories;

export const getTerritories = state => {
  return state.ids.map(id => state.byId[id])
}

// export const getRoutesByUser = (state, UserCode) => {
//   const ids = state.byUserId[UserCode] || [];
//   return ids.map(id => state.byId[id])
// }

export const isFetchingTerritories = state => state.fetchingTerrirtories;

export const getTerritoriesTotal = state => state.total;
