import { Button, Row, Select, Spin } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import Api from '../../../../api/consts';
import { generateRouteWiseProductiveReport } from '../../../../api/reports';
import { ProMateGreen } from '../../../../const/Theme';
import { fetchAllMonths, fetchAllYears, fetchAllBrands, fetchAllItemSubCategoryOne } from '../../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAllMonths, getAllYears, getAuthData, getAllBrands, getAllItemSubCateoryOne } from '../../../../redux/reducers';

export class RouteWiseProductiveReport extends Component {
    constructor(props) {
        super(props);
        this.treeData = [];
    }

    state = {
        loading: false,
        generating: false,
        filter: {
            Month: String(moment().format("MMM")).toUpperCase(),
            Year: moment().format("YYYY"),
            UserTag: "",
            BrandFilter: "",
            ItemSubCategory1Filter: ""
        },
        CSRUsers: []
    }
    async componentDidMount() {
        const { fetchAllMonths, fetchAllYears, fetchSalesUserHierarchy, authData, fetchAllBrands,
            fetchAllItemSubCategoryOne } = this.props;

        this.setState({
            loading: true
        });

        await fetchAllMonths();
        await fetchAllYears();
        const brands = await fetchAllBrands();
        const subCategories = await fetchAllItemSubCategoryOne();

        let results = await fetchSalesUserHierarchy(authData);
        results = results.records.map((result) => {
            return JSON.parse(result.jSONSalesHichyStruct);
        });
        for (const record of results) {
            this.generateTreeObject(record);
        }

        this.setState({
            loading: false,
            CSRUsers: this.treeData,
            filter: {
                ...this.state.filter,
                UserTag: this.treeData.length > 0 ? this.treeData[0].value : "",
                BrandFilter: brands.records.length > 0 ? brands.records[0].Code : "",
                ItemSubCategory1Filter: subCategories.records.length > 0 ? subCategories.records[0].Code : "",
            }
        });

    }

    handleSelectChange(type, value) {
        this.setState({
            filter: {
                ...this.state.filter,
                [type]: value
            }
        });
    }

    generateTreeObject = (input) => {
        const arr = Object.values(input)
        return arr.map(item => {
            const { user } = item;
            if (user?.role === "CSR") {
                this.treeData.push(
                    {
                        title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                        key: item.positionCode,
                        value: item.positionCode,
                        role: user?.role,
                        code: user.code,
                        name: user?.name
                    }
                );
            }
            return {
                title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                key: item.positionCode,
                value: item.positionCode,
                children: this.generateTreeObject(item.descendantStructure)
            }
        })
    }

    generateReport = async () => {
        const { toastManager } = this.props;
        const { Month, UserTag, Year, BrandFilter, ItemSubCategory1Filter } = this.state.filter;
        try {
            this.setState({
                generating: true
            });

            const response = await generateRouteWiseProductiveReport({
                Month,
                Year,
                UserTag,
                BrandFilter,
                ItemSubCategory1Filter
            });

            if (!response.error) {
                if (response.url !== "DBReports/null") {
                    setTimeout(() => {
                        this.setState({ generating: false });
                        window.open(Api.files.download.url(response.url), '_blank');
                    }, 5000);
                } else {
                    toastManager.add('Report details not found report. ', { autoDismiss: true, appearance: 'info' });
                    this.setState({ generating: false });

                }
            } else {
                toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                this.setState({ generating: false });
            }
        } catch (error) {
            toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
            this.setState({ generating: false });
        }
    }

    render() {
        const { Option } = Select;
        const { filter: { Month, Year, UserTag, BrandFilter, ItemSubCategory1Filter }, CSRUsers, loading, generating } = this.state;
        const { years, months, brands, itemSubCateoryOne } = this.props;

        return (
            <Spin spinning={loading} tip={"Loading Details"}>
                <div style={{ width: "100%", border: `1px solid ${ProMateGreen}`, height: "320px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ padding: "10px 0" }}>
                        <h3 style={{ textAlign: "center" }}>
                            <b>Route Wise Productive Report</b>
                        </h3>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%", padding: "0px 15px" }}>
                        <div style={{ width: "100%" }}>
                            <Select
                                value={Year}
                                style={{ width: "100%" }}
                                onChange={(value) => this.handleSelectChange('Year', value)}
                            >
                                {years?.records.map((year, index) =>
                                    (<Option key={index + year.Code} value={year.Code} >{year.Description}</Option>)
                                )}
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Select
                                value={Month}
                                style={{ width: "100%" }}
                                onChange={(value) => this.handleSelectChange('Month', value)}
                            >
                                {months?.records.map((month, index) =>
                                    (<Option key={index + month.Code} value={month.Code} >{month.Description}</Option>)
                                )}
                            </Select>
                        </div>
                        <div>
                            <Select
                                showSearch
                                placeholder={'Select Brand'}
                                style={{ width: '100%' }}
                                onChange={value => this.handleSelectChange('BrandFilter', value)}
                                value={BrandFilter}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {brands?.records.map((brand, index) =>
                                    <Option key={index + new Date()} value={brand.Code}>{brand.Description}</Option>
                                )}
                            </Select>
                        </div>
                        <div>
                            <Select
                                showSearch
                                placeholder={'Select Sub Category'}
                                style={{ width: '100%' }}
                                onChange={value => this.handleSelectChange('ItemSubCategory1Filter', value)}
                                value={ItemSubCategory1Filter}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {itemSubCateoryOne?.records.map((category, index) =>
                                    category.Code === "" ? < Option key={index + new Date()} value={category.Code} >All CATEGORIES</Option> : <Option key={index} value={category.Code}>{category.Code}</Option>
                                )}
                            </Select>
                        </div>
                        <div>
                            <Select
                                showSearch
                                placeholder={'Select CSR User'}
                                style={{ width: '100%' }}
                                onChange={value => this.handleSelectChange('UserTag', value)}
                                value={UserTag}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {CSRUsers.map((user, index) =>
                                    <Option key={index} value={user.value}>{user.title}</Option>
                                )}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", bottom: 0, left: 0, right: 0 }}>
                            <Button style={{ marginBottom: 20 }} type='primary' onClick={() => this.generateReport()} disabled={generating}>
                                {generating ? 'Generating...' : 'Generate Report'}
                            </Button>
                        </Row>
                    </div>
                </div>
            </Spin >
        )
    }
}

const mapStateToProps = (state) => {
    const months = getAllMonths(state);
    const years = getAllYears(state);
    const authData = getAuthData(state);
    const brands = getAllBrands(state);
    const itemSubCateoryOne = getAllItemSubCateoryOne(state);

    return { months, years, authData, brands, itemSubCateoryOne }
}

const mapDispatchToProps = {
    fetchAllMonths,
    fetchAllYears,
    fetchSalesUserHierarchy,
    fetchAllBrands,
    fetchAllItemSubCategoryOne
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(RouteWiseProductiveReport))