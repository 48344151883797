import React, { Component } from "react";
import Footer from './Footer'
import { Table, Row, Col, Form, Input, Button, DatePicker, TreeSelect, Select } from "antd";
import PaymentCard from './PaymentCard';
import { withRouter } from 'react-router-dom';
import PaymentCardHeader from "./PaymentCardHeader";
import { dateFormat, dateTimeFormatView } from "../../const/Formats";
import { routeNames } from "../Routes/RouteDemoData";
import { names } from "../Customers/CustomerDemoData";
import moment from 'moment';
const { Option } = Select;
const { Search } = Input;
const { TreeNode } = TreeSelect;

class PaymentTable extends Component {

  navigateToPaymentView = (payment) => {
    const { match } = this.props;
    return this.props.history.push(match.url + "/" + payment.paymentNo)
  }

  renderColumns = () => {
    return [{
      render: payment => <PaymentCard payment={payment} navigateToPaymentView={() => this.navigateToPaymentView(payment)} />
    }]
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 25;
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { authData: { userId, roleCode } } = this.props;
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter } = this.props;
    return (
      <div className="promate-table-header">
        <Form style={{ marginBottom: 20, marginRight: 15 }}>
          <Row gutter={5}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>

              <Col lg={5} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <TreeSelect
                  showSearch
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  defaultValue={roleCode === "SALES_REPRESENTATIVE" ? "Janaka" : (roleCode === "DISTRIBUTOR") ? "distributor" : "admin"}
                  allowClear
                  treeDefaultExpandAll
                >
                  <TreeNode value="admin" title="Promate Admin">
                    <TreeNode value="distributor" title="Distributor">
                      <TreeNode value="Janaka" title="Janaka" />
                      <TreeNode value="Nimal" title="Nimal" />
                    </TreeNode>
                    <TreeNode value="accountant" title="Promate Accountant">
                      <TreeNode value="Kamal" title={"Kamal"} />
                    </TreeNode>
                  </TreeNode>
                </TreeSelect>
              </Col>

              <Col lg={5} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <DatePicker
                  format={dateTimeFormatView}
                  value={filter.from}
                  placeholder="From"
                  onChange={(date, dateString) => handleInputDateChange("from", date)}
                  style={{ width: '100%' }}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                />
              </Col>

              <Col lg={5} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <DatePicker
                  format={dateTimeFormatView}
                  value={filter.to}
                  placeholder="To"
                  onChange={(date, dateString) => handleInputDateChange("to", date)}
                  style={{ width: '100%' }}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                />
              </Col>

              {/* <Col lg={3} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Select placeholder={"Route"}
                // onChange={(value) => handleInputTextChange(value)}
                >
                  {routeNames.map(route =>
                    <Option value={route}>{route}</Option>
                  )}
                </Select>
              </Col> */}


              {/* <Col lg={3} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Select placeholder={"Outlet"}
                // onChange={(value) => handleInputTextChange(value)}
                >
                  {names.map(name =>
                    <Option value={name}>{name}</Option>
                  )}
                </Select>
              </Col> */}

              <Col lg={6} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Search placeholder="Search Text" onChange={(e) => handleInputTextChange(e.target.value)} />
              </Col>

              <Col lg={3} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Button type="primary" block onClick={handleSearchClick}>Search</Button>
              </Col>
            </Col>
          </Row>
        </Form>
        <PaymentCardHeader />
      </div>
    )
  }

  renderFooter = (filteredPayments) => {
    return (
      <div>
        <Footer {...this.props} filteredPayments={filteredPayments} />
      </div>
    )
  }

  getFilteredPayments = () => {
    const { payments, filter } = this.props;
    let filteredPayments = payments;

    if (filter && filter.text) {
      filteredPayments = payments.filter(payment => {

        let paymentNo = payment.paymentNo;
        paymentNo = paymentNo.replace(/-/g, "");

        let searchTerm = filter.text;
        searchTerm = searchTerm.replace(/-/g, "");

        return paymentNo.toLowerCase().includes(searchTerm.toLowerCase())
          || payment.sellToCustomerCode.toLowerCase().includes((filter.text).toLowerCase())
          || payment.sellToCustomerName.toLowerCase().replace(/\W/g, '').includes((filter.text.replace(/\W/g, '')).toLowerCase())
          || payment.paymentType.toLowerCase().includes((filter.text).toLowerCase())
          || (payment.status && payment.status.toLowerCase().includes((filter.text).toLowerCase()))
      });
    }

    return filteredPayments;
  }

  render() {
    const filteredPayments = this.getFilteredPayments();
    return (
      <div>
        <Table
          rowKey={'paymentNo'}
          rowClassName={'collectionRow'}
          columns={this.renderColumns()}
          bodyStyle={{ minHeight: this.getTableHeight(filteredPayments) }}
          dataSource={filteredPayments}
          scroll={{ y: this.getScrollAreaHeight() }}
          pagination={false}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredPayments)}
          showHeader={false}
          size={'small'} />
      </div>
    )
  }
}

export default withRouter(PaymentTable);