import uuid from 'uuid';

const ITEM_COUNT = 100;
let data = [];

export const names = ["Andrew Stephen", "Simon Cowel", "Mitchell Richedson", "Daren Iyan", "Brayan Adems", "Nicoles Stephen", "Theron M Goodman", "Darren Lara", "Elizabeth Mendella"];
export const statuses = ["ENDED"];
export const createdByUsers = ["SALES001", "SALES002", "SALES003", "SALES004"];
export const mapLocations = ['6.882561,79.981768', '6.882571,79.981868', '6.882567,79.981761', '6.88221,79.981268'];
export const salesPersonNames = ["Sales Person 1", "Sales Person 2", "Sales Person 3", "Sales Person 4", "Sales Person 5", "Sales Person 6", "Sales Person 7"];
export const productiveStatuses = ["Productive", "Not Productive"]
export const transactionCodes1 = ["order 4", "return 6", "EXPN 3", "receipt0", "return 12", "receipt3"]
export const transactionCodes2 = ["order 10", "return 5", "EXPN 4", "receipt10", "return 11", "receipt13"]
export const transactionTypes = [
  {
    type: "Receipts",
    transactionCode: "",
    lineCount: 0,
    totalValue: 0
  },
  {
    type: "Sales orders",
    transactionCode: "order 4",
    lineCount: 5,
    totalValue: 5500
  },
  {
    type: "Sales returns",
    transactionCode: "return 6",
    lineCount: 0,
    totalValue: 0
  },
  {
    type: "Expenses",
    transactionCode: "EXPN 3",
    lineCount: 0,
    totalValuer: 0
  },
  {
    type: "Sales invoices",
    transactionCode: "Invoice 1",
    lineCount: 0,
    totalValue: 0
  },
  {
    type: "Credit notes",
    transactionCode: "CN 0",
    lineCount: 0,
    totalValue: 0
  },

  {
    type: "GRNs",
    transactionCode: "GRN 1",
    lineCount: 0,
    totalValue: 0
  },
]

export const generateData = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateItem(i));
  }
  return data;
}

const generateItem = index => {

  const item = {
    id: "ID" + index,
    remarks: "Demo call remark, This contains special note about the call"
  }

  const status = statuses[getRandomNumber(0, statuses.length)]
  const createdAt = randomDate(new Date(2012, 0, 1), new Date())
  const endedAt = endDate(createdAt, getRandomNumber(1, 7))
  const createdBy = createdByUsers[getRandomNumber(0, createdByUsers.length)]
  const mapLocation = mapLocations[getRandomNumber(0, mapLocations.length)]
  const salesPersonName = salesPersonNames[getRandomNumber(0, salesPersonNames.length)]
  const productiveStatus = productiveStatuses[getRandomNumber(0, productiveStatuses.length)]
  const transactionType = transactionTypes[getRandomNumber(0, transactionTypes.length)]
  const transactionCodes = ["1. " + transactionCodes1[getRandomNumber(0, transactionCodes1.length)] + " (5) ", "2. " + transactionCodes2[getRandomNumber(0, transactionCodes2.length)] + " (4) ", "3. Order " + getRandomNumber(0, 20) + " (5) "]

  item.createdAt = createdAt
  item.endedAt = endedAt
  item.createdBy = createdBy
  item.customer = customer()
  item.mapLocation = mapLocation
  item.status = status
  item.salesPersonName = salesPersonName
  item.productiveStatus = productiveStatus
  item.transactionCodes = transactionCodes
  if (productiveStatus === "Productive") {
    item.noOfTransactions = getRandomNumber(1, 4)
  } else {
    item.noOfTransactions = 0
  }
  item.transactionType = transactionType
  item.totalSalesOrderAmount = getRandomNumber(1500, 4000)
  item.salesOrderLineCount = getRandomNumber(4, 10)

  return item;
}

function customer() {
  let customer = {
    code: "CODE " + getRandomNumber(50, 100),
    name: names[getRandomNumber(0, names.length)]
  }
  return customer
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const endDate = (start, hours) => {
  return new Date(start.getTime() + (hours * 60 * 60 * 1000));
}

export const getDemoItem = id => {
  return data.find(item => item.id === id);
}

export const addDemoCall = call => {
  return data.push(call);
}

export const getDemoCallByUserId = userId => {
  return data.find(call => call.createdBy === userId && call.status === "STARTED");
}

export const endDemoCall = userId => {
  let indexOfCall = undefined;
  const ongoingCall = data.find((call, index) => {
    indexOfCall = index
    return call.createdBy === userId && call.status === "STARTED"
  });
  //console.log("REMOVE ", indexOfCall, ongoingCall)
  data.splice(indexOfCall, 1, ongoingCall)
}

export const getDemoTransactionList = () => {
  return [
    {
      "transaction": "Create a new sales order",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date()),
      transactionCode: "order 11",
      transactionType: "Sales orders",
    },
    {
      "transaction": "Create a new Receipt",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date()),
      transactionCode: "receipt0",
      transactionType: "Receipts",
    },

    {
      "transaction": "Confirmed Receipt",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date()),
      transactionCode: "receipt10",
      transactionType: "Receipts",
    },
    {
      "transaction": "Create new sales return",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date()),
      transactionCode: "return 6",
      transactionType: "Sales returns",
    }
  ]
}


