import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import OrderCard from "./OrderCard";
import { getLoggedInUser } from "../../redux/reducers";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class OrderCardHeader extends Component {

	render() {
		const { loggedInUser: { roleCode } } = this.props;

		return (
			<Row>
				<Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea' }}>
					{roleCode != 'CSR' && <Col xs={2} sm={0} md={3} lg={1} style={{ textAlign: 'left', marginRight: 5 }}>
						<input
							type="checkbox"
							style={styles.input}
							checked={this.props.checkedAll}
							onChange={this.props.handleAllChecked}
						/></Col>}
					<Col xs={6} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>Order No</Col>
					<Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Order Type</Col>
					<Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>Status</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Order Date</Col>
					<Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Customer Code</Col>
					<Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Customer Name</Col>
					<Col xs={8} sm={5} md={4} lg={3} style={{ textAlign: 'left', marginLeft: 10 }}>Customer Category</Col>
					<Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Salesperson</Col>
					<Col xs={0} sm={5} md={3} lg={2} style={{ textAlign: 'left' }}>Discount Reason</Col>
					{roleCode === 'CSR' && <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>}
					{roleCode != 'CSR' && <Col xs={8} sm={4} md={3} lg={2} style={{ textAlign: 'right' }}>Amount</Col>}
				</Row>
				{/* <Row style={styles.lowerRow}>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{sellToCustomerCode}</p>
					</Col>
				</Row>
				<Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
					</Col>
				</Row> */}
			</Row>
		)
	}
}

const styles = {
	orderNo: {
		fontSize: 16,
		fontWeight: 600
	},
	alignRow: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: 35,
		paddingLeft: 10
	},
	orderDate: {
		fontSize: 12,
		fontWeight: 700
	},
	customerCode: {
		fontSize: 10,
		fontWeight: 700,
	},
	customerCodeBottom: {
		fontSize: 10,
		fontWeight: 700,
	},
	status: {
		fontSize: 10,
		fontWeight: 700,
		marginRight: 0
	},
	price: {
		fontSize: 18,
		fontWeight: 600,
	},
	icon: {
		fontSize: 10,
		marginRight: 5
	},
	lowerRow: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 5,
		marginTop: 2
	},
	bottomColumnStyles: {
		textAlign: 'left',
		color: '#808080',
		fontSize: 12
	},
	input: {
		// position: 'absolute',
		// right: 10,
		// top: -10,
		height: 20,
		width: 20,
	}

}

const mapStateToProps = (state) => {

	const loggedInUser = getLoggedInUser(state);

	return { loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(OrderCardHeader));


