import { combineReducers } from 'redux';
import { FETCH_PURCHASE_HEADERS, FETCH_PURCHASE_HEADER, DELETE_PURCHASE_HEADER, START_FETCH_PURCHASE_HEADERS, FINISH_FETCH_PURCHASE_HEADERS, FETCH_PURCHASE_CREDIT_MEMO_REASONS, FETCH_PURCHASE_RETURN_REASONS } from '../actions/purchasingHeaders';
import { DOC_TYPE_DEFINITIONS } from ".";

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS:
      const newState = { ...state };
      action.purchaseHeaders.forEach(purchaseHeader => newState[purchaseHeader.No] = purchaseHeader);
      return newState;
    case FETCH_PURCHASE_HEADER: {
      const newState = { ...state };
      newState[action.purchaseHeader.No] = action.purchaseHeader;
      return newState;
    }
    case DELETE_PURCHASE_HEADER: {
      const newState = { ...state };
      delete newState[action.purchaseHeader.No];
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS: {
      const newState = [...state]
      action.purchaseHeaders.forEach(purchaseHeader => {
        if (newState.includes(purchaseHeader.No)) newState.push(purchaseHeader.No);
      });

      return newState;
    }
    case FETCH_PURCHASE_HEADER: {
      const newState = [...state];
      if (!newState.includes(action.purchaseHeader.No)) {
        newState.push(action.purchaseHeader.No)
      }
      return newState;
    }
    case DELETE_PURCHASE_HEADER: {
      const newState = [...state];
      return newState.filter(id => id !== action.purchaseHeader.No);
    }
    default:
      return state;
  }
};

export const byType = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS: {
      const newState = {};
      action.purchaseHeaders.forEach(purchaseHeader => {
        const key = purchaseHeader.DocType.replaceAll(' ', '');
        const idsByType = newState[key] || [];
        if (!idsByType.includes(purchaseHeader.No)) {
          idsByType.push(purchaseHeader.No);
          newState[key] = idsByType;
        }
      });

      return newState;
    }
    case FETCH_PURCHASE_HEADER: {
      const newState = {};
      const idsByType = newState[action.purchaseHeader.DocType.replaceAll(' ', '')] || [];

      if (!idsByType.includes(action.purchaseHeader.No)) {
        newState[action.purchaseHeader.DocType.replaceAll(' ', '')] = [...idsByType, action.purchaseHeader.No];
      }

      return newState;
    }
    case DELETE_PURCHASE_HEADER: {
      const newState = {};
      const idsByType = newState[action.purchaseHeader.DocType.replaceAll(' ', '')] || [];
      newState[action.purchaseHeader.DocType.replaceAll(' ', '')] = idsByType.filter(id => id !== action.purchaseHeader.No);
      return newState;
    }
    default:
      return state;
  }
};

export const byTypeAndNo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS: {
      const newState = { ...state };
      action.purchaseHeaders.forEach(purchaseHeader => {
        const key = purchaseHeader.DocType.replaceAll(' ', '') + '-' + purchaseHeader.No
        const idsByTypeAndNo = newState[key] || [];
        if (!idsByTypeAndNo.includes(purchaseHeader.No)) {
          idsByTypeAndNo.push(purchaseHeader.No);
          newState[key] = idsByTypeAndNo;
        }
      });
      return newState;
    }
    case FETCH_PURCHASE_HEADER: {
      const newState = { ...state };
      const key = action.purchaseHeader.DocType.replaceAll(' ', '') + '-' + action.purchaseHeader.No
      const idsByType = newState[key] || [];

      if (!idsByType.includes(action.purchaseHeader.No)) {
        newState[key] = [...idsByType, action.purchaseHeader.No];
      }

      return newState;
    }
    case DELETE_PURCHASE_HEADER: {
      const newState = { ...state };
      const key = action.purchaseHeader.DocType.replaceAll(' ', '') + '-' + action.purchaseHeader.No
      const idsByType = newState[key] || [];
      newState[action.purchaseHeader.DocType.replaceAll(' ', '')] = idsByType.filter(id => id !== action.purchaseHeader.No);
      return newState;
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.purchaseHeaders.map(header => header.No)
      };
    }
    case DELETE_PURCHASE_HEADER: {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        const ids = newState[key];
        newState[key] = ids.filter(id => id !== action.purchaseHeader.No)
      });
      return newState;
    }
    default:
      return state;
  }
};

export const total = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.total
      };

    default:
      return state;
  }
}

export const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.metadata
      };

    default:
      return state;
  }
}

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_PURCHASE_HEADERS:
      return true;

    case FINISH_FETCH_PURCHASE_HEADERS:
      return false;

    default:
      return state;
  }
}

const purchaseCreditMemoReasons = (state = [], action) => {
  switch (action.type) {
    case FETCH_PURCHASE_CREDIT_MEMO_REASONS:
      return action.creditMemoReasons;

    default:
      return state;
  }
}

const purchaseReturnReasons = (state = [], action) => {
  switch (action.type) {
    case FETCH_PURCHASE_RETURN_REASONS:
      return action.returnReasons;

    default:
      return state;
  }
}

const purchaseHeaders = combineReducers({
  byId,
  ids,
  byType,
  byTypeAndNo,
  bySearchKey,
  total,
  metadata,
  fetching,
  purchaseCreditMemoReasons,
  purchaseReturnReasons
});

export default purchaseHeaders;

export const getPurchaseHeadersByType = (state, type) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const getPurchaseHeadersByTypeAndNo = (state, type, no) => {
  const key = type + '-' + no;
  const ids = state && state.byTypeAndNo[key] || [];

  return ids && ids.length > 0 && state.byId[ids[0]];
}

export const getPurchaseHeadersByTypeAndCustomer = (state, type, id) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).filter(purchaseHeader => (purchaseHeader.DocType.replaceAll(' ', '') === type && purchaseHeader.SellToCusNo === id)).sort(sortDecByStartDate);
}

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;

export const getPurchaseCreditMemoReasons = state => state.purchaseCreditMemoReasons;

export const getPurchaseReturnReasons = state => state.purchaseReturnReasons;