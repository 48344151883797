import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ProMateGreen } from "../../../const/Theme";

export class AddButton extends Component {
    render() {
        return (
            <Link to={this.props.route}>
                <div style={styles.navButton}>{this.props.label}</div>
            </Link>
        )
    }
}

export class StyledButton extends Component {
    render() {
        return (
            <div style={{ ...styles.button, background: this.props.color, width: this.props.width ?? "100%", fontSize: this.props.fontSize ?? 12 }} onClick={this.props.onClick}>
                {this.props.label && this.props.label}
                {this.props.icon && this.props.icon}
            </div>
        );
    }
}

const styles = {
    button: {
        padding: "5px 15px",
        cursor: "pointer",
        color: "#ffffff",
        letterSpacing: "0.5",
        fontWeight: "bold",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        height: "32px"
    },
    navButton: {
        backgroundColor: ProMateGreen,
        border: `1px solid #ffffff`,
        padding: "5px 15px",
        cursor: "pointer",
        color: "#ffffff",
        letterSpacing: "0.5",
        fontWeight: "bold"
    },
}