import { combineReducers } from 'redux';
import { FETCH_POSTED_CREDIT_NOTE_LINES } from '../actions/postedCreditNoteLines';

const getKey = line => {
	const { DocNo, LineNo } = line;
	return DocNo + '-' + LineNo;
}

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_POSTED_CREDIT_NOTE_LINES:
			const newState = { ...state };
			action.lines.forEach(line => newState[getKey(line)] = line);
			return newState;
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_POSTED_CREDIT_NOTE_LINES: {
			const newState = [...state]
			action.lines.forEach(line => {
				const key = getKey(line);
				if (newState.includes(key)) newState.push(key);
			});

			return newState;
		}
		default:
			return state;
	}
};

export const byDocNo = (state = {}, action) => {
	switch (action.type) {
		case FETCH_POSTED_CREDIT_NOTE_LINES: {
			const newState = { ...state };
			action.lines.forEach(line => {
				const key = line.DocNo
				const idsByTypeAndNo = newState[key] || [];

				const id = getKey(line);
				if (!idsByTypeAndNo.includes(id)) {
					idsByTypeAndNo.push(id);
					newState[key] = idsByTypeAndNo;
				}
			});
			return newState;
		}
		default:
			return state;
	}
};

const lines = combineReducers({
	byId,
	ids,
	byDocNo
});

export default lines;

export const getLinesByDocNo = (state, no) => {
	const ids = state.byDocNo[no] || [];

	return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

export const getLine = (state, docNo, lineNo) => {
	return state.byId[docNo + '-' + lineNo];
}