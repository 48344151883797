import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from "antd";
import { ProMateGreen } from "../../const/Theme";
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import { defaultCombinedFilter } from '../../redux/reducers/inventory';
import { updateCombinedFilter } from '../../redux/actions/inventory';
import PromatePageHeader from '../common/PromatePageHeader';
import PurchaseOrderItemTable from './PurchaseOrderItemTable';
import StockTakeWithItemTable from './StockTakeWithItemTable';

const defaultState = {
  brand: undefined,
  category1: undefined,
  category2: undefined,
  productType: undefined,
  textFilter: undefined,
};

class Inventory extends Component {
  state = { ...defaultState };

  componentWillUnmount = () => {
    this.props.updateCombinedFilter(defaultCombinedFilter);
  }

  renderTable = () => {
    const { location = {} } = this.props
    const { state = {} } = location;
    if (state && state.resource === "purchasingOrders") {
      return (
        <PurchaseOrderItemTable
          multiSelect={false}
          purchaseHeader={state.order} />
      )
    } else if (state && state.resource === "stockTakes") {
      return (
        <StockTakeWithItemTable
          multiSelect={false}
          stockTakeHeader={state.order} />
      )
    } else {
      return (
        <ItemTable
          multiSelect={false}
          salesHeader={state.order} />
      )
    }
  }

  render() {
    const partHeight = (window.innerHeight - 80) / 2;
    const { location = {} } = this.props
    const { state = {} } = location

    //console.log(state.resource)

    return (
      <div>
        <PromatePageHeader
          title={'Items'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {this.renderTable()}
          </Col>
        </PromatePageHeader>
      </div>
    )
  }

  addItems = () => {
    const { location = {} } = this.props
    const { state = {} } = location
    const { history } = this.props;
    history.replace({ pathname: state.resource + "/" + state.id, state: { data: 1 } });
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default connect(null, { updateCombinedFilter })(Inventory);