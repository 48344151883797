import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Tag, Row } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import Api from '../../../api/consts';
import { getAuthData } from '../../../redux/reducers';
import { dateFormatView } from '../../../const/Formats';

class CollectionExport extends Component {

  generateSalesLines = salesLines => {
    const salesLineRows = [];
    let lineNumber = 0;
    salesLines.forEach(salesLine => {
      lineNumber = lineNumber + 1;
      salesLineRows.push(
        <Row style={{ padding: '2px 10px' }}>
          <Col span={2} style={{ fontSize: 10 }}>{lineNumber}</Col>
          <Col span={9} style={{ fontSize: 10 }}>{salesLine.description}</Col>
          <Col span={3} style={{ fontSize: 10, textAlign: 'right' }}>
            <CurrencyFormat
              value={salesLine.unitPrice}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
          <Col span={2} style={{ fontSize: 10, textAlign: 'right' }}>{salesLine.quantity}</Col>
          <Col span={4} style={{ fontSize: 10, textAlign: 'right' }}>
            <CurrencyFormat
              value={salesLine.discountAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
          <Col span={4} style={{ fontSize: 10, textAlign: 'right' }}>
            <CurrencyFormat
              value={salesLine.lineAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
      )
    })
    return salesLineRows;
  }

  render() {
    const { distributorPayment, authData: { displayName } } = this.props;
    const {
      distributorPaymentNo,
      distributorPaymentDate,
      status,
      sellToCustomerName,
      sellToCustomerCode,
      salespersonCode,
      bankCode,
      paymentType,
      amount,
      chequeNo,
      slipNo,
      postingDate,
      links } = distributorPayment;

    const showDepositDetailForm = paymentType === 'DEPOSIT' || paymentType === 'Direct Deposite'
    const showChequePDCform = paymentType === 'PDC' || (paymentType === 'CHEQUE' || paymentType === 'Cheque')
    const attachmentfileUrls = links && links.length > 0 && links.filter(link => link.includes("/attachments/"));
    const fileUrl = attachmentfileUrls && attachmentfileUrls.length > 0 && attachmentfileUrls[attachmentfileUrls.length - 1];
    const signaturefileUrls = links && links.length > 0 && links.filter(link => link.includes("/customer-signature"));
    const customerSigned = signaturefileUrls && signaturefileUrls.length > 0;
    const bank = banks.find(bank => bank.bankid === bankCode);
    const type = paymentTypes.find(type => type.value === paymentType)
    const sellToCustomer = sellToCustomerCode && sellToCustomerCode !== 'NULL';

    return (
      <Row>
        <Row>
          <div id={'distributorPaymentPrintDiv'} style={{ padding: 25 }}>
            <Row>
              <img src={'/logo.png'} style={{ width: 150 }} alt='Logo' />
            </Row>
            <Row style={{ lineHeight: '25px', width: '635px', borderRadius: 5, marginTop: 10, position: 'ab' }}>
              <Col span={12}>
                {distributorPaymentNo && <tr>
                  <td>Collection No</td>
                  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                  <td>{distributorPaymentNo}</td>
                </tr>}
                {distributorPaymentDate && <tr>
                  <td>Date</td>
                  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                  <td>{moment(distributorPaymentDate).format(dateFormatView)}</td>
                </tr>}
                {status && <tr>
                  <td>Status</td>
                  <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
                  <td>{customerSigned && status !== 'Unverified' ? <Tag color="blue">{"Signed"}</Tag> : <Tag color="blue">{status}</Tag>}</td>
                </tr>}
                <tr>
                  <td style={{ height: 20 }}></td>
                </tr>
              </Col>
              <Col span={12}>
                {sellToCustomer && <tr>
                  <td colSpan={3}><b>Customer</b></td>
                </tr>}
                {sellToCustomer && <tr>
                  <td colSpan={3}>{sellToCustomerCode}</td>
                </tr>}
                {sellToCustomer && <tr>
                  <td colSpan={3}>{sellToCustomerName}</td>
                </tr>}
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Col span={4} style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: 500 }}>Amount</div>
                  <CurrencyFormat
                    value={amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    isNumericString={true}
                    fixedDecimalScale={true}
                    style={{ fontSize: 'large' }}
                    decimalScale={2} />
                </Col>
              </Col>
            </Row>
            <Row style={{ display: 'flex', flexDirection: 'row' }}>
              <Col span={12}>
                <div style={{ fontWeight: 500 }}>Payment Type</div>
                <p style={{ fontSize: 'large' }}>{type ? type.name : 'N/A'}</p>
              </Col>
              {(showChequePDCform || showDepositDetailForm) && <Col span={12}>
                <div style={{ fontWeight: 500 }}>Bank</div>
                <p style={{ fontSize: 'large' }}>{bank ? bank.bankname : 'N/A'}</p>
              </Col>}
            </Row>
            <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {showChequePDCform && <Col span={16} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {chequeNo && <Col span={5}>
                  <div style={{ fontWeight: 500 }}>Cheque No</div>
                  <p style={{ fontSize: 'large' }}>{chequeNo}</p>
                </Col>}
                {postingDate && <Col span={5} style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: 500 }}>Posting Date</div>
                  <p style={{ fontSize: 'large' }}>{postingDate && moment(postingDate).format(dateFormatView)}</p>
                </Col>}
              </Col>}
              {showDepositDetailForm && <Col span={8}>
                <Col span={4} style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: 500 }}>Slip No</div>
                  <p style={{ fontSize: 'large' }}>{slipNo}</p>
                </Col>
              </Col>}
            </Row>
            <Row>
              <Col span={24} >
                <div style={{ fontWeight: 500 }}>Attachment</div>
                <img
                  style={{ height: 200, border: '1px solid #efefef' }}
                  src={Api.files.download.url(fileUrl)}
                  alt='Attachment'
                />
              </Col>
            </Row>
            <Row style={{ lineHeight: '25px', width: '635px', marginTop: 15 }}>
              <Col span={12}>
                <div style={{ fontWeight: 500 }}>Customer signature</div>
                <img style={{ height: '128px', width: '300px', border: '1px solid #efefef' }} src={Api.files.download.url(signaturefileUrls)} alt='Signature' />
              </Col>
              <Col span={12} style={{ marginLeft: 5, width: '305px', }}>
                <div style={{ fontWeight: 500 }}>Sales Person</div>
                {salespersonCode && <tr>
                  <td colSpan={3}>{salespersonCode}</td>
                </tr>}
                {displayName && <tr>
                  <td colSpan={3}>{displayName}</td>
                </tr>}
              </Col>
            </Row>
            <div id={'test'} />
          </div>
        </Row>
      </Row>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

const paymentTypes = [
  {
    name: 'CASH',
    value: 'CASH',
    key: 'CASH'
  },
  {
    name: 'PDC - Post Dated Cheque',
    value: 'PDC',
    key: 'PDC'
  },
  {
    name: 'CHEQUE',
    value: 'CHEQUE',
    key: 'CHEQUE'
  },
  {
    name: 'Direct Deposit',
    value: 'DEPOSIT',
    key: 'DEPOSIT'
  }
]

const banks = [
  { "_id": "nXykAeaxKY5iwj3Nf", "bankid": "17", "bankname": "Amana Bank PLC", "shortname": "Amana Bank" },
  { "_id": "a3EMXnpF2rCD9k9ZY", "bankid": "2", "bankname": "Bank of Ceylon", "shortname": "BOC" },
  { "_id": "af4F3jy9bS3G7TCmB", "bankid": "15", "bankname": "Cargills Bank Ltd", "shortname": "Cargills Bank" },
  { "_id": "oH9FPPuHG6wdD7rNA", "bankid": "12", "bankname": "Citibank", "shortname": "Citi Bank" },
  { "_id": "bzJvkTY7ztAzSr9CP", "bankid": "9", "bankname": "Commercial Bank of Ceylon PLC", "shortname": "Commercial" },
  { "_id": "5wDbsznEPvaPDsp9e", "bankid": "11", "bankname": "Deutsche Bank AG", "shortname": "Deutsche Bank" },
  { "_id": "FQr7DLgJHvMqsmeaF", "bankid": "14", "bankname": "DFCC Vardhana Bank PLC", "shortname": "DFCC" },
  { "_id": "PZe9SAt8QFY7HeyHZ", "bankid": "1", "bankname": "Hatton National Bank PLC", "shortname": "HNB" },
  { "_id": "pMEFuSGNK7f3i8rpe", "bankid": "21", "bankname": "Nation Trust Bank", "shortname": "NTB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
  { "_id": "c4ngHthzcBK7Aarqi", "bankid": "18", "bankname": "MCB Bank Ltd", "shortname": "MCB Bank" },
  { "_id": "w9pAudAozSXoMK4Zz", "bankid": "4", "bankname": "National Development Bank PLC", "shortname": "NDB" },
  { "_id": "S2kpZwpJphFgzeqSG", "bankid": "7", "bankname": "Pan Asia Banking Corporation PLC", "shortname": "Pan Asia Bank" },
  { "_id": "xWey22FL4K3rD7DF2", "bankid": "10", "bankname": "People’s Bank", "shortname": "Peoples" },
  { "_id": "7NMY9kBctARPvkgiX", "bankid": "20", "bankname": "Business Development Cooperative", "shortname": "Business Development Cooperative" },
  { "_id": "WGcjpYLz4tmDupfJj", "bankid": "3", "bankname": "Sampath Bank PLC", "shortname": "Sampath" },
  { "_id": "kjBoquK33CMLXCxCP", "bankid": "8", "bankname": "Seylan Bank PLC", "shortname": "Seylan" },
  { "_id": "j9jLkLhbRkHyjGiQs", "bankid": "16", "bankname": "Standard Bank", "shortname": "Standard Bank" },
  { "_id": "Pk9fBB5Rb7JRtY3kR", "bankid": "19", "bankname": "Bank of India", "shortname": "Bank of India" },
  { "_id": "ni9rx7s3XL6Auge7f", "bankid": "6", "bankname": "The Hongkong \u0026 Shanghai Banking Corporation Ltd", "shortname": "HSBC" },
  { "_id": "phjQCAyeGuoZXoyQf", "bankid": "13", "bankname": "Union Bank of Colombo PLC", "shortname": "Union" },
]

const mapStateToProps = state => {
  return {
    authData: getAuthData(state),
  };
};

export default connect(mapStateToProps, null)(CollectionExport);