import { combineReducers } from 'redux';
import { FETCH_SALES_LINES, FETCH_SALES_LINE, REMOVE_SALES_LINE } from '../actions/salesLines';

const getKey = line => {
	const { DocType, DocNo, LineNo } = line;
	return DocType.replaceAll(' ', '') + '-' + DocNo + '-' + LineNo;
	console.log(DocType);
	console.log(DocNo);
	console.log(LineNo);

	return DocType + '-' + DocNo + '-' + LineNo;
}

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_SALES_LINES:
			const newState = { ...state };
			action.salesLines.forEach(salesLine => newState[getKey(salesLine)] = salesLine);
			return newState;
		case FETCH_SALES_LINE: {
			const newState = { ...state };
			newState[getKey(action.salesLine)] = action.salesLine;
			return newState;
		}
		case REMOVE_SALES_LINE: {
			const newState = { ...state };
			delete newState[getKey(action.salesLine)];
			return newState;
		}
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_SALES_LINES: {
			const newState = [...state]
			action.salesLines.forEach(salesLine => {
				const key = getKey(salesLine);
				if (newState.includes(key)) newState.push(key);
			});

			return newState;
		}
		case FETCH_SALES_LINE: {
			const newState = [...state];
			const key = getKey(action.salesLine);

			if (!newState.includes(key)) {
				newState.push(key)
			}
			return newState;
		}
		case REMOVE_SALES_LINE: {
			const newState = [...state];
			const key = getKey(action.salesLine);
			return newState.filter(id => id !== key);
		}
		default:
			return state;
	}
};

export const byTypeAndDocNo = (state = {}, action) => {
	switch (action.type) {
		case FETCH_SALES_LINES: {
			const newState = { ...state };
			action.salesLines.forEach(salesLine => {
				const key = salesLine.DocType.replaceAll(' ', '') + '-' + salesLine.DocNo
				const idsByTypeAndNo = newState[key] || [];

				const id = getKey(salesLine);
				if (!idsByTypeAndNo.includes(id)) {
					idsByTypeAndNo.push(id);
					newState[key] = idsByTypeAndNo;
				}
			});
			return newState;
		}
		case FETCH_SALES_LINE: {
			const newState = { ...state };
			const key = action.salesLine.DocType.replaceAll(' ', '') + '-' + action.salesLine.DocNo
			const idsByType = newState[key] || [];

			const id = getKey(action.salesLine);

			if (!idsByType.includes(id)) {
				idsByType.push(id);
				newState[key] = idsByType;
			}

			return newState;
		}
		case REMOVE_SALES_LINE: {
			const newState = { ...state };
			const key = action.salesLine.DocType.replaceAll(' ', '') + '-' + action.salesLine.DocNo
			const idsByType = newState[key] || [];

			const removeId = getKey(action.salesLine);

			newState[key] = idsByType.filter(id => id !== removeId);

			return newState;
		}
		default:
			return state;
	}
};

const salesLines = combineReducers({
	byId,
	ids,
	byTypeAndDocNo
});

export default salesLines;

export const getSalesLinesByDocTypeAndDocNo = (state, type, no) => {
	const key = type + '-' + no;
	const ids = state.byTypeAndDocNo[key] || [];

	return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

export const getSalesLineMapByDocTypeAndDocNo = (state, type, no) => {
	const key = type + '-' + no;
	const ids = state.byTypeAndDocNo[key] || [];

	const salesLineMap = {};
	ids && ids.length > 0 && ids.forEach(id => {
		const { No, LotNo, LocCode } = state.byId[id];
		const key = No + '-' + LotNo + '-' + LocCode;

		salesLineMap[key] = state.byId[id];
	});

	return salesLineMap;
}

export const getSalesLine = (state, type, docNo, lineNo) => {
	return state.byId[type + '-' + docNo + '-' + lineNo];
}