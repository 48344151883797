import React, { Component } from 'react';
import { PageHeader, Row } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class GRNScreen extends Component {

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 80;
    return window.innerHeight - (headerHeight);
  }

  render() {
    const { match } = this.props;
    const { grnNo } = match.params
    const newGrn = grnNo === 'new' ? true : false;

    return (
      <div>
        <PromatePageHeader
          title={newGrn ? 'New GRN' : 'GRN'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
            <Container />
          </Row>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default GRNScreen;