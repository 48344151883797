import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Col, Table } from "antd";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import AdvanceSearchFilters from "../../components/common/AdvanceSearchFilters";
import OrderCard from "./OrderCard";
import OrderCardHeader from "./OrderCardHeader";
import InvoiceCard from "./InvoiceCard";
import InvoiceCardHeader from "./InvoiceCardHeader";
import ReturnCardHeader from "./ReturnCardHeader";
import ReturnCard from "./ReturnCard";
import CreditNoteCardHeader from "./CreditNoteCardHeader";
import CreditNoteCard from "./CreditNoteCard";

class OrderTable extends Component {

  componentDidMount = () => {
    window.addEventListener('locationchange', this.onUrlChange);
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  getFilterFieldPropertiesCreditNotes = () => {
    const { customerId, docType } = this.props.match.params;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneThridOfColumn = { lg: 8, md: 24, sm: 24, xs: 24 };
    const oneFourthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    let data = {
      SALES_HIERARCHY: entireColumn,
      SELECT_ROUTE: customerId ? hidden : oneThridOfColumn,
      SELECT_OUTLET: customerId ? hidden : oneThridOfColumn,
      SELECT_CATEGORY: customerId ? hidden : oneThridOfColumn,
      FROM_DATE_PICKER: oneFourthOfColumn,
      TO_DATE_PICKER: oneFourthOfColumn,
      SEARCH_BUTTON: oneFourthOfColumn
    };

    if (docType === 'CreditMemo') {
      data = {
        ...data,
        SELECT_RETURN_REASON: oneFourthOfColumn,
      }
    } else if (docType === 'ReturnOrder') {
      data = {
        ...data,
        SELECT_SALES_RETRUN_REASONS: oneFourthOfColumn,
      }
    }

    return data;

  }

  getFilterFieldProperties = docType => {
    return this.getFilterFieldPropertiesCreditNotes();
  }

  getTableHeaders = docType => {
    return <CreditNoteCardHeader />;
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, match, setDefaultCustomerOption, setDefaultRouteOption } = this.props;
    const { customerId, docType } = match.params

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          {...this.getFilterFieldProperties(docType)}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
          filter={filter}
        />
        {this.getTableHeaders(docType)}
      </div>
    )
  }

  getOrderTotals = orders => {
    let total = 0;
    orders.forEach(order => {
      let AmountIncluVAT = order.AmountIncluVAT.replace(/,/g, "");
      total = total + parseFloat(AmountIncluVAT);
    })
    return total;
  }

  getNumberOfRecordLabel = () => {
    return 'Credit Notes';
  }

  renderFooter = () => {
    const { total, totalAmount, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={totalAmount}
        noOfRecordLabel={this.getNumberOfRecordLabel()}
        style={{ paddingRight: 33 }}
      />
    );
  }

  renderColumns = () => {
    const { docType } = this.props;
    return [{
      render: order => <CreditNoteCard order={order} docType={docType} />
    }];
  }

  render() {
    const { orders: filteredOrders, match } = this.props;
    const { docType } = match.params
    return (
      <Col>
        <Table
          rowKey={'orderNo'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders) }}
          columns={this.renderColumns()}
          dataSource={filteredOrders}
          size={'small'}
          pagination={false}
          scroll={{ y: this.getScrollAreaHeight() }}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredOrders)} />
      </Col>
    )
  }
}

OrderTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(OrderTable);