import { combineReducers } from 'redux';

export const stockTransferStatuses = {
  'Open': 'Open',
  'Confirmed': 'Confirmed', // 2 will future
  'Approved': 'Approved',// 1 will future
};


const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_HEADERS':
    case 'FETCH_TRANSFER_HEADER': {
      const newState = { ...state };
      action.transferHeaders.forEach(transferHeader => newState[transferHeader.DocNo] = transferHeader);
      return newState;
    }
    case 'DELETE_TRANSFER_HEADER': {
      const newState = { ...state };
      delete newState[action.transferHeader.DocNo];
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_HEADERS': {
      return action.transferHeaders.map(header => header.DocNo);
    }
    case 'FETCH_TRANSFER_HEADER': {
      const { DocNo } = action.transferHeaders[0];
      const newState = [...state];
      if (!newState.includes(DocNo)) newState.push(DocNo);
      return newState;
    }
    case 'DELETE_TRANSFER_HEADER': {
      const newState = [...state];
      return newState.filter(id => id !== action.transferHeader.DocNo);
    }
    default:
      return state;
  }
};

export const total = (state = 0, action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_HEADERS':
      return action.total;
    default:
      return state;
  }
}

export const fetchingTransferHeaders = (state = false, action) => {
  switch (action.type) {
    case 'START_FETCH_TRANSFER_HEADERS':
      return true;

    case 'FINISH_FETCH_TRANSFER_HEADERS':
      return false;

    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_HEADERS':
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.transferHeaders.map(transfer => transfer.DocNo)
      };

    case 'DELETE_TRANSFER_HEADER': {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        const ids = newState[key];
        newState[key] = ids.filter(id => id !== action.transferHeader.DocNo)
      });
      return newState;
    }
    default:
      return state;
  }
}

const transferHeaders = combineReducers({
  byId,
  ids,
  total,
  fetchingTransferHeaders,
  bySearchKey
});

export default transferHeaders;

export const getAll = state => {
  return state.ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const get = (state, no) => state.byId[no];

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getTotal = state => state.total;

export const isFetchingTransferHeaders = state => state.fetchingTransferHeaders;

export const getBySearchKey = (state, key) => {
  const ids = state.bySearchKey[key] || [];
  return ids.map(id => state.byId[id]);
}

