import React, { Component } from 'react';
import { Form, Row, Col } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import { EnglishTextArea as TextArea } from '../../common/EnglishInput';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class TransferFooter extends Component {

  render() {
    const { Remarks } = this.props.transfer;

    return (
      <div style={{ marginTop: 10 }}>
        <Col lg={12} md={12} sm={24} xs={24} style={{ padding: 10 }}>
          <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
            <TextArea value={Remarks} rows={4} placeholder={"Type here"} onChange={(value, e) => this.props.onInputChange('Remarks', value, '')} />
          </FormItem>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24} style={{ padding: 15 }}>
          <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "large" }}>Total transfer quantity</div>
            <div style={{ fontSize: "large" }}>{this.props.totalTransferQty}</div>
          </Col>
        </Col>
      </div>
    )
  }
}

export default TransferFooter;