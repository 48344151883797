import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchRoutes } from '../../redux/actions/routes'
import { getRoutes, getAuthData } from "../../redux/reducers";

const { Option } = Select;

class SelectDistributorStaff extends Component {
  componentDidMount = () => {
    const filter = { UserCode: this.props.authData.userId };
    this.props.fetchRoutes(filter);
  }

  render() {
    const {
      routes,
      onChange,
      showSearch = true,
      placeholder = 'Select Distributor Staff',
      allowClear = true,
      defaultValue,
      disabled
    } = this.props
    return (
      <Select
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(value) => onChange('DistributorStaffCode', value)}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%' }}
      >
        {routes.map(route =>
          <Option value={route.Code}>{route.Name}</Option>
        )}
      </Select>
    )
  }
}

const dummyData = [
  {
    Code: 'DIS0001',
    Name: 'DistributorStaff 1'
  },
  {
    Code: 'DIS0002',
    Name: 'DistributorStaff 2'
  },
  {
    Code: 'DIS0003',
    Name: 'DistributorStaff 3'
  }
]

const mapStateToProps = state => {
  return {
    routes: dummyData,
    authData: getAuthData(state)
  };
};

export default connect(mapStateToProps, { fetchRoutes })(SelectDistributorStaff);