import React, { Component } from 'react';
import { Col, Tag } from "antd";
import moment from "moment";
import { dateTimeFormatFilterView } from '../../../const/Formats';

class CallHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'ACTIVE':
        return 'green';
      case 'BLOCKED':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {
    const { callId, call } = this.props;

    return (
      <div className='promate-form-header'>
        <Col lg={8} md={8} sm={24} xs={24}>
          {callId && <tr>
            <td>Id #</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{callId}</td>
          </tr>}
          <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment().format(dateTimeFormatFilterView)}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td><Tag color={this.getStatusColor('UNVERIFIED')}>{callId === "new" ? 'UNVERIFIED' : call.Status}</Tag></td>
          </tr>
        </Col>
      </div>
    )
  }
}

export default CallHeader;