import * as fromApi from '../../api/generic';

export const FETCH_GL_ACCOUNTS = 'FETCH_GL_ACCOUNTS';

export const fetchGlAccounts = (filter = {}) => dispatch => {
  return fromApi.get('GLAccount', filter).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_GL_ACCOUNTS, accounts: data });

    return response;
  }).catch(error => {
    return { error }
  })
};