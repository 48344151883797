import React, { Component } from 'react';
import { PageHeader } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import { withRouter } from 'react-router-dom';
import PromatePageHeader from '../../common/PromatePageHeader';
import ItemInfoContainer from './ItemInfoContainer';
import InvoiceAndReturnInfoContainer from './InvoiceAndReturnInfoContainer';

class ItemView extends Component {
  renderContainer = () => {
    const { docType, lineId } = this.props.match.params;
    const { location = {}, } = this.props;
    const invoiceNo = location && location.state && location.state.invoiceNo;
    if (docType === 'ReturnOrder') {
      if (invoiceNo) {
        return lineId === 'new' && <InvoiceAndReturnInfoContainer />
      } else {
        // line !== new will rediere to onther place
        return lineId === 'new' ? <ItemInfoContainer /> : null;
      }

    }
  }

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Item Info'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          {this.renderContainer()}
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default withRouter(ItemView);