let demoPurchasingOrders = []

const names = ["Andrew Stephen", "Simon Cowel", "Mitchell Richedson", "Daren Iyan", "Brayan Adems", "Nicoles Stephen", "Theron M Goodman", "Darren Lara", "Elizabeth Mendella"];
const paymentTypes = ["Cash", "PDC", "Credit (Bill to Bill)"]

export const generateDemoOrders = () => {
  demoPurchasingOrders = []
  demoPurchasingOrders.push(defaultOrder)
  var defaultDiscountPercentage = getRandomNumber(0, 69);
  var grossAmount = getRandomNumber(700, 2500);
  var discountAmount = (grossAmount * defaultDiscountPercentage) / 100
  var totalAmount = grossAmount - discountAmount
  for (let i = 0; i < 25; i++) {
    demoPurchasingOrders.push({
      invoiceNo: "Invoice " + i,
      orderType: "Type " + i,
      sellToCustomerCode: "Distributor " + i,
      sellToCustomerName: "Distributor Name",
      billToCustomerCode: "Code " + getRandomNumber(20, 25),
      billToCustomerName: names[getRandomNumber(0, names.length)],
      locationCode: "Location Code " + randoNumber(),
      orderDate: randomDate(new Date(2012, 0, 1), new Date()),
      salespersonCode: "Sales Person " + randoNumber(),
      totalWithoutDiscount: grossAmount,
      totalDiscountAmount: discountAmount,
      totalAmount: totalAmount,
      amountIncludingVat: getRandomNumber(50, 60),
      balanceToSettle: getRandomNumber(10, 25),
      discountPercentage: defaultDiscountPercentage,
      mapLocation: "",
      links: [],
      salesLines: getSalesLines("Invoice " + i, defaultDiscountPercentage),
      status: i === 10 ? "Open" : randomStatus(),
      sessionId: randoNumber(),
      error: "",
      paymentType: paymentTypes[getRandomNumber(0, paymentTypes.length)],
      ageOfInvoice: getRandomNumber(1, 30),
      outstandingTotal: getRandomNumber(150, 1000)
    })
  }

  return demoPurchasingOrders;
}

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const randoNumber = () => {
  return Math.floor((Math.random() * 50000) + 1);
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from);


const randomStatus = () => {
  const statuses = ["Open", "Close", "Confirmed"];
  return statuses[Math.floor((Math.random() * 3) + 0)];
}

const getSalesLines = (invoiceNo, discountPercentage) => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      invoiceNo,
      lineNo: "Line " + i,
      unitPrice: invoiceNo === "new" ? "1500" : randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: invoiceNo === "new" ? "1500" : randoNumber(),
      discountPercentage: discountPercentage,
      discountAmount: invoiceNo === "new" ? "0" : randoNumber(),
      amount: invoiceNo === "new" ? "1500" : randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

export const generateSalesLines = (orderNo, percentage, lineCount) => {
  let salesLines = [];
  for (let i = 0; i < lineCount; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      orderNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: percentage,
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }
  return salesLines;
}

export const getDemoInvoices = () => {
  generateDemoOrders();
  return demoPurchasingOrders;
}

export const getDemoInvoice = id => {
  const demoOrder = demoPurchasingOrders.find(order => order.invoiceNo === id);
  return demoOrder;
}

export const getDemoInvoicesByCount = (count = 5) => {
  return demoPurchasingOrders.slice(0, count);
}

export const getSalesLineItem = (invoiceNo) => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      invoiceNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: getRandomNumber(0, 67),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines[Math.floor((Math.random() * 3) + 0)];
}

const defaultOrder = {

  invoiceNo: "new",
  orderType: "Type 3",

  sellToCustomerCode: "Distributor 20",
  sellToCustomerName: "Distributor Name",
  billToCustomerCode: "Code " + getRandomNumber(20, 25),
  billToCustomerName: names[getRandomNumber(0, names.length)],
  locationCode: "Location Code " + randoNumber(),
  orderDate: randomDate(new Date(2012, 0, 1), new Date()),
  salespersonCode: "Sales Person " + randoNumber(),
  totalWithoutDiscount: 1500,
  totalDiscountAmount: 0,
  totalAmount: 1500,
  amountIncludingVat: getRandomNumber(50, 60),
  mapLocation: "",
  links: [],
  salesLines: getSalesLines("new", 0).slice(0, 1),
  status: "Open",
  discountPercentage: 0,
  sessionId: randoNumber(),
  error: "",
  paymentType: paymentTypes[getRandomNumber(0, paymentTypes.length)],
  ageOfInvoice: getRandomNumber(1, 30),
  outstandingTotal: getRandomNumber(150, 1000)
}