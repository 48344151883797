import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { format } from "currency-formatter";
import { dateFormatView } from "../../const/Formats";
import { toCommaSeparatedString } from "../../utils/Formats";
import { PURCHASING_INVOICES } from "../../const/Permissions";
import { getLoggedInUser } from "../../redux/reducers";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class InvoiceCard extends Component {
  getStatusColor = docStatus => {
    switch (docStatus) {
      case 'Overdue':
        return 'red';
      case 'Outstanding':
        return 'orange';
      case 'Settled':
        return 'green'
      default:
        return '';
    }
  }

  getCustomerStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { purchasingInvoice, history, navigateToInvoiceView, loggedInUser: { permissions = [] } } = this.props;
    const { No, OrderNo, PostingDate, PDCAmount, NetRemAmount, RemAmount, SettledAmount, DistributorCode, DistributorName, DocStatus, DocType, InvAge, CustStatus, Amount, TerritoryName, PayTermCode, OrgAmount } = purchasingInvoice;

    const grossAmount = OrgAmount && OrgAmount === "" ? 0 : OrgAmount.replace(/,/g, '');
    const discount = "0.00"; //DisAmount && DisAmount.replace(/,/g, '');
    const netAmount = Amount && Amount === "" ? 0 : Amount.replace(/,/g, '');
    const settledAmount = SettledAmount && SettledAmount === "" ? 0 : SettledAmount.replace(/,/g, '');
    const remAmount = RemAmount && RemAmount === "" ? 0 : RemAmount.replace(/,/g, '');
    const pdcAmount = PDCAmount && PDCAmount === "" ? 0 : PDCAmount.replace(/,/g, '');
    const netRemAmount = NetRemAmount && NetRemAmount === "" ? 0 : NetRemAmount.replace(/,/g, '');

    return (
      <Row onClick={() => history.push("/purchasingInvoices/" + encodeURIComponent(No))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>{No}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span style={styles.orderDate}>{moment(PostingDate).format(dateFormatView)}</span>
          </Col>

          <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'center' }}>
            <span >{DocType}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>
            <span >{OrderNo}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>
            <span >{DistributorCode}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span >{DistributorName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span >{DistributorName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getCustomerStatusColor(CustStatus)}>{CustStatus}</Tag>
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'center' }}>
            <span >{TerritoryName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>
            <span >{PayTermCode}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'center' }}>
            <span>{InvAge}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>
            <span style={styles.status} color={this.getStatusColor(DocStatus)}>{DocStatus}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span >{format(parseFloat(netAmount), currencyFormat)}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{format(parseFloat(settledAmount), currencyFormat)}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={4} style={{ textAlign: 'right' }}>
            <span>{format(parseFloat(remAmount), currencyFormat)}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{format(parseFloat(pdcAmount), currencyFormat)}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{format(parseFloat(netRemAmount), currencyFormat)}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={{
              fontSize: 12,
              fontWeight: 600,
            }}>{No && No.replace(/---/g, '/')}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDate}>{moment(PostingDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={3} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}><span style={{ ...styles.orderDateTitle }}>OS</span></Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontSize: 13 }}>{format(parseFloat(remAmount), currencyFormat)}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={14} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{DistributorName}</span>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDateTitle}>PDC</span>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontWeight: 'bolder', fontSize: 13 }}>{format(parseFloat(pdcAmount), currencyFormat)}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(DocStatus)} >{DocStatus}</Tag>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDate}>{InvAge} Day{InvAge !== 1 && 's'}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDateTitle}>NET OS</span>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontWeight: 700, fontSize: 13, color: 'red' }}>{format(parseFloat(netRemAmount), currencyFormat)}</span>
          </Col>
        </Row>
      </Row>
    );
  }
}

const styles = {
  orderNo: {
    fontSize: 12,
    //fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },

  orderDateTitle: {
    fontSize: 12
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(InvoiceCard));