import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { generateDemoReceipts } from '../ReceiptDemoData';
import { getPaymentHeaders, getPaymentHeadersByCustomer, getCreditPaymentHeadersByCustomer } from "../../../redux/reducers";
import { withRouter } from "react-router-dom";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ReceiptListMobile extends Component {

  componentDidMount = () => generateDemoReceipts()

  getColumns = () => {
    const columns = [
      {
        title: 'Receipt Details',
        dataIndex: 'No',
        key: 'receiptNo',
        render: (value, data) => this.renderLeftColumn(data),
      },
      {
        title: '',
        dataIndex: 'No',
        key: 'receiptNo',
        align: 'right',
        render: (value, data) => this.renderRightColumn(data),
      }
    ]

    return columns;
  }

  //nClick={() => history.push('/customers/' + match.params.customerId + '/receipts/' + receipt.No )}
  renderLeftColumn = receipt => {
    const { history, match } = this.props;
    return <div>
      <div style={{ cursor: 'default' }} o>{receipt.No}</div>
      <div>{receipt.DocDate}</div>
      <Tag>{receipt.OrderStatus}</Tag>
    </div>
  }

  renderRightColumn = receipt => {
    return <div>
      <div>{receipt.CollectType}</div>
      <div style={{ fontWeight: 'bold' }}>{format(receipt.PayAmount, currencyFormat)}</div>
      <div style={{ fontWeight: 'bold' }}>{this.renderBalance(receipt)}</div>
    </div>
  }


  renderBalance = receipt => {
    const balance = (parseFloat(receipt.PayAmount.replace(/,/g, "")) - parseFloat(receipt.AppliedAmount.replace(/,/g, "")));

    return <p>{format(balance, currencyFormat)}</p>;
  }

  getRowSelection = () => {
    const { selectedReceipt } = this.props;

    return {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = selectedRows.map(row => row.No);
        this.props.handleOnClickSelectReceipts(selectedRowKeys, selectedRows, record, selected)
      },
      type: 'radio',
      selectedRowKeys: selectedReceipt ? [selectedReceipt.No] : [],
      selectedRows: selectedReceipt ? [selectedReceipt] : []
    };
  }

  render() {
    let { receipts = [], pagination } = this.props;
    pagination = receipts.length > 5 ? pagination : false
    return (
      <Table
        rowKey={'No'}
        rowSelection={this.getRowSelection()}
        columns={this.getColumns()}
        showHeader={false}
        dataSource={receipts}
        pagination={pagination || false}
        size={'small'}
        title={() => <h3>Receipts</h3>} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps

  const receipts = customerId ? getCreditPaymentHeadersByCustomer(state, customerId) : [];
  return { receipts };
}

export default withRouter(connect(mapStateToProps, {})(ReceiptListMobile));