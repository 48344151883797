import Api from './consts';
import fetch from './commonFetch';

export const getAll = filter => {
  const { url, method } = Api.collections.getAll;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'collections');
}

export const get = id => {
  const { url, method } = Api.collections.get;

  return fetch(url(id), { method }, 'collection');
}

export const add = collection => {
  const { url, method } = Api.collections.add;
  return fetch(url(), { method, body: JSON.stringify(collection) }, 'collection');
}

export const update = collection => {
  const { url, method } = Api.collections.update;

  return fetch(url(collection.collectionNo), { method, body: JSON.stringify(collection) }, 'collection');
}

export const deleteCollection = collection => {
  const { url, method } = Api.collections.delete;

  return fetch(url(collection.collectionNo), { method });
}

export const addAttachments = collection => {
  const { url, method } = Api.collections.addAttachments;

  return fetch(url(collection.collectionNo), { method, body: JSON.stringify(collection) }, 'collection');
}

export const confirm = collection => {
  const { url, method } = Api.collections.confirm;

  return fetch(url(collection.collectionNo), { method, body: JSON.stringify(collection) }, 'collection');
}