import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

class LocationCard extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'UNBLOCK':
        return 'green';
      case 'BLOCK':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {
    const { location, navigateToLocationView } = this.props;
    const { locationNo, name, type, status } = location;

    return (
      <Row onClick={navigateToLocationView}>
        <Row style={styles.alignRow}>
          <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'left' }}>
            <span style={styles.locationNo}>#{locationNo}</span>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
            <span style={{ ...styles.customerCode, margin: 5 }}>{name}</span>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
        </Row>
      </Row>
    )
  }
}

export default LocationCard;

const styles = {
  locationNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  collectionDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  paymentType: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}