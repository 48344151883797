import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Spin } from "antd";
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import { fetchCustomers, fetchCustomerCategories, fetchCustomerSubcategories } from "../../../redux/actions/customers";
import { getCustomers, getFlatPositions, getMapCustomers, getFilteredRoutes, isFetchingCustomerCategories, isFetchingMapCustomers, isFetchingRoutes, getCustomersBySearchKey } from "../../../redux/reducers";
import { connect } from "react-redux";
import { getAuthData, getTopPosition, isFetchingCustomers, getNetworkModeDetector } from '../../../redux/reducers';
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import { updateFilterUserHierarchy } from '../../common/SelectSalesUserHierarchy';
import LocationMap from '../../common/LocationMap';
import CustomerTable from "./CustomerTable";
import { PAGE_SIZE } from '../../../redux/actions/fetchOData';
import { decodeUrlSearchParams, getSearchKey, handleUrlSearchParams, redirectToUrlState, shouldFilterUpdate } from '../../../utils/Search';

const BASE_URL = '/customers';

class CustomerTableContainer extends Component {
  state = {
    filter: {
      UserCode: undefined,
      RouteCode: undefined,
      OutletCategoryCode: undefined,
      OutletSubCategoryCode: undefined,
      text: undefined,
      text2: undefined,
      StatusFilter: undefined
    },
    currentPage: 1,
    showMap: false,
    currentSearchKey: ''
  }

  componentDidMount() {
    this.setState({ filter: { ...this.state.filter, UserCode: this.props.authData.userId } });
    this.props.fetchCustomerCategories();
    this.props.fetchCustomerSubcategories();
    this.handleUrlSearchParams(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    handleUrlSearchParams(BASE_URL, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadCustomers(nextProps);
      });
    });
  }

  loadCustomers = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);

    this.setState({ filter });
    this.fetchFilteredCustomers(filter, currentPage, currentSearchKey);
  }

  compareStatus = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const statusA = a && a.status && a.status.toUpperCase();
    const statusB = b && b.status && b.status.toUpperCase();

    let comparison = 0;
    if (statusA === "UNVERIFIED") {
      comparison = 1;
    } else {
      comparison = -1;
    }
    return comparison;
  }

  handleInputTextChange = (filter, value, forceReload) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, newFilter, flatPositions, topPosition);
        break;
      case 'UserCode':
        newFilter.RouteCode = undefined;
        break;
      case 'OutletCategoryCode':
        newFilter.OutletSubCategoryCode = undefined;
        break;
      case 'StatusFilter':
        newFilter.StatusFilter = value;
        break;
      default:
        break;
    }

    this.setState({ filter: newFilter }, () => {
      if (forceReload) {
        this.handleSearchClick();
      }
    });
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(BASE_URL, this.props, this.state.filter, page);

  fetchCustomerPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchFilteredCustomers(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchFilteredCustomers(filter, currentPage, currentSearchKey);
  }

  fetchFilteredCustomers = (filter, page, searchKey) => {
    this.props.fetchCustomers(this.getRequestFilter(filter), page, searchKey);
  }

  getRequestFilter = filter => {
    const requestFilter = { ...filter };
    requestFilter.Name = filter.text ? '*' + filter.text + '*' : undefined;
    requestFilter.Add = filter.text2 ? '*' + filter.text2 + '*' : undefined;

    return requestFilter;
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }

  renderFilter = () => {
    const sizeHierarchy = { lg: 24, md: 24, sm: 24, xs: 24 };
    const sizeFields4 = { lg: 4, md: 4, sm: 24, xs: 24 };
    const sizeFields2 = { lg: 2, md: 2, sm: 24, xs: 24 };
    const sizeFields3 = { lg: 3, md: 3, sm: 24, xs: 24 };
    const propertiesTextSearch = { lg: 4, md: 3, sm: 24, xs: 24, placeholder: 'Search Name' };
    const propertiesTextSearchAddress = { lg: 4, md: 5, sm: 24, xs: 24, placeholder: 'Search Address' };
    const sizeButton = { lg: 1, md: 1, sm: 24, xs: 24 };

    return (
      <AdvanceSearchFilters
        SALES_HIERARCHY={sizeHierarchy}
        SELECT_ROUTE={sizeFields4}
        SELECT_CATEGORY={sizeFields3}
        SELECT_SUBCATEGORY={sizeFields3}
        SELECT_CUSTOMER_STATUS={sizeFields2}
        SELECT_CUSTOMER_PAYMENT_TYPE={sizeFields3}
        SEARCH_TEXT={propertiesTextSearch}
        SEARCH_TEXT_2={propertiesTextSearchAddress}
        SEARCH_BUTTON={sizeButton}
        handleInputChange={this.handleInputTextChange}
        handleSearchClick={this.handleSearchClick}
        filter={this.state.filter}
        setDefaultRouteOption={this.setDefaultRouteOption}
      />
    )
  }

  onMapViewSelect = selected => this.setState({ showMap: selected });

  renderMobileViewSwitcher = () =>
    <div style={styles.alignRight}>
      <Button.Group>
        <Button icon='menu' size='small' onClick={() => this.onMapViewSelect(false)}>List</Button>
        <Button icon='environment' size='small' onClick={() => this.onMapViewSelect(true)}>Map</Button>
      </Button.Group>
    </div>

  renderDesktopViewSwitcher = () =>
    <div style={styles.desktopSwitcherButtonGroup}>
      <div style={styles.desktopSwitcherButtons}>
        <Button block icon='menu' onClick={() => this.onMapViewSelect(false)} />
      </div>

      <div style={styles.desktopSwitcherButtons}>
        <Button block icon='environment' onClick={() => this.onMapViewSelect(true)} />
      </div>
    </div>

  renderMobileHeader = () => [
    this.renderFilter(),
    this.renderMobileViewSwitcher()
  ]

  renderDesktopHeader = () =>
    <Row>
      <Col xs={23}>{this.renderFilter()}</Col>
      <Col xs={1}>{this.renderDesktopViewSwitcher()}</Col>
    </Row>

  renderHeader = () =>
    <div style={styles.body} className='promate-table-header'>
      {this.context.screenType === 'DESKTOP' ? this.renderDesktopHeader() : this.renderMobileHeader()}
    </div>

  renderTable = () => {
    const { customers, fetchingCustomers, fetchingRoutes, routes } = this.props;
    const { filter, currentPage } = this.state;
    const customersReady = fetchingCustomers && customers.length === 0;
    const routesReady = fetchingRoutes && routes.length === 0;
    return (
      <Spin spinning={customersReady || routesReady} tip={'Loading customers'}>
        <CustomerTable
          currentPage={currentPage}
          filter={filter}
          handleInputTextChange={this.handleInputTextChange}
          handleSearchClick={this.handleSearchClick}
          customers={customers}
          authData={this.props.authData}
          setDefaultRouteOption={this.setDefaultRouteOption}
          onPaginationChange={this.handlePaginationChange}
        />
      </Spin>
    );
  }

  renderMap = () => {
    const { fetchingMapCustomers, mapCustomers, routes } = this.props;
    const { RouteCode } = this.state.filter;
    const routesToShow = RouteCode ? [routes.find(route => route.RouteCode === RouteCode)] : routes;

    if (fetchingMapCustomers) return (
      <Spin spinning={fetchingMapCustomers} tip={'Loading customers'}>
        <div style={{ height: 400, width: '100%' }} />
      </Spin>
    );

    const height = this.context.screenType === 'MOBILE' ? window.innerHeight * 0.8 : undefined;
    return (
      <div style={{ ...styles.mapContainer, height: height }}>
        <LocationMap height={height} customers={mapCustomers} routes={routesToShow} />
      </div>
    );
  }

  render() {
    const { showMap } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {showMap ? this.renderMap() : this.renderTable()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const authData = getAuthData(state);

  return {
    customers: getCustomersBySearchKey(state, getSearchKey()),
    fetchingCustomers: isFetchingCustomers(state),
    fetchingCustomerCategories: isFetchingCustomerCategories(state),
    authData,
    fetchingRoutes: isFetchingRoutes(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    fetchingMapCustomers: isFetchingMapCustomers(state),
    mapCustomers: getMapCustomers(state),
    routes: getFilteredRoutes(state),
    isSystemOffline: getNetworkModeDetector(state)
  };
};

const styles = {
  body: {
    paddingLeft: 10,
    paddingRight: 10
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  desktopSwitcherButtonGroup: {
    marginTop: 2
  },
  desktopSwitcherButtons: {
    padding: 3
  },
  mapContainer: {
    marginTop: 10
  }
}

CustomerTableContainer.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, { fetchCustomers, fetchCustomerCategories, fetchCustomerSubcategories })(CustomerTableContainer));