import React, { Component } from "react";
import { Col, Row } from "antd";

class InvoiceCardHeader extends Component {

  render() {

    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', paddingRight: 13, paddingLeft: 7 }}>
          <Col xs={5} sm={4} md={2} lg={2} style={{ textAlign: 'left' }}>Invoice No</Col>

          <Col xs={5} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>Invoice Date</Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>Payment Type</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right', paddingRight: 45 }}>Age</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>Customer Code</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Customer Name</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Salesperson</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'right' }}>Invoice Total</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'right' }}>Outstanding Total</Col>

          <Col xs={7} sm={4} md={0} lg={0} style={{ textAlign: 'right' }}>Inv. Total</Col>
          <Col xs={7} sm={6} md={0} lg={0} style={{ textAlign: 'right' }}>Outs. Total</Col>
        </Row>
        <Row style={styles.lowerRow}>
          {/* <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={6} sm={0} md={9} lg={0} style={{ textAlign: 'left' }}>
            <span>{billToCustomerName}</span>
          </Col> */}
          {/* <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col> */}

        </Row>
        <Row style={styles.lowerRow}>
          {/* <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col> */}

        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}
export default InvoiceCardHeader;