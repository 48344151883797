import React, { Component } from 'react';
import { Card, Tag, Divider, Col, Icon, Row } from 'antd';

class Details extends Component {

  render() {
    const { warehouse = {} } = this.props;
    return (
      <div>
        <Card className={'warehouseInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={8} md={8} sm={24} xs={24}>
              {warehouse.warehouseNo && <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>ID</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{warehouse.warehouseNo}</td>
              </tr>}
              {warehouse.name && <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>Name</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{warehouse.name}</td>
              </tr>}
              <tr>
                <td style={{ width: 25 }}><Icon type="info-circle" /></td>
                <td><p>Status</p></td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td><Tag color={getStatusColor(warehouse.status)}>{warehouse.status || 'Active'}</Tag></td>
              </tr>
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
            {/* <Col lg={8} md={8} sm={24} xs={24}>
              {warehouse.contactNumber && <tr>
                <td style={{ width: 25 }}><Icon type="phone" /></td>
                <td>Contact No</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{warehouse.contactNumber}</td>
              </tr>}
              {warehouse.email && <tr>
                <td style={{ width: 25 }}><Icon type="mail" /></td>
                <td>Email</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{warehouse.email}</td>
              </tr>}
              
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col> */}
            <Col lg={8} md={8} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25 }}></td>
                <td>Distributor</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{warehouse.distributor}</td>
              </tr>
              {warehouse.address && <tr>
                <td style={{ width: 25 }}><Icon type="pushpin" /></td>
                <td>Address</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{warehouse.address}</td>
              </tr>}
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const getStatusColor = status => {
  switch (status) {
    case 'UNBLOCK':
      return 'green';
    case 'BLOCK':
      return 'red';
    default:
      return 'orange';
  }
}

export default Details;