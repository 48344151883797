import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';

export const FETCH_EXPENSES_CATEGORIES = 'FETCH_EXPENSES_CATEGORIES';
export const START_FETCH_EXPENSES_CATEGORIES = 'START_FETCH_EXPENSES_CATEGORIES';
export const FINISH_FETCH_EXPENSES_CATEGORIES = 'FINISH_FETCH_EXPENSES_CATEGORIES';
export const FETCH_EXPENSES_CATEGORY = 'FETCH_EXPENSES_CATEGORY';

let lastSearchKey;
export const fetchExpensesCategories = (filter, page, searchKey = '') => async dispatch => {


  if (getOfflineData('state').isSystemOffline) { } else {
    lastSearchKey = searchKey;
    dispatch({ type: START_FETCH_EXPENSES_CATEGORIES }); return fromApi.get('DistributorExpenseCategory', filter, page).then(response => {
      const { data, error, recordTotal } = response;
      if (error) return { error }

      if (data && data.length === 1) dispatch({ type: FETCH_EXPENSES_CATEGORY, expensesCategory: data[0] });

      dispatch({ type: FETCH_EXPENSES_CATEGORIES, expensesCategories: data, searchKey, total: recordTotal });

      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey)
        dispatch({ type: FINISH_FETCH_EXPENSES_CATEGORIES });
    });
  }
};