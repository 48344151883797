import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchSalesHeaders, addSalesHeader, updateSalesHeader, deleteSalesHeader } from "../../../redux/actions/salesInvoiceHeaders";
import { fetchSalesLines } from "../../../redux/actions/salesInvoiceLines";
import { fetchInventory } from "../../../redux/actions/inventory";
import { fetchCustomers } from "../../../redux/actions/customers";
import { fetchPaymentMethods, fetchPaymentMethodsForCustomer } from "../../../redux/actions/paymentMethods";
import { fetchPaymentTerms, fetchPaymentTermsForCustomer } from "../../../redux/actions/paymentTerms";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import OrderHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import { getAuthData, getSalesInvoiceHeadersByTypeAndNo, DOC_TYPE, getCustomer, getLocations, getSalesInvoiceLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser } from '../../../redux/reducers';
import CallStatusValidateButton from '../../common/CallStatusValidateButton';
import { generateGeneric } from '../../../api/reports';
import Api from '../../../api/consts';
import { SALES_INVOICES, SALES_ORDERS } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchWarehouseLocations } from '../../../redux/actions/warehouses'
import { dateFormatView } from '../../../const/Formats';

export const defaultOrder = {
  SessionID: uuid(),
  DocType: undefined,
  SellToCusNo: undefined,
  SellToCusName: undefined,
  BillToCusNo: undefined,
  BillToCusName: undefined,
  LocCode: undefined,
  OrderDate: undefined,
  Status: "Open",
  OrderStatus: undefined,
  PayMethodCode: undefined,
  PayTermCode: undefined,
  DistributorCode: undefined,
  MapLocation: undefined,
  OrderDate: moment(new Date()).format(dateFormatView),
  Amount: 0.0,
  AmountIncluVAT: 0.0,
  DisPersentage: 0.0,
  DisAmount: 0.0
}

const toastMessage = {

}

class Container extends Component {
  state = {
    loading: false,
    order: { ...defaultOrder },
    locCodeError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { invoiceNo, docType } = this.props.match.params
    const { customerId } = this.props
    const newOrder = invoiceNo === 'new';

    this.props.fetchPaymentMethods();
    this.props.fetchPaymentTerms();

    const filter = { UserCode: this.props.authData.userId };
    this.props.fetchWarehouseLocations(filter);

    if (!newOrder) {
      this.setState({ loading: true });
      this.props.fetchSalesLines({ DocNo: decodeURIComponent(invoiceNo), DocType: docType });
      this.props.fetchSalesHeaders({ No: decodeURIComponent(invoiceNo), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] }).then(({ data, error }) => {

        if (!error) {
          this.props.fetchPaymentMethodsForCustomer({ CustNo: data[0].SellToCusNo });
          this.props.fetchPaymentTermsForCustomer({ CustNo: data[0].SellToCusNo });
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
    } else {
      this.props.fetchPaymentMethodsForCustomer({ CustNo: customerId });
      this.props.fetchPaymentTermsForCustomer({ CustNo: customerId })
      this.setData(this.props);
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.order && !nextProps.order) {
      // The order is deleted.
      window.history.back();
    } else if (nextProps && nextProps.order) {
      this.setState({ order: { ...nextProps.order } });
    } else {
      this.setData(this.props);
    }
  }

  setData = data => {
    const { customer } = data;

    if (!customer) return;

    this.setState({
      order: {
        ...this.state.order,
        SellToCusNo: customer.No,
        SellToCusName: customer.Name + ' ' + customer.Name2
      }
    }, this.setDeviceMapLocation)
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  submitSalesHeader = () => {
    const { match, toastManager } = this.props;
    const { docType = '' } = match.params;

    if (!locationEnabled) {
      toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) return;

    const newSalesHeader = this.createNewSalesHeaderRequest();

    this.setState({ loading: true })
    this.sendCreateSalesHeaderRequest(newSalesHeader).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new ' + docType.toLowerCase() + '.', { autoDismiss: true, appearance: 'success' });
        this.props.history.replace("/customers/" + match.params.customerId + "/sales/" + docType + "/" + encodeURIComponent(response.data.No));
      } else {
        toastManager.add('Could not create the ' + docType.toLowerCase() + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  validateForm = () => {
    const { order } = this.state;
    const { LocCode } = order;

    let locCodeError = undefined;

    if (!this.isValidValue(LocCode)) locCodeError = 'Location is required.'

    this.setState({ locCodeError })

    return !locCodeError;
  }

  isValidValue = value => value && value.trim().length > 0;

  createNewSalesHeaderRequest = () => {
    const { order } = this.state;
    const { authData: { userId }, match } = this.props;
    const { docType } = match.params;

    return {
      ...order,
      SessionID: uuid(),
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      OrderDate: moment(new Date()).format(dateFormatView),
      MapLocation: this.state.MapLocation,
      UserCode: userId
    }
  }

  sendCreateSalesHeaderRequest = salesHeader => {
    return this.props.addSalesHeader(salesHeader);
  }

  sendUpdateSalesHeaderRequest = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    const { docType } = match.params;

    this.setState({ loading: true })
    this.props.updateSalesHeader({ ...this.state.order, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + docType.toLowerCase() + ' updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not update the ' + docType.toLowerCase() + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  shareOrder = () => {
    this.setState({ sharing: true });

    const { order, toastManager, match } = this.props;
    const { docType } = match.params;
    const reportRequest = {
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      No: order.No
    };

    generateGeneric(reportRequest, 'salesInvoice').then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventoryItemListView = () => {
    const { match, toastManager } = this.props;
    const { orderId, docType } = match.params;

    if (orderId === 'new') {
      if (!locationEnabled) {
        toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
        return;
      }

      if (!this.validateForm()) return;

      const newSalesHeader = this.createNewSalesHeaderRequest();

      this.setState({ loading: true })
      this.sendCreateSalesHeaderRequest(newSalesHeader).then(response => {
        if (!response.error) {
          toastManager.add('Successfully created a new ' + docType.toLowerCase() + '.', { autoDismiss: true, appearance: 'success' });
          const order = { orderNo: encodeURIComponent(response.data.No), discountPercentage: this.state.order.discountPercentage, ...response.data };
          this.props.history.replace({ pathname: "/customers/" + match.params.customerId + "/sales/" + docType + "/" + encodeURIComponent(response.data.No) });
          this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "salesOrders", order: order, id: response.data.No } });
        } else {
          this.setState({ loading: false })
          toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    } else {
      const order = this.state.order;
      this.setState({ loading: true });
      setTimeout(() => {
        this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "salesOrders", order: order, id: orderId } });
      }, 100);
    }
  }

  navigateToConfirmOrder = () => {
    const { orderId, docType } = this.props.match.params

    this.props.history.push({ pathname: '/sales/' + docType + '/' + orderId + '/confirm', state: { order: this.state.order } })
  }

  // generateInvoice = () => {
  //   const { orderId } = this.props.match.params
  //   this.props.history.push({ pathname: '/salesOrders/' + orderId + '/salesInvoices/new', state: { order: this.state.order } })
  // }



  onDeleteOrder = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    const { docType } = match.params;
    this.setState({ loading: true })
    this.props.deleteSalesHeader({ ...this.state.order, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + docType.toLowerCase() + ' deleted.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not delete the ' + docType.toLowerCase() + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onConfirmApproved = () => {
    const { updateSalesHeader, toastManager, order, authData: { userId } } = this.props;
    const updateOrderData = { ...order, OrderStatus: '1', UserCode: userId };

    updateSalesHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Invoice generated.', { autoDismiss: true, appearance: 'success' });
        window.history.back();
      } else {
        toastManager.add('Could not generate the invoice. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onInputChange = (field, value, errorKey) => {
    const { order } = this.state;

    this.setState({
      order: { ...order, [field]: value },
      [errorKey]: undefined
    }, () => this.handleResetPayTermCode(field, value))
  }

  handleResetPayTermCode = (field, value) => {
    const { order } = this.state;

    if (field === 'PayMethodCode' && value === 'CASH') {
      this.setState({ order: { ...order, PayTermCode: undefined } })
    }
  }

  renderActions = () => (
    <Row gutter={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Button
            loading={this.state.sharing}
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={this.shareOrder}
          >Print & Share</Button>
        </Col>
      </Row>
    </Row >
  )

  render() {
    const { customers, match, salesLines, customerId } = this.props;
    const { orderId } = match.params
    const newOrder = orderId === 'new';
    const { loading, order, locCodeError } = this.state;

    if (!order) return null;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <OrderHeader
            customerId={customerId}
            order={order}
            salesLines={salesLines}
            customers={customers}
            newOrder={newOrder}
            onSubmitOrder={this.submitOrder}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirmOrder}
            onDeleteOrder={this.onDeleteOrder}
            onInputChange={this.onInputChange}
            error={{ locCodeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToInventoryItemListView}
                order={order}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToInventoryItemListView}
                order={order}
              />
            </Col>
          </div>
          <OrderFooter order={order} onInputChange={this.onInputChange} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { invoiceNo, docType, customerId } = ownProps.match.params
  const newOrder = invoiceNo === 'new';
  const decodeId = decodeURIComponent(invoiceNo);
  const invoice = state.salesInvoiceHeaders.byId[decodeId];
  const selToCust = invoice ? invoice.SellToCusNo : undefined;
  const custId = newOrder ? customerId : selToCust;
  const customer = getCustomer(state, custId);

  return {
    order: invoice,
    authData: getAuthData(state),
    locations: getLocations(state),
    customer,
    salesLines: getSalesInvoiceLinesByDocTypeAndDocNo(state, docType, decodeId),
    loggedInUser: getLoggedInUser(state),
    customerId: custId
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchCustomers,
  fetchInventory,
  fetchSalesHeaders,
  addSalesHeader,
  updateSalesHeader,
  deleteSalesHeader,
  fetchPaymentMethodsForCustomer,
  fetchPaymentTermsForCustomer,
  fetchSalesLines,
  fetchPaymentMethods,
  fetchPaymentTerms,
  fetchWarehouseLocations
})(Container)));