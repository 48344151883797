import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, deleteOrder } from "../../../redux/actions/orders";
import { fetchInventory } from "../../../redux/actions/inventory";
import { getAuthData } from "../../../redux/reducers";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import CreditNoteHeader from './Header';
import CreditNoteDetailTable from './SalesLineTable';
import CreditNoteFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
// import CreditNoteExport from "../CreditNoteExport";
import uuid from 'uuid/v1';
import { currentLocation } from '../../../utils/Location';
import Api from '../../../api/consts';
import { getDemoCreditNote, getDemoCreditNotes, getSalesLineItem } from '../dummyCreditNotes'
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

export const defaultCreditNote = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  creditNoteDate: moment(),
  creditNoteNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "PENDING",
  sessionId: uuid(),
}

class Container extends Component {
  state = {
    loading: false,
    creditNote: { ...defaultCreditNote }
  }

  componentDidMount() {
    const { creditNoteId } = this.props.match.params
    const newCreditNote = creditNoteId === 'new';

    if (newCreditNote) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      this.setState({ creditNote: { ...defaultCreditNote, sellToCustomerCode: "Customer 1", sellToCustomerName: "Mathews" } })
    } else {
      // this.setState({ loading: true })
      // this.props.fetchCreditNote(creditNoteId).then(() => {
      //   this.setState({ loading: false })
      // })

      this.setState({ creditNote: getDemoCreditNote(creditNoteId) })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { creditNote, match } = nextProps;
    const { creditNoteId } = match.params

    const newCreditNote = creditNoteId === 'new';

    // if (newCreditNote) {
    //   this.setState({ creditNote: { ...defaultCreditNote } })
    // } else if (creditNote) {
    //   this.setState({ creditNote: { ...creditNote, status: creditNote.status } })
    // }
    this.setState({ creditNote: getDemoCreditNote(creditNoteId) })
  }

  calculateTotalAmount = () => {
    const { creditNote = {} } = this.props;
    const { salesLines = [] } = creditNote;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  submitCreditNote = () => {
    const { addCreditNote, toastManager } = this.props;

    this.setState({ loading: true })

    const { creditNote } = this.state;

    creditNote.mapLocation = currentLocation;

    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a new creditNote.', { autoDismiss: true, appearance: 'success' });
      this.props.history.replace('/creditNotes/' + "CN 6")
    }, 2000)
    // addCreditNote(creditNote).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new creditNote.', { autoDismiss: true, appearance: 'success' });
    //     defaultCreditNote.sessionId = uuid();
    //     this.props.history.replace('/creditNotes/' + response.creditNote.creditNoteNo)
    //   } else {
    //     toastManager.add('Could not create the creditNote. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
  }

  shareCreditNote = () => {
    const { creditNote } = this.state;
    const reportLink = creditNote && creditNote.links && creditNote.links.length > 0 && creditNote.links.find(link => link.includes('creditNote-report'))
    // reportLink && window.open(Api.server+"/files/"+reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');

    // const { creditNoteId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // printContainer.style.display = 'inline';
    // this.setState({ loading: true });
    // html2canvas(document.querySelector("#creditNotePrintDiv"), { width: 685 }).then((canvas) => {

    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = creditNoteId + '.png';
    //   a.click();

    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventory = () => {
    const { creditNoteId } = this.props.match.params
    const creditNote = creditNoteId === 'new' ? { creditNoteNo: 'new' } : this.state.creditNote
    this.props.history.push({ pathname: '/inventory', state: { resource: "creditNotes", creditNote, id: creditNoteId } })
  }

  navigateToConfirmCreditNote = () => {
    const { creditNoteId } = this.props.match.params

    this.props.history.push({ pathname: '/creditNotes/' + creditNoteId + '/confirm', state: { creditNote: this.state.creditNote } })
  }

  onDeleteCreditNote = () => {
    const { deleteCreditNote, toastManager, history, fetchInventory } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      toastManager.add('CreditNote successfully deleted.', { autoDismiss: true, appearance: 'success' });
      fetchInventory();
      history.goBack();
    }, 2000)
    // deleteCreditNote(this.state.creditNote).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete creditNote. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('CreditNote successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     fetchInventory();
    //     history.goBack();
    //   }
    // });
  }

  renderActions = () => {
    const { match } = this.props;
    const { salesLines, status } = this.state.creditNote;
    const { creditNoteId } = match.params
    //CN 0 ID temporaly add for show generate credit note flow from sales return
    const newCreditNote = creditNoteId === 'new' || creditNoteId === 'CN 0';

    if (newCreditNote) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.submitCreditNote}
        >
          <CallStatusValidateButton type="primary">Create</CallStatusValidateButton>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (creditNote.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!creditNote.links || creditNote.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteCreditNote}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       creditNote.salesLines && creditNote.salesLines.length > 0 &&
      //       (!creditNote.links || creditNote.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmCreditNote}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (creditNote.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       creditNote.links && creditNote.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareCreditNote()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteCreditNote}
            >
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 && status === 'Open' &&
          <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmCreditNote}>Confirm</CallStatusValidateButton>
            </Col>
            <Col>
              <CallStatusValidateButton
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => this.props.history.push("/creditNotes/" + creditNoteId + "/setoff")}
              >Setoff</CallStatusValidateButton>
            </Col>
          </Row>
        }
        {
          status === 'Confirmed' && <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareCreditNote}
              >Print</Button>
            </Col>
            <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareCreditNote}
              >Print & Share</Button>
            </Col>
          </Row>
        }
      </Row>
    }
  }

  render() {
    const { customers, match, authData } = this.props;
    const { creditNoteId } = match.params
    const newCreditNote = creditNoteId === 'new';
    const { loading, creditNote } = this.state;

    if (!creditNote) return null;

    return (
      <Spin spinning={loading} tip={"Loading credit note"}>
        <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          <CreditNoteHeader
            creditNote={creditNote}
            customers={customers}
            newCreditNote={newCreditNote}
            onSubmitCreditNote={this.submitCreditNote}
            onShareCreditNote={this.shareCreditNote}
            onClickConfirmCreditNote={this.navigateToConfirmCreditNote}
            onDeleteCreditNote={this.onDeleteCreditNote} />
          <div style={{ padding: '0 12px' }}>
            <CreditNoteDetailTable creditNote={creditNote} scrollHeight={this.getScrollAreaHeight()}
              OnClickAddItem={this.navigateToInventory} />
          </div>
          <CreditNoteFooter creditNote={creditNote} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <CreditNoteExport creditNote={creditNote} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { creditNoteId } = ownProps.match.params

  return { creditNote: getDemoCreditNote(creditNoteId), authData: getAuthData(state) };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, deleteOrder, fetchInventory, getAuthData })(Container)));