import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class CustomerView extends Component {

  render() {
    const { customerId } = this.props.match.params;

    return (
      <div>
        <PromatePageHeader
          title={!customerId ? 'New Customer' : 'Edit Customer'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Container customerId={customerId || 'new'} />
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

CustomerView.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

export default CustomerView;