import * as fromApi from '../../api/generic';
import { getOfflineData, saveOfflinePaymentTerms } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB, createObjectStoreIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_PAYMENT_TERMS = 'FETCH_PAYMENT_TERMS';
export const FETCH_CUSTOMER_PAYMENT_TERMS = 'FETCH_CUSTOMER_PAYMENT_TERMS';
export const REMOVE_CUSTOMER_PAYMENT_TERM = 'REMOVE_CUSTOMER_PAYMENT_TERM';

export const fetchPaymentTerms = (type, filter) => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {
    var { data, error } = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.PAYMENT_TERMS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    dispatch({ type: FETCH_PAYMENT_TERMS, paymentTerms: data });

  } else {

    return fromApi.get('PaymentTerms', filter).then(response => {

      var { data, error } = response;

      if (error) return { error };

      dispatch({ type: FETCH_PAYMENT_TERMS, paymentTerms: data });
      return response;
    }).catch(error => {
      return { error }
    });
  }
}

export const addPaymentTermForCustomer = paymentOption => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    paymentOption.Sync = false;
    saveOfflinePaymentTerms(paymentOption);

    /*
    var response = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_TERMS).then((res)=>{
      return res;
    }).catch((err) => { console.log(err);})
    console.log(response);
    if(typeof(response) != "undefined"){
      response.data.push(paymentOption)
      await insertDataIndexDB(TABLES.CUSTOMERS,OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_TERMS,response)
    }else{
      let response = {"data": [paymentOption]}
      await insertDataIndexDB(TABLES.CUSTOMERS,OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_TERMS,response)
    }
    */

    var data = paymentOption;
    dispatch({ type: FETCH_CUSTOMER_PAYMENT_TERMS, paymentOptions: [data], customerNo: paymentOption.CustNo });
  } else {
    return fromApi.add('CustomerPaymentTerms', paymentOption).then(response => {
      const { data, error } = response;
      if (error) return { error }
      dispatch({ type: FETCH_CUSTOMER_PAYMENT_TERMS, paymentOptions: [data], customerNo: paymentOption.CustNo });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const fetchPaymentTermsForCustomer = filter => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {

    let customerData = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res.data;
    }).catch((err) => { alert(err); })

    let paymentOption = customerData.filter(function (el) {
      return el.No == filter.CustNo
    }
    );

    if (paymentOption.length > 0) {
      var { data, error } = paymentOption[0].paymentTerms;

      dispatch({ type: FETCH_CUSTOMER_PAYMENT_TERMS, paymentOptions: data, customerNo: filter.CustNo });
    }

  } else {

    return fromApi.get('CustomerPaymentTerms', filter).then(response => {

      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_CUSTOMER_PAYMENT_TERMS, paymentOptions: data, customerNo: filter.CustNo });
      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const deletePaymentTermFromCustomer = paymentOption => dispatch => {
  return fromApi.deleteObject('CustomerPaymentTerms', paymentOption).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: REMOVE_CUSTOMER_PAYMENT_TERM, paymentOption });
    return response;
  }).catch(error => {
    return { error }
  })
};
