import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Col, Timeline, Comment, Card, Icon, Select, Button, Popconfirm } from "antd";
import FormItem from '../../common/FormItem';
import { updateRoute, fetchRoute } from "../../../redux/actions/routes";
import { fetchCustomers } from "../../../redux/actions/customers";
import { addOrder } from "../../../redux/actions/orders";
import { showNotification } from "../../../const/Notifications"
import Details from "./Details";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { defaultOrder } from '../../Orders/OrderScreen/Container';
import uuid from 'uuid/v1';
import moment from 'moment';
import CallHeader from './Header'
import CustomerRaw from './CustomerRaw'
import { generateData, getDemoItemWarehouses, getAllWarehouses, getDemoItemSalesPersons, getAllSalesPersons } from '../RouteDemoData'
import { getAuthData, getLoggedInUser, getRoute, getCustomersByRoute } from '../../../redux/reducers';
import { currentLocation } from '../../../utils/Location';
import { inCall } from "../../../redux/actions/auth";
import { GeoMap } from "../../common/GeoMap";
import AddCustomersContainer from './AddCustomesContainer';
import { ROUTES, CUSTOMERS } from "../../../const/Permissions";
import Input from '../../../components/common/EnglishInput';
import { formatGeoLocation } from "../../../utils/Formats";

const { Option } = Select;


const defaultRoute = {
  RouteCode: "new",
  RouteName: "",
  SartMapLocation: undefined,
  EndMapLocation: undefined,
}

class Container extends Component {
  state = {
    loading: false,
    route: {
      ...defaultRoute
    },
    warehouses: [],
    selectedWarehouse: undefined,
    salesPersons: [],
    selectedSalePerson: undefined,
    customers: [],
    remarks: "",
    expenseByArr: undefined,
    descriptionError: undefined,
    contactNoError: undefined,
    addressError: undefined,
    faxNoError: undefined,
    emailError: undefined,
    contactPersonError: undefined,
    showBlockReasonDialog: false,
    visible: false,
    blockedReason: ""
  }

  componentDidMount() {
    if (this.props.routeId !== "new") {
      const { routeId, route, customers } = this.props;

      this.setState({ loading: true })
      this.props.fetchRoute(routeId).then(() => {
        this.setState({ loading: false })
      })

      this.setState({
        warehouses: getDemoItemWarehouses(routeId),
        salesPersons: getDemoItemSalesPersons(routeId),
        customers
      })

      this.setState({ loading: true })
      this.props.fetchCustomers({ RouteCode: routeId }).then(() => {
        this.setState({ loading: false })
      })
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.route && !nextProps.route) {
      // The order is deleted.
      //window.history.back();
    } else if (nextProps && nextProps.route) {
      this.setRouteData(nextProps.route);
    }
  }

  // componentWillReceiveProps = (nextProps) => {
  //   const { route } = nextProps;
  //   if (this.props.routeId !== "new") {
  //     if (JSON.stringify(route) !== JSON.stringify(this.props.route)) {
  //       this.setRouteData(route)
  //     }
  //   }
  // }

  setRouteData = routeData => {
    const SartMapLocation = routeData && routeData.SartMapLocation;
    const EndMapLocation = routeData && routeData.EndMapLocation;

    this.setState({
      route: {
        ...routeData,
        SartMapLocation: this.getMapLocationObject(SartMapLocation),
        EndMapLocation: this.getMapLocationObject(EndMapLocation)
      },
    })
  }

  getMapLocationObject = MapLocation => {
    if (!MapLocation || (MapLocation && MapLocation.length === 0)) return;

    const location = MapLocation.split(',');
    const lat = location[0];
    const lng = location[1];

    return { lat: parseFloat(lat), lng: parseFloat(lng) }
  }

  handleCancel = () => {
    this.setState({ visible: false, blockedReason: "" });
  };

  handleOk = () => {
    //console.log(this.state.blockedReason)
    this.setState({ visible: false, blockedReason: "" });
  };


  onInputChange = (field, value, errorKey) => {
    this.setState({ expense: { ...this.state.expense, [field]: value }, [errorKey]: undefined });
  }

  onSelectWarehouse = warehouse => {
    const list = this.state.warehouses;
    var index = list.findIndex(x => x === warehouse)
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      list.push(warehouse);
      this.setState({ warehouses: list, selectedWarehouse: warehouse });
    }
  }

  onSelectSalesPerson = salesperson => {
    const list = this.state.salesPersons;
    var index = list.findIndex(x => x === salesperson)
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      list.push(salesperson);
      this.setState({ salesPersons: list, selectedSalePerson: salesperson });
    }
  }

  onSelectExpenseByChange = expenseBy => {
    this.setState({ expense: { ...this.state.expense, expenseBy: expenseBy } });
  }

  onSaveCall = () => {
    //if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { call, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });

    if (this.props.routeId !== 'new') {
      // updateCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the collection.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      // const { call } = this.state;
      // const { authData: { userId }, inCall } = this.props;


      toastManager.add('Route create Successfully.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
      this.setState({ loading: false });
      // addCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
      //    // defaultCollection.sessionId = uuid();
      //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
      //     this.props.history.replace('/customers/' + customer.id + '/collections/' + response.collection.collectionNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  addCustomersToRoute = () => {
    const { toastManager } = this.props;
    //if (!this.validateForm()) return;
    var list = this.props.customersList.slice(5, 10)
    this.setState({ loading: true, customers: list });
    this.setState({ loading: false });
    toastManager.add('Customers Added successfully.', { autoDismiss: true, appearance: 'success' });
    this.props.history.goBack();
  }

  onUpdateRoute = () => {
    const { toastManager, loggedInUser: { userId } } = this.props;
    const { route } = this.state;

    if (!this.validateForm()) return;

    const SartMapLocation = route.SartMapLocation ? formatGeoLocation(route.SartMapLocation.lat, route.SartMapLocation.lng) : undefined;
    const EndMapLocation = route.EndMapLocation ? formatGeoLocation(route.EndMapLocation.lat, route.EndMapLocation.lng) : undefined;

    const updateOptions = { ...route };
    updateOptions.SartMapLocation = SartMapLocation;
    updateOptions.EndMapLocation = EndMapLocation;
    updateOptions.UserCode = userId;

    this.setState({ loading: true })
    this.props.updateRoute(updateOptions).then(response => {
      this.setState({ loading: false })
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        toastManager.add('Route updated successfully.', { autoDismiss: true, appearance: 'success' });
      }
    })
  }

  onBloackCustomer = () => {
    this.setState({ visible: true })
  }

  onConfirmDelete = () => {
  }

  validateForm = () => {
    const { route } = this.state;
    const { RouteName } = route;

    let nameError = undefined;

    if (!this.isValidValue(RouteName)) nameError = 'Name is required.'

    this.setState({ nameError })

    return !nameError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = header && header[0] && header[0].clientHeight;
    return window.innerHeight - (headerHeight);
  }

  submitOrder = () => {
    const { addOrder, toastManager, customer } = this.props;
    const order = { ...defaultOrder, sellToCustomerCode: customer.id, sellToCustomerName: customer.name }

    this.setState({ loading: true })
    addOrder(order).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
        defaultOrder.sessionId = uuid();
        this.props.history.replace('/orders/' + response.order.orderNo)
      } else {
        toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  onClickRefresh = () => {
    this.setState({ loading: true });
    this.props.fetchCustomer(this.props.callId).then(res => {
      this.setState({ loading: false })
      if (res.customer) {
        showNotification('success', 'Refreshed', "Call Refreshed successfully.")
      } else {
        showNotification('error', 'Failed', "Failed to refresh call.")
      }
    })
  }

  getDeviceLocationObject = () => {
    if (currentLocation) {
      return {
        lat: currentLocation.latitude,
        lng: currentLocation.longitude
      };
    } else {
      return null;
    }
  }

  renderRouteName = () => {
    const { route = {} } = this.state;
    return (
      <Row gutter={10}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Col>
            <FormItem label={"Route Name"} lg={24} md={24} sm={24} xs={24}>
              <Input id="route-name" readOnly bordered={false} value={route.RouteName} rows={2} placeholder={"Type here"}
                onChange={value => this.setState({ route: { ...this.state.route, RouteName: value } })} >
              </Input>
            </FormItem>
          </Col>
        </Col>
      </Row>
    )
  }

  renderGoogleMap = () => {
    const { route } = this.state;
    const { SartMapLocation, EndMapLocation } = route;
    const { loggedInUser: { permissions = [] } } = this.props;

    return <Row gutter={10}>
      <Col lg={12} md={12} sm={24} xs={24}>
        <Col>
          <FormItem label={"Start Location"} lg={24} md={24} sm={24} xs={24}>
            <GeoMap
              isMarkerShown={true}
              location={SartMapLocation}
              height={415}
              editable={permissions.includes(ROUTES.MAP_UPDATE)}
              onMapClick={(e) => this.handleMapClick('SartMapLocation', e)}
              fallbackCenter={this.getDeviceLocationObject()} />
          </FormItem>
        </Col>
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
        <Col>
          <FormItem label={"End Location"} lg={24} md={24} sm={24} xs={24}>
            <GeoMap
              isMarkerShown={true}
              location={EndMapLocation}
              height={415}
              editable={permissions.includes(ROUTES.MAP_UPDATE)}
              onMapClick={(e) => this.handleMapClick('EndMapLocation', e)}
              fallbackCenter={this.getDeviceLocationObject()} />
          </FormItem>
        </Col>
      </Col>
    </Row>
  }

  handleMapClick = (field, event) => {
    const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };

    this.setState({ route: { ...this.state.route, [field]: location } });
  }

  renderWarehouses = () => {
    const { warehousesList } = this.props;
    const { warehouses, route, salesPersons } = this.state;
    if (warehousesList && warehousesList.length > 0) {
      return (
        <div>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Col style={{ marginBottom: 10 }}>

                <FormItem label={"Warehouses"} lg={24} md={24} sm={24} xs={24}>
                  <Select
                    placeholder={"Select Warehouse"}
                    value={this.state.selectedWarehouse}
                    onChange={this.onSelectWarehouse}
                    style={{ width: '100%' }}>
                    {warehousesList.map(warehouse => <Option key={warehouse} value={warehouse}>{warehouse}</Option>)}
                  </Select>
                </FormItem>

              </Col>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              {(warehouses && warehouses.length > 0) && warehouses.map((warehouse, index) => {
                return (
                  <div key={index}>
                    <tr >
                      <td style={{ width: 25 }}>{index + 1}.</td>
                      <td><b>{warehouse}</b></td>
                      <td>
                        <Icon type="close" style={{ fontSize: '16px', color: 'red' }} onClick={() => this.removeItemWarehouse(index)} />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: 25, height: 15 }}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div>
                )
              })}
            </Col>
          </Row>
        </div>
      )
    }
  }

  renderActions = () => {
    const { loggedInUser: { permissions = [] } } = this.props;
    const { route } = this.state;
    const newrouteId = route.RouteCode === "new";


    if (newrouteId) {
      return (<Row gutter={15} className='promate-form-actions'><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        {permissions.includes(ROUTES.CREATE) && <Button type="primary" onClick={this.onSaveCall}>Create</Button>}
      </Col></Row>)
    } else {
      return (<Row gutter={15} className='promate-form-actions'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {permissions.includes(ROUTES.UPDATE) && <Col>
            <Button type="primary" onClick={this.onUpdateRoute}>Update</Button>
          </Col>}
          {permissions.includes(ROUTES.DELETE) && <Col>
            {route.status == "UNVERIFIED" && <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onConfirmDelete}
            >
              <Button type="primary">Delete</Button>
            </Popconfirm>}
          </Col>}
        </div>
      </Row>)

    }
  }

  renderSalesPersons = () => {
    const { salesPersonsList } = this.props;
    const { salesPersons } = this.state;
    if (salesPersonsList && salesPersonsList.length > 0) {
      return (
        <div >
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Col style={{ marginBottom: 10 }}>
                <FormItem label={"Salespersons"} lg={24} md={24} sm={24} xs={24}>
                  <Select
                    placeholder={"Select Salesperson"}
                    value={this.state.selectedSalePerson}
                    onChange={this.onSelectSalesPerson}
                    style={{ width: '100%' }}>
                    {salesPersonsList.map(warehouse => <Option key={warehouse} value={warehouse}>{warehouse}</Option>)}
                  </Select>
                </FormItem>
              </Col>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              {/* <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Warehouses</td>
                <td></td>
              </tr> */}
              {(salesPersons && salesPersons.length > 0) && salesPersons.map((salePerson, index) => {
                return (
                  <div key={index}>
                    <tr >
                      <td style={{ width: 25 }}>{index + 1}.</td>
                      <td><b>{salePerson}</b></td>
                      <td>
                        <Icon type="close" style={{ fontSize: '16px', color: 'red' }} onClick={() => this.removeItemSalePerson(index)} />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: 25, height: 15 }}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div>
                )
              })}
            </Col>
          </Row>
        </div>
      )
    }
  }

  addCustomer = () => {
    const { match } = this.props;
    this.props.history.push(match.url + "/customers/new");
  }

  editCustomer = No => {
    const { match } = this.props;
    this.props.history.push("/customers/" + No);
  }

  renderCustomers = () => {
    const { loggedInUser: { permissions = [] }, customers } = this.props;

    return (
      <div style={{ margin: 10 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <Col span={12}><h3 style={{ fontWeight: 'bold' }}>Customers</h3></Col>
            <Col span={12}>{permissions.includes(CUSTOMERS.CREATE) && <Button
              onClick={this.addCustomer}
              size='small'
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF', float: 'right' }}>Add Customer</Button>}
            </Col>
          </Col>
        </Row>
        <Row>
          {customers && customers.length > 0 && customers.map((customer, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  permissions.includes(CUSTOMERS.VIEW) && this.editCustomer(customer.No);
                }}
                style={{ cursor: 'pointer' }}>
                <CustomerRaw customer={customer} />
              </div>)
          })}
        </Row>
      </div>
    )
  }


  removeItemWarehouse(index) {
    const list = this.state.warehouses;
    list.splice(index, 1);
    this.setState({ warehouses: list });
  }

  removeItemSalePerson(index) {
    const list = this.state.salesPersons;
    list.splice(index, 1);
    this.setState({ salesPersons: list });
  }

  renderComment = transaction =>
    <Timeline.Item>
      <Comment
        author={<h3>{transaction.transaction}</h3>}
        content={
          <div>
            <p>{transaction.customer}</p>
            <p>{transaction.address}</p>
          </div>}
        datetime={moment(transaction.time).format()}
      />
    </Timeline.Item>

  render() {
    const { loading, route } = this.state;
    const { match } = this.props;
    const path = match.path
    const isAddRoute = path.includes("new");

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        {!isAddRoute &&
          <div>
            <Row>
              <Details
                routeId={route.RouteCode}
                route={route}
                onSaveRoute={this.onSaveCall}
                onConfirmDelete={this.onConfirmDelete} />
              {isAddRoute && <CallHeader callId={this.props.routeId} call={route} />}
            </Row>

            <Row>
              {this.renderRouteName()}
            </Row>

            <Row>
              {this.renderGoogleMap()}
            </Row>

            {this.renderActions()}

            {/* <Row gutter={10}>
              <Col lg={12} md={12} sm={12} xs={24}>
                {this.renderWarehouses()}
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                {this.renderSalesPersons()}
              </Col>
            </Row> */}

            <Row>
              {this.renderCustomers()}
            </Row>
          </div>
        }

        {isAddRoute && <AddCustomersContainer customers={this.props.customersList} addCustomersToRoute={this.addCustomersToRoute} />}
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { routeId } = ownProps

  const customers = getCustomersByRoute(state, routeId);
  const route = getRoute(state, routeId);
  const warehousesList = getAllWarehouses();
  const salesPersonsList = getAllSalesPersons();
  const authData = getAuthData(state);
  const loggedInUser = getLoggedInUser(state);
  return { route, authData, warehousesList, salesPersonsList, customers, loggedInUser };
};


export default withRouter(withToastManager(connect(mapStateToProps, { updateRoute, addOrder, inCall, fetchCustomers, fetchRoute })(Container)));