import React, { Component } from 'react';
import { Button, Card, Tag, Divider, Col, Icon, Popconfirm, Row } from 'antd';
import moment from 'moment';
import { dateFormatView, dateTimeFormatView } from '../../../const/Formats'
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

class Details extends Component {



  renderActions = () => {
    const { callId, call, onSaveCall, onConfirmDelete } = this.props;
    const newCallId = callId ? callId : "new";
    const { Status } = call;

    if (newCallId === "new") {
      return (<Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}><Col>
        {<Button type="primary" onClick={onSaveCall}>Start Call</Button>}
        {/* <Button type="primary" onClick={this.onSaveCall}>Create</Button> */}
      </Col></Row>
      )
    } else {
      return (
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              {Status == "ENDED" && <Popconfirm
                title="Are you sure?"
                okText="Ok"
                cancelText="No"
                onConfirm={onConfirmDelete}
              >
                <CallStatusValidateButton type="danger" >Delete</CallStatusValidateButton>
              </Popconfirm>}
            </Col>
            <Col>
              {Status == "STARTED" && <Button type="danger" onClick={() => console.log("END")}>End Call</Button>}
            </Col>
          </div>
        </Row>)
    }
  }

  render() {
    const { call } = this.props;
    const { No, CustNo, StartDateTime, Status, EndDateTime, UserName } = call;

    const startTimeInLocalTime = StartDateTime && moment(StartDateTime).add('minutes', +330).format(dateTimeFormatView)
    const endTimeInLocalTime = EndDateTime && moment(EndDateTime).add('minutes', +330).format(dateTimeFormatView)

    return (
      <div style={{ margin: 10 }}>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td style={{ width: 25 }}><Icon type="idcard" /></td>
            <td style={{ width: 100 }}>ID</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          <tr>
            <td style={{ width: 25 }}><Icon type="info-circle" /></td>
            <td><p>Status</p></td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td><Tag color={getStatusColor(Status)}>{Status}</Tag></td>
          </tr>
        </Col>

        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>

        <Col lg={8} md={8} sm={24} xs={24}>
          <tr>
            <td style={{ width: 25 }}><Icon type="idcard" /></td>
            <td>Created By</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{UserName}</td>
          </tr>
          {CustNo && <tr>
            <td style={{ width: 25 }}><Icon type="idcard" /></td>
            <td style={{ width: 100 }}>Customer Code</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{CustNo}</td>
          </tr>}
        </Col>

        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>

        <Col lg={8} md={8} sm={24} xs={24}>
          <tr>
            <td style={{ width: 25 }}><Icon type="info-circle" /></td>
            <td style={{ width: 100 }}><p>Created At</p></td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{startTimeInLocalTime || 'Pending...'}</td>
          </tr>
          <tr>
            <td style={{ width: 25 }}><Icon type="info-circle" /></td>
            <td><p>Ended At</p></td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{endTimeInLocalTime || 'Pending...'}</td>
          </tr>
        </Col>
        {/* {(Remarks && Remarks !== '' && Status === 'Close') && <Col lg={8} md={8} sm={24} xs={24}>
          <tr>
            <td style={{ width: 25 }}><Icon type="info-circle" /></td>
            <td><p>Remarks: </p></td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{Remarks}</td>
          </tr>
        </Col>} */}
      </div>
    )
  }
}

const getStatusColor = status => {
  if (status === "Open") return 'green'
  else return 'red'
}

export default Details;