import { combineReducers } from 'redux';
import { FETCH_INVOICES, FETCH_INVOICES_BY_CUSTOMER, FETCH_INVOICE } from '../actions/invoices';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_INVOICES: {
			const newState = {};
			action.invoices.forEach(invoice => newState[invoice.invoiceNo] = invoice);
			return newState;
		}

		case FETCH_INVOICES_BY_CUSTOMER: {
			const newState = { ...state };
			action.invoices.forEach(invoice => state[invoice.invoiceNo] = invoice);
			return newState;
		}

		case FETCH_INVOICE: {
			const newState = { ...state };
			newState[action.invoice.invoiceNo] = action.invoice
			return newState;
		}

		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_INVOICES: {
			const newState = []
			const invoiceIds = action.invoices.map(invoice => invoice.invoiceNo)

			invoiceIds.forEach(id => {
				if (!newState.includes(id)) newState.push(id)
			})
			return newState;
		}

		case FETCH_INVOICES_BY_CUSTOMER: {
			const newState = [...state]
			const invoiceIds = action.invoices.map(invoice => invoice.invoiceNo)

			invoiceIds.forEach(id => {
				if (!newState.includes(id)) newState.push(id)
			})
			return newState;
		}

		case FETCH_INVOICE: {
			const newState = [...state]
			const invoiceNo = action.invoice.invoiceNo;

			if (!newState.includes(invoiceNo)) newState.push(invoiceNo)
			return newState;
		}

		default:
			return state;
	}
};

export const byCustomer = (state = {}, action) => {
	switch (action.type) {
		case FETCH_INVOICES_BY_CUSTOMER: {
			const newState = { ...state }
			const { customerId, invoices } = action;
			const existingIdsbyCustomer = newState[customerId] || []
			const invoiceIds = invoices.map(invoice => invoice.invoiceNo)

			invoiceIds.forEach(id => {
				if (!existingIdsbyCustomer.includes(id)) existingIdsbyCustomer.push(id)
			})

			newState[customerId] = existingIdsbyCustomer
			return newState;
		}

		default:
			return state;
	}
};

const invoices = combineReducers({
	byId,
	ids,
	byCustomer
});

export const getInvoices = state => {
	const invoices = state.ids.map(id => state.byId[id]).filter(invoice => !!invoice);
	return invoices;
}

export const getInvoicesByCustomer = (state, customerId) => {
	const invoiceIdsByCustomer = state.byCustomer[customerId]
	const invoices = invoiceIdsByCustomer && invoiceIdsByCustomer.length > 0 ? invoiceIdsByCustomer.map(id => state.byId[id]).filter(invoice => !!invoice) : []

	return invoices;
}

export const getInvoice = (state, invoiceNo) => state.byId[invoiceNo]

export default invoices;