import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrders, addOrder, deleteOrder } from "../../../redux/actions/orders";
import { fetchInventory } from "../../../redux/actions/inventory";
import { getOrders } from "../../../redux/reducers";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import SupplierReturnHeader from './Header';
import SupplierReturnDetailTable from './SalesLineTable';
import SupplierReturnFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
// import SupplierReturnExport from "../SupplierReturnExport";
import uuid from 'uuid/v1';
import { currentLocation } from '../../../utils/Location';
import Api from '../../../api/consts';
import { generateDemoSupplierReturns, getDemoSupplierReturn } from '../SupplierReturnDemoData'
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

export const defaultSupplierReturn = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  supplierReturnDate: new Date(),
  supplierReturnNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "Open",
  sessionId: uuid(),
}

class Container extends Component {
  state = {
    loading: false,
    supplierReturn: { ...defaultSupplierReturn }
  }

  componentDidMount() {
    generateDemoSupplierReturns()
    const { supplierReturnId } = this.props.match.params
    const newSupplierReturn = supplierReturnId === 'new';

    if (newSupplierReturn) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      this.setState({ supplierReturn: { ...this.state.supplierReturn, billToCustomerCode: "Customer 1", billToCustomerName: "Mathews" } })
    } else {
      // this.setState({ loading: true })
      // this.props.fetchSupplierReturn(supplierReturnId).then(() => {
      //   this.setState({ loading: false })
      // })
      this.setState({ supplierReturn: getDemoSupplierReturn(supplierReturnId) })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { supplierReturn, match } = nextProps;
    const { supplierReturnId } = match.params

    const newSupplierReturn = supplierReturnId === 'new';

    // if (newSupplierReturn) {
    //   this.setState({ supplierReturn: { ...defaultSupplierReturn } })
    // } else if (supplierReturn) {
    //   this.setState({ supplierReturn: { ...supplierReturn, status: supplierReturn.status } })
    // }
    this.setState({ supplierReturn: getDemoSupplierReturn(supplierReturnId) })
  }

  calculateTotalAmount = () => {
    const { supplierReturn = {} } = this.props;
    const { salesLines = [] } = supplierReturn;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  submitSupplierReturn = () => {
    const { addSupplierReturn, toastManager } = this.props;

    this.setState({ loading: true })

    const { supplierReturn } = this.state;

    supplierReturn.mapLocation = currentLocation;

    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a new supplier return.', { autoDismiss: true, appearance: 'success' });
      this.props.history.replace('/supplierReturns/' + "supplierReturn 6")
    }, 2000)
    // addSupplierReturn(supplierReturn).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new supplierReturn.', { autoDismiss: true, appearance: 'success' });
    //     defaultSupplierReturn.sessionId = uuid();
    //     this.props.history.replace('/supplierReturns/' + response.supplierReturn.supplierReturnNo)
    //   } else {
    //     toastManager.add('Could not create the supplierReturn. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
  }

  shareSupplierReturn = () => {
    const { supplierReturn } = this.state;
    const reportLink = supplierReturn && supplierReturn.links && supplierReturn.links.length > 0 && supplierReturn.links.find(link => link.includes('supplierReturn-report'))
    // reportLink && window.open(Api.server+"/files/"+reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');

    // const { supplierReturnId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // printContainer.style.display = 'inline';
    // this.setState({ loading: true });
    // html2canvas(document.querySelector("#supplierReturnPrintDiv"), { width: 685 }).then((canvas) => {

    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = supplierReturnId + '.png';
    //   a.click();

    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventory = () => {
    const { supplierReturnId } = this.props.match.params
    const { supplierReturn } = this.state
    this.props.history.push({ pathname: '/inventory', state: { resource: "supplierReturns", supplierReturn: { ...supplierReturn }, id: 'supplierReturn 2' } })
  }

  navigateToConfirmSupplierReturn = () => {
    const { supplierReturnId } = this.props.match.params

    this.props.history.push({ pathname: '/supplierReturns/' + supplierReturnId + '/confirm', state: { supplierReturn: this.state.supplierReturn } })
  }

  onDeleteSupplierReturn = () => {
    const { deleteSupplierReturn, toastManager, history, fetchInventory } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      toastManager.add('Supplier return successfully deleted.', { autoDismiss: true, appearance: 'success' });
      fetchInventory();
      history.goBack();
    }, 2000)
    // deleteSupplierReturn(this.state.supplierReturn).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete supplierReturn. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('SupplierReturn successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     fetchInventory();
    //     history.goBack();
    //   }
    // });
  }

  renderActions = () => {
    const { match } = this.props;
    const { salesLines, status } = this.state.supplierReturn;
    const { supplierReturnId } = match.params
    const newSupplierReturn = supplierReturnId === 'new';

    if (newSupplierReturn) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.submitSupplierReturn}
        >
          <CallStatusValidateButton type="primary">Create</CallStatusValidateButton>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (supplierReturn.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!supplierReturn.links || supplierReturn.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteSupplierReturn}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       supplierReturn.salesLines && supplierReturn.salesLines.length > 0 &&
      //       (!supplierReturn.links || supplierReturn.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmSupplierReturn}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (supplierReturn.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       supplierReturn.links && supplierReturn.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareSupplierReturn()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteSupplierReturn}
            >
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmSupplierReturn}>Confirm</CallStatusValidateButton>
          </Col>
        }
        {status === 'Confirmed' && <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col>
            <Button
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
              onClick={this.shareSupplierReturn}
            >Print & Share</Button>
          </Col>
          <Col>
            <Button
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
              onClick={this.shareSupplierReturn}
            >Print</Button>
          </Col>
        </Row>}
      </Row>
    }
  }

  render() {
    const { customers, match } = this.props;
    const { supplierReturnId } = match.params
    const newSupplierReturn = supplierReturnId === 'new';
    const { loading, supplierReturn } = this.state;

    if (!supplierReturn) return null;

    return (
      <Spin spinning={loading} tip={"Loading supplierReturn"}>
        <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          <SupplierReturnHeader
            supplierReturn={supplierReturn}
            customers={customers}
            newSupplierReturn={newSupplierReturn}
            onSubmitSupplierReturn={this.submitSupplierReturn}
            onShareSupplierReturn={this.shareSupplierReturn}
            onClickConfirmSupplierReturn={this.navigateToConfirmSupplierReturn}
            onDeleteSupplierReturn={this.onDeleteSupplierReturn} />
          <div style={{ padding: '0 12px' }}>
            <SupplierReturnDetailTable supplierReturn={supplierReturn} scrollHeight={this.getScrollAreaHeight()}
              OnClickAddItem={this.navigateToInventory} />
          </div>
          <SupplierReturnFooter supplierReturn={supplierReturn} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <SupplierReturnExport supplierReturn={supplierReturn} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // const { supplierReturnId } = ownProps.match.params

  // return { supplierReturn: getSupplierReturn(state, supplierReturnId) };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrders, addOrder, deleteOrder, fetchInventory })(Container)));