import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Col, Button, Modal, Form, Popconfirm } from "antd";
import { fetchCustomer } from "../../../redux/actions/customers";
import { addOrder } from "../../../redux/actions/orders";
import { showNotification } from "../../../const/Notifications"
import Details from "./Details";
import Actions from "./Actions";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { defaultOrder } from '../../Orders/OrderScreen/Container';
import uuid from 'uuid/v1';
import moment from 'moment';
import CustomerHeader from './Header'
import SaleRepresentativeForm from './Form'
import { getDemoItem } from '../CustomerDemoData'
import Input from '../../../components/common/EnglishInput';

const defaultCustomer = {
  id: "new",
  name: undefined,
  address: undefined,
  contactNumber: undefined,
  faxNumber: undefined,
  email: undefined,
  contactPerson: undefined,
  status: undefined,
  salespersonCode: undefined,
  outstanding: 0.0,
  createdAt: moment(),
  createdBy: undefined,
  updatedAt: moment(),
  updatedBy: undefined,
  blockedReasons: [],
}

class Container extends Component {
  state = {
    loading: false,
    customer: {
      ...defaultCustomer
    },
    nameError: undefined,
    contactNoError: undefined,
    addressError: undefined,
    faxNoError: undefined,
    emailError: undefined,
    contactPersonError: undefined,
    showBlockReasonDialog: false,
    visible: false,
    blockedReason: ""
  }

  componentDidMount() {
    if (this.props.salesPersonId === "new") {
      //console.log(this.props.salesPersonId);
    } else {
      this.setState({ loading: true })
      setTimeout(() => {
        this.setState({ customer: getDemoItem(this.props.salesPersonId), loading: false })
      }, 1000)
      // this.props.fetchCustomer(this.props.customerId).then(() => {
      //   this.setState({ loading: false })
      // })
      //console.log(this.props.match.path);
    }
  }

  handleCancel = () => {
    this.setState({ visible: false, blockedReason: "" });
  };

  handleOk = () => {
    //console.log(this.state.blockedReason)
    this.setState({ visible: false, blockedReason: "" });
  };


  onInputChange = (field, value, errorKey) => {
    this.setState({ customer: { ...this.state.customer, [field]: value }, [errorKey]: undefined });
  }

  onSaveCustomer = () => {
    if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { customer, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });

    if (this.props.salesPersonId !== 'new') {
      // updateCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the collection.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      toastManager.add('Successfully created a new sales Representative.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
      // addCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
      //    // defaultCollection.sessionId = uuid();
      //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
      //     this.props.history.replace('/customers/' + customer.id + '/collections/' + response.collection.collectionNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  onUpdateCustomer = () => {
    // if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { customer, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });
    this.setState({
      loading: false
    }, () => {
      toastManager.add('Successfully updated.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
    })

    // addCollection(collection).then(response => {
    //   this.setState({ loading: false });
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
    //    // defaultCollection.sessionId = uuid();
    //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
    //     this.props.history.replace('/customers/' + customer.id + '/collections/' + response.collection.collectionNo);
    //   } else {
    //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
    //   }
    // })

  }

  onBloackCustomer = () => {
    this.setState({ visible: true })
  }

  onConfirmDelete = () => {

  }



  validateForm = () => {
    const { customer } = this.state;
    const { name, address, email, faxNumber, contactPerson, contactNumber } = customer;

    let nameError = undefined;
    let contactNoError = undefined;
    let addressError = undefined;
    let faxNoError = undefined;
    let emailError = undefined;
    let contactPersonError = undefined;

    if (!this.isValidValue(name)) nameError = 'Name is required.'
    if (!this.isValidValue(address)) addressError = 'Address is required.'
    if (!this.isValidValue(contactNumber)) contactNoError = 'Contact Number is required.'

    this.setState({ nameError, contactNoError, addressError, faxNoError, emailError, contactPersonError })

    return !nameError && !addressError && !contactNoError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = header && header[0] && header[0].clientHeight;
    return window.innerHeight - (headerHeight);
  }

  submitOrder = () => {
    const { addOrder, toastManager, customer } = this.props;
    const order = { ...defaultOrder, sellToCustomerCode: customer.id, sellToCustomerName: customer.name }

    this.setState({ loading: true })
    addOrder(order).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
        defaultOrder.sessionId = uuid();
        this.props.history.replace('/orders/' + response.order.orderNo)
      } else {
        toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  onClickRefresh = () => {
    this.setState({ loading: true });
    this.props.fetchCustomer(this.props.customerId).then(res => {
      this.setState({ loading: false })
      if (res.customer) {
        showNotification('success', 'Refreshed', "Sales Representative Refreshed successfully.")
      } else {
        showNotification('error', 'Failed', "Failed to refresh Sales Representative.")
      }

    })
  }

  renderActions = () => {
    const { salesPersonId, customer } = this.props;
    const newcustomerId = salesPersonId ? salesPersonId : "new";

    const { match } = this.props;
    const path = match.path
    const isEditCustomer = path.includes("edit");

    if (newcustomerId === "new") {
      return (<Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', margin: '24px', float: 'right' }}><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        <Button type="primary" onClick={this.onSaveCustomer}>Create</Button>
      </Col></Row>)
    } else {
      if (isEditCustomer) {
        return (<Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', margin: '24px', float: 'right' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>

              <Button type="primary" onClick={this.onUpdateCustomer}>Update</Button>
            </Col>

            <Col>
              {customer && customer.status != "BLOCKED" && <Button type="primary" onClick={this.onBloackCustomer}>Block</Button>}
            </Col>
            <Col>
              {customer.status == "UNVERIFIED" && <Popconfirm
                title="Are you sure ?"
                okText="Ok"
                cancelText="No"
                onConfirm={this.onConfirmDelete}
              >
                <Button type="primary">Delete</Button>
              </Popconfirm>}
            </Col>

          </div>
        </Row>)
      }
    }
  }

  renderModal() {
    return (
      <Modal
        visible={this.state.visible}
        title="Add Block Reason"
        okText="Block"
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <Form layout="vertical">
          <Form.Item label="Reason">
            {(<Input type="textarea" onChange={value => this.setState({ blockedReason: value })} />)}
          </Form.Item>

        </Form>
      </Modal>
    );
  }

  render() {
    const { customer } = this.state;
    const {
      loading,
      nameError,
      addressError,
      emailError,
      contactNoError,
      faxNoError, contactPersonError } = this.state;

    const { match } = this.props;
    const path = match.path
    const isEditCustomer = path.includes("edit");

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ maxHeight: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          {customer && !isEditCustomer && this.props.salesPersonId != "new" && <Details salesRep={customer} />}
          {customer && !isEditCustomer && this.props.salesPersonId != "new" && <Actions salesRep={customer} submitOrder={this.submitOrder} onClickRefresh={this.onClickRefresh} />}
          {(this.props.salesPersonId === "new" || isEditCustomer) && <CustomerHeader customerId={this.props.salesPersonId} customer={customer} />}
          {(this.props.salesPersonId === "new" || isEditCustomer) &&
            <SaleRepresentativeForm
              customerId={this.props.customerId}
              onInputChange={this.onInputChange}
              onSaveCustomer={this.onSaveCustomer}
              showBlockReasonDialog={this.showBlockReasonDialog}
              nameError={nameError}
              contactNoError={contactNoError}
              contactPersonError={contactPersonError}
              faxNoError={faxNoError}
              addressError={addressError}
              emailError={emailError}
              salesRep={customer}
            />
          }

          {this.renderModal()}
          {this.renderActions()}

        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { salesPersonId } = ownProps
  // const customer = getCustomer(state, customerId);
  const customer = getDemoItem(salesPersonId);
  return { customer };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchCustomer, addOrder })(Container)));