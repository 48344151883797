import React, { Component } from 'react';
import { Col, Row } from "antd";
import InvoiceList from './InvoiceList';
import CreditNoteList from './CreditNoteList';
import ReceiptList from './ReceiptList';

class Body extends Component {

  render() {
    const {
      onClickSelectInvoices,
      onClickSelectReceipts,
      onClickSelectCreditNotes,
      selectedCreditNoteRowKeys,
      selectedCreditNoteRows,
      selectedInvoiceRowKeys,
      selectedInvoiceRows,
      selectedReceiptRowKeys,
      selectedReceiptRows,
      invoices,
      receipts,
      creditNotes,
    } = this.props;

    return (
      <Row gutter={15} style={{ margin: 10 }}>
        <Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <InvoiceList
              onClickSelectInvoices={onClickSelectInvoices}
              selectedRows={selectedInvoiceRows}
              selectedRowKeys={selectedInvoiceRowKeys}
              invoices={invoices} />
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <CreditNoteList
              onClickSelectCreditNotes={onClickSelectCreditNotes}
              selectedRows={selectedCreditNoteRows}
              selectedRowKeys={selectedCreditNoteRowKeys}
              creditNotes={creditNotes} />
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <ReceiptList
              onClickSelectReceipts={onClickSelectReceipts}
              selectedRows={selectedReceiptRows}
              selectedRowKeys={selectedReceiptRowKeys}
              receipts={receipts} />
          </Col>
        </Col>
      </Row>
    )
  }
}

export default Body;