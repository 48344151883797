import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, confirmOrder } from "../../../redux/actions/orders";
import { getOrder } from "../../../redux/reducers";
import { Spin, Modal } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';
import { currentLocation } from '../../../utils/Location';
import { getDemoGRN, getDemoInvoices, getSalesLineItem } from '../dummyGRNs'
const { confirm } = Modal;

const defaultGRN = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  orderDate: moment(),
  grnNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "PENDING",
  signedBy: undefined
}

class Container extends Component {
  state = {
    loading: false,
    orderConfirmationType: undefined,
    grn: { ...defaultGRN }
  }

  componentDidMount() {
    const { grnNo } = this.props.match.params
    const newGRN = grnNo === 'new';

    if (newGRN) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      this.setState({ grn: { ...defaultGRN, sellToCustomerCode: customer.id, sellToCustomerName: customer.name } })
    } else {
      this.setState({ loading: true })
      this.props.fetchOrder(grnNo).then(() => {
        this.setState({ loading: false })
      })
      this.setState({ grb: getDemoGRN(grnNo) })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { grn, match } = nextProps;
    const { grnNo } = match.params
    const newGrn = grnNo === 'new';

    if (newGrn) {
      this.setState({ grn: { ...defaultGRN } })
    } else {
      this.setState({ grn: { ...grn, status: grn.status } })
    }
  }

  calculateTotalAmount = () => {
    const { grn = {} } = this.props;
    const { salesLines = [] } = grn;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  confirmOrder = (signatureImageUrl, isEmptyUrl) => {
    const { confirmOrder, toastManager, history, match } = this.props;
    const { orderConfirmationType } = this.state;
    const { grnNo } = match.params

    if (!orderConfirmationType) {
      toastManager.add("You can't confirm the order without confirmation type.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const updateOrderData = { orderNo: grnNo, status: "CONFIRMED", links: [], signedBy: orderConfirmationType }

    this.setState({ loading: true })

    if (orderConfirmationType === "OTPO") {
      confirmOrder(updateOrderData).then(response => {
        this.setState({ loading: false })
        if (!response.error) {
          toastManager.add('Successfully update order.', { autoDismiss: true, appearance: 'success' });
          history.goBack()
        } else {
          toastManager.add('Failed to update order. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      })
    } else {
      if (isEmptyUrl) {
        this.setState({ loading: false })
        toastManager.add("You can't confirm the order without providing the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const imageFile = this.getImageFile(signatureImageUrl);

      upload(imageFile, "orders/" + grnNo, 'signature-' + new Date().getTime()).then(response => {
        if (response.error) {
          toastManager.add("Could not upload the customer signature.", { autoDismiss: true, appearance: 'error' });
          return;
        }

        updateOrderData.links = [response.path];

        confirmOrder(updateOrderData).then(response => {
          this.setState({ loading: false })
          if (!response.error) {
            toastManager.add('Successfully update GRN.', { autoDismiss: true, appearance: 'success' });
            history.goBack()
          } else {
            toastManager.add('Failed to update GRN. ' + response.error, { autoDismiss: true, appearance: 'error' });
          }
        })
      });
    }
  }

  showConfirmOrderConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    confirm({
      title: 'Are you sure ?',
      content: 'After confirm the GRN you cannot update this GRN.',
      onOk() {
        context.confirmOrder(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  onChangeOrderConfirmationType = type => {
    this.setState({ orderConfirmationType: type });
  }

  navigateToInventory = () => this.props.history.push({ pathname: '/inventory', state: { order: this.state.order } })

  render() {
    const { loading,/* order,*/ orderConfirmationType } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading GRN"}>
        {/* <OrderHeader order={order} />
        <SalesLineSummary order={order} />
        <OrderFooter totals={this.calculateTotalAmount()} /> */}
        <Pad orderConfirmationType={orderConfirmationType} onConfirmationTypeChange={this.onChangeOrderConfirmationType} onConfirmOrder={(signatureImageUrl, isEmptyUrl) => this.showConfirmOrderConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { grnNo } = ownProps.match.params

  //return { order: getOrder(state, orderId) };
  //console.log(getDemoGRN(grnNo))
  return { grn: getDemoGRN(grnNo) };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, confirmOrder })(Container)));