import * as fromApi from '../../api/generic';

export const FETCH_PURCHASING_INVOICE_HEADERS = 'FETCH_PURCHASING_INVOICE_HEADERS';
export const START_FETCH_PURCHASING_INVOICE_HEADERS = 'START_FETCH_PURCHASING_INVOICE_HEADERS';
export const FINISH_FETCH_PURCHASING_INVOICE_HEADERS = 'FINISH_FETCH_PURCHASING_INVOICE_HEADERS';
export const FETCH_PURCHASING_INVOICE_HEADER = 'FETCH_PURCHASING_INVOICE_HEADER';

let lastSearchKey;
export const fetchPurchasingInvoiceHeaders = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_PURCHASING_INVOICE_HEADERS });

  return fromApi.get('PurchaseInvoiceHeader', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    if (data && data.length === 1) dispatch({ type: FETCH_PURCHASING_INVOICE_HEADER, purchasingInvoiceHeader: data[0] });

    dispatch({ type: FETCH_PURCHASING_INVOICE_HEADERS, purchasingInvoiceHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: FINISH_FETCH_PURCHASING_INVOICE_HEADERS });
  });
};