import React, { Component } from 'react';
import ImageAttachment from './ImageAttachment';
import ImageCapture from './ImageCapture';
import FileUpload from './FileUpload';

export default class ImageAttachmentContainer extends Component {
  state = {
    imageFile: undefined,
    fileUrl: undefined,
    showCapture: false,
    previewUrl: undefined
  }

  onCaptureImage = (previewUrl, imageFile) => this.setState({ imageFile, previewUrl })

  onSelectImage = imageFile => {
    this.getBase64(imageFile, previewUrl => {
      this.setState({ imageFile, previewUrl })
    })
  }

  getBase64 = (img, callback) => {
    if (img) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  }

  onUploadFileUrl = fileUrl => {
    this.setState({ fileUrl, showCapture: false, previewUrl: undefined, imageFile: undefined })
    this.props.setImageLink(fileUrl)
  }

  onResetImage = () => {
    this.setState({
      imageFile: undefined,
      fileUrl: undefined,
      showCapture: false,
      previewUrl: undefined
    })
  }

  render() {
    const { imageFile, showCapture, previewUrl } = this.state;
    const { fileName, dirPath, fileUrl, customerSigned, onError, onAttachingStart } = this.props;

    return (
      <div>
        {!showCapture && !imageFile && <ImageAttachment fileUrl={fileUrl} onSelectImage={this.onSelectImage} onClickTakePicture={() => this.setState({ showCapture: true })} customerSigned={customerSigned} />}
        {showCapture && !imageFile && <ImageCapture onCaptureImage={this.onCaptureImage} onClickExit={this.onResetImage} />}
        {imageFile && <FileUpload
          previewUrl={previewUrl}
          imageFile={imageFile}
          dirPath={dirPath}
          fileName={fileName}
          onUploadStart={() => onAttachingStart && onAttachingStart()}
          onUploadFileUrl={this.onUploadFileUrl}
          onUploadError={onError}
          onResetImage={this.onResetImage} />}
      </div>
    );
  }
}
