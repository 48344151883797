import React, { Component } from 'react';
import { Button, Spin } from 'antd';
import { upload } from '../../api/files';
import BetterImg from '../common/BetterImage';

// We should decide this path according to the object we attach the file.
// Ex: If it is an order, 'orders/<order_number>
// Ex: If it is a collection, 'collections/<collection_number>
// const dirPath = 'orders/odr1000';

class FileUpload extends Component {
  state = {
    uploading: false
  }

  reset = () => this.props.onResetImage()

  uploadFile = () => {
    this.setState({ uploading: true });

    const { imageFile, dirPath, fileName, onUploadFileUrl, onUploadError, onUploadStart } = this.props;

    onUploadStart && onUploadStart();

    upload(imageFile, dirPath, fileName).then(response => {
      if (response.error) {
        onUploadError(response.error);
      } else if (!response.path) {
        onUploadError('An error occured while uploading the file. Please try again.');
      } else {
        onUploadFileUrl(response.path);
      }

      this.setState({ uploading: false });
    });
  }

  render() {
    const { uploading } = this.state;

    return (
      <Spin spinning={uploading} tip={"Uploading..."}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <BetterImg
              src={this.props.previewUrl}
              height={500}
              resizeMode="cover"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Button.Group>
              <Button disabled={uploading} type={"primary"} style={{ fontSize: 13 }} icon="upload" onClick={this.uploadFile}>Upload</Button>
              <Button disabled={uploading} style={{ fontSize: 13 }} icon="undo" onClick={this.reset}>Reset</Button>
            </Button.Group>
          </div>
        </div>
      </Spin>
    );
  }
}

export default FileUpload;