import React, { Component } from 'react';
import { Alert, Form, Icon, Button, Input as InputPassword } from 'antd';
import { withToastManager } from 'react-toast-notifications';
import { resetPassword as callResetPassword } from '../api/auth';
import { ProMateGreen } from '../const/Theme';
import packageJson from '../../package.json';
import Input from '../components/common/EnglishInput';

class ResetPassword extends Component {
  state = {
    submitting: false,
    showPasswordValidationError: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, toastManager } = this.props;

    form.validateFields((err, values) => {
      if (!err) {

        this.setState({ submitting: true });
        callResetPassword(values).then(response => {
          const newState = { submitting: false };
          if (!response.error) {
            newState.resetPassword = false;
            toastManager.add('Password reset successfully. Please login using your new password.', { autoDismiss: true, appearance: 'success' });
            this.props.history.replace('/');
          } else {
            toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
          }

          this.setState(newState);
        })
      }
    });
  };

  validatePassword = (rule, value, callback) => {
    const { form } = this.props;
    if (!this.isValidPassword(value)) {
      this.setState({ showPasswordValidationError: true });
      const errorMessage = '';
      callback(errorMessage);
      form.setFieldValue('confirm', '');
    } else {
      this.setState({ showPasswordValidationError: false });
      callback();
    }
  };

  isValidPassword = (inputtxt) => {
    var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;
    if (inputtxt.match(regex)) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting, showPasswordValidationError } = this.state;

    return (
      <div style={styles.loginPage}>
        <Form onSubmit={this.handleSubmit} style={styles.loginForm} autoComplete='off'>
          <Form.Item>
            <img alt='ProMate Logo' src='/logo.png' style={styles.logo} />
            <div style={styles.loginFormVersion}>v{packageJson.version}</div>
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('passcode', {
              rules: [{ required: true, message: 'Please input your passcode!' }],
            })(
              <Input
                prefix={<Icon type="lock" style={styles.textInput} />}
                type="passcode"
                placeholder="OTP code"
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input
                prefix={<Icon type="user" style={styles.textInput} />}
                placeholder="Username"
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your password!' },
                {
                  validator: this.validatePassword
                }
              ]
            })(
              <InputPassword.Password
                placeholder="Password"
                iconRender={visible => (visible ? <Icon.EyeTwoTone /> : <Icon.EyeInvisibleOutlined />)}
              />,
            )}
          </Form.Item>
          {showPasswordValidationError &&
            <Alert
              style={{ width: '100%', marginBottom: 10 }}
              description={
                <div style={{ textAlign: 'left' }}>
                  <span>Your password must contain:</span>
                  <ul>
                    <li><span class='item-text'>At least 6 characters</span></li>
                    <li>
                      <span>Including following:</span>
                      <div>
                        <ul>
                          <li><span>Lower case letters (a-z)</span></li>
                          <li><span>Upper case letters (A-Z)</span></li>
                          <li><span>Numbers (0-9)</span></li>
                          <li><span>Special characters (ex. !@#)</span></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              }
              type='success'
            />}

          <Form.Item>
            <Button type="primary" htmlType="submit" style={styles.loginFormButton} loading={submitting}>
              Reset password
            </Button>
            <Button type="primary" style={styles.loginFormButton} onClick={() => this.props.history.goBack()}>
              Resend OTP
            </Button>
            <Button type="link" style={styles.loginFormForgot} onClick={() => this.props.history.push('/login')}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withToastManager(Form.create({ name: 'resetPassword' })(ResetPassword));

const styles = {
  loginPage: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginForm: {
    width: '300px',
  },
  loginFormReset: {
    float: 'right',
  },
  loginFormButton: {
    width: '100%',
    backgroundColor: ProMateGreen,
  },
  logo: {
    width: 300,
    padding: 20
  },
  textInput: {
    color: 'rgba(0,0,0,.25)'
  },
  loginFormForgot: {
    float: 'right',
  },
  loginFormVersion: {
    width: '300px',
    textAlign: 'center',
    color: '#777',
    marginTop: -20,
  },
};