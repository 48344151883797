import React, { Component } from "react";
import { Col, Row } from "antd";

class RouteCardHeader extends Component {

  render() {

    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', padding: 18 }}>

          <Col xs={8} sm={8} md={4} lg={4} style={{ textAlign: 'left' }}>Code</Col>

          <Col xs={8} sm={8} md={18} lg={18} style={{ textAlign: 'left' }}>Name</Col>

          <Col xs={0} sm={0} md={0} lg={2} style={{ textAlign: 'center' }}>Status</Col>

          <Col xs={8} sm={8} md={2} lg={0} style={{ textAlign: 'right' }}>Status</Col>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Warehouses</Col>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Salespersons</Col>
        </Row>
      </Row >
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  location: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  remark: {
    fontSize: 12,
    marginTop: 2,
    marginBottom: 1,
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0,
    textAlign: 'right'
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 6
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },

}
export default RouteCardHeader;