import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_VEND_DETAILS = 'FETCH_VEND_DETAILS';

export const fetchvendDetails = filter => async dispatch => {

    return fromApi.get('Vendor', filter).then(response => {
      const { data, error } = response;
      let records = response.data;

      if (error) return { error }
      dispatch({ type: FETCH_VEND_DETAILS, vendors: records });
      return response;
    }).catch(error => {
      return { error }
    })
  
}


