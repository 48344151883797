const COULD_NOT_AUTHENTICATE_INCORRECT_USERNAME_PASSWORD = "Could not authenticate. Incorrect username or/and password.";
const MAX_VALIDITY_TIME_MILIS = 24 * 60 * 60 * 1000;

const users = [
    {
        id: 1,
        displayName: "Nimal",
        username: "user1",
        password: "123",
        roleCode: "ADMIN",
        status: "",
        deleted: false
    },
    {
        id: 2,
        displayName: "Kamal",
        username: "user2",
        password: "123",
        roleCode: "ADMIN_ACCOUNTANT",
        status: "",
        deleted: false
    },
    {
        id: 3,
        displayName: "Upali",
        username: "user3",
        password: "123",
        roleCode: "DISTRIBUTOR",
        status: "",
        deleted: false
    },
    {
        id: 4,
        displayName: "Jagath",
        username: "user4",
        password: "123",
        roleCode: "DISTRIBUTOR_ACCOUNTANT",
        status: "",
        deleted: false
    },
    {
        id: 5,
        displayName: "Chanaka",
        username: "user5",
        password: "123",
        roleCode: "SALES_REPRESENTATIVE",
        status: "",
        deleted: false
    },
    {
        id: 6,
        displayName: "Lal",
        username: "user6",
        password: "123",
        roleCode: "STOCK_KEEPER",
        status: "",
        deleted: false
    }
]

export const authencateUser = async (credentials) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    const user = users.find(u => u.username.toLowerCase() === credentials.username.toLowerCase())

    if (!user) return { error: COULD_NOT_AUTHENTICATE_INCORRECT_USERNAME_PASSWORD }

    const authenticity = {
        userId: user.id,
        displayName: user.displayName,
        username: user.username,
        roleCode: user.roleCode,
        authToken: "123654789",
        issuedAt: new Date(),
        expiration: new Date(new Date().getTime() + MAX_VALIDITY_TIME_MILIS),
        permissions: ['Customers.Create', 'Customers.Update', 'Routes.Update']
    }

    return { authData: authenticity }
}