import * as fromApi from '../../api/generic';

export const addPaymentLine = paymentLine => dispatch => {
  return fromApi.add('PaymentLine', paymentLine).then(response => {
    const { data, error } = response;

    if (error) {
      dispatch({ type: 'FAILED_TO_ADD_PAYMENT_LINE', paymentLines: [{ ...paymentLine, added: false }] });
      return { error }
    }

    dispatch({ type: 'FETCH_PAYMENT_LINE_FOR_DOC', paymentLines: [{ ...data, added: true }] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchForDoc = DocNo => dispatch => {
  return fromApi.get('PaymentLine', { DocNo }).then(response => {
    const { data, error } = response;
    if (!error) {
      const paymentLines = data.map(item => ({ ...item, added: true }))
      dispatch({ type: 'FETCH_PAYMENT_LINES_FOR_DOC', DocNo, paymentLines });
    }

    return response;
  }).catch(error => {
    return { error }
  })
};

export const updatePaymentLine = paymentLine => dispatch => {
  return fromApi.update('PaymentLine', paymentLine).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: 'FETCH_PAYMENT_LINE_FOR_DOC', paymentLines: [{ ...data, added: true }] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deletePaymentLine = paymentLine => dispatch => {
  return fromApi.deleteObject('PaymentLine', paymentLine).then(response => {
    const { error } = response;

    if (error) return { error };

    dispatch({ type: 'REMOVE_PAYMENT_LINE', paymentLines: [paymentLine] });
    return response;
  }).catch(error => {
    return { error }
  })
};