import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Modal, Form } from "antd";
import { fetchCustomer } from "../../../redux/actions/customers";
import { addOrder } from "../../../redux/actions/orders";
import { showNotification } from "../../../const/Notifications"
import Details from "./Details";
import Actions from "./Actions";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { getDemoWarehouse } from '../WarehouseDemoData'
import WarehouseHeader from './Header'
import WarehouseForm from './Form'
import Input from '../../../components/common/EnglishInput';

const defaultWarehouse = {
  warehouseNo: "new",
  name: undefined,
  type: "STATIC",
  status: "OPEN",
  locations: [],
  blockedReasons: [],
  warehouseLocations: [],
  address: ""
}

class Container extends Component {
  state = {
    loading: false,
    warehouse: {
      ...defaultWarehouse
    },
    warehouseNameError: undefined,
    editWarehouse: false,
    blockModelVisible: false,
  }

  componentDidMount() {
    const { warehouseId, newWarehouse } = this.props;
    if (!newWarehouse) {
      // this.setState({ loading: true })
      // this.props.fetchCustomer(warehouseId).then(() => {
      //   this.setState({ loading: false })
      // })
      this.setState({ loading: true })
      setTimeout(() => {
        this.setState({ warehouse: getDemoWarehouse(warehouseId), loading: false })
      }, 1000)
    }
  }

  onInputChange = (field, value, errorKey) => {
    this.setState({ warehouse: { ...this.state.warehouse, [field]: value }, [errorKey]: undefined });
  }

  onSelectWarehouseTypeChange = type => {
    this.setState({ warehouse: { ...this.state.warehouse, type } });
  }

  onSaveWarehouse = () => {
    if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { warehouse, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });

    if (this.props.warehouseId !== 'new') {
      // updateCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the collection.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      toastManager.add('Successfully created a new warehouse.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
      // addCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
      //    // defaultCollection.sessionId = uuid();
      //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
      //     this.props.history.replace('/warehouses/' + warehouse.id + '/collections/' + response.collection.collectionNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  onUpdateWarehouse = () => {
    // if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { warehouse, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });
    toastManager.add('Successfully update.', { autoDismiss: true, appearance: 'success' });
    this.setState({ loading: false, editWarehouse: false });
    this.props.history.goBack();
    // addCollection(collection).then(response => {
    //   this.setState({ loading: false });
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
    //    // defaultCollection.sessionId = uuid();
    //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
    //     this.props.history.replace('/warehouses/' + warehouse.id + '/collections/' + response.collection.collectionNo);
    //   } else {
    //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
    //   }
    // })

  }

  validateForm = () => {
    const { warehouse } = this.state;
    const { name, address, email, faxNumber, contactPerson, contactNumber } = warehouse;

    let nameError = undefined;
    let contactNoError = undefined;
    let addressError = undefined;
    let faxNoError = undefined;
    let emailError = undefined;
    let contactPersonError = undefined;

    if (!this.isValidValue(name)) nameError = 'Name is required.'
    if (!this.isValidValue(address)) addressError = 'Address is required.'
    if (!this.isValidValue(contactNumber)) contactNoError = 'Contact Number is required.'

    this.setState({ nameError, contactNoError, addressError, faxNoError, emailError, contactPersonError })

    return !nameError && !addressError && !contactNoError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  onCreateWarehouse = () => {
    const { addOrder, toastManager, warehouse } = this.props;
    // const order = { ...defaultOrder, sellToWarehouseCode: warehouse.id, sellToWarehouseName: warehouse.name }

    // this.setState({ loading: true })
    // addOrder(order).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
    //     defaultOrder.sessionId = uuid();
    //     this.props.history.replace('/orders/' + response.order.orderNo)
    //   } else {
    //     toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a warehouse.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack()
    }, 1000)
  }

  onDeleteWarehouse = () => {
    const { toastManager } = this.props;

    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully deleted a warehouse.', { autoDismiss: true, appearance: 'success' });
      this.props.history.push('/warehouses/')
    }, 1000)
  }

  onClickRefresh = () => {
    this.setState({ loading: true });
    this.props.fetchWarehouse(this.props.warehouseId).then(res => {
      this.setState({ loading: false })
      if (res.warehouse) {
        showNotification('success', 'Refreshed', "Warehouse Refreshed successfully.")
      } else {
        showNotification('error', 'Failed', "Failed to refresh warehouse.")
      }

    })
  }

  onRemoveReason = index => {
    const { blockedReasons } = this.state.warehouse;

    this.setState({ warehouse: { ...this.state.warehouse, blockedReasons: blockedReasons.splice(index, 1) } })
  }

  renderModal() {
    return (
      <Modal
        visible={this.state.blockModelVisible}
        title="Add Block Reason"
        okText="Block"
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <Form layout="vertical">
          <Form.Item label="Reason">
            {(<Input type="textarea" onChange={value => this.setState({ blockedReason: value })} />)}
          </Form.Item>

        </Form>
      </Modal>
    );
  }

  onBloackCustomer = () => this.setState({ blockModelVisible: true })

  handleOk = () => this.setState({ blockModelVisible: false })

  handleMapClick = (event) => {
    const location = { lat: event.latLng.lat(), lng: event.latLng.lng() }
    this.setState({ warehouse: { ...this.state.warehouse, locations: [location] } })
  }

  render() {
    const { editWarehouse, newWarehouse } = this.props;
    const {
      loading,
      warehouseNameError,
      warehouse } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        {this.renderModal()}
        <Row>
          {!newWarehouse && !editWarehouse && <Details warehouse={warehouse} />}
          {!newWarehouse && !editWarehouse && <Actions warehouse={warehouse} submitOrder={this.submitOrder} onClickRefresh={this.onClickRefresh} />}
          {(this.props.warehouseId === "new" || editWarehouse) && <WarehouseHeader warehouseId={this.props.warehouseId} warehouse={warehouse} />}
          {(this.props.warehouseId === "new" || editWarehouse) &&
            <WarehouseForm
              onInputChange={this.onInputChange}
              onSelectWarehouseTypeChange={this.onSelectWarehouseTypeChange}
              warehouseNameError={warehouseNameError}
              warehouse={warehouse}
              onCreateWarehouse={this.onCreateWarehouse}
              onUpdateWarehouse={this.onUpdateWarehouse}
              onDeleteWarehouse={this.onDeleteWarehouse}
              onRemoveReason={this.onRemoveReason}
              onBloackCustomer={this.onBloackCustomer}
              isMarkerShown={warehouse && warehouse.locations && warehouse.locations.length > 0}
              location={warehouse && warehouse.locations && warehouse.locations[0]}
              onMapClick={this.handleMapClick}
            />
          }
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { warehouseId } = ownProps
  // const warehouse = getCustomer(state, warehouseId);
  const warehouse = getDemoWarehouse(warehouseId);

  return { warehouse };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchCustomer, addOrder })(Container)));