import * as fromApi from '../../api/generic';
import { o } from '../../api/odata';
import { fetchOData, fetchAllBatches } from './fetchOData';
import { getOfflineData, saveOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_FILTERED_ROUTES = 'FETCH_FILTER_ROUTES';
export const FETCH_FILTERED_ROUTE = 'FETCH_FILTER_ROUTE';
export const START_FETCH_FILTERED_ROUTES = 'START_FETCH_FILTER_ROUTES';
export const FINISH_FETCH_FILTERED_ROUTES = 'FINISH_FETCH_FILTER_ROUTES';


let lastRequestId = undefined;
export const fetchFilteredRoutes = filter => async dispatch => {
  dispatch({ type: START_FETCH_FILTERED_ROUTES });

  if (getOfflineData('state').isSystemOffline) {

    const { data, error, recordTotal } = await readObjectFromIndexDB(TABLES.TRIP_SCHEDULES, OBJECT_KEYS.TRIP_SCHEDULER).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    dispatch({ type: FETCH_FILTERED_ROUTES, routes: data, UserCode: filter.UserCode });

    dispatch({ type: FINISH_FETCH_FILTERED_ROUTES });

  } else {
    return fromApi.get('Route', { UserHierarchy: filter.UserHierarchy, UserTag: filter.UserTag }).then(response => {

      const { data, error, recordTotal } = response;

      if (error) return { error }

      dispatch({ type: FETCH_FILTERED_ROUTES, routes: data, UserCode: filter.UserCode });

      return response;

    }).catch(error => {
      return { error }
    }).finally(() => {
      dispatch({ type: FINISH_FETCH_FILTERED_ROUTES });
    })
  }

};

const cleanFilter = filter => {
  if (filter.UserTag)
    filter.UserTag = filter.UserTag.filter(tag => tag.startsWith('CS'));
  filter.UserCode = undefined;
}
