import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchRoutes } from '../../redux/actions/routes'
import { getAuthData, getFlatPositions, } from "../../redux/reducers";

const { Option } = Select;

class SelectCSR extends Component {
  componentDidMount = () => {
    const filter = { UserCode: this.props.authData.userId };
    //this.props.fetchRoutes(filter);
  }

  setDefaultFilter = () => {
    const { salesReps, onChange, value } = this.props;
    if (salesReps.length === 1 && value !== salesReps[0].CSRCode) {
      setTimeout(() => {
        onChange('CSRCode', salesReps[0].CSRCode, true);
      }, 0);
    }
  }

  render() {
    this.setDefaultFilter();

    const {
      salesReps,
      onChange,
      showSearch = true,
      placeholder = 'Select CSR',
      allowClear = true,
      defaultValue,
      disabled,
      value,
      style = { width: '100%' }
    } = this.props

    return (
      <Select
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(value) => onChange('CSRCode', value)}
        allowClear={allowClear}
        value={value}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={style}
      >
        {salesReps && salesReps.map(csr =>
          <Option value={csr.CSRCode}>{csr.CSRName}</Option>
        )}
      </Select>
    )
  }
}

const dummyData = [
  {
    Code: 'CSR0001',
    Name: 'CSR 1'
  },
  {
    Code: 'CSR0002',
    Name: 'CSR 2'
  },
  {
    Code: 'CSR0003',
    Name: 'CSR 3'
  }
]

const mapStateToProps = state => {
  const flatPositions = getFlatPositions(state);
  const csrPositions = flatPositions && Object.values(flatPositions).filter(position1 => position1.positionType === 'CSR');
  let salesReps = []
  csrPositions && csrPositions.forEach((value, index) => {
    let obj = { CSRCode: value.user.code, CSRName: value.user.name }
    salesReps.push(obj)
  })

  return {
    flatPositions: getFlatPositions(state),
    authData: getAuthData(state),
    salesReps
  };
};

export default connect(mapStateToProps, { fetchRoutes })(SelectCSR);