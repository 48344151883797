import React, { Component } from 'react';
import { StyledButton } from './Buttons';
import FileServer from 'file-saver';
import XLSX from 'sheetjs-style';

export default class ExcelExport extends Component {
    fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    fileExtention = ".xlsx";

    exportToExcel = async () => {
        const { data, fileName } = this.props;
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
        const details = new Blob([excelBuffer], { type: this.fileType });
        FileServer.saveAs(details, fileName + this.fileExtention);
    }

    render() {
        return (
            <div>
                <StyledButton color="#0b5c22" label="Export to Excel" width={150} fontSize={10} onClick={() => this.exportToExcel()} />
            </div>
        )
    }
}
