import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchCollection, confirmCollection } from "../../../redux/actions/collection";
import { getCollection } from "../../../redux/reducers";
import { Spin, Modal } from "antd";
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';

const { confirm } = Modal;

class Container extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const { supplierReturnId } = this.props.match.params

    this.setState({ loading: true })
    this.props.fetchCollection(supplierReturnId).then(() => {
      this.setState({ loading: false })
    })
  }

  confirm = (signatureImageUrl, isEmptyUrl) => {
    const { confirmCollection, toastManager, history, match } = this.props;
    const { supplierReturnId } = match.params

    if (isEmptyUrl) {
      toastManager.add("You can't confirm the supplier return without providing customer signature.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const imageFile = this.getImageFile(signatureImageUrl);
    const updateCollectionData = { supplierReturnNo: supplierReturnId, status: "CONFIRMED", links: [] }

    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false });
      toastManager.add('Successfully update supplier return.', { autoDismiss: true, appearance: 'success' });
      history.goBack();
    }, 2000)
    // upload(imageFile, "supplierReturns/" + supplierReturnId, 'customer-signature-'+new Date().getTime()).then(response => {
    //   const fileUrl = response.path;
    //   updateCollectionData.links.push(fileUrl)

    //   confirmCollection(updateCollectionData).then(response => {
    //     this.setState({ loading: false })
    //     if (!response.error) {
    //       toastManager.add('Successfully update supplierReturn.', { autoDismiss: true, appearance: 'success' });
    //       history.goBack()
    //     } else {
    //       toastManager.add('Failed to update supplierReturn', { autoDismiss: true, appearance: 'error' });
    //     }
    //   })
    // });
  }

  showConfirmCollectionConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    confirm({
      title: 'Are you sure ?',
      content: 'After confirm the supplier return you cannot update.',
      onOk() {
        context.confirm(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  render() {
    const { loading } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Pad onConfirmCollection={(signatureImageUrl, isEmptyUrl) => this.showConfirmCollectionConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { supplierReturnId } = ownProps.match.params
  return { supplierReturn: getCollection(state, supplierReturnId) };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchCollection, confirmCollection })(Container)));