import React, { Component } from 'react';
import { Col, Row, Form, DatePicker, Input, Select, Button, } from "antd";
import SelectSalesUserHierarchy from './SelectSalesUserHierarchy';
import SelectRoute from './SelectRoute';
import SelectOutlet from './SelectOutlet';
import SelectCustomerCategory from './SelectCustomerCategory';
import SelectCustomerSubcategory from './SelectCustomerSubcategory';
import moment from 'moment';
import { dateTimeFormatView } from "../../const/Formats";
import { FaSearch, FaDownload, FaFileDownload } from "react-icons/fa";
import { fetchFilteredRoutes } from "../../redux/actions/filteredRoutes";
import { fetchTerritories } from "../../redux/actions/territories";
import { fetchExpensesCategories } from "../../redux/actions/ExpensesCategories";
import { fetchPaymentMethods } from "../../redux/actions/paymentMethods";
import { fetchPaymentTerms } from "../../redux/actions/paymentTerms";
import { fetchSalesReturnReasons } from "../../redux/actions/salesReturnReasons";
import { fetchWarehouseLocations } from '../../redux/actions/warehouses';
import { fetchSalesDiscountReason } from '../../redux/actions/salesDiscountReasons';
import { getSalesOrderType } from '../../api/commonFetch';
import { getAuthData, getTopPosition, getFlatPositions, isFetchingFilteredRoutes, getPaymentMethods, getPaymentTerms, getSalesReturnReasons, getSalesCreditMemoReasons, getPurchaseCreditMemoReasons, getPurchaseReturnReasons, getPaymentTypes, getLocations, getTerritories, isFetchingTerritories, getExpensesCategories, isFetchingexpensesCategories, getSalesDiscountReason } from "../../redux/reducers";
import { connect } from "react-redux";
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import SelectTerritory from './SelectTerritory';
import SelectExpensesCategory from './SelectExpensesCategory';
import Loader from "react-loader";

const { Option } = Select;

class FormItem extends Component {

  async componentDidMount() {
    const { fetchFilteredRoutes, fetchPaymentMethods, fetchPaymentTerms, authData, filter, fetchSalesReturnReasons, fetchWarehouseLocations, fetchSalesDiscountReason } = this.props;
    //authData.userId && fetchFilteredRoutes(authData.userId);
    this.loadFilteredRoutes(this.props);
    fetchPaymentMethods();
    fetchPaymentTerms();
    fetchSalesReturnReasons();
    fetchSalesDiscountReason();
    // const s = await fetchWarehouseLocations({ UserCode: authData.userId });
    // console.log("99999999999999999999999999");
    // console.log(s);
    this.loadLoctions(this.props);
    this.loadTerritories(this.props);
    this.loadExpensesCategories(this.props);
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.filter && nextProps.filter) {
      const oldUserTags = this.props.filter.UserTag;
      const newUserTags = nextProps.filter.UserTag;
      if (JSON.stringify(oldUserTags) !== JSON.stringify(newUserTags)) {
        this.loadFilteredRoutes(nextProps);
        this.loadTerritories(nextProps);
        this.loadExpensesCategories(nextProps);
        this.loadLoctions(nextProps)
      }
    }
  }

  loadFilteredRoutes = (props) => {
    const { fetchFilteredRoutes, topPosition, flatPositions, filter, authData } = props;
    // console.log(filter)
    if (!topPosition || flatPositions.length === 0) return;

    let updatedFilter = updateFilterUserHierarchy(filter.UserHierarchy, filter, flatPositions, topPosition);
    updatedFilter.UserCode = authData.userId;
    //console.log("updatedFilter", updatedFilter)
    authData.userId && updatedFilter.UserTag && fetchFilteredRoutes(updatedFilter);
  }

  loadTerritories = (props) => {
    const { fetchTerritories, topPosition, flatPositions, filter, authData } = props;
    //console.log(filter)
    if (!topPosition || flatPositions.length === 0) return;

    let updatedFilter = updateFilterUserHierarchy(filter.UserHierarchy, filter, flatPositions, topPosition);
    updatedFilter.UserCode = undefined;
    //console.log("updatedFilter", updatedFilter)
    authData.userId && updatedFilter.UserTag && fetchTerritories(updatedFilter);
  }

  loadLoctions = (props) => {
    const { fetchWarehouseLocations, topPosition, flatPositions, filter, authData } = props;
    //console.log(filter)
    if (!topPosition || flatPositions.length === 0) return;

    let updatedFilter = updateFilterUserHierarchy(filter.UserHierarchy, filter, flatPositions, topPosition);
    updatedFilter.UserCode = undefined;
    //console.log("updatedFilter", updatedFilter)
    authData.userId && updatedFilter.UserTag && fetchWarehouseLocations(updatedFilter);
  }

  loadExpensesCategories = (props) => {
    const { fetchExpensesCategories, topPosition, flatPositions, filter, authData } = props;
    if (!topPosition || flatPositions.length === 0) return;

    let updatedFilter = updateFilterUserHierarchy(filter.UserHierarchy, filter, flatPositions, topPosition);
    updatedFilter.UserCode = authData.userId;
    authData.userId && updatedFilter.UserTag && fetchExpensesCategories(updatedFilter);
  }

  renderField = (properties, content) =>
    properties && <Col lg={properties.lg} md={properties.md} sm={properties.sm} xs={properties.xs} style={{ marginBottom: 5 }}>
      {content}
    </Col>

  renderDatePicker = (key, placeholder) => {
    const { handleInputDateChange, filter } = this.props;

    return (
      <DatePicker
        format={dateTimeFormatView}
        value={filter[key]}
        placeholder={placeholder}
        onChange={(date, dateString) => handleInputDateChange(key, date)}
        style={{ width: '100%' }}
        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        allowClear={false} />
    );
  }

  renderSelect = (key, placeholder, options, defaultValue = "") => {
    const { handleInputChange, filter } = this.props;

    return (
      <Select
        placeholder={placeholder}
        allowClear={true}
        defaultValue={defaultValue}
        onChange={value => handleInputChange(key, value)}
        value={filter[key]}>
        {options.map(option =>
          <Option value={option.value}>{option.description}</Option>
        )}
      </Select>
    );
  }

  render() {
    const {
      SALES_HIERARCHY,
      FROM_DATE_PICKER = false,
      TO_DATE_PICKER = false,
      FROM_DATE_PICKER_2 = false,
      TO_DATE_PICKER_2 = false,
      FROM_DATE_PICKER_3 = false,
      TO_DATE_PICKER_3 = false,
      SELECT_ROUTE = false,
      SELECT_OUTLET = false,
      SELECT_CATEGORY = false,
      SELECT_SUBCATEGORY = false,
      SELECT_TRANSACTION_TYPE = false,
      SELECT_PURCHASING_INVOICE_DOC_TYPE = false,
      SELECT_PURCHASING_CREDIT_INVOICE_DOC_TYPE = false,
      SELECT_PAYMENT_METHOD = false,
      SELECT_PAYMENT_TERM = false,
      SELECT_PAYMENT_TYPE = false,
      SELECT_SALES_RETRUN_REASONS = false,
      SELECT_PURCHASE_RETRUN_REASONS = false,
      SELECT_PURCHASE_CREDIT_MEMO_REASONS = false,
      SELECT_STATUS = false,
      SELECT_PURCHASE_ORDER_STATUS = false,
      SELECT_PURCHASE_RETURN_STATUS = false,
      SELECT_PURCHASING_INVOICE_STATUS = false,
      SELECT_EXPENSES_STATUS = false,
      SELECT_RETURN_REASON = false,
      SELECT_PURCHASE_RETURN_REASON = false,
      SELECT_TRIP_STATUS = false,
      SELECT_TRIP_SCHEDULAR_STATUS = false,
      SELECT_CUSTOMER_STATUS = false,
      SELECT_DISTRIBUTOR_STATUS = false,
      SELECT_STOCK_TRANSFER_STATUS = false,
      SELECT_CUSTOMER_PAYMENT_TYPE = false,
      SELECT_STOCK_TAKE_STATUS = false,
      SELECT_LOCATION = false,
      SELECT_FROM_LOCATION = false,
      SELECT_TO_LOCATION = false,
      SELECT_TERRITORY = false,
      SELECT_EXPENSES_CATEGORY = false,
      SELECT_PURCHASE_CR_REASONS = false,
      SEARCH_TEXT = false,
      SEARCH_TEXT_2 = false,
      SEARCH_DISTRIBUTOR_PAYMENT_NO_TEXT = false,
      FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING = false,
      TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING = false,
      FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING = false,
      TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING = false,
      SELECT_DISTRIBUTOR_PAYMENT_STATUS = false,
      SELECT_DISTRIBUTOR_PAYMENT_TYPE = false,
      SEARCH_DISTRIBUTOR_PAYMENT_DOC_NO_TEXT = false,
      FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC = false,
      TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC = false,
      SELECT_ORDER_TYPE = false,
      SELECT_DISCOUNT_REASON = false,
      SEARCH_BUTTON,
      GENERATE_PICK_LIST,
      INVOICE_BATCH_PRINT,
      isLoadingSummary,
      handleInputChange,
      handleSearchClick,
      handleGeneratePickList,
      handleInvoiceBatchPrint,
      setDefaultCustomerOption,
      setDefaultRouteOption,
      setDefaultTerritoryOption,
      setDefaultExpensesCategoryOption,
      filter,
      paymentMethods,
      paymentTerms,
      paymentTypes,
      salesOrderTypes,
      salesDiscountReasons,
      statusOptions = ['Open', 'Customer Confirmed'],
      statusOptionsPurchaseOrder = ['Open', 'Confirmed', 'SO Created', 'SO Approved', 'Dispatched', 'On Hold', 'Received'],
      statusOptionsPurchaseReturn = ['Open', 'Confirmed'],
      statusOptionsPurchaseCreditInvoice = ['Open', 'Confirmed'],
      docTypeOptionsPurchaseCreditInvoice = ['PURCHASE INVOICE, DEBIT INVOICE'],
      salesReturnReasons,
      salesCreditMemoReasons,
      purchaseReturnReasons,
      purchaseCreditMemoReasons,
      locations,
      style,
      isBatchPrinting
    } = this.props;

    return (
      <Form style={{ marginBottom: 10, ...style }}>
        <Row gutter={5}>
          <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>
            {this.renderField(
              SALES_HIERARCHY,
              <SelectSalesUserHierarchy
                value={filter.UserHierarchy}
                onChange={handleInputChange}
                allowClear={false} />
            )}

            {this.renderField(
              SELECT_ROUTE,
              <SelectRoute
                filter={filter}
                value={filter.RouteCode}
                onChange={handleInputChange}
                allowClear={true}
                setDefaultRouteOption={setDefaultRouteOption} />
            )}

            {this.renderField(
              SELECT_OUTLET,
              <SelectOutlet
                filter={filter}
                value={filter.CustNo}
                onChange={handleInputChange}
                setDefaultCustomerOption={setDefaultCustomerOption} />
            )}

            {this.renderField(
              SELECT_CATEGORY,
              <SelectCustomerCategory
                value={filter.OutletCategoryCode}
                onChange={handleInputChange}
                allowClear={true} />
            )}

            {this.renderField(
              SELECT_PURCHASE_RETRUN_REASONS,
              this.renderSelect(
                'DocReasonCode',
                'Return Type',
                purchaseReturnReasons.map(reason => ({ value: reason.Code, description: reason.Description }))
              )
            )}

            {this.renderField(
              SELECT_SALES_RETRUN_REASONS,
              this.renderSelect(
                'ReturnReasonCode',
                'Return Type',
                salesReturnReasons.map(reason => ({ value: reason.Code, description: reason.Description }))
              )
            )}

            {this.renderField(
              SELECT_SUBCATEGORY,
              <SelectCustomerSubcategory
                parentCategoryCode={filter.OutletCategoryCode}
                value={filter.OutletSubCategoryCode}
                onChange={handleInputChange}
                allowClear={true} />
            )}

            {this.renderField(
              SELECT_CUSTOMER_STATUS,
              this.renderSelect(
                SELECT_CUSTOMER_STATUS.key || 'StatusFilter',
                SELECT_CUSTOMER_STATUS.placeholder || 'Status',
                [{ Code: 'Active', Description: 'Active' }, { Code: 'Blocked', Description: 'Blocked' }].map(status => ({ value: status.Code, description: status.Description }))
              )
            )}

            {this.renderField(
              SELECT_DISTRIBUTOR_STATUS,
              this.renderSelect(
                SELECT_DISTRIBUTOR_STATUS.key || 'StatusFilter',
                SELECT_DISTRIBUTOR_STATUS.placeholder || 'Status',
                [{ Code: 'Active', Description: 'Active' }, { Code: 'Blocked', Description: 'Blocked' }].map(status => ({ value: status.Code, description: status.Description }))
              )
            )}


            {this.renderField(
              SELECT_PAYMENT_METHOD,
              this.renderSelect(
                'PayMethodCode',
                'Payment Method',
                paymentMethods.map(method => ({ value: method.No, description: method.Description }))
              )
            )}

            {this.renderField(
              SELECT_PAYMENT_TERM,
              this.renderSelect(
                'PayTermCode',
                'Payment Term',
                paymentTerms.map(term => ({ value: term.No, description: term.Description }))
              )
            )}

            {this.renderField(
              SELECT_PAYMENT_TYPE,
              this.renderSelect(
                'CollectType',
                SELECT_PAYMENT_TYPE.placeholder || 'Payment Type',
                paymentTypes.map(type => ({ value: type.Code, description: type.Description }))
              )
            )}

            {this.renderField(
              SELECT_TRANSACTION_TYPE,
              this.renderSelect(
                'TransactionCap',
                'Transaction Type',
                transactionTypes.map(type => ({ value: type.type, description: type.description }))
              )
            )}

            {this.renderField(
              FROM_DATE_PICKER,
              this.renderDatePicker('from', 'From')
            )}

            {this.renderField(
              TO_DATE_PICKER,
              this.renderDatePicker('to', 'To')
            )}

            {this.renderField(
              FROM_DATE_PICKER_2,
              this.renderDatePicker('from2', FROM_DATE_PICKER_2.placeholder || 'From')
            )}

            {this.renderField(
              TO_DATE_PICKER_2,
              this.renderDatePicker('to2', TO_DATE_PICKER_2.placeholder || 'To')
            )}

            {this.renderField(
              FROM_DATE_PICKER_3,
              this.renderDatePicker('from3', FROM_DATE_PICKER_3.placeholder || 'From')
            )}

            {this.renderField(
              TO_DATE_PICKER_3,
              this.renderDatePicker('to3', TO_DATE_PICKER_3.placeholder || 'To')
            )}

            {this.renderField(
              SELECT_STATUS,
              this.renderSelect(
                'Status',
                'Status',
                statusOptions.map(status => ({ value: status, description: status })),
                'Customer Confirmed'
              )
            )}

            {this.renderField(
              SELECT_PURCHASE_ORDER_STATUS,
              this.renderSelect(
                'OrderStatus',
                'Status',
                statusOptionsPurchaseOrder.map(status => ({ value: status, description: status })),
                'Confirmed'
              )
            )}

            {this.renderField(
              SELECT_PURCHASE_RETURN_STATUS,
              this.renderSelect(
                'OrderStatus',
                'Status',
                statusOptionsPurchaseReturn.map(status => ({ value: status, description: status })),
                'Confirmed'
              )
            )}

            {this.renderField(
              SELECT_RETURN_REASON,
              this.renderSelect(
                'ReturnReasonCode',
                'Type',
                salesCreditMemoReasons.map(reason => ({ value: reason.Code, description: reason.Description })),
                'Customer Confirmed'
              )
            )}

            {this.renderField(
              SELECT_TRIP_SCHEDULAR_STATUS,
              this.renderSelect(
                'StatusFilter',
                'Status',
                ['New', 'Approved', 'Recalled'].map(status => ({ value: status, description: status }))
              )
            )}

            {this.renderField(
              SELECT_TRIP_STATUS,
              this.renderSelect(
                'TripStatusFilter',
                'Trip Status',
                ['Not Started', 'Complete', 'Incomplete'].map(status => ({ value: status, description: status }))
              )
            )}

            {this.renderField(
              SELECT_CUSTOMER_PAYMENT_TYPE,
              this.renderSelect(
                'PaymentType',
                'Payment Type',
                ['CASH', 'CREDIT'].map(status => ({ value: status, description: status }))
              )
            )}

            {this.renderField(
              SELECT_TERRITORY,
              <SelectTerritory
                filter={filter}
                value={filter.TerritoryCode}
                onChange={handleInputChange}
                allowClear={true}
                setDefaultTerritoryOption={setDefaultTerritoryOption} />
            )}

            {this.renderField(
              SELECT_EXPENSES_CATEGORY,
              <SelectExpensesCategory
                filter={filter}
                value={filter.ExpCategory}
                onChange={handleInputChange}
                allowClear={true}
                setDefaultExpensesCategoryOption={setDefaultExpensesCategoryOption} />
            )}

            {this.renderField(
              SELECT_PURCHASING_INVOICE_DOC_TYPE,
              this.renderSelect(
                SELECT_PURCHASING_INVOICE_DOC_TYPE.key || 'DocType',
                SELECT_PURCHASING_INVOICE_DOC_TYPE.placeholder || 'Doc type',
                [{ Code: 'PURCHASE INVOICE', Description: 'Purchase Invoice' }, { Code: 'DEBIT INVOICE', Description: 'Debit Invoice' }].map(doctype => ({ value: doctype.Code, description: doctype.Description }))
              )
            )
            }

            {this.renderField(
              SELECT_PURCHASING_CREDIT_INVOICE_DOC_TYPE,
              this.renderSelect(
                SELECT_PURCHASING_CREDIT_INVOICE_DOC_TYPE.key || 'DocType',
                SELECT_PURCHASING_CREDIT_INVOICE_DOC_TYPE.placeholder || 'Doc type',
                [{ Code: 'CREDIT MEMO', Description: 'Credit Memo' }, { Code: 'RETURN ORDER', Description: 'Return Order' }].map(doctype => ({ value: doctype.Code, description: doctype.Description }))
              )
            )
            }

            {this.renderField(
              SELECT_PURCHASE_CREDIT_MEMO_REASONS,
              this.renderSelect(
                'DocReasonCode',
                'Return Type',
                purchaseCreditMemoReasons.map(reason => ({ value: reason.Code, description: reason.Description }))
              )
            )}

            {this.renderField(
              SEARCH_TEXT,
              <Input
                placeholder={SEARCH_TEXT.placeholder || 'Search Text'}
                value={filter.text}
                onChange={(e) => handleInputChange('text', e.target.value)} />
            )}

            {this.renderField(
              SEARCH_TEXT_2,
              <Input
                placeholder={SEARCH_TEXT_2.placeholder || 'Search Text'}
                value={filter.text2}
                onChange={(e) => handleInputChange('text2', e.target.value)} />
            )}

            {this.renderField(
              SELECT_FROM_LOCATION,
              this.renderSelect(
                'FromLocCode',
                'From Location',
                locations.map(location => ({ value: location.LocCode, description: location.LocDescription }))
              )
            )}

            {this.renderField(
              SELECT_TO_LOCATION,
              this.renderSelect(
                'ToLocCode',
                'To Location',
                locations.map(location => ({ value: location.LocCode, description: location.LocDescription }))
              )
            )}

            {this.renderField(
              SELECT_LOCATION,
              this.renderSelect(
                'LocCode',
                'Location',
                locations.map(location => ({ value: location.LocCode, description: location.LocDescription }))
              )
            )}

            {
              this.renderField(
                SELECT_STOCK_TAKE_STATUS,
                this.renderSelect(
                  'StatusFilter',
                  'Status',
                  [{ Code: 'Open', Description: 'Open' },
                  { Code: 'Confirmed', Description: 'Confirmed' },
                  { Code: 'Approved', Description: 'Approved' },
                  { Code: 'Approved with Adj', Description: 'Approved with Adj' }
                  ].map(status => ({ value: status.Code, description: status.Description })),
                  ''
                )
              )
            }

            {
              this.renderField(
                SELECT_STOCK_TRANSFER_STATUS,
                this.renderSelect(
                  'StatusFilter',
                  'Status',
                  [{ Code: '0', Description: 'Open' }, { Code: '1', Description: 'Released' }].map(status => ({ value: status.Code, description: status.Description })),
                  ''
                )
              )
            }
            {
              this.renderField(
                SELECT_PURCHASING_INVOICE_STATUS,
                this.renderSelect(
                  SELECT_PURCHASING_INVOICE_STATUS.key || 'StatusFilter',
                  SELECT_PURCHASING_INVOICE_STATUS.placeholder || 'Status',
                  [{ Code: 'Overdue', Description: 'Overdue' }, { Code: 'Outstanding', Description: 'Outstanding' }, { Code: 'Settled', Description: 'Settled' }].map(status => ({ value: status.Code, description: status.Description }))
                )
              )}

            {this.renderField(
              SELECT_EXPENSES_STATUS,
              this.renderSelect(
                SELECT_EXPENSES_STATUS.key || 'StatusFilter',
                SELECT_EXPENSES_STATUS.placeholder || 'Status',
                [{ Code: 'Open', Description: 'Open' }, { Code: 'Confirmed', Description: 'Confirmed' }].map(status => ({ value: status.Code, description: status.Description }))
              )
            )}

            {this.renderField(
              SEARCH_DISTRIBUTOR_PAYMENT_NO_TEXT,
              <Input
                placeholder={SEARCH_DISTRIBUTOR_PAYMENT_NO_TEXT.placeholder || 'Search Text'}
                value={filter.text}
                onChange={(e) => handleInputChange('text', e.target.value)} />
            )}


            {this.renderField(
              FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING,
              this.renderDatePicker('from', FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING.placeholder || 'From')
            )}

            {this.renderField(
              TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING,
              this.renderDatePicker('to', TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING.placeholder || 'To')
            )}

            {this.renderField(
              SELECT_DISTRIBUTOR_PAYMENT_STATUS,
              this.renderSelect(
                SELECT_DISTRIBUTOR_PAYMENT_STATUS.key || 'StatusFilter',
                SELECT_DISTRIBUTOR_PAYMENT_STATUS.placeholder || 'Status',
                [{ Code: 'Open', Description: 'Open' },
                { Code: 'Released', Description: 'Released' },
                { Code: 'Posted', Description: 'Posted' },
                { Code: 'Reversed', Description: 'Reversed' },
                { Code: 'Returned', Description: 'Returned' },
                { Code: 'Customer Confirmed', Description: 'Customer Confirmed' },
                { Code: 'On Hold', Description: 'On Hold' },
                { Code: 'Rejected', Description: 'Rejected' }].map(status => ({ value: status.Code, description: status.Description }))
              )
            )}

            {this.renderField(
              FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING,
              this.renderDatePicker('from2', FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING.placeholder, 'From')
            )}

            {this.renderField(
              TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING,
              this.renderDatePicker('to2', FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING.placeholder, 'To')
            )}

            {this.renderField(
              SELECT_DISTRIBUTOR_PAYMENT_TYPE,
              this.renderSelect(
                'CollectType',
                SELECT_DISTRIBUTOR_PAYMENT_TYPE.placeholder || 'Payment Type',
                paymentTypes.map(type => ({ value: type.Code, description: type.Description }))
              )
            )}

            {this.renderField(
              SEARCH_DISTRIBUTOR_PAYMENT_DOC_NO_TEXT,
              <Input
                placeholder={SEARCH_DISTRIBUTOR_PAYMENT_DOC_NO_TEXT.placeholder || 'Search Text'}
                value={filter.text_2}
                onChange={(e) => handleInputChange('text_2', e.target.value)} />
            )}

            {this.renderField(
              FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC,
              this.renderDatePicker('from3', FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC.placeholder || 'From')
            )}

            {this.renderField(
              TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC,
              this.renderDatePicker('to3', TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC.placeholder || 'To')
            )}

            {this.renderField(
              SELECT_ORDER_TYPE,
              this.renderSelect(
                'SOTypeFilter',
                'Order Type',
                salesOrderTypes.map(type => ({ value: type.Code, description: type.Description }))
              )
            )}

            {this.renderField(
              SELECT_DISCOUNT_REASON,
              this.renderSelect(
                'DiscountReasonCode',
                'Discount Reason',
                salesDiscountReasons.map(reason => ({ value: reason.Code, description: reason.Description }))
              )
            )}

            {SEARCH_BUTTON && <Col lg={SEARCH_BUTTON.lg} md={0} sm={0} xs={0}>
              <Button type='primary' block onClick={handleSearchClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FaSearch /></Button>
            </Col>}

            {SEARCH_BUTTON && <Col lg={0} md={SEARCH_BUTTON.md} sm={SEARCH_BUTTON.sm} xs={SEARCH_BUTTON.xs}>
              <Button type="primary" block onClick={handleSearchClick}>Search</Button>
            </Col>}

            {GENERATE_PICK_LIST && <Col lg={GENERATE_PICK_LIST.lg} md={0} sm={0} xs={0}>
              <Button type='primary' block onClick={handleGeneratePickList} title="Generate Pick List" disabled={isLoadingSummary}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {!isLoadingSummary && <FaFileDownload />}
                {isLoadingSummary && <Loader
                  loaded={false}
                  lines={13}
                  length={5}
                  width={5}
                  radius={3}
                  corners={1}
                  rotate={0}
                  direction={1}
                  color="#a5d8ff"
                  speed={1}
                  trail={60}
                  shadow={false}
                  hwaccel={false}
                  className="spinner"
                  zIndex={2e9}
                  top="50%"
                  left="50%"
                  scale={1.0}
                  loadedClassName="loadedContent"
                />}
              </Button>
            </Col>}

            {GENERATE_PICK_LIST && <Col style={{ marginTop: 5 }} lg={0} md={GENERATE_PICK_LIST.md} sm={GENERATE_PICK_LIST.sm} xs={GENERATE_PICK_LIST.xs}>
              <Button type="primary" block onClick={handleGeneratePickList} disabled={isLoadingSummary}>{isLoadingSummary ? 'Generating...' : 'Generate Pick List'}</Button>
            </Col>}

            {INVOICE_BATCH_PRINT && <Col lg={INVOICE_BATCH_PRINT.lg} md={0} sm={0} xs={0}>
              <Button type='primary' block onClick={handleInvoiceBatchPrint} title="Invoice Batch Print" disabled={isBatchPrinting} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {isBatchPrinting ?
                  <Loader
                    loaded={false}
                    lines={13}
                    length={5}
                    width={5}
                    radius={3}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#a5d8ff"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                  : <FaFileDownload />}
              </Button>
            </Col>}

            {INVOICE_BATCH_PRINT && <Col style={{ marginTop: 5 }} lg={0} md={INVOICE_BATCH_PRINT.md} sm={INVOICE_BATCH_PRINT.sm} xs={0}>
              <Button type="primary" block onClick={handleInvoiceBatchPrint}>Invoice Batch Print</Button>
            </Col>}
          </Col>
        </Row>
      </Form>
    )
  }
}

export const transactionTypes = [
  {
    description: 'Customer Management',
    type: 'Customer'
  },
  {
    description: 'Sales',
    type: 'Sales'
  },
  {
    description: 'Payment',
    type: 'Payment'
  },
  {
    description: 'Transfer',
    type: 'Transfer'
  },
  {
    description: 'Purchase',
    type: 'Purchase'
  },
  {
    description: 'Other',
    type: 'Other'
  }
]


const mapStateToProps = state => {
  return {
    fetchingFilteredRoutes: isFetchingFilteredRoutes(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    authData: getAuthData(state),
    paymentMethods: getPaymentMethods(state),
    paymentTerms: getPaymentTerms(state),
    paymentTypes: getPaymentTypes(state),
    salesReturnReasons: getSalesReturnReasons(state),
    purchaseReturnReasons: getPurchaseReturnReasons(state),
    salesCreditMemoReasons: getSalesCreditMemoReasons(state),
    purchaseCreditMemoReasons: getPurchaseCreditMemoReasons(state),
    locations: getLocations(state),
    fetchingTerritories: isFetchingTerritories(state),
    territories: getTerritories(state),
    fetchingexpensesCategories: isFetchingexpensesCategories(state),
    expensesCategories: getExpensesCategories(state),
    salesDiscountReasons: getSalesDiscountReason(state),
    salesOrderTypes: getSalesOrderType(state),
  };
};

export default connect(mapStateToProps, { fetchFilteredRoutes, fetchWarehouseLocations, fetchPaymentMethods, fetchPaymentTerms, fetchSalesReturnReasons, fetchTerritories, fetchExpensesCategories, fetchSalesDiscountReason })(FormItem);
