import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { dateFormatView } from '../../const/Formats';

class CollectionCard extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Unverified':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {
    const { collection, navigateToCollectionView } = this.props;
    const { collectionNo, collectionDate, sellToCustomerCode, sellToCustomerName, paymentType, amount, status } = collection;

    return (
      <Row onClick={navigateToCollectionView}>
        <Row style={styles.alignRow}>
          <Col xs={9} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.collectionNo}>#{collectionNo}</span>
          </Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.collectionDate}>{collectionDate ? moment(collectionDate).format(dateFormatView) : "Date N/A"}</span>
          </Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}>{sellToCustomerCode}</p>
          </Col>
          <Col xs={0} sm={0} md={6} lg={6} style={{ textAlign: 'left' }}>
            <span>{sellToCustomerName}</span>
          </Col>
          <Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={styles.paymentType}>{paymentType}</p>
          </Col>
          <Col xs={9} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={amount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <p style={styles.paymentType}>{paymentType}</p>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={styles.bottomColumnStyles}>
            <FaUser style={styles.icon} /><span>{sellToCustomerCode}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
            <FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  collectionNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  collectionDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  paymentType: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

export default CollectionCard;