import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import { ServerDateTOLocalDate } from "../../utils/Formats";
import { PURCHASE_RETURNS } from "../../const/Permissions";

class ReturnCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  getDistributorStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history, loggedInUser: { permissions = [] } } = this.props;
    const { No, OrderDate, DistributorCode, DistributorName, OrderStatus, CustStatus, InvAge, UserCode, AmountIncluVAT, CreatedUserName, CreatedUserID, TerritoryName, DocReasonDescrip } = order;
    const distributorStatus = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;
    const orderDateInLocal = ServerDateTOLocalDate(OrderDate);
    return (
      <Row onClick={() => permissions.includes(PURCHASE_RETURNS.VIEW) && history.push("/purchasing/ReturnOrder/" + encodeURIComponent(No))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderNoDesktop}>{No}</span>
          </Col>

          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{orderDateInLocal}</span>
          </Col>


          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{DocReasonDescrip}</span>
          </Col>

          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}>{DistributorCode}</p>
          </Col>

          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{DistributorName}</span>
          </Col>


          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag color={this.getDistributorStatusColor(distributorStatus)}>{distributorStatus}</Tag>
          </Col>


          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{TerritoryName}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag>
          </Col>


          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={AmountIncluVAT || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
          {/* mobile view first row */}
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderNoMobile}>{No}</span>
          </Col>

          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 12, fontWeight: 700 }}>{orderDateInLocal}</span>
          </Col>

          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'Right' }}>
            <Tag style={styles.status} color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag>
          </Col>


        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={10} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{DistributorName}</span>
          </Col>
        </Row>

        <Row style={styles.lowerRow}>
          <Col xs={4} sm={4} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getDistributorStatusColor(distributorStatus)}>{distributorStatus}</Tag>
          </Col>

          <Col xs={15} sm={15} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{DocReasonDescrip}</span>
          </Col>

          <Col xs={5} sm={5} md={0} lg={0} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={AmountIncluVAT || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>

        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNoDesktop: {
    fontSize: 14,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderNoMobile: {
    fontSize: 12,
    fontWeight: 700
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 14,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginLeft: 10,
    marginRight: 10
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const { order } = ownProps;

  const loggedInUser = getLoggedInUser(state);

  return { loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(ReturnCard));