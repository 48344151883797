import { combineReducers } from 'redux';
import { FETCH_INVENTORY, UPDATE_FILTER, UPDATE_COMBINED_FILTER, FETCH_INVENTORY_ITEM, UPDATE_INVENTORY_PAGE_SIZE } from '../actions/inventory';

export const defaultCombinedFilter = {
  brandFilter: [],
  category1Filter: [],
  category2Filter: [],
  category3Filter: [],
  category4Filter: [],
  textFilter: '',
};

export const byId = (state = {}, action) => {
  //console.log(action)
  switch (action.type) {
    case FETCH_INVENTORY: {
      const newState = {};
      action.items.forEach(item => {
        const { ItemNo, LotNo, LocCode } = item;
        const key = ItemNo + '-' + LotNo + '-' + LocCode;
        newState[key] = item
      });
      return newState;
    }
    case FETCH_INVENTORY_ITEM: {
      const item = action.item;
      const { ItemNo, LotNo, LocCode } = item || {};
      const key = ItemNo + '-' + LotNo + '-' + LocCode;
      state[key] = item;
      return state;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVENTORY: {
      return action.items.map(item => {
        const { ItemNo, LotNo, LocCode } = item;
        return ItemNo + '-' + LotNo + '-' + LocCode;
      });
    }
    case FETCH_INVENTORY_ITEM: {
      const newState = [...state]

      const item = action.item
      const { ItemNo, LotNo, LocCode } = item || {};
      const key = ItemNo + '-' + LotNo + '-' + LocCode;
      if (!newState.includes(key)) newState.push(key)
      return newState;
    }
    default:
      return state;
  }
};

export const brands = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      const brands = [];
      action.items.forEach(item => item.Brand && !brands.includes(item.Brand) && brands.push(item.Brand));
      return brands;
    case FETCH_INVENTORY_ITEM:
      const item = action.item

      if (state && state.length > 0 && item.Brand && !state.includes(item.Brand)) state.push(item.Brand)
      return [...state];
    default:
      return state;
  }
};

export const category1s = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      const category1s = [];
      action.items.forEach(item => item.SubCategoryCode1 && !category1s.includes(item.SubCategoryCode1) && category1s.push(item.SubCategoryCode1));
      return category1s;
    case FETCH_INVENTORY_ITEM:
      const item = action.item

      if (state && state.length > 0 && item.SubCategoryCode1 && !state.includes(item.SubCategoryCode1)) state.push(item.SubCategoryCode1)
      return [...state];
    default:
      return state;
  }
};


export const category2s = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      const category2s = [];
      action.items.forEach(item => item.SubCategoryCode2 && !category2s.includes(item.SubCategoryCode2) && category2s.push(item.SubCategoryCode2));
      return category2s;
    case FETCH_INVENTORY_ITEM:
      const item = action.item

      if (state && state.length > 0 && item.SubCategoryCode2 && !state.includes(item.SubCategoryCode2)) state.push(item.SubCategoryCode2)
      return [...state];
    default:
      return state;
  }
};

export const category3s = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      const category3s = [];
      action.items.forEach(item => item.SubCategoryCode3 && !category3s.includes(item.SubCategoryCode3) && category3s.push(item.SubCategoryCode3));
      return category3s;
    case FETCH_INVENTORY_ITEM:
      const item = action.item

      if (state && state.length > 0 && item.SubCategoryCode3 && !state.includes(item.SubCategoryCode3)) state.push(item.SubCategoryCode3)
      return [...state];
    default:
      return state;
  }
};

export const category4s = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      const category4s = [];
      action.items.forEach(item => item.SubCategoryCode4 && !category4s.includes(item.SubCategoryCode4) && category4s.push(item.SubCategoryCode4));
      return category4s;
    case FETCH_INVENTORY_ITEM:
      const item = action.item

      if (state && state.length > 0 && item.SubCategoryCode4 && !state.includes(item.SubCategoryCode4)) state.push(item.SubCategoryCode4)
      return [...state];
    default:
      return state;
  }
};

export const combinedFilter = (state = defaultCombinedFilter, action) => {

  // Updating storage state
  if (!state.category3Filter) state.category3Filter = [];
  if (!state.category4Filter) state.category4Filter = [];

  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        [action.filterType]: action.filter,
      };

    case UPDATE_COMBINED_FILTER:
      return {
        ...action.filter,
      };

    default:
      return state;
  }
}

export const pageSize = (state = 10, action) => {
  switch (action.type) {
    case UPDATE_INVENTORY_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
}

const inventory = combineReducers({
  byId,
  ids,
  brands,
  category1s,
  category2s,
  category3s,
  category4s,
  combinedFilter,
  pageSize,
});

export default inventory;

export const getInventory = state => {
  const { textFilter = '', brandFilter, category1Filter = [], category2Filter = [], category3Filter = [], category4Filter = [] } = state.combinedFilter;
  const textFilterLower = textFilter.toLowerCase();

  const defaultMatchText = textFilterLower === '';
  const defaultMatchBrand = brandFilter.length === 0;
  const defaultMatchCategory1 = category1Filter.length === 0;
  const defaultMatchCategory2 = category2Filter.length === 0;
  const defaultMatchCategory3 = category3Filter.length === 0;
  const defaultMatchCategory4 = category4Filter.length === 0;

  return state.ids.map(id => state.byId[id]).filter(item => {
    let matchText = defaultMatchText;
    let matchBrand = defaultMatchBrand;
    let matchCategory1 = defaultMatchCategory1;
    let matchCategory2 = defaultMatchCategory2;
    let matchCategory3 = defaultMatchCategory3;
    let matchCategory4 = defaultMatchCategory4;

    if (!matchText) {
      matchText = item.ItemNo.toLowerCase().includes(textFilterLower) || item.Description.toLowerCase().includes(textFilterLower);
    }

    if (!matchBrand) {
      matchBrand = brandFilter.includes(item.Brand);
    }

    if (!matchCategory1) {
      matchCategory1 = category1Filter.includes(item.SubCategoryCode1);
    }

    if (!matchCategory2) {
      matchCategory2 = category2Filter.includes(item.SubCategoryCode2);
    }

    if (!matchCategory3) {
      matchCategory3 = category3Filter.includes(item.SubCategoryCode3);
    }

    if (!matchCategory4) {
      matchCategory4 = category4Filter.includes(item.SubCategoryCode4);
    }

    return matchText && matchBrand && matchCategory1 && matchCategory2 && matchCategory3 && matchCategory4;
  });
}

export const getBrands = state => (state.brands && state.brands.length > 0) ? state.brands : [];
export const getCategory1s = state => (state.category1s && state.category1s.length > 0) ? state.category1s : [];
export const getCategory2s = state => (state.category2s && state.category2s.length > 0) ? state.category2s : [];
export const getCategory3s = state => (state.category3s && state.category3s.length > 0) ? state.category3s : [];
export const getCategory4s = state => (state.category4s && state.category4s.length > 0) ? state.category4s : [];
export const getCombinedFilter = state => state.combinedFilter;
export const getItem = (state, id) => state.byId[id];
export const getPageSize = state => state.pageSize;