import React, { Component } from 'react';
import { Card, Row, Col, Select } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';

const { Option } = Select

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class OrderFooter extends Component {

  render() {
    const { totalWithoutDiscount, totalAmount, totalDiscountAmount, amountIncludingVat, discountPercentage } = this.props.order;

    return (
      <Card style={{ margin: 12 }}>
        <Row gutter={5}>
          <Col>
            <Col lg={12} md={12} sm={24} xs={24}>

              <FormItem label={"Remarks"}>
                <TextArea rows={4} placeholder={"Type here"} ></TextArea>
              </FormItem>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}></Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <div style={{ fontSize: "large" }}>Gross Amount</div>
                <div style={{ fontSize: "large" }}>{format(totalWithoutDiscount, currencyFormat)}</div>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <div style={{ fontSize: "large" }}>Discount ({discountPercentage}%) </div>
                <div style={{ fontSize: "large" }}>{format(totalDiscountAmount, currencyFormat)}</div>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <div style={{ fontSize: "large" }}>Net Amount</div>
                <div style={{ fontSize: "large" }}>{format(totalAmount, currencyFormat)}</div>
              </Col>
            </Col>
          </Col>

          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {/* <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Gross Amount</div>
              <div style={{ fontSize: "large" }}>{format(totalAmount, currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large", marginRight: 10 }}>Discount</div>
              <div style={{ fontSize: "large" }}>{format(totalDiscountAmount, currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large", marginRight: 10 }}>Tax</div>
              <div style={{ fontSize: "large" }}>{format((amountIncludingVat - (totalAmount + totalDiscountAmount)), currencyFormat)}</div>
            </Col> */}
            {/* <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Gross Amount</div>
              <div style={{ fontSize: "large" }}>{format(totalWithoutDiscount, currencyFormat)}</div>
            </Col> */}
            {/* <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Discount ({discountPercentage}%) </div>
              <div style={{ fontSize: "large" }}>{format(totalDiscountAmount, currencyFormat)}</div>
            </Col> */}
            {/* <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Net Amount</div>
              <div style={{ fontSize: "large" }}>{format(totalAmount, currencyFormat)}</div>
            </Col> */}
          </Row>
        </Row>
      </Card>
    )
  }
}
export default OrderFooter;