import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, deleteOrder } from "../../../redux/actions/orders";
import { fetchInventory } from "../../../redux/actions/inventory";
import { getOrder } from "../../../redux/reducers";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import OrderHeader from './Header';
import OrderDetailTable from './SalesLineTable';
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { currentLocation } from '../../../utils/Location';
import Api from '../../../api/consts'
import { defaultInvoice } from '../../PurchasingInvoices/PurchasingInvoiceScreen/Container';
import { getDemoGRN } from '../dummyGRNs'
import GRNExport from '../GRNExport'
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

export const defaultGRN = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  orderDate: moment(),
  grnNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "PENDING",
  sessionId: uuid(),
}

class Container extends Component {
  state = {
    loading: false,
    grn: { ...defaultGRN }
  }

  componentDidMount() {
    const { grnNo } = this.props.match.params
    const newGrn = grnNo === 'new';

    if (newGrn) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      //this.setState({ invoice: { ...defaulInvoice, sellToCustomerCode: customer.id, sellToCustomerName: customer.name } })
    } else {
      this.setState({ loading: true })
      this.props.fetchOrder(grnNo).then(() => {
        this.setState({ loading: false })
      })
      this.setState({ grn: getDemoGRN(grnNo) })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { grn, match } = nextProps;
    const { grnNo } = match.params
    const newGrn = grnNo === 'new';

    // if (newGrn) {
    //   this.setState({ grn: { ...defaultGRN} })
    // } else if (grn) {
    //   this.setState({ grn: { ...grn, status: grn.status } })
    // }

    this.setState({ grn: getDemoGRN(grnNo) })
  }


  calculateTotalAmount = () => {
    const { grn = {} } = this.props;
    const { salesLines = [] } = grn;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  submitOrder = () => {
    const { addOrder, toastManager, match } = this.props;

    this.setState({ loading: true })

    // const { order } = this.state;

    // order.mapLocation = currentLocation;

    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a new GRN.', { autoDismiss: true, appearance: 'success' });
      this.props.history.replace('/grns/' + "GRN 6")
    }, 2000)
    // addOrder(order).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
    //     defaultGRN.sessionId = uuid();
    //     this.props.history.replace('/grns/' + match.params.grnNo)
    //   } else {
    //     toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
  }

  shareOrder = () => {
    // const { order } = this.state;
    // const reportLink = order && order.links && order.links.length > 0 && order.links.find(link => link.includes('order-report'))
    // reportLink && window.open(Api.server + "/files/" + reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');
    // const { orderId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // printContainer.style.display = 'inline';
    // this.setState({ loading: true });
    // html2canvas(document.querySelector("#orderPrintDiv"), { width: 685 }).then((canvas) => {

    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = orderId + '.png';
    //   a.click();

    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }


  navigateToInventory = () => {
    const { grnNo } = this.props.match.params
    const grn = grnNo === 'new' ? { grnNo: 'new' } : this.state.grn
    this.props.history.push({ pathname: '/inventory', state: { resource: "grns", grn, id: grnNo } })
  }

  navigateToConfirmOrder = () => {
    const { grnNo } = this.props.match.params

    this.props.history.push({ pathname: '/grns/' + grnNo + '/confirm', state: { grn: this.state.grn } })
  }

  onDeleteOrder = () => {
    const { deleteOrder, toastManager, history, fetchInventory } = this.props;
    //this.setState({ loading: true });
    // deleteOrder(this.state.order).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete order. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Order successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     fetchInventory();
    //     history.goBack();
    //   }
    // });
    toastManager.add('GRN successfully deleted.', { autoDismiss: true, appearance: 'success' });
    history.goBack();
  }

  renderActions = () => {
    const { match } = this.props;
    const { salesLines, status } = this.state.grn;
    const { grnNo } = match.params
    const newGrn = grnNo === 'new' || grnNo === 'GRN 0';

    if (newGrn) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.submitOrder}
        >
          <CallStatusValidateButton type="primary">Create</CallStatusValidateButton>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (order.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteOrder}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       order.salesLines && order.salesLines.length > 0 &&
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (order.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       order.links && order.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareOrder()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteOrder}
            >
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmOrder}>Confirm</CallStatusValidateButton>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareOrder}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  render() {
    const { customers, match } = this.props;
    const { grnNo } = match.params
    const newGrn = grnNo === 'new';
    const { loading, grn } = this.state;

    if (!grn) return null;

    return (
      <Spin spinning={loading} tip={"Loading GRN"}>
        <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          <OrderHeader
            order={grn}
            customers={customers}
            newOrder={newGrn}
            onSubmitOrder={this.submitOrder}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirmOrder}
            onDeleteOrder={this.onDeleteOrder} />
          <div style={{ padding: '0 12px' }}>
            <OrderDetailTable order={grn} scrollHeight={this.getScrollAreaHeight()}
              OnClickAddItem={this.navigateToInventory} />
          </div>
          <OrderFooter order={grn} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            <GRNExport grn={grn} />
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { grnNo } = ownProps.match.params

  //return { order: getOrder(state, orderId) };
  //console.log(getDemoGRN(grnNo))
  return { grn: getDemoGRN(grnNo) };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, deleteOrder, fetchInventory })(Container)));