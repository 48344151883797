import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Col, Row, Table } from "antd";
import { withRouter } from "react-router-dom";
import DistributorPaymentCard from "./DistributorPayentCard";
import DistributorPaymentCardHeader from "./DistributorPaymentCardHeader";
import AdvanceSearchFilters from "../common/AdvanceSearchFilters";
import TableFooter from "../common/TableFooter";
import { format } from "currency-formatter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class PaymentTable extends Component {

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight + 20 : 25;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }

  getTableHeight = payments => {
    if (payments.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, setDefaultCustomerOption, setDefaultRouteOption } = this.props;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };

    const filterOptions = {
      SALES_HIERARCHY: entireColumn,
      SEARCH_DISTRIBUTOR_PAYMENT_NO_TEXT: { ...oneSixthOfColumn, placeholder: 'Search Collection No' },
      FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING: oneSixthOfColumn,
      TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_RECEIVING: oneSixthOfColumn,
      FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING: { ...oneSixthOfColumn, placeholder: 'Posting Date From' },
      TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_POSTING: { ...oneSixthOfColumn, placeholder: 'Posting Date To' },
      SELECT_DISTRIBUTOR_PAYMENT_STATUS: oneSixthOfColumn,
      SELECT_DISTRIBUTOR_PAYMENT_TYPE: { ...fiveBars, placeholder: 'Collection Type' },
      SEARCH_DISTRIBUTOR_PAYMENT_DOC_NO_TEXT: { ...fiveBars, placeholder: 'Search Doc no' },
      FROM_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC: { ...fiveBars, placeholder: 'Doc Date From' },
      TO_DATE_PICKER_DISTRIBUTOR_PAYMENT_DOC: { ...fiveBars, placeholder: 'Doc Date To' },
      SEARCH_BUTTON: oneSixthOfColumn
    };

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          {...filterOptions}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          filter={filter}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
        />
        <DistributorPaymentCardHeader />
      </div>
    )
  }

  renderFooter = distributorPayments => {
    const { total, totalAmount, currentPage, onPaginationChange } = this.props;
    const footerTotalAmount = format(parseFloat(totalAmount), currencyFormat);

    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={footerTotalAmount}
        noOfRecordLabel={'Distributor Payments'}
        style={{ paddingRight: 33 }}
      />
    );
  }

  renderColumns = () => {
    return [{
      render: distributorPayment => <DistributorPaymentCard distributorPayment={distributorPayment} />
    }]
  }

  render() {
    const { distributorPayments: filteredDistributorPayments } = this.props;

    return (
      <Col>
        <Table
          rowKey={'CollectionNo'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredDistributorPayments) }}
          columns={this.renderColumns()}
          dataSource={filteredDistributorPayments}
          size={'small'}
          pagination={false}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredDistributorPayments)} />
      </Col>
    )
  }
}

PaymentTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(PaymentTable);