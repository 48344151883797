import * as fromApi from '../../api/generic';

export const FETCH_PURCHASE_LINES = 'FETCH_PURCHASE_LINES';
export const FETCH_PURCHASE_LINE = 'FETCH_PURCHASE_LINE';
export const FAILED_TO_ADD_PURCHASE_LINE = 'FAILED_TO_ADD_PURCHASE_LINE';
export const REMOVE_PURCHASE_LINE = 'REMOVE_PURCHASE_LINE';

export const addPurchaseLine = purchaseLine => dispatch => {
  return fromApi.add('PurchaseLine', purchaseLine).then(response => {
    const { data, error } = response;

    if (error) {
      dispatch({ type: FAILED_TO_ADD_PURCHASE_LINE, purchaseLine: { ...purchaseLine, added: false } });
      return { error }
    }

    dispatch({ type: FETCH_PURCHASE_LINE, purchaseLine: { ...data, added: true } });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchPurchaseLines = filter => dispatch => {
  return fromApi.get('PurchaseLine', filter).then(response => {
    const { data, error } = response;

    if (error) return { error };

    const purchaseLines = data.map(item => ({ ...item, added: true }))
    dispatch({ type: FETCH_PURCHASE_LINES, purchaseLines: purchaseLines });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const updatePurchaseLine = purchaseLine => dispatch => {
  return fromApi.update('PurchaseLine', purchaseLine).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: FETCH_PURCHASE_LINE, purchaseLine: { ...data, added: true } });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deletePurchaseLine = purchaseLine => dispatch => {
  return fromApi.deleteObject('PurchaseLine', purchaseLine).then(response => {
    const { error } = response;

    if (error) return { error };

    dispatch({ type: REMOVE_PURCHASE_LINE, purchaseLine });
    return response;
  }).catch(error => {
    return { error }
  })
};