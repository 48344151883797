import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Modal, Form } from "antd";
import { fetchCustomer } from "../../../../redux/actions/customers";
import { addOrder } from "../../../../redux/actions/orders";
import { showNotification } from "../../../../const/Notifications"
import Details from "./Details";
import Actions from "./Actions";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { getDemoLocation } from '../LocationDemoData'
import LocationHeader from './Header'
import LocationForm from './Form'
import Input from '../../../../components/common/EnglishInput';

const defaultLocation = {
  locationNo: "new",
  name: undefined,
  type: "STATIC",
  status: "UNBLOCK",
  locations: [],
  blockedReasons: []
}

class Container extends Component {
  state = {
    loading: false,
    location: {
      ...defaultLocation
    },
    locationNameError: undefined,
    editLocation: false,
    blockModelVisible: false
  }

  componentDidMount() {
    const { locationId, newLocation } = this.props;
    if (!newLocation) {
      // this.setState({ loading: true })
      // this.props.fetchCustomer(locationId).then(() => {
      //   this.setState({ loading: false })
      // })
      this.setState({ loading: true })
      setTimeout(() => {
        this.setState({ location: getDemoLocation(locationId), loading: false })
      }, 1000)
    }
  }

  onInputChange = (field, value, errorKey) => {
    this.setState({ location: { ...this.state.location, [field]: value }, [errorKey]: undefined });
  }

  onSelectLocationTypeChange = type => {
    this.setState({ location: { ...this.state.location, type } });
  }

  onSaveLocation = () => {
    if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { location, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });

    if (this.props.locationId !== 'new') {
      // updateCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the collection.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      toastManager.add('Successfully created a new location.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
      // addCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
      //    // defaultCollection.sessionId = uuid();
      //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
      //     this.props.history.replace('/locations/' + location.id + '/collections/' + response.collection.collectionNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  onUpdateLocation = () => {
    // if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { location, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });
    toastManager.add('Successfully update.', { autoDismiss: true, appearance: 'success' });
    this.setState({ loading: false, editLocation: false });
    this.props.history.goBack();
    // addCollection(collection).then(response => {
    //   this.setState({ loading: false });
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
    //    // defaultCollection.sessionId = uuid();
    //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
    //     this.props.history.replace('/locations/' + location.id + '/collections/' + response.collection.collectionNo);
    //   } else {
    //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
    //   }
    // })

  }

  validateForm = () => {
    const { location } = this.state;
    const { name, address, email, faxNumber, contactPerson, contactNumber } = location;

    let nameError = undefined;
    let contactNoError = undefined;
    let addressError = undefined;
    let faxNoError = undefined;
    let emailError = undefined;
    let contactPersonError = undefined;

    if (!this.isValidValue(name)) nameError = 'Name is required.'
    if (!this.isValidValue(address)) addressError = 'Address is required.'
    if (!this.isValidValue(contactNumber)) contactNoError = 'Contact Number is required.'

    this.setState({ nameError, contactNoError, addressError, faxNoError, emailError, contactPersonError })

    return !nameError && !addressError && !contactNoError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  onCreateLocation = () => {
    const { addOrder, toastManager, location } = this.props;
    // const order = { ...defaultOrder, sellToLocationCode: location.id, sellToLocationName: location.name }

    // this.setState({ loading: true })
    // addOrder(order).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
    //     defaultOrder.sessionId = uuid();
    //     this.props.history.replace('/orders/' + response.order.orderNo)
    //   } else {
    //     toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a location.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack()
    }, 1000)
  }

  onDeleteLocation = () => {
    const { toastManager, match } = this.props;
    const { warehouseId } = match.params;

    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully deleted a location.', { autoDismiss: true, appearance: 'success' });
      this.props.history.push('/warehouses/' + warehouseId + '/locations')
    }, 1000)
  }

  onClickRefresh = () => {
    this.setState({ loading: true });
    this.props.fetchLocation(this.props.locationId).then(res => {
      this.setState({ loading: false })
      if (res.location) {
        showNotification('success', 'Refreshed', "Location Refreshed successfully.")
      } else {
        showNotification('error', 'Failed', "Failed to refresh location.")
      }

    })
  }

  onRemoveReason = index => {
    const { blockedReasons } = this.state.location;

    this.setState({ location: { ...this.state.location, blockedReasons: blockedReasons.splice(index, 1) } })
  }

  renderModal() {
    return (
      <Modal
        visible={this.state.blockModelVisible}
        title="Add Block Reason"
        okText="Block"
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <Form layout="vertical">
          <Form.Item label="Reason">
            {(<Input type="textarea" onChange={value => this.setState({ blockedReason: value })} />)}
          </Form.Item>

        </Form>
      </Modal>
    );
  }

  onBloackCustomer = () => this.setState({ blockModelVisible: true })

  handleOk = () => this.setState({ blockModelVisible: false })

  render() {
    const { editLocation, newLocation } = this.props;
    const {
      loading,
      locationNameError,
      location } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        {this.renderModal()}
        <Row>
          {!newLocation && !editLocation && <Details location={location} />}
          {!newLocation && !editLocation && <Actions location={location} submitOrder={this.submitOrder} onClickRefresh={this.onClickRefresh} />}
          {(this.props.locationId === "new" || editLocation) && <LocationHeader locationId={this.props.locationId} location={location} />}
          {(this.props.locationId === "new" || editLocation) &&
            <LocationForm
              onInputChange={this.onInputChange}
              onSelectLocationTypeChange={this.onSelectLocationTypeChange}
              locationNameError={locationNameError}
              location={location}
              onCreateLocation={this.onCreateLocation}
              onUpdateLocation={this.onUpdateLocation}
              onDeleteLocation={this.onDeleteLocation}
              onRemoveReason={this.onRemoveReason}
              onBloackCustomer={this.onBloackCustomer}
            />
          }
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationId } = ownProps
  // const location = getCustomer(state, locationId);
  const location = getDemoLocation(locationId);

  return { location };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchCustomer, addOrder })(Container)));