import * as fromApi from '../../api/generic';

let lastSearchKey;
export const fetchPaymentHeaders = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: 'START_FETCH_PAYMENT_HEADERS' });

  return fromApi.get('PaymentHeader', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_PAYMENT_HEADERS', paymentHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    console.log(error);
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: 'FINISH_FETCH_PAYMENT_HEADERS' });
  });
};

export const addPaymentHeader = header => dispatch => {
  return fromApi.add('PaymentHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_PAYMENT_HEADER', paymentHeaders: [data] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updatePaymentHeader = header => dispatch => {
  return fromApi.update('PaymentHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_PAYMENT_HEADER', paymentHeaders: [data] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deletePaymentHeader = header => dispatch => {
  return fromApi.deleteObject('PaymentHeader', header).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: 'DELETE_PAYMENT_HEADER', paymentHeader: header });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchPaymentTypes = () => dispatch => {
  return fromApi.get('RefData', { RefName: 'CollectionTypes' }).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_PAYMENT_TYPES', paymentTypes: data });
    return response;
  }).catch(error => {
    return { error }
  });
}