import { combineReducers } from 'redux';
import { getOfflineData } from '../../localStorage';
import { FETCH_CUSTOMERS, FETCH_CUSTOMER, FETCH_CUSTOMER_CATEGORIES, FETCH_CUSTOMER_SUBCATEGORIES, FETCH_CUSTOMER_PAYMENT_TERMS, FETCH_CUSTOMER_PAYMENT_METHODS, START_FETCH_CUSTOMERS, FINISH_FETCH_CUSTOMERS, START_FETCH_CUSTOMER_CATEGORIES, FINISH_FETCH_CUSTOMER_CATEGORIES, FINISH_FETCH_CUSTOMER_SUBCATEGORIES, START_FETCH_CUSTOMER_SUBCATEGORIES, START_FETCH_MAP_CUSTOMERS, FINISH_FETCH_MAP_CUSTOMERS, FETCH_MAP_CUSTOMERS, SET_ACTIVE_SEARCH_KEY, FETCH_DROPDOWN_CUSTOMERS, START_FETCH_DROPDOWN_CUSTOMERS, FINISH_FETCH_DROPDOWN_CUSTOMERS } from '../actions/customers';
import paymentTerms from './paymentTerms';
import { readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const defaultCombinedFilter = {
	brandFilter: [],
	category1Filter: [],
	category2Filter: [],
	productTypeFilter: [],
	textFilter: '',
};

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMERS:
			const newState = { ...state };
			action.customers.forEach(customer => newState[customer.No] = customer);
			return newState;
		case FETCH_CUSTOMER:
			const customer = action.customer
			state[customer.No] = customer
			return state;
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_CUSTOMERS: {
			return action.customers.map(customer => customer.No);
		}
		case FETCH_CUSTOMER:
			const customer = action.customer

			if (state && state.length > 0 && !state.includes(customer.No)) state.push(customer.No)
			return [...state];
		default:
			return state;
	}
};

export const byRoute = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMERS: {
			const newState = {};
			action.customers.forEach(customer => {
				const idsByRoute = newState[customer.RouteCode] || [];
				newState[customer.RouteCode] = [...idsByRoute, customer.No];
			});
			return newState;
		}
		case FETCH_CUSTOMER: {
			const newState = { ...state };
			const idsByRoute = newState[action.customer.RouteCode] || [];

			if (!idsByRoute.includes(action.customer.No)) {
				newState[action.customer.RouteCode] = [...idsByRoute, action.customer.No];
			}

			return newState;
		}
		default:
			return state;
	}
};

export const mapCustomers = (state = [], action) => {
	switch (action.type) {
		case FETCH_MAP_CUSTOMERS:
			return action.customers;

		default:
			return state;
	}
}

export const dropdownCustomers = (state = [], action) => {
	switch (action.type) {
		case FETCH_DROPDOWN_CUSTOMERS:
			return action.customers;

		default:
			return state;
	}
}

export const totalBySearchKey = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMERS:
			const newState = { ...state };
			newState[action.searchKey] = action.total;
			return newState;
		default:
			return state;
	}
}

export const fetchingCustomers = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_CUSTOMERS:
			return true;

		case FINISH_FETCH_CUSTOMERS:
			return false;

		default:
			return state;
	}
};

export const fetchingMapCustomers = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_MAP_CUSTOMERS:
			return true;

		case FINISH_FETCH_MAP_CUSTOMERS:
			return false;

		default:
			return state;
	}
};

export const fetchingDropdownCustomers = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_DROPDOWN_CUSTOMERS:
			return true;

		case FINISH_FETCH_DROPDOWN_CUSTOMERS:
			return false;

		default:
			return state;
	}
};

export const fetchingCustomerCategories = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_CUSTOMER_CATEGORIES:
			return true;

		case FINISH_FETCH_CUSTOMER_CATEGORIES:
			return false;

		default:
			return state;
	}
};

export const fetchingCustomerSubcategories = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_CUSTOMER_SUBCATEGORIES:
			return true;

		case FINISH_FETCH_CUSTOMER_SUBCATEGORIES:
			return false;

		default:
			return state;
	}
};

export const categoriesById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_CATEGORIES:
			const newState = {};
			action.customerCategories.forEach(category => newState[category.Code] = category);
			return newState;
		default:
			return state;
	}
};

export const categoryIds = (state = [], action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_CATEGORIES:
			return action.customerCategories.map(category => category.Code);
		default:
			return state;
	}
};

export const subcategoriesById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_SUBCATEGORIES:
			const newState = {};
			action.subcategories.forEach(category => newState[category.OutletCategory + category.Code] = category);
			return newState;
		default:
			return state;
	}
};

export const subcategoryIds = (state = [], action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_SUBCATEGORIES:
			return action.subcategories.map(category => category.OutletCategory + category.Code);
		default:
			return state;
	}
};

export const subcategoriesByCategory = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_SUBCATEGORIES:
			const newState = {};
			action.subcategories.forEach(category => {
				const categoryData = newState[category.OutletCategory] || [];
				categoryData.push(category.OutletCategory + category.Code);
				newState[category.OutletCategory] = categoryData;
			});
			return newState;
		default:
			return state;
	}
};

export const activeSearchKey = (state = '', action) => {
	switch (action.type) {
		case SET_ACTIVE_SEARCH_KEY:
			return action.searchKey || state;
		default:
			return state;
	}
}

export const customersBySearchKey = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMERS:
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.customers.map(customer => customer.No)
			};

		default:
			return state;
	}
}

const customers = combineReducers({
	byId,
	ids,
	totalBySearchKey,
	categoriesById,
	categoryIds,
	subcategoriesById,
	subcategoryIds,
	subcategoriesByCategory,
	byRoute,
	mapCustomers,
	dropdownCustomers,
	fetchingCustomers,
	fetchingMapCustomers,
	fetchingDropdownCustomers,
	fetchingCustomerCategories,
	fetchingCustomerSubcategories,
	activeSearchKey,
	customersBySearchKey
});

export default customers;

export const getCustomers = state => {
	return state.ids.map(id => state.byId[id]).filter(customer => !!customer)
}

export const getMapCustomers = state => {
	return state.mapCustomers
};

export const getDropdownCustomers = state => {
	return state.dropdownCustomers
};

// export const getDropdownCustomers = state => {
// 	if (getOfflineData('state').isSystemOffline) {
// 		// return getOfflineData('customerData').data;
// 		const { customerData, error } = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
// 			return res;
// 		  }).catch((err) => { alert(err); })
// 	} else {
// 		return state.dropdownCustomers;
// 	}
// }


export const getTotal = (state, searchKey) => state.totalBySearchKey[searchKey] || 0;

export const getCustomer = (state, customerId) => state.byId[customerId]

export const getCustomersByRoute = (state, routeId) => {
	const ids = state.byRoute[routeId];
	return (ids && ids.length > 0) ? ids.map(id => state.byId[id]) : [];
}

export const getCustomerCategories = state => {
	return state.categoryIds.map(id => state.categoriesById[id])
}

export const getCustomerSubcategories = state => {
	return state.subcategoryIds.map(id => state.subcategoriesById[id]);
}

export const getCustomerCategoryById = (state, id) => {
	return state.categoriesById[id];
}

export const getCustomerCategoryByCustomerId = (state, customerId) => {
	const customer = state.byId[customerId];
	const category = customer && state.categoriesById[customer.OutletCategoryCode];

	return category;
}

export const getCustomerSubcategoryByCustomerId = (state, customerId) => {
	const customer = state.byId[customerId];
	const category = customer && state.subcategoriesById[customer.OutletCategoryCode + customer.OutletSubCategoryCode];

	return category;
}

export const isFetchingCustomers = state => state.fetchingCustomers;
export const isFetchingMapCustomers = state => state.fetchingMapCustomers;
export const isFetchingDropdownCustomers = state => state.fetchingDropdownCustomers;
export const isFetchingCustomerCategories = state => state.fetchingCustomerCategories;

export const getBySearchKey = (state, searchKey) => {
	const ids = state.customersBySearchKey[searchKey] || [];
	return ids.map(id => state.byId[id]);
}