import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ProMateGreen } from '../../../../const/Theme'
import { Button, Row, Select, Spin } from 'antd';
import { fetchAllMonths, fetchAllYears } from '../../../../redux/actions/targetScheduler';
import { getAllMonths, getAllYears, getAuthData } from '../../../../redux/reducers';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import moment from 'moment'
import { generateRouteAchievementReport, generateRouteWiseDailyReport } from '../../../../api/reports';
import { withToastManager } from 'react-toast-notifications';
import Api from '../../../../api/consts';

export class RouteWiseDailyReport extends Component {
    constructor(props) {
        super(props);
        this.treeData = [];
    }

    state = {
        loading: false,
        generating: false,
        filter: {
            Month: String(moment().format("MMM")).toUpperCase(),
            Year: moment().format("YYYY"),
            UserTag: ""
        },
        CSRUsers: []
    }
    async componentDidMount() {
        const { fetchAllMonths, fetchAllYears, fetchSalesUserHierarchy, authData } = this.props;

        this.setState({
            loading: true
        });

        await fetchAllMonths();
        await fetchAllYears();

        let results = await fetchSalesUserHierarchy(authData);
        results = results.records.map((result) => {
            return JSON.parse(result.jSONSalesHichyStruct);
        });
        for (const record of results) {
            this.generateTreeObject(record);
        }

        this.setState({
            loading: false,
            CSRUsers: this.treeData,
            filter: {
                ...this.state.filter,
                UserTag: this.treeData.length > 0 ? this.treeData[0].value : "",
            }
        });

    }

    handleSelectChange(type, value) {
        this.setState({
            filter: {
                ...this.state.filter,
                [type]: value
            }
        });
    }

    generateTreeObject = (input) => {
        const arr = Object.values(input)
        return arr.map(item => {
            const { user } = item;
            if (user?.role === "CSR") {
                this.treeData.push(
                    {
                        title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                        key: item.positionCode,
                        value: item.positionCode,
                        role: user?.role,
                        code: user.code,
                        name: user?.name
                    }
                );
            }
            return {
                title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                key: item.positionCode,
                value: item.positionCode,
                children: this.generateTreeObject(item.descendantStructure)
            }
        })
    }

    generateReport = async () => {
        const { toastManager } = this.props;
        const { Month, UserTag, Year } = this.state.filter;
        try {
            this.setState({
                generating: true
            });
            await generateRouteWiseDailyReport({
                Month,
                Year,
                UserTag,
                CalData: "Yes",
            }, 'calculate');

            const response = await generateRouteWiseDailyReport({
                Month,
                Year,
                UserTag,
                GenReort: "Yes",
            }, 'generate');

            if (!response.error) {
                if (response.url !== "DBReports/null") {
                    setTimeout(() => {
                        this.setState({ generating: false });
                        window.open(Api.files.download.url(response.url), '_blank');
                    }, 5000);
                } else {
                    toastManager.add('Report details not found report. ', { autoDismiss: true, appearance: 'info' });
                    this.setState({ generating: false });
                }
            } else {
                toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                this.setState({ generating: false });
            }
        } catch (error) {
            console.log(error);
            toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
            this.setState({ generating: false });
        }
    }

    render() {
        const { Option } = Select;
        const { filter: { Month, Year, UserTag }, CSRUsers, loading, generating } = this.state;
        const { years, months } = this.props;

        return (
            <Spin spinning={loading} tip={"Loading Details"}>
                <div style={{ width: "100%", border: `1px solid ${ProMateGreen}`, height: "320px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ padding: "10px 0" }}>
                        <h3>
                            <b>Route Wise Daily Report</b>
                        </h3>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%", padding: "0px 15px" }}>
                        <div style={{ width: "100%" }}>
                            <Select
                                value={Year}
                                style={{ width: "100%" }}
                                onChange={(value) => this.handleSelectChange('Year', value)}
                            >
                                {years?.records.map((year, index) =>
                                    (<Option key={index + year.Code} value={year.Code} >{year.Description}</Option>)
                                )}
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Select
                                value={Month}
                                style={{ width: "100%" }}
                                onChange={(value) => this.handleSelectChange('Month', value)}
                            >
                                {months?.records.map((month, index) =>
                                    (<Option key={index + month.Code} value={month.Code} >{month.Description}</Option>)
                                )}
                            </Select>
                        </div>
                        <div>
                            <Select
                                showSearch
                                placeholder={'Select CSR User'}
                                style={{ width: '100%' }}
                                onChange={value => this.handleSelectChange('UserTag', value)}
                                value={UserTag}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {CSRUsers.map((user, index) =>
                                    <Option key={index} value={user.value}>{user.title}</Option>
                                )}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", bottom: 0, left: 0, right: 0 }}>
                            <Button style={{ marginBottom: 20 }} type='primary' onClick={() => this.generateReport()} disabled={generating}>
                                {generating ? 'Generating...' : 'Generate Report'}
                            </Button>
                        </Row>
                    </div>
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    const months = getAllMonths(state);
    const years = getAllYears(state);
    const authData = getAuthData(state);

    return { months, years, authData }
}

const mapDispatchToProps = {
    fetchAllMonths,
    fetchAllYears,
    fetchSalesUserHierarchy
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(RouteWiseDailyReport))