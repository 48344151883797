import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchPurchasingInvoiceHeaders } from "../../../redux/actions/purchasingInvoiceHeaders";
import { fetchPurchasingInvoiceLines } from "../../../redux/actions/purchasingInvoiceLines";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import InvoiceHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import InvoiceFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { getAuthData, getLocations, getPurchaseInvoiceLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser, getPurchasingInvoiceHeaderByDocNo } from '../../../redux/reducers';
import { generateGeneric } from '../../../api/reports';
import Api from '../../../api/consts';
import { PURCHASING_INVOICES } from "../../../const/Permissions";
import { fetchWarehouseLocations } from '../../../redux/actions/warehouses'
import { dateFormatView } from '../../../const/Formats';

export const defaultInvoice = {
  SessionID: uuid(),
  DocType: undefined,
  SellToCusNo: undefined,
  SellToCusName: undefined,
  BillToCusNo: undefined,
  BillToCusName: undefined,
  LocCode: undefined,
  OrderDate: undefined,
  Status: "Open",
  OrderStatus: undefined,
  PayMethodCode: undefined,
  PayTermCode: undefined,
  DistributorCode: undefined,
  MapLocation: undefined,
  OrderDate: moment(new Date()).format(dateFormatView),
  Amount: 0.0,
  AmountIncluVAT: 0.0,
  DisPersentage: 0.0,
  DisAmount: 0.0
}

class Container extends Component {
  state = {
    loading: false,
    invoice: { ...defaultInvoice },
    locCodeError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { invoiceNo, docType } = this.props.match.params
    const { distributorId } = this.props

    const filter = { UserCode: this.props.authData.userId };
    this.props.fetchWarehouseLocations(filter);

    this.setState({ loading: true });
    this.props.fetchPurchasingInvoiceLines({ DocNo: decodeURIComponent(invoiceNo) });
    this.props.fetchPurchasingInvoiceHeaders({ No: decodeURIComponent(invoiceNo) });
    this.setState({ loading: false });
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.invoice && !nextProps.invoice) {
      // The order is deleted.
      window.history.back();
    } else if (nextProps && nextProps.invoice) {
      this.setState({ invoice: { ...nextProps.invoice } });
    } else {
      // this.setData(this.props);
    }
  }

  validateForm = () => {
    const { invoice } = this.state;
    const { LocCode } = invoice;

    let locCodeError = undefined;

    if (!this.isValidValue(LocCode)) locCodeError = 'Location is required.'

    this.setState({ locCodeError })

    return !locCodeError;
  }

  isValidValue = value => value && value.trim().length > 0;

  shareOrder = () => {
    this.setState({ sharing: true });

    const { invoice, toastManager, match } = this.props;
    const { docType } = match.params;
    const reportRequest = {
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      No: invoice.No
    };

    generateGeneric(reportRequest, 'salesInvoice').then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  onConfirmApproved = () => {
    const { updateSalesHeader, toastManager, invoice, authData: { userId } } = this.props;
    const updateOrderData = { ...invoice, OrderStatus: '1', UserCode: userId };

    updateSalesHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Invoice generated.', { autoDismiss: true, appearance: 'success' });
        window.history.back();
      } else {
        toastManager.add('Could not generate the invoice. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onInputChange = (field, value, errorKey) => {
    const { invoice } = this.state;

    this.setState({
      invoice: { ...invoice, [field]: value },
      [errorKey]: undefined
    }, () => this.handleResetPayTermCode(field, value))
  }

  handleResetPayTermCode = (field, value) => {
    const { invoice } = this.state;

    if (field === 'PayMethodCode' && value === 'CASH') {
      this.setState({ invoice: { ...invoice, PayTermCode: undefined } })
    }
  }

  renderActions = () => {
    const { authData: { roleCode }, loggedInUser: { permissions = [] } } = this.props;
    const { invoice } = this.state;

    const enablePrint = permissions.includes(PURCHASING_INVOICES.PRINT)
    const enableMakePayment = permissions.includes(PURCHASING_INVOICES.MAKE_PAYMENT)
    const enableSetoff = permissions.includes(PURCHASING_INVOICES.SETOFF)
    const oustandingAvaialble = parseFloat(invoice.RemAmount) > 0 ? true : false

    return (<Row gutter={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {enableMakePayment && oustandingAvaialble && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={() => { this.props.history.push("/distributors/" + invoice.DistributorCode + "/receipts/new"); }}
          >Make Payment</Button>
        </Col>}
        {enableSetoff && oustandingAvaialble && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={() => { this.props.history.push("/distributors/" + invoice.DistributorCode + "/invoicesettlement"); }}
          >Set off</Button>
        </Col>}
        {enablePrint && <Col>
          <Button
            loading={this.state.sharing}
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={this.shareOrder}>Print & Share</Button>
        </Col>}
      </Row>
    </Row >
    )
  }

  handleDataAllocated = () => {
    const { invoiceNo, docType } = this.props.match.params
    this.setState({ loading: true })
    this.props.fetchPurchasingInvoiceLines({ DocNo: decodeURIComponent(invoiceNo), DocType: docType });
    this.props.fetchPurchasingInvoiceHeaders({ No: decodeURIComponent(invoiceNo), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] }).then(({ data, error }) => {
      this.setState({ loading: false })
    });
  }

  render() {
    const { invoiceHeader, invoiceLines, match, distributorId, distributors, loggedInUser: { permissions = [] } } = this.props;
    const { invoiceId } = match.params
    const { invoice, loading, locCodeError } = this.state;
    const enableDeallocate = permissions.includes(PURCHASING_INVOICES.DEALLOCATE);

    if (!invoice) return null;


    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <InvoiceHeader
            distributorId={distributorId}
            invoiceHeader={invoiceHeader}
            invoiceLines={invoiceLines}
            distributors={distributors}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirmOrder}
            onInputChange={this.onInputChange}
            error={{ locCodeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToInventoryItemListView}
                invoice={invoice} />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToInventoryItemListView}
                invoice={invoice} />
            </Col>
          </div>
          <InvoiceFooter invoice={invoiceHeader} onInputChange={this.onInputChange} enableDeallocate={enableDeallocate} onDataAllocated={this.handleDataAllocated} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { invoiceNo, distributorId } = ownProps.match.params
  console.log("PARAMS", ownProps.match.params);
  const decodeId = decodeURIComponent(invoiceNo);
  const invoice = getPurchasingInvoiceHeaderByDocNo(state, decodeId) ? getPurchasingInvoiceHeaderByDocNo(state, decodeId) : {};

  return {
    invoiceHeader: invoice,
    authData: getAuthData(state),
    locations: getLocations(state),
    invoiceLines: getPurchaseInvoiceLinesByDocTypeAndDocNo(state, "", decodeId),
    loggedInUser: getLoggedInUser(state),
    distributorId: distributorId
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchPurchasingInvoiceHeaders,
  fetchPurchasingInvoiceLines,
  fetchWarehouseLocations
})(Container)));