import React, { Component } from 'react';
import { Table } from "antd";
import { format } from "currency-formatter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTableMobile extends Component {
  getColumnsDefault = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderLeftColumn(item)
      },
      {
        title: '',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '40%',
        render: (Description, item) => this.renderRightColumn(item)
      }
    ];

  getColumnsCreditNotes = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        render: (Description, item) => (item.LotNo ? item.LotNo + ' - ' : '') + Description
      },
      {
        title: 'Amount',
        dataIndex: 'AmountIncluVAT',
        key: 'AmountIncluVAT',
        align: 'right',
        width: 100,
        render: AmountIncluVAT => format(AmountIncluVAT, currencyFormat)
      }
    ];


  getColumns = () => {
    if (this.props.order.DocType === 'RETURN ORDER') {
      return this.getColumnsDefault();
    } else {
      return this.getColumnsCreditNotes();
    }
  }

  renderLeftColumn = item => {
    return (
      <div>
        <div>{item.LotNo}</div>
        <div>{item.Description}</div>
      </div>
    )
  }

  renderRightColumn = item => {
    return (
      <div>
        <div>{item.Quantity}</div>
        <div>{format(item.UnitPrice, currencyFormat)}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 7 }}>{format(item.DisPercentage, currencyFormat)}%</div>
          <div style={{ fontWeight: 'bold' }}>{format(item.DisAmount, currencyFormat)}</div>
        </div>
        <div style={{ fontWeight: 'bold' }}>{format(item.AmountIncluVAT, currencyFormat)}</div>
      </div>
    )
  }

  renderFooter = () => {
    const { salesLines = [] } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {salesLines.length}</p>
      </div>
    )
  }

  render() {
    const { salesLines = [], match } = this.props;
    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={salesLines}
          size={'small'}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

export default ItemTableMobile;