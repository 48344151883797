import React, { Component } from 'react';
import { PageHeader } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class OrderScreen extends Component {

  render() {
    const { match } = this.props;
    const { docType } = match.params
    let title = 'Order';
    switch (docType) {
      case 'Invoice':
        title = 'Sales Invoice';
        break;
      case 'ReturnOrder':
        title = 'Sales Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Sales Order';
        break;
    }

    return (
      <div>
        <PromatePageHeader
          title={'Confirm ' + title}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Container />
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default OrderScreen;