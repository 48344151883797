import * as fromApi from '../../api/generic';

export const FETCH_PURCHASE_CREDIT_INVOICE_LINES = 'FETCH_PURCHASE_CREDIT_INVOICE_LINES';
// export const FETCH_POSTED_CREDIT_NOTE_LINE = 'FETCH_POSTED_CREDIT_NOTE_LINE';
// export const FAILED_TO_ADD_POSTED_CREDIT_NOTE_LINE = 'FAILED_TO_ADD_POSTED_CREDIT_NOTE_LINE';
// export const REMOVE_POSTED_CREDIT_NOTE_LINE = 'REMOVE_POSTED_CREDIT_NOTE_LINE';

export const fetchLines = filter => dispatch => {
  return fromApi.get('PurchaseCrMemoLine', filter).then(response => {
    const { data, error } = response;

    if (error) return { error };

    const lines = data.map(item => ({ ...item, added: true }))
    dispatch({ type: FETCH_PURCHASE_CREDIT_INVOICE_LINES, lines });

    return response;
  }).catch(error => {
    return { error }
  })
};