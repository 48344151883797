import numeral from 'numeral';
import CryptoJS from 'crypto-js';
import queryString from 'query-string';
import dotenv from 'dotenv';
import moment from 'moment';
dotenv.config();

export const withThousandSeperator = (value) => {
    return numeral(Number(value)).format('0,0.00');
}

export const withOutThousandSeperator = (value) => {
    return numeral(value).value();
}

export const encrypt = (details) => {
    const secret = process.env.REACT_APP_CRYPTO_SECRET;
    return CryptoJS.AES.encrypt(JSON.stringify(details), secret).toString();
}

export const decrypt = (ciphertext) => {
    const secret = process.env.REACT_APP_CRYPTO_SECRET;
    const bytes = CryptoJS.AES.decrypt(ciphertext, secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const addURLParams = (details = {}, props) => {
    return new Promise((resolve, reject) => {
        const encryptData = encrypt(details);
        if (window.location.pathname === props.location.pathname) {
            props.history.replace(props.location.pathname + '?' + "filter=" + encodeURIComponent(encryptData));
        }
        resolve(true);
    });
}

export const getURLParams = (props) => {
    return new Promise((resolve, reject) => {
        try {
            const params = queryString.parse(props.location.search);
            if ("filter" in params) {
                const decordedParams = decodeURIComponent(params.filter);
                resolve(decrypt(decordedParams))
            } else {
                resolve(null)
            }
        } catch (error) {
            resolve(null)
        }
    });
}

export const updateURLParams = (details = {}, props) => {
    return new Promise(async (resolve, reject) => {
        const params = await getURLParams(props);
        if (params) {
            const updatedParams = { ...params, ...details };
            await addURLParams(updatedParams, props);
            resolve(updatedParams)
        } else {
            await addURLParams(details, props);
            resolve(details)
        }
    });
}

export const sortDates = (dates = [], format = "MM/DD/YY") => {
    let momentDatesArray = dates.map((date) => {
        return moment(date, format);
    });
    const sortedMomentDatesArray = momentDatesArray.sort((a, b) => {
        return moment(a).diff(b);
    });
    const sortedDatesArray = sortedMomentDatesArray.map(date => {
        return date.format(format)
    })
    return sortedDatesArray;
}

export const hasDecimalValue = (n) => {
    var result = (n - Math.floor(n)) !== 0;
    if (result)
        return true;
    else
        return false;
}

export const trimDecimals = (value) => {
    const number = withOutThousandSeperator(value);
    if (hasDecimalValue(number)) {
        return numeral(Number(number)).format('0,0');
    } else {
        return numeral(Number(number)).format('0,0');
    }
}
const crypto = require('crypto');
export const encryptValue = (text, key) => {
    const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), Buffer.from('16charlongvector'));
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('hex');
}

export const decryptValue = (encryptedText) => {
    const encryptionKey = Buffer.from('userType'.padEnd(32, '0')); 
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(encryptionKey), Buffer.from('16charlongvector'));
    let decrypted = decipher.update(Buffer.from(encryptedText, 'hex'));
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }