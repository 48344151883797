import * as fromApiGeneric from '../../api/generic';
import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_DISTRIBUTORS = 'FETCH_DISTRIBUTORS';
export const FETCH_DISTRIBUTOR = 'FETCH_DISTRIBUTOR';
export const START_FETCH_DISTRIBUTORS = 'START_FETCH_DISTRIBUTORS';
export const FINISH_FETCH_DISTRIBUTORS = 'FINISH_FETCH_DISTRIBUTORS';
export const FETCH_MAP_DISTRIBUTORS = 'FETCH_MAP_DISTRIBUTORS';
export const START_FETCH_MAP_DISTRIBUTORS = 'START_FETCH_MAP_DISTRIBUTORS';
export const FINISH_FETCH_MAP_DISTRIBUTORS = 'FINISH_FETCH_MAP_DISTRIBUTORS';
export const SET_ACTIVE_SEARCH_KEY = 'SET_ACTIVE_SEARCH_KEY';

let lastSearchKey = undefined;
export const fetchDistributors = (filter, page, searchKey = '') => async dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: SET_ACTIVE_SEARCH_KEY, searchKey });
  dispatch({ type: START_FETCH_DISTRIBUTORS });
  dispatch({ type: START_FETCH_MAP_DISTRIBUTORS });
  setTimeout(() => fetchMapDistributors(filter, dispatch), 100);

  if (getOfflineData('state').isSystemOffline) {
    var { data, error, recordTotal } = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.DISTRIBUTORS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    if (data && data.length === 1) dispatch({ type: FETCH_DISTRIBUTOR, distributor: data[0], filter });

    dispatch({ type: FETCH_DISTRIBUTORS, distributors: data, total: recordTotal ? recordTotal : 0, searchKey, filter });

    if (lastSearchKey === searchKey) {
      dispatch({ type: FINISH_FETCH_MAP_DISTRIBUTORS });
      dispatch({ type: FINISH_FETCH_DISTRIBUTORS });
    }

  } else {

    return fromApiGeneric.get('Distributor', filter, page).then(response => {

      if (getOfflineData('state').isSystemOffline) {
        var { data, error, recordTotal } = getOfflineData('distributors');
      } else {
        var { data, error, recordTotal } = response;
      }

      if (error) return { error }

      if (data && data.length === 1) dispatch({ type: FETCH_DISTRIBUTOR, distributor: data[0], filter });

      dispatch({ type: FETCH_DISTRIBUTORS, distributors: data, total: recordTotal ? recordTotal : 0, searchKey, filter });

      return response;

    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey) {
        dispatch({ type: FINISH_FETCH_MAP_DISTRIBUTORS });
        dispatch({ type: FINISH_FETCH_DISTRIBUTORS });
      }
    })
  }

}

// I have change GetDistributorsmap endpoint to Distributors for apply  filters for map
const fetchMapDistributors = async (filter, dispatch) => {
  dispatch({ type: START_FETCH_MAP_DISTRIBUTORS });
  return fromApiGeneric.get('Distributor', filter).then(response => {
    const { data, error, recordTotal } = response;

    if (error) return { error }

    dispatch({ type: FETCH_MAP_DISTRIBUTORS, distributors: data });

    return response;

  }).catch(error => {
    return { error }
  }).finally(() => {
    dispatch({ type: FINISH_FETCH_MAP_DISTRIBUTORS });
  })

}

export const fetchDistributor = distributorId => dispatch => {
  return fromApiGeneric.get('Distributor', { Code: distributorId }).then(response => {
    const { data, error } = response;

    if (error) return { error }

    if (data.length > 0) dispatch({ type: FETCH_DISTRIBUTOR, distributor: data[0] });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    dispatch({ type: FINISH_FETCH_DISTRIBUTORS });
  })
};