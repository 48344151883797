import React, { Component } from 'react';
import { PageHeader } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import { withRouter } from 'react-router-dom';
import PromatePageHeader from '../../common/PromatePageHeader';
import ItemInfoContainer from './ItemInfoContainer';
import CreditNoteCardHeader from '../../SalesOrders/CreditNoteCardHeader';
import CreditNoteItemContainer from './CreditNoteItemContainer';

class ItemView extends Component {
  renderContainer = () => {
    const { docType, lineId } = this.props.match.params;
    if (docType === 'ReturnOrder') {
      return lineId === 'new' ? <ItemInfoContainer /> : <Container />;
    } else if (docType === 'CreditMemo') {
      return <CreditNoteItemContainer />;
    } else {
      return <Container />;
    }
  }

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Item Info'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          {this.renderContainer()}
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default withRouter(ItemView);