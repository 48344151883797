import React, { Component } from "react";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { dateFormatView } from "../../const/Formats";

class OrderCard extends Component {
	getStatusColor = status => {
		switch (status) {
			case 'Open':
				return 'green';
			default:
				return 'orange';
		}
	}

	render() {
		const { order, history } = this.props;
		const { orderNo, orderDate, sellToCustomerCode, sellToCustomerName, status, amountIncludingVat } = order;
		return (
			<Row onClick={() => history.push("/orders/" + orderNo)}>
				<Row style={styles.alignRow}>
					<Col xs={12} sm={6} md={3} lg={3} style={{ textAlign: 'left' }}>
						<span style={styles.orderNo}>#{orderNo ? orderNo.replace(/---/g, '/') : ''}</span>
					</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
						<Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
					</Col>
					<Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
						<span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
					</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{sellToCustomerCode}</p>
					</Col>
					<Col xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'left' }}>
						<span>{sellToCustomerName}</span>
					</Col>
					<Col xs={12} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
						<CurrencyFormat
							value={amountIncludingVat || 0}
							displayType={'text'}
							thousandSeparator={true}
							isNumericString={true}
							fixedDecimalScale={true}
							style={styles.price}
							decimalScale={2} />
					</Col>
				</Row>
				<Row style={styles.lowerRow}>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{sellToCustomerCode}</p>
					</Col>
				</Row>
				<Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
					</Col>
				</Row>
			</Row>
		)
	}
}

const styles = {
	orderNo: {
		fontSize: 16,
		fontWeight: 600
	},
	alignRow: {
		display: 'flex',
		alignItems: 'center'
	},
	orderDate: {
		fontSize: 12,
		fontWeight: 700
	},
	customerCode: {
		fontSize: 10,
		fontWeight: 700,
	},
	customerCodeBottom: {
		fontSize: 10,
		fontWeight: 700,
	},
	status: {
		fontSize: 10,
		fontWeight: 700,
		marginRight: 0
	},
	price: {
		fontSize: 18,
		fontWeight: 600,
	},
	icon: {
		fontSize: 10,
		marginRight: 5
	},
	lowerRow: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 5,
		marginTop: 2
	},
	bottomColumnStyles: {
		textAlign: 'left',
		color: '#808080',
		fontSize: 12
	}

}
export default withRouter(OrderCard);