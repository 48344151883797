import React, { Component } from 'react';
import { Col } from "antd";
import { ProMateGreen } from "../../const/Theme";
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import PromatePageHeader from '../common/PromatePageHeader';

class Stock extends Component {

  render() {
    const { location = {} } = this.props
    const { state = {} } = location

    return (
      <div>
        <PromatePageHeader
          title={'Transfer Items'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Col xs={0} sm={0} md={24} lg={24}>
            <ItemTable
              multiSelect={false}
              header={state.transfer}
            />
          </Col>
          <Col xs={24} sm={24} md={0} lg={0}>
            <ItemTableMobile
              multiSelect={false}
              header={state.transfer}
            />
          </Col>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default Stock;