import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchSalesCreditMemoReasons } from "../../../redux/actions/salesHeaders";
import { fetchPostedCreditNotes } from "../../../redux/actions/postedCreditNotes";
import { fetchLines } from "../../../redux/actions/postedCreditNoteLines";
import { fetchInventory } from "../../../redux/actions/inventory";
import { fetchCustomers } from "../../../redux/actions/customers";
import { Row, Spin, Col, Button } from "antd";
import OrderHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener } from '../../../utils/Location';
import { getAuthData, getPostedCreditNotesByTypeAndNo, getCustomer, getLocations, getSalesLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser, getPostedCreditNotesByNo, getPostedCreditNoteLinesByDocNo } from '../../../redux/reducers';
import { generateGeneric } from '../../../api/reports';
import Api from '../../../api/consts';
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchWarehouseLocations } from '../../../redux/actions/warehouses';
import { CREDIT_NOTES, CUSTOMER_CREDIT_INVOICES, SALES_RETURNS, SALES_RETURN_INVOICES } from '../../../const/Permissions';
import { dateFormatView } from '../../../const/Formats';

export const defaultOrder = {
  SessionID: uuid(),
  DocType: undefined,
  SellToCusNo: undefined,
  SellToCusName: undefined,
  BillToCusNo: undefined,
  BillToCusName: undefined,
  LocCode: undefined,
  Status: "Open",
  OrderStatus: undefined,
  PayMethodCode: undefined,
  PayTermCode: undefined,
  SOType: undefined,
  DiscountReasonCode: undefined,
  DistributorCode: undefined,
  MapLocation: undefined,
  PostingDate: moment(new Date()).format(dateFormatView),
  Amount: 0.0,
  AmountIncluVAT: 0.0,
  DisPersentage: 0.0,
  DisAmount: 0.0
}

const toastMessage = {

}

class Container extends Component {
  state = {
    loading: false,
    order: { ...defaultOrder },
    locCodeError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { orderId, docType } = this.props.match.params
    const { customerId } = this.props
    const newOrder = orderId === 'new';
    //console.log(docType)

    this.props.fetchWarehouseLocations({ UserCode: this.props.authData.userId });
    this.props.fetchSalesCreditMemoReasons();

    if (!newOrder) {
      this.setState({ loading: true });
      this.props.fetchLines({ DocNo: decodeURIComponent(orderId) });
      this.props.fetchPostedCreditNotes({ No: decodeURIComponent(orderId), DocType: docType === 'ReturnOrder' ? 'RETURN ORDER' : 'CREDIT MEMO' }).then(({ data, error }) => {
        this.setState({ loading: false });
      })
    } else {
      this.setData(this.props);
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.order && !nextProps.order) {
      // The order is deleted.
      window.history.back();
    } else if (nextProps && nextProps.order) {
      this.setState({ order: { ...nextProps.order } });
    } else {
      this.setData(this.props);
    }
  }

  setData = data => {
    const { customer } = data;

    if (!customer) return;

    this.setState({
      order: {
        ...this.state.order,
        SellToCusNo: customer.No,
        SellToCusName: customer.Name + ' ' + customer.Name2
      }
    }, this.setDeviceMapLocation)
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  shareOrder = () => {
    this.setState({ sharing: true });

    const { toastManager, match } = this.props;
    const { docType, orderId } = match.params;
    const reportRequest = {
      No: decodeURIComponent(orderId)
    };

    generateGeneric(reportRequest, docType === 'ReturnOrder' ? 'salesCreditMemo' : 'creditNote').then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  getPermission = (docType, type, permissions) => {
    //console.log(" doc type", docType)
    let enabled = false;
    if (type === 'PRINT') {
      if (docType === 'CreditMemo') {
        enabled = permissions.includes(CUSTOMER_CREDIT_INVOICES.PRINT)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURN_INVOICES.PRINT)
      }
    } else if (type === 'SETOFF') {
      if (docType === 'CreditMemo') {
        enabled = permissions.includes(CUSTOMER_CREDIT_INVOICES.SETOFF)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURN_INVOICES.SETOFF)
      }
    }
    return enabled;
  }

  handleNavigateToSetOff = () => {
    const { match, history, order } = this.props;
    history.push('/customers/' + encodeURIComponent(order.SellToCusNo) + match.url + '/setoff');
  }

  handleDataAllocated = () => {
    const { orderId, docType } = this.props.match.params
    this.setState({ loading: true });
    this.props.fetchLines({ DocNo: decodeURIComponent(orderId) });
    this.props.fetchPostedCreditNotes({ No: decodeURIComponent(orderId), DocType: 'RETURN ORDER' }).then(({ data, error }) => {
      this.setState({ loading: false });
    })

  }

  renderActions = () => {
    const { match, loggedInUser: { permissions = [] } } = this.props;
    const { docType } = this.props.match.params;

    const enablePrintAndShare = this.getPermission(docType, 'PRINT', permissions);
    const enableSetoff = this.getPermission(docType, "SETOFF", permissions)

    return <Row gutter={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {enablePrintAndShare && <Col>
          <Button
            loading={this.state.sharing}
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={this.shareOrder}
          >Print & Share</Button>
        </Col>}

        {enableSetoff && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={this.handleNavigateToSetOff}
          >Setoff </Button>
        </Col>}
      </Row>
    </Row >
  }

  render() {
    const { customers, salesLines, customerId, loggedInUser: { permissions = [] } } = this.props;
    const { loading, order, locCodeError } = this.state;
    const enableDeallocate = permissions.includes(CUSTOMER_CREDIT_INVOICES.DEALLOCATE);
    //console.log(enableDeallocate)
    if (!order) return null;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <OrderHeader
            customerId={customerId}
            order={order}
            salesLines={salesLines}
            customers={customers}
            onSubmitOrder={this.submitOrder}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirmOrder}
            onDeleteOrder={this.onDeleteOrder}
            onInputChange={this.onInputChange}
            error={{ locCodeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToInventoryItemListView}
                order={order}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToInventoryItemListView}
                order={order}
              />
            </Col>
          </div>
          <OrderFooter order={order} onInputChange={this.onInputChange} enableDeallocate={enableDeallocate} onDataAllocated={this.handleDataAllocated} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId, customerId } = ownProps.match.params
  const newOrder = orderId === 'new';
  const decodeId = decodeURIComponent(orderId);
  const order = getPostedCreditNotesByNo(state, decodeId);
  const selToCust = order ? order.SellToCusNo : undefined;
  const custId = newOrder ? customerId : selToCust;
  const customer = getCustomer(state, custId);

  return {
    order,
    authData: getAuthData(state),
    locations: getLocations(state),
    customer,
    salesLines: getPostedCreditNoteLinesByDocNo(state, decodeId),
    loggedInUser: getLoggedInUser(state),
    customerId: custId,
    location: getLocations(state).filter(location => order && location.LocCode === order.LocCode && location.WarehouseOwner === 'Yes')[0]
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchCustomers,
  fetchInventory,
  fetchPostedCreditNotes,
  fetchLines,
  fetchWarehouseLocations,
  fetchSalesCreditMemoReasons
})(Container)));