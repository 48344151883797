import moment from 'moment';
import { OBJECT_KEYS, TABLES } from '../../const/IndexDBObjects';
import { insertDataIndexDB } from '../../indexDB';
import { getDetailsFromIndexDB } from '../common/FileExport';

const serverError = "An error occured while connecting to the server.";
var logData = { 0: {} };

const setErrorLog = (retriesCount, key, value) => {
    if (retriesCount in logData) {
        logData = { ...logData, [retriesCount]: { ...logData[retriesCount], [key]: value, timestamp: moment().format('YYYY/MM/DD hh:mm A') } };
    } else {
        logData = { ...logData, [retriesCount]: { [key]: value, timestamp: moment().format('YYYY/MM/DD hh:mm A') } }
    }
}

export const sync = async (props) => {
    let trycount = 0;
    logData = { 0: {} };

    const {
        data,
        authData,
        api: {
            startCall,
            endCall,
            createCustomer,
            createPaymentMethod,
            createPaymentTerm,
            createSalesHeader,
            createSalesLine,
            uploadFile,
            addSalesAttachment,
            confirmSalesHeader,
        },
        onSuccess,
        onCatch,
        onError,
    } = props;

    base:
    for (let retryCount = 0; retryCount < 4; retryCount++) {
        try {
            let isSyncSucceed = true;

            const { offlineCallsResults,
                customers,
                salesHeaders,
                salesLines,
                images,
                salesReturnHeaders,
                salesReturnLines,
                salesReturnSignatures } = data ? data : await getDetailsFromIndexDB();

            let offlineCalls = offlineCallsResults ? offlineCallsResults.records : [];

            let offlineCustomers = customers.data.filter(function (customer) {
                return customer.callId != null;
            });

            let salesOrderCalls = offlineCalls.filter(function (el) {
                return el.StartReasonCode === "CS-0001" && (el.SyncStart == false || el.SyncEnd == false);
            });

            let customerCreationCalls = offlineCalls.filter(function (el) {
                return el.StartReasonCode === "CS-0002";
            });

            // Get Sales Return Calls
            let salesReturnOrderCalls = offlineCalls.filter(function (el) {
                return el.StartReasonCode === "CS-0005" && (el.SyncStart == false || el.SyncEnd == false);
            });

            createCustomers:
            for (let i = 0; i < customerCreationCalls.length; i++) {
                let customerCreationCall = customerCreationCalls[i];

                let offlineCustomersForThisCall = offlineCustomers.filter(function (offlineCustomer) {
                    return offlineCustomer.callId === customerCreationCall.CallNo;
                });

                if (!customerCreationCall.SyncStart) {
                    let startCallResponse = await startCall(customerCreationCall);

                    if (startCallResponse.error) {
                        setErrorLog(retryCount, 'SyncCustomerCreationStartCall', { status: false, data: customerCreationCall, message: String(startCallResponse.error) });
                    }

                    if (startCallResponse.error === serverError) {
                        console.log("Stop Start Call");
                        isSyncSucceed = false;
                        break createCustomers;
                    }
                }

                for (let j = 0; j < offlineCustomersForThisCall.length; j++) {
                    let offlineCustomerForThisCall = offlineCustomersForThisCall[j];

                    if (!offlineCustomerForThisCall.Sync) {
                        let newCustomer = await createCustomer(offlineCustomerForThisCall);

                        if (newCustomer.error) {
                            setErrorLog(retryCount, 'SyncCreateCustomers', { status: false, data: offlineCustomerForThisCall, message: String(newCustomer.error) });
                        }

                        if (newCustomer.error == serverError) {
                            console.log("Stop Customer");
                            isSyncSucceed = false;
                            break createCustomers;
                        } else {
                            //set new customer No to order creation call and sales header
                            salesOrderCalls.filter(function (salesOrderCall) {
                                if (salesOrderCall.CustNo == offlineCustomerForThisCall.NoCus) {
                                    salesOrderCall.CustNo = newCustomer.No;
                                }
                                return salesOrderCall;
                            });
                            salesHeaders.data.filter(function (salesHeader) {
                                if (salesHeader.SellToCusNo == offlineCustomerForThisCall.NoCus) {
                                    salesHeader.BillToCusNo = newCustomer.No;
                                    salesHeader.SellToCusNo = newCustomer.No;
                                }
                                return salesHeader;
                            });
                        }
                    }

                    let customerOfflinePaymentMethods = offlineCustomerForThisCall.paymentMethods.data;
                    for (let k = 0; k < customerOfflinePaymentMethods.length; k++) {
                        let paymentMethod = customerOfflinePaymentMethods[k];

                        paymentMethod.CustNo = offlineCustomerForThisCall.No;

                        if (!paymentMethod.Sync) {
                            let paymentMethodResponse = await createPaymentMethod(paymentMethod);

                            if (paymentMethodResponse.error) {
                                setErrorLog(retryCount, 'SyncCreatePaymentMethod', { status: false, data: paymentMethod, message: String(paymentMethodResponse.error) });
                            }

                            if (paymentMethodResponse.error === serverError) {
                                console.log("Stop Payment Method");
                                isSyncSucceed = false;
                                break createCustomers;
                            }
                        }
                    }

                    let customerOfflinePaymentTerms = offlineCustomerForThisCall.paymentTerms.data;
                    for (let m = 0; m < customerOfflinePaymentTerms.length; m++) {
                        let paymentTerm = customerOfflinePaymentTerms[m];

                        paymentTerm.CustNo = offlineCustomerForThisCall.No;

                        if (!paymentTerm.Sync) {
                            let paymentTermResponse = await createPaymentTerm(paymentTerm);

                            if (paymentTermResponse.error) {
                                setErrorLog(retryCount, 'SyncCreatePaymentTerm', { status: false, data: paymentTerm, message: String(paymentTermResponse.error) });
                            }

                            if (paymentTermResponse.error === serverError) {
                                console.log("Stop Payment Terms");
                                isSyncSucceed = false;
                                break createCustomers;
                            }
                        }
                    }
                }

                if (!customerCreationCall.SyncEnd) {
                    let endCallResponse = await endCall(customerCreationCall);
                    console.log('endCallResponse');
                    console.log(endCallResponse);

                    if (endCallResponse.error) {
                        setErrorLog(retryCount, 'SyncCustomerCreationEndCall', { status: false, data: customerCreationCall, message: String(endCallResponse.error) });
                    }

                    if (endCallResponse.error === serverError) {
                        console.log("Stop End Call");
                        break createCustomers;
                    }
                }

            }

            if (isSyncSucceed == true) {
                console.log('salesHeaders');
                console.log(salesHeaders);

                console.log('salesOrderCalls');
                console.log(salesOrderCalls);

                // return false;

                createSalesOrders:
                //sync salesorders
                for (let i = 0; i < salesOrderCalls.length; i++) {
                    console.log('start sync salesorder data');
                    let salesOrderCall = salesOrderCalls[i];

                    let filteredSalesHeaders = salesHeaders.data.filter(function (el) {
                        return el.ActiveCallNo === salesOrderCall.CallNo;
                    });

                    //sync salesorder calls
                    if (!salesOrderCall.SyncStart) {
                        console.log('start call sync');
                        let startCallResponse = await startCall(salesOrderCall);

                        if (startCallResponse.error) {
                            setErrorLog(retryCount, 'SyncSalesOrderStartCall', { status: false, data: salesOrderCall, message: String(startCallResponse.error) });
                        }

                        if (startCallResponse.error === serverError) {
                            console.log("Stop Start Call");
                            isSyncSucceed = false;
                            break createSalesOrders;
                        } else {
                            salesOrderCall.No = startCallResponse.No;
                        }
                    }

                    //push salesheaders
                    console.log('start sync salesheaders');
                    for (let k = 0; k < filteredSalesHeaders.length; k++) {
                        let filterSalesHeader = filteredSalesHeaders[k];

                        if (!filterSalesHeader.Sync) {
                            console.log('sync salesheader');
                            let salesHeaderResults = await createSalesHeader(filterSalesHeader);

                            if (salesHeaderResults.error) {
                                setErrorLog(retryCount, 'SyncSalesOrderHeader', { status: false, data: filterSalesHeader, message: String(salesHeaderResults.error) });
                            }

                            if (salesHeaderResults.error === serverError) {
                                console.log("Stop Sales Header");
                                isSyncSucceed = false;
                                break createSalesOrders;
                            }
                        }

                        //get sales lines of given salesheader
                        let filteredSalesLines = salesLines.data.records.filter(function (el) {
                            return el.DocNo === filterSalesHeader.No && !el.Sync;
                        });

                        //sync sales lines
                        console.log('start sync saleslines');
                        for (let j = 0; j < filteredSalesLines.length; j++) {
                            let filterSalesLine = filteredSalesLines[j];
                            let lineNo = filterSalesLine.LineNo;
                            let docNo = filterSalesLine.DocNo;
                            filterSalesLine.LineNo = "";
                            if (filterSalesHeader.Sync) {
                                filterSalesLine.DocNo = filterSalesHeader.OnlineNo;
                            }

                            if (!filterSalesLine.Sync) {
                                console.log('sync salesline ' + filterSalesLine.DocNo);
                                let salesLineResults = await createSalesLine(filterSalesLine);
                                filterSalesLine.LineNo = lineNo;
                                filterSalesLine.DocNo = docNo;
                                console.log(filterSalesLine)

                                if (salesLineResults.error) {
                                    setErrorLog(retryCount, 'SyncSalesOrderLine', { status: false, data: filterSalesLine, message: String(salesLineResults.error) });
                                }

                                if (salesLineResults.error === serverError) {
                                    console.log("Stop Sales Lines");
                                    isSyncSucceed = false;
                                    break createSalesOrders;
                                }
                            }
                        }

                        //upload images and confirm sales order
                        if (!filterSalesHeader.SyncConfirm && typeof images != "undefined") {
                            console.log('sync signature');

                            let signature = images.data.filter(function (el) {
                                return el.orderId === filterSalesHeader.No;
                            });

                            if (signature.length > 0) {

                                let offlineNo = filterSalesHeader.No;

                                filterSalesHeader.No = filterSalesHeader.OnlineNo;
                                if (filterSalesHeader.isSignatureUploaded != true) {
                                    let filUploadeResponse = await uploadFile(filterSalesHeader, signature[0].image);
                                    console.log('filUploadeResponse');
                                    console.log(filUploadeResponse);

                                    if (filUploadeResponse.error) {
                                        setErrorLog(retryCount, 'SyncSalesOrderSignature', { status: false, data: { filterSalesHeader, signature: signature[0].image }, message: String(filUploadeResponse.error) });
                                    }

                                    if (filUploadeResponse.error === serverError) {
                                        isSyncSucceed = false;
                                        filterSalesHeader.No = offlineNo;
                                        break createSalesOrders;
                                    } else {
                                        filterSalesHeader.UploadedImagePath = filUploadeResponse.path;
                                    }
                                }

                                if (filterSalesHeader.isSignatureAttached != true) {
                                    let salesAttachmentResponse = await addSalesAttachment(filterSalesHeader, authData);

                                    if (salesAttachmentResponse.error) {
                                        setErrorLog(retryCount, 'SyncSalesOrderAttachement', { status: false, data: { filterSalesHeader, authData: authData }, message: String(salesAttachmentResponse.error) });
                                    }

                                    console.log('salesAttachmentResponse');
                                    console.log(salesAttachmentResponse);
                                    if (salesAttachmentResponse.error === serverError) {
                                        isSyncSucceed = false;
                                        filterSalesHeader.No = offlineNo;
                                        break createSalesOrders;
                                    }
                                }

                                filterSalesHeader.Status = "Customer Confirmed"
                                filterSalesHeader.SignedBy = "Customer"
                                filterSalesHeader.OrderStatus = "2"

                                let salesHeaderConfirmResponse = await confirmSalesHeader(filterSalesHeader, signature[0].image, authData)

                                if (salesHeaderConfirmResponse.error) {
                                    setErrorLog(retryCount, 'SyncConfirmSalesOrderHeader', { status: false, data: { filterSalesHeader, signature: signature[0].image, authData: authData }, message: String(salesHeaderConfirmResponse.error) });
                                }

                                console.log('salesHeaderConfirmResponse');
                                console.log(salesHeaderConfirmResponse);
                                if (salesHeaderConfirmResponse.error === serverError) {
                                    isSyncSucceed = false;
                                    filterSalesHeader.Status = "Open";
                                    filterSalesHeader.OrderStatus = "Open";
                                    delete filterSalesHeader.SignedBy;
                                    break createSalesOrders;
                                }
                                filterSalesHeader.No = offlineNo;
                            }
                        }
                    }

                    // if (typeof startCallResponse != "undefined") {
                    //   salesOrderCall.No = startCallResponse.No;
                    // }

                    if (!salesOrderCall.SyncEnd) {
                        let endCallResponse = await endCall(salesOrderCall);

                        if (endCallResponse.error) {
                            setErrorLog(retryCount, 'SyncSalesOrderEndCall', { status: false, data: salesOrderCall, message: String(endCallResponse.error) });
                        }

                        console.log('endCallResponse');
                        console.log(endCallResponse);
                        if (endCallResponse.error === serverError) {
                            console.log("Stop End Call");
                            isSyncSucceed = false;
                            break createSalesOrders;
                        }
                    }
                }

                createSalesReturnOrders:
                // Sync Sales Return Orders
                for (let index = 0; index < salesReturnOrderCalls.length; index++) {
                    // console.log('start sync sales return order data');
                    let salesReturnOrderCall = salesReturnOrderCalls[index];

                    let filteredSalesReturnHeaders = salesReturnHeaders.data.filter((el) =>
                        el.ActiveCallNo === salesReturnOrderCall.CallNo
                    );

                    //Sync Sales Return Order Calls
                    if (!salesReturnOrderCall.SyncStart) {
                        // console.log('start sales return call sync');
                        let startCallResponse = await startCall(salesReturnOrderCall);

                        if (startCallResponse.error) {
                            setErrorLog(retryCount, 'SyncSalesReturnOrderStartCall', { status: false, data: salesReturnOrderCall, message: String(startCallResponse.error) });
                        }

                        if (startCallResponse.error === serverError) {
                            // console.log("stop sales return call sync");
                            isSyncSucceed = false;
                            break createSalesReturnOrders;
                        } else {
                            salesReturnOrderCall.No = startCallResponse.No;
                            // console.log("success sales return call sync, No: ", startCallResponse.No);
                        }
                    }

                    // Sync Sales Return Order Headers
                    // console.log('start sales return headers sync');
                    for (let index = 0; index < filteredSalesReturnHeaders.length; index++) {
                        let filteredSalesReturnHeader = filteredSalesReturnHeaders[index];
                        filteredSalesReturnHeader.DocType = "5";

                        if (!filteredSalesReturnHeader.Sync) {
                            // console.log('start sales return header sync');
                            let salesReturnHeaderResults = await createSalesHeader(filteredSalesReturnHeader);

                            if (salesReturnHeaderResults.error) {
                                setErrorLog(retryCount, 'SyncSalesReturnOrderHeader', { status: false, data: filteredSalesReturnHeader, message: String(salesReturnHeaderResults.error) });
                            }

                            if (salesReturnHeaderResults.error === serverError) {
                                // console.log("stop sales return header sync");
                                isSyncSucceed = false;
                                break createSalesReturnOrders;
                            } else {
                                // console.log("success sales return header sync, No: ", salesReturnHeaderResults.No);
                            }
                        }

                        // Get Sales Lines of Given Sales Header
                        let filteredSalesReturnLines = salesReturnLines.data.filter((el) => el.DocNo === filteredSalesReturnHeader.No && !el.Sync)

                        // Sync Sales Return Order Lines
                        // console.log('start sales return lines sync');
                        for (let index = 0; index < filteredSalesReturnLines.length; index++) {
                            let filteredSalesReturnLine = filteredSalesReturnLines[index];
                            filteredSalesReturnLine.DocType = "5";
                            let lineNo = filteredSalesReturnLine.LineNo;
                            let docNo = filteredSalesReturnLine.DocNo;
                            filteredSalesReturnLine.LineNo = "";
                            if (filteredSalesReturnHeader.Sync) {
                                filteredSalesReturnLine.DocNo = filteredSalesReturnHeader.OnlineNo;
                            }

                            if (!filteredSalesReturnLine.Sync) {
                                // console.log('start sales return line sync No: ', filteredSalesReturnLine.DocNo);
                                let salesReturnLineResults = await createSalesLine(filteredSalesReturnLine);
                                filteredSalesReturnLine.LineNo = lineNo;
                                filteredSalesReturnLine.DocNo = docNo;
                                // console.log('Final Sales Line: ', filteredSalesReturnLine);

                                if (salesReturnLineResults.error) {
                                    setErrorLog(retryCount, 'SyncSalesReturnOrderLine', { status: false, data: filteredSalesReturnLine, message: String(salesReturnLineResults.error) });
                                }

                                if (salesReturnLineResults.error === serverError) {
                                    // console.log("stop sales return line sync");
                                    isSyncSucceed = false;
                                    break createSalesReturnOrders;
                                } else {
                                    // console.log("success sales return line sync, No: ");
                                }
                            }
                        }

                        // Upload Signatures and Confirm Sales Order 
                        if (!filteredSalesReturnHeader.SyncConfirm && typeof salesReturnSignatures !== undefined) {
                            // console.log('start sales return signatures sync');

                            let signature = salesReturnSignatures.data.find((el) => el.orderId === filteredSalesReturnHeader.No);

                            if (signature) {
                                let offlineNo = filteredSalesReturnHeader.No;

                                filteredSalesReturnHeader.No = filteredSalesReturnHeader.OnlineNo;

                                if (filteredSalesReturnHeader.isSignatureUploaded !== true) {
                                    let fileUploadResponse = await uploadFile(filteredSalesReturnHeader, signature.image);
                                    // console.log('file Upload Response: ', fileUploadResponse);

                                    if (fileUploadResponse.error) {
                                        setErrorLog(retryCount, 'SyncSalesReturnOrderSignature', { status: false, data: { filteredSalesReturnHeader, signature: signature.image }, message: String(fileUploadResponse.error) });
                                    }

                                    if (fileUploadResponse.error === serverError) {
                                        // console.log('file Upload was failed');
                                        isSyncSucceed = false;
                                        filteredSalesReturnHeader.No = offlineNo;
                                        break createSalesReturnOrders;
                                    } else {
                                        // console.log('file Upload successfully');
                                        filteredSalesReturnHeader.UploadedImagePath = fileUploadResponse.path;
                                    }
                                }

                                if (filteredSalesReturnHeader.isSignatureAttached !== true) {
                                    let salesReturnAttachmentResponse = await addSalesAttachment({ ...filteredSalesReturnHeader, DocType: 'ReturnOrder' }, authData);
                                    // console.log('Sales Return Attachment Response: ', salesReturnAttachmentResponse);

                                    if (salesReturnAttachmentResponse.error) {
                                        setErrorLog(retryCount, 'SyncSalesReturnOrderAttachement', { status: false, data: { filteredSalesReturnHeader: { ...filteredSalesReturnHeader, DocType: 'ReturnOrder' }, authData: authData }, message: String(salesReturnAttachmentResponse.error) });
                                    }

                                    if (salesReturnAttachmentResponse.error === serverError) {
                                        // console.log('Sale Attachment was Failed');
                                        isSyncSucceed = false;
                                        filteredSalesReturnHeader.No = offlineNo;
                                        break createSalesReturnOrders;
                                    } else {
                                        // console.log('Sale Attachment Successfully');
                                    }
                                }

                                filteredSalesReturnHeader.Status = "Customer Confirmed";
                                filteredSalesReturnHeader.SignedBy = "Customer";
                                filteredSalesReturnHeader.OrderStatus = "2";

                                let salesReturnHeaderConfirmResponse = await confirmSalesHeader(filteredSalesReturnHeader, signature.image, authData);
                                // console.log('Sales Return Header Confirm Response: ', salesReturnHeaderConfirmResponse);

                                if (salesReturnHeaderConfirmResponse.error) {
                                    setErrorLog(retryCount, 'SyncSalesReturnOrderConfirmHeader', { status: false, data: { filteredSalesReturnHeader, signature: signature.image, authData: authData }, message: String(salesReturnHeaderConfirmResponse.error) });
                                }

                                if (salesReturnHeaderConfirmResponse.error === serverError) {
                                    isSyncSucceed = false;
                                    filteredSalesReturnHeader.Status = "Open";
                                    filteredSalesReturnHeader.OrderStatus = "Open";
                                    delete filteredSalesReturnHeader.SignedBy;
                                    break createSalesReturnOrders;
                                }
                                filteredSalesReturnHeader.No = offlineNo;
                            }
                        }
                    }

                    if (!salesReturnOrderCall.SyncEnd) {
                        let endCallResponse = await endCall(salesReturnOrderCall);

                        if (endCallResponse.error) {
                            setErrorLog(retryCount, 'SyncSalesReturnOrderEndCall', { status: false, data: salesReturnOrderCall, message: String(endCallResponse.error) });
                        }

                        // console.log('End Call Response: ', endCallResponse);
                        if (endCallResponse.error === serverError) {
                            // console.log("Stop End Call");
                            isSyncSucceed = false;
                            break createSalesReturnOrders;
                        }
                    }
                }
            }

            await insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA, offlineCallsResults);
            await insertDataIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA, customers);
            await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS, salesHeaders);
            await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE, salesLines);
            await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS, {
                data: salesReturnHeaders.data.map(el => {
                    el.DocType = "ReturnOrder";
                    if (el.OrderStatus === "2") {
                        el.OrderStatus = "Customer Confirmed";
                    }
                    return el;
                })
            });
            await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES, {
                data: salesReturnLines.data.map(el => {
                    el.DocType = "Return Order";
                    return el;
                })
            });

            if (isSyncSucceed == false) {
                await new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve();
                    }, 10000);
                });
                trycount++;
                continue base;
            } else {
                return onSuccess(logData);
            }

        } catch (error) {
            setErrorLog(retryCount, 'tryCatchError', { status: false, message: String(error) });
            return onCatch(error, logData);
        }
    }
    if (trycount == 4) {
        return onError(logData);
    }
}

