import React, { Component } from 'react';
import { Spin } from "antd";
import { fetchCustomers } from "../../../redux/actions/customers";
import { getCustomers } from "../../../redux/reducers";
import { connect } from "react-redux";
import SalesRepresentativeTable from "./SalesRepTable";
import { showLoadingMessage } from '../../common/Message';
import { generateData } from '../CustomerDemoData'
import { getAuthData } from '../../../redux/reducers';

class SalesRepresentativeTableContainer extends Component {
  state = {
    filter: {
      text: undefined
    },
    loading: false
  }

  componentDidMount() {
    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchCustomers().then(() => {
      this.setState({ loading: false })
      this.hideLoadingMessage();
    })
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Sales Representatives.');
  }

  getFilteredCustomers = () => {
    const { customers } = this.props;
    const { text } = this.state.filter;

    let filteredCustomers = customers;

    if (text) {
      filteredCustomers = customers.filter(customer => {
        const { id, name, address, contactNumber, status } = customer;
        const idFilter = this.stringCompare(id, text);
        const nameFilter = this.stringCompare(name, text);
        const addressFilter = this.stringCompare(address, text);
        const contactNoFilter = this.stringCompare(contactNumber, text);
        const statusFilter = this.stringCompare(status, text)

        return idFilter || nameFilter || addressFilter || contactNoFilter || statusFilter;
      })
    }

    return filteredCustomers;
  }

  getOutstandingTotals = customerList => {
    let totalOutstanding = 0
    customerList.forEach(customer => totalOutstanding = totalOutstanding + customer.outstanding)
    return totalOutstanding;
  }

  stringCompare = (string1, string2) => string1.toLowerCase().includes(string2.toLowerCase());

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  render() {
    const { loading } = this.state;
    const filteredCustomers = this.getFilteredCustomers();
    const outstandingTotal = this.getOutstandingTotals(filteredCustomers);

    return (
      <Spin spinning={loading && this.props.customers.length === 0} tip={"Loading Sales Representatives"}>
        <SalesRepresentativeTable
          outstandingTotal={outstandingTotal}
          handleInputTextChange={this.handleInputTextChange}
          customers={filteredCustomers}
          authData={this.props.authData}
        />
      </Spin>
    )
  }
}

const mapStateToProps = state => {
  //const customers = getCustomers(state);
  const customers = generateData();
  return { customers, authData: getAuthData(state) };
};


export default connect(mapStateToProps, { fetchCustomers })(SalesRepresentativeTableContainer);