import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchInvoice } from "../../../redux/actions/invoices";
import { getInvoice } from "../../../redux/reducers";
import { Row, Spin } from "antd";
import InvoiceHeader from './Header';
import InvoiceDetailTable from './SalesLineTable';
import InvoiceFooter from './Footer'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { currentLocation } from '../../../utils/Location';

export const defaultInvoice = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  orderDate: moment(),
  invoiceNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "PENDING"
}

class Container extends Component {
  state = {
    loading: false,
    invoice: { ...defaultInvoice }
  }

  componentDidMount() {
    const { invoiceNo } = this.props.match.params
    const newInvoice = invoiceNo === 'new';
    if (newInvoice) {
      const { location } = this.props;
      const { orderId } = this.props.match.params
      if (orderId) {
      } else {
        this.setState({ invoice: { ...defaultInvoice } })
      }
    } else {
      this.setState({ loading: true })
      this.props.fetchInvoice(invoiceNo).then(() => {
        this.setState({ loading: false })
      })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { invoice } = nextProps;
    if (invoice) {
      this.setState({ invoice: { ...invoice, status: invoice.status } })
    }
  }

  calculateTotalAmount = () => {
    const { invoice = {} } = this.props;
    const { salesLines = [] } = invoice;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  render() {
    const { match } = this.props;
    const { invoiceNo } = match.params
    const newInvoice = invoiceNo === 'new';
    const { loading, invoice } = this.state;

    if (!invoice) return null;

    return (
      <Spin spinning={loading} tip={"Loading invoice"}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <InvoiceHeader
            invoice={invoice}
            newInvoice={newInvoice} />
          <div style={{ padding: '0 12px' }}>
            <InvoiceDetailTable invoice={invoice} scrollHeight={this.getScrollAreaHeight()}
              OnClickAddItem={this.navigateToInventory} />
          </div>
          <InvoiceFooter invoice={invoice} />
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { invoiceNo } = ownProps.match.params

  return { invoice: getInvoice(state, invoiceNo) };
};

export default withRouter(connect(mapStateToProps, { fetchInvoice })(Container));