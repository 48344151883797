import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import SelectWarehouseLocation from "../../common/SelectWarehouseLocation";
import SelectVendorNo from "../../common/SelectVendorNo";
import { getPaymentTerms, getPurchaseReturnReasons, getTerritories } from '../../../redux/reducers';
import { withRouter } from "react-router-dom";
import { dateFormatView } from '../../../const/Formats';
import SelectTerritory from '../../common/SelectTerritory';
import SelectTerritoryView from '../../common/SelectTerritoryView';

const { Option } = Select;
class OrderHeader extends Component {

  getStatusColorDefault = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return 'orange';
    }
  }

  getStatusColor = (docType, status) => {
    return this.getStatusColorDefault(status);
  }

  renderReturnReasonSelect = () => {
    const { order, onInputChange, match, purchaseReturnReasons, error } = this.props;
    const { DocReasonCode } = order;
    const { docType } = match.params;

    if (docType !== 'ReturnOrder') return null;

    let title = '';
    let placeholder = '';
    let options;
    if (docType === 'ReturnOrder') {
      title = 'Return Type';
      placeholder = 'Select Return Type';
      options = purchaseReturnReasons;
    }

    return (
      <tr style={{ justifyContent: 'center' }}>
        <td style={{ width: 120 }}>{title}</td>
        <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
        <td>
          <Select
            placeholder={placeholder}
            value={(!DocReasonCode || (DocReasonCode && DocReasonCode === '')) ? undefined : DocReasonCode}
            style={{ width: 200 }}
            onChange={(value) => onInputChange("DocReasonCode", value, "returnReasonError")}
          //disabled={Status === "Confirmed"}
          >
            {options.map(option => <Option value={option.Code}>{option.Description}</Option>)}
          </Select>
          {error.returnReasonError && <p style={{ color: 'red', marginBottom: 5 }}>{error.returnReasonError}</p>}
        </td>
      </tr>
    );
  }

  renderDesktopHeader = () => {
    const { order, onInputChange, error, paymentTerms, match, purchaseReturnReasons, distributor, territories, newOrder } = this.props;
    const { DisPersentage,No , VendorNo, OrderDate, DocDate, OrderStatus, SellToCusName, SellToCusNo, PayMethodCode, LocCode, CreatedUserName, DocReasonCode, DistributorCode, DistributorName, TerritoryName } = order;
    const { docType } = match.params;
    let { TerritoryCode, PayTermCode } = order;

    //TerritoryCode = newOrder && distributor && territories.length === 1 ? territories[0].TerrCode : TerritoryCode;
    PayTermCode = newOrder && distributor ? distributor.PayTermCode : PayTermCode;

    const dbName = newOrder ? distributor ? distributor.Name : null : DistributorName;
    const dbCode = newOrder ? distributor ? distributor.Code : null : DistributorCode;

    const date = OrderDate;
    //console.log("date", newOrder, distributor, dbName)
    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
        <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Vendor</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectVendorNo
                disabled={No}
                type="purchasing_order"
                placeholder={'Select Vendor'}
                defaultValue={(!SellToCusNo || (SellToCusNo && SellToCusNo === '')) ? undefined : SellToCusNo}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.vendorNoError && <p style={{ color: 'red', marginBottom: 5 }}>{error.vendorNoError}</p>}
            </td>
          </tr>
          {No && <tr>
            <td style={{ width: 120 }}>No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {date && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(date).format(dateFormatView)}</td>
          </tr>}
          {OrderStatus && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(docType, OrderStatus)}>{OrderStatus}</Tag>}
            </td>
          </tr>}
          {CreatedUserName && <tr>
            <td>Created User</td>
            <td style={{ width: 25, textAlign: 'center' }}>:</td>
            <td>{CreatedUserName}</td>
          </tr>}
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={0} xs={0}>
          {dbCode && <tr >
            <td style={{ width: 120 }}>Distributor No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{dbCode}</td>
          </tr>}
          {dbName && <tr>
            <td style={{ width: 120 }}>Distributor Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 350 }}>{dbName}</td>
          </tr>}

          <tr>
            <td style={{ width: 120 }}>Territory</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectTerritoryView
                disabled={No}
                value={(!TerritoryCode || (TerritoryCode && TerritoryCode === '')) ? undefined : TerritoryCode}
                onChange={(filter, value) => onInputChange(filter, value, "territoryCodeError")}
              />
              {error.territoryCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.territoryCodeError}</p>}
            </td>
          </tr>

        </Col>
        {/* For mobile view */}
        <Col lg={0} md={0} sm={24} xs={24}>
          {dbCode && <tr>
            <td style={{ width: 120 }}>Distributor No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{dbCode}</td>
          </tr>}
          {dbName && <tr>
            <td style={{ width: 120 }}>Distributor Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 250 }}>{dbName}</td>
          </tr>}

          <tr>
            <td style={{ width: 120 }}>Territory</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectTerritoryView
                disabled={No}
                value={(!TerritoryCode || (TerritoryCode && TerritoryCode === '')) ? undefined : TerritoryCode}
                onChange={(filter, value) => onInputChange(filter, value, "territoryCodeError")}
              />
              {error.territoryCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.territoryCodeError}</p>}
            </td>
          </tr>
        </Col>

        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 0, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={24} xs={24}>


          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                type="purchasing_order"
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>

          {docType === 'Order' && <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Payment Term</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <Select
                disabled={PayTermCode}
                placeholder={'Select Pay Term'}
                value={(!PayTermCode || (PayTermCode && PayTermCode === '')) ? undefined : PayTermCode}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("PayTermCode", value, "payTermCodeError")}
              >
                {paymentTerms.map(term => term && <Option value={term.No}>{term.Description}</Option>)}
              </Select>
              {error.payTermCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.payTermCodeError}</p>}
            </td>
          </tr>}
          {this.renderReturnReasonSelect()}
        </Col>
      </div>
    )
  }

  render() {

    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
          {/* <Col lg={0} md={0} sm={24} xs={24}>
            {this.renderMobileHeader()}
          </Col> */}
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { order } = ownProps
  return {
    paymentTerms: getPaymentTerms(state),
    territories: getTerritories(state),
    purchaseReturnReasons: getPurchaseReturnReasons(state),
  };
};

export default withRouter(connect(mapStateToProps, {})(OrderHeader));