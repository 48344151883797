import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchExpenses, updateExpense } from "../../../redux/actions/Expenses";
import { addAttachment } from '../../../redux/actions/attachments';
import { getAuthData, getExpenseByDocNo } from '../../../redux/reducers';
import { Spin, Modal } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';
import { currentLocation } from '../../../utils/Location';
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';

const { confirm } = Modal;

const defaultExpense = {
  CollectionNo: undefined,
  DocDate: moment(new Date()).format(dateFormatUpdate),
  PayNo: undefined,
  CusCode: undefined,
  CusName: undefined,
  Status: "Open",
  ExpCategory: undefined,
  ExpDescription: undefined,
  ExpenseDate: moment(new Date()).format(dateFormatUpdate),
  Description: undefined,
  Amount: 0.0,
  Remarks: undefined,
  UserCode: undefined,
  signedBy: undefined
}

class Container extends Component {
  state = {
    loading: false,
    expenseConfirmationType: 'Customer',
    expense: { ...defaultExpense }
  }

  componentDidMount() {
    const { authData, match } = this.props;
    const { expenseId } = match.params
    const newExpense = expenseId === 'new';

    if (newExpense) {
      this.setState({ expense: { ...defaultExpense } })
    } else {
      this.setState({ loading: true })
      this.props.fetchExpenses({ CollectionNo: decodeURIComponent(expenseId), UserCode: authData.userId }).then(() => {
        this.setState({ loading: false })
      })
      this.setState({ expense: { ...defaultExpense, No: decodeURIComponent(expenseId) } })
    }
  }

  confirmExpense = (signatureImageUrl, isEmptyUrl) => {
    const { updateExpense, toastManager, history, match, expense, authData, addAttachment } = this.props;
    const { expenseConfirmationType } = this.state;
    const { expenseId } = match.params;
    const directory = 'expenses';

    let title = 'Expense';

    if (!expenseConfirmationType) {
      toastManager.add("You can't confirm the " + title.toLowerCase() + " without confirmation type.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const updateExpenserData = { ...expense, UserCode: authData.userId, SignedBy: expenseConfirmationType };

    updateExpenserData.Status = '3';

    this.setState({ loading: true });

    const update = () => {
      updateExpense(updateExpenserData).then(response => {
        this.setState({ loading: false })
        if (!response.error) {
          toastManager.add(title + ' confirmed.', { autoDismiss: true, appearance: 'success' });
          window.history.back();
        } else {
          toastManager.add('Could not confirm the ' + title.toLowerCase() + '. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    }

    if (expenseConfirmationType === "OTPO") {
      update();
    } else {
      if (isEmptyUrl) {
        this.setState({ loading: false })
        toastManager.add("You can't confirm the " + title.toLowerCase() + " without providing the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const imageFile = this.getImageFile(signatureImageUrl);

      upload(imageFile, directory + "/" + expenseId, 'signature-' + new Date().getTime()).then(response => {
        if (response.error) {
          toastManager.add("Could not upload the signature.", { autoDismiss: true, appearance: 'error' });
          return;
        }

        const link = {
          AttachmentName: 'DistributorExpenseAttachment',
          //DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
          DocNo: expense.CollectionNo,
          AttachmentURL: response.path,
          UserCode: authData.userId
        };

        addAttachment('DistributorExpenseAttachment', link).then(response => {
          if (response.error) {
            toastManager.add("Could not link the signature.", { autoDismiss: true, appearance: 'error' });
            this.setState({ loading: false });
            // return; // TODO
          }

          update();
        });
      });
    }
  }

  showConfirmExpenseConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    let title = 'Expense';

    confirm({
      title: 'Are you sure ?',
      content: "After confirm you can't update the " + title.toLowerCase() + '.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        context.confirmExpense(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  onChangeExpenseConfirmationType = type => {
    this.setState({ expenseConfirmationType: type });
  }

  render() {
    const { loading,/* expense,*/ expenseConfirmationType } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading expense"}>
        <Pad expenseConfirmationType={expenseConfirmationType} onConfirmationTypeChange={this.onChangeExpenseConfirmationType} onConfirmExpense={(signatureImageUrl, isEmptyUrl) => this.showConfirmExpenseConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { expenseId } = ownProps.match.params
  const decodeId = decodeURIComponent(expenseId);

  return {
    expense: getExpenseByDocNo(state, decodeId),
    authData: getAuthData(state)
  };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchExpenses, updateExpense, addAttachment: addAttachment })(Container)));