import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchCustomers, fetchCustomerCategories, fetchCustomerSubcategories } from "../../redux/actions/customers";
import { fetchTransferHeaders } from "../../redux/actions/transferHeaders";
import { getAuthData, getLoggedInUser, getTransferHeaders, getFlatPositions, getTopPosition, isFetchingTransferHeaders, getTransferHeadersBySearchKey } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import StockTransferTable from "./StockTransferTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { DOC_TYPE } from "../../redux/reducers";
import { withToastManager } from 'react-toast-notifications';
import { STOCK_TRANSFERS } from "../../const/Permissions";
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { handleUrlSearchParams, redirectToUrlState, getSearchKey } from '../../utils/Search';
import { fetchSalesUserHierarchy } from '../../redux/actions/users'
import { withRouter } from 'react-router';
import { FaPlus } from 'react-icons/fa';
import { dateFormatUpdate, dateFormatView } from '../../const/Formats';

const BASE_URL = '/stockTransfers';

class StockTransfers extends Component {
  state = {
    loading: false,
    filter: {
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1,
  }

  componentDidMount() {
    const { authData, fetchSalesUserHierarchy } = this.props;
    this.setState({ loading: true, filter: { ...this.state.filter, UserCode: authData.userId } });
    this.handleUrlSearchParams(this.props);
    fetchSalesUserHierarchy(authData);
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    handleUrlSearchParams(BASE_URL, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadTransferHeaders(nextProps);
      });
    }, this.state.filter);
  }


  loadTransferHeaders = props => {
    const { currentPage, currentSearchKey } = this.state;
    const { topPosition, flatPositions } = props;
    //console.log(topPosition, flatPositions)
    if (!topPosition || flatPositions.length === 0) return;

    const filter = updateFilterUserHierarchy(this.state.filter.UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = undefined;

    this.setState({ filter });
    this.fetchTransferHeaders(filter, currentPage, currentSearchKey);
  }

  fetchTransferHeaders = (newfilter, page, searchKey) => {
    const { filter } = this.state;
    const updatedFilter = {
      ...newfilter,
      PostingDateFilter: moment(filter.from).format(dateFormatUpdate) + '..' + moment(filter.to).format(dateFormatUpdate),
    }
    this.props.fetchTransferHeaders(this.getRequestFilter(updatedFilter), page, searchKey);
  }

  getRequestFilter = filter => {
    const requestFilter = { ...filter };
    requestFilter.likeName = requestFilter.text;
    requestFilter.text = undefined;

    return requestFilter;
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(BASE_URL, this.props, this.state.filter, page);

  fetchTransferHeaderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchTransferHeaders(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchTransferHeaders(filter, currentPage, currentSearchKey);
  }

  // fetchStockTransfers = () => {
  //   const { filter } = this.state;
  //   const updatedFilter = {
  //     ...filter,
  //     PostingDateFilter: moment(filter.from).format(dateFormatView) + '..' + moment(filter.to).format(dateFormatView),
  //   }

  //   this.showLoadingMessage();
  //   this.setState({ loading: true })
  //   this.props.fetchTransferHeaders(updatedFilter).then(() => {
  //     this.hideLoadingMessage()
  //     this.setState({ loading: false })
  //   })
  // }


  handleInputTextChange = (filter, value, forceReload) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, newFilter, flatPositions, topPosition);
        break;
      default:
        break;
    }

    this.setState({ filter: newFilter }, () => {
      if (forceReload) {
        this.handleSearchClick();
      }
    });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing transfers.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  getFilteredTransfers = () => {
    const { transfers } = this.props;

    const { text } = this.state.filter;

    let filteredTransfers = transfers;

    filteredTransfers = transfers.filter(order => {
      const { DocNo = "", FromLocCode = "", FromLocDescrip = "", ToLocCode = "", ToLocDescrip = "", Status = "", CreatedUserID = "", CreatedUserName = "" } = order;
      const docNoFilter = text ? this.stringCompare(DocNo, text) : true;
      const fromLocCodeFilter = text ? this.stringCompare(FromLocCode, text) : true;
      const fromLocDescripFilter = text ? this.stringCompare(FromLocDescrip, text) : true;
      const toLocCodeFilter = text ? this.stringCompare(ToLocCode, text) : true;
      const toLocDescripFilter = text ? this.stringCompare(ToLocDescrip, text) : true;
      const statusFilter = text ? this.stringCompare(Status, text) : true;
      const createdUserIdFilter = text ? this.stringCompare(CreatedUserID, text) : true;
      const createdUserNameFilter = text ? this.stringCompare(CreatedUserName, text) : true;

      return (docNoFilter || fromLocCodeFilter || fromLocDescripFilter || toLocCodeFilter || toLocDescripFilter || statusFilter || createdUserIdFilter || createdUserNameFilter);
    })

    //sort 
    return filteredTransfers.sort(this.compareStatus).reverse();
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  render() {
    const { loading, filter, currentPage } = this.state;
    const transfers = this.getFilteredTransfers();
    const { loggedInUser: { permissions = [] }, fetchingTransfers } = this.props;

    return (
      <div>
        <PromatePageHeader
          title={'Stock Transfers'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={permissions.includes(STOCK_TRANSFERS.CREATE) && [
            <CallStatusValidateButton onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </CallStatusValidateButton>,
          ]}>

          <Spin spinning={fetchingTransfers && transfers.length === 0} tip={"Loading Stock Transfers"}>
            <StockTransferTable
              transfers={transfers}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              onPaginationChange={this.handlePaginationChange} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    this.props.history.push(this.props.match.url + "/new");
  }
}

StockTransfers.contextTypes = {
  screenType: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
  return {
    transfers: getTransferHeadersBySearchKey(state, getSearchKey()),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    fetchingTransfers: isFetchingTransferHeaders(state),
  };
};

export default withToastManager(connect(mapStateToProps, { fetchTransferHeaders, fetchCustomers, fetchCustomerCategories, fetchCustomerSubcategories, fetchSalesUserHierarchy })(StockTransfers));

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};