import React from 'react';
import { Row, Col } from "antd";
import CurrencyFormat from "react-currency-format";

const getPaymentsTotal = filteredPayments => {
  let total = 0;
  filteredPayments.forEach(paymentData => {
    total = total + paymentData.amount;
  })
  return total;
}

const Footer = (props) => {
  const { filteredPayments } = props;
  return (
    <div style={{
      backgroundColor: '#eceaea',
      height: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: -10,
      padding: 10
    }}>
      <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
        <Col span={12}>{filteredPayments.length > 0 ? 'Total ' + filteredPayments.length : 'No'} payments{filteredPayments.length !== 1 && 's'}</Col>
        <Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
          <CurrencyFormat
            value={getPaymentsTotal(filteredPayments)}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            decimalScale={2} />
        </Col>
      </Row>
    </div>
  )
}

export default Footer