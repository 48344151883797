import * as fromApi from '../../api/generic';
import { PAGE_SIZE } from './fetchOData';
import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB, createObjectStoreIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'
import { DOC_TYPE_DEFINITIONS, DOC_TYPE_ID_BY_DEFINITION } from '../reducers'
import moment from 'moment';
import { dateTimeFormatFilterUpdate, dateFormatUpdate } from '../../const/Formats'

export const FETCH_SALES_HEADERS = 'FETCH_SALES_HEADERS';
export const START_FETCH_SALES_HEADERS = 'START_FETCH_SALES_HEADERS';
export const FINISH_FETCH_SALES_HEADERS = 'FINISH_FETCH_SALES_HEADERS';
export const FETCH_SALES_HEADER = 'FETCH_SALES_HEADER';
export const DELETE_SALES_HEADER = 'DELETE_SALES_HEADER';
export const FETCH_SALES_CREDIT_MEMO_REASONS = 'FETCH_SALES_CREDIT_MEMO_REASONS';

let lastSearchKey;
export const fetchSalesHeaders = (filter = {}, page = 0, searchKey = '') => async dispatch => {
  let pageIndex = PAGE_SIZE * page;
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_SALES_HEADERS });

  console.log('lastSearchKey: ', lastSearchKey)

  if (getOfflineData('state').isSystemOffline) {

    if (filter.DocType === "5") {
      let salesHeaders = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS);

      filter = { ...filter, OrderStatus: filter.OrderStatus === 0 ? 'Open' : filter.OrderStatus === 2 ? "Customer Confirmed" : undefined }

      if (salesHeaders.data) {
        let filterSalesHeaders = [];

        if (page === 0) {
          filterSalesHeaders = (salesHeaders.data).filter(function (el) {
            return (filter.No ? String(el.No).includes(String(filter.No)) : el)
          });
        } else {
          filterSalesHeaders = (salesHeaders.data).filter(function (el) {
            return (filter.RouteCode ? String(el.RouteCode).includes(filter.RouteCode) : el)
              && (filter.No ? String(el.No).includes(String(filter.No)) : el)
              && (filter.OrderStatus ? (el.OrderStatus).includes(filter.OrderStatus) : el)
              && (filter.OutletCategoryCode ? String(el.OutletCategoryCode).includes(filter.OutletCategoryCode) : el)
              && (filter.ReturnReasonCode ? String(el.ReturnReasonCode).includes(filter.ReturnReasonCode) : el)
              && moment(el.CreatedDateTime).isBetween(filter.from, filter.to)
          });
        }

        if (filterSalesHeaders && filterSalesHeaders.length === 1) dispatch({ type: FETCH_SALES_HEADER, salesHeader: filterSalesHeaders[0] });

        dispatch({
          type: FETCH_SALES_HEADERS,
          salesHeaders: filterSalesHeaders,
          searchKey,
          total: filterSalesHeaders.length,
          // metadata: responseMetadata
        });

        dispatch({ type: FINISH_FETCH_SALES_HEADERS });

        return { data: filterSalesHeaders };
      }

    } else {
      return readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((offlineSalesHeadersResponse) => {
        if (offlineSalesHeadersResponse.data) {

          var filterSalesHeaders = (offlineSalesHeadersResponse.data).filter(function (el) {
            return (filter.RouteCode ? el.RouteCode.includes(filter.RouteCode) : el)
              && (filter.No ? el.No.includes(filter.No) : el)
              && (filter.PayTermCode ? el.PayTermCode.includes(filter.PayTermCode) : el)
              && (filter.PayMethodCode ? el.PayMethodCode.includes(filter.PayMethodCode) : el)
              && (filter.Status ? el.Status.includes(filter.Status) : el)
              && (filter.OutletCategoryCode ? el.OutletCategoryCode.includes(filter.OutletCategoryCode) : el)
              && (filter.SOTypeFilter ? el.SOType.includes(filter.SOTypeFilter) : el)
              && (filter.DiscountReasonCode ? el.DiscountReasonCode.includes(filter.DiscountReasonCode) : el)
          });

          if (filterSalesHeaders && filterSalesHeaders.length === 1) dispatch({ type: FETCH_SALES_HEADER, salesHeader: filterSalesHeaders[0] });

          dispatch({
            type: FETCH_SALES_HEADERS,
            salesHeaders: getOfflineData('state').isSystemOffline ? filterSalesHeaders.slice(pageIndex - PAGE_SIZE, pageIndex) : filterSalesHeaders,
            searchKey,
            total: filterSalesHeaders.length,
            // metadata: responseMetadata
          });
          dispatch({ type: FINISH_FETCH_SALES_HEADERS });

          var response = {
            "data": filterSalesHeaders,
          }
          return response;
        }
      }).catch((err) => {
        console.log(err);
      })
    }


  } else {
    return fromApi.get('SalesHeader', filter, page).then(response => {
      const { data, error, recordTotal, responseMetadata } = response;
      if (error) return { error }

      if (data && data.length === 1) dispatch({ type: FETCH_SALES_HEADER, salesHeader: data[0] });

      dispatch({ type: FETCH_SALES_HEADERS, salesHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });
      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey)
        dispatch({ type: FINISH_FETCH_SALES_HEADERS });
    });
  }
};

async function aggregateSalesHeaders(newSalesHeader) {

  var response = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.SALES_HEADERS).then((res) => {
    return res;
  }).catch((err) => { alert(err); })

  response.data.push(newSalesHeader)
  response.recordTotal = response.data.length

  await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.SALES_HEADERS, response)

}

export const addSalesHeader = salesHeader => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {

    const callResponse = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })
    const customers = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA);
    let currentActiveCall = callResponse.records[callResponse.records.length - 1]

    if (currentActiveCall.CustNo == salesHeader.SellToCusNo) {
      if (salesHeader.DocType === DOC_TYPE_ID_BY_DEFINITION['ReturnOrder'] && currentActiveCall.StartReasonDescrip != "Sales Return") {
        return { ...response, error: "There is an active call for " + currentActiveCall.StartReasonDescrip + ". Please close it and create Sales return call." };
      } else if (salesHeader.DocType === DOC_TYPE_ID_BY_DEFINITION['Order'] && currentActiveCall.StartReasonDescrip != "Sales Order") {
        return { ...response, error: "There is an active call for " + currentActiveCall.StartReasonDescrip + ". Please close it and create Sales order call." };
      }
    } else {
      return { ...response, error: "There is an active call for another customer. Please close the call and try again." };
    }
    if (salesHeader.DocType === "5") {
      try {
        const SalesReturnReasons = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.SALES_RETURN_REASONS);
        const tripSchedulars = await readObjectFromIndexDB(TABLES.TRIP_SCHEDULES, OBJECT_KEYS.TRIP_SCHEDULER);
        const No = Math.round((new Date()).getTime() / 1000).toString();

        let newSalesHeader = {
          ActiveCallNo: currentActiveCall.No,
          Amount: parseFloat(salesHeader.Amount).toFixed(2).toString(),
          AmountIncluVAT: parseFloat(salesHeader.AmountIncluVAT).toFixed(2).toString(),
          BillToCusName: salesHeader.SellToCusName,
          BillToCusNo: salesHeader.SellToCusNo,
          CSRUserCode: getOfflineData('state').auth.user.userId,
          CSRUserName: getOfflineData('state').auth.user.displayName,
          CreatedDateTime: moment().format(dateTimeFormatFilterUpdate),
          CreatedUserID: getOfflineData('state').auth.user.userId,
          CreatedUserName: getOfflineData('state').auth.user.displayName,
          CustStatus: customers.data.find(customer => customer.No === salesHeader.SellToCusNo).Status,
          DistributorCode: customers.data.find(customer => customer.No === salesHeader.SellToCusNo).DistributorCode,
          DocDate: moment().format(dateFormatUpdate),
          DocType: DOC_TYPE_DEFINITIONS[salesHeader.DocType],
          LocCode: salesHeader.LocCode,
          MapLocation: salesHeader.MapLocation,
          ModifiedDateTime: moment().format(dateTimeFormatFilterUpdate),
          ModifiedUserID: getOfflineData('state').auth.user.userId,
          ModifiedUserName: getOfflineData('state').auth.user.displayName,
          No,
          OrderDate: salesHeader.OrderDate,
          OrderStatus: "Open", // Open
          OutletCategoryCode: customers.data.find(customer => customer.No === salesHeader.SellToCusNo).OutletCategoryCode,
          OutletSubCategoryCode: customers.data.find(customer => customer.No === salesHeader.SellToCusNo).OutletSubCategoryCode,
          PayMethodCode: customers.data.find(customer => customer.No === salesHeader.SellToCusNo).PayMethodCode,
          Remarks: salesHeader.Remarks,
          ReturnReasonCode: salesHeader.ReturnReasonCode,
          ReturnReasonDescrip: SalesReturnReasons.data.find(reason => reason.Code === salesHeader.ReturnReasonCode).Description,
          RouteCode: tripSchedulars.data.find(trip => trip.LocCode === salesHeader.LocCode).RouteCode,
          RouteName: tripSchedulars.data.find(trip => trip.LocCode === salesHeader.LocCode).RouteName,
          SellToCusName: salesHeader.SellToCusName,
          SellToCusNo: salesHeader.SellToCusNo,
          SessionID: salesHeader.SessionID,
          ShipToName: tripSchedulars.data.find(trip => trip.LocCode === salesHeader.LocCode).LocName,
          Status: salesHeader.Status,
          StatusFilter: "Yes", // Open // For filter
          UserCode: salesHeader.UserCode,
          Sync: false,
          SyncConfirm: false
        }

        let response = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS);

        if (typeof (response) != undefined) {
          response.data.push(newSalesHeader)
        } else {
          response.data = [newSalesHeader]
        }

        // Save sales header into indexDB
        await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS, response)

        dispatch({ type: FETCH_SALES_HEADER, salesHeader: newSalesHeader });

        return { data: newSalesHeader };
      } catch (error) {
        console.error('addSalesHeader Error: ', error);
        return { error };
      }
    } else {
      var newSalesHeader = salesHeader;
      var SalesHeaderNo = Math.round((new Date()).getTime() / 1000).toString();
      newSalesHeader.No = SalesHeaderNo;
      newSalesHeader.BillToCusNo = newSalesHeader.SellToCusNo
      newSalesHeader.BillToCusName = newSalesHeader.SellToCusName
      newSalesHeader.DocType = "Order"
      newSalesHeader.Status = "Open"
      newSalesHeader.OrderStatus = "Open"
      newSalesHeader.CustStatus = "Active"
      newSalesHeader.PricesIncluVAT = "No"
      newSalesHeader.AmountIncluVAT = "0.00"
      newSalesHeader.Amount = "0.00"
      newSalesHeader.DisAmount = "0.00"
      newSalesHeader.RouteCode = customers.data.find(customer => customer.No === salesHeader.SellToCusNo).RouteCode
      newSalesHeader.StatusFilter = "No"
      newSalesHeader.OutletCategoryCode = customers.data.find(customer => customer.No === salesHeader.SellToCusNo).OutletCategoryCode
      newSalesHeader.ActiveCallNo = currentActiveCall.No
      newSalesHeader.Sync = false
      newSalesHeader.SyncConfirm = false
      newSalesHeader.SalesHeaderNo = SalesHeaderNo

      var response = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
        return res;
      }).catch((err) => { console.log(err); })

      if (typeof (response) != "undefined") {
        response.data.push(newSalesHeader)
      } else {
        var response = {
          newSalesHeader,
          "data": [newSalesHeader]
        }
      }
      await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS, response)
      var response = {
        "data": newSalesHeader,
      }

      dispatch({ type: FETCH_SALES_HEADER, salesHeader: response.data });

      return response;
    }
  } else {
    return fromApi.add('SalesHeader', salesHeader).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_SALES_HEADER, salesHeader: data });
      console.log(response);
      return response;
    }).catch(error => {
      return { error }
    })
  };
}

export const updateSalesHeader = salesHeader => async dispatch => {

  console.log('updateSalesHeader: ', salesHeader);

  if (getOfflineData('state').isSystemOffline) {
    // Return Order
    if (salesHeader.DocType === "ReturnOrder") {
      let salesHeaders = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS);
      const SalesReturnReasons = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.SALES_RETURN_REASONS);

      salesHeaders.data = salesHeaders.data.map(header => {
        if (header.No === salesHeader.No) {
          const reason = SalesReturnReasons.data.find(reason => reason.Code === salesHeader.ReturnReasonCode);
          header.ReturnReasonDescrip = reason.Description;
          salesHeader.ReturnReasonDescrip = reason.Description;
          header.ReturnReasonCode = reason.Code;
          salesHeader.ReturnReasonCode = reason.Code;
          header.Remarks = salesHeader.Remarks
          header.ModifiedDateTime = moment().format(dateTimeFormatFilterUpdate);
        }
        return header;
      });

      await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS, salesHeaders)

      dispatch({ type: FETCH_SALES_HEADER, salesHeader: salesHeader });

      return salesHeaders;

    } else {
      let orderId = salesHeader.No

      let responseSalesHeaders = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
        return res;
      }).catch((err) => { console.log(err); })

      let salesHeadersWithoutUpdate = responseSalesHeaders.data.filter(function (el) {
        return el.No != orderId;
      });

      salesHeadersWithoutUpdate.push(salesHeader)

      let response = {
        "data": salesHeadersWithoutUpdate,
        "records": salesHeadersWithoutUpdate
      }

      await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS, response)

      dispatch({ type: FETCH_SALES_HEADER, salesHeader: salesHeader });

      return response;
    }
  } else {

    return fromApi.update('SalesHeader', salesHeader).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_SALES_HEADER, salesHeader: data });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const deleteSalesHeader = salesHeader => async dispatch => {
  console.log('deleteSalesHeader: ', salesHeader);
  if (getOfflineData('state').isSystemOffline) {
    if (salesHeader.DocType === "ReturnOrder") {
      // Read Existing Sales Return Header 
      let salesHeaders = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS);
      salesHeaders.data = salesHeaders.data.filter(header => header.No !== salesHeader.No);

      // Adding Updated Sales Return Headers 
      await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS, salesHeaders);

      dispatch({ type: DELETE_SALES_HEADER, salesHeader });

      // Read Existing Sales Return Signatures
      let signatures = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SALES_RETURN_SIGNATURES);
      signatures.data = signatures.data.filter(signature => signature.orderId !== salesHeader.No);

      // Adding Updated Sales Return Signatures
      await insertDataIndexDB(TABLES.IMAGES, OBJECT_KEYS.SALES_RETURN_SIGNATURES, signatures);

      return salesHeader;

    } else {
      var salesHeaders = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS);

      const salesHeadersAfterDelete = salesHeaders.data.filter(function (el) {
        return el.No != salesHeader.No;
      });

      var data = {
        data: salesHeadersAfterDelete
      }
      await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS, data);
      dispatch({ type: DELETE_SALES_HEADER, salesHeader });
      return data;
    }

  } else {
    return fromApi.deleteObject('SalesHeader', salesHeader).then(response => {
      const { error } = response;

      if (error) return { error }

      dispatch({ type: DELETE_SALES_HEADER, salesHeader });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const fetchSalesCreditMemoReasons = () => dispatch => {
  if (getOfflineData('state').isSystemOffline) {

  } else {
    return fromApi.get('RefData', { 'RefName': 'SalesCreditMemoReasons' }).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_SALES_CREDIT_MEMO_REASONS, creditMemoReasons: data });

      return response;
    }).catch(error => {
      return { error }
    });
  }

};
