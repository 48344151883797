import { localFetch } from './commonFetch';
import Api from './consts';

export const getFailedSalesHeaders = (filter) => {
    const { url, method } = Api.local.order.failedHeaders;
    return localFetch(() => url(filter), { method });
}

export const getFailedSalesLines = (headerId) => {
    const { url, method } = Api.local.order.failedLines;
    return localFetch(() => url(headerId), { method });
}
