import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Table, Tag } from "antd";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import AdvanceSearchFilters from "../../components/common/AdvanceSearchFilters";
import InvoiceCard from "./InvoiceCard";
import InvoiceCardHeader from "./InvoiceCardHeader";
import CurrencyFormat from "react-currency-format";
import _ from 'lodash'
import { PURCHASING_INVOICES } from "../../const/Permissions";
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import moment from 'moment';

class InvoiceTable extends Component {

  componentDidMount = () => {
    window.addEventListener('locationchange', this.onUrlChange);
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 70;
  }

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  getCustomerStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  getDocStatusColor = docStatus => {
    switch (docStatus) {
      case 'Overdue':
        return 'red';
      case 'Outstanding':
        return 'orange';
      case 'Settled':
        return 'green'
      default:
        return '';
    }
  }

  getTableHeight = purchasingInvoices => {
    if (purchasingInvoices && purchasingInvoices.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { filter, handleInputDateChange, handleInputTextChange, handleSearchClick } = this.props;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={entireColumn}
          FROM_DATE_PICKER={oneEighthOfColumn}
          TO_DATE_PICKER={oneEighthOfColumn}
          SELECT_PURCHASING_INVOICE_DOC_TYPE={oneEighthOfColumn}
          SELECT_PURCHASING_INVOICE_STATUS={oneEighthOfColumn}
          SEARCH_TEXT={{ ...(oneEighthOfColumn), placeholder: 'Invoice No' }}
          SEARCH_TEXT_2={{ ...(oneEighthOfColumn), placeholder: 'PO No' }}
          SELECT_TERRITORY={oneEighthOfColumn}
          SEARCH_BUTTON={oneEighthOfColumn}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          filter={filter}
        />
      </div>
    )
  }

  renderFooter = () => {
    const { onPaginationChange, total, metaData, currentPage } = this.props;
    const { TotalAmount, TotSettledAmount, TotRemAmount, TotPDCAmount, TotNetRemAmount } = metaData;

    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        totals={[TotalAmount, TotSettledAmount, TotRemAmount, TotPDCAmount, TotNetRemAmount]}
        widths={[116, 116, 116, 116, 116, 116]}
        total={TotNetRemAmount}
        docType={'purchasingInvoices'}
      />
    );
  }

  renderCustomerStatus = (CustStatus) => {
    const customerStatus = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;

    return (
      <Tag style={styles.status} color={this.getCustomerStatusColor(customerStatus)}>{customerStatus}</Tag>
    );
  }

  renderColumns = () => {
    return [{
      render: purchasingInvoice => purchasingInvoice === 0 ? <InvoiceCardHeader style={{ backgroundColor: '#eceaea' }} /> : (
        purchasingInvoice === 1 ? this.renderFooter() : <InvoiceCard purchasingInvoice={purchasingInvoice} />
      )
    }]
  }

  render() {
    const { purchasingInvoices, loggedInUser: { permissions = [] }, history } = this.props;
    const sourceData = purchasingInvoices.length === 0 ? [] : [0, ...purchasingInvoices];
    const pcSourceData = purchasingInvoices.length === 0 ? [] : [...purchasingInvoices];

    const pcColumns = [
      {
        title: 'Invoice No',
        dataIndex: 'No',
        key: 'no',
        align: 'left',
        width: 150,
        render: text => <span style={{ ...styles.no, width: 200 }}>{text}</span>,
      },
      {
        title: 'Invoice Date',
        dataIndex: 'PostingDate',
        key: 'PostingDate',
        width: 120,
        align: 'left',
        render: date => <span>{moment(date).format(dateFormatView)}</span>
      },
      {
        title: 'Doc Type',
        dataIndex: 'DocType',
        key: 'DocType',
        align: 'left',
        width: 120
      },
      {
        title: 'PO Number',
        dataIndex: 'OrderNo',
        key: 'OrderNo',
        align: 'left',
        width: 150,
      },
      {
        title: 'DB Code',
        dataIndex: 'DistributorCode',
        key: 'DistributorCode',
        align: 'left',
        width: 150,
      },
      {
        title: 'DB Name',
        dataIndex: 'DistributorName',
        key: 'DistributorName',
        align: 'left',
        width: 250,
      },
      {
        title: 'Territory',
        dataIndex: 'TerritoryName',
        key: 'TerritoryName',
        align: 'left',
        width: 120
      },
      {
        title: 'Payment Term',
        dataIndex: 'PayTermCode',
        key: 'PayTermCode',
        align: 'left',
        width: 150,
      },
      {
        title: 'Invoice Age',
        dataIndex: 'InvAge',
        key: 'InvAge',
        align: 'center',
        width: 80,
      },
      {
        title: 'Status',
        dataIndex: 'DocStatus',
        key: 'DocStatus',
        align: 'center',
        width: 80,
        render: status => <Tag style={styles.status} color={this.getDocStatusColor(status)}>{status}</Tag>
      },
      {
        title: 'Invoice Amount',
        dataIndex: 'Amount',
        key: 'Amount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text => <div style={{ width: 100 }}>
          <CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
        </div>
      },
      {
        title: 'Settled Amount',
        dataIndex: 'SettledAmount',
        key: 'SettledAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text => <div style={{ width: 100 }}><CurrencyFormat
          value={text || 0}
          displayType={'text'}
          thousandSeparator={true}
          isNumericString={true}
          fixedDecimalScale={true}
          style={styles.price}
          decimalScale={2} />
        </div>
      },
      {
        title: 'Remainning Outstanding',
        dataIndex: 'RemAmount',
        key: 'RemAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}> <CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>
      },
      {
        title: 'PDC Balance',
        dataIndex: 'PDCAmount',
        key: 'PDCAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}><CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>
      },
      {
        title: 'Net Outstanding',
        dataIndex: 'NetRemAmount',
        key: 'NetRemAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}><CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>,
        footer: (data) => {
          return <div>Summary</div>
        },
      }
    ];

    if (window.innerWidth < 768) {
      return (
        <Table
          columns={this.renderColumns()}
          rowKey={'invoiceNo'}
          rowClassName={'orderRow'}
          size={'small'}
          bodyStyle={{ minHeight: this.getTableHeight(purchasingInvoices), overflowY: 'scroll' }}
          pagination={false}
          showHeader={false}
          title={() => this.renderHeader()}
          dataSource={sourceData}
          footer={() => this.renderFooter()} />
      )
    }
    else {
      return (
        <Col>
          <Table
            rowKey={'invoiceNo'}
            onRowClick={(data) => (permissions.includes(PURCHASING_INVOICES.VIEW) || permissions.includes(PURCHASING_INVOICES.UPDATE)) && history.push("/purchasingInvoices/" + encodeURIComponent(data.No))}
            rowClassName={'table-row-dark'}
            dataSource={pcSourceData}
            columns={pcColumns}
            size={'middle'}
            scroll={{ y: this.getScrollAreaHeight(), x: 2050 }}
            bodyStyle={{ minHeight: this.getTableHeight(purchasingInvoices), overflowY: 'scroll' }}
            pagination={false}
            title={() => this.renderHeader()}
            summary={() => {
              return (<div>apple</div>)
            }}
            footer={() => this.renderFooter()}
          />
        </Col>
      )
    }
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderRow: {
    backgroundColor: 'black'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  orderDateTitle: {
    fontSize: 12
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%'
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

InvoiceTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(InvoiceTable));