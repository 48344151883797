import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import SelectWarehouseLocation from "../../common/SelectWarehouseLocation"
import { getCustomerPaymentTermsDataByCustomerId, getCustomerPaymentMethodsDataByCustomerId, getLocations } from '../../../redux/reducers';
import customers from '../../../redux/reducers/customers';
import { dateFormatView } from '../../../const/Formats';

const { Option } = Select;
class OrderHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Customer Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  renderActions = () => {
    const { order, newOrder, onSubmitOrder, onClickConfirmOrder, onShareOrder, onDeleteOrder } = this.props;
    const { salesLines, status } = order;

    if (newOrder) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitOrder}
        >
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (order.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteOrder}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       order.salesLines && order.salesLines.length > 0 &&
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (order.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       order.links && order.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareOrder()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareOrder()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  renderDesktopHeader = () => {
    const { order, onInputChange, error, salesLines, customerPaymentMethods, customerPaymentTerms, locations } = this.props;
    const { DisPersentage, No, OrderDate, Status, SellToCusName, SellToCusNo, PayMethodCode, PayTermCode, LocCode, CreatedUserName } = order;
    //console.log("apple hish", locations)
    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td style={{ width: 120 }}>No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {OrderDate && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(OrderDate).format(dateFormatView)}</td>
          </tr>}
          {Status && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(Status)}>{Status}</Tag>}
            </td>
          </tr>}
          {CreatedUserName && <tr>
            <td>Created User</td>
            <td style={{ width: 25, textAlign: 'center' }}>:</td>
            <td>{CreatedUserName}</td>
          </tr>}
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={0} xs={0}>
          {SellToCusNo && <tr>
            <td style={{ width: 120 }}>Customer No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusNo}</td>
          </tr>}
          {SellToCusName && <tr>
            <td style={{ width: 120 }}>Customer Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={true}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>
        </Col>
        {/* For mobile view */}
        <Col lg={0} md={0} sm={24} xs={24}>
          {SellToCusNo && <tr>
            <td style={{ width: 120 }}>Customer No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusNo}</td>
          </tr>}
          {SellToCusName && <tr>
            <td style={{ width: 120 }}>Customer Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
        </Col>
        {/* For mobile view */}
        <Col lg={0} md={0} sm={24} xs={24}>
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 0, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          <tr style={{ justifyContent: 'center' }}>
            <td>Base Discount %</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <p style={{ width: '100%' }}>{DisPersentage}</p>
            </td>
          </tr>
          <tr style={{ justifyContent: 'center' }}>
            <td>Payment Method</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>
              <p style={{ width: '100%' }}>{(!PayMethodCode || (PayMethodCode && PayMethodCode === '')) ? undefined : PayMethodCode}</p>
            </td>
          </tr>
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Payment Term</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td> <p style={{ width: '100%' }}>{(!PayTermCode || (PayTermCode && PayTermCode === '')) ? undefined : PayTermCode}</p> </td>
          </tr>
        </Col>
      </div>
    )
  }

  renderMobileHeader = () => {
    const { order, onInputChange, error, salesLines, customerPaymentMethods, customerPaymentTerms, locations } = this.props;
    const { DisPersentage, No, OrderDate, Status, SellToCusName, SellToCusCode, PayMethodCode, PayTermCode, LocCode, CreatedUserName } = order;
    //console.log('locations', locations)
    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td>No #</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {OrderDate && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(OrderDate).format(dateFormatView)}</td>
          </tr>}
          {Status && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(Status)}>{Status}</Tag>}
            </td>
          </tr>}
          {CreatedUserName && <tr>
            <td>Created User</td>
            <td style={{ width: 25, textAlign: 'center' }}>:</td>
            <td>{CreatedUserName}</td>
          </tr>}
          <tr style={{ marginBottom: 5 }}>
            <td colSpan='3'><hr /></td>
          </tr>
          {SellToCusName && <tr>
            <td>Customer #</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
          <tr style={{ justifyContent: 'center' }}>
            <td>Base Discount %</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>
              <input
                className="ant-input"
                value={DisPersentage}
                style={{ width: '100%' }}
                type="number"
                min="0"
                disabled={salesLines && salesLines.length > 0}
                onChange={(e) => onInputChange("DisPersentage", e.target.value, "discountPercentageError")}
                onFocus={(event) => event.target.select()} />
            </td>
          </tr>
          <tr style={{ justifyContent: 'center' }}>
            <td>Payment Method</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>
              <Select
                placeholder={'Select Payment Method'}
                value={(!PayMethodCode || (PayMethodCode && PayMethodCode === '')) ? undefined : PayMethodCode}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("PayMethodCode", value, "paymentTypeError")}
                disabled={Status === "Confirmed"} >
                {customerPaymentMethods.map(method => <Option value={method.No}>{method.Description}</Option>)}
              </Select>
            </td>
          </tr>
          <tr style={{ marginBottom: 5 }}>
            <td colSpan='3'><hr /></td>
          </tr>
          <tr style={{ justifyContent: 'center' }}>
            <td>Payment Term</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <Select
                placeholder={'Select Pay Term'}
                value={(!PayTermCode || (PayTermCode && PayTermCode === '')) ? undefined : PayTermCode}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("PayTermCode", value, "paymentTypeError")}
                disabled={Status === "Confirmed"} >
                {customerPaymentTerms.map(term => <Option value={term.No}>{term.Description}</Option>)}
              </Select>
            </td>
          </tr>
          <tr style={{ justifyContent: 'center' }}>
            <td>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>
        </Col>
      </div>
    )
  }

  render() {

    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
          {/* <Col lg={0} md={0} sm={24} xs={24}>
            {this.renderMobileHeader()}
          </Col> */}
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { order, customerId } = ownProps
  return {
    customerPaymentMethods: getCustomerPaymentMethodsDataByCustomerId(state, customerId),
    customerPaymentTerms: getCustomerPaymentTermsDataByCustomerId(state, customerId),
    locations: getLocations(state),
  };
};

export default connect(mapStateToProps, {})(OrderHeader);