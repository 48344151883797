import React, { Component } from 'react';
import { Spin, Col, Row, Button } from "antd";
import PropTypes from 'prop-types';
import { fetchRoutes } from "../../../redux/actions/routes";
import { getRoutes, getRoutesBySearchKey } from "../../../redux/reducers";
import { connect } from "react-redux";
import RoutesTable from "./RoutesTable";
import LocationMap from '../../common/LocationMap';
import { getAuthData, getFlatPositions, getTopPosition, isFetchingRoutes } from '../../../redux/reducers';
import { updateFilterUserHierarchy } from '../../common/SelectSalesUserHierarchy';
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../../utils/Search';
import { withRouter } from 'react-router';

const BASE_URL = '/routes';

class RoutesTableContainer extends Component {
  state = {
    filter: {
      UserCode: undefined,
      text: undefined
    },
    loading: false,
    currentPage: 1,
    showMap: false
  }

  componentDidMount() {
    const { authData } = this.props;
    this.setState({ loading: true, filter: { ...this.state.filter, UserCode: authData.userId } });
    this.handleUrlSearchParams(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    handleUrlSearchParams(BASE_URL, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadRoutes(nextProps);
      });
    }, this.state.filter);
  }

  loadRoutes = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = undefined;
    this.setState({ filter });
    this.fetchdRoutes(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(BASE_URL, this.props, this.state.filter, page);

  fetchRoutePage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchdRoutes(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchdRoutes(filter, currentPage, currentSearchKey);
  }

  fetchdRoutes = (filter, page, searchKey) => {
    this.props.fetchRoutes(this.getRequestFilter(filter), page, searchKey);
  }

  getRequestFilter = filter => {
    const requestFilter = { ...filter };
    requestFilter.RouteName = filter.text ? '*' + filter.text + '*' : undefined;

    return requestFilter;
  }

  getFilteredRoutes = () => {
    const { routes } = this.props;
    const { text } = this.state.filter;

    let filteredCalls = routes;

    filteredCalls = routes.filter(route => {
      const { RouteCode, RouteName } = route;
      const idFilter = text ? this.stringCompare(RouteCode, text) : true;
      const routeNameFilter = text ? this.stringCompare(RouteName, text) : true;

      return idFilter || routeNameFilter;
    })

    return filteredCalls;
  }

  getOutstandingTotals = expenseList => {
    let totalOutstanding = 0
    expenseList.forEach(expense => totalOutstanding = totalOutstanding + expense.amount)
    return totalOutstanding;
  }

  stringCompare = (string1, string2) => string1.toLowerCase().includes(string2.toLowerCase());

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = (filter, value, forceReload) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, newFilter, flatPositions, topPosition);
        break;
      default:
        break;
    }

    this.setState({ filter: newFilter }, () => {
      if (forceReload) {
        this.handleSearchClick();
      }
    });
  }

  onMapViewSelect = selected => this.setState({ showMap: selected });

  renderMobileViewSwitcher = () =>
    <div style={styles.alignRight}>
      <Button.Group>
        <Button icon='menu' size='small' onClick={() => this.onMapViewSelect(false)}>List</Button>
        <Button icon='environment' size='small' onClick={() => this.onMapViewSelect(true)}>Map</Button>
      </Button.Group>
    </div>

  renderDesktopViewSwitcher = () =>
    <div style={styles.desktopSwitcherButtonGroup}>
      <div style={styles.desktopSwitcherButtons}>
        <Button block icon='menu' onClick={() => this.onMapViewSelect(false)} />
      </div>

      <div style={styles.desktopSwitcherButtons}>
        <Button block icon='environment' onClick={() => this.onMapViewSelect(true)} />
      </div>
    </div>

  renderMobileHeader = () => [
    this.renderFilter(),
    this.renderMobileViewSwitcher()
  ]

  renderDesktopHeader = () =>
    <Row>
      <Col xs={23}>{this.renderFilter()}</Col>
      <Col xs={1}>{this.renderDesktopViewSwitcher()}</Col>
    </Row>

  renderHeader = () =>
    <div style={styles.body} className='promate-table-header'>
      {this.renderMobileHeader()}
    </div>

  renderFilter = () => {
    return (
      <AdvanceSearchFilters
        SALES_HIERARCHY={{ lg: 14, md: 14, sm: 24, xs: 24 }}
        SEARCH_TEXT={{ lg: 7, md: 7, sm: 24, xs: 24, placeholder: 'Search Name' }}
        SEARCH_BUTTON={{ lg: 3, md: 3, sm: 24, xs: 24 }}
        handleInputChange={this.handleInputTextChange}
        handleSearchClick={this.handleSearchClick}
        filter={this.state.filter}
      />
    )
  }

  renderTable = () => {
    const { filter, currentPage } = this.state;
    const { fetchingRoutes, routes, topPosition } = this.props;

    return (
      <Spin spinning={fetchingRoutes && routes.length === 0 || !topPosition} tip={"Loading Routes"}>
        <RoutesTable
          filter={filter}
          currentPage={currentPage}
          routes={routes}
          authData={this.props.authData}
          onPaginationChange={this.handlePaginationChange}
        />
      </Spin>
    )
  }

  renderMap = () => {
    const { routes, fetchingRoutes } = this.props;
    const routesToShow = routes;
    const customers = [];

    return (
      <div style={styles.mapContainer}>
        <Spin spinning={fetchingRoutes} tip={'Loading Routes'}>
          <LocationMap customers={customers} routes={routesToShow} />
        </Spin>
      </div>
    );
  }

  render() {
    const { showMap } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {showMap ? this.renderMap() : this.renderTable()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    routes: getRoutesBySearchKey(state, getSearchKey()),
    authData: getAuthData(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    fetchingRoutes: isFetchingRoutes(state),
  };
};

const styles = {
  body: {
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  desktopSwitcherButtonGroup: {
    marginTop: 2
  },
  desktopSwitcherButtons: {
    padding: 3
  },
  mapContainer: {
    marginTop: 10
  }
}

RoutesTableContainer.contextTypes = {
  screenType: PropTypes.object.isRequired
}


export default withRouter(connect(mapStateToProps, { fetchRoutes })(RoutesTableContainer));