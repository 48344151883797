import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchvendDetails } from '../../redux/actions/vendorDetails'
import { getVendorNumbers, getAuthData } from "../../redux/reducers";

const { Option } = Select;

class SelectVendorNo extends Component {
  componentDidMount = () => {
    this.props.fetchvendDetails();
  }

  render() {
    const {
      vendorNumbers,
      onChange,
      showSearch = true,
      placeholder = 'Select Vendor No',
      allowClear = false,
      defaultValue,
      disabled = false,
      style = { width: '100%' },
      type,
    } = this.props

    const vendoers = vendorNumbers

    return (
      <Select
        //defaultValue={vendorNumbers.length >0 ? vendorNumbers[0].LocCode : defaultValue}
        placeholder={placeholder}
        value={defaultValue}
        onChange={(value) => onChange('SellToCusNo', value, 'vendorNoError')}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        style={style}
      >
        {vendoers.map(vendor => <Option key={vendor.No} value={vendor.No}>{vendor.Name}</Option>)}
      </Select>
    )
  }
}

const mapStateToProps = state => {

  return {
    vendorNumbers: getVendorNumbers(state),
    authData: getAuthData(state)
  };
};

export default connect(mapStateToProps, { fetchvendDetails })(SelectVendorNo);