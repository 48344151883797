import { v4 as uuid } from 'uuid';
import { o, createFilter } from '../../api/odata';

export const PAGE_SIZE = 15;

const latestRequestIds = {};

const newRequestId = type => {
  const requestId = uuid();
  latestRequestIds[type] = requestId;
  return requestId;
}

const isLatestRequestId = (type, id) => {
  return latestRequestIds[type] === id;
}

export const fetchOData = ({ resourceName, filter, page, pageSize = PAGE_SIZE, beforeRequest, onSuccess, onError, onFinish, uniqueIdentified = '', orderBy }) => {
  const requestId = newRequestId(resourceName + uniqueIdentified);

  beforeRequest && beforeRequest(requestId);

  const queryOptions = { $count: true };

  if (page) {
    queryOptions['$top'] = pageSize;
    queryOptions['$skip'] = pageSize * (page - 1);
  }

  const filterString = filter && createFilter(filter);
  if (filterString) queryOptions.$filter = filterString;

  if (orderBy) queryOptions.$orderby = orderBy;

  return o(resourceName).query(queryOptions).then(response => {
    const { value } = response;
    const count = response['@odata.count'];

    if (!value) return {};

    if (isLatestRequestId(resourceName + uniqueIdentified, requestId)) {
      onSuccess && onSuccess(value, count, requestId);
    }

    return response;
  }).catch(error => {
    onError && onError(error, requestId);
    return { error }
  }).finally(() => {
    if (isLatestRequestId(resourceName + uniqueIdentified, requestId)) {
      onFinish && onFinish(requestId);
    }
  });
};

export const fetchAllBatches = async ({ requestId, resource, queryOptions, filterFunction, beforeRequest, onSuccess, onError, onFinish }) => {
  beforeRequest(requestId);

  const pageSize = 1000;
  queryOptions['$top'] = pageSize

  try {
    let records = [];
    let page = 1;

    while (true) {
      queryOptions['$skip'] = (page - 1) * pageSize;

      const response = await o(resource).query(queryOptions);
      const { value } = response;
      if (!value) return {};

      const recordSet = value.filter(filterFunction);

      if (value.length === 0) break;

      records = [...records, ...recordSet];

      page++;
    }

    onSuccess && onSuccess(records, requestId);
  } catch (error) {
    onError && onError(error, requestId);
  }

  onFinish && onFinish(requestId);
};