import React, { Component } from "react";
import { Col, Row, Tag } from "antd";

class GRNCardHeader extends Component {

	render() {
		return (
			<Row>
				<Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, marginRight: 10, backgroundColor: '#eceaea', padding: 10 }}>
					<Col xs={8} sm={6} md={3} lg={3} style={{ textAlign: 'left' }}>GRN No</Col>
					<Col xs={8} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Status</Col>
					<Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>Date</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Customer Code</Col>
					<Col xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'left' }}>Customer Name</Col>
					<Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>
				</Row>
				{/* <Row style={styles.lowerRow}>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Status</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.orderDate}>Date</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}>Customer Code</p>
					</Col>
				</Row>
				<Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<span>Customer Name</span>
					</Col>
				</Row> */}
			</Row>
		)
	}
}

const styles = {
	alignRow: {
		display: 'flex',
		alignItems: 'center'
	},
	status: {
		marginRight: 0
	},
	icon: {
		marginRight: 5
	},
	lowerRow: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 5,
		marginTop: 2
	},
	bottomColumnStyles: {
		textAlign: 'left',
		color: '#808080',
		fontSize: 12
	}
}
export default GRNCardHeader;