import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Pagination, Row, Table } from "antd";
import { getRoutes, getCustomerCategories, getAuthData, getCustomerTotal } from "../../../redux/reducers";
import CustomerCard from "./CustomerCard";
import CurrencyFormat from "react-currency-format";
import CustomerCardHeader from './CustomerCardHeader';
import { fetchSalesUserHierarchy } from '../../../redux/actions/users'
import { getSalesUserHierarchy } from "../../../redux/reducers";
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import TableFooter from "../../common/TableFooter";
import { getSearchKey } from "../../../utils/Search";

class CustomerTable extends Component {
  componentDidMount = () => {
    const { fetchSalesUserHierarchy, authData } = this.props;
    fetchSalesUserHierarchy(authData);
  }

  renderColumns = () => {
    return [{
      render: customer => <CustomerCard authData={this.props.authData} customer={customer} />
    }]
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return this.context.screenHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 70;
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    return (
      <div className="promate-table-header">
        <CustomerCardHeader />
      </div>
    )
  }

  renderFooter = () => {
    const { total, outstanding, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
      />
    );
  }

  render() {
    const { customers } = this.props
    return (
      <Col>
        <Table
          rowKey={'id'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(customers) }}
          columns={this.renderColumns()}
          dataSource={customers}
          size={'middle'}
          bordered={false}
          pagination={false}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(customers)}
        />
      </Col>
    )
  }
}

const mapStateToProps = state => {
  return {
    routes: getRoutes(state),
    salesUserHierarchy: getSalesUserHierarchy(state),
    customerCategories: getCustomerCategories(state),
    authData: getAuthData(state),
    total: getCustomerTotal(state, getSearchKey())
  };
};

CustomerTable.contextTypes = {
  screenHeight: PropTypes.object.isRequired,
  screenType: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { fetchSalesUserHierarchy })(CustomerTable);