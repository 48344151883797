import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaMapMarker, FaPhoneSquare } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';

class DistributorCard extends Component {

  render() {

    return (
      <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', padding: 18 }}>
        <Col xs={0} sm={0} md={2} lg={2}><span>DB Code</span></Col>
        <Col xs={0} sm={0} md={6} lg={6}><span >DB Name</span></Col>
        <Col xs={0} sm={0} md={2} lg={2}><span>Status</span></Col>
        <Col xs={0} sm={0} md={2} lg={2}><span>Contact No.</span></Col>
        <Col xs={0} sm={0} md={6} lg={6}><span>Address</span></Col>
        <Col xs={0} sm={0} md={3} lg={3}><span>RSM</span></Col>
        <Col xs={0} sm={0} md={3} lg={3}>ASM</Col>

        <Col xs={12} sm={12} md={0} lg={0}><span>Details</span></Col>
        <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'right' }}><span >Status</span></Col>
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  }

}
export default withRouter(DistributorCard);