import { combineReducers } from 'redux';
import { FETCH_SALES_RETURN_REASONS } from '../actions/salesReturnReasons';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_RETURN_REASONS: {
      const newState = { ...state };
      action.salesReturnReasons.forEach(reason => {
        newState[reason.Code] = reason;
      });
      return newState;
    }

    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_SALES_RETURN_REASONS: {
      const newState = [...state]
      action.salesReturnReasons.forEach(reason => {
        if (!newState.includes(reason.Code)) newState.push(reason.Code)
      })

      return newState;
    }

    default:
      return state;
  }
};

const reasons = combineReducers({
  byId,
  ids
});

export const getSalesReturnReasons = state => state && state.ids && state.byId && state.ids.map(id => state.byId[id]) || [];

export default reasons;