import * as fromApi from '../../api/generic';

export const FETCH_PURCHASING_INVOICE_LINES = 'FETCH_PURCHASING_INVOICE_LINES';
export const FETCH_PURCHASING_INVOICE_LINE = 'FETCH_PURCHASING_INVOICE_LINE';
// export const FAILED_TO_ADD_SALES_LINE = 'FAILED_TO_ADD_PURCHASING_INVOICE_LINE';
// export const REMOVE_SALES_LINE = 'REMOVE_PURCHASING_INVOICE_LINE';

  // export const addSalesLine = salesLine => dispatch => {
  //   return fromApi.add('SalesInvoiceLine', salesLine).then(response => {
  //     const { data, error } = response;
      
  //     if (error) { 
  //       dispatch({ type: FAILED_TO_ADD_SALES_LINE, salesLine: { ...salesLine, added: false } });
  //       return { error } 
  //     }
      
  //     dispatch({ type: FETCH_SALES_LINE, salesLine: { ...data, added: true } });
  //     return response;
  //   }).catch(error => {
  //     return { error }
  //   })
  // };

  export const fetchPurchasingInvoiceLines = filter => dispatch => {
    return fromApi.get('PurchaseInvoiceLine', filter).then(response => {
      const { data, error } = response;

      if (error) return { error };
      const purchasingInvoiceLines = data.map(item => ({ ...item, added: true }))
      dispatch({ type: FETCH_PURCHASING_INVOICE_LINES, purchasingInvoiceLines: purchasingInvoiceLines });
  
      return response;
    }).catch(error => {
      return { error }
    })
  };

  // export const updateSalesLine = salesLine => dispatch => {
  //   return fromApi.update('SalesInvoiceLine', salesLine).then(response => {
  //     const { data, error } = response;
      
  //     if (error) return { error };
      
  //     dispatch({ type: FETCH_SALES_LINE, salesLine: { ...data, added: true } });
  //     return response;
  //   }).catch(error => {
  //     return { error }
  //   })
  // };

  // export const deleteSalesLine = salesLine => dispatch => {
  //   return fromApi.deleteObject('SalesInvoiceLine', salesLine).then(response => {
  //     const { error } = response;
      
  //     if (error) return { error };
      
  //     dispatch({ type: REMOVE_SALES_LINE, salesLine });
  //     return response;
  //   }).catch(error => {
  //     return { error }
  //   })
  // };