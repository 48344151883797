import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchExpensesCategories } from '../../redux/actions/ExpensesCategories'
import { getAuthData, getExpensesCategories, isFetchingexpensesCategories } from "../../redux/reducers";

const { Option } = Select;

class SelectExpensesCategory extends Component {
  state = { loading: false }

  setDefaultFilter = () => {
    const { expensesCategories, onChange, value } = this.props;
    if (expensesCategories.length === 1 && value !== expensesCategories[0].Code) {
      setTimeout(() => {
        onChange('ExpCategory', expensesCategories[0].Code, true);
      }, 0);
    }
  }

  render() {
    const {
      expensesCategories,
      onChange,
      showSearch = true,
      placeholder = 'Select Category',
      allowClear = true,
      value,
      disabled = false,
      isFetchingExpensesCategories,
      style = { width: '100%' }
    } = this.props

    return (
      <Select
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={(value) => onChange('ExpCategory', value)}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={style}
        loading={isFetchingExpensesCategories}>
        {expensesCategories.map(trr =>
          <Option value={trr.Code}>{trr.Description}</Option>
        )}
      </Select>
    )
  }
}

const mapStateToProps = state => {
  const authData = getAuthData(state);
  return {
    authData,
    expensesCategories: getExpensesCategories(state),
    isFetchingExpensesCategories: isFetchingexpensesCategories(state)
  };
};

export default connect(mapStateToProps, { fetchExpensesCategories })(SelectExpensesCategory);