import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, deleteOrder } from "../../../redux/actions/orders";
import { fetchInventory } from "../../../redux/actions/inventory";
import { getOrder } from "../../../redux/reducers";
import { Row, Spin, Col, Button, Popconfirm, Card, Select } from "antd";
import FormItem from '../../common/FormItem';
import StockTransferHeader from './Header';
import StockTransferTable from './SalesLineTable';
import StockTransferFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { currentLocation } from '../../../utils/Location';
import { getAllWarehouses, getAllLocations } from '../../Routes/RouteDemoData'
import { getDemoTransfer } from '../dummyStockTransfer';
import { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';
const { Option } = Select;

export const defaultStockTransfer = {
  salesLines: [],
  status: "Open",
  sessionId: uuid(),
  stockTransferId: "new",
  fromWarehouseCode: undefined,
  fromLocationCode: undefined,
  toWarehouseCode: undefined,
  toLocationCode: undefined,
  transferDate: moment(),
  transferdBy: "CODE 001",
  totalDiscountAmount: 0,
  totalAmount: 54231,
  amountIncludingVat: 562349,
}

class Container extends Component {
  state = {
    loading: false,
    stockTransfer: { ...defaultStockTransfer }
  }

  componentDidMount() {
    //generateDemoStockTransfers()
    const { transferId } = this.props.match.params
    const newTransfer = transferId === 'new';

    if (newTransfer) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      //this.setState({ stockTransfer: { ...defaultStockTransfer } })
    } else {
      // this.setState({ loading: true })
      // this.props.fetchOrder(orderId).then(() => {
      //   this.setState({ loading: false })
      // })
      const { transferId } = this.props.match.params
      this.setState({ stockTransfer: getDemoTransfer(transferId) })
    }
  }

  componentWillReceiveProps = nextProps => {
    const { stockTransfer, match, toastManager } = nextProps;
    const { transferId } = match.params

    const newTransfer = transferId === 'new';
    //console.log(newTransfer)
    // if (newOrder) {
    //   this.setState({ order: { ...defaultOrder } })
    // } else if (order) {
    //   this.setState({ order: { ...order, status: order.status } })
    // }
    this.setState({ stockTransfer: getDemoTransfer(transferId) })
  }

  calculateTotalAmount = () => {
    //const { stockTransfer = {} } = this.props;
    const { stockTransfer } = this.state;
    const { salesLines = [] } = stockTransfer;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].transferQuantity + total;
    }
    //console.log(total)
    return total;
  }



  submitOrder = () => {
    const { addOrder, toastManager } = this.props;

    this.setState({ loading: true })

    const { order } = this.state;

    order.mapLocation = currentLocation;

    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
      this.props.history.replace('/salesOrders/' + "order 6")
    }, 2000)
    // addOrder(order).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
    //     defaultOrder.sessionId = uuid();
    //     this.props.history.replace('/orders/' + response.order.orderNo)
    //   } else {
    //     toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
  }

  shareOrder = () => {
    const { order } = this.state;
    const reportLink = order && order.links && order.links.length > 0 && order.links.find(link => link.includes('order-report'))
    // reportLink && window.open(Api.server+"/files/"+reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');

    // const { orderId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // printContainer.style.display = 'inline';
    // this.setState({ loading: true });
    // html2canvas(document.querySelector("#orderPrintDiv"), { width: 685 }).then((canvas) => {

    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = orderId + '.png';
    //   a.click();

    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventory = () => {
    const { transferId } = this.props.match.params
    const stockTransfer = transferId === 'new' ? { transferId: 'new' } : this.state.stockTransfer
    this.props.history.replace({ pathname: '/inventory', state: { resource: "stockTransfers", stockTransfer, id: transferId, multiSelect: true } })
  }

  navigateToConfirm = () => {
    const { transferId } = this.props.match.params
    this.props.history.replace({ pathname: '/stockTransfers/' + transferId + '/confirm', state: { order: this.state.stockTransfer } })
  }

  onDeleteOrder = () => {
    const { deleteOrder, toastManager, history, fetchInventory } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      toastManager.add('Stock Transfer successfully deleted.', { autoDismiss: true, appearance: 'success' });
      fetchInventory();
      history.goBack();
    }, 2000)
    // deleteOrder(this.state.order).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete order. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Order successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     fetchInventory();
    //     history.goBack();
    //   }
    // });
  }

  onUpdateStockTransfer = () => {
    const { deleteOrder, toastManager, history, fetchInventory } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      toastManager.add('Stock Transfer successfully Updated.', { autoDismiss: true, appearance: 'success' });
      history.goBack();
      this.setState({ loading: false });
    }, 500)
  }

  onSavedStockTransfer = () => {
    const { deleteOrder, toastManager, history, fetchInventory } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      toastManager.add('Stock Transfer successfully Saved.', { autoDismiss: true, appearance: 'success' });
      history.goBack();
      this.setState({ loading: false });
    }, 500)
  }

  renderWarehouses = () => {
    const { warehousesList } = this.props;
    //console.log(warehousesList)
    if (warehousesList && warehousesList.length > 0) {
      return (
        <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Col style={{ marginBottom: 10 }}>

                <FormItem label={"From Warehouse"} lg={24} md={24} sm={24} xs={24}>
                  <Select
                    placeholder={"Select Warehouse"}
                    // value={this.state.selectedWarehouse}
                    // onChange={this.onSelectWarehouse}
                    style={{ width: '100%' }}>
                    {warehousesList.map(warehouse => <Option key={warehouse} value={warehouse}>{warehouse}</Option>)}
                  </Select>
                </FormItem>
                {this.renderLocations("From Location")}
              </Col>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Col style={{ marginBottom: 10 }}>

                <FormItem label={"To Warehouse"} lg={24} md={24} sm={24} xs={24}>
                  <Select
                    placeholder={"Select Warehouse"}
                    // value={this.state.selectedWarehouse}
                    // onChange={this.onSelectWarehouse}
                    style={{ width: '100%' }}>
                    {warehousesList.map(warehouse => <Option key={warehouse} value={warehouse}>{warehouse}</Option>)}
                  </Select>
                </FormItem>
                {this.renderLocations("To Location")}
              </Col>
            </Col>
          </Row>
        </Card>
      )
    }
  }

  renderRemarks = () => {
    return (
      <Card className={'customerInfoCard'} style={{ margin: 5 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col style={{ marginBottom: 10 }}>
              <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24}>
                <TextArea rows={2} placeholder={"Type here"}
                // onChange={(e) => this.setState({ route: { ...this.state.route, remarks: e.target.value } })}
                >
                </TextArea>
              </FormItem>
            </Col>
          </Col>
        </Row>
      </Card>
    )
  }

  renderLocations = (lable) => {
    const { locationList } = this.props;
    if (locationList && locationList.length > 0) {
      return (
        <FormItem label={lable} lg={24} md={24} sm={24} xs={24}>
          <Select
            placeholder={"Select Location"}
            // value={this.state.selectedWarehouse}
            // onChange={this.onSelectWarehouse}
            style={{ width: '100%' }}>
            {locationList.map(location => <Option key={location} value={location}>{location}</Option>)}
          </Select>
        </FormItem>
      )
    }
  }

  renderActions = () => {
    const { match } = this.props;
    const { salesLines, status } = this.state.stockTransfer;
    const { transferId } = match.params
    const newOrder = transferId === 'new';

    if (newOrder) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={this.onSavedStockTransfer}>Save</Button>
          </Col>}
        {salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={this.navigateToConfirm}>{"Save & Confirm"}</Button>
          </Col>}
      </Row>
    } else {
      // if (order.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteOrder}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       order.salesLines && order.salesLines.length > 0 &&
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (order.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       order.links && order.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareOrder()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={this.onUpdateStockTransfer}>Update</Button>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={this.navigateToConfirm}>Confirm</Button>
          </Col>
        }

        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareOrder}
              >Share</Button>
            </Col>

          }
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareOrder}
              >Print</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  isValidAmount = value => {
    if (value.trim(" ", "") === "") {
      value = 0
    }
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;
    return validatorString.test(value);
  }


  onInputChange = (field, value, object, index, errorKey) => {
    //console.log(value, object, index)

    if (this.isValidAmount(value)) {
      const { stockTransfer } = this.state;
      const { salesLines = [] } = stockTransfer;
      const list = salesLines;
      const salesLine = list[index];
      const filteredList = list.slice(0, index).concat(list.slice(index + 1, list.length))
      //console.log(filteredList)
      var newSalesLine = { ...object, transferQuantity: +value }
      //console.log(newSalesLine)
      filteredList.splice(index, 0, newSalesLine);
      //console.log(filteredList)
      this.setState({ stockTransfer: { ...this.state.stockTransfer, salesLines: filteredList } });
    }
  }


  render() {
    const { customers, match } = this.props;
    const { transferId } = match.params
    const newTransfer = transferId === 'new';
    const { loading, stockTransfer } = this.state;
    const { status } = stockTransfer;
    //console.log(stockTransfer)

    return (
      <Spin spinning={loading} tip={"Loading Stock transfer"}>
        <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          <StockTransferHeader
            stockTransfer={stockTransfer}
            customers={customers}
            newTransfer={newTransfer}
            onSubmitOrder={this.submitOrder}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirm}
            onDeleteOrder={this.onDeleteOrder} />

          <div style={{ padding: '0 12px' }}>
            {newTransfer && this.renderWarehouses()}
            <StockTransferTable order={stockTransfer} scrollHeight={this.getScrollAreaHeight()} onInputChange={this.onInputChange}
              OnClickAddItem={this.navigateToInventory} />
          </div>
          {(status === "Open" || newTransfer) && this.renderRemarks()}
          <StockTransferFooter stockTransfer={stockTransfer} totalTransferedAmount={this.calculateTotalAmount()} />

          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId } = ownProps.match.params
  const warehousesList = getAllWarehouses();
  const locationList = getAllLocations();
  return { order: getOrder(state, orderId), warehousesList, locationList };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, deleteOrder, fetchInventory })(Container)));