import React, { Component } from 'react';
import { Row } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class OrderScreen extends Component {

  render() {
    const { docType } = this.props.match.params;
    let title = 'Order';
    switch (docType) {

      case 'CreditMemo':
        title = 'Customer Credit Invoice';
        break;
      case 'ReturnOrder':
        title = 'Sales Return Invoice';
        break;
      default:
        title = 'Sales Return Invoice';
        break;
    }
    return (
      <div>
        <PromatePageHeader
          title={title}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Row>
            <Container />
          </Row>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default OrderScreen;