import React from 'react';
import { Input, Button, DatePicker, Form, Row, Col } from "antd";
import CurrencyFormat from "react-currency-format";
import { dateFormatView } from "../../../const/Formats";

// const getLocationsTotal = filteredLocations => {
//   let total = 0;
//   filteredLocations.forEach(locationData => {
//     total = total + locationData.amount;
//   })
//   return total;
// }

const Footer = (props) => {
  const { filteredLocations, handleInputDateChange, handleSearchClick, filter, handleInputTextChange } = props;
  return (
    <div style={{
      backgroundColor: '#eceaea',
      height: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: -10,
      padding: 10
    }}>
      <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
        <Col span={12}>{filteredLocations.length > 0 ? 'Total ' + filteredLocations.length : 'No'} location{filteredLocations.length !== 1 && 's'}</Col>
        {/* <Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
          <CurrencyFormat
            value={getLocationsTotal(filteredLocations)}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            decimalScale={2} />
        </Col> */}
      </Row>

    </div>
  )
}

export default Footer