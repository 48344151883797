import React, { Component } from "react";
import { Col, Row } from "antd";

class SalesRepCardHeader extends Component {

  render() {
    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, marginRight: 15, backgroundColor: '#eceaea', padding: 10 }}>
          <Col xs={6} sm={5} md={2} lg={2}>ID</Col>
          <Col xs={12} sm={10} md={6} lg={6}>Name</Col>
          <Col xs={0} sm={0} md={6} lg={6}>Address</Col>
          <Col xs={0} sm={0} md={3} lg={3}>Contact No</Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={6} sm={5} md={4} lg={4} style={{ textAlign: 'right' }}>Amount</Col>
        </Row>
        {/* <Row style={styles.lowerRow}>
          <Col xs={6} sm={0} md={0} lg={0}><Tag style={styles.statusBottom} color={this.getStatusColor(status)}>{status || 'Active'}</Tag></Col>
          <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'left', color: '#808080', fontSize: 12 }}><FaPhoneSquare style={styles.icon} /><span>{contactNumber}</span></Col>
          <Col xs={18} sm={19} md={0} lg={0} style={{ color: '#808080', fontSize: 12 }}><FaMapMarker style={styles.icon} /><span>{address}</span></Col>
        </Row> */}
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  }

}
export default SalesRepCardHeader;