import React from "react";
import { SalesOrder, SalesReturnOrder, CustomerCreation } from "./components";

export default class SyncReport extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                    [...this.props.data.callsData].length > 0 ? this.props.data.callsData.map((call, index) => {
                        if (call.StartReasonCode == 'CS-0001') {
                            return <SalesOrder key={'parent' + index} call={call} orders={this.props.data.salesOrders} isLastElement={this.props.data.callsData.length == (index + 1)} />;
                        }
                        if (call.StartReasonCode == 'CS-0002') {
                            return <CustomerCreation key={'parent' + index} call={call} customers={this.props.data.customerCreationData} isLastElement={this.props.data.callsData.length == (index + 1)} />;
                        }
                        if (call.StartReasonCode == 'CS-0005') {
                            return <SalesReturnOrder key={'parent' + index} call={call} orders={this.props.data.salesReturnOrders} isLastElement={this.props.data.callsData.length == (index + 1)} />;
                        }
                        return (<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '20px' }}>
                            <h1>No Related Report Details Found</h1>
                        </div>);
                    }) : (<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '20px' }}>
                        <h1>No Offline Data Found</h1>
                    </div>)
                }
            </>
        );
    }
}



