import { Col, Row, Select, Spin, Typography } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { fetchAllMonths, fetchAllYears, fetchTargetSchedulerDetailsByFilter, updateFilter } from '../../../redux/actions/targetScheduler';
import { fetchTerritories } from "../../../redux/actions/territories";
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { getAllMonths, getAllTargetSchedulerDetails, getAllYears, getAuthData, getFilter, getSalesUserHierarchy, getTerritories } from "../../../redux/reducers";
import { StyledButton } from "../common/Buttons";
import { getURLParams } from "../common/helpers";
import UserHierachy from "../common/UserHierachy";

const { Text } = Typography;
const { Option } = Select;

export class Header extends Component {
    componentDidMount = async () => {
        const { loading, setLoading, toastManager, fetchTargetSchedulerDetailsByFilter, fetchTerritories, fetchSalesUserHierarchy, authData, fetchAllYears, fetchAllMonths, updateFilter } = this.props;
        setLoading(true);

        await fetchSalesUserHierarchy(authData);
        await fetchAllYears();
        await fetchAllMonths();

        const res = await getURLParams(this.props);
        let updatedFilter = {};
        if (res) {
            updatedFilter = await updateFilter({ ...res }, this.props);
        } else {
            updatedFilter = await updateFilter({
                Month: "JAN",
                Year: new Date().getFullYear(),
            }, this.props);
        }
        if (updatedFilter.treeData.length > 0 && updatedFilter.treeData[0].role === "CSR") {
            const results = await fetchTerritories({ UserTagFilterOne: updatedFilter.UserTag });
            const { records } = results;
            const months = await fetchAllMonths();
            // Find Month numeric value
            const monthNamesArray = months?.records.map((month) => (month.Code));

            const res = await fetchTargetSchedulerDetailsByFilter({
                Year: updatedFilter.Year,
                Month: String(monthNamesArray.indexOf(updatedFilter.Month) + 1),
                UserTag: updatedFilter.UserTag,
                TerrCode: records[0]?.TerrCode
            });
            if (loading) {
                await updateFilter({
                    TerrCode: records[0]?.TerrCode
                }, this.props);
            }
            if (res?.error) {
                toastManager.add("In the system does not have any details", { autoDismiss: true, appearance: 'error' });
            }
        } else {
            toastManager.add("Can't find any CSR user for fetch details", { autoDismiss: true, appearance: 'error' });
        }
        setLoading(false);
    }

    handleChange = async (name, value) => {
        const { updateFilter } = this.props;
        await updateFilter({ [name]: value }, this.props);
    };

    onHandleSearch = async () => {
        const { filter, months, setLoading, toastManager, fetchTargetSchedulerDetailsByFilter, fetchTerritories } = this.props;

        setLoading(true);
        const results = await fetchTerritories({ UserTagFilterOne: filter.UserTag });
        const { records } = results;
        const monthNamesArray = months?.records.map((month) => (month.Code));
        const res = await fetchTargetSchedulerDetailsByFilter({
            UserTag: filter.UserTag,
            Year: Number(filter.Year),
            Month: String(monthNamesArray.indexOf(filter.Month) + 1),
            TerrCode: records[0]?.TerrCode ?? ""
        });

        if (res?.error) {
            toastManager.add("In the system does not have any details", { autoDismiss: true, appearance: 'error' });
        }
        setLoading(false);
    }

    render() {
        const { filter, targetScheduler } = this.props;
        const details = targetScheduler?.records.length > 0 ? targetScheduler?.records[0] : null;
        return (
            <Spin spinning={false} tip={"Loading"}>
                <UserHierachy onUpdate={(value) => { }} />
                <Row style={{ padding: "0px 5px" }} gutter={[5, 5]}>
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Year:</Text>
                        <Select
                            value={filter.Year}
                            style={{ width: "100%", ...styles.text }}
                            onChange={(value) => this.handleChange('Year', value)}
                        >
                            {this.props?.years?.records.map((year, index) =>
                                (<Option key={index + year.Code} value={year.Code} style={styles.text}>{year.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Month:</Text>
                        <Select
                            style={{ width: "100%", ...styles.text }}
                            value={filter.Month}
                            onChange={(value) => this.handleChange('Month', value)}
                        >
                            {this.props?.months?.records.map((month, index) =>
                                (<Option key={index + month.Code} value={month.Code} style={styles.text}>{month.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={styles.container}>
                        <StyledButton color="#1890ff" label="Search" onClick={() => this.onHandleSearch()} width={100} />
                    </Col>
                    <Col xs={24} sm={12} lg={8} style={styles.container}>
                        <Text style={styles.text}>Monthly Target:</Text>
                        <Text style={styles.text}>{details?.MonthTargetAmt ?? "0.00"}</Text>
                    </Col>

                </Row>
                <Row style={{ padding: "0px 5px" }} gutter={[5, 5]}>
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={styles.text}>Avg Sale Last 3 Months:</Text>
                        <Text style={styles.text}>{details?.AvgSalesThreeMonth ?? "0.00"}</Text>
                    </Col>
                    <Col xs={24} sm={12} lg={3} style={styles.container}>
                        <Text style={styles.text}>Total Outlets:</Text>
                        <Text style={styles.text}>{details?.TotalOutlet ?? "0.00"}</Text>
                    </Col>
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={styles.text}>Last Month Target:</Text>
                        <Text style={styles.text}>{details?.LMonthTarget ?? "0.00"}</Text>
                    </Col>
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={styles.text}>Last Month Achievement:</Text>
                        <Text style={styles.text}>{details?.LMonthAch ?? "0.00"}</Text>
                    </Col>
                    <Col xs={24} sm={12} lg={3} style={styles.container}>
                        <Text style={styles.text}>(%):</Text>
                        <Text style={styles.text}>{details?.LMonthAchPre ?? "0.00"}</Text>
                    </Col>
                </Row>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    months: getAllMonths(state),
    years: getAllYears(state),
    territories: getTerritories(state),
    targetScheduler: getAllTargetSchedulerDetails(state),
    filter: getFilter(state)
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchTerritories,
    fetchTargetSchedulerDetailsByFilter,
    updateFilter
}

export default withRouter(withToastManager(connect(mapStateToProps, mapDispatchToProps)(Header)));

const styles = {
    container: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        gap: 10,
        justifyContent: "flex-start",
    },
    text: {
        color: '#000000',
        textAlign: "left",
    }
}