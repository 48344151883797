import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Spin } from "antd";
import PropTypes from 'prop-types';
import { fetchCalls } from "../../../redux/actions/calls";
import { getCalls, getAuthData, getTopPosition, getFlatPositions, getCallsBySearchKey, getFilteredRoutes } from "../../../redux/reducers";
import CallsTable from "./CallsTable";
import moment from 'moment';
import { transactionTypes } from '../CallDemoData';
import { isFetchingCalls } from '../../../redux/reducers';
import { updateFilterUserHierarchy } from '../../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../../const/Formats';
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import LocationMap from '../../common/LocationMap';

import { decodeUrlSearchParams, getSearchKey, handleUrlSearchParams, redirectToUrlState, shouldFilterUpdate } from '../../../utils/Search';

const BASE_URL = '/calls';
const transactionTypeOptions = {
  'Customer': 0,
  'Sales': 1,
  'Payment': 2,
  'Transfer': 3,
  'Purchase': 4,
  'Other': 5
}

class CallsTableContainer extends Component {
  state = {
    filter: {
      text: undefined,
      from: moment(new Date().setDate(new Date().getDate() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1,
    showMap: false
  }

  componentDidMount() {
    const { authData: { userId }, match } = this.props;
    const { customerId } = match.params;

    if (customerId)
      this.setState({
        filter: {
          ...this.state.filter,
          UserCode: userId,
          CustNo: customerId
        }
      });
    else
      this.setState({
        filter: {
          ...this.state.filter,
          UserCode: userId
        }
      });
    this.handleUrlSearchParams(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    handleUrlSearchParams(BASE_URL, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadCalls(nextProps);
      });
    }, this.state.filter);
  }

  loadCalls = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    this.setState({ filter });
    this.fetchCalls(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(BASE_URL, this.props, this.state.filter, page);

  fetchCallPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchCalls(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchCalls(filter, currentPage, currentSearchKey);
  }

  fetchCalls = (filter, page, searchKey) => {
    this.props.fetchCalls(this.getRequestFilter(filter), page, searchKey);
  }

  getRequestFilter = filter => {
    const requestFilter = { ...filter };

    requestFilter.StartDateFilter = moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate);
    requestFilter.from = undefined;
    requestFilter.to = undefined;

    requestFilter.TransactionTypeFilter = transactionTypeOptions[requestFilter.TransactionCap];
    requestFilter.TransactionCap = undefined;

    requestFilter.UserCode = undefined;
    requestFilter.UserHierarchy = undefined;

    return requestFilter;
  }

  getFilteredCalls = () => {
    const { calls } = this.props;
    const { text, RouteCode: routeCode, UserCode: userCode, CustNo: custNo } = this.state.filter;

    let filteredCalls = calls;

    filteredCalls = calls.filter(call => {
      const { No, CustNo, CustName, UserCode, Status, UserName, Productive, RouteCode } = call;

      const idFilter = text ? this.stringCompare(No, text) : true
      const customerNameFilter = text ? this.stringCompare(CustName, text) : true;
      // const customerCodeFilter = custNo ? this.stringCompare(CustNo, custNo) : true;
      // const createByFilter = userCode ? this.stringCompare(UserCode, userCode) : true;
      // const routeCodeFilter = routeCode ? this.stringCompare(RouteCode, routeCode) : true;
      const salesPersonNameFilter = text ? this.stringCompare(UserName, text) : true;
      const statusFilter = text ? this.stringCompare(Status, text) : true;
      const productiveStatusFilter = text ? this.stringCompare(Productive, text) : true;

      return (idFilter || customerNameFilter || statusFilter || salesPersonNameFilter || productiveStatusFilter);
    })

    return filteredCalls;
  }

  getOutstandingTotals = expenseList => {
    let totalOutstanding = 0
    expenseList.forEach(expense => totalOutstanding = totalOutstanding + expense.amount)
    return totalOutstanding;
  }

  stringCompare = (string1, string2) => string1.toLowerCase().includes(string2.toLowerCase());

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    switch (filter) {
      case 'UserHierarchy':
        this.setState({ filter: updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition) });
        break;
      case 'UserCode':
        this.setState({ filter: { ...this.state.filter, [filter]: value, RouteCode: undefined, CustNo: undefined } });
        break;
      case 'RouteCode':
        this.setState({ filter: { ...this.state.filter, [filter]: value, CustNo: undefined } });
        break;
      default:
        this.setState({ filter: { ...this.state.filter, [filter]: value } });
        break;
    }
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }


  renderFilter = () => {
    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={{ lg: 24, md: 24, sm: 24, xs: 24 }}
          FROM_DATE_PICKER={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          TO_DATE_PICKER={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SELECT_ROUTE={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SELECT_OUTLET={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SELECT_TRANSACTION_TYPE={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SEARCH_TEXT={{ lg: 0, md: 0, sm: 0, xs: 0 }}
          SEARCH_BUTTON={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          handleInputChange={this.handleInputChange}
          handleSearchClick={this.handleSearchClick}
          handleInputDateChange={this.handleInputDateChange}
          filter={this.state.filter}
          setDefaultCustomerOption={this.setDefaultCustomerOption}
          setDefaultRouteOption={this.setDefaultRouteOption}
        />

      </div>
    )
  }

  onMapViewSelect = selected => this.setState({ showMap: selected });

  renderMobileViewSwitcher = () =>
    <div style={styles.alignRight}>
      <Button.Group>
        <Button icon='menu' size='small' onClick={() => this.onMapViewSelect(false)}>List</Button>
        <Button icon='environment' size='small' onClick={() => this.onMapViewSelect(true)}>Map</Button>
      </Button.Group>
    </div>

  renderDesktopViewSwitcher = () =>
    <div style={styles.desktopSwitcherButtonGroup}>
      <div style={styles.desktopSwitcherButtons}>
        <Button block icon='menu' onClick={() => this.onMapViewSelect(false)} />
      </div>

      <div style={styles.desktopSwitcherButtons}>
        <Button block icon='environment' onClick={() => this.onMapViewSelect(true)} />
      </div>
    </div>


  renderHeader = () =>
    <div style={styles.body} className='promate-table-header'>
      {this.context.screenType === 'DESKTOP' ? this.renderDesktopHeader() : this.renderMobileHeader()}
    </div>

  renderMobileHeader = () => [
    this.renderFilter(),
    this.renderMobileViewSwitcher()
  ]

  renderDesktopHeader = () =>
    <Row>
      <Col xs={23}>{this.renderFilter()}</Col>
      <Col xs={1}>{this.renderDesktopViewSwitcher()}</Col>
    </Row>

  renderTable() {
    const { fetchingCalls, calls } = this.props;
    const { filter, currentPage } = this.state;
    const filteredCalls = this.getFilteredCalls();

    return (
      <Spin spinning={fetchingCalls && calls.length === 0} tip={"Loading Calls"}>
        <CallsTable
          currentPage={currentPage}
          filter={filter}
          calls={filteredCalls}
          handleInputDateChange={this.handleInputDateChange}
          handleInputChange={this.handleInputChange}
          handleSearchClick={this.handleSearchClick}
          transactionTypes={transactionTypes}
          setDefaultCustomerOption={this.setDefaultCustomerOption}
          setDefaultRouteOption={this.setDefaultRouteOption}
          onPaginationChange={this.handlePaginationChange}
        />
      </Spin>
    )
  }

  renderMap = () => {
    const { fetchingCalls, calls, routes } = this.props;
    const { RouteCode } = this.state.filter;
    const routesToShow = RouteCode ? [routes.find(route => route.RouteCode === RouteCode)] : routes;

    if (fetchingCalls) return (
      <Spin spinning={fetchingCalls} tip={'Loading Calls'}>
        <div style={{ height: 400, width: '100%' }} />
      </Spin>
    );

    const height = this.context.screenType === 'MOBILE' ? window.innerHeight * 0.8 : undefined;
    return (
      <div style={{ ...styles.mapContainer, height: height }}>
        <LocationMap height={height} customers={[]} calls={calls} routes={routesToShow} />
      </div>
    );
  }

  render() {
    const { showMap } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {showMap ? this.renderMap() : this.renderTable()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    calls: getCallsBySearchKey(state, getSearchKey()),
    fetchingCalls: isFetchingCalls(state),
    authData: getAuthData(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    routes: getFilteredRoutes(state)
  };
};

const styles = {
  body: {
    paddingLeft: 10,
    paddingRight: 10
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  desktopSwitcherButtonGroup: {
    marginTop: 2
  },
  desktopSwitcherButtons: {
    padding: 3
  },
  mapContainer: {
    marginTop: 10
  }
}

CallsTableContainer.contextTypes = {
  screenType: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps, { fetchCalls })(CallsTableContainer));