import React, { Component } from "react";
import { Col, Row, Tag } from "antd";

class ExpenseCardHeader extends Component {

  render() {
    return (
      <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', padding: 10, paddingRight: window.innerWidth < 768 ? 10 : 24 }}>
        <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>No</Col>
        <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Date</Col>
        <Col xs={0} sm={0} md={4} lg={3} style={{ textAlign: 'left' }}>Expense Category</Col>
        <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>Doc No</Col>
        <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>DB Code</Col>
        <Col xs={0} sm={0} md={4} lg={7} style={{ textAlign: 'left' }}>DB Name</Col>
        <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>Status</Col>
        <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right' }}>Amount</Col>

        {/* mobile view */}
        <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Details</Col>
        <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'Center' }}>Date</Col>
        <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>Amount</Col>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

export default ExpenseCardHeader;