import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { getDemoCreditNotesByCount, generateDemoCreditNotes } from '../../CreditNotes/dummyCreditNotes'
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class CreditNoteList extends Component {

  componentDidMount = () => generateDemoCreditNotes()

  getColumns = () => {
    const columns = [{
      title: 'CreditNote ID',
      dataIndex: 'creditNoteNo',
      key: 'creditNoteNo',
      render: text => <p>{text}</p>,
    }, {
      title: 'Customer ID',
      dataIndex: 'billToCustomerCode',
      key: 'billToCustomerCode',
      render: text => <p>{text}</p>,
    }, {
      title: 'Customer Name',
      dataIndex: 'billToCustomerName',
      key: 'billToCustomerName',
      render: text => <p>{text}</p>,
    }, {
      title: 'CreditNote Date',
      dataIndex: 'creditNoteDate',
      key: 'creditNoteDate',
      render: text => <p>{text && moment(text).format(dateFormatView)}</p>,
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: text => text && <Tag>{text}</Tag>,
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    }, {
      title: 'Balance',
      dataIndex: 'balanceToSettle',
      key: 'balanceToSettle',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    }]

    return columns;
  }

  getRowSelection = () => {
    const { selectedRowKeys, selectedRows } = this.props;
    return {
      selectedRows,
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.handleOnClickSelectCreditNotes(selectedRowKeys, selectedRows)
      }
    };
  }

  render() {
    return (
      <Table
        rowSelection={this.getRowSelection()}
        columns={this.getColumns()}
        dataSource={getDemoCreditNotesByCount(1)}
        pagination={false}
        size={'small'}
        scroll={{ x: 800, y: 470 }} />
    )
  }
}

export default CreditNoteList;