import { combineReducers } from 'redux';
import { FETCH_ROUTES, FETCH_ROUTE, START_FETCH_ROUTES, FINISH_FETCH_ROUTES } from '../actions/routes';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROUTES:
      const newState = { ...state };
      action.routes.forEach(route => newState[route.RouteCode] = route);
      return newState;
    case FETCH_ROUTE: {
      const newState = { ...state };
      newState[action.route.RouteCode] = action.route;
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_ROUTES:
      return action.routes.map(route => route.RouteCode);
    default:
      return state;
  }
};

export const byUserId = (state = [], action) => {
  switch (action.type) {
    case FETCH_ROUTES: {
      const newState = {};
      const ids = newState[action.filter.UserCode] || [];
      action.routes.forEach(route => {
        ids.push(route.RouteCode);
      });
      newState[action.filter.UserCode] = ids;
      return newState;
    }
    default:
      return state;
  }
};

export const fetchingRoutes = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_ROUTES:
      return true;

    case FINISH_FETCH_ROUTES:
      return false;

    default:
      return state;
  }
};

export const totalBySearchKey = (state = 0, action) => {
  switch (action.type) {
    case FETCH_ROUTES:
      const newState = { ...state };
      newState[action.searchKey] = action.total;
      return newState;
    default:
      return state;
  }
}

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROUTES:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.routes.map(route => route.RouteCode)
      };

    default:
      return state;
  }
}

const routes = combineReducers({
  byId,
  ids,
  byUserId,
  fetchingRoutes,
  totalBySearchKey,
  bySearchKey
});

export default routes;

export const getRoutes = state => {
  return state.ids.map(id => state.byId[id])
}

export const getRoutesByUser = (state, UserCode) => {
  const ids = state.byUserId[UserCode] || [];
  return ids.map(id => state.byId[id])
}

export const getRoute = (state, routeId) => state.byId[routeId]

export const isFetchingRoutes = state => state.fetchingRoutes;

export const getTotal = (state, key) => state.totalBySearchKey[key] || 0;

export const getBySearchKey = (state, key) => {
  const ids = state.bySearchKey[key] || [];
  return ids.map(id => state.byId[id]);
}
