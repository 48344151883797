import { combineReducers } from 'redux';
import { FETCH_DISTRIBUTORS, FETCH_DISTRIBUTOR, START_FETCH_DISTRIBUTORS, FINISH_FETCH_DISTRIBUTORS, START_FETCH_MAP_DISTRIBUTORS, FINISH_FETCH_MAP_DISTRIBUTORS, FETCH_MAP_DISTRIBUTORS, SET_ACTIVE_SEARCH_KEY } from '../actions/distributors';

export const defaultCombinedFilter = {
	brandFilter: [],
	category1Filter: [],
	category2Filter: [],
	productTypeFilter: [],
	textFilter: '',
};

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_DISTRIBUTORS:
			const newState = { ...state };
			action.distributors.forEach(distributor => newState[distributor.Code] = distributor);
			return newState;
		case FETCH_DISTRIBUTOR:
			const distributor = action.distributor
			state[distributor.Code] = distributor
			return state;
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_DISTRIBUTORS: {
			return action.distributors.map(distributor => distributor.Code);
		}
		case FETCH_DISTRIBUTOR:
			const distributor = action.distributor

			if (state && state.length > 0 && !state.includes(distributor.Code)) state.push(distributor.Code)
			return [...state];
		default:
			return state;
	}
};

export const mapDistributors = (state = [], action) => {
	switch (action.type) {
		case FETCH_MAP_DISTRIBUTORS:
			return action.distributors;

		default:
			return state;
	}
}

export const totalBySearchKey = (state = {}, action) => {
	switch (action.type) {
		case FETCH_DISTRIBUTORS:
			const newState = { ...state };
			newState[action.searchKey] = action.total;
			return newState;
		default:
			return state;
	}
}

export const fetchingDistributors = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_DISTRIBUTORS:
			return true;

		case FINISH_FETCH_DISTRIBUTORS:
			return false;

		default:
			return state;
	}
};

export const fetchingMapDistributors = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_MAP_DISTRIBUTORS:
			return true;

		case FINISH_FETCH_MAP_DISTRIBUTORS:
			return false;

		default:
			return state;
	}
};

export const activeSearchKey = (state = '', action) => {
	switch (action.type) {
		case SET_ACTIVE_SEARCH_KEY:
			return action.searchKey || state;
		default:
			return state;
	}
}

export const distributorsBySearchKey = (state = {}, action) => {
	switch (action.type) {
		case FETCH_DISTRIBUTORS:
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.distributors.map(distributor => distributor.Code)
			};

		default:
			return state;
	}
}

const distributors = combineReducers({
	byId,
	ids,
	totalBySearchKey,
	mapDistributors,
	fetchingDistributors,
	fetchingMapDistributors,
	activeSearchKey,
	distributorsBySearchKey
});

export default distributors;

export const getDistributors = state => {
	return state.ids.map(id => state.byId[id]).filter(distributor => !!distributor)
}

export const getMapDistributors = state => state.mapDistributors;

export const getTotal = (state, searchKey) => state.totalBySearchKey[searchKey] || 0;

export const getDistributor = (state, distributorId) => state.byId[distributorId]

export const isFetchingDistributors = state => state.fetchingDistributors;
export const isFetchingMapDistributors = state => state.fetchingMapDistributors;

export const getBySearchKey = (state, searchKey) => {
	const ids = state.distributorsBySearchKey[searchKey] || [];
	return ids.map(id => state.byId[id]);
}