import React, { Component } from 'react';
import { PageHeader } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class GRNConfirmScreen extends Component {

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Confirm GRN'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Container />
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default GRNConfirmScreen;