import Api from './consts';
import { uploadFile, downloadFile } from './commonFetch';

export const upload = (file, location, name) => {
  const { url, method } = Api.files.upload;

  const fd = createFormData(file, location, name);

  return uploadFile(url(), { method, body: fd }, 'path');
}

export const download = path => {
  const { url, method } = Api.files.download;
  return downloadFile(url(path), { method }, 'file');
}

const createFormData = (file, location, name) => {
  const fd = new FormData();
  fd.append('file', file);
  fd.append('location', location);

  if (name) fd.append('name', name);

  return fd;
}