import React, { Component } from "react";
import { Table, Button, DatePicker, Form, Row, Col } from "antd";
import InvoiceCard from "./InvoiceCard";
import { withRouter } from 'react-router-dom';
import CurrencyFormat from "react-currency-format";
import Input from '../../components/common/EnglishInput';
import { dateFormatView } from "../../const/Formats";

class InvoicesTable extends Component {

  state = {
    page: 0,
    pageSize: 10
  }

  navigateToInvoiceView = invoice => this.props.history.push("/invoices/" + invoice.invoiceNo)

  renderColumns = () => {
    return [{
      render: invoice => <InvoiceCard invoice={invoice} navigateToInvoiceView={() => this.navigateToInvoiceView(invoice)} />
    }]
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const headerHeight = header && header[0] && header[0].clientHeight;
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;
    return window.innerHeight - (headerHeight + tableFooterHeight);
  }

  getTableHeight = invoices => {
    if (invoices.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderFooter = invoices => {
    const { handleInputDateChange, handleSearchClick, filter, handleInputTextChange } = this.props;
    return (
      <div style={{ marginLeft: -15, marginRight: -15 }}>
        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
          <Col span={12}>{invoices.length > 0 ? 'Total ' + invoices.length : 'No'} invoice{invoices.length !== 1 && 's'}</Col>
          <Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
            <CurrencyFormat
              value={this.getTotals(invoices)}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              decimalScale={2} />
          </Col>
        </Row>
        <Form layout={'vertical'}>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Col lg={10} md={10} sm={24} xs={24} style={{ padding: 5 }}>
                <Input placeholder="Search Text" onChange={value => handleInputTextChange(value)} />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ padding: 5 }}>
                <DatePicker
                  format={dateFormatView}
                  value={filter.from}
                  placeholder="From"
                  onChange={(date, dateString) => handleInputDateChange("from", date)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ padding: 5 }}>
                <DatePicker
                  format={dateFormatView}
                  value={filter.to}
                  placeholder="To"
                  onChange={(date, dateString) => handleInputDateChange("to", date)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col lg={4} md={4} sm={24} xs={24} style={{ padding: 5 }}>
                <Button type="primary" block onClick={handleSearchClick}>Search</Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }

  getTotals = invoices => {
    let total = 0;
    invoices.forEach(invoice => {
      total = total + invoice.totalAmount;
    })
    return total;
  }

  getFilteredInvoices = () => {
    const { invoices, filter } = this.props;
    let filteredInvoices = invoices;

    if (filter && filter.text) {
      filteredInvoices = invoices.filter(invoice => {
        let invoiceNoTerm = invoice.invoiceNo;
        invoiceNoTerm = invoiceNoTerm.replace(/---/g, "");
        invoiceNoTerm = invoiceNoTerm.replace(/_/g, "");

        let searchTerm = filter.text;
        searchTerm = searchTerm.replace(/\//g, "");
        searchTerm = searchTerm.replace(/_/g, "");

        return invoiceNoTerm.toLowerCase().includes(searchTerm.toLowerCase())
          || invoice.sellToCustomerCode.toLowerCase().includes((filter.text).toLowerCase())
          || invoice.sellToCustomerName.toLowerCase().includes((filter.text).toLowerCase())
      }
      )
    }

    return filteredInvoices;
  }

  render() {
    const filteredInvoices = this.getFilteredInvoices();
    return (
      <div>
        <Table rowKey={'invoiceNo'} rowClassName={'invoiceRow'} columns={this.renderColumns()} bodyStyle={{ minHeight: this.getTableHeight(filteredInvoices) }} showHeader={false} dataSource={filteredInvoices} size={'small'} scroll={{ y: this.getScrollAreaHeight() }} pagination={false} footer={() => this.renderFooter(filteredInvoices)} />
      </div>
    )
  }
}

export default withRouter(InvoicesTable);