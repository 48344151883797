import React, { Component } from 'react'
import { PageHeader, Spin, Button } from 'antd'
import styles from './styles'
import CollectionTable from './CollectionTable'
import moment from 'moment';
import { fetchCollectionsByCustomer, fetchCollections } from "../../redux/actions/collection";
import { getCollections, getCollectionsByCustomer } from "../../redux/reducers";
import { connect } from 'react-redux'
import { showLoadingMessage } from '../common/Message';
import { generateData } from './CollectionDemoData';
import { getAuthData } from '../../redux/reducers';
import PromatePageHeader from '../common/PromatePageHeader';
import { FaPlus } from 'react-icons/fa';
class Collections extends Component {
  state = {
    loading: false,
    filter: {
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    const { customerId } = this.props.match.params;
    if (customerId) {
      this.setState({ filter: { ...this.state.filter, customerId } }, () => this.fetchAllByCustomer());
    } else {
      this.fetchAllByDateRange();
    }
  }

  fetchAllByDateRange = () => {
    this.showLoadingMessage();
    this.setState({ loading: true });
    this.props.fetchCollections(this.state.filter).then(() => {
      this.setState({ loading: false });
      this.hideLoadingMessage();
    })
  }

  fetchAllByCustomer = () => {
    this.showLoadingMessage();
    this.setState({ loading: true });
    this.props.fetchCollectionsByCustomer(this.state.filter).then(() => {
      this.setState({ loading: false });
      this.hideLoadingMessage();
    });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing collections.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })


  render() {
    const { collections } = this.props;
    const { loading, filter } = this.state;

    return (
      <div>
        <PromatePageHeader
          title={'Collections'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={this.showNewButton() && [
            <Button style={window.innerWidth < 768 ? styles.addBtnMobile : styles.addBtnWeb} onClick={this.onClickNew}>
              {
                window.innerWidth < 768 ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>


          <Spin spinning={loading && collections.length === 0} tip={"Loading Collections"}>
            <CollectionTable
              collections={collections}
              handleInputDateChange={this.handleInputDateChange}
              handleSearchClick={this.fetchAllByDateRange}
              filter={filter}
              handleInputTextChange={this.handleInputTextChange} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { match } = this.props;
    const url = match.url
    console.log(url)
    this.props.history.push(url + "/new");
    //this.props.history.push("/collections/new");
  }

  showNewButton = () => {
    const { match } = this.props;
    const { authData: { userId, roleCode } } = this.props;

    const insideCustomer = match.path.includes("customer");
    const insideDistributor = match.path.includes("distributors");
    const insideSalesRep = match.path.includes("salesRepresentatives");

    console.log(insideCustomer, insideDistributor, insideSalesRep, roleCode)
    if (roleCode === "ADMIN" || roleCode === "ADMIN_ACCOUNTANT") {
      if (!insideDistributor && !insideSalesRep && insideCustomer) {
        return true;
      } else {
        return false;
      }
    } else if (roleCode === "DISTRIBUTOR") {
      if (!insideSalesRep && insideCustomer) {
        return true;
      } else {
        return false;
      }
    } else if (roleCode === "SALES_REPRESENTATIVE") {
      return true;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;

  //const collections = customerId ? getCollectionsByCustomer(state, customerId) : getCollections(state);
  const collections = generateData();
  return { collections: sortListByOrderDate(collections), authData: getAuthData(state) };
};

const sortListByOrderDate = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (collection1, collection2) {
    const collectionDate1 = parseInt(collection1.collectionDate);
    const collectionDate2 = parseInt(collection2.collectionDate);

    if (collectionDate1 < collectionDate2) return 1;
    if (collectionDate1 > collectionDate2) return -1;
    return 0;
  }) : []
  return sortedList;
}

export default connect(mapStateToProps, { fetchCollections, fetchCollectionsByCustomer })(Collections);