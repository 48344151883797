import React, { Component } from 'react';
import { Card, Col, Row, Button, Modal } from 'antd';
import Radium from 'radium';
import { ProMateGreen, ProMateGreenBorder } from '../../../const/Theme';
import { Link, withRouter } from 'react-router-dom';
import { FaBoxes, FaCalendarPlus, FaFileInvoice, FaInbox, FaCartArrowDown } from 'react-icons/fa';
import { getAuthData } from '../../../redux/reducers';
import { connect } from "react-redux";
const { confirm } = Modal;

const options = [
  {
    title: 'CUSTOMERS',
    key: 'CUSTOMERS',
  },
  // {
  //   title: 'COLLECTIONS',
  //   key: 'COLLECTIONS'
  // },
  {
    title: 'SALES ORDERS',
    key: 'SALES_ORDERS',
  },
  {
    title: 'SALES INVOICES',
    key: 'SALES_INVOICES',
  },
  {
    title: 'RECEIPTS',
    key: 'RECEIPTS',
  },
  {
    title: 'CREDIT NOTES',
    key: 'CREDIT_NOTES',
  },
  // {
  //   title: 'NEW CREDIT NOTE',
  //   key: 'NEW_CREDIT_NOTE'
  // },
  {
    title: 'SUPPLIER RETURNS',
    key: 'SUPPLIER_RETURNS',
  },
  {
    title: 'EXPENSES',
    key: 'EXPENSES'
  },
  // {
  //   title: 'NEW EXPENSES',
  //   key: 'NEW_EXPENSES'
  // },
  // {
  //   title: 'VIEW EXPENSES CATEGORIES',
  //   key: 'VIEW_EXPENSES_CATEGORIES'
  // },
  // {
  //   title: 'NEW EXPENSES CATEGORIES',
  //   key: 'NEW_EXPENSES_CATEGORIES'
  // },
  {
    title: 'INVENTORY',
    key: 'INVENTORY'
  },
  {
    title: 'CALLS',
    key: 'CALLS'
  },

];



class Actions extends Component {

  showConfirmCreateOrder = () => {
    const { submitOrder } = this.props;

    confirm({
      title: 'Are you sure ?',
      content: 'This will create a new order.',
      onOk() {
        submitOrder();
      },
    });
  }

  componentDidMount() {
    const { authData: { userId, roleCode } } = this.props;
    const { match } = this.props;

    //console.log(options);
    const insideDistributor = match.path.includes("distributors");
    if (roleCode === "ADMIN" && !insideDistributor) {

      var isSetOfAvaialbe = false
      for (let option of options) {
        //console.log(option.key)
        if (option.key === "WAREHOUSES") {
          isSetOfAvaialbe = true
        }
      }

      if (!isSetOfAvaialbe) {
        options.push(
          {
            title: 'WAREHOUSES',
            key: 'WAREHOUSES'
          },
        )
      }

      //console.log(options);
    }
  }

  getTileIcon = (title, disabled) => {
    switch (title) {
      case 'NEW ORDER':
        return <FaCalendarPlus style={disabled ? styles.disabledTileIcon : styles.tileIcon} />;
      // case 'NEW COLLECTION':
      //   return <FaCartArrowDown style={styles.tileIcon} />;
      case 'ORDERS':
        return <FaBoxes style={styles.tileIcon} />;
      // case 'COLLECTIONS':
      //   return <FaInbox style={styles.tileIcon} />;
      case 'INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW SALES INVOICE':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW RECEIPT':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'SALES INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW SALES ORDER':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'SALES ORDERS':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'RECEIPTS':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW CREDIT NOTE':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'CREDIT NOTES':
        return <FaFileInvoice style={styles.tileIcon} />;

      case 'CUSTOMERS':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'SUPPLIER RETURNS':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'EXPENSES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW EXPENSES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'EXPENSES CATEGORIES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW EXPENSES CATEGORIES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'INVENTORY':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'CALLS':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'WAREHOUSES':
        return <FaFileInvoice style={styles.tileIcon} />;
      default:
        return null
    }
  }

  renderTile = ({ title, key }) => {
    const { salesRep } = this.props;
    const disabled = key === "NEW_ORDER" && !!salesRep.status;

    const path = this.getPath(key);

    if (path) {
      if (key === "NEW_ORDER") {
        return <div
          style={disabled ? styles.cursor_not_allowed : {}}
          onClick={() => !disabled && this.showConfirmCreateOrder()}>
          <Col className="gutter-row" xs={12} sm={8}>
            <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
              {this.getTileIcon(title, disabled)}
              <div style={disabled ? styles.disabled_tileText : styles.tileText}><h2 style={{ color: disabled ? '#999999' : '#ffffff', fontWeight: 600 }}>{title}</h2></div>
            </div>
          </Col>
        </div>
      } else {
        return (
          <Link to={path} key={key}>
            <Col className="gutter-row" xs={12} sm={8}>
              <div style={styles.tile} key={key}>
                {this.getTileIcon(title)}
                <div style={styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
              </div>
            </Col>
          </Link>
        );
      }
    } else {
      return (
        <Col className="gutter-row" lg={4} xs={12} sm={6}>
          <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
            {this.getTileIcon(title)}
            <div style={disabled ? styles.disabled_tileText : styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
          </div>
        </Col>
      );
    }
  }

  getPath = key => {
    const { salesRep } = this.props;
    const { match } = this.props;
    const url = match.url
    switch (key) {
      case 'CUSTOMERS':
        return url + '/customers'
      // case 'COLLECTIONS':
      //   return url + '/collections';
      case 'SALES_ORDERS':
        return url + '/salesOrders';
      case 'SALES_INVOICES':
        return url + '/salesInvoices';
      case 'RECEIPTS':
        return url + '/receipts';
      case 'CREDIT_NOTES':
        return url + '/creditNotes';
      case 'NEW_CREDIT_NOTE':
        return '/salesRepresentatives/' + salesRep.id + '/creditNotes/new';
      case 'SUPPLIER_RETURNS':
        return '/salesRepresentatives/' + salesRep.id + '/supplierReturns';
      case 'INVENTORY':
        return '/salesRepresentatives/' + salesRep.id + '/inventory';
      case 'CALLS':
        return '/salesRepresentatives/' + salesRep.id + '/calls'
      case 'EXPENSES':
        return '/salesRepresentatives/' + salesRep.id + '/expenses'
      case 'NEW_EXPENSES':
        return '/salesRepresentatives/' + salesRep.id + '/expenses/new'
      case 'EXPENSES_CATEGORIES':
        return '/salesRepresentatives/' + salesRep.id + '/expenseCategories'
      case 'NEW_EXPENSES_CATEGORIES':
        return '/salesRepresentatives/' + salesRep.id + '/expenseCategories/new'
      case 'WAREHOUSES':
        return '/salesRepresentatives/' + salesRep.id + '/warehouses'

      default:
        return null;
    }
  }

  render() {
    const { onClickRefresh } = this.props;

    return (
      <Card style={{ margin: 5 }} className={'customerInfoCard'} extra={<Button icon="redo" type="link" onClick={() => onClickRefresh()}>Refresh</Button>}>
        <Row gutter={10}>
          {options.map(option => this.renderTile(option))}
        </Row>
      </Card>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return { authData: getAuthData(state) };
};

export default connect(mapStateToProps)(withRouter(Radium((Actions))));


const styles = {
  tile: {
    backgroundColor: ProMateGreen,
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    minHeight: 200,
    ':hover': {
      backgroundColor: ProMateGreenBorder,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileText: {
    textAlign: 'center',
    paddingTop: '5px',
    color: 'white',
  },
  disabled_tile: {
    backgroundColor: "#f1f1f1",
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  disabled_tileText: {
    textAlign: 'center',
    paddingTop: 5,
    color: 'black',
  },
  cursor_not_allowed: {
    cursor: "not-allowed"
  },
  tileIcon: {
    fontSize: 65,
    color: '#ffffff',
    paddingBottom: 4
  },
  disabledTileIcon: {
    fontSize: 65,
    color: '#999999',
    paddingBottom: 4
  },
};