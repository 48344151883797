import React, { Component } from 'react';
import { Card, Col, Row, Button, Modal } from 'antd';
import Radium from 'radium';
import { ProMateGreen, ProMateGreenBorder } from '../../../const/Theme';
import { Link, withRouter } from 'react-router-dom';
import { FaBoxes, FaCalendarPlus, FaFileInvoice, FaInbox, FaCartArrowDown } from 'react-icons/fa';

const { confirm } = Modal;

const options = [
  {
    title: 'NEW SALES ORDER',
    key: 'NEW_SALES_ORDER',
  },
  {
    title: 'NEW SALES INVOICE',
    key: 'NEW_SALES_INVOICE'
  },
  {
    title: 'NEW COLLECTION',
    key: 'NEW_COLLECTION'
  },
  {
    title: 'NEW CREDIT NOTE',
    key: 'NEW_CREDIT_NOTE'
  },
  {
    title: 'NEW RECEIPT',
    key: 'NEW_RECEIPT'
  },
  {
    title: 'VIEW COLLECTIONS',
    key: 'VIEW_COLLECTIONS'
  },
  {
    title: 'VIEW SALES ORDERS',
    key: 'VIEW_SALES_ORDERS',
  },
  {
    title: 'VIEW SALES INVOICES',
    key: 'VIEW_SALES_INVOICES',
  },
  {
    title: 'VIEW CREDIT NOTES',
    key: 'VIEW_CREDIT_NOTES',
  },
  {
    title: 'VIEW RECEIPTS',
    key: 'VIEW_RECEIPTS',
  }

];

class Actions extends Component {

  showConfirmCreateOrder = () => {
    const { submitOrder } = this.props;

    confirm({
      title: 'Are you sure ?',
      content: 'This will create a new order.',
      onOk() {
        submitOrder();
      },
    });
  }

  getTileIcon = (title, disabled) => {
    switch (title) {
      case 'NEW ORDER':
        return <FaCalendarPlus style={disabled ? styles.disabledTileIcon : styles.tileIcon} />;
      case 'NEW COLLECTION':
        return <FaCartArrowDown style={styles.tileIcon} />;
      case 'VIEW ORDERS':
        return <FaBoxes style={styles.tileIcon} />;
      case 'VIEW COLLECTIONS':
        return <FaInbox style={styles.tileIcon} />;
      case 'INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW SALES INVOICE':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW RECEIPT':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'VIEW SALES INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW SALES ORDER':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'VIEW SALES ORDERS':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'NEW CREDIT NOTE':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'VIEW CREDIT NOTES':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'VIEW RECEIPTS':
        return <FaFileInvoice style={styles.tileIcon} />;
      default:
        return null
    }
  }

  renderTile = ({ title, key }) => {
    const { customer } = this.props;
    const disabled = key === "NEW_ORDER" && !!customer.status;

    const path = this.getPath(key);

    if (path) {
      if (key === "NEW_ORDER") {
        return <div
          style={disabled ? styles.cursor_not_allowed : {}}
          onClick={() => !disabled && this.showConfirmCreateOrder()}>
          <Col className="gutter-row" sm={8}>
            <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
              {this.getTileIcon(title, disabled)}
              <div style={disabled ? styles.disabled_tileText : styles.tileText}><h2 style={{ color: disabled ? '#999999' : '#ffffff', fontWeight: 600 }}>{title}</h2></div>
            </div>
          </Col>
        </div>
      } else {
        return (
          <Link to={path} key={key}>
            <Col className="gutter-row" sm={8}>
              <div style={styles.tile} key={key}>
                {this.getTileIcon(title)}
                <div style={styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
              </div>
            </Col>
          </Link>
        );
      }
    } else {
      return (
        <Col className="gutter-row" lg={4} xs={12} sm={6}>
          <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
            {this.getTileIcon(title)}
            <div style={disabled ? styles.disabled_tileText : styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
          </div>
        </Col>
      );
    }
  }

  getPath = key => {
    const { customer } = this.props;

    switch (key) {
      case 'VIEW_ORDERS':
        return '/customers/' + customer.id + '/orders'
      case 'INVOICES':
        return '/customers/' + customer.id + '/invoices'
      case 'NEW_ORDER':
        return '/orders/new';

      case 'VIEW_COLLECTIONS':
        return '/customers/' + customer.id + '/collections';
      case 'NEW_COLLECTION':
        return '/customers/' + customer.id + '/collections/new';
      case 'NEW_SALES_INVOICE':
        return '/customers/' + customer.id + '/salesInvoices/new';
      case 'NEW_SALES_ORDER':
        return '/customers/' + customer.id + '/salesOrders/new';
      case 'NEW_CREDIT_NOTE':
        return '/customers/' + customer.id + '/creditNotes/new';
      case 'NEW_RECEIPT':
        return '/customers/' + customer.id + '/receipts/new';

      case 'VIEW_SALES_INVOICES':
        return '/customers/' + customer.id + '/salesInvoices';
      case 'VIEW_SALES_ORDERS':
        return '/customers/' + customer.id + '/salesOrders';
      case 'VIEW_CREDIT_NOTES':
        return '/customers/' + customer.id + '/creditNotes';
      case 'VIEW_RECEIPTS':
        return '/customers/' + customer.id + '/receipts';
      default:
        return null;
    }
  }

  render() {
    const { onClickRefresh } = this.props;
    return (
      <Card style={{ margin: 5 }} className={'customerInfoCard'} extra={<Button icon="redo" type="link" onClick={() => onClickRefresh()}>Refresh</Button>}>
        <Row gutter={10}>
          {options.map(option => this.renderTile(option))}
        </Row>
      </Card>
    );
  }
}

export default withRouter(Radium((Actions)));

const styles = {
  tile: {
    backgroundColor: ProMateGreen,
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    minHeight: 200,
    ':hover': {
      backgroundColor: ProMateGreenBorder,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileText: {
    textAlign: 'center',
    paddingTop: '5px',
    color: 'white',
  },
  disabled_tile: {
    backgroundColor: "#f1f1f1",
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  disabled_tileText: {
    textAlign: 'center',
    paddingTop: 5,
    color: 'black',
  },
  cursor_not_allowed: {
    cursor: "not-allowed"
  },
  tileIcon: {
    fontSize: 65,
    color: '#ffffff',
    paddingBottom: 4
  },
  disabledTileIcon: {
    fontSize: 65,
    color: '#999999',
    paddingBottom: 4
  },
};