import { o as odataO } from 'odata';
import moment from 'moment';

const serviceRoot = process.env.REACT_APP_ODATA_SERVICE_ROOT;
const resourceRoot = process.env.REACT_APP_ODATA_RESOURCE_ROOT;

export const o = resource => odataO(serviceRoot, {
  headers: {
    'Authorization': `Basic ${btoa(process.env.REACT_APP_ODATA_USERNAME + ':' + process.env.REACT_APP_ODATA_PASSWORD)}`
  },
  credentials: 'include',
  mode: 'cors',
  fragment: ''
}).get(resourceRoot + resource);

export const createFilter = filterObject => {
  let filter = '';
  Object.keys(filterObject).forEach(key => {
    let value = filterObject[key];
    if (value === undefined || key === 'UserHierarchy') return;

    if (Array.isArray(value)) {
      value = value.filter((item, index) => value.indexOf(item) === index);
      if (value.length > 0) {
        filter += '(';
        value.forEach(item => {
          filter += key + " eq " + getFilterStringValue(item) + " or ";
        });
        filter = filter.substr(0, filter.length - 4);
        filter += ') and ';
      }
    } else if (key.startsWith('from')) {
      filter += '(' + key.substring(4) + " gt " + getFilterStringValue(value) + ") and ";
    } else if (key.startsWith('to')) {
      filter += '(' + key.substring(2) + " lt " + getFilterStringValue(value) + ") and ";
    } else if (key.startsWith('like')) {
      filter += '(contains(tolower(' + key.substring(4) + "), " + getFilterStringValue(value, true) + ")) and ";
    } else {
      filter += '(' + key + " eq " + getFilterStringValue(value) + ") and ";
    }
  });
  filter = filter.substr(0, filter.length - 5);
  return filter;
}

const getFilterStringValue = (value, wildcard) => {
  if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string') {
    if (wildcard)
      return "'*" + value + "*'";
    else
      return "'" + value + "'";
  } else if (moment.isMoment(value)) {
    return value.format('YYYY-MM-DDTHH:mm:ssZ');
  }
}