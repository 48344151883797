import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import CallsTableContainer from './CallsTableContainer';
import { getAuthData } from '../../../redux/reducers';
import { connect } from 'react-redux';
import PromatePageHeader from '../../common/PromatePageHeader';

class Calls extends Component {

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Calls'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={this.showNewButton() && [
            // <Button style={{
            //   backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute',
            //   top: 30,
            //   right: 25
            // }} onClick={this.addCall}>
            //   New
            // </Button>,
          ]}>

          <CallsTableContainer authData={this.props.authData} />
        </PromatePageHeader>
      </div>
    )
  }

  addCall = () => {
    const { match } = this.props;
    const url = match.url
    this.props.history.push(url + "/new");
  }

  showNewButton = () => {
    const { match } = this.props;
    const { authData: { userId, roleCode } } = this.props;

    // const insideCustomer = match.path.includes("customer");
    // const insideDistributor = match.path.includes("distributors");
    // const insideSalesRep = match.path.includes("salesRepresentatives");
    if (roleCode === "SALES_REPRESENTATIVE") {
      return true;
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { callId } = ownProps.match.params;
  //const collections = customerId ? getCollectionsByCustomer(state, customerId) : getCollections(state);
  return { authData: getAuthData(state) };
};

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
    position: 'fixed',
    zIndex: 1500,
    width: '100%',
    top: 40
  },
};

export default connect(mapStateToProps)(Calls);
