import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaMapMarker, FaPhoneSquare } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';

class ExpenseCategoryCard extends Component {
  getStatusColor = status => {
    if (status) return 'red';
    else return 'green';
  }

  render() {
    const { match } = this.props;
    const url = match.url
    const { expense, history } = this.props;
    const { id, category, amount, description, status } = expense;
    return (
      <Row onClick={() => history.push(url + "/" + id)}>
        <Row style={styles.alignRow}>
          <Col xs={12} sm={12} md={6} lg={6}><span style={styles.name}>{category}</span></Col>
          <Col xs={12} sm={12} md={6} lg={6}><span>{description}</span></Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 10
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  }

}
export default withRouter(ExpenseCategoryCard);