import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchDistributorPaymentHeaders } from "../../../redux/actions/distributorPaymentHeaders";
import { fetchCustomer } from "../../../redux/actions/customers";
import { addAttachment, fetchAttachment } from "../../../redux/actions/attachments";
import { getAuthData, getDistributorPaymentHeader, getAttachemnt, getPaymentLinesForDoc, getLoggedInUser, getOutstandingInvoiceHeadersByCustomer, getDistributor } from "../../../redux/reducers";
import { Spin, Popconfirm, Row, Col, Button } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import DistributorPaymentHeader from './Header';
import CollectionExport from "../DistributorPaymentExport";
import { dateFormatView, dateTimeFormatView } from '../../../const/Formats';

const defaultDistributorPayment = {
  CollectionNo: undefined,
  CollectType: 'CASH',
  DocDate: moment().format(dateFormatView),
  BankCode: undefined,
  BankBranchCode: undefined,
  PayNo: undefined,
  PayAmount: 0,
  CusCode: undefined,
  CusName: undefined,
  Status: 'Open',
  CollectDateTime: moment().format(dateTimeFormatView),
  ChequeDate: moment().add(1, 'days').format(dateTimeFormatView),
  CreditCardNo: undefined,
  UserCode: undefined,
  Remarks: ''
}

class Container extends Component {
  state = {
    loading: false,
    isDisableSave: true,
    isDisableConfirm: true,
    distributorPayment: {
      ...defaultDistributorPayment
    },
    bankCodeError: undefined,
    chequeNoError: undefined,
    slipNoError: undefined,
    amountError: undefined,
    paymentAmountError: undefined,
    postingDateError: undefined
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchDistributorPaymentHeaders, fetchAttachment, fetchPaymentLines, customer } = this.props;
    const { distributorPaymentId, customerId } = match.params

    const decodeId = decodeURIComponent(distributorPaymentId);
    fetchAttachment('PaymentAttachment', { DocNo: decodeId });
    this.setState({ loading: true })
    fetchDistributorPaymentHeaders({ CollectionNo: decodeId }).then(() => {
      this.setState({ loading: false })
    })
  }

  setData = data => {
    const { distributorPayment } = data;

    if (!distributorPayment) return;
    const PayAmount = distributorPayment.PayAmount && parseFloat(distributorPayment.PayAmount.replace(/,/g, ""))
    this.setState({
      distributorPayment: {
        ...this.state.distributorPayment,
        ...distributorPayment,
        PayAmount
      }
    }
    )
  }

  RequestSent = false;

  componentWillReceiveProps = nextProps => {
    const { distributorPayment, paymentLines } = nextProps;

    if (distributorPayment) this.setData({ distributorPayment });

  }

  setImageLink = fileUrl => {
    const { addAttachment, toastManager, authData: { userId } } = this.props;
    const { distributorPayment } = this.state;

    const attachment = {
      DocNo: distributorPayment.CollectionNo,
      AttachmentURL: fileUrl,
      UserCode: userId
    }

    this.setState({ loading: true });
    addAttachment('PaymentAttachment', attachment).then(response => {
      this.setState({ loading: false });
      if (!response.error) {
        toastManager.add('Successfully upload attachment.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Failed to upload attachment', { autoDismiss: true, appearance: 'error' });
      }
    })
    this.setState({ distributorPayment: { ...this.state.distributorPayment, links: [fileUrl] } })
  }

  shareCollection = () => {
    const { distributorPayment } = this.state;
    //const reportLink = distributorPayment && distributorPayment.links && distributorPayment.links.length > 0 && distributorPayment.links.find(link => link.includes('distributorPayment-report'))
    //reportLink && window.open(Api.server + "/files/" + reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');
    // const { distributorPaymentId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // const printCollectionView = document.getElementById('printCollectionView');
    // printContainer.style.display = 'inline';
    // printCollectionView.style.display = 'none';
    // this.setState({ loading: true });

    // html2canvas(document.querySelector("#distributorPaymentPrintDiv"), { width: 685 }).then((canvas) => {
    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = distributorPaymentId + '.png';
    //   a.click();

    //   printCollectionView.style.display = 'inline';
    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  renderActions = () => {
    const { match, distributorPayment = {}, loggedInUser: { permissions = [] } } = this.props;
    const { distributorPaymentId } = match.params
    const { OrderStatus, CollectDateTime, ChequeDate, CollectType, PayAmount, AppliedAmount } = distributorPayment;

    const balance = distributorPayment ? (parseFloat(PayAmount) - parseFloat(AppliedAmount)) : 0;
  }

  render() {
    const { match, attachment, paymentLines } = this.props;
    const { distributorPaymentId } = match.params
    const newDistributorPaymentId = distributorPaymentId ? decodeURIComponent(distributorPaymentId) : "new";

    const {
      loading,
      distributorPayment,
      bankCodeError,
      chequeNoError,
      slipNoError,
      amountError,
      postingDateError } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading Payment"}>
        <Row id={'printCollectionView'}>
          <DistributorPaymentHeader
            newDistributorPaymentId={newDistributorPaymentId}
            distributorPayment={distributorPayment}
            onSaveDistributorPayment={this.onSaveDistributorPayment}
            onConfirmDistributorPayment={this.navigateToConfirmDistributorPayment}
            onDeleteDistributorPayment={this.onDeleteDistributorPayment}
            onWorking={working => this.setState({ working })}
            attachment={attachment}
            setImageLink={this.setImageLink}
          />
          <div style={{ float: 'right', margin: 10 }}>
            {this.renderActions()}
          </div>
        </Row>
        <Row>
          <div id={'printContainer'} style={{ display: 'none' }}>
            <CollectionExport distributorPayment={distributorPayment} />
          </div>
        </Row>

      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { distributorId, distributorPaymentId } = ownProps.match.params
  const decodeId = decodeURIComponent(distributorPaymentId);

  return {
    distributor: getDistributor(state, distributorId),
    distributorPayment: getDistributorPaymentHeader(state, decodeId),
    authData: getAuthData(state),
    attachment: getAttachemnt(state, decodeId),
    paymentLines: getPaymentLinesForDoc(state, decodeId),
    loggedInUser: getLoggedInUser(state),
  };
};

export default withRouter(withToastManager(connect(mapStateToProps,
  { fetchCustomer, fetchDistributorPaymentHeaders, addAttachment, fetchAttachment })(Container)));

const banks = [
  { "_id": "PZe9SAt8QFY7HeyHZ", "bankid": "1", "bankname": "Hatton National Bank PLC", "shortname": "HNB" },
  { "_id": "a3EMXnpF2rCD9k9ZY", "bankid": "2", "bankname": "Bank of Ceylon", "shortname": "BOC" },
  { "_id": "WGcjpYLz4tmDupfJj", "bankid": "3", "bankname": "Sampath Bank PLC", "shortname": "Sampath" },
  { "_id": "w9pAudAozSXoMK4Zz", "bankid": "4", "bankname": "National Development Bank PLC", "shortname": "NDB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
  { "_id": "ni9rx7s3XL6Auge7f", "bankid": "6", "bankname": "The Hongkong \u0026 Shanghai Banking Corporation Ltd", "shortname": "HSBC" },
  { "_id": "S2kpZwpJphFgzeqSG", "bankid": "7", "bankname": "Pan Asia Banking Corporation PLC", "shortname": "Pan Asia Bank" },
  { "_id": "kjBoquK33CMLXCxCP", "bankid": "8", "bankname": "Seylan Bank PLC", "shortname": "Seylan" },
  { "_id": "bzJvkTY7ztAzSr9CP", "bankid": "9", "bankname": "Commercial Bank of Ceylon PLC", "shortname": "Commercial" },
  { "_id": "xWey22FL4K3rD7DF2", "bankid": "10", "bankname": "People’s Bank", "shortname": "Peoples" },
  { "_id": "5wDbsznEPvaPDsp9e", "bankid": "11", "bankname": "Deutsche Bank AG", "shortname": "Deutsche Bank" },
  { "_id": "oH9FPPuHG6wdD7rNA", "bankid": "12", "bankname": "Citibank", "shortname": "Citi Bank" },
  { "_id": "phjQCAyeGuoZXoyQf", "bankid": "13", "bankname": "Union Bank of Colombo PLC", "shortname": "Union" },
  { "_id": "FQr7DLgJHvMqsmeaF", "bankid": "14", "bankname": "DFCC Vardhana Bank PLC", "shortname": "DFCC" },
  { "_id": "af4F3jy9bS3G7TCmB", "bankid": "15", "bankname": "Cargills Bank Ltd", "shortname": "Cargills Bank" },
  { "_id": "j9jLkLhbRkHyjGiQs", "bankid": "16", "bankname": "Standard Bank", "shortname": "Standard Bank" },
  { "_id": "nXykAeaxKY5iwj3Nf", "bankid": "17", "bankname": "Amana Bank PLC", "shortname": "Amana Bank" },
  { "_id": "c4ngHthzcBK7Aarqi", "bankid": "18", "bankname": "MCB Bank Ltd", "shortname": "MCB Bank" },
  { "_id": "Pk9fBB5Rb7JRtY3kR", "bankid": "19", "bankname": "Bank of India", "shortname": "Bank of India" },
  { "_id": "7NMY9kBctARPvkgiX", "bankid": "20", "bankname": "Business Development Cooperative", "shortname": "Business Development Cooperative" },
  { "_id": "pMEFuSGNK7f3i8rpe", "bankid": "21", "bankname": "Nation Trust Bank", "shortname": "NTB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
]