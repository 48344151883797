
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchCustomers, fetchCustomerCategories } from "../../redux/actions/customers";
import { fetchSalesReturnReceiptHeaders } from "../../redux/actions/salesReturnReceiptHeaders";
import { getSalesHeadersByType, getSalesHeadersByTypeAndCustomer, getAuthData, getLoggedInUser, DOC_TYPE_ID_BY_DEFINITION, getTopPosition, getFlatPositions, getSalesReturnReceiptsHeadersBySearchKey, getSalesSalesReturnReceiptsHeaderTotal, isFetchingSalesReturnReceiptsHeaders } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import OrderTable from "./OrderTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { withToastManager } from 'react-toast-notifications';
import { SALES_ORDERS } from "../../const/Permissions";
import customers from '../../redux/reducers/customers';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import { FaPlus } from 'react-icons/fa';
class Orders extends Component {
  baseUrl = window.location.pathname;
  state = {
    loading: false,
    filter: {
      DocType: undefined,
      UserCode: undefined,
      RouteCode: undefined,
      OutletCategoryCode: undefined,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchCustomerCategories, fetchCustomers } = this.props;
    const { customerId, docType } = match.params

    fetchCustomerCategories();
    fetchCustomers({ UserCode: userId });

    this.setState({
      filter: {
        ...this.state.filter,
        DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
        UserCode: userId,
        CustNo: customerId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { customerId, docType } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadOrders(nextProps);
      });
    }, { ...this.state.filter, CustNo: customerId, DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  loadOrders = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  fetchOrderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchOrders(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  fetchOrders = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      OrderDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      CustNo: undefined,
      SellToCusNo: filter.CustNo,
      UserCode: undefined,
      text: undefined,
      No: filter.text ? '*' + filter.text + '*' : undefined
    }
    this.props.fetchSalesReturnReceiptHeaders(updatedFilter, page, searchKey);
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Return Receipts.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
      case 'RouteCode':
        newFilter.CustNo = undefined;
        break;
      case 'OutletCategoryCode':
        newFilter.OutletSubCategoryCode = undefined;
    }

    newFilter.StatusFilter = newFilter.Status ? 'Yes' : 'No';

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No, CustNo: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }

  render() {
    const { filter, currentPage } = this.state;
    const { loading, loggedInUser: { permissions = [] }, match, orders, total, totalAmount, topPosition } = this.props;
    const { customerId, docType } = match.params;

    const title = 'Return Receips';

    return (
      <div>
        <PromatePageHeader
          title={'Sales ' + title}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Spin spinning={(loading && orders.length === 0) || !topPosition} tip={"Loading Receips"}>
            <OrderTable
              orders={orders}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              setDefaultCustomerOption={this.setDefaultCustomerOption}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    this.props.history.push(this.props.match.url + "/new");
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 20,
    right: 180,
    height: 50
  }
};

Orders.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const searchKey = getSearchKey();

  return {
    orders: getSalesReturnReceiptsHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getSalesSalesReturnReceiptsHeaderTotal(state, searchKey),
    //totalAmount: getSalesInvoiceHeaderMetadata(state, searchKey).TotalAmountIncluVAT,
    loading: isFetchingSalesReturnReceiptsHeaders(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchSalesReturnReceiptHeaders, fetchCustomers, fetchCustomerCategories })(Orders));