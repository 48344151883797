import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";

class LocationHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'UNBLOCK':
        return 'green';
      case 'BLOCK':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {
    const { locationId, location = {} } = this.props;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {locationId && <tr>
              <td>Location Id #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{locationId}</td>
            </tr>}
            <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              {location.status && <td><Tag color={this.getStatusColor(location.status)}>{location.status}</Tag></td>}
            </tr>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default LocationHeader;