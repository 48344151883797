import React, { Component } from 'react';
import { Col, Row } from 'antd';

class StockTransferCardHeader extends Component {
  render() {
    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', paddingLeft: 13, paddingRight: 7, marginRight: 3 }}>
          <Col xs={0} sm={6} md={6} lg={4} style={{ textAlign: 'left' }}>Transfer No</Col>
          <Col xs={0} sm={4} md={4} lg={2} style={{ textAlign: 'left' }}>Transfer Date</Col>
          <Col xs={0} sm={0} md={0} lg={2} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={0} sm={7} md={7} lg={6} style={{ textAlign: 'left' }}>From Location</Col>
          <Col xs={0} sm={7} md={7} lg={6} style={{ textAlign: 'left' }}>To Location</Col>
          <Col xs={0} sm={0} md={0} lg={4} style={{ textAlign: 'left' }}>Transfered By</Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>From To</Col>

          {/* mobileview */}
          <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Details</Col>
          <Col xs={7} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>Transfer Date</Col>
          <Col xs={7} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>Status</Col>

        </Row>
      </Row>
    )
  }
}

export default StockTransferCardHeader;

const styles = {
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  }
}