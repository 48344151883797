import React, { Component } from 'react';
import { connect } from "react-redux";
import { addCollection, updateCollection, fetchCollection, deleteCollection, addAttachments } from "../../../redux/actions/collection";
import { fetchPaymentHeaders, updatePaymentHeader } from "../../../redux/actions/paymentHeaders";
import { fetchPostedCreditNotes } from "../../../redux/actions/postedCreditNotes";
import { getCustomer, getAuthData, getLoggedInUser, isCallInProgress, getInProgressCall, getCustomers, getPaymentHeader, isFetchingCustomers, getPostedCreditNotesByNo } from "../../../redux/reducers";
import { Spin, Row, Col, Button, Statistic, Popconfirm } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import ReceiptHeader from './Header';
import { currentLocation } from '../../../utils/Location';
import { getDemoReceipt } from '../ReceiptDemoData'
import InvoiceTable from '../ReceiptScreen/InvoiceTable';
import InvoiceTableMobile from '../ReceiptScreen/InvoiceTableMobile';
import ReceiptList from './ReceiptList';
import ReceiptListMobile from './ReceiptListMobile';
import { format } from "currency-formatter";
import { addPaymentLine, updatePaymentLine, deletePaymentLine } from "../../../redux/actions/paymentLines";
import { fetchSalesHeaders } from "../../../redux/actions/salesInvoiceHeaders";
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import uuid from 'uuid/v1';
import { formatGeoLocation } from "../../../utils/Formats";
import { updateFilterUserHierarchy } from '../../common/SelectSalesUserHierarchy';
import { fetchCustomers } from '../../../redux/actions/customers'
import CreditNoteList from './CreditNoteList';
import { addSettlementHeader, addSettlementLine, updateSettlementHeader } from '../../../redux/actions/settlementHeaders'
import { INVOICE_SETTLEMENT } from '../../../const/Permissions';
import CreditNoteListMobile from './CreditNoteListMobile';


const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const defaultReceipt = {
  receiptNo: "new",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  receiptDate: moment(),
  salesPersonCode: "",
  amount: 0.00,
  receiptType: "CASH",
  status: "Unverified",
  bankCode: undefined,
  bankName: undefined,
  chequeNo: undefined,
  slipNo: undefined,
  postingDate: moment(),
  links: [],
  mapLocation: currentLocation,
  sessionId: uuid(),
}

const PAGE_SIZE = 5;

class Container extends Component {
  state = {
    loading: false,
    isDisableSave: true,
    isDisableConfirm: true,
    receipt: undefined,
    creditNote: undefined,
    selectedReceiptRowKeys: [],
    selectedReceiptRows: [],
    selectedInvoiceRowKeys: [],
    selectedInvoiceRows: [],
    invoiceTotal: 0,
    setoffTotal: 0,
    bankCodeError: undefined,
    chequeNoError: undefined,
    slipNoError: undefined,
    amountError: undefined,
    postingDateError: undefined,
    MapLocation: undefined,
    invoiceData: [],

    customerId: undefined,
    selectedReceiptId: undefined,
    selectedCreditNoteId: undefined,
    settlementError: undefined,
    invoiceTotalBalanceToSettle: 0,
    receiptTotalBalance: 0,
    creditNoteTotalBalance: 0
  }

  //AppliedAmount

  componentDidMount() {
    const { customerId } = this.props.match.params
    const { ongoingCall, inCall, selectedReceiptId } = this.props;
    let custId = customerId;
    const { CustNo } = ongoingCall || {};
    if (inCall && !customerId) {
      custId = CustNo
    }

    custId && this.setState({ customerId: custId, disableCustomerSelect: true, selectedReceiptId: selectedReceiptId })

    this.setDeviceMapLocation();
    this.loadCustomers();
  }


  initializeTableData = false;

  componentWillReceiveProps(nextProps) {
    const { ongoingCall, inCall, match, receipt, creditNote } = nextProps;
    const { customerId } = match.params;
    let custId = customerId;
    const { CustNo } = ongoingCall || {};
    if (inCall && !customerId) {
      custId = CustNo
    }

    this.setState({ receipt: receipt, creditNote: creditNote })
    this.resetInvoicesPaymentAmount();

    custId && !this.initializeTableData && this.setState({ customerId: custId, disableCustomerSelect: true }, () => {
      this.initializeTableData = true;
      this.loadDataForTables(custId)
    })
  }


  loadCustomers = () => {
    const { authData } = this.props;
    this.props.fetchCustomers({ UserCode: authData.userId }).then(response => {
      this.setState({ loading: false })
      if (response.customers && response.customers.length === 1) {
        //
      }
    });
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleInputTextChange = (filter, value) => {
    if (filter === 'customerId') {
      this.setState({ customerId: value, settlementError: undefined },
        () => {
          this.loadDataForTables(value)
        }
      );
    }
  }

  loadDataForTables = (customerId) => {
    this.props.fetchPaymentHeaders({ CusCode: customerId, OutstandAmtFilte: 'Yes' }).then(response => this.calculateTotalReceiptBalance(response.data))
    this.props.fetchPostedCreditNotes({ SellToCusNo: customerId, OutstandAmtFilte: 'Yes' }).then(response => this.calculateTotalCreditNoteBalance(response.data));
    this.fetchInvoices(customerId);
  }

  fetchInvoices = (customerId) => {
    const { match, authData: { userId } } = this.props;

    const filter = {
      DocType: '2',
      UserCode: userId,
      SellToCusNo: customerId,
      OutstandAmtFilte: 'Yes'
    }

    if (customerId) {
      this.setState({ loading: true })
      this.props.fetchSalesHeaders(filter).then(response => {
        this.setState({ invoices: response.data })
        this.setState({ loading: false })
        this.resetInvoicesPaymentAmount();
        this.calculateTotalBalanceToSettle(response.data);
      });
    }
  }

  calculateTotalBalanceToSettle = (invoices) => {
    let balanceToSettle = 0;
    for (var i = 0; i < invoices.length; i++) {
      const invoice = invoices[i];
      const { PDCAmount, UnconfirmedNonPDC, RemAmount } = invoice;
      const pdcAmount = parseFloat(PDCAmount.replaceAll(',', ''));
      const unconfirmedNonPdc = parseFloat(UnconfirmedNonPDC.replaceAll(',', ''));
      const remAmount = parseFloat(RemAmount.replaceAll(',', ''));

      const postingPending = pdcAmount + unconfirmedNonPdc;
      const balance = (remAmount - postingPending).toFixed(2)

      balanceToSettle = parseFloat(balanceToSettle) + parseFloat(balance);
    }

    //console.log(balanceToSettle)

    this.setState({ invoiceTotalBalanceToSettle: balanceToSettle });
  }

  calculateTotalReceiptBalance = (receipts) => {
    let balance = 0;
    for (var i = 0; i < receipts.length; i++) {
      const receipt = receipts[i];
      balance = balance + (parseFloat(receipt.PayAmount.replace(/,/g, "")) - parseFloat(receipt.AppliedAmount.replace(/,/g, "")));
    }

    this.setState({ receiptTotalBalance: balance });
  }

  calculateTotalCreditNoteBalance = (creditNotes) => {
    let balance = 0;
    for (var i = 0; i < creditNotes.length; i++) {
      const creditNote = creditNotes[i];
      balance = balance + parseFloat(creditNote.RemAmount.replace(/,/g, ""));
    }

    this.setState({ creditNoteTotalBalance: balance });
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  hasCollectionUpdated = (thisCollection = {}, nextCollection = {}) => {
    return JSON.stringify(thisCollection) !== JSON.stringify(nextCollection);
  }

  getBankCodeFromName = name => {
    const bank = banks.find(bank => bank.shortname === name);
    return bank && bank.bankid;
  }

  onInputChange = (field, value, errorKey) => {
    if (field === 'amount' && value !== "" && !this.isValidAmount(value)) return

    this.setState({ receipt: { ...this.state.receipt, [field]: value }, [errorKey]: undefined });
  }

  onSelectBankChange = bankid => {
    const selectedBank = banks.find(bank => bank.bankid === bankid)
    this.setState({ receipt: { ...this.state.receipt, bankCode: selectedBank.bankid, bankName: selectedBank.bankname }, bankCodeError: undefined });
  }

  onSelectReceiptTypeChange = type => {
    this.setState({ receipt: { ...this.state.receipt, paymentType: type } });
  }

  handleSaveReceipt = () => {
    const { match } = this.props;
    const { receiptId } = match.params
    const newCollectionId = receiptId === 'undefined' ? "new" : receiptId;

    const { addCollection, updateCollection, customer, toastManager } = this.props;
    const { receipt } = this.state;
    this.setState({ loading: true });

    if (newCollectionId !== 'new') {
      setTimeout(() => {
        this.setState({ loading: false });
        toastManager.add('Receipt successfully updated.', { autoDismiss: true, appearance: 'success' });
      }, 2000)
      // updateCollection(receipt).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the receipt.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      receipt.mapLocation = currentLocation;
      setTimeout(() => {
        this.setState({ loading: false });
        toastManager.add('Successfully created a new receipt.', { autoDismiss: true, appearance: 'success' });
        this.props.history.push('/receipts/')
      }, 2000)
      // addCollection(receipt).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new receipt.', { autoDismiss: true, appearance: 'success' });
      //     defaultReceipt.sessionId = uuid();
      //     this.setState({ receiptNo: response.receipt.receiptNo, isDisableConfirm: false })
      //     this.props.history.replace('/customers/' + customer.id + '/receipts/' + response.receipt.receiptNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  validateForm = () => {
    const { toastManager } = this.props;
    const balance = this.getBalanceToSettOff();
    //console.log(balance)

    if (balance < 0) {
      //toastManager.add('Invalid settlment amount', { autoDismiss: true, appearance: 'error' });
      return undefined
    }
    else { return true }
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }


  setImageLink = fileUrl => {
    const { addAttachments, toastManager } = this.props;
    const { receipt } = this.state;

    this.setState({ loading: true });
    addAttachments({ ...receipt, links: [fileUrl] }).then(response => {
      this.setState({ loading: false });
      if (!response.error) {
        toastManager.add('Successfully upload attachment.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Failed to upload attachment', { autoDismiss: true, appearance: 'error' });
      }
    })
    //this.setState({ receipt: { ...this.state.receipt, links: [fileUrl] } })
  }

  navigateToConfirmReceipt = () => {
    const { receiptId } = this.props.match.params

    this.props.history.push({ pathname: '/receipts/' + receiptId + '/confirm' })
  }

  onDeleteReceipt = () => {
    const { deleteCollection, toastManager, history } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      toastManager.add('Receipt successfully deleted.', { autoDismiss: true, appearance: 'success' });
      history.goBack();
    }, 2000)
    // deleteCollection(this.state.receipt).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete receipt. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Collection successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     history.goBack();
    //   }
    // });
  }

  onSetoffReceipt = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      this.props.toastManager.add('Receipt successfully setoffed.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack()
    }, 1000)
    // deleteCollection(this.state.receipt).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete receipt. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Collection successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     history.goBack();
    //   }
    // });
  }

  shareCollection = () => {
    const { receipt } = this.state;
    //const reportLink = receipt && receipt.links && receipt.links.length > 0 && receipt.links.find(link => link.includes('receipt-report'))
    //reportLink && window.open(Api.server + "/files/" + reportLink, '_blank');
    window.open('/purchasingOrderReport.pdf', '_blank');
    // const { receiptId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // const printCollectionView = document.getElementById('printCollectionView');
    // printContainer.style.display = 'inline';
    // printCollectionView.style.display = 'none';
    // this.setState({ loading: true });

    // html2canvas(document.querySelector("#receiptPrintDiv"), { width: 685 }).then((canvas) => {
    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = receiptId + '.png';
    //   a.click();

    //   printCollectionView.style.display = 'inline';
    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  renderActions = () => {
    const { loggedInUser: { permissions = [] }, n } = this.props;
    const { invoiceTotal, invoiceTotalBalanceToSettle, creditNoteTotalBalance, receiptTotalBalance, setoffTotal, receipt, creditNote } = this.state;
    const totalUnallocatedPayments = receiptTotalBalance + creditNoteTotalBalance;
    const balanceOustanding = invoiceTotalBalanceToSettle - totalUnallocatedPayments
    const enableSetOff = permissions.includes(INVOICE_SETTLEMENT.SETOFF);

    return <Row gutter={16}>
      <Col lg={6} md={6} sm={8} xs={8} style={{ textAlign: 'center' }}>
        <Statistic title="Invoice Outstanding" value={invoiceTotalBalanceToSettle} precision={2} />
      </Col>
      <Col lg={6} md={6} sm={8} xs={8} style={{ textAlign: 'center' }}>
        <Statistic title="Unallocated Payments" value={totalUnallocatedPayments} precision={2} />
      </Col>
      <Col lg={6} md={6} sm={8} xs={8} style={{ textAlign: 'center' }}>
        {this.state.settlementError && <p style={{ color: 'red', textAlign: 'center', fontSize: 14 }}>{this.state.settlementError}</p>}
        <Statistic title="Balance Outstanding" value={balanceOustanding} precision={2} />
      </Col>
      {enableSetOff && <Col lg={6} md={0} sm={0} xs={0} style={{ textAlign: 'end' }}>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.handleAddPaymentLines}>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, width: '60%', marginTop: 15 }}
          >Set Off</Button>
        </Popconfirm>
      </Col>}
      {enableSetOff && <Col lg={0} md={6} sm={0} xs={0} style={{ textAlign: 'center' }}>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.handleAddPaymentLines}>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, width: '75%', marginTop: 15 }}
          >Set Off</Button>
        </Popconfirm>
      </Col>}
      {enableSetOff && <Col lg={0} md={0} sm={24} xs={24} style={{ textAlign: 'center' }}>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.handleAddPaymentLines}>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, width: '100%', marginTop: 15 }}
          >Set Off</Button>
        </Popconfirm>
      </Col>}
    </Row>
    // <Row>
    //   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //     <div style={{ fontSize: "large" }}>Invoice Amount:</div>
    //     <div style={{
    //         fontSize: "large",
    //         display: "flex",
    //         justifyContent: "flex-end",
    //         paddingRight: 5,
    //       }}>{format(invoiceTotal, currencyFormat)}</div>
    //   </div>
    //   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //     <div style={{ fontSize: "large" }}>Setoff Amount:</div>
    //     <div style={{
    //         fontSize: "large",
    //         display: "flex",
    //         justifyContent: "flex-end",
    //         paddingRight: 5,
    //       }}>{format(setoffTotal, currencyFormat)}</div>
    //   </div>
    //   <div style={{ borderTop: '1px solid rgba(0,0,0,.25)', width: 279, marginTop: 10, marginBottom: 10 }}></div>
    //   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //     <div style={{ fontSize: "large" }}>Balance:</div>
    //     <div style={{
    //         fontSize: "large",
    //         display: "flex",
    //         justifyContent: "flex-end",
    //         paddingRight: 5,
    //       }}>{format(invoiceTotal - setoffTotal, currencyFormat)}</div>
    //   </div>
    //   <Col>
    //     <Button
    //       type="secondary"
    //       style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, width: 279, marginTop: 15 }}
    //       onClick={this.handleAddPaymentLines}>Set Off</Button>
    //   </Col>
    // </Row>


  }

  handlePaymentLineActions = (paymentLine, action) => {
    const { receipt, MapLocation } = this.state;
    const { addPaymentLine, authData, toastManager } = this.props;
    const { No } = receipt;

    if (!No) {
      toastManager.add('Please select a receipt to allocate invoice.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!locationEnabled) {
      toastManager.add('You can not allocate invoices for receipt without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    const paymentLineDetail = {
      SessionID: uuid(),
      DocNo: No,
      InvoiceNo: paymentLine.No,
      InvoiceAmount: paymentLine.Amount,
      AmontToApply: paymentLine.AmontToApply,
      MapLocation,
      UserCode: authData.userId
    }

    this.handleAction(addPaymentLine, paymentLineDetail);
  }

  handleAction = (action, paymentLineItem) => {
    const { toastManager } = this.props;

    this.setState({ loading: true });
    action(paymentLineItem).then(response => {
      this.setState({ loading: false });
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  handleOnClickSelectReceipts = (selectedRowKeys, selectedRows, record, selected) => {
    const setoffTotal = this.getBalanceToSettOff(selectedRows)

    this.setState({ receipt: { ...record }, setoffTotal, selectedReceiptId: record.No })
    this.setState({ creditNote: undefined, selectedCreditNoteId: undefined, settlementError: undefined });
  }

  handleOnClickSelectCreditNotes = (selectedRowKeys, selectedRows, record, selected) => {
    const setoffTotal = this.getBalanceToSettOff(selectedRows)

    //this.resetInvoicesPaymentAmount();

    this.setState({ creditNote: { ...record }, setoffTotal, selectedCreditNoteId: record.No })
    this.setState({ receipt: undefined, selectedReceiptId: undefined });
    this.setState({ invoiceTotal: 0, settlementError: undefined });

  }

  resetInvoicesPaymentAmount = () => {
    const { invoices = [] } = this.state;
    const lines = invoices;
    lines.forEach(inv => {
      inv.AmontToApply = 0
      inv.paymentAmountError = undefined
    });

    this.setState({
      invoices: lines,
      paymentAmountError: undefined
    })
  }

  getBalanceToSettOff = () => {
    const { receipt, creditNote, invoiceData } = this.state;
    const invoiceTotal = this.getInvoiceTotal(invoiceData)

    let finalbalance = 0;
    if (!receipt && !creditNote) {
      finalbalance = 0
    } else if (receipt) {
      const balance = (parseFloat(receipt.PayAmount.replace(/,/g, "")) - parseFloat(receipt.AppliedAmount.replace(/,/g, "")));
      finalbalance = balance - invoiceTotal;
    } else if (creditNote) {
      finalbalance = (parseFloat(creditNote.RemAmount.replace(/,/g, "")) - invoiceTotal);
    }

    return finalbalance;
  }

  getInvoiceTotal = (invoiceData) => {
    //console.log(invoiceData)
    let total = 0;
    for (var i = 0; i < invoiceData.length; i++) {
      if (!invoiceData[i].paymentAmountError) {
        total = total + parseFloat(invoiceData[i].AmontToApply)
      }
    }

    return total;
  }

  getInvoiceLengthForUpdate = (invoiceData) => {
    //console.log(invoiceData)
    let length = 0;
    for (var i = 0; i < invoiceData.length; i++) {
      if (!invoiceData[i].paymentAmountError) {
        if (parseInt(invoiceData[i].AmontToApply) !== 0) {
          length = length + 1;
        }

      }
    }

    return length;
  }


  // getInvoiceTotal = invoices => {
  //   let invoiceTotal = 0;
  //   invoices.forEach(invoice => {
  //     invoiceTotal = invoiceTotal + parseFloat(invoice.AmontToApply.replace(/,/g, ""));
  //   })
  //   return invoiceTotal;
  // }

  handleAmountChange = (value, invoice) => {
    const { receipt } = this.state;
    const { invoiceData = [], invoices = [] } = this.state;
    const existingInvoice = invoiceData.find(inv => inv.No === invoice.No);
    let total = 0;
    const remaningOutstanding = (parseFloat(invoice.RemAmount.replace(/,/g, "")) - parseFloat(invoice.AllocatedAmount.replace(/,/g, "")));

    const updatedArray = invoices.map(line => {
      const updateLine = line;
      if (invoice.No === line.No) {
        updateLine['AmontToApply'] = value === '' ? 0 : parseFloat(value);
        if (remaningOutstanding < parseFloat(value)) {
          updateLine['paymentAmountError'] = 'Invalid amount'
        } else {
          updateLine['paymentAmountError'] = undefined
        }
      }
      return updateLine;
    })

    this.setState({ invoiceData: updatedArray, invoices, settlementError: undefined })
  }

  // handleAddPaymentLines = receipt => {
  //   const { addPaymentLine } = this.props;
  //   const { invoiceData = [] } = this.state;

  //   let invoiceLength = invoiceData.length;
  //   invoiceData.forEach(invoice => {
  //     if (invoice.AmontToApply > 0) {
  //       const paymentLineDetail = this.createPaymentLineRequest(receipt, invoice);
  //       //document.getElementById(invoice.No).value = 0;

  //       this.handleAction(addPaymentLine, paymentLineDetail, 'ADD').then(() => {
  //         invoiceLength--;
  //         if (invoiceLength == 0) this.setState({ invoiceData: [], invoiceTotal: 0 }, () => {
  //           this.RequestSent = false;
  //           this.resetInvoicesPaymentAmount();
  //         });
  //       });
  //     }
  //   });
  // }


  handleAddPaymentLines = () => {
    const { receipt, creditNote, customerId } = this.state;
    const { } = this.props;
    const { fetchSalesHeaders, toastManager, addSettlementHeader, addSettlementLine } = this.props;

    if (!this.validateForm()) {
      this.setState({ settlementError: "Invalid settelment amount." })
      return;
    }

    if (!this.state.receipt && !this.state.creditNote) {
      this.setState({ settlementError: "Select a Receipt/Credit Note to setoff." })
      return;
    }

    let { invoiceData = [], } = this.state;
    let invoiceLength = this.getInvoiceLengthForUpdate(invoiceData);
    //console.log("invoiceLength, invoiceLength", invoiceLength)
    const invoiceTotal = this.getInvoiceTotal(invoiceData);

    if (invoiceTotal <= 0) {
      this.setState({ settlementError: "Invoice total must be greather than 0." });
      return;
    }

    // passs 1 for payment , 3 for credit note
    const settlmentHeaderRequest = receipt ? this.createSettlementHeaderRequest(receipt, "1") : this.createSettlementHeaderRequest(creditNote, "3");
    this.setState({ loading: true })
    addSettlementHeader(settlmentHeaderRequest).then(response => {
      const settlementResponse = response.data;
      if (response.error) {
        toastManager.add(response.error, { autoDismiss: true, appearance: 'error' });
        this.setState({ loading: false })
      } else {
        if (invoiceLength === 0) this.setState({ loading: false })
        invoiceData.forEach((invoice, i) => {
          if (parseInt(invoice.AmontToApply) !== 0) {
            const settlementLineDetail = settlementResponse && this.createSettlementLineRequest(invoice, settlementResponse, i);
            addSettlementLine(settlementLineDetail).then(response => {
              invoiceLength--;

              if (!response.error) {
                const newPaymentLine = response.data;

                invoiceData = invoiceData.filter(inv => inv.No != newPaymentLine.DocNo);
                fetchSalesHeaders({ No: newPaymentLine.DocNo });
              }
              if (invoiceLength === 0) {
                this.setState({ loading: false })
                this.resetInvoicesPaymentAmount();
                this.setState({ invoiceData, setoffTotal: 0 });
                this.loadDataForTables(customerId)

                toastManager.add('Settlement successfully completed.', { autoDismiss: true, appearance: 'success' });
                // }
                //});
              }
            });
          }
        });
      }
    });
  }

  createPaymentLineRequest = paymentLine => {
    const { authData } = this.props;
    const { MapLocation, receipt } = this.state;
    const { No } = receipt;

    return {
      SessionID: uuid(),
      DocNo: No,
      InvoiceNo: paymentLine.No,
      AmontToApply: paymentLine.AmontToApply,
      MapLocation,
      UserCode: authData.userId
    }
  }



  createSettlementHeaderRequest = (creditNote, docType,) => {
    const { authData } = this.props;
    const { MapLocation, invoiceData } = this.state;
    const invoiceTotal = this.getInvoiceTotal(invoiceData);
    return {
      SessionID: uuid(),
      No: '',
      DocDate: '',
      DocType: docType,
      DocNo: creditNote.No,
      MapLocation,
      Amount: invoiceTotal,
      RemAmount: '',
      PostingDate: '',
      CusNo: '',
      CusName: '',
      UserCode: authData.userId,
      SettlementStatus: '',
      CreatedUserID: '',
      ModifiedUserID: '',
      UserTagFilterOne: '',
      UserTagFilterTwo: '',

    }
  }

  createSettlementLineRequest = (paymentLine, settlement, lineNo) => {
    const { authData } = this.props;
    const { MapLocation } = this.state;
    return {
      LineSessionID: uuid(),
      LineDocNo: settlement.No,
      LineNo: lineNo,
      DocType: 2, // this must be invoice doc type
      DocNo: paymentLine.No,
      MapLocation,
      AmountToApply: paymentLine.AmontToApply,
      RemAmount: '',
      PostingDate: '',
      UserCode: authData.userId,
      CreatedUserID: '',
      ModifiedUserID: ''
    }
  }



  render() {
    const { match, isFetchingCustomers } = this.props;
    const { receiptId } = match.params
    const newReceiptId = receiptId ? receiptId : "new";


    const {
      loading,
      receipt,
      disableCustomerSelect,
      MapLocation,
      selectedReceiptId,
      selectedCreditNoteId, creditNote } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading receipt"}>
        <Row id={'printCollectionView'}>
          <ReceiptHeader
            {...this.props}
            isFetchingCustomers={isFetchingCustomers}
            newReceiptId={newReceiptId}
            receipt={receipt}
            disableCustomerSelect={disableCustomerSelect}
            customerId={this.state.customerId}
            onSaveReceipt={this.handleSaveReceipt}
            onConfirmReceipt={this.navigateToConfirmReceipt}
            onDeleteReceipt={this.onDeleteReceipt}
            handleInputChange={this.handleInputTextChange}
          />
          <Row>
            <Col>
              <Col lg={12} md={24} sm={24} xs={24} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 10 }}>
                <Col xs={0} sm={24} md={24} lg={24}>
                  <ReceiptList pagination={{ pageSize: PAGE_SIZE }}
                    handleOnClickSelectReceipts={this.handleOnClickSelectReceipts}
                    selectedReceipt={receipt}
                    customerId={this.state.customerId}
                    selectedReceiptId={selectedReceiptId} />
                </Col>
                <Col xs={24} sm={0} md={0} lg={0}>
                  <ReceiptListMobile
                    pagination={{ pageSize: PAGE_SIZE }}
                    handleOnClickSelectReceipts={this.handleOnClickSelectReceipts}
                    selectedReceipt={receipt}
                    customerId={this.state.customerId}
                    selectedReceiptId={selectedReceiptId} />
                </Col>
                <Col xs={0} sm={24} md={24} lg={24} style={{ marginBottom: 45 }}>
                  <CreditNoteList
                    pagination={{ pageSize: PAGE_SIZE }}
                    handleOnClickSelectCreditNotes={this.handleOnClickSelectCreditNotes}
                    selectedCreditNoteId={selectedCreditNoteId}
                    customerId={this.state.customerId}
                    selectedCreditNote={creditNote} />
                </Col>
                <Col xs={24} sm={0} md={0} lg={0}>
                  <CreditNoteListMobile
                    pagination={{ pageSize: PAGE_SIZE }}
                    handleOnClickSelectCreditNotes={this.handleOnClickSelectCreditNotes}
                    selectedCreditNoteId={selectedCreditNoteId}
                    customerId={this.state.customerId}
                    selectedCreditNote={creditNote} />
                </Col>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 150, paddingLeft: 5, paddingRight: 5 }}>
                <Col xs={0} sm={0} md={24} lg={24}>
                  <InvoiceTable pagination={{ pageSize: PAGE_SIZE }} onAmountChange={this.handleAmountChange} invoices={this.state.invoices} />
                </Col>
                <Col xs={24} sm={24} md={0} lg={0}>
                  <InvoiceTableMobile pagination={{ pageSize: PAGE_SIZE }} onAmountChange={this.handleAmountChange} invoices={this.state.invoices} />
                </Col>
              </Col>
            </Col>
          </Row>
          <div style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: '#fff', position: 'fixed', bottom: 0, right: 0, left: 0, paddingTop: 5, paddingBottom: 5 }}>
            {this.renderActions()}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, receiptId, orderId } = ownProps.match.params
  const receipt = receiptId && getPaymentHeader(state, decodeURIComponent(receiptId));
  const creditNote = orderId && getPostedCreditNotesByNo(state, decodeURIComponent(orderId))
  return {
    isFetchingCustomers: isFetchingCustomers(state),
    customers: getCustomers(state),
    receipt: receipt,
    creditNote: creditNote,
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    inCall: isCallInProgress(state),
    ongoingCall: getInProgressCall(state),
  };
};

export default withRouter(withToastManager(connect(mapStateToProps,
  {
    addCollection, updateCollection, fetchCollection, deleteCollection, addAttachments, fetchCustomers,
    fetchPaymentHeaders, updatePaymentHeader, addPaymentLine, updatePaymentLine, deletePaymentLine,
    fetchSalesHeaders, fetchPostedCreditNotes, addSettlementHeader, updateSettlementHeader, addSettlementLine
  })(Container)));

const banks = [
  { "_id": "nXykAeaxKY5iwj3Nf", "bankid": "17", "bankname": "Amana Bank PLC", "shortname": "Amana Bank" },
  { "_id": "a3EMXnpF2rCD9k9ZY", "bankid": "2", "bankname": "Bank of Ceylon", "shortname": "BOC" },
  { "_id": "af4F3jy9bS3G7TCmB", "bankid": "15", "bankname": "Cargills Bank Ltd", "shortname": "Cargills Bank" },
  { "_id": "oH9FPPuHG6wdD7rNA", "bankid": "12", "bankname": "Citibank", "shortname": "Citi Bank" },
  { "_id": "bzJvkTY7ztAzSr9CP", "bankid": "9", "bankname": "Commercial Bank of Ceylon PLC", "shortname": "Commercial" },
  { "_id": "5wDbsznEPvaPDsp9e", "bankid": "11", "bankname": "Deutsche Bank AG", "shortname": "Deutsche Bank" },
  { "_id": "FQr7DLgJHvMqsmeaF", "bankid": "14", "bankname": "DFCC Vardhana Bank PLC", "shortname": "DFCC" },
  { "_id": "PZe9SAt8QFY7HeyHZ", "bankid": "1", "bankname": "Hatton National Bank PLC", "shortname": "HNB" },
  { "_id": "pMEFuSGNK7f3i8rpe", "bankid": "21", "bankname": "Nation Trust Bank", "shortname": "NTB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
  { "_id": "c4ngHthzcBK7Aarqi", "bankid": "18", "bankname": "MCB Bank Ltd", "shortname": "MCB Bank" },
  { "_id": "w9pAudAozSXoMK4Zz", "bankid": "4", "bankname": "National Development Bank PLC", "shortname": "NDB" },
  { "_id": "S2kpZwpJphFgzeqSG", "bankid": "7", "bankname": "Pan Asia Banking Corporation PLC", "shortname": "Pan Asia Bank" },
  { "_id": "xWey22FL4K3rD7DF2", "bankid": "10", "bankname": "People’s Bank", "shortname": "Peoples" },
  { "_id": "7NMY9kBctARPvkgiX", "bankid": "20", "bankname": "Business Development Cooperative", "shortname": "Business Development Cooperative" },
  { "_id": "WGcjpYLz4tmDupfJj", "bankid": "3", "bankname": "Sampath Bank PLC", "shortname": "Sampath" },
  { "_id": "kjBoquK33CMLXCxCP", "bankid": "8", "bankname": "Seylan Bank PLC", "shortname": "Seylan" },
  { "_id": "j9jLkLhbRkHyjGiQs", "bankid": "16", "bankname": "Standard Bank", "shortname": "Standard Bank" },
  { "_id": "Pk9fBB5Rb7JRtY3kR", "bankid": "19", "bankname": "Bank of India", "shortname": "Bank of India" },
  { "_id": "ni9rx7s3XL6Auge7f", "bankid": "6", "bankname": "The Hongkong \u0026 Shanghai Banking Corporation Ltd", "shortname": "HSBC" },
  { "_id": "phjQCAyeGuoZXoyQf", "bankid": "13", "bankname": "Union Bank of Colombo PLC", "shortname": "Union" },
]