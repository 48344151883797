import React, { Component } from 'react';
import companyLogo from '../../../assets/logo.png';
import printXcelLogo from '../../../assets/print-xcel-logo.png';
import rathnaLogo from '../../../assets/rathna-logo.png';
import { Row, Spin, Col, Button, Popconfirm, Divider, Table, Tag } from "antd";
import { readObjectFromIndexDB, insertDataIndexDB, createObjectStoreIndexDB } from '../../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../../const/IndexDBObjects'

let columns = [
  {
    title: 'No',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'Item Code',
    dataIndex: 'No',
    key: 'No',
  },
  {
    title: 'Item Description',
    dataIndex: 'item_description',
    key: 'item_description',
  },
  {
    title: 'MRP',
    dataIndex: 'MarketPrice',
    key: 'MarketPrice',
  },
  {
    title: 'Qty',
    dataIndex: 'Quantity',
    key: 'Quantity',
  },
  {
    title: 'Unit Price',
    dataIndex: 'UnitPrice',
    key: 'UnitPrice',
  },
  {
    title: 'Dis %',
    dataIndex: 'DisPercentage',
    key: 'DisPercentage',
  },
  // {
  //   title: 'Amount',
  //   dataIndex: 'amount',
  //   key: 'amount',
  // }
];

//let salesLines = { "data": [] }
//let total=0
//let signature = { "data": [] };

export class ComponentToPrint extends Component {
  state = {
    salesHeader: [],
    total: 0,
    salesLines: [],
    signature: []
  };

  getSignature = async orderNo => {

    let response = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SIGNATURES).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })

    if (typeof (response) != "undefined") {
      var arr = response.data.filter(function (el) {
        return el.orderId.includes(orderNo)
      });
      this.state.signature.push(arr[0].image)
      return arr[0].image;
    } else {
      return null
    }
  }

  getSalesHeader = async orderNo => {
    var { data, error, recordTotal, responseMetadata } = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    var filterSalesHeaders = data.filter(function (el) {
      return el.No.includes(orderNo)
    });

    return filterSalesHeaders;
  }

  getSalesLines = async orderNo => {

    var { data, error, recordTotal, responseMetadata } = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    var filterSalesLines = data.records.filter(function (el) {
      return el.DocNo.includes(orderNo)
    });

    for (let index = 0; index < filterSalesLines.length; index++) {
      this.state.total = this.state.total + parseInt(filterSalesLines[index].Amount)
      filterSalesLines[index].count = index + 1
    }

    this.state.salesLines.push(filterSalesLines)
    return filterSalesLines;
  }

  componentDidMount = () => {
    let orderId = this.props.orderId;

    (async () => {
      var resSalesLine = await this.getSalesLines(orderId)
      var resSalesHeader = await this.getSalesHeader(orderId)
      var tt = await this.getSignature(orderId)
      this.setState({ salesHeader: resSalesHeader[0] });

    })()
  }

  render() {
    var salesHeader = this.state.salesHeader

    return (
      <div style={{ margin: 50 }}>
        <Row style={{ backgroundColor: 'gray' }} span={10}>
          <Col span={24} style={{ textAlign: 'center' }}><h1>Sales Order</h1></Col>
        </Row>
        <Row>
          <Col span={8} order={1}>
            <p>Distributor Code : </p>
          </Col>
          <Col span={8} order={2}>
            <p>Order No</p>
            <p>Order Date</p>
            <p>Customer Code</p>
            <p>Customer Name</p>
            <p>Address</p>
          </Col>
          <Col span={8} order={2}>
            <p>{salesHeader.No}</p>
            <p>{salesHeader.OrderDate}</p>
            <p>{salesHeader.BillToCusNo}</p>
            <p>{salesHeader.BillToCusName}</p>
            <p></p>
          </Col>
        </Row>
        <Row>
          <Col span={8} order={1}>
            <img width="200" src={printXcelLogo} alt="promate" />
          </Col>
          <Col span={8} order={2}>
            <img width="200" src={companyLogo} alt="promate" />
          </Col>
          <Col span={8} order={3}>
            <img width="200" src={rathnaLogo} alt="promate" />
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <Row>
          <Col span={24} order={1}>
            <Table columns={columns} dataSource={this.state.salesLines[0]} pagination={false} />
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <Row>
          <Col span={6}>
            <p>Payment Method</p>
            <p>Payment Term</p>
            <p>Sales Person</p>
            <p>Contact</p>
          </Col>
          <Col span={6}>
            <p><b>{salesHeader.PayMethodCode}</b></p>
            <p><b>{salesHeader.PayTermCode}</b></p>
            <p></p>
            <p></p>
          </Col>
          <Col span={6}>
            <p>TOTAL</p>
            <p>DISCOUNT %</p>
            <p>DISCOUNT</p>
            <p><b>GRAND TOTAL</b></p>
          </Col>
          <Col span={6}>
            <p>{this.state.total}</p>
            <p>0</p>
            <p>0.00</p>
            <p><b>{this.state.total}</b></p>
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <Row>
          <Col span={5}>

          </Col>
          <Col span={10}>
            <div>
              <table style={{ border: "1px solid black", textAlign: "center", marginBottom: "50px" }}>
                <tr style={{ border: "1px solid black" }}>
                  <th>Customer Acknowledgement</th>
                </tr>
                <tr style={{ height: "100px" }}>
                  <td></td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>Date Month Year</td>
                </tr>
                <tr>
                  <td><img width="400" src={this.state.signature[0]} alt="Signature" /></td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td>Customer Signature</td>
                </tr>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((ref) => {
  // eslint-disable-line max-len
  return <ComponentToPrint ref={ref} />;
});
