import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper'
import { Card, Button } from "antd";
import withRouter from "react-router/es/withRouter";

class Pad extends Component {

  componentDidMount = () => {
    this.signaturePad = undefined;
  }

  clear = () => {
    this.signaturePad.clear()
  }

  render() {
    const { onConfirmCollection } = this.props;

    if (this.signaturePad) {
      this.signaturePad.penColor = 'rgb(66, 133, 244)';
    }

    return (
      <div>
        <Card
          style={{ margin: 10 }}
          title={<h6 style={{ fontSize: 'medium', margin: 10 }}>Signature</h6>}
          extra={<div style={{ width: 235, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button onClick={() => this.props.history.goBack(-1)}>Cancel</Button>
            <Button onClick={this.clear}>Clear</Button>
            <Button type="primary" onClick={() => onConfirmCollection(this.signaturePad.toDataURL("image/png"), this.signaturePad.isEmpty())}>Confirm</Button>
          </div>}>
          <SignaturePad ref={ref => this.signaturePad = ref} />
        </Card>
      </div>
    )
  }
}

export default withRouter(Pad);