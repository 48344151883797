import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Spin } from "antd";
import { withRouter } from 'react-router-dom';
import { fetchDistributors } from "../../../redux/actions/distributors";
import { getFlatPositions, getMapDistributors, isFetchingMapDistributors, getDistributorsBySearchKey } from "../../../redux/reducers";
import { connect } from "react-redux";
import { getAuthData, getTopPosition, isFetchingDistributors } from '../../../redux/reducers';
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import { updateFilterUserHierarchy } from '../../common/SelectSalesUserHierarchy';
import LOcationMap from '../../common/LocationMap';
import DistributorTable from "./DistributorTable";
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../../utils/Search';

const BASE_URL = '/distributors';

class DistributorTableContainer extends Component {
  state = {
    filter: {
      UserCode: undefined,
      StatusFilter: undefined
    },
    currentPage: 1,
    showMap: false,
    currentSearchKey: ''
  }

  componentDidMount() {
    this.setState({ filter: { ...this.state.filter, UserCode: this.props.authData.userId } });
    this.handleUrlSearchParams(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    handleUrlSearchParams(BASE_URL, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadDistributors(nextProps);
      });
    });
  }

  loadDistributors = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);

    this.setState({ filter });
    this.fetchFilteredDistributors(filter, currentPage, currentSearchKey);
  }

  compareStatus = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const statusA = a && a.status && a.status.toUpperCase();
    const statusB = b && b.status && b.status.toUpperCase();

    let comparison = 0;
    if (statusA === "UNVERIFIED") {
      comparison = 1;
    } else {
      comparison = -1;
    }
    return comparison;
  }

  handleInputTextChange = (filter, value, forceReload) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, newFilter, flatPositions, topPosition);
        break;
      case 'StatusFilter':
        newFilter.StatusFilter = value;
        break;
      default:
        break;
    }

    this.setState({ filter: newFilter }, () => {
      if (forceReload) {
        this.handleSearchClick();
      }
    });
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(BASE_URL, this.props, this.state.filter, page);

  fetchDistributorPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchFilteredDistributors(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchFilteredDistributors(filter, currentPage, currentSearchKey);
  }

  fetchFilteredDistributors = (filter, page, searchKey) => {
    this.props.fetchDistributors(this.getRequestFilter(filter), page, searchKey);
  }

  getRequestFilter = filter => {
    const requestFilter = { ...filter };
    requestFilter.Name = filter.text ? '*' + filter.text + '*' : undefined;
    requestFilter.Add = filter.text2 ? '*' + filter.text2 + '*' : undefined;

    return requestFilter;
  }

  // setDefaultRouteOption = route => {
  //   this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  // }

  renderFilter = () => {
    const sizeHierarchy = { lg: 16, md: 16, sm: 24, xs: 24 };
    const oneSixth = { lg: 4, md: 4, sm: 24, xs: 24 };

    return (
      <AdvanceSearchFilters
        SALES_HIERARCHY={sizeHierarchy}
        SELECT_DISTRIBUTOR_STATUS={oneSixth}
        SEARCH_BUTTON={oneSixth}
        handleInputChange={this.handleInputTextChange}
        handleSearchClick={this.handleSearchClick}
        filter={this.state.filter}
        style={{ marginBottom: 0 }}
      />
    )
  }

  onMapViewSelect = selected => this.setState({ showMap: selected });

  renderMobileViewSwitcher = () =>
    <div style={styles.alignRight}>
      <Button.Group>
        <Button style={styles.mobileSwitcherButtons} icon='menu' size='small' onClick={() => this.onMapViewSelect(false)}>List</Button>
        <Button style={styles.mobileSwitcherButtons} icon='environment' size='small' onClick={() => this.onMapViewSelect(true)}>Map</Button>
      </Button.Group>
    </div>

  renderDesktopViewSwitcher = () =>
    <div style={styles.alignRight}>
      <Row>
        <Col xs={12}>
          <Button style={styles.desktopSwitcherButtons} icon='menu' size='small' onClick={() => this.onMapViewSelect(false)} />
        </Col>
        <Col xs={12}>
          <Button style={styles.desktopSwitcherButtons} icon='environment' size='small' onClick={() => this.onMapViewSelect(true)} />
        </Col>
      </Row>
    </div>

  renderMobileHeader = () => [
    this.renderFilter(),
    this.renderMobileViewSwitcher()
  ]

  renderDesktopHeader = () =>
    <Row>
      <Col xs={23}>{this.renderFilter()}</Col>
      <Col xs={1}>{this.renderDesktopViewSwitcher()}</Col>
    </Row>

  renderHeader = () =>
    <div style={{ ...styles.body, marginTop: 5 }} className='promate-table-header'>
      {this.context.screenType === 'DESKTOP' ? this.renderDesktopHeader() : this.renderMobileHeader()}
    </div>

  renderTable = () => {
    const { distributors, fetchingDistributors } = this.props;
    const { filter, currentPage } = this.state;

    const distributorsReady = fetchingDistributors && distributors.length === 0;

    return (
      <Spin spinning={distributorsReady} tip={'Loading distributors'}>
        <DistributorTable
          currentPage={currentPage}
          filter={filter}
          handleInputTextChange={this.handleInputTextChange}
          handleSearchClick={this.handleSearchClick}
          distributors={distributors}
          authData={this.props.authData}
          setDefaultRouteOption={this.setDefaultRouteOption}
          onPaginationChange={this.handlePaginationChange}
        />
      </Spin>
    );
  }

  renderMap = () => {
    const { fetchingMapDistributors, mapDistributors, routes } = this.props;

    if (fetchingMapDistributors) return (
      <Spin spinning={fetchingMapDistributors} tip={'Loading distributors'}>
        <div style={{ height: 400, width: '100%' }} />
      </Spin>
    );

    const height = this.context.screenType === 'MOBILE' ? window.innerHeight * 0.8 : undefined;
    return (
      <div style={{ ...styles.mapContainer, height: height }}>
        <LOcationMap height={height} distributors={mapDistributors} />
      </div>
    );
  }

  render() {
    const { showMap } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {showMap ? this.renderMap() : this.renderTable()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const authData = getAuthData(state);

  return {
    distributors: getDistributorsBySearchKey(state, getSearchKey()),
    fetchingDistributors: isFetchingDistributors(state),
    authData,
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    fetchingMapDistributors: isFetchingMapDistributors(state),
    mapDistributors: getMapDistributors(state)
  };
};

const styles = {
  body: {
    paddingLeft: 10,
    paddingRight: 10
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  desktopSwitcherButtonGroup: {
    marginTop: 4,
  },
  desktopSwitcherButtons: {
    marginTop: 4,
    height: 34,
    width: 34,
    padding: 5
  },
  mobileSwitcherButtons: {
    marginTop: 4,
    height: 34,
    padding: 5
  },
  mapContainer: {
    marginTop: 10
  }
}

DistributorTableContainer.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, { fetchDistributors })(DistributorTableContainer));