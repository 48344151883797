import React, { Component } from 'react';
import { Table, Row, Input, Col, Button } from "antd";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchStockTakesHeaders } from "../../redux/actions/stockTakeHeaders";
import { fetchStockTakeLines, updateStockTakeLine } from "../../redux/actions/stockTakeLines";
import { fetchItems } from "../../redux/actions/items";
import { getItems, getAuthData, getStockTakeLineMapByDocNo, getLoggedInUser, DOC_TYPE_ID_BY_DEFINITION, getStockTakesHeadersByNo, getItemFilterOptions } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { FaCheck, FaTimes, FaSave, FaUndo } from "react-icons/fa";
import { format } from "currency-formatter";
import uuid from "uuid/v1";
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { withToastManager } from 'react-toast-notifications';
import { formatGeoLocation } from "../../utils/Formats";
import SelectCategoryTree, { filterItemsByCategory } from '../common/SelectCategoryTree';
import { STOCK_TAKE } from '../../const/Permissions';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const { Search } = Input;

class ItemTable extends Component {
  state = {
    LocCode: undefined,
    text: undefined,
    stockTakeLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false,
    itemFilter: []
  }

  getColumns = () => {
    const { loggedInUser: { permissions = [] } } = this.props;
    //const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);
    const visiblePhysicalData = permissions.includes(STOCK_TAKE.VISIBLE_PHYSICAL_DATA);
    if (!visiblePhysicalData) {
      return [
        {
          title: 'No',
          dataIndex: 'LotNo',
          key: 'LotNo',
          width: '10%',
          render: (Description, item) => item.LotNo
        },
        {
          title: 'Description',
          dataIndex: 'Description',
          key: 'description',
          width: '80%',
          render: (Description, item) => Description
        },

        // {
        //   title: '',
        //   dataIndex: 'status',
        //   key: 'status',
        //   align: 'right',
        //   width: '5%',
        //   render: (status, item) => <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>,
        // },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          align: 'right',
          width: '5%',
          render: (action, item) => <div style={{ marginRight: 10 }}>
            {this.renderActions(item)}
          </div>
        }
      ];
    } else {
      return [
        {
          title: 'No',
          dataIndex: 'LotNo',
          key: 'LotNo',
          width: '10%',
          render: (Description, item) => item.LotNo
        },
        {
          title: 'Description',
          dataIndex: 'Description',
          key: 'description',
          width: '70%',
          render: (Description, item) => Description
        },
        {
          title: 'Physical Quantity',
          dataIndex: 'PhysicalQty',
          key: 'PhysicalQty',
          align: 'right',
          width: '10%',
          render: (quntity, item) => this.renderQuntity(item),
        },
        // {
        //   title: '',
        //   dataIndex: 'status',
        //   key: 'status',
        //   align: 'right',
        //   width: '5%',
        //   render: (status, item) => <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>,
        // },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          align: 'right',
          width: '5%',
          render: (action, item) => <div style={{ marginRight: 10 }}>
            {this.renderActions(item)}
          </div>
        }
      ];
    }
  }


  getMobileColumns = () => {
    const { loggedInUser: { permissions = [] } } = this.props;
    //const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);
    const visiblePhysicalData = permissions.includes(STOCK_TAKE.VISIBLE_PHYSICAL_DATA);

    return [
      {
        title: <div style={{ width: window.innerWidth - 150 }}>
          <table>
            <tr>
              <td style={{ textAlign: 'right', width: '50%' }}>Description</td>
              <td style={{ textAlign: 'right', width: '50%' }}>Physical Qty</td>
            </tr>
          </table>
        </div>,
        dataIndex: 'Description',
        key: 'description',
        width: '75%',
        render: (Description, item) => <div style={{ width: window.innerWidth - 150 }}>
          <div style={{ height: 40 }}>{item.LotNo + ' - ' + Description}</div>
          {visiblePhysicalData && <div style={{ height: 40, marginTop: -7, textAlign: 'right' }}>{this.renderQuntity(item)}</div>}
        </div>
      },
      {
        title: <div style={{ marginRight: 10 }}></div>,
        dataIndex: 'Inventory',
        key: 'Inventory',
        align: 'right',
        width: 150,
        render: (Description, item) => <div style={{}}>
          <div style={{ height: 40, textAlign: 'right' }}></div>
          <div style={{ height: 40, marginTop: -7, textAlign: 'right' }}>{this.renderActions(item)}</div>
        </div>
      }
    ];
  }

  renderQuntity = item => {
    const { stockTakeLine } = this.state;
    const stockTakeLineItem = this.getExistingStockTakeLine(item);
    const key1 = stockTakeLineItem && (stockTakeLineItem.ItemNo + '-' + stockTakeLineItem.LotNo + '-' + stockTakeLineItem.LocCode);
    const key2 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;

    let lineQty = '0';

    if (key1 === key2) lineQty = '' + stockTakeLineItem.PhysicalQty;

    if (stockTakeLine) {
      const key3 = stockTakeLine.ItemNo + '-' + stockTakeLine.LotNo + '-' + stockTakeLine.LocCode;

      if (key3 === key2) lineQty = '' + stockTakeLine.PhysicalQty;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={parseFloat(lineQty.replaceAll(',', ''))}
        className="ant-input arrowless"
        min='0'
        style={{ width: 100, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onChange={(e) => this.handleQtyChange(e.target.value, item, stockTakeLineItem)} />
    )
  }

  isDisableRowActions = item => {
    const { stockTakeLine } = this.state;

    if (!stockTakeLine) return false;

    const key1 = item.ItemNo + '-' + item.LotNo + '-' + item.LocCode;
    const key2 = stockTakeLine.ItemNo + '-' + stockTakeLine.LotNo + '-' + stockTakeLine.LocCode;
    return key1 !== key2;
  }

  renderStatus = item => {
    const stockTakeLine = this.getExistingStockTakeLine(item);

    if (stockTakeLine) {
      if (stockTakeLine.added) {
        return (
          <FaCheck color='#07e607' />
        )
      } else {
        return (
          <FaTimes color='red' />
        )
      }
    } else return null;
  }

  getRowClassName = item => {
    const stockTakeLine = this.getExistingStockTakeLine(item);
    const qty = stockTakeLine && stockTakeLine.PhysicalQty ? parseFloat(stockTakeLine.PhysicalQty) : 0;
    if (stockTakeLine) {
      if (stockTakeLine.added && qty > 0) {
        return 'sales-header-selected-item-row'
      } else {
        return ''
      }
    } else return '';
  }

  getExistingStockTakeLine = item => {
    const { stockTakeLineMap } = this.props;
    const { ItemNo, LotNo, LocCode } = item;
    const key = ItemNo + '-' + LotNo + '-' + LocCode;

    return stockTakeLineMap[key];
  }

  renderActions = (item) => {
    const { stockTakeLine } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disabled={this.isDisableRowActions(item)} type='primary' size={'small'} onClick={this.handleSaveItem} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        <Button disabled={this.isDisableRowActions(item)} size={'small'} onClick={this.handleResetItem} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
      </div>
    )
  }

  componentDidMount() {
    const { stockTakeHeader, fetchStockTakeLines, fetchStockTakesHeaders } = this.props;
    fetchStockTakesHeaders({ No: stockTakeHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[stockTakeHeader.DocType] });
    fetchStockTakeLines({ DocNo: stockTakeHeader.No, DocType: stockTakeHeader.DocType });
    this.setState({ LocCode: stockTakeHeader.LocCode }, this.loadItems);
    this.setDeviceMapLocation();
  }

  loadItems = item => {
    const { authData, fetchItems } = this.props;

    const filter = {
      LocCode: this.state.LocCode,
      UserCode: authData.userId,
      StockTake: 'yes'
    }

    if (item) {
      filter.ItemNo = item.ItemNo;
      filter.LotNo = item.LotNo;
    }

    this.setState({ loadingItems: true });
    fetchItems(filter).then(() => {
      this.setState({ loadingItems: false });
    });
  }

  loadStockTakeHeader = () => {
    const { stockTakeHeader, fetchStockTakesHeaders } = this.props;

    fetchStockTakesHeaders({ No: stockTakeHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[stockTakeHeader.DocType] });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  handleSaveItem = () => {
    const { stockTakeHeader, authData, toastManager, updateStockTakeLine } = this.props;
    const { stockTakeLine, MapLocation } = this.state;

    if (!stockTakeLine) return;

    const exitingStockTakeLine = this.getExistingStockTakeLine(stockTakeLine);

    const stockTakeLineItem = {
      StockTemplateName: stockTakeHeader.StockTemplateName,
      StockBatchName: stockTakeHeader.StockBatchName,
      DocNo: stockTakeHeader.No,
      ItemNo: stockTakeLine.ItemNo,
      LotNo: stockTakeLine.LotNo,
      LocCode: stockTakeLine.LocCode,
      PhysicalQty: stockTakeLine.PhysicalQty,
      UnitCost: stockTakeLine.UnitCost,
      UserCode: authData.userId
    }

    if (!exitingStockTakeLine) return;
    else {
      const updatedStockTakeLine = {
        ...stockTakeLineItem,
        LineNo: exitingStockTakeLine.LineNo
      }

      this.handleAction(updateStockTakeLine, updatedStockTakeLine);
    }
  }

  handleAction = (action, stockTakeLineItem) => {
    const { toastManager } = this.props;

    this.setState({ loading: true });
    action(stockTakeLineItem).then(response => {
      this.setState({ loading: false });
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        this.loadItems({ ...this.state.stockTakeLine });
        this.loadStockTakeHeader();
        this.setState({ stockTakeLine: undefined });
      }
    })
  }

  handleResetItem = () => this.setState({ stockTakeLine: undefined });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const inEditMode = !!this.state.stockTakeLine;
    const summaryAvailable = !!this.props.stockTakeHeaderDetails;
    const tableFooterHeight = !inEditMode && summaryAvailable ? 135 : 0;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  handleChange = (field, value, error) => {
    this.setState({ [field]: value })
  }

  handleCategoryFilterChange = value => {
    this.setState({ itemFilter: value });
  };

  renderTitle = () => {
    return (
      <Row className="promate-table-header" gutter={5} style={{ paddingBottom: 10 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ marginBottom: 5 }}>
          <SelectCategoryTree itemFilter={this.state.itemFilter} onChange={this.handleCategoryFilterChange} />
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Search
            name='filterText'
            placeholder='Input search text'
            size='default'
            color={'black'}
            onChange={e => this.handleChange('text', e.target.value, '')} />
        </Col>
      </Row>
    )
  }

  renderFooter = () => {
    const { stockTakeHeaderDetails, loggedInUser: { permissions = [] } } = this.props;
    const { Change } = stockTakeHeaderDetails;
    const formattedChange = Change && Change.replace(/,/g, '');
    const { } = this.props;
    const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);

    const inEditMode = !!this.state.stockTakeLine;
    if (inEditMode) {
      return null;
    } else {
      return (
        <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {visibleSytemData && <Col lg={8} md={8} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: 10 }}>
            <div style={{ fontSize: "medium" }}>Net Differance Value</div>
            <div style={{ fontSize: "medium" }}>{format(formattedChange, currencyFormat)}</div>
          </Col>}
          <Col lg={2} md={2} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: 10 }}>
            <Button block type='primary' onClick={() => this.props.history.goBack()}>Done</Button>
          </Col>
        </Row>
      );
    }
  }

  getFilteredItems = () => {
    const { items = [] } = this.props;
    const { text, itemFilter } = this.state;

    let filteredItems = items;

    filteredItems = items.filter(item => {
      const { Description } = item;
      const descriptionFilter = text ? this.stringCompare(Description, text) : true;

      return descriptionFilter;
    });

    filteredItems = filterItemsByCategory(filteredItems, itemFilter);

    return filteredItems
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  handleQtyChange = (value, item, stockTakeLineItem) => {
    value = value.replaceAll('-', '');
    const { stockTakeLine } = this.state;
    const { stockTakeHeaderDetails } = this.props;
    this.setState({
      stockTakeLine: {
        ...item,
        PhysicalQty: value
      }
    });
  }



  render() {
    const { stockTakeHeaderDetails } = this.props;
    const items = this.getFilteredItems();
    const { loading, loadingItems } = this.state;

    return (
      <div>
        <Table
          rowKey={'id'}
          rowClassName={(item, index) => this.getRowClassName(item)}
          className='hovering-scroller'
          columns={this.context.screenType === 'MOBILE' ? this.getMobileColumns() : this.getColumns()}
          bodyStyle={{ minHeight: this.getTableHeight(items) }}
          dataSource={items}
          size={'small'}
          scroll={{ y: this.getScrollAreaHeight() }}
          pagination={false}
          title={this.renderTitle}
          footer={stockTakeHeaderDetails && this.renderFooter}
          loading={loading || (items.length === 0 && loadingItems)} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakeHeader } = ownProps;
  const items = getItems(state);
  const stockTakeLineMap = getStockTakeLineMapByDocNo(state, stockTakeHeader.No);
  return {
    authData: getAuthData(state),
    items,
    stockTakeHeaderDetails: getStockTakesHeadersByNo(state, stockTakeHeader.No),
    stockTakeLineMap,
    loggedInUser: getLoggedInUser(state),
  };
};

ItemTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withToastManager(withRouter(connect(mapStateToProps, { fetchItems, fetchStockTakeLines, updateStockTakeLine, fetchStockTakesHeaders })(ItemTable)));