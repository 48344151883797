import uuid from 'uuid';

const ITEM_COUNT = 10;
let data = [];
let customers = [];

export const names = ["Andrew Stephen", "Simon Cowel", "Mitchell Richedson", "Daren Iyan", "Brayan Adems", "Nicoles Stephen", "Theron M Goodman", "Darren Lara", "Elizabeth Mendella"];
export const routeNames = ["Route Name 1", "Route Name 2", "Route Name 3", "Route Name 4", "Route Name 5", "Route Name 6", "Route Name 7", "Route Name 8", "Route Name 9", "Route Name 10"];
export const statuses = ["Active", "Inactive"];
export const salesPersons = ["SALES001", "SALES002", "SALES003", "SALES004"];
export const warehouses = ["Warehouse 1", "Warehouse2", "Warehouse3"];
export const mapLocations = ['6.882561,79.981768', '6.882571,79.981868', '6.882567,79.981761', '6.88221,79.981268'];
export const emails = ["afton1986@yahoo.com", "mafalda1991@gmail.com", "nestor.bedn@yahoo.com", "alexander.rosenba@gmail.com", "monty_treut@hotmail.com", "jabari1983@hotmail.com", "florida_fun7@yahoo.com", "emmanuell9@hotmail.com", "audie_rober@yahoo.com", "rosario_dubuq@hotmail.com"];
export const addresses = ["4611 Lamberts Branch Road", "1828 Ersel Street", "1598 Lost Creek Road", "1441 Pearlman Avenue", "3580 Java Lane", "1429 Pritchard Court", "3047 George Street", "158 Hannah Street", "3514 Broad Street", "414 Werninger Street", "1933 Coburn Hollow Road", "L1933 Evergreen Lane", "4306 Progress Way", "1423 Emeral Dreams Drive", "2439 Stoney Lane", "3054 Memory Lane"];
export const contactNumbers = ["(213)629-1739", "(301)994-9648", "(703)285-6963", "(847)979-9052", "(208)503-5351", " (614)203-3663", "(617)926-0845", "(214)440-1377", "(573)369-1597", "(607)348-3295", " (619)422-2857", "(812)456-6483", "(715)879-3146", "(574)329-1290", "(509)662-2593", "(252)227-3060", "(704)569-2006", "(979)334-0775", "(310)557-3260", "(515)627-9940", "(956)936-3954", "(818)270-1718"];

export const generateData = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateItem(i));
  }
  return data;
}

const generateItem = index => {
  const item = {
    id: "ID" + index,
    routeDescription: "Demo Route description, This contains special note about the route"
  }

  const status = statuses[getRandomNumber(0, statuses.length)]

  item.routeName = routeNames[index];
  item.salesPersons = salesPersons
  item.warehouses = warehouses
  item.mapLocation = mapLocations
  item.status = status
  item.customers = generateCustomers()

  return item;
}

const generateCustomers = () => {
  customers = [];
  for (let i = 0; i < 15; i++) {
    customers.push(generateCustomer(i));
  }
  return customers;
}

const generateCustomer = index => {
  let customer = {
    id: "CODE " + index,
    name: names[getRandomNumber(0, names.length)],
    status: statuses[getRandomNumber(0, statuses.length)],
    email: emails[getRandomNumber(0, emails.length)],
    address: addresses[getRandomNumber(0, addresses.length)],
    contactNumber: contactNumbers[getRandomNumber(0, contactNumbers.length)],
  }
  return customer
}


export const getAllWarehouses = () => {
  let warehouses = ["Warehouse 1", "Warehouse2", "Warehouse3", "Warehouse4", "Warehouse5", "Warehouse6", "Warehouse7"];
  return warehouses
}

export const getAllSalesPersons = () => {
  let warehouses = ["SALES001", "SALES002", "SALES003", "SALES004", "SALES005", "SALES006", "SALES007", "SALES008"];
  return warehouses
}

export const getAllLocations = () => {
  let locations = ["Loc 1", "Loc 2", "Loc 3", "Loc 4"];
  return locations
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const endDate = (start, hours) => {
  return new Date(start.getTime() + (hours * 60 * 60 * 1000));
}

export const getDemoItem = id => {
  return data.find(item => item.id === id);
}

export const getDemoItemWarehouses = id => {
  const route = data.find(item => item.id === id);

  return route ? route.warehouses : [];
}

export const getDemoItemSalesPersons = id => {
  const route = data.find(item => item.id === id);

  return route ? route.salesPersons : [];
}

export const getDemoItemCustomers = id => {
  const route = data.find(item => item.id === id);

  return route ? route.customers : [];
}

export const addDemoCall = call => {
  return data.push(call);
}

export const getDemoCallByUserId = userId => {
  return data.find(call => call.createdBy === userId && call.status === "STARTED");
}

export const endDemoCall = userId => {
  let indexOfCall = undefined;
  const ongoingCall = data.find((call, index) => {
    indexOfCall = index
    return call.createdBy === userId && call.status === "STARTED"
  });
  //console.log("REMOVE ", indexOfCall, ongoingCall)
  data.splice(indexOfCall, 1, ongoingCall)
}

export const getDemoTransactionList = () => {
  return [
    {
      "transaction": "Create a new sales order",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date())
    },
    {
      "transaction": "Create a new collection",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date())
    },
    {
      "transaction": "Confirmed collection",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date())
    },
    {
      "transaction": "Create new customer return",
      "customer": "Mack Shepherd",
      "address": "370 Freeman Street, Sardis, Michigan, 5266",
      time: randomDate(new Date(2012, 0, 1), new Date())
    }
  ]
}


