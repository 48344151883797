import React, { Component } from 'react';
import { Row, Card, Col, Select, DatePicker } from "antd";
import moment from "moment";
import ImageAttachmentContainer from "../../common/ImageAttachmentContainer";
import FormItem from '../../common/FormItem';
import Api from '../../../api/consts';
import Input from '../../../components/common/EnglishInput';
import { dateFormatView } from '../../../const/Formats';

const { Option } = Select;

class PaymentForm extends Component {
  state = {
    showDepositDetailForm: false,
    showChequePDCform: false
  }

  render() {
    const {
      newPaymentId,
      onSelectBankChange,
      onSelectPaymentTypeChange,
      setImageLink,
      payment = {},
      banks,
      onInputChange,
      bankCodeError,
      chequeNoError,
      slipNoError,
      amountError,
      postingDateError } = this.props;
    const { bankCode, paymentType, amount, chequeNo, slipNo, postingDate, links } = payment;
    const showDepositDetailForm = paymentType === 'DEPOSIT' || paymentType === 'Direct Deposite'
    const showChequePDCform = paymentType === 'PDC' || (paymentType === 'CHEQUE' || paymentType === 'Cheque')
    const mainFormWidth = newPaymentId === 'new' ? 24 : 12;
    const attachmentfileUrls = links && links.length > 0 && links.filter(link => link.includes("/attachments/"));
    const fileUrl = attachmentfileUrls && attachmentfileUrls.length > 0 && attachmentfileUrls[attachmentfileUrls.length - 1];
    const signaturefileUrls = links && links.length > 0 && links.filter(link => link.includes("/customer-signature"));
    const customerSigned = signaturefileUrls && signaturefileUrls.length > 0;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={mainFormWidth} md={mainFormWidth} sm={24} xs={24}>
            <Col>
              <Col style={{ marginBottom: 10 }}>
                <div style={{ fontWeight: 500 }}>Payment Type</div>
                <Select value={paymentType} onChange={onSelectPaymentTypeChange} style={{ width: '100%' }}>
                  {paymentTypes.map(type => <Option key={type.key} value={type.value}>{type.name}</Option>)}
                </Select>
              </Col>
              <FormItem label={"Amount"} error={amountError}>
                <input
                  className="ant-input"
                  value={amount || 0}
                  style={{ width: '100%' }}
                  type="number"
                  min="0"
                  onChange={(e) => onInputChange("amount", e.target.value, "amountError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>
            {showChequePDCform && <Col>
              <FormItem label={"Bank"} error={bankCodeError} lg={24} md={24} sm={24} xs={24}>
                <Select value={bankCode} placeholder={"Select bank"} onChange={onSelectBankChange} style={{ width: '100%' }}>
                  {banks.map(bank => <Option key={bank.bankid} value={bank.bankid}>{bank.bankname}</Option>)}
                </Select>
              </FormItem>
              <FormItem label={"Cheque No"} error={chequeNoError} lg={24} md={24} sm={24} xs={24}>
                <Input value={chequeNo} onChange={value => onInputChange("chequeNo", value, "chequeNoError")} />
              </FormItem>
              <FormItem label={"posting Date"} error={postingDateError} lg={24} md={24} sm={24} xs={24}>
                <DatePicker
                  allowClear={false}
                  format={dateFormatView}
                  style={{ width: '100%' }}
                  value={postingDate && moment(postingDate)}
                  onChange={(date, dateString) => onInputChange("postingDate", date, "postingDateError")}
                />
              </FormItem>
            </Col>}
            {showDepositDetailForm && <Col>
              <FormItem label={"Bank"} error={bankCodeError} lg={24} md={24} sm={24} xs={24}>
                <Select value={bankCode} placeholder={"Select bank"} onChange={onSelectBankChange} style={{ width: '100%' }}>
                  {banks.map(bank => <Option key={bank.bankid} value={bank.bankid}>{bank.bankname}</Option>)}
                </Select>
              </FormItem>
              <FormItem label={"Slip No"} error={slipNoError} lg={24} md={24} sm={24} xs={24}>
                <Input value={slipNo} onChange={value => onInputChange("slipNo", value, "slipNoError")} />
              </FormItem>
            </Col>}
          </Col>
          {newPaymentId !== 'new' && <Col lg={12} md={12} sm={24} xs={24}>
            <div style={{ fontWeight: 500 }}>Attach image</div>
            <ImageAttachmentContainer
              setImageLink={setImageLink}
              fileUrl={fileUrl ? Api.files.download.url(fileUrl) : undefined}
              dirPath={"collections/" + newPaymentId + "/attachments"}
              fileName={"collection-" + newPaymentId + "-" + new Date().getTime()}
              customerSigned={payment.status !== 'Unverified'} />
          </Col>}
        </Row>
      </Card>
    )
  }
}

export default PaymentForm;

const paymentTypes = [
  {
    name: 'CASH',
    value: 'CASH',
    key: 'CASH'
  },
  {
    name: 'PDC - Post Dated Cheque',
    value: 'PDC',
    key: 'PDC'
  },
  {
    name: 'CHEQUE',
    value: 'CHEQUE',
    key: 'CHEQUE'
  },
  {
    name: 'Direct Deposit',
    value: 'DEPOSIT',
    key: 'DEPOSIT'
  }
]