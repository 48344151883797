import React, { Component } from 'react';
import { Form, Row, Col } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import AllocatedDetails from '../component/AllocatedDetails';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class InvoiceFooter extends Component {
  render() {
    const { enableDeallocate, onDataAllocated, invoice } = this.props;
    const { OrgAmount, AvgDisPersentage, Amount, SettledAmount, RemAmount, PDCAmount, NetRemAmount, Remarks, No, UnconfirmedNonPDC, DisAmount } = invoice || {};

    const grossAmount = !OrgAmount || OrgAmount && OrgAmount === "" ? 0 : OrgAmount.replace(/,/g, '');
    const discount = DisAmount && DisAmount.replace(/,/g, '');
    const netAmount = !Amount || Amount && Amount === "" ? 0 : Amount.replace(/,/g, '');
    const settledAmount = !SettledAmount || SettledAmount && SettledAmount === "" ? 0 : SettledAmount.replace(/,/g, '');
    const remAmount = !RemAmount || RemAmount && RemAmount === "" ? 0 : RemAmount.replace(/,/g, '');
    const pdcAmount = !RemAmount || RemAmount && PDCAmount === "" ? 0 : PDCAmount.replace(/,/g, '');
    const netRemAmount = !RemAmount || RemAmount && NetRemAmount === "" ? 0 : NetRemAmount.replace(/,/g, '');

    return (
      <div className='promate-form-header'>
        <Form>
          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Col span={24}>
              <Col lg={12} md={12} sm={0} xs={0}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <p>{Remarks}</p>
                </FormItem>
                <Col xs={0} sm={0} md={24} lg={24}>
                  <AllocatedDetails docType={"2"} docNo={No} isMobileView={false} enableDeallocate={false} onDataAllocated={onDataAllocated} />
                </Col>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Gross Amount</div>
                  <div style={{ fontSize: "large" }}>{format(parseFloat(grossAmount), currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Discount ({AvgDisPersentage}%) </div>
                  <div style={{ fontSize: "large" }}>{format(parseFloat(discount), currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>Net Amount</div>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>{format(parseFloat(netAmount), currencyFormat)}</div>
                </Col>
                <br></br>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Settled Amount</div>
                  <div style={{ fontSize: "large" }}>{format(parseFloat(settledAmount), currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Remaining Outstanding</div>
                  <div style={{ fontSize: "large" }}>{format(parseFloat(remAmount), currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>PDC Balance</div>
                  <div style={{ fontSize: "large" }}>{format(parseFloat(pdcAmount), currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>Net Outstanding</div>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>{format(parseFloat(netRemAmount), currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "medium", color: 'dodgerblue' }}>Other Payments(Posting Pending)</div>
                  <div style={{ fontSize: "medium", color: 'dodgerblue' }}>{format(parseFloat(UnconfirmedNonPDC), currencyFormat)}</div>
                </Col>

              </Col>
              <Col lg={0} md={0} sm={24} xs={24}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <p>{Remarks}</p>
                </FormItem>
                <Col lg={0} md={0} sm={24} xs={24}>
                  <AllocatedDetails docType={"2"} docNo={No} isMobileView={true} enableDeallocate={false} onDataAllocated={onDataAllocated} />
                </Col>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default InvoiceFooter;