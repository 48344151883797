import md5 from 'md5';
import Api from './consts';
import fetch from './commonFetch';

export const authenticate = credentials => {
	credentials.password = md5(credentials.password);
	const { url, method } = Api.auth;
	return fetch(url(), { method, body: JSON.stringify(credentials) }, 'authData');
}

export const resetPassword = credentials => {
	credentials.password = md5(credentials.password);
	const { url, method } = Api.auth.resetPassword;
	return fetch(url(), { method, body: JSON.stringify(credentials) }, 'data');
}

export const sendPasswordResetOTP = credentials => {
	const { url, method } = Api.auth.sendPasswordResetOTP;
	return fetch(url(), { method, body: JSON.stringify(credentials) }, 'status');
}
