import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchCustomers, fetchCustomerCategories, fetchCustomerSubcategories } from "../../redux/actions/customers";
import { fetchPaymentHeaders, fetchPaymentTypes } from "../../redux/actions/paymentHeaders";
import { getPaymentHeadersBySearchKey, getAuthData, getLoggedInUser, getTopPosition, getFlatPositions, getPaymentHeaderTotal, getPaymentHeaderMetadata, isFetchingPaymentHeaders, isCallInProgress, getInProgressCall } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import ReceiptTable from "./ReceiptTable";
import moment from 'moment';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { withToastManager } from 'react-toast-notifications';
import { RECEIPTS } from "../../const/Permissions";
import { FaPlus } from 'react-icons/fa';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate, dateTimeFormatFilterView } from '../../const/Formats';

const receiptStatuses = {
  Open: 0,
  'Customer Confirmed': 1,
  Released: 2,
  Returned: 3,
  Reversed: 4,
  'On Hold': 6,
  Posted: 7,
  Rejected: 8
};

class Receipts extends Component {
  baseUrl = window.location.pathname;
  state = {
    loading: false,
    filter: {
      DocType: undefined,
      UserCode: undefined,
      RouteCode: undefined,
      OutletCategoryCode: undefined,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchCustomerCategories, fetchCustomerSubcategories, fetchCustomers, fetchPaymentTypes } = this.props;
    const { customerId } = match.params

    fetchCustomerCategories();
    fetchCustomerSubcategories();
    fetchPaymentTypes();
    fetchCustomers({ UserCode: userId });

    this.setState({
      filter: {
        ...this.state.filter,
        UserCode: userId,
        CusCode: customerId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { customerId, distributorId } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadReceipts(nextProps);
      });
    }, { ...this.state.filter, CustNo: customerId, DistributorCode: distributorId });
  }

  loadReceipts = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchReceipts(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page);

  fetchReceiptPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchReceipts(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchReceipts(filter, currentPage, currentSearchKey);
  }

  fetchReceipts = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      PostingDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      CustNo: undefined,
      CusCode: filter.CustNo,
      UserCode: undefined,
      text: undefined,
      No: filter.text ? '*' + filter.text + '*' : undefined,
      OrderStatus: undefined,
      Status: undefined,
      StatusFilter: receiptStatuses[filter['Status']]
    }

    this.props.fetchPaymentHeaders(updatedFilter, page, searchKey);
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
      case 'RouteCode':
        newFilter.CustNo = undefined;
        break;
    }

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No, CusCode: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }

  render() {
    const { filter, currentPage } = this.state;
    const { loading, loggedInUser: { permissions = [], roleCode }, match, receipts, total, totalAmount, topPosition } = this.props;
    const { ongoingCall, inCall } = this.props;
    const { CustNo, StartReasonCode } = ongoingCall || {};
    const { customerId } = match.params;

    return (
      <div>
        <PromatePageHeader
          title={'Receipts'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={permissions.includes(RECEIPTS.CREATE) && ((inCall && CustNo !== '') || customerId) && [
            <CallStatusValidateButton onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </CallStatusValidateButton>,
          ]}>

          <Spin spinning={(loading && receipts.length === 0) || !topPosition} tip={"Loading..."}>
            <ReceiptTable
              receipts={receipts}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              setDefaultCustomerOption={this.setDefaultCustomerOption}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount}
              statusOptions={Object.keys(receiptStatuses)} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { ongoingCall, inCall, match } = this.props;
    const { customerId } = match.params
    const { CustNo } = ongoingCall || {};
    if (inCall && !customerId) {
      this.props.history.push(`/customers/${CustNo}/receipts/new`);
    } else {
      this.props.history.push(this.props.match.url + "/new");
    }
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

Receipts.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params
  const searchKey = getSearchKey();

  return {
    receipts: getPaymentHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getPaymentHeaderTotal(state, searchKey),
    totalAmount: getPaymentHeaderMetadata(state, searchKey).TotalAmount,
    loading: isFetchingPaymentHeaders(state),
    inCall: isCallInProgress(state),
    ongoingCall: getInProgressCall(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchPaymentHeaders, fetchCustomers, fetchCustomerCategories, fetchCustomerSubcategories, fetchPaymentTypes })(Receipts));