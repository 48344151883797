import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

class CreditNoteHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  renderActions = () => {
    const { creditNote, newCreditNote, onSubmitCreditNote, onClickConfirmCreditNote, onShareCreditNote, onDeleteCreditNote } = this.props;
    const { salesLines, status } = creditNote;

    if (newCreditNote) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitCreditNote}
        >
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (creditNote.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!creditNote.links || creditNote.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteCreditNote}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       creditNote.salesLines && creditNote.salesLines.length > 0 &&
      //       (!creditNote.links || creditNote.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmCreditNote}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (creditNote.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       creditNote.links && creditNote.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareCreditNote()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteCreditNote}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmCreditNote}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareCreditNote()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  render() {
    const { creditNote } = this.props;
    const { creditNoteNo, creditNoteDate, status, sellToCustomerName, sellToCustomerCode, billToCustomerCode, billToCustomerName } = creditNote;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {creditNoteNo && <tr>
              <td>CreditNote #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{creditNoteNo ? creditNoteNo.replace(/---/g, '/') : ''}</td>
            </tr>}
            {creditNoteDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(creditNoteDate).format(dateFormatView)}</td>
            </tr>}
            {status && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>
                {<Tag color={this.getStatusColor(status)}>{status}</Tag>}
              </td>
            </tr>}
            {sellToCustomerCode && <tr>
              <td>Merchant #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerCode}</td>
            </tr>}
            {sellToCustomerName && <tr>
              <td>Merchant Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerName}</td>
            </tr>}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {billToCustomerCode && <tr>
              <td>Customer #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerCode}</td>
            </tr>}
            {billToCustomerName && <tr>
              <td>Customer Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerName}</td>
            </tr>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default CreditNoteHeader;