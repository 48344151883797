import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_LINES_FOR_DOC': {
			const newState = { ...state };
			action.paymentLines.forEach(line => {
				newState[getId(line)] = line;
			});
			return newState;
		}
		case 'FETCH_PAYMENT_LINE_FOR_DOC': {
			const newState = { ...state };
			action.paymentLines.forEach(line => {
				newState[getId(line)] = line;
			});
			return newState;
		}
		case 'REMOVE_PAYMENT_LINE': {
			const newState = { ...state };
			action.paymentLines.forEach(line => {
				delete newState[getId(line)];
			});
			return newState;
		}
		default:
			return state;
	}
};

const byDocNo = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_LINES_FOR_DOC': {
			const { DocNo, paymentLines } = action;
			return { ...state, [DocNo]: paymentLines.map(getId) };
		}
		case 'FETCH_PAYMENT_LINE_FOR_DOC': {
			const newState = { ...state };
			action.paymentLines.forEach(line => {
				const ids = newState[line.DocNo] || [];
				if (!ids.includes(getId(line))) {
					ids.push(getId(line));
					newState[line.DocNo] = ids;
				}
			});
			return newState;
		}
		case 'REMOVE_PAYMENT_LINE': {
			const newState = { ...state };
			action.paymentLines.forEach(line => {
				const ids = newState[line.DocNo] || [];
				newState[line.DocNo] = ids.filter(id => id !== getId(line))
			});
			return newState;
		}
		default:
			return state;
	}
}

const paymentLines = combineReducers({
	byId,
	byDocNo
});

export default paymentLines;

export const getForDoc = (state, docNo) => (state.byDocNo[docNo] || []).map(id => state.byId[id]);

export const getPaymentLineMapForDoc = (state, docNo) => {
	const PaymentLineMap = {};
	(state.byDocNo[docNo] || []).forEach(id => {
		const { InvoiceNo } = state.byId[id];
		PaymentLineMap[InvoiceNo] = state.byId[id];
	});

	return PaymentLineMap;
}

const getId = line => line.LineNo;