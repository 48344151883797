import React, { Component } from "react";
import { Col, Row } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { dateFormatView } from "../../const/Formats";

class InvoiceCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'PENDING':
        return 'green';
      case 'CONFIRMED':
        return 'red';
      case 'PAID':
        return 'blue';
      case 'DELIVERED':
        return 'yellow';
      default:
        return '';
    }
  }

  render() {
    const { invoice, navigateToInvoiceView } = this.props;
    const { invoiceNo, orderDate, sellToCustomerCode, sellToCustomerName, totalAmount } = invoice;

    return (
      <Row onClick={navigateToInvoiceView}>
        <Row style={styles.alignRow}>
          <Col xs={13} sm={11} md={6} lg={6} style={{ textAlign: 'left' }}>
            <span style={styles.invoiceNo}>#{invoiceNo && invoiceNo.replace(/---/g, '/')}</span>
          </Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={4} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{sellToCustomerCode}</p>
          </Col>
          <Col xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'left' }}>
            <span>{sellToCustomerName}</span>
          </Col>
          <Col xs={7} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={totalAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={18} sm={21} md={0} lg={0} style={styles.bottomColumnStyles}>
            <FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  invoiceNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

export default InvoiceCard;