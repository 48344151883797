import { combineReducers } from 'redux';
import { FETCH_STOCKTAKES_HEADER, FETCH_STOCKTAKES_HEADERS, DELETE_STOCKTAKES_HEADER, START_FETCH_STOCKTAKES_HEADERS, FINISH_FETCH_STOCKTAKES_HEADERS } from '../actions/stockTakeHeaders';
import { DOC_TYPE_DEFINITIONS } from ".";

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STOCKTAKES_HEADERS:
      const newState = { ...state };
      action.stockTakeHeaders.forEach(stockTakeHeader => newState[stockTakeHeader.No] = stockTakeHeader);
      return newState;
    case FETCH_STOCKTAKES_HEADER: {
      const newState = { ...state };
      newState[action.stockTakeHeader.No] = action.stockTakeHeader;
      return newState;
    }
    case DELETE_STOCKTAKES_HEADER: {
      const newState = { ...state };
      delete newState[action.stockTakeHeader.No];
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_STOCKTAKES_HEADERS: {
      const newState = [...state]
      action.stockTakeHeaders.forEach(stockTakeHeader => {
        if (!newState.includes(stockTakeHeader.No)) newState.push(stockTakeHeader.No);
      });

      return newState;
    }
    case FETCH_STOCKTAKES_HEADER: {
      const newState = [...state];
      if (!newState.includes(action.stockTakeHeader.No)) {
        newState.push(action.stockTakeHeader.No)
      }
      return newState;
    }
    case DELETE_STOCKTAKES_HEADER: {
      const newState = [...state];
      return newState.filter(id => id !== action.stockTakeHeader.No);
    }
    default:
      return state;
  }
};


export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STOCKTAKES_HEADERS: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.stockTakeHeaders.map(header => header.No)
      };
    }
    case DELETE_STOCKTAKES_HEADER: {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        const ids = newState[key];
        newState[key] = ids.filter(id => id !== action.stockTakeHeader.No)
      });
      return newState;
    }
    default:
      return state;
  }
};

export const total = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STOCKTAKES_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.total
      };

    default:
      return state;
  }
}

export const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STOCKTAKES_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.metadata
      };

    default:
      return state;
  }
}

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_STOCKTAKES_HEADERS:
      return true;

    case FINISH_FETCH_STOCKTAKES_HEADERS:
      return false;

    default:
      return state;
  }
}

const stockTakeHeaders = combineReducers({
  byId,
  ids,
  bySearchKey,
  total,
  metadata,
  fetching
});

export default stockTakeHeaders;

export const getStockTakesHeadersByNo = (state, no) => state.byId[no];


const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;

