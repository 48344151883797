import React, { Component } from 'react';
import { Card, Tag, Divider, Col, Icon, Row } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { dateFormatView } from "../../../const/Formats";

const currencyFormat = {
  decimalDigits: 2,
  decimalSeparator: '.'
}

class Details extends Component {

  render() {
    const { expense } = this.props;
    return (
      <div>
        <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={8} md={8} sm={24} xs={24}>
              {expense.id && <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>ID</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{expense.id}</td>
              </tr>}
              <tr>
                <td style={{ width: 25 }}><Icon type="info-circle" /></td>
                <td><p>Status</p></td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td><Tag color={getStatusColor(expense.status)}>{expense.status || 'Active'}</Tag></td>
              </tr>

            </Col>

            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>Created By</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{expense.createdBy}</td>
              </tr>
              <tr>
                <td style={{ width: 25 }}><Icon type="info-circle" /></td>
                <td><p>Created At</p></td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{moment(expense.createdAt).format(dateFormatView)}</td>
              </tr>
            </Col>


            {/* <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              {expense.contactNumber && <tr>
                <td style={{ width: 25 }}><Icon type="phone" /></td>
                <td>Contact No</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{expense.contactNumber}</td>
              </tr>}
              {expense.email && <tr>
                <td style={{ width: 25 }}><Icon type="mail" /></td>
                <td>Email</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{expense.email}</td>
              </tr>}
              {expense.address && <tr>
                <td style={{ width: 25 }}><Icon type="pushpin" /></td>
                <td>Address</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{expense.address}</td>
              </tr>}
            </Col> */}

          </Row>
        </Card>
      </div>
    )
  }
}

const getStatusColor = status => {
  if (status) return 'red'
  else return 'green'
}

export default Details;