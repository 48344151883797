import { combineReducers } from 'redux';
import { cleanState } from '../../store';
import auth, * as fromAuth from './auth';
import inventory, * as fromInventory from './inventory';
import orders, * as fromOrders from './orders';
import customers, * as fromCustomers from './customers';
import invoices, * as fromInvoices from './invoices';
import collections, * as fromCollections from './collections';
import networkDetector from './networkDetector';
import routes, * as fromRoutes from './routes';
import filteredRoutes, * as fromFilteredRoutes from './filteredRoutes';
import users, * as fromUsers from './users';
import distributors, * as fromDistributors from './distributors';
import calls, * as fromCalls from './calls';
import salesHeaders, * as fromSalesHeaders from './salesHeaders';
import postedCreditNotes, * as fromPostedCreditNotes from './postedCreditNotes';
import items, * as fromItems from './items';
import glAccounts, * as fromGlAccounts from './glAccounts';
import warehouses, * as fromWarehouses from './warehouses';
import vendoers, * as fromVendorNumbers from './vendor';
import salesLines, * as fromSalesLines from './salesLines';
import postedCreditNoteLines, * as fromPostedCreditNoteLines from './postedCreditNoteLines';
import transferHeaders, * as fromTransferHeaders from './transferHeaders';
import transferLines, * as fromTransferLines from './transferLines';
import paymentHeaders, * as fromPaymentHeaders from './paymentHeaders';
import paymentLines, * as fromPaymentLines from './paymentLines';
import distributorPaymentHeaders, * as fromDistributorPaymentHeaders from './distributorPaymentHeaders';
import distributorPaymentLines, * as fromDistributorPaymentLines from './distributorPaymentLines';
import attachments, * as fromAttachments from './attachments';
import tripSchedules, * as fromTripSchedules from './tripSchedules';
import salesInvoiceHeaders, * as fromSalesInvoiceHeaders from './salesInvoiceHeaders';
import salesInvoiceLines, * as fromSalesInvoiceLines from './salesInvoiceLines';
import paymentMethods, * as fromPaymentMethods from './paymentMethods';
import paymentTerms, * as fromPaymentTerms from './paymentTerms';
import salesReturnReasons, * as fromSalesReturnReasons from './SalesReturnReasons';
import itemFilters, * as fromItemFilters from './itemFilters';
import itemFiltersAllItems, * as fromItemAllItemsFilters from './itemFiltersAllItems';

import salesReturnReceiptsHeaders, * as fromSalesReturnReceiptsHeaders from './salesReturnReceiptHeaders';
import allocatedDetails, * as fromAllocatedDetails from './allocatedDetails';
import purchaseHeaders, * as fromPurchaseHeaders from './purchasingHeaders';
import purchaseLines, * as fromPurchaseLines from './purchaseLines';
import allItems, * as fromAllItems from './AllItems';
import territories, * as fromTerritories from './territories';
import lotWiseItems, * as fromLotWiseItems from './lotwiseItems';

import purchasingInvoiceHeaders, * as fromPurchasingInvoiceHeaders from './purchasingInvoiceHeaders';
import purchasingInvoiceLines, * as fromPurchasingInvoiceLines from './purchasingInvoiceLines';
import purchaseAllocatedDetails, * as fromPurchaseAllocatedDetails from './purchaseAllocatedDetails';
import stockTakesHeaders, * as fromStockTakesHeaders from './stockTakeHeaders';
import stockTakeLines, * as fromStockTakeLines from './stockTakeLines';
import expenses, * as fromExpenses from './expenses';
import expensesCategories, * as fromExpensesCategories from './expensesCategories';

import purchaseCreditInvoices, * as fromPurchaseCreditInvoices from './purchaseCreditInvoices';
import purchaseCreditInvoiceLines, * as fromPurchaseCreditInvoiceLines from './purchaseCreditInvoiceLines';

/*OFFLINE REVELOPMENT*/
import isSystemOffline from './networkMode';
import salesDiscountReasons, * as fromSalesDiscountReasons from './salesDiscountReasons';

// TASK SCHEDULER
import targetScheduler from './targetScheduler'

// FAILED ORDERS
import failedOrders from './failedOrders';

export const DOC_TYPE = {
  SALES_ORDER: '1',
  SALES_INVOICE: '2',
  CREDIT_MEMO: '3'
}

export const DOC_TYPE_DEFINITIONS = {
  '1': 'Order',
  '2': 'Invoice',
  '5': 'ReturnOrder',
  '3': 'CreditMemo'
}

export const DOC_TYPE_DEFINITIONS_WITH_SPACE = {
  '1': 'Order',
  '2': 'Invoice',
  '5': 'Return Order',
  '3': 'CreditMemo'
}

export const DOC_TYPE_ID_BY_DEFINITION = {
  'Order': '1',
  'Invoice': '2',
  'ReturnOrder': '5',
  'CreditMemo': '3'
}


export const DOC_TYPE_FLAG_BY_DEFINITION = {

  'ReturnOrder': 'RETURN ORDER',
  'CreditMemo': 'CREDIT MEMO'
}

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = cleanState(state);
  }

  return appReducer(state, action)
}

const appReducer = combineReducers({
  auth,
  inventory,
  orders,
  customers,
  invoices,
  collections,
  networkDetector,
  routes,
  users,
  distributors,
  calls,
  salesHeaders,
  postedCreditNotes,
  items,
  glAccounts,
  warehouses,
  vendoers,
  salesLines,
  postedCreditNoteLines,
  transferHeaders,
  transferLines,
  paymentHeaders,
  paymentLines,
  distributorPaymentHeaders,
  distributorPaymentLines,
  attachments,
  tripSchedules,
  salesInvoiceHeaders,
  salesInvoiceLines,
  paymentMethods,
  paymentTerms,
  filteredRoutes,
  salesReturnReasons,
  salesReturnReceiptsHeaders,
  itemFilters,
  allocatedDetails,
  purchaseHeaders,
  purchaseLines,
  allItems,
  territories,
  lotWiseItems,
  purchasingInvoiceHeaders,
  purchasingInvoiceLines,
  purchaseAllocatedDetails,
  stockTakesHeaders,
  stockTakeLines,
  expenses,
  expensesCategories,
  purchaseCreditInvoices,
  purchaseCreditInvoiceLines,
  itemFiltersAllItems,
  isSystemOffline,
  salesDiscountReasons,
  targetScheduler,
  failedOrders
});

export default rootReducer;

export const isAuthenticated = state => fromAuth.isAuthenticated(state.auth);
export const getAuthData = state => fromAuth.getAuthData(state.auth);
export const getLoggedInUser = state => fromAuth.getUser(state.auth);

export const getInventory = state => fromInventory.getInventory(state.inventory);
export const getBrands = state => fromInventory.getBrands(state.inventory);
export const getCategory1s = state => fromInventory.getCategory1s(state.inventory);
export const getCategory2s = state => fromInventory.getCategory2s(state.inventory);
export const getCategory3s = state => fromInventory.getCategory3s(state.inventory);
export const getCategory4s = state => fromInventory.getCategory4s(state.inventory);
export const getCombinedFilter = state => fromInventory.getCombinedFilter(state.inventory);
export const getInventoryItem = (state, id) => fromInventory.getItem(state.inventory, id);
export const getInventoryPageSize = state => fromInventory.getPageSize(state.inventory);

export const getOrders = state => fromOrders.getOrders(state.orders);
export const getOrdersByCustomer = (state, customerId) => fromOrders.getOrdersByCustomer(state.orders, customerId);
export const getOrder = (state, orderId) => fromOrders.getOrder(state.orders, orderId);

export const getCustomers = state => fromCustomers.getCustomers(state.customers);
export const getMapCustomers = state => fromCustomers.getMapCustomers(state.customers);
export const getDropdownCustomers = state => fromCustomers.getDropdownCustomers(state.customers);
export const getCustomer = (state, customerId) => fromCustomers.getCustomer(state.customers, customerId);
export const getCustomersByRoute = (state, routeId) => fromCustomers.getCustomersByRoute(state.customers, routeId);
export const getCustomerTotal = (state, searchKey) => fromCustomers.getTotal(state.customers, searchKey);
export const isFetchingCustomers = state => fromCustomers.isFetchingCustomers(state.customers);
export const isFetchingMapCustomers = state => fromCustomers.isFetchingMapCustomers(state.customers);
export const isFetchingDropdownCustomers = state => fromCustomers.isFetchingDropdownCustomers(state.customers);
export const isFetchingCustomerCategories = state => fromCustomers.isFetchingCustomerCategories(state.customers);
export const getCustomerCategories = state => fromCustomers.getCustomerCategories(state.customers);
export const getCustomerSubcategories = state => fromCustomers.getCustomerSubcategories(state.customers);
export const getCustomerCategoryById = (state, id) => fromCustomers.getCustomerCategoryById(state.customers, id);
export const getCustomerCategoryByCustomerId = (state, customerId) => fromCustomers.getCustomerCategoryByCustomerId(state.customers, customerId);
export const getCustomerSubcategoryByCustomerId = (state, customerId) => fromCustomers.getCustomerSubcategoryByCustomerId(state.customers, customerId);
export const getCustomersBySearchKey = (state, searchKey) => fromCustomers.getBySearchKey(state.customers, searchKey);

export const getInvoices = state => fromInvoices.getInvoices(state.invoices);
export const getInvoicesByCustomer = (state, customerId) => fromInvoices.getInvoicesByCustomer(state.invoices, customerId);
export const getInvoice = (state, invoiceNo) => fromInvoices.getInvoice(state.invoices, invoiceNo);

export const getCollections = state => fromCollections.getCollections(state.collections);
export const getCollectionsByCustomer = (state, customerId) => fromCollections.getCollectionsByCustomer(state.collections, customerId);
export const getCollection = (state, collectionID) => fromCollections.getCollection(state.collections, collectionID);

export const getRoutes = state => fromRoutes.getRoutes(state.routes);
export const getRoute = (state, routeCode) => fromRoutes.getRoute(state.routes, routeCode);
export const getRoutesByUser = (state, UserCode) => fromRoutes.getRoutesByUser(state.routes, UserCode);
export const isFetchingRoutes = state => fromRoutes.isFetchingRoutes(state.routes);
export const getRouteTotal = (state, key) => fromRoutes.getTotal(state.routes, key);
export const getRoutesBySearchKey = (state, key) => fromRoutes.getBySearchKey(state.routes, key);

export const getSalesUserHierarchy = state => fromUsers.getHierarchy(state.users);
export const getTopPosition = state => fromUsers.getTopPosition(state.users);
export const getFlatPositions = state => fromUsers.getFlatPositions(state.users);
export const getHierarchyBranch = (state, positionCode) => fromUsers.getHierarchyBranch(state.users, positionCode);

export const getDistributors = state => fromDistributors.getDistributors(state.distributors);
export const getMapDistributors = state => fromDistributors.getMapDistributors(state.distributors);
export const getDistributor = (state, distributorId) => fromDistributors.getDistributor(state.distributors, distributorId);
export const getDistributorTotal = (state, searchKey) => fromDistributors.getTotal(state.distributors, searchKey);
export const isFetchingDistributors = state => fromDistributors.isFetchingDistributors(state.distributors);
export const isFetchingMapDistributors = state => fromDistributors.isFetchingMapDistributors(state.distributors);
export const getDistributorsBySearchKey = (state, searchKey) => fromDistributors.getBySearchKey(state.distributors, searchKey);

export const getCalls = state => fromCalls.getCalls(state.calls);
export const isFetchingCalls = state => fromCalls.isFetchingCalls(state.calls);
export const isFetchingCall = (state, No) => fromCalls.isFetchingCall(state.calls, No);
export const isFetchingCallRegister = (state, CallRegID) => fromCalls.isFetchingRegister(state.calls, CallRegID);
export const getCallLog = (state, CallRegID) => fromCalls.getLog(state.calls, CallRegID);
export const getCallTotal = (state, searchKey) => fromCalls.getTotal(state.calls, searchKey);
export const getCall = (state, callCode) => fromCalls.getCall(state.calls, callCode);
export const isCallInProgress = state => fromCalls.isCallInProgress(state.calls);
export const getStartCallReasons = state => fromCalls.getStartCallReasons(state.calls);
export const getInProgressCall = state => fromCalls.getInProgressCall(state.calls);
export const getCallEndStatuses = state => fromCalls.getCallEndStatuses(state.calls);
export const getSalesValue = state => fromCalls.getSalesValue(state.calls);
export const isFetchingSalesValue = state => fromCalls.isFetchingSalesValue(state.calls);
export const getCallsBySearchKey = (state, key) => fromCalls.getBySearchKey(state.calls, key);

export const getSalesHeadersByType = (state, type) => fromSalesHeaders.getSalesHeadersByType(state.salesHeaders, type);
export const getSalesHeadersByTypeAndNo = (state, type, no) => fromSalesHeaders.getSalesHeadersByTypeAndNo(state.salesHeaders, type, no);
export const getSalesHeadersByTypeAndCustomer = (state, type, id) => fromSalesHeaders.getSalesHeadersByTypeAndCustomer(state.salesHeaders, type, id);
//export const getTotalRecordCountByFilter = state => fromSalesHeaders.getTotalRecordCountByFilter(state.salesHeaders);
export const getSalesHeadersBySearchKey = (state, key) => fromSalesHeaders.getBySearchKey(state.salesHeaders, key);
export const getSalesHeaderTotal = (state, searchKey) => fromSalesHeaders.getTotal(state.salesHeaders, searchKey);
export const getSalesHeaderMetadata = (state, searchKey) => fromSalesHeaders.getMetadata(state.salesHeaders, searchKey);
export const isFetchingSalesHeaders = state => fromSalesHeaders.isFetching(state.salesHeaders);
export const getSalesCreditMemoReasons = state => fromSalesHeaders.getSalesCreditMemoReasons(state.salesHeaders);

export const getPostedCreditNotesByType = (state, type) => fromPostedCreditNotes.getPostedCreditNotesByType(state.postedCreditNotes, type);
export const getPostedCreditNotesByTypeAndNo = (state, type, no) => fromPostedCreditNotes.getPostedCreditNotesByTypeAndNo(state.postedCreditNotes, type, no);
export const getPostedCreditNotesByNo = (state, no) => fromPostedCreditNotes.getPostedCreditNotesByNo(state.postedCreditNotes, no);
export const getPostedCreditNotesByTypeAndCustomer = (state, type, id) => fromPostedCreditNotes.getPostedCreditNotesByTypeAndCustomer(state.postedCreditNotes, type, id);
export const getPostedCreditNotesBySearchKey = (state, key) => fromPostedCreditNotes.getBySearchKey(state.postedCreditNotes, key);
export const getPostedCreditNoteTotal = (state, searchKey) => fromPostedCreditNotes.getTotal(state.postedCreditNotes, searchKey);
export const getPostedCreditNoteMetadata = (state, searchKey) => fromPostedCreditNotes.getMetadata(state.postedCreditNotes, searchKey);
export const isFetchingPostedCreditNotes = state => fromPostedCreditNotes.isFetching(state.postedCreditNotes);

export const getItems = state => fromItems.getItems(state.items);
export const getItem = (state, itemNo) => fromItems.getItem(state.items, itemNo);
export const getItemByItemNo = (state, itemNo) => fromItems.getItemByItemNo(state.items, itemNo);

export const getAllGlAccounts = state => fromGlAccounts.getAll(state.glAccounts);

export const getLocations = state => fromWarehouses.getLocations(state.warehouses);

export const getVendorNumbers = state => fromVendorNumbers.getVendorDetails(state.vendoers);

export const getSalesLinesByDocTypeAndDocNo = (state, type, no) => fromSalesLines.getSalesLinesByDocTypeAndDocNo(state.salesLines, type, no);
export const getSalesLineMapByDocTypeAndDocNo = (state, type, no) => fromSalesLines.getSalesLineMapByDocTypeAndDocNo(state.salesLines, type, no);
export const getSalesLine = (state, type, docNo, lineNo) => fromSalesLines.getSalesLine(state.salesLines, type, docNo, lineNo);

export const getPostedCreditNoteLinesByDocNo = (state, no) => fromPostedCreditNoteLines.getLinesByDocNo(state.postedCreditNoteLines, no);
export const getPostedCreditNoteLine = (state, docNo, lineNo) => fromPostedCreditNoteLines.getLine(state.salesLines, docNo, lineNo);

export const getSalesInvoiceLinesByDocTypeAndDocNo = (state, type, no) => fromSalesInvoiceLines.getSalesLinesByDocTypeAndDocNo(state.salesInvoiceLines, type, no);
export const getSalesInvoiceLineMapByDocTypeAndDocNo = (state, type, no) => fromSalesInvoiceLines.getSalesLineMapByDocTypeAndDocNo(state.salesInvoiceLines, type, no);
export const getSalesInvoiceLineMapByDocTypeAndItemNo = (state, type, itemNo) => fromSalesInvoiceLines.getSalesLinesByDocTypeAndItemNo(state.salesInvoiceLines, type, itemNo);
export const getSalesInvoiceLine = (state, type, docNo, lineNo) => fromSalesInvoiceLines.getSalesLine(state.salesInvoiceLines, type, docNo, lineNo);

export const getTransferHeaders = state => fromTransferHeaders.getAll(state.transferHeaders);
export const getTransferHeader = (state, no) => fromTransferHeaders.get(state.transferHeaders, no);
export const getTransferHeadersTotal = (state) => fromTransferHeaders.getTotal(state.transferHeaders);
export const isFetchingTransferHeaders = state => fromTransferHeaders.isFetchingTransferHeaders(state.transferHeaders);
export const getTransferHeadersBySearchKey = (state, key) => fromTransferHeaders.getBySearchKey(state.transferHeaders, key);

export const getTransferLinesForDoc = (state, docNo) => fromTransferLines.getForDoc(state.transferLines, docNo);
export const getTransferLineMapForDoc = (state, docNo) => fromTransferLines.getTransferLineMapForDoc(state.transferLines, docNo);
export const getTotalTranferQtyForDoc = (state, docNo) => fromTransferLines.getTotalTranferQtyForDoc(state.transferLines, docNo);

export const getPaymentHeaders = state => fromPaymentHeaders.getAll(state.paymentHeaders);
export const getPaymentHeader = (state, no) => fromPaymentHeaders.get(state.paymentHeaders, no);
export const getPaymentHeadersByCustomer = (state, customerId) => fromPaymentHeaders.getByCustomer(state.paymentHeaders, customerId);
export const getCreditPaymentHeadersByCustomer = (state, customerId) => fromPaymentHeaders.getCreditPaymentHeadersByCustomer(state.paymentHeaders, customerId);
export const getCreditPaymentHeaders = state => fromPaymentHeaders.getCreditPaymentHeaders(state.paymentHeaders);
export const getPaymentHeadersBySearchKey = (state, key) => fromPaymentHeaders.getBySearchKey(state.paymentHeaders, key);
export const getPaymentHeaderTotal = (state, searchKey) => fromPaymentHeaders.getTotal(state.paymentHeaders, searchKey);
export const getPaymentHeaderMetadata = (state, searchKey) => fromPaymentHeaders.getMetadata(state.paymentHeaders, searchKey);
export const isFetchingPaymentHeaders = state => fromPaymentHeaders.isFetching(state.paymentHeaders);
export const getPaymentTypes = state => fromPaymentHeaders.getPaymentTypes(state.paymentHeaders);

export const getPaymentLinesForDoc = (state, docNo) => fromPaymentLines.getForDoc(state.paymentLines, docNo);
export const getPaymentLineMapForDoc = (state, docNo) => fromPaymentLines.getPaymentLineMapForDoc(state.paymentLines, docNo);

export const getDistributorPaymentHeaders = state => fromDistributorPaymentHeaders.getAll(state.distributorPaymentHeaders);
export const getDistributorPaymentHeader = (state, no) => fromDistributorPaymentHeaders.get(state.distributorPaymentHeaders, no);
export const getDistributorPaymentHeadersByCustomer = (state, customerId) => fromDistributorPaymentHeaders.getByCustomer(state.distributorPaymentHeaders, customerId);
export const getDistributorCreditPaymentHeadersByCustomer = (state, customerId) => fromDistributorPaymentHeaders.getCreditDistributorPaymentHeadersByCustomer(state.distributorPaymentHeaders, customerId);
export const getDistributorCreditPaymentHeaders = state => fromDistributorPaymentHeaders.getCreditDistributorPaymentHeaders(state.distributorPaymentHeaders);
export const getDistributorPaymentHeadersBySearchKey = (state, key) => fromDistributorPaymentHeaders.getBySearchKey(state.distributorPaymentHeaders, key);
export const getDistributorPaymentHeaderTotal = (state, searchKey) => fromDistributorPaymentHeaders.getTotal(state.distributorPaymentHeaders, searchKey);
export const getDistributorPaymentHeaderMetadata = (state, searchKey) => fromDistributorPaymentHeaders.getMetadata(state.distributorPaymentHeaders, searchKey);
export const isFetchingDistributorPaymentHeaders = state => fromDistributorPaymentHeaders.isFetching(state.distributorPaymentHeaders);

export const getDistributorPaymentLinesForDoc = (state, docNo) => fromDistributorPaymentLines.getForDoc(state.distributorPaymentLines, docNo);
export const getDistributorPaymentLineMapForDoc = (state, docNo) => fromDistributorPaymentLines.getDistributorPaymentLineMapForDoc(state.distributorPaymentLines, docNo);

export const getAttachemnt = (state, docNo) => fromAttachments.getAttachment(state.attachments, docNo);

export const getTripSchedules = state => fromTripSchedules.getAll(state.tripSchedules);
export const getTripSchedulesTotal = (state) => fromTripSchedules.getTotal(state.tripSchedules);
export const isFetchingTripSchedulars = state => fromTripSchedules.isFetchingTripSchedulars(state.tripSchedules);
export const getTripSchedulesBySearchKey = (state, key) => fromTripSchedules.getBySearchKey(state.tripSchedules, key);


export const getSalesInvoiceHeaders = (state, key) => fromSalesInvoiceHeaders.getBySearchKey(state.salesInvoiceHeaders, key);
export const getSalesInvoiceHeadersByCustomer = (state, id) => fromSalesInvoiceHeaders.getSalesInvoiceHeadersByCustomer(state.salesInvoiceHeaders, id);
export const getOutstandingInvoiceHeadersByCustomer = (state, id) => fromSalesInvoiceHeaders.getOutstandingInvoiceHeadersByCustomer(state.salesInvoiceHeaders, id);

export const getSalesInvoiceHeadersBySearchKey = (state, key) => fromSalesInvoiceHeaders.getBySearchKey(state.salesInvoiceHeaders, key);
export const getSalesInvoiceHeaderTotal = (state, searchKey) => fromSalesInvoiceHeaders.getTotal(state.salesInvoiceHeaders, searchKey);
export const getSalesInvoiceHeaderMetadata = (state, searchKey) => fromSalesInvoiceHeaders.getMetadata(state.salesInvoiceHeaders, searchKey);
export const isFetchingSalesInvoiceHeaders = state => fromSalesInvoiceHeaders.isFetching(state.salesInvoiceHeaders);
export const getSalesInvoiceHeadersByTypeAndNo = (state, type, no) => fromSalesInvoiceHeaders.getSalesHeadersByTypeAndNo(state.salesInvoiceHeaders, type, no);

export const getPaymentMethods = state => fromPaymentMethods.getAll(state.paymentMethods);
export const getCustomerPaymentMethodsByCustomerId = (state, customerId) => fromPaymentMethods.getCustomerPaymentMethodsByCustomerId(state.paymentMethods, customerId);
export const getCustomerPaymentMethodsDataByCustomerId = (state, customerId) => fromPaymentMethods.getCustomerPaymentMethodsDataByCustomerId(state.paymentMethods, customerId);

export const getPaymentTerms = state => fromPaymentTerms.getAll(state.paymentTerms);
export const getCustomerPaymentTermsByCustomerId = (state, customerId) => fromPaymentTerms.getCustomerPaymentTermsByCustomerId(state.paymentTerms, customerId);
export const getCustomerPaymentTermsDataByCustomerId = (state, customerId) => fromPaymentTerms.getCustomerPaymentTermsDataByCustomerId(state.paymentTerms, customerId);

export const getFilteredRoutes = state => fromFilteredRoutes.getFilteredRoutes(state.filteredRoutes);
export const isFetchingFilteredRoutes = state => fromFilteredRoutes.isFetchingFilteredRoutes(state.filteredRoutes);
export const getFilteredRouteTotal = state => fromFilteredRoutes.getFilteredRouteTotal(state.filteredRoutes);
export const getFilteredRoutesByUser = (state, UserCode) => fromFilteredRoutes.getRoutesByUser(state.filteredRoutes, UserCode);

export const getSalesReturnReasons = (state) => fromSalesReturnReasons.getSalesReturnReasons(state.salesReturnReasons);

export const getSalesReturnReceiptsHeaders = (state, key) => fromSalesReturnReceiptsHeaders.getBySearchKey(state.salesReturnReceiptsHeaders, key);
export const getSalesReturnReceiptsHeadersByCustomer = (state, id) => fromSalesReturnReceiptsHeaders.getSalesReturnReceiptsHeadersByTypeAndCustomer(state.salesReturnReceiptsHeaders, id);

export const getSalesReturnReceiptsHeadersBySearchKey = (state, key) => fromSalesReturnReceiptsHeaders.getBySearchKey(state.salesReturnReceiptsHeaders, key);
export const getSalesSalesReturnReceiptsHeaderTotal = (state, searchKey) => fromSalesReturnReceiptsHeaders.getTotal(state.salesReturnReceiptsHeaders, searchKey);
export const isFetchingSalesReturnReceiptsHeaders = state => fromSalesReturnReceiptsHeaders.isFetching(state.salesReturnReceiptsHeaders);
export const getSalesSalesReturnReceiptsHeadersByTypeAndNo = (state, type, no) => fromSalesReturnReceiptsHeaders.getSalesReturnReceiptsHeadersByTypeAndNo(state.salesReturnReceiptsHeaders, type, no);

export const getItemFilterOptions = (state, filterField) => fromItemFilters.getFilterOptions(state.itemFilters, filterField);
export const getItemFilterOptionsFromAllItems = (state, filterField) => fromItemAllItemsFilters.getFilterOptions(state.itemFiltersAllItems, filterField);


export const getAllocatedDataByDocNo = (state, DocNo) => fromAllocatedDetails.getAllocatedDataByDocNo(state.allocatedDetails, DocNo);

export const getPurchaseHeadersBySearchKey = (state, key) => fromPurchaseHeaders.getBySearchKey(state.purchaseHeaders, key);
export const getPurchaseHeaderTotal = (state, searchKey) => fromPurchaseHeaders.getTotal(state.purchaseHeaders, searchKey);
export const getPurchaseHeaderMetadata = (state, searchKey) => fromPurchaseHeaders.getMetadata(state.purchaseHeaders, searchKey);
export const isFetchingPurchaseHeaders = state => fromPurchaseHeaders.isFetching(state.purchaseHeaders);
export const getPurchaseCreditMemoReasons = state => fromPurchaseHeaders.getPurchaseCreditMemoReasons(state.purchaseHeaders);
export const getPurchaseHeadersByType = (state, type) => fromPurchaseHeaders.getPurchaseHeadersByType(state.purchaseHeaders, type);
export const getPurchaseHeadersByTypeAndNo = (state, type, no) => fromPurchaseHeaders.getPurchaseHeadersByTypeAndNo(state.purchaseHeaders, type, no);
export const getPurchaseReturnReasons = state => fromPurchaseHeaders.getPurchaseReturnReasons(state.purchaseHeaders);

//export const getPurchaseHeadersByTypeAndDistributor = (state, type, id) => fromPurchaseHeaders.getPurcahseHeadersByTypeAndDistributor(state.purchaseHeaders, type, id);

export const getPurchaseLinesByDocTypeAndDocNo = (state, type, no) => fromPurchaseLines.getPurchaseLinesByDocTypeAndDocNo(state.purchaseLines, type, no);
export const getPurchaseLineMapByDocTypeAndDocNo = (state, type, no) => fromPurchaseLines.getPurchaseLineMapByDocTypeAndDocNo(state.purchaseLines, type, no);
export const getPurchaseLine = (state, type, docNo, lineNo) => fromPurchaseLines.getPurchaseLine(state.purchaseLines, type, docNo, lineNo);


export const getAllItems = state => fromAllItems.getAllItems(state.allItems);
export const getSingleItemFromAllItems = (state, itemNo) => fromAllItems.getSingleItemFromAllItems(state.allItems, itemNo);

export const getTerritories = state => fromTerritories.getTerritories(state.territories);
export const isFetchingTerritories = state => fromTerritories.isFetchingTerritories(state.territories);
export const getTerritoriesTotal = state => fromTerritories.getTerritoriesTotal(state.territories);

export const getLotWiseItems = state => fromLotWiseItems.getLotWiseItems(state.lotWiseItems);
export const getLotWiseItem = (state, itemNo) => fromLotWiseItems.getItem(state.lotWiseItems, itemNo);
export const getLotWiseItemBrands = state => fromLotWiseItems.getBrands(state.lotWiseItems);
export const getLotWiseItemCategory1s = state => fromLotWiseItems.getCategory1s(state.lotWiseItems);
export const getLotWiseItemCategory2s = state => fromLotWiseItems.getCategory2s(state.lotWiseItems);
export const getLotWiseItemCategory3s = state => fromLotWiseItems.getCategory3s(state.lotWiseItems);
export const getLotWiseItemCategory4s = state => fromLotWiseItems.getCategory4s(state.lotWiseItems);
export const getLotWiseItemCombinedFilter = state => fromLotWiseItems.getCombinedFilter(state.lotWiseItems);
export const getLotWiseItemsPageSize = state => fromLotWiseItems.getPageSize(state.lotWiseItems);

export const getPurchasingInvoiceHeadersBySearchKey = (state, key) => fromPurchasingInvoiceHeaders.getBySearchKey(state.purchasingInvoiceHeaders, key);
export const getPurchasingInvoiceTotal = (state, searchKey) => fromPurchasingInvoiceHeaders.getTotal(state.purchasingInvoiceHeaders, searchKey);
export const getPurchasingInvoiceMetadata = (state, searchKey) => fromPurchasingInvoiceHeaders.getMetadata(state.purchasingInvoiceHeaders, searchKey);
export const isFetchingPurchasingInvoiceHeaders = state => fromPurchasingInvoiceHeaders.isFetching(state.purchasingInvoiceHeaders);

export const getPurchasingInvoiceLineMapByDocTypeAndDocNo = (state, type, no) => fromPurchasingInvoiceLines.getPurchaseInvoiceLineMapByDocTypeAndDocNo(state.purchasingInvoiceLines, type, no);
export const getPurchaseInvoiceLinesByDocTypeAndDocNo = (state, type, no) => fromPurchasingInvoiceLines.getInvoiceLinesByDocNo(state.purchasingInvoiceLines, type, no);

export const getPurchasingInvoiceHeadersByTypeAndNo = (state, type, no) => fromPurchasingInvoiceHeaders.getByTypeAndNo(state.purchasingInvoiceHeaders, type, no);
export const getPurchasingInvoiceHeaderByDocNo = (state, invoiceNo) => fromPurchasingInvoiceHeaders.getByDocNo(state.purchasingInvoiceHeaders, invoiceNo);
export const getpurchasingInvoiceLine = (state, type, docNo, lineNo) => fromPurchasingInvoiceLines.getInvoiceLine(state.purchasingInvoiceLines, type, docNo, lineNo);
export const getPurchaseAllocatedDataByDocNo = (state, DocNo) => fromPurchaseAllocatedDetails.getAllocatedDataByDocNo(state.purchaseAllocatedDetails, DocNo);

export const getStockTakesHeadersByNo = (state, no) => fromStockTakesHeaders.getStockTakesHeadersByNo(state.stockTakesHeaders, no);
export const getStockTakesHeadersBySearchKey = (state, key) => fromStockTakesHeaders.getBySearchKey(state.stockTakesHeaders, key);
export const getStockTakeHeaderTotal = (state, searchKey) => fromStockTakesHeaders.getTotal(state.stockTakesHeaders, searchKey);
export const getStockTakeHeaderMetadata = (state, searchKey) => fromStockTakesHeaders.getMetadata(state.stockTakesHeaders, searchKey);
export const isFetchingStockTakesHeaders = state => fromStockTakesHeaders.isFetching(state.stockTakesHeaders);
export const getStockTakeLinesByDocNo = (state, docNo) => fromStockTakeLines.getStockTakeLinesByDocNo(state.stockTakeLines, docNo);
export const getStockTakeLine = (state, docNo, lineNo) => fromStockTakeLines.getStockTakeLine(state.stockTakeLines, docNo, lineNo);
export const isFetchingStockTakeLines = state => fromStockTakeLines.isFetching(state.stockTakeLines);
export const getStockTakeLineMapByDocNo = (state, docNo) => fromStockTakeLines.getStockTakeLineMapByDocNo(state.stockTakeLines, docNo);

export const getExpensesBySearchKey = (state, key) => fromExpenses.getBySearchKey(state.expenses, key);
export const getExpensesTotal = (state, key) => fromExpenses.getTotal(state.expenses, key);
export const isFetchingExpenses = state => fromExpenses.isFetching(state.expenses);
export const getExpenseMetadata = (state, searchKey) => fromExpenses.getMetadata(state.expenses, searchKey);
export const getExpenseByDocNo = (state, docNo) => fromExpenses.getByDocNo(state.expenses, docNo);

export const isFetchingexpensesCategories = state => fromExpensesCategories.isFetching(state.expensesCategories);
export const getExpensesCategories = (state) => fromExpensesCategories.getExpensesCategories(state.expensesCategories);

export const getPurchaseCreditInvoicesByType = (state, type) => fromPurchaseCreditInvoices.getPurchaseCreditInvoicesByType(state.purchaseCreditInvoices, type);
export const getPurchaseCreditInvoicesByTypeAndNo = (state, type, no) => fromPurchaseCreditInvoices.getPurchaseCreditInvoicesByTypeAndNo(state.purchaseCreditInvoices, type, no);
export const getPurchaseCreditInvoiceByNo = (state, no) => fromPurchaseCreditInvoices.getPurchaseCreditInvoiceByNo(state.purchaseCreditInvoices, no);
export const getPurchaseCreditInvoicesBySearchKey = (state, key) => fromPurchaseCreditInvoices.getBySearchKey(state.purchaseCreditInvoices, key);
export const getPurchaseCreditInvoiceTotal = (state, searchKey) => fromPurchaseCreditInvoices.getTotal(state.purchaseCreditInvoices, searchKey);
export const getPurchaseCreditInvoiceMetadata = (state, searchKey) => fromPurchaseCreditInvoices.getMetadata(state.purchaseCreditInvoices, searchKey);
export const isFetchingPurchaseCreditInvoices = state => fromPurchaseCreditInvoices.isFetching(state.purchaseCreditInvoices);


export const getPurchaseCreditInvoiceLinesByDocNo = (state, no) => fromPurchaseCreditInvoiceLines.getLinesByDocNo(state.purchaseCreditInvoiceLines, no);

export const getNetworkModeDetector = state => state.isSystemOffline;
export const getSalesDiscountReason = state => fromSalesDiscountReasons.getSalesDiscountReason(state.salesDiscountReasons);

// TARGET SCHEDULER
export const getAllYears = state => state.targetScheduler.years;
export const getAllMonths = state => state.targetScheduler.months;
export const getFilteredRouteTargets = state => state.targetScheduler.routeTargets;
export const getMonthlyTarget = state => state.targetScheduler.monthlyTarget;
export const getCustomerFrequencies = state => state.targetScheduler.customerFrequencies;
export const getAllCustomerVisitTargets = state => state.targetScheduler.customerVisitTargets;
export const getAllTargetSchedulerDetails = state => state.targetScheduler.targetScheduler;
export const getFilter = state => state.targetScheduler.filter;
export const getAllBrands = state => state.targetScheduler.brands;
export const getAllItemSubCateoryOne = state => state.targetScheduler.itemSubCateoryOne;

// FAILED ORDERS
export const getAllFailedHeaders = state => state.failedOrders.headers;
export const getAllFailedLines = state => state.failedOrders.lines;



