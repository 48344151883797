import React, { Component } from 'react';
import { Table } from "antd";
import { connect } from "react-redux";
import { getTransferHeader, getTransferLinesForDoc, getItems, getLoggedInUser } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { STOCK_TRANSFERS } from "../../../const/Permissions";

class ItemTableMobile extends Component {
  getColumns = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderDescription(item)
      },
      {
        title: 'From Qty',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '20%',
        render: (Description, item) => this.renderFromQuantity(item)
      },
      {
        title: 'Trans. Qty',
        dataIndex: 'Quantity',
        key: 'Quantity',
        align: 'right',
        width: '20%'
      }
    ];

  renderDescription = line => {
    const item = this.getItem(line);

    if (!item) {
      return (
        <div>
          <div>{line.LotNo}</div>
          <div>{line.ItemNo}</div>
        </div>
      )
    } else {
      return (
        <div>
          <div>{line.LotNo}</div>
          <div>{line.ItemNo}</div>
          <div>{item.Description}</div>
        </div>
      )
    }
  }

  renderRightColumn = item => {
    return (
      <div>
        <div style={{ fontWeight: 'bold' }}>{this.renderFromQuantity(item)}</div>
        <div style={{ fontWeight: 'bold' }}>{item.Quantity}</div>
      </div>
    )
  }

  renderFooter = () => {
    const { lines = [], transfer = {}, loggedInUser: { permissions = [] } } = this.props;
    const enableUpdate = permissions.includes(STOCK_TRANSFERS.UPDATE);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 50 }}>
        <p>No of Items: {lines.length}</p>
        {enableUpdate && <CallStatusValidateButton onClick={this.props.OnClickAddItem} type="primary">Add Item</CallStatusValidateButton>}
      </div>
    )
  }

  renderFromQuantity = line => {
    const item = this.getItem(line);

    if (!item) return '';
    else return item.Inventory;
  }

  getItem = line => {
    const { transfer, items } = this.props;

    if (!transfer) return null;
    else return items.filter(item => item.LotNo === line.LotNo && item.ItemNo === line.ItemNo && transfer.FromLocCode === item.LocCode)[0];
  }

  render() {
    const { height = 100, transfer, history, lines = [], match, loggedInUser: { permissions = [] } } = this.props;
    const { transferId } = match.params;
    const decodeId = decodeURIComponent(transferId);
    const enableUpdate = permissions.includes(STOCK_TRANSFERS.UPDATE);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          bodyStyle={{ minHeight: height }}
          dataSource={lines}
          size={'small'}
          onRowClick={() => enableUpdate && history.push({ pathname: '/stock', state: { resource: "stockTransfer", transfer, id: decodeId } })}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { transferId } = ownProps.match.params;
  const decodeId = decodeURIComponent(transferId);

  return {
    transfer: getTransferHeader(state, decodeId),
    lines: getTransferLinesForDoc(state, decodeId),
    items: getItems(state),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTableMobile));