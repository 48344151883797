import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getCustomerCategoryByCustomerId, getCustomerSubcategoryByCustomerId, getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import { CREDIT_NOTES } from "../../const/Permissions";

class CreditNoteCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Completed':
      case 'Active':
        return 'green';
      case 'Block':
        return 'red';
      case 'Open':
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history, loggedInUser: { permissions = [] } } = this.props;
    const { No, DocDate, ReturnReasonDescrip, SellToCusNo, SellToCusName, CustStatus, OutletCategoryCode, RouteName, CSRUserName, Amount } = order;
    const status = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;
    return (
      <Row onClick={() => permissions.includes(CREDIT_NOTES.VIEW) && history.push("/sales/CreditMemo/" + encodeURIComponent(No))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{No}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>{moment(DocDate).format(dateFormatView)}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>{ReturnReasonDescrip}</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{SellToCusNo}</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{SellToCusName}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>{OutletCategoryCode}</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{RouteName}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>{CSRUserName}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right' }}>{Amount}</Col>

          <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'left', ...styles.largeBold }}>{No}</Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'center', ...styles.bigBold }}>{moment(DocDate).format(dateFormatView)}</Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'right' }}></Col>
          {/* <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>
						<span style={styles.orderNoDesktop}>{No}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.orderNoMobile}>{No}</span>
					</Col>

					<Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>
						<Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
					</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
						<span style={styles.orderDate}>{moment(OrderDate).format(dateFormat)}</span>
					</Col>
					<Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}>{BillToCusNo}</p>
					</Col>
					<Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
						<span>{BillToCusName}</span>
					</Col>
          			<Col xs={8} sm={5} md={4} lg={4} style={{ textAlign: 'left' }}>
						<span>{customerCategory && customerCategory.Description} {customerSubcategory && ('(' + customerSubcategory.Description + ')')}</span>
					</Col>
          			<Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
						<span>{CreatedUserName}</span>
					</Col>
					<Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
						<CurrencyFormat
							value={AmountIncluVAT || 0}
							displayType={'text'}
							thousandSeparator={true}
							isNumericString={true}
							fixedDecimalScale={true}
							style={styles.price}
							decimalScale={2} />
					</Col> */}
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'left' }}>{SellToCusName}</Col>

          {/* <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.orderDate}>{moment(OrderDate).format(dateFormat)}</span>
					</Col> */}
          {/* <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{billToCustomerCode}</p>
					</Col> */}
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>
          <Col xs={9} sm={9} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>{ReturnReasonDescrip}</Col>
          <Col xs={9} sm={9} md={0} lg={0} style={{ textAlign: 'right', ...styles.bigBold }}>{Amount}</Col>

          {/* <Col xs={0} sm={5} md={0} lg={0} style={styles.bottomColumnStyles}>
					 <span>{BillToCusNo}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={styles.bottomColumnStyles}>
						<span>{BillToCusNo}</span>
					</Col>
					<Col xs={0} sm={5} md={0} lg={0} style={{ textAlign : 'center'}}>
						<span>{BillToCusName}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign : 'left'}}>
						<span>{BillToCusName}</span>
					</Col>
					<Col xs={0} sm={5} md={0} lg={0} style={{ textAlign : 'center'}}>
						<span>{CreatedUserName}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign : 'right' }}>
					 	<span>{CreatedUserName}</span>
					</Col> */}
        </Row>
      </Row>
    )
  }
}

const styles = {
  largeBold: {
    fontSize: 12,
    fontWeight: 700
  },
  bigBold: {
    fontSize: 12,
    fontWeight: 700
  },
  normalBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  orderNoDesktop: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderNoMobile: {
    fontSize: 12,
    fontWeight: 700
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    marginRight: 0
  },
  price: {
    fontSize: 15,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const { order } = ownProps;

  const customerCategory = getCustomerCategoryByCustomerId(state, order.BillToCusNo);
  const customerSubcategory = getCustomerSubcategoryByCustomerId(state, order.BillToCusNo);
  const loggedInUser = getLoggedInUser(state);

  return { customerCategory, customerSubcategory, loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(CreditNoteCard));