import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { dateFormatView } from "../../const/Formats";
import { getLoggedInUser } from "../../redux/reducers";
import { SALES_INVOICES } from "../../const/Permissions";
import { toCommaSeparatedString } from "../../utils/Formats";

class InvoiceCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  getCustomerStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history, loggedInUser: { permissions = [] } } = this.props;
    const { No, OrderDate, SellToCusNo, SellToCusName, CustStatus, Amount, PayMethodCode, ageOfInvoice = 0, AmountIncluVAT,
      CreatedUserName, OutletCategoryCode, OrderStatus, OrgAmount, DisAmount, AvgDisPersentage, SettledAmount, RemAmount, PDCAmount,
      NetRemAmount = '', CSRUserName } = order;

    const customerStatus = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;

    return (
      <Row onClick={this.props.clickable !== false ? () => (permissions.includes(SALES_INVOICES.VIEW) || permissions.includes(SALES_INVOICES.UPDATE)) && history.push("/salesInvoices/" + encodeURIComponent(No)) : () => { }}>

        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>{No}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
          </Col>

          <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'center' }}>
            <span >{PayMethodCode}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getCustomerStatusColor(customerStatus)}>{customerStatus}</Tag>
          </Col>

          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>
            <span >{SellToCusName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span >{OutletCategoryCode}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'center' }}>
            <span >{OrderStatus}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>
            <span >{CSRUserName}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'center' }}>
            <span>{ageOfInvoice}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span >{OrgAmount}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'right' }}>
            <span >{DisAmount}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'center' }}>
            <span style={{ wordBreak: 'break-all' }}>{AvgDisPersentage}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{Amount}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{SettledAmount}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={4} style={{ textAlign: 'right' }}>
            <span>{RemAmount}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{PDCAmount}</span>
          </Col>
          <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'right' }}>
            <span>{parseFloat(NetRemAmount.replaceAll(',', '')).toFixed(2)}</span>
          </Col>


        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={{
              fontSize: 14,
              fontWeight: 600,
            }}>{No}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={3} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}><span style={{ ...styles.orderDateTitle }}>OS</span></Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontSize: 13 }}>{RemAmount}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{SellToCusName}</span>
          </Col>
          <Col xs={11} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDateTitle}>PDC</span>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontWeight: 'bolder', fontSize: 13 }}>{PDCAmount}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={4} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getCustomerStatusColor(customerStatus)}>{customerStatus}</Tag>
          </Col>
          <Col xs={4} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status}>{PayMethodCode}</Tag>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDate}>{ageOfInvoice} day{ageOfInvoice !== 1 && 's'}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={styles.orderDateTitle}>NET OS</span>
          </Col>
          <Col xs={5} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontWeight: 700, fontSize: 13, color: 'red' }}>{toCommaSeparatedString(parseFloat(NetRemAmount.replaceAll(',', '')).toFixed(2))}</span>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 12,
    //fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  orderDateTitle: {
    fontSize: 12
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(InvoiceCard));