import React, { Component } from "react";
import { Col, Row, Table, Button, DatePicker, Form } from "antd";
import OrderCard from "./OrderCard";
import CurrencyFormat from "react-currency-format";
import Input from '../../components/common/EnglishInput';
import { dateFormatView } from "../../const/Formats";

class OrderTable extends Component {

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const headerHeight = header && header[0] && header[0].clientHeight;
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;
    return window.innerHeight - (headerHeight + tableFooterHeight);
  }

  getTableHeight = orders => {
    if (orders.length > 0) {
      return this.getScrollAreaHeight() - 5;
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  getOrderTotals = orders => {
    let total = 0;
    orders.forEach(order => {
      total = total + order.totalAmount;
    })
    return total;
  }

  getFilteredOrders = () => {
    const { orders, filter } = this.props;
    let filteredOrders = orders;

    if (filter && filter.text) {
      const finalFilterText = filter.text.replace(/\W/g, '');

      filteredOrders = orders.filter(order => order.orderNo.toLowerCase().replace(/\W/g, '').includes(finalFilterText)
        || order.sellToCustomerCode.toLowerCase().replace(/\W/g, '').includes((finalFilterText).toLowerCase())
        || order.sellToCustomerName.toLowerCase().replace(/\W/g, '').includes((finalFilterText).toLowerCase())
        || (order.status && order.status.toLowerCase().includes((filter.text).toLowerCase()))
      )
    }

    return filteredOrders;
  }

  renderFooter = orders => {
    const { handleInputDateChange, handleSearchClick, handleInputTextChange, filter } = this.props;
    return (
      <div style={{ marginLeft: -15, marginRight: -15 }}>
        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
          <Col span={12}>{orders.length > 0 ? 'Total ' + orders.length : 'No'} order{orders.length !== 1 && 's'}</Col>
          <Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
            <CurrencyFormat
              value={this.getOrderTotals(orders)}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              decimalScale={2} />
          </Col>
        </Row>
        <Form layout={'vertical'}>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Col lg={10} md={10} sm={24} xs={24} style={{ padding: 5 }}>
                <Input placeholder="Search Text"
                  onChange={value => handleInputTextChange(value)} />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ padding: 5 }}>
                <DatePicker
                  format={dateFormatView}
                  value={filter.from}
                  placeholder="From"
                  onChange={(date, dateString) => handleInputDateChange("from", date)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ padding: 5 }}>
                <DatePicker
                  format={dateFormatView}
                  value={filter.to}
                  placeholder="To"
                  onChange={(date, dateString) => handleInputDateChange("to", date)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col lg={4} md={4} sm={24} xs={24} style={{ padding: 5 }}>
                <Button type="primary" block onClick={handleSearchClick}>Search</Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }

  renderColumns = () => {
    return [{
      render: order => <OrderCard order={order} />
    }]
  }

  render() {
    const filteredOrders = this.getFilteredOrders();
    return (
      <Col>
        <Table rowKey={'orderNo'} rowClassName={'orderRow'} showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders) }} columns={this.renderColumns()}
          dataSource={filteredOrders} size={'small'} pagination={false} scroll={{ y: this.getScrollAreaHeight() }}
          footer={() => this.renderFooter(filteredOrders)} />
      </Col>
    )
  }
}

export default OrderTable;