import * as fromApi from '../../api/generic';
import moment from "moment";
import { readObjectFromIndexDB } from '../../indexDB';
import { OBJECT_KEYS, TABLES } from '../../const/IndexDBObjects';
import { getOfflineData } from '../../localStorage';

const dummyData = [
  {
    No: 'TRISCHE001',
    TripType: 'Conventional',
    RouteCode: '12048',
    CSRCode: 'CSR0001',
    DistributorStaffCode: 'DIS0002',
    LocCode: 'WH-1703-02',
    StartTime: moment().startOf('day'),
    EndTime: moment().endOf('day'),
    RecallTime: undefined,
    Status: 'Approved'
  },
  {
    No: 'TRISCHE002',
    TripType: 'Delivery',
    RouteCode: '12048',
    CSRCode: 'CSR0003',
    DistributorStaffCode: 'DIS0001',
    LocCode: 'WH-1703-02',
    StartTime: moment().startOf('day'),
    EndTime: moment().endOf('day'),
    RecallTime: moment().endOf('day'),
    Status: 'Approved'
  }
]

export const fetchTripSchedules = (filter, page = 1, searchKey = '') => async dispatch => {
  cleanFilter(filter);
  dispatch({ type: 'START_FETCH_TRIP_SCHEDULES' });
  if (getOfflineData('state').isSystemOffline) {
    var response = await readObjectFromIndexDB(TABLES.TRIP_SCHEDULES, OBJECT_KEYS.TRIP_SCHEDULER).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })

    if (typeof response != 'undefined') {

      var { data, error, recordTotal } = await readObjectFromIndexDB(TABLES.TRIP_SCHEDULES, OBJECT_KEYS.TRIP_SCHEDULER);

      recordTotal = data.length;
      dispatch({ type: 'FINISH_FETCH_TRIP_SCHEDULES' });
      dispatch({ type: 'FETCH_TRIP_SCHEDULES', schedules: data, total: recordTotal ? recordTotal : 0, searchKey });
      return response;
    }
  }

  else {
    return fromApi.get('TripSchedular', filter, page).then(response => {
      dispatch({ type: 'FINISH_FETCH_TRIP_SCHEDULES' });
      const { data, error, recordTotal } = response;
      if (error) return { error }

      if (data && data.length === 0) dispatch({ type: 'FETCH_TRIP_SCHEDULE', schedule: data });

      dispatch({ type: 'FETCH_TRIP_SCHEDULES', schedules: data, total: recordTotal ? recordTotal : 0, searchKey });

      return response;
    }).catch(error => {
      console.log(error);
      return { error }
    })
  }
};


const cleanFilter = filter => {
  if (filter.UserTag)
    filter.UserTag = filter.UserTag.filter(tag => tag.startsWith('CS'));
  filter.UserCode = undefined;
}

export const addTripSchedule = schedule => dispatch => {
  return fromApi.add('TripSchedular', schedule).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_TRIP_SCHEDULE', schedule: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateTripSchedule = schedule => dispatch => {
  return fromApi.update('TripSchedular', schedule).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_TRIP_SCHEDULE', schedule: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteTripSchedule = schedule => dispatch => {
  return fromApi.deleteObject('TripSchedular', schedule).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: 'DELETE_TRIP_SCHEDULE', schedule });
    return response;
  }).catch(error => {
    return { error }
  })
};