import React, { Component } from 'react';
import { Table } from "antd";
import { connect } from "react-redux";
import { getSalesLinesByDocTypeAndDocNo, getLoggedInUser } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { SALES_INVOICES, SALES_ORDERS, SALES_RETURNS, CREDIT_NOTES } from "../../../const/Permissions";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTableMobile extends Component {
  getColumnsDefault = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderLeftColumn(item)
      },
      {
        title: '',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '40%',
        render: (Description, item) => this.renderRightColumn(item)
      }
    ];

  getColumnsCreditNotes = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        render: (Description, item) => (item.LotNo ? item.LotNo + ' - ' : '') + item.No + ' - ' + Description
      },
      {
        title: 'Amount',
        dataIndex: 'LineAmount',
        key: 'LineAmount',
        align: 'right',
        width: 100,
        render: LineAmount => format(LineAmount, currencyFormat)
      }
    ];

  getColumns = () => {
    if (this.props.match.params.docType === 'CreditMemo') {
      return this.getColumnsCreditNotes();
    } else {
      return this.getColumnsDefault();
    }
  }

  renderLeftColumn = item => {
    return (
      <div>
        <div>{item.LotNo}</div>
        <div>{item.No}</div>
        <div>{item.Description}</div>
      </div>
    )
  }

  renderRightColumn = item => {
    return (
      <div>
        <div>{item.Quantity}</div>
        <div>{format(item.UnitPrice, currencyFormat)}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 7 }}>{format(item.DisPercentage, currencyFormat)}%</div>
          <div style={{ fontWeight: 'bold' }}>{format(item.DisAmount, currencyFormat)}</div>
        </div>

        <div style={{ fontWeight: 'bold' }}>{format(item.LineAmount, currencyFormat)}</div>
      </div>
    )
  }

  getPermission = (docType, type, permissions) => {
    let enabled = false;
    if (type === 'CREATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.CREATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CREATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CREATE)
      }
    }
    else if (type === 'UPDATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.UPDATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.UPDATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.UPDATE)
      }
    }
    else if (type === 'DELETE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.DELETE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.DELETE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.DELETE)
      }
    } else if (type === 'CONFIRM') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.CONFIRM)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CONFIRM)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CONFIRM)
      }
    }
    else if (type === 'PRINT') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.PRINT)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.PRINT)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.PRINT)
      }
    }

    else if (type === 'GENERATE_INVOICE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.GENERATE_INVOICE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.GENERATE_RETURN_INVOICE)
      }
    }

    return enabled;
  }


  renderFooter = () => {
    const { salesLines = [], order = {}, loggedInUser: { permissions = [] }, match } = this.props;
    const { docType } = match.params;
    const enableUpdate = this.getPermission(docType, 'UPDATE', permissions);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {salesLines.length}</p>
        <div>
          {(enableUpdate) && <CallStatusValidateButton onClick={this.props.OnClickSelectInvoice} type="primary" style={{ marginRight: '10px' }}>Select invoice</CallStatusValidateButton>}
          {(enableUpdate) && <CallStatusValidateButton onClick={this.props.OnClickAddItem} type="primary">Add Item</CallStatusValidateButton>}
        </div>
      </div>
    )
  }

  render() {
    const { height = 100, order, history, salesLines = [], match, loggedInUser: { permissions = [] } } = this.props;
    const { orderId, docType } = match.params;
    const decodeId = decodeURIComponent(orderId);
    const enableUpdate = this.getPermission(docType, 'UPDATE', permissions);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={salesLines}
          size={'small'}
          onRowClick={item => {
            if (docType === 'CreditMemo') {
              enableUpdate && history.push({ pathname: '/sales/' + docType + '/' + orderId + '/salesLines/' + item.LineNo + '/items/' + item.No });
            } else {
              enableUpdate && history.push({ pathname: '/sales/' + docType + '/' + orderId + '/salesLines/' + item.LineNo });
            }
          }}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId, docType } = ownProps.match.params;
  const decodeId = decodeURIComponent(orderId);

  return {
    salesLines: getSalesLinesByDocTypeAndDocNo(state, docType, decodeId),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTableMobile));