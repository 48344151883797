import { Collapse, Spin } from "antd";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ProMateGreen } from "../../../const/Theme";
import { fetchFailedSalesLinesFromLocalAPI } from '../../../redux/actions/failedOrdedrs';
import { getAllFailedLines } from '../../../redux/reducers';
import PromatePageHeader from '../../common/PromatePageHeader';

const { Panel } = Collapse;

class FailedSalesOrderLines extends Component {
    componentDidMount() {
        const { match: { params }, fetchFailedSalesLinesFromLocalAPI } = this.props;
        const headerNo = decodeURIComponent(params.id);
        fetchFailedSalesLinesFromLocalAPI(headerNo);
    }

    render() {
        const { lines: { list, isLoading } } = this.props;
        return (
            <>
                <PromatePageHeader
                    title="Failed Order Lines"
                    style={styles.pageHeader}
                    onBack={() => this.props.history.goBack()}
                    showCallButton={false}
                />
                <Spin spinning={isLoading} style={{ width: '100vw', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ padding: 10 }}>
                        {
                            Array.isArray(list) && list.length === 0 && <p>Not have any failed lines.</p>
                        }
                        {
                            Array.isArray(list) && list.length != 0 && (
                                <Collapse>
                                    {
                                        list.map((line, index) => {
                                            return (
                                                <Panel header={line.no} key={index}>
                                                    <ul>
                                                        <li>No: <span style={{fontWeight: 'bold'}}>{line.no}</span></li>
                                                        <li>LotNo: <span style={{fontWeight: 'bold'}}>{line.lotNo}</span></li>
                                                        <li>Location: <span style={{fontWeight: 'bold'}}>{line.locCode}</span></li>
                                                        <li>DocNo: <span style={{fontWeight: 'bold'}}>{line.docNo}</span></li>
                                                        <li>Description: <span style={{fontWeight: 'bold'}}>{line.description}</span></li>
                                                        <li>Amount: <span style={{fontWeight: 'bold'}}>{line.amount}</span></li>
                                                        <li>Discount: <span style={{fontWeight: 'bold'}}>{line.disAmount}</span></li>
                                                        <li>Discount Percentage: <span style={{fontWeight: 'bold'}}>{line.disPercentage}</span></li>
                                                        <li>User Code: <span style={{fontWeight: 'bold'}}>{line.userCode}</span></li>
                                                        <li>Quantity: <span style={{fontWeight: 'bold'}}>{line.quantity}</span></li>
                                                        <li>Error Log: <span style={{fontWeight: 'bold'}}>{line.message ?? ' -- '}</span></li>
                                                    </ul>
                                                </Panel>
                                            );
                                        })
                                    }
                                </Collapse>
                            )
                        }
                    </div>
                </Spin>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    lines: getAllFailedLines(state),
})

const mapDispatchToProps = {
    fetchFailedSalesLinesFromLocalAPI
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FailedSalesOrderLines));

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    },
    text: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}


