import React, { Component } from "react";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';

class CreditNoteCardHeader extends Component {
	getStatusColor = status => {
		switch (status) {
			case 'Open':
				return 'green';
			default:
				return 'orange';
		}
	}

	render() {

		return (
			<Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, marginRight: 15, backgroundColor: '#eceaea', padding: 10 }}>
				<Col xs={8} sm={6} md={3} lg={3} style={{ textAlign: 'left' }}>
					<span>No</span>
				</Col>
				<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Status</Col>
				<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
					<span>Date</span>
				</Col>
				<Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
					<span>Date</span>
				</Col>
				<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
					<p style={{ margin: 5 }}>Customer Code</p>
				</Col>
				<Col xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'left' }}>
					<span>Customer Name</span>
				</Col>
				<Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>
				<Row style={styles.lowerRow}>
					{/* <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Status</Col>
					<Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<p style={{ margin: 5 }}>Customer Code</p>
					</Col> */}
				</Row>
				<Row style={styles.lowerRow}>
					{/* <Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
					</Col> */}
				</Row>
			</Row>
		)
	}
}

const styles = {
	creditNoteNo: {
		fontSize: 16,
		fontWeight: 600
	},
	alignRow: {
		display: 'flex',
		alignItems: 'center'
	},
	creditNoteDate: {
		fontSize: 12,
		fontWeight: 700
	},
	customerCode: {
		fontSize: 10,
		fontWeight: 700,
	},
	customerCodeBottom: {
		fontSize: 10,
		fontWeight: 700,
	},
	status: {
		fontSize: 10,
		fontWeight: 700,
		marginRight: 0
	},
	price: {
		fontSize: 18,
		fontWeight: 600,
	},
	icon: {
		fontSize: 10,
		marginRight: 5
	},
	lowerRow: {
		display: 'flex',
		alignItems: 'center',
		bcreditNoteRadius: 5,
		marginTop: 2
	},
	bottomColumnStyles: {
		textAlign: 'left',
		color: '#808080',
		fontSize: 12
	}

}
export default withRouter(CreditNoteCardHeader);