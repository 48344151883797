import * as dotenv from 'dotenv';
import moment from 'moment';

export const isValidTimeForWork = async () => {
    dotenv.config();

    const response = await fetch("https://worldtimeapi.org/api/ip");
    const json = await response.json();
    const currentTime = moment(json.datetime);
    return { isValid: moment(currentTime).isBetween(moment(process.env.REACT_APP_WORK_START_TIME, 'HH:mm'), moment(process.env.REACT_APP_WORK_END_TIME, 'HH:mm')), start: process.env.REACT_APP_WORK_START_TIME, end: process.env.REACT_APP_WORK_END_TIME }
} 

export const getCurrentDate = async (dateFormat) => {
    const response = await fetch("https://worldtimeapi.org/api/ip");
    const json = await response.json();
    const currentDate = moment(json.datetime).format(dateFormat);
    return currentDate;
}

export const saveSwitchNetworkModeTime = async (key) => {
    const response = await fetch("https://worldtimeapi.org/api/ip");
    const json = await response.json();
    const currentDate = moment(json.datetime).format('DD/MM/YYYY HH:mm:ss A');
    localStorage.setItem(key, currentDate);
    console.log(currentDate);
}