import React, { Component } from 'react';
import { Row, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import SelectWarehouseLocation from "../../common/SelectWarehouseLocation";
import StockTransferCard from '../StockTransferCard';
import { stockTransferStatuses } from '../../../redux/reducers/transferHeaders';
import { dateFormatView } from '../../../const/Formats';

class TransferHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  renderActions = () => {
    const { transfer, newTransfer, onSubmitTransfer, onClickConfirmTransfer, onShareTransfer, onDeleteTransfer } = this.props;
    const { lines, Status } = transfer;
    const mappedStatus = stockTransferStatuses[Status];

    if (newTransfer) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitTransfer}
        >
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (transfer.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!transfer.links || transfer.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteTransfer}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       transfer.lines && transfer.lines.length > 0 &&
      //       (!transfer.links || transfer.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmTransfer}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (transfer.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       transfer.links && transfer.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareTransfer()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          mappedStatus === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteTransfer}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          lines && lines.length > 0 &&
          mappedStatus === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmTransfer}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            mappedStatus === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareTransfer()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  render() {
    const { transfer, onInputChange, error } = this.props;
    const { DocNo, PostingDate, Status, CreatedUserName, FromLocCode, ToLocCode } = transfer;
    const mappedStatus = stockTransferStatuses[Status];

    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {DocNo && <tr>
              <td style={{ width: 110 }}>Stock Transfer #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{DocNo}</td>
            </tr>}
            {PostingDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(PostingDate).format(dateFormatView)}</td>
            </tr>}
            {mappedStatus && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>
                {<Tag color={this.getStatusColor(mappedStatus)}>{mappedStatus}</Tag>}
              </td>
            </tr>}
            {CreatedUserName && <tr style={{ justifyContent: 'center' }}>
              <td>Transferred By #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{CreatedUserName}</td>
            </tr>}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <tr>
              <td style={{ width: 50 }}>From</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td style={{ width: 300 }}>
                <SelectWarehouseLocation
                  disabled={DocNo}
                  placeholder={'Select Location'}
                  defaultValue={(!FromLocCode || (FromLocCode && FromLocCode === '')) ? undefined : FromLocCode}
                  onChange={(_, value, errorKey) => onInputChange('FromLocCode', value, errorKey)}
                  style={{ width: 300, borderColor: 'red' }} />
                {error.fromLocCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.fromLocCodeError}</p>}
              </td>
            </tr>
            <tr style={{ justifyContent: 'center' }}>
              <td>To</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td style={{ width: 300 }}>
                <SelectWarehouseLocation
                  disabled={DocNo}
                  placeholder={'Select Location'}
                  defaultValue={(!ToLocCode || (ToLocCode && ToLocCode === '')) ? undefined : ToLocCode}
                  onChange={(_, value, errorKey) => onInputChange('ToLocCode', value, errorKey)}
                  style={{ width: 300, borderColor: 'red' }} />
                {error.toLocCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.toLocCodeError}</p>}
              </td>
            </tr>
          </Col>
        </Row>
      </div>
    )
  }
}

export default TransferHeader;