import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_SALES_RETURN_REASONS = 'FETCH_SALES_RETURN_REASONS';

export const fetchSalesReturnReasons = () => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    const salesReturnReasons = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.SALES_RETURN_REASONS);
    if (salesReturnReasons.error) return salesReturnReasons.error;

    dispatch({ type: FETCH_SALES_RETURN_REASONS, salesReturnReasons: salesReturnReasons.data });
    return salesReturnReasons;

  } else {
    return fromApi.get('SalesRetrunReasons').then(response => {
      const { data, error } = response;

      if (error) return { error };

      dispatch({ type: FETCH_SALES_RETURN_REASONS, salesReturnReasons: data });
      return response;
    }).catch(error => {
      return { error }
    });
  }

}
