import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchItems } from '../../../redux/actions/items';
import { fetchSalesLines, updateSalesLine, deleteSalesLine } from '../../../redux/actions/salesLines';
import { getAuthData, getItem, getSalesLine, DOC_TYPE_ID_BY_DEFINITION } from "../../../redux/reducers";
import Details from "./Details";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';

class Container extends Component {
  state = {
    salesLine: undefined
  };

  componentDidMount() {
    this.loadSalesLines();
    this.loadInventoryItem(this.props);
  }

  loadSalesLines = () => {
    const { docType, orderId } = this.props.match.params;
    this.props.fetchSalesLines({ DocNo: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  componentWillReceiveProps = nextProps => {
    this.loadInventoryItem(nextProps);
  }

  inventoryItemRequestSent = false;
  loadInventoryItem = props => {
    const { salesLine, fetchItems } = props;
    if (this.inventoryItemRequestSent || !salesLine) return;

    this.setState({ salesLine });
    this.inventoryItemRequestSent = true;
    const { CreatedUserID, No, LocCode, LotNo } = salesLine;
    fetchItems({ UserCode: CreatedUserID, ItemNo: No, LocCode, LotNo });
  }

  handleUpdateOrder = () => this.handleRequest(this.props.updateSalesLine, 'Line updated successfully.');

  handleRemoveSalesLine = () => this.handleRequest(this.props.deleteSalesLine, 'Line removed successfully.');

  handleRequest = (requestCall, successMessage) => {
    const { authData, deleteSalesLine, history, toastManager } = this.props;
    const { docType } = this.props.match.params;
    this.setState({ loading: true });
    let salesLineObject = undefined

    if (docType === 'ReturnOrder') {
      salesLineObject = { ...this.state.salesLine, UserCode: authData.userId, ReturnInvDocNo: this.state.salesLine.ReturnInvDocNo, ReturnInvLineNo: this.state.salesLine.ReturnInvLineNo }
    } else {
      salesLineObject = { ...this.state.salesLine, UserCode: authData.userId }
    }

    requestCall(salesLineObject).then(({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = () => this.setState({ salesLine: { ...this.props.salesLine } });

  handleUpdateNumberField = (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    if (key === 'DisPercentage') {
      if (value < 0) value *= -1;
      if (value > 100) value = 100;
    }

    this.setState({ salesLine: { ...this.state.salesLine, [key]: '' + value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  render() {
    const { match, resource, order, inventoryItem, salesLine: salesLineDatabase } = this.props;
    const { salesLine, loading } = this.state;
    const { docType } = this.props.match.params;

    return (
      <Spin spinning={loading || !salesLine || !inventoryItem} tip={"Loading item"}>
        {(inventoryItem && salesLine) ?
          <Details
            inventoryItem={inventoryItem}
            onUpdateNumberField={this.handleUpdateNumberField}
            salesLineItem={salesLine}
            salesLineItemDatabase={salesLineDatabase}
            onUpdateOrder={this.handleUpdateOrder}
            onRemoveSalesLine={this.handleRemoveSalesLine}
            onResetSalesLine={this.handleResetSalesLine}
            resource={resource}
            order={order}
            newOrderItem={match && match.params.lineId === 'new'}
            docType={docType}
          /> :
          <div style={{ width: '100%', height: 200 }} />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { docType, orderId, lineId } = ownProps.match.params;

  const salesLine = getSalesLine(state, docType, decodeURIComponent(orderId), lineId);

  let inventoryItem;
  if (salesLine) {
    const { No, LotNo, LocCode } = salesLine;
    const itemId = No + '-' + LotNo + '-' + LocCode;
    inventoryItem = getItem(state, itemId);
  }

  return { authData: getAuthData(state), inventoryItem, salesLine };
};


export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchItems,
        fetchSalesLines,
        updateSalesLine,
        deleteSalesLine
      }
    )(Container)
  )
);