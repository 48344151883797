
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { fetchStockTakesHeaders } from "../../redux/actions/stockTakeHeaders";
import { getAuthData, getLoggedInUser, getTopPosition, getFlatPositions, getStockTakesHeadersBySearchKey, getStockTakeHeaderTotal, getStockTakeHeaderMetadata, isFetchingStockTakesHeaders, } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import StockTakeTable from "./StockTakeTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import { withToastManager } from 'react-toast-notifications';
import { STOCK_TAKE } from "../../const/Permissions";
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import { FaPlus } from 'react-icons/fa';


class StockTake extends Component {
  baseUrl = window.location.pathname;
  state = {
    loading: false,
    filter: {
      UserCode: undefined,
      text: undefined,
      LocCode: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1
  }

  componentDidMount() {
    const { match, authData: { userId } } = this.props;
    const { distributorId } = match.params

    // fetch initial data here 

    this.setState({
      filter: {
        ...this.state.filter,
        UserCode: userId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { distributorId } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadStockTakes(nextProps);
      });
    }, { ...this.state.filter, DistributorCode: distributorId });
  }

  loadStockTakes = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchStockTakes(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  fetchOrderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchStockTakes(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchStockTakes(filter, currentPage, currentSearchKey);
  }

  fetchStockTakes = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      UserCode: undefined,
      DocDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      No: filter.text ? '*' + filter.text + '*' : undefined
    }
    this.props.fetchStockTakesHeaders(updatedFilter, page, searchKey);
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Stock take.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
    }

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }


  render() {
    const { filter, currentPage } = this.state;
    const { loading, loggedInUser: { permissions = [] }, match, stockTakes, total, totalNetChange, topPosition } = this.props;
    const { distributorId } = match.params;
    const { CustStatus } = stockTakes;

    const enableCreate = permissions.includes(STOCK_TAKE.CREATE);
    //const isBlocked = CustStatus && CustStatus === 'Blocked' && docType && docType === 'Order';

    return (
      <div>
        <PromatePageHeader
          title={"Stock Take"}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={enableCreate && distributorId && [
            <Button onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>

          <Spin spinning={(loading && stockTakes.length === 0) || !topPosition} tip={"Loading..."}>
            <StockTakeTable
              stockTakes={stockTakes}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              setDefaultCustomerOption={this.setDefaultCustomerOption}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalNetChange={totalNetChange} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { match } = this.props;
    const { distributorId } = match.params
    if (distributorId) {
      this.props.history.push(`/distributors/${distributorId}/stocktake/new`);
    }
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

StockTake.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const searchKey = getSearchKey();

  // TODO here
  return {
    stockTakes: getStockTakesHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getStockTakeHeaderTotal(state, searchKey),
    totalNetChange: getStockTakeHeaderMetadata(state, searchKey).NetChange,
    loading: isFetchingStockTakesHeaders(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchStockTakesHeaders })(StockTake));