import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from "antd";
import PropTypes from 'prop-types';
import { ProMateGreen } from "../../../const/Theme";
import RoutesTableContainer from './RoutesTableContainer';
import { getAuthData, getLoggedInUser } from '../../../redux/reducers';
import PromatePageHeader from '../../common/PromatePageHeader';
import { ROUTES } from "../../../const/Permissions";

class Routes extends Component {

  render() {
    const { loggedInUser: { permissions = [] } } = this.props;

    return (
      <div>
        <PromatePageHeader
          title={'Routes'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
        // extra={permissions.includes(ROUTES.CREATE) && [
        //   <Button style={{
        //     backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute',
        //     top: 30,
        //     right: this.context.screenType === 'MOBILE' ? 65 : 105
        //   }} onClick={this.addRoute}>
        //     New
        //   </Button>,
        // ]}
        >

          <RoutesTableContainer />
        </PromatePageHeader>
      </div>
    )
  }

  addRoute = () => {
    const { match } = this.props;
    const url = match.url
    //console.log(url)
    this.props.history.push(url + "/new");
  }
}

const mapStateToProps = state => {
  return { authData: getAuthData(state), loggedInUser: getLoggedInUser(state) };
};

Routes.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(Routes);

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};
