import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../const/Theme";
import ItemTable from "./ItemTable";
import AdvancedFilter from "./AdvancedFilter";
import { defaultCombinedFilter } from '../../redux/reducers/inventory';
import { updateCombinedFilter } from '../../redux/actions/inventory';
import PromatePageHeader from '../common/PromatePageHeader';

const defaultState = {
  brand: undefined,
  category1: undefined,
  category2: undefined,
  productType: undefined,
  textFilter: undefined,
};

class Inventory extends Component {
  state = { ...defaultState };

  componentWillUnmount = () => {
    this.props.updateCombinedFilter(defaultCombinedFilter);
  }

  render() {
    const partHeight = (window.innerHeight - 120) / 2;
    const { location = {} } = this.props
    const { state = {} } = location
    var multiSelect = false;
    if (state.multiSelect) {
      multiSelect = state.multiSelect
    }
    return (
      <div>
        <PromatePageHeader
          title={'Inventory'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={(multiSelect) ? [
            <Button style={{
              backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute',
              top: 30,
              right: 25
            }} onClick={this.addItems}>
              Add Items
            </Button>
          ] : []}>

          <ItemTable height={partHeight + 40}
            multiSelect={multiSelect}
            salesHeader={state.order} />

          <AdvancedFilter height={partHeight - 40} />
        </PromatePageHeader>
      </div>
    )
  }

  addItems = () => {
    const { location = {} } = this.props
    const { state = {} } = location
    const { history } = this.props;
    //console.log(state, location)
    history.replace({ pathname: state.resource + "/" + state.id, state: { data: 1 } });
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default connect(null, { updateCombinedFilter })(Inventory);