import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Table } from "antd";
import { withRouter } from "react-router-dom";
import StockTransferCard from "./StockTransferCard";
import StockTransferCardHeader from "./StockTransferCardHeader";
import AdvanceSearchFilters from "../common/AdvanceSearchFilters";
import { getAuthData, getTransferHeadersTotal, getSalesUserHierarchy } from "../../redux/reducers";
import TableFooter from "../common/TableFooter";
import { fetchSalesUserHierarchy } from '../../redux/actions/users'

class StockTransferTable extends Component {

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 25;
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter } = this.props;

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={{ lg: 24, md: 24, sm: 24, xs: 24 }}
          FROM_DATE_PICKER={{ lg: 5, md: 24, sm: 24, xs: 24 }}
          TO_DATE_PICKER={{ lg: 5, md: 24, sm: 24, xs: 24 }}
          SELECT_FROM_LOCATION={{ lg: 6, md: 24, sm: 24, xs: 24 }}
          SELECT_TO_LOCATION={{ lg: 6, md: 24, sm: 24, xs: 24 }}
          //SELECT_STOCK_TRANSFER_STATUS={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SEARCH_BUTTON={{ lg: 2, md: 24, sm: 24, xs: 24 }}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          filter={filter}
        />
        <StockTransferCardHeader />
      </div>
    )
  }

  renderFooter = () => {
    const { total, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
      />
    );
  }

  renderColumns = () => {
    return [{
      render: stockTransfer => <StockTransferCard stockTransfer={stockTransfer} />
    }]
  }

  render() {
    const filteredTransfers = this.props.transfers;
    return (
      <Col>
        <Table
          rowKey={'orderNo'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredTransfers) }}
          columns={this.renderColumns()}
          dataSource={filteredTransfers}
          size={'small'}
          pagination={false}
          scroll={{ y: this.getScrollAreaHeight() }}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredTransfers)} />
      </Col>
    )
  }
}

const mapStateToProps = state => {
  return {
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    total: getTransferHeadersTotal(state)
  };
};

export default connect(mapStateToProps, { fetchSalesUserHierarchy })(StockTransferTable);