import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Table, Tag } from "antd";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import AdvanceSearchFilters from "../common/AdvanceSearchFilters";

import ReturnCard from "./ReturnCard";
import ReturnCardHeader from "./ReturnCardHeader";
import CurrencyFormat from "react-currency-format";
import _ from 'lodash'
import { SALES_INVOICES } from "../../const/Permissions";
import { getLoggedInUser } from "../../redux/reducers";

class OrderTable extends Component {

  componentDidMount = () => {
    window.addEventListener('locationchange', this.onUrlChange);
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 70;
  }

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  getCustStatusColor = text => {
    const status = this.getCustomerStatus(text)
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, match, setDefaultCustomerOption, setDefaultRouteOption } = this.props;
    const { customerId, docType } = match.params

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={entireColumn}
          SELECT_ROUTE={customerId ? hidden : oneForthOfColumn}
          SELECT_OUTLET={customerId ? hidden : oneForthOfColumn}
          SELECT_CATEGORY={customerId ? hidden : oneForthOfColumn}
          SELECT_SALES_RETRUN_REASONS={oneForthOfColumn}
          SELECT_STATUS={customerId ? oneSixthOfColumn : fiveBars}
          FROM_DATE_PICKER={customerId ? fiveBars : fiveBars}
          TO_DATE_PICKER={customerId ? fiveBars : fiveBars}
          SEARCH_TEXT={{ ...(customerId ? oneSixthOfColumn : fiveBars), placeholder: 'Return No' }}
          SEARCH_BUTTON={customerId ? oneTwelwthOfColumn : oneSixthOfColumn}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
          filter={filter}
          statusOptions={['Confirmed']}
        />
      </div>
    )
  }

  getOrderTotals = orders => {
    let total = 0;
    orders.forEach(order => {
      let AmountIncluVAT = order.AmountIncluVAT.replace(/,/g, "");
      total = total + parseFloat(AmountIncluVAT);
    })
    return total;
  }

  renderFooter = () => {
    const { total, totalAmount, currentPage, onPaginationChange, orders: filteredOrders } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
      //total={totalAmount}
      //originalAmountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.OrgAmount.replace(/,/g, '')), 0)}
      //discountAmountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.DisAmount.replace(/,/g, '')), 0)}
      //AvgDisPersentageTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.AvgDisPersentage.replace(/,/g, '')), 0)}
      //amountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.Amount.replace(/,/g, '')), 0)}
      //settledAmountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.SettledAmount.replace(/,/g, '')), 0)}
      //remAmountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.RemAmount.replace(/,/g, '')), 0)}
      //pDCAmountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.PDCAmount.replace(/,/g, '')), 0)}
      //netRemAmountTotal={filteredOrders.reduce((accumulator, current) => accumulator + parseFloat(current.NetRemAmount.replace(/,/g, '')), 0)}
      //docType={'salesInvoice'}
      />
    );
  }

  renderColumns = () => {
    return [{
      render: order => order === 0 ? <ReturnCardHeader style={{ backgroundColor: '#eceaea' }} /> : (
        order === 1 ? this.renderFooter() : <ReturnCard order={order} />
      )
    }]
  }

  getCustomerStatus = (CustStatus) => {
    return (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;
  }

  render() {
    const { orders: filteredOrders, loggedInUser: { permissions = [] }, history } = this.props;
    const sourceData = filteredOrders.length === 0 ? [] : [0, ...filteredOrders, 1]

    const columns = [
      {
        title: 'Return No',
        dataIndex: 'No',
        key: 'no',
        align: 'left',
        width: 150,
        render: text => <span style={{ ...styles.orderNo, width: 200 }}>{text}</span>,
      },
      {
        title: 'Return Date',
        dataIndex: 'PostingDate',
        key: 'PostingDate',
        align: 'center',
      },
      {
        title: 'Return Type',
        dataIndex: 'ReturnReasonDescrip',
        key: 'ReturnReasonDescrip',
        align: 'center',
      },
      {
        title: 'Customer Code',
        dataIndex: 'SellToCusNo',
        key: 'SellToCusNo',
        align: 'center',
      },
      {
        title: 'Customer Name',
        dataIndex: 'SellToCusName',
        key: 'SellToCusName',
        align: 'center',
      },

      {
        title: 'Customer Status',
        dataIndex: 'CustStatus',
        key: 'CustStatus',
        align: 'center',
        render: text => <Tag style={styles.status} color={this.getCustStatusColor(text)}>{this.getCustomerStatus(text)}</Tag>
      },

      {
        title: 'Category',
        dataIndex: 'OutletCategoryCode',
        key: 'OutletCategoryCode',
        align: 'center',
      },

      {
        title: 'Route',
        dataIndex: 'RouteName',
        key: 'RouteName',
        align: 'center',
      },

      {
        title: 'Status',
        dataIndex: 'OrderStatus',
        key: 'OrderStatus',
        align: 'center',
        render: text => <Tag style={styles.status} color={this.getStatusColor(text)}>{text}</Tag>
      },

      {
        title: 'Sales Person',
        dataIndex: 'CSRUserName',
        key: 'CSRUserName',
        align: 'center',
      },

      {
        title: 'Amount',
        dataIndex: 'Amount ',
        key: 'Amount ',
        align: 'right',
        render: text => <CurrencyFormat
          value={text || 0}
          displayType={'text'}
          thousandSeparator={true}
          isNumericString={true}
          fixedDecimalScale={true}
          style={styles.price}
          decimalScale={2} />
      }
    ];

    if (window.innerWidth < 768) {
      return (
        <Table
          columns={this.renderColumns()}
          rowKey={'orderNo'}
          rowClassName={'orderRow'}
          size={'small'}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders), overflowY: 'scroll' }}
          pagination={false}
          showHeader={false}
          title={() => this.renderHeader()}
          dataSource={sourceData} />
      )
    }
    return (
      <Col>
        <Table
          rowKey={'orderNo'}
          rowClassName={'table-row-dark'}
          onRowClick={(data) => (permissions.includes(SALES_INVOICES.VIEW) || permissions.includes(SALES_INVOICES.UPDATE)) && history.push("/salesInvoices/" + encodeURIComponent(data.No))}
          dataSource={filteredOrders}
          columns={columns}
          size={'middle'}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders), overflowY: 'scroll' }}
          pagination={false}
          title={() => this.renderHeader()}
          scroll={{ y: this.getScrollAreaHeight() }}
          summary={() => {
            return (<div>apple</div>)
          }}
          footer={() => this.renderFooter()} />
      </Col>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderRow: {
    backgroundColor: 'black'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  orderDateTitle: {
    fontSize: 12
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%'
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

OrderTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(OrderTable));