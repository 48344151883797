import React, { Component } from 'react';
import Logo from '../../../../assets/logo.png';

export default class SalesReturnOrder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const call = this.props.call;
        const customers = this.props.customers.filter((customer) => (customer.callId == this.props.call.CallNo));
        return (
            <>
                <div style={{ padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start" }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px', paddingLeft: '16px', alignItems: 'center', background: '#a0cc4b', height: '30px' }}>
                            <p style={{ fontWeight: 'bold', color: 'black' }}>Customer Creation</p>
                        </div>
                        <img style={{ height: '50px' }} src={Logo} />
                    </div>

                    {/* Start Call Details Section */}
                    <p style={{ fontWeight: 'bold', color: 'black', textDecoration: 'underline', marginTop: '10px', marginBottom: '0' }}>Call Details:</p>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Start Reason</span>
                                <span style={{ fontSize: '12px' }}>{call.StartReasonDescrip}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Remarks</span>
                                <span style={{ fontSize: '12px' }}>{(call.Remarks != '' && call.Remarks != undefined) ? call.Remarks : '-'}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>Start Time</span>
                                <span style={{ fontSize: '12px' }}>{call.StartDateTime}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>End Time</span>
                                <span style={{ fontSize: '12px' }}>{call.EndDateTime}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <span style={{ fontSize: '12px' }}>End Status</span>
                                <span style={{ fontSize: '12px' }}>{call.EndStatusDescrip}</span>
                            </div>
                        </div>
                    </div>

                    {/* Start Sale Return Details Section */}

                    {
                        customers.map((customer, index) => (
                            <div key={'customer-' + index}>
                                <p style={{ fontWeight: 'bold', color: 'black', textDecoration: 'underline', marginTop: '10px', marginBottom: '0' }}>{index + 1}: Customer Details</p>
                                <div style={{ marginTop: '10px' }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '500px', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Route Code</span>
                                                <span style={{ fontSize: '12px' }}>{customer.RouteCode}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Distributor</span>
                                                <span style={{ fontSize: '12px' }}>{customer.DistributorName}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Outlet Name</span>
                                                <span style={{ fontSize: '12px' }}>{customer.Name}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Address</span>
                                                <span style={{ fontSize: '12px' }}>{customer.Add}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>NIC/Passport</span>
                                                <span style={{ fontSize: '12px' }}>{customer.NIC}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Contact No</span>
                                                <span style={{ fontSize: '12px' }}>{customer.PhoneNo}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Fax</span>
                                                <span style={{ fontSize: '12px' }}>{customer.FaxNo}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Email</span>
                                                <span style={{ fontSize: '12px' }}>{customer.EMail}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Contact Person</span>
                                                <span style={{ fontSize: '12px' }}>{customer.ContactPerson}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Category</span>
                                                <span style={{ fontSize: '12px' }}>{customer.OutletCategoryCode}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Customer Type</span>
                                                <span style={{ fontSize: '12px' }}>{customer.PaymentType}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Payment Methods</span>
                                                <span style={{ fontSize: '12px' }}>{customer.paymentMethods.data.map((method, index) => (<span key={'method' + index} style={{marginRight: '7px'}}>{method.Code}</span>))}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                                <span style={{ fontSize: '12px' }}>Payment Terms</span>
                                                <span style={{ fontSize: '12px' }}>{customer.paymentTerms.data.map((term, index) => (<span key={'term' + index} style={{marginRight: '7px'}}>{term.Code}</span>))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {!this.props.isLastElement ? <div className="page-break"></div> : <></>}
            </>
        );
    }
}