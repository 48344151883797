import React, { Component } from 'react';
import { Table } from "antd";
import { connect } from "react-redux";
import { getTransferLinesForDoc, getTransferHeader, getItems, getLoggedInUser } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { STOCK_TRANSFERS } from "../../../const/Permissions";

class ItemTable extends Component {
  getItem = line => {
    const { transfer, items } = this.props;

    if (!transfer) return null;
    else return items.filter(item => item.LotNo === line.LotNo && item.ItemNo === line.ItemNo && transfer.FromLocCode === item.LocCode)[0];
  }

  renderDescription = line => {
    let description = line.LotNo + ' - ' + line.ItemNo;
    const item = this.getItem(line);

    if (!item) return description;
    else return description + ' - ' + item.Description;
  }

  renderFromQuantity = line => {
    const item = this.getItem(line);

    if (!item) return '';
    else return item.Inventory;
  }

  getColumns = () => [
    {
      title: 'No',
      align: 'center',
      width: 35,
      render: (_, __, index) => (index + 1)
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
      width: '65%',
      render: (_, item) => this.renderDescription(item)
    },
    {
      title: 'From Quantity',
      dataIndex: 'FromQuantity',
      key: 'Quantity',
      width: '15%',
      align: 'right',
      render: (_, item) => this.renderFromQuantity(item)
    },
    {
      title: 'Transfer Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      align: 'right',
      width: '15%'
    }
  ];

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderFooter = () => {
    const { lines = [], transfer = {}, loggedInUser: { permissions = [] } } = this.props;
    const enableUpdate = permissions.includes(STOCK_TRANSFERS.UPDATE);
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {lines.length}</p>
        {enableUpdate && <CallStatusValidateButton onClick={this.props.OnClickAddItem} type="primary">Add Item</CallStatusValidateButton>}
      </div>
    )
  }

  render() {
    const { height = 100, transfer, history, lines = [], match, loggedInUser: { permissions = [] } } = this.props;
    const { transferId } = match.params;
    const decodeId = decodeURIComponent(transferId);
    const enableUpdate = permissions.includes(STOCK_TRANSFERS.UPDATE);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          bodyStyle={{ minHeight: height }}
          dataSource={lines}
          size={'small'}
          onRowClick={() => enableUpdate && history.push({ pathname: '/stock', state: { resource: "stockTransfer", transfer, id: decodeId } })}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { transferId } = ownProps.match.params;
  const decodeId = decodeURIComponent(transferId);

  return {
    transfer: getTransferHeader(state, decodeId),
    lines: getTransferLinesForDoc(state, decodeId),
    items: getItems(state),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTable));