import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ADD_ROUTE_TARGET, ROUTE_TARGET } from '../../../const/Permissions';
import { RouteTargetStatus } from '../../../const/status';
import { ProMateGreen } from "../../../const/Theme";
import { updateRouteTarget } from '../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { getAuthData, getFilteredRouteTargets, getSalesUserHierarchy } from "../../../redux/reducers";
import PromatePageHeader from '../../common/PromatePageHeader';
import { AddButton } from '../common/Buttons';
import DataTable from './components/DataTable';
import Header from './components/Header';

export class RouteTarget extends Component {
  _isMounted = false;

  state = {
    loading: true,
    selectedRowKeys: []
  }

  componentDidMount = async () => {
    const { fetchSalesUserHierarchy, authData, history } = this.props;
    this._isMounted = true;

    if (!authData.permissions.includes(ROUTE_TARGET.VIEW)) {
      history.push('/targetScheduler');
      return;
    }
    await fetchSalesUserHierarchy(authData);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  setLoading = (value) => {
    if (this._isMounted) {
      this.setState({
        loading: value,
      });
    }
  }

  setSelectedRowKeys = (selectedRowKeys) => {
    if (this._isMounted) {
      this.setState({
        selectedRowKeys
      });
    }
  }

  onHandleAllApproved = async () => {
    const { routeTargets, toastManager, updateRouteTarget } = this.props;
    const list = routeTargets.records.filter((record => this.state.selectedRowKeys.includes(record.RouteCode)));
    if (list.length > 0) {
      for (const item of list) {
        await updateRouteTarget({ ...item, Approved: RouteTargetStatus.APPROVED });
      }
      toastManager.add("Selected Route targets were approved.", { autoDismiss: true, appearance: 'success' });
    } else {
      toastManager.add("No Route targets are select.", { autoDismiss: true, appearance: 'info' });
    }
  }

  onHandleAllRejected = async () => {
    const { routeTargets, toastManager, updateRouteTarget } = this.props;
    const list = routeTargets.records.filter((record => this.state.selectedRowKeys.includes(record.RouteCode)));
    if (list.length > 0) {
      for (const item of list) {
        await updateRouteTarget({ ...item, Approved: RouteTargetStatus.REJECTED });
      }
      toastManager.add("Selected Route targets were rejected.", { autoDismiss: true, appearance: 'success' });
    } else {
      toastManager.add("No Route targets are select.", { autoDismiss: true, appearance: 'info' });
    }
  }

  render() {
    const { salesUserHierarchy, authData } = this.props;
    const hierarchy = salesUserHierarchy[Object.keys(salesUserHierarchy)[0]];

    return (
      <PromatePageHeader
        title="Route Target"
        style={styles.pageHeader}
        onBack={() => this.props.history.goBack()}
        showCallButton={false}
        extra={authData?.permissions.includes(ADD_ROUTE_TARGET.CREATE) && !this.state.loading && <AddButton label="Add Route Target" route="/targetScheduler/routeTarget/add" />}
      >
        <Header
          setLoading={this.setLoading}
          loading={this.state.loading}
          permissions={authData?.permissions ?? []}
          onHandleAllApproved={this.onHandleAllApproved}
          onHandleAllRejected={this.onHandleAllRejected}
        />
        <DataTable
          loading={this.state.loading}
          setLoading={this.setLoading}
          positionType={hierarchy?.positionType}
          permissions={authData?.permissions ?? []}
          setSelectedRowKeys={this.setSelectedRowKeys}
          selectedRowKeys={this.state.selectedRowKeys}
        />
      </PromatePageHeader>
    )
  }
}

const mapStateToProps = (state) => ({
  salesUserHierarchy: getSalesUserHierarchy(state),
  authData: getAuthData(state),
  routeTargets: getFilteredRouteTargets(state),
})

const mapDispatchToProps = {
  fetchSalesUserHierarchy,
  updateRouteTarget
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTarget)));

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  text: {
    height: "100%",
    padding: "5px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}