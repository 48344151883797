import { combineReducers } from 'redux';
import { FETCH_EXPENSES_CATEGORIES, START_FETCH_EXPENSES_CATEGORIES, FETCH_EXPENSES_CATEGORY, FINISH_FETCH_EXPENSES_CATEGORIES } from '../actions/ExpensesCategories';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES_CATEGORIES:
      const newState = { ...state };
      action.expensesCategories.forEach(category => newState[category.Code] = category);
      return newState;
    case FETCH_EXPENSES_CATEGORY: {
      const newState = { ...state };
      newState[action.expensesCategories.Code] = action.expensesCategories;
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_EXPENSES_CATEGORIES: {
      const newState = [...state]
      action.expensesCategories.forEach(category => {
        if (!newState.includes(category.Code)) newState.push(category.Code);
      });

      return newState;
    }
    case FETCH_EXPENSES_CATEGORY: {
      const newState = [...state];
      if (!newState.includes(action.expensesCategories.Code)) {
        newState.push(action.expensesCategories.Code)
      }
      return newState;
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES_CATEGORIES: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.expensesCategories.map(category => category.Code)
      };
    }
    default:
      return state;
  }
};

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_EXPENSES_CATEGORIES:
      return true;

    case FINISH_FETCH_EXPENSES_CATEGORIES:
      return false;

    default:
      return state;
  }
}

const expensesCategories = combineReducers({
  byId,
  ids,
  bySearchKey,
  fetching
});

export default expensesCategories;

export const getExpensesCategories = state => {
  return state.ids.map(id => state.byId[id])
}

export const getByDocNo = (state, no) => {
  const key = no;
  return state.byId[key] || undefined;
}

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.DocDate) - new Date(s1.DocDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const isFetching = state => state.fetching;