export const CUSTOMERS = {
  CREATE: 'Customers.Create',
  VIEW: 'Customers.View',
  UPDATE: 'Customers.Update',
  //DELETE: 'Customers.Delete',
  PAYMENT_METHOD_CODES: {
    CREDIT: 'Customers.PaymentMethodCodes.Credit'
  },
  MAP_UPDATE: 'Customers.Maps.Update'
}

export const DISTRIBUTORS = {
  CREATE: 'Distributors.Create',
  VIEW: 'Distributors.View',
  UPDATE: 'Distributors.Update',
  DELETE: 'Distributors.Delete'
}

export const SALES_REPRESENTATIVES = {
  CREATE: 'SalesRepresentatives.Create',
  VIEW: 'SalesRepresentatives.View',
  UPDATE: 'SalesRepresentatives.Update',
  DELETE: 'SalesRepresentatives.Delete'
}

export const SALES_ORDERS = {
  CREATE: 'SalesOrders.Create',
  VIEW: 'SalesOrders.View',
  UPDATE: 'SalesOrders.Update',
  DELETE: 'SalesOrders.Delete',
  CONFIRM: 'SalesOrders.Confirm',
  GENERATE_INVOICE: 'SalesOrders.GenerateInvoice',
  PRINT: 'SalesOrders.Print',
}

export const SALES_INVOICES = {
  VIEW: 'SalesInvoices.View',
  PRINT: 'SalesInvoices.Print',
  MAKE_PAYMENT: 'SalesInvoices.MakePayment',
  SETOFF: 'SalesInvoices.SetOff',
  DEALLOCATE: 'SalesInvoices.deallocate'
}

export const RECEIPTS = {
  CREATE: 'Receipts.Create',
  VIEW: 'Receipts.View',
  UPDATE: 'Receipts.Update',
  DELETE: 'Receipts.Delete',
  CONFIRM: 'Receipts.Confirm',
  SETOFF: 'Receipts.SetOff',
  PRINT: 'Receipts.Print',
  RETURN: 'Receipts.Return',
  DEALLOCATE: 'Receipts.deallocate'
}

export const DISTRIBUTOR_PAYMENTS = {
  VIEW: 'DistributorPayments.View'
}

export const CREDIT_NOTES = {
  CREATE: 'CreditNotes.Create',
  VIEW: 'CreditNotes.View',
  UPDATE: 'CreditNotes.Update',
  DELETE: 'CreditNotes.Delete',
  CONFIRM: 'CreditNotes.Confirm',
  PRINT: 'CreditNotes.Print',
}

export const CUSTOMER_CREDIT_INVOICES = {
  VIEW: 'CustomerCreditInvoices.View',
  PRINT: 'CustomerCreditInvoices.Print',
  SETOFF: 'CustomerCreditInvoices.SetOff',
  DEALLOCATE: 'CustomerCreditInvoices.deallocate'
}

export const CALLS = {
  CREATE: 'Calls.Create',
  VIEW: 'Calls.View',
  UPDATE: 'Calls.Update',
  // DELETE: 'Calls.Delete'
}

export const SUPPLIER_RETURNS = {
  CREATE: 'SupplierReturns.Create',
  VIEW: 'SupplierReturns.View',
  UPDATE: 'SupplierReturns.Update',
  DELETE: 'SupplierReturns.Delete'
}

export const EXPENSES = {
  CREATE: 'Expenses.Create',
  VIEW: 'Expenses.View',
  UPDATE: 'Expenses.Update',
  DELETE: 'Expenses.Delete',
  PRINT: 'Expenses.Print',
  CONFIRM: 'Expenses.Confirm',
}

export const EXPENSE_CATEGORIES = {
  CREATE: 'ExpenseCategories.Create',
  VIEW: 'ExpenseCategories.View',
  UPDATE: 'ExpenseCategories.Update',
  DELETE: 'ExpenseCategories.Delete'
}

export const GRNs = {
  CREATE: 'GRNs.Create',
  VIEW: 'GRNs.View',
  UPDATE: 'GRNs.Update',
  DELETE: 'GRNs.Delete'
}

export const INVENTORY = {
  //CREATE: 'Inventory.Create',
  VIEW: 'Inventory.View',
  //UPDATE: 'Inventory.Update',
  //DELETE: 'Inventory.Delete'
}

export const PURCHASING_ORDERS = {
  CREATE: 'PurchasingOrders.Create',
  VIEW: 'PurchasingOrders.View',
  UPDATE: 'PurchasingOrders.Update',
  DELETE: 'PurchasingOrders.Delete',
  PRINT: 'PurchasingOrders.Print',
  CONFIRM: 'PurchasingOrders.Confirm',
  RECEIVE: 'PurchasingOrders.Receive',
  RECEIVE_AND_RETURN: 'PurchasingOrders.ReceiveAndReturn'
}

export const SUPPLIER_PAYMENTS = {
  CREATE: 'SuplierPayments.Create',
  VIEW: 'SuplierPayments.View',
  UPDATE: 'SuplierPayments.Update',
  DELETE: 'SuplierPayments.Delete'
}

export const WAREHOUSES = {
  CREATE: 'Warehouses.Create',
  VIEW: 'Warehouses.View',
  UPDATE: 'Warehouses.Update',
  DELETE: 'Warehouses.Delete'
}

export const ROUTES = {
  CREATE: 'Routes.Create',
  VIEW: 'Routes.View',
  UPDATE: 'Routes.Update',
  DELETE: 'Routes.Delete',
  MAP_UPDATE: 'Routes.Maps.Update'
}

export const STOCK_TRANSFERS = {
  CREATE: 'StockTransfers.Create',
  VIEW: 'StockTransfers.View',
  UPDATE: 'StockTransfers.Update',
  DELETE: 'StockTransfers.Delete',
  APPROVE: 'StockTransfers.Approve',
  CONFIRM: 'StockTransfers.Confirm',
  PRINT: 'StockTransfers.Print'
}

export const STOCK_TAKE = {
  CREATE: 'StockTake.Create',
  VIEW: 'StockTake.View',
  UPDATE: 'StockTake.Update',
  DELETE: 'StockTake.Delete',
  CONFIRM: 'StockTake.Confirm',
  PRINT: 'StockTake.Print',
  VISIBLE_SYSTEM_DATA: 'StockTake.VisibleSystemData',
  VISIBLE_PHYSICAL_DATA: 'StockTake.VisiblePhysicalData',
  APPROVE: 'StockTake.Approve',
  REJECT: 'StockTake.Reject'
}

export const SALES_RETURNS = {
  CREATE: 'SalesReturns.Create',
  VIEW: 'SalesReturns.View',
  UPDATE: 'SalesReturns.Update',
  DELETE: 'SalesReturns.Delete',
  CONFIRM: 'SalesReturns.Confirm',
  GENERATE_RETURN_INVOICE: 'SalesReturns.GenerateReturnInvoice',
  PRINT: 'SalesReturns.Print'
}

export const SALES_RETURN_INVOICES = {
  VIEW: 'SalesReturnInvoice.View',
  PRINT: 'SalesReturnInvoice.Print',
  SETOFF: 'SalesReturnInvoice.SetOff'
}

export const TRIP_SCHEDULER = {
  VIEW: 'TripScheduler.View',
  CREATE: 'TripScheduler.Create',
  UPDATE: 'TripScheduler.Update',
  APPROVE: 'TripScheduler.Approve',
  RECALL: 'TripScheduler.Recall',
  DELETE: 'TripScheduler.Delete'
}

export const INVOICE_SETTLEMENT = {
  //CREATE: 'InvoiceSettlements.Create',
  VIEW: 'InvoiceSettlements.View',
  SETOFF: 'InvoiceSettlements.SetOff',
  //UPDATE: 'InvoiceSettlements.Update',
  //DELETE: 'InvoiceSettlements.Delete'
}

export const PURCHASE_RETURNS = {
  CREATE: 'PurchaseReturns.Create',
  VIEW: 'PurchaseReturns.View',
  UPDATE: 'PurchaseReturns.Update',
  DELETE: 'PurchaseReturns.Delete',
  CONFIRM: 'PurchaseReturns.Confirm',
  //GENERATE_RETURN_INVOICE: 'SalesReturns.GenerateReturnInvoice',
  PRINT: 'PurchaseReturns.Print'
}
export const PURCHASING_INVOICES = {
  // CREATE: 'PurchasingInvoices.Create',
  VIEW: 'PurchasingInvoices.View'
  // UPDATE: 'PurchasingInvoices.Update',
  // DELETE: 'PurchasingInvoices.Delete'
}

export const PURCHASE_CREDIT_INVOICES = {
  VIEW: 'DistributorCreditInvoices.View',
  PRINT: 'DistributorCreditInvoices.Print',
  //SETOFF: 'DistributorCreditInvoices.SetOff',
  //DEALLOCATE: 'DistributorCreditInvoices.deallocate'
}

export const FAILED_ORDERS = {
  VIEW: 'SalesOrders.View',
}

export const REPORTS = {
  VIEW: 'SalesOrders.View',
}

// permissions for target scheduler
export const TARGET_SCHEDULER = {
  VIEW: 'TargetScheduler.View',
}

export const CUSTOMER_FREQUENCY = {
  VIEW: 'CustomerFrequency.View',
  UPDATE: 'CustomerFrequency.Update'
}

export const ROUTE_TARGET = {
  EXPORT: 'RouteTarget.Export',
  VIEW: 'RouteTarget.View',
  APPROVE: 'RouteTarget.Approve',
  REJECT: 'RouteTarget.Reject'
}

export const ADD_ROUTE_TARGET = {
  UPDATE: 'AddRouteTarget.Update',
  CREATE: 'AddRouteTarget.Create',
  VIEW: 'AddRouteTarget.View',

}

export const ROUTE_CUSTOMER_TARGET = {
  VIEW: 'RouteCustomerTarget.View',
  APPROVE: 'RouteCustomerTarget.Approve',
  REJECT: 'RouteCustomerTarget.Reject'
}

export const ADD_CUSTOMER_ROUTE_TARGET = {
  CREATE: 'AddRouteCustomerTarget.Create',
  UPDATE: 'AddRouteCustomerTarget.Update',
  VIEW: 'AddRouteCustomerTarget.View'
}

