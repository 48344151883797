import moment from 'moment';
import { dateFormatView, dateTimeFormatView, dateTimeFormatUpdate, dateFormatUpdate } from '../const/Formats';

export const formatDateTime = dateTimeString => {
  const dateTime = moment(dateTimeString);
  return dateTime.toDate().getTime() > 0 ? dateTime.format(dateTimeFormatView) : undefined;
}

export const formatGeoLocation = (lat, lng, accuracy) => {
  const cordinateAccuracy = accuracy || 13;
  return lat.toFixed(cordinateAccuracy) + ',' + lng.toFixed(cordinateAccuracy)
}

export const toDateTimeString = dateTimeMoment => dateTimeMoment.format(dateTimeFormatView);

export const parseMoment = dateTimeString => moment(dateTimeString, dateTimeFormatView);

export const isValidDateTimeString = dateTimeString => moment(dateTimeString, dateTimeFormatView, true).isValid()

export const toCommaSeparatedString = float => float.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


export const ServerDateTimeTOLocalDateTime = (serverUTCStr) => {
  //var DF_SERVER_UTC = 'MM/DD/YY hh:mm A';
  //var DF_LOCAL = 'DD/MM/YYYY';
  var DF_SERVER_UTC = dateTimeFormatUpdate;
  var DF_LOCAL = dateTimeFormatView

  // var serverUTCStr = '07/12/21 02:28 PM';
  var serverUTC = moment.utc(serverUTCStr, DF_SERVER_UTC);
  // alert("serverUTC Formatted= " + serverUTC.format(DF_SERVER_UTC));
  var local = moment(serverUTC).local();
  // alert("local Formatted= " + local.format(DF_LOCAL));

  return local.format(DF_LOCAL);
}

export const ServerDateTOLocalDate = (serverUTCStr) => {
  //var DF_SERVER_UTC = 'MM/DD/YY hh:mm A';
  //var DF_LOCAL = 'DD/MM/YYYY';
  var DF_SERVER_UTC = dateFormatUpdate;
  var DF_LOCAL = dateFormatView
  // var serverUTCStr = '07/12/21 02:28 PM';
  var serverUTC = moment.utc(serverUTCStr, DF_SERVER_UTC);
  // alert("serverUTC Formatted= " + serverUTC.format(DF_SERVER_UTC));

  var local = moment(serverUTC).local();

  //console.log("local Formatted= " , local.format(DF_LOCAL));

  return local.format(DF_LOCAL);

}



export const LocalDateTimeTOSeverDateTime = (localStr) => {
  // var DF_CALENDAR = 'DD/MM/YYYY hh:mm A';
  // var DF_SERVER_UTC = 'MM/DD/YY hh:mm A';

  var DF_CALENDAR = dateTimeFormatView;
  var DF_SERVER_UTC = dateTimeFormatUpdate;

  //var localStr = '12/07/2021 07:58 PM';

  var localInUTC = moment.utc(moment(localStr).format(DF_CALENDAR)).format(DF_SERVER_UTC);
  //console.log(localInUTC)

  // var calDateAsDateObj = null;

  // var localInUTC = moment.utc(calDateAsDateObj).format(DF_SERVER_UTC);
  // alert(localInUTC)
  return localInUTC;
}

export const LocalDateTOSeverDate = (localStr) => {
  // var DF_CALENDAR = 'DD/MM/YYYY hh:mm A';
  // var DF_SERVER_UTC = 'MM/DD/YY hh:mm A';

  var DF_CALENDAR = dateFormatView;
  var DF_SERVER_UTC = dateFormatUpdate;

  //var localStr = '12/07/2021 07:58 PM';

  var localInUTC = moment.utc(moment(localStr).format(DF_CALENDAR)).format(DF_SERVER_UTC);
  //console.log(localInUTC)

  // var calDateAsDateObj = null;

  // var localInUTC = moment.utc(calDateAsDateObj).format(DF_SERVER_UTC);
  // alert(localInUTC)
  return localInUTC;
}



