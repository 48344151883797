import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import store from './store';
import Root from './Root';
import { updateLocation } from './utils/Location';
import 'antd/dist/antd.css';
import './App.css';


class App extends Component {
  state = {
    screenHeight: window.innerWidth < 768 ? window.innerHeight : window.innerHeight,
    screenType: window.innerWidth < 768 ? 'MOBILE' : 'DESKTOP'
  }

  componentDidMount = () => {
    window.addEventListener('resize', () => this.getView());
    this.locationUpdater = setInterval(updateLocation, 5000);
  }

  componentWillUnmount = () => {
    window.addEventListener('resize', this.getView());
    clearInterval(this.locationUpdater);
  }

  getView = () => {
    const width = window.innerWidth;
    this.setState({
      screenHeight: width < 768 ? window.innerHeight : window.innerHeight,
      screenType: width < 768 ? 'MOBILE' : 'DESKTOP'
    });
  }

  getChildContext() {
    return { screenHeight: this.state.screenHeight, screenType: this.state.screenType }
  }

  render = () => {
    return (
      <ToastProvider autoDismissTimeout={5000}>
        <Provider store={store}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </Provider>
      </ToastProvider>
    );
  }
}

App.childContextTypes = {
  screenHeight: PropTypes.object.isRequired,
  screenType: PropTypes.object.isRequired
}

export default App;
