let demoPurchasingOrders = []

const generateDemoOrders = () => {
  demoPurchasingOrders = []
  demoPurchasingOrders.push(defaultGRN)
  demoPurchasingOrders.push({ ...defaultGRN2, salesLines: getSalesLines("GRN 0") })

  for (let i = 1; i < 25; i++) {
    demoPurchasingOrders.push({
      grnNo: "GRN " + i,
      orderType: "Type " + i,
      sellToCustomerCode: "Distributor Code" + i,
      sellToCustomerName: "Distributor Name " + i + 1,
      billToCustomerCode: "Supplier Code " + randoNumber(),
      billToCustomerName: "Supplier Name " + i,
      locationCode: "Location Code " + randoNumber(),
      orderDate: randomDate(new Date(2012, 0, 1), new Date()),
      salesLines: getSalesLines("order " + i),
      salespersonCode: "Sales Person " + randoNumber(),
      totalDiscountAmount: 0,
      totalAmount: randoNumber(),
      amountIncludingVat: randoNumber(),
      status: randomStatus(),
      links: [],
      sessionId: randoNumber()
    })
  }

  return demoPurchasingOrders;
}

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const randoNumber = () => {
  return Math.floor((Math.random() * 50000) + 1);
}

const randomStatus = () => {
  const statuses = ["Open", "Close", "Confirmed"];
  return statuses[Math.floor((Math.random() * 3) + 0)];
}

const getSalesLines = grnNo => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      grnNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: randoNumber(),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

export const getDemoGRNs = () => {
  generateDemoOrders();
  return demoPurchasingOrders;
}

export const getDemoGRN = id => {
  console.log(id);
  const demoOrder = demoPurchasingOrders.find(order => order.grnNo === id);
  return demoOrder;
}

export const getSalesLineItem = grnNo => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      grnNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: randoNumber(),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

const defaultGRN = {
  grnNo: "new",
  orderType: "Type 3",
  sellToCustomerCode: "Distributor Code",
  sellToCustomerName: "Distributor Name ",
  billToCustomerCode: "Supplier Code " + randoNumber(),
  billToCustomerName: "Supplier Name",
  locationCode: "Location Code 454",
  orderDate: new Date(2012, 0, 1),
  salesLines: getSalesLines("new").slice(0, 1),
  salespersonCode: "Sales Person 12",
  totalDiscountAmount: 0,
  totalAmount: 54231,
  amountIncludingVat: 562349,
  status: "Open",
  links: [],
  sessionId: 123465446
}

const defaultGRN2 = {
  grnNo: "GRN 0",
  orderType: "Type 3",
  sellToCustomerCode: "Distributor Code",
  sellToCustomerName: "Distributor Name ",
  billToCustomerCode: "Supplier Code " + randoNumber(),
  billToCustomerName: "Supplier Name",
  locationCode: "Location Code 454",
  orderDate: new Date(2012, 0, 1),
  salesLines: [],
  salespersonCode: "Sales Person 12",
  totalDiscountAmount: 0,
  totalAmount: 54231,
  amountIncludingVat: 562349,
  status: "Open",
  links: [],
  sessionId: 123465446
}