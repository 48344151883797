import React, { Component } from 'react';
import { Col, Pagination, Row } from 'antd';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { PAGE_SIZE } from '../../redux/actions/fetchOData';
import { toCommaSeparatedString } from '../../utils/Formats';
import { format } from "currency-formatter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class TableFooter extends Component {

  renderNoOfRecords = colSize => {
    const { noOfRecords, noOfRecordLabel } = this.props;
    return (
      // <Col xs={colSize}>Total {noOfRecordLabel || ''} {noOfRecords}</Col>
      <Col xs={colSize}>Total {noOfRecords}</Col>
    );
  }

  renderTotalOutstanding = colSize => {
    const { total, fetchingTotal } = this.props;

    return total != undefined ? (
      <Col xs={colSize} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
        {fetchingTotal ? '...' : (
          <CurrencyFormat
            value={total}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            decimalScale={2} />
        )}
      </Col>
    ) : null;
  }

  renderFooterTotal(docType, total, customLabel) {
    switch (docType) {
      case "purchasingInvoices":
        return <span>Total NET OS <p style={{ color: 'red' }}>{format(toCommaSeparatedString(total), currencyFormat)}</p></span>;
      case "salesInvoice":
        return <span>Total NET OS <p style={{ color: 'red' }}>{format(toCommaSeparatedString(total), currencyFormat)}</p></span>;
      default:
        if (customLabel) {
          return <span>{customLabel}<p >{format(toCommaSeparatedString(total), currencyFormat)}</p></span>;
        } else {
          return <span>Total Amount <p >{format(toCommaSeparatedString(total), currencyFormat)}</p></span>;
        }

    }
  }

  renderPagination = colSize => {
    const { noOfRecords, currentPage, onPaginationChange, remAmountTotal, pDCAmountTotal, total, docType, totalLabel } = this.props;

    return (
      <Col xs={colSize} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
        {window.innerWidth < 768 && total && this.renderFooterTotal(docType, total, totalLabel)}
        <Pagination
          size='small'
          pageSize={PAGE_SIZE}
          total={noOfRecords}
          current={currentPage}
          onChange={onPaginationChange}
        />
      </Col>
    );
  }

  renderTotals = () => {
    const { totals, widths } = this.props;
    const valuesArray = []
    totals.forEach((total, i) => {
      const element =
        <div style={{ width: widths[i], float: 'right' }}>
          <CurrencyFormat
            value={total || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            decimalScale={2} />
        </div>

      valuesArray.push(element);
    });
    return valuesArray.reverse();

  }

  getFooterItemsDesktop = () => {
    const { total, docType, widths } = this.props;

    if (docType) {
      var amountColumnWidth = widths && widths.reduce((prev, curr) => prev + curr);
      return (
        <Row style={{ padding: 0, backgroundColor: '#eceaea' }}>
          <Row style={{
            alignItems: 'center',
            padding: 0, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea'
          }}>
            <Col xs={3} style={{ textAlign: 'left', float: 'left' }}>{this.renderNoOfRecords(24)}</Col>
            <Col style={{ textAlign: 'right', float: 'right', width: amountColumnWidth }}>
              {docType && this.renderTotals()}
            </Col>
            <Col xs={6} style={{ textAlign: 'center', float: 'right' }}>{this.renderPagination(24)}</Col>
          </Row>
        </Row>
      )
    }

    return [
      this.renderNoOfRecords(6),
      this.renderPagination(total ? 12 : 18),
      this.renderTotalOutstanding(total ? 6 : 0)
    ];
  }

  getFooterItemsMobile = () => {
    const { docType } = this.props;
    if (docType) {
      return [
        this.renderNoOfRecords(11),
        // this.renderTotalOutstanding(12),
        this.renderPagination(12)
      ]
    }
    return [
      this.renderNoOfRecords(10),
      // this.renderTotalOutstanding(12),
      this.renderPagination(24)
    ]
  };

  render = () => {
    const { docType, style } = this.props;
    return (
      <div style={{
        backgroundColor: '#eceaea',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //marginLeft: -10,
        paddingTop: 10,
        ...style
      }}>
        <Row style={{ marginBottom: 5, marginLeft: 5, display: this.context.screenType === 'DESKTOP' ? '' : docType && 'flex' }}>
          {this.context.screenType === 'DESKTOP' ? this.getFooterItemsDesktop() : this.getFooterItemsMobile()}
        </Row>
      </div>
    )
  }
}

TableFooter.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default TableFooter;
