import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_HEADERS':
		case 'FETCH_PAYMENT_HEADER': {
			const newState = { ...state };
			action.paymentHeaders.forEach(paymentHeader => newState[paymentHeader.No] = paymentHeader);
			return newState;
		}
		case 'DELETE_PAYMENT_HEADER': {
			const newState = { ...state };
			delete newState[action.paymentHeader.No];
			return newState;
		}
		default:
			return state;
	}
};

const ids = (state = [], action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_HEADERS': {
			return action.paymentHeaders.map(header => header.No);
		}
		case 'FETCH_PAYMENT_HEADER': {
			const { No } = action.paymentHeaders[0];
			const newState = [...state];
			if (!newState.includes(No)) newState.push(No);
			return newState;
		}
		case 'DELETE_PAYMENT_HEADER': {
			const newState = [...state];
			return newState.filter(id => id !== action.paymentHeader.No);
		}
		default:
			return state;
	}
};

const byCustomer = (state = [], action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_HEADERS': {
			const newState = { ...state };
			action.paymentHeaders.forEach(header => {
				const ids = newState[header.CusCode] || [];
				if (!ids.includes(header.No)) {
					ids.push(header.No);
					newState[header.CusCode] = ids;
				}
			});
			return newState;
		}
		case 'FETCH_PAYMENT_HEADER': {
			const newState = { ...state };
			const paymentHeader = action.paymentHeaders[0];
			const ids = newState[paymentHeader.CusCode] || [];
			if (!ids.includes(paymentHeader.No)) {
				ids.push(paymentHeader.No);
				newState[paymentHeader.CusCode] = ids;
			}
			return newState;
		}
		case 'DELETE_PAYMENT_HEADER': {
			const newState = { ...state };
			const ids = newState[action.paymentHeader.CusCode] || [];
			newState[action.paymentHeader.CusCode] = ids.filter(id => id !== action.paymentHeader.No)

			return newState;
		}
		default:
			return state;
	}
};

export const bySearchKey = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_HEADERS': {
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.paymentHeaders.map(header => header.No)
			};
		}
		case 'DELETE_PAYMENT_HEADER': {
			const newState = { ...state };
			Object.keys(newState).forEach(key => {
				const ids = newState[key];
				newState[key] = ids.filter(id => id !== action.paymentHeader.No)
			});
			return newState;
		}
		default:
			return state;
	}
};

export const total = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_HEADERS':
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.total
			};

		default:
			return state;
	}
}

export const metadata = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_HEADERS':
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.metadata
			};

		default:
			return state;
	}
}

export const fetching = (state = false, action) => {
	switch (action.type) {
		case 'START_FETCH_PAYMENT_HEADERS':
			return true;

		case 'FINISH_FETCH_PAYMENT_HEADERS':
			return false;

		default:
			return state;
	}
}

export const paymentTypesById = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_PAYMENT_TYPES':
			const paymentTypes = {};
			action.paymentTypes.forEach(type => paymentTypes[type.Code] = type);
			return paymentTypes;

		default:
			return state;
	}
}

const paymentHeaders = combineReducers({
	byId,
	ids,
	byCustomer,
	bySearchKey,
	total,
	metadata,
	fetching,
	paymentTypesById
});

export default paymentHeaders;

export const getAll = state => {
	return state.ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const get = (state, no) => state.byId[no];

export const getByCustomer = (state, customerId) => {
	const ids = state.byCustomer[customerId] || [];
	return ids.map(id => state.byId[id]).sort(sortDecByStartDate)
}

export const getCreditPaymentHeaders = (state, id) => {
	return state.ids.map(id => state.byId[id]).filter(filterCreditReceipts).sort(sortDecByStartDate);
}

export const getCreditPaymentHeadersByCustomer = (state, id) => {
	const ids = state.byCustomer[id] || [];
	return ids.map(id => state.byId[id]).filter(filterCreditReceipts).sort(sortDecByStartDate);
}

const filterCreditReceipts = receipt => {
	const credit = (parseFloat(receipt.PayAmount.replace(/,/g, "")) - parseFloat(receipt.AppliedAmount.replace(/,/g, ""))) > 0;
	const filtered = (receipt.OrderStatus === 'Open') || (receipt.OrderStatus === 'Customer Confirmed') || (receipt.OrderStatus === 'Released') || (receipt.OrderStatus === 'Posted');

	return credit && filtered;
}

const sortDecByStartDate = (s1, s2) => {
	return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
	const ids = state.bySearchKey[searchKey] || [];
	return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;

export const getPaymentTypes = state => Object.values(state.paymentTypesById);