import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_SALES_LINES = 'FETCH_SALES_LINES';
export const FETCH_SALES_LINE = 'FETCH_SALES_LINE';
export const FAILED_TO_ADD_SALES_LINE = 'FAILED_TO_ADD_SALES_LINE';
export const REMOVE_SALES_LINE = 'REMOVE_SALES_LINE';

export const addSalesLine = salesLine => dispatch => {
  return fromApi.add('SalesInvoiceLine', salesLine).then(response => {
    const { data, error } = response;

    if (error) {
      dispatch({ type: FAILED_TO_ADD_SALES_LINE, salesLine: { ...salesLine, added: false } });
      return { error }
    }

    dispatch({ type: FETCH_SALES_LINE, salesLine: { ...data, added: true } });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchSalesLines = filter => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {

    let invoices = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.INVOICES);

    invoices = invoices.data.filter(invoice => invoice.SellToCusNo === filter.SellToCusNo);

    let salesLines = [];

    invoices.forEach(invoice => {
      const lines = invoice.salesLines.filter(line => {
        if (filter.LotNo) {
          return line.LotNo === filter.LotNo
        }
        return true;
      });
      salesLines = [...salesLines, ...lines];
    })

    salesLines = salesLines.map(item => ({ ...item, added: true }))
    dispatch({ type: FETCH_SALES_LINES, salesLines: salesLines });

    return { data: salesLines, records: salesLines };

  } else {
    return fromApi.get('SalesInvoiceLine', filter).then(response => {
      const { data, error } = response;

      if (error) return { error };

      const salesLines = data.map(item => ({ ...item, added: true }))
      dispatch({ type: FETCH_SALES_LINES, salesLines: salesLines });

      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const updateSalesLine = salesLine => dispatch => {
  return fromApi.update('SalesInvoiceLine', salesLine).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: FETCH_SALES_LINE, salesLine: { ...data, added: true } });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteSalesLine = salesLine => dispatch => {
  return fromApi.deleteObject('SalesInvoiceLine', salesLine).then(response => {
    const { error } = response;

    if (error) return { error };

    dispatch({ type: REMOVE_SALES_LINE, salesLine });
    return response;
  }).catch(error => {
    return { error }
  })
};

// This function is use to validate quantity in sales return update
export const fetchInvoiceSalesLine = async (salesLine) => {
  try {
    let salesLines = [];

    if (salesLine.ReturnInvDocNo === "") return { data: [] }

    if (getOfflineData('state').isSystemOffline) {
      const invoiceDetails = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.INVOICES);

      salesLines = invoiceDetails.data.find(invoice => invoice.No === salesLine.ReturnInvDocNo).salesLines;

    } else {
      const { data } = await fromApi.get('SalesInvoiceLine', { DocNo: salesLine.ReturnInvDocNo });
      salesLines = data;
    }

    const filteredLine = salesLines.find(line => line.LotNo === salesLine.LotNo);

    return filteredLine;
  } catch (error) {
    return {}
  }
}