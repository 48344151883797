import * as fromApi from '../../api/generic';

let lastSearchKey;
export const fetchDistributorPaymentHeaders = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: 'START_FETCH_DISTRIBUTOR_PAYMENT_HEADERS' });

  return fromApi.get('DistributorPaymentsHeader', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS', distributorPaymentHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    console.log(error);
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: 'FINISH_FETCH_DISTRIBUTOR_PAYMENT_HEADERS' });
  });
};

export const addDistributorPaymentHeader = header => dispatch => {
  return fromApi.add('DistributorPaymentsHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_DISTRIBUTOR_PAYMENT_HEADER', distributorPaymentHeaders: [data] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateDistributorPaymentHeader = header => dispatch => {
  return fromApi.update('DistributorPaymentsHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_DISTRIBUTOR_PAYMENT_HEADER', distributorPaymentHeaders: [data] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteDistributorPaymentHeader = header => dispatch => {
  return fromApi.deleteObject('DistributorPaymentsHeader', header).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: 'DELETE_DISTRIBUTOR_PAYMENT_HEADER', distributorPaymentHeader: header });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchDistributorPaymentTypes = () => dispatch => {
  return fromApi.get('RefData', { RefName: 'CollectionTypes' }).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_DISTRIBUTOR_PAYMENT_TYPES', distributorPaymentTypes: data });
    return response;
  }).catch(error => {
    return { error }
  });
}