import * as fromApi from '../../api/networkDetector';

export const FETCH_SERVER_HEALTH_STATUS = 'FETCH_SERVER_HEALTH_STATUS';

export const fetchServerHealthStatus = () => dispatch => {
  return fromApi.check().then(response => {
    const { status } = response;

    dispatch({ type: FETCH_SERVER_HEALTH_STATUS, status });
    return response;
  }).catch(error => {
    return { error }
  })
};