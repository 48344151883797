import React, { Component } from 'react';
import { Button, Card, Tag, Divider, Col, Icon, Popconfirm, Row } from 'antd';

class Details extends Component {

  renderActions = () => {
    const { routeId, onSaveRoute, onConfirmDelete } = this.props;
    const newRouteId = routeId === "new";

    if (newRouteId) {
      return (<Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}><Col>
        {<Button type="primary" onClic={onSaveRoute}>Start Call</Button>}
        {/* <Button type="primary" onClick={this.onSaveCall}>Create</Button> */}
      </Col></Row>
      )
    } else {
      return (
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Col>
              {route.status == "ENDED" && <Popconfirm
                title="Are you sure ?"
                okText="Ok"
                cancelText="No"
                onConfirm={onConfirmDelete}
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>}
            </Col>
            <Col>
              {call.status == "STARTED" && <Popconfirm
                title="Are you sure ?"
                okText="Ok"
                cancelText="No"
                onConfirm={() => console.log("END")}
              >
                <Button type="danger">End Call</Button>
              </Popconfirm>}
            </Col> */}
          </div>
        </Row>)
    }
  }

  render() {
    const { route } = this.props;
    const status = route.Status ? route.Status : 'Active';
    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {route.RouteCode && <tr>
              <td style={{ width: 25 }}><Icon type="idcard" /></td>
              <td>ID</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{route.RouteCode}</td>
            </tr>}
            <tr>
              <td style={{ width: 25 }}><Icon type="info-circle" /></td>
              <td><p>Status</p></td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={getStatusColor(status)}>{status}</Tag></td>
            </tr>
          </Col>
        </Row>
      </div>
    )
  }
}

const getStatusColor = status => {
  if (status === "Active") return 'green'
  else return 'red'
}

export default Details;