import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { getAuthData, DOC_TYPE_ID_BY_DEFINITION } from "../../../redux/reducers";
import InvoiceLinesDetails from "./InvoiceLinesDetails";
import { fetchPurchasingInvoiceLines } from '../../../redux/actions/purchasingInvoiceLines';
import { addPurchaseLine } from '../../../redux/actions/purchaseLines';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';

import uuid from "uuid/v1";


class Container extends Component {
  state = {
    loading: false,
    invoiceLines: [],
    returnTotalQty: 0,
    returnTotalQtyError: undefined,
    selectedRowKeys: []
  };
  componentDidMount() {
    this.loadInvoiceLines(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.loadInvoiceLines(nextProps);
  }

  invoiceLineRequestSent = false;
  loadInvoiceLines = props => {
    if (this.invoiceLineRequestSent) return;
    const { location = {}, authData, order } = props;
    const invoiceNo = location && location.state && location.state.invoiceNo;

    invoiceNo && this.props.fetchPurchasingInvoiceLines({ DocType: 'Invoice', DocNo: invoiceNo }).then(response => {
      //console.log(response)
      if (!response.error) {
        this.setState({ invoiceLines: response.data })
      }
      this.setState({ loading: false })
    });
  }

  handleUpdateOrder = () => {
    const { invoiceLines } = this.state;
    const { orderId } = this.props.match.params;
    const { toastManager, authData, history, itemId, order, inventoryItem } = this.props;

    const invoiceLinesWithError = invoiceLines ? invoiceLines.filter(line => line.ReturnQty && line.ReturnQtyError && line.Selected) : [];
    if (invoiceLinesWithError.length > 0) return;

    let returnLines = [];
    returnLines = invoiceLines ? invoiceLines.filter(line => line.ReturnQty && !line.ReturnQtyError && line.Selected) : [];

    returnLines.forEach((line, index) => {
      this.setState({ loading: true });
      const lineObject =
      {
        ...line,
        LineSessionID: uuid(),
        Type: '2',
        Quantity: line.ReturnQty,
        DocNo: decodeURIComponent(orderId),
        UserCode: authData.userId,
        DocType: '5',
        ReturnInvDocNo: line.DocNo,
        ReturnInvLineNo: line.LineNo
      }

      this.props.addPurchaseLine(line.isDefaultLine ? line : lineObject)
        .then(({ error }) => {
          if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
          else {
            toastManager.add('Line added successfully.', { autoDismiss: true, appearance: 'success' });
          }
        }).finally(() => {
          if (index + 1 === returnLines.length) {
            history.goBack();
          }
        });
    });
    //this.handleRequest(this.props.addSalesLine, 'Line updated successfully.');
  }

  handleRemoveSalesLine = () => this.handleRequest(this.props.deleteSalesLine, 'Line removed successfully.');

  handleRequest = (requestCall, successMessage) => {
    const { authData, deleteSalesLine, history, toastManager } = this.props;
    this.setState({ loading: true });
    requestCall({ ...this.state.salesLine, UserCode: authData.userId }).then(({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = () => this.setState({ salesLine: { ...this.props.salesLine } });

  handleUpdateNumberField = (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    this.setState({ salesLine: { ...this.state.salesLine, [key]: value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  handleTableInputTextChange = (field, value, record, errorField) => {
    const { invoiceLines, returnTotalQty } = this.state;

    this.setState({ returnTotalQtyError: undefined })

    const updatedArray = invoiceLines.map(line => {
      const updateLine = line;
      if (record.DocNo === line.DocNo && record.LotNo === line.LotNo) {
        updateLine['ReturnQty'] = value;

        const { Quantity = '0', QuantityReturned = '0' } = line;
        const quantity = parseFloat(Quantity.replaceAll(',', ''));
        const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));

        if (quantity - quantityReturned < parseFloat(value)) {
          updateLine['ReturnQtyError'] = 'Invalid quantity'
        } else {
          updateLine['ReturnQtyError'] = undefined
        }
      }
      return updateLine;
    })

    this.setState({ invoiceLines: updatedArray })
  }


  handleSelectRowChange = selectedRowKeys => {
    const { invoiceLines } = this.state;

    const updatedArray = invoiceLines.map((line, index) => {
      const updateLine = line;
      const filteredIndexArray = selectedRowKeys.filter(i => i === line.LineSessionID + "_" + line.LotNo + '_' + line.DocNo);
      if (filteredIndexArray && filteredIndexArray.length > 0) {
        const { Quantity = '0', QuantityReturned = '0' } = line;
        const quantity = parseFloat(Quantity.replaceAll(',', ''));
        const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));

        updateLine['Selected'] = true
        updateLine['ReturnQty'] = line.ReturnQty ? line.ReturnQty : (quantity - quantityReturned);
      } else {
        updateLine['Selected'] = false
        updateLine['ReturnQty'] = undefined
      }

      return updateLine;
    })

    this.setState({ invoiceLines: updatedArray, selectedRowKeys: selectedRowKeys })
  }



  getTotalLineReturnQuantity = (lines) => {
    let totalReturnFromLines = 0
    const updatedArray = lines ? lines : []
    for (var i = 0; i < updatedArray.length; i++) {
      if (!updatedArray[i].ReturnQtyError && updatedArray[i].ReturnQty && updatedArray[i].Selected) {
        totalReturnFromLines = totalReturnFromLines + parseInt(updatedArray[i].ReturnQty)
      }
    }

    return totalReturnFromLines;
  }

  render() {
    const { location = {}, match, resource, order, inventoryItem } = this.props;
    const { state = {} } = location
    const { loading, invoiceLines, returnTotalQty, returnTotalQtyError, selectedRowKeys } = this.state;
    const { docType } = this.props.match.params;

    const invoiceLinesToShow = invoiceLines && invoiceLines.filter(line => {
      const { Quantity = '0', QuantityReturned = '0' } = line;
      const quantity = parseFloat(Quantity.replaceAll(',', ''));
      const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));
      return quantity - quantityReturned > 0;
    })

    return (
      <Spin spinning={loading} tip={"Loading item"}>
        {
          <InvoiceLinesDetails
            onUpdateNumberField={this.handleUpdateNumberField}
            onUpdateOrder={this.handleUpdateOrder}
            resource={resource}
            handleTableInputTextChange={this.handleTableInputTextChange}
            order={order}
            newOrderItem={match && match.params.lineId === 'new'}
            docType={docType}
            invoiceLines={invoiceLinesToShow}
            onSelectRowChange={this.handleSelectRowChange}
            selectedRowKeys={selectedRowKeys}
          />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location = {} } = ownProps
  //const { state = {} } = location
  //const invoiceLines = getPurchaseInvoiceLineMapByDocTypeAndDocNo(state, 'Invoice', itemId);
  return { authData: getAuthData(state) };
};



export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchPurchasingInvoiceLines,
        addPurchaseLine
        //updateSalesLine,
        //deleteSalesLine
      }
    )(Container)
  )
);