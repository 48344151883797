import { Table, Tag, Button } from "antd";
import moment from 'moment';
import React, { Component } from 'react';
import CurrencyFormat from "react-currency-format";
import { dateFormatView } from "../../../const/Formats";
import TableFooter from "../../common/TableFooter";
import AdvanceSearchFilters from "../../../components/common/AdvanceSearchFilters";
import InvoiceCard from "../../SalesInvoices/InvoiceCard";
import InvoiceCardHeader from "../../SalesInvoices/InvoiceCardHeader";

class InvoicesTable extends Component {
    getScrollAreaHeight = () => {
        const networkHeader = document.getElementsByClassName('server-connection-lost');
        const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

        const promateTableHeader = document.getElementsByClassName('promate-table-header');
        const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

        const promatePageHeader = document.getElementsByClassName('promate-page-header');
        const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

        const tableFooter = document.getElementsByClassName('ant-table-footer');
        const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

        return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 70;
    }

    getTableHeight = customers => {
        if (customers.length > 0) {
            return this.getScrollAreaHeight();
        } else {
            return this.getScrollAreaHeight() - 167;
        }
    }

    renderCustomerStatus = (CustStatus) => {
        const customerStatus = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;

        return (
            <Tag style={styles.status} color={this.getCustomerStatusColor(customerStatus)}>{customerStatus}</Tag>
        );
    }

    getCustomerStatusColor = status => {
        switch (status) {
            case 'Active':
                return 'green';
            default:
                return 'orange';
        }
    }

    columns = [
        {
            title: 'Invoice No',
            dataIndex: 'No',
            key: 'no',
            align: 'left',
            width: 150,
            render: text => <span style={{ ...styles.orderNo, width: 200 }}>{text}</span>,
        },
        {
            title: 'Invoice Date',
            dataIndex: 'PostingDate',
            key: 'PostingDate',
            width: 120,
            align: 'center',
            render: date => <span>{moment(date).format(dateFormatView)}</span>
        },
        {
            title: 'Order Type',
            dataIndex: 'SOType',
            key: 'SOType',
            align: 'center',
            width: 120,
        },
        {
            title: 'Payment method',
            dataIndex: 'PayMethodCode',
            key: 'PayMethodCode',
            align: 'center',
            width: 120,
        },
        {
            title: 'Customer Name',
            dataIndex: 'SellToCusName',
            key: 'SellToCusName',
            align: 'center',
            width: 150,
        },
        {
            title: 'Category',
            dataIndex: 'OutletCategoryCode',
            key: 'OutletCategoryCode',
            align: 'center',
            width: 150,
        },
        {
            title: 'Customer Status',
            dataIndex: 'CustStatus',
            key: 'CustStatus',
            align: 'center',
            width: 120,
            render: text => this.renderCustomerStatus(text)
        },
        {
            title: 'Sales Person',
            dataIndex: 'CSRUserName',
            key: 'CSRUserName',
            align: 'center',
            width: 150,
        },
        {
            title: 'Invoice Age',
            dataIndex: 'InvAge',
            key: 'InvAge',
            align: 'center',
            width: 150,
        },
        {
            title: 'Discount Reason',
            dataIndex: 'DiscountReasonCode',
            key: 'DiscountReasonCode',
            align: 'center',
            width: 150,
        },
        {
            title: 'Original Amount',
            dataIndex: 'OrgAmount',
            key: 'OrgAmount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text => <div style={{ width: 100 }}>
                <CurrencyFormat
                    value={text || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    isNumericString={true}
                    fixedDecimalScale={true}
                    style={styles.price}
                    decimalScale={2} />
            </div>
        },
        {
            title: 'Discount Value',
            dataIndex: 'DisAmount',
            key: 'DisAmount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text => <div style={{ width: 100 }}><CurrencyFormat
                value={text || 0}
                displayType={'text'}
                thousandSeparator={true}
                isNumericString={true}
                fixedDecimalScale={true}
                style={styles.price}
                decimalScale={2} />
            </div>
        },
        {
            title: 'Discount %',
            dataIndex: 'AvgDisPersentage',
            key: 'AvgDisPersentage',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text =>
                <div style={{ width: 100 }}> <CurrencyFormat
                    value={text || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    isNumericString={true}
                    fixedDecimalScale={true}
                    style={styles.price}
                    decimalScale={2} />
                </div>
        },
        {
            title: 'Invoiced Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text =>
                <div style={{ width: 100 }}> <CurrencyFormat
                    value={text || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    isNumericString={true}
                    fixedDecimalScale={true}
                    style={styles.price}
                    decimalScale={2} />
                </div>
        },
        {
            title: 'Settled Amount',
            dataIndex: 'SettledAmount',
            key: 'SettledAmount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text =>
                <div style={{ width: 100 }}><CurrencyFormat
                    value={text || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    isNumericString={true}
                    fixedDecimalScale={true}
                    style={styles.price}
                    decimalScale={2} />
                </div>
        },
        {
            title: 'Remaining Outstanding',
            dataIndex: 'RemAmount',
            key: 'RemAmount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text =>
                <div style={{ width: 100 }}>
                    <CurrencyFormat
                        value={text || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        isNumericString={true}
                        fixedDecimalScale={true}
                        style={styles.price}
                        decimalScale={2} />
                </div>
        },
        {
            title: 'PDC Balance',
            dataIndex: 'PDCAmount',
            key: 'PDCAmount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text =>
                <div style={{ width: 100 }}>
                    <CurrencyFormat
                        value={text || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        isNumericString={true}
                        fixedDecimalScale={true}
                        style={styles.price}
                        decimalScale={2} />
                </div>
        },
        {
            title: 'Net Outstanding',
            dataIndex: 'NetRemAmount',
            key: 'NetRemAmount',
            align: 'right',
            fixed: 'right',
            width: 116,
            render: text =>
                <div style={{ width: 100 }}><CurrencyFormat
                    value={text || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    isNumericString={true}
                    fixedDecimalScale={true}
                    style={styles.price}
                    decimalScale={2} />
                </div>,
            footer: (data) => {
                return <div>Summary</div>
            },

        }
    ];

    renderFooter = () => {
        const { total, currentPage, onPaginationChange, handleAddingInvoice, invoiceAdding } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <TableFooter
                    noOfRecords={total}
                    currentPage={currentPage}
                    onPaginationChange={onPaginationChange}
                    style={{ width: '100%' }}
                />
                <Button type="primary" loading={invoiceAdding} onClick={handleAddingInvoice} style={{ width: 100, margin: '10px 0' }}>Done</Button>
            </div>

        );
    }

    renderHeader = () => {
        const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, setDefaultCustomerOption, setDefaultRouteOption } = this.props;
        const customerId = null

        const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
        const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };

        return (
            <div className="promate-table-header">
                <AdvanceSearchFilters
                    SALES_HIERARCHY={entireColumn}
                    FROM_DATE_PICKER={customerId ? oneSixthOfColumn : oneSixthOfColumn}
                    TO_DATE_PICKER={customerId ? oneSixthOfColumn : oneSixthOfColumn}
                    SEARCH_TEXT={{ ...(customerId ? oneSixthOfColumn : oneSixthOfColumn), placeholder: 'Invoice No' }}
                    SELECT_ORDER_TYPE={customerId ? oneSixthOfColumn : oneSixthOfColumn}
                    SELECT_DISCOUNT_REASON={customerId ? oneSixthOfColumn : oneSixthOfColumn}
                    SEARCH_BUTTON={customerId ? oneSixthOfColumn : oneSixthOfColumn}
                    handleInputChange={handleInputTextChange}
                    handleSearchClick={handleSearchClick}
                    handleInputDateChange={handleInputDateChange}
                    setDefaultCustomerOption={setDefaultCustomerOption}
                    setDefaultRouteOption={setDefaultRouteOption}
                    filter={filter}
                    statusOptions={['Confirmed']}
                />
            </div>
        )
    }


    renderColumns = () => {
        return [{
            render: order => order === 0 ? <InvoiceCardHeader style={{ backgroundColor: '#eceaea' }} /> : (
                order === 1 ? this.renderFooter() : <InvoiceCard order={order} clickable={false} />
            )
        }]
    }

    render() {
        const { orders, handleRowSelect } = this.props;

        if (window.innerWidth < 768) {
            return (
                <Table
                    columns={this.renderColumns()}
                    rowKey={'orderNo'}
                    rowClassName={'orderRow'}
                    size={'small'}
                    bodyStyle={{ minHeight: this.getTableHeight(orders), overflowY: 'scroll' }}
                    pagination={false}
                    showHeader={false}
                    title={() => this.renderHeader()}
                    dataSource={orders}
                    footer={() => this.renderFooter()}
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys, selectedRows) => {
                            handleRowSelect(selectedRowKeys, selectedRows);
                        }
                    }}
                />
            )
        }

        return (
            <Table
                rowKey={'orderNo'}
                rowClassName={'table-row-dark'}
                dataSource={orders}
                columns={this.columns}
                size={'small'}
                scroll={{ y: this.getScrollAreaHeight(), x: 2050 }}
                bodyStyle={{ minHeight: this.getTableHeight([]), overflowY: 'scroll' }}
                pagination={false}
                title={() => this.renderHeader()}
                summary={() => {
                    return (<div>apple</div>)
                }}
                footer={() => this.renderFooter()}
                rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys, selectedRows) => {
                        handleRowSelect(selectedRowKeys, selectedRows);
                    }
                }}

            />
        );
    }
}


const styles = {
    orderNo: {
        fontSize: 16,
        fontWeight: 600,
        wordBreak: 'break-all'
    },
    orderRow: {
        backgroundColor: 'black'
    },
    alignRow: {
        display: 'flex',
        alignItems: 'center',
    },
    orderDate: {
        fontSize: 12,
        fontWeight: 700
    },
    orderDateTitle: {
        fontSize: 12
    },
    customerCode: {
        fontSize: 10,
        fontWeight: 700,
    },
    customerCodeBottom: {
        fontSize: 10,
        fontWeight: 700,
    },
    status: {
        fontWeight: 700,
        marginRight: 0
    },
    price: {
        fontSize: 14,
        fontWeight: 600,
        width: '100%'
    },
    icon: {
        fontSize: 10,
        marginRight: 5
    },
    lowerRow: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 2,
        marginRight: 10,
        marginLeft: 5
    },
    bottomColumnStyles: {
        textAlign: 'left',
        color: '#808080',
        fontSize: 12
    }

}


export default InvoicesTable;