import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

class CollectionHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Unverified':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  renderActions = customerSigned => {
    const { newCollectionId, onSaveCollection, onConfirmCollection, onDeleteCollection } = this.props;

    if (newCollectionId === "new") {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        <Button type="primary" onClick={onSaveCollection}>Create</Button>
      </Col></Row>
    } else {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!customerSigned && <div style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={onDeleteCollection}
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Col>
          <Col>
            {/* <Popconfirm
            title="Are you sure ?"
            okText="Update"
            cancelText="No"
            onConfirm={onSaveCollection}
          >
            <Button type="primary">Update</Button>
          </Popconfirm> */}
            <Button type="primary" onClick={onSaveCollection}>Update</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onConfirmCollection}>Confirm</Button>
          </Col></div>}
        {customerSigned && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}>Print & Share</Button>
        </Col>}
      </Row>
    }
  }

  render() {
    const { newCollectionId, collection = {} } = this.props;
    const { collectionDate, sellToCustomerCode, sellToCustomerName, status } = collection;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {newCollectionId && <tr>
              <td>Collection #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{newCollectionId}</td>
            </tr>}
            {collectionDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(collectionDate).format(dateFormatView)}</td>
            </tr>}
            {status && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={this.getStatusColor(status)}>{status}</Tag></td>
            </tr>}
          </Col>
          {(sellToCustomerCode || sellToCustomerName) && <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>}
          <Col lg={8} md={8} sm={24} xs={24}>
            {sellToCustomerCode && <tr>
              <td>Customer #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerCode}</td>
            </tr>}
            {sellToCustomerName && <tr>
              <td>Customer Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerName}</td>
            </tr>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default CollectionHeader;