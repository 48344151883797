import React, { Component } from "react";
import { Col, Row, Tag } from "antd";

class OrderCardHeader extends Component {
	render() {

		return (

			<Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 30, backgroundColor: '#eceaea', padding: 10, paddingRight: window.innerWidth < 768 ? 10 : 24 }}>
				<Col xs={0} sm={5} md={5} lg={5} style={{ textAlign: 'left' }}>Code</Col>
				<Col xs={0} sm={0} md={0} lg={5} style={{ textAlign: 'left' }}>Location</Col>
				<Col xs={0} sm={9} md={9} lg={7} style={{ textAlign: 'left' }}>Description</Col>
				<Col xs={0} sm={3} md={3} lg={2} style={{ textAlign: 'right' }}>Uom</Col>
				<Col xs={0} sm={3} md={3} lg={2} style={{ textAlign: 'right' }}>Quantity</Col>
				<Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'right' }}>Unit Price</Col>

				<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Code</Col>
				<Col xs={16} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Description</Col>
			</Row>

		)
	}
}

const styles = {
	orderNo: {
		fontSize: 16,
		fontWeight: 600
	},
	alignRow: {
		display: 'flex',
		alignItems: 'center'
	},
	orderDate: {
		fontSize: 12,
		fontWeight: 700
	},
	customerCode: {
		fontSize: 10,
		fontWeight: 700,
	},
	customerCodeBottom: {
		fontSize: 10,
		fontWeight: 700,
	},
	status: {
		fontSize: 10,
		fontWeight: 700,
		marginRight: 0
	},
	price: {
		fontSize: 18,
		fontWeight: 600,
	},
	icon: {
		fontSize: 10,
		marginRight: 5
	},
	lowerRow: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 5,
		marginTop: 2
	},
	bottomColumnStyles: {
		textAlign: 'left',
		color: '#808080',
		fontSize: 12
	}

}
export default OrderCardHeader;