import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

const { Option } = Select;

class ReceiptHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Reversed':
        return 'red';
      default:
        return 'orange';
    }
  }

  renderActions = customerSigned => {
    const { newReceiptId, onSaveReceipt, onConfirmReceipt, onDeleteReceipt } = this.props;

    if (newReceiptId === "new") {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Button type="primary" onClick={onSaveReceipt}>Create</Button>
      </Col></Row>
    } else {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!customerSigned && <div style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={onDeleteReceipt}
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Col>
          <Col>
            <Button type="primary" onClick={onSaveReceipt}>Update</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onConfirmReceipt}>Confirm</Button>
          </Col></div>}
        {customerSigned && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}>Print & Share</Button>
        </Col>}
      </Row>
    }
  }

  render() {
    const { newReceiptId, onSelectReceiptStatusChange, receipt = {} } = this.props;
    const { DocDate, CusCode, CusName, OrderStatus, CreatedUserName, Status } = receipt;

    return (
      <div style={{ margin: 10, marginBottom: -5 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {newReceiptId && <tr>
              <td style={{ width: 120 }}>Receipt #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{newReceiptId}</td>
            </tr>}
            {DocDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(DocDate).format(dateFormatView)}</td>
            </tr>}
            {<tr>
              <td>Status</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <Select style={{ width: 200, textAlign: 'center' }} value={OrderStatus} onChange={onSelectReceiptStatusChange} >
                  {receiptStatuses.map(status => <Option key={status.key} value={status.value}>{status.name}</Option>)}
                </Select>
              </td>
              {/* <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td> */}
              {/* <td><Tag color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag></td> */}
            </tr>}
            {CreatedUserName && <tr>
              <td>Created User</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{CreatedUserName}</td>
            </tr>}
          </Col>
          {(CusCode || CusName) && <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>}
          <Col lg={8} md={8} sm={24} xs={24}>
            {CusCode && <tr>
              <td style={{ width: 120 }}>Customer #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{CusCode}</td>
            </tr>}
            {CusName && <tr>
              <td>Customer Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{CusName}</td>
            </tr>}
          </Col>
        </Row>
      </div>
    )
  }
}

export default ReceiptHeader;

const receiptStatuses = [
  {
    name: 'Open',
    value: 'Open',
    key: '1'
  },
  {
    name: 'Customer Confirmed',
    value: 'Customer Confirmed',
    key: '2'
  },
  {
    name: 'On Hold',
    value: 'On Hold',
    key: '3'
  },
  {
    name: 'Rejected',
    value: 'Rejected',
    key: '4'
  },
  {
    name: 'Released',
    value: 'Released',
    key: '5'
  },
  {
    name: 'Posted',
    value: 'Posted',
    key: '6'
  },
  {
    name: 'Reversed',
    value: 'Reversed',
    key: '7'
  },
  {
    name: 'Returned',
    value: 'Returned',
    key: '8'
  }
]