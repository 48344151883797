import * as fromApi from '../../api/generic';

export const FETCH_ATTACHMENT = 'FETCH_ATTACHMENT';

export const addAttachment = (type, attachment) => dispatch => {
  return fromApi.add(type, attachment).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: FETCH_ATTACHMENT, attachment: { ...attachment, ...data[0] } })
    return response;
  }).catch(error => {
    return { error }
  });
}

export const fetchAttachment = (type, filter) => dispatch => {
  return fromApi.get(type, filter).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: FETCH_ATTACHMENT, attachment: { ...data.records[0], ...filter } });
    return response;
  }).catch(error => {
    return { error }
  });
}