import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withToastManager } from 'react-toast-notifications';
import { fetchFailedSalesHeadersFromLocalAPI } from '../../../../redux/actions/failedOrdedrs';
import { getAllFailedHeaders } from "../../../../redux/reducers";
import OrderCard from './OrderCard';

class DataTable extends Component {
    state = {
        pagination: {
            pageSize: 20
        }
    };

    componentDidMount() {
        const { fetchFailedSalesHeadersFromLocalAPI, filter: { from, to } } = this.props;
        fetchFailedSalesHeadersFromLocalAPI({ from: from.format('MM/DD/YY'), to: to.format('MM/DD/YY') });
    }

    getScrollAreaHeight = () => {
        const header = document.getElementsByClassName('ant-page-header');
        const tableFooter = document.getElementsByClassName('ant-table-footer');
        const headerHeight = header && header[0] && header[0].clientHeight;
        const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;
        return window.innerHeight - (headerHeight + tableFooterHeight);
    }

    getTableHeight = orders => {
        if (orders.length > 0) {
            return this.getScrollAreaHeight() - 5;
        } else {
            return this.getScrollAreaHeight() - 167;
        }
    }

    renderColumns = () => {
        return [{
            render: order => <OrderCard order={order} />
        }]
    }

    render() {
        const { headers: { isLoading, list } } = this.props;
        return (
            <div style={{ padding: '0 10px' }}>
                <Row>
                    <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea' }}>
                        <Col xs={6} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>Order No</Col>
                        <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Order Type</Col>
                        <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>Status</Col>
                        <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Order Date</Col>
                        <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Customer Code</Col>
                        <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Customer Name</Col>
                        <Col xs={8} sm={5} md={4} lg={3} style={{ textAlign: 'left', marginLeft: 10 }}>Customer Category</Col>
                        <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>Sales Person</Col>
                        <Col xs={0} sm={5} md={3} lg={2} style={{ textAlign: 'left' }}>Discount Reason</Col>
                        <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>
                    </Row>
                </Row>
                <Table
                    size='small'
                    columns={this.renderColumns()}
                    showHeader={false}
                    rowKey={record => record.RouteCode}
                    dataSource={list}
                    pagination={this.state.pagination}
                    loading={isLoading}
                    style={{ padding: 0 }}
                    bodyStyle={{ minHeight: this.getTableHeight(0), padding: 0 }}
                    scroll={{ y: this.getScrollAreaHeight() }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    headers: getAllFailedHeaders(state)
})

const mapDispatchToProps = {
    fetchFailedSalesHeadersFromLocalAPI
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(DataTable));

const styles = {
    orderNo: {
        fontSize: 16,
        fontWeight: 600
    },
    alignRow: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 35,
        paddingLeft: 10
    },
    orderDate: {
        fontSize: 12,
        fontWeight: 700
    },
    customerCode: {
        fontSize: 10,
        fontWeight: 700,
    },
    customerCodeBottom: {
        fontSize: 10,
        fontWeight: 700,
    },
    status: {
        fontSize: 10,
        fontWeight: 700,
        marginRight: 0
    },
    price: {
        fontSize: 18,
        fontWeight: 600,
    },
    icon: {
        fontSize: 10,
        marginRight: 5
    },
    lowerRow: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 2
    },
    bottomColumnStyles: {
        textAlign: 'left',
        color: '#808080',
        fontSize: 12
    },
    input: {
        // position: 'absolute',
        // right: 10,
        // top: -10,
        height: 20,
        width: 20,
    }

}
