import React, { Component } from 'react';
import { Col, Divider } from "antd";
import FormItem from './FormItem';

export default class FormSectionTitle extends Component {

    render() {
        const { label } = this.props;

        return (
            // <Col style={{ marginBottom: -10 }}>
            //   {/* <FormItem label={<p style={{ fontWeight: 600, padding: 0, marginTop: 10, marginBottom: 0, lineHeight: 0, fontSize: 16 }}>{label}</p>}></FormItem>
            //   <div className='promate-form-divider'><Divider /></div> */}

            // </Col>
            <Divider orientation='left'>{label}</Divider>
        )
    }
}