import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchInvoicesByCustomer, fetchInvoices } from "../../redux/actions/invoices";
import { getInvoices, getInvoicesByCustomer } from "../../redux/reducers";
import { PageHeader, Spin } from "antd";
import moment from 'moment';
import { ProMateGreen } from "../../const/Theme";
import InvoicesTable from "./InvoicesTable";
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';

class Invoices extends Component {
  state = {
    loading: false,
    filter: {
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    const { customerId } = this.props.match.params

    if (customerId) {
      this.setState({ filter: { ...this.state.filter, customerId } }, () => this.fetchAllByCustomer())
    } else {
      this.fetchAllByDateRange();
    }
  }

  handleSearchClick = () => {
    const { customerId } = this.props.match.params

    if (customerId) {
      this.fetchAllByCustomer()
    } else {
      this.fetchAllByDateRange();
    }
  }

  fetchAllByDateRange = () => {
    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchInvoices(this.state.filter).then(() => {
      this.setState({ loading: false })
      this.hideLoadingMessage();
    })
  }

  fetchAllByCustomer = () => {
    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchInvoicesByCustomer(this.state.filter).then(() => {
      this.setState({ loading: false })
      this.hideLoadingMessage();
    })
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing invoices.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  render() {
    const { invoices } = this.props;
    const { loading, filter } = this.state;
    return (
      <div>
        <PromatePageHeader
          title={'Invoices'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Spin spinning={loading && invoices.length === 0} tip={"Loading Invoices"}>
            <InvoicesTable
              invoices={invoices}
              handleInputDateChange={this.handleInputDateChange}
              handleSearchClick={this.handleSearchClick}
              filter={filter}
              handleInputTextChange={this.handleInputTextChange} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params

  const invoices = customerId ? getInvoicesByCustomer(state, customerId) : getInvoices(state);

  return { invoices: sortListByOrderDate(invoices) };
};

const sortListByOrderDate = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (invoice1, invoice2) {
    let invoice1_orderDate = parseInt(invoice1.orderDate);
    let invoice2_orderDate = parseInt(invoice2.orderDate);

    if (invoice1_orderDate < invoice2_orderDate) return 1;
    if (invoice1_orderDate > invoice2_orderDate) return -1
    return 0
  }) : []
  return sortedList;
}


export default connect(mapStateToProps, { fetchInvoices, fetchInvoicesByCustomer })(Invoices);