import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ADD_CUSTOMER_ROUTE_TARGET, ROUTE_CUSTOMER_TARGET } from '../../../const/Permissions';
import { ProMateGreen } from "../../../const/Theme";
import { updateCustomerVisitTarget } from "../../../redux/actions/targetScheduler";
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { getAllCustomerVisitTargets, getAuthData, getSalesUserHierarchy } from "../../../redux/reducers";
import PromatePageHeader from '../../common/PromatePageHeader';
import { AddButton } from '../common/Buttons';
import DataTable from './components/DataTable';
import Header from './components/Header';

export class RouteCustomerTarget extends Component {
  _isMounted = false;

  state = {
    loading: true,
    selectedRowKeys: []
  };

  componentDidMount = () => {
    const { authData, history } = this.props;
    this._isMounted = true;
    if (!authData.permissions.includes(ROUTE_CUSTOMER_TARGET.VIEW)) {
      history.push('/targetScheduler');
      return;
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  setLoading = (value) => {
    if (this._isMounted) {
      this.setState({
        loading: value,
      });
    }
  }

  setSelectedRowKeys = (selectedRowKeys) => {
    if (this._isMounted) {
      this.setState({
        selectedRowKeys
      });
    }
  }

  handleAllUpdate = async (status) => {
    this.setLoading(true);
    const { toastManager, updateCustomerVisitTarget, customerVisitTargets } = this.props;
    const list = customerVisitTargets.records.filter((record => this.state.selectedRowKeys.includes(record.CustCode)));
    if (list.length > 0) {
      for (const item of list) {
        await updateCustomerVisitTarget({ ...item, Approved: status });
      }
      toastManager.add("Selected Route targets were updated.", { autoDismiss: true, appearance: 'success' });
    } else {
      toastManager.add("No Route customer targets are select.", { autoDismiss: true, appearance: 'info' });
    }
    this.setLoading(false);
  }

  render() {
    const { authData } = this.props;
    return (
      <PromatePageHeader
        title="Route Customer Target"
        style={styles.pageHeader}
        onBack={() => this.props.history.goBack()}
        showCallButton={false}
        extra={authData?.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.CREATE) && !this.state.loading && <AddButton label="Add Route Customer Target" route="/targetScheduler/routeCustomerTarget/add" />}
      >
        <Header
          loading={this.state.loading}
          setLoading={this.setLoading}
          permissions={authData?.permissions ?? []}
          handleAllUpdate={this.handleAllUpdate}
        />
        <DataTable
          permissions={authData?.permissions ?? []}
          setLoading={this.setLoading}
          loading={this.state.loading}
          selectedRowKeys={this.state.selectedRowKeys}
          setSelectedRowKeys={this.setSelectedRowKeys}
        />
      </PromatePageHeader>
    )
  }
}

const mapStateToProps = (state) => ({
  salesUserHierarchy: getSalesUserHierarchy(state),
  authData: getAuthData(state),
  customerVisitTargets: getAllCustomerVisitTargets(state),
})

const mapDispatchToProps = {
  fetchSalesUserHierarchy,
  updateCustomerVisitTarget
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteCustomerTarget)));

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  text: {
    height: "100%",
    padding: "5px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}