import React, { Component } from 'react';
import { Table } from "antd";
import { connect } from "react-redux";
import { getPurchaseInvoiceLinesByDocTypeAndDocNo, getLoggedInUser } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import { PURCHASING_INVOICES } from "../../../const/Permissions";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTableMobile extends Component {
  getColumns = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderLeftColumn(item)
      },
      {
        title: '',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '40%',
        render: (Description, item) => this.renderRightColumn(item)
      }
    ];

  renderLeftColumn = item => {
    return (
      <div>
        <div>{item.LotNo}</div>
        <div>{item.No}</div>
        <div>{item.Description}</div>
      </div>
    )
  }

  renderRightColumn = item => {
    return (
      <div>
        <div>{item.Quantity}</div>
        <div>{item.InvoicedQty}</div>
        <div>{format(item.UnitPrice, currencyFormat)}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 7 }}>{format(item.DisPercentage, currencyFormat)}%</div>
          <div style={{ fontWeight: 'bold' }}>{format(item.DisAmount, currencyFormat)}</div>
        </div>

        <div style={{ fontWeight: 'bold' }}>{format(item.LineAmount, currencyFormat)}</div>
      </div>
    )
  }

  renderFooter = () => {
    const { purchasingLines = [], loggedInUser: { permissions = [] } } = this.props;
    const enableUpdate = permissions.includes(PURCHASING_INVOICES.UPDATE) || permissions.includes(PURCHASING_INVOICES.UPDATE);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {purchasingLines.length}</p>
      </div>
    )
  }

  render() {
    const { height = 100, history, purchasingLines = [], match, loggedInUser: { permissions = [] } } = this.props;
    const { invoiceNo } = match.params;
    const decodeId = decodeURIComponent(invoiceNo);
    const enableUpdate = permissions.includes(PURCHASING_INVOICES.UPDATE) || permissions.includes(PURCHASING_INVOICES.UPDATE);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={purchasingLines}
          size={'small'}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { invoiceNo } = ownProps.match.params;
  const decodeId = decodeURIComponent(invoiceNo);
  return {
    purchasingLines: getPurchaseInvoiceLinesByDocTypeAndDocNo(state, '', decodeId),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTableMobile));