import { combineReducers } from 'redux';
import { FETCH_STOCKTAKE_LINE, FETCH_STOCKTAKE_LINES, REMOVE_STOCKTAKE_LINE, START_FETCH_STOCKTAKE_LINES, FINISH_FETCH_STOCKTAKE_LINES } from '../actions/stockTakeLines';

const getKey = line => {
	const { DocNo, LineNo } = line;
	return DocNo + '-' + LineNo;
}

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_STOCKTAKE_LINES:
			const newState = {};
			action.stockTakeLines.forEach(stockTakeLine => newState[getKey(stockTakeLine)] = stockTakeLine);
			return newState;
		case FETCH_STOCKTAKE_LINE: {
			const newState = { ...state };
			newState[getKey(action.stockTakeLine)] = action.stockTakeLine;
			return newState;
		}
		case REMOVE_STOCKTAKE_LINE: {
			const newState = { ...state };
			delete newState[getKey(action.stockTakeLine)];
			return newState;
		}
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_STOCKTAKE_LINES: {
			const newState = [...state]
			action.stockTakeLines.forEach(stockTakeLine => {
				const key = getKey(stockTakeLine);
				if (newState.includes(key)) newState.push(key);
			});

			return newState;
		}
		case FETCH_STOCKTAKE_LINE: {
			const newState = [...state];
			const key = getKey(action.stockTakeLine);

			if (!newState.includes(key)) {
				newState.push(key)
			}
			return newState;
		}
		case REMOVE_STOCKTAKE_LINE: {
			const newState = [...state];
			const key = getKey(action.stockTakeLine);
			return newState.filter(id => id !== key);
		}
		default:
			return state;
	}
};

export const byDocNo = (state = {}, action) => {
	switch (action.type) {
		case FETCH_STOCKTAKE_LINES: {
			const newState = {};
			action.stockTakeLines.forEach(stockTakeLine => {
				const key = stockTakeLine.DocNo
				const idsByDocNo = newState[key] || [];

				const id = getKey(stockTakeLine);
				if (!idsByDocNo.includes(id)) {
					idsByDocNo.push(id);
					newState[key] = idsByDocNo;
				}
			});
			return newState;
		}
		case FETCH_STOCKTAKE_LINE: {
			const newState = { ...state };
			const key = action.stockTakeLine.DocNo
			const idsByDocNo = newState[key] || [];

			const id = getKey(action.stockTakeLine);

			if (!idsByDocNo.includes(id)) {
				idsByDocNo.push(id);
				newState[key] = idsByDocNo;
			}

			return newState;
		}
		case REMOVE_STOCKTAKE_LINE: {
			const newState = { ...state };
			const key = action.stockTakeLine.DocNo
			const idsByDocNo = newState[key] || [];

			const removeId = getKey(action.stockTakeLine);

			newState[key] = idsByDocNo.filter(id => id !== removeId);

			return newState;
		}
		default:
			return state;
	}
};

export const fetching = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_STOCKTAKE_LINES:
			return true;

		case FINISH_FETCH_STOCKTAKE_LINES:
			return false;

		default:
			return state;
	}
}


const stockTakeLines = combineReducers({
	byId,
	ids,
	byDocNo,
	fetching
});

export default stockTakeLines;

export const getStockTakeLinesByDocNo = (state, no) => {
	const key = no;
	const ids = state.byDocNo[key] || [];

	return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

export const getStockTakeLineMapByDocNo = (state, no) => {
	const key = no;
	const ids = state.byDocNo[key] || [];

	const lineMap = {};
	ids && ids.length > 0 && ids.forEach(id => {
		const { ItemNo, LotNo, LocCode } = state.byId[id];
		const key = ItemNo + '-' + LotNo + '-' + LocCode;

		lineMap[key] = state.byId[id];
	});

	return lineMap;
}

export const getStockTakeLine = (state, docNo, lineNo) => {
	return state.byId[docNo + '-' + lineNo];
}

export const isFetching = state => state.fetching;