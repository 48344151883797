import { Row, Spin } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { CUSTOMER_FREQUENCY, ROUTE_CUSTOMER_TARGET, ROUTE_TARGET } from "../../const/Permissions";
import { ProMateGreen } from "../../const/Theme";
import { fetchSalesUserHierarchy } from '../../redux/actions/users';
import { getAuthData, getSalesUserHierarchy } from "../../redux/reducers";
import PromatePageHeader from '../common/PromatePageHeader';
import Header from './components/Header';
import SheduleTile from "./components/SheduleTile";

export class TargetScheduler extends Component {
    _isMounted = false;

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        const { fetchSalesUserHierarchy, authData } = this.props;
        this._isMounted = true;
        await fetchSalesUserHierarchy(authData);
    }

    setLoading = (value) => {
        if (this._isMounted) {
            this.setState({
                loading: value
            });
        }
    }

    getItemList = () => {
        return [
            {
                name: "Route Target",
                route: "/targetScheduler/routeTarget",
                permission: ROUTE_TARGET.VIEW
            },
            {
                name: "Route Customer Target",
                route: "/targetScheduler/routeCustomerTarget",
                permission: ROUTE_CUSTOMER_TARGET.VIEW
            },
            {
                name: "Customer Frequency",
                route: "/targetScheduler/customerFrequency",
                permission: CUSTOMER_FREQUENCY.VIEW
            },
            {
                name: "Generate Report",
                route: "/targetScheduler/generateReport"
            }
        ]
    }


    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        const { authData } = this.props;
        return (
            <PromatePageHeader
                title="Route Wise Palnning & Performance"
                style={styles.pageHeader}
                onBack={() => this.props.history.goBack()}
                showCallButton={false}
            >
                <Spin spinning={this.state.loading} tip={"Loading..."}>
                    <Header loading={this.state.loading} setLoading={this.setLoading} />
                    <Row gutter={[10, 10]} style={{ padding: "10px" }}>
                        {
                            this.getItemList().map((item, index) => {
                                return <SheduleTile item={item} key={index} permissions={authData?.permissions} />
                            })
                        }
                    </Row>
                </Spin>
            </PromatePageHeader>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TargetScheduler));

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    }
}