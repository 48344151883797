import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { format } from "currency-formatter";
import { dateFormatView } from "../../const/Formats";
import { toCommaSeparatedString } from "../../utils/Formats";
import { EXPENSES } from "../../const/Permissions";
import { getLoggedInUser } from "../../redux/reducers";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ExpenseCard extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { expense, history, loggedInUser: { permissions = [] } } = this.props;
    const { CollectionNo, PayNo, DocDate, CusCode, CusName, Status, ExpCategory, ExpDescription, Amount } = expense;

    const ExpAmount = Amount && Amount === "" ? 0 : Amount.replace(/,/g, '');

    return (
      <Row onClick={() => history.push("/expenses/" + encodeURIComponent(CollectionNo))} style={{ paddingRight: window.innerWidth < 768 ? 0 : 5 }}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>{CollectionNo}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(DocDate).format(dateFormatView)}</span>
          </Col>

          <Col xs={0} sm={0} md={1} lg={3} style={{ textAlign: 'left' }}>
            <span >{ExpDescription}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'left' }}>
            <span >{PayNo}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span >{CusCode}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={7} style={{ textAlign: 'left' }}>
            <span >{CusName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(Status)} >{Status}</Tag>
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'right' }}>
            <span >{format(parseFloat(ExpAmount), currencyFormat)}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={{
              fontSize: 12,
              fontWeight: 600,
            }}>{CollectionNo && CollectionNo.replace(/---/g, '/')}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(DocDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <Tag style={styles.status} color={this.getStatusColor(Status)} >{Status}</Tag>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={{ margin: 5, fontSize: 13 }}>{ExpCategory}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span style={{ margin: 5, fontSize: 13 }}>{PayNo}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span style={{ margin: 5, fontSize: 13 }}>{format(parseFloat(ExpAmount), currencyFormat)}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{CusName}</span>
          </Col>
        </Row>
      </Row>
    );
  }
}

const styles = {
  orderNo: {
    fontSize: 14,
    //fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 14,
    fontWeight: 700
  },

  orderDateTitle: {
    fontSize: 14
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(ExpenseCard));