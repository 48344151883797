import { FETCH_SERVER_HEALTH_STATUS } from '../actions/networkDetector';

export const networkStatus = (state = {}, action) => {
	switch (action.type) {
		case FETCH_SERVER_HEALTH_STATUS:
			return action.status
		default:
			return state;
	}
};

const networkDetector = networkStatus;

export default networkDetector;

export const getNetworkStatus = state => {
	return state.networkStatus
}