import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Col, Button, Modal, Form, Popconfirm } from "antd";
import { fetchCustomer, addCustomer, fetchCustomerCategories, fetchCustomerSubcategories, updateCustomer } from "../../../redux/actions/customers";
import { addPaymentMethodForCustomer, fetchPaymentMethodsForCustomer, deletePaymentMethodFromCustomer } from "../../../redux/actions/paymentMethods";
import { addPaymentTermForCustomer, fetchPaymentTermsForCustomer, deletePaymentTermFromCustomer } from "../../../redux/actions/paymentTerms";
import { fetchFilteredRoutes } from "../../../redux/actions/filteredRoutes";
import { addOrder } from "../../../redux/actions/orders";
import { fetchDistributors } from '../../../redux/actions/distributors';
import { fetchPaymentMethods } from '../../../redux/actions/paymentMethods';
import { fetchPaymentTerms } from '../../../redux/actions/paymentTerms';
import { showNotification } from "../../../const/Notifications"
import Details from "./Details";
import Actions from "./Actions";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { defaultOrder } from '../../Orders/OrderScreen/Container';
import uuid from 'uuid/v1';
import CustomerForm from './Form'
import { getAuthData, getLoggedInUser, getCustomer, getDistributors, getCustomerPaymentMethodsByCustomerId, getCustomerPaymentTermsByCustomerId, getCustomerSubcategories, getFilteredRoutes, getInProgressCall } from '../../../redux/reducers';
import { locationEnabled, addListener, removeListener } from '../../../utils/Location';
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { CUSTOMERS } from "../../../const/Permissions";
import Input from '../../../components/common/EnglishInput';
import { currentLocation } from '../../../utils/Location';
import customers from '../../../redux/reducers/customers';
import { formatGeoLocation } from "../../../utils/Formats";
import { getNetworkModeDetector } from '../../../redux/reducers';

const defaultCustomer = {
  OutstandAmount: 0.0,
  PaymentType: 'CASH',
  ProfileImageURL: undefined
}

class Container extends Component {
  state = {
    loading: false,
    customer: {
      ...defaultCustomer
    },
    nameError: undefined,
    contactNoError: undefined,
    addressError: undefined,
    faxNoError: undefined,
    emailError: undefined,
    contactPersonError: undefined,
    showBlockReasonDialog: false,
    visible: false,
    blockedReason: "",
    working: false,
    selectedCustomerPaymentMethods: [],
    selectedCustomerPaymentTerms: []
  }

  componentDidMount() {
    const {
      fetchCustomer,
      fetchCustomerCategories,
      fetchCustomerSubcategories,
      customerId,
      customer,
      routes,
      match,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchPaymentMethodsForCustomer,
      fetchPaymentTermsForCustomer,
      customerPaymentMethods,
      customerPaymentTerms } = this.props;
    let customerDataApiCalls = 3;

    //this.loadRoutes(this.props);
    fetchPaymentMethods();
    fetchPaymentTerms();
    fetchCustomerCategories();
    fetchCustomerSubcategories();
    this.loadDistributors(this.props);
    if (customerId !== "new") {
      this.setCustomerData(customer);
      this.setState({ loading: true, customerPaymentMethods, customerPaymentTerms });

      fetchPaymentMethodsForCustomer({ CustNo: customerId }).then((response) => {
        customerDataApiCalls--;
        fetchPaymentTermsForCustomer({ CustNo: customerId }).then(() => {
          customerDataApiCalls--;
          fetchCustomer(customerId).then(() => {
            this.setState({ loading: false })
            customerDataApiCalls--
          });
        });
      });

      if (customerDataApiCalls === 0) this.setState({ loading: false });
    } else {
      const routeCode = match.params.routeId ? match.params.routeId : routes && routes.length > 0 && routes[0].RouteCode;
      const distributor = this.getDistributorFromRoute(routeCode);

      this.setCustomerData({
        ...defaultCustomer,
        RouteCode: routeCode,
        DistributorCode: distributor && distributor.DistributorCode,
        DistributorName: distributor && distributor.DistributorName
      });
    }
  }

  setDeviceMapLocation = () => {
    if (!this.state.customer.Code && !this.state.customer.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.customer.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          customer: {
            ...this.state.customer,
            MapLocation: formatGeoLocation(location.latitude, location.longitude)
          },
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  customerLoaded = false;
  paymentMethodsLoaded = false;
  paymentTermsLoaded = false;
  componentWillReceiveProps = nextProps => {
    const { customer, customerPaymentMethods, customerPaymentTerms, match, routes } = nextProps || {};

    if (customer && !this.customerLoaded) {
      const routeCode = match.params.routeId ? match.params.routeId : routes && routes.length > 0 && routes[0].RouteCode;
      const distributor = this.getDistributorFromRoute(routeCode);

      if (distributor) {
        if (!customer.No) {
          this.setCustomerData({
            ...customer,
            RouteCode: routeCode,
            DistributorCode: distributor && distributor.DistributorCode,
            DistributorName: distributor && distributor.DistributorName
          });
        } else {
          this.setCustomerData({ ...customer });
        }

        this.customerLoaded = true;
      }
    }

    if (!this.paymentMethodsLoaded && customerPaymentMethods.length > 0) {
      this.setState({ selectedCustomerPaymentMethods: customerPaymentMethods });
      this.paymentMethodsLoaded = true;
    }

    if (!this.paymentTermsLoaded && customerPaymentTerms.length > 0) {
      this.setState({ selectedCustomerPaymentTerms: customerPaymentTerms });
      this.paymentTermsLoaded = true;
    }
  }

  // loadRoutes = props => {
  //   const { fetchFilteredRoutes, authData } = props;
  //   if (!authData) return;

  //   this.setState({ loading: true })
  //   fetchFilteredRoutes(authData.userId).then(() => {
  //     this.setState({ loading: false })
  //   })
  // }

  distributorsLoaded = false;
  loadDistributors = props => {
    if (this.distributorsLoaded) return;
    const { fetchDistributors, authData } = props;
    if (!!authData) {
      fetchDistributors(authData);
      this.distributorsLoaded = true;
    }
  }

  setCustomerData = customer => {
    if (customer) {
      this.setState({
        customer: { ...customer }
      }, this.setDeviceMapLocation);
    }
  }

  handleCancel = () => {
    this.setState({ visible: false, blockedReason: "" });
  };

  handleOk = () => {
    console.log(this.state.blockedReason)
    this.setState({ visible: false, blockedReason: "" });
  };


  onInputChange = (field, value, errorKey) => {
    if (field === 'RouteCode') {
      const distributor = this.getDistributorFromRoute(value)

      this.setState({
        customer: {
          ...this.state.customer,
          [field]: value,
          DistributorCode: distributor && distributor.DistributorCode
        }, [errorKey]: undefined
      });
    } else if (field === 'OutletCategoryCode') {
      this.setState({ customer: { ...this.state.customer, [field]: value, OutletSubCategoryCode: '' }, [errorKey]: undefined });
    } else {
      this.setState({ customer: { ...this.state.customer, [field]: value }, [errorKey]: undefined });
    }
  }

  handleSelectPaymentOptionChange = (field, value, errorKey) => {
    this.setState({ [field]: value, [errorKey]: undefined });
  }

  handleSelectPaymentOption = (field, value) => {
    const { customerId, addPaymentMethodForCustomer, addPaymentTermForCustomer } = this.props;

    if (customerId !== "new") {
      if (field === "selectedCustomerPaymentMethods") {
        this.handleSaveCustomerPaymentConfigOpions(addPaymentMethodForCustomer, this.state.customer, value);
      } else {
        this.handleSaveCustomerPaymentConfigOpions(addPaymentTermForCustomer, this.state.customer, value);
      }
    } else {
      const selectedOptions = this.state[field];
      selectedOptions.push(value);
      this.setState({ [field]: selectedOptions, [field === "selectedCustomerPaymentMethods" ? 'paymentMethodError' : 'paymentTermError']: null });
    }
  }

  handleDeselectPaymentOption = (field, value) => {
    const { customerId, deletePaymentMethodFromCustomer, deletePaymentTermFromCustomer, toastManager } = this.props;
    const { selectedCustomerPaymentMethods, selectedCustomerPaymentTerms } = this.state;

    if (customerId !== "new") {
      if (field === "selectedCustomerPaymentMethods") {
        if (selectedCustomerPaymentMethods.length === 1) {
          toastManager.add('You should have at least one payment method selected.', { autoDismiss: true, appearance: 'error' });
        } else {
          this.handleSaveCustomerPaymentConfigOpions(deletePaymentMethodFromCustomer, this.state.customer, value);
        }
      } else {
        if (selectedCustomerPaymentTerms.length === 1) {
          toastManager.add('You should have at least one payment term selected.', { autoDismiss: true, appearance: 'error' });
        } else {
          this.handleSaveCustomerPaymentConfigOpions(deletePaymentTermFromCustomer, this.state.customer, value);
        }
      }
    } else {
      const filteredSelectedOptions = this.state[field].filter(option => option.key !== value.key);
      this.setState({ [field]: filteredSelectedOptions });
    }
  }

  getDistributorFromRoute = routeCode => {
    const { routes } = this.props;

    const distributor = routes && routes.find(route => route.RouteCode === routeCode);
    return distributor;
  }

  onSaveCustomer = () => {
    const { toastManager, authData: { roleCode }, loggedInUser: { userId }, match, addPaymentMethodForCustomer, addPaymentTermForCustomer } = this.props;
    const { customer, selectedCustomerPaymentMethods, selectedCustomerPaymentTerms } = this.state;

    if (!locationEnabled) {
      toastManager.add('You can not create a customer without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) {
      toastManager.add('You have errors in your form.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    customer.SessionID = uuid();
    customer.UserCode = userId;
    if (roleCode == "CSR") {
      customer.callId = this.props.ongoingCall.No;
    }

    this.setState({ loading: true })
    this.props.addCustomer(customer).then(response => {
      if (response.error == "offline") {
        this.setState({ loading: false })
        selectedCustomerPaymentMethods.forEach(method => {
          this.handleSaveCustomerPaymentConfigOpions(addPaymentMethodForCustomer, response.customer, method)
        });

        selectedCustomerPaymentTerms.forEach(term => {
          this.handleSaveCustomerPaymentConfigOpions(addPaymentTermForCustomer, response.customer, term)
        });
        this.props.history.goBack();
      } else if (response.error) {
        this.setState({ loading: false })
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        selectedCustomerPaymentMethods.forEach(method => {
          this.handleSaveCustomerPaymentConfigOpions(addPaymentMethodForCustomer, response.customer, method)
        });

        selectedCustomerPaymentTerms.forEach(term => {
          this.handleSaveCustomerPaymentConfigOpions(addPaymentTermForCustomer, response.customer, term)
        });

        this.setState({ loading: false })
        toastManager.add('Customer added successfully.', { autoDismiss: true, appearance: 'success' });
        if (match.params.routeId) {
          this.props.history.goBack();
        } else {
          this.props.history.replace('/customers');
        }
      }
    })
  }

  onUpdateCustomer = () => {
    const { toastManager, loggedInUser: { userId } } = this.props;
    const { customer } = this.state;

    if (!locationEnabled) {
      toastManager.add('You can not update customer details without enable location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) {
      toastManager.add('You have errors in your form.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    customer.SessionID = uuid();
    customer.UserCode = userId;

    this.setState({ loading: true })
    this.props.updateCustomer(customer).then(response => {
      this.setState({ loading: false })
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        toastManager.add('Customer updated successfully.', { autoDismiss: true, appearance: 'success' });
      }
    })
  }

  handleSaveCustomerPaymentConfigOpions = (action, customer, option) => {
    const request = this.getCustomerPaymentConfigOptionRequest(option);
    if (!this.props.isSystemOffline) {
      request.CustNo = customer.No;
    }

    return action(request);
  }

  getCustomerPaymentConfigOptionRequest = configOption => {
    const { loggedInUser: { userId } } = this.props;
    const { customer } = this.state;

    return {
      SessionID: uuid(),
      CustNo: customer.No,
      Code: configOption.key,
      MapLocation: customer.MapLocation,
      UserCode: userId
    }
  }


  onBloackCustomer = () => {
  }

  onConfirmDelete = () => {
    const { toastManager } = this.props;
    toastManager.add('Customer deleted successfully.', { autoDismiss: true, appearance: 'success' });
    this.props.history.goBack();
  }

  onConfirmApproved = () => {
    const { toastManager } = this.props;
    toastManager.add('Customer approved successfully.', { autoDismiss: true, appearance: 'success' });
    this.props.history.goBack();
  }

  validateForm = () => {
    const { customerSubcategories } = this.props;
    const { customer, selectedCustomerPaymentMethods, selectedCustomerPaymentTerms } = this.state;
    const { Name, Add, PhoneNo, RouteCode, OutletCategoryCode, OutletSubCategoryCode, FaxNo, ContactPerson, EMail, NIC } = customer;

    let nameError = undefined;
    let addressError = undefined;
    let contactNoError = undefined;
    let routeError = undefined;
    let categoryError = undefined;
    let subcategoryError = undefined;
    let faxNoError = undefined;
    let contactPersonError = undefined;
    let emailError = undefined;
    let nicError = undefined;
    let paymentMethodError = undefined;
    let paymentTermError = undefined;

    if (!this.isValidValue(Name)) nameError = 'Name is required.'
    if (!this.isValidValue(Add)) addressError = 'Address is required.'

    contactNoError = this.isValidPhoneNo(PhoneNo);
    faxNoError = this.isValidFaxNo(FaxNo);
    contactPersonError = this.isValidContactPerson(ContactPerson);
    emailError = this.isValidEmail(EMail);
    nicError = NIC && NIC.length > 9 && NIC.length < 51 ? undefined : 'NIC/Passport invalid.';
    paymentMethodError = selectedCustomerPaymentMethods.length === 0 ? 'Select at least one payment method.' : undefined;
    paymentTermError = selectedCustomerPaymentTerms.length === 0 ? 'Select at least one payment term' : undefined;

    if (!this.isValidValue(RouteCode)) routeError = 'Route is required.'
    if (!this.isValidValue(OutletCategoryCode)) categoryError = 'Category is required.'

    const hasSubcategories = customerSubcategories.filter(category => category.OutletCategory === OutletCategoryCode).length > 0;
    if (hasSubcategories && !this.isValidValue(OutletSubCategoryCode)) subcategoryError = 'Subcategory is required.';

    this.setState({ nameError, contactNoError, addressError, routeError, categoryError, subcategoryError, faxNoError, contactPersonError, emailError, nicError, paymentMethodError, paymentTermError })
    return !nameError && !addressError && !contactNoError && !routeError && !categoryError && !subcategoryError && !faxNoError && !contactPersonError && !emailError && !nicError && !paymentMethodError && !paymentTermError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidPhoneNo = value => {
    if (!value) return 'Contact Number is required.';

    const phoneNumber = value.replace(/\s/g, '');
    if (phoneNumber.length !== 11) return 'Contact number must contain 9 digits excluding +94.';

    return;
  }

  isValidFaxNo = value => {
    if(!value ) return 'POSM required.'
    return;
    // if (!value) return;

    // const faxNumber = value.replace(/\s/g, '');
    // if (faxNumber.length !== 11) return 'Fax number must contain 9 digits excluding +94.';

    // return;
  }

  isValidContactPerson = (value = '') => {
    const contactPerson = value.replace(/[^a-zA-Z]+/g, '');
    if (contactPerson.length < 3) return 'Contact person name must contains at least 3 characters without digits';
    else return null;
  }

  isValidEmail = (value = '') => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(value)) return 'Invalid email';
    else return null;
  }

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  submitOrder = () => {
    const { addOrder, toastManager, customer } = this.props;
    const order = { ...defaultOrder, sellToCustomerCode: customer.id, sellToCustomerName: customer.name }

    this.setState({ loading: true })
    addOrder(order).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
        defaultOrder.sessionId = uuid();
        this.props.history.replace('/orders/' + response.order.orderNo)
      } else {
        toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  renderActions = () => {
    const { authData: { roleCode }, loggedInUser: { permissions = [] } } = this.props;
    const { customerId, customer } = this.props;
    const newcustomerId = customerId ? customerId : "new";

    const { match } = this.props;
    const path = match.path
    const isEditCustomer = path.includes("edit");

    const { working } = this.state;

    if (newcustomerId === "new") {
      return (<Row gutter={15} className='promate-form-actions'><Col>
        {permissions.includes(CUSTOMERS.CREATE) && <CallStatusValidateButton disabled={working} type="primary" onClick={this.onSaveCustomer}>Create</CallStatusValidateButton>}
      </Col></Row>)
    } else {
      if (isEditCustomer && roleCode != "SALES_REPRESENTATIVE") {
        if (!customer) {
          return null;
        } else {
          return (<Row gutter={15} className='promate-form-actions'>
            {permissions.includes(CUSTOMERS.UPDATE) && <Col>
              {customer.Status == "UNVERIFIED" && roleCode === "DISTRIBUTOR" && <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={this.onConfirmApproved}
              >
                <Button disabled={working} type="primary">Approve</Button>
              </Popconfirm>}
            </Col>}
            {permissions.includes(CUSTOMERS.UPDATE) && <Col>
              <Button disabled={working} type="primary" onClick={this.onUpdateCustomer}>Update</Button>
            </Col>}

            {/* {permissions.includes(CUSTOMERS.UPDATE) && <Col>
                {customer && customer.status != "BLOCKED" && <Button type="primary" onClick={this.onBloackCustomer}>Block</Button>}
              </Col>}
              {permissions.includes(CUSTOMERS.DELETE) && <Col>
                {customer.status == "UNVERIFIED" && <Popconfirm
                  title="Are you sure ?"
                  okText="Ok"
                  cancelText="No"
                  onConfirm={this.onConfirmDelete}
                >
                  <Button type="primary">Delete</Button>
                </Popconfirm>}
              </Col>} */}
          </Row>)
        }
      }
    }
  }

  renderModal() {
    return (
      <Modal
        visible={this.state.visible}
        title="Add Block Reason"
        okText="Block"
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <Form layout="vertical">
          <Form.Item label="Reason">
            {(<Input type="textarea" onChange={value => this.setState({ blockedReason: value })} />)}
          </Form.Item>

        </Form>
      </Modal>
    );
  }

  handleMapClick = (event) => {
    const { permissions = [] } = this.props.loggedInUser || {};
    if (permissions.includes('Customers.Maps.Update')) {
      const location = formatGeoLocation(event.latLng.lat(), event.latLng.lng());

      this.setState({ customer: { ...this.state.customer, MapLocation: location } });
    }
  }

  setImageLink = fileUrl => {
    this.setState({ customer: { ...this.state.customer, ProfileImageURL: fileUrl } })
  }

  render() {
    const {
      loading,
      nameError,
      addressError,
      emailError,
      contactNoError,
      faxNoError,
      contactPersonError,
      routeError,
      categoryError,
      subcategoryError,
      nicError,
      paymentMethodError,
      paymentTermError,
      customer,
      locationAccuracy,
      selectedCustomerPaymentMethods,
      selectedCustomerPaymentTerms
    } = this.state;

    const { match, authData: { roleCode }, distributors, customerId } = this.props;
    const path = match.path
    const isEditCustomer = path.includes("edit");

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        {customer && !isEditCustomer && this.props.customerId != "new" && <Details customerId={customerId} />}
        {customer && !isEditCustomer && this.props.customerId != "new" && <Actions customer={customer} submitOrder={this.submitOrder} />}
        {(this.props.customerId === "new" || isEditCustomer) &&
          <CustomerForm
            customerId={this.props.customerId}
            onInputChange={this.onInputChange}
            onSaveCustomer={this.onSaveCustomer}
            showBlockReasonDialog={this.showBlockReasonDialog}
            nameError={nameError}
            contactNoError={contactNoError}
            contactPersonError={contactPersonError}
            faxNoError={faxNoError}
            addressError={addressError}
            emailError={emailError}
            routeError={routeError}
            categoryError={categoryError}
            subcategoryError={subcategoryError}
            nicError={nicError}
            paymentMethodError={paymentMethodError}
            paymentTermError={paymentTermError}
            customer={customer}
            distributors={distributors}
            authData={this.props.authData}
            onMapClick={this.handleMapClick}
            roleCode={roleCode}
            onWorking={working => this.setState({ working })}
            setImageLink={this.setImageLink}
            locationAccuracy={locationAccuracy}
            onSelectPaymentOptionChange={this.handleSelectPaymentOptionChange}
            onSelectPaymentOption={this.handleSelectPaymentOption}
            onDeselectPaymentOption={this.handleDeselectPaymentOption}
            selectedCustomerPaymentMethods={selectedCustomerPaymentMethods}
            selectedCustomerPaymentTerms={selectedCustomerPaymentTerms}
            deviceLocation={currentLocation}
          />
        }

        {this.renderModal()}
        {this.renderActions()}
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    customer: getCustomer(state, ownProps.customerId),
    ongoingCall: getInProgressCall(state),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    distributors: getDistributors(state),
    routes: getFilteredRoutes(state),
    customerPaymentMethods: getCustomerPaymentMethodsByCustomerId(state, ownProps.customerId),
    customerPaymentTerms: getCustomerPaymentTermsByCustomerId(state, ownProps.customerId),
    customerSubcategories: getCustomerSubcategories(state),
    isSystemOffline: getNetworkModeDetector(state),
  };
};


export default withRouter(withToastManager(connect(mapStateToProps,
  {
    fetchCustomer,
    addOrder,
    fetchFilteredRoutes,
    addCustomer,
    fetchCustomerCategories,
    fetchCustomerSubcategories,
    updateCustomer,
    fetchDistributors,
    fetchPaymentMethods,
    fetchPaymentTerms,
    addPaymentMethodForCustomer,
    fetchPaymentMethodsForCustomer,
    addPaymentTermForCustomer,
    fetchPaymentTermsForCustomer,
    deletePaymentMethodFromCustomer,
    deletePaymentTermFromCustomer
  })(Container)));
