import React, { Component } from 'react'
import { Button, PageHeader, Spin } from 'antd'
import styles from './styles'
import WarehouseTable from './WarehouseTable'
import moment from 'moment';
import { fetchCollectionsByCustomer, fetchCollections } from "../../redux/actions/collection";
import { getCollections, getCollectionsByCustomer } from "../../redux/reducers";
import { connect } from 'react-redux'
import { showLoadingMessage } from '../common/Message';
import { generateDemoWarehouses, getDemoWarehouses } from './WarehouseDemoData';
import PromatePageHeader from '../common/PromatePageHeader';
import { getAuthData } from '../../redux/reducers';

class warehouses extends Component {
  state = {
    loading: false,
    filter: {
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    generateDemoWarehouses()
    const { customerId } = this.props.match.params;
    if (customerId) {
      this.setState({ filter: { ...this.state.filter, customerId } }, () => this.fetchAllByCustomer());
    } else {
      this.fetchAllByDateRange();
    }
  }

  fetchAllByDateRange = () => {
    this.showLoadingMessage();
    this.setState({ loading: true });
    this.props.fetchCollections(this.state.filter).then(() => {
      this.setState({ loading: false });
      this.hideLoadingMessage();
    })
  }

  fetchAllByCustomer = () => {
    this.showLoadingMessage();
    this.setState({ loading: true });
    this.props.fetchCollectionsByCustomer(this.state.filter).then(() => {
      this.setState({ loading: false });
      this.hideLoadingMessage();
    });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing warehouses.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  render() {
    const warehouses = getDemoWarehouses();
    const { loading, filter } = this.state;
    return (
      <div>
        <PromatePageHeader
          title={'Warehouses'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={[
            <Button
              style={{
                backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute',
                top: 30,
                right: 25
              }}
              onClick={() => this.props.history.push("/warehouses/new")}>New</Button>,
          ]}>
          <Spin spinning={loading && warehouses.length === 0} tip={"Loading Warehouses"}>
            <WarehouseTable
              warehouses={warehouses}
              handleInputDateChange={this.handleInputDateChange}
              handleSearchClick={this.fetchAllByDateRange}
              filter={filter}
              handleInputTextChange={this.handleInputTextChange}
              authData={this.props.authData} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  //const collections = customerId ? getCollectionsByCustomer(state, customerId) : getCollections(state);
  return { warehouses: [], authData: getAuthData(state) };
};

// const sortListByOrderDate = list => {
//   const sortedList = list && list.length > 0 ? list.sort(function (collection1, collection2) {
//     const collectionDate1 = parseInt(collection1.warehouseDate);
//     const collectionDate2 = parseInt(collection2.warehouseDate);

//     if (collectionDate1 < collectionDate2) return 1;
//     if (collectionDate1 > collectionDate2) return -1;
//     return 0;
//   }) : []
//   return sortedList;
// }

export default connect(mapStateToProps, { fetchCollections, fetchCollectionsByCustomer })(warehouses);