import { PDFExport } from '@progress/kendo-react-pdf';
import { Col, Modal } from 'antd';
import moment from 'moment';
import React from "react";
import { withToastManager } from 'react-toast-notifications';
import { ProMateGreen } from '../../../const/Theme';
import { readObjectFromIndexDB } from '../../../indexDB';
import { exportJson } from '../../common/FileExport';
import SyncReport from "../Pdf/SyncReport";
import { TABLES, OBJECT_KEYS } from '../../../const/IndexDBObjects';


const { confirm } = Modal;

class ExportData extends React.Component {
    state = {
        data: {
            callsData: [],
            customerCreationData: [],
            salesOrders: {
                salesHeaders: [],
                salesLines: [],
                signatures: []
            },
            salesReturnOrders: {
                salesHeaders: [],
                salesLines: [],
                signatures: []
            }
        }
    };

    constructor(props) {
        super(props);
        this.pdfExportComponent = React.createRef();
    }

    exportData() {
        confirm({
            title: "Confirmation",
            content: "Are you want to export data?",
            maskClosable: false,
            onOk: async () => {
                try {
                    await exportJson(this.props.toastManager);
                    await this.exportPdf();
                } catch (error) {
                    this.props.toastManager.add("Offile details expor proccess was failed. " + error.message, { autoDismiss: false, appearance: 'error' });
                }
            }
        });
    }

    async exportPdf() {
        try {
            let res = await this.getDetailsFromIndexDB();
            this.setState({ data: res }, () => {
                if (res) {
                    this.pdfExportComponent.current.save();
                }
            });
        } catch (error) {
            this.props.toastManager.add('PDF export proccess was failed', { autoDismiss: true, appearance: 'error' });
        }
    }

    getDetailsFromIndexDB = async () => {
        const callsData = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const customerCreationData = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const salesHeaders = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const salesLines = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const salesOrderSignatures = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SIGNATURES).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const salesReturnHeaders = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const salesReturnLines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
        const salesReturnSignatures = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SALES_RETURN_SIGNATURES).then((res) => {
            return res;
        }).catch((err) => { console.log(err); });

        return {
            callsData: (callsData && callsData.records != undefined) ? callsData.records : [],
            customerCreationData: (customerCreationData && customerCreationData.data != undefined) ? customerCreationData.data : [],
            salesOrders: {
                salesHeaders: (salesHeaders && salesHeaders.data != undefined) ? salesHeaders.data : [],
                salesLines: (salesLines && salesLines.data.records != undefined) ? salesLines.data.records : [],
                signatures: (salesOrderSignatures && salesOrderSignatures.data != undefined) ? salesOrderSignatures.data : []
            },
            salesReturnOrders: {
                salesHeaders: (salesReturnHeaders && salesReturnHeaders.data != undefined) ? salesReturnHeaders.data : [],
                salesLines: (salesReturnLines && salesReturnLines.data != undefined) ? salesReturnLines.data : [],
                signatures: (salesReturnSignatures && salesReturnSignatures.data != undefined) ? salesReturnSignatures.data : []
            }
        };
    }

    render() {
        return (
            <>
                <div style={{ position: 'absolute', top: '-100vh', left: '-100vw' }}>
                    <PDFExport
                        fileName={moment(new Date()).format('YYYY-MM-DD(h-mma)')}
                        ref={this.pdfExportComponent}
                        paperSize="A4"
                        scale={0.8}
                        forcePageBreak=".page-break"
                    >
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 10px 5px 20px', borderBottom: '1px solid gray' }}>
                            <span>Session Start at: {localStorage.getItem('offline_session_start_timestamp') ?? '-'}</span>
                            <span>Session End at: {localStorage.getItem('offline_session_end_timestamp') ?? '-'}</span>
                        </div>
                        <SyncReport data={this.state.data} />
                    </PDFExport>
                </div>
                <Col onClick={() => this.exportData()} xs={12} sm={8} style={{ height: '100%', display: 'flex', alignContents: 'center', cursor: 'pointer' }}>
                    <div style={styles.topTiles}>
                        {this.props.icon}
                        <h2 style={{ color: '#FFFFFF', fontWeight: 600 }}>{this.props.title}</h2>
                    </div>
                </Col>
            </>
        );
    }
}

export default withToastManager(ExportData);

const styles = {
    topTiles: {
        backgroundColor: ProMateGreen,
        width: '100%',
        height: '100%',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        textAlign: 'center',
        color: '#FFFFFF'
    }
};