import React, { Component } from 'react';
import { Button, Icon, Row, Card, Col, Popconfirm } from "antd";
import moment from 'moment';
import { dateFormatView } from "../../../../const/Formats";
import Input, { EnglishTextArea as TextArea } from '../../../../components/common/EnglishInput';

class LocationForm extends Component {

  render() {
    const {
      onSelectLocationTypeChange,
      location = {},
      onInputChange,
      locationNameError,
      onCreateLocation,
      onUpdateLocation,
      onDeleteLocation,
      onRemoveReason,
      onBloackCustomer } = this.props;
    const { locationNo, type, name, blockedReasons = [], status } = location;
    const blockedReasonsExist = status === 'BLOCK' && blockedReasons.length > 0;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={blockedReasonsExist ? 12 : 24} md={blockedReasonsExist ? 12 : 24} sm={24} xs={24}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Location Name</div>
              <Input value={name} onChange={value => onInputChange("name", value, "locationNameError")} />
              {locationNameError && <div style={{ fontWeight: 500, color: 'red' }}>{locationNameError}</div>}
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Description</div>
              <TextArea rows={4} />
            </Col>
          </Col>
          {blockedReasonsExist && <Col lg={12} md={12} sm={24} xs={24}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td><div style={{ fontWeight: 500 }}>Block Reasons</div></td>
                <td></td>
              </tr>
              {blockedReasons.map((reason, index) => {
                return (
                  <div key={index}>
                    <tr >
                      <td style={{ width: 25 }}>{index + 1}.</td>
                      <td><b>{reason.type} - {reason.description}</b> </td>
                      <td style={{ width: 250, textAlign: 'center' }}><i> {moment(reason.time).format(dateFormatView + 'hh:mm a')}</i></td>
                      <td>
                        <Icon type="delete" theme="filled" style={{ fontSize: '14px', color: 'red' }} onClick={() => onRemoveReason(index)} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 25 }}></td>
                      <td colSpan={2}>{reason.narration}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: 25, height: 15 }}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div>
                )
              })}
            </Col>
          </Col>}
        </Row>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {locationNo === "new" ? <Col lg={4} md={4} sm={24} xs={24}>
              <Popconfirm
                title="Are you sure ?"
                okText="Yes"
                cancelText="No"
                onConfirm={onCreateLocation}
              >
                <Button block type="primary">Create</Button>
              </Popconfirm>
            </Col>
              :
              <Col lg={8} md={8} sm={24} xs={24}>
                <Col lg={8} md={8} sm={24} xs={24} style={{ marginBottom: 10 }}>
                  <Button block type="primary" onClick={onUpdateLocation}>Update</Button>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24} style={{ marginBottom: 10 }}>
                  <Button block type="primary" onClick={onBloackCustomer}>Block</Button>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Popconfirm
                    title="Are you sure ?"
                    okText="Ok"
                    cancelText="No"
                    onConfirm={onDeleteLocation}
                  >
                    <Button block type="danger">Delete</Button>
                  </Popconfirm>
                </Col>
              </Col>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default LocationForm;

const locationTypes = [
  {
    name: 'Mobile',
    value: 'MOBILE',
    key: 'MOBILE'
  },
  {
    name: 'Static',
    value: 'STATIC',
    key: 'STATIC'
  }
]