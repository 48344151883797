import { combineReducers } from 'redux';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS': {
			const newState = { ...state };
			action.distributorPaymentHeaders.forEach(distributorPaymentHeader => newState[distributorPaymentHeader.CollectionNo] = distributorPaymentHeader);
			return newState;
		}
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADER': {
			const newState = { ...state };
			newState[action.distributorPaymentHeader.CollectionNo] = action.distributorPaymentHeader;
			return newState;
		}
		case 'DELETE_DISTRIBUTOR_PAYMENT_HEADER': {
			const newState = { ...state };
			delete newState[action.distributorPaymentHeader.CollectionNo];
			return newState;
		}
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS': {
			return action.distributorPaymentHeaders.map(header => header.CollectionNo);
		}
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADER': {
			const { CollectionNo } = action.distributorPaymentHeaders[0];
			const newState = [...state];
			if (!newState.includes(CollectionNo)) newState.push(CollectionNo);
			return newState;
		}
		case 'DELETE_DISTRIBUTOR_PAYMENT_HEADER': {
			const newState = [...state];
			return newState.filter(id => id !== action.distributorPaymentHeader.CollectionNo);
		}
		default:
			return state;
	}
};

export const byCustomer = (state = [], action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS': {
			const newState = { ...state };
			action.distributorPaymentHeaders.forEach(header => {
				const ids = newState[header.CusCode] || [];
				if (!ids.includes(header.CollectionNo)) {
					ids.push(header.CollectionNo);
					newState[header.CusCode] = ids;
				}
			});
			return newState;
		}
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADER': {
			const newState = { ...state };
			const distributorPaymentHeader = action.distributorPaymentHeaders[0];
			const ids = newState[distributorPaymentHeader.CusCode] || [];
			if (!ids.includes(distributorPaymentHeader.CollectionNo)) {
				ids.push(distributorPaymentHeader.CollectionNo);
				newState[distributorPaymentHeader.CusCode] = ids;
			}
			return newState;
		}
		case 'DELETE_DISTRIBUTOR_PAYMENT_HEADER': {
			const newState = { ...state };
			const ids = newState[action.distributorPaymentHeader.CusCode] || [];
			newState[action.distributorPaymentHeader.CusCode] = ids.filter(id => id !== action.distributorPaymentHeader.CollectionNo)

			return newState;
		}
		default:
			return state;
	}
};

export const bySearchKey = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS': {
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.distributorPaymentHeaders.map(header => header.CollectionNo)
			};
		}
		case 'DELETE_DISTRIBUTOR_PAYMENT_HEADER': {
			const newState = { ...state };
			Object.keys(newState).forEach(key => {
				const ids = newState[key];
				newState[key] = ids.filter(id => id !== action.distributorPaymentHeader.CollectionNo)
			});
			return newState;
		}
		default:
			return state;
	}
};

export const total = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS':
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.total
			};

		default:
			return state;
	}
}

export const metadata = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_HEADERS':
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.metadata
			};

		default:
			return state;
	}
}

export const fetching = (state = false, action) => {
	switch (action.type) {
		case 'START_FETCH_DISTRIBUTOR_PAYMENT_HEADERS':
			return true;

		case 'FINISH_FETCH_DISTRIBUTOR_PAYMENT_HEADERS':
			return false;

		default:
			return state;
	}
}

export const distributorPaymentTypesById = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_DISTRIBUTOR_PAYMENT_TYPES':
			const distributorPaymentTypes = {};
			action.distributorPaymentTypes.forEach(type => distributorPaymentTypes[type.Code] = type);
			return distributorPaymentTypes;

		default:
			return state;
	}
}

const distributorPaymentHeaders = combineReducers({
	byId,
	ids,
	byCustomer,
	bySearchKey,
	total,
	metadata,
	fetching,
	distributorPaymentTypesById
});

export default distributorPaymentHeaders;

export const getAll = state => {
	return state.ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const get = (state, no) => state.byId[no];

export const getByCustomer = (state, customerId) => {
	const ids = state.byCustomer[customerId] || [];
	return ids.map(id => state.byId[id]).sort(sortDecByStartDate)
}

export const getCreditDistributorPaymentHeaders = (state, id) => {
	return state.ids.map(id => state.byId[id]).filter(filterCreditReceipts).sort(sortDecByStartDate);
}

export const getCreditDistributorPaymentHeadersByCustomer = (state, id) => {
	const ids = state.byCustomer[id] || [];
	return ids.map(id => state.byId[id]).filter(filterCreditReceipts).sort(sortDecByStartDate);
}

const filterCreditReceipts = receipt => {
	const credit = (parseFloat(receipt.PayAmount.replace(/,/g, "")) - parseFloat(receipt.AppliedAmount.replace(/,/g, ""))) > 0;
	const confirmed = receipt.OrderStatus === 'Confirmed';

	return credit && confirmed;
}

const sortDecByStartDate = (s1, s2) => {
	return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
	const ids = state.bySearchKey[searchKey] || [];
	return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;

export const getDistributorPaymentTypes = state => Object.values(state.distributorPaymentTypesById);