import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchWarehouseLocations } from '../../redux/actions/warehouses'
import { getLocations, getAuthData } from "../../redux/reducers";

const { Option } = Select;

class SelectWarehouseLocation extends Component {
  componentDidMount = () => {
    const filter = { UserCode: this.props.authData.userId };
    this.props.fetchWarehouseLocations(filter);
  }

  render() {
    const {
      locations,
      onChange,
      showSearch = true,
      placeholder = 'Select Location',
      allowClear = false,
      defaultValue,
      disabled = false,
      style = { width: '100%' },
      type,
    } = this.props

    const updatedLocations = type === "purchasing_order" ? locations.filter((location) => !String(location.LocCode).includes("DV")) : locations

    return (
      <Select
        //defaultValue={locations.length >0 ? locations[0].LocCode : defaultValue}
        placeholder={placeholder}
        value={defaultValue}
        onChange={(value) => onChange('LocCode', value, 'locCodeError')}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        style={style}
      >
        {updatedLocations.map(location => <Option key={location.LocCode} value={location.LocCode}>{location.LocDescription}</Option>)}
      </Select>
    )
  }
}

const mapStateToProps = state => {
  return {
    locations: getLocations(state),
    authData: getAuthData(state)
  };
};

export default connect(mapStateToProps, { fetchWarehouseLocations })(SelectWarehouseLocation);