import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class SalesRepView extends Component {
  state = {
    isEdit: false
  }

  render() {
    const { salesPersonId } = this.props.match.params
    const newSalesRep = salesPersonId === 'new';
    //console.log(salesPersonId);

    const { match } = this.props;
    const path = match.path
    const isEdit = path.includes("edit");

    return (
      <div>
        <PromatePageHeader
          title={newSalesRep ? 'New Sales Representative' : 'Sales Representative Info'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={(!newSalesRep && !isEdit) ? [
            <Button style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute', top: 30, right: 25 }} onClick={this.editSalesRep}>
              Edit
            </Button>
          ] : []}>
          <Container salesPersonId={salesPersonId} />
        </PromatePageHeader>
      </div>
    )
  }

  editSalesRep = () => {
    const { salesPersonId } = this.props.match.params
    //this.setState({ isEditCustomer : true});
    ////console.log(this.state.isEditCustomer);
    this.props.history.push("/salesRepresentatives/" + salesPersonId + "/edit");
  }
}


const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default SalesRepView;