import { Col } from 'antd';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProMateGreen } from '../../../const/Theme';
import { withToastManager } from 'react-toast-notifications';

export class SheduleTile extends Component {

    handleNavigate = () => {
        const { route, permission } = this.props.item;
        this.props.history.push(route + this.props.location.search);

        // if (this.props.permissions.includes(permission)) {
        //     this.props.history.push(route);
        // } else {
        //     this.props.toastManager.add("You doesn't have any permission for this section", { autoDismiss: true, appearance: 'error' });
        // }
    }

    render() {
        const { name } = this.props.item;
        return (
            <Col className="gutter-row" xs={24} sm={12} md={8} lg={6}>
                <div onClick={() => this.handleNavigate()}>
                    <div style={styles.topTiles} >
                        <h2 style={{ color: '#FFFFFF', fontWeight: 600 }}>{name}</h2>
                    </div>
                </div>
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(SheduleTile)));

const styles = {
    topTiles: {
        backgroundColor: ProMateGreen,
        width: '100%',
        height: '100%',
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        cursor: "pointer"
    },
}