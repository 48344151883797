import Api from '../../../api/consts'
import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';
import ImageAttachmentContainer from "../../common/ImageAttachmentContainer";
import AllocatedDetails from "../../PurchasingInvoices/component/AllocatedDetails";
import { showNotification } from '../../../const/Notifications';
import { format } from "currency-formatter";
import BetterImg from '../../common/BetterImage';
const defaultImagePath = "/placeholder-image.png";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class DistributorDistributorPaymentHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Released':
        return 'orange';
      case 'Posted':
        return 'blue';
      case 'Reversed':
        return 'red';
      case 'Returned':
        return 'red';
      case 'Customer Confirmed':
        return 'green';
      case 'On Hold':
        return 'red';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  handleAttachmentError = error => {
    showNotification('error', 'Attachment Failed', error);

    const { onWorking } = this.props;
    onWorking && onWorking(false);
  }

  handleImageLink = link => {
    const { setImageLink, onWorking } = this.props;
    setImageLink(link);
    onWorking && onWorking(false);
  }

  // renderActions = customerSigned => {
  //   const { newDistributorPaymentId, onSaveDistributorPayment, onConfirmDistributorPayment, onDeleteDistributorPayment } = this.props;

  //   if (newDistributorPaymentId === "new") {
  //     return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
  //       {/* <Popconfirm
  //         title="Are you sure ?"
  //         okText="Yes"
  //         cancelText="No"
  //         onConfirm={onSaveCollection}>
  //         <Button type="primary">Create</Button>
  //       </Popconfirm> */}
  //       <Button type="primary" onClick={onSaveDistributorPayment}>Create</Button>
  //     </Col></Row>
  //   } else {
  //     return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
  //       {customerSigned && <Col>
  //         <Button 
  //           type="secondary"
  //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}>Print & Share</Button>
  //       </Col>}
  //     </Row>
  //   }
  // }

  render() {
    const { newDistributorPaymentId, distributorPayment = {}, onWorking, attachment } = this.props;
    const { CollectionNo, ReceivingDate, CollectType, PayNo, DocDate, PostingDate, CusCode, CusName, CustStatus, OrderStatus, Amount, BankAccount, BankBranchCode, BankCode, CollectDateTime, DocDateFilter, PaymentGroup, Remarks, ReturnedDate, UserCode, AppliedAmount } = distributorPayment;

    const fileUrl = attachment && attachment.AttachmentURL ? Api.files.download.url(attachment.AttachmentURL) : undefined;

    const payAmount = !Amount || Amount && Amount === "" ? 0 : Amount.replace(/,/g, '');
    const appliedAmount = !AppliedAmount || AppliedAmount && AppliedAmount === "" ? 0 : AppliedAmount.replace(/,/g, '');

    const BalanceAmount = parseFloat(payAmount) - parseFloat(appliedAmount);

    return (
      <div style={{ margin: 10, marginBottom: -5 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {CollectDateTime && <tr>
              <td>Collection Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(CollectDateTime).format(dateFormatView)}</td>
            </tr>}
            {CollectionNo && <tr>
              <td style={{ width: 120 }}>Collection No</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{CollectionNo}</td>
            </tr>}
            {ReceivingDate && <tr>
              <td>Receiving Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(ReceivingDate).format(dateFormatView)}</td>
            </tr>}
            {CollectType && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{CollectType}</td>
            </tr>}
            {PayNo && <tr>
              <td style={{ width: 120 }}>Doc No</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{PayNo}</td>
            </tr>}
            {DocDate && <tr>
              <td>Doc Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(DocDate).format(dateFormatView)}</td>
            </tr>}
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {CusCode && <tr>
              <td style={{ width: 120 }}>Distributor #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{CusCode}</td>
            </tr>}
            <tr>
              <td>Distributor Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{CusName}</td>
            </tr>
            {CustStatus && <tr>
              <td>DB Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={this.getStatusColor(CustStatus)}>{CustStatus}</Tag></td>
            </tr>}
            {BankCode && <tr>
              <td>DB Bank</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{BankCode}</td>
            </tr>}
            {BankBranchCode && <tr>
              <td>DB Bank Branch</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{BankBranchCode}</td>
            </tr>}
            {BankAccount && <tr>
              <td>Bank Account PXL</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{BankAccount}</td>
            </tr>}
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {PostingDate && <tr>
              <td>Posting Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(PostingDate).format(dateFormatView)}</td>
            </tr>}
            {OrderStatus && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag></td>
            </tr>}
            {ReturnedDate && <tr>
              <td>Posting Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(ReturnedDate).format(dateFormatView)}</td>
            </tr>}
            {UserCode && <tr>
              <td>Created User</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{UserCode}</td>
            </tr>}
            {Remarks && <tr>
              <td>Narration</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>{Remarks}</td>
            </tr>}
          </Col>
        </Row>
        <Row gutter={10} style={{ paddingTop: 20 }}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ fontWeight: 'bolder' }}>Payment Amount</h3>
                <h3 style={{ fontWeight: 'bolder' }}>{format(Amount, currencyFormat)}</h3>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ fontWeight: 'bolder' }}>Allocated Amount</h3>
                <h3 style={{ fontWeight: 'bolder' }}>{format(AppliedAmount, currencyFormat)}</h3>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ fontWeight: 'bolder' }}>Balance</h3>
                <h3 style={{ fontWeight: 'bolder' }}>{format(parseFloat(BalanceAmount), currencyFormat)}</h3>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col xs={0} sm={0} md={24} lg={24}>
                <AllocatedDetails docType={"1"} docNo={CollectionNo} isMobileView={false} enableDeallocate={false} />
              </Col>
              <Col xs={24} sm={24} md={0} lg={0}>
                <AllocatedDetails docType={"1"} docNo={CollectionNo} isMobileView={true} enableDeallocate={false} />
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            {CollectionNo &&
              <div>
                <Col lg={24} md={24} sm={0} xs={0} style={{ paddingLeft: 10 }}>
                  <div style={{ fontWeight: 500 }}>Attachment</div>
                  <BetterImg
                    src={fileUrl ? fileUrl : defaultImagePath}
                    width={'100%'}
                    resizeMode="cover" />
                </Col>
                <Col lg={0} md={0} sm={24} xs={24}>
                  <div style={{ fontWeight: 500 }}>Attachment</div>
                  <BetterImg
                    src={fileUrl ? fileUrl : defaultImagePath}
                    width={'100%'}
                    resizeMode="cover" />
                </Col>
              </div>}
          </Col>
        </Row>
      </div>
    )
  }
}

export default DistributorDistributorPaymentHeader;