import uuid from 'uuid';

const ITEM_COUNT = 100;
let data = [];


export const LOCATION_CODE = "7.249641, 80.450267";
export const INVOICE_TYPE = "PROMATE";
export const customers = ["Lasitha", "Yehan", "Chinthaka", "Ranga"];
export const inventoryLocations = ["WAREHOUSE", "LOCATION001", "LOCATION002", "LOCATION003"];
export const salesPerson = ["SALES001", "SALES002", "SALES003", "SALES004"];
export const paymentTypes = ["CASH", "CHEQUE", "FRIMI"];
export const statuses = ["COMPLETED", "ORDERED", "PAID", "DELIVERED", "Unverified"];

export const generateData = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateItem(i));
  }

  return data;
}

const generateItem = index => {

  const item = {
    collectionNo: "Collection" + index
  }



  const paymentType = paymentTypes[getRandomNumber(0, paymentTypes.length)]
  const status = statuses[getRandomNumber(0, statuses.length)]
  const sellToCustomerCode = "CODE " + getRandomNumber(50, 100)
  const sellToCustomerName = customers[getRandomNumber(0, customers.length)]
  const billToCustomerCode = "CODE " + getRandomNumber(50, 100)
  const billToCustomerName = customers[getRandomNumber(0, customers.length)]
  const collectionDate = randomDate(new Date(2012, 0, 1), new Date())
  const salespersonCode = salesPerson[getRandomNumber(0, salesPerson.length)]
  const mapLocation = LOCATION_CODE
  const amount = getRandomNumber(300, 700)

  item.paymentType = paymentType
  item.sellToCustomerCode = sellToCustomerCode
  item.sellToCustomerName = sellToCustomerName
  item.billToCustomerCode = billToCustomerCode
  item.billToCustomerName = billToCustomerName
  item.collectionDate = collectionDate
  item.salespersonCode = salespersonCode
  item.mapLocation = mapLocation
  item.amount = amount
  item.status = status

  return item;
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const getDemoItem = id => {
  return data.find(item => item.collectionNo === id);
}