import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import { STOCK_TAKE } from "../../const/Permissions";

class Card extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { stockTake, history, customerCategory, customerSubcategory, loggedInUser: { permissions = [] } } = this.props;
    const { No, DocDate, TakenBy, DistributorCode, DistributorName, LocCode, LocDescrip, Status, Change } = stockTake;

    return (
      <Row onClick={() => permissions.includes(STOCK_TAKE.VIEW) && history.push("/stockTake/" + encodeURIComponent(No))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.orderNoDesktop}>{No}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderNoMobile}>{No}</span>
          </Col>

          <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>
            <span style={styles.orderDate}>{moment(DocDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{TakenBy}</span>
          </Col>
          <Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{DistributorCode}</span>
          </Col>
          <Col xs={0} sm={0} md={4} lg={4} style={{ textAlign: 'left' }}>
            <span>{DistributorName}</span>
          </Col>

          <Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{LocDescrip}</span>
          </Col>

          <Col xs={0} sm={3} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
          </Col>

          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
          </Col>


          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={Change || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>

        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{LocDescrip}</span>
          </Col>

        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={16} sm={0} md={0} lg={0} style={styles.bottomColumnStyles}>
            <span>{DistributorName}</span>
          </Col>

          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={Change || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNoDesktop: {
    fontSize: 14,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderNoMobile: {
    fontSize: 11,
    fontWeight: 700
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 9,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 15,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginLeft: 10,
    marginRight: 10
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const { stockTake } = ownProps;
  const loggedInUser = getLoggedInUser(state);

  return { loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(Card));