import { combineReducers } from 'redux';
import { FETCH_EXPENSES, START_FETCH_EXPENSES, FETCH_EXPENSE, FINISH_FETCH_EXPENSES } from '../actions/Expenses';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES:
      const newState = { ...state };
      action.expenses.forEach(expense => newState[expense.CollectionNo] = expense);
      return newState;
    case FETCH_EXPENSE: {
      const newState = { ...state };
      newState[action.expense.CollectionNo] = action.expense;
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_EXPENSES: {
      const newState = [...state]
      action.expenses.forEach(expense => {
        if (!newState.includes(expense.CollectionNo)) newState.push(expense.CollectionNo);
      });

      return newState;
    }
    case FETCH_EXPENSE: {
      const newState = [...state];
      if (!newState.includes(action.expense.CollectionNo)) {
        newState.push(action.expense.CollectionNo)
      }
      return newState;
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.expenses.map(expense => expense.CollectionNo)
      };
    }
    default:
      return state;
  }
};

export const total = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.total
      };

    default:
      return state;
  }
}

export const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.metadata
      };

    default:
      return state;
  }
}

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_EXPENSES:
      return true;

    case FINISH_FETCH_EXPENSES:
      return false;

    default:
      return state;
  }
}

const expenses = combineReducers({
  byId,
  ids,
  bySearchKey,
  total,
  metadata,
  fetching
});

export default expenses;

export const getByDocNo = (state, no) => {
  const key = no;
  return state.byId[key] || undefined;
}

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.DocDate) - new Date(s1.DocDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;