import React, { Component } from "react";

import { Col, Row, Tag } from "antd";

class OrderCard extends Component {

  render() {
    const { item } = this.props;
    const { LocCode, LotNo, Description, UOM, Inventory, UnitPrice } = item;

    return (
      <Row>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={6} md={5} lg={5} style={{ textAlign: 'left' }}>
            <span  >{LotNo}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={5} style={{ textAlign: 'left' }}>
            <span  >{LocCode}</span>
          </Col>
          <Col xs={0} sm={9} md={9} lg={7} style={{ textAlign: 'left' }}>
            <span>{Description}</span>
          </Col>
          <Col xs={0} sm={3} md={3} lg={2} style={{ textAlign: 'right' }}>
            <p  >{UOM}</p>
          </Col>
          <Col xs={0} sm={3} md={3} lg={2} style={{ textAlign: 'right' }}>
            <span>{Inventory}</span>
          </Col>

          <Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'right' }}>
            <span>{UnitPrice}</span>
          </Col>

          {/* mobileVIew */}
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span  >{LotNo}</span>
          </Col>
          <Col xs={16} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{Description}</span>
          </Col>

        </Row>

        <Row style={styles.lowerRow}>

          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{UOM}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{Inventory}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{LocCode}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{UnitPrice}</span>
          </Col>

        </Row>
        <Row style={styles.lowerRow}>

        </Row>
      </Row>
    )
  }
}

const styles = {
  largeBold: {
    fontSize: 18,
    fontWeight: 700
  },
  bigBold: {
    fontSize: 16,
    fontWeight: 700
  },
  normalBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  receiptNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10
  },
  receiptDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: -10
  },
  price: {
    fontSize: 18,
    fontWeight: 600
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}



export default OrderCard;