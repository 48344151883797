import React, { Component } from 'react';
import { Card, Divider, Col, Button, Row, Form, Table, Popconfirm } from 'antd';
import { format } from "currency-formatter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
// import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../../localStorage';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}


const columns = [
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    align: 'center',
  },
  {
    title: 'Discount Percentage(%)',
    dataIndex: 'discountPercentage',
    key: 'discountPercentage',
    align: 'center',
  },
  {
    title: 'Discount Amount',
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    align: 'right',
  },
];

const invoiceTableColumns = [
  {
    title: 'Invoice No',
    dataIndex: "invoiceNo",
    key: "invoiceNo",
    width: 100,


  }, {
    title: 'Code',
    dataIndex: 'itemNo',
    key: 'itemNo',
    width: 60,
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  }, {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    fixed: 'right',
    align: 'right',
    width: 60
  }, {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    width: 100,
    fixed: 'right',
    align: 'right',
    render: unitPrice => format(unitPrice, currencyFormat)
  }, {
    title: 'Discount',
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    width: 100,
    fixed: 'right',
    align: 'right',
    render: (discountAmount, rowData) => `${format(discountAmount, currencyFormat)} (${rowData.discountPercentage}%)`
  }, {
    title: 'Total',
    dataIndex: 'lineAmount',
    key: 'lineAmount',
    width: 80,
    fixed: 'right',
    align: 'right',
    render: total => format(total, currencyFormat)
  }


];

class Details extends Component {

  state = {
    quantityError: "",
    selectedRowKeys: [],
  }


  validateQuantity = (value) => {
    if (value > 30) {
      this.setState({ quantityError: "error" })
    } else {
      this.setState({ quantityError: "" })
      this.props.onUpdateOrderQty(value)
    }
  }

  renderItemDiscountTable = () => {

    const { inventoryItem, order, resource, salesLineItem } = this.props;
    const discountAmount = format((inventoryItem.unitPrice * order.discountPercentage) / 100, currencyFormat);
    const totalAmount = format((inventoryItem.unitPrice - discountAmount) * salesLineItem.quantity, currencyFormat)
    //console.log(inventoryItem, order, resource, salesLineItem.quantity)
    const obj = [{
      unitPrice: format(inventoryItem.unitPrice, currencyFormat), discountPercentage: order.discountPercentage, discountAmount: discountAmount, quantity: salesLineItem.quantity,
      totalAmount: totalAmount
    }]

    return (
      <Table dataSource={obj} columns={columns} />
    )
  }

  renderItemInvoiceTable = (itemNo, desc) => {

    const obj = [
      {
        invoiceNo: "Invoice 7",
        quantity: 10,
        itemNo: itemNo,
        description: desc,
        unitPrice: "500.00",
        discountAmount: "25.00",
        lineAmount: "475.00",
        discountPercentage: "5"
      },
      {
        invoiceNo: "Invoice 12",
        quantity: 5,
        itemNo: itemNo,
        description: desc,
        unitPrice: "500.00",
        discountAmount: "50.00",
        lineAmount: "450.00",
        discountPercentage: "10"
      },
      {
        invoiceNo: "Invoice 6",
        quantity: 3,
        itemNo: itemNo,
        description: desc,
        unitPrice: "500.00",
        discountAmount: "25.00",
        lineAmount: "475.00",
        discountPercentage: "5"
      },
      {
        invoiceNo: "Invoice 54",
        quantity: 12,
        itemNo: itemNo,
        description: desc,
        unitPrice: "500.00",
        discountAmount: "35.00",
        lineAmount: "465",
        discountPercentage: "7"
      },
    ]

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Table rowSelection={rowSelection} dataSource={obj} columns={invoiceTableColumns} />
    )
  }

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  hasSalesLineChanged = () => {
    const { salesLineItem, salesLineItemDatabase } = this.props;
    return salesLineItem.Quantity !== salesLineItemDatabase.Quantity ||
      salesLineItem.DisPercentage !== salesLineItemDatabase.DisPercentage;
  }

  getChangeAwareValue = key => {
    if (this.hasSalesLineChanged()) return 'N/A';
    else return this.props.salesLineItem[key];
  }

  renderRightAlignedTableRow = (title, value) => this.renderTableRow(title, value, 'right')

  renderEditableTableRow = (title, key, error) => {
    const { salesLineItem, onUpdateNumberField, returnTotalQtyError } = this.props;
    const value = parseFloat((salesLineItem[key] || '0').replaceAll(',', ''));
    return this.renderTableRow(
      title,
      <Form.Item
        validateStatus={salesLineItem.DocType === "Return Order" ? returnTotalQtyError ? 'error' : 'success' : error}
        help={returnTotalQtyError && 'You cannot exceed the return Qty'}
        style={{ marginTop: -8 }}>
        <input
          className="ant-input"
          value={value}
          style={{ textAlign: 'right' }}
          type="number"
          min="0"
          onChange={event => onUpdateNumberField(key, event.target.value)}
          onFocus={event => event.target.select()} />
      </Form.Item>
    );
  }

  renderTableRow = (title, value, alignment = 'left') =>
    <tr>
      <td ></td>
      <td style={{ width: 100, verticalAlign: 'center' }}>{title}</td>
      <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
      <td style={{ width: window.innerWidth < 768 ? window.innerWidth - 105 : undefined, textAlign: alignment }}>{value}</td>
    </tr>

  renderDicountPercentageRow = () => {
    const { docType, salesLineItem } = this.props;

    if (docType === 'ReturnOrder') {
      return this.renderRightAlignedTableRow('Discount %', (salesLineItem.DisPercentage || 0) + '%')
    } else {
      return this.renderEditableTableRow('Discount %', 'DisPercentage', this.state.discountPercentageError)
    }
  }

  render() {
    const { inventoryItem, salesLineItem, onUpdateOrderQty, onUpdateOrder, newOrderItem, onRemoveSalesLine, order, resource, onUpdateDiscountPercentage, onResetSalesLine, returnTotalQtyError } = this.props;
    const { quantityError, discountPercentageError } = this.state;
    const { docType } = this.props;
    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {inventoryItem.LotNo && this.renderTableRow('CODE', inventoryItem.LotNo)}
            {inventoryItem.Description && this.renderTableRow('Description', inventoryItem.Description)}

          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {inventoryItem.Brand && this.renderTableRow('Brand', inventoryItem.Brand)}
            {inventoryItem.Type && this.renderTableRow('Product type', inventoryItem.Type)}
            {salesLineItem.ReturnInvDocNo && this.renderTableRow('Return Inv. No.', salesLineItem.ReturnInvDocNo)}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {this.renderRightAlignedTableRow('UoM', inventoryItem.UOM)}
            {this.renderRightAlignedTableRow('For Sale', inventoryItem.Inventory)}
            {this.renderRightAlignedTableRow('Unit Price', inventoryItem.UnitPrice)}
            {this.renderEditableTableRow('Qty', 'Quantity', quantityError)}
            {this.renderDicountPercentageRow()}
            {this.renderRightAlignedTableRow('Discount', this.getChangeAwareValue('DisAmount'))}
            {this.renderRightAlignedTableRow('Total', this.getChangeAwareValue('AmountIncluVAT'))}
          </Col>
        </Row>

        {(resource === "salesOrders" || resource === "salesInvoices") && this.renderItemDiscountTable()}
        {(resource === "salesReturns") && this.renderItemInvoiceTable(inventoryItem.item.itemCode, inventoryItem.item.description)}


        <Row gutter={15} style={{ display: 'flex', float: 'right', marginTop: 15 }}>
          {this.hasSalesLineChanged() && <Col>
            <CallStatusValidateButton
              type="primary"
              onClick={onResetSalesLine}>
              Reset item
            </CallStatusValidateButton>
          </Col>}
          {newOrderItem || this.hasSalesLineChanged() ? <div></div> : <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onRemoveSalesLine}>
              <CallStatusValidateButton type="danger">{'Remove item'}</CallStatusValidateButton>
            </Popconfirm>
          </Col>}
          <Col>
            <CallStatusValidateButton
              type="primary"
              onClick={!returnTotalQtyError ? onUpdateOrder : () => { }}>{newOrderItem ? 'Add' : 'Update item'}
            </CallStatusValidateButton>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default Details;