import React, { Component } from 'react';
import { Card, Col, Row, Button, Modal } from 'antd';
import Radium from 'radium';
import { ProMateGreen, ProMateGreenBorder } from '../../../const/Theme';
import { Link, withRouter } from 'react-router-dom';
import { getAuthData, getLoggedInUser } from '../../../redux/reducers';
import { connect } from "react-redux";
import { SALES_ORDERS, SALES_INVOICES, CREDIT_NOTES, RECEIPTS, CALLS, INVOICE_SETTLEMENT, SALES_RETURNS, PURCHASING_ORDERS, PURCHASE_RETURNS, PURCHASING_INVOICES, STOCK_TAKE, EXPENSES, DISTRIBUTOR_PAYMENTS, PURCHASE_CREDIT_INVOICES } from "../../../const/Permissions";
import { FaBoxes, FaClock, FaFileInvoice, FaInbox, FaUser, FaUsers, FaWarehouse, FaPlusCircle, FaThList, FaTruckMoving, FaUserTie, FaCalendar, FaSignOutAlt, FaCartPlus, FaCoins, FaPhoneAlt, FaExternalLinkAlt, FaFileImport, FaBalanceScaleLeft, FaSyncAlt, FaReplyAll, FaRoute, FaShoppingCart, FaLink, FaReply, FaPaperPlane } from 'react-icons/fa'
import { MdCompareArrows, MdLayers, MdLayersClear, MdLocationCity } from 'react-icons/md';

import { AiOutlineSchedule, AiOutlineStock } from 'react-icons/ai';

const { confirm } = Modal;

var optionsWithSettlement = [
  {
    title: 'SALES ORDERS',
    key: 'SALES_ORDERS',
    permitedActions: [...Object.values(SALES_ORDERS)]
  },
  {
    title: 'SALES INVOICES',
    key: 'SALES_INVOICES',
    permitedActions: [...Object.values(SALES_INVOICES)]
  },
  {
    title: 'CREDIT NOTES',
    key: 'CREDIT_NOTES',
    permitedActions: [...Object.values(CREDIT_NOTES)]
  },
  {
    title: 'CREDIT INVOICES',
    key: 'CREDIT_INVOICES',
    permitedActions: [...Object.values(CREDIT_NOTES)]
  },
  {
    title: 'RECEIPTS',
    key: 'RECEIPTS',
    permitedActions: [...Object.values(RECEIPTS)]
  },
  {
    title: 'SALES RETURNS',
    key: 'SALES_RETURNS',
    permitedActions: [...Object.values(SALES_RETURNS)]
  },
  {
    title: 'SALES RETURN INVOICES',
    key: 'SALES_RETURN_RECEIPTS',
    permitedActions: [...Object.values(SALES_RETURNS)]
  },
  {
    title: 'SALES INVOICE SETTLEMENT',
    key: 'SALES_INVOICE_SETTLEMENT',
    permitedActions: [...Object.values(INVOICE_SETTLEMENT)]
  },

  {
    title: 'PURCHASING ORDERS',
    key: 'PURCHASING_ORDERS',
    permitedActions: [...Object.values(PURCHASING_ORDERS)]
  },
  {
    title: 'PURCHASE RETURNS',
    key: 'PURCHASE_RETURNS',
    permitedActions: [...Object.values(PURCHASE_RETURNS)]
  },

  {
    title: 'PURCHASING INVOICES',
    key: 'PURCHASING_INVOICES',
    permitedActions: [...Object.values(PURCHASING_INVOICES)]
  },
  {
    title: 'EXPENSES',
    key: 'EXPENSES',
    permitedActions: [...Object.values(EXPENSES)]
  },

  {
    title: 'STOCK TAKE',
    key: 'STOCK_TAKE',
    permitedActions: [...Object.values(STOCK_TAKE)]
  },

  {
    title: 'DISTRIBUTOR PAYMENTS',
    key: 'DISTRIBUTOR_PAYMENTS',
    permitedActions: [...Object.values(DISTRIBUTOR_PAYMENTS)]
  },

  {
    title: 'PURCHASE CREDIT INVOICES',
    key: 'PURCHASE_CREDIT_INVOICES',
    permitedActions: [...Object.values(PURCHASE_CREDIT_INVOICES)]
  },

];

var options = [
  {
    title: 'SALES ORDERS',
    key: 'SALES_ORDERS',
    permitedActions: [...Object.values(SALES_ORDERS)]
  },
  {
    title: 'SALES INVOICES',
    key: 'SALES_INVOICES',
    permitedActions: [...Object.values(SALES_INVOICES)]
  },
  {
    title: 'CREDIT NOTES',
    key: 'CREDIT_NOTES',
    permitedActions: [...Object.values(CREDIT_NOTES)]
  },
  {
    title: 'CREDIT INVOICES',
    key: 'CREDIT_INVOICES',
    permitedActions: [...Object.values(CREDIT_NOTES)]
  },
  {
    title: 'RECEIPTS',
    key: 'RECEIPTS',
    permitedActions: [...Object.values(RECEIPTS)]
  },
  {
    title: 'SALES RETURNS',
    key: 'SALES_RETURNS',
    permitedActions: [...Object.values(SALES_RETURNS)]
  },
  {
    title: 'SALES RETURN INVOICES',
    key: 'SALES_RETURN_RECEIPTS',
    permitedActions: [...Object.values(SALES_RETURNS)]
  },
  {
    title: 'PURCHASING ORDERS',
    key: 'PURCHASING_ORDERS',
    permitedActions: [...Object.values(PURCHASING_ORDERS)]
  },
  {
    title: 'PURCHASE RETURNS',
    key: 'PURCHASE_RETURNS',
    permitedActions: [...Object.values(PURCHASE_RETURNS)]
  },
  {
    title: 'STOCK TAKE',
    key: 'STOCK_TAKE',
    permitedActions: [...Object.values(STOCK_TAKE)]
  },
  {
    title: 'EXPENSES',
    key: 'EXPENSES',
    permitedActions: [...Object.values(EXPENSES)]
  },
  {
    title: 'DISTRIBUTOR PAYMENTS',
    key: 'DISTRIBUTOR_PAYMENTS',
    permitedActions: [...Object.values(DISTRIBUTOR_PAYMENTS)]
  },

  {
    title: 'PURCHASE CREDIT INVOICES',
    key: 'PURCHASE_CREDIT_INVOICES',
    permitedActions: [...Object.values(PURCHASE_CREDIT_INVOICES)]
  },
];

class Actions extends Component {

  showConfirmCreateOrder = () => {
    const { submitOrder } = this.props;

    confirm({
      title: 'Are you sure ?',
      content: 'This will create a new order.',
      onOk() {
        submitOrder();
      },
    });
  }

  componentDidMount() {
    const { authData: { userId, roleCode } } = this.props;
    const { match } = this.props;

    const insideDistributor = match.path.includes("distributors");
    const insideSalesRep = match.path.includes("salesRepresentatives");

    if (!insideSalesRep) {
      var isSetOfAvaialbe = false
      for (let option of options) {
        if (option.key === "SET_OFF") {
          isSetOfAvaialbe = true
        }
      }

      if (!isSetOfAvaialbe) {
        options = optionsWithSettlement
      } else {
        options = options
      }

    }
  }

  getTileIcon = (title, disabled) => {
    switch (title) {
      case 'CUSTOMERS':
        return <FaUsers style={styles.tileIcon} />
      case 'DISTRIBUTOR':
        return <FaTruckMoving style={styles.tileIcon} />
      case 'SALES REPRESENTATIVES':
        return <FaUserTie style={styles.tileIcon} />
      case 'SALES ORDERS':
        return <FaCartPlus style={styles.tileIcon} />
      case 'SALES INVOICES':
        return <FaShoppingCart style={styles.tileIcon} />
      case 'RECEIPTS':
        return <FaCoins style={styles.tileIcon} />
      case 'CREDIT NOTES':
        return <MdLayersClear style={styles.tileIcon} />
      case 'CREDIT INVOICES':
        return <MdLayers style={styles.tileIcon} />
      case 'PURCHASING INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />
      case 'CALLS':
        return <FaPhoneAlt style={styles.tileIcon} />
      case 'EXPENSES':
        return <FaExternalLinkAlt style={styles.tileIcon} />
      case 'EXPENSE CATEGORIES':
        return <FaFileImport style={styles.tileIcon} />
      case 'GRNs':
        return <FaPlusCircle style={styles.tileIcon} />
      case 'COLLECTIONS':
        return '/collections';
      case 'INVENTORY':
        return <FaThList style={styles.tileIcon} />
      case 'WAREHOUSES':
        return <MdLocationCity style={styles.tileIcon} />
      case 'STOCK TRANSFERS':
        return <FaSyncAlt style={styles.tileIcon} />
      case 'STOCK TAKE':
        return <FaBalanceScaleLeft style={styles.tileIcon} />
      case 'SALES RETURNS':
        return <FaReplyAll style={styles.tileIcon} />
      case 'SALES RETURN INVOICES':
        return <FaReply style={styles.tileIcon} />
      case 'TRIP SCHEDULER':
        return <FaPaperPlane style={styles.tileIcon} />
      case 'ROUTES':
        return <FaRoute style={styles.tileIcon} />
      case 'SALES INVOICE SETTLEMENT':
        return <FaLink style={styles.tileIcon} />
      case 'PURCHASING ORDERS':
        return <FaCartPlus style={styles.tileIcon} />
      case 'PURCHASE RETURNS':
        return <FaReplyAll style={styles.tileIcon} />
      case 'DISTRIBUTOR PAYMENTS':
        return <FaCoins style={styles.tileIcon} />
      case 'PURCHASE CREDIT INVOICES':
        return <FaFileInvoice style={styles.tileIcon} />
      default:
        return null
    }
  }

  renderTile = ({ title, key }) => {
    const { distributor } = this.props;
    var disabled = key === "NEW_ORDER" && !!distributor.status;
    disabled = distributor.status === "UNVERIFIED"

    const path = disabled ? "" : this.getPath(key);

    if (path) {
      if (key === "NEW_ORDER") {
        return <div
          style={disabled ? styles.cursor_not_allowed : {}}
          onClick={() => !disabled && this.showConfirmCreateOrder()}>
          <Col className="gutter-row" xs={12} sm={8}>
            <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
              {this.getTileIcon(title, disabled)}
              <div style={disabled ? styles.disabled_tileText : styles.tileText}><h2 style={{ color: disabled ? '#999999' : '#ffffff', fontWeight: 600 }}>{title}</h2></div>
            </div>
          </Col>
        </div>
      } else {
        return (
          <Link to={path} key={key}>
            <Col className="gutter-row" xs={12} sm={8}>
              <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
                {this.getTileIcon(title)}
                <div style={styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
              </div>
            </Col>
          </Link>
        );
      }
    } else {
      return (
        <Col className="gutter-row" lg={4} xs={12} sm={6}>
          <div disabled={disabled} style={disabled ? styles.disabled_tile : styles.tile} key={key}>
            {this.getTileIcon(title)}
            <div style={disabled ? styles.disabled_tileText : styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
          </div>
        </Col>
      );
    }
  }

  getPath = key => {
    const { distributor } = this.props;
    const { match } = this.props;
    const url = match.url

    switch (key) {
      case 'VIEW_ORDERS':
        return '/distributors/' + distributor.id + '/orders'
      case 'INVOICES':
        return '/distributors/' + distributor.id + '/invoices'
      case 'NEW_ORDER':
        return '/orders/new';
      case 'COLLECTIONS':
        return url + '/collections';
      case 'NEW_COLLECTION':
        return '/distributors/' + distributor.id + '/collections/new';
      case 'NEW_SALES_INVOICE':
        return '/distributors/' + distributor.id + '/salesInvoices/new';
      case 'NEW_SALES_ORDER':
        return '/distributors/' + distributor.id + '/salesOrders/new';
      case 'NEW_CREDIT_NOTE':
        return '/distributors/' + distributor.id + '/creditNotes/new';
      case 'NEW_RECEIPT':
        return '/distributors/' + distributor.id + '/receipts/new';
      case 'NEW_EXPENSE':
        return '/distributors/' + distributor.id + '/expense/new';

      case 'SALES_INVOICES':
        return url + '/invoices';
      case 'SALES_ORDERS':
        return url + '/sales/Order';
      case 'CREDIT_NOTES':
        return url + '/sales/CreditMemo';
      case 'CREDIT_INVOICES':
        return url + '/postedCreditNotes/CreditMemo';
      case 'RECEIPTS':
        return url + '/receipts';
      case 'CALLS':
        return url + '/calls';
      case 'SALES_INVOICE_SETTLEMENT':
        return url + '/invoicesettlement';
      case 'SALES_RETURNS':
        return url + '/sales/ReturnOrder';
      case 'SALES_RETURN_RECEIPTS':
        return url + '/postedCreditNotes/ReturnOrder';
      case 'PURCHASING_ORDERS':
        return url + '/purchasing/Order';
      case 'PURCHASE_RETURNS':
        return url + '/purchasing/ReturnOrder';
      case 'PURCHASING_INVOICES':
        return url + '/purchasingInvoices';
      case 'STOCK_TAKE':
        return url + '/stockTake';
      case 'EXPENSES':
        return url + '/expenses';
      case 'DISTRIBUTOR_PAYMENTS':
        return url + '/distributorPayments';
      case 'PURCHASE_CREDIT_INVOICES':
        return url + '/purchasingCreditInvoices';
      default:
        return null;
    }
  }

  render() {
    const { loggedInUser: { permissions = [] } } = this.props;
    return (
      <Card style={{ margin: 5 }} className={'customerInfoCard'}>
        <Row gutter={10}>
          {options.map(option => option.permitedActions.filter(action => permissions.includes(action)).length > 0 && this.renderTile(option))}
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { authData: getAuthData(state), loggedInUser: getLoggedInUser(state) };
};

export default connect(mapStateToProps)(withRouter(Radium((Actions))));

const styles = {
  tile: {
    backgroundColor: ProMateGreen,
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '100%',
    width: '100%',
    padding: 10,
    marginBottom: 10,
    minHeight: 200,
    ':hover': {
      backgroundColor: ProMateGreenBorder,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileText: {
    textAlign: 'center',
    paddingTop: '5px',
    color: 'white',
  },
  disabled_tile: {
    backgroundColor: "#f1f1f1",
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  disabled_tileText: {
    textAlign: 'center',
    paddingTop: 5,
    color: 'black',
  },
  cursor_not_allowed: {
    cursor: "not-allowed"
  },
  tileIcon: {
    fontSize: 65,
    color: '#ffffff',
    paddingBottom: 4
  },
  disabledTileIcon: {
    fontSize: 65,
    color: '#999999',
    paddingBottom: 4
  },
};