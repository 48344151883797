import React, { Component } from 'react';
import { Table, Button, Tag, Spin, Popconfirm } from "antd";
import { connect } from "react-redux";
import { getAuthData, getLoggedInUser, getAllocatedDataByDocNo } from "../../../redux/reducers";
import { fetchAllocatedDetails, unallocateDetails } from "../../../redux/actions/AllocatedDetails";
import { FaSave, FaUndo, FaTrash } from "react-icons/fa";
import { format } from "currency-formatter";
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class AllocatedDetails extends Component {
  state = {
    loading: false,
    //allocatedData: []
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.docNo !== this.props.docNo) {
      //console.log("fetching allocated data from component will receive")
      this.fetchData(nextProps);
    }
  }

  fetchData = (props) => {
    const { docNo, docType, authData: { userId }, fetchAllocatedDetails } = props
    const filter = {
      DocType: docType,
      DocNo: docNo,
      UserCode: userId
    }
    if (docNo) {
      this.setState({ loading: true })
      fetchAllocatedDetails(filter).then(response => {
        //this.setState({ allocatedData: response.data })
        this.setState({ loading: false })
      });
    }

  }

  getColumnsForMobileView = () =>
    [
      {
        title: 'Details',
        dataIndex: '',
        key: '1',
        width: '55%',
        render: (Description, record) => this.renderLeftColumn(record)
      },
      {
        title: '',
        dataIndex: '',
        key: '2',
        align: 'right',
        width: '40%',
        render: (Description, record) => this.renderRightColumn(record)
      }
    ];

  renderLeftColumn = record => {
    return <div>
      <div>{record.DocType}</div>
      <div>{record.DocNo}</div>
      <div>{record.DocDate ? moment(record.DocDate).format(dateFormatView) : 'N/A'}</div>
    </div>
  }

  renderRightColumn = record => {
    return <div>
      <div style={{ fontWeight: 'bold' }}>{format(record.AllocatedAmount, currencyFormat)}</div>
    </div>
  }

  getColumns = () => {
    const { loggedInUser: { permissions = [] }, receipt } = this.props;

    const columns = [
      {
        title: 'Type',
        dataIndex: 'DocType',
        key: 'DocType',
        width: '15%'
      },
      {
        title: 'No',
        dataIndex: 'DocNo',
        key: 'DocNo',
        align: 'center',
        width: '25%',
      },
      {
        title: 'Date',
        dataIndex: 'DocDate',
        key: 'DocDate',
        align: 'center',
        width: '15%',
        render: value => value ? moment(value).format(dateFormatView) : 'N/A',
      },
      {
        title: 'Allocated Amount',
        dataIndex: 'AllocatedAmount',
        key: 'AllocatedAmount',
        align: 'right',
        width: '45%'
      }
    ];
    return columns;
  }

  isDisableRowActions = paymentLine => {
    const { paymentLineData } = this.state;

    if (!paymentLineData) return false;

    const key1 = paymentLine.LineNo;
    const key2 = paymentLineData.LineNo;

    return key1 !== key2;
  }

  renderActions = () => {
    const { enableDeallocate } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 5 }}>
        {enableDeallocate && <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.handleClickUnallocateDetails}>
          <Button type='danger' size={'small'} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}>Deallocate</Button>
        </Popconfirm>}
      </div>
    )
  }


  handleClickUnallocateDetails = () => {
    const { unallocateDetails, docNo, docType, authData: { userId }, toastManager, allocatedData } = this.props
    //const { allocatedData } = this.state;
    this.setState({ loading: true });
    const detail = {
      ...allocatedData[0],
      DocType: docType,
      DocNo: docNo,
      UserCode: userId
    }
    unallocateDetails(detail).then(response => {
      this.setState({ loading: false });
      if (!response.error) {
        toastManager.add('Successfully deallocated  settlements.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Failed to deallocated  settlements. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
      this.props.onDataAllocated(true);
      this.fetchData(this.props)
    })
  }


  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 850;
    }
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  render() {
    const { isMobileView, allocatedData = [] } = this.props;
    const columns = isMobileView ? this.getColumnsForMobileView() : this.getColumns()
    if (allocatedData.length > 0) {
      return (
        <div>
          <Spin spinning={this.state.loading} >
            <Table
              rowKey={'DocNo'}
              columns={columns}
              dataSource={allocatedData}
              size={'small'}
              scroll={{ y: this.getScrollAreaHeight() }}
              pagination={false}
              title={() => <h4>Settlement Details</h4>}
            />
            {this.renderActions()}
          </Spin>
        </div>
      )
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { docNo } = ownProps;
  const decodeId = decodeURIComponent(docNo);
  //console.log("allocated data", getAllocatedDataByDocNo(state, decodeId), decodeId)
  return {
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    allocatedData: getAllocatedDataByDocNo(state, decodeId)
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchAllocatedDetails, unallocateDetails })(AllocatedDetails)));