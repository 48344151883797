import React, { Component } from "react";
import { Col, Row, Divider } from "antd";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';

class CustomerRaw extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'UNVERIFIED':
        return 'orange';
      case 'ACTIVE':
        return 'green';
      case 'BLOCKED':
        return 'red';
      default:
        return '';
    }
  }

  render() {
    const { customer } = this.props;
    const { No, Name, Add, OutstandAmount, PhoneNo } = customer;

    return (
      <Row >
        <Row style={styles.alignRow}>
          <Col xs={12} sm={5} md={5} lg={3}>{No}</Col>
          <Col xs={0} sm={10} md={12} lg={8}><span style={styles.name}>{Name}</span></Col>
          <Col xs={0} sm={0} md={0} lg={6}><span>{Add}</span></Col>
          <Col xs={0} sm={4} md={3} lg={3}><span>{PhoneNo}</span></Col>
          <Col xs={12} sm={5} md={4} lg={4} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={OutstandAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={12} sm={0} md={0} lg={0}><span style={styles.name}>{Name}</span></Col>
          <Col xs={12} sm={0} md={0} lg={0}><span style={{ float: 'right' }}>{PhoneNo}</span></Col>
        </Row>
        <Divider style={{ margin: 5 }} />
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  }

}
export default withRouter(CustomerRaw);