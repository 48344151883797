import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import SelectWarehouseLocation from "../../common/SelectWarehouseLocation";
import { getCustomerPaymentTermsDataByCustomerId, getCustomerPaymentMethodsDataByCustomerId, getSalesReturnReasons, getSalesCreditMemoReasons, getSalesDiscountReason } from '../../../redux/reducers';
import { withRouter } from "react-router-dom";
import { dateFormatView } from '../../../const/Formats';
import { readObjectFromIndexDB } from '../../../indexDB';
import { OBJECT_KEYS, TABLES } from '../../../const/IndexDBObjects';
import { getSalesOrderType } from '../../../api/commonFetch';
import { fetchSalesDiscountReason } from '../../../redux/actions/salesDiscountReasons';
const { Option } = Select;
class OrderHeader extends Component {

  componentDidMount() {
    const { fetchSalesDiscountReason } = this.props;
    fetchSalesDiscountReason();
  }

  getStatusColorDefault = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Customer Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  getStatusColorCreditMemo = status => {
    switch (status) {
      case 'Completed':
      case 'Active':
        return 'green';
      case 'Block':
        return 'red';
      case 'Open':
      default:
        return 'orange';
    }
  }

  getStatusColor = (docType, status) => {
    switch (docType) {
      case 'CreditMemo':
        return this.getStatusColorCreditMemo(status);

      default:
        return this.getStatusColorDefault(status);
    }
  }

  renderActions = () => {
    const { order, newOrder, onSubmitOrder, onClickConfirmOrder, onShareOrder, onDeleteOrder } = this.props;
    const { salesLines, status } = order;

    if (newOrder) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitOrder}
        >
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (order.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteOrder}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       order.salesLines && order.salesLines.length > 0 &&
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (order.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       order.links && order.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareOrder()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareOrder()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  renderReturnReasonSelect = () => {
    const { order, onInputChange, match, salesReturnReasons, salesCreditMemoReasons, error } = this.props;
    const { ReturnReasonCode } = order;
    const { docType } = match.params;

    if (docType !== 'ReturnOrder' && docType !== 'CreditMemo') return null;

    let title = '';
    let placeholder = '';
    let options;
    if (docType === 'ReturnOrder') {
      title = 'Return Type';
      placeholder = 'Select Return Type';
      options = salesReturnReasons;
    } else if (docType === 'CreditMemo') {
      title = 'Type';
      placeholder = 'Select Type';
      options = salesCreditMemoReasons;
    }

    return (
      <tr style={{ justifyContent: 'center' }}>
        <td style={{ width: 120 }}>{title}</td>
        <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
        <td>
          <Select
            placeholder={placeholder}
            value={(!ReturnReasonCode || (ReturnReasonCode && ReturnReasonCode === '')) ? undefined : ReturnReasonCode}
            style={{ width: 200 }}
            onChange={(value) => onInputChange("ReturnReasonCode", value, "returnReasonError")}
          //disabled={Status === "Confirmed"}
          >
            {options.map(option => <Option value={option.Code}>{option.Description}</Option>)}
          </Select>
          {error.returnReasonError && <p style={{ color: 'red', marginBottom: 5 }}>{error.returnReasonError}</p>}
        </td>
      </tr>
    );
  }

  renderDesktopHeader = () => {
    const { order, onInputChange, error, salesLines, customerPaymentMethods, customerPaymentTerms, match, salesReturnReasons, salesDiscountReasons, salesOrderTypes } = this.props;
    const { DisPersentage, No, OrderDate, DocDate, Status, SellToCusName, SellToCusNo, PayMethodCode, PayTermCode, LocCode, CreatedUserName, DiscountReasonCode, SOType } = order;
    const { docType } = match.params
    const date = docType === 'CreditMemo' ? DocDate : OrderDate;
    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td style={{ width: 120 }}>No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {docType === 'Order' && <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Order Type</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <Select
                placeholder={'Order Type'}
                value={(!SOType || (SOType && SOType === '')) ? undefined : SOType}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("SOType", value, "SOTypeError")}
              //disabled={Status === "Confirmed"}
              >
                {salesOrderTypes.map(term => term && <Option value={term.Code}>{term.Description}</Option>)}
              </Select>
              {error.SOTypeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.SOTypeError}</p>}
            </td>
          </tr>}
          {date && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(date).format(dateFormatView)}</td>
          </tr>}
          {Status && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(docType, Status)}>{Status}</Tag>}
            </td>
          </tr>}
          {CreatedUserName && <tr>
            <td>Created User</td>
            <td style={{ width: 25, textAlign: 'center' }}>:</td>
            <td>{CreatedUserName}</td>
          </tr>}
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={0} xs={0}>
          {SellToCusNo && <tr>
            <td style={{ width: 120 }}>Customer No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusNo}</td>
          </tr>}
          {SellToCusName && <tr>
            <td style={{ width: 120 }}>Customer Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
          {docType !== 'CreditMemo' && <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>}
        </Col>
        {/* For mobile view */}
        <Col lg={0} md={0} sm={24} xs={24}>
          {SellToCusNo && <tr>
            <td style={{ width: 120 }}>Customer No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusNo}</td>
          </tr>}
          {SellToCusName && <tr>
            <td style={{ width: 120 }}>Customer Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
        </Col>
        {/* For mobile view */}
        {docType !== 'CreditMemo' && <Col lg={0} md={0} sm={24} xs={24}>
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>
        </Col>}
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 0, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          {docType === 'Order' && <tr style={{ justifyContent: 'center' }}>
            <td>Base Discount %</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <input
                className="ant-input"
                value={DisPersentage}
                style={{ width: '100%' }}
                type="number"
                min="0"
                max="100"
                disabled={salesLines && salesLines.length > 0}
                onChange={(e) => {
                  let { value } = e.target;
                  if (value < 0) value *= -1;
                  if (value > 100) value = 100;
                  onInputChange("DisPersentage", value, "discountPercentageError");
                }}
                onFocus={(event) => event.target.select()} />
            </td>
          </tr>}
          {docType === 'Order' && DisPersentage > 0 && <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Discount Reason</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>
              <Select
                placeholder={'Discount Reason'}
                value={(!DiscountReasonCode || (DiscountReasonCode && DiscountReasonCode === '')) ? undefined : DiscountReasonCode}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("DiscountReasonCode", value, "discountReasonError")}
              //disabled={Status === "Confirmed"}
              >
                {salesDiscountReasons.map(reason => reason && <Option value={reason.Code}>{reason.Description}</Option>)}
              </Select>
              {error.discountReasonError && <p style={{ color: 'red', marginBottom: 5 }}>{error.discountReasonError}</p>}
            </td>
          </tr>}
          {docType === 'Order' && <tr style={{ justifyContent: 'center' }}>
            <td>Payment Method</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>
              <Select
                placeholder={'Select Payment Method'}
                value={(!PayMethodCode || (PayMethodCode && PayMethodCode === '')) ? undefined : PayMethodCode}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("PayMethodCode", value, "payMethodCodeError")}
              //disabled={Status === "Confirmed"} 
              >
                {customerPaymentMethods.map(method => method && <Option value={method.No}>{method.Description}</Option>)}
              </Select>
              {error.payMethodCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.payMethodCodeError}</p>}
            </td>
          </tr>}
          {docType === 'Order' && <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Payment Term</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>
              <Select
                placeholder={'Select Pay Term'}
                value={(!PayTermCode || (PayTermCode && PayTermCode === '')) ? undefined : PayTermCode}
                style={{ width: '100%' }}
                onChange={(value) => onInputChange("PayTermCode", value, "payTermCodeError")}
              //disabled={Status === "Confirmed"}
              >
                {customerPaymentTerms.map(term => term && <Option value={term.No}>{term.Description}</Option>)}
              </Select>
              {error.payTermCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.payTermCodeError}</p>}
            </td>
          </tr>}
          {this.renderReturnReasonSelect()}
        </Col>
      </div>
    )
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
          {/* <Col lg={0} md={0} sm={24} xs={24}>
            {this.renderMobileHeader()}
          </Col> */}
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { order, customerId } = ownProps
  return {
    customerPaymentMethods: getCustomerPaymentMethodsDataByCustomerId(state, customerId),
    customerPaymentTerms: getCustomerPaymentTermsDataByCustomerId(state, customerId),
    salesReturnReasons: getSalesReturnReasons(state),
    salesCreditMemoReasons: getSalesCreditMemoReasons(state),
    salesOrderTypes: getSalesOrderType(state),
    salesDiscountReasons: getSalesDiscountReason(state),

  };
};

export default withRouter(connect(mapStateToProps, { fetchSalesDiscountReason })(OrderHeader));
