import Api from './consts';
import fetch from './commonFetch';

export const generate = filter => {
  const { url, method } = Api.reports.sales.create;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateReport = (object, filter) => {
  const { url, method } = Api.reports[object].create;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateGeneric = (filter, type) => {
  const { url, method } = Api.reports.generic.create;
  return fetch(url(type), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateRouteWiseDailyReport = (filter, type) => {
  const { url, method } = Api.reports.routeWiseDaily[type];
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateRouteAchievementReport = (filter, type) => {
  const { url, method } = Api.reports.routeAchievementReport[type];
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateCustomerWiseVisitReport = (filter) => {
  const { url, method } = Api.reports.customerWiseVisitReport;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateRouteWiseProductiveReport = (filter) => {
  const { url, method } = Api.reports.routeWiseProductiveReport;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateCustomerWiseDiscountReport = (filter) => {
  const { url, method } = Api.reports.customerWiseDiscountReport;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateDailyCategoryReport = (filter) => {
  const { url, method } = Api.reports.dailyCategoryReport;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}

export const generateStockCountReport = (filter) => {
  const { url, method } = Api.reports.stockCountReport;
  return fetch(url(), { method, body: JSON.stringify(filter) }, 'url');
}