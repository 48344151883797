import React, { Component } from 'react';
import { connect } from "react-redux";
import { updateStockTakeHeader, fetchStockTakesHeaders } from "../../../redux/actions/stockTakeHeaders";
import { addAttachment } from '../../../redux/actions/attachments';
import { getStockTakesHeadersByNo, getAuthData } from '../../../redux/reducers';
import { Spin, Modal } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';
import { currentLocation } from '../../../utils/Location';

const { confirm } = Modal;

const defaultOrder = {
  StockTemplateName: undefined,
  StockBatchName: undefined,
  No: undefined,
  DocDate: undefined,
  TakenBy: undefined,
  DistributorCode: undefined,
  DistributorName: undefined,
  Status: "Open",
  signedBy: undefined
}

class Container extends Component {
  state = {
    loading: false,
    orderConfirmationType: 'Customer',
    stockTake: { ...defaultOrder }
  }

  componentDidMount() {
    const { authData, match } = this.props;
    const { stockTakeId } = match.params
    const newOrder = stockTakeId === 'new';

    if (newOrder) {
      this.setState({ stockTake: { ...defaultOrder } })
    } else {
      this.setState({ loading: true })
      this.props.fetchStockTakesHeaders({ No: decodeURIComponent(stockTakeId), UserCode: authData.userId }).then(() => {
        this.setState({ loading: false })
      })
      this.setState({ stockTake: { ...defaultOrder, No: decodeURIComponent(stockTakeId) } })
    }
  }

  // componentWillReceiveProps = nextProps => {
  //   const { stockTake, match } = nextProps;
  //   const { stockTakeId } = match.params
  //   const newOrder = stockTakeId === 'new';

  //   if (newOrder) {
  //     this.setState({ stockTake: { ...defaultOrder } })
  //   } else {
  //     this.setState({ stockTake: { ...stockTake, status: stockTake.status } })
  //   }
  // }

  calculateTotalAmount = () => {
    const { stockTake = {} } = this.props;
    const { salesLines = [] } = stockTake;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  confirmOrder = (signatureImageUrl, isEmptyUrl) => {
    const { updateStockTakeHeader, toastManager, history, match, stockTake, authData, addAttachment } = this.props;
    const { orderConfirmationType } = this.state;
    const { stockTakeId } = match.params;
    const directory = 'stockTake';

    let title = 'Stock Take';

    if (!orderConfirmationType) {
      toastManager.add("You can't confirm the " + title.toLowerCase() + " without confirmation type.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const updateOrderData = { ...stockTake, UserCode: authData.userId, SignedBy: orderConfirmationType };

    updateOrderData.Status = 'Confirmed';

    this.setState({ loading: true });

    const update = () => {
      updateStockTakeHeader(updateOrderData).then(response => {
        this.setState({ loading: false })
        if (!response.error) {
          toastManager.add(title + ' confirmed.', { autoDismiss: true, appearance: 'success' });
          window.history.back();
        } else {
          toastManager.add('Could not confirm the ' + title.toLowerCase() + '. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    }

    if (orderConfirmationType === "OTPO") {
      update();
    } else {
      if (isEmptyUrl) {
        this.setState({ loading: false })
        toastManager.add("You can't confirm the " + title.toLowerCase() + " without providing the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const imageFile = this.getImageFile(signatureImageUrl);

      upload(imageFile, directory + "/" + stockTakeId, 'signature-' + new Date().getTime()).then(response => {
        if (response.error) {
          toastManager.add("Could not upload the signature.", { autoDismiss: true, appearance: 'error' });
          return;
        }

        const link = {
          AttachmentName: 'StockAttachment',
          //DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
          DocNo: stockTake.No,
          AttachmentURL: response.path,
          UserCode: authData.userId
        };

        addAttachment('StockAttachment', link).then(response => {
          if (response.error) {
            toastManager.add("Could not link the signature.", { autoDismiss: true, appearance: 'error' });
            this.setState({ loading: false });
            // return; // TODO
          }

          update();
        });
      });
    }
  }

  showConfirmOrderConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    let title = 'Stock Take';

    confirm({
      title: 'Are you sure ?',
      content: "After confirm you can't update the " + title.toLowerCase() + '.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        context.confirmOrder(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  onChangeOrderConfirmationType = type => {
    this.setState({ orderConfirmationType: type });
  }

  render() {
    const { loading,/* stockTake,*/ orderConfirmationType } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading stockTake"}>
        {/* <OrderHeader stockTake={stockTake} />
        <SalesLineSummary stockTake={stockTake} />
        <OrderFooter totals={this.calculateTotalAmount()} /> */}
        <Pad orderConfirmationType={orderConfirmationType} onConfirmationTypeChange={this.onChangeOrderConfirmationType} onConfirmOrder={(signatureImageUrl, isEmptyUrl) => this.showConfirmOrderConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakeId } = ownProps.match.params
  const decodeId = decodeURIComponent(stockTakeId);

  return {
    stockTake: getStockTakesHeadersByNo(state, decodeId),
    authData: getAuthData(state)
  };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchStockTakesHeaders, updateStockTakeHeader, addAttachment: addAttachment })(Container)));