import React from 'react';
import { Row, Col } from "antd";

const Footer = (props) => {
  const { filteredWarehouses } = props;
  return (
    <div style={{
      backgroundColor: '#eceaea',
      height: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: -10,
      padding: 10
    }}>
      <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
        <Col span={12}>{filteredWarehouses.length > 0 ? 'Total ' + filteredWarehouses.length : 'No'} warehouse{filteredWarehouses.length !== 1 && 's'}</Col>
        {/* <Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
          <CurrencyFormat
            value={getWarehousesTotal(filteredWarehouses)}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            decimalScale={2} />
        </Col> */}
      </Row>
    </div>
  )
}

export default Footer