import Api from './consts';
import fetch from './commonFetch';

export const getAll = () => {
  const { url, method } = Api.inventory.getAll;
  return fetch(url(), { method }, 'items');
}

export const get = id => {
  const { url, method } = Api.inventory.get;

  return fetch(url(id), { method }, 'item');
}