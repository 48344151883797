import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';
const { Option } = Select;
class ReceiptHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Unverified':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  renderActions = customerSigned => {
    const { newReceiptId, onSaveReceipt, onConfirmReceipt, onDeleteReceipt } = this.props;

    if (newReceiptId === "new") {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        <Button type="primary" onClick={onSaveReceipt}>Create</Button>
      </Col></Row>
    } else {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!customerSigned && <div style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={onDeleteReceipt}
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Col>
          <Col>
            {/* <Popconfirm
            title="Are you sure ?"
            okText="Update"
            cancelText="No"
            onConfirm={onSaveCollection}
          >
            <Button type="primary">Update</Button>
          </Popconfirm> */}
            <Button type="primary" onClick={onSaveReceipt}>Update</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onConfirmReceipt}>Confirm</Button>
          </Col></div>}
        {customerSigned && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}>Print & Share</Button>
        </Col>}
      </Row>
    }
  }

  renderCustomer = () => {
    const { handleInputChange, setDefaultCustomerOption, customerId, customers, isFetchingCustomers, disableCustomerSelect } = this.props;
    //console.log("isFetchingCustomers", isFetchingCustomers)
    const disableSelect = disableCustomerSelect || customers.length < 2;
    return (
      <div className="promate-table-header">
        <Select
          disabled={disableSelect}
          placeholder={'Select Customers'}
          value={customerId}
          style={{ width: 250 }}
          onChange={(value) => handleInputChange('customerId', value)}
          showSearch={true}
          filterOption={(inputValue, option) => {
            const text = option.props.children.toLowerCase();
            return text.indexOf(inputValue.toLowerCase()) > -1;
          }}
          loading={isFetchingCustomers}
        >
          {customers.map(customer =>
            <Option value={customer.No}>{customer.Name}</Option>
          )}
        </Select>
      </div>
    )
  }

  render() {
    const { newReceiptId, receipt = {}, customer } = this.props;
    const { receiptDate, sellToCustomerCode, sellToCustomerName, status } = receipt;

    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {/* {customer && <tr>
              <td>Customer ID</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{customer.No}</td>
            </tr>}
             <tr>
              <td>Customer ID</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{customer.Name}</td>
            </tr>} */}

            {<tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment().format(dateFormatView)}</td>
            </tr>}

          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <tr>
              <td>Customer</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{this.renderCustomer()}</td>
            </tr>

          </Col>
        </Row>
      </div>
    )
  }
}

export default ReceiptHeader;