import { combineReducers } from 'redux';
import { FETCH_PURCHASING_INVOICE_LINES, FETCH_PURCHASING_INVOICE_LINE } from '../actions/purchasingInvoiceLines';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_LINES:
      const newState = { ...state };
      action.purchasingInvoiceLines.forEach(purchasingInvoiceLine => {
        const { Type, DocNo, LineNo } = purchasingInvoiceLine;
        const key = DocNo + '-' + LineNo;
        //console.log("key", key)
        newState[key] = purchasingInvoiceLine;
      });
      return newState;
    case FETCH_PURCHASING_INVOICE_LINE: {
      const newState = { ...state };
      const { Type, DocNo, LineNo } = action.purchasingInvoiceLine;
      const key = DocNo + '-' + LineNo;
      newState[key] = action.purchasingInvoiceLine;
      return newState;
    }
    default:
      return state;
  }
};

const getKey = line => {
  const { DocType, DocNo, LineNo } = line;
  return DocNo + '-' + LineNo;
}


export const ids = (state = [], action) => {

  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_LINES: {
      const newState = [...state]
      action.purchasingInvoiceLines.forEach(line => {
        const key = getKey(line);
        if (!newState.includes(key)) newState.push(key);
      });

      return newState;
    }
    case FETCH_PURCHASING_INVOICE_LINE: {
      const newState = [...state];
      const key = getKey(action.purchasingInvoiceLine);

      if (!newState.includes(key)) {
        newState.push(key)
      }
      return newState;
    }
    default:
      return state;
  }
};

export const byTypeAndDocNo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_LINES: {
      const newState = { ...state };
      action.purchasingInvoiceLines.forEach(purchaseLine => {
        const key = purchaseLine.DocNo
        const idsByTypeAndNo = newState[key] || [];

        const { DocNo, LineNo } = purchaseLine;
        const id = DocNo + '-' + LineNo;
        if (!idsByTypeAndNo.includes(id)) {
          idsByTypeAndNo.push(id);
          newState[key] = idsByTypeAndNo;
        }
      });
      return newState;
    }
    case FETCH_PURCHASING_INVOICE_LINE: {
      const newState = { ...state };
      const key = action.purchasingInvoiceLine.DocType + '-' + action.purchasingInvoiceLine.DocNo
      const idsByType = newState[key] || [];

      const { DocType, DocNo, LineNo } = action.purchasingInvoiceLine;
      const id = DocType + '-' + DocNo + '-' + LineNo;

      if (!idsByType.includes(id)) {
        idsByType.push(id);
        newState[key] = idsByType;
      }

      return newState;
    }
    default:
      return state;
  }
};

const purchasingInvoiceLines = combineReducers({
  byId,
  ids,
  byTypeAndDocNo
});

export default purchasingInvoiceLines;

export const getInvoiceLinesByDocTypeAndDocNo = (state, type, no) => {
  const key = no;
  const ids = state && state.byTypeAndDocNo[key] || [];

  return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

// export const getSalesLinesByDocTypeAndItemNo = (state, type, itemNo) => {
//   const ids = state.ids || [];
//   return state.ids.map(id => state.byId[id]).filter(line => line && line.No === itemNo && line.DocType === type)

// }

export const getPurchaseInvoiceLineMapByDocTypeAndDocNo = (state, type, no) => {
  const key = type + '-' + no;
  const ids = state.byTypeAndDocNo[key] || [];

  const purchaseLineMap = {};
  ids && ids.length > 0 && ids.forEach(id => {
    const { No, LotNo, LocCode } = state.byId[id];
    const key = No + '-' + LotNo;

    purchaseLineMap[key] = state.byId[id];
  });

  return purchaseLineMap;
}

export const getSalesLinesByDocTypeAndItemNo = (state, type, itemNo) => {
  const ids = state.ids || [];
  return ids.map(id => state.byId[id]).filter(line => line && line.No === itemNo && line.DocType === type)
}

export const getInvoiceLinesByDocNo = (state, type, no) => {
  const ids = state.ids || [];
  return ids.map(id => state.byId[id]).filter(line => line && line.DocNo === no)
}

export const getInvoiceLine = (state, type, docNo, lineNo) => {
  return state.byId[type + '-' + docNo + '-' + lineNo];
}