import React, { Component } from "react";
import { TreeSelect } from "antd";
import { connect } from "react-redux";
import { getItemFilterOptions, getItemFilterOptionsFromAllItems } from "../../redux/reducers";

class SelectCategoryTree extends Component {

  render() {
    return (
      <TreeSelect
        treeData={this.props.filterTree}
        value={this.props.itemFilter}
        onChange={this.props.onChange}
        treeCheckable={true}
        treeCheckStrictly={true}
        searchPlaceholder='Please select categories'
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: window.innerHeight * 0.6 }} />
    )
  }
}

const generateItemFilterTree = state => {
  const optionsToTreeChildren = (option, filterFieldId) => ({ title: option, value: filterFieldId + '|' + option, key: option });
  const filterFieldToTreeParent = filterField => ({
    title: filterField.description,
    value: filterField.id,
    key: filterField.id,
    disableCheckbox: true,
    children: getItemFilterOptions(state, filterField.id).map(option => optionsToTreeChildren(option, filterField.id))
  });

  const filterFields = [{
    id: 'Brand',
    description: 'Brand'
  }, {
    id: 'SubCategoryCode1',
    description: 'Category 1'
  }, {
    id: 'SubCategoryCode2',
    description: 'Category 2'
  }, {
    id: 'SubCategoryCode3',
    description: 'Category 3'
  }, {
    id: 'SubCategoryCode4',
    description: 'Category 4'
  }];
  const tree = filterFields.map(filterFieldToTreeParent);
  return tree;
}

const generateItemFilterTreeForAllItems = state => {
  const optionsToTreeChildren = (option, filterFieldId) => ({ title: option, value: filterFieldId + '|' + option, key: option });
  const filterFieldToTreeParent = filterField => ({
    title: filterField.description,
    value: filterField.id,
    key: filterField.id,
    disableCheckbox: true,
    children: getItemFilterOptionsFromAllItems(state, filterField.id).map(option => optionsToTreeChildren(option, filterField.id))
  });

  const filterFields = [{
    id: 'Brand',
    description: 'Brand'
  }, {
    id: 'SubCategoryCode1',
    description: 'Category 1'
  }, {
    id: 'SubCategoryCode2',
    description: 'Category 2'
  }, {
    id: 'SubCategoryCode3',
    description: 'Category 3'
  }, {
    id: 'SubCategoryCode4',
    description: 'Category 4'
  }];
  const tree = filterFields.map(filterFieldToTreeParent);
  return tree;
}

const mapStateToProps = (state, props) => {
  const { inventoryType } = props;
  //console.log(inventoryType)
  return {
    filterTree: inventoryType && inventoryType === "AllItems" ? generateItemFilterTreeForAllItems(state) : generateItemFilterTree(state),
  };
};

export default connect(mapStateToProps)(SelectCategoryTree);

export const filterItemsByCategory = (items, itemFilter) => {
  if (itemFilter.length > 0) {
    const itemFilterOptionMap = {};
    itemFilter.forEach(selection => {
      const parts = selection.value.split('|');
      const filterField = parts[0];
      const filterOption = parts[1];

      const options = itemFilterOptionMap[filterField] || [];
      options.push(filterOption);
      itemFilterOptionMap[filterField] = options;
    });

    return items.filter(item => {
      let isAMatch = true;
      Object.keys(itemFilterOptionMap).forEach(filterField => {
        const options = itemFilterOptionMap[filterField];
        if (!options.includes(item[filterField])) {
          isAMatch = false;
        }
      });
      return isAMatch;
    });
  } else {
    return items;
  }
};