import React, { Component } from 'react';
import { PageHeader } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import { withRouter } from 'react-router-dom';
import PromatePageHeader from '../../common/PromatePageHeader';


class StockTakeItemView extends Component {
  renderContainer = () => {
    const { docType, lineId } = this.props.match.params;
    if (docType === 'ReturnOrder') {
      return lineId === 'new' ? null : <Container />;
    } else {
      return <Container />;
    }
  }

  render() {
    const { docType } = this.props.match.params;
    const { history, location } = this.props;
    return (
      <div>
        <PromatePageHeader
          title={'Item Info'}
          style={styles.pageHeader}
          onBack={() => {
            if (docType === 'ReturnOrder') {
              history.goBack()
            } else {
              if (location.state && location.state.prevPath && location.state.currentPage) {
                history.replace({ pathname: location.state.prevPath, state: { currentPage: location.state.currentPage } })
              } else {
                history.goBack();
              }
            }
          }}>
          {this.renderContainer()}
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default withRouter(StockTakeItemView);