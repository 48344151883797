import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { CUSTOMER_FREQUENCY } from '../../../const/Permissions';
import { ProMateGreen } from "../../../const/Theme";
import { fetchRoutes } from '../../../redux/actions/routes';
import { updateCustomerFrequency } from '../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { getAuthData, getSalesUserHierarchy } from "../../../redux/reducers";
import PromatePageHeader from '../../common/PromatePageHeader';
import { DataTable } from './components/DataTable';
import Header from './components/Header';

export class CustomerFrequency extends Component {
  state = {
    loading: true,
    tableData: {
      records: []
    },
    filter: {
      Month: "JAN",
      Year: new Date().getFullYear(),
      RouteCode: null,
      UserTag: null
    },
    selectedRowKeys: []
  };

  componentDidMount = async () => {
    const { fetchSalesUserHierarchy, authData, history } = this.props;
    if (!authData.permissions.includes(CUSTOMER_FREQUENCY.VIEW)) {
      history.push('/targetScheduler');
      return;
    }
    await fetchSalesUserHierarchy(authData);
  }

  componentWillUnmount = () => {
    this.setLoading(false);
  }

  setLoading = (value) => {
    this.setState({
      loading: value,
    });
  }

  setTableData = (data) => {
    this.setState({
      tableData: data
    });
  }

  setSelectedRowKeys = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys
    });
  }

  handleUpdate = async (record) => {
    this.setState({
      loading: true
    });
    const res = await this.props.updateCustomerFrequency(record);
    if (!res.errors) {
      this.props.toastManager.add('Visit Frequency was successfully updated.', { autoDismiss: true, appearance: 'success' });
    } else {
      this.props.toastManager.add('Visit Frequency update was failed.', { autoDismiss: true, appearance: 'error' });
    }
    this.setState({
      loading: false
    });
  }

  handleAllUpdate = async () => {
    if (this.state.selectedRowKeys.length == 0) {
      this.props.toastManager.add('No items are selected.', { autoDismiss: true, appearance: 'info' });
    } else {
      this.setState({
        loading: true
      });
      for (const key of this.state.selectedRowKeys) {
        const record = this.state.tableData.records.find((record) => (record.CustCode == key));
        await this.props.updateCustomerFrequency(record);
      }
      this.props.toastManager.add('Visit Frequency was successfully updated.', { autoDismiss: true, appearance: 'success' });
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const { salesUserHierarchy, authData } = this.props;
    const hierarchy = salesUserHierarchy[Object.keys(salesUserHierarchy)[0]];

    return (
      <>
        <PromatePageHeader
          title="Customer Frequency"
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          showCallButton={false}
        >
          <Header
            loading={this.state.loading}
            setLoading={this.setLoading}
            setTableData={this.setTableData}
            handleAllUpdate={this.handleAllUpdate}
            permissions={authData?.permissions ?? []}
          />
          <DataTable
            setSelectedRowKeys={this.setSelectedRowKeys}
            selectedRowKeys={this.state.selectedRowKeys}
            loading={this.state.loading}
            setTableData={this.setTableData}
            tableData={this.state.tableData}
            handleUpdate={this.handleUpdate}
            positionType={hierarchy?.positionType}
            permissions={authData?.permissions ?? []}
          />
        </PromatePageHeader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  salesUserHierarchy: getSalesUserHierarchy(state),
  authData: getAuthData(state),
})

const mapDispatchToProps = {
  fetchSalesUserHierarchy,
  updateCustomerFrequency,
  fetchRoutes
}

export default withRouter(withToastManager(connect(mapStateToProps, mapDispatchToProps)(CustomerFrequency)));

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  }
}