let demoPurchasingOrders = []
export const names = ["Andrew Stephen", "Simon Cowel", "Mitchell Richedson", "Daren Iyan", "Brayan Adems", "Nicoles Stephen", "Theron M Goodman", "Darren Lara", "Elizabeth Mendella"];


export const generateDemoStockTransfers = () => {
  demoPurchasingOrders = []
  demoPurchasingOrders.push(defaultOrder)

  for (let i = 0; i < 25; i++) {
    demoPurchasingOrders.push({
      stockTransferId: "Transfer " + i,
      fromWarehouseCode: "Warehouse Code " + getRandomNumber(1, 5),
      fromLocationCode: "Location Code " + getRandomNumber(1, 10),
      toWarehouseCode: "Warehouse Code " + getRandomNumber(1, 5),
      toLocationCode: "Location Code " + getRandomNumber(1, 10),
      fromWarehouseName: "Warehouse" + getRandomNumber(1, 5),
      fromLocationName: "Location" + getRandomNumber(1, 10),
      toWarehouseName: "Warehouse" + getRandomNumber(1, 5),
      toLocationName: "Location" + getRandomNumber(1, 10),
      transferDate: randomDate(new Date(2012, 0, 1), new Date()),
      salesLines: getSalesLines("Transfer " + i),
      transferdBy: "Code " + getRandomNumber(1, 10),
      transferdByName: names[getRandomNumber(0, names.length)],
      totalDiscountAmount: 0,
      totalAmount: getRandomNumber(1000, 11000),
      amountIncludingVat: randoNumber(),
      status: i === 2 ? "Open" : randomStatus(),
      sessionId: randoNumber(),
      remarks: "Stock Transfer remark, This contains special note about the stock transfer"
    })
  }

  return demoPurchasingOrders;
}

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from);

const randoNumber = () => {
  return Math.floor((Math.random() * 50000) + 1);
}

const randomStatus = () => {
  const statuses = ["Open", "Cancelled", "Confirmed"];
  return statuses[Math.floor((Math.random() * 3) + 0)];
}

const getSalesLines = orderNo => {
  let salesLines = [];
  const itemLength = orderNo === "order 2" ? 1 : 5;

  for (let i = 0; i < itemLength; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      orderNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      fromQuantity: getRandomNumber(10000, 29000),
      transferQuantity: getRandomNumber(100, 290),
      lineAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

export const getDemoOrders = () => {
  return demoPurchasingOrders;
}

export const getDemoTransfer = id => {
  const demoOrder = demoPurchasingOrders.find(stockTransfer => stockTransfer.stockTransferId === id);
  return demoOrder;
}

export const getSalesLineItem = orderNo => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      orderNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      fromQuantity: getRandomNumber(10000, 29000),
      transferQuantity: getRandomNumber(100, 290),
      lineAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

const defaultOrder = {
  stockTransferId: "new",
  fromWarehouseCode: "Warehouse Code " + getRandomNumber(1, 5),
  fromLocationCode: "Location Code " + getRandomNumber(1, 10),
  toWarehouseCode: "Warehouse Code " + getRandomNumber(1, 5),
  toLocationCode: "Location Code " + getRandomNumber(1, 10),
  fromWarehouseName: "Warehouse 1",
  fromLocationName: "Location 2",
  toWarehouseName: "Warehouse 3",
  toLocationName: "Location 4",
  transferDate: randomDate(new Date(2012, 0, 1), new Date()),
  salesLines: getSalesLines("new"),
  transferdBy: "Code " + getRandomNumber(1, 10),
  transferdByName: "Andrew Stephen",
  totalDiscountAmount: 0,
  totalAmount: 54231,
  amountIncludingVat: 562349,
  status: "Open",
  remarks: "This is a demo remark for the stock tranfer",
  sessionId: 123465446
}