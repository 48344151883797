import Api from './consts';
import fetch from './commonFetch';

export const getAll = (filter = {}) => {
  const { url, method } = Api.generic.get;
  return fetch(url('Route'), { method, body: JSON.stringify(filter) }, 'routes');
}

export const update = body => {
  const { url, method } = Api.generic.update;
  return fetch(url('Route'), { method, body: JSON.stringify(body) }, 'route');
}