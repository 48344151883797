import { CALLS, DISTRIBUTORS, TRIP_SCHEDULER } from "./Permissions"

export const TABLES = {
  CUSTOMERS: 'Customers',
  COMMON: 'Common',
  SALES_ORDERS: 'salesOrders',
  IMAGES: 'Images',
  CALLS: 'Calls',
  TRIP_SCHEDULES: 'TripSchedules',
  SALES_INVOICES: 'salesInvoices',
  INVENTORY: 'inventory'
}

export const OBJECT_KEYS = {
  OFFLINE_CUSTOMER_DATA: 'offlineCustomerData',
  CUSTOMER_DATA: 'customerData',
  CUSTOMER_CATEGORIES: 'customerCategories',
  FILTERED_ROUTES: 'filteredRoutes',
  PAYMENT_TERMS: 'paymentTerms',
  PAYMENT_METHODS: 'paymentMethods',
  DISTRIBUTORS: 'distributors',
  SALES_HEADERS: 'salesHeaders',
  USER_WISE_LOCATON: 'userWiseLocation',
  ITEMS: 'items',
  OFFLINE_SALES_HEADERS: 'offlineSalesHeaders',
  OFFLINE_SALES_LINE: 'offlineSalesLine',
  SALES_LINE: 'salesLine',
  CUSTOMER_OFFLINE_PAYMENT_TERMS: "customerOfflinePaymentTerms",
  CUSTOMER_OFFLINE_PAYMENT_METHODS: "customerOfflinePaymentMethods",
  CALL_START_REASONS: 'callStartReasons',
  CALL_END_REASONS: 'callEndStatuses',
  OFFLINE_CALL_DATA: 'offlineCallData',
  SIGNATURES: 'signatures',
  TRIP_SCHEDULER: 'TripSchedular',
  DISCOUNT_REASONS: 'salesDiscountReasons',
  IS_SYNC_SUCCEED: 'isSyncSucceed',
  INVOICES: 'invoices',
  SALES_RETURN_REASONS: 'salesReturnReasons',
  OFFLINE_SALES_RETURN_HEADERS: 'offlineSalesReturnHeaders',
  OFFLINE_SALES_RETURN_LINES: 'offlineSalesReturnLines',
  SALES_RETURN_SIGNATURES: 'salesReturnSignatures',
  USER_HIERARCHY: 'userHierarchy'
}
