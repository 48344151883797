import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin, Button } from 'antd';
import { ProMateGreen } from "../../const/Theme";
import PromatePageHeader from '../common/PromatePageHeader';
import TripSchedulerTable from "./TripSchedulerTable";
import { fetchTripSchedules, updateTripSchedule, deleteTripSchedule, addTripSchedule } from "../../redux/actions/tripSchedules";
import { getAuthData, getFlatPositions, getTopPosition, isFetchingTripSchedulars, getTripSchedules, getTripSchedulesBySearchKey, getLoggedInUser } from "../../redux/reducers";
import { showLoadingMessage } from '../common/Message';
import moment from "moment";
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { handleUrlSearchParams, redirectToUrlState, getSearchKey } from '../../utils/Search';
import { withRouter } from 'react-router';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import { TRIP_SCHEDULER } from "../../const/Permissions";
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { withToastManager } from 'react-toast-notifications';
import uuid from "uuid/v1";
import { addListener, removeListener, locationEnabled } from '../../utils/Location';
import { formatGeoLocation } from "../../utils/Formats";

import { FaPlus } from 'react-icons/fa';

const BASE_URL = '/tripScheduler';

class TripScheduler extends Component {

  state = {
    submitting: false,
    filter: {
      UserCode: undefined,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1,

    existingSchedule: undefined,
    schedule: undefined,
    routeError: undefined,
    csrError: undefined,
    distributorStaffError: undefined,
    warehouseError: undefined,
    typeError: undefined,
    recallError: undefined,
    startDateError: undefined,
    MapLocation: undefined,
  }

  componentDidMount() {
    const { authData } = this.props;
    //this.loadSchedules(this.props);
    this.setState({
      loading: true,
      filter: {
        ...this.state.filter,
        UserCode: authData.userId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    handleUrlSearchParams(BASE_URL, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadSchedules(nextProps);
      });
    }, this.state.filter);
    this.setDeviceMapLocation();
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  loadSchedules = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchSchedules(filter, currentPage, currentSearchKey);
  }

  fetchSchedules = (newfilter, page, searchKey) => {
    const { filter } = this.state;
    const updatedFilter = {
      ...newfilter,
      StartingDateTimeFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      text: undefined,
      Remarks: filter.text ? '*' + filter.text + '*' : undefined
    }
    this.props.fetchTripSchedules(this.getRequestFilter(updatedFilter), page, searchKey);
  }

  getRequestFilter = filter => {
    const requestFilter = { ...filter };
    requestFilter.likeName = requestFilter.text;
    requestFilter.text = undefined;

    return requestFilter;
  }

  // fetchSchedules = () => {
  //   const { filter } = this.state;
  //   const updatedFilter = {
  //     ...filter,
  //     StartDateFilter: moment(filter.from).format(dateFormatView) + '..' + moment(filter.to).format(dateFormatView),
  //   }

  //   this.showLoadingMessage();
  //   this.setState({ loading: false, filter: { ...updatedFilter } })
  //   this.props.fetchTripSchedules(updatedFilter).then((res) => {
  //     this.hideLoadingMessage()
  //     this.setState({ loading: false })
  //   })
  // }

  handlePaginationChange = page => this.redirectToUrlState(page);

  fetchfetchSchedulesPage = page => {
    this.setState({ currentPage: page });
    this.fetchFilteredSchedules(this.state.filter, page);
  }

  redirectToUrlState = (page = 1) => redirectToUrlState(BASE_URL, this.props, this.state.filter, page);

  fetchSchedularPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchTripSchedules(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchSchedules(filter, currentPage, currentSearchKey);
  }

  // handleInputTextChange = (filter, value) => {
  //   switch (filter) {
  //     case 'UserCode':
  //       this.setState({ filter: { ...this.state.filter, [filter]: value, RouteCode: undefined } });
  //       break;
  //     default:
  //       this.setState({ filter: { ...this.state.filter, [filter]: value } });
  //       break;
  //   }
  // }

  handleInputTextChange = (filter, value, forceReload) => {
    //console.log(filter, value)
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, newFilter, flatPositions, topPosition);
        break;
      default:
        break;
    }

    //console.log(newFilter)

    this.setState({ filter: newFilter }, () => {

      if (forceReload) {
        this.handleSearchClick();
      }
    });
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing schedules.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }


  handleAdd = () => {
    const newData = {
      EntryNo: 'New',
      TripType: undefined,
      RouteCode: undefined,
      CSRCode: undefined,
      DistributorStaffCode: undefined,
      LocCode: undefined,
      StartDateTime: undefined,
      EndDateTime: undefined,
      RecallDateTime: undefined,
      Status: 'New'
    };

    this.setState({ schedule: newData });
  };

  handleDuplicate = record => {
    const newData = {
      ...record,
      EntryNo: 'New ' + uuid(),
      StartDateTime: moment().add('minutes', -330).format(dateTimeFormatFilterUpdate),
      EndDateTime: undefined,
      RecallDateTime: undefined,
      Status: 'New'
    };

    this.setState({ schedule: newData, existingSchedule: newData });
  };

  // handleTableInputTextChange = (field, value, record, errorField) => {
  //   const schedule = this.state.schedule ? this.state.schedule : record;
  //   const existingSchedule = this.state.existingSchedule ? this.state.existingSchedule : undefined;
  //   if (field === "Remarks") {
  //     this.setState({ schedule: { ...schedule, ['Remarks']: value }, existingSchedule: { ...existingSchedule, ['Remarks']: value }, [errorField]: undefined })
  //   } else {
  //     this.setState({ schedule: { ...schedule, [field]: value },  existingSchedule, [errorField]: undefined })
  //   }
  // }

  handleTableInputTextChange = (field, value, record, errorField) => {
    const existingSchedule = this.state.existingSchedule ? this.state.existingSchedule : { ...record }
    this.setState({ schedule: { ...this.state.schedule, [field]: value }, existingSchedule, [errorField]: undefined })
  }

  handleTableInputDateChange = (field, value, record, errorField) => {
    const existingSchedule = this.state.existingSchedule ? this.state.existingSchedule : { ...record }
    this.setState({ schedule: { ...this.state.schedule, [field]: moment(value).add('minutes', -330).format(dateTimeFormatFilterUpdate) }, existingSchedule, [errorField]: undefined })
  }

  handleResetItem = () => this.setState({
    existingSchedule: undefined,
    schedule: undefined,
    routeError: undefined,
    csrError: undefined,
    distributorStaffError: undefined,
    warehouseError: undefined,
    typeError: undefined,
    recallError: undefined,
    startDateError: undefined
  });

  // handleUpdateStatus = (record, status) => {
  //   const schedule = this.state.schedule ? this.state.schedule : record;
  //   const existingSchedule = this.state.existingSchedule ? this.state.existingSchedule : record;
  //   this.setState({ schedule: { ...schedule, Status: status }, existingSchedule }, this.updateStatus)
  // }

  handleUpdateStatus = (record, status) => {
    const existingSchedule = this.state.existingSchedule ? this.state.existingSchedule : { ...record }
    this.setState({ schedule: { ...this.state.schedule, ['Status']: status }, existingSchedule }, this.handleSave)
  }

  handleDelete = schedule => {
    const { deleteTripSchedule } = this.props;
    const deleteRequest = { ...schedule, SessionID: uuid() };

    this.handleAction(deleteTripSchedule, deleteRequest);
  };

  handleSave = () => {
    const { schedule, existingSchedule } = this.state;
    const { updateTripSchedule, addTripSchedule } = this.props;
    if (!existingSchedule.EntryNo.includes('New')) {
      if (!this.validateSchedule(existingSchedule, schedule)) return;

      //Update
      const updatedSchedule = {
        ...existingSchedule,
        ...schedule,
        SessionID: uuid()
      }

      this.handleAction(updateTripSchedule, updatedSchedule);
    } else {
      //Create
      if (!this.validateSchedule(schedule)) return;
      const updatedSchedule = {
        ...schedule,
        SessionID: uuid(),
        EntryNo: 1,
        MapLocation: this.state.MapLocation,
      }

      this.handleAction(addTripSchedule, updatedSchedule);
    }
  };

  updateStatus = () => {
    const { schedule } = this.state;
    const { updateTripSchedule } = this.props;
    if (!this.validateSchedule()) return;

    const updatedSchedule = {
      ...schedule,
      SessionID: uuid()
    }

    this.handleAction(updateTripSchedule, updatedSchedule);
  };

  handleAction = (action, data) => {
    //console.log(action, data)
    const { toastManager } = this.props;

    this.setState({ submitting: true });
    action(data).then(response => {
      //console.log(response)
      this.setState({ submitting: false });
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        this.setState({
          existingSchedule: undefined,
          schedule: undefined,
          routeError: undefined,
          csrError: undefined,
          distributorStaffError: undefined,
          warehouseError: undefined,
          typeError: undefined,
          recallError: undefined,
          startDateError: undefined
        })
        this.handleSearchClick();
      }
    })
  }

  validateSchedule = (existingSchedule, schedule) => {
    const updatedSchedule = {
      ...existingSchedule,
      ...schedule,
    }
    //console.log(updatedSchedule)
    const { RouteCode, TripType, CSRCode, DistributorStaffCode, LocCode, Status, RecallDateTime, StartDateTime, EndDateTime } = updatedSchedule;

    let typeError, routeError, csrError, distributorStaffError, warehouseError, recallError, startDateError = undefined;

    if (!this.isValidValue(TripType)) typeError = 'Trip type is required.';
    if (!this.isValidValue(RouteCode)) routeError = 'Route is required.';
    if (schedule && schedule.Status === 'Recalled' && !RecallDateTime) recallError = 'Recall time is required.';
    if (!StartDateTime || StartDateTime === "Invalid date") startDateError = 'Start Date is required.';
    if (StartDateTime && EndDateTime && !moment(EndDateTime).isAfter(StartDateTime)) startDateError = 'Start date must be ealier than the end date';
    if (RecallDateTime && EndDateTime && !moment(EndDateTime).isAfter(RecallDateTime)) recallError = 'Recall date must be ealier than the end date';

    switch (TripType) {
      case 'Conventional':
        csrError = !this.isValidValue(CSRCode) && 'Salesperson is required.';
        warehouseError = !this.isValidValue(LocCode) && 'Location is required';
        break;
      case 'Pre Selling':
        csrError = !this.isValidValue(CSRCode) && 'Salesperson is required.';
        warehouseError = !this.isValidValue(LocCode) && 'Location is required.';
        break;
      default:
        break;
    }

    this.setState({ typeError, routeError, csrError, distributorStaffError, warehouseError, recallError, startDateError })
    //console.log(!typeError, !routeError, !csrError, !warehouseError, !recallError, !startDateError)
    return !typeError && !routeError && !csrError && !warehouseError && !recallError && !startDateError;
  }



  isValidValue = value => {
    if (!value) return false;
    const spaceRemovedValue = value.replace(/\s/g, '');

    return spaceRemovedValue.length > 0
  }


  render() {
    const { submitting, filter, currentPage } = this.state;
    const { fetchingSchedulers, schedules, loggedInUser: { permissions = [] }, topPosition } = this.props;
    return (
      <div>
        <PromatePageHeader
          title={'Trip Scheduler'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={permissions.includes(TRIP_SCHEDULER.CREATE) && [
            <Button disabled={this.state.schedule} onClick={() => this.handleAdd()}
              style={window.innerWidth < 768 ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                window.innerWidth < 768 ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>
        </PromatePageHeader>

        <Spin spinning={(fetchingSchedulers && schedules.length === 0) || !topPosition || submitting} tip={submitting ? "Updating schedules" : "Loading schedules"}>
          <div>
            <TripSchedulerTable
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              filter={filter}
              screenHeight={this.context.screenHeight}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              handleSearchClick={this.handleSearchClick}
              currentPage={currentPage}
              newClicked={this.state.newClicked}
              setNewButtonDisableValue={this.setNewButtonDisableValue}
              {...this.state}
              handleTableInputTextChange={this.handleTableInputTextChange}
              handleTableInputDateChange={this.handleTableInputDateChange}
              handleResetItem={this.handleResetItem}
              handleDuplicate={this.handleDuplicate}
              handleUpdateStatus={this.handleUpdateStatus}
              handleSave={this.handleSave}
              handleDelete={this.handleDelete}
              schedules={schedules}
            />
          </div>
        </Spin>
      </div>
    )
  }
}

TripScheduler.contextTypes = {
  screenHeight: PropTypes.object.isRequired,
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

const mapStateToProps = state => {
  return {
    authData: getAuthData(state),
    schedules: getTripSchedulesBySearchKey(state, getSearchKey()),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    fetchingSchedulers: isFetchingTripSchedulars(state),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchTripSchedules, updateTripSchedule, deleteTripSchedule, addTripSchedule })(TripScheduler));