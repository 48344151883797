import * as fromApi from '../../api/generic';

// export const fetchTransferHeaders = (filter, page = 1, searchKey = '') => dispatch => {
//   cleanFilter(filter);
//   dispatch({ type: 'START_FETCH_TRANSFER_HEADERS'});
//   console.log("------------0", filter, page) 
//   return fromApi.get('TransferHeader', filter).then(response => {   
//     dispatch({ type: 'FINISH_FETCH_TRANSFER_HEADERS'});
//     const { data, error, metaData } = response;
//     if (!error)
//       dispatch({ type: 'FETCH_TRANSFER_HEADERS', transferHeaders: data, total: metaData ? metaData.recordCount: 19, searchKey});
//     return response;
//   }).catch(error => {
//     console.log(error);
//     return { error }
//   })
// };

let lastSearchKey;

export const fetchTransferHeaders = (filter, page = 1, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: 'START_FETCH_TRANSFER_HEADERS' });
  return fromApi.get('TransferHeader', filter, page).then(response => {
    const { data, error, recordTotal } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_TRANSFER_HEADERS', transferHeaders: data, searchKey, total: recordTotal });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: 'FINISH_FETCH_TRANSFER_HEADERS' });
  });
};


const cleanFilter = filter => {
  if (filter.UserTag)
    filter.UserTag = filter.UserTag.filter(tag => tag.startsWith('CS'));
  filter.UserCode = undefined;
}

export const addTransferHeader = header => dispatch => {
  return fromApi.add('TransferHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_TRANSFER_HEADER', transferHeaders: [data] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateTransferHeader = header => dispatch => {
  return fromApi.update('TransferHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: 'FETCH_TRANSFER_HEADER', transferHeaders: [data] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteTransferHeader = header => dispatch => {
  return fromApi.deleteObject('TransferHeader', header).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: 'DELETE_TRANSFER_HEADER', transferHeader: header });
    return response;
  }).catch(error => {
    return { error }
  })
};