import { Button, Col, Modal } from 'antd';

import React from "react";
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { ProMateGreen } from '../../../const/Theme';
import { addSalesAttachment, confirmSalesHeader, createPaymentMethod, createSalesHeader, createSalesLine, endCall, startCall, uploadFile } from '../../../redux/actions/networkMode';
import { getAuthData } from '../../../redux/reducers';
import { exportJson } from '../../common/FileExport';
import { sync } from '../../common/SyncDetails';
import { getCurrentDate } from '../../common/TimeStamp';

const { confirm } = Modal;

class ImportData extends React.Component {

    state = {
        isConfirmDialogShow: false,
        file: null,
        importing: false,
    }

    constructor(props) {
        super(props);
        this.fileRef = React.createRef(null);
        this.retriesCount = 0;
    }

    openDialog() {
        this.setState({ isConfirmDialogShow: true, file: null });

    }

    closeDialog() {
        this.setState({ isConfirmDialogShow: false, importing: false, file: null });
    }

    import() {
        const { toastManager } = this.props;
        this.setState({ importing: true });
        try {
            if (this.state.file == null) {
                this.setState({ importing: false });
                return toastManager.add("You must choose file to import.", { autoDismiss: true, appearance: 'error' });
            }

            if (!window.navigator.onLine) {
                this.setState({ importing: false }, () => this.closeDialog());
                confirm({
                    title: "No internet",
                    content: "Cannot import details, when you are offline.",
                    maskClosable: false,
                    cancelButtonProps: { style: { display: 'none' } },
                });
                return;
            }

            const fileReader = new FileReader();
            fileReader.readAsText(this.state.file, "UTF-8");
            fileReader.onload = async e => {
                const date = await getCurrentDate('MM/DD/YY');
                await this.importData(JSON.parse(e.target.result), date);
            };
            fileReader.addEventListener('error', () => {
                toastManager.add("File import was failed", { autoDismiss: true, appearance: 'error' });
                this.setState({ importing: false });
            });
        } catch (error) {
            console.log(error);
            toastManager.add("File import was failed", { autoDismiss: true, appearance: 'error' });
            this.setState({ importing: false });
        }
    }

    handleChange(event) {
        if (event.target.files.length > 0) {
            this.setState({ file: event.target.files[0] });
        } else {
            this.setState({ file: null });
        }
    };

    importData = async (data) => {
        let {
            offlineCallsResults,
            customers,
            salesHeaders,
            salesLines,
            images,
            salesReturnHeaders,
            salesReturnLines,
            salesReturnSignatures,
            token
        } = data;

        const {
            startCall,
            endCall,
            createCustomer,
            createPaymentMethod,
            createPaymentTerm,
            createSalesHeader,
            createSalesLine,
            uploadFile,
            addSalesAttachment,
            confirmSalesHeader,
        } = this.props;

        const storedToken = localStorage.getItem('filetoken') ?? '';

        if (storedToken != token) {
            this.props.toastManager.add("Selected file is not valid. Because that is not recent downloaded file.", { autoDismiss: true, appearance: 'error' });
            this.setState({ importing: false }, () => this.closeDialog());
            return;
        }

        await sync({
            data: {
                offlineCallsResults,
                customers,
                salesHeaders,
                salesLines,
                images,
                salesReturnHeaders,
                salesReturnLines,
                salesReturnSignatures
            },
            authData: this.props.authData,
            api: {
                startCall,
                endCall,
                createCustomer,
                createPaymentMethod,
                createPaymentTerm,
                createSalesHeader,
                createSalesLine,
                uploadFile,
                addSalesAttachment,
                confirmSalesHeader
            },
            onSuccess: async (log) => {
                this.props.toastManager.add("File import was successfully", { autoDismiss: true, appearance: 'success' });
                this.setState({ importing: false }, () => this.closeDialog());
                await exportJson(this.props.toastManager, log);
            },
            onCatch: async (error, log) => {
                console.log(error);
                this.props.toastManager.add("File import was failed", { autoDismiss: true, appearance: 'error' });
                this.setState({ importing: false }, () => this.closeDialog());
                await exportJson(this.props.toastManager, log);
            },
            onError: async (log) => {
                this.props.toastManager.add("File import was failed (Connection Error)", { autoDismiss: true, appearance: 'error' });
                this.setState({ importing: false }, () => this.closeDialog());
                await exportJson(this.props.toastManager, log);
            }
        });
    }

    render() {
        return (
            <>
                <Modal
                    footer={null}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                    okText="Continue"
                    title="Are you want to import Details?"
                    style={{ top: 100 }}
                    visible={this.state.isConfirmDialogShow}
                    onCancel={() => this.closeDialog()}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <input key={this.state.file} accept='application/json' ref={this.fileRef} id='file' type="file" onChange={(event) => this.handleChange(event)} style={{ display: 'none' }} />
                            {
                                this.state.file == null ? <label htmlFor="file" style={{ padding: '5px 10px', borderRadius: '4px', border: '1px solid #d9d9d9', cursor: 'pointer' }}>
                                    Choose Import File
                                </label> : <Button type="default" loading={this.state.importing} onClick={() => this.import()}>
                                    Import
                                </Button>
                            }
                        </div>
                        <Button type="primary" onClick={() => this.closeDialog()}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
                <Col xs={12} sm={8} style={{ height: '100%', display: 'flex', alignContents: 'center', cursor: 'pointer' }} onClick={() => this.openDialog()}>
                    <div style={styles.topTiles}>
                        {this.props.icon}
                        <h2 style={{ color: '#FFFFFF', fontWeight: 600 }}>{this.props.title}</h2>
                    </div>
                </Col>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authData: getAuthData(state),
    };
};

export default withToastManager(connect(mapStateToProps, {
    startCall,
    createSalesHeader,
    createPaymentMethod,
    createSalesLine,
    uploadFile,
    addSalesAttachment,
    confirmSalesHeader,
    endCall,

})(ImportData));

const styles = {
    topTiles: {
        backgroundColor: ProMateGreen,
        width: '100%',
        height: '100%',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        textAlign: 'center',
        color: '#FFFFFF'
    }
};
