import { Col, Row, Select, Typography } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ROUTE_CUSTOMER_TARGET } from '../../../../const/Permissions';
import { RouteTargetStatus } from '../../../../const/status';
import { fetchRoutes } from '../../../../redux/actions/routes';
import { fetchAllCustomerVisitTargetsByFilter, fetchAllMonths, fetchAllYears, updateFilter } from '../../../../redux/actions/targetScheduler';
import { fetchTerritories } from "../../../../redux/actions/territories";
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAllCustomerVisitTargets, getAllMonths, getAllYears, getAuthData, getFilter, getRoutesByUser, getSalesUserHierarchy, getTerritories } from '../../../../redux/reducers';
import { StyledButton } from '../../common/Buttons';
import { getURLParams } from '../../common/helpers';
import UserHierachy from '../../common/UserHierachy';

const { Text } = Typography;
const { Option } = Select;

export class Header extends Component {
    componentDidMount = async () => {
        const { loading, setLoading, fetchAllCustomerVisitTargetsByFilter, updateFilter, toastManager, fetchSalesUserHierarchy, authData, fetchTerritories, fetchAllMonths, fetchAllYears, fetchRoutes, salesUserHierarchy } = this.props;
        await fetchSalesUserHierarchy(authData);
        await fetchAllMonths();
        await fetchAllYears();

        setLoading(true);

        const urlParams = await getURLParams(this.props);
        if (urlParams) {
            const routes = await fetchRoutes({ UserTagFilterOne: urlParams.UserTag }, 0);
            const territories = await fetchTerritories({ UserTagFilterOne: urlParams.UserTag });

            await updateFilter({
                UserTag: urlParams.UserTag,
                RouteCode: urlParams.RouteCode ?? routes.records[0]?.RouteCode,
                TerrCode: urlParams.TerrCode ?? territories.records[0]?.TerrCode,
                Month: urlParams.Month,
                Year: urlParams.Year
            }, this.props);


            await fetchAllCustomerVisitTargetsByFilter({
                UserTag: urlParams.UserTag,
                RouteCode: urlParams.RouteCode ?? routes.records[0]?.RouteCode,
                Year: urlParams.Year,
                Month: urlParams.Month
            }, 0);
        } else {
            toastManager.add("User details not found.", { autoDismiss: true, appearance: 'error' });
        }
        setLoading(false);
    }

    handleChange = async (name, value) => {
        await this.props.updateFilter({
            [name]: value
        }, this.props);
    };

    handleSearch = async () => {
        const { fetchAllCustomerVisitTargetsByFilter, setLoading, filter } = this.props;
        setLoading(true);
        const results = await fetchAllCustomerVisitTargetsByFilter(filter, 0);
        setLoading(false);
    }

    handleUpdateUserHierarchy = async (UserTag) => {
        const { updateFilter, setLoading, fetchTerritories, fetchRoutes } = this.props;
        setLoading(true);
        const urlParams = await getURLParams(this.props);
        if (urlParams) {
            const routes = await fetchRoutes({ UserTagFilterOne: urlParams.UserTag }, 0);
            const territories = await fetchTerritories({ UserTagFilterOne: urlParams.UserTag });
            await updateFilter({
                UserTag: urlParams.UserTag,
                RouteCode: routes.records[0]?.RouteCode,
                TerrCode: territories.records[0]?.TerrCode,
                Month: urlParams.Month,
                Year: urlParams.Year
            }, this.props);
        }
        setLoading(false);
    }

    render() {
        const { handleAllUpdate, filter, years, months, territories, routes, permissions } = this.props;

        return (
            <>
                <UserHierachy onUpdate={(value) => {
                    this.handleUpdateUserHierarchy(value)
                }} />
                <Row style={{ padding: "0px 10px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={12} lg={3} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Year: </Text>
                        <Select
                            value={filter.Year}
                            style={{ width: "100%", ...styles.text }}
                            onChange={(value) => this.handleChange('Year', value)}
                        >
                            {years?.records?.map((year, index) =>
                                (<Option style={styles.text} key={`${index}-${year.Code}`} value={year.Code}>{year.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} lg={3} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Month: </Text>
                        <Select
                            style={{ width: "100%", ...styles.text }}
                            value={filter.Month}
                            onChange={(value) => this.handleChange('Month', value)}
                        >
                            {months?.records?.map((month, index) =>
                                (<Option style={styles.text} key={`${index}-${month.Code}`} value={month.Code}>{month.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    {
                        territories?.length > 0 && (
                            <Col xs={24} sm={12} lg={4} style={styles.container}>
                                <Text style={{ marginRight: 10, ...styles.text }}>Teritory: </Text>
                                <Select
                                    style={{ width: "100%", ...styles.text }}
                                    value={filter.TerrCode ?? (territories?.length > 0 && territories[0]?.TerrCode)}
                                    onChange={(value) => this.handleChange('TerrCode', value)}
                                >
                                    {territories.map((territory, index) =>
                                        (<Option style={styles.text} key={`${index}-${territory.TerrCode}`} value={territory.TerrCode}>{territory.TerrName}</Option>)
                                    )}
                                </Select>
                            </Col>
                        )
                    }
                    {
                        routes.length > 0 && (
                            <Col xs={24} sm={12} lg={8} style={styles.container}>
                                <Text style={{ marginRight: 10, ...styles.text }}>Route: </Text>
                                <Select
                                    style={{ width: "100%", ...styles.text }}
                                    value={(filter.RouteCode ?? (routes?.length > 0 && routes[0]?.RouteCode))}
                                    onChange={(value) => this.handleChange('RouteCode', value)}
                                >
                                    {routes.map((route, index) =>
                                        (<Option style={styles.text} key={`${index}-${route.RouteCode}`} value={route.RouteCode}>{route.RouteName}</Option>)
                                    )}
                                </Select>
                            </Col>
                        )
                    }
                    <Col sm={24} md={24} lg={4} style={styles.container}>
                        <StyledButton color="#106bc7" label="Search" onClick={() => this.handleSearch()} />
                        {permissions?.includes(ROUTE_CUSTOMER_TARGET.APPROVE) && (<StyledButton color="#0f8a86" label="Approve" onClick={() => handleAllUpdate(RouteTargetStatus.APPROVED)} />)}
                    </Col>
                    <Col sm={12} md={12} lg={2} style={styles.container}>
                        {permissions?.includes(ROUTE_CUSTOMER_TARGET.REJECT) && (<StyledButton color="#a60d33" label="Reject" onClick={() => handleAllUpdate(RouteTargetStatus.REJECTED)} />)}
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    years: getAllYears(state),
    months: getAllMonths(state),
    routes: getRoutesByUser(state),
    territories: getTerritories(state),
    customerVisitTargets: getAllCustomerVisitTargets(state),
    filter: getFilter(state)
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchRoutes,
    fetchTerritories,
    updateFilter,
    fetchAllCustomerVisitTargetsByFilter
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));

const styles = {
    text: {
        color: '#000000',
        textOverflow: "ellipsis"
    },
    container: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        gap: 5,
        justifyContent: "flex-start",
    }
}