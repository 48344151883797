import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_TRANSFER_LINES_FOR_DOC': {
			const newState = { ...state };
			action.transferLines.forEach(line => {
				newState[getId(line)] = line;
			});
			return newState;
		}
		case 'FETCH_TRANSFER_LINE_FOR_DOC': {
			const newState = { ...state };
			action.transferLines.forEach(line => {
				newState[getId(line)] = line;
			});
			return newState;
		}
		case 'REMOVE_TRANSFER_LINE': {
			const newState = { ...state };
			action.transferLines.forEach(line => {
				delete newState[getId(line)];
			});
			return newState;
		}
		default:
			return state;
	}
};

const byDocNo = (state = {}, action) => {
	switch (action.type) {
		case 'FETCH_TRANSFER_LINES_FOR_DOC': {
			const { DocNo, transferLines } = action;
			return { ...state, [DocNo]: transferLines.map(getId) };
		}
		case 'FETCH_TRANSFER_LINE_FOR_DOC': {
			const newState = { ...state };
			action.transferLines.forEach(line => {
				const ids = newState[line.DocNo] || [];
				if (!ids.includes(getId(line))) {
					ids.push(getId(line));
					newState[line.DocNo] = ids;
				}
			});
			return newState;
		}
		case 'REMOVE_TRANSFER_LINE': {
			const newState = { ...state };
			action.transferLines.forEach(line => {
				const ids = newState[line.DocNo] || [];
				newState[line.DocNo] = ids.filter(id => id !== getId(line))
			});
			return newState;
		}
		default:
			return state;
	}
}

const transferLines = combineReducers({
	byId,
	byDocNo
});

export default transferLines;

export const getForDoc = (state, docNo) => (state.byDocNo[docNo] || []).map(id => state.byId[id]);

export const getTransferLineMapForDoc = (state, docNo) => {
	const TransferLineMap = {};
	(state.byDocNo[docNo] || []).forEach(id => {
		const { ItemNo, LotNo } = state.byId[id];
		const key = ItemNo + LotNo;
		TransferLineMap[key] = state.byId[id];
	});

	return TransferLineMap;
}

export const getTotalTranferQtyForDoc = (state, docNo) => {
	let totalTransferQuantity = 0;
	(state.byDocNo[docNo] || []).forEach(id => {
		const item = state.byId[id];

		totalTransferQuantity = totalTransferQuantity + parseFloat(item.Quantity);
	});

	return totalTransferQuantity;
}

const getId = line => line.DocNo + line.LineNo;