import * as fromApi from '../../api/generic';


export const addSettlementHeader = header => dispatch => {
  return fromApi.add('SettlementHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    //dispatch({ type: 'FETCH_PAYMENT_HEADER', paymentHeaders: [ data ] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateSettlementHeader = header => dispatch => {
  return fromApi.update('SettlementHeader', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    //dispatch({ type: 'FETCH_PAYMENT_HEADER', paymentHeaders: [ data ] });
    return response;
  }).catch(error => {
    return { error }
  })
};


export const addSettlementLine = header => dispatch => {
  return fromApi.add('SettlementLine', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    //dispatch({ type: 'FETCH_PAYMENT_HEADER', paymentHeaders: [ data ] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateSettlementLine = header => dispatch => {
  return fromApi.update('SettlementLine', header).then(response => {
    const { data, error } = response;

    if (error) return { error }

    //dispatch({ type: 'FETCH_PAYMENT_HEADER', paymentHeaders: [ data ] });
    return response;
  }).catch(error => {
    return { error }
  })
};
