import { combineReducers } from 'redux';
import { FETCH_PAYMENT_TERMS, FETCH_CUSTOMER_PAYMENT_TERMS, REMOVE_CUSTOMER_PAYMENT_TERM } from '../actions/paymentTerms';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_TERMS: {
      const newState = { ...state };
      action.paymentTerms.forEach(term => {
        newState[term.No] = term;
      });
      return newState;
    }

    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAYMENT_TERMS: {
      const newState = [...state]
      action.paymentTerms.forEach(term => {
        if (!newState.includes(term.No)) newState.push(term.No)
      })

      return newState;
    }

    default:
      return state;
  }
};

export const paymentTermIdsByCustomer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_PAYMENT_TERMS: {
      const newState = { ...state };
      const ids = newState[action.customerNo] || [];
      action.paymentOptions.forEach(paymentOption => {
        if (!ids.includes(paymentOption.Code)) ids.push(paymentOption.Code)
      });
      newState[action.customerNo] = ids;
      return newState;
    }
    case REMOVE_CUSTOMER_PAYMENT_TERM: {
      const newState = { ...state };
      const ids = newState[action.paymentOption.CustNo] || [];
      newState[action.paymentOption.CustNo] = ids.filter(id => id !== action.paymentOption.Code);

      return newState;
    }
    default:
      return state;
  }
};

const paymentTerms = combineReducers({
  byId,
  ids,
  paymentTermIdsByCustomer
});

export const getAll = state => state && state.ids && state.byId && state.ids.map(id => state.byId[id]) || [];

export const getCustomerPaymentTermsByCustomerId = (state, customerId) => {
  const ids = state.paymentTermIdsByCustomer[customerId] || [];

  return ids;
}

export const getCustomerPaymentTermsDataByCustomerId = (state, customerId) => {
  const ids = state.paymentTermIdsByCustomer[customerId] || [];

  return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

export default paymentTerms;