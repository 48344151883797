import { combineReducers } from 'redux';
import { FETCH_FILTERED_ROUTES, START_FETCH_FILTERED_ROUTES, FINISH_FETCH_FILTERED_ROUTES } from '../actions/filteredRoutes';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FILTERED_ROUTES:
      const newState = {};
      action.routes.forEach(route => newState[route.RouteCode] = route);
      return newState;
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_FILTERED_ROUTES:
      return action.routes.map(route => route.RouteCode);
    default:
      return state;
  }
};

export const byUserId = (state = [], action) => {
  switch (action.type) {
    case FETCH_FILTERED_ROUTES: {
      const newState = {};
      const ids = newState[action.UserCode] || [];
      action.routes.forEach(route => {
        ids.push(route.RouteCode);
      });
      newState[action.UserCode] = ids;
      return newState;
    }
    default:
      return state;
  }
};

export const fetchingRoutes = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_FILTERED_ROUTES:
      return true;

    case FINISH_FETCH_FILTERED_ROUTES:
      return false;

    default:
      return state;
  }
};

const routes = combineReducers({
  byId,
  ids,
  byUserId,
  fetchingRoutes
});

export default routes;

export const getFilteredRoutes = state => {
  return state.ids.map(id => state.byId[id])
}

export const getRoutesByUser = (state, UserCode) => {
  const ids = state.byUserId[UserCode] || [];
  return ids.map(id => state.byId[id])
}

export const isFetchingFilteredRoutes = state => state.fetchingRoutes;

export const getFilteredRouteTotal = state => state.total;
