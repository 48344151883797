import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchPurchaseCreditMemoReasons } from "../../../redux/actions/purchasingHeaders";
import { fetchPurchaseCreditInvoices } from "../../../redux/actions/purchaseCreditInvoices";
import { fetchLines } from "../../../redux/actions/purchaseCreditInvoiceLines";
import { Row, Spin, Col, Button } from "antd";
import OrderHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener } from '../../../utils/Location';
import { getAuthData, getLocations, getLoggedInUser, getPurchaseCreditInvoiceByNo, getPurchaseCreditInvoiceLinesByDocNo } from '../../../redux/reducers';
import { generateGeneric } from '../../../api/reports';
import Api from '../../../api/consts';
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchTerritories } from '../../../redux/actions/territories';
import { CREDIT_NOTES, CUSTOMER_CREDIT_INVOICES, PURCHASE_CREDIT_INVOICES, SALES_RETURNS, SALES_RETURN_INVOICES } from '../../../const/Permissions';
import { dateFormatView } from '../../../const/Formats';

export const defaultOrder = {
  SessionID: uuid(),
  DocType: undefined,
  SellToCusNo: undefined,
  SellToCusName: undefined,
  BillToCusNo: undefined,
  BillToCusName: undefined,
  LocCode: undefined,
  OrderStatus: "Open",
  PayMethodCode: undefined,
  PayTermCode: undefined,
  DistributorCode: undefined,
  DistributorName: undefined,
  MapLocation: undefined,
  PostingDate: undefined,
  Amount: 0.0,
  AmountIncluVAT: 0.0,
  DisPersentage: 0.0,
  DisAmount: 0.0,
  TerritoryCode: undefined,
  TerritoryName: undefined
}

const toastMessage = {

}

class Container extends Component {
  state = {
    loading: false,
    invoice: { ...defaultOrder },
    locCodeError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { invoiceNo } = this.props.match.params;

    this.props.fetchPurchaseCreditMemoReasons();

    this.setState({ loading: true });
    this.props.fetchLines({ DocNo: decodeURIComponent(invoiceNo) });
    this.props.fetchPurchaseCreditInvoices({ No: decodeURIComponent(invoiceNo) }).then(({ data, error }) => {
      this.setState({ loading: false });
    })
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.invoice && !nextProps.invoice) {
      // The invoice is deleted.
      window.history.back();
    } else if (nextProps && nextProps.invoice) {
      this.setState({ invoice: { ...nextProps.invoice } });
    } else {
      this.setData(this.props);
    }
  }

  setData = data => {
    this.setState({
      invoice: {
        ...this.state.invoice
      }
    }, this.setDeviceMapLocation)
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  shareOrder = () => {
    this.setState({ sharing: true });

    const { toastManager, match } = this.props;
    const { docType, orderId } = match.params;
    const reportRequest = {
      No: decodeURIComponent(orderId)
    };

    // generateGeneric(reportRequest, docType === 'ReturnOrder' ? 'salesCreditMemo' : 'creditNote').then(response => {
    //   if (!response.error) {
    //     window.open(Api.files.download.url(response.url), '_blank');
    //   } else {
    //     toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }


  // handleNavigateToSetOff = () => {
  //   const { match, history, invoice } = this.props;
  //   history.push('/customers/' + encodeURIComponent(invoice.SellToCusNo) + match.url + '/setoff');
  // }

  handleDataAllocated = () => {
    const { invoiceNo, docType } = this.props.match.params
    this.setState({ loading: true });
    this.props.fetchLines({ DocNo: decodeURIComponent(invoiceNo) });
    this.props.fetchPurchaseCreditInvoices({ No: decodeURIComponent(invoiceNo) }).then(({ data, error }) => {
      this.setState({ loading: false });
    })

  }

  renderActions = () => {
    const { loggedInUser: { permissions = [] } } = this.props;

    const enablePrintAndShare = permissions.includes(PURCHASE_CREDIT_INVOICES.PRINT)
    //const enableSetoff = this.getPermission(docType, "SETOFF", permissions)

    return <Row gutter={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {enablePrintAndShare && <Col>
          <Button
            loading={this.state.sharing}
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={this.shareOrder}
          >Print & Share</Button>
        </Col>}

      </Row>
    </Row >
  }

  render() {
    const { salesLines, loggedInUser: { permissions = [] } } = this.props;
    const { loading, invoice, locCodeError } = this.state;
    const enableDeallocate = permissions.includes(CUSTOMER_CREDIT_INVOICES.DEALLOCATE);
    //console.log(enableDeallocate)
    if (!invoice) return null;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <OrderHeader
            order={invoice}
            onShareOrder={this.shareOrder}
            error={{ locCodeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                salesLines={salesLines}
                order={invoice}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                salesLines={salesLines}
                order={invoice}
              />
            </Col>
          </div>
          <OrderFooter order={invoice} onInputChange={this.onInputChange} enableDeallocate={enableDeallocate} onDataAllocated={this.handleDataAllocated} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport invoice={invoice} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { invoiceNo } = ownProps.match.params
  const decodeId = decodeURIComponent(invoiceNo);
  const invoice = getPurchaseCreditInvoiceByNo(state, decodeId);

  return {
    invoice,
    authData: getAuthData(state),
    locations: getLocations(state),
    salesLines: getPurchaseCreditInvoiceLinesByDocNo(state, decodeId),
    loggedInUser: getLoggedInUser(state),
    location: getLocations(state).filter(location => invoice && location.LocCode === invoice.LocCode && location.WarehouseOwner === 'Yes')[0]
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchPurchaseCreditInvoices,
  fetchLines,
  fetchTerritories,
  fetchPurchaseCreditMemoReasons
})(Container)));