import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchRoutes } from '../../redux/actions/routes'
import { getAuthData, getRoutesByUser, getFilteredRoutes, isFetchingFilteredRoutes } from "../../redux/reducers";

const { Option } = Select;

class SelectRoutes extends Component {
  state = { loading: false }

  componentDidMount = () => {
    //this.handleFetchRoutes(this.props)
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.filter.UserCode !== nextProps.filter.UserCode) {
      //this.handleFetchRoutes(nextProps)
    }
  }

  handleFetchRoutes = props => {
    const { filter = {}, fetchRoutes, setDefaultRouteOption, authData } = props;
    if (filter.UserCode) {
      const routeFilter = { UserCode: authData.userId };
      this.setState({ loading: true });
      fetchRoutes(routeFilter).then(response => {
        if (response.routes && response.routes.length === 1) {
          setDefaultRouteOption(response.routes[0])
        }
        this.setState({ loading: false })
      });
    }
  }

  setDefaultFilter = () => {
    const { filteredRoutes, routes, onChange, value } = this.props;
    if (filteredRoutes.length === 1 && value !== filteredRoutes[0].RouteCode) {
      setTimeout(() => {
        onChange('RouteCode', filteredRoutes[0].RouteCode, true);
      }, 0);
    }
  }

  render() {
    this.setDefaultFilter();

    const {
      filteredRoutes,
      onChange,
      showSearch = true,
      placeholder = 'Select Route',
      allowClear = true,
      value,
      disabled,
      isFetchingFilteredRoutes,
      style = { width: '100%' }
    } = this.props

    return (
      <Select
        disabled={disabled || filteredRoutes.length < 2}
        value={value}
        placeholder={placeholder}
        onChange={(value) => onChange('RouteCode', value)}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={style}
        loading={isFetchingFilteredRoutes}
      >
        {filteredRoutes.map(route =>
          <Option value={route.RouteCode}>{route.RouteName}</Option>
        )
        }
      </Select>

    )
  }
}

const mapStateToProps = state => {
  const authData = getAuthData(state);
  return {
    authData,
    //routes: getRoutesByUser(state, authData.userId),
    filteredRoutes: getFilteredRoutes(state),
    isFetchingFilteredRoutes: isFetchingFilteredRoutes(state)
  };
};

export default connect(mapStateToProps, { fetchRoutes })(SelectRoutes);
