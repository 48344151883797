import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Button, Popconfirm, Col, DatePicker, Tooltip, Tag, Select, Input } from 'antd';
import { dateTimeFormatView } from "../../const/Formats";
import moment from 'moment';
import SelectRoute from "../common/SelectRoute";
import SelectCSR from "../common/SelectCSR";
import SelectDistributorStaff from "../common/SelectDistributorStaff";
import SelectWarehouseLocation from "../common/SelectWarehouseLocation";
import { FaTrash, FaSave, FaRegClone, FaRegCheckSquare, FaSyncAlt, FaUndo } from "react-icons/fa";
import { getTripSchedules, getLoggedInUser } from "../../redux/reducers";
import { updateTripSchedule, deleteTripSchedule, addTripSchedule } from '../../redux/actions/tripSchedules';
import { withToastManager } from 'react-toast-notifications';
import uuid from "uuid/v1";
import AdvanceSearchFilters from "../common/AdvanceSearchFilters";
import { TRIP_SCHEDULER } from "../../const/Permissions";
import { getAuthData, getTripSchedulesTotal, getSalesUserHierarchy } from "../../redux/reducers";
import TableFooter from "../common/TableFooter";
import { fetchSalesUserHierarchy } from '../../redux/actions/users'


const { Option } = Select;

class TripSchedulerTable extends Component {
  constructor(props) {
    super(props);
    //this.state = { ...defaultState }
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 22;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return this.props.screenHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }

  getTableHeight = schedules => {
    if (schedules.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter = {}, setDefaultRouteOption } = this.props;
    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={entireColumn}
          FROM_DATE_PICKER={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          TO_DATE_PICKER={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SELECT_TRIP_SCHEDULAR_STATUS={{ lg: 3, md: 24, sm: 24, xs: 24 }}
          SELECT_TRIP_STATUS={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SELECT_ROUTE={{ lg: 4, md: 24, sm: 24, xs: 24 }}
          SEARCH_TEXT={{ lg: 4, md: 24, sm: 24, xs: 24, placeholder: 'Remarks' }}
          SEARCH_BUTTON={{ lg: 1, md: 24, sm: 24, xs: 24 }}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          filter={filter}
          setDefaultRouteOption={setDefaultRouteOption}
        />
      </div>
    )
  }

  renderFooter = () => {
    const { total, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
      />
    );
  }

  // renderFooter = () => {
  //   return (
  //     <div style={{ height: 40, textAlign: 'end', marginRight: 10 }}>
  //       <Button disabled={this.state.schedule} onClick={this.handleAdd} type="primary">Add New</Button>
  //     </div>
  //   )
  // }

  getColumns = () => {
    const { routeError, csrError, distributorStaffError, warehouseError, recallError, startDateError } = this.props;
    const { loggedInUser: { roleCode } } = this.props;
    const actionColumnLength = roleCode === "CSR" ? 100 : 220
    return [
      {
        title: 'Trip Type',
        dataIndex: 'TripType',
        width: 180,
        render: (value, record) => this.renderTripTypes(value, record)
      },
      {
        title: 'Route',
        dataIndex: 'RouteCode',
        width: 200,
        render: (value, record) => {
          const { schedule, existingSchedule } = this.props;
          if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule.RouteCode) {
            value = schedule.RouteCode;
          }
          return (
            <div>
              <SelectRoute
                allowClear={false}
                disabled={this.isDisbaleSelection(record) || record.Status !== 'New'}
                value={value}
                style={{ width: 200 }}
                filter={{ UserCode: '' }}
                onChange={(field, value) => this.props.handleTableInputTextChange(field, value, record, 'routeError')} />
              {this.displayError(record, routeError)}
            </div>
          )
        }
      },
      {
        title: 'CSR',
        dataIndex: 'CSRCode',
        width: 200,
        render: (value, record) => {
          const { schedule, existingSchedule } = this.props;
          if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule.CSRCode) {
            value = schedule.CSRCode;
          }
          return (
            <div>
              <SelectCSR
                disabled={this.isDisbaleSelection(record) || record.Status !== 'New'}
                //defaultValue={value}
                value={value}
                style={{ width: 200 }}
                onChange={(field, value) => this.props.handleTableInputTextChange(field, value, record, 'csrError')} />
              {this.displayError(record, csrError)}
            </div>
          )
        }
      },
      // {
      //   title: 'Distributor Staff',
      //   dataIndex: 'DistributorStaffCode',
      //   width: '9%',
      //   render: (value, record) => (
      //     <div>
      //       <SelectDistributorStaff
      //         disabled={this.isDisbaleSelection(record)}
      //         defaultValue={value}
      //         onChange={(field, value) => this.handleInputTextChange(field, value, record, 'distributorStaffError')} />
      //       {this.displayError(record, distributorStaffError)}
      //     </div>
      //   )
      // },
      {
        title: 'Warehouse (Mobile)',
        dataIndex: 'LocCode',
        width: 300,
        render: (value, record) => {
          const { schedule, existingSchedule } = this.props;
          if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule.LocCode) {
            value = schedule.LocCode;
          }
          return (
            <div>
              <SelectWarehouseLocation
                allowClear={true}
                disabled={this.isDisbaleSelection(record) || record.Status !== 'New'}
                defaultValue={value}
                style={{ width: 300 }}
                onChange={(field, value) => this.props.handleTableInputTextChange(field, value, record, 'warehouseError')} />
              {this.displayError(record, warehouseError)}
            </div>
          )
        }
      },
      {
        title: 'Start Time',
        dataIndex: 'StartDateTime',
        render: (value, record) => this.rendeDatePicker('Start Time', value, record, 'StartDateTime', startDateError),
        width: 200
      },

      {
        title: 'End Time',
        dataIndex: 'EndDateTime',
        render: (value, record) => this.rendeDatePicker('End Time', value, record, 'EndDateTime'),
        width: 200
      },
      {
        title: 'Recalled Time',
        dataIndex: 'RecallDateTime',
        render: (value, record) => this.rendeDatePicker('Recall Time', value, record, 'RecallDateTime', recallError),
        width: 200
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        render: (value, record) => <Tag>{value}</Tag>,
        width: 100,
        align: 'center',
      },
      {
        title: 'Trip Status',
        dataIndex: 'TripStatus',
        //render: (value, record) => <Tag>{value}</Tag>,
        width: 180,
        align: 'center',
        render: (value, record) => this.renderTripSatus(value, record)
      },
      {
        title: 'Remarks',
        dataIndex: 'Remarks',
        render: (value, record) => this.renderRemarks('Remarks', value, record, 'Remarks'),
        width: 300,
        align: 'center',
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        width: actionColumnLength,
        fixed: 'right',
        render: (text, record) => {
          //return this.props.schedules.length >= 1 ? this.renderActions(record) : null
          return this.renderActions(record);
        }
      },
    ]
  }

  displayError = (record, error) => {
    const { schedule = {}, existingSchedule = {} } = this.props;
    if ((record.EntryNo === schedule.EntryNo) && error) {
      return <p style={{ color: 'red', marginBottom: -10 }}>{error}</p>
    } else if (existingSchedule && existingSchedule.EntryNo === record.EntryNo) {
      return <p style={{ color: 'red', marginBottom: -10 }}>{error}</p>
    } else
      return null;
  }

  isDisbaleSelection = record => {
    const { existingSchedule } = this.props;
    return existingSchedule && existingSchedule.EntryNo !== record.EntryNo;
  }

  renderTripTypes = (value, record) => {
    const { schedule, existingSchedule, typeError } = this.props;
    if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule.TripType) {
      value = schedule.TripType;
    }
    return (
      <div>
        <Select
          disabled={(existingSchedule && existingSchedule.EntryNo !== record.EntryNo) || record.Status !== 'New'}
          placeholder={'Select Trip Type'}
          value={value}
          onChange={(value) => this.props.handleTableInputTextChange('TripType', value, record, 'typeError')}
          style={{ width: '100%' }}
        >
          {[{ Code: 'Conventional', Description: 'Conventional' }, { Code: 'Pre Selling', Description: 'Pre-Selling' }].map(type =>
            <Option value={type.Code}>{type.Description}</Option>
          )}
        </Select>
        {this.displayError(record, typeError)}
      </div>
    )
  }

  renderTripSatus = (value, record) => {
    const { schedule, existingSchedule, typeError } = this.props;
    if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule.TripStatus) {
      value = schedule.TripStatus;
    }
    return (
      <div>
        <Select
          disabled={(existingSchedule && existingSchedule.EntryNo !== record.EntryNo) || record.Status !== "Approved" || record.TripStatus === "Complete" || record.TripStatus === "Incomplete"}
          placeholder={'Select Trip Status'}
          value={value}
          onChange={(value) => this.props.handleTableInputTextChange('TripStatus', value, record)}
          style={{ width: '100%' }}
        >
          {['Not Started', 'Complete', 'Incomplete'].map(status =>
            <Option value={status}>{status}</Option>
          )}
        </Select>
        {/* {this.displayError(record, typeError)} */}
      </div>
    )
  }

  disabledDate(current) {
    // Can not select days before today
    return current && current < moment().add(-1, 'days').endOf('day');
  }

  rendeDatePicker = (placeholder, value, record, field, error) => {
    const { schedule, existingSchedule, typeError } = this.props;
    if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule[field]) {
      value = schedule[field];
    }
    return (
      <div>
        <DatePicker
          disabledDate={(current) => this.disabledDate(current)}
          disabled={(existingSchedule && existingSchedule.EntryNo !== record.EntryNo) || (field === 'StartDateTime' && record.Status !== 'New') || (field === 'EndDateTime' && record.Status !== 'New') || (field === 'RecallDateTime' && record.Status === 'Recalled') || (field === 'RecallDateTime' && record.Status !== 'Approved')}
          value={value && moment(value).add('minutes', +330)}
          format={dateTimeFormatView}
          placeholder={placeholder}
          style={{ width: '100%' }}
          allowClear={false}
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          onChange={(date, dateString) => this.props.handleTableInputDateChange(field, date, record, field === 'StartDateTime' ? 'startDateError' : 'recallError')} />
        {this.displayError(record, error)}
      </div>

    )
  }

  renderRemarks = (placeholder, value, record, field, error) => {
    const { schedule, existingSchedule } = this.props;
    if (existingSchedule && existingSchedule.EntryNo === record.EntryNo && schedule.Remarks) {
      value = schedule.Remarks;
    }
    return (
      <div>
        <Input
          disabled={(existingSchedule && existingSchedule.EntryNo !== record.EntryNo) || record.TripStatus === "Complete" || record.TripStatus === "Incomplete"}
          placeholder={placeholder || 'Search Text'}
          value={value}
          style={{ width: 280, marginRight: 10 }}
          onChange={(e) => this.props.handleTableInputTextChange('Remarks', e.target.value, record)} />
      </div>
    )
  }

  renderActions = record => {
    const { loggedInUser: { permissions = [] } } = this.props;
    const { existingSchedule, schedule } = this.props;
    const disabled = (!existingSchedule || existingSchedule && existingSchedule.EntryNo !== record.EntryNo);
    const newRecord = record.EntryNo.includes('New');

    const enableCreate = permissions.includes(TRIP_SCHEDULER.CREATE);
    const enableUpdate = permissions.includes(TRIP_SCHEDULER.UPDATE);
    const enableDelete = permissions.includes(TRIP_SCHEDULER.DELETE);
    const enableApprove = permissions.includes(TRIP_SCHEDULER.APPROVE);
    const enableRecall = permissions.includes(TRIP_SCHEDULER.RECALL);
    return (

      <Col lg={24} md={24} sm={0} xs={0} style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#fff' }}>
        {enableUpdate && <Tooltip title='Save'>
          <Button disabled={disabled || (schedule && schedule.RecallDateTime)} onClick={this.props.handleSave} type='primary' size={'small'} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        </Tooltip>}

        {enableDelete && <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          disabled={newRecord || (record.Status !== 'New' || schedule)}
          onConfirm={() => this.props.handleDelete(record)}>
          <Tooltip title='Delete'>
            <Button disabled={newRecord || (record.Status !== 'New' || schedule)} type='danger' size={'small'} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaTrash /></Button>
          </Tooltip>
        </Popconfirm>}

        {enableCreate &&
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            disabled={schedule}
            onConfirm={() => this.props.handleDuplicate(record)}>
            <Tooltip title='Duplicate'>
              <Button disabled={schedule} type='primary' size={'small'} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaRegClone /></Button>
            </Tooltip>
          </Popconfirm>}

        {enableApprove && <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          disabled={newRecord || (record.Status !== 'New' || schedule)}
          onConfirm={() => this.props.handleUpdateStatus(record, 'Approved')}>
          <Tooltip title='Approve'>
            <Button disabled={newRecord || (record.Status !== 'New' || schedule)} type='primary' size={'small'} style={{ marginRight: 5, display: 'flex', alignItems: 'center', backgroundColor: '#00c100', borderColor: '#00c100' }}><FaRegCheckSquare /></Button>
          </Tooltip>
        </Popconfirm>}

        {enableRecall && <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          disabled={newRecord || (record.Status !== 'Approved') || (schedule && !schedule.RecallDateTime) || (record.TripStatus === "Complete") || (record.TripStatus === "Incomplete")}
          onConfirm={() => this.props.handleUpdateStatus(record, 'Recalled')}>
          <Tooltip title='Recall'>
            <Button disabled={newRecord || (record.Status !== 'Approved' || schedule && !schedule.RecallDateTime) || (record.TripStatus === "Complete") || (record.TripStatus === "Incomplete")} type='primary' size={'small'} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSyncAlt /></Button>
          </Tooltip>
        </Popconfirm>}

        <Tooltip title='Reset'>
          <Button disabled={!newRecord && disabled} onClick={this.props.handleResetItem} size={'small'} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
        </Tooltip>
      </Col>
    )
  }


  render() {
    const { schedule } = this.props;
    let { schedules = [] } = this.props;

    if (schedule && schedule.EntryNo && schedule.EntryNo.includes('New')) schedules = [schedule, ...schedules];

    return (
      <Col style={{ overflowY: 'hidden' }}>
        <Table
          rowKey='EntryNo'
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(schedules) }}
          dataSource={schedules}
          columns={this.getColumns()}
          size={'small'}
          pagination={false}
          scroll={{ y: this.getScrollAreaHeight(), x: 2300 }}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter()}
        />
      </Col>
    );
  }
}

const mapSatetToProps = state => {
  return {
    loggedInUser: getLoggedInUser(state),
    authData: getAuthData(state),
    salesUserHierarchy: getSalesUserHierarchy(state),
    total: getTripSchedulesTotal(state)
  };
}

export default withToastManager(connect(mapSatetToProps, { fetchSalesUserHierarchy })(TripSchedulerTable));