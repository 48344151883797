export let currentLocation;

export let locationEnabled = false;

export let lastUpdatedAt = 0;

export const updateLocation = () =>
  navigator.geolocation.getCurrentPosition(location => {
    if (!currentLocation ||
      currentLocation.latitude !== location.coords.latitude ||
      currentLocation.longitude !== location.coords.longitude) {
      currentLocation = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        accuracy: location.coords.accuracy
      };
      lastUpdatedAt = new Date().getTime();
      locationEnabled = true;
      listeners.forEach(listener => listener(currentLocation));
    }
  }, error => {
    console.error(error);
    listeners.forEach(listener => listener(null, error));
  });

export const metersBetween = (l1, l2) => {
  const { latitude: lat1, longitude: lon1 } = l1;
  const { latitude: lat2, longitude: lon2 } = l2;

  var R = 6371e3; // metres
  var φ1 = lat1 * Math.PI / 180;
  var φ2 = lat2 * Math.PI / 180;
  var Δφ = (lat2 - lat1) * Math.PI / 180;
  var Δλ = (lon2 - lon1) * Math.PI / 180;

  var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  var d = R * c;

  return d;
}

export const kmBetween = (l1, l2) => {
  const meters = metersBetween(l1, l2);
  return (meters / 1000).toFixed(0);
}

const listeners = [];

export const addListener = listener => {
  listeners.push(listener);
  if (currentLocation) listener(currentLocation);
}

export const removeListener = listener => {
  var index = listeners.indexOf(listener);
  if (index > -1) listeners.splice(index, 1);
}