import { ProMateGreen } from "../../const/Theme";
const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
    position: 'fixed',
    zIndex: 1500,
    width: '100%',
    top: 40
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30,
    right: 105
  }
};
export default styles