import { combineReducers } from 'redux';

export const ACTION_TYPES = {
    START_FETCH_FAILED_HEADERS: 'START_FETCH_FAILED_HEADERS',
    FETCH_FAILED_HEADERS: 'FETCH_FAILED_HEADERS',

    START_FETCH_FAILED_LINES: 'START_FETCH_FAILED_LINES',
    FETCH_FAILED_LINES: 'FETCH_FAILED_LINES',
}

export const headers = (state = { isLoading: false, list: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_FAILED_HEADERS:
            return { isLoading: true, list: [] };
        case ACTION_TYPES.FETCH_FAILED_HEADERS:
            return { isLoading: false, list: action.headers };
        default:
            return state;
    }
};

export const lines = (state = [], action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_FAILED_LINES:
            return { isLoading: true, list: [] };
        case ACTION_TYPES.FETCH_FAILED_LINES:
            return { isLoading: false, list: action.lines };
        default:
            return state;
    }
};


export default combineReducers({
    headers,
    lines
});