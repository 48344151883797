import React, { Component } from 'react';
import { connect } from "react-redux";
import { addCollection, updateCollection, fetchCollection, deleteCollection, addAttachments } from "../../../redux/actions/collection";
import { fetchCustomer } from "../../../redux/actions/customers"
import { getCustomer, getCollection } from "../../../redux/reducers";
import { Spin, Popconfirm, Row, Col, Button } from "antd";
import PaymentForm from './Form';
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import PaymentHeader from './Header';
import { currentLocation } from '../../../utils/Location';
import uuid from 'uuid/v1';
import CollectionExport from "../PaymentExport";
import Api from '../../../api/consts';
import { getDemoItem as getDemoCustomer } from '../../Customers/CustomerDemoData'
import { getDemoPaymentItem } from '../PaymentDemoData'

const defaultPayment = {
  paymentNo: "new",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  paymentDate: moment(),
  salesPersonCode: "",
  amount: 0.00,
  paymentType: "CASH",
  status: "Unverified",
  bankCode: undefined,
  bankName: undefined,
  chequeNo: undefined,
  slipNo: undefined,
  postingDate: moment(),
  links: [],
  mapLocation: currentLocation,
  sessionId: uuid(),
}

class Container extends Component {
  state = {
    loading: false,
    isDisableSave: true,
    isDisableConfirm: true,
    collection: {
      ...defaultPayment
    },
    bankCodeError: undefined,
    chequeNoError: undefined,
    slipNoError: undefined,
    amountError: undefined,
    postingDateError: undefined
  }

  componentDidMount() {
    const { customerId, collectionId } = this.props.match.params
    this.props.fetchCustomer(customerId)
    if (collectionId !== 'new') {
      this.setState({ loading: true })
      this.props.fetchCollection(collectionId).then(() => {
        this.setState({ loading: false })
      })
    }
    this.updateState({}, this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.updateState(this.props, nextProps);
  }

  updateState = (thisProps, nextProps) => {
    const { collection, match, customer } = nextProps;
    const { collectionId } = match.params
    const newCollection = collectionId === 'new';
    const updateStateCollection = this.hasCollectionUpdated(thisProps.collection, collection);

    if (newCollection) {
      this.setState({ collection: { ...defaultPayment, sellToCustomerCode: customer.id, sellToCustomerName: customer.name } })
    } else if (collection && updateStateCollection) {
      this.setState({ collection: { ...collection, bankCode: this.getBankCodeFromName(collection.bankCode) } })
    }
  }

  hasCollectionUpdated = (thisCollection = {}, nextCollection = {}) => {
    return JSON.stringify(thisCollection) !== JSON.stringify(nextCollection);
  }

  getBankCodeFromName = name => {
    const bank = banks.find(bank => bank.shortname === name);
    return bank && bank.bankid;
  }

  onInputChange = (field, value, errorKey) => {
    if (field === 'amount' && value !== "" && !this.isValidAmount(value)) return

    this.setState({ collection: { ...this.state.collection, [field]: value }, [errorKey]: undefined });
  }

  onSelectBankChange = bankid => {
    const selectedBank = banks.find(bank => bank.bankid === bankid)
    this.setState({ collection: { ...this.state.collection, bankCode: selectedBank.bankid, bankName: selectedBank.bankname }, bankCodeError: undefined });
  }

  onSelectPaymentTypeChange = type => {
    this.setState({ collection: { ...this.state.collection, paymentType: type } });
  }

  onSavePayment = () => {
    // if (!this.validateForm()) return;

    const { match } = this.props;
    const { collectionId } = match.params
    const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { addCollection, updateCollection, customer, toastManager } = this.props;
    const { collection } = this.state;
    this.setState({ loading: true });

    if (newCollectionId !== 'new') {
      setTimeout(() => {
        this.setState({ loading: false });
        toastManager.add('Payment successfully updated.', { autoDismiss: true, appearance: 'success' });
      }, 2000)
      // updateCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the collection.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      collection.mapLocation = currentLocation;
      setTimeout(() => {
        this.setState({ loading: false });
        toastManager.add('Successfully created a new payment.', { autoDismiss: true, appearance: 'success' });
        this.props.history.goBack()
      }, 2000)
      // addCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
      //     defaultPayment.sessionId = uuid();
      //     this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
      //     this.props.history.replace('/customers/' + customer.id + '/collections/' + response.collection.collectionNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  validateForm = () => {
    const { collection } = this.state;
    const { bankCode, amount, chequeNo, slipNo, paymentType, postingDate } = collection;

    let bankCodeError = undefined;
    let chequeNoError = undefined;
    let slipNoError = undefined;
    let amountError = undefined;
    let postingDateError = undefined;

    if (paymentType !== 'CASH') {
      if (!this.isValidValue(bankCode)) bankCodeError = 'Bank is required.'
      if ((paymentType === 'CHEQUE' || paymentType === 'PDC') && !this.isValidValue(chequeNo)) chequeNoError = 'Cheque no is required.'
      if ((paymentType === 'CHEQUE' || paymentType === 'PDC') && !postingDate) postingDateError = 'Posting date is required.'
      if (paymentType === 'DEPOSIT' && !this.isValidValue(slipNo)) slipNoError = 'Slip no is required.'
    }

    if (!this.isValidAmount(amount)) amountError = 'Invalid amount value.'

    this.setState({ bankCodeError, chequeNoError, slipNoError, amountError, postingDateError })

    return !bankCodeError && !chequeNoError && !slipNoError && !amountError && !postingDateError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  setImageLink = fileUrl => {
    const { addAttachments, toastManager } = this.props;
    const { collection } = this.state;

    this.setState({ loading: true });
    addAttachments({ ...collection, links: [fileUrl] }).then(response => {
      this.setState({ loading: false });
      if (!response.error) {
        toastManager.add('Successfully upload attachment.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Failed to upload attachment', { autoDismiss: true, appearance: 'error' });
      }
    })
    //this.setState({ collection: { ...this.state.collection, links: [fileUrl] } })
  }

  navigateToConfirmPayment = () => {
    const { paymentId } = this.props.match.params

    this.props.history.push({ pathname: '/payments/' + paymentId + '/confirm' })
  }

  onDeletePayment = () => {
    const { deleteCollection, toastManager, history } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      toastManager.add('Payment successfully deleted.', { autoDismiss: true, appearance: 'success' });
      history.goBack();
    }, 2000)
    // deleteCollection(this.state.collection).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete collection. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Collection successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     history.goBack();
    //   }
    // });
  }

  shareCollection = () => {
    const { collection } = this.state;
    //const reportLink = collection && collection.links && collection.links.length > 0 && collection.links.find(link => link.includes('collection-report'))
    //reportLink && window.open(Api.server + "/files/" + reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');
    // const { collectionId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // const printCollectionView = document.getElementById('printCollectionView');
    // printContainer.style.display = 'inline';
    // printCollectionView.style.display = 'none';
    // this.setState({ loading: true });

    // html2canvas(document.querySelector("#collectionPrintDiv"), { width: 685 }).then((canvas) => {
    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = collectionId + '.png';
    //   a.click();

    //   printCollectionView.style.display = 'inline';
    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  renderActions = () => {
    const { match, collection: payment = {} } = this.props;
    const { paymentId } = match.params
    const { status } = payment;
    const newPaymentId = paymentId ? paymentId : "new";

    if (newPaymentId === "new") {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        <Button type="primary" onClick={this.onSavePayment}>Create</Button>
      </Col></Row>
    } else {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {status === 'Unverified' &&
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={this.onDeletePayment}>
                <Button type="danger">Delete</Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button type="primary" onClick={this.onSavePayment}>Update</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={this.navigateToConfirmPayment}>Confirm</Button>
            </Col>
          </div>
        }

        {status !== 'Unverified' &&
          <Col>
            <Button
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, marginRight: 4 }} onClick={this.shareCollection}>Share</Button>
            <Button
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000 }} onClick={this.shareCollection}>Print</Button>
          </Col>
        }
      </Row>
    }
  }

  render() {
    const { match } = this.props;
    const { paymentId } = match.params
    const newPaymentId = paymentId ? paymentId : "new";
    const {
      loading,
      collection,
      bankCodeError,
      chequeNoError,
      slipNoError,
      amountError,
      postingDateError } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading collection"}>
        <Row id={'printCollectionView'}>
          <PaymentHeader
            newPaymentId={newPaymentId}
            payment={collection}
            onSavePayment={this.onSavePayment}
            onConfirmPayment={this.navigateToConfirmPayment}
            onDeletePayment={this.onDeletePayment}
          />
          <PaymentForm
            newPaymentId={newPaymentId}
            payment={collection}
            banks={banks}
            onInputChange={this.onInputChange}
            onSelectBankChange={this.onSelectBankChange}
            onSelectPaymentTypeChange={this.onSelectPaymentTypeChange}
            setImageLink={this.setImageLink}
            onSaveCollection={this.onSaveCollection}
            onConfirmCollection={this.navigateToConfirmCollection}
            bankCodeError={bankCodeError}
            chequeNoError={chequeNoError}
            slipNoError={slipNoError}
            amountError={amountError}
            postingDateError={postingDateError}
          />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
        </Row>
        <Row>
          <div id={'printContainer'} style={{ display: 'none' }}>
            <CollectionExport collection={collection} />
          </div>
        </Row>

      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, paymentId } = ownProps.match.params
  //return { customer: getCustomer(state, customerId), collection: getCollection(state, collectionId) };
  return { customer: getDemoCustomer(customerId), collection: getDemoPaymentItem(paymentId) };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchCustomer, addCollection, updateCollection, fetchCollection, deleteCollection, addAttachments })(Container)));

const banks = [
  { "_id": "nXykAeaxKY5iwj3Nf", "bankid": "17", "bankname": "Amana Bank PLC", "shortname": "Amana Bank" },
  { "_id": "a3EMXnpF2rCD9k9ZY", "bankid": "2", "bankname": "Bank of Ceylon", "shortname": "BOC" },
  { "_id": "af4F3jy9bS3G7TCmB", "bankid": "15", "bankname": "Cargills Bank Ltd", "shortname": "Cargills Bank" },
  { "_id": "oH9FPPuHG6wdD7rNA", "bankid": "12", "bankname": "Citibank", "shortname": "Citi Bank" },
  { "_id": "bzJvkTY7ztAzSr9CP", "bankid": "9", "bankname": "Commercial Bank of Ceylon PLC", "shortname": "Commercial" },
  { "_id": "5wDbsznEPvaPDsp9e", "bankid": "11", "bankname": "Deutsche Bank AG", "shortname": "Deutsche Bank" },
  { "_id": "FQr7DLgJHvMqsmeaF", "bankid": "14", "bankname": "DFCC Vardhana Bank PLC", "shortname": "DFCC" },
  { "_id": "PZe9SAt8QFY7HeyHZ", "bankid": "1", "bankname": "Hatton National Bank PLC", "shortname": "HNB" },
  { "_id": "pMEFuSGNK7f3i8rpe", "bankid": "21", "bankname": "Nation Trust Bank", "shortname": "NTB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
  { "_id": "c4ngHthzcBK7Aarqi", "bankid": "18", "bankname": "MCB Bank Ltd", "shortname": "MCB Bank" },
  { "_id": "w9pAudAozSXoMK4Zz", "bankid": "4", "bankname": "National Development Bank PLC", "shortname": "NDB" },
  { "_id": "S2kpZwpJphFgzeqSG", "bankid": "7", "bankname": "Pan Asia Banking Corporation PLC", "shortname": "Pan Asia Bank" },
  { "_id": "xWey22FL4K3rD7DF2", "bankid": "10", "bankname": "People’s Bank", "shortname": "Peoples" },
  { "_id": "7NMY9kBctARPvkgiX", "bankid": "20", "bankname": "Business Development Cooperative", "shortname": "Business Development Cooperative" },
  { "_id": "WGcjpYLz4tmDupfJj", "bankid": "3", "bankname": "Sampath Bank PLC", "shortname": "Sampath" },
  { "_id": "kjBoquK33CMLXCxCP", "bankid": "8", "bankname": "Seylan Bank PLC", "shortname": "Seylan" },
  { "_id": "j9jLkLhbRkHyjGiQs", "bankid": "16", "bankname": "Standard Bank", "shortname": "Standard Bank" },
  { "_id": "Pk9fBB5Rb7JRtY3kR", "bankid": "19", "bankname": "Bank of India", "shortname": "Bank of India" },
  { "_id": "ni9rx7s3XL6Auge7f", "bankid": "6", "bankname": "The Hongkong \u0026 Shanghai Banking Corporation Ltd", "shortname": "HSBC" },
  { "_id": "phjQCAyeGuoZXoyQf", "bankid": "13", "bankname": "Union Bank of Colombo PLC", "shortname": "Union" },
]