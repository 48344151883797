import * as fromApi from '../../api/generic';
import { getOfflineData, saveOfflinePaymentMethods } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB, createObjectStoreIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_CUSTOMER_PAYMENT_METHODS = 'FETCH_CUSTOMER_PAYMENT_METHODS';
export const REMOVE_CUSTOMER_PAYMENT_METHOD = 'REMOVE_CUSTOMER_PAYMENT_METHOD';

export const fetchPaymentMethods = (type, filter) => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    const { data, error } = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.PAYMENT_METHODS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    dispatch({ type: FETCH_PAYMENT_METHODS, paymentMethods: data });

  } else {
    return fromApi.get('PaymentMethod', filter).then(response => {

      const { data, error } = response;

      if (error) return { error };

      dispatch({ type: FETCH_PAYMENT_METHODS, paymentMethods: data });
      return response;
    }).catch(error => {
      return { error }
    });
  }

}

export const addPaymentMethodForCustomer = paymentOption => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {
    paymentOption.Sync = false;
    saveOfflinePaymentMethods(paymentOption);
    /*
    var response = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_METHODS).then((res)=>{
      return res;
    }).catch((err) => { console.log(err);})
    console.log(response);
    if(typeof(response) != "undefined"){
      response.data.push(paymentOption)
      await insertDataIndexDB(TABLES.CUSTOMERS,OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_METHODS,response)
    }else{
      let response = {"data": [paymentOption]}
      await insertDataIndexDB(TABLES.CUSTOMERS,OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_METHODS,response)
    }
    */

    var data = paymentOption;

    dispatch({ type: FETCH_CUSTOMER_PAYMENT_METHODS, paymentOptions: [data], customerNo: paymentOption.CustNo });

  } else {
    return fromApi.add('CustomerPaymentMethod', paymentOption).then(response => {
      const { data, error } = response;
      if (error) return { error }
      dispatch({ type: FETCH_CUSTOMER_PAYMENT_METHODS, paymentOptions: [data], customerNo: paymentOption.CustNo });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const fetchPaymentMethodsForCustomer = filter => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {
    let customerData = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res.data;
    }).catch((err) => { alert(err); })

    let paymentOption = customerData.filter(function (el) {
      return el.No == filter.CustNo
    }
    );

    if (paymentOption.length > 0) {
      var { data, error } = paymentOption[0].paymentMethods;

      dispatch({ type: FETCH_CUSTOMER_PAYMENT_METHODS, paymentOptions: data, customerNo: filter.CustNo });
    }

  } else {

    return fromApi.get('CustomerPaymentMethod', filter).then(response => {

      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_CUSTOMER_PAYMENT_METHODS, paymentOptions: data, customerNo: filter.CustNo });
      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const deletePaymentMethodFromCustomer = paymentOption => dispatch => {
  return fromApi.deleteObject('CustomerPaymentMethod', paymentOption).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: REMOVE_CUSTOMER_PAYMENT_METHOD, paymentOption });
    return response;
  }).catch(error => {
    return { error }
  })
};
