import React, { Component } from 'react';
import { Row, Card, Col, Select, DatePicker } from "antd";
import moment from "moment";
import ImageAttachmentContainer from "../../common/ImageAttachmentContainer";
import FormItem from '../../common/FormItem';
import Api from '../../../api/consts'
import { format } from "currency-formatter";
import Input, { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';
import { showNotification } from '../../../const/Notifications';
import AllocatedDetails from "../component/AllocatedDetails";
import { RECEIPTS } from "../../../const/Permissions";
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const { Option } = Select;

class ReceiptForm extends Component {
  state = {
    showDepositDetailForm: false,
    showChequePDCform: false
  }

  handleAttachmentError = error => {
    showNotification('error', 'Attachment Failed', error);

    onWorking && onWorking(false);
    const { onWorking } = this.props;
  }

  handleImageLink = link => {
    const { setImageLink, onWorking } = this.props;
    setImageLink(link);
    onWorking && onWorking(false);
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() < Date.now();
  }

  render() {
    const {
      onSelectBankChange,
      onSelectReceiptTypeChange,
      receipt = {},
      banks,
      onInputChange,
      bankCodeError,
      chequeNoError,
      slipNoError,
      amountError,
      postingDateError,
      chequeDateError,
      invoiceTotal = 0,
      onWorking,
      attachment } = this.props;
    const { loggedInUser: { permissions = [] }, onDataAllocated } = this.props;

    const { No, BankCode, CollectType, PayAmount, PayNo, CollectDateTime, ChequeDate, Remarks, OrderStatus, PDCChequeDate } = receipt;
    const showDepositDetailForm = CollectType === 'DEPOSIT' || CollectType === 'Direct Deposite'
    const showChequePDCform = CollectType === 'PDC' || (CollectType === 'CHEQUE' || CollectType === 'Cheque');
    const fileUrl = attachment && attachment.AttachmentURL ? Api.files.download.url(attachment.AttachmentURL) : undefined;
    const formWidth = No ? 12 : 24;
    const enableDeallocate = permissions.includes(RECEIPTS.DEALLOCATE);
    const appliedAmount = receipt.AppliedAmount ? parseFloat(receipt.AppliedAmount.replace(/,/g, "")) : 0;
    //const payAmount = receipt.PayAmount ? parseFloat(receipt.PayAmount.replace(/,/g, "")) : 0
    const allocatedAmount = appliedAmount + invoiceTotal;

    let chequeDate = ChequeDate && moment(ChequeDate).add('minutes', +330);
    let pdcChequeDate = PDCChequeDate && moment(PDCChequeDate).add('minutes', +330);

    //console.log(chequeDate, pdcChequeDate, "receipt", receipt)

    const disableEdit = false;
    // && OrderStatus !== 'Open ? true : false'

    return (
      <div style={{ margin: 10 }}>
        <Row>
          <Col lg={formWidth} md={formWidth} sm={24} xs={24} style={{ marginTop: 15 }}>
            <Col>
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bolder' }}>Allocated Amount</h3>
                  <h3 style={{ fontWeight: 'bolder' }}>{format(allocatedAmount, currencyFormat)}</h3>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bolder' }}>Balance Amount</h3>
                  <h3 style={{ fontWeight: 'bolder' }}>{format((PayAmount - allocatedAmount), currencyFormat)}</h3>
                </Col>
                <br></br>
              </Row>

              <Row gutter={5}>
                <Col>
                  <h3>Receive Payment</h3>
                </Col>
                <FormItem label={"Payment Type"} lg={12} md={12} sm={24} xs={24}>
                  <Select disabled={disableEdit} value={CollectType} onChange={onSelectReceiptTypeChange} style={{ width: '100%' }}>
                    {paymentTypes.map(type => <Option key={type.key} value={type.value}>{type.name}</Option>)}
                  </Select>
                </FormItem>
                <FormItem label={"Payment"} error={amountError} lg={12} md={12} sm={24} xs={24}>
                  <input
                    disabled={disableEdit}
                    className="ant-input"
                    value={PayAmount || 0}
                    style={{ width: '100%', textAlign: 'end' }}
                    type="number"
                    min="0"
                    onChange={(e) => onInputChange("PayAmount", e.target.value, "amountError")}
                    onFocus={(event) => event.target.select()} />
                </FormItem>
              </Row>
            </Col>
            {showChequePDCform && <Row gutter={5}>
              <FormItem label={"Bank"} error={bankCodeError} lg={12} md={12} sm={24} xs={24}>
                <Select disabled={disableEdit} value={BankCode} placeholder={"Select bank"} onChange={onSelectBankChange} style={{ width: '100%' }}>
                  {banks.map(bank => <Option key={bank.bankid} value={bank.bankid}>{bank.bankname}</Option>)}
                </Select>
              </FormItem>
              <FormItem label={"Cheque No"} error={chequeNoError} lg={12} md={12} sm={24} xs={24}>
                <Input disabled={disableEdit} value={PayNo} onChange={value => onInputChange("PayNo", value, "chequeNoError")} />
              </FormItem>
              {CollectType === "PDC" && <FormItem label={"Posting Date"} error={postingDateError} lg={24} md={24} sm={24} xs={24}>
                <DatePicker
                  disabledDate={(current) => this.disabledDate(current)}
                  disabled={disableEdit}
                  allowClear={false}
                  format={dateFormatView}
                  showToday={false}
                  style={{ width: '100%' }}
                  value={pdcChequeDate}
                  onChange={(date, dateString) => onInputChange("PDCChequeDate", date.add('minutes', -330).format(dateFormatUpdate), "postingDateError")}
                />
              </FormItem>}
              {CollectType === 'CHEQUE' && <FormItem label={"Cheque Date"} error={chequeDateError} lg={24} md={24} sm={24} xs={24}>
                <DatePicker
                  disabled={disableEdit}
                  allowClear={false}
                  format={dateFormatView}
                  style={{ width: '100%' }}
                  value={chequeDate}
                  onChange={(date, dateString) => onInputChange("ChequeDate", date.add('minutes', -330).format(dateFormatUpdate), "chequeDateError")}
                />
              </FormItem>}
            </Row>}
            {showDepositDetailForm && <Row>
              <FormItem label={"Bank"} error={bankCodeError} lg={24} md={24} sm={24} xs={24}>
                <Select disabled={disableEdit} value={BankCode} placeholder={"Select bank"} onChange={onSelectBankChange} style={{ width: '100%' }}>
                  {banks.map(bank => <Option key={bank.bankid} value={bank.bankid}>{bank.bankname}</Option>)}
                </Select>
              </FormItem>
              <FormItem label={"Slip No"} error={slipNoError} lg={24} md={24} sm={24} xs={24}>
                <Input disabled={disableEdit} value={PayNo} onChange={value => onInputChange("PayNo", value, "slipNoError")} />
              </FormItem>
            </Row>}
            <Row>
              <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24}>
                <TextArea
                  disabled={disableEdit}
                  rows={4}
                  value={Remarks}
                  placeholder={"Type here"}
                  onChange={(value, e) => onInputChange("Remarks", value, "")}>
                </TextArea>
              </FormItem>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col xs={0} sm={0} md={24} lg={24}>
                <AllocatedDetails docType={"1"} docNo={No} isMobileView={false} enableDeallocate={enableDeallocate} onDataAllocated={onDataAllocated} />
              </Col>
              <Col xs={24} sm={24} md={0} lg={0}>
                <AllocatedDetails docType={"1"} docNo={No} isMobileView={true} enableDeallocate={enableDeallocate} onDataAllocated={onDataAllocated} />
              </Col>
            </Row>
          </Col>
          {No &&
            <div>
              <Col lg={12} md={12} sm={0} xs={0} style={{ paddingLeft: 10, marginTop: 15 }}>
                <div style={{ fontWeight: 500 }}>Attach image</div>
                <ImageAttachmentContainer
                  setImageLink={this.handleImageLink}
                  onAttachingStart={() => onWorking && onWorking(true)}
                  onError={this.handleAttachmentError}
                  fileUrl={fileUrl}
                  dirPath={"payments/" + No + "/attachments"}
                  fileName={"payments-" + No + "-" + new Date().getTime()}
                  customerSigned={false} />
              </Col>
              <Col lg={0} md={0} sm={24} xs={24}>
                <div style={{ fontWeight: 500 }}>Attach image</div>
                <ImageAttachmentContainer
                  setImageLink={this.handleImageLink}
                  onAttachingStart={() => onWorking && onWorking(true)}
                  onError={this.handleAttachmentError}
                  fileUrl={fileUrl}
                  dirPath={"payments/" + No + "/attachments"}
                  fileName={"payments-" + No + "-" + new Date().getTime()}
                  customerSigned={false} />
              </Col>
            </div>}
        </Row>
      </div>
    )
  }
}

export default ReceiptForm;

const paymentTypes = [
  {
    name: 'CASH',
    value: 'CASH',
    key: 'CASH'
  },
  {
    name: 'PDC - Post Dated Cheque',
    value: 'PDC',
    key: 'PDC'
  },
  {
    name: 'CHEQUE',
    value: 'CHEQUE',
    key: 'CHEQUE'
  },
  {
    name: 'Direct Deposit',
    value: 'DEPOSIT',
    key: 'DEPOSIT'
  }
]