
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchDistributors } from "../../redux/actions/distributors";
import { fetchPurchaseCreditMemoReasons } from "../../redux/actions/purchasingHeaders";
import { fetchPurchaseCreditInvoices } from "../../redux/actions/purchaseCreditInvoices";
import { getAuthData, getLoggedInUser, DOC_TYPE_FLAG_BY_DEFINITION, getTopPosition, getFlatPositions, getPurchaseCreditInvoicesBySearchKey, isFetchingPurchaseCreditInvoices, getPurchaseCreditInvoiceMetadata, getPurchaseCreditInvoicesByType, getPurchaseCreditInvoiceTotal } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import OrderTable from "./OrderTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import { withToastManager } from 'react-toast-notifications';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';

const creditInvoicesStatuses = {
  Open: 0,
  'Confirmed': 1
};

class Orders extends Component {
  baseUrl = window.location.pathname;
  state = {
    loading: false,
    filter: {
      DocType: undefined,
      UserCode: undefined,
      DocReasonCode: undefined,
      text: undefined,
      text2: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day"),
    },
    currentPage: 1
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchDistributors, fetchPurchaseCreditMemoReasons } = this.props;
    const { distributorId } = match.params

    fetchDistributors({ UserCode: userId });
    fetchPurchaseCreditMemoReasons().then(response => console.log("response"));

    this.setState({
      filter: {
        ...this.state.filter,
        UserCode: userId,
        DistributorCode: distributorId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { distributorId } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadOrders(nextProps);
      });
    }, { ...this.state.filter, DistributorCode: distributorId });
  }

  loadOrders = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  fetchOrderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchOrders(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  fetchOrders = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      OrderDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      UserCode: undefined,
      text: undefined,
      text2: undefined,
      No: filter.text ? '*' + filter.text + '*' : undefined,
      ReturnOrderNo: filter.text2 ? '*' + filter.text2 + '*' : undefined,
      OrderStatus: filter['Status'],
    }
    this.props.fetchPurchaseCreditInvoices(updatedFilter, page, searchKey);
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing invoices.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
    }

    newFilter.StatusFilter = newFilter.Status ? 'Yes' : 'No';

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No, CustNo: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }


  getTitle = () => {
    let type = 'Purchase Credit Invoices';
    return type;
  }

  render() {
    const { filter, currentPage } = this.state;
    const { loading, orders, total, totalAmount, topPosition, match } = this.props;

    return (
      <div>
        <PromatePageHeader
          title={this.getTitle()}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Spin spinning={(loading && orders.length === 0) || !topPosition} tip={"Loading..."}>
            <OrderTable
              orders={orders}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              setDefaultCustomerOption={this.setDefaultCustomerOption}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 20,
    right: 180,
    height: 50
  }
};

Orders.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => {
  const searchKey = getSearchKey();
  return {
    orders: getPurchaseCreditInvoicesBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getPurchaseCreditInvoiceTotal(state, searchKey),
    totalAmount: getPurchaseCreditInvoiceMetadata(state, searchKey).TotalAmountIncluVAT,
    loading: isFetchingPurchaseCreditInvoices(state),
  };
};

export default withToastManager(connect(mapStateToProps, { fetchPurchaseCreditInvoices, fetchDistributors, fetchPurchaseCreditMemoReasons })(Orders));