import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchPurchasingInvoiceHeaders } from "../../redux/actions/purchasingInvoiceHeaders";
import { getAuthData, getLoggedInUser, getTopPosition, getFlatPositions, getPurchasingInvoiceHeadersBySearchKey, getPurchasingInvoiceTotal, getPurchasingInvoiceMetadata, isFetchingPurchasingInvoiceHeaders } from "../../redux/reducers";
import { PageHeader, Spin, Button } from "antd";
import moment from 'moment';
import { ProMateGreen } from "../../const/Theme";
import InvoiceTable from "./InvoiceTable";
import PromatePageHeader from '../common/PromatePageHeader';
import { FaPlus } from 'react-icons/fa';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';

class PurchasingInvoices extends Component {
  baseUrl = window.location.pathname;

  state = {
    loading: false,
    filter: {
      UserCode: undefined,
      text: undefined,
      text_2: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day"),
      TerritoryCode: undefined
    },
    currentPage: 1
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchCustomerCategories, fetchPurchaseCreditMemoReasons } = this.props;

    this.setState({
      filter: {
        ...this.state.filter,
        UserCode: userId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { distributorId } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadInvoices(nextProps);
      });
    }, { ...this.state.filter, DistributorCode: distributorId });
  }

  loadInvoices = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchInvoices(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchInvoices(filter, currentPage, currentSearchKey);
  }

  fetchInvoices = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      OrderDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      UserCode: undefined,
      text: undefined,
      No: filter.text ? '*' + filter.text + '*' : undefined,
      OrderNo: filter.text2 ? '*' + filter.text2 + '*' : undefined,
    }
    this.props.fetchPurchasingInvoiceHeaders(updatedFilter, page, searchKey);
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = (filter, value, forceReload) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
      case 'DocType':
        newFilter.DocType = value;
        break;
      case 'StatusFilter':
        newFilter.StatusFilter = value;
        break;
      default:
        break;
    }

    this.setState({ filter: newFilter }, () => {
      if (forceReload) {
        this.handleSearchClick();
      }
    });
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  render() {
    const { purchasingInvoices, total, metaData } = this.props;
    const { loading, filter, currentPage } = this.state;

    //console.log("Logged in user", this.props.loggedInUser);

    return (
      <div>
        <PromatePageHeader
          title={'Purchasing Invoices'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={this.showNewButton() && [
            <Button style={window.innerWidth < 768 ? styles.addBtnMobile : styles.addBtnWeb} onClick={this.onClickNew}>
              {
                window.innerWidth < 768 ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>
          <Spin spinning={loading && purchasingInvoices.length === 0} tip={"Loading Invoices"}>
            <InvoiceTable
              purchasingInvoices={purchasingInvoices}
              filter={filter}
              metaData={metaData}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              onPaginationChange={this.handlePaginationChange}
              total={total} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { match } = this.props;
    const url = match.url
    //console.log(url)
    this.props.history.push(url + "/new");
    //this.props.history.push("/collections/new");
  }

  showNewButton = () => {
    const { match } = this.props;
    const { authData: { userId, roleCode } } = this.props;
    //console.log(roleCode)
    if (roleCode === "DISTRIBUTOR") {
      return true;
    } else {
    }
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 20,
    right: 180,
    height: 50
  }
};

const mapStateToProps = (state, ownProps) => {
  const searchKey = getSearchKey();

  return {
    purchasingInvoices: getPurchasingInvoiceHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getPurchasingInvoiceTotal(state, searchKey),
    metaData: getPurchasingInvoiceMetadata(state, searchKey),
    loading: isFetchingPurchasingInvoiceHeaders(state)
  };
};

export default connect(mapStateToProps, { fetchPurchasingInvoiceHeaders })(PurchasingInvoices);