import React, { Component } from 'react';
import Api from '../../api/consts'
import fetch from '../../api/commonFetch';
import { getOfflineData } from '../../localStorage';

class NetworkDetector extends Component {
  state = {
    isDisconnected: !window.navigator.onLine,
    serverConnectionLost: false
  }

  componentDidMount() {
    this.monitorServerConnection();
    this.connectionMonitor = setInterval(this.monitorServerConnection, 15000);

    window.addEventListener('online', this.handleOnlineChange);
    window.addEventListener('offline', this.handleOnlineChange);
  }

  componentWillUnmount() {
    clearInterval(this.connectionMonitor);
    window.removeEventListener('online', this.handleOnlineChange);
    window.removeEventListener('offline', this.handleOnlineChange);
  }

  handleOnlineChange = e => {
    if (!e) {
      return;
    }

    const { isDisconnected } = this.state;
    if (e.type === 'online' && isDisconnected) {
      this.setState({ isDisconnected: false });
      this.props.onNetworkErrorHandler('isDisconnected', false)
    } else if (e.type === 'offline' && !isDisconnected) {
      this.setState({ isDisconnected: true });
      this.props.onNetworkErrorHandler('isDisconnected', true)
    }
  }

  monitorServerConnection = () => {
    if (!getOfflineData('state').isSystemOffline) {
      const { serverConnectionLost } = this.state;
      const { url, method } = Api.generic.health;

      fetch(url(), { method }).then(response => {
        const connectionLost = !response.response;

        if (connectionLost !== serverConnectionLost) {
          this.setState({ serverConnectionLost: connectionLost });
          this.props.onNetworkErrorHandler('serverConnectionLost', connectionLost)
        }
      }).catch(() => {
        if (!serverConnectionLost) {
          this.setState({ serverConnectionLost: true });
          this.props.onNetworkErrorHandler('serverConnectionLost', true)
        }
      })
    }
  }

  render() {
    const { isDisconnected, serverConnectionLost } = this.state;
    const { isOffline } = this.props;

    let connectionError = null;
    let offlineDetector = null;

    if (isDisconnected) {
      connectionError = (
        <div className="internet-lost">
          <p>No internet.</p>
        </div>
      );
    } else if (serverConnectionLost) {
      connectionError = (
        <div className="server-connection-lost">
          <p>
            Cannot connect to server.<br />
            Please contact PrintXcel IT 0776844273.
          </p>
          <br />
        </div>
      );
    }

    return (
      <div>
        {isOffline ? <div className="offline-mode">
          <p>You are in offline mode.
            {isDisconnected ? ' No internet.' : ''}
          </p>
        </div>
          :
          connectionError
        }
      </div>
    );
  }
}

export default NetworkDetector;