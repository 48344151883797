import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { withRouter } from 'react-router-dom';
import { fetchCustomers } from '../../redux/actions/customers'
import { getCustomers, getAuthData } from "../../redux/reducers";

const { Option } = Select;

class SelectOutlet extends Component {
  state = { loading: false }

  componentDidMount = () => {
    this.handleFetchCustomers(this.props);
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.filter.RouteCode !== nextProps.filter.RouteCode) {
      this.handleFetchCustomers(nextProps)
    }
  }

  handleFetchCustomers = props => {
    const { filter = {}, fetchCustomers, setDefaultCustomerOption, match } = props;
    const { customerId } = match.params;

    const customerFilter = {};
    let loadCustomers = false;
    if (filter.RouteCode) {
      customerFilter.RouteCode = filter.RouteCode;
      loadCustomers = true;
    } else if (customerId) {
      customerFilter.No = customerId;
      loadCustomers = true;
    }

    if (loadCustomers) {
      this.setState({ loading: true });
      fetchCustomers(customerFilter).then(response => {
        if (response.customers && response.customers.length === 1) {
          setDefaultCustomerOption(response.customers[0])
        }

        this.setState({ loading: false })
      });
    }
  }

  setDefaultFilter = () => {
    const { customers, onChange, value } = this.props;
    if (customers.length === 1 && value !== customers[0].No) {
      setTimeout(() => {
        onChange('CustNo', customers[0].No, true);
      }, 0);
    }
  }

  render() {
    this.setDefaultFilter();

    const {
      customers,
      onChange,
      showSearch = true,
      placeholder = 'Customer Name',
      allowClear = true,
      disabled = false,
      value,
      filter
    } = this.props
    const disableSelect = disabled || !filter || (filter && !filter.RouteCode) || customers.length < 2;
    return (
      <Select
        disabled={disableSelect}
        placeholder={placeholder}
        value={value}
        onChange={(value) => onChange('CustNo', value)}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(inputValue, option) => {
          const text = option.props.children.toLowerCase();
          return text.indexOf(inputValue.toLowerCase()) > -1;
        }}
        loading={this.state.loading}
      >
        {customers.map(customer =>
          <Option value={customer.No}>{customer.Name}</Option>
        )}
      </Select>
    )
  }
}

const mapStateToProps = state => {
  return {
    customers: getCustomers(state),
    authData: getAuthData(state)
  };
};

export default withRouter(connect(mapStateToProps, { fetchCustomers })(SelectOutlet));
