import { combineReducers } from 'redux';
import { FETCH_PURCHASING_INVOICE_HEADERS, START_FETCH_PURCHASING_INVOICE_HEADERS, FETCH_PURCHASING_INVOICE_HEADER, FINISH_FETCH_PURCHASING_INVOICE_HEADERS } from '../actions/purchasingInvoiceHeaders';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS:
      const newState = { ...state };
      action.purchasingInvoiceHeaders.forEach(purchasingHeader => newState[purchasingHeader.No] = purchasingHeader);
      return newState;
    case FETCH_PURCHASING_INVOICE_HEADER: {
      const newState = { ...state };
      newState[action.purchasingInvoiceHeader.No] = action.purchasingInvoiceHeader;
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS: {
      const newState = [...state]
      action.purchasingInvoiceHeaders.forEach(purchasingInvoiceHeader => {
        if (!newState.includes(purchasingInvoiceHeader.No)) newState.push(purchasingInvoiceHeader.No);
      });

      return newState;
    }
    case FETCH_PURCHASING_INVOICE_HEADER: {
      const newState = [...state];
      if (!newState.includes(action.purchasingInvoiceHeader.No)) {
        newState.push(action.purchasingInvoiceHeader.No)
      }
      return newState;
    }
    default:
      return state;
  }
};

export const byType = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS: {
      const newState = {};
      action.purchasingInvoiceHeaders.forEach(purchasingInvoiceHeader => {
        const key = purchasingInvoiceHeader.DocType;
        const idsByType = newState[key] || [];
        if (!idsByType.includes(purchasingInvoiceHeader.No)) {
          idsByType.push(purchasingInvoiceHeader.No);
          newState[key] = idsByType;
        }
      });

      return newState;
    }
    case FETCH_PURCHASING_INVOICE_HEADER: {
      const newState = {};
      const idsByType = newState[action.purchasingInvoiceHeader.DocType] || [];

      if (!idsByType.includes(action.purchasingInvoiceHeader.No)) {
        newState[action.purchasingInvoiceHeader.DocType] = [...idsByType, action.purchasingInvoiceHeader.No];
      }

      return newState;
    }
    default:
      return state;
  }
};

export const byTypeAndNo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS: {
      const newState = { ...state };
      action.purchasingInvoiceHeaders.forEach(purchasingInvoiceHeader => {
        const key = purchasingInvoiceHeader.DocType + '-' + purchasingInvoiceHeader.No
        const idsByTypeAndNo = newState[key] || [];
        if (!idsByTypeAndNo.includes(purchasingInvoiceHeader.No)) {
          idsByTypeAndNo.push(purchasingInvoiceHeader.No);
          newState[key] = idsByTypeAndNo;
        }
      });
      return newState;
    }
    case FETCH_PURCHASING_INVOICE_HEADER: {
      const newState = { ...state };
      const key = action.purchasingInvoiceHeader.DocType + '-' + action.purchasingInvoiceHeader.No
      const idsByType = newState[key] || [];

      if (!idsByType.includes(action.purchasingInvoiceHeader.No)) {
        newState[key] = [...idsByType, action.purchasingInvoiceHeader.No];
      }

      return newState;
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.purchasingInvoiceHeaders.map(header => header.No)
      };
    }
    default:
      return state;
  }
};

export const total = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.total
      };

    default:
      return state;
  }
}

export const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PURCHASING_INVOICE_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.metadata
      };

    default:
      return state;
  }
}

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_PURCHASING_INVOICE_HEADERS:
      return true;

    case FINISH_FETCH_PURCHASING_INVOICE_HEADERS:
      return false;

    default:
      return state;
  }
}

const purchasingInvoiceHeaders = combineReducers({
  byId,
  ids,
  byType,
  byTypeAndNo,
  bySearchKey,
  total,
  metadata,
  fetching
});

export default purchasingInvoiceHeaders;

export const getPurchasingInvoiceHeadersByType = (state, type) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const getByTypeAndNo = (state, type, no) => {
  const key = type + '-' + no;
  const ids = state.byTypeAndNo[key] || [];

  return ids && ids.length > 0 && state.byId[ids[0]];
}

export const getByDocNo = (state, no) => {
  const key = no;
  return state.byId[key] || undefined;
}

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;