import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEM = 'FETCH_ITEM';;

export const fetchItems = (filter) => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {
    var { data, error } = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.ITEMS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    if (error) return { error }

    if (data.length === 1) {
      dispatch({ type: FETCH_ITEM, item: data[0] });
      dispatch({ type: 'FETCH_INVENTORY_ITEM', item: data[0] });
      //dispatch({ type: 'FETCH_INVENTORY', items: data });
    }
    else {
      dispatch({ type: FETCH_ITEMS, items: data });
      dispatch({ type: 'FETCH_INVENTORY', items: data });
    }
    return data;
  } else {
    return fromApi.get('Item', filter).then(response => {
      const { data, error } = response;

      if (error) return { error }

      if (data.length === 1) {
        dispatch({ type: FETCH_ITEM, item: data[0] });
        dispatch({ type: 'FETCH_INVENTORY_ITEM', item: data[0] });
        //dispatch({ type: 'FETCH_INVENTORY', items: data });
      }
      else {
        dispatch({ type: FETCH_ITEMS, items: data });
        dispatch({ type: 'FETCH_INVENTORY', items: data });
      }

      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const updateAllItems = (items) => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    const response = await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.ITEMS, { data: items });
    return response;
  }
};
