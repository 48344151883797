import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../const/Theme";
import NetworkDetector from '../common/NetworkDetector';
import SalesRepCallHandler from '../common/SalesRepCallHandler';
import { getAuthData, getInProgressCall, getNetworkModeDetector } from '../../redux/reducers';
import { logOut } from '../../redux/actions/auth'
import Api from '../../api/consts'
import * as fromApiGeneric from '../../api/generic';
import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../localStorage';

import { FiPhone } from 'react-icons/fi';
import { AiOutlineHome } from 'react-icons/ai';
import { MdArrowBack, MdFullscreen } from 'react-icons/md';
import EllipsisText from "react-ellipsis-text";
import OfflineButtonComponent from '../Offline/OfflineButtonComponent';
import LocationMap from '../common/LocationMap';
import { decryptValue } from "../TargetScheduler/common/helpers";

class PromatePageHeader extends Component {
  state = {
    isDisconnected: !window.navigator.onLine,
    serverConnectionLost: false,
    loading: false,
    buttonText: "EXTRACT",
    isDisabled: false,

  }

  componentDidMount = () => {
    this.monitorServerConnection();
  }

  monitorServerConnection = () => {
    const { serverConnectionLost } = this.state;
    const { url, method } = Api.generic.health;

    // fetch(url(), { method }).then(response => {
    //   this.setState({ serverConnectionLost: false });
    // }).catch(() => {
    //   if (!serverConnectionLost) {
    //     this.setState({ serverConnectionLost: true });
    //   }
    // })
  }

  networkErrorHandler = (field, value) => {
    this.setState({ [field]: value });
  }

  getTitleLength = (title) => {
    const width = window.innerWidth;
    const caractorLength = 4
    const noOfCaractorsInSpace = width / caractorLength;
    //console.log(width, caractorLength, noOfCaractorsInSpace)
    if (width < 768 && noOfCaractorsInSpace < 20) {
      return title.length;
    } else if (width < 768 && noOfCaractorsInSpace >= 20) {
      return 20;
    } else if (width >= 768) {
      return title.length
    }
  }


  //   deleteCall = () => {
  //     console.log('Bye');
  //   return fromApiGeneric.deleteObject('SalesHeader', {
  //     "SessionID": "cBMdPzxpGww8HRx",
  //     "DocType": "Order",
  //     "No": "SO2932/22/00001",
  //     "SellToCusNo": "RDCST003328",
  //     "SellToCusName": "3&K TRADERS",
  //     "BillToCusNo": "RDCST003328",
  //     "BillToCusName": "3&K TRADERS",
  //     "LocCode": "MWH-2932",
  //     "OrderDate": "01/05/22",
  //     "MapLocation": "6.8903249000000,79.9752103000000",
  //     "Status": "Customer Confirmed",
  //     "Amount": "67.50",
  //     "AmountIncluVAT": "67.50",
  //     "OrderStatus": "Customer Confirmed",
  //     "SignedBy": "Customer",
  //     "PricesIncluVAT": "No",
  //     "UserCode": "",
  //     "OrderDateFilter": "12/30/21 06:30 PM..01/31/22 06:29 PM",
  //     "RouteCode": "R00267",
  //     "OutletCategoryCode": "GROCERY",
  //     "DisPersentage": "0.00",
  //     "DisAmount": "0.00",
  //     "PayMethodCode": "CASH",
  //     "PayTermCode": "COD",
  //     "DistributorCode": "PXLCST2932",
  //     "CreatedUserID": "1001888",
  //     "ModifiedUserID": "1001888",
  //     "Remarks": "",
  //     "AmountLCY": "0.00",
  //     "RemAmount": "0.00",
  //     "RemAmountLCY": "0.00",
  //     "AllocatedAmount": "0.00",
  //     "AllocatedAmountLCY": "0.00",
  //     "StatusFilter": "No",
  //     "ShipToCode": "",
  //     "ShipToName": "3&K TRADERS",
  //     "ShipToAdd": "158,ROBATGUNAWARDANA MAWATHA,BATHTHARAMULLA",
  //     "CreatedUserName": "Lahiru Sandaruwan",
  //     "ModifiedUserName": "Lahiru Sandaruwan",
  //     "OutstandAmtFilte": "No",
  //     "DeliveryCharge": "0.00",
  //     "UserTagFilterOne": "CSR-024",
  //     "UserTagFilterTwo": "",
  //     "OutletSubCategoryCode": "",
  //     "AvgDisPersentage": "0",
  //     "CreatedDateTime": "01/05/22 09:42 AM",
  //     "ModifiedDateTime": "01/05/22 09:42 AM",
  //     "CSRUserCode": "1001888",
  //     "CSRUserName": "Lahiru Sandaruwan",
  //     "ReturnReasonCode": "",
  //     "ReturnReasonDescrip": "",
  //     "CustStatus": "Active",
  //     "RouteName": "Koswatta to Pothuarawa Road",
  //     "DocDate": "01/05/22"
  // }).then(response => {
  //     const { error } = response;
  //     console.log(response);
  //     if (error) return { error }

  //     // dispatch({ type: DELETE_SALES_HEADER, salesHeader });
  //     return response;
  //   }).catch(error => {
  //     console.log(error);
  //     return { error }
  //   })
  // }


  offlineButton(mode) {
    if (mode) {
      return "Offline";
    } else {
      return "Online"
    }
  }

  getRange(){
    if (decryptValue(localStorage.getItem('userType')) == "r1") {
      return "Range - 1";
    }else if (decryptValue(localStorage.getItem('userType')) == "r2") {
      return "Range - 2";
    }
    return "";
  }

  myErrorHandler(error, info) {
    console.log('myErrorHandler: ', error, info);
    // Do something with the error
    // E.g. log to an error logging client here
  }

  render() {
    const { isSystemOffline, title, onBack, extra, children, titleColor = '#000', ongoingCall = {}, hideSubTitle = false,
      showProfileIcon = true, showHomeIcon = true, showToggleButton = false, authData: { userId, roleCode, displayName }, isOffline, history = {} } = this.props;

    var { showMap, isDisconnected, serverConnectionLost } = this.state;
    var height = 55;
    if (isSystemOffline) {
      height = 80;
    } else {
      if (serverConnectionLost) {
        height = 100;
      }
      if (isDisconnected) {
        height = 80;
      }
      // var height = (isDisconnected || serverConnectionLost) ? 60 : ongoingCall && this.context.screenType === 'MOBILE' && !hideSubTitle ? 80 : 60;
    }
    const callText = ongoingCall ? ongoingCall.StartReasonDescrip + ' - ' + ongoingCall.CustName : '';
    const titleLength = this.getTitleLength(title);

    return (
      <div>
        <div style={{ display: 'none' }}>
          <LocationMap />
        </div>
        <div style={styles.pageHeader}>
          <NetworkDetector onNetworkErrorHandler={this.networkErrorHandler} isOffline={isSystemOffline} />
          <div className="promate-page-header" style={{ height: ongoingCall && this.context.screenType === 'MOBILE' ? 65 : 55, display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingBottom: 0, paddingTop: 0, paddingRight: 10 }} >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100% ' }}>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: showToggleButton ? 6 : this.context.screenType === 'MOBILE' && ongoingCall ? 10 : 9 }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                  {onBack && <MdArrowBack style={{ color: titleColor, fontSize: 25, marginRight: 8, marginTop: 5 }} onClick={onBack}></MdArrowBack>}
                  {!showHomeIcon && <AiOutlineHome style={{ color: titleColor, fontSize: 25, marginRight: 8 }}></AiOutlineHome>}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                    {/* <span style={{ color: titleColor, fontSize: 24, fontWeight: 600 }} >{title}</span> */}
                    <EllipsisText style={{ color: titleColor, fontSize: 22, fontWeight: 600 }} text={title} length={titleLength} />
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: this.context.screenType === 'MOBILE' ? 'row' : 'row-reverse' }}>
                  {this.context.screenType === 'DESKTOP' && showHomeIcon && <span
                    onClick={() => this.props.history.push('/')}
                    key='home'
                    style={styles.logout}>
                    <AiOutlineHome style={{ fontSize: 24, color: '#ffffff' }} />
                  </span>}
                 
                  {extra}
               
                  {roleCode === 'CSR' &&
                    showToggleButton &&
                    <div style={{ marginLeft: 15, marginRight: 15 }}>
                      <div style={{ fontWeight: 'bold', color: 'white', fontSize: 12, textAlign: 'center' }}>
                        {this.offlineButton(isSystemOffline)}
                      </div>

                      <OfflineButtonComponent name="Offline" btnActionType="Offline" ongoingCall={ongoingCall} history={history} />

                    </div>
                  }

                  {
                    (this.props.showCallButton == null || this.props.showCallButton) && (
                      <SalesRepCallHandler showHomeIcon={showHomeIcon} showProfileIcon={showProfileIcon} screenType={this.context.screenType} displayNetworkDetector={(isDisconnected || serverConnectionLost)} />
                    )
                  }
                  {
                    <div style={{ marginLeft: 2, marginRight: 15, fontWeight:'bold', color:'white' , fontSize:16}}>
                       {this.getRange()}
                    </div>
                  }
                </div>
              </div>

              <div style={{ marginLeft: onBack || !showHomeIcon ? 4 : 0, marginTop: 0 }}>
                {this.context.screenType === 'MOBILE' && ongoingCall && !hideSubTitle && roleCode === "CSR" &&
                  <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => { this.props.history.push("/calls/" + ongoingCall.No) }}>
                    <FiPhone style={{ color: titleColor, ontSize: 11, marginRight: 2, }} />
                    <span style={{ color: titleColor, pointer: 'cursor', fontSize: 11 }}>{callText}</span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        {/* <PageHeader
          title={title}
          subTitle = "Ongoing call order aaa"
          style={styles.pageHeader}
          onBack={onBack}
          extra={extra} 
          className="promate-page-header"/>
        </div> */}
        <div style={{ height }}></div>
        {children}
      </div >
    )
  }
}

const mapStateToProps = state => {
  const authData = getAuthData(state)
  const ongoingCall = getInProgressCall(state);
  const isSystemOffline = getNetworkModeDetector(state)

  return { authData, ongoingCall, isSystemOffline }
}


PromatePageHeader.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps, { logOut })(PromatePageHeader));


const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    //height: 55,
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
  },
  logout: {
    cursor: 'pointer',
    marginLeft: 25,
    marginTop: 5
    //position: 'absolute',
    //top: 33,
    //right: 20
  },
};
