import * as fromApi from '../../api/generic';

export const FETCH_PURCHASE_CREDIT_INVOICES = 'FETCH_PURCHASE_CREDIT_INVOICES';
export const START_FETCH_PURCHASE_CREDIT_INVOICES = 'START_FETCH_PURCHASE_CREDIT_INVOICES';
export const FINISH_FETCH_PURCHASE_CREDIT_INVOICES = 'FINISH_FETCH_PURCHASE_CREDIT_INVOICES';

let lastSearchKey;
export const fetchPurchaseCreditInvoices = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_PURCHASE_CREDIT_INVOICES });

  return fromApi.get('PurchaseCrMemoHeader', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    dispatch({ type: FETCH_PURCHASE_CREDIT_INVOICES, creditInvoices: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: FINISH_FETCH_PURCHASE_CREDIT_INVOICES });
  });
};