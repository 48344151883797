import { getOfflineData, saveOfflineData, removeOfflineItem, savePaymentMethods, savePaymentTerms, saveOfflineCustomer } from '../../localStorage';
import { testIndexDB, deleteIndexDB, createObjectStoreIndexDB, readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';

import * as fromApiGeneric from '../../api/generic';
import * as fromApiCalls from '../../api/calls';
import * as fromApiCustomers from '../../api/customers';
import salesHeaders from '../reducers/salesHeaders';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects';
import { upload } from '../../api/files';
import { getSalesHeadersByTypeAndNo, DOC_TYPE_ID_BY_DEFINITION, getAuthData } from '../reducers';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import moment from "moment";

export const NETWORK_MODE = "NETWORK_MODE";
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CALLS = 'FETCH_CALLS';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_TRIP_SCHEDULES = 'FETCH_TRIP_SCHEDULES';

const serverError = "An error occured while connecting to the server.";

export const NETWORK_MODE_INITIAL = () => {
  if (typeof getOfflineData("state") === 'undefined') {
    return false;
  } else if (getOfflineData("state").isSystemOffline == true) {
    return true;
  } else {
    return false;
  }
}

export const changeNetworkMode = (mode) => {
  return {
    type: NETWORK_MODE,
    payload: mode
  }
};


var loadPaymentMethodsForOfflineCustomers = filter => {
  return fromApiGeneric.get('CustomerPaymentMethod', filter).then(response => {

    const { data, error } = response;
    if (error) return { error }
    // dispatch({ type: FETCH_CUSTOMER_PAYMENT_METHODS, paymentOptions: data, customerNo: filter.CustNo });
    return response;
  })
}

var loadPaymentTermsForOfflineCustomers = filter => {
  return fromApiGeneric.get('CustomerPaymentTerms', filter).then(response => {

    const { data, error } = response;
    if (error) return { error }

    // dispatch({ type: FETCH_CUSTOMER_PAYMENT_METHODS, paymentOptions: data, customerNo: filter.CustNo });
    return response;
  })
}



export const loadOfflineCustomers = () => async dispatch => {
  const code = getOfflineData("state").users.hierarchy;
  const userHierarchyObjectKeys = Object.keys(code);

  let tripSchedulesById = getOfflineData("state").tripSchedules.byId;
  var routeCodes = [];

  Object.keys(tripSchedulesById).map(function (key) {
    routeCodes.push(tripSchedulesById[key].RouteCode)
  });

  let filter;
  var rows = { data: [] };
  const page = 0

  return new Promise(async (resolve, reject) => {

    for (let i = 0; i < routeCodes.length; i++) {
      const routeCode = routeCodes[i];

      filter = {
        "RouteCode": routeCode,

        "UserHierarchy": [
          {
            "value": userHierarchyObjectKeys[0],
          }
        ],
        "UserTagFilterOne": userHierarchyObjectKeys[0],
        "UserTagFilterTwo": ""
      }

      var customerData = await fromApiGeneric.get('Customer', filter);
      for (let j = 0; j < customerData.data.length; j++) {
        rows.data.push(customerData.data[j]);
      }
    }

    let customers = [] = rows.data;
    var count = 0;

    const getPaymentMethodsPaymentTermsAndSave = async (customers) => {
      for (let j = 0; j < customers.length; j++) {
        let customer = customers[j];

        var paymentMethods = await loadPaymentMethodsForOfflineCustomers({ CustNo: customer.No })
        if (paymentMethods.recordTotal > 0) {
          customer.paymentMethods = paymentMethods;
        }

        var paymentTerms = await loadPaymentTermsForOfflineCustomers({ CustNo: customer.No })
        if (paymentTerms.recordTotal > 0) {
          customer.paymentTerms = paymentTerms;
        }
      }

      let customersWithoutPaymentMethodsPaymentTerms = customers.filter(function (customer) {
        return !(customer.paymentMethods && customer.paymentTerms);
      });

      if (customersWithoutPaymentMethodsPaymentTerms.length == 0 || count == 3) {
        insertDataIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA, rows)
        resolve("Customer data sync finished.")
      } else {
        count++;
        getPaymentMethodsPaymentTermsAndSave(customersWithoutPaymentMethodsPaymentTerms);
      }
    }
    getPaymentMethodsPaymentTermsAndSave(customers);
  })
};

export const loadOfflineCustomerCategories = () => dispatch => {
  return fromApiCustomers.getCustomerCategories().then(response => {
    const { customerCategories, error } = response;
    insertDataIndexDB(TABLES.COMMON, OBJECT_KEYS.CUSTOMER_CATEGORIES, response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })
};

export const loadOfflinePaymentTerms = () => dispatch => {
  return fromApiGeneric.get('PaymentTerms').then(response => {
    const { data, error } = response;
    insertDataIndexDB(TABLES.COMMON, OBJECT_KEYS.PAYMENT_TERMS, response)
    if (error) return { error };
    return response;
  }).catch(error => {
    return { error }
  });
}

export const loadOfflinePaymentMethods = () => dispatch => {
  return fromApiGeneric.get('PaymentMethod').then(response => {
    const { data, error } = response;
    insertDataIndexDB(TABLES.COMMON, OBJECT_KEYS.PAYMENT_METHODS, response)
    if (error) return { error };
    return response;
  }).catch(error => {
    return { error }
  });
}

export const loadOfflineDistributors = (filter) => dispatch => {
  return fromApiGeneric.get('Distributor', filter).then(response => {
    const { data, error, recordTotal } = response;
    insertDataIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.DISTRIBUTORS, response)
    if (error) return { error }
    return response;

  }).catch(error => {
    return { error }
  })
}


export const loadSalesHeaders = () => dispatch => {
  const code = getOfflineData("state").users.hierarchy;
  const userHierarchyObjectKeys = Object.keys(code);

  let filter = {
    "DocType": "1",
    "UserHierarchy": [
      {
        "value": userHierarchyObjectKeys[0]
      }
    ],
    "from": "2022-01-09T18:30:00.000Z",
    "to": "2022-02-10T18:29:59.000Z",
    "OrderDateFilter": "01/09/22 06:30 PM..02/10/22 06:29 PM",
    "UserTagFilterOne": userHierarchyObjectKeys[0],
    "UserTagFilterTwo": ""
  }
  let page = 0;
  return fromApiGeneric.get('SalesHeader', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;
    insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.SALES_HEADERS, response)
    if (error) return { error }
    return response;
  })
};

export const loadWarehouseLocations = () => dispatch => {
  const code = getOfflineData("state").users.hierarchy;
  const userHierarchyObjectKeys = Object.keys(code);

  let filter = {
    "DocType": "1",
    "UserHierarchy": [
      {
        "value": userHierarchyObjectKeys[0]
      }
    ],
    "from": "2022-01-09T18:30:00.000Z",
    "to": "2022-02-10T18:29:59.000Z",
    "UserTagFilterOne": userHierarchyObjectKeys[0],
    "UserTagFilterTwo": ""
  }

  return fromApiGeneric.get('UserWiseLocation', filter).then(response => {
    const { data, error } = response;
    insertDataIndexDB(TABLES.COMMON, OBJECT_KEYS.USER_WISE_LOCATON, response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })

}

export const loadSalesLines = () => async dispatch => {

  var res = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.SALES_HEADERS).then((res) => {
    return res;
  }).catch((err) => { })

  if (res.data.length > 0) {
    return new Promise((resolve, reject) => {
      var array = { "data": [], "response": [] };
      for (let i = 0; i < res.data.length; i++) {
        let filter = {
          "DocNo": res.data[i].No,
          "DocType": "1"
        }

        fromApiGeneric.get('SalesLine', filter).then(response => {
          response.data.forEach(element => {
            array.response.push(element)
            array.data.push(element)
          });

          if (i == res.data.length - 1) {
            insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.SALES_LINE, array)
            resolve("Salesline data sync with clould db");
          }

        })
      }

    })
  }
}

export const loadItems = (authData) => async dispatch => {

  var { data, error } = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.USER_WISE_LOCATON).then((res) => {
    return res;
  }).catch((err) => { alert(err); })

  let filter = {
    "LocCode": data[0].LocCode,
    "UserCode": authData.userId
  }

  return fromApiGeneric.get('Item', filter).then(response => {
    const { data, error } = response;
    insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.ITEMS, response)
    if (error) return { error }

    return response;
  }).catch(error => {
    return { error }
  })
}

export const loadOfflineDiscountReasons = (filter = {}, page = 0) => dispatch => {
  return fromApiGeneric.get('SalesDiscountReasons', filter, page).then(response => {
    const { data, error } = response;
    // saveOfflineData("callStartReasons", response)
    insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.DISCOUNT_REASONS, data)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })
};


export const loadOfflineStartCallReasons = () => dispatch => {
  return fromApiCalls.getStartCallReasons().then(response => {
    const { callStartReasons, error } = response;
    // saveOfflineData("callStartReasons", response)
    insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.CALL_START_REASONS, response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })
};

export const loadOfflineCallEndStatuses = () => dispatch => {
  return fromApiCalls.getCallEndStatuses().then(response => {
    const { callEndStatuses, error } = response;
    // saveOfflineData("callEndStatuses", response)
    insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.CALL_END_REASONS, response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })
};

export const loadOfflineCustomersDropdown = (filter) => dispatch => {
  return fromApiCustomers.getAll(filter).then(response => {
    const { customers, error } = response;
    saveOfflineData("customers", response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })
};

export const loadOfflineActiveCall = (newCall) => dispatch => {
  return fromApiCalls.add(newCall).then(response => {
    const { call, error } = response;
    saveOfflineData("call", response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  })
};


export const loadOfflineSalesInvoices = () => async (dispatch) => {
  try {
    let salesInvoices = {
      data: [],
      recordTotal: 0,
    };

    const code = getOfflineData("state").users.hierarchy;
    const userHierarchyObjectKeys = Object.keys(code);

    const filter = {
      UserHierarchy: [
        {
          value: userHierarchyObjectKeys[0],
        },
      ],
      OrderDateFilter: `${moment()
        .subtract(6, "months")
        .format("MM/DD/YY")} 00:00 AM..${moment()
          .add(1, "day")
          .format("MM/DD/YY")} 00:00 AM`,
      UserTagFilterOne: userHierarchyObjectKeys[0],
      UserTagFilterTwo: "",
    };

    let tripSchedulesById = getOfflineData("state").tripSchedules.byId;

    // Getting SalesInvoiceHeader each RouteCode from API
    let details = Object.keys(tripSchedulesById).map(async function (key) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = fromApiGeneric.get("SalesInvoiceHeader", {
            ...filter,
            RouteCode: tripSchedulesById[key].RouteCode,
          });
          resolve(res);
        } catch (error) {
          reject({});
        }
      });
    });

    details = await Promise.all(details);

    // Generate SalesInvoices array
    details.forEach((item) => {
      salesInvoices = {
        data: [...salesInvoices.data, ...item.data],
        recordTotal:
          parseInt(salesInvoices.recordTotal) + parseInt(item.recordTotal),
      };
    });

    const fetchSalesLines = async (salesInvoices) => {

      // Getting SalesLines from API
      const salesLinesPromises = salesInvoices.map(async (item) => {
        return new Promise((resolve, reject) => {
          try {
            const res = fromApiGeneric.get("SalesInvoiceLine", {
              DocNo: item.No,
            });
            resolve(res);
          } catch (error) {
            reject({});
          }
        });
      });

      let salesLines = await Promise.all(salesLinesPromises);

      // Appending salesLines into invoice headers
      salesInvoices = salesInvoices.map((invoice) => {
        salesLines.forEach((salesLine) => {
          if (
            salesLine &&
            salesLine.data &&
            salesLine.data.find((item) => item.DocNo === invoice.No)
          ) {
            invoice.salesLines = salesLine.data;
          } else if (salesLine.error) {
            invoice.salesLines = [];
          }
        });
        return invoice;
      });
    }

    await fetchSalesLines(salesInvoices.data);

    // Checking all sales lines are already fetched
    await new Promise(async (resolve, reject) => {
      try {
        for (let i = 0; i < 5; i++) {
          const unfetchedSalesLinesInvoices = salesInvoices.data.filter(invoice => invoice.salesLines.length === 0);
          if (unfetchedSalesLinesInvoices.length === 0) {
            break;
          } else {
            await fetchSalesLines(unfetchedSalesLinesInvoices);
            continue;
          }
        }
        resolve()
      } catch (error) {
        reject()
      }
    })

    // Save SalesInvoices to IndexDB
    await insertDataIndexDB(
      TABLES.SALES_INVOICES,
      OBJECT_KEYS.INVOICES,
      salesInvoices
    );
    // return salesInvoices;
  } catch (error) {
    console.error("loadOfflineSalesInvoices Errors: ", error);
    return { error };
  }
};

export const loadOfflineSalesReturnReasons = () => async (dispatch) => {
  try {
    // Fetching Sales Return Reasons from API 
    const salesReturnReasons = await fromApiGeneric.get("SalesRetrunReasons", {});

    // Save Sales Return Reasons to IndexDB
    await insertDataIndexDB(
      TABLES.COMMON,
      OBJECT_KEYS.SALES_RETURN_REASONS,
      salesReturnReasons
    );
  } catch (error) {
    console.error("loadOfflineSalesReturnReasons Errors: ", error);
    return { error };
  }
}

export const loadOfflineInventoryItems = () => async dispatch => {
  try {
    // Getting signin userId from localStorage
    const userCode = await getOfflineData("state").auth.user.userId;

    // Fetching Inventory items from API 
    const { data, error } = await fromApiGeneric.get("Item", {
      UserCode: userCode,
      AllItems: "Yes"
    });

    if (error) throw new Error(error);

    // Save inventory items to IndexDB
    await insertDataIndexDB(
      TABLES.INVENTORY,
      OBJECT_KEYS.ITEMS,
      data
    );

  } catch (error) {
    console.error("loadOfflineInventoryItems Errors: ", error);
    return { error };
  }
}

export const loadOfflineUsersHierarchy = () => async (dispatch) => {
  try {
    // Getting signin userId from localStorage
    const userCode = await getOfflineData("state").auth.user.userId;

    // Fetching Sales Hierarchy Structure from API 
    const { data, error } = await fromApiGeneric.get('SalesHierarchyStructure', { userCode })

    if (error) throw new Error(error);

    console.log('hierarchy: ', JSON.parse(data[0].jSONSalesHichyStruct))

    // Save data to IndexDB
    await insertDataIndexDB(
      TABLES.COMMON,
      OBJECT_KEYS.USER_HIERARCHY,
      JSON.parse(data[0].jSONSalesHichyStruct)
    );

  } catch (error) {
    console.error("loadOfflineUsersHierarchy Errors: ", error);
    return { error };
  }
}

//Sync
export const syncCustomer = () => async dispatch => {
  //let oldCustomerObj = getOfflineData('offlineCustomerData');
  var paymentTerms = getOfflineData('customerOfflinePaymentTerms');
  var paymentMethods = getOfflineData('customerOfflinePaymentMethods');

  var oldCustomerObj = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_DATA).then((res) => {
    return res;
  }).catch((err) => { })

  if (typeof oldCustomerObj != 'undefined') {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < oldCustomerObj.data.length; i++) {
        fromApiCustomers.add(oldCustomerObj.data[i]).then(response => {
          var { customer, error } = response;
          let cusNo = response.No;
          let customerOfflinePaymentTerms = paymentTerms.data.filter(function (el) {
            return el.CustNo === oldCustomerObj.data[i].No
          }
          );

          let customerOfflinePaymentMethods = paymentMethods.data.filter(function (el) {
            return el.CustNo === oldCustomerObj.data[i].No
          }
          );

          for (let i = 0; i < customerOfflinePaymentTerms.length; i++) {
            customerOfflinePaymentTerms[i].CustNo = cusNo;
            fromApiGeneric.add('CustomerPaymentTerms', customerOfflinePaymentTerms[i]).then(response => {
              var { data, error } = response;
            })
          }

          for (let i = 0; i < customerOfflinePaymentMethods.length; i++) {
            customerOfflinePaymentMethods[i].CustNo = cusNo;
            fromApiGeneric.add('CustomerPaymentMethod', customerOfflinePaymentMethods[i]).then(response => {
              var { data, error } = response;
            })
          }

        })
      }
      clearLocalStorage();
      resolve("Customer data sync with clould db");
    })
  }
  clearLocalStorage();
};

export const syncSalesHeader = () => async dispatch => {
  var salesHeaders = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
    return res;
  }).catch((err) => { console.log(err); })

  var salesLines = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then((res) => {
    return res;
  }).catch((err) => { console.log(err); })

  if (typeof salesHeaders != 'undefined' && typeof salesLines != 'undefined') {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < salesHeaders.data.length; i++) {
        fromApiGeneric.add('SalesHeader', salesHeaders.data[i]).then(response => {
          const { data, error } = response;
          let DocNo = response.No;
          console.log(response);
          console.log(salesHeaders.data[i]);
          let filterSalesLines = salesLines.data.filter(function (el) {
            return el.DocNo === salesHeaders.data[i].No
          });

          console.log(filterSalesLines);

          for (let i = 0; i < filterSalesLines.length; i++) {
            filterSalesLines[i].DocNo = DocNo;
            fromApiGeneric.add('SalesLine', filterSalesLines[i]).then(response => {
              console.log(response);
            })
          }

        })
      }
      resolve("Sales Header data sync with clould db");
    })
  }
}

export const loadOfflineTripSchedules = () => dispatch => {
  const code = getOfflineData("state").users.hierarchy;
  const userHierarchyObjectKeys = Object.keys(code);
  let page = 1;
  let filter = {

    "StatusFilter": "APPROVED",
    "TripStatusFilter": "Not Started",
    "StartingDateTimeFilter": moment(new Date()).startOf("day").add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(new Date()).endOf("day").add('minutes', -330).format(dateTimeFormatFilterUpdate),
    "UserTagFilterOne": userHierarchyObjectKeys[0]

  }
  return fromApiGeneric.get('TripSchedular', filter, page).then(response => {
    const { data, error, recordTotal } = response;
    insertDataIndexDB(TABLES.TRIP_SCHEDULES, OBJECT_KEYS.TRIP_SCHEDULER, response)
    if (error) return { error }
    return response;
  }).catch(error => {
    return { error }
  });
};

export const startCall = (offlineCallData) => dispatch => {
  offlineCallData.Status = "Open";
  return fromApiCalls.add(offlineCallData).then(response => {
    if (response.error === serverError) {
      offlineCallData.SyncStart = false;
      offlineCallData.Status = "Close";
    } else {
      offlineCallData.No = response.No;
      offlineCallData.SyncStart = true;
    }
    const { call, error } = response;
    return response;
  });
}

export const endCall = (offlineCallData) => dispatch => {
  offlineCallData.Status = "Close";
  return fromApiCalls.update(offlineCallData).then(response => {
    if (response.error === serverError) {
      offlineCallData.SyncEnd = false;
      offlineCallData.Status = "Open";
    } else {
      offlineCallData.No = response.No;
      offlineCallData.SyncEnd = true;
    }
    return response;
  });
}

export const createCustomer = (offlineCustomerData) => dispatch => {
  return fromApiCustomers.add(offlineCustomerData).then(response => {
    if (response.error === serverError) {
      offlineCustomerData.Sync = false;
    } else {
      offlineCustomerData.No = response.No;
      offlineCustomerData.Sync = true;
    }
    return response;
  });
}

export const createPaymentMethod = (paymentMethod) => dispatch => {
  return fromApiGeneric.add('CustomerPaymentMethod', paymentMethod).then(response => {
    if (response.error === serverError) {
      paymentMethod.Sync = false;
    } else {
      paymentMethod.No = response.No
      paymentMethod.Sync = true;
    }
    return response;
  })
}

export const createPaymentTerm = (paymentTerm) => dispatch => {
  return fromApiGeneric.add('CustomerPaymentTerms', paymentTerm).then(response => {
    if (response.error === serverError) {
      paymentTerm.Sync = false;
    } else {
      paymentTerm.No = response.No
      paymentTerm.Sync = true;
    }
    return response;
  })
}

export const createSalesHeader = (salesHeader) => dispatch => {
  return fromApiGeneric.add('SalesHeader', salesHeader).then(response => {

    if (response.error === serverError) {
      salesHeader.Sync = false;
    } else {
      /*
       Don't set response.No to offline salesheader.No because if sync fails in middle of sync process,
       it won't show the offline sales headers and data in offline mode.
      */
      salesHeader.OnlineNo = response.No;
      salesHeader.Sync = true;
    }

    return response;
  })
}

export const updateSalesHeader = (salesHeader) => dispatch => {
  return fromApiGeneric.update('SalesHeader', salesHeader).then(response => {
    return response;
  });
}

export const uploadFile = (salesHeader, imageUrl) => dispatch => {
  const blob = createBlob(imageUrl);
  const imageFile = new File([blob], "signature.png", { type: "image/png" });
  const directory = salesHeader.DocType.toLowerCase() + 's';
  const orderId = salesHeader.No.replaceAll("/", "%");
  return upload(imageFile, directory + "/" + orderId, 'signature-' + new Date().getTime()).then(response => {
    if (response.error === serverError) {
      salesHeader.isSignatureUploaded = false;
    } else {
      salesHeader.isSignatureUploaded = true;
    }
    console.log(response);
    return response;
  });
};

export const addSalesAttachment = (salesHeader, authData) => async dispatch => {
  const link = {
    AttachmentName: 'SalesAttachment',
    DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType],
    DocNo: salesHeader.No,
    AttachmentURL: salesHeader.UploadedImagePath,
    UserCode: authData.userId
  };
  return fromApiGeneric.add("SalesAttachment", link).then(response => {
    if (response.error === serverError) {
      salesHeader.isSignatureAttached = false;
    } else {
      salesHeader.isSignatureAttached = true;
    }
    return response;
  });
};

export const confirmSalesHeader = (salesHeader) => async dispatch => {
  return fromApiGeneric.update('SalesHeader', salesHeader).then(response => {
    if (response.error === serverError) {
      salesHeader.SyncConfirm = false;
    } else {
      salesHeader.SyncConfirm = true;
    }
    return response;
  });
}

function createBlob(dataURL) {
  var BASE64_MARKER = ';base64,';
  var parts;
  var contentType;
  var raw;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(',');
    contentType = parts[0].split(':')[1];
    raw = decodeURIComponent(parts[1]);

    return new Blob([raw], { type: contentType });
  }

  parts = dataURL.split(BASE64_MARKER);
  contentType = parts[0].split(':')[1];
  raw = window.atob(parts[1]);
  var rawLength = raw.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}

export const createSalesLine = (salesLine) => dispatch => {
  return fromApiGeneric.add('SalesLine', salesLine).then(response => {
    if (response.error === serverError) {
      salesLine.Sync = false
    } else {
      salesLine.Sync = true
    }
    return response;
  });
}

export const clearLocalStorage = () => dispatch => {
  removeOfflineItem("customerOfflinePaymentTerms");
  removeOfflineItem("customerOfflinePaymentMethods");
}
















