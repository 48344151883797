import { combineReducers } from 'redux';

export const ACTION_TYPES = {
    START_FETCH_YEARS: 'START_FETCH_YEARS',
    FETCH_YEARS: 'FETCH_YEARS',

    START_FETCH_MONTHS: 'START_FETCH_MONTHS',
    FETCH_MONTHS: 'FETCH_MONTHS',

    START_FETCH_ALL_ROUTE_TARGETS_BY_FILTER: 'START_FETCH_ALL_ROUTE_TARGETS_BY_FILTER',
    FETCH_ALL_ROUTE_TARGETS_BY_FILTER: 'FETCH_ALL_ROUTE_TARGETS_BY_FILTER',

    UPDATE_ROUTE_TARGET: 'UPDATE_ROUTE_TARGET',

    START_FETCH_MONTHLY_TARGET: 'START_FETCH_MONTHLY_TARGET',
    FETCH_MONTHLY_TARGET: 'FETCH_MONTHLY_TARGET',

    START_FETCH_ALL_CUSTOMER_FREQUENCIES: 'START_FETCH_ALL_CUSTOMER_FREQUENCIES',
    FETCH_ALL_CUSTOMER_FREQUENCIES: 'FETCH_ALL_CUSTOMER_FREQUENCIES',

    UPDATE_CUSTOMER_FREQUENCY: 'UPDATE_CUSTOMER_FREQUENCY',

    START_FETCH_CUSTOMER_VISIT_TARGETS: 'START_FETCH_CUSTOMER_VISIT_TARGETS',
    FETCH_CUSTOMER_VISIT_TARGETS: 'FETCH_CUSTOMER_VISIT_TARGETS',

    UPDATE_CUSTOMER_VISIT_TARGET: 'UPDATE_CUSTOMER_VISIT_TARGET',

    START_FETCH_TARGET_SCHEDULER_DETAILS: 'START_FETCH_TARGET_SCHEDULER_DETAILS',
    FETCH_TARGET_SCHEDULER_DETAILS: 'FETCH_TARGET_SCHEDULER_DETAILS',

    UPDATE_FILTER: 'UPDATE_FILTER',

    START_FETCH_BRANDS: 'START_FETCH_BRANDS',
    FETCH_BRANDS: 'FETCH_BRANDS',

    START_FETCH_ITEM_SUB_CATEGORY_ONE: 'START_FETCH_ITEM_CATEGORY_ONE',
    FETCH_ITEM_SUB_CATEGORY_ONE: 'FETCH_ITEM_CATEGORY_ONE'
}

export const months = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MONTHS:
            return action.months;
        default:
            return state;
    }
};

export const years = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_YEARS:
            return { records: [] };
        case ACTION_TYPES.FETCH_YEARS:
            return action.years;
        default:
            return state;
    }
};

export const brands = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_BRANDS:
            return { records: [] };
        case ACTION_TYPES.FETCH_BRANDS:
            return action.brands;
        default:
            return state;
    }
};

export const itemSubCateoryOne = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_ITEM_SUB_CATEGORY_ONE:
            return { records: [] };
        case ACTION_TYPES.FETCH_ITEM_SUB_CATEGORY_ONE:
            return action.itemSubCateoryOne;
        default:
            return state;
    }
}

export const monthlyTarget = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_MONTHLY_TARGET:
            return { records: [] };
        case ACTION_TYPES.FETCH_MONTHLY_TARGET:
            return action.monthlyTarget;
        default:
            return state;
    }
}

export const routeTargets = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_ALL_ROUTE_TARGETS_BY_FILTER:
            return { records: [] };
        case ACTION_TYPES.FETCH_ALL_ROUTE_TARGETS_BY_FILTER:
            return action.routeTargets;
        case ACTION_TYPES.UPDATE_ROUTE_TARGET:
            const updatedRecords = state.records.map((record) => {
                if (record.RouteCode == action.routeTarget.RouteCode) {
                    return action.routeTarget;
                }
                return record;
            });
            return { ...state, records: updatedRecords };
        default:
            return state;
    }
};

export const customerFrequencies = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_ALL_CUSTOMER_FREQUENCIES:
            return { records: [] };
        case ACTION_TYPES.FETCH_ALL_CUSTOMER_FREQUENCIES:
            return action.customerFrequencies;
        case ACTION_TYPES.UPDATE_CUSTOMER_FREQUENCY:
            const updatedRecords = state.records.map((record) => {
                if (record.CustCode == action.customerFrequency.CustCode) {
                    return action.customerFrequency;
                }
                return record;
            });
            return { ...state, records: updatedRecords };
        default:
            return state;
    }
}

export const customerVisitTargets = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_CUSTOMER_VISIT_TARGETS:
            return { records: [] };
        case ACTION_TYPES.FETCH_CUSTOMER_VISIT_TARGETS:
            return action.customerVisitTarget;
        case ACTION_TYPES.UPDATE_CUSTOMER_VISIT_TARGET:
            const updatedRecords = state.records.map((record) => {
                if (record.CustCode == action.customerVisitTarget.CustCode) {
                    return action.customerVisitTarget;
                }
                return record;
            });
            return { ...state, records: updatedRecords };
        default:
            return state;
    }
}

export const targetScheduler = (state = { records: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCH_TARGET_SCHEDULER_DETAILS:
            return action.targetScheduler;
        case ACTION_TYPES.FETCH_TARGET_SCHEDULER_DETAILS:
            return action.targetScheduler;
        default:
            return state;
    }
}

export const filter = (state = { Month: "JAN", Year: new Date().getFullYear(), UserTag: null, TerrCode: null, RouteCode: null, treeData: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_FILTER:
            return { ...state, ...action.filter };
        default:
            return state;
    }
}

export default combineReducers({
    filter,
    months,
    years,
    monthlyTarget,
    routeTargets,
    customerFrequencies,
    customerVisitTargets,
    targetScheduler,
    brands,
    itemSubCateoryOne
});