import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchSalesHeaders } from "../../../redux/actions/salesInvoiceHeaders";
import { addPaymentHeader, fetchPaymentHeaders, updatePaymentHeader, deletePaymentHeader } from "../../../redux/actions/paymentHeaders";
import { addPaymentLine, updatePaymentLine, deletePaymentLine, fetchForDoc as fetchPaymentLines } from "../../../redux/actions/paymentLines";
import { addCollection, updateCollection, fetchCollection, deleteCollection } from "../../../redux/actions/collection";
import { addSettlementHeader, addSettlementLine } from '../../../redux/actions/settlementHeaders'
import { fetchCustomer } from "../../../redux/actions/customers";
import { addAttachment, fetchAttachment } from "../../../redux/actions/attachments";
import { getAuthData, getPaymentHeader, getAttachemnt, getPaymentLinesForDoc, getLoggedInUser, getOutstandingInvoiceHeadersByCustomer, getCustomer } from "../../../redux/reducers";
import { Spin, Popconfirm, Row, Col, Button } from "antd";
import ReceiptForm from './Form';
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import ReceiptHeader from './Header';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import uuid from 'uuid/v1';
import CollectionExport from "../ReceiptExport";
import { getDemoItem as getDemoCustomer } from '../../Customers/CustomerDemoData'
import CallStatusValidateButton from '../../common/CallStatusValidateButton';
import { generateReport } from '../../../api/reports';
import Api from '../../../api/consts';
import InvoiceTable from "./InvoiceTable";
import InvoiceTableMobile from "./InvoiceTableMobile";
import { RECEIPTS } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchAllocatedDetails } from "../../../redux/actions/AllocatedDetails";
import { dateFormatUpdate, dateTimeFormatUpdate } from '../../../const/Formats';

const defaultReceipt = {
  CollectType: 'CASH',
  DocDate: moment().format(dateFormatUpdate),
  BankCode: undefined,
  BankBranchCode: undefined,
  PayNo: undefined,
  PayAmount: 0,
  CusCode: undefined,
  CusName: undefined,
  CollectDateTime: undefined,
  PDCChequeDate: moment().add(1, 'days').format(dateFormatUpdate),
  ChequeDate: moment().format(dateFormatUpdate),
  MapLocation: undefined,
  RouteCode: undefined,
  CreditCardNo: undefined,
  UserCode: undefined,
  Remarks: '',
  OrderStatus: 'Open'
}

class Container extends Component {
  state = {
    loading: false,
    isDisableSave: true,
    isDisableConfirm: true,
    receipt: {
      ...defaultReceipt
    },
    invoiceData: [],
    invoices: [],
    invoiceTotal: 0,
    bankCodeError: undefined,
    chequeNoError: undefined,
    slipNoError: undefined,
    amountError: undefined,
    paymentAmountError: undefined,
    postingDateError: undefined,
    chequeDateError: undefined
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchPaymentHeaders, fetchAttachment, fetchPaymentLines, customer } = this.props;
    const { receiptId, customerId } = match.params

    this.fetchInvoices();
    if (receiptId !== 'new') {
      const decodeId = decodeURIComponent(receiptId);
      fetchPaymentLines(decodeId);
      fetchAttachment('PaymentAttachment', { DocNo: decodeId });
      this.setState({ loading: true })
      fetchPaymentHeaders({ No: decodeId }).then(() => {
        this.setState({ loading: false })
      })
    } else {
      this.setData({ receipt: { UserCode: userId, CusCode: customerId, CusName: customer && customer.Name, PayAmount: 0 } })
    }
  }

  setData = data => {
    const { receipt } = data;

    if (!receipt) return;
    const PayAmount = receipt.PayAmount && parseFloat(receipt.PayAmount.replace(/,/g, ""))
    this.setState({
      receipt: {
        ...this.state.receipt,
        ...receipt,
        PayAmount
      }
    }, this.setDeviceMapLocation)
  }

  loadDataForTables = () => {
    const { match, authData: { userId }, } = this.props;
    const { receiptId } = match.params
    const decodeId = decodeURIComponent(receiptId);
    this.props.fetchPaymentLines(decodeId);
    this.props.fetchAttachment('PaymentAttachment', { DocNo: decodeId });
    this.props.fetchPaymentHeaders({ No: decodeId });

    this.fetchInvoices();
    this.props.fetchAllocatedDetails({ DocType: '1', DocNo: decodeId, UserCode: userId });
  }

  setDeviceMapLocation = () => {
    if (!this.state.receipt.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.receipt.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          receipt: {
            ...this.state.receipt,
            MapLocation: formatGeoLocation(location.latitude, location.longitude)
          },
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  RequestSent = false;

  fetchInvoices = () => {
    const { match, authData: { userId }, receipt } = this.props;
    const { customerId } = match.params
    let custId = customerId
    if (receipt && !custId) {
      custId = receipt.CusCode;
    }
    //console.log(custId);
    const filter = {
      DocType: '2',
      UserCode: userId,
      SellToCusNo: custId,
      OutstandAmtFilte: 'Yes'
    }

    if (custId) {
      if (this.RequestSent) return;
      this.setState({ loading: true })
      this.RequestSent = true;
      this.props.fetchSalesHeaders(filter).then(response => {
        this.setState({ invoices: response.data })
        this.setState({ loading: false })
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    const { receipt, paymentLines } = nextProps;

    if (receipt) this.setData({ receipt });
    // if (paymentLines) {
    //   this.setState({ invoiceTotal: this.calculateInvoiceTotal(paymentLines) })
    // }

    this.fetchInvoices();
  }

  hasCollectionUpdated = (thisCollection = {}, nextCollection = {}) => {
    return JSON.stringify(thisCollection) !== JSON.stringify(nextCollection);
  }

  getBankCodeFromName = name => {
    const bank = banks.find(bank => bank.shortname === name);
    return bank && bank.bankid;
  }

  onInputChange = (field, value, errorKey) => {
    if (field === 'PayAmount' && !value) {
      this.setState({ receipt: { ...this.state.receipt, [field]: 0 }, [errorKey]: undefined });
    } else {
      this.setState({ receipt: { ...this.state.receipt, [field]: value }, [errorKey]: undefined });
    }
  }

  onSelectBankChange = bankid => {
    const selectedBank = banks.find(bank => bank.bankid === bankid)
    this.setState({ receipt: { ...this.state.receipt, BankCode: selectedBank.shortname }, bankCodeError: undefined });
  }

  onSelectReceiptTypeChange = type => {
    switch (type) {
      case 'CASH':
        this.setState({
          receipt: {
            ...this.state.receipt,
            CollectType: type,
            PayNo: undefined,
            BankCode: undefined,
            PDCChequeDate: moment().add(1, 'days').format(dateFormatUpdate),
            ChequeDate: moment().format(dateFormatUpdate),
          },
          slipNoError: undefined, chequeNoError: undefined, bankCodeError: undefined, postingDateError: undefined, chequeDateError: undefined
        });
        break;
      case 'DEPOSIT':
        this.setState({
          receipt: {
            ...this.state.receipt,
            CollectType: type,
            PayNo: undefined,
            PDCChequeDate: moment().add(1, 'days').format(dateFormatUpdate),
            ChequeDate: moment().format(dateFormatUpdate),
          }, chequeNoError: undefined, postingDateError: undefined, chequeDateError: undefined
        });
        break;
      default:
        this.setState({ receipt: { ...this.state.receipt, CollectType: type } });
        break;
    }
  }

  onSelectReceiptStatusChange = orderStatus => {
    this.setState({ receipt: { ...this.state.receipt, OrderStatus: orderStatus } });
  }

  onReturnReceipt = () => {
    if (!this.validateForm()) return;

    const { match, updatePaymentHeader, toastManager } = this.props;
    const { receiptId, customerId } = match.params;
    const receiptRequest = this.getReceiptRequest();
    const { invoiceData = [], receipt } = this.state;

    if (receiptId !== 'new') {
      this.setState({ loading: true });
      updatePaymentHeader({ ...receiptRequest, OrderStatus: 4 }).then(response => {
        this.setState({ loading: false });
        if (!response.error) {
          this.handleAddPaymentLines(receipt);
          toastManager.add('Successfully returned the receipt.', { autoDismiss: true, appearance: 'success' });
        } else {
          toastManager.add('Failed to return receipt. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      })
    }
  }

  onSaveReceipt = () => {
    if (!this.validateForm()) return;

    const { match, updatePaymentHeader, toastManager, loggedInUser: { permissions = [] } } = this.props;
    const { receiptId, customerId } = match.params;
    const receiptRequest = this.getReceiptRequest();
    const { invoiceData = [], receipt } = this.state;
    const { OrderStatus } = receipt;

    if (receiptId !== 'new') {
      this.setState({ loading: true });
      //console.log("Order status in Receipt container", OrderStatus)

      updatePaymentHeader(receiptRequest).then(response => {
        this.setState({ loading: false });
        if (!response.error) {
          invoiceData.length > 0 && this.handleAddPaymentLines(receipt);
          toastManager.add('Successfully updated the receipt.', { autoDismiss: true, appearance: 'success' });
          if (OrderStatus === 'Customer Confirmed' && this.props.receipt.OrderStatus !== "Customer Confirmed") {
            this.navigateToConfirmReceipt()
          }
        } else {
          this.setState({ loading: false });
          toastManager.add('Failed to update receipt. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      })
    } else {
      this.setState({ loading: true });
      this.handleCreateReceipt(receiptRequest).then(response => {
        this.setState({ loading: false });
        if (!response.error) {
          toastManager.add('Successfully created a new receipt.', { autoDismiss: true, appearance: 'success' });
          this.props.history.replace('/customers/' + customerId + '/receipts/' + encodeURIComponent(response.data.No));
          invoiceData.length > 0 && this.handleAddPaymentLines(response.data);
        } else {
          toastManager.add('Failed to create receipt. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      })
    }
  }

  handleCreateReceipt = (receiptRequest) => {
    return this.props.addPaymentHeader(receiptRequest)
  }

  getReceiptRequest = () => {
    const { authData: { userId } } = this.props;
    const { receipt } = this.state;

    const orderStatus = receipt.OrderStatus === 'Customer Confirmed' ? this.props.receipt.OrderStatus : receipt.OrderStatus;
    let chequeDate = undefined;
    if (receipt.CollectType === "PDC") {
      chequeDate = receipt.PDCChequeDate;
    } else if (receipt.CollectType === "CHEQUE") {
      chequeDate = receipt.ChequeDate;
    }

    return {
      ...this.state.receipt,
      SessionID: uuid(),
      UserCode: userId,
      OrderStatus: orderStatus,
      ChequeDate: chequeDate
    }
  }

  validateForm = () => {
    const { receipt } = this.state;
    const { BankCode, PayAmount, PayNo, CollectType, CollectDateTime, ChequeDate, PDCChequeDate } = receipt;

    let bankCodeError = undefined;
    let chequeNoError = undefined;
    let slipNoError = undefined;
    let amountError = undefined;
    let postingDateError = undefined;
    let chequeDateError = undefined;

    if (CollectType.toUpperCase() !== 'CASH') {
      if (!this.isValidValue(BankCode)) bankCodeError = 'Bank is required.'
      if ((CollectType === 'CHEQUE' || CollectType === 'PDC') && !this.isValidValue(PayNo)) chequeNoError = 'Cheque no is required.'
      if ((CollectType === 'CHEQUE') && !ChequeDate) chequeDateError = 'Date is required.'
      if ((CollectType === 'PDC') && !PDCChequeDate) postingDateError = 'Date is required.'
      if (CollectType === 'DEPOSIT' && !this.isValidValue(PayNo)) slipNoError = 'Slip no is required.'
    }

    amountError = this.isValidAmount(PayAmount);

    this.setState({ bankCodeError, chequeNoError, slipNoError, amountError, postingDateError, chequeDateError })

    return !bankCodeError && !chequeNoError && !slipNoError && !amountError && !postingDateError && !chequeDateError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const { invoiceTotal, invoices = [] } = this.state;
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    const invalidInvoices = invoices.filter(inv => inv.paymentAmountError !== undefined);
    if (invalidInvoices.length > 0) {
      return 'Invalid invoice payment amount';
    }
    if (!validatorString.test(value)) return 'Invalid amount value';
    if (parseFloat(value) <= 0) return "Receipt amount must be greater than zero";
    if (parseFloat(value) < parseFloat(invoiceTotal)) return 'Receipt amount must be greater than or equal to allocated invoice amount.';
  }

  // Old way of saving rececipt
  // handleAddPaymentLines = receipt => {
  //   const { addPaymentLine } = this.props;
  //   const { invoiceData = [] } = this.state;

  //   let invoiceLength = invoiceData.length;
  //   invoiceData.forEach(invoice => {
  //     if (invoice.AmontToApply > 0) {
  //       const paymentLineDetail = this.createPaymentLineRequest(receipt, invoice);

  //       this.handleAction(addPaymentLine, paymentLineDetail, 'ADD').then(() => {
  //         invoiceLength--;
  //         if (invoiceLength == 0) this.setState({ invoiceData: [], invoiceTotal: 0 }, () => {
  //           this.RequestSent = false;
  //           this.resetInvoicesPaymentAmount();
  //         });
  //       });
  //     }
  //   });
  // }

  handleAddPaymentLines = (receipt) => {
    const { addSettlementHeader, addSettlementLine, toastManager } = this.props;
    let { invoiceData = [] } = this.state;

    let invoiceLength = invoiceData.length;

    // passs 1 for payment
    const settlmentHeaderRequest = this.createSettlementHeaderRequest(receipt, "1");
    this.setState({ loading: true });
    addSettlementHeader(settlmentHeaderRequest).then(response => {
      const settlementResponse = response.data;
      if (response.error) {
        toastManager.add(response.error, { autoDismiss: true, appearance: 'error' });
        this.setState({ loading: false });
      } else {
        invoiceData.forEach((invoice, i) => {
          if (parseInt(invoice.AmountToApply) !== 0) {
            const settlementLineDetail = settlementResponse && this.createSettlementLineRequest(invoice, settlementResponse, i);
            //console.log(settlementLineDetail)
            addSettlementLine(settlementLineDetail).then(response => {
              invoiceLength--;

              if (!response.error) {
                const newPaymentLine = response.data;
                invoiceData = invoiceData.filter(inv => inv.No != newPaymentLine.DocNo);
                fetchSalesHeaders({ No: newPaymentLine.DocNo });
              } else {
                this.setState({ loading: false });
              }

              if (invoiceLength === 0) {
                this.setState({ invoiceData: [], invoiceTotal: 0, loading: false }, () => {
                  this.RequestSent = false;
                  this.resetInvoicesPaymentAmount();
                  this.loadDataForTables()
                });
              }
            });
          }
        });
      }
    });
  }

  createSettlementHeaderRequest = (receipt, docType) => {
    const { authData } = this.props;
    const { invoiceTotal } = this.state;
    const { No, MapLocation } = receipt;

    return {
      SessionID: uuid(),
      No: '',
      DocDate: '',
      DocType: docType,
      DocNo: No,
      MapLocation,
      Amount: invoiceTotal,
      RemAmount: '',
      PostingDate: '',
      CusNo: '',
      CusName: '',
      UserCode: authData.userId,
      SettlementStatus: '',
      CreatedUserID: '',
      ModifiedUserID: '',
      UserTagFilterOne: '',
      UserTagFilterTwo: '',

    }
  }

  createSettlementLineRequest = (paymentLine, settlement, lineNo) => {
    const { authData } = this.props;
    const { MapLocation } = settlement;
    return {
      LineSessionID: uuid(),
      LineDocNo: settlement.No,
      LineNo: lineNo,
      DocType: 2, // this must be invoice doc type
      DocNo: paymentLine.No,
      MapLocation,
      AmountToApply: paymentLine.AmontToApply,
      RemAmount: '',
      PostingDate: '',
      UserCode: authData.userId,
      CreatedUserID: '',
      ModifiedUserID: ''
    }
  }

  resetInvoicesPaymentAmount = () => {
    const { invoices } = this.state;
    const lines = invoices;
    lines.forEach(inv => {
      inv.AmontToApply = 0
    });

    this.setState({
      invoices: lines
    })
  }

  setImageLink = fileUrl => {
    const { addAttachment, toastManager, authData: { userId } } = this.props;
    const { receipt } = this.state;

    const attachment = {
      DocNo: receipt.No,
      AttachmentURL: fileUrl,
      UserCode: userId
    }
    this.setState({ loading: true });
    addAttachment('PaymentAttachment', attachment).then(response => {
      this.setState({ loading: false });
      if (!response.error) {
        toastManager.add('Successfully upload attachment.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Failed to upload attachment', { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  navigateToConfirmReceipt = () => {
    const { receiptId } = this.props.match.params
    this.props.history.push({ pathname: '/receipts/' + receiptId + '/confirm' })
  }

  onDeleteReceipt = () => {
    const { deletePaymentHeader, toastManager, history, authData: { userId } } = this.props;

    this.setState({ loading: true });
    deletePaymentHeader({ ...this.state.receipt, UserCode: userId }).then(({ error }) => {
      this.setState({ loading: false });
      if (error) {
        toastManager.add('Failed to delete receipt. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
      } else {
        toastManager.add('Receipt successfully deleted.', { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    });
  }

  shareCollection = () => {
    const { receipt } = this.state;

    window.open('/demo_report.pdf', '_blank');
  }

  shareReceipt = () => {
    this.setState({ sharing: true });

    const { receipt, toastManager } = this.props;
    const reportRequest = {
      No: receipt.No
    };

    generateReport('receipt', reportRequest).then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  handleAmountChange = (value, invoice) => {
    const { receipt } = this.props;
    const { invoiceData = [], invoices = [] } = this.state;
    const existingInvoice = invoiceData.find(inv => inv.No === invoice.No);
    let total = 0;
    const remaningOutstanding = (parseFloat(invoice.RemAmount.replace(/,/g, "")) - parseFloat(invoice.AllocatedAmount.replace(/,/g, "")));

    const updatedArray = invoices.map(line => {
      const updateLine = line;
      if (invoice.No === line.No) {
        updateLine['AmontToApply'] = value === '' ? 0 : parseFloat(value);

        const { PDCAmount, UnconfirmedNonPDC, RemAmount } = invoice;
        const pdcAmount = parseFloat(PDCAmount.replaceAll(',', ''));
        const unconfirmedNonPdc = parseFloat(UnconfirmedNonPDC.replaceAll(',', ''));
        const remAmount = parseFloat(RemAmount.replaceAll(',', ''));
        const postingPending = pdcAmount + unconfirmedNonPdc;
        const balanceToSettle = remAmount - postingPending;

        if (balanceToSettle < parseFloat(value)) {
          updateLine['paymentAmountError'] = 'Invalid amount'
        } else {
          updateLine['paymentAmountError'] = undefined
        }
      }
      return updateLine;
    })

    for (var i = 0; i < updatedArray.length; i++) {
      //console.log("total", total, updatedArray[i].AmontToApply);
      if (updatedArray[i].AmontToApply) {
        total = total + updatedArray[i].AmontToApply
      }
    }

    //console.log("total", total);


    if (existingInvoice) {
      existingInvoice.AmontToApply = value === '' ? 0 : parseFloat(value);

      this.setState({ invoices: updatedArray })
    } else {
      invoiceData.push({
        ...invoice,
        AmontToApply: value
      });
    }

    this.setState({ invoiceData, invoiceTotal: receipt ? this.calculateInvoiceTotal(invoiceData) : total })
  }

  calculateInvoiceTotal = invoiceData => {
    let total = 0;
    invoiceData.forEach(inv => {
      total = total + parseFloat(inv.AmontToApply)
    });

    return total;
  }

  renderActions = () => {
    const { match, receipt = {}, loggedInUser: { permissions = [] } } = this.props;
    const { receiptId } = match.params
    const { OrderStatus, CollectDateTime, ChequeDate, CollectType, PayAmount, AppliedAmount } = receipt;
    const enableCreate = permissions.includes(RECEIPTS.CREATE);
    const enableUpdate = permissions.includes(RECEIPTS.UPDATE);
    const enableDelete = permissions.includes(RECEIPTS.DELETE);
    const enableSetoff = permissions.includes(RECEIPTS.SETOFF);
    const enableConfirm = permissions.includes(RECEIPTS.CONFIRM);
    const enablePrint = permissions.includes(RECEIPTS.PRINT);

    const canUpdate = (OrderStatus === "Open") ? enableUpdate : enableConfirm;

    const payAmount = receipt.PayAmount ? parseFloat(receipt.PayAmount.replace(/,/g, "")) : 0;
    const appliedAmount = receipt.AppliedAmount ? parseFloat(receipt.AppliedAmount.replace(/,/g, "")) : 0

    const balance = receipt ? payAmount - appliedAmount : 0;

    if (receiptId === "new" && enableCreate) {
      return (
        <CallStatusValidateButton type="primary" onClick={this.onSaveReceipt}>Create</CallStatusValidateButton>
      )
    } else {
      return <div>
        {enableSetoff && balance > 0 &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000 }}
              onClick={this.handleNavigateToSetOff}>Set Off</CallStatusValidateButton>
          </Col>}

        {enablePrint &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000 }} onClick={this.shareReceipt}>Print & Share</Button>
          </Col>}

        {canUpdate &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton type="primary" onClick={this.onSaveReceipt}>Update</CallStatusValidateButton>
          </Col>}

        {/* Status management change - 2021/07-05 */}
        {/* {OrderStatus === 'Open' && enableDelete &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteReceipt}>
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {enableReturn &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onReturnReceipt}>
              <CallStatusValidateButton type="danger" >Return</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        } */}

      </div>
    }
  }

  handleNavigateToSetOff = () => {
    const { match, history } = this.props;

    history.push(match.url + "/setoff")
  }

  handleDeletePaymentLine = (paymentLine, action) => {
    const { toastManager, deletePaymentLine } = this.props;

    if (!locationEnabled) {
      toastManager.add('You can not allocate invoices for receipt without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    const paymentLineDetail = this.createPaymentLineRequest(this.state.receipt, paymentLine);

    const updatedPaymentLineDetail = {
      ...paymentLineDetail,
      LineNo: paymentLine.LineNo,
      AmontToApply: 0
    }

    this.handleAction(deletePaymentLine, updatedPaymentLineDetail, 'DELETE').then(() => {
      this.RequestSent = false;
    });
  }

  createPaymentLineRequest = (receipt, paymentLine) => {
    const { authData } = this.props;
    const { No, MapLocation } = receipt;

    return {
      SessionID: uuid(),
      DocNo: No,
      InvoiceNo: paymentLine.No,
      AmontToApply: paymentLine.AmontToApply,
      MapLocation,
      UserCode: authData.userId
    }
  }

  handleAction = (action, paymentLineItem, actionType) => {
    const { toastManager, fetchSalesHeaders } = this.props;

    this.setState({ loading: true });
    return action(paymentLineItem).then(response => {
      fetchSalesHeaders({ No: paymentLineItem.InvoiceNo }).then(() => {
        const element = document.getElementById(paymentLineItem.InvoiceNo);
        if (element) element.value = 0;
        this.setState({ loading: false });
        if (response.error) {
          toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
        } else {
          if (actionType === 'DELETE') {
            toastManager.add('Successfully deleted.', { autoDismiss: true, appearance: 'success' });
          } else if (actionType === 'ADD') {
            toastManager.add('Successfully updated.', { autoDismiss: true, appearance: 'success' });
          }
        }
      })
    })
  }

  handleDataAllocated = () => {
    this.RequestSent = false;
    this.loadDataForTables();
  }

  render() {
    const { match, attachment, paymentLines, loggedInUser } = this.props;
    const { receiptId } = match.params
    const newReceiptId = receiptId ? decodeURIComponent(receiptId) : "new";
    const {
      loading,
      receipt,
      bankCodeError,
      chequeNoError,
      slipNoError,
      amountError,
      postingDateError,
      invoiceTotal } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading receipt"}>
        <Row id={'printCollectionView'}>
          <ReceiptHeader
            newReceiptId={newReceiptId}
            receipt={receipt}
            onSaveReceipt={this.onSaveReceipt}
            onConfirmReceipt={this.navigateToConfirmReceipt}
            onDeleteReceipt={this.onDeleteReceipt}
            onSelectReceiptStatusChange={this.onSelectReceiptStatusChange}
          />
          {receipt && <Row style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <InvoiceTable
                onAmountChange={this.handleAmountChange} invoices={this.state.invoices} />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <InvoiceTableMobile
                onAmountChange={this.handleAmountChange} invoices={this.state.invoices} />
            </Col>
          </Row>}
          <div>
            <ReceiptForm
              newReceiptId={newReceiptId}
              receipt={receipt}
              banks={banks}
              onInputChange={this.onInputChange}
              onSelectBankChange={this.onSelectBankChange}
              onSelectReceiptTypeChange={this.onSelectReceiptTypeChange}
              setImageLink={this.setImageLink}
              onSaveCollection={this.onSaveCollection}
              onConfirmCollection={this.navigateToConfirmCollection}
              bankCodeError={bankCodeError}
              chequeNoError={chequeNoError}
              slipNoError={slipNoError}
              amountError={amountError}
              postingDateError={postingDateError}
              invoiceTotal={invoiceTotal}
              onWorking={working => this.setState({ working })}
              attachment={attachment}
              handleOnDeletePaymentLine={this.handleDeletePaymentLine}
              paymentLines={paymentLines}
              loggedInUser={loggedInUser}
              onDataAllocated={this.handleDataAllocated}
            />
          </div>
          <div style={{ float: 'right', margin: 10 }}>
            {this.renderActions()}
          </div>
        </Row>
        <Row>
          <div id={'printContainer'} style={{ display: 'none' }}>
            <CollectionExport receipt={receipt} />
          </div>
        </Row>

      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, receiptId } = ownProps.match.params
  const decodeId = decodeURIComponent(receiptId);
  let receipt = getPaymentHeader(state, decodeId);
  if (receipt && receipt.CollectType === "PDC") {
    receipt = { ...receipt, PDCChequeDate: receipt.ChequeDate }
  }

  return {
    customer: getCustomer(state, customerId),
    receipt,
    authData: getAuthData(state),
    attachment: getAttachemnt(state, decodeId),
    paymentLines: getPaymentLinesForDoc(state, decodeId),
    loggedInUser: getLoggedInUser(state),
  };
};

export default withRouter(withToastManager(connect(mapStateToProps,
  {
    fetchCustomer, addCollection, updateCollection, fetchCollection, deleteCollection,
    fetchSalesHeaders,
    addPaymentHeader, fetchPaymentHeaders, updatePaymentHeader, deletePaymentHeader,
    addPaymentLine, updatePaymentLine, deletePaymentLine, fetchPaymentLines, addSettlementHeader, addSettlementLine,
    addAttachment, fetchAttachment, fetchAllocatedDetails
  })(Container)));

const banks = [
  { "_id": "PZe9SAt8QFY7HeyHZ", "bankid": "1", "bankname": "Hatton National Bank PLC", "shortname": "HNB" },
  { "_id": "a3EMXnpF2rCD9k9ZY", "bankid": "2", "bankname": "Bank of Ceylon", "shortname": "BOC" },
  { "_id": "WGcjpYLz4tmDupfJj", "bankid": "3", "bankname": "Sampath Bank PLC", "shortname": "Sampath" },
  { "_id": "w9pAudAozSXoMK4Zz", "bankid": "4", "bankname": "National Development Bank PLC", "shortname": "NDB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
  { "_id": "ni9rx7s3XL6Auge7f", "bankid": "6", "bankname": "The Hongkong \u0026 Shanghai Banking Corporation Ltd", "shortname": "HSBC" },
  { "_id": "S2kpZwpJphFgzeqSG", "bankid": "7", "bankname": "Pan Asia Banking Corporation PLC", "shortname": "Pan Asia Bank" },
  { "_id": "kjBoquK33CMLXCxCP", "bankid": "8", "bankname": "Seylan Bank PLC", "shortname": "Seylan" },
  { "_id": "bzJvkTY7ztAzSr9CP", "bankid": "9", "bankname": "Commercial Bank of Ceylon PLC", "shortname": "Commercial" },
  { "_id": "xWey22FL4K3rD7DF2", "bankid": "10", "bankname": "People’s Bank", "shortname": "Peoples" },
  { "_id": "5wDbsznEPvaPDsp9e", "bankid": "11", "bankname": "Deutsche Bank AG", "shortname": "Deutsche Bank" },
  { "_id": "oH9FPPuHG6wdD7rNA", "bankid": "12", "bankname": "Citibank", "shortname": "Citi Bank" },
  { "_id": "phjQCAyeGuoZXoyQf", "bankid": "13", "bankname": "Union Bank of Colombo PLC", "shortname": "Union" },
  { "_id": "FQr7DLgJHvMqsmeaF", "bankid": "14", "bankname": "DFCC Vardhana Bank PLC", "shortname": "DFCC" },
  { "_id": "af4F3jy9bS3G7TCmB", "bankid": "15", "bankname": "Cargills Bank Ltd", "shortname": "Cargills Bank" },
  { "_id": "j9jLkLhbRkHyjGiQs", "bankid": "16", "bankname": "Standard Bank", "shortname": "Standard Bank" },
  { "_id": "nXykAeaxKY5iwj3Nf", "bankid": "17", "bankname": "Amana Bank PLC", "shortname": "Amana Bank" },
  { "_id": "c4ngHthzcBK7Aarqi", "bankid": "18", "bankname": "MCB Bank Ltd", "shortname": "MCB Bank" },
  { "_id": "Pk9fBB5Rb7JRtY3kR", "bankid": "19", "bankname": "Bank of India", "shortname": "Bank of India" },
  { "_id": "7NMY9kBctARPvkgiX", "bankid": "20", "bankname": "Business Development Cooperative", "shortname": "Business Development Cooperative" },
  { "_id": "pMEFuSGNK7f3i8rpe", "bankid": "21", "bankname": "Nation Trust Bank", "shortname": "NTB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
]