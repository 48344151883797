import React, { Component } from 'react';
import { Row, Col, Button, Divider, Select, Tag } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

const { Option } = Select;
class InvoiceHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Customer Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  getDocStatusColor = docStatus => {
    switch (docStatus) {
      case 'Overdue':
        return 'red';
      case 'Outstanding':
        return 'orange';
      case 'Settled':
        return 'green'
      default:
        return '';
    }
  }

  renderActions = () => {
    const { invoiceHeader, invoiceLines, onShareOrder, match } = this.props;

    return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={() => onShareOrder()}>Print & Share</Button>
        </Col>
      </Row>
    </Row>
  }

  renderDesktopHeader = () => {
    const { invoiceHeader } = this.props;
    const { No, OrderDate, OrderNo, CreatedUserName, DistributorCode, DistributorName, LocCode, TerritoryName, OrderStatus, PayTermCode, DocStatus } = invoiceHeader;

    return (
      <div>
        <Col lg={8} md={8} sm={0} xs={0}>
          {No && <tr>
            <td style={{ width: 120 }}>Invoice No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {OrderDate && <tr>
            <td>Invoice Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(OrderDate).format(dateFormatView)}</td>
          </tr>}
          {OrderNo && <tr>
            <td style={{ width: 120 }}>PO No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{OrderNo}</td>
          </tr>}
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={0} xs={0}>
          {DistributorCode && <tr>
            <td style={{ width: 120 }}>DB Code</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{DistributorCode}</td>
          </tr>}
          {DistributorName && <tr>
            <td style={{ width: 120 }}>DB Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{DistributorName}</td>
          </tr>}
          {TerritoryName && <tr>
            <td style={{ width: 120 }}>Territory</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{TerritoryName}</td>
          </tr>}
        </Col>
        <Col lg={8} md={8} sm={0} xs={0}>
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Payment Term</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td> <p style={{ width: '100%' }}>{(!PayTermCode || (PayTermCode && PayTermCode === '')) ? undefined : PayTermCode}</p> </td>
          </tr>
          {DocStatus && <tr>
            <td style={{ width: 120 }}>Status</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td><Tag color={this.getDocStatusColor(DocStatus)}>{DocStatus}</Tag></td>
          </tr>}
        </Col>
      </div>
    )
  }

  renderMobileHeader = () => {
    const { invoiceHeader, onInputChange } = this.props;
    const { No, OrderDate, OrderNo, CreatedUserName, DistributorCode, DistributorName, TerritoryName, OrderStatus, PayTermCode, DocStatus } = invoiceHeader;

    //console.log("Locations", invoiceHeader)
    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td>Invoice #</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {OrderDate && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(OrderDate).format(dateFormatView)}</td>
          </tr>}
          {OrderNo && <tr>
            <td>Order #</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{OrderNo}</td>
          </tr>}
          {DistributorCode && <tr>
            <td>DB Code</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{DistributorCode}</td>
          </tr>}
          {DistributorName && <tr>
            <td>DB Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{DistributorName}</td>
          </tr>}
          {TerritoryName && <tr>
            <td style={{ width: 120 }}>Territory</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{TerritoryName}</td>
          </tr>}
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Payment Term</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td> <p style={{ width: '100%' }}>{(!PayTermCode || (PayTermCode && PayTermCode === '')) ? undefined : PayTermCode}</p> </td>
          </tr>
          {DocStatus && <tr>
            <td>Status</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td><Tag color={this.getDocStatusColor(DocStatus)}>{DocStatus}</Tag></td>

          </tr>}

        </Col>
      </div>
    )
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <Row >
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}>
            {this.renderMobileHeader()}
          </Col>
        </Row>
      </div>
    )
  }
}

export default InvoiceHeader;