import React, { Component } from "react";
import { Col, Row } from "antd";

class WarehouseCardHeader extends Component {

  render() {

    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, marginRight: 15, backgroundColor: '#eceaea', padding: 10 }}>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Warehouse No</Col>
          <Col xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>Name</Col>
          <Col xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>Type</Col>
          <Col xs={6} sm={6} md={4} lg={4} style={{ textAlign: 'center' }}>Distributor</Col>
          <Col xs={0} sm={0} md={8} lg={8} style={{ textAlign: 'left' }}>Address</Col>
        </Row>
        {/* <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={styles.bottomColumnStyles}>
            <span>{name}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <p style={styles.paymentType}>{type}</p>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<span>{name}</span>
					</Col>
				</Row> */}
      </Row>
    )
  }
}

export default WarehouseCardHeader;

const styles = {
  warehouseNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 5
  },
  collectionDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  paymentType: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}