import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Col, Table } from "antd";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import AdvanceSearchFilters from "../../components/common/AdvanceSearchFilters";
import CreditNoteCardHeader from "./CreditNoteCardHeader";
import CreditNoteCard from "./CreditNoteCard";

class OrderTable extends Component {

  componentDidMount = () => {
    window.addEventListener('locationchange', this.onUrlChange);
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  getFilterFieldPropertiesCreditNotes = () => {

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneThridOfColumn = { lg: 8, md: 24, sm: 24, xs: 24 };
    const oneFourthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const oneFifthOfColumn = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelvethOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };


    let data = {
      SALES_HIERARCHY: entireColumn,
      FROM_DATE_PICKER: oneSixthOfColumn,
      TO_DATE_PICKER: oneSixthOfColumn,
      SELECT_PURCHASING_CREDIT_INVOICE_DOC_TYPE: oneSixthOfColumn,
      // SELECT_PURCHASE_RETRUN_REASONS: oneSixthOfColumn,
      SELECT_PURCHASE_CREDIT_MEMO_REASONS: oneSixthOfColumn,
      //SELECT_PURCHASING_INVOICE_STATUS:oneSixthOfColumn,
      SEARCH_TEXT: { ...(oneEighthOfColumn), placeholder: 'Credit Note No' },
      SEARCH_TEXT_2: { ...(oneEighthOfColumn), placeholder: 'Return Order No' },
      SEARCH_BUTTON: oneTwelvethOfColumn,

    };
    return data;

  }

  getFilterFieldProperties = () => {
    return this.getFilterFieldPropertiesCreditNotes();
  }

  getTableHeaders = () => {
    return <CreditNoteCardHeader />;
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, match, setDefaultCustomerOption, setDefaultRouteOption } = this.props;

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          {...this.getFilterFieldProperties()}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
          filter={filter}
        />
        {this.getTableHeaders()}
      </div>
    )
  }


  getNumberOfRecordLabel = () => {
    return 'Credit Invoices';
  }

  renderFooter = () => {
    const { total, totalAmount, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={totalAmount}
        noOfRecordLabel={this.getNumberOfRecordLabel()}
        style={{ paddingRight: 33 }}
      />
    );
  }

  renderColumns = () => {
    const { docType } = this.props;
    return [{
      render: order => <CreditNoteCard order={order} />
    }];
  }

  render() {
    const { orders: filteredOrders, match } = this.props;
    const { docType } = match.params
    return (
      <Col>
        <Table
          rowKey={'No'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders) }}
          columns={this.renderColumns()}
          dataSource={filteredOrders}
          size={'small'}
          pagination={false}
          scroll={{ y: this.getScrollAreaHeight() }}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredOrders)} />
      </Col>
    )
  }
}

OrderTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(OrderTable);