
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchCustomers, fetchCustomerCategories } from "../../redux/actions/customers";
import { fetchSalesHeaders } from "../../redux/actions/salesInvoiceHeaders";
import { getSalesHeadersByType, getSalesHeadersByTypeAndCustomer, getAuthData, getLoggedInUser, DOC_TYPE_ID_BY_DEFINITION, getTopPosition, getFlatPositions, getSalesInvoiceHeadersBySearchKey, getSalesInvoiceHeaderTotal, isFetchingSalesInvoiceHeaders, getSalesInvoiceHeaderMetadata } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import OrderTable from "./OrderTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { withToastManager } from 'react-toast-notifications';
import { SALES_ORDERS } from "../../const/Permissions";
import customers from '../../redux/reducers/customers';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import { FaPlus } from 'react-icons/fa';
import { generateGeneric } from '../../api/reports';
import Api from '../../api/consts';

class Orders extends Component {
  baseUrl = window.location.pathname;

  state = {
    loading: false,
    selectedSalesInvoices: [],
    selectedInvoices: [],
    finalSalesInvoices: [],
    common2: [],
    filter: {
      DocType: undefined,
      UserCode: undefined,
      RouteCode: undefined,
      OutletCategoryCode: undefined,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1,
    isBatchPrinting: false,
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchCustomerCategories, fetchCustomers } = this.props;
    const { customerId, docType } = match.params
    const { selectedSalesInvoices } = this.state;
    fetchCustomerCategories();
    fetchCustomers({ UserCode: userId });

    this.setState({
      filter: {
        ...this.state.filter,
        DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
        UserCode: userId,
        CustNo: customerId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillUnmount = () => {
    this.setState({ selectedSalesInvoices: [] });
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
    this.setSelectedInvoices(nextProps.orders);
  }

  handleUrlSearchParams = nextProps => {
    const { customerId, distributorId, docType } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadOrders(nextProps);
      });
    }, { ...this.state.filter, CustNo: customerId, DistributorCode: distributorId, DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  loadOrders = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handleInvoiceBatchPrint = async () => {
    const { order, toastManager, match } = this.props;
    const { docType } = match.params;
    const { finalSalesInvoices } = this.state;

    if (finalSalesInvoices.length > 0) {

      var noOfInvoices = [];
      var finalInvoices = "";
      finalSalesInvoices.forEach(element => {
        noOfInvoices.push(element.No)
        finalInvoices = finalInvoices + "," + element.No;
      });

      finalInvoices = finalInvoices.substring(1);

      const reportRequest = {
        DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
        No: finalInvoices
      };

      this.setState({ isBatchPrinting: true });
      generateGeneric(reportRequest, 'batchPrintSalesInvoice').then(response => {
        if (!response.error) {
          window.open(Api.files.download.url(response.url), '_blank');
          this.setState({ isBatchPrinting: false });
        } else {
          toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
          this.setState({ isBatchPrinting: false });
        }
      })
    } else {
      toastManager.add('Please Select Invoices First.', { autoDismiss: true, appearance: 'error' });
      this.setState({ isBatchPrinting: false });
    }
  }

  onSelectedSalesInvoicesChange = async (selectedSalesInvoices) => {
    await this.setState({ selectedInvoices: selectedSalesInvoices }, () => {
      this.setFinalSalesInvoices();
    });
  }

  setFinalSalesInvoices = async () => {
    const { orders } = this.props;
    let { selectedInvoices, finalSalesInvoices } = this.state;

    selectedInvoices.forEach((invoice) => {
      if (finalSalesInvoices.indexOf(orders[invoice]) == -1) {
        finalSalesInvoices.push(orders[invoice]);
      }
    });

    var result = [];
    finalSalesInvoices.forEach(function (item) {
      if (result.indexOf(item) == -1) {
        result.push(item);
      }
    });

    finalSalesInvoices = result;

    var commonInvoices = [];
    var commonForOrdersAndFinalInvoices = [];
    var orderNosOfCurrentPage = [];

    orders.forEach(o => {
      finalSalesInvoices.forEach(finalInv => {
        selectedInvoices.forEach(selectedInv => {
          if (o.No == orders[selectedInv].No && o.No == finalInv.No) {
            if (commonInvoices.indexOf(o) == -1) {
              commonInvoices.push(o);
            }
          }
        });
      });
    });

    orders.forEach(element => {
      orderNosOfCurrentPage.push(element.No);
    });

    commonForOrdersAndFinalInvoices = finalSalesInvoices.filter(inv => !orderNosOfCurrentPage.includes(inv.No));

    var finalResult = [];

    if (commonInvoices.length != 0 && commonForOrdersAndFinalInvoices.length != 0) {
      finalResult = commonInvoices.concat(commonForOrdersAndFinalInvoices);
      finalSalesInvoices = finalResult;

    } else if (commonInvoices.length == 0 && commonForOrdersAndFinalInvoices.length != 0) {
      finalSalesInvoices = commonForOrdersAndFinalInvoices;

    } else if (commonInvoices.length != 0 && commonForOrdersAndFinalInvoices.length == 0) {
      finalSalesInvoices = commonInvoices;

    } else {
      finalSalesInvoices = [];
    }

    var finalSalesInvoicesWithoutDuplicates = [];
    finalSalesInvoicesWithoutDuplicates = finalSalesInvoices.filter((v, i, a) => a.findIndex(v2 => (v2.No === v.No)) === i)

    await this.setState({ finalSalesInvoices: finalSalesInvoicesWithoutDuplicates });
  }

  setSelectedInvoices = (filteredOrders) => {
    var result = [];
    this.state.finalSalesInvoices.forEach(function (item) {
      if (result.find(x => x.No === item.No)) {
      } else {
        result.push(item);
      }
    });

    var selectedInvoiceForPage = [];
    if (result != []) {
      filteredOrders.forEach((o, index) => {
        result.forEach(invoice => {
          if (o.No == invoice.No) {
            selectedInvoiceForPage.push(index);
          }
        });
      });
      if (selectedInvoiceForPage != []) {
        this.setState({ selectedInvoices: selectedInvoiceForPage })
      } else {
        this.setState({ selectedInvoices: [] })
      }
    } else {
      this.setState({ selectedInvoices: [] })
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  fetchOrderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchOrders(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  fetchOrders = (filter, page, searchKey) => {
    const { authData: { distributorCSR } } = this.props;
    const updatedFilter = {
      ...filter,
      OrderDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      CustNo: undefined,
      SellToCusNo: filter.CustNo,
      UserCode: undefined,
      text: undefined,
      No: filter.text ? '*' + filter.text + '*' : undefined,
      DistributorCSR: distributorCSR
    }
    this.props.fetchSalesHeaders(updatedFilter, page, searchKey);
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing orders.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
      case 'RouteCode':
        newFilter.CustNo = undefined;
        break;
      case 'OutletCategoryCode':
        newFilter.OutletSubCategoryCode = undefined;
    }

    //newFilter.StatusFilter = newFilter.CustStatus ? 'Yes' : 'No';

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No, CustNo: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }

  render() {
    const { filter, currentPage, isBatchPrinting } = this.state;
    const { loading, loggedInUser: { permissions = [] }, match, orders, total, totalAmount, metaData, topPosition } = this.props;
    const { customerId, docType } = match.params;

    const title = docType === 'Order' ? 'Orders' : 'Invoices';

    return (
      <div>
        <PromatePageHeader
          title={'Sales ' + title}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Spin spinning={(loading && orders.length === 0) || !topPosition} tip={"Loading Sales Invoices"}>
            <OrderTable
              orders={orders}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              selectedSalesInvoices={this.state.selectedSalesInvoices}
              selectedInvoices={this.state.selectedInvoices}
              finalSalesInvoices={this.state.finalSalesInvoices}
              handleInvoiceBatchPrint={this.handleInvoiceBatchPrint}
              onSelectedSalesInvoicesChange={this.onSelectedSalesInvoicesChange}
              setSelectedInvoices={this.setSelectedInvoices}
              authData={this.props.authData}
              setDefaultCustomerOption={this.setDefaultCustomerOption}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount}
              metaData={metaData}
              isBatchPrinting={isBatchPrinting}
            />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    this.props.history.push(this.props.match.url + "/new");
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 20,
    right: 180,
    height: 50
  }
};

Orders.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const searchKey = getSearchKey();

  return {
    orders: getSalesInvoiceHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getSalesInvoiceHeaderTotal(state, searchKey),
    totalAmount: getSalesInvoiceHeaderMetadata(state, searchKey).TotalAmountIncluVAT,
    metaData: getSalesInvoiceHeaderMetadata(state, searchKey),
    loading: isFetchingSalesInvoiceHeaders(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchSalesHeaders, fetchCustomers, fetchCustomerCategories })(Orders));