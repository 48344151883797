import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Row, Table } from "antd";
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { fetchRoutes } from '../../../redux/actions/routes';
import { fetchCustomers } from '../../../redux/actions/customers';
import { getSalesUserHierarchy, getRoutes, getCustomers, getAuthData, getCallTotal, getSalesValue, isFetchingSalesValue } from "../../../redux/reducers";
import CallCard from "./CallCard";
import CallCardHeader from "./CallCardHeader";
import AdvanceSearchFilters from "../../common/AdvanceSearchFilters";
import TableFooter from "../../common/TableFooter";
import { getSearchKey } from "../../../utils/Search";

class CallsTable extends Component {
  componentDidMount = () => {
    const { fetchSalesUserHierarchy, fetchRoutes, fetchCustomers, authData } = this.props;
    fetchSalesUserHierarchy(authData);
    fetchRoutes({ UserCode: authData.userId });
    // TODO: Replace with UserTag
    fetchCustomers({ UserCode: authData.userId });
  }

  renderColumns = () => {
    return [{
      render: call => <CallCard call={call} />
    }]
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 25;
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    return (
      <div className="promate-table-header">
        <CallCardHeader />
      </div>
    )
  }

  renderFooter = () => {
    const { total, currentPage, onPaginationChange, salesValue, fetchingSalesValue } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={salesValue || '...'}
        fetchingTotal={fetchingSalesValue} />
    );
  }

  render() {
    const { calls } = this.props

    return (
      <Col>
        <Table
          rowKey={'id'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(calls) }}
          columns={this.renderColumns()}
          dataSource={calls}
          size={'small'}
          pagination={false}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter()}
        />
      </Col>
    )
  }
}

const mapStateToProps = state => {
  return {
    routes: getRoutes(state),
    salesUserHierarchy: getSalesUserHierarchy(state),
    outlets: getCustomers(state),
    authData: getAuthData(state),
    total: getCallTotal(state, getSearchKey()),
    salesValue: getSalesValue(state),
    fetchingSalesValue: isFetchingSalesValue(state)
  };
};

CallsTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { fetchSalesUserHierarchy, fetchCustomers, fetchRoutes })(CallsTable);