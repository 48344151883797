import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Tag } from "antd";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from '../../../redux/reducers';
import { ROUTES } from "../../../const/Permissions";

class RouteCard extends Component {
  getStatusColor = status => {
    if (status === "Active") return 'green';
    else return 'red';
  }

  handleCardClick = () => {
    const { route, history, loggedInUser: { permissions = [] } } = this.props;
    const { RouteCode } = route;
    const { match } = this.props;
    const url = match.url

    if (permissions.includes(ROUTES.VIEW)) {
      history.push(url + "/" + RouteCode)
    }
  }

  render() {
    const { route = {} } = this.props;
    const { RouteCode, RouteName, routeDescription, warehouses = [], Status, salesPersons = [] } = route;
    const status = Status ? Status : 'Active';

    return (
      <Row onClick={this.handleCardClick}>
        <Row style={styles.alignRow}>

          <Col xs={8} sm={8} md={4} lg={4} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>{RouteCode}</span>
          </Col>

          <Col xs={8} sm={8} md={18} lg={18} style={{ textAlign: 'left' }}>
            <span >{RouteName}</span>
          </Col>

          <Col xs={0} sm={0} md={0} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>

          <Col xs={8} sm={8} md={2} lg={0} style={{ textAlign: 'right' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{warehouses.join(',')}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{salesPersons.join(',')}</span>
          </Col>

        </Row>
        <Row style={styles.lowerRow}>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{warehouses.join(',')}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{salesPersons.join(',')}</span>
          </Col>

        </Row>
        <Row style={styles.lowerRow}>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{salesPersons.join(',')}</span>
          </Col>

        </Row>
        <Row style={styles.lowerRow}>
        </Row>
      </Row >
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  location: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  remark: {
    fontSize: 12,
    marginTop: 2,
    marginBottom: 1,
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0,
    textAlign: 'right'
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 6
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },

}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) }
}

export default withRouter(connect(mapStateToProps, {})(RouteCard));