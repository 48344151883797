import { combineReducers } from 'redux';
import { FETCH_SALES_LINES, FETCH_SALES_LINE, REMOVE_SALES_LINE } from '../actions/salesLines';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_LINES:
      const newState = { ...state };
      action.salesLines.forEach(salesLine => {
        const { DocType, DocNo, LineNo } = salesLine;
        const key = DocNo + '-' + LineNo;
        ////console.log("key", key)
        newState[key] = salesLine;
      });
      return newState;
    case FETCH_SALES_LINE: {
      const newState = { ...state };
      const { DocType, DocNo, LineNo } = action.salesLine;
      const key = DocType + '-' + DocNo + '-' + LineNo;
      newState[key] = action.salesLine;
      return newState;
    }
    case REMOVE_SALES_LINE: {
      const newState = { ...state };
      const { DocType, DocNo, LineNo } = action.salesLine;
      const key = DocType + '-' + DocNo + '-' + LineNo;
      delete newState[key];
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_SALES_LINES: {
      return action.salesLines.map(salesLine => salesLine.DocNo + '-' + salesLine.LineNo);
    }
    case FETCH_SALES_LINE: {
      const saleLine = action.salesLine;

      if (state && state.length > 0 && !state.includes(saleLine.DocNo + '-' + saleLine.LineNo)) state.push(saleLine.DocNo + '-' + saleLine.LineNo)
      return [...state];
    }

    case REMOVE_SALES_LINE: {
      const newState = [...state];
      return newState.filter(id => id !== (action.salesLine.DocNo + '-' + action.salesLine.LineNo));
    }
    default:
      return state;
  }
};

export const byTypeAndDocNo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_LINES: {
      const newState = { ...state };
      action.salesLines.forEach(salesLine => {
        const key = salesLine.DocNo
        const idsByTypeAndNo = newState[key] || [];

        const { DocType, DocNo, LineNo } = salesLine;
        const id = DocNo + '-' + LineNo;
        if (!idsByTypeAndNo.includes(id)) {
          idsByTypeAndNo.push(id);
          newState[key] = idsByTypeAndNo;
        }
      });
      return newState;
    }
    case FETCH_SALES_LINE: {
      const newState = { ...state };
      const key = action.salesLine.DocType + '-' + action.salesLine.DocNo
      const idsByType = newState[key] || [];

      const { DocType, DocNo, LineNo } = action.salesLine;
      const id = DocType + '-' + DocNo + '-' + LineNo;

      if (!idsByType.includes(id)) {
        idsByType.push(id);
        newState[key] = idsByType;
      }

      return newState;
    }
    case REMOVE_SALES_LINE: {
      const newState = { ...state };
      const key = action.salesLine.DocType + '-' + action.salesLine.DocNo
      const idsByType = newState[key] || [];

      const { DocType, DocNo, LineNo } = action.salesLine;
      const removeId = DocType + '-' + DocNo + '-' + LineNo;

      newState[key] = idsByType.filter(id => id !== removeId);

      return newState;
    }
    default:
      return state;
  }
};

const salesLines = combineReducers({
  byId,
  ids,
  byTypeAndDocNo
});

export default salesLines;

export const getSalesLinesByDocTypeAndDocNo = (state, type, no) => {
  const ids = state.byTypeAndDocNo[no] || [];
  return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

export const getSalesLinesByDocTypeAndItemNo = (state, type, itemNo) => {
  const ids = state.ids || [];
  return state.ids.map(id => state.byId[id]).filter(line => line && line.No === itemNo && line.DocType === type)

}

export const getSalesLineMapByDocTypeAndDocNo = (state, type, no) => {
  const key = type + '-' + no;
  const ids = state.byTypeAndDocNo[key] || [];

  const salesLineMap = {};
  ids && ids.length > 0 && ids.forEach(id => {
    const { No, LotNo, LocCode } = state.byId[id];
    const key = No + '-' + LotNo + '-' + LocCode;

    salesLineMap[key] = state.byId[id];
  });

  return salesLineMap;
}

export const getSalesLine = (state, type, docNo, lineNo) => {
  return state.byId[type + '-' + docNo + '-' + lineNo];
}