let demoPurchasingSupplierReturns = []
export const paymentTypes = ["CASH", "CHEQUE", "FRIMI"];

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)
const paymentType = paymentTypes[getRandomNumber(0, paymentTypes.length)]

export const generateDemoSupplierReturns = () => {
  demoPurchasingSupplierReturns = []
  for (let i = 0; i < 25; i++) {
    demoPurchasingSupplierReturns.push({
      supplierReturnNo: "SR" + i,
      supplierReturnType: "Type " + i,
      sellToCustomerCode: "Supplier " + i,
      sellToCustomerName: "Supplier " + i + 1,
      billToCustomerCode: "Supplier Code " + randoNumber(),
      billToCustomerName: "Supplier Name " + i,
      locationCode: "Location Code " + randoNumber(),
      supplierReturnDate: randomDate(new Date(2012, 0, 1), new Date()),
      salesLines: getSalesLines("SR" + i),
      salespersonCode: "Sales Person " + randoNumber(),
      totalDiscountAmount: 0,
      totalAmount: randoNumber(),
      amountIncludingVat: randoNumber(),
      status: i === 2 ? "Open" : randomStatus(),
      sessionId: randoNumber(),
      paymentType: paymentType
    })
  }

  return demoPurchasingSupplierReturns;
}

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const randoNumber = () => {
  return Math.floor((Math.random() * 50000) + 1);
}

const randomStatus = () => {
  const statuses = ["Open", "Close", "Confirmed"];
  return statuses[Math.floor((Math.random() * 3) + 0)];
}

const getSalesLines = supplierReturnNo => {
  let salesLines = [];
  const itemLength = supplierReturnNo === "SR2" ? 1 : 5

  for (let i = 0; i < itemLength; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      supplierReturnNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: randoNumber(),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

export const getDemoSupplierReturns = () => {
  return demoPurchasingSupplierReturns;
}

export const getDemoSupplierReturn = id => {
  const demoSupplierReturn = demoPurchasingSupplierReturns.find(supplierReturn => supplierReturn.supplierReturnNo === id);
  return demoSupplierReturn;
}

export const getSalesLineItem = supplierReturnNo => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      supplierReturnNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: randoNumber(),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines[Math.floor((Math.random() * 3) + 0)];
}