import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { generateDemoReceipts } from '../ReceiptDemoData';
import { getPostedCreditNotesByTypeAndCustomer } from "../../../redux/reducers";
import { withRouter } from "react-router-dom";
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class CreditNoteList extends Component {

  //componentDidMount = () => generateDemoReceipts()

  getColumns = () => {
    const { history, match } = this.props;
    //onClick={() => history.push('/customers/' + match.params.customerId + '/receipts/' + id )}
    const columns = [{
      title: 'No',
      dataIndex: 'No',
      key: 'creditNoteNo',
      render: id => <p style={{ cursor: 'default' }}>{id}</p>,
    }, {
      title: 'Date',
      dataIndex: 'PostingDate',
      key: 'PostingDate',
      render: text => <p>{text && moment(text).format(dateFormatView)}</p>,
    }, {
      title: 'Reason',
      dataIndex: 'ReturnReasonDescrip',
      key: 'ReturnReasonDescrip',
      render: text => <p>{text}</p>,
    }, {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    },
    {
      title: 'Balance ',
      dataIndex: 'RemAmount',
      key: 'RemAmount',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    },
    ]

    return columns;
  }


  getRowSelection = () => {
    const { selectedCreditNote } = this.props;
    return {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = selectedRows.map(row => row.No);
        this.props.handleOnClickSelectCreditNotes(selectedRowKeys, selectedRows, record, selected)
      },
      type: 'radio',
      selectedRowKeys: selectedCreditNote ? [selectedCreditNote.No] : [],
      selectedRows: selectedCreditNote ? [selectedCreditNote] : []
    };
  }

  render() {
    let { postedCreditNotes = [], pagination } = this.props;
    pagination = postedCreditNotes.length > 5 ? pagination : false
    return (
      <Table
        rowKey={'No'}
        rowSelection={this.getRowSelection()}
        columns={this.getColumns()}
        dataSource={postedCreditNotes}
        pagination={pagination || false}
        size={'small'}
        title={() => <h3>Credit Invoices</h3>} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, match } = ownProps;
  const { docType } = match.params
  // we combine posted credit notes and return invoices gather to show credit invoice list
  const postedCreditNotes = customerId ? getPostedCreditNotesByTypeAndCustomer(state, 'CREDITMEMO', customerId) : [];
  const returnInvoices = customerId ? getPostedCreditNotesByTypeAndCustomer(state, 'RETURNORDER', customerId) : [];
  const combinedCreditInvoices = postedCreditNotes.concat(returnInvoices);
  return { postedCreditNotes: combinedCreditInvoices };
}

export default withRouter(connect(mapStateToProps, {})(CreditNoteList));