import React, { Component } from 'react';
import PromatePageHeader from '../common/PromatePageHeader';
import { ProMateGreen } from '../../const/Theme';
import { Col, Row, Button, Card, DatePicker, Select, Spin } from 'antd';
import { withToastManager } from 'react-toast-notifications';
import { connect } from "react-redux";
import moment from 'moment';
import { dateFormatView, dateTimeFormatFilterUpdate } from '../../const/Formats';
import { getAuthData } from '../../redux/reducers';
import { fetchTerritories } from '../../redux/actions/territories';
import { getSalesUserHierarchy, getLocations, getAllMonths, getAllYears } from '../../redux/reducers';
import { generateGeneric } from '../../api/reports';
import Api from '../../api/consts';
import { fetchWarehouseLocations } from '../../redux/actions/warehouses';
import { fetchSalesUserHierarchy } from '../../redux/actions/users';
import { fetchAllMonths, fetchAllYears } from '../../redux/actions/targetScheduler';


class DailyReport extends Component {

    state = {
        loading: {
            dailySummary: true,
            routeWiseDaily: true,
        },
        documentTypeofCSRDailySummary: 0,
        fromDateofCSRDailySummary: undefined,
        toDateofCSRDailySummary: undefined,
        isLoadingofCSRDailySummary: false,
        documentTypeofStockMovement: 0,
        fromDateofStockMovement: undefined,
        toDateofStockMovement: undefined,
        LocCodeofStockMovement: undefined,
        isLoadingofStockMovement: false,
        documentTypeofSalesReturnDetail: 1,
        fromDateofSalesReturnDetail: undefined,
        toDateofSalesReturnDetail: undefined,
        LocCodeofSalesReturnDetail: undefined,
        isLoadingofSalesReturnDetail: false,
        documentTypeofSalesDetailReport: 1,
        fromDateofSalesDetailReport: undefined,
        toDateofSalesDetailReport: undefined,
        LocCodeofSalesDetailReport: undefined,
        isLoadingofSalesDetailReport: false,
        CSRUser: {
            selected: undefined,
            list: []
        },
        CSRTeritory: {
            selected: undefined,
            list: []
        },
        routeWiseDailyReport: {
            loading: false,
            Month: String(moment().format("MMM")).toUpperCase(),
            Year: moment().format("YYYY"),
            UserTag: undefined,
        }
    }

    constructor(props) {
        super(props);
        this.treeData = [];
    }

    async componentDidMount() {
        const { authData, fetchWarehouseLocations, fetchAllMonths, fetchAllYears } = this.props;
        await fetchWarehouseLocations({ UserCode: authData.userId });
        await fetchAllMonths();
        await fetchAllYears();

        this.setState({
            loading: {
                ...this.state.loading,
                dailySummary: true,

            }
        });
        let results = await this.props.fetchSalesUserHierarchy(authData);
        results = results.records.map((result) => {
            return JSON.parse(result.jSONSalesHichyStruct);
        });

        for (const record of results) {
            this.generateTreeObject(record);
        }

        let territories = [];
        if (this.treeData.length > 0) {
            const data = await this.props.fetchTerritories({ 'UserCode': this.treeData[0]['code'] });
            territories = data.records;
        }

        this.setState({
            CSRUser: {
                selected: this.treeData.length > 0 ? this.treeData[0] : undefined,
                list: this.treeData
            },
            CSRTeritory: {
                selected: territories.length > 0 ? territories[0] : undefined,
                list: territories
            },
            routeWiseDailyReport: {
                ...this.state.routeWiseDailyReport,
                UserTag: this.treeData.length > 0 ? this.treeData[0].value : undefined,
            },
            loading: {
                ...this.state.loading,
                dailySummary: false,
                routeWiseDaily: false,
            }
        });
    }

    getFromDateofCSRDailySummary = (e) => {
        this.setState({ fromDateofCSRDailySummary: e._d });
    }

    getToDateofCSRDailySummary = (e) => {
        this.setState({ toDateofCSRDailySummary: e._d });
    }

    getDocumentTypeofCSRDailySummary = (e) => {
        if (e.currentTarget.value == 0) {
            this.setState({ documentTypeofCSRDailySummary: e.currentTarget.value });
        } else if (e.currentTarget.value == 1) {
            this.setState({ documentTypeofCSRDailySummary: e.currentTarget.value });
        }
    }

    generateCSRDailySummaryReport = async () => {

        const { authData: { userId, displayName, username }, toastManager } = this.props;

        if (this.state.fromDateofCSRDailySummary != undefined && this.state.toDateofCSRDailySummary != undefined) {

            if (moment(this.state.toDateofCSRDailySummary).diff(moment(this.state.fromDateofCSRDailySummary)) < 0) {
                toastManager.add('Please enter correct dates.', { autoDismiss: true, appearance: 'error' });
            } else {
                var dateFilter = moment(this.state.fromDateofCSRDailySummary).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(this.state.toDateofCSRDailySummary).add('minutes', -330).format(dateTimeFormatFilterUpdate);

                const reportRequest = {
                    CSRCode: this.state.CSRUser.selected?.code,
                    CSRName: this.state.CSRUser.selected?.name,
                    TerritoryCode: this.state.CSRTeritory.selected?.TerrCode,
                    TerrtoryName: this.state.CSRTeritory.selected?.TerrName,
                    DateFilter: dateFilter,
                    ExcelReport: this.state.documentTypeofCSRDailySummary,
                };
                this.setState({ isLoadingofCSRDailySummary: true });

                generateGeneric(reportRequest, 'cSRDailySummary').then(response => {

                    if (!response.error) {
                        setTimeout(() => {
                            window.open(Api.files.download.url(response.url), '_blank');
                            this.setState({ isLoadingofCSRDailySummary: false });
                        }, 40000);
                    } else {
                        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                        this.setState({ isLoadingofCSRDailySummary: false });
                    }
                })
            }
        } else {
            toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
        }
    }

    getFromDateofStockMovement = (e) => {
        this.setState({ fromDateofStockMovement: e._d });
    }

    getToDateofStockMovement = (e) => {
        this.setState({ toDateofStockMovement: e._d });
    }

    getDocumentTypeofStockMovement = (e) => {
        if (e.currentTarget.value == 0) {
            this.setState({ documentTypeofStockMovement: e.currentTarget.value });
        } else if (e.currentTarget.value == 1) {
            this.setState({ documentTypeofStockMovement: e.currentTarget.value });
        }
    }

    handleInputChangeofStockMovement = (value) => {
        this.setState({ LocCodeofStockMovement: value })
    }

    generateStockMovementReport = async () => {
        const { authData: { userId, displayName }, toastManager } = this.props;
        const { fromDateofStockMovement, toDateofStockMovement, LocCodeofStockMovement, documentTypeofStockMovement } = this.state;
        if (fromDateofStockMovement != undefined && toDateofStockMovement != undefined && LocCodeofStockMovement != undefined) {

            if (moment(toDateofStockMovement).diff(moment(fromDateofStockMovement)) < 0) {
                toastManager.add('Please enter correct dates.', { autoDismiss: true, appearance: 'error' });
            } else {
                var dateFilter = moment(fromDateofStockMovement).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(toDateofStockMovement).add('minutes', -330).format(dateTimeFormatFilterUpdate);

                const reportRequest = {
                    DistributorCode: userId,
                    DistributorName: displayName,
                    LocCode: LocCodeofStockMovement,
                    ExcelReport: documentTypeofStockMovement,
                    DateFilter: dateFilter,
                    UserCode: userId,
                };
                this.setState({ isLoadingofStockMovement: true });

                generateGeneric(reportRequest, 'stockMovement').then(response => {

                    if (!response.error) {
                        setTimeout(() => {
                            window.open(Api.files.download.url(response.url), '_blank');
                            this.setState({ isLoadingofStockMovement: false });
                        }, 240000);
                    } else {
                        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                        this.setState({ isLoadingofStockMovement: false });
                    }
                })
            }
        } else {
            toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
        }
    }

    getDocumentTypeofSalesDetailReport = (e) => {
        if (e.currentTarget.value == 0) {
            this.setState({ documentTypeofSalesDetailReport: e.currentTarget.value });
        } else if (e.currentTarget.value == 1) {
            this.setState({ documentTypeofSalesDetailReport: e.currentTarget.value });
        }
    }

    getFromDateofSalesDetailReport = (e) => {
        this.setState({ fromDateofSalesDetailReport: e._d });
    }

    getToDateofSalesDetailReport = (e) => {
        this.setState({ toDateofSalesDetailReport: e._d });
    }


    handleInputChangeofSalesDetailReport = (value) => {
        this.setState({ LocCodeofSalesDetailReport: value })
    }

    getFromDateofSalesReturnDetail = (e) => {
        this.setState({ fromDateofSalesReturnDetail: e._d });
    }

    getToDateofSalesReturnDetail = (e) => {
        this.setState({ toDateofSalesReturnDetail: e._d });
    }

    handleInputChangeofSalesReturnDetail = (value) => {
        this.setState({ LocCodeofSalesReturnDetail: value })
    }

    getDocumentTypeofSalesReturnDetail = (e) => {
        if (e.currentTarget.value == 0) {
            this.setState({ documentTypeofSalesReturnDetail: e.currentTarget.value });
        } else if (e.currentTarget.value == 1) {
            this.setState({ documentTypeofSalesReturnDetail: e.currentTarget.value });
        }
    }

    generateSalesReturnDetail = async () => {

        const { authData: { userId, displayName, username }, toastManager } = this.props;
        const { LocCodeofSalesReturnDetail, fromDateofSalesReturnDetail, toDateofSalesReturnDetail, documentTypeofSalesReturnDetail } = this.state;

        if (fromDateofSalesReturnDetail != undefined && toDateofSalesReturnDetail != undefined && LocCodeofSalesReturnDetail != undefined) {

            if (moment(toDateofSalesReturnDetail).diff(moment(fromDateofSalesReturnDetail)) < 0) {
                toastManager.add('Please enter correct dates.', { autoDismiss: true, appearance: 'error' });
            } else {
                var dateFilter = moment(fromDateofSalesReturnDetail).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(toDateofSalesReturnDetail).add('minutes', -330).format(dateTimeFormatFilterUpdate);
                const reportRequest = {
                    DistributorCode: userId,
                    DistributorName: displayName,
                    LocCode: LocCodeofSalesReturnDetail,
                    ExcelReport: documentTypeofSalesReturnDetail,
                    DateFilter: dateFilter,
                    UserCode: userId,
                };
                this.setState({ isLoadingofSalesReturnDetail: true });

                generateGeneric(reportRequest, 'salesReturnDetail').then(response => {

                    if (!response.error) {
                        setTimeout(() => {
                            window.open(Api.files.download.url(response.url), '_blank');
                            this.setState({ isLoadingofSalesReturnDetail: false });
                        }, 10000);
                    } else {
                        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                        this.setState({ isLoadingofSalesReturnDetail: false });
                    }
                })
            }
        } else {
            toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
        }
    }

    generateReportofSalesDetailReport = async () => {
        const { authData: { userId, displayName, username }, toastManager } = this.props;
        const { fromDateofSalesDetailReport, toDateofSalesDetailReport, documentTypeofSalesDetailReport, LocCodeofSalesDetailReport } = this.state;

        if (fromDateofSalesDetailReport != undefined && toDateofSalesDetailReport != undefined && LocCodeofSalesDetailReport != undefined) {

            if (moment(toDateofSalesDetailReport).diff(moment(fromDateofSalesDetailReport)) < 0) {
                toastManager.add('Please enter correct dates.', { autoDismiss: true, appearance: 'error' });
            } else {
                var dateFilter = moment(fromDateofSalesDetailReport).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(toDateofSalesDetailReport).add('minutes', -330).format(dateTimeFormatFilterUpdate);
                const reportRequest = {
                    DistributorCode: userId,
                    DistributorName: displayName,
                    LocCode: LocCodeofSalesDetailReport,
                    ExcelReport: documentTypeofSalesDetailReport,
                    DateFilter: dateFilter,
                    UserCode: userId,
                };

                this.setState({ isLoadingofSalesDetailReport: true });

                generateGeneric(reportRequest, 'salesDetail').then(response => {
                    if (!response.error) {
                        setTimeout(() => {
                            window.open(Api.files.download.url(response.url), '_blank');
                            this.setState({ isLoadingofSalesDetailReport: false });
                        }, 10000);
                    } else {
                        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                        this.setState({ isLoadingofSalesDetailReport: false });
                    }
                })
            }
        } else {
            toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
        }
    }

    handleChangeUSRUser = async (value) => {
        this.setState({
            loading: {
                ...this.state.loading,
                dailySummary: true
            }
        });
        const user = this.state.CSRUser.list.find(user => user.value === value);

        let territories = [];
        if (this.treeData.length > 0) {
            const data = await this.props.fetchTerritories({ 'UserCode': user.code });
            territories = data.records;
        }

        this.setState({
            CSRUser: { ...this.state.CSRUser, selected: user },
            CSRTeritory: {
                selected: territories.length > 0 ? territories[0] : undefined,
                list: territories
            },
            loading: {
                ...this.state.loading,
                dailySummary: false
            }
        });
    }

    handleChangeCSRTeritory = (value) => {
        const teritory = this.state.CSRTeritory.list.find(teritory => teritory.Code === value);
        this.setState({
            CSRTeritory: { ...this.state.CSRTeritory, selected: teritory }
        });
    }

    generateTreeObject = (input) => {
        const arr = Object.values(input)
        return arr.map(item => {
            const { user } = item;
            if (user?.role === "CSR") {
                this.treeData.push(
                    {
                        title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                        key: item.positionCode,
                        value: item.positionCode,
                        role: user?.role,
                        code: user.code,
                        name: user?.name
                    }
                );
            }
            return {
                title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                key: item.positionCode,
                value: item.positionCode,
                children: this.generateTreeObject(item.descendantStructure)
            }
        })
    }

    handleChange = (type, value) => {
        this.setState({
            routeWiseDailyReport: {
                ...this.state.routeWiseDailyReport,
                [type]: value
            }
        });
    }
    setLoading(value) {
        this.setState({
            routeWiseDailyReport: {
                ...this.state.routeWiseDailyReport,
                loading: value
            }
        });
    }

    render() {
        const { authData: { roleCode, userId }, locations } = this.props;
        const { Option } = Select;
        return (
            <div>
                <PromatePageHeader
                    title={'Reports'}
                    style={styles.pageHeader}
                    onBack={() => this.props.history.goBack()}>
                </PromatePageHeader>
                <Row>
                    <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card style={styles.card} bodyStyle={{ padding: "0", width: "100%" }}>
                            <Spin spinning={this.state.loading.dailySummary} tip={"Loading Details"}>
                                <div style={{ padding: 9, paddingInline: 9 }}>
                                    <div style={styles.cardBody}>
                                        <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>Daily Summary</b></h3>
                                        <br />
                                        <Row>
                                            <Col xs={11} sm={11}>
                                                <DatePicker
                                                    format={dateFormatView}
                                                    placeholder='From'
                                                    onChange={this.getFromDateofCSRDailySummary}
                                                />
                                            </Col>
                                            <Col xs={2} sm={2}>
                                            </Col>
                                            <Col xs={11} sm={11}>
                                                <DatePicker
                                                    format={dateFormatView}
                                                    placeholder='To'
                                                    onChange={this.getToDateofCSRDailySummary}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={24}>
                                                <Select
                                                    showSearch
                                                    placeholder={'Select CSR User'}
                                                    style={{ width: '100%' }}
                                                    onChange={value => this.handleChangeUSRUser(value)}
                                                    value={this.state.CSRUser.selected?.value}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {this.state.CSRUser.list.map((user, index) =>
                                                        <Option key={index} value={user.value}>{user.title}</Option>
                                                    )}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={24}>
                                                <Select
                                                    placeholder={'Select Terrtory'}
                                                    style={{ width: '100%' }}
                                                    onChange={value => this.handleChangeCSRTeritory(value)}
                                                    value={this.state.CSRTeritory.selected?.TerrCode}>
                                                    {this.state.CSRTeritory.list.map((teritory, index) =>
                                                        <Option key={index} value={teritory.TerrCode}>{teritory.TerrName}</Option>
                                                    )}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <input
                                                    style={{ marginBottom: 0, marginLeft: 65 }}
                                                    type='radio'
                                                    name='documentTypeofCSRDailySummary'
                                                    checked={this.state.documentTypeofCSRDailySummary == 0}
                                                    value={0}
                                                    onChange={this.getDocumentTypeofCSRDailySummary}

                                                />
                                                <label style={{ marginLeft: 7 }}>PDF</label>
                                            </Col>
                                            <Col xs={12} sm={12}>
                                                <input
                                                    style={{ marginBottom: 0, marginLeft: 25, marginRight: 15 }}
                                                    type='radio'
                                                    name='documentTypeofCSRDailySummary'
                                                    value={1}
                                                    onChange={this.getDocumentTypeofCSRDailySummary}
                                                />
                                                <label style={{ marginLeft: -9 }}>Excel</label>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Button style={{ marginBottom: 20 }} type='primary' onClick={this.generateCSRDailySummaryReport} disabled={this.state.isLoadingofCSRDailySummary}>
                                                {this.state.isLoadingofCSRDailySummary ? 'Generating...' : 'Generate Daily Summary Report'}
                                            </Button>
                                        </Row>
                                    </div>
                                </div>
                            </Spin>
                        </Card>
                    </Col>

                    {roleCode != "CSR" && <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card style={styles.card} bodyStyle={{ padding: "0" }}>
                            <div style={{ padding: 9, paddingInline: 9 }}>
                                <div style={styles.cardBody}>
                                    <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>Stock Movement Report</b></h3>
                                    <br />
                                    <Row>
                                        <Col xs={11} sm={11}>
                                            <DatePicker
                                                format={dateFormatView}
                                                placeholder='From'
                                                onChange={this.getFromDateofStockMovement}
                                            />
                                        </Col>
                                        <Col xs={2} sm={2}>
                                        </Col>
                                        <Col xs={11} sm={11}>
                                            <DatePicker
                                                format={dateFormatView}
                                                placeholder='To'
                                                onChange={this.getToDateofStockMovement}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={24} sm={24}>
                                            <Select
                                                placeholder={'Select Location'}
                                                style={{ width: '100%' }}
                                                onChange={value => this.handleInputChangeofStockMovement(value)}
                                                value={this.state.LocCodeofStockMovement}>
                                                {locations.map(location =>
                                                    <Option value={location.LocCode}>{location.LocDescription}</Option>
                                                )}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <input
                                                style={{ marginBottom: 5, marginLeft: 74 }}
                                                type='radio'
                                                name='documentTypeofStockMovement'
                                                checked={this.state.documentTypeofStockMovement == 0}
                                                value={0}
                                                onChange={this.getDocumentTypeofStockMovement}
                                            />
                                            <label style={{ marginLeft: 7 }}>PDF</label>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <input
                                                style={{ marginBottom: 5, marginLeft: 25, marginRight: 15 }}
                                                type='radio'
                                                name='documentTypeofStockMovement'
                                                disabled='true'
                                                value={1}
                                                onChange={this.getDocumentTypeofStockMovement}
                                            />
                                            <label style={{ marginLeft: -9 }}>Excel</label>
                                        </Col>
                                    </Row>
                                    {/* <br /> */}
                                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", bottom: 25, left: 0, right: 0 }}>
                                        <Button type='primary' style={{ marginTop: 7 }} onClick={this.generateStockMovementReport} disabled={this.state.isLoadingofStockMovement}>
                                            {this.state.isLoadingofStockMovement ? 'Generating...' : 'Generate Stock Movement Report'}
                                        </Button>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </Col>}

                    {roleCode != "CSR" && <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card style={styles.card} bodyStyle={{ padding: "0" }}>
                            <div style={{ padding: 9, paddingInline: 9 }}>
                                <div style={styles.cardBody}>
                                    <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>Sales Return Detail</b></h3>
                                    <br />
                                    <Row>
                                        <Col xs={11} sm={11}>
                                            <DatePicker
                                                format={dateFormatView}
                                                placeholder='From'
                                                onChange={this.getFromDateofSalesReturnDetail}
                                            />
                                        </Col>
                                        <Col xs={2} sm={2}>
                                        </Col>
                                        <Col xs={11} sm={11}>
                                            <DatePicker
                                                format={dateFormatView}
                                                placeholder='To'
                                                onChange={this.getToDateofSalesReturnDetail}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={24} sm={24}>
                                            <Select
                                                placeholder={'Select Location'}
                                                style={{ width: '100%' }}
                                                onChange={value => this.handleInputChangeofSalesReturnDetail(value)}
                                                value={this.state.LocCodeofSalesReturnDetail}>
                                                {locations.map(location =>
                                                    <Option value={location.LocCode}>{location.LocDescription}</Option>
                                                )}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <input
                                                style={{ marginBottom: 5, marginLeft: 74 }}
                                                type='radio'
                                                name='documentTypeofSalesReturnDetail'
                                                // checked={this.state.documentTypeofSalesReturnDetail == 0}
                                                disabled='true'
                                                value={0}
                                                onChange={this.getDocumentTypeofSalesReturnDetail}
                                            />
                                            <label style={{ marginLeft: 7 }}>PDF</label>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <input
                                                style={{ marginBottom: 5, marginLeft: 25, marginRight: 15 }}
                                                type='radio'
                                                name='documentTypeofSalesReturnDetail'
                                                checked={this.state.documentTypeofSalesReturnDetail == 1}
                                                value={1}
                                                onChange={this.getDocumentTypeofSalesReturnDetail}
                                            />
                                            <label style={{ marginLeft: -9 }}>Excel</label>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", bottom: 25, left: 0, right: 0 }}>
                                        <Button type='primary' style={{ marginTop: 7 }} onClick={this.generateSalesReturnDetail} disabled={this.state.isLoadingofSalesReturnDetail}>
                                            {this.state.isLoadingofSalesReturnDetail ? 'Generating...' : 'Generate Sales Return Detail'}
                                        </Button>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </Col>}

                    {roleCode != "CSR" && <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card style={styles.card} bodyStyle={{ padding: "0" }}>
                            <div style={{ padding: 9, paddingInline: 9 }}>
                                <div style={styles.cardBody}>
                                    <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>Sales Detail Report</b></h3>

                                    <br />
                                    <Row>
                                        <Col xs={11} sm={11}>
                                            <DatePicker
                                                format={dateFormatView}
                                                placeholder='From'
                                                onChange={this.getFromDateofSalesDetailReport}
                                            />
                                        </Col>
                                        <Col xs={2} sm={2}>
                                        </Col>
                                        <Col xs={11} sm={11}>
                                            <DatePicker
                                                format={dateFormatView}
                                                placeholder='To'
                                                onChange={this.getToDateofSalesDetailReport}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={24} sm={24}>
                                            <Select
                                                placeholder={'Select Location'}
                                                style={{ width: '100%' }}
                                                onChange={value => this.handleInputChangeofSalesDetailReport(value)}
                                                value={this.state.LocCodeofSalesDetailReport}>
                                                {locations.map(location =>
                                                    <Option value={location.LocCode} > {location.LocDescription}</Option>
                                                )}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <input
                                                style={{ marginBottom: 5, marginLeft: 65 }}
                                                type='radio'
                                                name='documentTypeofSalesDetailReport'
                                                // checked={this.state.documentTypeofSalesDetailReport == 0}
                                                disabled='true'
                                                value={0}
                                                onChange={this.getDocumentTypeofSalesDetailReport}

                                            />
                                            <label style={{ marginLeft: 7 }}>PDF</label>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <input
                                                style={{ marginBottom: 5, marginLeft: 25, marginRight: 15 }}
                                                type='radio'
                                                name='documentTypeofSalesDetailReport'
                                                checked={this.state.documentTypeofSalesDetailReport == 1}
                                                value={1}
                                                onChange={this.getDocumentTypeofSalesDetailReport}
                                            />
                                            <label style={{ marginLeft: -9 }}>Excel</label>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -10, position: "absolute", bottom: 25, left: 0, right: 0 }}>
                                        <Button type='primary' onClick={this.generateReportofSalesDetailReport} disabled={this.state.isLoadingofSalesDetailReport}>
                                            {this.state.isLoadingofSalesDetailReport ? 'Generating...' : 'Generate Sales Detail Report'}
                                        </Button>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </Col>}
                    <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card style={styles.card} bodyStyle={{ padding: "0" }}>
                            <div style={{ padding: 9, paddingInline: 9 }}>

                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card style={styles.card} bodyStyle={{ padding: "0" }}>
                            <div style={{ padding: 9, paddingInline: 9 }}>

                            </div>
                        </Card>
                    </Col>
                </Row >
            </div >
        )
    }
}

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    },

    card: {
        width: "100%",
        height: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },

    cardBody: {
        border: '2px solid #a0cc4b',
        padding: 14,
        height: 320,
    }

}

const mapStateToProps = (state) => {
    const authData = getAuthData(state);
    const heirarchy = getSalesUserHierarchy(state);
    const locations = getLocations(state);
    const months = getAllMonths(state);
    const years = getAllYears(state);

    return { authData, heirarchy, locations, months, years };
};

export default withToastManager((connect(mapStateToProps, { fetchTerritories, fetchWarehouseLocations, fetchSalesUserHierarchy, fetchAllMonths, fetchAllYears })(DailyReport)));

