import { combineReducers } from 'redux';
import { FETCH_ORDERS, FETCH_ORDERS_BY_CUSTOMER, FETCH_ORDER, UPDATE_QUANTITY, DELETE_SALES_LINE, DELETE_ORDER } from '../actions/orders';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ORDERS: {
			const newState = {};
			action.orders.forEach(order => newState[order.orderNo] = order);
			return newState;
		}

		case FETCH_ORDERS_BY_CUSTOMER: {
			const newState = { ...state };
			action.orders.forEach(order => newState[order.orderNo] = order);
			return newState;
		}

		case FETCH_ORDER: {
			const newState = { ...state };
			newState[action.order.orderNo] = action.order
			return newState;
		}

		case UPDATE_QUANTITY: {
			const order = state[action.salesLine.orderNo];
			if (!order) return state;
			order.salesLines.forEach(line => {
				if (line.lineNo === action.salesLine.lineNo) {
					line.quantity = action.salesLine.quantity;
				}
			});
			return { ...state };
		}

		case DELETE_SALES_LINE: {
			const order = state[action.salesLine.orderNo];
			if (!order) return state;
			order.salesLines = order.salesLines.filter(line => line.lineNo !== action.salesLine.lineNo);
			return { ...state };
		}

		case DELETE_ORDER: {
			const { [action.order.orderNo]: orderToDelete, ...rest } = state;
			return rest;
		}

		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_ORDERS: {
			const newState = []
			const orderIds = action.orders.map(order => order.orderNo)

			orderIds.forEach(id => {
				if (!newState.includes(id)) newState.push(id)
			})
			return newState;
		}

		case FETCH_ORDERS_BY_CUSTOMER: {
			const newState = [...state]
			const orderIds = action.orders.map(order => order.orderNo)

			orderIds.forEach(id => {
				if (!newState.includes(id)) newState.push(id)
			})
			return newState;
		}

		case FETCH_ORDER: {
			const newState = [...state]
			const orderId = action.order.orderNo;

			if (!newState.includes(orderId)) newState.push(orderId)
			return newState;
		}

		case DELETE_ORDER: {
			return state.filter(id => id !== action.order.orderNo);
		}

		default:
			return state;
	}
};

export const byCustomer = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ORDERS_BY_CUSTOMER:
			const newState = { ...state };
			const customerOrders = [];

			action.orders.forEach(order => {
				customerOrders.push(order.orderNo);
			});

			newState[action.customerId] = customerOrders;
			return newState;

		case DELETE_ORDER: {
			const newState = {};
			Object.keys(state).forEach(key => {
				const customerOrders = state[key] || [];
				newState[key] = customerOrders.filter(id => id !== action.order.orderNo);
			});
			return newState;
		}

		default:
			return state;
	}
};

const orders = combineReducers({
	byId,
	ids,
	byCustomer
});

export const getOrders = state => {
	const orders = state.ids.map(id => state.byId[id]).filter(order => !!order);
	return orders;
}

export const getOrdersByCustomer = (state, customerId) => {
	const orderIdsByCustomer = state.byCustomer[customerId]
	const orders = orderIdsByCustomer && orderIdsByCustomer.length > 0 ? orderIdsByCustomer.map(id => state.byId[id]).filter(order => !!order) : []

	return orders;
}

export const getOrder = (state, orderId) => state.byId[orderId]

export default orders;