import React, { Component } from 'react';
import { Row } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class SalesReturnScreen extends Component {

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 80;
    return window.innerHeight - (headerHeight);
  }

  render() {
    const { match } = this.props;
    const { id } = match.params
    const newOrder = id === 'new' ? true : false;

    return (
      <div>
        <PromatePageHeader
          title={newOrder ? 'New Sales Return' : 'Sales Return'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
            <Container />
          </Row>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default SalesReturnScreen;