import * as fromApi from '../../api/generic';

export const FETCH_STOCKTAKES_HEADERS = 'FETCH_STOCKTAKES_HEADERS';
export const START_FETCH_STOCKTAKES_HEADERS = 'START_FETCH_STOCKTAKES_HEADERS';
export const FINISH_FETCH_STOCKTAKES_HEADERS = 'FINISH_FETCH_STOCKTAKES_HEADERS';
export const FETCH_STOCKTAKES_HEADER = 'FETCH_STOCKTAKES_HEADER';
export const DELETE_STOCKTAKES_HEADER = 'DELETE_STOCKTAKES_HEADER';

let lastSearchKey;
export const fetchStockTakesHeaders = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_STOCKTAKES_HEADERS });

  return fromApi.get('StockTakeHeader', filter, page).then(response => {
    //console.log("response ", response, filter, page)
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    if (data && data.length === 1) dispatch({ type: FETCH_STOCKTAKES_HEADER, stockTakeHeader: data[0] });

    dispatch({ type: FETCH_STOCKTAKES_HEADERS, stockTakeHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: FINISH_FETCH_STOCKTAKES_HEADERS });
  });
};

export const addStockTakeHeader = stockTakeHeader => dispatch => {
  return fromApi.add('StockTakeHeader', stockTakeHeader).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_STOCKTAKES_HEADER, stockTakeHeader: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateStockTakeHeader = stockTakeHeader => dispatch => {
  return fromApi.update('StockTakeHeader', stockTakeHeader).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_STOCKTAKES_HEADER, stockTakeHeader: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteStockTakeHeader = stockTakeHeader => dispatch => {
  return fromApi.deleteObject('StockTakeHeader', stockTakeHeader).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: DELETE_STOCKTAKES_HEADER, stockTakeHeader });
    return response;
  }).catch(error => {
    return { error }
  })
};
