import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Card, Col, Select, Icon } from "antd";
import moment from "moment";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomerHeader from './Header';
import FormItem from '../../common/FormItem';
import FormSectionTitle from '../../common/FormSectionTitle';
import { getRoutes, getCustomerCategories, getCustomerSubcategories, getLoggedInUser, getPaymentMethods, getPaymentTerms, getDistributor, getFilteredRoutes } from "../../../redux/reducers";
import { GeoMap } from '../../common/GeoMap';
import ImageAttachmentContainer from "../../common/ImageAttachmentContainer";
import { dateFormatView } from "../../../const/Formats";
import Api from '../../../api/consts';
import Input from '../../common/EnglishInput';
import { showNotification } from '../../../const/Notifications';
import { CUSTOMERS } from '../../../const/Permissions';

const { Option } = Select;

class CustomerForm extends Component {
  state = {
    showDepositDetailForm: false,
    showChequePDCform: false,
    blockedReasons: [],
    isLoggedSalesRep: false
  }


  componentDidMount() {
    const { roleCode, customerId } = this.props;
    if (this.props.customer) {
      const { blockedReasons } = this.props.customer;
      this.setState({
        blockedReasons: blockedReasons
      })
    }

    if (roleCode === "SALES_REPRESENTATIVE") {
      if (customerId != "new") {
        this.setState({ isLoggedSalesRep: true })
      }
    }
  }

  removeItem(index) {
    if (!this.state.isLoggedSalesRep) {
      const list = this.state.blockedReasons;
      list.splice(index, 1);
      this.setState({ blockedReasons: list });
    }
  }

  getLocationObject = MapLocation => {
    if (MapLocation) {
      const parts = MapLocation.split(',');
      return {
        lat: parseFloat(parts[0]),
        lng: parseFloat(parts[1])
      };
    } else {
      return null;
    }
  }

  getDeviceLocationObject = () => {
    const { deviceLocation } = this.props;
    if (deviceLocation) {
      return {
        lat: deviceLocation.latitude,
        lng: deviceLocation.longitude
      };
    } else {
      return null;
    }
  }

  handleAttachmentError = error => {
    showNotification('error', 'Attachment Failed', error);

    const { onWorking } = this.props;
    onWorking && onWorking(false);
  }

  handleImageLink = link => {
    const { setImageLink, onWorking } = this.props;
    setImageLink(link);
    onWorking && onWorking(false);
  }

  getFilteredOptions = (options, selectedOptions) => {
    return selectedOptions.length > 0 ? options.filter(option1 => !selectedOptions.find(method => option1.No === method)) : options;
  }

  getSelectedOptionsDisplayValues = (options, selectedOptions) => {
    const stringArray = selectedOptions.every(option => typeof option === 'string');

    return selectedOptions.map(value => {
      const method = options.find(method => {
        if (stringArray && method.No === value) return true;
        else if (method.No === value.key) return true;
        else return false
      });

      return method && { key: method.No, label: method.Description };
    }).filter(method => !!method);
  }

  render() {
    const {
      customerId,
      customer = {},
      distributors,
      routes,
      customerCategories,
      customerSubcategories,
      onInputChange,
      onMapClick,
      nameError,
      contactNoError,
      addressError,
      nicError,
      paymentMethodError,
      paymentTermError,
      faxNoError,
      emailError,
      contactPersonError,
      categoryError,
      subcategoryError,
      distributorError,
      routeError,
      typeError,
      loggedInUser: { roleCode, permissions = [] },
      setImageLink,
      locationAccuracy,
      onWorking,
      paymentMethods = [],
      paymentTerms = [],
      selectedCustomerPaymentMethods = [],
      selectedCustomerPaymentTerms = [],
      onSelectPaymentOptionChange,
      onSelectPaymentOption,
      onDeselectPaymentOption,
      distributor,
      deviceLocation
    } = this.props;
    //console.log(deviceLocation)
    const { blockedReasons, isLoggedSalesRep } = this.state
    const {
      Name,
      Add,
      NIC,
      PhoneNo,
      EMail,
      ContactPerson,
      FaxNo,
      MapLocation,
      RouteCode,
      PaymentType,
      OutletCategoryCode,
      OutletSubCategoryCode,
      ProfileImageURL
    } = customer;
    const fileUrl = ProfileImageURL;

    const filteredPaymentMethods = this.getFilteredOptions(paymentMethods, selectedCustomerPaymentMethods);
    const filteredPaymentTerms = this.getFilteredOptions(paymentTerms, selectedCustomerPaymentTerms);

    const selectedMethodDisplayValues = this.getSelectedOptionsDisplayValues(paymentMethods, selectedCustomerPaymentMethods);
    const selectedTermsDisplayValues = this.getSelectedOptionsDisplayValues(paymentTerms, selectedCustomerPaymentTerms);

    const eligibleSubcategories = customerSubcategories.filter(category => category.OutletCategory === OutletCategoryCode);
    const showSubcategories = eligibleSubcategories.length > 0;
    return (
      <div>
        <Row gutter={10}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <CustomerHeader customerId={customerId} customer={customer} />

            <FormSectionTitle label={'Basic Data'} />

            <Col>
              {routes && routes.length > 0 &&
                <FormItem label={"Route"} error={routeError}>
                  <Select
                    showSearch
                    placeholder='Select Route'
                    value={RouteCode}
                    style={{ width: '100%' }}
                    onChange={(value) => onInputChange("RouteCode", value, "routeError")}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {routes.map(route => <Option key={route.RouteCode}>{route.RouteName}</Option>)}
                  </Select>
                </FormItem>
              }
            </Col>

            <Col>
              <FormItem label={"Distributor"}>
                <Input
                  disabled={true}
                  className="ant-input"
                  value={distributor && distributor.Name}
                  style={{ width: '100%' }} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Outlet Name"} error={nameError}>
                <Input
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  className="ant-input"
                  value={Name}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("Name", value, "nameError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Address"} error={addressError}>
                <Input
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  className="ant-input"
                  value={Add}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("Add", value, "addressError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"NIC/Passport"} error={nicError}>
                <Input
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  className="ant-input"
                  value={NIC}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("NIC", value, "nicError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <FormItem label={"Contact No"} error={contactNoError}>
                <PhoneInput
                  placeholder='Contact No'
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  inputStyle={{ width: 'inherit', borderColor: contactNoError && 'red' }}
                  buttonStyle={{ borderColor: contactNoError && 'red' }}
                  country='lk'
                  disableDropdown
                  value={PhoneNo}
                  onChange={(PhoneNo) => onInputChange("PhoneNo", PhoneNo, "contactNoError")}
                />
              </FormItem>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              {/* <FormItem label={"Fax"} error={faxNoError}>
                <PhoneInput
                  placeholder='Fax No'
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  inputStyle={{ width: 'inherit', borderColor: faxNoError && 'red' }}
                  buttonStyle={{ borderColor: faxNoError && 'red' }}
                  country='lk'
                  disableDropdown
                  value={FaxNo}
                  onChange={(PhoneNo) => onInputChange("FaxNo", PhoneNo, "faxNoError")}
                />
              </FormItem> */}
              <FormItem label={"POSM"} error={faxNoError}>
                <Select placeholder='POSM' value={FaxNo} style={{ width: '100%' }} onChange={(value) => onInputChange("FaxNo", value, "faxNoError")} disabled={isLoggedSalesRep}>
                  <Option value={"Yes"}>Yes</Option>
                  <Option value={"No"}>No</Option>
                </Select>
              </FormItem>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24} style={{ marginBottom: 10 }}>
              <FormItem label={"Email"} error={emailError}>
                <Input
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  className="ant-input"
                  value={EMail}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("EMail", value, "emailError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>
            <FormSectionTitle label={'Other'} />
            <Col>
              <FormItem label={"Contact Person"} error={contactPersonError}>
                <Input
                  disabled={isLoggedSalesRep ? "disabled" : ""}
                  className="ant-input"
                  value={ContactPerson}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("ContactPerson", value, "contactPersonError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>
            <Col lg={showSubcategories ? 12 : 24} md={showSubcategories ? 12 : 24} sm={24} xs={24}>
              <FormItem label={"Category"} error={categoryError}>
                <Select placeholder='Select Category' value={OutletCategoryCode} style={{ width: '100%' }} onChange={(value) => onInputChange("OutletCategoryCode", value, "categoryError")} disabled={isLoggedSalesRep}>
                  {customerCategories.map(category =>
                    <Option value={category.Code}>{category.Description}</Option>
                  )}
                </Select>
              </FormItem>
            </Col>
            {showSubcategories && <Col lg={12} md={12} sm={24} xs={24}>
              <FormItem label={"Subcategory"} error={subcategoryError}>
                <Select placeholder='Select Subategory' value={OutletSubCategoryCode} style={{ width: '100%' }} onChange={(value) => onInputChange("OutletSubCategoryCode", value, "subcategoryError")} disabled={isLoggedSalesRep}>
                  {eligibleSubcategories.map(category =>
                    <Option key={category.Code + category.OutletCategory + category.Description} value={category.Code}>{category.Description}</Option>
                  )}
                </Select>
              </FormItem>
            </Col>}
            <Col lg={24} md={24} sm={24} xs={24}>
              <FormItem label={"Customer Type"} error={typeError}>
                <Select placeholder='Select Customer Type' value={PaymentType} defaultValue="CASH" style={{ width: '100%' }} onChange={(value) => onInputChange("PaymentType", value, "typeError")} disabled={isLoggedSalesRep}>
                  <Option key="CASH">CASH</Option>
                  <Option key="CREDIT" disabled={!permissions.includes(CUSTOMERS.PAYMENT_METHOD_CODES.CREDIT)}>CREDIT</Option>
                </Select>
              </FormItem>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <FormItem label={"Payment Methods"} error={paymentMethodError}>
                <Select
                  mode="multiple"
                  placeholder='Select Payment Methods'
                  labelInValue={true}
                  value={selectedMethodDisplayValues}
                  onSelect={(value) => onSelectPaymentOption("selectedCustomerPaymentMethods", value)}
                  onDeselect={(value) => onDeselectPaymentOption("selectedCustomerPaymentMethods", value)}
                  style={{ width: '100%' }}>
                  {filteredPaymentMethods.map(method =>
                    <Option value={method.No}>{method.Description}</Option>
                  )}
                </Select>
              </FormItem>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <FormItem label={"Payment Terms"} error={paymentTermError}>
                <Select
                  mode="multiple"
                  placeholder='Select Payment Terms'
                  labelInValue={true}
                  value={selectedTermsDisplayValues}
                  onSelect={(value) => onSelectPaymentOption("selectedCustomerPaymentTerms", value)}
                  onDeselect={(value) => onDeselectPaymentOption("selectedCustomerPaymentTerms", value)}
                  style={{ width: '100%' }}>
                  {filteredPaymentTerms.map(method =>
                    <Option value={method.No}>{method.Description}</Option>
                  )}
                </Select>
              </FormItem>
            </Col>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24} style={{ padding: 10 }}>
            <FormSectionTitle label={'Outlet Image'} />
            <ImageAttachmentContainer
              setImageLink={this.handleImageLink}
              onAttachingStart={() => onWorking && onWorking(true)}
              onError={this.handleAttachmentError}
              fileUrl={fileUrl ? Api.files.download.url(fileUrl) : undefined}
              dirPath={"customers/" + customerId + "/attachments"}
              fileName={"customers-" + customerId + "-" + new Date().getTime()}
              customerSigned={false} />

          </Col>
        </Row>
        <Row style={{ margin: 10 }}>
          <FormSectionTitle label={'Location'} />
          <Col>
            <FormItem >
              Location: {(MapLocation ? '(' + MapLocation + (locationAccuracy ? ',' + locationAccuracy + 'm' : '') + ')' : 'Waiting...')}
              <GeoMap
                isMarkerShown={true}
                location={this.getLocationObject(MapLocation)}
                height={415}
                editable={permissions.includes(CUSTOMERS.MAP_UPDATE)}
                onMapClick={onMapClick}
                fallbackCenter={this.getDeviceLocationObject()}
              />
            </FormItem>
          </Col>
        </Row>

        {blockedReasons && blockedReasons.length > 0 && <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Blcoked Reasons</td>
                <td></td>
              </tr>
              {this.state.blockedReasons.map((reason, index) => {
                return (
                  <div key={index}>
                    <tr >
                      <td style={{ width: 25 }}>{index + 1}.</td>
                      <td><b>{reason.type} - {reason.reasonDescription}</b> </td>
                      <td textAlign='right'><i> {moment(reason.time).format(dateFormatView)}</i></td>
                      <td>

                        <Icon type="close" style={{ fontSize: '16px', color: 'red' }} onClick={() => this.removeItem(index)} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 25 }}></td>
                      <td colSpan={2}>{reason.narration}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: 25, height: 15 }}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div>
                )
              })}
            </Col>
          </Row>
        </Card>}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    routes: getFilteredRoutes(state),
    customerCategories: getCustomerCategories(state),
    customerSubcategories: getCustomerSubcategories(state),
    loggedInUser: getLoggedInUser(state),
    paymentMethods: getPaymentMethods(state),
    paymentTerms: getPaymentTerms(state),
    distributor: getDistributor(state, ownProps.customer.DistributorCode)
  }
}

export default connect(mapStateToProps, null)(CustomerForm);