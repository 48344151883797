import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Col, Button, Row, Form, Table, Input } from 'antd';
import { format } from "currency-formatter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const columns = [
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    align: 'center',
  },
  {
    title: 'Discount Percentage(%)',
    dataIndex: 'discountPercentage',
    key: 'discountPercentage',
    align: 'center',
  },
  {
    title: 'Discount Amount',
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    align: 'right',
  },
];


class Details extends Component {

  state = {
    quantityError: "",
    selectedRowKeys: [],

  }


  getInvoiceColumns = () => {

    const columns = [
      {
        title: 'Invoice No',
        dataIndex: "DocNo",
        key: "DocNo",
        width: this.context.screenType === 'DESKTOP' ? 180 : 120,
      },
      {
        title: 'Invoice Date',
        dataIndex: "PostingDateTime",
        key: "PostingDateTime",
        width: this.context.screenType === 'DESKTOP' ? 200 : 150,

      }, {
        title: 'Item Code',
        dataIndex: 'No',
        key: 'No',
        width: this.context.screenType === 'DESKTOP' ? 180 : 100,
        className: this.context.screenType === 'MOBILE' && 'item-code-column',
      }, {
        title: 'Item Des.',
        dataIndex: 'Description',
        key: 'Description',
        width: this.context.screenType === 'DESKTOP' ? 250 : 180,
      },
      {
        title: 'Lot No',
        dataIndex: 'LotNo',
        key: 'LotNo',
        width: this.context.screenType === 'DESKTOP' ? 200 : 150,

      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitPrice',
        key: 'UnitPrice',
        width: this.context.screenType === 'DESKTOP' ? 150 : 100,
        align: 'right',
        className: this.context.screenType === 'MOBILE' && 'unit-price-column',
        render: unitPrice => format(unitPrice, currencyFormat)
      }, {
        title: 'Discount',
        dataIndex: 'DisAmount',
        key: 'DisAmount',
        width: this.context.screenType === 'DESKTOP' ? 150 : 100,
        align: 'right',
        className: this.context.screenType === 'MOBILE' && 'discount-column',
        render: (discountAmount, rowData) => `${format(discountAmount, currencyFormat)} (${rowData.DisPercentage}%)`
      },
      {
        title: 'Qty',
        dataIndex: 'Quantity',
        key: 'Quantity',
        align: 'right',
        fixed: 'right',
        className: this.context.screenType === 'MOBILE' && 'qty-column',
        width: this.context.screenType === 'MOBILE' ? 50 : undefined,
        render: (_, rowData) => {
          const { Quantity = '0', QuantityReturned = '0' } = rowData;
          const quantity = parseFloat(Quantity.replaceAll(',', ''));
          const quantityReturned = parseFloat(QuantityReturned.replaceAll(',', ''));

          return <div>{(quantity - quantityReturned).toFixed(2)}</div>;
        }
      },
      {
        title: 'Total',
        dataIndex: 'AmountIncluVAT',
        key: 'AmountIncluVAT',
        fixed: 'right',
        align: 'right',
        className: this.context.screenType === 'MOBILE' && 'total-column',
        width: this.context.screenType === 'MOBILE' ? 60 : undefined,
        render: total => <span style={{ padding: 0, marginRight: 3 }}>{format(total, currencyFormat)}</span>
      },
      {
        title: this.context.screenType === 'MOBILE' ? 'Re.Qty' : 'Return Qty',
        dataIndex: 'ReturnQty',
        key: 'ReturnQty',
        fixed: 'right',
        align: 'right',
        className: this.context.screenType === 'MOBILE' && 'return-qty-column',
        render: (value, record) => this.renderReturnQty(value, record, 'RetrunQty', 'retrunQtyError'),
      }
    ];
    return columns;
  }


  renderReturnQty = (value, record, field, error) => {
    return (
      <div >
        <Input
          disabled={!record.Selected}
          value={value}
          style={{ width: this.context.screenType === 'MOBILE' ? 60 : 80 }}
          type='number'
          onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()}
          onChange={(e) => this.props.handleTableInputTextChange('ReturnQty', e.target.value, record, error)} />
        {record.ReturnQtyError && <p style={{ color: 'red', textAlign: 'center', fontSize: this.context.screenType === 'MOBILE' ? 9 : 14 }}>{record.ReturnQtyError}</p>}
      </div>

    )
  }

  validateQuantity = (value) => {
    if (value > 30) {
      this.setState({ quantityError: "error" })
    } else {
      this.setState({ quantityError: "" })
      this.props.onUpdateOrderQty(value)
    }
  }

  renderItemDiscountTable = () => {

    const { inventoryItem, order, resource, salesLineItem } = this.props;
    const discountAmount = format((inventoryItem.unitPrice * order.discountPercentage) / 100, currencyFormat);
    const totalAmount = format((inventoryItem.unitPrice - discountAmount) * salesLineItem.quantity, currencyFormat)
    //console.log(inventoryItem, order, resource, salesLineItem.quantity)
    const obj = [{
      unitPrice: format(inventoryItem.unitPrice, currencyFormat), discountPercentage: order.discountPercentage, discountAmount: discountAmount, quantity: salesLineItem.quantity,
      totalAmount: totalAmount
    }]

    return (
      <Table dataSource={obj} columns={columns} />
    )
  }

  renderItemInvoiceTable = () => {
    const { invoiceLines } = this.props;

    const { selectedRowKeys } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.props.onSelectRowChange,
      fixed: true,
      columnWidth: this.context.screenType === 'MOBILE' && 40,
    };

    if (this.context.screenType === 'MOBILE') {
      return (
        <Table
          rowSelection={rowSelection}
          dataSource={invoiceLines}
          rowClassName={() => 'editable-row'}
          columns={this.getInvoiceColumns()}
          scroll={{ y: this.getScrollAreaHeight(), x: 1170 }}
          rowKey={record => record.LineSessionID + "_" + record.LotNo + '_' + record.DocNo}
        />
      )
    } else {
      return (
        <Table
          rowSelection={rowSelection}
          dataSource={invoiceLines}
          columns={this.getInvoiceColumns()}
          scroll={{ y: this.getScrollAreaHeight(), x: 1170 }}
          rowKey={record => record.LineSessionID + "_" + record.LotNo + '_' + record.DocNo}
        />
      )
    }

  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 22;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return this.props.screenHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }


  onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  onUpdateOrderQty = (value) => {
    const { inventoryItem } = this.props
    //console.log("retrun", value, inventoryItem.Quantity);
    if (value > inventoryItem.Quantity) {
      this.setState({ quantityError: "error" })
    } else {
      this.setState({ quantityError: "" })
      this.setState({ returnQty: value })
    }
  }

  render() {
    const { inventoryItem, salesLineItem, onUpdateOrder, returnTotalQtyError, newOrderItem, onRemoveSalesLine, order, docType, onUpdateOrderQty } = this.props;

    return (
      // <Card style={{ margin: 5 }} extra={[
      //   <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {newOrderItem ? <div></div> : <Col><Button type="danger" onClick={onRemoveSalesLine}>{'Remove item'}</Button></Col>}
      //     <Col>
      //       <Button 
      //         type="primary"
      //         onClick={onUpdateOrder}>{newOrderItem ? 'Add to order' : 'Update item'}
      //       </Button>
      //     </Col>
      //   </Row>
      // ]}>
      <div style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
        <Row gutter={10} style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {inventoryItem && inventoryItem.LotNo && <tr>
              <td ></td>
              <td>CODE</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.LotNo}</td>
            </tr>}
            {inventoryItem && inventoryItem.Description && <tr>
              <td ></td>
              <td>Description</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.Description}</td>
            </tr>}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {inventoryItem && inventoryItem.Brand && <tr>
              <td ></td>
              <td>Brand</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.Brand}</td>
            </tr>}
            {/* {inventoryItem.LotNo && <tr>
              <td ></td>
              <td>Lot No</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.LotNo}</td>
            </tr>} */}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>

            {(docType !== "ReturnOrder") && inventoryItem && <tr>
              <td ></td>
              <td>Item available count</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.totalAvb}</td>
            </tr>
            }
            {(docType !== "ReturnOrder") && inventoryItem && <tr>
              <td ></td>
              <td><p>Allocated item count</p></td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.allocated}</td>
            </tr>
            }
            {(docType !== "ReturnOrder") && inventoryItem && <tr>
              <td ></td>
              <td>Quantity in hand</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{inventoryItem.totalAvb - inventoryItem.allocated}</td>
            </tr>
            }
            {inventoryItem && inventoryItem.Quantity ? (
              <tr>
                <td ></td>
                <td>Total Quantity</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{inventoryItem.Quantity}</td>
              </tr>
            ) :
              null
            }
            <tr>
              <td ></td>
              <td>{docType !== "ReturnOrder" ? 'Order quantity' : 'Return Qty'}</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <Form.Item
                  validateStatus={returnTotalQtyError ? 'error' : undefined}
                  help={returnTotalQtyError}
                >
                  <input
                    className="ant-input"
                    type="number"
                    min="0"
                    value={this.props.returnTotalQty}
                    onChange={(event) => onUpdateOrderQty(event.target.value)}
                    onFocus={(event) => event.target.select()}
                    onKeyDown={(e) => { if (e.key === 'Enter') onUpdateOrder() }} />

                </Form.Item>
              </td>
            </tr>

          </Col>
        </Row>

        {(docType === "Order" || docType === "Invoice") && this.renderItemDiscountTable()}
        {(docType === "ReturnOrder") && this.renderItemInvoiceTable()}


        <Row gutter={15} style={{ float: 'right', padding: 10 }}>
          {newOrderItem ? <div></div> : <Col><CallStatusValidateButton type="danger" onClick={onRemoveSalesLine}>{'Remove item'}</CallStatusValidateButton></Col>}
          <Col>
            <CallStatusValidateButton
              type="primary"
              onClick={onUpdateOrder}>{newOrderItem ? 'Add' : 'Update item'}
            </CallStatusValidateButton>
          </Col>
        </Row>
      </div>
    )
  }
}

Details.contextTypes = {
  screenType: PropTypes.object.isRequired,
}


export default Details;