import React, { useState } from "react";
import NodeGeocoder from 'node-geocoder';
import { compose, withProps } from "recompose";
import { Switch } from 'antd';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const geocoder = NodeGeocoder({
  provider: 'google',
  fetch: window.fetch.bind(window),
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  formatter: null
});

const renderMarker = location => {
  if (location.label) return renderMarkerWithLabel(location);
  else return renderMarkerWithoutLabel(location);
}

const renderMarkerWithoutLabel = location => <Marker position={location} icon={location.icon} />

const renderMarkerWithLabel = location => {
  const { title, subtitle, adjustedForSameLocation, icon, subtitle2, subtitle3, subtitle4, subtitle5 } = location.label;
  const hasTitle = !!title;
  const hasSubtitle = !!subtitle;
  const hasSubtitle2 = !!subtitle2;
  const hasSubtitle3 = !!subtitle3;
  const hasSubtitle4 = !!subtitle4;
  const hasSubtitle5 = !!subtitle5;

  return (
    <MarkerWithLabel
      position={location}
      labelAnchor={location}
      icon={icon}>
      <div
        style={styles.markerLabel.container}>
        {hasTitle && (
          <div style={styles.markerLabel.title}>
            {adjustedForSameLocation ? '<> ' : ''}
            {title}
          </div>
        )}
        {hasSubtitle && <div>{subtitle}</div>}
        {hasSubtitle2 && <div>{subtitle2}</div>}
        {hasSubtitle3 && <div>{subtitle3}</div>}
        {hasSubtitle4 && <div>{subtitle4}</div>}
        {hasSubtitle5 && <div>{hasSubtitle5}</div>}
      </div>
    </MarkerWithLabel>
  );
}

const MIN_CHANGE = Math.pow(10, -5);
const separateSameLocations = locations => {
  const locationCounts = {};
  locations.forEach(location => {
    const id = location.lat + ',' + location.lng;
    if (!locationCounts[id]) {
      locationCounts[id] = 1;
    } else {
      const count = locationCounts[id];
      let adjustment;
      if (count % 2 === 0) {
        adjustment = count / 2;
      } else {
        adjustment = -Math.ceil(count / 2);
      }

      location.lat += adjustment * MIN_CHANGE;
      location.lng += adjustment * MIN_CHANGE;

      location.label.adjustedForSameLocation = true;

      locationCounts[id] = count + 1;
    }
  });
}

const getCenter = (locations = [], clusters = []) => {
  if (locations.length > 0) return locations[0];
  else if (clusters.length > 0) {
    for (let i = 0; i < clusters.length; i++) {
      const cluster = clusters[i];
      if (cluster.locations.length > 0) {
        return cluster.locations[0];
      }
    }
  }
}

export const GeoMap = compose(
  withProps(props => ({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_MAPS_API_KEY + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: props.height }} />,
    mapElement: <div style={{ height: `100%` }} />
  })),
  withScriptjs,
  withGoogleMap
)(props => {
  const { location, locations = [], clusters = [], isMarkerShown, onMapClick, editable = true, center, fallbackCenter } = props;
  const [editing, setEditing] = useState(false);

  const onToggleMapEditMode = () => {
    setEditing(!editing);
  };


  // Only pass out the locations in Sri Lanka
  const onClick = e => {
    geocoder.reverse({ lat: e.latLng.lat(), lon: e.latLng.lng() }).then(response => {
      const summary = response[0];
      const inLk = summary.countryCode === 'LK';
      const validLocation = Object.keys(summary.administrativeLevels).length > 0;

      if (inLk && validLocation && editing) onMapClick(e);
    }).catch(error => {
      // Ignore.
      // Most probably the selection is in the sea.
    });
  }

  const markerLocations = [];

  if (isMarkerShown) {
    if (isValidLocation(location)) markerLocations.push(location);
    locations.forEach(location => {
      if (isValidLocation(location)) markerLocations.push(location)
    });
  }

  separateSameLocations(markerLocations);

  const finalCenter = center || getCenter(markerLocations, clusters) || fallbackCenter || { lat: 6.887328, lng: 79.967235 };

  const getClusterStyles = icon => {
    const style = { url: icon, height: 24, width: 24 };
    return [style, style, style]; // As high, medium, low density styles
  }

  clusters && clusters.forEach(cluster => {
    separateSameLocations(cluster.locations);
  });

  return <GoogleMap defaultZoom={11} defaultCenter={finalCenter} center={finalCenter} onClick={onClick} >
    {editable && <div style={{ position: 'absolute', bottom: -3, left: 0 }}>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingLeft: 5, paddingRight: 5, fontWeight: 500 }}>Enable Edit</div>
        <Switch checked={editing} onChange={onToggleMapEditMode} />
      </div>
    </div>}

    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={30}>
      {markerLocations.map(location => renderMarker(location))}
    </MarkerClusterer>

    {isMarkerShown && clusters.map(cluster => {
      const { locations, icon } = cluster;
      return (
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={30}
          styles={getClusterStyles(icon)}>
          {locations.map(location => renderMarker(location))}
        </MarkerClusterer>
      );
    })}
  </GoogleMap>
});

const styles = {
  markerLabel: {
    container: {
      background: '#ffffff99',
      borderRadius: 5,
      color: 'black',
      padding: 5,
      width: 200
    },
    title: {
      fontWeight: 'bold'
    }
  }
}

const isValidLocation = location => location && !isNaN(location.lat) && !isNaN(location.lng);