import React, { Component } from 'react';
import { PageHeader } from "antd";
import Container from './Container';
import styles from '../styles'
import PromatePageHeader from '../../common/PromatePageHeader';

class CollectionScreen extends Component {

  render() {
    const { match } = this.props;
    const { receiptId } = match.params
    const newReceipt = receiptId === 'new';

    return (
      <div>
        <PromatePageHeader
          title={newReceipt ? 'New Receipt' : 'Receipt'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Container />
        </PromatePageHeader>
      </div>
    )
  }
}

export default CollectionScreen;