import * as React from "react";
import ReactToPrint from "react-to-print";
import { Row, Spin, Col, Button, Popconfirm, Divider, Table, Tag } from "antd";
import { ComponentToPrint } from "./ComponentToPrint";

class OfflineOrderReport extends React.PureComponent {
  componentRef = null;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      text: "old boring text",
      orderId: this.props.location.state.order.No
    };
  }

  handleAfterPrint = () => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  };

  handleBeforePrint = () => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  };

  handleOnBeforeGetContent = () => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    this.setState({ text: "Loading new text...", isLoading: true });

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          { text: "New, Updated Text!", isLoading: false },
          resolve
        );
      }, 2000);
    });
  };

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  reactToPrintTrigger = () => {
    return (
      <div>

        <Row type="flex" align="middle">
          <Button type="primary">Generate Offline Report</Button>
        </Row>

      </div>
    );
  };

  render() {
    return (
      <div>
        <ReactToPrint
          content={this.reactToPrintContent}
          documentTitle={this.state.orderId}
          onAfterPrint={this.handleAfterPrint}
          onBeforeGetContent={this.handleOnBeforeGetContent}
          onBeforePrint={this.handleBeforePrint}
          removeAfterPrint
          trigger={this.reactToPrintTrigger}
        />

        <ComponentToPrint ref={this.setComponentRef} text={this.state.text} orderId={this.state.orderId} />
      </div>
    );
  }
}

export default OfflineOrderReport;