import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Tag } from "antd";
import { FaMapMarker, FaPhoneSquare } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from '../../../redux/reducers';
import { DISTRIBUTORS } from "../../../const/Permissions";

class DistributorCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'UNVERIFIED':
        return 'orange';
      case 'Active':
        return 'green';
      case 'Blocked':
        return 'red';
      default:
        return '';
    }
  }


  handleCardClick = () => {
    const { distributor, history } = this.props;
    const { Code } = distributor;
    const { match } = this.props;
    const url = match.url

    if (!!Code)
      history.push(url + "/" + Code)
  }

  render() {
    const { distributor, loggedInUser: { permissions = [] } } = this.props;
    const { Code, Name, Name2, Add, Add2, ContPhoneNo, RSMName, ASMName, Status } = distributor;
    const status = (!Status || (Status && Status.length === 0)) ? 'Active' : Status;
    return (
      <Row onClick={permissions.includes(DISTRIBUTORS.VIEW) && this.handleCardClick}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={2} lg={2}><span style={{ wordBreak: 'break-all' }}>{Code}</span></Col>
          <Col xs={0} sm={0} md={6} lg={6}><span style={styles.name}>{Name + ' ' + Name2}</span></Col>
          <Col xs={0} sm={0} md={2} lg={2}><Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag></Col>
          <Col xs={0} sm={0} md={2} lg={2}><span>{ContPhoneNo}</span></Col>
          <Col xs={0} sm={0} md={6} lg={6}><span>{Add + ' ' + Add2}</span></Col>
          <Col xs={0} sm={0} md={3} lg={3}><span>{RSMName}</span></Col>
          <Col xs={0} sm={0} md={3} lg={3}><span>{ASMName}</span></Col>

          <Col xs={12} sm={12} md={0} lg={0}><span style={{ wordBreak: 'break-all' }}>{Code}</span></Col>
          <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'right' }}><Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag></Col>
        </Row>

        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0}><span style={styles.name}>{Name + ' ' + Name2}</span></Col>
        </Row>

        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0}><span>{ContPhoneNo}</span></Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    paddingLeft: 10,
    paddingRight: 10
  }

}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) }
}

export default withRouter(connect(mapStateToProps, {})(DistributorCard));