import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class CallView extends Component {
  state = {
    isEdit: false
  }

  render() {
    const { callId } = this.props.match.params
    const newCall = callId === 'new';

    const { match } = this.props;
    const path = match.path
    const isEdit = path.includes("edit");

    return (
      <div>
        <PromatePageHeader
          title={newCall ? 'New Call' : 'Call Info'}
          hideSubTitle={true}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Container callId={callId} />
        </PromatePageHeader>
      </div>
    )
  }

}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default CallView;