import { combineReducers } from 'redux';
import { FETCH_WAREHOUSE_LOCATIONS } from '../actions/warehouses';
import { getOfflineData } from '../../localStorage';

export const byLocationCode = (state = {}, action) => {
	switch (action.type) {
		case FETCH_WAREHOUSE_LOCATIONS:
			const newState = {};
			action.locations.forEach(location => newState[location.LocCode] = location);
			return newState;
		default:
			return state;
	}
};

export const locationCodes = (state = [], action) => {
	switch (action.type) {
		case FETCH_WAREHOUSE_LOCATIONS:
			return action.locations.map(location => location.LocCode);
		default:
			return state;
	}
};

export const byUser = (state = {}, action) => {
	switch (action.type) {
		case FETCH_WAREHOUSE_LOCATIONS: {
			state[action.UserCode] = action.locations.map(location => location.LocCode);
			return state;
		}
		default:
			return state;
	}
};

const warehouses = combineReducers({
	byLocationCode,
	locationCodes,
	byUser
});

export default warehouses;

export const getLocations = state => {
	if (getOfflineData('state').isSystemOffline) {
		let locationData = state.locationCodes.map(code => state.byLocationCode[code]);
		locationData = locationData.filter(function (el) {
			return el.LocCode.charAt(0) === "M";
		});
		return locationData;
	} else {
		return state.locationCodes.map(code => state.byLocationCode[code])
	}

}
