import { Input } from 'antd';
import React, { Component } from 'react';
const { TextArea } = Input;

export default class EnglishInput extends Component {
  render = () => <Input {...this.props} onChange={e => handleChange(e, this.props.onChange)} />
}

export class EnglishTextArea extends Component {
  render = () => <TextArea {...this.props} onChange={e => handleChange(e, this.props.onChange)} />
}

const handleChange = (event, onChange) => {
  if (!onChange) return;

  const { value } = event.target;

  let output = '';
  for (let i = value.length - 1; i > -1; i--) {
    if (value.charCodeAt(i) < 192) {
      output = value.charAt(i) + output;
    }
  }

  onChange(output, event);
}