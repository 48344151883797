import React, { Component } from "react";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard, FaUser } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { dateFormatView } from "../../const/Formats";

class StockTransferCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { stockTransfer, history } = this.props;
    const { stockTransferId, fromWarehouseName, fromLocationName, toWarehouseName, toLocationName, totalAmount, transferDate, transferdBy, transferdByName, status, amountIncludingVat, remarks } = stockTransfer;
    return (
      <Row onClick={() => history.push("/stockTransfers/" + stockTransferId)}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={0} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>#{stockTransferId ? stockTransferId.replace(/---/g, '/') : ''}</span>
          </Col>

          <Col xs={6} sm={5} md={5} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>#{stockTransferId ? stockTransferId.replace(/---/g, '/') : ''}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={2} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>

          <Col xs={6} sm={5} md={5} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>


          <Col xs={6} sm={5} md={5} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(transferDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={5} md={6} lg={3} style={{ textAlign: 'left' }}>
            <span >{transferdByName}</span>
          </Col>
          {/* <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'left' }}>
            <span >{remarks}</span>
          </Col> */}

          <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{fromWarehouseName}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{fromLocationName}</span>
          </Col>

          <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{toWarehouseName}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.location} >{toLocationName}</span>
          </Col>

          {/* <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{stockTakeBy}</p>
          </Col> */}

          <Col xs={0} sm={0} md={0} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={totalAmount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2}
            />
          </Col>

          <Col xs={4} sm={4} md={3} lg={0} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={totalAmount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2}
            />
          </Col>

        </Row>
        <Row style={styles.lowerRow}>

          <Col xs={6} sm={5} md={5} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{fromWarehouseName}</span>
          </Col>
          <Col xs={6} sm={5} md={5} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{fromLocationName}</span>
          </Col>
          <Col xs={6} sm={5} md={5} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{toWarehouseName}</span>
          </Col>
          <Col xs={6} sm={5} md={5} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.location}>{toLocationName}</span>
          </Col>


          {/* 
					<Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
					</Col>
          
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.stockTakeDate}>{moment(stockTakeEndDate).format(dateTimeFormatView)}</span>
					</Col> */}


        </Row>
        <Row style={styles.lowerRow}>

          <Col xs={0} sm={0} md={20} lg={20} style={{ textAlign: 'left' }}>
            <span style={styles.remark}>{remarks}</span>
          </Col>
          <Col xs={12} sm={20} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.remark}>{remarks}</span>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span >{transferdByName}</span>
          </Col>

          {/* <Col xs={6} sm={8} md={0} lg={0} style={styles.bottomColumnStyles}>
						<FaUser style={styles.icon} /><span>{stockTakeBy}</span>
					</Col>
        
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span  >{warehouseName}</span>
					</Col> */}
        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 5
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  location: {
    fontSize: 14,
  },
  remark: {
    marginTop: 4,
    fontSize: 12,
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}
export default withRouter(StockTransferCard);