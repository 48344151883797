import { Col, Row, Select, Typography } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ROUTE_TARGET } from '../../../../const/Permissions';
import { fetchAllMonths, fetchAllRouteTargetsByFilter, fetchAllYears, updateFilter } from '../../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAllMonths, getAllYears, getAuthData, getFilter, getFilteredRouteTargets, getSalesUserHierarchy } from "../../../../redux/reducers";
import { StyledButton } from '../../common/Buttons';
import ExcelExport from "../../common/ExcelExport";
import { getURLParams, withOutThousandSeperator } from '../../common/helpers';
import UserHierachy from '../../common/UserHierachy';

const { Text } = Typography;
const { Option } = Select;

export class Header extends Component {
    componentDidMount = async () => {
        const { loading, setLoading, updateFilter, fetchAllRouteTargetsByFilter, toastManager, fetchSalesUserHierarchy, authData, fetchAllMonths, fetchAllYears } = this.props;
        await fetchSalesUserHierarchy(authData);
        await fetchAllMonths();
        await fetchAllYears();

        const urlParams = await getURLParams(this.props);
        if (urlParams) {
            await fetchAllRouteTargetsByFilter({
                Year: urlParams.Year,
                Month: urlParams.Month,
                UserTag: urlParams.UserTag
            });
            await updateFilter({
                Year: urlParams.Year,
                Month: urlParams.Month,
                UserTag: urlParams.UserTag
            }, this.props);

        } else {
            toastManager.add("Route targets getting was failed.", { autoDismiss: true, appearance: 'error' });
        }
        setLoading(false);
    }

    handleChange = async (name, value) => {
        await this.props.updateFilter({ [name]: value }, this.props);
    };

    handleSearch = async () => {
        const { fetchAllRouteTargetsByFilter, filter, setLoading } = this.props;
        setLoading(true);
        await fetchAllRouteTargetsByFilter(
            {
                Year: filter.Year,
                Month: filter.Month,
                UserTag: filter.UserTag
            }
        );
        setLoading(false);
    }

    render() {
        const { filter, months, years, onHandleAllApproved, onHandleAllRejected, routeTargets } = this.props;
        const exportData = routeTargets.records.map(routeTarget => {
            const {
                TerrName,
                RouteCode,
                RouteName,
                TotOutlets,
                PordOutlets,
                PlannedTgt,
                RouteAchievement,
                RouteReturns,
                RouteContri,
                Approved
            } = routeTarget;

            const netSales = Number(withOutThousandSeperator(RouteAchievement ?? 0)) - Number(withOutThousandSeperator(RouteReturns ?? 0));
            let tgtVsAch = 0;
            if (withOutThousandSeperator(PlannedTgt)) {
                tgtVsAch = (netSales / Number(withOutThousandSeperator(PlannedTgt))) * 100;
            }
            return {
                Territory: TerrName,
                RouteCode: RouteCode,
                RouteName: RouteName,
                TotalOutlets: TotOutlets,
                ProductiveOutlets: PordOutlets,
                RouteTarget: PlannedTgt,
                RouteAchievement: RouteAchievement,
                RouteReturns: RouteReturns,
                NetSales: netSales,
                TGTVsACH: tgtVsAch,
                RouteContribution: RouteContri,
                Approved: Approved
            }
        });
        return (
            <>
                <UserHierachy onUpdate={(value) => { }} />
                <Row style={{ padding: "0px 5px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={12} lg={4} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Year:</Text>
                        <Select
                            value={filter.Year}
                            style={{ width: "100%", ...styles.text }}
                            onChange={(value) => this.handleChange('Year', value)}
                        >
                            {years?.records?.map((year, index) =>
                                (<Option style={styles.text} key={`${index}-${year.Code}`} value={year.Code}>{year.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Month:</Text>
                        <Select
                            style={{ width: "100%", ...styles.text }}
                            value={filter.Month}
                            onChange={(value) => this.handleChange('Month', value)}
                        >
                            {months?.records?.map((month, index) =>
                                (<Option style={styles.text} key={`${index}-${month.Code}`} value={month.Code}>{month.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col sm={24} md={24} lg={16} style={styles.container}>
                        <StyledButton color="#106bc7" label="Search" width={150} onClick={() => this.handleSearch()} />
                        {this.props.permissions.includes(ROUTE_TARGET.EXPORT) && (<ExcelExport data={exportData} fileName={`Route Targets (${filter.Month}-${filter.Year})`} />)}
                        {this.props.permissions.includes(ROUTE_TARGET.APPROVE) && (<StyledButton color="#0f8a86" width={150} label="Approve" onClick={() => onHandleAllApproved()} />)}
                        {this.props.permissions.includes(ROUTE_TARGET.REJECT) && (<StyledButton color="#a60d33" width={150} label="Reject" onClick={() => onHandleAllRejected()} />)}
                    </Col>
                </Row >
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    months: getAllMonths(state),
    years: getAllYears(state),
    filter: getFilter(state),
    routeTargets: getFilteredRouteTargets(state),
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchAllRouteTargetsByFilter,
    updateFilter
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));

const styles = {
    text: {
        color: '#000000'
    },
    container: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        gap: 5,
        justifyContent: "flex-start",
    }
}