import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { GeoMap } from "./GeoMap";
import { dateTimeFormatFilterView } from '../../const/Formats';
import moment from 'moment';

class LocationMap extends Component {

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    return this.context.screenHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight) - 20;
  }

  getCustomerCluster = () => {
    const locations = [];
    this.props.customers && this.props.customers.forEach(customer => {
      const { Add, MapLocation, Name } = customer;
      const location = this.getLocation(Name, Add, MapLocation, '/green-marker.png');
      if (location) locations.push(location);
    });
    return { locations, icon: '/green-circle.png' };
  }

  getDistributorCluster = () => {
    const locations = [];
    this.props.distributors && this.props.distributors.forEach(distributor => {
      const { Add, MapLocation, Name } = distributor;
      const location = this.getLocation(Name, Add, MapLocation, '/blue-marker.png');
      if (location) locations.push(location);
    });
    return { locations, icon: '/blue-circle.png' };
  }

  getRouteCluster = () => {
    const locations = [];
    this.props.routes && this.props.routes.forEach(route => {
      const { SartMapLocation, EndMapLocation } = route || {};
      const startLocation = this.getRouteMapLocation('Start', SartMapLocation, route);
      const endLocation = this.getRouteMapLocation('End', EndMapLocation, route);

      if (startLocation) locations.push(startLocation);
      if (endLocation) locations.push(endLocation);
    });
    return { locations, icon: '/red-circle.png' };
  }

  getCallsCluster = () => {
    const locations = [];
    this.props.calls && this.props.calls.forEach(call => {
      const { StartMapLoc } = call || {};
      const startLocation = this.getCallsMapLocation(StartMapLoc, call);

      if (startLocation) locations.push(startLocation);
    });
    return { locations, icon: '/orange-circle.png' };
  }

  getCallsMapLocation = (mapLocation, call) =>
    call && this.getLocation(call.StartReasonDescrip, call.CustName, mapLocation, '/orange-marker.png', call.RouteName, call.UserName, moment(call.StartDateTime).add('minutes', +330).format(dateTimeFormatFilterView),);

  getRouteMapLocation = (type, mapLocation, route) =>
    route && this.getLocation(type + ' Route ' + route.RouteCode, route.RouteName, mapLocation, '/red-marker.png');

  getLocation = (title, subtitle, mapLocation, icon, subtitle2, subtitle3, subtitle4) => {
    if (!mapLocation) return;

    const parts = mapLocation.split(',');
    if (parts.length !== 2) return;

    if (isNaN(parts[0]) || isNaN(parts[1])) return;

    return {
      lat: parseFloat(parts[0]),
      lng: parseFloat(parts[1]),
      label: {
        title,
        subtitle,
        icon,
        subtitle2,
        subtitle3,
        subtitle4
      }
    };
  }

  render() {
    return (
      <GeoMap
        height={this.props.height || this.getScrollAreaHeight()}
        clusters={[
          this.getCustomerCluster(),
          this.getDistributorCluster(),
          this.getRouteCluster(),
          this.getCallsCluster()
        ]}
        isMarkerShown={true}
        editable={false} />
    )
  }
}

const mapStateToProps = state => {
  return {

  };
};

LocationMap.contextTypes = {
  screenHeight: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(LocationMap);