import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import { PURCHASE_CREDIT_INVOICES } from "../../const/Permissions";

class CreditNoteCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Completed':
      case 'Active':
        return 'green';
      case 'Block':
        return 'red';
      case 'Open':
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history, loggedInUser: { permissions = [] } } = this.props;
    const { No, ReturnOrderNo, PostingDate, DocType, DocReasonDescrip, DocReasonCode, DistributorCode, DistributorName, CustStatus, AmountIncluVAT } = order;

    const status = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;
    return (
      <Row onClick={() => permissions.includes(PURCHASE_CREDIT_INVOICES.VIEW) && history.push("/purchasingCreditInvoices/" + encodeURIComponent(No))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{No}</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{ReturnOrderNo}</Col>
          <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>{moment(PostingDate).format(dateFormatView)}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>{DocType}</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{DocReasonDescrip}</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>{DistributorCode}</Col>
          <Col xs={0} sm={0} md={3} lg={4} style={{ textAlign: 'left' }}>{DistributorName}</Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right' }}>{AmountIncluVAT}</Col>


          {/* mobieview */}
          <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'left', ...styles.largeBold }}>{No}</Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'center', ...styles.largeBold }}>{moment(PostingDate).format(dateFormatView)}</Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'right' }}></Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0} style={{ textAlign: 'left' }}>{DistributorName}</Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
          </Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>{DocType}</Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>{DocReasonDescrip || DocReasonCode}</Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'right', ...styles.bigBold }}>{AmountIncluVAT}</Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  largeBold: {
    fontSize: 14,
    fontWeight: 700
  },
  bigBold: {
    fontSize: 16,
    fontWeight: 700
  },
  normalBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  orderNoDesktop: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderNoMobile: {
    fontSize: 12,
    fontWeight: 700
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    marginRight: 0
  },
  price: {
    fontSize: 15,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const { order } = ownProps;

  const loggedInUser = getLoggedInUser(state);

  return { loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(CreditNoteCard));