import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { generateDemoReceipts } from '../ReceiptDemoData';
import { getPostedCreditNotesByTypeAndCustomer } from "../../../redux/reducers";
import { withRouter } from "react-router-dom";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class CreditNoteListMobile extends Component {

  //componentDidMount = () => generateDemoReceipts()
  getColumns = () => {
    const columns = [
      {
        title: 'Details',
        dataIndex: 'No',
        key: 'creditNoteNo',
        render: (value, data) => this.renderLeftColumn(data),
      },
      {
        title: '',
        dataIndex: 'No',
        key: 'creditNoteNo',
        align: 'right',
        render: (value, data) => this.renderRightColumn(data),
      }
    ]

    return columns;
  }

  renderLeftColumn = creditNo => {
    const { history, match } = this.props;
    return <div>
      <div style={{ cursor: 'default' }} o>{creditNo.No}</div>
      <div>{creditNo.DocDate}</div>
      <div>{creditNo.ReturnReasonDescrip}</div>
    </div>
  }

  renderRightColumn = creditNo => {
    return <div>
      <div style={{ fontWeight: 'bold' }}>{format(creditNo.Amount, currencyFormat)}</div>
      <div style={{ fontWeight: 'bold' }}>{format(creditNo.RemAmount, currencyFormat)}</div>
    </div>
  }

  getRowSelection = () => {
    const { selectedCreditNote } = this.props;
    return {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = selectedRows.map(row => row.No);
        this.props.handleOnClickSelectCreditNotes(selectedRowKeys, selectedRows, record, selected)
      },
      type: 'radio',
      selectedRowKeys: selectedCreditNote ? [selectedCreditNote.No] : [],
      selectedRows: selectedCreditNote ? [selectedCreditNote] : []
    };
  }

  render() {
    let { postedCreditNotes = [], pagination } = this.props;
    pagination = postedCreditNotes.length > 5 ? pagination : false
    return (
      <Table
        rowKey={'No'}
        rowSelection={this.getRowSelection()}
        columns={this.getColumns()}
        showHeader={false}
        dataSource={postedCreditNotes}
        pagination={pagination || false}
        size={'small'}
        title={() => <h3>Credit Invoices</h3>} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, match } = ownProps;
  const { docType } = match.params
  // we combine posted credit notes and return invoices gather to show credit invoice list
  const postedCreditNotes = customerId ? getPostedCreditNotesByTypeAndCustomer(state, 'CREDITMEMO', customerId) : [];
  const returnInvoices = customerId ? getPostedCreditNotesByTypeAndCustomer(state, 'RETURNORDER', customerId) : [];
  const combinedCreditInvoices = postedCreditNotes.concat(returnInvoices);
  return { postedCreditNotes: combinedCreditInvoices };
}

export default withRouter(connect(mapStateToProps, {})(CreditNoteListMobile));