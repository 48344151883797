import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Col, Row, Table } from "antd";
import { withRouter } from "react-router-dom";
import ReceiptCard from "./ReceiptCard";
import ReceiptCardHeader from "./ReceiptCardHeader";
import CurrencyFormat from "react-currency-format";
import AdvanceSearchFilters from "../common/AdvanceSearchFilters";
import TableFooter from "../common/TableFooter";

class OrderTable extends Component {

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight + 20 : 25;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, setDefaultCustomerOption, setDefaultRouteOption, match, statusOptions } = this.props;
    const { customerId } = match.params;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const singleBar = { lg: 1, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    const filterOptions = {
      SALES_HIERARCHY: entireColumn,
      SELECT_ROUTE: customerId ? hidden : oneForthOfColumn,
      SELECT_OUTLET: customerId ? hidden : oneForthOfColumn,
      SELECT_CATEGORY: customerId ? hidden : oneForthOfColumn,
      SELECT_PAYMENT_TYPE: customerId ? fiveBars : oneForthOfColumn,
      FROM_DATE_PICKER: customerId ? fiveBars : oneForthOfColumn,
      TO_DATE_PICKER: customerId ? fiveBars : oneForthOfColumn,
      SELECT_STATUS: customerId ? fiveBars : oneForthOfColumn,
      SEARCH_BUTTON: customerId ? oneSixthOfColumn : oneForthOfColumn
    };

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          {...filterOptions}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          filter={filter}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
          statusOptions={statusOptions}
        />
        <ReceiptCardHeader />
      </div>
    )
  }

  getOrderTotals = receipts => {
    let total = 0;
    receipts.forEach(receipt => {
      let PayAmount = receipt.PayAmount.replace(/,/g, "");
      total = total + parseFloat(PayAmount);
    })
    return total;
  }

  renderFooter = receipts => {
    const { total, totalAmount, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={totalAmount}
        noOfRecordLabel={'Receipts'}
        style={{ paddingRight: 33 }}
      />
    );
  }

  renderColumns = () => {
    return [{
      render: receipt => <ReceiptCard receipt={receipt} />
    }]
  }

  render() {
    const { receipts: filteredReceipts } = this.props;

    return (
      <Col>
        <Table
          rowKey={'No'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredReceipts) }}
          columns={this.renderColumns()}
          dataSource={filteredReceipts}
          size={'small'}
          pagination={false}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredReceipts)} />
      </Col>
    )
  }
}

OrderTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(OrderTable);