import React, { Component } from 'react';
import { Spin } from "antd";
import { fetchCustomers } from "../../../redux/actions/customers";
import { getCustomers } from "../../../redux/reducers";
import { connect } from "react-redux";
import ExpenseCategoryTable from "./ExpenseCategoryTable";
import { showLoadingMessage } from '../../common/Message';
import { generateData } from '../ExpenseCategoryDemoData'

class ExpenseCategoryTableContainer extends Component {
  state = {
    filter: {
      text: undefined
    },
    loading: false
  }

  componentDidMount() {
    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchCustomers().then(() => {
      this.setState({ loading: false })
      this.hideLoadingMessage();
    })
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Expense Categories.');
  }

  getFilteredExpenseCategories = () => {
    const { expenseCategories } = this.props;
    const { text } = this.state.filter;

    let filteredExpenses = expenseCategories;

    if (text) {
      filteredExpenses = expenseCategories.filter(expense => {
        const { id, category, description } = expense;
        const idFilter = this.stringCompare(id, text);
        const categoryFilter = this.stringCompare(category, text);
        //const amountFilter = this.stringCompare(amount, text);
        const descriptionFilter = this.stringCompare(description, text);
        return categoryFilter || descriptionFilter;
      })
    }

    return filteredExpenses;
  }

  getOutstandingTotals = expenseList => {
    let totalOutstanding = 0
    expenseList.forEach(expense => totalOutstanding = totalOutstanding + expense.amount)
    return totalOutstanding;
  }

  stringCompare = (string1, string2) => string1.toLowerCase().includes(string2.toLowerCase());

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  render() {
    const { loading } = this.state;
    const filteredExpenseCategories = this.getFilteredExpenseCategories();

    return (
      <Spin spinning={loading && this.props.expenseCategories.length === 0} tip={"Loading expense Categories."}>
        <ExpenseCategoryTable
          handleInputTextChange={this.handleInputTextChange}
          expenses={filteredExpenseCategories}
        />
      </Spin>
    )
  }
}

const mapStateToProps = state => {
  //const customers = getCustomers(state);
  const expenseCategories = generateData();
  return { expenseCategories };
};


export default connect(mapStateToProps, { fetchCustomers })(ExpenseCategoryTableContainer);