import * as fromApi from '../../api/orders';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_BY_CUSTOMER = 'FETCH_ORDERS_BY_CUSTOMER';
export const FETCH_ORDER = 'FETCH_ORDER';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const DELETE_SALES_LINE = 'DELETE_SALES_LINE';
export const DELETE_ORDER = 'DELETE_ORDER';

export const fetchOrders = () => dispatch => {
  return fromApi.getAll().then(response => {
    dispatch({ type: FETCH_ORDERS, orders: response.orders });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchOrdersByCustomer = customerId => dispatch => {
  return fromApi.getForCustomer(customerId).then(response => {
    const { orders, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_ORDERS_BY_CUSTOMER, customerId, orders });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchOrdersForDateRange = filter => dispatch => {
  return fromApi.getForDateRange(filter).then(response => {
    const { orders, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_ORDERS, orders });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchOrder = orderId => dispatch => {
  return fromApi.get(orderId).then(response => {
    const { order, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_ORDER, order });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const addOrder = order => dispatch => {
  return fromApi.add(order).then(response => {
    const { order, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_ORDER, order });
    dispatch({ type: FETCH_ORDERS_BY_CUSTOMER, customerId: order.sellToCustomerCode, orders: [order] });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateOrder = order => dispatch => {
  return fromApi.update(order).then(response => {
    const { order, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_ORDER, order });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const confirmOrder = order => dispatch => {
  return fromApi.confirm(order).then(response => {
    const { order, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_ORDER, order });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteOrder = order => dispatch => {
  return fromApi.deleteOrder(order).then(response => {
    const { error } = response;
    if (error) return { error }

    dispatch({ type: DELETE_ORDER, order });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const addSalesLine = salesLine => dispatch => {
  return fromApi.addSalesLine(salesLine).then(response => {
    const { order, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_ORDER, order });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateSalesLineQuantity = salesLine => dispatch => {
  return fromApi.updateSalesLineQuantity(salesLine).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: UPDATE_QUANTITY, salesLine });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteSalesLine = salesLine => dispatch => {
  return fromApi.deleteSalesLine(salesLine).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: DELETE_SALES_LINE, salesLine });

    return response;
  }).catch(error => {
    return { error }
  })
};