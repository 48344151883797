import uuid from 'uuid';

const ITEM_COUNT = 100;
let data = [];


export const names = ["Andrew Stephen", "Simon Cowel", "Mitchell Richedson", "Daren Iyan", "Brayan Adems", "Nicoles Stephen", "Theron M Goodman", "Darren Lara", "Elizabeth Mendella"];
export const emails = ["afton1986@yahoo.com", "mafalda1991@gmail.com", "nestor.bedn@yahoo.com", "alexander.rosenba@gmail.com", "monty_treut@hotmail.com", "jabari1983@hotmail.com", "florida_fun7@yahoo.com", "emmanuell9@hotmail.com", "audie_rober@yahoo.com", "rosario_dubuq@hotmail.com"];
export const addresses = ["4611 Lamberts Branch Road", "1828 Ersel Street", "1598 Lost Creek Road", "1441 Pearlman Avenue", "3580 Java Lane", "1429 Pritchard Court", "3047 George Street", "158 Hannah Street", "3514 Broad Street", "414 Werninger Street", "1933 Coburn Hollow Road", "L1933 Evergreen Lane", "4306 Progress Way", "1423 Emeral Dreams Drive", "2439 Stoney Lane", "3054 Memory Lane"];
export const contactNumbers = ["(213)629-1739", "(301)994-9648", "(703)285-6963", "(847)979-9052", "(208)503-5351", " (614)203-3663", "(617)926-0845", "(214)440-1377", "(573)369-1597", "(607)348-3295", " (619)422-2857", "(812)456-6483", "(715)879-3146", "(574)329-1290", "(509)662-2593", "(252)227-3060", "(704)569-2006", "(979)334-0775", "(310)557-3260", "(515)627-9940", "(956)936-3954", "(818)270-1718"];
export const statues = ["ACTIVE", "BLOCKED", "UNVERIFIED"];
export const bloackedReasons = ["Over Credit Limit", "unsettled Outstanding"];
export const blockedReasonsType = ["Type 1", "Type 2"];
export const categories = ["Book Shop", "Communications", "Groceries", "Corporate", "Office", "Super Markets", "Schools"];
export const distributors = [
  {
    distributorCode: "Distributor Code 001",
    distributorName: "Distributor Name 001",
  },
  {
    distributorCode: "Distributor Code 002",
    distributorName: "Distributor Name 002",
  },
  {
    distributorCode: "Distributor Code 003",
    distributorName: "Distributor Name 003",
  },
  {
    distributorCode: "Distributor Code 004",
    distributorName: "Distributor Name 004",
  },
];

export const routes = [
  {
    routeCode: "Route Code 001",
    routeName: "Route Name 001",
  },
  {
    routeCode: "Route Code 002",
    routeName: "Route Name 002",
  },
  {
    routeCode: "Route Code 003",
    routeName: "Route Name 003",
  },
  {
    distributorCode: "Route Code 004",
    routeName: "Route Name 004",
  },
];


export const generateData = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateItem(i));
  }

  return data;
}

const generateItem = index => {

  var bloackedReasonArray = []
  const item = {
    id: "CODE " + index
  }



  for (let i = 0; i < 2; i++) {
    const bloackedReasonItem = {
      reasonDescription: bloackedReasons[i],
      reasonType: blockedReasonsType[getRandomNumber(0, blockedReasonsType.length)]
    }
    bloackedReasonArray.push(bloackedReasonItem)
  }


  const name = names[getRandomNumber(0, names.length)]
  const email = emails[getRandomNumber(0, emails.length)]
  const address = addresses[getRandomNumber(0, addresses.length)]
  const contactNumber = contactNumbers[getRandomNumber(0, contactNumbers.length)]
  const status = statues[getRandomNumber(0, statues.length)]
  const outstanding = getRandomNumber(300, 700)
  const customerCategory = categories[getRandomNumber(0, categories.length)]

  const createdAt = randomDate(new Date(2012, 0, 1), new Date())
  const createdBy = "CODE " + getRandomNumber(50, 100)
  const updatedAt = randomDate(new Date(2012, 0, 1), new Date())
  const updatedBy = "CODE " + getRandomNumber(50, 100)

  item.name = name
  item.email = email
  item.address = address
  item.contactNumber = contactNumber
  item.status = status
  item.outstanding = outstanding
  item.createdAt = createdAt
  item.createdBy = createdBy
  item.updatedAt = updatedAt
  item.updatedBy = updatedBy
  item.customerCategory = customerCategory
  if (status === "BLOCKED") {
    item.blockedReasons = bloackedReasonArray
  }
  item.location = {
    lat: 6.9271,
    lng: 79.8612
  }

  return item;
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const getDemoItem = id => {
  return data.find(item => item.id === id);
}