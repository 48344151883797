import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaUser } from "react-icons/fa";

class CollectionCardHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Unverified':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {

    return (
      <Row style={{ fontWeight: 'bold', height: 50, marginRight: 15, backgroundColor: '#eceaea', padding: 10 }} >
        <Row style={styles.alignRow}>
          <Col xs={9} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>Collection No</span>
          </Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={6} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>Collection Date</span>
          </Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={{ margin: 5 }}>Customer Code</p>
          </Col>
          <Col xs={0} sm={0} md={6} lg={6} style={{ textAlign: 'left' }}>
            <span>Customer Name</span>
          </Col>
          <Col xs={0} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={styles.paymentType}>Payment Type</p>
          </Col>
          <Col xs={9} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <p style={styles.paymentType}>Payment Type</p>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={styles.bottomColumnStyles}>
            <FaUser style={styles.icon} /><span>Customer Code</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
            <FaUser style={styles.icon} /><span>Customer Name</span>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },

  paymentType: {
    marginRight: 0,
    marginBottom: 0
  },
  icon: {
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
  }
}

export default CollectionCardHeader;