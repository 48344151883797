import * as fromApi from '../../api/generic';

export const FETCH_PURCHASE_ALLOCATED_DETAILS = 'FETCH_PURCHASE_ALLOCATED_DETAILS';

export const fetchAllocatedDetails = (filter, page, searchKey = '') => dispatch => {
 
  return fromApi.get('AppliedVendLedEntry', filter, page).then(response => {    
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error } 

    dispatch({ type: FETCH_PURCHASE_ALLOCATED_DETAILS, allocatedData: data, filter });

    return response;
  }).catch(error => {
    console.log(error);
    return { error }
  }) 
};

// export const unallocateDetails = detail => dispatch => {
//     return fromApi.add('UnApplyCustLedEntry', detail).then(response => {
//       const { data, error } = response;
      
//       if (error) return { error }
      
//       return response;
//     }).catch(error => {
//       return { error }
//     })
//   };
