import { Checkbox, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ADD_CUSTOMER_ROUTE_TARGET } from '../../../../const/Permissions';
import { ProMateGreen } from "../../../../const/Theme";
import { PAGE_SIZE } from '../../../../redux/actions/fetchOData';
import { fetchAllCustomerVisitTargetsByFilter, updateCustomerVisitTarget } from '../../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAuthData, getSalesUserHierarchy } from "../../../../redux/reducers";
import PromatePageHeader from '../../../common/PromatePageHeader';
import { sortDates } from '../../common/helpers';
import DataTable from './components/DataTable';
import Header from './components/Header';

class AddRouteCustomerTarget extends Component {
    _isMounted = false;

    state = {
        loading: true,
        tableData: {
            records: []
        },
        selectedRowKeys: [],
        pagination: {
            current: 0,
            pageSize: PAGE_SIZE,
            total: 0
        },
        modal: {
            show: false,
            selectedRow: null,
            VisitDays: [],
            PlannedVisitDays: []
        }
    };

    componentDidMount = () => {
        const { authData, history } = this.props;
        this._isMounted = true;
        if (!authData.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.CREATE)) {
            history.push('/targetScheduler');
            return;
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    setLoading = (value) => {
        if (this._isMounted) {
            this.setState({
                loading: value,
            });
        }
    }

    setTableData = (data) => {
        if (this._isMounted) {
            this.setState({
                tableData: data,
            });
        }
    }

    setSelectedRowKeys = (selectedRowKeys) => {
        if (this._isMounted) {
            this.setState({
                selectedRowKeys
            });
        }
    }

    setPagination = (data, callback = () => { }) => {
        if (this._isMounted) {
            this.setState({
                pagination: data
            }, () => callback(this.state.pagination));
        }
    }

    handleUpdate = async (record) => {
        const { toastManager } = this.props;
        this.setLoading(true);
        const result = await this.props.updateCustomerVisitTarget(record);
        if (result?.error) {
            toastManager.add('Customer visit target update was failed.', { autoDismiss: true, appearance: 'error' });
        } else {
            toastManager.add('Customer visit target successfully updated.', { autoDismiss: true, appearance: 'success' });
        }
        this.setLoading(false);
    }

    handleAllUpdate = async () => {
        const { toastManager } = this.props;
        this.setLoading(true);
        if (this.state.selectedRowKeys.length > 0) {
            for (const key of this.state.selectedRowKeys) {
                const record = this.state.tableData.records.find((record) => record.CustCode == key);
                await this.props.updateCustomerVisitTarget(record);
            }
            toastManager.add('Customer visit targets successfully updated.', { autoDismiss: true, appearance: 'success' });
        } else {
            toastManager.add('No items are selected.', { autoDismiss: true, appearance: 'info' });
        }
        this.setLoading(false);
    }

    handleInputChange = (value, type, row) => {
        const updatedData = this.state.tableData.records.map((record) => {
            if (record.CustCode == row.CustCode) {
                if (type === "PlannedTarget") {
                    switch (Number(record['Freq'])) {
                        case 1:
                            row['VisitValueOne'] = value;
                            row['VisitValueTwo'] = 0.00;
                            row['VisitValueThree'] = 0.00;
                            row['VisitValueFour'] = 0.00;
                            break;
                        case 2:
                            const seperatedValue1 = Number(value) / 2;
                            row['VisitValueOne'] = seperatedValue1.toFixed(2);
                            row['VisitValueTwo'] = seperatedValue1.toFixed(2);
                            row['VisitValueThree'] = 0.00;
                            row['VisitValueFour'] = 0.00;
                            break;
                        case 3:
                            const seperatedValue2 = Number(value) / 3;
                            row['VisitValueOne'] = seperatedValue2.toFixed(2);
                            row['VisitValueTwo'] = seperatedValue2.toFixed(2);
                            row['VisitValueThree'] = seperatedValue2.toFixed(2);
                            row['VisitValueFour'] = 0.00;
                            break;
                        case 4:
                            const seperatedValue3 = Number(value) / 4;
                            row['VisitValueOne'] = seperatedValue3.toFixed(2);
                            row['VisitValueTwo'] = seperatedValue3.toFixed(2);
                            row['VisitValueThree'] = seperatedValue3.toFixed(2);
                            row['VisitValueFour'] = seperatedValue3.toFixed(2);
                            break;
                        default:
                            break;
                    }
                }
                return { ...row, [type]: value }
            }
            return record;
        });
        this.setState({
            tableData: { ...this.state.tableData, records: updatedData }
        });
    }

    handleTableChange = async (pagination) => {
        const { fetchAllCustomerVisitTargetsByFilter } = this.props;
        this.setLoading(true);
        const results = await fetchAllCustomerVisitTargetsByFilter(this.state.filter, 0);
        this.setTableData(results);
        this.setPagination({ ...pagination, total: results.recordTotal });
        this.setLoading(false);
    }

    handleModal = (status, row = null) => {
        if (status) {
            const { PlannedVisitDays, VisitDays } = row;
            let visitDays = (VisitDays === "") ? [] : String(VisitDays).split(',');
            visitDays = sortDates(visitDays);
            let plannedVisitDays = (PlannedVisitDays === "") ? [] : String(PlannedVisitDays).split(',');
            plannedVisitDays = sortDates(plannedVisitDays);

            this.setState({
                modal: {
                    show: status,
                    selectedRow: row,
                    VisitDays: visitDays,
                    PlannedVisitDays: plannedVisitDays
                }
            });
        } else {
            this.setState({
                modal: {
                    show: status,
                    selectedRow: null,
                    VisitDays: [],
                    PlannedVisitDays: []
                }
            });
        }
    }

    handleModalOk = () => {
        const updatedTableData = this.state.tableData.records.map(record => {
            if (record.CustCode === this.state.modal.selectedRow.CustCode) {
                let selectedRecord = this.state.modal.selectedRow;
                selectedRecord.PlannedVisitDays = this.state.modal.PlannedVisitDays.join(",");
                return selectedRecord;
            }
            return record;
        })

        this.setState({
            tableData: {
                ...this.state.tableData,
                records: updatedTableData
            },
            modal: {
                show: false,
                selectedRow: null,
                VisitDays: [],
                PlannedVisitDays: []
            }
        });
    }

    onChange = (e, date) => {
        if (Boolean(e.target.checked)) {
            this.setState({
                modal: {
                    ...this.state.modal,
                    PlannedVisitDays: sortDates([...(new Set([...this.state.modal.PlannedVisitDays, date]))])
                }
            });
        } else {
            this.setState({
                modal: {
                    ...this.state.modal,
                    PlannedVisitDays: sortDates(this.state.modal.PlannedVisitDays.filter(day => day !== date))
                }
            });
        }
    }

    render() {
        const { authData } = this.props;

        return (
            <PromatePageHeader
                title="Add Customer Route Target"
                style={styles.pageHeader}
                onBack={() => this.props.history.goBack()}
                showCallButton={false}
            >
                <Header
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                    setTableData={this.setTableData}
                    handleAllUpdate={this.handleAllUpdate}
                    pagination={this.state.pagination}
                    setPagination={this.setPagination}
                    tableData={this.state.tableData}
                    permissions={authData?.permissions ?? []}
                />
                <DataTable
                    loading={this.state.loading}
                    tableData={this.state.tableData}
                    setSelectedRowKeys={this.setSelectedRowKeys}
                    selectedRowKeys={this.state.selectedRowKeys}
                    handleUpdate={this.handleUpdate}
                    handleInputChange={this.handleInputChange}
                    pagination={this.state.pagination}
                    setPagination={this.setPagination}
                    handleTableChange={this.handleTableChange}
                    handleModal={this.handleModal}
                    permissions={authData?.permissions ?? []}
                />
                <Modal
                    title="Visit Days"
                    visible={this.state.modal.show}
                    width={350}
                    onOk={this.handleModalOk}
                    onCancel={() => this.handleModal(false)}
                >
                    {/* <CustomCalendar visitDays={this.state.modal.VisitDays} /> */}
                    <div>
                        {
                            this.state.modal.VisitDays.map((date, index) => (
                                <Checkbox
                                    key={index}
                                    checked={Boolean(this.state.modal.PlannedVisitDays.find((day) => (day === date)))}
                                    onChange={(e) => this.onChange(e, date)}
                                >{date}</Checkbox>
                            ))
                        }
                    </div>
                </Modal>
            </PromatePageHeader>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
})

const mapDispatchToProps = {
    updateCustomerVisitTarget,
    fetchAllCustomerVisitTargetsByFilter,
    fetchSalesUserHierarchy
}

export default withRouter(withToastManager(connect(mapStateToProps, mapDispatchToProps)(AddRouteCustomerTarget)));

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    },
    text: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}