import Api from "./consts";
import { getAuthData } from '../redux/reducers';
import store from '../store';
import { PAGE_SIZE } from "../redux/actions/fetchOData";

export const getAllYears = () => {
	const { url, method } = Api.targetScheduler.years;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Accept'] = 'application/json';
	headers['Auth-Token'] = authData.authToken;

	return fetch(url(), { method, headers }, 'years');
}

export const getAllMonths = () => {
	const { url, method } = Api.targetScheduler.months;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Accept'] = 'application/json';
	headers['Auth-Token'] = authData.authToken;

	return fetch(url(), { method, headers }, 'months');
}

export const getAllBrands = () => {
	const { url, method } = Api.targetScheduler.brands;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Accept'] = 'application/json';
	headers['Auth-Token'] = authData.authToken;

	return fetch(url(), { method, headers }, 'brands');
}

export const getAllItemSubCategoryOne = () => {
	const { url, method } = Api.targetScheduler.itemSubCategoryOne;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Accept'] = 'application/json';
	headers['Auth-Token'] = authData.authToken;

	return fetch(url(), { method, headers }, 'itemSubCategoryOne');
}

export const getMonthlyTarget = (filter) => {
	const { url, method } = Api.targetScheduler.monthlyTarget;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Accept'] = 'application/json';
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(filter) }, 'monthlyTarget');
}

export const getAllRouteTargetsByFilter = (filter) => {
	const { url, method } = Api.targetScheduler.routeTarget.getAll;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(filter) }, 'routeTarget');
}

export const updateRouteTarget = (data) => {
	const { url, method } = Api.targetScheduler.routeTarget.update;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(data) }, 'routeTarget/update');
}

export const getAllCustomerFrequenciesByFilter = (filter) => {
	const { url, method } = Api.targetScheduler.customerFrequency.getAll;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(filter) }, 'customerFrequency');
}

export const updateCustomerFrequency = (data) => {
	const { url, method } = Api.targetScheduler.customerFrequency.update;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(data) }, 'customerFrequency/update');
}

export const getAllCustomerVisitTargets = (filter, page) => {
	const { url, method } = Api.targetScheduler.customerVisitTarget.getAll;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url() + '?page=' + page + '&pageSize=' + (page === 0 ? 0 : PAGE_SIZE), { method, headers, body: JSON.stringify(filter) }, 'customerVisitTarget/getAll');
}

export const updateCustomerVisitTarget = (data) => {
	const { url, method } = Api.targetScheduler.customerVisitTarget.update;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(data) }, 'customerVisitTarget/update');
}

export const getTargetSchedulerDetailsByFilter = (filter) => {
	const { url, method } = Api.targetScheduler.targetScheduler.getAll;

	const authData = getAuthData(store.getState());
	const headers = {};
	headers['Auth-Token'] = authData.authToken;
	headers['Content-Type'] = 'application/json';

	return fetch(url(), { method, headers, body: JSON.stringify(filter) }, 'targetScheduler');
}
