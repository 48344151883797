import { notification } from 'antd'

const showNotification = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
    duration: 2
  });
};

export { showNotification };