import React, { Component } from 'react';
import { Row, Card, Col, Icon, } from "antd";
import moment from "moment";
import FormItem from '../../common/FormItem';
import { dateFormatView } from "../../../const/Formats";
import Input from '../../common/EnglishInput';

class SaleRepresentativeForm extends Component {
  state = {
    showDepositDetailForm: false,
    showChequePDCform: false,
    // name: "",
    // address: "",
    // contactNumber: "",
    // email: "",
    // contactPerson: "",
    // faxNumber: "",
    blockedReasons: [],
  }


  componentDidMount() {
    if (this.props.salesRep) {
      const { blockedReasons } = this.props.salesRep;
      this.setState({
        blockedReasons: blockedReasons
      })
    }
  }

  removeItem(index) {
    const list = this.state.blockedReasons;
    list.splice(index, 1);
    this.setState({ blockedReasons: list });
  }

  render() {
    const {
      newCollectionId,
      onInputChange,
      nameError,
      contactNoError,
      addressError,
      faxNoError,
      emailError,
      contactPersonError,
      links,
      salesRep = {},
      postingDateError } = this.props;

    const { blockedReasons } = this.state
    const { name, address, contactNumber, email, contactPerson, faxNumber } = salesRep;

    const mainFormWidth = newCollectionId === 'new' ? 24 : 12;
    const attachmentfileUrls = links && links.length > 0 && links.filter(link => link.includes("/attachments/"));
    const fileUrl = attachmentfileUrls && attachmentfileUrls.length > 0 && attachmentfileUrls[attachmentfileUrls.length - 1];
    const signaturefileUrls = links && links.length > 0 && links.filter(link => link.includes("/customer-signature"));
    const customerSigned = signaturefileUrls && signaturefileUrls.length > 0;
    return (
      <Card>

        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col>
              <FormItem label={"Name"} error={nameError}>
                <Input
                  className="ant-input"
                  value={name}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("name", value, "nameError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Address"} error={addressError}>
                <Input
                  className="ant-input"
                  value={address}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("address", value, "addressError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Contact No"} error={contactNoError}>
                <Input
                  className="ant-input"
                  value={contactNumber}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("contactNumber", value, "contactNoError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Fax Number"} error={faxNoError}>
                <Input
                  className="ant-input"
                  value={faxNumber}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("faxNumber", value, "faxNoError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Email"} error={emailError}>
                <Input
                  className="ant-input"
                  value={email}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("email", value, "emailError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Contact Person"} error={contactPersonError}>
                <Input
                  className="ant-input"
                  value={contactPerson}
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("email", value, "contactPersonError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>
          </Col>
        </Row>

        {blockedReasons && blockedReasons.length > 0 && <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Blcoked Reasons</td>
                <td></td>
              </tr>
              {this.state.blockedReasons.map((reason, index) => {
                return (
                  <div key={index}>
                    <tr >
                      <td style={{ width: 25 }}>{index + 1}.</td>
                      <td><b>{reason.type} - {reason.reasonDescription}</b> </td>
                      <td textAlign='right'><i> {moment(reason.time).format(dateFormatView + 'hh:mm a')}</i></td>
                      <td>

                        <Icon type="close" style={{ fontSize: '16px', color: 'red' }} onClick={() => this.removeItem(index)} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 25 }}></td>
                      <td colSpan={2}>{reason.narration}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: 25, height: 15 }}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div>
                )
              })}


            </Col>
          </Row>
        </Card>}
      </Card>

    )
  }
}

export default SaleRepresentativeForm;

const paymentTypes = [
  {
    name: 'CASH',
    value: 'CASH',
    key: 'CASH'
  },
  {
    name: 'PDC - Post Dated Cheque',
    value: 'PDC',
    key: 'PDC'
  },
  {
    name: 'CHEQUE',
    value: 'CHEQUE',
    key: 'CHEQUE'
  },
  {
    name: 'Direct Deposit',
    value: 'DEPOSIT',
    key: 'DEPOSIT'
  }
]