import * as fromApi from '../../api/generic';
import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB, createObjectStoreIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects';
import uuid from 'uuid/v1'
import { DOC_TYPE_DEFINITIONS_WITH_SPACE } from '../reducers'
import { FETCH_SALES_HEADER } from './salesHeaders'

export const FETCH_SALES_LINES = 'FETCH_SALES_LINES';
export const FETCH_SALES_LINE = 'FETCH_SALES_LINE';
export const FAILED_TO_ADD_SALES_LINE = 'FAILED_TO_ADD_SALES_LINE';
export const REMOVE_SALES_LINE = 'REMOVE_SALES_LINE';

export const addSalesLine = salesLine => async dispatch => {

  // console.log('addSalesLine: ', salesLine)

  if (getOfflineData('state').isSystemOffline) {

    // Return Sales Invoice
    if (salesLine.DocType === "5") {

      // let currentActiveCall = callResponse.records[callResponse.records.length - 1]
      salesLine.UnitPrice = String(salesLine.UnitPrice).replace(/,/g, '')
      salesLine.DisPercentage = isNaN(parseFloat(salesLine.DisPercentage)) ? '0.00' : parseFloat(salesLine.DisPercentage);
      salesLine.DisAmount = (parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity)).toFixed(2) * parseFloat(salesLine.DisPercentage).toFixed(2) / 100;
      salesLine.Amount = ((parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity)) - (parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity) * parseFloat(salesLine.DisPercentage) / 100)).toFixed(2)

      const newSaleLine = {
        ...salesLine,
        Quantity: parseInt(salesLine.Quantity).toString(),
        ReturnQty: isNaN(parseInt(salesLine.ReturnQty)) ? '0.00' : parseInt(salesLine.ReturnQty).toString(),
        LineAmount: salesLine.Amount,
        AmountIncluVAT: salesLine.Amount,

        Type: "Item",
        DocType: DOC_TYPE_DEFINITIONS_WITH_SPACE[salesLine.DocType],
        LineNo: uuid(),
        Sync: false

        // LineSessionID: "f2933750-a6ad-11ec-9290-ad00fb872b8f",
        // DocType: "5",
        // DocNo: "SRO2884/22/00110",
        // LineNo: "10000",
        // Type: "2",
        // No: "BPFG0352",
        // Description: "RATHNA EX SINGLE RULED 40P",
        // LocCode: "MWH-2884",
        // MapLocation: "6.8747264000000,79.9080448000000",
        // Quantity: 12,
        // UnitPrice: "23.10",
        // LineAmount: "277.20",
        // DisPercentage: "0",
        // DisAmount: "0.00",
        // Amount: "277.20",
        // AmountIncluVAT: "277.20",
        // UserCode: "1001580",
        // LotNo: "BPFG0352-35",
        // MarketPrice: "35.00",
        // CreatedUserID: "1001580",
        // ModifiedUserID: "1001580",
        // CreatedUserName: "RUWANTHILAKA BANDARA",
        // ModifiedUserName: "RUWANTHILAKA BANDARA",
        // PostingDateTime: "01/08/22 08:22 AM",
        // SellToCusNo: "RDCST052602",
        // QuantityReturned: "0.00",
        // Selected: true,
        // ReturnQty: 12,
        // ReturnInvDocNo: "D2884/22/00103",
        // ReturnInvLineNo: "10000"
      }

      let lines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES);

      lines.data.push(newSaleLine)

      await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES, lines);

      await calculateReturnSalesHeaderTotal(newSaleLine, dispatch);

      dispatch({ type: FETCH_SALES_LINE, salesLine: { ...newSaleLine, added: true } });

      return newSaleLine;

    } else {
      var callResponse = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
        return res;
      }).catch((err) => { console.log(err); })

      let currentActiveCall = callResponse.records[callResponse.records.length - 1]

      var newSalesLine = salesLine;
      newSalesLine.ActiveCallNo = currentActiveCall.No
      newSalesLine.Sync = false

      return readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then(async (response) => {
        var existingLineItems = response.data.records.filter(function (lineItem) {
          return lineItem.DocNo == newSalesLine.DocNo;
        });

        response.data.records.push(newSalesLine);

        const res = await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE, response);

        dispatch({ type: FETCH_SALES_LINE, salesLine: { ...newSalesLine, added: true } });

        await calculateSalesHeaderTotal(newSalesLine);

        return newSalesLine;
      }).catch((err) => {
        console.log("err");
        console.log(err);
        return err;
      })
    }

  } else {
    return fromApi.add('SalesLine', salesLine).then(response => {
      const { data, error } = response;
      if (error) {
        dispatch({ type: FAILED_TO_ADD_SALES_LINE, salesLine: { ...salesLine, added: false } });
        return { error }
      }
      dispatch({ type: FETCH_SALES_LINE, salesLine: { ...data, added: true } });
      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const fetchSalesLines = filter => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {

    if (filter.DocType === "5") {

      let lines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES);

      var filterSalesLineData = lines.data.filter(function (el) {
        return el.DocNo.includes(filter.DocNo)
      });

      const salesLines = filterSalesLineData.map(item => ({ ...item, added: true }))

      dispatch({ type: FETCH_SALES_LINES, salesLines: salesLines });

      return filterSalesLineData;

    } else {
      var res = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then((res) => {
        return res;
      }).catch((err) => { console.log(err); })

      if (typeof (res) != "undefined") {

        var filterSalesLineData = res.data.records.filter(function (el) {
          return el.DocNo.includes(filter.DocNo)
        });

        const salesLines = filterSalesLineData.map(item => ({ ...item, added: true }))

        dispatch({ type: FETCH_SALES_LINES, salesLines: salesLines });
        return filterSalesLineData;
      }
    }
  } else {
    return fromApi.get('SalesLine', filter).then(response => {
      const { data, error } = response;

      if (error) return { error };

      const salesLines = data.map(item => ({ ...item, added: true }))

      dispatch({ type: FETCH_SALES_LINES, salesLines: salesLines });

      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const updateSalesLine = salesLine => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    // Return Order
    if (salesLine.DocType === "Return Order") {

      salesLine.Quantity = parseInt(String(salesLine.Quantity).replace(/,/g, '')).toString();
      salesLine.ReturnQty = parseInt(salesLine.Quantity).toString();
      salesLine.DisAmount = (parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity)).toFixed(2) * parseFloat(salesLine.DisPercentage).toFixed(2) / 100;

      salesLine.Amount = ((parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity)) - parseFloat(salesLine.DisAmount)).toFixed(2);

      salesLine.LineAmount = salesLine.Amount
      salesLine.AmountIncluVAT = salesLine.Amount

      let salesLines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES);

      salesLines.data = salesLines.data.map(line => {
        if (line.LineNo === salesLine.LineNo) {
          return salesLine
        }
        return line;
      });

      await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES, salesLines);

      await calculateReturnSalesHeaderTotal(salesLine, dispatch);

      dispatch({ type: FETCH_SALES_LINE, salesLine: { ...salesLine, added: true } });

      return salesLine;
    } else {
      return readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then(async (response) => {
        var lineItemsWithoutExistingLineItem = response.data.records.filter(function (lineItem) {
          return !((lineItem.LotNo == salesLine.LotNo) && (lineItem.DocNo == salesLine.DocNo));
        });

        lineItemsWithoutExistingLineItem.push(salesLine);

        var response = {
          data: {
            records: lineItemsWithoutExistingLineItem
          }
        };

        const res = await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE, response);
        dispatch({ type: FETCH_SALES_LINE, salesLine: { ...salesLine, added: true } });
        await calculateSalesHeaderTotal(salesLine);
        return salesLine;
      });
    }

  } else {
    return fromApi.update('SalesLine', salesLine).then(response => {
      const { data, error } = response;

      if (error) return { error };

      dispatch({ type: FETCH_SALES_LINE, salesLine: { ...data, added: true } });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const deleteSalesLine = (salesLine, dispactEvent = true) => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    // Return Order
    if (salesLine.DocType === "Return Order") {
      let salesLines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES);

      const filledSalesLines = salesLines.data.filter((line) => salesLine.LineNo !== line.LineNo);

      await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES, { data: filledSalesLines });

      await calculateReturnSalesHeaderTotal(salesLine, dispatch);

      if (dispactEvent) dispatch({ type: REMOVE_SALES_LINE, salesLine });
      return salesLine;

    } else {
      return readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then(async (response) => {
        var lineItemsWithoutExistingLineItem = response.data.records.filter(function (lineItem) {
          return !((lineItem.LotNo == salesLine.LotNo) && (lineItem.DocNo == salesLine.DocNo));
        });

        var response = {
          data: {
            records: lineItemsWithoutExistingLineItem
          }
        };

        await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE, response);
        await calculateSalesHeaderTotal(salesLine);
        if (dispactEvent) dispatch({ type: REMOVE_SALES_LINE, salesLine });
        return salesLine;
      });
    }

  } else {
    return fromApi.deleteObject('SalesLine', salesLine).then(response => {
      const { error } = response;

      if (error) return { error };

      dispatch({ type: REMOVE_SALES_LINE, salesLine });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

async function calculateSalesHeaderTotal(newSalesLine) {
  var offlineSalesHeadersResponse = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS);

  var currentSalesHeader = (offlineSalesHeadersResponse.data).filter(function (salesHeader) {
    return salesHeader.No == newSalesLine.DocNo;
  });

  currentSalesHeader = currentSalesHeader[0];

  var offlineSalesLinesAll = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE);

  var currentSalesHeaderSalesLines = (offlineSalesLinesAll.data.records).filter(function (salesLine) {
    return salesLine.DocNo == currentSalesHeader.No;
  });

  var total = 0;
  var grossAmount = 0;
  var disAmount = 0;
  currentSalesHeaderSalesLines.forEach(salesLine => {
    grossAmount += parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity);
    total += parseFloat(salesLine.LineAmount);
    disAmount += parseFloat(salesLine.DisAmount);
  });

  var otherSalesHeaders = (offlineSalesHeadersResponse.data).filter(function (salesHeader) {
    return salesHeader.No != newSalesLine.DocNo;
  });

  currentSalesHeader.Amount = total.toString();
  currentSalesHeader.AmountIncluVAT = total.toString();
  currentSalesHeader.DisAmount = disAmount.toString();

  const avgDisPercentage = ((disAmount / grossAmount) * 100).toFixed(2);
  currentSalesHeader.AvgDisPersentage = avgDisPercentage;

  otherSalesHeaders.push(currentSalesHeader);

  var response = {
    data: otherSalesHeaders,
    records: otherSalesHeaders
  };
  await insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS, response);
}

async function calculateReturnSalesHeaderTotal(newSalesLine, dispatch = () => { }) {
  let offlineSalesHeadersResponse = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS);

  let currentSalesHeader = (offlineSalesHeadersResponse.data).find(function (salesHeader) {
    return salesHeader.No === newSalesLine.DocNo;
  });

  let offlineSalesLinesAll = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES);

  let currentSalesHeaderSalesLines = (offlineSalesLinesAll.data).filter(function (salesLine) {
    return salesLine.DocNo === currentSalesHeader.No;
  });

  let total = 0;
  let grossAmount = 0;
  let disAmount = 0;
  currentSalesHeaderSalesLines.forEach(salesLine => {
    grossAmount += parseFloat(salesLine.UnitPrice) * parseFloat(salesLine.Quantity);
    total += parseFloat(salesLine.LineAmount);
    disAmount += parseFloat(String(salesLine.DisAmount).replace(/,/g, ''));
  });

  let otherSalesHeaders = (offlineSalesHeadersResponse.data).filter(function (salesHeader) {
    return salesHeader.No !== newSalesLine.DocNo;
  });

  currentSalesHeader.Amount = total.toFixed(2).toString();
  currentSalesHeader.AmountIncluVAT = total.toFixed(2).toString();
  currentSalesHeader.DisAmount = isNaN(disAmount) ? '0.00' : disAmount.toFixed(2).toString();

  const avgDisPercentage = ((disAmount / grossAmount) * 100).toFixed(2);
  currentSalesHeader.AvgDisPersentage = isNaN(avgDisPercentage) ? '0.00' : avgDisPercentage;

  otherSalesHeaders.push(currentSalesHeader);

  await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS, { data: otherSalesHeaders });

  dispatch({ type: FETCH_SALES_HEADER, salesHeader: currentSalesHeader });

}
