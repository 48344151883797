
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchCustomers, fetchCustomerCategories } from "../../redux/actions/customers";
import { fetchSalesHeaders, fetchSalesCreditMemoReasons } from "../../redux/actions/salesHeaders";
import { getSalesHeadersByType, getSalesHeadersByTypeAndCustomer, getAuthData, getLoggedInUser, DOC_TYPE_ID_BY_DEFINITION, getTopPosition, getFlatPositions, getSalesHeadersBySearchKey, getSalesHeaderTotal, isFetchingSalesHeaders, getSalesHeaderMetadata, isCallInProgress, getInProgressCall } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import OrderTable from "./OrderTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { withToastManager } from 'react-toast-notifications';
import { CREDIT_NOTES, SALES_INVOICES, SALES_ORDERS, SALES_RETURNS } from "../../const/Permissions";
// import customers, { getCustomer } from '../../redux/reducers/customers';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import { FaPlus } from 'react-icons/fa';
import { fetchWarehouseLocations } from '../../redux/actions/warehouses';
import Api from '../../api/consts';
import { generateGeneric } from '../../api/reports';
import { fetchFailedSalesHeadersFromLocalAPI } from '../../redux/actions/failedOrdedrs';

const salesOrderStatuses = {
  Open: 0,
  'Customer Confirmed': 2
};

class Orders extends Component {
  baseUrl = window.location.pathname;
  state = {
    loading: false,
    salesOrders: [],
    checkedAll: false,
    selectedSalesOrders: [],
    filter: {
      DocType: undefined,
      UserCode: undefined,
      RouteCode: undefined,
      OutletCategoryCode: undefined,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    },
    currentPage: 1,
    isLoadingSummary: false,
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchCustomerCategories, fetchCustomers, fetchSalesCreditMemoReasons } = this.props;
    const { customerId, docType } = match.params

    fetchCustomerCategories();
    fetchCustomers({ UserCode: userId });

    if (docType === 'CreditMemo') {
      fetchSalesCreditMemoReasons();
    }

    this.setState({
      filter: {
        ...this.state.filter,
        DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
        UserCode: userId,
        CustNo: customerId,
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { customerId, distributorId, docType } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadOrders(nextProps);
      });
    }, { ...this.state.filter, CustNo: customerId, DistributorCode: distributorId, DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  loadOrders = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const { fetchFailedSalesHeadersFromLocalAPI } = this.props;
    const { filter: { from, to } } = this.state;
    fetchFailedSalesHeadersFromLocalAPI({ from: from.format('MM/DD/YY'), to: to.format('MM/DD/YY') });
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
      window.addEventListener('locationchange', this.onUrlChange);
      // this.setState({ filteredOrders: this.props.orders });
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  fetchOrderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchOrders(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  fetchOrders = async (filter, page, searchKey) => {
    const { authData: { distributorCSR } } = this.props;
    const updatedFilter = {
      ...filter,
      OrderDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      CustNo: undefined,
      SellToCusNo: filter.CustNo,
      UserCode: undefined,
      text: undefined,
      No: filter.text ? filter.text : undefined,
      // No: filter.text ? '*' + filter.text + '*' : undefined,
      OrderStatus: salesOrderStatuses[filter['Status']],
      // Status: undefined,
      // SOType: undefined,
      // DiscountReason: undefined,
      DistributorCSR: distributorCSR
    }
    await this.props.fetchSalesHeaders(updatedFilter, page, searchKey);
    this.setSalesOrders();
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing orders.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
      case 'RouteCode':
        newFilter.CustNo = undefined;
        break;
      case 'OutletCategoryCode':
        newFilter.OutletSubCategoryCode = undefined;
    }

    newFilter.StatusFilter = newFilter.Status ? 'Yes' : 'No';

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No, CustNo: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }

  getTitle = () => {
    const { docType } = this.props.match.params;
    let type = '';
    if (docType === 'CreditMemo') {
      type = 'Credit Notes';
    } else if (docType === 'ReturnOrder') {
      type = 'Sales Returns';
    } else {
      type = 'Sales ' + docType + 's';
    }

    return type;
  }

  getPermission = (docType, type, permissions) => {

    let enabled = false;
    if (type === 'CREATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.CREATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CREATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CREATE)
      }
    }

    return enabled;
  }

  setSalesOrders = async () => {
    this.setState({ checkedAll: false });
    var allSalesOrders = this.props.orders;
    allSalesOrders = allSalesOrders.map(order => {
      this.state.selectedSalesOrders.forEach(selectOrder => {
        if (order.No === selectOrder.No) {
          order.isChecked = true;
        }
      })
      return order.isChecked ? order : { ...order, isChecked: false };
    })
    await this.setState({ salesOrders: allSalesOrders });
    if (allSalesOrders.find(order => order.isChecked === false)) {
      this.setState({ checkedAll: false });
    } else {
      this.setState({ checkedAll: true });
    }
  }

  handleAllChecked = async (event) => {
    this.setState({ checkedAll: event.target.checked });
    if (event.target.checked) {
      let newOrders = [...this.state.selectedSalesOrders];
      this.state.salesOrders.forEach(order => {
        let exsistedSelectedOrder = this.state.selectedSalesOrders.find(s_order => s_order.No === order.No);
        if (!exsistedSelectedOrder) {
          newOrders.push({ ...order, isChecked: true })
        }
      });
      await this.setState({ selectedSalesOrders: newOrders });
      await this.setState({ salesOrders: this.state.salesOrders.map(order => ({ ...order, isChecked: true })) })
    } else {
      var newOrders = [];
      this.state.salesOrders.forEach(order => {
        this.state.selectedSalesOrders.forEach(s_order => {
          if (s_order.No === order.No) {
            s_order.isChecked = false;
            newOrders.push(s_order);
          } else {
            newOrders.push(s_order)
          }
        });
      });

      //remove duplicates from 'newOrders' array
      var result = [];
      newOrders.forEach(function (item) {
        if (result.indexOf(item) < 0) {
          result.push(item);
        }
      });

      await this.setState({ selectedSalesOrders: result.filter(o => o.isChecked) });
      await this.setState({ salesOrders: this.state.salesOrders.map(order => ({ ...order, isChecked: false })) })
    }
  };

  handleOnChange = async (event, No) => {
    this.setState({ checkedAll: false });
    var salesOrders = this.state.salesOrders.map(order => {
      if (order.No === No) {
        return { ...order, isChecked: event.target.checked };
      }
      return order;
    });
    await this.setState({ salesOrders: salesOrders });
    await this.setSelectedSalesOrder(salesOrders.find(order => order.No === No))
    this.checking();
  }

  checking = () => {
    if (!this.state.salesOrders.find(order => order.isChecked === false)) {
      this.setState({ checkedAll: true });
    }
  }

  setSelectedSalesOrder = async (selectedOrder) => {
    if (this.state.selectedSalesOrders.length === 0) {
      if (selectedOrder.isChecked === true) {
        await this.setState({ selectedSalesOrders: [selectedOrder] })
      }
    } else {
      var newSelectedOrders = [];
      let exsistedSelectedOrder = this.state.selectedSalesOrders.find(order => order.No === selectedOrder.No);
      if (exsistedSelectedOrder) {
        newSelectedOrders = this.state.selectedSalesOrders.filter(order => order.No !== selectedOrder.No)
      } else {
        if (selectedOrder.isChecked) {
          newSelectedOrders = [...this.state.selectedSalesOrders, selectedOrder]
        }
      }
      await this.setState({ selectedSalesOrders: newSelectedOrders })
    }
  }

  handleGeneratePickList = async () => {
    const { authData: { userId, displayName, username }, toastManager } = this.props;
    var orderNOs = "";

    if (this.state.selectedSalesOrders.length > 0) {
      this.state.selectedSalesOrders.forEach(order => {
        orderNOs = orderNOs + "|" + order.No
      });

      orderNOs = orderNOs.substring(1);

      const reportRequest = {
        DistributorCode: userId,
        DistributorName: displayName,
        LocCode: "",
        ExcelReport: 0,
        UserCode: userId,
        OrderFilterText: orderNOs,
      };
      this.setState({ isLoadingSummary: true });

      generateGeneric(reportRequest, 'loadingSummary').then(response => {
        if (!response.error) {
          setTimeout(() => {
            window.open(Api.files.download.url(response.url), '_blank');
            this.setState({ isLoadingSummary: false });
          }, 10000);
        } else {
          toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
          this.setState({ isLoadingSummary: false });
        }
      })
    } else {
      toastManager.add('Please select Sales Order/Orders first. ', { autoDismiss: true, appearance: 'error' });
    }
  }

  render() {
    const { filter, currentPage, isLoadingSummary } = this.state;
    const { loading, loggedInUser: { permissions = [] }, match, orders, total, totalAmount, topPosition } = this.props;
    const { ongoingCall, inCall } = this.props;
    const { CustNo, StartReasonCode, StartReasonDescrip } = ongoingCall || {};
    const { customerId, docType } = match.params;
    const { CustStatus } = orders;
    const { checkedAll, salesOrders } = this.state;

    const enableCreate = this.getPermission(docType, 'CREATE', permissions);

    return (
      <div>
        <PromatePageHeader
          title={this.getTitle()}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={enableCreate && ((inCall && StartReasonDescrip !== 'Customer Creation') || customerId) && [
            <CallStatusValidateButton onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </CallStatusValidateButton>,
          ]}>

          <Spin spinning={(loading && orders.length === 0) || !topPosition} tip={"Loading..."}>
            <OrderTable
              orders={salesOrders}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              setDefaultCustomerOption={this.setDefaultCustomerOption}
              setDefaultRouteOption={this.setDefaultRouteOption}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount}
              checkedAll={checkedAll}
              handleAllChecked={this.handleAllChecked}
              handleOnChange={this.handleOnChange}
              handleGeneratePickList={this.handleGeneratePickList}
              isLoadingSummary={isLoadingSummary}
            />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { ongoingCall, inCall, match } = this.props;
    const { customerId } = match.params
    const { CustNo } = ongoingCall || {};
    if (inCall && !customerId) {
      this.props.history.push(`/customers/${CustNo}/sales/${match.params.docType}/new`);
    } else {
      this.props.history.push(this.props.match.url + "/new");
    }
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

Orders.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const searchKey = getSearchKey();

  return {
    orders: getSalesHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getSalesHeaderTotal(state, searchKey),
    totalAmount: getSalesHeaderMetadata(state, searchKey).TotalAmountIncluVAT,
    loading: isFetchingSalesHeaders(state),
    inCall: isCallInProgress(state),
    ongoingCall: getInProgressCall(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchSalesHeaders, fetchCustomers, fetchCustomerCategories, fetchSalesCreditMemoReasons, fetchWarehouseLocations, fetchFailedSalesHeadersFromLocalAPI })(Orders));