import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Affix, Menu, Dropdown, Icon, Modal } from 'antd';
import { ProMateGreen } from '../../const/Theme';
import { withRouter } from 'react-router-dom';
import { outCall, preparingCall, logOut } from '../../redux/actions/auth'
import { getAuthData, isAuthenticated, isCallInProgress, getInProgressCall, getNetworkModeDetector } from '../../redux/reducers';
import { FaEllipsisV, FaPhoneAlt, FaPhoneSlash, FaRegUserCircle } from "react-icons/fa";
import { fetchActiveCall } from '../../redux/actions/calls';
import { FiPhoneMissed, FiPhoneForwarded } from "react-icons/fi"
import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../localStorage';

function SalesRepCallHandler(props) {
  const [top, setIsModalVisible] = useState(60);
  const { authData: { userId, roleCode, displayName }, outCall, preparingCall, authenticated, inCall, ongoingCall, history, screenType, logOut, displayNetworkDetector, fetchActiveCall, match, showProfileIcon, showHomeIcon, isSystemOffline } = props;
  //console.log(match)
  const { customerId } = match.params;
  const urlSerch = customerId ? '?customer_id=' + customerId : '';
  useEffect(() => {
    fetchActiveCall(userId);
  }, []);

  const hideEndCallBtn = ongoingCall && window.location.pathname === '/calls/' + ongoingCall.No;
  const hideStartCallBtn = window.location.pathname === "/salesRepresentatives/" + userId + '/calls/new' + urlSerch;

  let callAction = <div></div>

  const rightPadding = props.location.pathname === '/' ? 60 : (screenType === 'DESKTOP' ? 10 : 100)
  const { confirm } = Modal;

  function checkOfflineModeBeforeLogin(isSystemOffline) {

    if (isSystemOffline) {
      setIsModalVisible(true);
      confirm({
        title: "Log Out!",
        content: "Please switch to online mode before logging out. Or you will lose all offline data.",
        maskClosable: false,
        onOk() {
          setIsModalVisible(false);
        },
        onCancel() {
          setIsModalVisible(true);
        },
      });
    } else {
      logOut();
    }
  }

  if (screenType === 'DESKTOP') {
    if (roleCode === 'CSR') {
      if (inCall) {
        callAction = <div>
          <Button
            onClick={() => {
              history.push("/calls/" + ongoingCall.No)
            }}
            style={{ ...styles.callButton, backgroundColor: '#ff8100' }}
          >
            <div style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>
              <span>Ongoing Call<br /> {ongoingCall.CustName || ongoingCall.StartReasonDescrip}</span><br />
            </div>
          </Button>
        </div>
      } else {
        callAction = <Button
          onClick={() => {
            history.push("/salesRepresentatives/" + userId + "/calls/new" + urlSerch)
          }}
          style={{ ...styles.callButton }}
        >Start Call</Button>
      }
    }
  } else {
    if (inCall) {
      callAction = <Menu>
        <Menu.Item style={{ marginTop: 15 }} disabled>{displayName}</Menu.Item>
        {roleCode === 'CSR' && <Menu.Item
          onClick={() => {
            history.push("/calls/" + ongoingCall.No)
          }}
        >
          <div>
            <div style={{ fontWeight: 'bold', fontSize: 'smaller' }}>
              <span>Ongoing Call<br /> {ongoingCall.CustName || ongoingCall.StartReasonDescrip}</span><br />
            </div>
          </div>
        </Menu.Item>}
        {!showHomeIcon && <Menu.Item onClick={() => checkOfflineModeBeforeLogin(isSystemOffline)}>Log Out</Menu.Item>}
        {showHomeIcon && <Menu.Item onClick={() => history.push('/')}>Goto Home</Menu.Item>}
      </Menu>
    } else {
      callAction = <Menu onClick={preparingCall}>
        <Menu.Item disabled style={{ marginTop: 15 }}>{displayName}</Menu.Item>
        {roleCode === 'CSR' && <Menu.Item
          onClick={() => {
            history.push("/salesRepresentatives/" + userId + "/calls/new" + urlSerch)
          }}
        >Start Call</Menu.Item>}
        {!showHomeIcon && <Menu.Item onClick={() => checkOfflineModeBeforeLogin(isSystemOffline)}>Log Out</Menu.Item>}
        {showHomeIcon && <Menu.Item onClick={() => history.push('/')}>Goto Home</Menu.Item>}

      </Menu>
    }
  }

  if (authenticated && !hideStartCallBtn && !hideEndCallBtn) {
    return screenType === 'DESKTOP' ?
      <div className="callBtn">{callAction}</div> :
      <div style={{ display: 'flex', alignItems: 'center' }}>

        {/* call Icons */}

        {inCall && roleCode === 'CSR' &&
          <FiPhoneMissed
            style={{ color: '#ff8100', fontSize: 25, marginRight: 10, display: 'flex', alignItems: 'center' }}
            onClick={() => {
              history.push("/calls/" + ongoingCall.No)
            }}

          >
          </FiPhoneMissed>
        }

        {!inCall && roleCode === 'CSR' &&
          <FiPhoneForwarded
            style={{ color: 'white', fontSize: 25, marginRight: 10, display: 'flex', alignItems: 'center' }}
            onClick={() => {
              history.push("/salesRepresentatives/" + userId + "/calls/new" + urlSerch)
            }}

          >Start Call</FiPhoneForwarded>
        }

        {/* profile drowpdown */}

        {showProfileIcon && <Dropdown overlay={callAction} trigger='click'>
          <a style={{ color: 'white', fontSize: 25, display: 'flex', alignItems: 'center' }}>
            <FaRegUserCircle />
          </a>
        </Dropdown>}
      </div>
  } else return <div></div>
}

const mapStateToProps = state => {
  const authData = getAuthData(state)
  const authenticated = isAuthenticated(state)
  const inCall = isCallInProgress(state)
  const ongoingCall = getInProgressCall(state);
  const isSystemOffline = getNetworkModeDetector(state)

  return { authData, authenticated, inCall, ongoingCall, isSystemOffline }
}

export default withRouter(connect(mapStateToProps, { outCall, preparingCall, logOut, fetchActiveCall })(SalesRepCallHandler));

const styles = {
  callButton: {
    cursor: 'pointer',
    //right: 15,
    color: 'white',
    backgroundColor: ProMateGreen,
    //position: 'absolute',
    height: 40
  },
  callIcon: {
    cursor: 'pointer',
    right: 48,
    color: 'white',
    backgroundColor: ProMateGreen,
    position: 'absolute',
    height: 50
  }
};