import React, { Component } from 'react';
import { Form, Icon, Button } from 'antd';
import { withToastManager } from 'react-toast-notifications';
import { sendPasswordResetOTP } from '../api/auth';
import { ProMateGreen } from '../const/Theme';
import packageJson from '../../package.json';
import Input from '../components/common/EnglishInput';

class OTPRequestPage extends Component {
  state = {
    submitting: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, toastManager } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        sendPasswordResetOTP(values).then(response => {
          this.setState({ submitting: false });
          if (response.status === "") {
            this.props.history.push('/resetPassword')
          } else {
            toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;

    return (
      <div style={styles.loginPage}>
        <Form onSubmit={this.handleSubmit} style={styles.loginForm}>
          <Form.Item>
            <img alt='ProMate Logo' src='/logo.png' style={styles.logo} />
            <div style={styles.loginFormVersion}>v{packageJson.version}</div>
          </Form.Item>
          <p style={styles.infoText}>Enter your username and we'll send you a security code to your mobile.<br /> Use that code to reset your password.<br />If you do not receive the security code, please contact PrinXcel.</p>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input
                prefix={<Icon type="user" style={styles.textInput} />}
                placeholder="Username"
              />,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={styles.loginFormButton} loading={submitting}>
              Reset my password
            </Button>
            <Button type="link" style={styles.loginFormForgot} onClick={() => this.props.history.push('/login')}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withToastManager(Form.create({ name: 'OTPRequestPage' })(OTPRequestPage));

const styles = {
  loginPage: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginForm: {
    width: '300px',
  },
  loginFormReset: {
    float: 'right',
  },
  loginFormButton: {
    width: '100%',
    backgroundColor: ProMateGreen,
  },
  logo: {
    width: 300,
    padding: 20
  },
  textInput: {
    color: 'rgba(0,0,0,.25)'
  },
  loginFormForgot: {
    float: 'right',
  },
  loginFormVersion: {
    width: '300px',
    textAlign: 'center',
    color: '#777',
    marginTop: -20,
  },
  infoText: {
    textAlign: 'center',
    fontSize: 'small',
    fontWeight: 800,
    color: 'darkgray'
  }
};