import { Col, Input, Row, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { ADD_ROUTE_TARGET } from '../../../../../const/Permissions';
import { updateRouteTarget } from '../../../../../redux/actions/targetScheduler';
import { getAuthData, getFilteredRouteTargets, getSalesUserHierarchy } from "../../../../../redux/reducers";
import { StyledButton } from '../../../common/Buttons';
import { trimDecimals, withOutThousandSeperator, withThousandSeperator } from '../../../common/helpers';

const { Text } = Typography;
class DataTable extends Component {
    state = {
        pagination: {
            pageSize: 16
        }
    };

    columns = [
        {
            title: 'Route Code',
            width: 30,
            dataIndex: 'RouteCode',
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Route Name',
            width: 150,
            dataIndex: 'RouteName',
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Total Outlets',
            dataIndex: 'TotOutlets',
            width: 25,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Productive Outlets',
            dataIndex: 'PordOutlets',
            width: 30,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Last 3 Months Avg. Sales',
            dataIndex: 'LastThreeMonthAvgSales',
            width: 40,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Last Month TGT',
            dataIndex: 'LastMonthTgt',
            width: 30,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: '1/2X Outlet Total Sale',
            dataIndex: 'HalfXOutletSales',
            width: 30,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: '1/4X Outlet Total Sale',
            dataIndex: 'QuaXOutletSales',
            width: 30,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Planned Target',
            dataIndex: 'PlannedTgt',
            width: 40,
            render: (record, row) => this.props.permissions.includes(ADD_ROUTE_TARGET.UPDATE) ? <Input type='number' placeholder="Planned Target" value={record} onChange={(event) => this.handleInputChange(event.target.value, row)} style={{ fontSize: 12 }}/> : <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Target Growth in Value',
            dataIndex: 'TargetGrowthVal',
            width: 30,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Target Growth in %',
            dataIndex: 'TargetGrowthPre',
            width: 30,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            key: 'save',
            width: 50,
            render: (record) => this.props.permissions.includes(ADD_ROUTE_TARGET.UPDATE) && <StyledButton label="Save" color="#1890ff" onClick={() => this.onSave(record)} />,
        },
    ];

    renderFooter = () => {
        const TotOutlets = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['TotOutlets']) == '' ? 0 : withOutThousandSeperator(currentValue['TotOutlets']))), 0);
        const PordOutlets = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['PordOutlets']) == '' ? 0 : withOutThousandSeperator(currentValue['PordOutlets']))), 0);
        const LastThreeMonthAvgSales = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['LastThreeMonthAvgSales']) == '' ? 0 : withOutThousandSeperator(currentValue['LastThreeMonthAvgSales']))), 0);
        const LastMonthTgt = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['LastMonthTgt']) == '' ? 0 : withOutThousandSeperator(currentValue['LastMonthTgt']))), 0);
        const HalfXOutletSales = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['HalfXOutletSales']) == '' ? 0 : withOutThousandSeperator(currentValue['HalfXOutletSales']))), 0);
        const QuaXOutletSales = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['QuaXOutletSales']) == '' ? 0 : withOutThousandSeperator(currentValue['QuaXOutletSales']))), 0);
        const PlannedTgt = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['PlannedTgt']) == '' ? 0 : withOutThousandSeperator(currentValue['PlannedTgt']))), 0);
        const TargetGrowthVal = (((Number(PlannedTgt) - Number(LastMonthTgt)) / ((Number(LastMonthTgt) == 0) ? 1 : Number(LastMonthTgt))) * 100).toFixed(2);
        const TargetGrowthPre = (Number(PlannedTgt) - Number(LastMonthTgt)).toFixed(2)

        return (
            <div style={{ background: "#e1e1e1", padding: "5px" }}>
                <Row>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Records: <span style={{ fontWeight: "bold", fontSize: 12 }}>{this.props.tableData.recordTotal ?? 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Outlets: <span style={{ fontWeight: "bold", fontSize: 12 }}>{TotOutlets}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Productive Outlets: <span style={{ fontWeight: "bold", fontSize: 12 }}>{PordOutlets}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Last 3 Months Avg. Sales: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(LastThreeMonthAvgSales))}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Last Month Target: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(LastMonthTgt.toFixed(2)))}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total 1/2X Outlet Total Sale: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(HalfXOutletSales.toFixed(2)))}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total 1/4X Outlet Total Sale: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(QuaXOutletSales.toFixed(2)))}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Planned Target: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(PlannedTgt.toFixed(2)))}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Target Growth in Value: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(TargetGrowthVal))}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Target Growth in %: <span style={{ fontWeight: "bold", fontSize: 12 }}>{(trimDecimals(TargetGrowthPre))}</span></span>
                    </Col>
                </Row>
            </div>
        );
    }

    handleInputChange = (value, row) => {
        const updatedTableData = this.props.tableData.records.map((record) => {
            if (record.RouteCode == row.RouteCode) {
                record.PlannedTgt = value;

                const lastMonthTgt = withOutThousandSeperator(record.LastMonthTgt);

                const updatedTargetGrowthPre = ((Number(value) - Number(lastMonthTgt)) / (Number(lastMonthTgt) == "0.00" ? 1 : Number(lastMonthTgt))) * 100
                record.TargetGrowthPre = updatedTargetGrowthPre.toFixed(2);

                const updatedTargetGrowthVal = (Number(value) - Number(lastMonthTgt)).toFixed(2);
                record.TargetGrowthVal = withThousandSeperator(updatedTargetGrowthVal);
            }
            return record;
        });
        this.props.setTableData({ ...this.props.tableData, records: updatedTableData });
    }

    onSave = async (record) => {
        const { toastManager } = this.props;
        this.props.setLoading(true);
        const updatedData = await this.props.updateRouteTarget(record);
        if (!updatedData.error) {
            toastManager.add('Planned target was successfully updated.', { autoDismiss: true, appearance: 'success' });
        }
        this.props.setLoading(false);
    }

    render() {
        return (
            <div style={{ padding: 10, marginTop: 5 }}>
                <Table
                    size='small'
                    columns={this.columns}
                    rowKey={record => record.RouteCode}
                    dataSource={this.props.tableData.records}
                    pagination={this.state.pagination}
                    loading={this.props.loading}
                    footer={this.renderFooter}
                    scroll={{ x: 1300 }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    routeTargets: getFilteredRouteTargets(state),
})

const mapDispatchToProps = {
    updateRouteTarget
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(DataTable));

const styles = {
    text: {
        color: '#000000',
        textAlign: "left",
        fontWeight: "bold"
    }
}