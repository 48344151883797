import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper'
import { Card, Button, Radio } from "antd";
import withRouter from "react-router/es/withRouter";

class Pad extends Component {

  componentDidMount = () => {
    this.signaturePad = undefined;
  }

  clear = () => {
    this.signaturePad.clear()
  }

  isSignatureEnabled = () => {
    const { orderConfirmationType } = this.props;
    return orderConfirmationType === "Customer" || orderConfirmationType === "Representative";
  }
  render() {
    const { onConfirmOrder, orderConfirmationType, onConfirmationTypeChange } = this.props;
    const enableSignature = this.isSignatureEnabled();
    if (this.signaturePad) {
      this.signaturePad.penColor = 'rgb(66, 133, 244)';
    }

    return (
      <div>
        {/* <div style={{margin: 10}}>
          <Radio.Group buttonStyle="solid" defaultValue={orderConfirmationType} onChange={event => onConfirmationTypeChange(event.target.value)}>
            <Radio.Button value={"Customer"} style={styles.radioButton}>Customer</Radio.Button>
            <Radio.Button value={"Representative"} style={styles.radioButton}>Representative</Radio.Button>
            <Radio.Button value={"OTPO"} style={styles.radioButton}>OTPO</Radio.Button>
          </Radio.Group>
        </div> */}

        {enableSignature && <Card
          style={{ margin: 10, height: 500 }}
          title={<h6 style={{ fontSize: 'medium', margin: 10 }}>{orderConfirmationType === "Customer" ? "Customer" : "Representative"} Signature</h6>}>
          <SignaturePad height={500} ref={ref => this.signaturePad = ref} />
        </Card>}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', float: 'right' }}>
          <Button style={{ marginRight: 8 }} onClick={() => this.props.history.goBack(-1)}>Cancel</Button>
          {enableSignature && <Button style={{ marginRight: 8 }} onClick={this.clear}>Clear</Button>}
          {(orderConfirmationType || enableSignature) && <Button style={{ marginRight: 8 }} type="primary" onClick={() => onConfirmOrder(enableSignature ? this.signaturePad.toDataURL("image/png") : null, enableSignature ? this.signaturePad.isEmpty() : null)}>Confirm</Button>}
        </div>

      </div>
    )
  }
}

const styles = {
  radioButton: {
    textAlign: 'center',
    width: '32.2vw'
  }
}

export default withRouter(Pad);