import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, deleteOrder } from "../../../redux/actions/orders";
import { fetchInventory } from "../../../redux/actions/inventory";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import OrderHeader from './Header';
import OrderDetailTable from './SalesLineTable';
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { currentLocation } from '../../../utils/Location';
import { getDemoOrder, generateSalesLines } from '../dummyOrders'
import { getAuthData } from '../../../redux/reducers';
import { getDemoCreditNotes } from '../../CreditNotes/dummyCreditNotes'
import { getDemoGRNs } from '../../GRN/dummyGRNs'
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

export const defaultOrder = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  orderDate: moment(),
  orderNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "Open",
  sessionId: uuid(),
  discountPercentage: 0.0,
}

class Container extends Component {
  state = {
    loading: false,
    order: { ...defaultOrder }
  }

  componentDidMount() {
    getDemoCreditNotes()
    getDemoGRNs()
    //generateDemoOrders()
    const { id } = this.props.match.params
    const newOrder = id === 'new';

    if (newOrder) {
      this.setState({ order: { ...defaultOrder } })
    } else {
      // this.setState({ loading: true })
      // this.props.fetchOrder(orderId).then(() => {
      //   this.setState({ loading: false })
      // })
      this.setState({ order: getDemoOrder(id) })
    }

  }

  componentWillReceiveProps = nextProps => {
    const { match } = nextProps;
    const { id } = match.params

    this.setState({ order: getDemoOrder(id) })
  }

  calculateTotalAmount = () => {
    const { order = {} } = this.props;
    const { salesLines = [] } = order;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  submitOrder = () => {
    const { addOrder, toastManager } = this.props;

    this.setState({ loading: true })

    const { order } = this.state;

    order.mapLocation = currentLocation;

    setTimeout(() => {
      this.setState({ loading: false })
      toastManager.add('Successfully created a new sales return.', { autoDismiss: true, appearance: 'success' });
      this.props.history.replace('/salesReturns/' + "return 6")
    }, 2000)
    // addOrder(order).then(response => {
    //   this.setState({ loading: false })
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
    //     defaultOrder.sessionId = uuid();
    //     this.props.history.replace('/orders/' + response.order.orderNo)
    //   } else {
    //     toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
    //   }
    // })
  }

  shareOrder = () => {
    const { order } = this.state;
    const reportLink = order && order.links && order.links.length > 0 && order.links.find(link => link.includes('order-report'))
    // reportLink && window.open(Api.server+"/files/"+reportLink, '_blank');
    window.open('/demo_report.pdf', '_blank');

    // const { orderId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // printContainer.style.display = 'inline';
    // this.setState({ loading: true });
    // html2canvas(document.querySelector("#orderPrintDiv"), { width: 685 }).then((canvas) => {

    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = orderId + '.png';
    //   a.click();

    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventory = () => {
    const { id } = this.props.match.params
    const order = id === 'new' ? { orderNo: 'new', discountPercentage: this.state.order.discountPercentage } : this.state.order
    this.props.history.push({ pathname: '/inventory', state: { resource: "salesReturns", order, id: id } })
  }

  navigateToCreditNote = () => {
    const { id } = this.props.match.params

    this.props.history.push({ pathname: '/salesReturns/' + id + '/creditNotes/CN 0', state: { order: this.state.order } })
  }

  navigateToGRN = () => {
    const { id } = this.props.match.params

    this.props.history.push({ pathname: '/salesReturns/' + id + '/grns/GRN 0', state: { order: this.state.order } })
  }

  navigateToConfirmOrder = () => {
    const { id } = this.props.match.params

    this.props.history.push({ pathname: '/salesReturns/' + id + '/confirm', state: { order: this.state.order } })
  }

  onDeleteOrder = () => {
    const { deleteOrder, toastManager, history, fetchInventory } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      toastManager.add('Sales return successfully deleted.', { autoDismiss: true, appearance: 'success' });
      fetchInventory();
      //history.goBack();
    }, 2000)
    history.goBack();
    // deleteOrder(this.state.order).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete order. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Order successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     fetchInventory();
    //     history.goBack();
    //   }
    // });
  }

  onConfirmApproved = () => {
    const { toastManager } = this.props;
    toastManager.add('Sales return approved successfully.', { autoDismiss: true, appearance: 'success' });
    this.props.history.goBack();
  }

  onInputChange = (field, value, errorKey) => {
    const { id } = this.props.match.params
    if (field === "discountPercentage") {
      var percentage = value;
      var totalWithoutDiscount = this.state.order.totalWithoutDiscount;
      var discountAmount = (totalWithoutDiscount * percentage) / 100;
      var totalAmount = totalWithoutDiscount - discountAmount;
      this.setState({
        order: {
          ...this.state.order,
          [field]: value,
          totalDiscountAmount: discountAmount,
          totalWithoutDiscount: totalWithoutDiscount,
          totalAmount: totalAmount,
          salesLines: generateSalesLines(id, percentage, this.state.order.salesLines.length)
        },
        [errorKey]: undefined
      });
    }
  }

  renderActions = () => {
    const { match, authData: { roleCode } } = this.props;
    const { salesLines, status } = this.state.order;
    const { id } = match.params
    const newOrder = id === 'new';

    if (newOrder) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.submitOrder}
        >
          <CallStatusValidateButton type="primary">Create</CallStatusValidateButton>
        </Popconfirm>
      </Col></Row>
    } else {

      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status != 'Confirmed' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteOrder}
            >
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Customer Confirmed' && roleCode === "DISTRIBUTOR" &&
          <Col>
            <Popconfirm
              title="Are you sure ?"
              okText="Ok"
              cancelText="No"
              onConfirm={this.onConfirmApproved}
            >
              <CallStatusValidateButton type="primary">Approve</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {
          status === 'Confirmed' &&
          <Col>
            <CallStatusValidateButton type="primary" onClick={this.navigateToCreditNote}>Generate Credit Note</CallStatusValidateButton>
          </Col>
        }
        {
          status === 'Confirmed' &&
          <Col style={{ paddingLeft: -7.5 }}>
            <CallStatusValidateButton type="primary" onClick={this.navigateToGRN}>Generate GRN</CallStatusValidateButton>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmOrder}>Confirm</CallStatusValidateButton>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareOrder}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row >
    }
  }

  render() {
    const { customers, match } = this.props;
    const { id } = match.params
    const newOrder = id === 'new';
    const { loading, order } = this.state;

    if (!order) return null;

    return (
      <Spin spinning={loading} tip={"Loading Sales Returns"}>
        <Row style={{ height: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          <OrderHeader
            order={order}
            customers={customers}
            newOrder={newOrder}
            onSubmitOrder={this.submitOrder}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirmOrder}
            onDeleteOrder={this.onDeleteOrder}
            onInputChange={this.onInputChange} />
          <div style={{ padding: '0 12px' }}>
            <OrderDetailTable order={order} scrollHeight={this.getScrollAreaHeight()}
              OnClickAddItem={this.navigateToInventory} />
          </div>
          <OrderFooter order={order} />
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params

  return { order: getDemoOrder(id), authData: getAuthData(state) };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, deleteOrder, fetchInventory })(Container)));