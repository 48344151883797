import React, { Component } from 'react';
import { Table, Button } from "antd";
import { format } from "currency-formatter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class SalesLineTable extends Component {

  renderColumns = () => {

    return [{
      title: 'Code',
      dataIndex: 'itemNo',
      key: 'itemNo',
      width: 60
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    }, {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      fixed: 'right',
      align: 'right',
      width: 60
    }, {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 80,
      fixed: 'right',
      align: 'right',
      render: unitPrice => format(unitPrice, currencyFormat)
    }, {
      title: 'Discount',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      width: 100,
      fixed: 'right',
      align: 'right',
      render: (discountAmount, rowData) => `${format(discountAmount, currencyFormat)} (${rowData.discountPercentage}%)`
    }, {
      title: 'Total',
      dataIndex: 'lineAmount',
      key: 'lineAmount',
      width: 80,
      fixed: 'right',
      align: 'right',
      render: total => format(total, currencyFormat)
    }]
  }

  renderFooter = () => {
    return <div style={{ display: 'flex', justifyContent: 'flex-end' }}><Button onClick={this.props.OnClickAddItem} type="primary" size="small">Add Item</Button></div>
  }


  render() {
    const { invoice } = this.props;

    return (
      <Table
        rowKey={'lineNo'}
        columns={this.renderColumns()}
        dataSource={invoice.salesLines}
        size={'small'}
        pagination={false}
        scroll={{ x: 800 }} />
    )
  }
}

export default SalesLineTable;