import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { withRouter } from 'react-router-dom';

class ExpenseCategoryCardHeader extends Component {
  getStatusColor = status => {
    if (status) return 'red';
    else return 'green';
  }

  render() {

    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, marginRight: 15, backgroundColor: '#eceaea', padding: 10 }}>
          <Col xs={12} sm={12} md={6} lg={6}>Category Name</Col>
          <Col xs={12} sm={12} md={6} lg={6}>Discription</Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  }

}
export default withRouter(ExpenseCategoryCardHeader);