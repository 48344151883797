import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class WarehouseView extends Component {
  state = {
    editWarehouse: false
  }

  render() {
    const { warehouseId } = this.props.match.params
    const { editWarehouse } = this.state
    const newWarehouse = warehouseId === 'new';

    return (
      <div>
        <PromatePageHeader
          title={newWarehouse ? 'New Warehouse' : 'Warehouse'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={(!newWarehouse && !editWarehouse) ? [
            <Button style={{
              backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute', top: 30, right: 25
            }} onClick={this.editWarehouse}>
              Edit
            </Button>
          ] : []}>
          <Container
            warehouseId={warehouseId}
            editWarehouse={editWarehouse}
            newWarehouse={newWarehouse} />
        </PromatePageHeader>
      </div>
    )
  }

  editWarehouse = () => {
    const { warehouseId } = this.props.match.params
    this.setState({ editWarehouse: true });

    this.props.history.push("/warehouses/" + warehouseId + "/edit");
  }
}


const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default WarehouseView;