import React, { Component } from 'react';
import { Button } from "antd";
import Radium from 'radium';
import { ProMateGreen, ProMateGreenBorder } from '../../const/Theme';

class Scroller extends Component {

  onSelectItem = item => {
    const { selectedElements, onClickItem } = this.props;

    const newSelectedElements = [...selectedElements];

    if (newSelectedElements.includes(item)) {
      newSelectedElements.splice(newSelectedElements.indexOf(item), 1);
    } else {
      newSelectedElements.push(item);
    }

    onClickItem(newSelectedElements);
  }

  renderFilters = () => {
    const { elements, selectedElements = [] } = this.props;

    return elements.map((filter, index) =>
      <Button
        size={'small'}
        style={selectedElements.includes(filter) ? styles.filter.active : styles.filter.innactive}
        onClick={() => this.onSelectItem(filter)}
        key={index + '.' + filter}>
        {filter}
      </Button>
    );
  }

  render() {
    const { height } = this.props;
    return (
      <div style={[styles.container, { maxHeight: height }]} className='thin-scrollbar'>
        {this.renderFilters()}
      </div>
    )
  }
}

export default Radium(Scroller);

const styles = {
  filter: {
    active: {
      backgroundColor: ProMateGreen,
      width: '100%',
      fontSize: 10,
      color: 'white',
      marginTop: 2,
    },
    innactive: {
      width: '100%',
      fontSize: 10,
      marginTop: 2,
    },
    changing: {
      width: '100%',
      fontSize: 10,
      borderColor: ProMateGreenBorder,
      marginTop: 2,
    },
  },
  container: {
    overflow: 'scroll',
    marginTop: 5,
  },
};