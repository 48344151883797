import React, { Component } from 'react';
import { Card, Col, Row, Button, Popconfirm } from "antd";
import { format } from "currency-formatter";
import Input from '../../../components/common/EnglishInput';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class Footer extends Component {

  render() {
    const { invoiceTotal, setoffTotal } = this.props;

    return (
      <Card>
        <Row gutter={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Col>
            <tr>
              <td>Invoice Amount</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <div style={{
                  fontSize: "large",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 5,
                }}>{format(invoiceTotal, currencyFormat)}</div>
              </td>
            </tr>
            <tr>
              <td>Setoff Amount</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <div style={{
                  fontSize: "large",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 5,
                }}>{format(setoffTotal, currencyFormat)}</div>
              </td>
            </tr>
            <tr>
              <td>Remarks</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td><Input /></td>
            </tr>
            <Popconfirm
              title="Are you sure ?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.props.onSetoffReceipt}>
              <Button
                block
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, marginTop: 15 }}>Set Off</Button>
            </Popconfirm>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default Footer;