import React, { Component } from 'react';
import { Form, Card, Row, Col } from "antd";
import { format } from "currency-formatter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class InvoiceFooter extends Component {

  render() {
    const { totalAmount, totalDiscountAmount, amountIncludingVat } = this.props.invoice;

    return (
      <Card style={{ border: 0 }}>
        <Form>
          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Gross Amount</div>
              <div style={{ fontSize: "large" }}>{format(totalAmount, currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large", marginRight: 10 }}>Discount</div>
              <div style={{ fontSize: "large" }}>{format(totalDiscountAmount, currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large", marginRight: 10 }}>Tax</div>
              <div style={{ fontSize: "large" }}>{format((amountIncludingVat - (totalAmount + totalDiscountAmount)), currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Net Amount</div>
              <div style={{ fontSize: "large" }}>{format(amountIncludingVat, currencyFormat)}</div>
            </Col>
          </Row>
        </Form>
      </Card>
    )
  }
}
export default InvoiceFooter;