import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchSalesHeaders, addSalesHeader, updateSalesHeader, deleteSalesHeader, fetchSalesCreditMemoReasons } from "../../../redux/actions/salesHeaders";
import { fetchSalesLines, deleteSalesLine } from "../../../redux/actions/salesLines";
import { fetchInventory } from "../../../redux/actions/inventory";
import { fetchCustomers } from "../../../redux/actions/customers";
import { fetchPaymentMethods, fetchPaymentMethodsForCustomer } from "../../../redux/actions/paymentMethods";
import { fetchPaymentTerms, fetchPaymentTermsForCustomer } from "../../../redux/actions/paymentTerms";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import OrderHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import { getAuthData, getSalesHeadersByTypeAndNo, DOC_TYPE, getCustomer, getLocations, getSalesLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser, getLotWiseItemCategory4s, getCustomerPaymentMethodsDataByCustomerId, getCustomerPaymentTermsDataByCustomerId } from '../../../redux/reducers';
import CallStatusValidateButton from '../../common/CallStatusValidateButton';
import { generate as generateReport } from '../../../api/reports';
import Api from '../../../api/consts';
import { CREDIT_NOTES, SALES_INVOICES, SALES_ORDERS, SALES_RETURNS } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchWarehouseLocations } from '../../../redux/actions/warehouses';
import { fetchSalesReturnReasons } from '../../../redux/actions/salesReturnReasons';
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';
import { getOfflineData } from '../../../localStorage';
import { fetchItems, updateAllItems } from '../../../redux/actions/items';
import { fetchSalesDiscountReason } from '../../../redux/actions/salesDiscountReasons';

export const defaultOrder = {
  SessionID: uuid(),
  DocType: undefined,
  SellToCusNo: undefined,
  SellToCusName: undefined,
  BillToCusNo: undefined,
  BillToCusName: undefined,
  LocCode: undefined,
  //OrderDate: undefined,
  Status: "Open",
  OrderStatus: undefined,
  PayMethodCode: undefined,
  PayTermCode: undefined,
  SOType: "Retail Sales",
  DiscountReasonCode: undefined,
  DistributorCode: undefined,
  MapLocation: undefined,
  OrderDate: moment(new Date()).format(dateFormatUpdate),
  Amount: 0.0,
  AmountIncluVAT: 0.0,
  DisPersentage: 0.0,
  DisAmount: 0.0,
  Remarks: ''
}

const toastMessage = {

}

class Container extends Component {
  state = {
    loading: false,
    order: { ...defaultOrder },
    locCodeError: undefined,
    returnReasonError: undefined,
    sharing: false,
    isSystemOffline: false,
    items: [],
    mode: getOfflineData('state').isSystemOffline
  }

  async componentDidMount() {
    const { orderId, docType } = this.props.match.params
    const { customerId } = this.props
    const newOrder = orderId === 'new';

    this.props.fetchPaymentMethods();
    this.props.fetchPaymentTerms();
    this.props.fetchWarehouseLocations({ UserCode: this.props.authData.userId });
    this.props.fetchSalesReturnReasons();
    this.props.fetchSalesCreditMemoReasons();

    if (getOfflineData('state').isSystemOffline) {
      this.setState({ isSystemOffline: true });
    }

    if (!newOrder) {
      this.setState({ loading: true });
      await this.props.fetchSalesLines({ DocNo: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
      this.props.fetchSalesHeaders({ No: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] }).then(({ data, error }) => {
        if (!error) {
          this.props.fetchPaymentMethodsForCustomer({ CustNo: customerId });
          this.props.fetchPaymentTermsForCustomer({ CustNo: customerId });
          this.setState({ loading: false });

        } else {
          this.setState({ loading: false });
        }
      })
    } else {
      this.props.fetchPaymentMethodsForCustomer({ CustNo: customerId });
      this.props.fetchPaymentTermsForCustomer({ CustNo: customerId })
      this.setData(this.props);
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.order && !nextProps.order) {
      // The order is deleted.
      window.history.back();
    } else if (nextProps && nextProps.order) {
      this.setState({ order: { ...nextProps.order } });
    } else {
      this.setData(this.props);
    }
  }

  setData = data => {
    const { customer, locations, customerPaymentMethods, customerPaymentTerms } = data;

    // const firstLocation = locations.length > 0 ? locations.find(location => String(location.LocCode).includes("MWH")) : null;
    // const LocCode = (new Object(firstLocation)).hasOwnProperty("LocCode") ? firstLocation.LocCode : undefined;
    const firstPaymentMethod = customerPaymentMethods.length > 0 ? customerPaymentMethods.find(method => method.No === "CASH") : null;
    const PayMethodCode = (new Object(firstPaymentMethod)).hasOwnProperty("No") ? firstPaymentMethod.No : undefined;
    const firstPaymentTerm = customerPaymentTerms.length > 0 ? customerPaymentTerms.find(term => term.No === "COD") : null;
    const PayTermCode = (new Object(firstPaymentTerm)).hasOwnProperty("No") ? firstPaymentTerm.No : undefined;

    if (!customer) return;

    this.setState({
      order: {
        ...this.state.order,
        SellToCusNo: customer.No,
        SellToCusName: customer.Name + ' ' + customer.Name2,
        // LocCode,
        PayMethodCode,
        PayTermCode
      }
    }, this.setDeviceMapLocation)
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  submitSalesHeader = () => {
    const { match, toastManager } = this.props;
    const { docType = '' } = match.params;
    let title = 'Order';
    switch (docType) {
      case 'Invoice':
        title = 'Sales Invoice';
        break;
      case 'ReturnOrder':
        title = 'Sales Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Sales Order';
        break;
    }

    if (!locationEnabled) {
      toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) return;

    const newSalesHeader = this.createNewSalesHeaderRequest();

    this.setState({ loading: true })
    this.sendCreateSalesHeaderRequest(newSalesHeader).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
        this.props.history.replace("/customers/" + match.params.customerId + "/sales/" + docType + "/" + encodeURIComponent(response.data.No));
      } else {
        toastManager.add('Could not create the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  validateForm = () => {
    const { docType } = this.props.match.params;
    const { order } = this.state;
    const { LocCode, ReturnReasonCode, PayMethodCode, PayTermCode, DiscountReasonCode, SOType, DisPersentage } = order;

    let locCodeError = undefined;
    let payMethodCodeError = undefined;
    let payTermCodeError = undefined;
    let returnReasonError = undefined;
    let discountReasonError = undefined;
    let SOTypeError = undefined;

    if (!this.isValidValue(LocCode)) locCodeError = 'Location is required.'
    if (docType === 'Order') {
      if (!this.isValidValue(PayMethodCode)) payMethodCodeError = 'Payment method is required.';
      if (!this.isValidValue(PayTermCode)) payTermCodeError = 'Payment term is required.';
      if (!this.isValidValue(DiscountReasonCode)) discountReasonError = 'Discount reason is required.';
      if (!this.isValidValue(SOType)) SOTypeError = 'Sales order type is required.';
    }
    if (docType === 'CreditMemo' && !this.isValidValue(ReturnReasonCode)) returnReasonError = 'Type is required.';
    if (docType === 'ReturnOrder' && !this.isValidValue(ReturnReasonCode)) returnReasonError = 'Return Type is required.';
    this.setState({ locCodeError, payMethodCodeError, payTermCodeError, returnReasonError, discountReasonError, SOTypeError })

    if (docType === 'CreditMemo') {
      return !payMethodCodeError && !payTermCodeError && !returnReasonError;
    } else {
      return !locCodeError && !payMethodCodeError && !payTermCodeError && !returnReasonError && !SOTypeError && (DisPersentage > 0 ? !discountReasonError : true);
    }

  }

  isValidValue = value => value && value.trim().length > 0;

  createNewSalesHeaderRequest = () => {
    const { order } = this.state;
    const { authData: { userId }, match } = this.props;
    const { docType } = match.params;

    return {
      ...order,
      SessionID: uuid(),
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      OrderDate: moment(new Date()).format(dateFormatUpdate),
      MapLocation: this.state.MapLocation,
      UserCode: userId
    }
  }

  sendCreateSalesHeaderRequest = salesHeader => {
    return this.props.addSalesHeader(salesHeader);
  }

  sendUpdateSalesHeaderRequest = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    const { docType } = match.params;
    let title = 'Order';
    switch (docType) {
      case 'Invoice':
        title = 'Sales Invoice';
        break;
      case 'ReturnOrder':
        title = 'Sales Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Sales Order';
        break;
    }
    this.setState({ loading: true })
    this.props.updateSalesHeader({ ...this.state.order, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not update the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  shareOrder = () => {
    this.setState({ sharing: true });

    const { order, toastManager, match } = this.props;
    const { docType } = match.params;
    const reportRequest = {
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      No: order.No
    };

    generateReport(reportRequest).then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventoryItemListView = () => {
    const { match, toastManager } = this.props;
    const { orderId, docType } = match.params;

    let title = 'Order';
    switch (docType) {
      case 'Invoice':
        title = 'Sales Invoice';
        break;
      case 'ReturnOrder':
        title = 'Sales Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Sales Order';
        break;
    }

    if (orderId === 'new') {
      if (!locationEnabled) {
        toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
        return;
      }

      if (!this.validateForm()) return;

      const newSalesHeader = this.createNewSalesHeaderRequest();

      this.setState({ loading: true })
      this.sendCreateSalesHeaderRequest(newSalesHeader).then(response => {
        if (!response.error) {
          toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
          const order = { orderNo: encodeURIComponent(response.data.No), discountPercentage: this.state.order.discountPercentage, ...response.data };
          this.props.history.replace({ pathname: "/customers/" + match.params.customerId + "/sales/" + docType + "/" + encodeURIComponent(response.data.No) });

          if (docType === 'ReturnOrder') {
            this.props.history.push({ pathname: '/inventoryLotWiseItems', state: { resource: "salesReturns", order: order, id: response.data.No } });
            //this.props.history.push({ pathname: '/inventory', state: { resource: "salesOrders", order: order, id: response.data.No } });
          } else if (docType === 'Order') {
            this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "salesOrders", order: order, id: response.data.No } });
          } else if (docType === 'CreditMemo') {
            this.props.history.push('/sales/CreditMemo/' + encodeURIComponent(response.data.No) + '/glItemList');
          }

        } else {
          this.setState({ loading: false })
          toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    } else {
      const order = this.state.order;
      this.setState({ loading: true });
      setTimeout(() => {
        if (docType === 'ReturnOrder') {
          this.props.history.push({ pathname: '/inventoryLotWiseItems', state: { resource: "salesReturns", order: order, id: orderId } });
          //this.props.history.push({ pathname: '/inventory', state: { resource: "salesOrders", order: order, id: orderId } });
        } else if (docType === 'Order') {
          this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "salesReturns", order: order, id: orderId } });
        } else if (docType === 'CreditMemo') {
          this.props.history.push('/sales/CreditMemo/' + orderId + '/glItemList');
        }
      }, 100);
    }
  }

  navigateToInvoiceList = () => {
    const { match, toastManager } = this.props;
    const { orderId } = match.params;

    if (orderId === 'new') {
      if (!locationEnabled) {
        toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
        return;
      }

      if (!this.validateForm()) return;

      const newSalesHeader = this.createNewSalesHeaderRequest();

      this.setState({ loading: true })
      this.sendCreateSalesHeaderRequest(newSalesHeader).then(response => {
        if (!response.error) {
          toastManager.add('Successfully created a new Sales Return.', { autoDismiss: true, appearance: 'success' });

          this.props.history.push({ pathname: `/salesInvoicesList/${encodeURIComponent(response.data.No)}/${encodeURIComponent(this.props.customerId)}`, state: { from: 'salesReturn' } })

        } else {
          this.setState({ loading: false })
          toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    } else {
      this.setState({ loading: true });

      this.props.history.push({ pathname: `/salesInvoicesList/${encodeURIComponent(orderId)}/${encodeURIComponent(this.props.customerId)}`, state: { from: 'salesReturn' } })
    }
  }

  navigateToConfirmOrder = () => {
    const { orderId, docType } = this.props.match.params

    this.props.history.push({ pathname: '/sales/' + docType + '/' + orderId + '/confirm', state: { order: this.state.order } })
  }

  navigateToOfflineOrderReport = () => {
    const { orderId, docType } = this.props.match.params
    this.props.history.push({ pathname: '/sales/' + docType + '/' + orderId + '/offlineOrderReport', state: { order: this.state.order, orderId: orderId } })
  }
  // generateInvoice = () => {
  //   const { orderId } = this.props.match.params
  //   this.props.history.push({ pathname: '/salesOrders/' + orderId + '/salesInvoices/new', state: { order: this.state.order } })
  // }



  onDeleteOrder = async () => {
    const { toastManager, match, authData: { userId }, fetchItems, deleteSalesLine, updateAllItems } = this.props;
    const { docType } = match.params;
    let title = 'Order';
    switch (docType) {
      case 'Invoice':
        title = 'Sales Invoice';
        break;
      case 'ReturnOrder':
        title = 'Sales Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Sales Order';
        break;
    }
    this.setState({ loading: true })

    if (this.state.isSystemOffline) {
      var allItems = await fetchItems();

      for (let i = 0; i < this.props.salesLines.length; i++) {
        const salesLine = this.props.salesLines[i];

        if (docType !== 'ReturnOrder') {
          var item = allItems.find(function (item) {
            return item.LotNo == salesLine.LotNo;
          });
          item.Inventory = (parseInt(item.Inventory.replace(/,/g, '')) + parseInt(salesLine.Quantity.replace(/,/g, ''))).toLocaleString();
        }
        await deleteSalesLine(salesLine, false);
      }
      await updateAllItems(allItems);
    }

    this.props.deleteSalesHeader({ ...this.state.order, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' deleted.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not delete the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onConfirmApproved = () => {
    const { updateSalesHeader, toastManager, order, authData: { userId } } = this.props;
    const updateOrderData = { ...order, OrderStatus: '1', UserCode: userId };

    updateSalesHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Invoice generated.', { autoDismiss: true, appearance: 'success' });
        window.history.back();
      } else {
        toastManager.add('Could not generate the invoice. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onInputChange = (field, value, errorKey) => {
    const { order } = this.state;

    this.setState({
      order: { ...order, [field]: value },
      [errorKey]: undefined
    }, () => this.handleResetPayTermCode(field, value))
  }

  handleResetPayTermCode = (field, value) => {
    const { order } = this.state;

    if (field === 'PayMethodCode' && value === 'CASH') {
      this.setState({ order: { ...order, PayTermCode: undefined } })
    }
  }

  getGenerateButtonLable = () => {
    const { match } = this.props;
    const { docType } = match.params
    let label = 'Generate Invoice';
    if (docType === 'ReturnOrder') {
      label = 'Generate Ret. Invoice';
    } else if (docType === 'CreditMemo') {
      label = 'Confirm';
    }

    return label;
  }

  getPermission = (docType, type, permissions) => {
    let enabled = false;
    if (type === 'CREATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.CREATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CREATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CREATE)
      }
    }
    else if (type === 'UPDATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.UPDATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.UPDATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.UPDATE)
      }
    }
    else if (type === 'DELETE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.DELETE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.DELETE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.DELETE)
      }
    } else if (type === 'CONFIRM') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.CONFIRM)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CONFIRM)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CONFIRM)
      }
    }
    else if (type === 'PRINT') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.PRINT)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.PRINT)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.PRINT)
      }
    }

    else if (type === 'GENERATE_INVOICE') {
      if (docType === 'Order') {
        enabled = permissions.includes(SALES_ORDERS.GENERATE_INVOICE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.GENERATE_RETURN_INVOICE)
      }
    }

    return enabled;
  }

  renderActions = () => {
    const { match, authData: { roleCode, distributorCSR }, order, salesLines, loggedInUser: { permissions = [] }, location, customer } = this.props;
    const { Status, CustStatus } = order || {};
    const { orderId, docType } = match.params
    const newOrder = orderId === 'new';
    const isLocationOwner = location && location.WarehouseOwner;
    const enableCreate = this.getPermission(docType, 'CREATE', permissions);
    const enableUpdate = this.getPermission(docType, 'UPDATE', permissions);
    const enableDelete = this.getPermission(docType, 'DELETE', permissions);
    const enableConfirm = this.getPermission(docType, 'CONFIRM', permissions);
    const enableGenerateInvoice = this.getPermission(docType, "GENERATE_INVOICE", permissions);
    const enablePrint = this.getPermission(docType, "PRINT", permissions);

    if (newOrder && enableCreate) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={this.submitSalesHeader}
          >
            <CallStatusValidateButton type="primary" >Create</CallStatusValidateButton>
          </Popconfirm>
        </Col></Row>
    } else {
      return <div>
        {
          (Status !== '' && Status !== 'Open') && true && !this.state.mode &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button
              loading={this.state.sharing}
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
              onClick={this.shareOrder}
            >Print & Share</Button>
          </Col>
        }

        {
          salesLines && salesLines.length > 0 && Status === 'Open' && enableConfirm && !this.state.mode &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmOrder}>Confirm</CallStatusValidateButton>
          </Col>
        }

        {
          this.state.mode && !this.state.order.OfflineOrderConfirm &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmOrder}>Confirm</CallStatusValidateButton>
          </Col>
        }

        {
          Status === 'Customer Confirmed' && salesLines && salesLines.length > 0 &&
          enableGenerateInvoice &&
          isLocationOwner && distributorCSR === "No" &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"

              cancelText="No"
              onConfirm={this.onConfirmApproved}
            >
              <CallStatusValidateButton type="primary">{this.getGenerateButtonLable()}</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }

        {
          (enableDelete) && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteOrder}
            >
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }

        {
          (enableUpdate) && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton type="primary" onClick={this.sendUpdateSalesHeaderRequest}>Update</CallStatusValidateButton>
          </Col>
        }

        {
          // // TODO: Only allow this to DISTRIBUTORs in the future.
          // Status === 'Released' && docType === 'Order' && enableApprove && //roleCode === "DISTRIBUTOR" &&
          // <Col>
          //   <Popconfirm
          //     title="Are you sure?"
          //     okText="Yes"

          //     cancelText="No"
          //     onConfirm={this.onConfirmApproved}
          //   >
          //     <Button type="primary">Approve</Button>
          //   </Popconfirm>
          // </Col>
        }

      </div >
    }
  }

  render() {
    const { customers, match, salesLines, customerId } = this.props;
    const { orderId, docType } = match.params
    const newOrder = orderId === 'new';
    const { loading, order, locCodeError, payMethodCodeError, payTermCodeError, returnReasonError, discountReasonError, SOTypeError } = this.state;

    if (!order) return null;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <OrderHeader
            customerId={customerId}
            order={order}
            salesLines={salesLines}
            customers={customers}
            newOrder={newOrder}
            onSubmitOrder={this.submitOrder}
            onShareOrder={this.shareOrder}
            onClickConfirmOrder={this.navigateToConfirmOrder}
            onDeleteOrder={this.onDeleteOrder}
            onInputChange={this.onInputChange}
            error={{ locCodeError, payMethodCodeError, payTermCodeError, returnReasonError, discountReasonError, SOTypeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToInventoryItemListView}
                OnClickSelectInvoice={this.navigateToInvoiceList}
                order={order}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToInventoryItemListView}
                OnClickSelectInvoice={this.navigateToInvoiceList}
                order={order}
              />
            </Col>
          </div>
          <OrderFooter order={order} onInputChange={this.onInputChange} docType={docType} />
          <div style={{ float: 'right', margin: 15 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId, docType, customerId } = ownProps.match.params
  const newOrder = orderId === 'new';
  const decodeId = decodeURIComponent(orderId);
  const order = getSalesHeadersByTypeAndNo(state, docType, decodeId);
  const selToCust = order ? order.SellToCusNo : undefined;
  const custId = newOrder ? customerId : selToCust;

  const customer = getCustomer(state, custId);

  return {
    order,
    authData: getAuthData(state),
    locations: getLocations(state),
    customer,
    salesLines: getSalesLinesByDocTypeAndDocNo(state, docType, decodeId),
    loggedInUser: getLoggedInUser(state),
    customerId: custId,
    location: getLocations(state).filter(location => order && location.LocCode === order.LocCode && location.WarehouseOwner === 'Yes')[0],
    customerPaymentMethods: getCustomerPaymentMethodsDataByCustomerId(state, custId),
    customerPaymentTerms: getCustomerPaymentTermsDataByCustomerId(state, custId),
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchCustomers,
  fetchInventory,
  fetchSalesHeaders,
  addSalesHeader,
  updateSalesHeader,
  deleteSalesHeader,
  fetchPaymentMethodsForCustomer,
  fetchPaymentTermsForCustomer,
  fetchSalesLines,
  fetchPaymentMethods,
  fetchPaymentTerms,
  fetchWarehouseLocations,
  fetchSalesReturnReasons,
  fetchSalesCreditMemoReasons,
  fetchItems,
  deleteSalesLine,
  updateAllItems,
  fetchSalesDiscountReason,
})(Container)));
