import React, { Component } from 'react';
import { connect } from "react-redux";
import { addCollection, updateCollection, fetchCollection, deleteCollection, addAttachments } from "../../../redux/actions/collection";
import { fetchCustomer } from "../../../redux/actions/customers"
import { getCustomer, getCollection } from "../../../redux/reducers";
import { Form, Spin, Row, Col, Button } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import ReceiptHeader from './Header';
import { currentLocation } from '../../../utils/Location';
import uuid from 'uuid/v1';
import Api from '../../../api/consts';
import { getDemoItem as getDemoCustomer } from '../../Customers/CustomerDemoData'
import { getDemoCreditNote } from '../dummyCreditNotes'
import InvoiceList from './InvoiceList';
import ReceiptList from './ReceiptList';
import { format } from "currency-formatter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const defaultReceipt = {
  receiptNo: "new",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  receiptDate: moment(),
  salesPersonCode: "",
  amount: 0.00,
  receiptType: "CASH",
  status: "Unverified",
  bankCode: undefined,
  bankName: undefined,
  chequeNo: undefined,
  slipNo: undefined,
  postingDate: moment(),
  links: [],
  mapLocation: currentLocation,
  sessionId: uuid(),
}

class Container extends Component {
  state = {
    loading: false,
    isDisableSave: true,
    isDisableConfirm: true,
    receipt: {
      ...defaultReceipt
    },
    selectedReceiptRowKeys: [],
    selectedReceiptRows: [],
    selectedInvoiceRowKeys: [],
    selectedInvoiceRows: [],
    invoiceTotal: 0,
    setoffTotal: 0,
    bankCodeError: undefined,
    chequeNoError: undefined,
    slipNoError: undefined,
    amountError: undefined,
    postingDateError: undefined
  }

  componentDidMount() {
    const { customerId, receiptId } = this.props.match.params
    this.props.fetchCustomer(customerId)
    if (receiptId !== 'new') {
      this.setState({ loading: true })
      this.props.fetchCollection(receiptId).then(() => {
        this.setState({ loading: false })
      })
    }
    this.updateState({}, this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.updateState(this.props, nextProps);
  }

  updateState = (thisProps, nextProps) => {
    const { receipt, match } = nextProps;
    const { customerId, receiptId } = match.params
    const customer = getDemoCustomer(customerId)
    const newReceipt = receiptId === 'new';
    const updateStateCollection = this.hasCollectionUpdated(thisProps.receipt, receipt);

    if (newReceipt) {
      this.setState({ receipt: { ...defaultReceipt, sellToCustomerCode: customer && customer.id, sellToCustomerName: customer && customer.name } })
    } else if (receipt && updateStateCollection) {
      this.setState({ receipt: { ...receipt, bankCode: this.getBankCodeFromName(receipt.bankCode) } })
    }
  }

  hasCollectionUpdated = (thisCollection = {}, nextCollection = {}) => {
    return JSON.stringify(thisCollection) !== JSON.stringify(nextCollection);
  }

  getBankCodeFromName = name => {
    const bank = banks.find(bank => bank.shortname === name);
    return bank && bank.bankid;
  }

  onInputChange = (field, value, errorKey) => {
    if (field === 'amount' && value !== "" && !this.isValidAmount(value)) return

    this.setState({ receipt: { ...this.state.receipt, [field]: value }, [errorKey]: undefined });
  }

  onSelectBankChange = bankid => {
    const selectedBank = banks.find(bank => bank.bankid === bankid)
    this.setState({ receipt: { ...this.state.receipt, bankCode: selectedBank.bankid, bankName: selectedBank.bankname }, bankCodeError: undefined });
  }

  onSelectReceiptTypeChange = type => {
    this.setState({ receipt: { ...this.state.receipt, paymentType: type } });
  }

  onSaveReceipt = () => {
    // if (!this.validateForm()) return;

    const { match } = this.props;
    const { receiptId } = match.params
    const newCollectionId = receiptId === 'undefined' ? "new" : receiptId;

    const { addCollection, updateCollection, customer, toastManager } = this.props;
    const { receipt } = this.state;
    this.setState({ loading: true });

    if (newCollectionId !== 'new') {
      setTimeout(() => {
        this.setState({ loading: false });
        toastManager.add('Receipt successfully updated.', { autoDismiss: true, appearance: 'success' });
      }, 2000)
      // updateCollection(receipt).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the receipt.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      receipt.mapLocation = currentLocation;
      setTimeout(() => {
        this.setState({ loading: false });
        toastManager.add('Successfully created a new receipt.', { autoDismiss: true, appearance: 'success' });
        this.props.history.push('/receipts/')
      }, 2000)
      // addCollection(receipt).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new receipt.', { autoDismiss: true, appearance: 'success' });
      //     defaultReceipt.sessionId = uuid();
      //     this.setState({ receiptNo: response.receipt.receiptNo, isDisableConfirm: false })
      //     this.props.history.replace('/customers/' + customer.id + '/receipts/' + response.receipt.receiptNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  validateForm = () => {
    const { receipt } = this.state;
    const { bankCode, amount, chequeNo, slipNo, receiptType, postingDate } = receipt;

    let bankCodeError = undefined;
    let chequeNoError = undefined;
    let slipNoError = undefined;
    let amountError = undefined;
    let postingDateError = undefined;

    if (receiptType !== 'CASH') {
      if (!this.isValidValue(bankCode)) bankCodeError = 'Bank is required.'
      if ((receiptType === 'CHEQUE' || receiptType === 'PDC') && !this.isValidValue(chequeNo)) chequeNoError = 'Cheque no is required.'
      if ((receiptType === 'CHEQUE' || receiptType === 'PDC') && !postingDate) postingDateError = 'Posting date is required.'
      if (receiptType === 'DEPOSIT' && !this.isValidValue(slipNo)) slipNoError = 'Slip no is required.'
    }

    if (!this.isValidAmount(amount)) amountError = 'Invalid amount value.'

    this.setState({ bankCodeError, chequeNoError, slipNoError, amountError, postingDateError })

    return !bankCodeError && !chequeNoError && !slipNoError && !amountError && !postingDateError;
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidAmount = value => {
    const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

    return validatorString.test(value) && parseFloat(value) > 0;
  }

  setImageLink = fileUrl => {
    const { addAttachments, toastManager } = this.props;
    const { receipt } = this.state;

    this.setState({ loading: true });
    addAttachments({ ...receipt, links: [fileUrl] }).then(response => {
      this.setState({ loading: false });
      if (!response.error) {
        toastManager.add('Successfully upload attachment.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Failed to upload attachment', { autoDismiss: true, appearance: 'error' });
      }
    })
    //this.setState({ receipt: { ...this.state.receipt, links: [fileUrl] } })
  }

  navigateToConfirmReceipt = () => {
    const { receiptId } = this.props.match.params

    this.props.history.push({ pathname: '/receipts/' + receiptId + '/confirm' })
  }

  onDeleteReceipt = () => {
    const { deleteCollection, toastManager, history } = this.props;
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      toastManager.add('Receipt successfully deleted.', { autoDismiss: true, appearance: 'success' });
      history.goBack();
    }, 2000)
    // deleteCollection(this.state.receipt).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete receipt. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Collection successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     history.goBack();
    //   }
    // });
  }

  onSetoffReceipt = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        loading: false,
        selectedInvoiceRowKeys: [],
        selectedReceiptRowKeys: [],
        selectedInvoiceRows: [],
        selectedReceiptRows: [],
        invoiceTotal: 0,
        setoffTotal: 0
      });
      this.props.toastManager.add('Credit note successfully setoffed.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack()
    }, 1000)
    // deleteCollection(this.state.receipt).then(({ error }) => {
    //   this.setState({ loading: false });
    //   if (error) {
    //     toastManager.add('Failed to delete receipt. ' + (error || ''), { autoDismiss: true, appearance: 'error' });
    //   } else {
    //     toastManager.add('Collection successfully deleted.', { autoDismiss: true, appearance: 'success' });
    //     history.goBack();
    //   }
    // });
  }

  shareCollection = () => {
    const { receipt } = this.state;
    //const reportLink = receipt && receipt.links && receipt.links.length > 0 && receipt.links.find(link => link.includes('receipt-report'))
    //reportLink && window.open(Api.server + "/files/" + reportLink, '_blank');
    window.open('/purchasingOrderReport.pdf', '_blank');
    // const { receiptId } = this.props.match.params
    // const printContainer = document.getElementById('printContainer');
    // const printCollectionView = document.getElementById('printCollectionView');
    // printContainer.style.display = 'inline';
    // printCollectionView.style.display = 'none';
    // this.setState({ loading: true });

    // html2canvas(document.querySelector("#receiptPrintDiv"), { width: 685 }).then((canvas) => {
    //   var a = document.createElement('a');
    //   a.href = canvas.toDataURL("image/png");
    //   a.download = receiptId + '.png';
    //   a.click();

    //   printCollectionView.style.display = 'inline';
    //   printContainer.style.display = 'none';
    //   this.setState({ loading: false });
    // })
  }

  renderActions = () => {
    const { invoiceTotal, setoffTotal } = this.state;

    return <Row gutter={15}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: "large" }}>Invoice Amount:</div>
        <div style={{
          fontSize: "large",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 5,
        }}>{format(invoiceTotal, currencyFormat)}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: "large" }}>Setoff Amount:</div>
        <div style={{
          fontSize: "large",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 5,
        }}>{format(setoffTotal, currencyFormat)}</div>
      </div>
      {/* <div style={{ borderTop: '1px solid rgba(0,0,0,.25)', width: 279, marginTop: 10, marginBottom: 10 }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: "large" }}>Balance:</div>
        <div style={{
            fontSize: "large",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 5,
          }}>{format(invoiceTotal - setoffTotal, currencyFormat)}</div>
      </div> */}
      <Col>
        <Button
          type="secondary"
          style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, width: 279, marginTop: 15 }}
          onClick={this.onSetoffReceipt}>Set Off</Button>
      </Col>
    </Row>

  }

  handleOnClickSelectInvoices = (selectedReceiptRowKeys, selectedReceiptRows) => {
    const invoiceTotal = this.getTotal(selectedReceiptRows)

    this.setState({ selectedReceiptRowKeys, selectedReceiptRows, invoiceTotal })
  }

  handleOnClickSelectCreditNotes = (selectedInvoiceRowKeys, selectedInvoiceRows) => {
    const setoffTotal = this.getTotal(selectedInvoiceRows)

    this.setState({ selectedInvoiceRowKeys, selectedInvoiceRows, setoffTotal })
  }

  getTotal = selectedReceiptRows => {
    let invoiceTotal = 0;
    selectedReceiptRows.forEach(row => {
      invoiceTotal = invoiceTotal + row.balanceToSettle;
    })
    return invoiceTotal;
  }

  render() {
    const { match } = this.props;
    const { receiptId } = match.params
    const newReceiptId = receiptId ? receiptId : "new";
    const {
      loading,
      receipt,
      selectedReceiptRowKeys,
      selectedReceiptRows,
      selectedInvoiceRowKeys,
      selectedInvoiceRows } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading receipt"}>
        <Row id={'printCollectionView'}>
          <ReceiptHeader
            newReceiptId={newReceiptId}
            receipt={receipt}
            onSaveReceipt={this.onSaveReceipt}
            onConfirmReceipt={this.navigateToConfirmReceipt}
            onDeleteReceipt={this.onDeleteReceipt}
          />
          <Row style={{ margin: 10 }} gutter={10}>
            <Col>
              <Col style={{ marginBottom: 10 }} lg={12} md={24} sm={24} xs={24}>
                <InvoiceList
                  handleOnClickSelectInvoices={this.handleOnClickSelectInvoices}
                  selectedRowKeys={selectedReceiptRowKeys}
                  selectedRows={selectedReceiptRows} />
                  />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <ReceiptList
                  handleOnClickSelectCreditNotes={this.handleOnClickSelectCreditNotes}
                  selectedRowKeys={selectedInvoiceRowKeys}
                  selectedRows={selectedInvoiceRows} />
              </Col>
            </Col>
          </Row>
          <div style={{ float: 'right', margin: 24 }}>
            {this.renderActions()}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, receiptId } = ownProps.match.params
  //return { customer: getCustomer(state, customerId), receipt: getCollection(state, receiptId) };
  return { customer: getDemoCustomer(customerId), receipt: getDemoCreditNote(receiptId) };
};

export default withRouter(withToastManager(connect(mapStateToProps, { fetchCustomer, addCollection, updateCollection, fetchCollection, deleteCollection, addAttachments })(Container)));

const banks = [
  { "_id": "nXykAeaxKY5iwj3Nf", "bankid": "17", "bankname": "Amana Bank PLC", "shortname": "Amana Bank" },
  { "_id": "a3EMXnpF2rCD9k9ZY", "bankid": "2", "bankname": "Bank of Ceylon", "shortname": "BOC" },
  { "_id": "af4F3jy9bS3G7TCmB", "bankid": "15", "bankname": "Cargills Bank Ltd", "shortname": "Cargills Bank" },
  { "_id": "oH9FPPuHG6wdD7rNA", "bankid": "12", "bankname": "Citibank", "shortname": "Citi Bank" },
  { "_id": "bzJvkTY7ztAzSr9CP", "bankid": "9", "bankname": "Commercial Bank of Ceylon PLC", "shortname": "Commercial" },
  { "_id": "5wDbsznEPvaPDsp9e", "bankid": "11", "bankname": "Deutsche Bank AG", "shortname": "Deutsche Bank" },
  { "_id": "FQr7DLgJHvMqsmeaF", "bankid": "14", "bankname": "DFCC Vardhana Bank PLC", "shortname": "DFCC" },
  { "_id": "PZe9SAt8QFY7HeyHZ", "bankid": "1", "bankname": "Hatton National Bank PLC", "shortname": "HNB" },
  { "_id": "pMEFuSGNK7f3i8rpe", "bankid": "21", "bankname": "Nation Trust Bank", "shortname": "NTB" },
  { "_id": "wyH5JKJ4xQ7iNqeus", "bankid": "5", "bankname": "National Savings Bank", "shortname": "NSB" },
  { "_id": "c4ngHthzcBK7Aarqi", "bankid": "18", "bankname": "MCB Bank Ltd", "shortname": "MCB Bank" },
  { "_id": "w9pAudAozSXoMK4Zz", "bankid": "4", "bankname": "National Development Bank PLC", "shortname": "NDB" },
  { "_id": "S2kpZwpJphFgzeqSG", "bankid": "7", "bankname": "Pan Asia Banking Corporation PLC", "shortname": "Pan Asia Bank" },
  { "_id": "xWey22FL4K3rD7DF2", "bankid": "10", "bankname": "People’s Bank", "shortname": "Peoples" },
  { "_id": "7NMY9kBctARPvkgiX", "bankid": "20", "bankname": "Business Development Cooperative", "shortname": "Business Development Cooperative" },
  { "_id": "WGcjpYLz4tmDupfJj", "bankid": "3", "bankname": "Sampath Bank PLC", "shortname": "Sampath" },
  { "_id": "kjBoquK33CMLXCxCP", "bankid": "8", "bankname": "Seylan Bank PLC", "shortname": "Seylan" },
  { "_id": "j9jLkLhbRkHyjGiQs", "bankid": "16", "bankname": "Standard Bank", "shortname": "Standard Bank" },
  { "_id": "Pk9fBB5Rb7JRtY3kR", "bankid": "19", "bankname": "Bank of India", "shortname": "Bank of India" },
  { "_id": "ni9rx7s3XL6Auge7f", "bankid": "6", "bankname": "The Hongkong \u0026 Shanghai Banking Corporation Ltd", "shortname": "HSBC" },
  { "_id": "phjQCAyeGuoZXoyQf", "bankid": "13", "bankname": "Union Bank of Colombo PLC", "shortname": "Union" },
]