import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_LOT_WISE_ITEMS = 'FETCH_LOT_WISE_ITEMS';
export const UPDATE_FILTER_LOT_WISE_ITEMS = 'UPDATE_FILTER_LOT_WISE_ITEMS';
export const UPDATE_COMBINED_FILTER_LOT_WISE_ITEMS = 'UPDATE_COMBINED_FILTER_LOT_WISE_ITEMS';
export const UPDATE_LOT_WISE_ITEMS_PAGE_SIZE = 'UPDATE_LOT_WISE_ITEMS_PAGE_SIZE';

export const fetchLotWiseItems = (filter) => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {

    const items = await readObjectFromIndexDB(TABLES.INVENTORY, OBJECT_KEYS.ITEMS);

    dispatch({ type: FETCH_LOT_WISE_ITEMS, items: items });

  } else {
    return fromApi.get('Item', filter).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_LOT_WISE_ITEMS, items: data });

      return response;
    }).catch(error => {
      return { error }
    })
  }

};


export const updateFilter = (type, filter) => dispatch => {
  dispatch({
    type: UPDATE_FILTER_LOT_WISE_ITEMS,
    filterType: type,
    filter,
  });
};

export const updateCombinedFilter = filter => dispatch => {
  dispatch({
    type: UPDATE_COMBINED_FILTER_LOT_WISE_ITEMS,
    filter,
  });
};

export const updatePageSize = size => dispatch => {
  dispatch({
    type: UPDATE_LOT_WISE_ITEMS_PAGE_SIZE,
    pageSize: size,
  });
};
