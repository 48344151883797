import { combineReducers } from 'redux';
import { CUSTOMERS, SALES_INVOICES, SALES_ORDERS, RECEIPTS, CALLS, ROUTES, STOCK_TRANSFERS, TRIP_SCHEDULER } from '../../const/Permissions';

const authData = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return action.authData;

    case 'LOGOUT':
      return {};

    case 'CALL_STARTED':
      return { ...state, inCall: true, callPreparing: false };

    case 'CALL_ENDED':
      return { ...state, inCall: false };

    case 'CALL_PREPARING':
      return { ...state, callPreparing: true };

    default:
      return state;
  }
}

const user = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN_USER':
      return action.user;

    case 'LOGOUT':
      return {};

    case 'ACCEPT_CONTRACT':
      return { ...state, contractAccepted: true };

    case 'UPDATE_USER':
      if (state._id === action.user._id)
        return action.user;
      else
        return state;

    case 'UPDATE_THUMB':
      return action.user;

    default:
      return state;
  }
}

export default combineReducers({
  authData,
  user,
});

export const isAuthenticated = state => {
  const { expiration } = state.authData;
  return expiration && expiration > new Date().getTime();
}

export const getAuthData = state => state.authData;

export const getUser = state => state.user;

const getValues = data => {
  return Object.values(data);
}