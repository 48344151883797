
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { fetchCustomers, fetchCustomerCategories } from "../../redux/actions/customers";
import { fetchPurchaseHeaders, fetchPurchaseCreditMemoReasons, fetchPurchaseRetrunReasons } from "../../redux/actions/purchasingHeaders";
import { getAuthData, getLoggedInUser, DOC_TYPE_ID_BY_DEFINITION, getTopPosition, getFlatPositions, getPurchaseHeadersBySearchKey, getPurchaseHeaderTotal, isFetchingPurchaseHeaders, getPurchaseHeaderMetadata, isCallInProgress, getInProgressCall, getDistributor } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import OrderTable from "./OrderTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';
import { withToastManager } from 'react-toast-notifications';
import { CREDIT_NOTES, PURCHASING_ORDERS, PURCHASE_RETURNS, PURCHASING_INVOICES } from "../../const/Permissions";
import customers from '../../redux/reducers/customers';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate } from '../../const/Formats';
import { FaPlus } from 'react-icons/fa';

const purchaseOrderStatuses = {
  Open: 0,
  'Confirmed': 1
};

class PurchasingOrders extends Component {
  baseUrl = window.location.pathname;

  state = {
    loading: false,
    filter: {
      DocType: undefined,
      UserCode: undefined,
      RouteCode: undefined,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day"),
      TerritoryCode: undefined
    },
    currentPage: 1
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchCustomerCategories, fetchPurchaseCreditMemoReasons, fetchPurchaseRetrunReasons } = this.props;
    const { customerId, docType } = match.params

    //fetchCustomerCategories();

    if (docType === 'CreditMemo') {
      fetchPurchaseCreditMemoReasons();
    } else if (docType === 'ReturnOrder') {
      fetchPurchaseRetrunReasons();
    }

    this.setState({
      filter: {
        ...this.state.filter,
        DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
        UserCode: userId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { distributorId, docType } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadOrders(nextProps);
      });
    }, { ...this.state.filter, DocType: DOC_TYPE_ID_BY_DEFINITION[docType], DistributorCode: distributorId });
  }

  loadOrders = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page,);

  fetchOrderPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchOrders(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchOrders(filter, currentPage, currentSearchKey);
  }

  fetchOrders = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      OrderDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      //SellToCusNo: filter.CustNo,
      UserCode: undefined,
      text: undefined,
      No: filter.text ? '*' + filter.text + '*' : undefined,
      //OrderStatus: purchaseOrderStatuses[filter['OrderStatus']]
    }
    this.props.fetchPurchaseHeaders(updatedFilter, page, searchKey);
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing orders.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
    }

    newFilter.StatusFilter = newFilter.OrderStatus;

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  setDefaultCustomerOption = customer => {
    this.setState({ filter: { ...this.state.filter, CustNo: customer.No, CustNo: customer.No } })
  }

  setDefaultRouteOption = route => {
    this.setState({ filter: { ...this.state.filter, RouteCode: route.RouteCode } })
  }

  getTitle = () => {
    const { docType } = this.props.match.params;
    let type = '';
    if (docType === 'CreditMemo') {
      type = 'Credit Notes';
    } else if (docType === 'ReturnOrder') {
      type = 'Purchase Returns';
    } else {
      type = 'Purchasing ' + docType + 's';
    }

    return type;
  }

  getPermission = (docType, type, permissions) => {

    let enabled = false;
    if (type === 'CREATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.CREATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(PURCHASE_RETURNS.CREATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CREATE)
      }
    }

    return enabled;
  }

  render() {
    const { filter, currentPage } = this.state;
    const { loading, loggedInUser: { permissions = [] }, match, orders, total, totalAmount, topPosition } = this.props;
    const { CustStatus } = orders;
    const { distributorId, docType } = match.params;

    const enableCreate = this.getPermission(docType, 'CREATE', permissions);

    return (
      <div>
        <PromatePageHeader
          title={this.getTitle()}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={enableCreate && distributorId && [
            <Button onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>

          <Spin spinning={(loading && orders.length === 0) || !topPosition} tip={"Loading..."}>
            <OrderTable
              orders={orders}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
    // return (
    //   <div>
    //     <PromatePageHeader
    //       title={this.getTitle()}
    //       style={styles.pageHeader}
    //       onBack={() => this.props.history.goBack()} extra={ enableCreate && ((inCall && CustNo !== '') || customerId) && [
    //         <Button onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
    //           {
    //             this.context.screenType === 'MOBILE' ? (
    //               <FaPlus color={'white'} size={25} />
    //             ) : (<span>New</span>)
    //           }
    //         </Button>,
    //       ]}>

    //       <Spin spinning={(loading && orders.length === 0) || !topPosition} tip={"Loading..."}>
    //         <OrderTable
    //           orders={orders}
    //           filter={filter}
    //           currentPage={currentPage}
    //           handleInputDateChange={this.handleInputDateChange}
    //           handleInputTextChange={this.handleInputTextChange}
    //           handleSearchClick={this.handleSearchClick}
    //           authData={this.props.authData}
    //           onPaginationChange={this.handlePaginationChange}
    //           total={total}
    //           totalAmount={totalAmount} />
    //       </Spin>
    //     </PromatePageHeader>
    //   </div>
    // )
  }

  onClickNew = () => {
    const { match } = this.props;
    const { distributorId } = match.params
    if (distributorId) {
      this.props.history.push(`/distributors/${distributorId}/purchasing/${match.params.docType}/new`);
    }
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

PurchasingOrders.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const searchKey = getSearchKey();

  return {
    orders: getPurchaseHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getPurchaseHeaderTotal(state, searchKey),
    totalAmount: getPurchaseHeaderMetadata(state, searchKey).TotalAmountIncluVAT,
    loading: isFetchingPurchaseHeaders(state),
    inCall: isCallInProgress(state),
    ongoingCall: getInProgressCall(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchPurchaseHeaders, fetchCustomerCategories, fetchPurchaseCreditMemoReasons, fetchPurchaseRetrunReasons })(PurchasingOrders));