import React, { Component } from 'react';
import { Form, Row, Col } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class OrderFooter extends Component {

  render() {
    const { stockTake, docType } = this.props;
    const { Change } = stockTake;
    const changeValue = Change && Change.replace(/,/g, '');

    return (
      <div className='promate-form-header'>
        <Form>
          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Col span={24}>
              <Col lg={16} md={16} sm={0} xs={0}>
                {/* <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <TextArea value={Remarks} rows={4} placeholder={"Type here"} onChange={(value, e) => this.props.onInputChange('Remarks', value, '')} />
                </FormItem> */}
              </Col>
              <Col lg={8} md={8} sm={24} xs={24} style={{ marginTop: 15 }}>

                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Net Differance Value</div>
                  <div style={{ fontSize: "large" }}>{format(changeValue, currencyFormat)}</div>
                </Col>
              </Col>
              {/* <Col lg={0} md={0} sm={24} xs={24}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <TextArea value={Remarks} rows={4} placeholder={"Type here"} onChange={(value, e) => this.props.onInputChange('Remarks', value, '')} />
                </FormItem>
              </Col> */}
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default OrderFooter;