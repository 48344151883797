import React, { Component } from "react";
import { Col, Row, Tag } from "antd";

class InvoiceCardHeader extends Component {

  render() {
    return (
      <Row style={{ padding: 0, backgroundColor: '#eceaea', margin: -4 }}>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', fontSize: 14, margin: -4 }}>
          <Col xs={8} sm={4} md={0} lg={0} style={{ textAlign: 'left', padding: 10 }}>Details</Col>
          <Col xs={8} sm={4} md={0} lg={0} style={{ textAlign: 'center' }}>Invoice Date</Col>
          <Col xs={10} sm={4} md={0} lg={0} style={{ textAlign: 'center' }}>Amount</Col>

          {/* <Col xs={0} sm={4} md={2} lg={2} style={{ textAlign: 'center' }}>Invoice No</Col>
            <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>Invoice Date</Col>
            <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'center' }}>Doc Type</Col>
            <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>PO Number</Col>
  
            <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>DB COde</Col>
            <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>DB Name</Col>
            <Col xs={0} sm={0} md={1} lg={2} style={{ textAlign: 'center' }}>DB Status</Col>
            <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'center' }}>Teritory</Col>
            <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'center' }}>Payment Terms</Col>
            <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'center' }}>Invoice Age</Col>
            <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'center' }}>Status</Col>
  
            <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'center' }}>Invoice Amount</Col>
            <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'center' }}>Settled Total</Col>
            <Col xs={0} sm={0} md={2} lg={4} style={{ textAlign: 'center' }}>Remaining Outstanding</Col>
  
            <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'center' }}>PDC Balance</Col>
            <Col xs={0} sm={0} md={1} lg={4} style={{ textAlign: 'center' }}>Net Outstanding</Col> */}
        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

export default InvoiceCardHeader;