import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Col, Button, Modal, Form, Input, Popconfirm } from "antd";
import { fetchCustomer } from "../../../redux/actions/customers";
import { addOrder } from "../../../redux/actions/orders";
import { getCustomer } from "../../../redux/reducers";
import { showNotification } from "../../../const/Notifications"
import Details from "./Details";
import Actions from "./Actions";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { defaultOrder } from '../../Orders/OrderScreen/Container';
import uuid from 'uuid/v1';
import moment from 'moment';
import ExpenseHeader from './Header'
import ExpenseCategoryForm from './Form'
import { getDemoItem } from '../ExpenseCategoryDemoData'

const defaultExpenseCategory = {
  id: "new",
  description: undefined,
  category: undefined,
}

class Container extends Component {
  state = {
    loading: false,
    expenseCategory: {
      ...defaultExpenseCategory
    },
    descriptionError: undefined,
    categoryError: undefined,
    showBlockReasonDialog: false,
    visible: false,
  }

  componentDidMount() {
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({
        expenseCategory: getDemoItem(this.props.expenseCategoryId),
        loading: false
      })
    }, 1000)
    // this.props.fetchCustomer(this.props.expenseId).then(() => {
    //   this.setState({ loading: false })
    // })
    //console.log(this.props.match.path);
  }


  handleCancel = () => {
    this.setState({ visible: false, blockedReason: "" });
  };

  handleOk = () => {
    //console.log(this.state.blockedReason)
    this.setState({ visible: false, blockedReason: "" });
  };


  onInputChange = (field, value, errorKey) => {
    this.setState({ expenseCategory: { ...this.state.expenseCategory, [field]: value }, [errorKey]: undefined });
  }


  onSaveExpense = () => {
    if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { expense, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });

    if (this.props.expenseCategoryId !== 'new') {
      // updateCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully updated the collection.', { autoDismiss: true, appearance: 'success' });
      //   } else {
      //     toastManager.add('Failed to update Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    } else {
      toastManager.add('Successfully created a new Expense Category.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
      // addCollection(collection).then(response => {
      //   this.setState({ loading: false });
      //   if (!response.error) {
      //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
      //    // defaultCollection.sessionId = uuid();
      //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
      //     this.props.history.replace('/customers/' + customer.id + '/collections/' + response.collection.collectionNo);
      //   } else {
      //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
      //   }
      // })
    }
  }

  onUpdateExpense = () => {
    // if (!this.validateForm()) return;

    // const { match } = this.props;
    // const { collectionId } = match.params
    // const newCollectionId = collectionId === 'undefined' ? "new" : collectionId;

    const { customer, toastManager } = this.props;
    // const { collection } = this.state;
    this.setState({ loading: true });
    this.setState({
      loading: false
    }, () => {
      toastManager.add('Successfully updated.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack();
    })

    // addCollection(collection).then(response => {
    //   this.setState({ loading: false });
    //   if (!response.error) {
    //     toastManager.add('Successfully created a new collection.', { autoDismiss: true, appearance: 'success' });
    //    // defaultCollection.sessionId = uuid();
    //     //this.setState({ collectionNo: response.collection.collectionNo, isDisableConfirm: false })
    //     this.props.history.replace('/customers/' + customer.id + '/collections/' + response.collection.collectionNo);
    //   } else {
    //     toastManager.add('Failed to created Collection', { autoDismiss: true, appearance: 'error' });
    //   }
    // })

  }

  onBloackCustomer = () => {
    this.setState({ visible: true })
  }

  onConfirmDelete = () => {

  }

  validateForm = () => {
    const { expenseCategory } = this.state;
    const { category, description } = expenseCategory;

    let categoryError = undefined;
    let descriptionError = undefined;
    //   let addressError = undefined;
    //   let faxNoError = undefined;
    //   let emailError = undefined;
    //   let contactPersonError = undefined;

    if (!this.isValidValue(category)) categoryError = 'Category Name is required.'
    if (!this.isValidValue(description)) descriptionError = 'Category description is required.'

    this.setState({ descriptionError, categoryError })
    return !descriptionError && !categoryError;
  }

  isValidValue = value => value && value.trim().length > 0;

  // isValidAmount = value => {
  //   const validatorString = /^(?:\d*\.\d{1,2}|\d+)$/;

  //   return validatorString.test(value) && parseFloat(value) > 0;


  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = header && header[0] && header[0].clientHeight;
    return window.innerHeight - (headerHeight);
  }

  submitOrder = () => {
    const { addOrder, toastManager, customer } = this.props;
    const order = { ...defaultOrder, sellToCustomerCode: customer.id, sellToCustomerName: customer.name }

    this.setState({ loading: true })
    addOrder(order).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
        defaultOrder.sessionId = uuid();
        this.props.history.replace('/orders/' + response.order.orderNo)
      } else {
        toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  onClickRefresh = () => {
    this.setState({ loading: true });
    this.props.fetchCustomer(this.props.expenseId).then(res => {
      this.setState({ loading: false })
      if (res.customer) {
        showNotification('success', 'Refreshed', "Expense Category Refreshed successfully.")
      } else {
        showNotification('error', 'Failed', "Failed to refresh expense category.")
      }

    })
  }

  renderActions = () => {
    const { expenseCategoryId, expense } = this.props;
    const newExpenseCategoryId = expenseCategoryId ? expenseCategoryId : "new";

    const { match } = this.props;
    const path = match.path
    const isEdit = path.includes("edit");

    if (newExpenseCategoryId === "new") {
      return (<Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', margin: '24px', float: 'right' }}><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        <Button type="primary" onClick={this.onSaveExpense}>Create</Button>
      </Col></Row>)
    } else {

      return (<Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between', margin: '24px', float: 'right' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col>

            <Button type="primary" onClick={this.onUpdateExpense}>Update</Button>
          </Col>

          <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onConfirmDelete}
            >
              <Button type="primary">Delete</Button>
            </Popconfirm>
          </Col>
        </div>
      </Row>)

    }
  }


  render() {
    const { expenseCategory } = this.state;
    const {
      loading,
      descriptionError,
      categoryError } = this.state;
    //console.log("expenseCategory", this.state.expenseCategory)
    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ maxHeight: this.getScrollAreaHeight(), overflow: 'scroll' }}>
          {/* {expenseCategory && this.props.expenseCategoryId != "new" && <Details expense={expenseCategory} />}
          {expense && !isEdit && this.props.expenseId != "new" && <Actions customer={expense} submitOrder={this.submitOrder} onClickRefresh={this.onClickRefresh} />} */}
          {/* {(this.props.expenseCategoryId === "new") && <ExpenseHeader expenseId={this.props.expenseCategoryId} expense={expenseCategory} />} */}
          {(this.props.expenseCategoryId) &&
            <ExpenseCategoryForm
              expenseId={this.props.expenseCategoryId}
              onInputChange={this.onInputChange}
              onSaveExpense={this.onSaveExpense}
              descriptionError={descriptionError}
              categoryError={categoryError}
              expenseCategory={expenseCategory}
            />
          }

          {this.renderActions()}

        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { expenseCategoryId } = ownProps
  // const customer = getCustomer(state, expenseId);
  const expenseCategory = getDemoItem(expenseCategoryId);
  return { expenseCategory };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchCustomer, addOrder })(Container)));