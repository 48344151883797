import * as fromApi from '../../api/generic';

export const FETCH_PURCHASE_HEADERS = 'FETCH_PURCHASE_HEADERS';
export const START_FETCH_PURCHASE_HEADERS = 'START_FETCH_PURCHASE_HEADERS';
export const FINISH_FETCH_PURCHASE_HEADERS = 'FINISH_FETCH_PURCHASE_HEADERS';
export const FETCH_PURCHASE_HEADER = 'FETCH_PURCHASE_HEADER';
export const DELETE_PURCHASE_HEADER = 'DELETE_PURCHASE_HEADER';
export const FETCH_PURCHASE_CREDIT_MEMO_REASONS = 'FETCH_PURCHASE_CREDIT_MEMO_REASONS';
export const FETCH_PURCHASE_RETURN_REASONS = 'FETCH_PURCHASE_RETURN_REASONS';

let lastSearchKey;
export const fetchPurchaseHeaders = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_PURCHASE_HEADERS });

  return fromApi.get('PurchaseHeader', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    if (data && data.length === 1) dispatch({ type: FETCH_PURCHASE_HEADER, purchaseHeader: data[0] });

    dispatch({ type: FETCH_PURCHASE_HEADERS, purchaseHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: FINISH_FETCH_PURCHASE_HEADERS });
  });
};

export const addPurchaseHeader = purchaseHeader => dispatch => {
  return fromApi.add('PurchaseHeader', purchaseHeader).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_PURCHASE_HEADER, purchaseHeader: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updatePurchaseHeader = purchaseHeader => dispatch => {
  return fromApi.update('PurchaseHeader', purchaseHeader).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_PURCHASE_HEADER, purchaseHeader: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deletePurchaseHeader = purchaseHeader => dispatch => {
  return fromApi.deleteObject('PurchaseHeader', purchaseHeader).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: DELETE_PURCHASE_HEADER, purchaseHeader });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchPurchaseCreditMemoReasons = () => dispatch => {
  return fromApi.get('RefData', { 'RefName': 'PurchaseCreditMemoReasons' }).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_PURCHASE_CREDIT_MEMO_REASONS, creditMemoReasons: data });

    return response;
  }).catch(error => {
    return { error }
  });
};

export const fetchPurchaseRetrunReasons = () => dispatch => {
  return fromApi.get('RefData', { 'RefName': 'PurchaseRetrunReasons' }).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_PURCHASE_RETURN_REASONS, returnReasons: data });

    return response;
  }).catch(error => {
    return { error }
  });
};