import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Input, Spin } from 'antd';
import { getBrands, getCategory1s, getCategory2s, getCategory3s, getCategory4s, getCombinedFilter } from '../../redux/reducers';
import { defaultCombinedFilter } from '../../redux/reducers/inventory';
import { ProMateGreen, ProMateGreenBorder } from '../../const/Theme';
import Filter from './Filter';
import { updateFilter, updateCombinedFilter } from '../../redux/actions/inventory';
//import { brands, category2List as category2s, category1List as category3s, category1List as category4s } from './InventoryDemoData';

const { Search } = Input;

class AdvancedFilter extends Component {
	state = {
		combinedFilter: defaultCombinedFilter,
		loading: false,
	};

	componentDidMount = () => {
		this.setState({ combinedFilter: this.props.combinedFilter });
	}

	onTextFilterChange = filter => {
		this.updateFilterState('textFilter', filter);
	}

	onFilterItemSelect = (type, filter) => {
		this.updateFilterState(type, filter);
		this.updateFilterRedux();
	}

	updateFilterState = (type, filter) => {
		this.setState({
			combinedFilter: {
				...this.state.combinedFilter,
				[type]: filter,
			}
		});
	}

	updateFilterRedux = () => {
		this.setState({ loading: true });

		if (this.updateTimeout) clearTimeout(this.updateTimeout);

		this.updateTimeout = setTimeout(() => {
			this.props.updateCombinedFilter(this.state.combinedFilter);
			this.setState({ loading: false });
		}, 1000);
	}

	handleFilterReset = () => {
		this.setState({ combinedFilter: defaultCombinedFilter });
		this.props.updateCombinedFilter(defaultCombinedFilter);
	}

	render() {
		const { height, brands, category1s, category2s, category3s, category4s } = this.props;
		const { combinedFilter, loading } = this.state;
		const { brandFilter, category1Filter, category2Filter, category3Filter, category4Filter, textFilter } = combinedFilter;

		const filterHeight = height - 115;

		return (
			<Row style={styles.filterContainer}>
				<Row>
					<Col xs={12}>
						{loading && <Spin size='small' style={{ paddingLeft: 10 }} />}
					</Col>

					<Col xs={12}>
						<Button type='link' size='small' style={{ float: 'right' }} onClick={this.handleFilterReset}>
							Reset filter
						</Button>
					</Col>
				</Row>

				<Row>
					<Col md={24} style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
						<Search
							placeholder='Input search text'
							size='default'
							color={'black'}
							value={textFilter}
							onChange={value => this.onTextFilterChange(value.target.value)}
							onSearch={() => this.updateFilterRedux()} />
					</Col>
				</Row>

				<Row style={styles.filterGrid}>
					<Col style={styles.filterColumn}>
						<span style={styles.title}>BRAND</span>
						<Filter
							onClickItem={selectedItems => this.onFilterItemSelect('brandFilter', selectedItems)}
							selectedElements={brandFilter}
							height={filterHeight}
							elements={brands}
						/>
					</Col>

					<Col style={styles.filterColumn}>
						<span style={styles.title}>CATEGORY 1</span>
						<Filter
							onClickItem={selectedItems => this.onFilterItemSelect('category1Filter', selectedItems)}
							selectedElements={category1Filter}
							height={filterHeight}
							elements={category1s} />
					</Col>

					<Col style={styles.filterColumn}>
						<span style={styles.title}>CATEGORY 2</span>
						<Filter
							onClickItem={selectedItems => this.onFilterItemSelect('category2Filter', selectedItems)}
							selectedElements={category2Filter}
							height={filterHeight}
							elements={category2s} />
					</Col>

					<Col style={styles.filterColumn}>
						<span style={styles.title}>CATEGORY 3</span>
						<Filter
							onClickItem={selectedItems => this.onFilterItemSelect('category3Filter', selectedItems)}
							selectedElements={category3Filter}
							height={filterHeight}
							elements={category3s} />
					</Col>

					<Col style={styles.filterColumn}>
						<span style={styles.title}>CATEGORY 4</span>
						<Filter
							onClickItem={selectedItems => this.onFilterItemSelect('category4Filter', selectedItems)}
							selectedElements={category4Filter}
							height={filterHeight}
							elements={category4s} />
					</Col>
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = state => {
	return {
		brands: getBrands(state),
		category1s: getCategory1s(state),
		category2s: getCategory2s(state),
		category3s: getCategory3s(state),
		category4s: getCategory4s(state),
		combinedFilter: getCombinedFilter(state),
	};
};

export default connect(mapStateToProps, { updateFilter, updateCombinedFilter })(AdvancedFilter);

const styles = {
	searchButton: {
		color: '#FFFFFF',
		backgroundColor: ProMateGreen,
		borderColor: ProMateGreenBorder,
	},
	filterGrid: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0px 10px',
	},
	filterColumn: {
		width: '25%',
		textAlign: 'center',
		fontSize: 10,
		fontWeight: 600,
		padding: 2,
	},
	filterContainer: {
		marginTop: 15,
		boxShadow: '0 -1px 4px #0000003d',
		padding: '5px 0px',
		backgroundColor: 'white',
	},
	title: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
};