import { combineReducers } from 'redux';

export const ACTION_TYPES = {
	FETCH_SALES_USER_HIERARCHY: 'FETCH_SALES_USER_HIERARCHY'
}

export const hierarchy = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_SALES_USER_HIERARCHY:
			return action.hierarchy;
		default:
			return state;
	}
};

export const flatPositions = (state = {}, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_SALES_USER_HIERARCHY:
			return generateFlatPositions(action.hierarchy);
		default:
			return state;
	}
}

export default combineReducers({
	hierarchy,
	flatPositions
});

const generateFlatPositions = (hierarchy, parentPositionCode) => {
	let positions = {};
	Object.values(hierarchy).forEach(position => {
		positions[position.positionCode] = { ...position, parentPositionCode };
		positions = { ...positions, ...generateFlatPositions(position.descendantStructure, position.positionCode) };
	});
	return positions;
}

export const getHierarchy = state => state.hierarchy;

export const getTopPosition = state => Object.values(state.hierarchy)[0];

export const getFlatPositions = state => state.flatPositions;

export const getHierarchyBranch = (state, positionCode) => {
	const branch = {};
	while (true) {
		const position = state.flatPositions[positionCode];
		if (!position) return branch;
		branch[position.positionType] = position;
		positionCode = position.parentPositionCode;
	}
}