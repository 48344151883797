import { Col, Table } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdvanceSearchFilters from "../../components/common/AdvanceSearchFilters";
import { fetchFailedSalesHeadersFromLocalAPI } from '../../redux/actions/failedOrdedrs';
import { getAllFailedHeaders } from "../../redux/reducers";
import TableFooter from "../common/TableFooter";
import CreditNoteCard from "./CreditNoteCard";
import CreditNoteCardHeader from "./CreditNoteCardHeader";
import InvoiceCard from "./InvoiceCard";
import InvoiceCardHeader from "./InvoiceCardHeader";
import OrderCard from "./OrderCard";
import OrderCardHeader from "./OrderCardHeader";
import ReturnCard from "./ReturnCard";
import ReturnCardHeader from "./ReturnCardHeader";

class OrderTable extends Component {
  state = {
    filteredOrders: [],
  }

  componentDidMount = () => {
    const { fetchFailedSalesHeadersFromLocalAPI, filter: { from, to } } = this.props;
    window.addEventListener('locationchange', this.onUrlChange);
    // this.setState({ filteredOrders: this.props.orders });
    fetchFailedSalesHeadersFromLocalAPI({ from: from.format('MM/DD/YY'), to: to.format('MM/DD/YY') });
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  getFilterFieldPropertiesSalesOrders = () => {
    const { customerId } = this.props.match.params;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    if (this.props.authData.roleCode != 'CSR') {
      return {
        SALES_HIERARCHY: entireColumn,
        SELECT_ROUTE: customerId ? hidden : oneSixthOfColumn,
        SELECT_OUTLET: customerId ? hidden : oneSixthOfColumn,
        SELECT_CATEGORY: customerId ? hidden : oneSixthOfColumn,
        SELECT_SUBCATEGORY: customerId ? hidden : oneSixthOfColumn,
        SELECT_PAYMENT_METHOD: customerId ? oneForthOfColumn : oneSixthOfColumn,
        SELECT_PAYMENT_TERM: customerId ? oneForthOfColumn : oneSixthOfColumn,
        FROM_DATE_PICKER: customerId ? oneForthOfColumn : oneSixthOfColumn,
        TO_DATE_PICKER: customerId ? oneForthOfColumn : oneSixthOfColumn,
        SELECT_STATUS: customerId ? fiveBars : oneEighthOfColumn,
        SEARCH_TEXT: { ...(customerId ? fiveBars : oneEighthOfColumn), placeholder: 'Order No' },
        SELECT_ORDER_TYPE: customerId ? fiveBars : oneEighthOfColumn,
        SELECT_DISCOUNT_REASON: customerId ? fiveBars : oneEighthOfColumn,
        SEARCH_BUTTON: customerId ? oneTwelwthOfColumn : oneTwelwthOfColumn,
        GENERATE_PICK_LIST: customerId ? oneTwelwthOfColumn : oneTwelwthOfColumn,
      };
    } else {
      return {
        SALES_HIERARCHY: entireColumn,
        SELECT_ROUTE: customerId ? hidden : oneSixthOfColumn,
        SELECT_OUTLET: customerId ? hidden : oneSixthOfColumn,
        SELECT_CATEGORY: customerId ? hidden : oneSixthOfColumn,
        SELECT_SUBCATEGORY: customerId ? hidden : oneSixthOfColumn,
        SELECT_PAYMENT_METHOD: customerId ? oneSixthOfColumn : oneSixthOfColumn,
        SELECT_PAYMENT_TERM: customerId ? oneSixthOfColumn : oneSixthOfColumn,
        FROM_DATE_PICKER: customerId ? oneSixthOfColumn : fiveBars,
        TO_DATE_PICKER: customerId ? oneSixthOfColumn : fiveBars,
        SELECT_STATUS: customerId ? oneEighthOfColumn : fiveBars,
        SEARCH_TEXT: { ...(customerId ? oneEighthOfColumn : fiveBars), placeholder: 'Order No' },
        SEARCH_BUTTON: customerId ? oneTwelwthOfColumn : oneSixthOfColumn
      };
    }
  }

  getFilterFieldPropertiesSalesReturns = () => {
    const { customerId } = this.props.match.params;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneThirdfColumn = { lg: 8, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    return {
      SALES_HIERARCHY: entireColumn,
      SELECT_ROUTE: customerId ? hidden : oneForthOfColumn,
      SELECT_OUTLET: customerId ? hidden : oneForthOfColumn,
      SELECT_CATEGORY: customerId ? hidden : oneForthOfColumn,
      SELECT_SALES_RETRUN_REASONS: customerId ? oneSixthOfColumn : oneForthOfColumn,
      FROM_DATE_PICKER: customerId ? oneSixthOfColumn : fiveBars,
      TO_DATE_PICKER: customerId ? oneSixthOfColumn : fiveBars,
      SELECT_STATUS: customerId ? oneSixthOfColumn : fiveBars,
      SEARCH_TEXT: { ...(customerId ? oneForthOfColumn : fiveBars), placeholder: 'Return No' },
      SEARCH_BUTTON: customerId ? oneTwelwthOfColumn : oneSixthOfColumn
    };
  }

  getFilterFieldPropertiesCreditNotes = () => {
    const { customerId } = this.props.match.params;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneThridOfColumn = { lg: 8, md: 24, sm: 24, xs: 24 };
    const oneFourthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    return {
      SALES_HIERARCHY: entireColumn,
      SELECT_ROUTE: customerId ? hidden : oneThridOfColumn,
      SELECT_OUTLET: customerId ? hidden : oneThridOfColumn,
      SELECT_CATEGORY: customerId ? hidden : oneThridOfColumn,
      FROM_DATE_PICKER: oneFourthOfColumn,
      TO_DATE_PICKER: oneFourthOfColumn,
      SELECT_RETURN_REASON: oneFourthOfColumn,
      SEARCH_BUTTON: oneFourthOfColumn
    };
  }

  getFilterFieldProperties = docType => {
    if (docType === 'CreditMemo') {
      return this.getFilterFieldPropertiesCreditNotes();
    } else if (docType === 'ReturnOrder') {
      return this.getFilterFieldPropertiesSalesReturns();
    } else {
      return this.getFilterFieldPropertiesSalesOrders();
    }
  }

  getTableHeaders = docType => {
    if (docType === 'Order') {
      return <OrderCardHeader
        checkedAll={this.props.checkedAll}
        handleAllChecked={this.props.handleAllChecked}
      />;
    } else if (docType === 'ReturnOrder') {
      return <ReturnCardHeader />
    } else if (docType === 'CreditMemo') {
      return <CreditNoteCardHeader />;
    } else {
      return <InvoiceCardHeader />;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, match, setDefaultCustomerOption, setDefaultRouteOption, handleGeneratePickList, isLoadingSummary } = this.props;
    const { customerId, docType } = match.params

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          {...this.getFilterFieldProperties(docType)}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleGeneratePickList={handleGeneratePickList}
          handleInputDateChange={handleInputDateChange}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
          filter={filter}
          isLoadingSummary={isLoadingSummary}
        />
        {this.getTableHeaders(docType)}
      </div>
    )
  }

  getOrderTotals = orders => {
    let total = 0;
    orders.forEach(order => {
      let AmountIncluVAT = order.AmountIncluVAT.replace(/,/g, "");
      total = total + parseFloat(AmountIncluVAT);
    })
    return total;
  }

  getNumberOfRecordLabel = () => {
    const { docType } = this.props.match.params;
    switch (docType) {
      case 'Order':
        return 'Orders';
      case 'Invoice':
        return 'Invoices';
      case 'CreditMemo':
        return 'Credit Notes';
      default:
        return undefined;
    }
  }

  renderFooter = () => {
    const { total, totalAmount, currentPage, onPaginationChange } = this.props;
    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={totalAmount}
        noOfRecordLabel={this.getNumberOfRecordLabel()}
        style={{ paddingRight: 33 }}
      />
    );
  }

  renderColumns = () => {
    const { match } = this.props;
    const { docType } = match.params;

    if (docType === 'Order') {
      return [{
        render: order => <OrderCard order={order}
          handleOnChange={this.props.handleOnChange} />
      }]
    } else if (docType === 'ReturnOrder') {
      return [{
        render: order => <ReturnCard order={order} />
      }]
    } else if (docType === 'CreditMemo') {
      return [{
        render: order => <CreditNoteCard order={order} />
      }]
    } else {
      return [{
        render: order => <InvoiceCard order={order} />
      }]
    }
  }

  render() {
    const { orders: filteredOrders, match, headers: { list } } = this.props;
    const { docType } = match.params
    return (
      <Col>
        <Table
          rowKey={'orderNo'}
          rowClassName={(record, index) => [...list].find(header => header.no === record.No) ? 'orderRow table-row-danger' : 'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders) }}
          columns={this.renderColumns()}
          dataSource={filteredOrders}
          size={'small'}
          pagination={false}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(filteredOrders)} />
      </Col>
    )
  }
}

OrderTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  headers: getAllFailedHeaders(state)
})

const mapDispatchToProps = {
  fetchFailedSalesHeadersFromLocalAPI
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderTable));