import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchItems } from '../../../redux/actions/items';
import { fetchStockTakeLines, updateStockTakeLine } from '../../../redux/actions/stockTakeLines';
import { getAuthData, getStockTakeLine, getItem, getStockTakesHeadersByNo, getLoggedInUser } from "../../../redux/reducers";
import { fetchStockTakesHeaders } from '../../../redux/actions/stockTakeHeaders';
import Details from "./Details";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';

class Container extends Component {
  state = {
    stockTakeLine: undefined
  };

  componentDidMount() {
    this.loadStockTakeLines();
    this.loadInventoryItem(this.props);
  }

  loadStockTakeLines = () => {
    const { stockTakeId } = this.props.match.params;
    this.props.fetchStockTakesHeaders({ No: decodeURIComponent(stockTakeId) });
    this.props.fetchStockTakeLines({ DocNo: decodeURIComponent(stockTakeId) });
  }

  componentWillReceiveProps = nextProps => {
    this.loadInventoryItem(nextProps);
  }

  inventoryItemRequestSent = false;
  loadInventoryItem = props => {
    const { stockTakeLine, fetchItems } = props;
    const { docType } = this.props.match.params;
    if (this.inventoryItemRequestSent || !stockTakeLine) return;

    this.setState({ stockTakeLine });
    this.inventoryItemRequestSent = true;
    const { CreatedUserID, No, LocCode, LotNo } = stockTakeLine;

    fetchItems({ UserCode: CreatedUserID, ItemNo: No, LocCode, LotNo, StockTake: 'Yes' });
  }

  handleUpdateOrder = () => this.handleRequest(this.props.updateStockTakeLine, 'Line updated successfully.');

  handleRequest = (requestCall, successMessage) => {
    const { authData, deletePurchaseLine, history, toastManager, location } = this.props;
    const { docType } = this.props.match.params;
    this.setState({ loading: true });
    let salesLineObject = undefined

    if (docType === 'ReturnOrder') {
      salesLineObject = { ...this.state.stockTakeLine, UserCode: authData.userId, ReturnInvDocNo: this.state.stockTakeLine.ReturnInvDocNo, ReturnInvLineNo: this.state.stockTakeLine.ReturnInvLineNo }
    } else {
      salesLineObject = { ...this.state.stockTakeLine, UserCode: authData.userId }
    }

    requestCall(salesLineObject).then(({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        if (docType === 'ReturnOrder') {
          history.goBack();
        } else {
          if (location.state && location.state.prevPath && location.state.currentPage) {
            history.replace({ pathname: location.state.prevPath, state: { currentPage: location.state.currentPage } })
          } else {
            history.goBack();
          }
        }
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = () => this.setState({ stockTakeLine: { ...this.props.stockTakeLine } });

  handleUpdateNumberField = (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    if (key === 'DisPercentage') {
      if (value < 0) value *= -1;
      if (value > 100) value = 100;
    }

    this.setState({ stockTakeLine: { ...this.state.stockTakeLine, [key]: '' + value } })
  }

  handleUpdateTextField = (key, value) => {

    this.setState({ stockTakeLine: { ...this.state.stockTakeLine, [key]: value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  render() {
    const { match, resource, inventoryItem, stockTakeLine: stockTakeLineDatabase, stockTakeHeader, loggedInUser } = this.props;
    const { stockTakeLine, loading } = this.state;
    return (
      <Spin spinning={loading || !stockTakeLine || !inventoryItem} tip={"Loading item"}>
        {(inventoryItem && stockTakeLine) ?
          <Details
            inventoryItem={inventoryItem}
            onUpdateNumberField={this.handleUpdateNumberField}
            onUpdateTextField={this.handleUpdateTextField}
            docLine={stockTakeLine}
            docLineItemDatabase={stockTakeLineDatabase}
            onUpdateOrder={this.handleUpdateOrder}
            onResetLine={this.handleResetSalesLine}
            resource={resource}
            loggedInUser={loggedInUser}
            newOrderItem={match && match.params.lineId === 'new'}
            stockTakeHeader={stockTakeHeader}
          /> :
          <div style={{ width: '100%', height: 200 }} />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakeId, lineId } = ownProps.match.params;
  const stockTakeLine = getStockTakeLine(state, decodeURIComponent(stockTakeId), lineId);
  const stockTakeHeader = getStockTakesHeadersByNo(state, decodeURIComponent(stockTakeId));
  let inventoryItem;
  if (stockTakeLine) {
    const { ItemNo, LotNo, LocCode } = stockTakeLine;
    let itemId = ItemNo + '-' + LotNo + '-' + LocCode
    inventoryItem = itemId && getItem(state, itemId);
  }

  return {
    authData: getAuthData(state),
    inventoryItem,
    stockTakeLine,
    stockTakeHeader,
    loggedInUser: getLoggedInUser(state)
  };
};


export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchItems,
        fetchStockTakeLines,
        updateStockTakeLine,
        fetchStockTakesHeaders
      }
    )(Container)
  )
);