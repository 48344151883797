import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { dateFormatView, dateTimeFormatView } from '../../../const/Formats';
import { formatDateTime } from "../../../utils/Formats";

class CallCard extends Component {
  getStatusColor = status => {
    if (status === "Open") return 'green';
    else return 'red';
  }

  render() {
    const { call, history } = this.props;
    const { match } = this.props;
    const url = match.url
    const { No, CustNo, CustName, UserCode, StartDateTime, Status, EndDateTime, UserName, Productive, TransactionType, SalesValue } = call;

    const startTimeInLocalTime = StartDateTime && moment(StartDateTime).add('minutes', +330).format(dateTimeFormatView)
    const endTimeInLocalTime = EndDateTime && moment(EndDateTime).add('minutes', +330).format(dateTimeFormatView)

    return (
      <Row onClick={() => history.push(url + "/" + No)}>
        <Row style={styles.alignRow}>
          <Col xs={8} sm={8} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.code}>{No}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2}>
            <Tag style={styles.statusBottom} color={this.getStatusColor(Status)}>{Status || 'Active'}
            </Tag></Col>

          <Col xs={0} sm={0} md={4} lg={5} style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}>{CustNo}</div>
            <div style={{ textAlign: 'left' }}>{CustName}</div>
          </Col>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.customerCode}>{CustNo}</span>
          </Col>

          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span >{CustName}</span>
          </Col>

          <Col xs={0} sm={0} md={4} lg={4} style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'left' }}><b>S :</b> {startTimeInLocalTime || 'Pending...'}</div>
            <div style={{ textAlign: 'left' }}><b>E :</b> {endTimeInLocalTime || 'Pending...'}</div>
          </Col>

          <Col xs={0} sm={0} md={3} lg={4} style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}>{UserCode}</div>
            <div style={{ textAlign: 'left' }}>{UserName}</div>
          </Col>

          <Col xs={0} sm={0} md={4} lg={3} style={{ textAlign: 'left' }}>
            {TransactionType}
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'center' }}>
            <span>{Productive ? 'Yes' : 'No'}</span>
          </Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{Productive ? 'Yes' : 'No'}</span>
          </Col>

          <Col xs={8} sm={8} md={4} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={SalesValue || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>


        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={8} md={0} lg={0}><Tag style={styles.statusBottom} color={this.getStatusColor(Status)}>{Status || 'Active'}</Tag></Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{CustNo}</span>
            <br />
            <span>{CustName}</span>
          </Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{UserCode}</span>
            <br />
            <span>{UserName}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={9} sm={9} md={0} lg={0} style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}><b>S :</b> {startTimeInLocalTime || 'Pending...'}</div>
          </Col>
          <Col xs={9} sm={9} md={0} lg={0} style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}><b>E :</b> {endTimeInLocalTime || 'Pending...'}</div>
          </Col>

          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}>{TransactionType}</div>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    // fontWeight: 600,
    wordBreak: 'break-all'
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 10
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },

}
export default withRouter(CallCard);