import { Col, Row, Select, TreeSelect } from "antd";
import moment from "moment";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { fetchAllMonths, fetchTargetSchedulerDetailsByFilter, updateFilter } from "../../../redux/actions/targetScheduler";
import { fetchTerritories } from "../../../redux/actions/territories";
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { getAllMonths, getAuthData, getFilter, getSalesUserHierarchy } from "../../../redux/reducers";
import { getURLParams } from "./helpers";

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

export class UserHierachy extends Component {
    constructor(props) {
        super(props);
        this.treeData = [];
    }

    componentDidMount = async () => {
        const { fetchSalesUserHierarchy, authData, updateFilter } = this.props;

        let results = await fetchSalesUserHierarchy(authData);
        results = results.records.map((result) => {
            return JSON.parse(result.jSONSalesHichyStruct);
        });

        for (const record of results) {
            this.generateTreeObject(record);
        }

        const urlParams = await getURLParams(this.props);

        if (urlParams) {
            await updateFilter({
                UserTag: urlParams.UserTag,
                treeData: this.treeData,
            }, this.props);
        } else {
            await updateFilter({
                UserTag: this.treeData.length > 0 ? this.treeData[0].value : '',
                treeData: this.treeData,
                Month: String(moment().format("MMM")).toUpperCase(),
                Year: moment().format("YYYY"),
            }, this.props);
        }
    }

    generateTreeObject = (input) => {
        const arr = Object.values(input)
        return arr.map(item => {
            const { user } = item;
            if (user?.role === "CSR") {
                this.treeData.push(
                    {
                        title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                        key: item.positionCode,
                        value: item.positionCode,
                        role: user?.role
                    }
                );
            }
            return {
                title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                key: item.positionCode,
                value: item.positionCode,
                children: this.generateTreeObject(item.descendantStructure)
            }
        })
    }

    onChange = async (value) => {
        const { updateFilter, onUpdate } = this.props;
        await updateFilter({ UserTag: value }, this.props);
        onUpdate(value);
    }

    render() {
        const { filter } = this.props;
        return (
            <Row>
                <Col span={24} style={{ padding: "5px 12px" }}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a CSR"
                        optionFilterProp="children"
                        onChange={this.onChange}
                        value={filter.UserTag}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            filter.treeData.map((item, index) => (
                                <Option key={index} value={item.value}>{item.title}</Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    months: getAllMonths(state),
    filter: getFilter(state)
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    updateFilter,
    fetchTerritories,
    fetchTargetSchedulerDetailsByFilter
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserHierachy)));


const styles = {
    text: {
        color: '#000000',
        textAlign: "center"
    }
}