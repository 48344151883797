import { Col, Row, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ROUTE_CUSTOMER_TARGET } from '../../../../const/Permissions';
import { RouteTargetStatus } from '../../../../const/status';
import { updateCustomerVisitTarget } from '../../../../redux/actions/targetScheduler';
import { getAllCustomerVisitTargets } from '../../../../redux/reducers';
import { StyledButton } from '../../common/Buttons';
import { trimDecimals, withOutThousandSeperator, withThousandSeperator } from '../../common/helpers';

const { Text } = Typography;
class DataTable extends Component {
    state = {
        pagination: {
            pageSize: 20
        },
    };

    columns = [
        {
            title: 'Code',
            dataIndex: 'CustCode',
            width: 100,
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{record}</Text>
            }
        },
        {
            title: 'Name',
            width: 200,
            dataIndex: 'CustName',
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{record}</Text>
            }
        },
        {
            title: 'Frq.',
            width: 50,
            dataIndex: 'Freq',
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{record}</Text>
            }
        },
        {
            title: 'Last Month TGT',
            dataIndex: 'LastMonthTarget',
            width: 60,
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{trimDecimals(record)}</Text>
            }
        },
        {
            title: 'Scheduled TGT',
            dataIndex: 'PlannedTarget',
            width: 60,
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{trimDecimals(record)}</Text>
            }
        },
        {
            title: 'Visit 1 Date',
            dataIndex: 'VisitDateOne',
            width: 50,
            render: (record, row) => {
                if (!row) {
                    return <Text style={{ fontSize: 12 }}>-</Text>
                }
                const datesArray = String(row.PlannedVisitDays).split(',');
                return <Text style={{ fontSize: 12 }}>{datesArray[0] ?? "-"}</Text>
            }
        },
        {
            title: 'Visit 1 Value',
            dataIndex: 'VisitValueOne',
            width: 50,
            key: '5',
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{trimDecimals(record)}</Text>
            }
        },
        {
            title: 'Visit 2 Date',
            dataIndex: 'VisitDateTwo',
            key: '6',
            width: 50,
            render: (record, row) => {
                if (!row) {
                    return <Text style={{ fontSize: 12 }}>-</Text>
                }
                const datesArray = String(row.PlannedVisitDays).split(',');
                return <Text style={{ fontSize: 12 }}>{datesArray[1] ?? "-"}</Text>
            }
        },
        {
            title: 'Visit 2 Value',
            dataIndex: 'VisitValueTwo',
            width: 50,
            key: '7',
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{trimDecimals(record)}</Text>
            }
        },
        {
            title: 'Visit 3 Date',
            dataIndex: 'VisitDateThree',
            width: 50,
            render: (record, row) => {
                if (!row) {
                    return <Text style={{ fontSize: 12 }}>-</Text>
                }
                const datesArray = String(row.PlannedVisitDays).split(',');
                return <Text style={{ fontSize: 12 }}>{datesArray[2] ?? "-"}</Text>
            }
        },
        {
            title: 'Visit 3 Value',
            dataIndex: 'VisitValueThree',
            width: 50,
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{trimDecimals(record)}</Text>
            }
        },
        {
            title: 'Visit 4 Date',
            dataIndex: 'VisitDateFour',
            width: 50,
            render: (record, row) => {
                if (!row) {
                    return <Text style={{ fontSize: 12 }}>-</Text>
                }
                const datesArray = String(row.PlannedVisitDays).split(',');
                return <Text style={{ fontSize: 12 }}>{datesArray[3] ?? "-"}</Text>
            }
        },
        {
            title: 'Visit 4 Value',
            dataIndex: 'VisitValueFour',
            width: 50,
            render: (record) => {
                return <Text style={{ fontSize: 12 }}>{trimDecimals(record)}</Text>
            }
        },
        {
            title: '',
            key: 'approve',
            width: 100,
            render: (record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                        {this.props.permissions.includes(ROUTE_CUSTOMER_TARGET.APPROVE) && ((record.Approved === RouteTargetStatus.OPEN) || (record.Approved === RouteTargetStatus.REJECTED)) && (<StyledButton color="#0f8a86" icon={<FaCheck />} width={40} onClick={() => this.onApprove(record)} />)}
                        {this.props.permissions.includes(ROUTE_CUSTOMER_TARGET.REJECT) && ((record.Approved === RouteTargetStatus.OPEN) || (record.Approved === RouteTargetStatus.APPROVED)) && (<StyledButton color="#a60d33" icon={<FaTimes />} width={40} onClick={() => this.onReject(record)} />)}
                    </div>
                );
            },
        }
    ];

    renderFooter = () => {
        const { customerVisitTargets } = this.props;

        const LastMonthTarget = customerVisitTargets.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['LastMonthTarget']) == '' ? 0 : withOutThousandSeperator(currentValue['LastMonthTarget']))), 0);
        const PlannedTarget = customerVisitTargets.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['PlannedTarget']) == '' ? 0 : withOutThousandSeperator(currentValue['PlannedTarget']))), 0);
        const VisitValueOne = customerVisitTargets.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueOne']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueOne']))), 0);
        const VisitValueTwo = customerVisitTargets.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueTwo']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueTwo']))), 0);
        const VisitValueThree = customerVisitTargets.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueThree']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueThree']))), 0);
        const VisitValueFour = customerVisitTargets.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueFour']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueFour']))), 0);

        return (
            <div style={{ background: "#e1e1e1", padding: "5px" }}>
                <Row>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Customers: <span style={{ fontWeight: "bold", fontSize: 12 }}>{customerVisitTargets.recordTotal ?? 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Target Last Month: <span style={{ fontWeight: "bold", fontSize: 12 }}>{trimDecimals(LastMonthTarget)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Scheduled Target: <span style={{ fontWeight: "bold", fontSize: 12 }}>{trimDecimals(PlannedTarget)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Visit 1 Value: <span style={{ fontWeight: "bold", fontSize: 12 }}>{trimDecimals(VisitValueOne)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Visit 2 Value: <span style={{ fontWeight: "bold", fontSize: 12 }}>{trimDecimals(VisitValueTwo)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Visit 3 Value: <span style={{ fontWeight: "bold", fontSize: 12 }}>{trimDecimals(VisitValueThree)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Visit 4 Value: <span style={{ fontWeight: "bold", fontSize: 12 }}>{trimDecimals(VisitValueFour)}</span></span>
                    </Col>
                </Row>
            </div >
        );
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    onApprove = async (record) => {
        const { toastManager, setLoading, updateCustomerVisitTarget } = this.props;
        setLoading(true);
        if (record.Approved === RouteTargetStatus.APPROVED) {
            toastManager.add("Route customer targets already approved.", { autoDismiss: true, appearance: 'info' });
        } else {
            const updatedData = await updateCustomerVisitTarget({ ...record, Approved: RouteTargetStatus.APPROVED });
            if (!updatedData.error) {
                toastManager.add('Route customer targets was successfully approved.', { autoDismiss: true, appearance: 'success' });
            }
        }
        setLoading(false);
    }

    onReject = async (record) => {
        const { toastManager, setLoading, updateCustomerVisitTarget } = this.props;
        setLoading(true);
        if (record.Approved === RouteTargetStatus.REJECTED) {
            toastManager.add("Route customer targets already rejected.", { autoDismiss: true, appearance: 'info' });
        } else {
            const updatedData = await updateCustomerVisitTarget({ ...record, Approved: RouteTargetStatus.REJECTED });
            if (!updatedData.error) {
                toastManager.add('Route customer targets was successfully rejected.', { autoDismiss: true, appearance: 'success' });
            }
        }
        setLoading(false);
    }

    render() {
        const { customerVisitTargets, loading, selectedRowKeys, setSelectedRowKeys } = this.props;

        return (
            <div style={{ padding: 10, marginTop: 5 }}>
                <Table showTotal
                    size='small'
                    columns={this.columns}
                    rowKey={record => record.CustCode}
                    dataSource={customerVisitTargets.records}
                    pagination={this.state.pagination}
                    loading={loading}
                    footer={() => this.renderFooter()}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys
                    }}
                    scroll={{ x: 1600 }}
                // expandedRowRender={record => <p style={{ margin: 0 }}>{record.territory}</p>}
                // onChange={this.handleTableChange}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customerVisitTargets: getAllCustomerVisitTargets(state),
})

const mapDispatchToProps = {
    updateCustomerVisitTarget
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable)));

const styles = {
    text: {
        color: '#000000',
        textAlign: "left",
        fontWeight: "bold"
    }
}