import Api from './consts';
import fetch from './commonFetch';

export const getAll = (filter = {}) => {
  const { url, method } = Api.generic.get;
  return fetch(url('Customer'), { method, body: JSON.stringify(filter) }, 'customers');
}

export const get = customerId => {
  const { url, method } = Api.generic.get;

  return fetch(url('Customer'), { method, body: JSON.stringify({ No: customerId }) }, 'customers');
}

export const add = customer => {
  const { url, method } = Api.customers.add;

  return fetch(url(), { method, body: JSON.stringify(customer) }, 'customer');
}

export const getCustomerCategories = (filter = {}) => {
  //const { url, method } = Api.customers.categories.getAll;
  const { url, method } = Api.generic.get;
  return fetch(url('CustomerCategory'), { method, body: JSON.stringify(filter) }, 'customerCategories');
}

export const update = customer => {
  const { url, method } = Api.customers.update;

  return fetch(url(), { method, body: JSON.stringify(customer) }, 'customer');
}