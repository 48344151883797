import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchCustomerCategories } from '../../redux/actions/customers'
import { getCustomerCategories } from "../../redux/reducers";

const { Option } = Select;

class SelectCustomerCategory extends Component {
  componentDidMount = () => {
    this.props.fetchCustomerCategories();
  }

  setDefaultFilter = () => {
    const { customerCategories, onChange, value } = this.props;
    if (customerCategories.length === 1 && value !== customerCategories[0].Code) {
      setTimeout(() => {
        onChange('OutletCategoryCode', customerCategories[0].Code, true);
      }, 0);
    }
  }

  render() {
    this.setDefaultFilter();

    const {
      customerCategories,
      onChange,
      showSearch = false,
      placeholder = 'Select Category',
      allowClear = true,
      value
    } = this.props
    return (
      <Select
        disabled={customerCategories.length < 2}
        placeholder={placeholder}

        onChange={value => onChange('OutletCategoryCode', value)}
        allowClear={allowClear}
        showSearch={showSearch}
      >
        {customerCategories.map(category =>
          <Option value={category.Code}>{category.Description}</Option>
        )}
      </Select>
    )
  }
}

const mapStateToProps = state => {
  return {
    customerCategories: getCustomerCategories(state),
  };
};

export default connect(mapStateToProps, { fetchCustomerCategories })(SelectCustomerCategory);