import { combineReducers } from 'redux';
import { FETCH_PURCHASE_ALLOCATED_DETAILS } from '../actions/purchaseAllocatedDetails';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_PURCHASE_ALLOCATED_DETAILS:
			const newState = {};

			action.allocatedData.forEach(allocated => {
				const key = allocated.DocNo + "_" + allocated.DocDate
				newState[key] = allocated
			});
			return newState;
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_PURCHASE_ALLOCATED_DETAILS:
			return action.allocatedData.map(allocated => {
				const key = allocated.DocNo + "_" + allocated.DocDate
				return key
			});
		default:
			return state;
	}
};


export const byDocId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_PURCHASE_ALLOCATED_DETAILS:
			const newState = {};
			const ids = newState[action.filter.DocNo] || [];
			action.allocatedData.forEach(allocated => {
				//const key = allocated.DocNo + "_" + allocated.DocDate
				ids.push(allocated);
			});
			newState[action.filter.DocNo] = ids;
			return newState;
		default:
			return state;
	}
};




const allocatedData = combineReducers({
	byId,
	ids,
	byDocId
});

export default allocatedData;

export const getAllocatedData = state => {
	return state.ids.map(id => state.byId[id]);
}

export const getAllocatedDataByDocNo = (state, DocNo) => {
	const ids = state.byDocId[DocNo] || [];
	return ids
}
