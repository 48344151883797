import React, { Component } from 'react';
import { Form, Row, Col } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import { EnglishTextArea as TextArea } from '../../common/EnglishInput';
import AllocatedDetails from '../../Receipt/component/AllocatedDetails';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class OrderFooter extends Component {

  render() {
    const { enableDeallocate, onDataAllocated } = this.props;
    const { No, Amount, AmountIncluVAT, DisAmount, DisPersentage, Remarks, AvgDisPersentage, DocType, RemAmount } = this.props.order;
    const inludeVatAmount = AmountIncluVAT && AmountIncluVAT.replace(/,/g, '');
    const remAmount = RemAmount && RemAmount.replace(/,/g, '');
    const discount = DisAmount && DisAmount.replace(/,/g, '');
    const grossAmount = parseFloat(inludeVatAmount) + parseFloat(discount);
    const allocatedAmount = parseFloat(inludeVatAmount) - parseFloat(remAmount);
    return (
      <div className='promate-form-header'>
        <Form>
          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Col span={24}>
              <Col lg={12} md={12} sm={0} xs={0}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <TextArea value={Remarks} rows={4} placeholder={"Type here"} />
                </FormItem>
                <Col xs={0} sm={0} md={24} lg={24}>
                  <AllocatedDetails docType={"3"} docNo={No} isMobileView={false} enableDeallocate={enableDeallocate} onDataAllocated={onDataAllocated} />
                </Col>

              </Col>
              <Col lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
                {DocType !== 'CREDIT MEMO' && [<Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Gross Amount</div>
                  <div style={{ fontSize: "large" }}>{format(grossAmount, currencyFormat)}</div>
                </Col>,
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Discount ({AvgDisPersentage}%) </div>
                  <div style={{ fontSize: "large" }}>{format(DisAmount, currencyFormat)}</div>
                </Col>]}
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>{DocType !== 'CREDIT MEMO' ? 'Net Amount' : 'Total'}</div>
                  <div style={{ fontSize: "large" }}>{format(AmountIncluVAT, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>{DocType !== 'CREDIT MEMO' ? 'Allocated Amount' : 'Allocated Amount'}</div>
                  <div style={{ fontSize: "large" }}>{format(allocatedAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>{DocType !== 'CREDIT MEMO' ? 'Balance' : 'Balance'}</div>
                  <div style={{ fontSize: "large" }}>{format(RemAmount, currencyFormat)}</div>
                </Col>

              </Col>
              <Col lg={0} md={0} sm={24} xs={24}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <TextArea value={Remarks} rows={4} placeholder={"Type here"} />
                </FormItem>
                <Col lg={0} md={0} sm={24} xs={24}>
                  <AllocatedDetails docType={"3"} docNo={No} isMobileView={true} enableDeallocate={enableDeallocate} onDataAllocated={onDataAllocated} />
                </Col>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default OrderFooter;