import React, { Component } from 'react';
import { Table, Button } from "antd";
import { connect } from "react-redux";
import { getPurchaseLinesByDocTypeAndDocNo, getLoggedInUser } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { SALES_INVOICES, PURCHASING_ORDERS, SALES_RETURNS, CREDIT_NOTES } from "../../../const/Permissions";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTable extends Component {
  getColumnsDefault = () =>
    [
      {
        title: 'No',
        align: 'center',
        width: 35,
        render: (unitPrice, object, index) => (index + 1)
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '49%',
        render: (Description, item) => item.No + ' - ' + Description
      },
      {
        title: 'Order Qty',
        dataIndex: 'Quantity',
        key: 'Quantity',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Invoiced Qty',
        dataIndex: 'QtyToInvoice',
        key: 'QtyToInvoice',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitCost',
        key: 'UnitCost',
        align: 'right',
        width: '8%',
        render: unitPrice => format(unitPrice, currencyFormat)
      },
      {
        title: 'Discount %',
        dataIndex: 'DisPercentage',
        key: 'DisPercentage',
        width: '8%',
        align: 'right',
        render: DisPercentage => format(DisPercentage, currencyFormat)
      },
      {
        title: 'Discount',
        dataIndex: 'DisAmount',
        key: 'DisAmount',
        width: '8%',
        align: 'right',
        render: DisAmount => format(DisAmount, currencyFormat)
      },
      {
        title: 'Total',
        dataIndex: 'LineAmount',
        key: 'LineAmount',
        align: 'right',
        width: '8%',
        render: LineAmount => format(LineAmount, currencyFormat)
      }
    ];

  getColumnsReturnInfo = () =>
    [
      {
        title: 'No',
        align: 'center',
        width: 35,
        render: (unitPrice, object, index) => (index + 1)
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '49%',
        render: (Description, item) => item.LotNo + ' - ' + Description
      },
      {
        title: 'Quantity',
        dataIndex: 'Quantity',
        key: 'Quantity',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitCost',
        key: 'UnitCost',
        align: 'right',
        width: '8%',
        render: unitPrice => format(unitPrice, currencyFormat)
      },
      {
        title: 'Discount %',
        dataIndex: 'DisPercentage',
        key: 'DisPercentage',
        width: '8%',
        align: 'right',
        render: DisPercentage => format(DisPercentage, currencyFormat)
      },
      {
        title: 'Discount',
        dataIndex: 'DisAmount',
        key: 'DisAmount',
        width: '8%',
        align: 'right',
        render: DisAmount => format(DisAmount, currencyFormat)
      },
      {
        title: 'Total',
        dataIndex: 'LineAmount',
        key: 'LineAmount',
        align: 'right',
        width: '8%',
        render: LineAmount => format(LineAmount, currencyFormat)
      }
    ];

  getColumns = () => {
    if (this.props.match.params.docType === 'ReturnOrder') {
      return this.getColumnsReturnInfo();
    } else {
      return this.getColumnsDefault();
    }
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  getPermission = (docType, type, permissions) => {

    let enabled = false;
    if (type === 'CREATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.CREATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CREATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CREATE)
      }
    }
    else if (type === 'UPDATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.UPDATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.UPDATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.UPDATE)
      }
    }
    else if (type === 'DELETE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.DELETE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.DELETE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.DELETE)
      }
    } else if (type === 'CONFIRM') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.CONFIRM)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.CONFIRM)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CONFIRM)
      }
    }
    else if (type === 'PRINT') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.PRINT)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.PRINT)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.PRINT)
      }
    }

    else if (type === 'GENERATE_INVOICE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.GENERATE_INVOICE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(SALES_RETURNS.GENERATE_RETURN_INVOICE)
      }
    }

    return enabled;
  }


  renderFooter = () => {
    const { purchaseLines = [], order = {}, loggedInUser: { permissions = [] }, match } = this.props;
    const { docType } = match.params;
    const enableUpdate = this.getPermission(docType, 'UPDATE', permissions);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {purchaseLines.length}</p>
        {(enableUpdate) && (order && order.OrderStatus === 'Open') && <Button onClick={this.props.OnClickAddItem} type="primary">Add Item</Button>}
      </div>
    )
  }

  render() {
    const { height = 100, order, history, purchaseLines = [], match, loggedInUser: { permissions = [] }, OnClickAddItem } = this.props;
    const { orderId, docType } = match.params;
    const decodeId = decodeURIComponent(orderId);
    const enableUpdate = this.getPermission(docType, 'UPDATE', permissions);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={purchaseLines}
          size={'small'}
          onRowClick={item => {
            enableUpdate && (order && order.OrderStatus === 'Open') && history.push({ pathname: '/purchasing/' + docType + '/' + orderId + '/lines/' + item.LineNo });
          }}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId, docType } = ownProps.match.params;
  const decodeId = decodeURIComponent(orderId);

  return {
    purchaseLines: getPurchaseLinesByDocTypeAndDocNo(state, docType, decodeId),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTable));