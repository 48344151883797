import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import DistributorTableContainer from './DistributorTableContainer';
import PromatePageHeader from '../../common/PromatePageHeader';
import CallStatusValidateButton from "../../common/CallStatusValidateButton";
import { getLoggedInUser } from '../../../redux/reducers';
import { DISTRIBUTORS } from "../../../const/Permissions";
import { FaPlus } from 'react-icons/fa';
class Distributors extends Component {
  render() {
    const { loggedInUser: { permissions = [] } } = this.props;

    return (
      <div>
        <PromatePageHeader
          title={'Distributors'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={permissions.includes(DISTRIBUTORS.CREATE) && [
            <CallStatusValidateButton
              onClick={this.addDistributor}
              style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}
            >
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </CallStatusValidateButton>,
          ]}>
          <DistributorTableContainer />
        </PromatePageHeader>
        {/* <PageHeader
          title={'Distributors'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={[
            <Button style={{
              backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute',
              top: 30,
              right: 25
            }} onClick={this.addDistributor}>
              New
            </Button>,
          ]} className="promate-page-header"/>
        <div style={{ height: 120 }}></div> */}

      </div>
    )
  }

  addDistributor = () => {
    this.props.history.push("/distributors/new");

  }

}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
    position: 'fixed',
    zIndex: 1500,
    width: '100%',
    top: 40
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

Distributors.contextTypes = {
  screenType: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
  return {
    loggedInUser: getLoggedInUser(state)
  }
}

export default connect(mapStateToProps, {})(Distributors);