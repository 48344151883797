import React, { Component } from "react";
import FailedOrders from "./FailedOrders";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import PropTypes from 'prop-types';
import { Col, Table, Row } from "antd";

class FailedOrdersTable extends Component {
    // componentDidMount = () => {
    //     window.addEventListener('locationchange', this.onUrlChange);
    // }

    // componentWillUnmount = () => {
    //     window.removeEventListener('locationchange', this.onUrlChange);
    // }

    // onUrlChange = () => this.setState({ pathname: window.location.pathname });

    getScrollAreaHeight = () => {
        const networkHeader = document.getElementsByClassName('server-connection-lost');
        const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

        const promateTableHeader = document.getElementsByClassName('promate-table-header');
        const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

        const promatePageHeader = document.getElementsByClassName('promate-page-header');
        const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

        const tableFooter = document.getElementsByClassName('ant-table-footer');
        const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

        return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight);
    }

    getTableHeight = customers => {
        if (customers.length > 0) {
            return this.getScrollAreaHeight();
        } else {
            return this.getScrollAreaHeight() - 167;
        }
    }

    getOrderTotals = orders => {
        let total = 0;
        orders.forEach(order => {
            let AmountIncluVAT = order.AmountIncluVAT.replace(/,/g, "");
            total = total + parseFloat(AmountIncluVAT);
        })
        return total;
    }

    renderColumns = () => {
        const { match } = this.props;
        const { docType } = match.params;

        return [{
            render: salesHeaders => <FailedOrders salesHeaders={salesHeaders} />
        }]
    }

    render() {
        const { salesHeaders: filteredOrders, match } = this.props;
        const { docType } = match.params
        return (
            <div>
                <Row>
                    <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea' }}>
                        <Col xs={8} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>Order No</Col>
                        <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>Status</Col>
                        <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Order Date</Col>
                        <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Customer Code</Col>
                        <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Customer Name</Col>
                        <Col xs={8} sm={5} md={4} lg={4} style={{ textAlign: 'left', marginLeft: 10 }}>Customer Category</Col>
                        <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Salesperson</Col>
                        <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>
                    </Row>
                </Row>


                <Col>
                    <Table
                        rowKey={'orderNo'}
                        rowClassName={'orderRow'}
                        showHeader={false}
                        bodyStyle={{ minHeight: this.getTableHeight(filteredOrders) }}
                        columns={this.renderColumns()}
                        dataSource={filteredOrders}
                        size={'small'}
                        pagination={false}
                        scroll={{ y: this.getScrollAreaHeight() }}
                        scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
                    />
                </Col>
            </div>
        )
    }
}

FailedOrdersTable.contextTypes = {
    screenType: PropTypes.object.isRequired
}
const styles = {
    orderNo: {
        fontSize: 16,
        fontWeight: 600
    },
    alignRow: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 35,
        paddingLeft: 20
    },
    orderDate: {
        fontSize: 12,
        fontWeight: 700
    },
    customerCode: {
        fontSize: 10,
        fontWeight: 700,
    },
    customerCodeBottom: {
        fontSize: 10,
        fontWeight: 700,
    },
    status: {
        fontSize: 10,
        fontWeight: 700,
        marginRight: 0
    },
    price: {
        fontSize: 18,
        fontWeight: 600,
    },
    icon: {
        fontSize: 10,
        marginRight: 5
    },
    lowerRow: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 2
    },
    bottomColumnStyles: {
        textAlign: 'left',
        color: '#808080',
        fontSize: 12
    }

}

export default withRouter(FailedOrdersTable);