import { NETWORK_MODE, NETWORK_MODE_INITIAL } from '../actions/networkMode';

export const networkMode = (state = {}, action) => {
	switch (action.type) {
		case NETWORK_MODE:
			return action.payload
		default:
			return NETWORK_MODE_INITIAL();
	}
};

const isSystemOffline = networkMode;

export default isSystemOffline;

export const getNetworkModeDetector = state => {
	return state.networkMode
}