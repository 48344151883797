import React, { Component } from 'react';
import Header from './Header';
import { generateData } from '../../Customers/CustomerDemoData'
import Body from './Body';
import Footer from './Footer'
import { getDemoInvoicesByCount, generateDemoOrders } from '../../SalesInvoices/dummyInvoice'
import { Spin } from 'antd';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";

const customers = generateData();

class Container extends Component {
  state = {
    customer: undefined,

    selectedInvoiceRowKeys: [],
    selectedInvoiceRows: [],

    selectedReceiptRowKeys: [],
    selectedReceiptRows: [],

    selectedCreditNoteRowKeys: [],
    selectedCreditNoteRows: [],

    invoiceTotal: 0,
    receiptTotal: 0,
    creditNoteTotal: 0,

    invoices: [],
    receipts: [],
    creditNotes: [],

    totalCustomerInvoiceAmount: 0,
    totalCustomerReceiptAmount: 0,
    totalCustomerCreditAmount: 0,

    loading: false
  }

  componentDidMount = () => {
    const customerId = this.props.match.params.customerId;
    this.handleSelectCustomerId(customerId)
  }

  handleSelectCustomerId = id => {
    const selectedCustomer = customers.find(customer => customer.id === id);

    generateDemoOrders();

    const invoices = getDemoInvoicesByCount(Math.floor((Math.random() * 8) + 2)).filter(data => data.invoiceNo !== 'new')
    const receipts = getDemoInvoicesByCount(Math.floor((Math.random() * 15) + 2)).filter(data => data.invoiceNo !== 'new')
    const creditNotes = getDemoInvoicesByCount(Math.floor((Math.random() * 5) + 2)).filter(data => data.invoiceNo !== 'new')

    const totalCustomerInvoiceAmount = this.getAmountTotal(invoices)
    const totalCustomerReceiptAmount = this.getAmountTotal(receipts)
    const totalCustomerCreditAmount = this.getAmountTotal(creditNotes)

    const totalCustomerInvoiceOutstandingAmount = this.getTotal(invoices)
    const totalCustomerReceiptOutstandingAmount = this.getTotal(receipts)
    const totalCustomerCreditOutstandingAmount = this.getTotal(creditNotes)

    this.setState({
      customer: selectedCustomer,
      invoices,
      receipts,
      creditNotes,

      selectedInvoiceRowKeys: [],
      selectedInvoiceRows: [],

      selectedReceiptRowKeys: [],
      selectedReceiptRows: [],

      selectedCreditNoteRowKeys: [],
      selectedCreditNoteRows: [],
      invoiceTotal: 0,
      receiptTotal: 0,
      creditNoteTotal: 0,

      totalCustomerInvoiceAmount,
      totalCustomerReceiptAmount,
      totalCustomerCreditAmount,

      totalCustomerInvoiceOutstandingAmount,
      totalCustomerReceiptOutstandingAmount,
      totalCustomerCreditOutstandingAmount,
    })
  }

  handleOnClickSelectInvoices = (selectedInvoiceRowKeys, selectedInvoiceRows) => {
    const invoiceTotal = this.getTotal(selectedInvoiceRows)

    this.setState({ selectedInvoiceRowKeys, selectedInvoiceRows, invoiceTotal })
  }

  handleOnClickSelectReceipts = (selectedReceiptRowKeys, selectedReceiptRows) => {
    const receiptTotal = this.getTotal(selectedReceiptRows)

    this.setState({ selectedReceiptRowKeys, selectedReceiptRows, receiptTotal })
  }

  handleOnClickSelectCreditNotes = (selectedCreditNoteRowKeys, selectedCreditNoteRows) => {
    const creditNoteTotal = this.getTotal(selectedCreditNoteRows)

    this.setState({ selectedCreditNoteRowKeys, selectedCreditNoteRows, creditNoteTotal })
  }

  getTotal = selectedRows => {
    let total = 0;
    selectedRows.forEach(row => {
      total = total + row.balanceToSettle;
    })
    return total;
  }

  getAmountTotal = selectedRows => {
    let total = 0;
    selectedRows.forEach(row => {
      total = total + row.totalAmount;
    })
    return total;
  }

  handleOnSetoffReceipt = () => {
    //console.log("CALL SET OFF")
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      this.props.toastManager.add('Receipt successfully setoffed.', { autoDismiss: true, appearance: 'success' });
      this.props.history.goBack()
    }, 1000)
  }

  render() {
    const {
      customer,
      invoiceTotal,
      receiptTotal,
      creditNoteTotal,
      selectedCreditNoteRowKeys,
      selectedCreditNoteRows,
      selectedInvoiceRowKeys,
      selectedInvoiceRows,
      selectedReceiptRowKeys,
      selectedReceiptRows,
      invoices,
      receipts,
      creditNotes,
      totalCustomerInvoiceAmount,
      totalCustomerReceiptAmount,
      totalCustomerCreditAmount,
      totalCustomerInvoiceOutstandingAmount,
      totalCustomerReceiptOutstandingAmount,
      totalCustomerCreditOutstandingAmount,

      loading } = this.state;

    return (
      <Spin spinning={loading}>
        <Header
          onSelectCustomerId={this.handleSelectCustomerId}
          customers={customers}
          customer={customer}
          totalCustomerInvoiceAmount={totalCustomerInvoiceAmount}
          totalCustomerReceiptAmount={totalCustomerReceiptAmount}
          totalCustomerCreditAmount={totalCustomerCreditAmount}
          totalCustomerInvoiceOutstandingAmount={totalCustomerInvoiceOutstandingAmount}
          totalCustomerReceiptOutstandingAmount={totalCustomerReceiptOutstandingAmount}
          totalCustomerCreditOutstandingAmount={totalCustomerCreditOutstandingAmount} />
        {customer && <Body
          onClickSelectInvoices={this.handleOnClickSelectInvoices}
          onClickSelectReceipts={this.handleOnClickSelectReceipts}
          onClickSelectCreditNotes={this.handleOnClickSelectCreditNotes}
          selectedCreditNoteRowKeys={selectedCreditNoteRowKeys}
          selectedCreditNoteRows={selectedCreditNoteRows}
          selectedInvoiceRowKeys={selectedInvoiceRowKeys}
          selectedInvoiceRows={selectedInvoiceRows}
          selectedReceiptRowKeys={selectedReceiptRowKeys}
          selectedReceiptRows={selectedReceiptRows}
          invoices={invoices}
          receipts={receipts}
          creditNotes={creditNotes} />}
        {customer && <Footer
          invoiceTotal={invoiceTotal}
          setoffTotal={receiptTotal + creditNoteTotal} onSetoffReceipt={this.handleOnSetoffReceipt} />}
      </Spin>
    )
  }
}

export default withRouter(withToastManager(Container));