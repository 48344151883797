import Api from './consts';
import fetch from './commonFetch';
import { PAGE_SIZE } from '../redux/actions/fetchOData';

export const get = (objectName, filter = {}, page = 0) => {
  normalizeFilter(filter);
  const { url, method } = Api.generic.get;
  return fetch(url(objectName) + '?page=' + page + '&pageSize=' + (page === 0 ? 0 : PAGE_SIZE), { method, body: JSON.stringify(filter) }, 'data');
}

export const add = (objectName, data) => {
  const { url, method } = Api.generic.add;
  return fetch(url(objectName), { method, body: JSON.stringify(data) }, 'data');
}

export const update = (objectName, data) => {
  const { url, method } = Api.generic.update;

  return fetch(url(objectName), { method, body: JSON.stringify(data) }, 'data');
}

export const deleteObject = (objectName, data) => {
  const { url, method } = Api.generic.delete;

  return fetch(url(objectName), { method, body: JSON.stringify(data) }, 'data');
}

const normalizeFilter = filter => {
  const { UserTag } = filter;

  if (UserTag && Array.isArray(UserTag)) {
    const total = UserTag.length;
    const secondStart = Math.ceil(total / 2);
    const list1 = UserTag.slice(0, secondStart);
    const list2 = UserTag.slice(secondStart, total);
    filter.UserTagFilterOne = list1.join('|');
    filter.UserTagFilterTwo = (list2.length > 0 ? '|' : '') + list2.join('|');
  }

  filter.UserTag = undefined;
}
