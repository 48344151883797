import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchExpenses, addExpense, updateExpense, deleteExpense } from "../../../redux/actions/Expenses";
import { fetchDistributors } from "../../../redux/actions/distributors";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import ExpenseHeader from './Header';
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { getAuthData, getExpenseByDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser, getDistributor } from '../../../redux/reducers';
import CallStatusValidateButton from '../../common/CallStatusValidateButton';
import { generateReport } from '../../../api/reports';
import Api from '../../../api/consts';
import { EXPENSES } from "../../../const/Permissions";
import { fetchExpensesCategories } from '../../../redux/actions/ExpensesCategories';
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';
import { FaYoutubeSquare } from 'react-icons/fa';

// import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
// import { formatGeoLocation } from "../../../utils/Formats";

export const defaultExpense = {
  SessionID: uuid(),
  CollectionNo: undefined,
  DocDate: moment(new Date()).format(dateFormatUpdate),
  PayNo: undefined,
  CusCode: undefined,
  CusName: undefined,
  Status: "Open",
  ExpCategory: undefined,
  ExpDescription: undefined,
  ExpenseDate: moment(new Date()).format(dateFormatUpdate),
  Description: undefined,
  Amount: 0.0,
  Remarks: undefined,
  UserCode: undefined
}

class Container extends Component {
  state = {
    loading: false,
    expense: { ...defaultExpense },
    locCodeError: undefined,
    expenseTypeError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { expenseId } = this.props.match.params
    const { authData: { userId } } = this.props;

    const newOrder = expenseId === 'new';

    this.props.fetchExpensesCategories();
    this.props.fetchDistributors({ UserCode: userId });

    if (!newOrder) {
      this.setState({ loading: true });

      this.props.fetchExpenses({ CollectionNo: decodeURIComponent(expenseId) }).then(({ data, error }) => {
        if (!error) {
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
    } else {
      this.setData(this.props);
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.expense && !nextProps.expense) {
      // The order is deleted.
      window.history.back();
    } else if (nextProps && nextProps.expense) {
      this.setState({ expense: { ...nextProps.expense } });
    } else {
      this.setData(nextProps);
    }
  }

  setData = props => {
    const { distributor } = props;

    this.setState({
      expense: {
        ...this.state.expense,
        CusCode: distributor ? distributor.Code : undefined,
        CusName: distributor ? distributor.Name : undefined,
      }
    })
  }

  submitExpenseHeader = () => {
    const { match, toastManager } = this.props;
    let title = 'Expense';

    if (!this.validateForm()) return;

    const newExpenseHeader = this.createNewExpenseHeaderRequest();

    this.setState({ loading: true })
    this.sendCreateExpenseRequest(newExpenseHeader).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        const expenseNo = response.data && response.data.CollectionNo;
        toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
        this.props.history.replace("/distributors/" + match.params.distributorId + "/" + "expenses" + "/" + encodeURIComponent(expenseNo));
      } else {
        toastManager.add('Could not create the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  validateForm = () => {
    const { expense } = this.state;
    const { ExpCategory, ExpenseDate, Amount } = expense;

    let expCategoryError = undefined;
    let expDateError = undefined;
    let expAmountError = undefined;

    if (!this.isValidValue(ExpCategory)) expCategoryError = 'Expense category is required.'
    if (!this.isValidExpDate(ExpenseDate)) expDateError = 'Expense date is invalid.'
    if (!this.isValidAmount(Amount)) expAmountError = 'Expense amount is required.'

    this.setState({ expCategoryError, expDateError, expAmountError })
    return !expCategoryError && !expDateError && !expAmountError
  }

  isValidValue = value => value && value.trim().length > 0;

  isValidExpDate = value => {
    const expenceDate = moment(moment(value).format(dateFormatUpdate));
    const today = moment();
    const lastDayOfPrevoiusMoth = moment().subtract(1, 'months').endOf('month');
    const fifthDateOfCurrentMonth = moment(lastDayOfPrevoiusMoth).add(5, 'day');
    const firstDayOfThisMoth = moment().subtract(0, 'months').startOf('month');

    if (expenceDate.isSameOrBefore(lastDayOfPrevoiusMoth, 'day') && today.isSameOrBefore(fifthDateOfCurrentMonth, 'day')) {
      return true;
    } else if (expenceDate.isSameOrAfter(firstDayOfThisMoth)) {
      return true
    } else {
      return false;
    }
  }

  isValidAmount = value => value && value > 0;

  createNewExpenseHeaderRequest = () => {
    const { expense } = this.state;
    const { authData: { userId }, match } = this.props;

    return {
      ...expense,
      SessionID: uuid(),
      DocDate: moment(new Date()).format(dateFormatUpdate),
      UserCode: userId
    }
  }

  sendCreateExpenseRequest = expense => {
    return this.props.addExpense(expense);
  }

  sendUpdateExpenseRequest = () => {
    const { toastManager, authData: { userId } } = this.props;
    let title = 'Expense';

    this.setState({ loading: true })
    this.props.updateExpense({ ...this.state.expense, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not update the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  shareExpense = () => {
    this.setState({ sharing: true });
    const { expense, toastManager, match } = this.props;
    const reportRequest = {
      CollectionNo: expense.CollectionNo
    };

    generateReport('expense', reportRequest).then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToConfirmExpense = () => {
    const { expenseId } = this.props.match.params
    this.props.history.push({ pathname: '/expense/' + expenseId + '/confirm', state: { expense: this.state.expense } })
  }

  onDeleteExpense = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    let title = 'Expense';

    this.setState({ loading: true })
    this.props.deleteExpense({ ...this.state.expense, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' deleted.', { autoDismiss: true, appearance: 'success' });
        window.history.back();
      } else {
        toastManager.add('Could not delete the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onInputChange = (field, value, errorKey) => {
    const { expense } = this.state;

    this.setState({
      expense: { ...expense, [field]: value },
      [errorKey]: undefined
    })
    // , () => this.handleResetExpenseCategory(field, value))
  }

  // handleResetExpenseCategory = (field, value) => {
  //   const { expense } = this.state;
  //   this.setState({ expense: { ...expense, ExpCategory: undefined } })
  // }

  getPermission = (type, permissions) => {
    let enabled = false;
    if (type === 'CREATE') {
      enabled = permissions.includes(EXPENSES.CREATE)
    } else if (type === 'UPDATE') {
      enabled = permissions.includes(EXPENSES.UPDATE)
    } else if (type === 'DELETE') {
      enabled = permissions.includes(EXPENSES.DELETE)
    } else if (type === 'CONFIRM') {
      enabled = permissions.includes(EXPENSES.CONFIRM)
    } else if (type === 'PRINT') {
      enabled = permissions.includes(EXPENSES.PRINT)
    }

    return enabled;
  }

  renderActions = () => {
    const { match, authData: { roleCode }, expense, loggedInUser: { permissions = [] } } = this.props;
    const { Status } = expense || {};
    const { expenseId } = match.params
    const newExpense = expenseId === 'new';

    const enableCreate = this.getPermission('CREATE', permissions);
    const enableUpdate = this.getPermission('UPDATE', permissions);
    const enableDelete = this.getPermission('DELETE', permissions);
    const enableConfirm = this.getPermission('CONFIRM', permissions);
    const enablePrint = this.getPermission("PRINT", permissions);

    if (newExpense && enableCreate) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={this.submitExpenseHeader}>
            <CallStatusValidateButton type="primary">Create</CallStatusValidateButton>
          </Popconfirm>
        </Col></Row>
    } else {
      return <div>
        {
          (Status !== '' && Status !== 'Open') && enablePrint &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button
              loading={this.state.sharing}
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
              onClick={this.shareExpense}
            >Print & Share</Button>
          </Col>
        }
        {
          Status === 'Open' && enableConfirm &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmExpense}>Confirm</CallStatusValidateButton>
          </Col>
        }
        {
          Status === 'Open' && enableDelete && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteExpense}>
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {
          Status === 'Open' && enableUpdate && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <CallStatusValidateButton type="primary" onClick={this.sendUpdateExpenseRequest}>Update</CallStatusValidateButton>
          </Col>
        }
      </div>
    }
  }

  render() {
    const { distributor, match, loggedInUser } = this.props;
    const { expenseId, distributorId } = match.params
    const newExpense = expenseId === 'new';
    const { loading, expense, expCategoryError, expDateError, expAmountError } = this.state;

    if (!expense) return null;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <ExpenseHeader
            distributorId={distributorId}
            expense={expense}
            distributor={distributor}
            newExpense={newExpense}
            onSubmitExpense={this.submitExpenseHeader}
            onShareExpense={this.shareExpense}
            onClickConfirmExpense={this.navigateToConfirmExpense}
            onDeleteExpense={this.onDeleteExpense}
            onInputChange={this.onInputChange}
            loggedInUser={loggedInUser}
            error={{ expCategoryError, expDateError, expAmountError }} />
          <div style={{ float: 'right', margin: 15 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { expenseId, distributorId } = ownProps.match.params
  const newExpense = expenseId === 'new';
  const decodeId = decodeURIComponent(expenseId);
  const expense = getExpenseByDocNo(state, decodeId);
  const expCustNo = expense ? expense.CusCode : undefined;
  const custId = newExpense ? distributorId : expCustNo;
  const distributor = getDistributor(state, custId);

  return {
    expense,
    authData: getAuthData(state),
    distributor,
    loggedInUser: getLoggedInUser(state),
    distributorId: custId
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchDistributors,
  fetchExpenses,
  addExpense,
  updateExpense,
  deleteExpense,
  fetchExpensesCategories
})(Container)));

/* Might be needed for Future
  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  } */



/* Might be needed for Future
if (!locationEnabled) {
  toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
  return;
} */