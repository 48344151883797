import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchGlAccounts } from '../../../redux/actions/glAccounts';
import { fetchSalesLines, addSalesLine, updateSalesLine, deleteSalesLine } from '../../../redux/actions/salesLines';
import { getAuthData, getItem, getSalesLine, DOC_TYPE_ID_BY_DEFINITION, getAllGlAccounts } from "../../../redux/reducers";
import CreditNoteDetails from "./CreditNoteDetails";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { locationEnabled, addListener, removeListener } from '../../../utils/Location';
import { formatGeoLocation } from "../../../utils/Formats";
import uuid from "uuid/v1";

class Container extends Component {
  state = {
    salesLine: undefined
  };

  componentDidMount() {
    this.loadSalesLines();
    this.props.fetchGlAccounts();
    this.setDeviceMapLocation();
  }

  loadSalesLines = () => {
    const { docType, orderId } = this.props.match.params;
    this.props.fetchSalesLines({ DocNo: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        }, () => this.loadInventoryItem(this.props));
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillReceiveProps = nextProps => {
    this.loadInventoryItem(nextProps);
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  inventoryItemRequestSent = false;
  loadInventoryItem = props => {
    let { salesLine, fetchGlAccounts, match, inventoryItem, authData } = props;
    const { salesLine: stateSalesLine, MapLocation } = this.state;

    if (this.inventoryItemRequestSent) {
      this.inventoryItemRequestSent = true;
      fetchGlAccounts();
    }

    if (!stateSalesLine) {
      if (!MapLocation) return;

      const { orderId, lineId } = match.params;

      if (lineId === 'new') {
        if (!inventoryItem) return;

        salesLine = {
          LineSessionID: uuid(),
          Description: inventoryItem.Name,
          DocType: 'Credit Memo',
          DocNo: decodeURIComponent(orderId),
          Type: '1',
          MapLocation,
          No: inventoryItem.No,
          Quantity: 1,
          UnitPrice: 0,
          UserCode: authData.userId
        }
      } else {
        if (!salesLine) return;
      }

      this.setState({ salesLine });
    }
  }

  handleUpdateOrder = () => {
    const { addSalesLine, updateSalesLine, match } = this.props;

    if (match.params.lineId === 'new') {
      this.handleRequest(addSalesLine, 'Line added successfully.');
    } else {
      this.handleRequest(updateSalesLine, 'Line updated successfully.');
    }
  }

  handleRemoveSalesLine = () => this.handleRequest(this.props.deleteSalesLine, 'Line removed successfully.');

  handleRequest = (requestCall, successMessage) => {
    const { authData, deleteSalesLine, history, toastManager } = this.props;
    const { docType } = this.props.match.params;
    this.setState({ loading: true });
    let salesLineObject = undefined

    if (docType === 'ReturnOrder') {
      salesLineObject = { ...this.state.salesLine, UserCode: authData.userId, ReturnInvDocNo: this.state.salesLine.DocNo, ReturnInvLineNo: this.state.salesLine.LineNo }
    } else {
      salesLineObject = { ...this.state.salesLine, UserCode: authData.userId }
    }

    requestCall(salesLineObject).then(({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = () => this.setState({ salesLine: { ...this.props.salesLine } });

  handleUpdateNumberField = (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    this.setState({ salesLine: { ...this.state.salesLine, [key]: value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  render() {
    const { match, resource, order, inventoryItem, salesLine: salesLineDatabase } = this.props;
    const { salesLine, loading } = this.state;
    const { docType } = this.props.match.params;

    return (
      <Spin spinning={loading || !inventoryItem || !salesLine} tip={"Loading item"}>
        {(inventoryItem && salesLine) ?
          <CreditNoteDetails
            inventoryItem={inventoryItem}
            onUpdateNumberField={this.handleUpdateNumberField}
            salesLineItem={salesLine}
            salesLineItemDatabase={salesLineDatabase || salesLine}
            onUpdateOrder={this.handleUpdateOrder}
            onRemoveSalesLine={this.handleRemoveSalesLine}
            onResetSalesLine={this.handleResetSalesLine}
            resource={resource}
            order={order}
            newOrderItem={match && match.params.lineId === 'new'}
            docType={docType}
          /> :
          <div style={{ width: '100%', height: 200 }} />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { docType, orderId, lineId, itemId } = ownProps.match.params;

  const salesLine = getSalesLine(state, docType, decodeURIComponent(orderId), lineId);

  const inventoryItem = getAllGlAccounts(state).filter(account => account.No === itemId)[0];

  return { authData: getAuthData(state), inventoryItem, salesLine };
};


export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchGlAccounts,
        fetchSalesLines,
        addSalesLine,
        updateSalesLine,
        deleteSalesLine
      }
    )(Container)
  )
);