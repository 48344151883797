import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { dateFormatView } from "../../const/Formats";

class SupplierReturnCard extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Close':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {
    const { supplierReturn, navigateToSupplierReturnView } = this.props;
    const { supplierReturnNo, supplierReturnDate, sellToCustomerCode, sellToCustomerName, paymentType, totalAmount, status } = supplierReturn;

    return (
      <Row onClick={navigateToSupplierReturnView}>
        <Row style={styles.alignRow}>
          <Col xs={8} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
            <span style={styles.supplierReturnNo}>#{supplierReturnNo}</span>
          </Col>
          <Col xs={0} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={0} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
            <span style={styles.collectionDate}>{supplierReturnDate ? moment(supplierReturnDate).format(dateFormatView) : "Date N/A"}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span style={styles.collectionDate}>{supplierReturnDate ? moment(supplierReturnDate).format(dateFormatView) : "Date N/A"}</span>
          </Col>
          <Col xs={0} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}>{sellToCustomerCode}</p>
          </Col>
          <Col xs={0} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
            <span>{sellToCustomerName}</span>
          </Col>

          <Col xs={8} sm={4} md={4} lg={4} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={totalAmount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>

          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{sellToCustomerCode}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right', marginRight: 5 }}>
            <span>{sellToCustomerName}</span>
          </Col>
        </Row>

      </Row>
    )
  }
}

export default SupplierReturnCard;

const styles = {
  supplierReturnNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 10
  },
  collectionDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  paymentType: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}