import { Col, Input, Row, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import { ADD_CUSTOMER_ROUTE_TARGET } from '../../../../../const/Permissions';
import { StyledButton } from '../../../common/Buttons';
import { trimDecimals, withOutThousandSeperator, withThousandSeperator } from '../../../common/helpers';

const { Text } = Typography;
class DataTable extends Component {
    state = {
        pagination: {
            pageSize: 20
        }
    }

    columns = [
        {
            title: 'Code',
            width: 50,
            dataIndex: 'CustCode',
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'CustName',
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Frq.',
            dataIndex: 'Freq',
            width: 50,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Avg Sale Lst 3Mns',
            dataIndex: 'AvgSalesThreeMonth',
            width: 50,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Tgt Last Month',
            dataIndex: 'LastMonthTarget',
            width: 50,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Planned Target',
            dataIndex: 'PlannedTarget',
            width: 70,
            render: (record, row) => this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) ? <Input type='number' placeholder="Planned Target" value={record} onChange={(event) => this.props.handleInputChange(event.target.value, 'PlannedTarget', row)} style={{ fontSize: 12 }} /> : <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>,
        },
        {
            title: 'Visit Days',
            dataIndex: 'VisitDays',
            width: 50,
            render: (record, row) => <StyledButton icon={<FaCalendarAlt />} color="#0f8a86" onClick={() => this.props.handleModal(true, row)} />,
        },
        {
            title: 'Visit 1 Value',
            dataIndex: 'VisitValueOne',
            width: 70,
            render: (record, row) => this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) ? <Input type='number' placeholder="Visit 1 Value" value={record} onChange={(event) => this.props.handleInputChange(event.target.value, 'VisitValueOne', row)} style={{ fontSize: 12 }} /> : <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>,
        },
        {
            title: 'Visit 2 Value',
            dataIndex: 'VisitValueTwo',
            width: 70,
            render: (record, row) => this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) ? <Input type='number' placeholder="Visit 2 Value" value={record} onChange={(event) => this.props.handleInputChange(event.target.value, 'VisitValueTwo', row)} style={{ fontSize: 12 }} /> : <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>,
        },
        {
            title: 'Visit 3 Value',
            dataIndex: 'VisitValueThree',
            width: 70,
            render: (record, row) => this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) ? <Input type='number' placeholder="Visit 3 Value" value={record} onChange={(event) => this.props.handleInputChange(event.target.value, 'VisitValueThree', row)} style={{ fontSize: 12 }} /> : <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>,
        },
        {
            title: 'Visit 4 Value',
            dataIndex: 'VisitValueFour',
            width: 70,
            render: (record, row) => this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) ? <Input type='number' placeholder="Visit 4 Value" value={record} onChange={(event) => this.props.handleInputChange(event.target.value, 'VisitValueFour', row)} style={{ fontSize: 12 }} /> : <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>,
        },
        {
            key: 'save',
            width: 80,
            render: (record) => this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) && <StyledButton label="Save" color="#1890ff" onClick={() => this.props.handleUpdate(record)} />,
        },
    ];

    renderFooter = () => {
        const LastMonthTarget = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['LastMonthTarget']) == '' ? 0 : withOutThousandSeperator(currentValue['LastMonthTarget']))), 0);
        const PlannedTarget = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['PlannedTarget']) == '' ? 0 : withOutThousandSeperator(currentValue['PlannedTarget']))), 0);
        const VisitValueOne = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueOne']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueOne']))), 0);
        const VisitValueTwo = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueTwo']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueTwo']))), 0);
        const VisitValueThree = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueThree']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueThree']))), 0);
        const VisitValueFour = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(withOutThousandSeperator(currentValue['VisitValueFour']) == '' ? 0 : withOutThousandSeperator(currentValue['VisitValueFour']))), 0);

        return (
            <div style={{ background: "#e1e1e1", padding: "5px" }}>
                <Row>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Customers: <span style={{ fontWeight: "bold" }}>{this.props.tableData?.recordTotal ?? 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Target Last Month: <span style={{ fontWeight: "bold" }}>{trimDecimals(LastMonthTarget)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Planned Target: <span style={{ fontWeight: "bold" }}>{trimDecimals(PlannedTarget)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Visit 1 Value: <span style={{ fontWeight: "bold" }}>{trimDecimals(VisitValueOne)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Visit 2 Value: <span style={{ fontWeight: "bold" }}>{trimDecimals(VisitValueTwo)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Visit 3 Value: <span style={{ fontWeight: "bold" }}>{trimDecimals(VisitValueThree)}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black" }}>Total Visit 4 Value: <span style={{ fontWeight: "bold" }}>{trimDecimals(VisitValueFour)}</span></span>
                    </Col>
                </Row>
            </div >
        );
    }

    onSelectChange = (selectedRowKeys) => {
        this.props.setSelectedRowKeys(selectedRowKeys);
    }

    handleTableChange = (pagination) => {
        this.props.handleTableChange(pagination);
    }

    render() {
        return (
            <div style={{ padding: 10, marginTop: 5 }}>
                <Table
                    size='small'
                    columns={this.columns}
                    rowKey={record => record.CustCode}
                    dataSource={this.props.tableData.records}
                    pagination={ this.state.pagination}
                    loading={this.props.loading}
                    footer={() => this.renderFooter()}
                    rowSelection={{
                        columnWidth: 20,
                        selectedRowKeys: this.props.selectedRowKeys,
                        onChange: this.onSelectChange
                    }}
                    scroll={{ x: 1600 }}
                // onChange={this.handleTableChange}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DataTable)

const styles = {
    text: {
        color: '#000000',
        textAlign: "left",
        fontWeight: "bold"
    }
}