import React, { Component } from 'react';
import { Col, Row, Tag } from "antd";
import { ProMateGreen } from '../../const/Theme';
import moment from "moment";
import { dateFormatView } from "../../const/Formats";
import CurrencyFormat from "react-currency-format";
import { FAILED_ORDERS } from "../../const/Permissions";
import { getCustomerCategoryByCustomerId, getCustomerSubcategoryByCustomerId, getLoggedInUser } from "../../redux/reducers";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class FailedOrders extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  // async componentDidMount() {
  //   const { fetchSalesHeaders } = this.props;

  //   // const cus = await fetchCustomers({ UserCode: userId });
  //   const cus = await fetchSalesHeaders();
  //   // console.log('cus');

  //   // console.log(cus);

  //   console.log("this.state123");
  //   console.log(this.state);
  //   this.setState({
  //     salesHeaders: cus
  //   });

  //   // this.setState({
  //   //   filter: {
  //   //     ...this.state.filter,
  //   //     DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
  //   //     UserCode: userId,
  //   //     CustNo: customerId,
  //   //   }
  //   // }, () => this.handleUrlSearchParams(this.props));
  // }

  render() {
    const { salesHeaders, history, customerCategory, customerSubcategory, loggedInUser: { permissions = [] } } = this.props;
    const { No, OrderDate, BillToCusNo, BillToCusName, Status, UserCode, AmountIncluVAT, CreatedUserName } = salesHeaders;

    return (

      <div>

        <Row onClick={() => permissions.includes(FAILED_ORDERS.VIEW) && history.push("/sales/Order/" + encodeURIComponent(No))}>
          <Row style={styles.alignRow}>
            <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>
              <span style={styles.orderNoDesktop}>{No}</span>
            </Col>
            <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
              <span style={styles.orderNoMobile}>{No}</span>
            </Col>

            <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>
              <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
            </Col>
            <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
              <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
            </Col>
            <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
              <p style={{ ...styles.customerCode, margin: 5 }}>{BillToCusNo}</p>
            </Col>
            <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
              <span>{BillToCusName}</span>
            </Col>
            <Col xs={8} sm={5} md={4} lg={4} style={{ textAlign: 'left', marginLeft: 5 }}>
              <span>{customerCategory && customerCategory.Description} {customerSubcategory && ('(' + customerSubcategory.Description + ')')}</span>
            </Col>
            <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
              <span>{CreatedUserName}</span>
            </Col>
            <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
              <CurrencyFormat
                value={AmountIncluVAT || 0}
                displayType={'text'}
                thousandSeparator={true}
                isNumericString={true}
                fixedDecimalScale={true}
                style={styles.price}
                decimalScale={2} />
            </Col>
          </Row>
          <Row style={styles.lowerRow}>
            <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
              <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
            </Col>
            <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left', marginLeft: 5 }}>
              <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
            </Col>

          </Row>
          <Row style={styles.lowerRow}>
            <Col xs={0} sm={5} md={0} lg={0} style={styles.bottomColumnStyles}>
              <span>{BillToCusNo}</span>
            </Col>

            <Col xs={8} sm={0} md={0} lg={0} style={{ marginLeft: 5, textAlign: 'left', color: '#808080', fontSize: 12 }}>
              <span>{BillToCusNo}</span>
            </Col>
            <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'center' }}>
              <span>{BillToCusName}</span>
            </Col>
            <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
              <span>{BillToCusName}</span>
            </Col>
            <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'center' }}>
              <span>{CreatedUserName}</span>
            </Col>
            <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
              <span>{CreatedUserName}</span>
            </Col>
          </Row>
        </Row>

      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },

  orderNoDesktop: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderNoMobile: {
    fontSize: 11,
    fontWeight: 700
  },
  orderNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 9,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 15,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const { salesHeaders } = ownProps;
  const customerCategory = getCustomerCategoryByCustomerId(state, salesHeaders.BillToCusNo);
  const customerSubcategory = getCustomerSubcategoryByCustomerId(state, salesHeaders.BillToCusNo);
  const loggedInUser = getLoggedInUser(state);

  return { customerCategory, customerSubcategory, loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(FailedOrders));