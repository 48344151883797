import React, { Component } from "react";
import { connect } from "react-redux";
import { TreeSelect } from "antd";
import { fetchSalesUserHierarchy } from '../../redux/actions/users'
import { getSalesUserHierarchy, getAuthData } from "../../redux/reducers";

class SelectSalesUserHierarchy extends Component {
  componentDidMount = () => {
    const { fetchSalesUserHierarchy, authData } = this.props;
    fetchSalesUserHierarchy(authData);
  }

  getUserTreeData = hierarchy => {
    return Object.values(hierarchy).map(position => ({
      value: position.positionCode,
      title: position.positionCode + ' - ' + position.user.name + ' (' + position.positionType + ')',
      type: position.positionType,
      children: this.getUserTreeData(position.descendantStructure)
    }));
  }

  render() {
    const {
      salesUserHierarchy,
      onChange,
      style = { width: '100%' },
      dropdownStyle = { maxHeight: window.innerHeight / 2, width: window.innerWidth - 20, overflow: 'auto' },
      placeholder = "Please select",
      allowClear = true,
      treeDefaultExpandAll = true,
      value
    } = this.props
    const treeData = this.getUserTreeData(salesUserHierarchy);

    let enabled =
      treeData.length > 1 ||
      (treeData.length === 1 && treeData[0].children.length > 0);

    return (
      <TreeSelect
        disabled={!enabled}
        showSearch={false}
        style={style}
        dropdownStyle={dropdownStyle}
        placeholder={placeholder}
        allowClear={allowClear}
        treeDefaultExpandAll={treeDefaultExpandAll}
        treeData={treeData}
        value={value}
        onChange={(value, lable, extra) => onChange('UserHierarchy', value)}
        filterTreeNode={(inputValue, treeNode) => {
          const text = (treeNode.props.value + ' ' + treeNode.props.title).toLowerCase();
          return text.indexOf(inputValue.toLowerCase()) > -1;
        }}
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeCheckStrictly={true}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state)
  };
};

export default connect(mapStateToProps, { fetchSalesUserHierarchy })(SelectSalesUserHierarchy);

export const updateFilterUserHierarchy = (hierarchy, currentFilter = {}, flatPositions, topPosition) => {
  if (!hierarchy || !flatPositions || !topPosition) return currentFilter;

  const newFilter = { ...currentFilter };

  hierarchy = clearSameBranch(hierarchy, flatPositions);

  if (hierarchy.length > 0) {
    newFilter.UserHierarchy = [...hierarchy];
  } else if (topPosition) {
    newFilter.UserHierarchy = [{ value: topPosition.positionCode }];
  }

  newFilter.UserTag = [];

  newFilter.UserHierarchy.forEach(({ value }) => {
    newFilter.UserTag = [...newFilter.UserTag, value];

    const position = flatPositions[value];
    if (position) {
      // newFilter.UserCode = position.user.code;
      newFilter.UserTag = [...newFilter.UserTag, ...position.decendantCodes];
    }
  });

  return newFilter;
}

const clearSameBranch = (hierarchySelection, flatPositions) => {
  let clearedSelections = [...hierarchySelection];

  // Priority goes to the newest selection
  for (let i = clearedSelections.length - 1; i > -1; i--) {
    const selection = clearedSelections[i];
    const position = flatPositions[selection.value];
    if (!position) continue;

    let positionCodesToRemove = [...position.decendantCodes];

    let parentPositionCode = position.parentPositionCode;
    while (!!parentPositionCode) {
      positionCodesToRemove.push(parentPositionCode);
      const parentPosition = flatPositions[parentPositionCode];
      parentPositionCode = parentPosition ? parentPosition.parentPositionCode : undefined;
    }

    positionCodesToRemove = [...positionCodesToRemove, ...position.decendantCodes];

    const countBeforeClear = clearedSelections.length;
    clearedSelections = clearedSelections.filter(selection => !positionCodesToRemove.includes(selection.value));
    const countAfterClear = clearedSelections.length;

    if (countBeforeClear !== countAfterClear) i = clearedSelections.length - 1;
  }

  return clearedSelections;
}