import { readObjectFromIndexDB, insertDataIndexDB, } from './indexDB';
import { TABLES, OBJECT_KEYS } from './const/IndexDBObjects';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    return serializedState && serializedState !== null ?
      JSON.parse(serializedState) :
      undefined;
  } catch (e) {
    return undefined;
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const getOfflineData = (key) => {
  try {
    const serializedValue = localStorage.getItem(key);
    return serializedValue && serializedValue !== null ?
      JSON.parse(serializedValue) :
      undefined;
  } catch (e) {
    return undefined;
  }
}

export const saveOfflineData = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const saveOfflineCustomer = (customer) => {
  try {
    if (typeof getOfflineData("offlineCustomerData") === 'undefined') {
      let arr = { "data": [customer] }
      saveOfflineData("offlineCustomerData", arr)
    } else {
      let oldCusObj = getOfflineData("offlineCustomerData")
      oldCusObj.data.push(customer)
      saveOfflineData("offlineCustomerData", oldCusObj)
    }
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const saveOfflinePaymentTerms = (paymentTerms) => {
  try {
    if (typeof getOfflineData("customerOfflinePaymentTerms") === 'undefined') {
      let arr = { "data": [paymentTerms] }
      saveOfflineData("customerOfflinePaymentTerms", arr)
    } else {
      let oldCusObj = getOfflineData("customerOfflinePaymentTerms")
      oldCusObj.data.push(paymentTerms)
      saveOfflineData("customerOfflinePaymentTerms", oldCusObj)
    }
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const saveOfflinePaymentMethods = (paymentMethods) => {
  try {
    if (typeof getOfflineData("customerOfflinePaymentMethods") === 'undefined') {
      let arr = { "data": [paymentMethods] }
      saveOfflineData("customerOfflinePaymentMethods", arr)
    } else {
      let oldCusObj = getOfflineData("customerOfflinePaymentMethods")
      oldCusObj.data.push(paymentMethods)
      saveOfflineData("customerOfflinePaymentMethods", oldCusObj)
    }
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}


export const savePaymentMethods = (paymentMethods, i) => {
  try {
    let oldCusObj = getOfflineData("customerData")
    oldCusObj.data[i].paymentMethods = paymentMethods
    saveOfflineData("customerData", oldCusObj)

  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const savePaymentTerms = (paymentTerms, i) => {
  try {
    let oldCusObj = getOfflineData("customerData")
    oldCusObj.data[i].paymentTerms = paymentTerms
    saveOfflineData("customerData", oldCusObj)

  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const removeOfflineItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error('Could not remove the item.', e);
  }
}

// export const saveOfflineCall = (call) => {
//   // console.log("abcde");
//   // console.log( readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.CALL_END_REASONS));
//   // var request = indexedDB;
//   // console.log(request);
//   try {
//     if(typeof readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA) === 'undefined'){
//       let arr = {"data": [call]}
//       // saveOfflineData("offlineCallData",arr)
//       insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA,arr)
//     }else{
//       let oldCallObj=readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA)
//       oldCallObj.data.push(call)
//       // saveOfflineData("offlineCallData",oldCallObj)
//       insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA,oldCallObj)
//     }
//   } catch (e) {
//     console.error('Could not save the state.', e);
//   }
// }

export const saveOfflineCall = (call) => {
  try {
    if (typeof getOfflineData("offlineCallData") === 'undefined') {
      let arr = { "data": [call] }
      saveOfflineData("offlineCallData", arr)
    } else {
      let oldCallObj = getOfflineData("offlineCallData")
      oldCallObj.data.push(call)
      saveOfflineData("offlineCallData", oldCallObj)
    }
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const saveOfflineCallBackup = (call) => {
  try {
    if (typeof getOfflineData("offlineCallDataBackup") === 'undefined') {
      let arr = { "data": [call] }
      saveOfflineData("offlineCallDataBackup", arr)
    } else {
      let oldCallObj = getOfflineData("offlineCallDataBackup")
      oldCallObj.data.push(call)
      saveOfflineData("offlineCallDataBackup", oldCallObj)
    }
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

export const saveOfflineUpdateCall = (call) => {
  try {
    if (typeof getOfflineData("offlineUpdateCallData") === 'undefined') {
      let arr = { "data": [call] }
      saveOfflineData("offlineUpdateCallData", arr)
    } else {
      let oldCallObj = getOfflineData("offlineUpdateCallData")
      oldCallObj.data.push(call)
      saveOfflineData("offlineUpdateCallData", oldCallObj)
    }
  } catch (e) {
    console.error('Could not save the state.', e);
  }
}

// export const saveOfflineCall= (newCall) => {
//   try {
//     if(typeof getOfflineData("offlineCustomerData") === 'undefined'){
//       let arr = {"data": [customer]}
//       saveOfflineData("offlineCustomerData",arr)
//     }else{
//       let oldCusObj=getOfflineData("offlineCustomerData")
//       oldCusObj.data.push(newCall)
//       saveOfflineData("offlineCustomerData",oldCusObj)
//     }
//   } catch (e) {
//     console.error('Could not save the state.', e);
//   }
// }
