import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchForDoc as fetchLinesForDoc } from "../../../redux/actions/transferLines";
import { addTransferHeader, updateTransferHeader, deleteTransferHeader } from "../../../redux/actions/transferHeaders";
import { fetchInventory } from "../../../redux/actions/inventory";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import TransferHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import TransferFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import { getAuthData, getTransferHeader, getTransferLinesForDoc, getLocations, getTotalTranferQtyForDoc, getLoggedInUser } from '../../../redux/reducers';
import CallStatusValidateButton from '../../common/CallStatusValidateButton';
import { generateReport } from '../../../api/reports';
import Api from '../../../api/consts';
import { fetchItems } from '../../../redux/actions/items';
import { fetchTransferHeaders } from '../../../redux/actions/transferHeaders';
import { STOCK_TRANSFERS } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { stockTransferStatuses } from '../../../redux/reducers/transferHeaders';
import { dateFormatUpdate, dateFormatView } from '../../../const/Formats';

export const defaultStockTransfer = {
  SessionID: uuid(),
  FromLocCode: undefined,
  FromLocDescrip: undefined,
  ToLocCode: undefined,
  ToLocDescrip: undefined,
  PostingDate: new Date(),
  Status: 'Open',
  MapLocation: undefined,
  OrderStatus: undefined,
  DistributorCode: undefined,
  Remarks: undefined
}

class Container extends Component {
  state = {
    loading: false,
    transfer: { ...defaultStockTransfer },
    locCodeError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { transfer, match, transferId } = this.props;
    const newTransfer = transferId === 'new';
    //console.log(newTransfer, transferId)
    if (!newTransfer) {
      this.setState({ loading: true });
      this.props.fetchTransferHeaders({ DocNo: decodeURIComponent(transferId) });
      this.props.fetchLinesForDoc(transferId).then(() => {
        this.setState({ loading: false });
      })
      this.props.fetchLinesForDoc(transferId).then(() => {
        this.setState({ loading: false });
      })
    }

    this.setDeviceMapLocation();

    if (transfer) this.fetchItems(transfer.FromLocCode);
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.transfer && !nextProps.transfer) {
      // The transfer is deleted.
      window.history.back();
    } else if (nextProps && nextProps.transfer) {
      this.setState({ transfer: { ...nextProps.transfer } });
    }

    if (!this.props.transfer && nextProps.transfer) this.fetchItems(nextProps.transfer.FromLocCode);
  }

  fetchItems = LocCode => {
    const { authData, fetchItems } = this.props;
    fetchItems({ LocCode, UserCode: authData.userId });
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  submitTransferHeader = () => {
    const { match, toastManager } = this.props;

    if (!locationEnabled) {
      toastManager.add('You can not create a stock transfer without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) return;

    const newSalesHeader = this.createNewTransferHeaderRequest();

    this.setState({ loading: true })
    this.sendCreateTransferHeaderRequest(newSalesHeader).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new stock transfer.', { autoDismiss: true, appearance: 'success' });
        this.props.history.replace("/stockTransfers/" + encodeURIComponent(response.data.DocNo));
      } else {
        toastManager.add('Could not create the stock transfer. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  validateForm = (lazy = false) => {
    let { transfer, fromLocCodeError, toLocCodeError } = this.state;
    const { FromLocCode, ToLocCode } = transfer;

    if (!fromLocCodeError && !toLocCodeError && lazy) return true;

    fromLocCodeError = !this.isValidValue(FromLocCode) ? 'From location is required.' : '';
    toLocCodeError = !this.isValidValue(ToLocCode) ? 'To location is required.' : '';

    if (!fromLocCodeError && !toLocCodeError && FromLocCode === ToLocCode) {
      toLocCodeError = 'From and To locations cannot be the same.';
    }

    this.setState({ fromLocCodeError, toLocCodeError });

    return !fromLocCodeError && !toLocCodeError;
  }

  isValidValue = value => value && value.trim().length > 0;

  createNewTransferHeaderRequest = () => {
    const { transfer, MapLocation } = this.state;
    const { authData: { userId } } = this.props;

    return {
      ...transfer,
      SessionID: uuid(),
      PostingDate: moment(new Date()).format(dateFormatUpdate),
      MapLocation,
      UserCode: userId
    }
  }

  sendCreateTransferHeaderRequest = transferHeader => {
    return this.props.addTransferHeader(transferHeader);
  }

  sendUpdateSalesHeaderRequest = (hideToast = true) => {
    const { toastManager, authData: { userId } } = this.props;
    const updateRequest = {
      ...this.state.transfer,
      UserCode: userId
    };

    this.setState({ loading: true })
    this.props.updateTransferHeader(updateRequest).then(response => {
      this.setState({ loading: false })
      if (hideToast) {
        if (!response.error) {
          toastManager.add('Stock transfer updated successfully.', { autoDismiss: true, appearance: 'success' });
        } else {
          toastManager.add('Could not update the stock transfer. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      }
    });
  }

  shareTransfer = () => {
    this.setState({ sharing: true });

    const { transfer, toastManager } = this.props;
    const reportRequest = {
      DocNo: transfer.DocNo
    };

    generateReport('stockTransfer', reportRequest).then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToStock = () => {
    const { match, toastManager } = this.props;
    const { transferId } = match.params;

    if (transferId === 'new') {
      if (!locationEnabled) {
        toastManager.add('You can not create a stock transfer without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
        return;
      }

      if (!this.validateForm()) return;

      const newTransferHeader = this.createNewTransferHeaderRequest();

      this.setState({ loading: true })
      this.sendCreateTransferHeaderRequest(newTransferHeader).then(response => {
        this.setState({ loading: false })
        if (!response.error) {
          toastManager.add('Successfully created a new stock transfer.', { autoDismiss: true, appearance: 'success' });
          const transfer = { transferNo: encodeURIComponent(response.data.DocNo), ...response.data };
          this.props.history.replace("/stockTransfers/" + encodeURIComponent(response.data.DocNo));
          this.props.history.push({ pathname: '/stock', state: { resource: "stockTransfers", transfer: transfer, id: response.data.DocNo } });
        } else {
          toastManager.add('Could not create the stock transfer. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    } else {
      const transfer = this.state.transfer;
      this.props.history.push({ pathname: '/stock', state: { resource: "stockTransfers", transfer: transfer, id: transferId } });
    }
  }

  navigateToConfirmTransfer = () => {
    const { transferId } = this.props.match.params

    this.props.history.push({ pathname: '/stockTransfers/' + transferId + '/confirm', state: { transfer: this.state.transfer } })
  }

  generateInvoice = () => {
    const { transferId } = this.props.match.params
    this.props.history.push({ pathname: '/stockTransfers/' + transferId + '/salesInvoices/new', state: { transfer: this.state.transfer } })
  }

  onDeleteTransfer = () => {
    const { toastManager } = this.props;

    this.setState({ loading: true })
    this.props.deleteTransferHeader(this.state.transfer).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Stock transfer deleted successfully.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not delete the stock transfer. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onConfirmApproved = () => {
    const { updateTransferHeader, toastManager, transfer, authData: { userId } } = this.props;
    const updateTransferData = { ...transfer, OrderStatus: '2', UserCode: userId };

    updateTransferHeader(updateTransferData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Transfer confirmed.', { autoDismiss: true, appearance: 'success' });
        window.history.back();
      } else {
        toastManager.add('Could not confirmed. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onInputChange = (field, value) => {
    this.setState({
      transfer: { ...this.state.transfer, [field]: value }
    }, () => this.validateForm(true));
  }

  renderActions = () => {
    const { match, transfer, lines, loggedInUser: { permissions = [] }, location } = this.props;
    const { Status } = transfer || {};
    const { transferId } = match.params
    const newTransfer = transferId === 'new';
    const enableCreate = permissions.includes(STOCK_TRANSFERS.CREATE);
    const enableUpdate = permissions.includes(STOCK_TRANSFERS.UPDATE);
    const enableDelete = permissions.includes(STOCK_TRANSFERS.DELETE);
    const enableApprove = permissions.includes(STOCK_TRANSFERS.APPROVE);
    const enableConfirm = permissions.includes(STOCK_TRANSFERS.CONFIRM);
    const enablePrint = permissions.includes(STOCK_TRANSFERS.PRINT);

    const isLocationOwner = location && location.WarehouseOwner;

    const mappedStatus = stockTransferStatuses[Status];

    if (newTransfer && enableCreate) {
      return <Row style={{ margin: 10 }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={this.submitTransferHeader}
        >
          <CallStatusValidateButton type="primary">Create</CallStatusValidateButton>
        </Popconfirm>
      </Col></Row>
    } else {

      return <Row gutter={10} style={{ display: 'flex', justifyContent: 'space-between', margin: 20 }}>
        {
          mappedStatus == 'Open' && enableUpdate && <Col>
            <CallStatusValidateButton type="primary" onClick={this.sendUpdateSalesHeaderRequest}>Update</CallStatusValidateButton>
          </Col>
        }
        {
          mappedStatus == 'Open' && enableDelete && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteTransfer}
            >
              <CallStatusValidateButton type="danger">Delete</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        }
        {/* {
          // TODO: Only allow this to DISTRIBUTORs in the future.
          mappedStatus !== 'Open' && enableApprove && //roleCode === "DISTRIBUTOR" &&
          <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onConfirmApproved}
            >
              <Button type="primary">Approve</Button>
            </Popconfirm>
          </Col>
        } */}
        {/* {
          lines && lines.length > 0 &&
          mappedStatus === 'Confirmed' && enableConfirm
          &&
          <Col>
            <CallStatusValidateButton type="primary" onClick={this.generateInvoice}>Generate Invoice</CallStatusValidateButton>
          </Col>
        } */}
        {
          lines && lines.length > 0 && mappedStatus === 'Open' && enableConfirm &&
          isLocationOwner &&
          <Col>
            <CallStatusValidateButton type="primary" onClick={this.navigateToConfirmTransfer}>Confirm</CallStatusValidateButton>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            enablePrint &&
            <Col>
              <Button
                loading={this.state.sharing}
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={this.shareTransfer}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row >
    }
  }

  render() {
    const { transferId } = this.props.match.params
    const newTransfer = transferId === 'new';
    const { loading, transfer, fromLocCodeError, toLocCodeError } = this.state;

    if (!transfer) return null;

    return (
      <Spin spinning={loading} tip={"Loading stock transfer"}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <TransferHeader
            transfer={transfer}
            newTransfer={newTransfer}
            onSubmitTransfer={this.submitTransfer}
            onShareTransfer={this.shareTransfer}
            onClickConfirmTransfer={this.navigateToConfirmTransfer}
            onDeleteTransfer={this.onDeleteTransfer}
            onInputChange={this.onInputChange}
            error={{ fromLocCodeError, toLocCodeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToStock}
                transfer={transfer}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToStock}
                transfer={transfer}
              />
            </Col>
          </div>
          <TransferFooter
            transfer={transfer}
            totalTransferQty={this.props.totalTransferQty}
            onInputChange={this.onInputChange} />
          <div style={{ float: 'right' }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <TransferExport transfer={transfer} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const decodeId = decodeURIComponent(ownProps.match.params.transferId);
  //console.log(decodeId, getTransferLinesForDoc(state, decodeId))
  const transfer = getTransferHeader(state, decodeId)
  return {
    transfer: transfer,
    authData: getAuthData(state),
    locations: getLocations(state),
    lines: getTransferLinesForDoc(state, decodeId),
    totalTransferQty: getTotalTranferQtyForDoc(state, decodeId),
    loggedInUser: getLoggedInUser(state),
    transferId: decodeId,
    location: getLocations(state).filter(location => transfer && location.LocCode === transfer.ToLocCode && location.WarehouseOwner === 'Yes')[0]
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchInventory,
  fetchLinesForDoc,
  fetchItems,
  addTransferHeader,
  updateTransferHeader,
  deleteTransferHeader,
  fetchTransferHeaders
})(Container)));