import * as fromApi from '../../api/generic';

export const FETCH_EXPENSES = 'FETCH_EXPENSES';
export const START_FETCH_EXPENSES = 'START_FETCH_EXPENSES';
export const FINISH_FETCH_EXPENSES = 'FINISH_FETCH_EXPENSES';
export const FETCH_EXPENSE = 'FETCH_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';

let lastSearchKey;
export const fetchExpenses = (filter, page, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_EXPENSES });

  return fromApi.get('DistributorExpense', filter, page).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;
    //console.log("Exp data", data);
    if (error) return { error }

    if (data && data.length === 1) dispatch({ type: FETCH_EXPENSE, expense: data[0] });

    dispatch({ type: FETCH_EXPENSES, expenses: data, searchKey, total: recordTotal, metadata: responseMetadata });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: FINISH_FETCH_EXPENSES });
  });
};

export const addExpense = expense => dispatch => {
  return fromApi.add('DistributorExpense', expense).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_EXPENSE, expense: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateExpense = expense => dispatch => {
  return fromApi.update('DistributorExpense', expense).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_EXPENSE, expense: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteExpense = expense => dispatch => {
  return fromApi.deleteObject('DistributorExpense', expense).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: DELETE_EXPENSE, expense });
    return response;
  }).catch(error => {
    return { error }
  })
};