import React, { Component } from 'react';
import { connect } from "react-redux";
import { withToastManager } from 'react-toast-notifications';
import { ProMateGreen } from '../../const/Theme';
import { fetchCustomerCategories, fetchCustomers } from "../../redux/actions/customers";
import { fetchSalesCreditMemoReasons, fetchSalesHeaders } from "../../redux/actions/salesHeaders";
import { getAuthData, getFlatPositions, getInProgressCall, getLoggedInUser, getSalesHeaderMetadata, getSalesHeaderTotal, getSalesHeadersBySearchKey, getTopPosition, isCallInProgress, isFetchingSalesHeaders } from "../../redux/reducers";
import { getSearchKey } from '../../utils/Search';
import PromatePageHeader from '../common/PromatePageHeader';
import FailedOrdersTable from './FailedOrdersTable';

class FailedSalesOrders extends Component {

    state = {
        loading: false,
        salesHeaders: [],
        customerCategory: []
    }

    async componentDidMount() {
        const { fetchSalesHeaders, fetachFailedSalesHeadersFromLocalAPI, authData: { userId }, fetchCustomerCategories, fetchCustomers } = this.props;
        const cus = await fetchSalesHeaders();

        fetchCustomerCategories();
        fetchCustomers({ UserCode: userId });

        this.setState({
            salesHeaders: cus.data,

        });
    }

    render() {
        const { loading, loggedInUser: { permissions = [] }, match, salesHeaders, total, totalAmount, topPosition } = this.props;
        return (
            <div>
                <PromatePageHeader
                    title={'Failed Orders'}
                    style={styles.pageHeader}
                    onBack={() => this.props.history.goBack()}
                >
                </PromatePageHeader>

                {/* <Spin spinning={(loading && salesHeaders.length === 0) || !topPosition} tip={"Loading..."}> */}

                <FailedOrdersTable
                    salesHeaders={this.state.salesHeaders}
                    customerCategory={this.state.customerCategory}
                />
                {/* </Spin> */}
            </div>
        )
    }
}

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    },
}

const mapStateToProps = (state, ownProps) => {
    const searchKey = getSearchKey();

    return {
        salesHeaders: getSalesHeadersBySearchKey(state, searchKey),
        authData: getAuthData(state),
        loggedInUser: getLoggedInUser(state),
        topPosition: getTopPosition(state),
        flatPositions: getFlatPositions(state),
        total: getSalesHeaderTotal(state, searchKey),
        totalAmount: getSalesHeaderMetadata(state, searchKey).TotalAmountIncluVAT,
        loading: isFetchingSalesHeaders(state),
        inCall: isCallInProgress(state),
        ongoingCall: getInProgressCall(state)
    };
};

export default withToastManager(connect(mapStateToProps, { fetchSalesHeaders, fetchCustomers, fetchCustomerCategories, fetchSalesCreditMemoReasons })(FailedSalesOrders));