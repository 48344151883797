import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class ExpenseCategroyView extends Component {
  state = {
    isEditExpense: false
  }

  render() {
    const { expenseCategoryId } = this.props.match.params
    const newExpenseCategory = expenseCategoryId === 'new';
    //console.log(expenseCategoryId);

    const { match } = this.props;
    const path = match.path
    const isEditExpense = path.includes("edit");

    return (
      <div>
        <PromatePageHeader
          title={newExpenseCategory ? 'New Expense Category' : 'Expense Category Info'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Container expenseCategoryId={expenseCategoryId} />
        </PromatePageHeader>
      </div>
    )
  }

}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default ExpenseCategroyView;