import uuid from 'uuid';

const ITEM_COUNT = 8;
let data = [];

export const expenseCategoryDescriptions = ["Descrition one", "Descrition two", "Descrition three", "Descrition four", "Descrition five", "Description six", "Description seven", "Description eight", "Description nine"];
export const categories = ["fuel", "transport", "snacks", "stationary", "Buying Furniture", "Vehicle Repairing", "Vehicle servicing", "Cleaning"];

export const generateData = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateItem(i));
  }
  return data;
}

const generateItem = index => {
  const item = {
    id: "Category " + index
  }

  const description = expenseCategoryDescriptions[index]
  const category = categories[index];
  item.description = description
  item.category = category
  return item;
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const getDemoItem = id => {
  return data.find(item => item.id === id);
}
