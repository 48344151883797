import { combineReducers } from 'redux';
import { FETCH_SALES_HEADERS, FETCH_SALES_HEADER, DELETE_SALES_HEADER, START_FETCH_SALES_HEADERS, FINISH_FETCH_SALES_HEADERS } from '../actions/salesHeaders';
import { DOC_TYPE_DEFINITIONS } from "../reducers";

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS:
      const newState = { ...state };
      action.salesHeaders.forEach(salesHeader => newState[salesHeader.No] = salesHeader);
      return newState;
    case FETCH_SALES_HEADER: {
      const newState = { ...state };
      newState[action.salesHeader.No] = action.salesHeader;
      return newState;
    }
    case DELETE_SALES_HEADER: {
      const newState = { ...state };
      delete newState[action.salesHeader.No];
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS: {
      const newState = [...state]
      action.salesHeaders.forEach(salesHeader => {
        if (newState.includes(salesHeader.No)) newState.push(salesHeader.No);
      });

      return newState;
    }
    case FETCH_SALES_HEADER: {
      const newState = [...state];
      if (!newState.includes(action.salesHeader.No)) {
        newState.push(action.salesHeader.No)
      }
      return newState;
    }
    case DELETE_SALES_HEADER: {
      const newState = [...state];
      return newState.filter(id => id !== action.salesHeader.No);
    }
    default:
      return state;
  }
};

export const byType = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS: {
      const newState = {};
      action.salesHeaders.forEach(salesHeader => {
        const key = salesHeader.DocType;
        const idsByType = newState[key] || [];
        if (!idsByType.includes(salesHeader.No)) {
          idsByType.push(salesHeader.No);
          newState[key] = idsByType;
        }
      });

      return newState;
    }
    case FETCH_SALES_HEADER: {
      const newState = {};
      const idsByType = newState[action.salesHeader.DocType] || [];

      if (!idsByType.includes(action.salesHeader.No)) {
        newState[action.salesHeader.DocType] = [...idsByType, action.salesHeader.No];
      }

      return newState;
    }
    case DELETE_SALES_HEADER: {
      const newState = {};
      const idsByType = newState[action.salesHeader.DocType] || [];
      newState[action.salesHeader.DocType] = idsByType.filter(id => id !== action.salesHeader.No);
      return newState;
    }
    default:
      return state;
  }
};

export const byTypeAndNo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS: {
      const newState = { ...state };
      action.salesHeaders.forEach(salesHeader => {
        const key = salesHeader.DocType + '-' + salesHeader.No
        const idsByTypeAndNo = newState[key] || [];
        if (!idsByTypeAndNo.includes(salesHeader.No)) {
          idsByTypeAndNo.push(salesHeader.No);
          newState[key] = idsByTypeAndNo;
        }
      });
      return newState;
    }
    case FETCH_SALES_HEADER: {
      const newState = { ...state };
      const key = action.salesHeader.DocType + '-' + action.salesHeader.No
      const idsByType = newState[key] || [];

      if (!idsByType.includes(action.salesHeader.No)) {
        newState[key] = [...idsByType, action.salesHeader.No];
      }

      return newState;
    }
    case DELETE_SALES_HEADER: {
      const newState = { ...state };
      const key = action.salesHeader.DocType + '-' + action.salesHeader.No
      const idsByType = newState[key] || [];
      newState[action.salesHeader.DocType] = idsByType.filter(id => id !== action.salesHeader.No);
      return newState;
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS: {
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.salesHeaders.map(header => header.No)
      };
    }
    case DELETE_SALES_HEADER: {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        const ids = newState[key];
        newState[key] = ids.filter(id => id !== action.salesHeader.No)
      });
      return newState;
    }
    default:
      return state;
  }
};

export const total = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.total
      };

    default:
      return state;
  }
}

export const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS:
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.metadata
      };

    default:
      return state;
  }
}

export const fetching = (state = false, action) => {
  switch (action.type) {
    case START_FETCH_SALES_HEADERS:
      return true;

    case FINISH_FETCH_SALES_HEADERS:
      return false;

    default:
      return state;
  }
}

export const byCustomer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SALES_HEADERS: {
      const newState = { ...state };
      action.salesHeaders.forEach(salesHeader => {
        const key = salesHeader.SellToCusNo;
        const idsByCustomer = newState[key] || [];
        if (!idsByCustomer.includes(salesHeader.No)) {
          idsByCustomer.push(salesHeader.No);
          newState[key] = idsByCustomer;
        }
      });
      return newState;
    }
    case FETCH_SALES_HEADER: {
      const newState = { ...state };
      const key = action.salesHeader.SellToCusNo;
      const idsByCustomer = newState[key] || [];

      if (!idsByCustomer.includes(action.salesHeader.No)) {
        newState[key] = [...idsByCustomer, action.salesHeader.No];
      }

      return newState;
    }
    case DELETE_SALES_HEADER: {
      const newState = { ...state };
      const key = action.salesHeader.SellToCusNo;
      const idsByCustomer = newState[key] || [];
      newState[key] = idsByCustomer.filter(id => id !== action.salesHeader.No);
      return newState;
    }
    default:
      return state;
  }
};

const salesHeaders = combineReducers({
  byId,
  ids,
  byType,
  byTypeAndNo,
  bySearchKey,
  total,
  metadata,
  fetching,
  byCustomer
});

export default salesHeaders;

export const getSalesHeadersByType = (state, type) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const getSalesHeadersByTypeAndNo = (state, type, no) => {
  const key = type + '-' + no;
  const ids = state.byTypeAndNo[key] || [];

  return ids && ids.length > 0 && state.byId[ids[0]];
}

export const getSalesHeadersByTypeAndCustomer = (state, type, id) => {
  const ids = state.byType[type] || [];
  return ids.map(id => state.byId[id]).filter(salesHeader => (salesHeader.DocType === type && salesHeader.SellToCusNo === id)).sort(sortDecByStartDate);
}

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getBySearchKey = (state, searchKey) => {
  const ids = state.bySearchKey[searchKey] || [];
  return ids.map(id => state.byId[id]);
}

export const getOutstandingInvoiceHeadersByCustomer = (state, id) => {
  const ids = state.byCustomer[id] || [];
  return ids.map(id => state.byId[id]).filter(filterOutstanding).sort(sortDecByStartDate);
}

const filterOutstanding = invoice => {
  return (parseFloat(invoice.RemAmount.replace(/,/g, "")) - parseFloat(invoice.AllocatedAmount.replace(/,/g, ""))) > 0
}

export const getSalesInvoiceHeadersByCustomer = (state, id) => {
  const ids = state.byCustomer[id] || [];
  return ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const getTotal = (state, searchKey) => state.total[searchKey] || 0;

export const getMetadata = (state, searchKey) => state.metadata[searchKey] || 0;

export const isFetching = state => state.fetching;