import { Button, Col, Divider, Row, Select, Tag } from "antd";
import moment from "moment";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import Api from '../../../api/consts';
import { generateStockCountReport } from '../../../api/reports';
import { dateFormatView } from '../../../const/Formats';
import SelectWarehouseLocation from "../../common/SelectWarehouseLocation";


const { Option } = Select;
class Header extends Component {
  state = {
    generating: false
  }

  getStatusColorDefault = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Customer Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  getStatusColorCreditMemo = status => {
    switch (status) {
      case 'Completed':
      case 'Active':
        return 'green';
      case 'Block':
        return 'red';
      case 'Open':
      default:
        return 'orange';
    }
  }

  getStatusColor = (docType, status) => {
    switch (docType) {
      case 'CreditMemo':
        return this.getStatusColorCreditMemo(status);

      default:
        return this.getStatusColorDefault(status);
    }
  }

  generateStockCountReport = async () => {
    const { stockTake: { DistributorCode, DistributorName, LocCode }, toastManager } = this.props;
    if (!LocCode) {
      return toastManager.add('Please select location before generate report.', { autoDismiss: true, appearance: 'error' });
    }
    try {
      this.setState({
        generating: true
      });

      const response = await generateStockCountReport({
        DistributorCode,
        DistributorName,
        LocCode,
        ExcelReport: 1
      });

      if (!response.error) {
        if (response.url !== "DBReports/null") {
          setTimeout(() => {
            this.setState({ generating: false });
            window.open(Api.files.download.url(response.url), '_blank');
          }, 30000);
        } else {
          toastManager.add('Report details not found report. ', { autoDismiss: true, appearance: 'info' });
          this.setState({ generating: false });

        }
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
        this.setState({ generating: false });
      }
    } catch (error) {
      toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
      this.setState({ generating: false });
    }
  }

  renderDesktopHeader = () => {
    const { stockTake, onInputChange, error, match, stockTakeLines } = this.props;
    const { No, DocDate, Status, DistributorCode, DistributorName, LocCode, TakenBy } = stockTake;
    const { docType } = match.params
    //console.log("error", error)

    const date = DocDate;

    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td style={{ width: 120 }}>No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          {date && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(date).format(dateFormatView)}</td>
          </tr>}

          {Status && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(docType, Status)}>{Status}</Tag>}
            </td>
          </tr>}

        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          {TakenBy && <tr>
            <td style={{ width: 120 }}>Taken by</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{TakenBy}</td>
          </tr>}
          {DistributorCode && <tr>
            <td>DB Code</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{DistributorCode}</td>
          </tr>}
          {DistributorName && <tr>
            <td style={{ width: 120 }}>DB Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{DistributorName}</td>
          </tr>}
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>
          <tr style={{ justifyContent: 'center' }}>
            <Button disabled={this.state.generating} type="primary" title='Export Stock Count Report' style={{ marginBottom: 10 }} onClick={() => this.generateStockCountReport()}>{this.state.generating ? "Generating" : "Export Excel"}</Button>
          </tr>
        </Col>

        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 0, marginBottom: 5 }} /></Col>

      </div>
    )
  }

  render() {

    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
          {/* <Col lg={0} md={0} sm={24} xs={24}>
            {this.renderMobileHeader()}
          </Col> */}
        </Row>
      </div>
    )
  }
}



export default withToastManager(withRouter(connect(null, {})(Header)));