import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchCustomerSubcategories } from '../../redux/actions/customers'
import { getCustomerSubcategories } from "../../redux/reducers";

const { Option } = Select;

class SelectCustomerSubcategory extends Component {
  componentDidMount = () => {
    this.props.fetchCustomerSubcategories();
  }

  setDefaultFilter = () => {
    const { parentCategoryCode, customerSubcategories, onChange, value } = this.props;
    const eligibleCategories = customerSubcategories.filter(category => category.OutletCategory === parentCategoryCode);
    if (eligibleCategories.length === 1 && value !== eligibleCategories[0].Code) {
      setTimeout(() => {
        onChange('OutletSubCategoryCode', eligibleCategories[0].Code, true);
      }, 0);
    }
  }

  render() {
    this.setDefaultFilter();

    const {
      parentCategoryCode,
      customerSubcategories,
      onChange,
      showSearch = false,
      placeholder = 'Select Subcategory',
      allowClear = true,
      value
    } = this.props;

    const eligibleCategories = customerSubcategories.filter(category => category.OutletCategory === parentCategoryCode);

    return (
      <Select
        disabled={eligibleCategories.length < 2}
        placeholder={placeholder}
        value={value}
        onChange={value => onChange('OutletSubCategoryCode', value)}
        allowClear={allowClear}
        showSearch={showSearch}
      >
        {eligibleCategories.map(category =>
          <Option value={category.Code}>{category.Description}</Option>
        )}
      </Select>
    )
  }
}

const mapStateToProps = state => {
  return {
    customerSubcategories: getCustomerSubcategories(state),
  };
};

export default connect(mapStateToProps, { fetchCustomerSubcategories })(SelectCustomerSubcategory);