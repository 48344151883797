import { combineReducers } from 'redux';
import { FETCH_CALLS, FETCH_SINGLE_CALL, FETCH_START_CALL_REASONS, FETCH_END_CALL_STATUSES, START_FETCH_CALLS, FINISH_FETCH_CALLS, START_FETCH_SINGLE_CALL, FINISH_FETCH_SINGLE_CALL, START_FETCH_CALL_REGISTER, FINISH_FETCH_CALL_REGISTER, FETCH_CALL_LOG, FETCH_ACTIVE_CALL, FETCH_SALES_VALUE, START_FETCH_SALES_VALUE, FINISH_FETCH_SALES_VALUE } from '../actions/calls';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CALLS:
			const newState = { ...state };
			action.calls.forEach(call => newState[call.No] = call);
			return newState;
		case FETCH_SINGLE_CALL: {
			const newState = { ...state };
			newState[action.call.No] = action.call;
			return newState;
		}
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_CALLS:
			return action.calls.map(call => call.No);
		default:
			return state;
	}
};

export const fetchingCalls = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_CALLS:
			return true;

		case FINISH_FETCH_CALLS:
			return false;

		default:
			return state;
	}
};

export const totalBySearchKey = (state = 0, action) => {
	switch (action.type) {
		case FETCH_CALLS:
			const newState = { ...state };
			newState[action.searchKey] = action.total;
			return newState;
		default:
			return state;
	}
}

export const startReasonsById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_START_CALL_REASONS:
			const newState = {};
			action.callStartReasons.forEach(reason => newState[reason.Code] = reason);
			return newState;
		default:
			return state;
	}
};

export const startReasonsIds = (state = [], action) => {
	switch (action.type) {
		case FETCH_START_CALL_REASONS:
			return action.callStartReasons.map(reason => reason.Code);
		default:
			return state;
	}
};

export const endStatusesById = (state = {}, action) => {
	switch (action.type) {
		case FETCH_END_CALL_STATUSES:
			const newState = {};
			action.callEndStatuses.forEach(status => newState[status.Code] = status);
			return newState;
		default:
			return state;
	}
};

export const endStatusesIds = (state = [], action) => {
	switch (action.type) {
		case FETCH_END_CALL_STATUSES:
			return action.callEndStatuses.map(status => status.Code);
		default:
			return state;
	}
};

export const fetchingCall = (state = {}, action) => {
	const newState = { ...state };

	switch (action.type) {
		case START_FETCH_SINGLE_CALL:
			newState[action.No] = true;
			return newState;
		case FINISH_FETCH_SINGLE_CALL:
			delete newState[action.No];
			return newState;
		default:
			return state;
	}
}

export const logs = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CALL_LOG:
			const newState = { ...state };
			newState[action.CallRegID] = action.log;
			return newState;
		default:
			return state;
	}
}

export const fetchingRegister = (state = {}, action) => {
	const newState = { ...state };

	switch (action.type) {
		case START_FETCH_CALL_REGISTER:
			newState[action.CallRegID] = true;
			return newState;
		case FINISH_FETCH_CALL_REGISTER:
			delete newState[action.CallRegID];
			return newState;
		default:
			return state;
	}
}

export const salesValue = (state = 0, action) => {
	switch (action.type) {
		case FETCH_CALLS:
			return action.metadata.TotalSalesValue;

		default:
			return state;
	}
}

export const fetchingSalesValue = (state = false, action) => {
	switch (action.type) {
		case START_FETCH_SALES_VALUE:
			return true;
		case FINISH_FETCH_SALES_VALUE:
			return false;
		default:
			return state;
	}
}

export const activeCall = (state = null, action) => {
	switch (action.type) {
		case FETCH_ACTIVE_CALL:
			return action.call || null;
		default:
			return state;
	}
}

export const bySearchKey = (state = {}, action) => {
	switch (action.type) {
		case FETCH_CALLS:
			if (!action.searchKey) return state;

			return {
				...state,
				[action.searchKey]: action.calls.map(call => call.No)
			};

		default:
			return state;
	}
}

const calls = combineReducers({
	byId,
	ids,
	fetchingCalls,
	totalBySearchKey,
	startReasonsById,
	startReasonsIds,
	endStatusesById,
	endStatusesIds,
	fetchingCall,
	fetchingRegister,
	logs,
	activeCall,
	salesValue,
	fetchingSalesValue,
	bySearchKey
});

export default calls;

export const getCalls = state => {
	return state.ids.map(id => state.byId[id]);
}

export const isFetchingCalls = state => state.fetchingCalls;

export const isFetchingCall = (state, No) => state.fetchingCall[No];

export const isFetchingRegister = (state, CallRegID) => state.fetchingCall[CallRegID];

export const isFetchingSalesValue = state => state.fetchingSalesValue;

export const getLog = (state, CallRegID) => state.logs[CallRegID];

export const getTotal = (state, key) => state.totalBySearchKey[key] || 0;

export const getCall = (state, callId) => state.byId[callId]

export const isCallInProgress = state => {
	return !!state.activeCall;
}

export const getStartCallReasons = state => {
	return state.startReasonsIds.map(id => state.startReasonsById[id])
}

export const getCallEndStatuses = state => {
	return state.endStatusesIds.map(id => state.endStatusesById[id])
}

export const getInProgressCall = state => {
	return state.activeCall;
}

export const getSalesValue = state => state.salesValue;

export const getBySearchKey = (state, key) => {
	const ids = state.bySearchKey[key] || [];
	return ids.map(id => state.byId[id]);
}