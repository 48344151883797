import React, { Component } from 'react';
import { Card, Tag, Divider, Col, Icon, Row } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { dateFormatView } from "../../../const/Formats";

const currencyFormat = {
  decimalDigits: 2,
  decimalSeparator: '.'
}

class Details extends Component {

  render() {
    const { salesRep } = this.props;
    return (
      <div>
        <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={8} md={8} sm={24} xs={24}>
              {salesRep.id && <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>ID</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{salesRep.id}</td>
              </tr>}
              {salesRep.name && <tr>
                <td style={{ width: 25 }}><Icon type="user-add" /></td>
                <td>Name</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{salesRep.name}</td>
              </tr>}
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              {salesRep.contactNumber && <tr>
                <td style={{ width: 25 }}><Icon type="phone" /></td>
                <td>Contact No</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{salesRep.contactNumber}</td>
              </tr>}
              {salesRep.email && <tr>
                <td style={{ width: 25 }}><Icon type="mail" /></td>
                <td>Email</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{salesRep.email}</td>
              </tr>}
              {salesRep.address && <tr>
                <td style={{ width: 25 }}><Icon type="pushpin" /></td>
                <td>Address</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{salesRep.address}</td>
              </tr>}
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25 }}><Icon type="info-circle" /></td>
                <td><p>Status</p></td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td><Tag color={getStatusColor(salesRep.status)}>{salesRep.status || 'Active'}</Tag></td>
              </tr>
              <tr>
                <td style={{ width: 25 }}><Icon type="minus-square" /></td>
                <td>Outstanding</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{format(salesRep.outstanding, currencyFormat)}</td>
              </tr>
            </Col>
          </Row>
        </Card>

        {salesRep.blockedReasons && salesRep.blockedReasons.length > 0 && <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Blcoked Reasons</td>
                <td></td>
              </tr>

              {salesRep.blockedReasons.map((reason, index) => [
                <tr>
                  <td style={{ width: 25 }}>{index + 1}.</td>
                  <td><b>{reason.type} - {reason.reasonDescription}</b> </td>
                  <td textAlign='right'><i> {moment(reason.time).format(dateFormatView + 'hh:mm a')}</i></td>
                </tr>,
                <tr>
                  <td style={{ width: 25 }}></td>
                  <td colSpan={2}>{reason.narration}</td>
                  <td></td>
                </tr>,
                <tr>
                  <td style={{ width: 25, height: 15 }}></td>
                  <td></td>
                  <td></td>
                </tr>
              ])}
            </Col>
          </Row>
        </Card>}
      </div>
    )
  }
}

const getStatusColor = status => {
  if (status) return 'red'
  else return 'green'
}

export default Details;