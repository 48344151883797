import * as fromRouteApi from '../../api/routes';
import * as fromApi from '../../api/generic';
import { o } from '../../api/odata';
import { fetchOData, fetchAllBatches } from './fetchOData';

export const FETCH_ROUTES = 'FETCH_ROUTES';
export const FETCH_ROUTE = 'FETCH_ROUTE';
export const START_FETCH_ROUTES = 'START_FETCH_ROUTES';
export const FINISH_FETCH_ROUTES = 'FINISH_FETCH_ROUTES';

let lastSearchKey;
export const fetchRoutes = (filter, page = 1, searchKey = '') => dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_ROUTES });
  return fromApi.get('Route', filter, page).then(response => {
    const { data, error, recordTotal } = response;

    if (error) return { error }

    if (data && data.length === 1) dispatch({ type: FETCH_ROUTE, route: data[0], filter });

    dispatch({ type: FETCH_ROUTES, routes: data, total: recordTotal ? recordTotal : 0, searchKey, filter });

    return response;

  }).catch(error => {
    return { error }
  }).finally(() => {
    if (lastSearchKey === searchKey)
      dispatch({ type: FINISH_FETCH_ROUTES });
  })
}


export const fetchRoute = RouteCode => dispatch => {
  return fromRouteApi.getAll({ RouteCode }).then(response => {
    const { routes, error } = response;

    if (error) return { error }

    if (routes.length > 0) dispatch({ type: FETCH_ROUTE, route: routes[0] });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    dispatch({ type: FINISH_FETCH_ROUTES });
  })
};

export const updateRoute = body => dispatch => {
  return fromRouteApi.update(body).then(response => {
    const { route, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_ROUTE, route });
    return response;
  }).catch(error => {
    return { error }
  })
};
