import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { generateDemoReceipts } from '../../Receipt/ReceiptDemoData';
import { getCreditPaymentHeaders, getCreditPaymentHeadersByCustomer } from "../../../redux/reducers";
import { withRouter } from "react-router-dom";
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ReceiptList extends Component {

  //componentDidMount = () => generateDemoReceipts()

  getColumns = () => {
    const { history, match } = this.props;
    //onClick={() => history.push('/customers/' + match.params.customerId + '/receipts/' + id )}
    const columns = [{
      title: 'No',
      dataIndex: 'No',
      key: 'receiptNo',
      render: id => <p style={{ cursor: 'default' }}>{id}</p>,
    }, {
      title: 'Date',
      dataIndex: 'DocDate',
      key: 'DocDate',
      render: text => <p>{text && moment(text).format(dateFormatView)}</p>,
    }, {
      title: 'Type',
      dataIndex: 'CollectType',
      key: 'CollectType',
      render: text => <p>{text}</p>,
    }, {
      title: 'Status',
      dataIndex: 'OrderStatus',
      key: 'OrderStatus',
      align: 'center',
      render: text => text && <Tag>{text}</Tag>,
    }, {
      title: 'Amount',
      dataIndex: 'PayAmount',
      key: 'PayAmount',
      align: 'right',
      render: text => <p>{format(text, currencyFormat)}</p>,
    }, {
      title: 'Balance',
      dataIndex: 'balanceToSettle',
      key: 'balanceToSettle',
      align: 'right',
      render: (text, receipt) => this.renderBalance(receipt),
    }]

    return columns;
  }

  renderBalance = receipt => {
    const balance = (parseFloat(receipt.PayAmount.replace(/,/g, "")) - parseFloat(receipt.AppliedAmount.replace(/,/g, "")));

    return <p>{format(balance, currencyFormat)}</p>;
  }

  getRowSelection = () => {
    const { selectedReceipt } = this.props;
    return {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        const selectedRowKeys = selectedRows.map(row => row.No);
        this.props.handleOnClickSelectReceipts(selectedRowKeys, selectedRows, record, selected)
      },
      type: 'radio',
      selectedRowKeys: selectedReceipt ? [selectedReceipt.No] : [],
      selectedRows: selectedReceipt ? [selectedReceipt] : []
    };
  }

  render() {
    let { receipts = [], pagination } = this.props;
    pagination = receipts.length > 5 ? pagination : false
    return (
      <Table
        rowKey={'No'}
        rowSelection={this.getRowSelection()}
        columns={this.getColumns()}
        dataSource={receipts}
        pagination={pagination || false}
        size={'small'}
        title={() => <h3>Receipts</h3>} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps;

  const receipts = customerId ? getCreditPaymentHeadersByCustomer(state, customerId) : [];
  return { receipts };
}

export default withRouter(connect(mapStateToProps, {})(ReceiptList));