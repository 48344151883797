import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { ADD_ROUTE_TARGET } from '../../../../const/Permissions';
import { ProMateGreen } from "../../../../const/Theme";
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAuthData, getSalesUserHierarchy } from "../../../../redux/reducers";
import PromatePageHeader from '../../../common/PromatePageHeader';
import DataTable from './components/DataTable';
import Header from './components/Header';

export class AddRouteTarget extends Component {
    _isMounted = false;

    state = {
        loading: true,
        tableData: {
            records: []
        },
    };

    componentDidMount = async () => {
        const { authData, history } = this.props;
        this._isMounted = true;
        if (!authData.permissions.includes(ADD_ROUTE_TARGET.CREATE)) {
            history.push('/targetScheduler');
            return;
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    setLoading = (value) => {
        if (this._isMounted) {
            this.setState({
                loading: value,
            });
        }
    }

    setTableData = (data) => {
        if (this._isMounted) {
            this.setState({
                tableData: data,
            });
        }
    }

    render() {
        const { authData } = this.props;
        return (
            <PromatePageHeader
                title="Add Route Target"
                style={styles.pageHeader}
                onBack={() => this.props.history.goBack()}
                showCallButton={false}
            >
                <Header
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                    setTableData={this.setTableData}
                    permissions={authData?.permissions ?? []}
                />
                <DataTable
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                    tableData={this.state.tableData}
                    setTableData={this.setTableData}
                    permissions={authData?.permissions ?? []}
                />
            </PromatePageHeader>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRouteTarget));

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    },
    text: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}