import React, { Component } from 'react';
import { Row, Card, Col, Select, DatePicker, Icon } from "antd";

import moment from "moment";
import ImageAttachmentContainer from "../../common/ImageAttachmentContainer";
import FormItem from '../../common/FormItem';
import { dateFormatView } from "../../../const/Formats";
import Input from '../../common/EnglishInput';

const { Option } = Select;

class ExpenseCategoryForm extends Component {
  state = {
    showDepositDetailForm: false,
    showChequePDCform: false,
    // name: "",
    // address: "",
    // contactNumber: "",
    // email: "",
    // contactPerson: "",
    // faxNumber: "",
    blockedReasons: [],
  }


  componentDidMount() {
    if (this.props.expense) {
      const { blockedReasons } = this.props.expense;
      this.setState({
        blockedReasons: blockedReasons
      })
    }
  }

  removeItem(index) {
    const list = this.state.blockedReasons;
    list.splice(index, 1);
    this.setState({ blockedReasons: list });
  }

  render() {
    const {
      newCollectionId,
      onInputChange,
      links,
      expenseCategory = {},
      descriptionError,
      categoryError,
    } = this.props;

    const { blockedReasons } = this.state
    const { category, description } = expenseCategory;

    const mainFormWidth = newCollectionId === 'new' ? 24 : 12;
    const attachmentfileUrls = links && links.length > 0 && links.filter(link => link.includes("/attachments/"));
    const fileUrl = attachmentfileUrls && attachmentfileUrls.length > 0 && attachmentfileUrls[attachmentfileUrls.length - 1];
    const signaturefileUrls = links && links.length > 0 && links.filter(link => link.includes("/customer-signature"));
    const customerSigned = signaturefileUrls && signaturefileUrls.length > 0;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col>
              <FormItem label={"Category Name"} error={categoryError}>
                <Input
                  className="ant-input"
                  value={category}
                  type="text"
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("category", value, "categoryError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>

            <Col>
              <FormItem label={"Description"} error={descriptionError}>
                <Input
                  className="ant-input"
                  value={description}
                  type="text"
                  style={{ width: '100%' }}
                  onChange={value => onInputChange("description", value, "descriptionError")}
                  onFocus={(event) => event.target.select()} />
              </FormItem>
            </Col>
          </Col>
        </Row>

        {blockedReasons && blockedReasons.length > 0 && <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Blcoked Reasons</td>
                <td></td>
              </tr>
              {this.state.blockedReasons.map((reason, index) => {
                return (
                  <div key={index}>
                    <tr >
                      <td style={{ width: 25 }}>{index + 1}.</td>
                      <td><b>{reason.type} - {reason.reasonDescription}</b> </td>
                      <td textAlign='right'><i> {moment(reason.time).format(dateFormatView)}</i></td>
                      <td>

                        <Icon type="close" style={{ fontSize: '16px', color: 'red' }} onClick={() => this.removeItem(index)} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 25 }}></td>
                      <td colSpan={2}>{reason.narration}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: 25, height: 15 }}></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </div>
                )
              })}
            </Col>
          </Row>
        </Card>}
      </Card>
    )
  }
}

export default ExpenseCategoryForm;
