import { Button, DatePicker, Row, Select, Spin } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import Api from '../../../../api/consts';
import { generateCustomerWiseDiscountReport } from '../../../../api/reports';
import { ProMateGreen } from '../../../../const/Theme';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAuthData } from '../../../../redux/reducers';

export class CustomerWiseDiscountReport extends Component {
    constructor(props) {
        super(props);
        this.treeData = [];
    }

    state = {
        loading: false,
        generating: false,
        filter: {
            DateFilter: undefined,
            UserTag: undefined
        },
        CSRUsers: []
    }
    async componentDidMount() {
        const { fetchSalesUserHierarchy, authData } = this.props;

        this.setState({
            loading: true
        });
        

        let results = await fetchSalesUserHierarchy(authData);
        results = results.records.map((result) => {
            return JSON.parse(result.jSONSalesHichyStruct);
        });
        for (const record of results) {
            this.generateTreeObject(record);
        }

        this.setState({
            loading: false,
            CSRUsers: this.treeData,
            filter: {
                ...this.state.filter,
                UserTag: this.treeData.length > 0 ? this.treeData[0].value : "",
            }
        });

    }

    handleSelectChange(type, value) {
        this.setState({
            filter: {
                ...this.state.filter,
                [type]: value
            }
        });
    }

    generateTreeObject = (input) => {
        const arr = Object.values(input)
        return arr.map(item => {
            const { user } = item;
            if (user?.role === "CSR") {
                this.treeData.push(
                    {
                        title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                        key: item.positionCode,
                        value: item.positionCode,
                        role: user?.role,
                        code: user.code,
                        name: user?.name
                    }
                );
            }
            return {
                title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                key: item.positionCode,
                value: item.positionCode,
                children: this.generateTreeObject(item.descendantStructure)
            }
        })
    }

    generateReport = async () => {
        const { filter: { DateFilter, UserTag } } = this.state;
        const { toastManager } = this.props;

        if (DateFilter && UserTag) {
            try {
                this.setState({
                    generating: true
                });

                const response = await generateCustomerWiseDiscountReport({
                    DateFilter,
                    UserTag
                });

                if (!response.error) {
                    if (response.url !== "DBReports/null") {
                        setTimeout(() => {
                            this.setState({ generating: false });
                            window.open(Api.files.download.url(response.url), '_blank');
                        }, 30000);
                    } else {
                        toastManager.add('Report details not found report. ', { autoDismiss: true, appearance: 'info' });
                        this.setState({ generating: false });

                    }
                } else {
                    toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                    this.setState({ generating: false });
                }
            } catch (error) {
                toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
                this.setState({ generating: false });
            }
        } else {
            toastManager.add('Please fill required details before generate report. ', { autoDismiss: true, appearance: 'error' });
        }
    }

    onDateChange = (date, _dateString) => {
        if (Array.isArray(date) && date.length > 0) {
            const startDate = moment(date[0]).format("M/DD/YYYY").toString();
            const endDate = moment(date[1]).format("M/DD/YYYY").toString();
            this.setState({
                filter: {
                    ...this.state.filter,
                    DateFilter: `${startDate}..${endDate}`
                }
            });
        }
    }

    render() {
        const { Option } = Select;
        const { RangePicker } = DatePicker;
        const { filter: { UserTag }, CSRUsers, loading, generating } = this.state;

        return (
            <Spin spinning={loading} tip={"Loading Details"}>
                <div style={{ width: "100%", border: `1px solid ${ProMateGreen}`, height: "320px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ padding: "10px 0", textAlign: "center" }}>
                        <h3>
                            <b>Customer Wise Discount Report</b>
                        </h3>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%", padding: "0px 15px" }}>
                        <div style={{ width: "100%" }}>
                            <RangePicker onChange={this.onDateChange} style={{ width: '100%' }} />
                        </div>
                        <div>
                            <Select
                                showSearch
                                placeholder={'Select CSR User'}
                                style={{ width: '100%' }}
                                onChange={value => this.handleSelectChange('UserTag', value)}
                                value={UserTag}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {CSRUsers.map((user, index) =>
                                    <Option key={index} value={user.value}>{user.title}</Option>
                                )}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", bottom: 0, left: 0, right: 0 }}>
                            <Button style={{ marginBottom: 20 }} type='primary' onClick={() => this.generateReport()} disabled={generating}>
                                {generating ? 'Generating...' : 'Generate Report'}
                            </Button>
                        </Row>
                    </div>
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    const authData = getAuthData(state);
    return { authData }
}

const mapDispatchToProps = {
    fetchSalesUserHierarchy
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(CustomerWiseDiscountReport))