import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchCollection, confirmCollection } from "../../../redux/actions/collection";

import { updatePaymentHeader } from "../../../redux/actions/paymentHeaders";
import { addAttachment } from '../../../redux/actions/attachments';
import { getCollection, getPaymentHeader, getAuthData } from '../../../redux/reducers';

import { Spin, Modal } from "antd";
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';

const { confirm } = Modal;

class Container extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const { collectionId } = this.props.match.params

    this.setState({ loading: true })
    this.props.fetchCollection(collectionId).then(() => {
      this.setState({ loading: false })
    })
  }

  confirm = (signatureImageUrl, isEmptyUrl) => {
    const { updatePaymentHeader, toastManager, match, receipt, authData, addAttachment } = this.props;
    const { receiptId } = match.params;

    if (isEmptyUrl) {
      this.setState({ loading: false })
      toastManager.add("You can't confirm the payment without providing the signature.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const updateReceiptData = { ...receipt, OrderStatus: 'Customer Confirmed', UserCode: authData.userId };

    this.setState({ loading: true });
    const update = () => {
      updatePaymentHeader(updateReceiptData).then(response => {
        this.setState({ loading: false })
        if (!response.error) {
          toastManager.add('Payment confirmed.', { autoDismiss: true, appearance: 'success' });
          window.history.go(-1);
        } else {
          toastManager.add('Could not confirm the payment. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    }

    const imageFile = this.getImageFile(signatureImageUrl);

    upload(imageFile, "payments/" + receiptId, 'signature-' + new Date().getTime()).then(response => {
      if (response.error) {
        toastManager.add("Could not upload the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const link = {
        AttachmentName: 'PaymentAttachment',
        DocNo: receipt && receipt.No,
        AttachmentURL: response.path,
        UserCode: authData.userId
      };

      addAttachment('PaymentAttachment', link).then(response => {
        if (response.error) {
          toastManager.add("Could not link the signature.", { autoDismiss: true, appearance: 'error' });
          return;
        }

        update();
      });
    });
  }

  showConfirmCollectionConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    confirm({
      title: 'Are you sure ?',
      content: 'After confirm the receipt you cannot update.',
      onOk() {
        context.confirm(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  render() {
    const { loading } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Pad onConfirmCollection={(signatureImageUrl, isEmptyUrl) => this.showConfirmCollectionConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { receiptId } = ownProps.match.params
  const decodeId = decodeURIComponent(receiptId)
  return {
    collection: getCollection(state, decodeId),
    receipt: getPaymentHeader(state, decodeId),
    authData: getAuthData(state),
  };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchCollection, confirmCollection, updatePaymentHeader, addAttachment })(Container)));