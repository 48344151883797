import React, { Component } from 'react'
import { Button, PageHeader, Spin } from 'antd'
import styles from './styles'
import SupplierReturnTable from './SupplierReturnTable'
import moment from 'moment';
import { fetchCollectionsByCustomer, fetchCollections } from "../../redux/actions/collection";
import { getCollections, getCollectionsByCustomer } from "../../redux/reducers";
import { connect } from 'react-redux'
import { showLoadingMessage } from '../common/Message';
import { generateDemoSupplierReturns } from './SupplierReturnDemoData';
import { getAuthData } from '../../redux/reducers';
import PromatePageHeader from '../common/PromatePageHeader';
import CallStatusValidateButton from '../common/CallStatusValidateButton';

class Receipts extends Component {
  state = {
    loading: false,
    filter: {
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    const { customerId } = this.props.match.params;
    if (customerId) {
      this.setState({ filter: { ...this.state.filter, customerId } }, () => this.fetchAllByCustomer());
    } else {
      this.fetchAllByDateRange();
    }
  }

  fetchAllByDateRange = () => {
    this.showLoadingMessage();
    this.setState({ loading: true });
    this.props.fetchCollections(this.state.filter).then(() => {
      this.setState({ loading: false });
      this.hideLoadingMessage();
    })
  }

  fetchAllByCustomer = () => {
    this.showLoadingMessage();
    this.setState({ loading: true });
    this.props.fetchCollectionsByCustomer(this.state.filter).then(() => {
      this.setState({ loading: false });
      this.hideLoadingMessage();
    });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing...');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  showNewButton = () => {
    const { authData: { roleCode } } = this.props;
    //console.log(roleCode)
    if (roleCode === "DISTRIBUTOR") {
      return true;
    }
    else {
      return false;
    }
  }

  onClickNew = () => {
    const { match } = this.props;
    const url = match.url
    this.props.history.push(url + "/new");
  }

  render() {
    const { supplierReturns } = this.props;
    const { loading, filter } = this.state;
    return (
      <div>
        <PromatePageHeader
          title={'Supplier Returns'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={this.showNewButton() && [
            <CallStatusValidateButton
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute', top: 30, right: 25 }}
              onClick={this.onClickNew}>New</CallStatusValidateButton>
          ]}>
          <Spin spinning={loading && supplierReturns.length === 0} tip={"Loading..."}>
            <SupplierReturnTable
              supplierReturns={supplierReturns}
              handleInputDateChange={this.handleInputDateChange}
              handleSearchClick={this.fetchAllByDateRange}
              filter={filter}
              handleInputTextChange={this.handleInputTextChange}
              authData={this.props.authData} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  //const collections = customerId ? getCollectionsByCustomer(state, customerId) : getCollections(state);
  const supplierReturns = generateDemoSupplierReturns();
  return { supplierReturns: sortListByOrderDate(supplierReturns), authData: getAuthData(state) };
};

const sortListByOrderDate = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (collection1, collection2) {
    const collectionDate1 = parseInt(collection1.supplierReturnDate);
    const collectionDate2 = parseInt(collection2.supplierReturnDate);

    if (collectionDate1 < collectionDate2) return 1;
    if (collectionDate1 > collectionDate2) return -1;
    return 0;
  }) : []
  return sortedList;
}

export default connect(mapStateToProps, { fetchCollections, fetchCollectionsByCustomer })(Receipts);