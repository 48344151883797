import { combineReducers } from 'redux';
import { FETCH_ATTACHMENT } from '../actions/attachments';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ATTACHMENT: {
      const newState = { ...state };
      const attachmentList = newState[action.attachment.DocNo] || [];
      const attachment = attachmentList.length > 0 && attachmentList.find(att => att.AttachmentURL === action.attachment.AttachmentURL);
      if (!attachment) attachmentList.push(action.attachment);
      newState[action.attachment.DocNo] = attachmentList;
      return newState;
    }

    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_ATTACHMENT: {
      const newState = [...state]

      if (!newState.includes(action.attachment.DocNo)) newState.push(action.attachment.DocNo)
      return newState;
    }

    default:
      return state;
  }
};

const attachments = combineReducers({
  byId,
  ids
});

export const getAttachment = (state, docNo) => {
  const attachmentList = state.byId[docNo];

  return attachmentList && attachmentList.length > 0 && attachmentList.find(attachment => attachment && attachment.AttachmentURL && attachment.AttachmentURL.includes('attachments'));
}


export default attachments;