import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { getDemoInvoicesByCount, generateDemoOrders } from '../../SalesInvoices/dummyInvoice'
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
	code: 'lkr',
	symbol: '',
	decimalDigits: 2,
	decimalSeparator: '.'
}

class InvoiceList extends Component {

	getColumns = () => [
		{
			title: 'Invoice ID',
			dataIndex: 'invoiceNo',
			key: 'invoiceNo',
			fixed: 'left',
			width: 100,
			render: text => <p>{text}</p>,
		},
		{
			title: 'Invoice Date',
			dataIndex: 'orderDate',
			key: 'orderDate',
			render: text => <p>{text && moment(text).format(dateFormatView)}</p>,
		},
		{
			title: 'Invoice Type',
			dataIndex: 'orderType',
			key: 'orderType',
			render: text => <p>{text}</p>,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			render: text => text && <Tag>{text}</Tag>,
		},
		{
			title: 'Amount',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			fixed: 'right',
			width: 80,
			align: 'right',
			render: text => <p>{format(text, currencyFormat)}</p>,
		},
		{
			title: 'Outstanding',
			dataIndex: 'balanceToSettle',
			key: 'balanceToSettle',
			fixed: 'right',
			width: 80,
			align: 'right',
			render: text => <p>{format(text, currencyFormat)}</p>,
		}
	];

	getRowSelection = () => {
		const { selectedRowKeys, selectedRows } = this.props;
		return {
			selectedRows,
			selectedRowKeys,
			onChange: (selectedRowKeys, selectedRows) => {
				this.props.onClickSelectInvoices(selectedRowKeys, selectedRows)
			}
		};
	}

	render() {
		generateDemoOrders()
		return (
			<Table
				rowSelection={this.getRowSelection()}
				columns={this.getColumns()}
				dataSource={this.props.invoices}
				pagination={false}
				size={'small'}
				title={() => "Invoices"}
				scroll={{ x: 850, y: 250 }} />
		)
	}
}

export default InvoiceList;