import Api from './consts';
import fetch from './commonFetch';

export const getAll = (filter = {}) => {
  const { url, method } = Api.generic.get;
  return fetch(url('CallRegister'), { method, body: JSON.stringify(filter) }, 'calls');
}

export const getStartCallReasons = () => {
  const { url, method } = Api.generic.get;
  return fetch(url('RefData'), { method, body: JSON.stringify({ 'RefName': 'CallStartReasons' }) }, 'callStartReasons');
}

export const getCallEndStatuses = () => {
  const { url, method } = Api.generic.get;
  return fetch(url('RefData'), { method, body: JSON.stringify({ 'RefName': 'CallEndStatuses' }) }, 'callEndStatuses');
}

export const add = call => {
  const { url, method } = Api.generic.add;

  return fetch(url('CallRegister'), { method, body: JSON.stringify(call) }, 'call');
}

export const update = call => {
  const { url, method } = Api.generic.update;

  return fetch(url('CallRegister'), { method, body: JSON.stringify(call) }, 'call');
}

