import Api from './consts';
import fetch from './commonFetch';

export const getAll = () => {
  const { url, method } = Api.orders.getAll;
  return fetch(url(), { method }, 'orders');
}

export const getForCustomer = id => {
  const { url, method } = Api.customers.orders.getAll;

  return fetch(url(id), { method }, 'orders');
}

export const getForDateRange = filter => {
  const { url, method } = Api.orders.getForDateRange;

  return fetch(url(), { method, body: JSON.stringify(filter) }, 'orders');
}

export const get = id => {
  const { url, method } = Api.orders.get;

  return fetch(url(id), { method }, 'order');
}

export const add = order => {
  const { url, method } = Api.orders.add;

  return fetch(url(), { method, body: JSON.stringify(order) }, 'order');
}

export const update = order => {
  const { url, method } = Api.orders.update;

  return fetch(url(order.orderNo), { method, body: JSON.stringify(order) }, 'order');
}

export const confirm = order => {
  const { url, method } = Api.orders.confirm;

  return fetch(url(order.orderNo), { method, body: JSON.stringify(order) }, 'order');
}

export const deleteOrder = order => {
  const { url, method } = Api.orders.delete;

  return fetch(url(order.orderNo), { method });
}

export const addSalesLine = salesLine => {
  const { url, method } = Api.orders.salesLines.add;

  return fetch(url(salesLine.orderNo), { method, body: JSON.stringify(salesLine) }, 'order');
}

export const updateSalesLineQuantity = salesLine => {
  const { url, method } = Api.orders.salesLines.quantity.update;

  return fetch(url(salesLine.orderNo, salesLine.lineNo), { method, body: JSON.stringify(salesLine) });
}

export const deleteSalesLine = salesLine => {
  const { url, method } = Api.orders.salesLines.delete;

  return fetch(url(salesLine.orderNo, salesLine.lineNo), { method, body: JSON.stringify(salesLine) });
}