import React, { Component } from 'react';
import { Row, Col, Tag, Button, Divider, Select } from "antd";
import moment from "moment";
import SelectWarehouseLocation from "../../common/SelectWarehouseLocation";
import { withRouter } from "react-router-dom";
import { dateFormatView } from '../../../const/Formats';

const { Option } = Select;
class OrderHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Completed':
      case 'Active':
        return 'green';
      case 'Block':
        return 'red';
      case 'Open':
      default:
        return 'orange';
    }
  }

  renderActions = () =>
    <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
            onClick={() => this.props.onShareOrder()}
          >Print & Share</Button>
        </Col>
      </Row>
    </Row>

  renderReturnReasonSelect = () => {
    const { order, onInputChange } = this.props;
    const { ReturnReasonDescrip, ReturnReasonCode } = order;

    return (
      <tr style={{ justifyContent: 'center' }}>
        <td style={{ width: 120 }}>Type</td>
        <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
        <td>
          <Select
            placeholder={'Select Type'}
            value={ReturnReasonDescrip || ReturnReasonCode || ''}
            style={{ width: 200 }}
            onChange={(value) => onInputChange("ReturnReasonCode", value, "ReturnReasonError")}
            disabled={true}
          >

          </Select>
        </td>
      </tr>
    );
  }

  renderDesktopHeader = () => {
    const { order, onInputChange, error, match } = this.props;
    const { No, DocDate, Status, SellToCusName, SellToCusNo, LocCode, CreatedUserName, PostingDate } = order;
    const { docType } = match.params;
    //console.log(docType)
    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {No && <tr>
            <td style={{ width: 120 }}>No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{No}</td>
          </tr>}
          <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(PostingDate).format(dateFormatView)}</td>
          </tr>
          {Status && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(Status)}>{Status}</Tag>}
            </td>
          </tr>}
          {CreatedUserName && <tr>
            <td>Created User</td>
            <td style={{ width: 25, textAlign: 'center' }}>:</td>
            <td>{CreatedUserName}</td>
          </tr>}
        </Col>
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={0} xs={0}>
          {SellToCusNo && <tr>
            <td style={{ width: 120 }}>Customer No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusNo}</td>
          </tr>}
          {SellToCusName && <tr>
            <td style={{ width: 120 }}>Customer Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
          {docType !== 'CreditMemo' && <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>}
        </Col>
        {/* For mobile view */}
        <Col lg={0} md={0} sm={24} xs={24}>
          {SellToCusNo && <tr>
            <td style={{ width: 120 }}>Customer No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusNo}</td>
          </tr>}
          {SellToCusName && <tr>
            <td style={{ width: 120 }}>Customer Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{SellToCusName}</td>
          </tr>}
        </Col>
        {/* For mobile view */}
        {docType !== 'CreditMemo' && <Col lg={0} md={0} sm={24} xs={24}>
          <tr style={{ justifyContent: 'center' }}>
            <td style={{ width: 120 }}>Location</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td style={{ width: 150 }}>
              <SelectWarehouseLocation
                disabled={No}
                placeholder={'Select Location'}
                defaultValue={(!LocCode || (LocCode && LocCode === '')) ? undefined : LocCode}
                onChange={onInputChange}
                style={{ width: '100%', borderColor: 'red' }} />
              {error.locCodeError && <p style={{ color: 'red', marginBottom: 5 }}>{error.locCodeError}</p>}
            </td>
          </tr>
        </Col>}
        <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 0, marginBottom: 5 }} /></Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          {this.renderReturnReasonSelect()}
        </Col>
      </div>
    )
  }

  render() {

    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(OrderHeader);