import React, { Component } from 'react';
import { Row } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class OrderScreen extends Component {

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 80;
    return window.innerHeight - (headerHeight);
  }

  render() {
    const { match } = this.props;
    const { orderId, docType } = match.params
    const newOrder = orderId === 'new' ? true : false;
    let title = 'Order';
    switch (docType) {
      case 'Invoice':
        title = 'Sales Invoice';
        break;
      case 'ReturnOrder':
        title = 'Purchase Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Purchasing Order';
        break;
    }

    return (
      <div>
        <PromatePageHeader
          title={(newOrder ? 'New ' : '') + title}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Row>
            <Container />
          </Row>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default OrderScreen;