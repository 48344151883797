import * as fromApi from '../../api/targetScheduler';
import { updateURLParams, withOutThousandSeperator } from '../../components/TargetScheduler/common/helpers';

export const START_FETCH_YEARS = 'START_FETCH_YEARS';
export const FETCH_YEARS = 'FETCH_YEARS';

export const START_FETCH_MONTHS = 'START_FETCH_MONTHS';
export const FETCH_MONTHS = 'FETCH_MONTHS';

export const START_FETCH_ALL_ROUTE_TARGETS_BY_FILTER = 'START_FETCH_ALL_ROUTE_TARGETS_BY_FILTER';
export const FETCH_ALL_ROUTE_TARGETS_BY_FILTER = 'FETCH_ALL_ROUTE_TARGETS_BY_FILTER';

export const UPDATE_ROUTE_TARGET = 'UPDATE_ROUTE_TARGET';

export const START_FETCH_MONTHLY_TARGET = 'START_FETCH_MONTHLY_TARGET';
export const FETCH_MONTHLY_TARGET = 'FETCH_MONTHLY_TARGET';

export const START_FETCH_ALL_CUSTOMER_FREQUENCIES = 'START_FETCH_ALL_CUSTOMER_FREQUENCIES';
export const FETCH_ALL_CUSTOMER_FREQUENCIES = 'FETCH_ALL_CUSTOMER_FREQUENCIES';

export const UPDATE_CUSTOMER_FREQUENCY = 'UPDATE_CUSTOMER_FREQUENCY';

export const START_FETCH_CUSTOMER_VISIT_TARGETS = 'START_FETCH_CUSTOMER_VISIT_TARGETS';
export const FETCH_CUSTOMER_VISIT_TARGETS = 'FETCH_CUSTOMER_VISIT_TARGETS';

export const UPDATE_CUSTOMER_VISIT_TARGET = 'UPDATE_CUSTOMER_VISIT_TARGET';

export const START_FETCH_TARGET_SCHEDULER_DETAILS = 'START_FETCH_TARGET_SCHEDULER_DETAILS';
export const FETCH_TARGET_SCHEDULER_DETAILS = 'FETCH_TARGET_SCHEDULER_DETAILS';

export const UPDATE_FILTER = 'UPDATE_FILTER';

export const START_FETCH_BRANDS = 'START_FETCH_BRANDS';
export const FETCH_BRANDS = 'FETCH_BRANDS';

export const START_FETCH_ITEM_SUB_CATEGORY_ONE = 'START_FETCH_ITEM_CATEGORY_ONE';
export const FETCH_ITEM_SUB_CATEGORY_ONE = 'FETCH_ITEM_CATEGORY_ONE';

export const fetchAllYears = () => async dispatch => {
    dispatch({ type: START_FETCH_YEARS });
    return fromApi.getAllYears().then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_YEARS, years: data });
        return data;
    }).catch(error => {
        return { error }
    });
}

export const fetchAllMonths = () => async dispatch => {
    dispatch({ type: START_FETCH_MONTHS });
    return fromApi.getAllMonths().then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_MONTHS, months: data });
        return data;
    }).catch(error => {
        return { error }
    });
}

export const fetchAllBrands = () => async dispatch => {
    dispatch({ type: START_FETCH_BRANDS });
    return fromApi.getAllBrands().then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_BRANDS, brands: data });
        return data;
    }).catch(error => {
        return { error }
    });
}

export const fetchAllItemSubCategoryOne = () => async dispatch => {
    dispatch({ type: START_FETCH_ITEM_SUB_CATEGORY_ONE });
    return fromApi.getAllItemSubCategoryOne().then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_ITEM_SUB_CATEGORY_ONE, itemSubCateoryOne: data });
        return data;
    }).catch(error => {
        return { error }
    });
}

export const fetchMonthlyTarget = (filter) => async dispatch => {
    dispatch({ type: START_FETCH_MONTHLY_TARGET });
    return fromApi.getMonthlyTarget(filter).then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_MONTHLY_TARGET, monthlyTarget: data });
        return data;
    }).catch(error => {
        return { error }
    });
}

export const fetchAllRouteTargetsByFilter = (filter) => async dispatch => {
    dispatch({ type: START_FETCH_ALL_ROUTE_TARGETS_BY_FILTER });
    return fromApi.getAllRouteTargetsByFilter(filter).then(async (response) => {
        const { data, error } = await response.json();

        // remove thousand seperater (,) in PlannedTgt
        let updatedData = { ...data, records: data.records.map(record => ({ ...record, PlannedTgt: withOutThousandSeperator(record.PlannedTgt) })) };

        if (error) return { error };

        dispatch({ type: FETCH_ALL_ROUTE_TARGETS_BY_FILTER, routeTargets: updatedData });
        return updatedData;
    }).catch(error => {
        return { error }
    });
}

export const updateRouteTarget = (updateData) => async dispatch => {
    return fromApi.updateRouteTarget(updateData).then((async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        // remove thousand seperater (,) in PlannedTgt
        let updatedData = { ...data, PlannedTgt: withOutThousandSeperator(data.PlannedTgt) };

        dispatch({ type: UPDATE_ROUTE_TARGET, routeTarget: updatedData });
        return updatedData;
    })).catch(error => {
        return { error }
    });
}

export const fetchAllCustomerFrequenciesByFilter = (filter) => async dispatch => {
    dispatch({ type: START_FETCH_ALL_CUSTOMER_FREQUENCIES });
    return fromApi.getAllCustomerFrequenciesByFilter(filter).then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_ALL_CUSTOMER_FREQUENCIES, customerFrequencies: data });

        return data;
    }).catch(error => {
        return { error }
    });
}

export const updateCustomerFrequency = (updateData) => async dispatch => {
    return fromApi.updateCustomerFrequency(updateData).then((async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: UPDATE_CUSTOMER_FREQUENCY, customerFrequency: data });
        return data;
    })).catch(error => {
        return { error }
    });
}

export const fetchAllCustomerVisitTargetsByFilter = (filter, page) => async dispatch => {
    dispatch({ type: START_FETCH_CUSTOMER_VISIT_TARGETS });
    return fromApi.getAllCustomerVisitTargets(filter, page).then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        // remove thousand seperater (,) in some columns
        const updatedData = {
            ...data, records: data.records.map((record) => {
                return {
                    ...record,
                    PlannedTarget: withOutThousandSeperator(record.PlannedTarget),
                    VisitValueOne: withOutThousandSeperator(record.VisitValueOne),
                    VisitValueTwo: withOutThousandSeperator(record.VisitValueTwo),
                    VisitValueThree: withOutThousandSeperator(record.VisitValueThree),
                    VisitValueFour: withOutThousandSeperator(record.VisitValueFour),
                };
            })
        }

        dispatch({ type: FETCH_CUSTOMER_VISIT_TARGETS, customerVisitTarget: updatedData });
        return updatedData;
    }).catch(error => {
        return { error }
    });
}

export const updateCustomerVisitTarget = (data) => async dispatch => {
    return fromApi.updateCustomerVisitTarget(data).then(async (response) => {
        const { data, error } = await response.json();
        if (error) return { error };

        // remove thousand seperater (,) in some columns
        let updatedData = {
            ...data,
            PlannedTarget: withOutThousandSeperator(data.PlannedTarget),
            VisitValueOne: withOutThousandSeperator(data.VisitValueOne),
            VisitValueTwo: withOutThousandSeperator(data.VisitValueTwo),
            VisitValueThree: withOutThousandSeperator(data.VisitValueThree),
            VisitValueFour: withOutThousandSeperator(data.VisitValueFour),
        };

        dispatch({ type: UPDATE_CUSTOMER_VISIT_TARGET, customerVisitTarget: updatedData });
        return updatedData;
    }).catch(error => {
        return { error }
    });
}

export const fetchTargetSchedulerDetailsByFilter = (filter) => async dispatch => {
    dispatch({ type: START_FETCH_TARGET_SCHEDULER_DETAILS, targetScheduler: { records: [] } });
    return fromApi.getTargetSchedulerDetailsByFilter(filter).then(async (response) => {
        const { data, error } = await response.json();

        if (error) return { error };

        dispatch({ type: FETCH_TARGET_SCHEDULER_DETAILS, targetScheduler: data });

        return data;
    }).catch(error => {
        return { error }
    });
}

export const updateFilter = (filter, props) => async dispatch => {
    const params = await updateURLParams(filter, props);
    dispatch({ type: UPDATE_FILTER, filter: filter });
    return params;
}


