import * as fromApi from '../../api/generic';
import { ACTION_TYPES } from '../reducers/users';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects';

const UserRoles = {
  DGM: 'DGM',
  RSM: 'RSM',
  ASM: 'ASM',
  CSR: 'CSR'
};

export const fetchSalesUserHierarchy = (authData) => async dispatch => {

  const filter = {
    userCode: authData.userId
  };

  if (getOfflineData('state').isSystemOffline) {

    const data = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.USER_HIERARCHY);

    dispatch({ type: ACTION_TYPES.FETCH_SALES_USER_HIERARCHY, hierarchy: data });

    return { data }

  } else {
    return fromApi.get('SalesHierarchyStructure', filter).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: ACTION_TYPES.FETCH_SALES_USER_HIERARCHY, hierarchy: JSON.parse(data[0].jSONSalesHichyStruct) });
      return response;
    }).catch(error => {
      return { error }
    })
  }


};