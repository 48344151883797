import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'
import moment from 'moment';

export const FETCH_SALES_HEADERS = 'FETCH_SALES_HEADERS';
export const START_FETCH_SALES_HEADERS = 'START_FETCH_SALES_HEADERS';
export const FINISH_FETCH_SALES_HEADERS = 'FINISH_FETCH_SALES_HEADERS';
export const FETCH_SALES_HEADER = 'FETCH_SALES_HEADER';
export const DELETE_SALES_HEADER = 'DELETE_SALES_HEADER';

let lastSearchKey;
export const fetchSalesHeaders = (filter, page, searchKey = '') => async dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_SALES_HEADERS });

  if (getOfflineData('state').isSystemOffline) {

    let invoices = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.INVOICES);

    let filterInvoiceHeaders = (invoices.data).filter(function (el) {
      return (filter.SOTypeFilter ? el.SOType.includes(filter.SOTypeFilter) : el)
        && (filter.No ? el.No.includes(String(filter.No).split('*')[1]) : el)
        && (filter.DiscountReasonCode ? el.DiscountReasonCode.includes(filter.DiscountReasonCode) : el)
        && (filter.SellToCusNo ? el.SellToCusNo.includes(filter.SellToCusNo) : el)
        && moment(el.CreatedDateTime).isBetween(filter.from, filter.to)
    });

    return { data: filterInvoiceHeaders }

  } else {
    return fromApi.get('SalesInvoiceHeader', filter, page).then(response => {
      const { data, error, recordTotal, responseMetadata } = response;

      if (error) return { error }

      if (data && data.length === 1) dispatch({ type: FETCH_SALES_HEADER, salesHeader: data[0] });

      dispatch({ type: FETCH_SALES_HEADERS, salesHeaders: data, searchKey, total: recordTotal, metadata: responseMetadata });

      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey)
        dispatch({ type: FINISH_FETCH_SALES_HEADERS });
    });
  }
};

export const addSalesHeader = salesHeader => dispatch => {
  return fromApi.add('SalesInvoiceHeader', salesHeader).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_SALES_HEADER, salesHeader: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateSalesHeader = salesHeader => dispatch => {
  return fromApi.update('SalesInvoiceHeader', salesHeader).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_SALES_HEADER, salesHeader: data });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteSalesHeader = salesHeader => dispatch => {
  return fromApi.deleteObject('SalesInvoiceHeader', salesHeader).then(response => {
    const { error } = response;

    if (error) return { error }

    dispatch({ type: DELETE_SALES_HEADER, salesHeader });
    return response;
  }).catch(error => {
    return { error }
  })
};