import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import ExpensesCategoryTableContainer from './ExpenseCategoryTableContainer';
import PromatePageHeader from '../../common/PromatePageHeader';
import { FaPlus } from 'react-icons/fa';
class expenseCategories extends Component {

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Expense Categories'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={[
            <Button style={window.innerWidth < 768 ? styles.addBtnMobile : styles.addBtnWeb} onClick={this.addExpense}>
              {
                window.innerWidth < 768 ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>
          <ExpensesCategoryTableContainer />
        </PromatePageHeader>
      </div>
    )
  }

  addExpense = () => {
    const { match } = this.props;
    const url = match.url
    //console.log(url)
    this.props.history.push(url + "/new");
  }

}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 20,
    right: 180,
    height: 50
  }
};

export default expenseCategories;