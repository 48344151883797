import React, { Component } from 'react';
import { Card, Col, Row, Button, Modal } from 'antd';
import Radium from 'radium';
import { ProMateGreen, ProMateGreenBorder } from '../../../../const/Theme';
import { withRouter } from 'react-router-dom';
import { FaBoxes, FaInbox, FaFileInvoice } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const { confirm } = Modal;

class Actions extends Component {

  getOptions = () => {
    const { match } = this.props;
    const { warehouseId, locationId } = match.params

    return [
      // {
      //   title: 'NEW LOCATION',
      //   to: { pathname: '/locations/new', state: {} },
      //   key: 'NEW_LOCATION'
      // },
      // {
      //   title: 'VIEW LOCATIONS',
      //   to: { pathname: '/locations/5', state: {} },
      //   key: 'VIEW_LOCATIONS'
      // },
      {
        title: 'VIEW INVENTORY',
        to: { pathname: '/warehouses/' + warehouseId + '/locations/' + locationId + '/inventory', state: {} },
        key: 'VIEW_INVENTORY'
      },
      // {
      //   title: 'VIEW LOCATION HISTORY',
      //   to: { pathname: warehouse.warehouseNo+'/history', state: {} },
      //   key: 'LOCATION_HISTORY'
      // }
    ]
  }

  showConfirmCreateOrder = () => {
    const { submitOrder } = this.props;

    confirm({
      title: 'Are you sure ?',
      content: 'This will create a new order.',
      onOk() {
        submitOrder();
      },
    });
  }

  getTileIcon = (title, disabled) => {
    switch (title) {
      case 'NEW LOCATION':
        return <FaInbox style={styles.tileIcon} />;
      case 'VIEW LOCATIONS':
        return <FaBoxes style={styles.tileIcon} />;
      case 'VIEW INVENTORY':
        return <FaFileInvoice style={styles.tileIcon} />;
      case 'VIEW LOCATION HISTORY':
        return <FaFileInvoice style={styles.tileIcon} />;
      default:
        return null
    }
  }

  renderTile = ({ title, key, to }) => {
    return (
      <Link to={to} key={key}>
        <Col className="gutter-row" lg={4} xs={12} sm={6}>
          <div style={styles.tile} key={key}>
            {this.getTileIcon(title)}
            <div style={styles.tileText}><h2 style={{ color: '#ffffff', fontWeight: 600 }}>{title}</h2></div>
          </div>
        </Col>
      </Link>
    );
  }

  render() {
    const { onClickRefresh } = this.props;
    return (
      <Card style={{ margin: 5 }} className={'warehouseInfoCard'} extra={<Button icon="redo" type="link" onClick={() => onClickRefresh()}>Refresh</Button>}>
        <Row gutter={10}>
          {this.getOptions().map(option => this.renderTile(option))}
        </Row>
      </Card>
    );
  }
}

export default withRouter(Radium((Actions)));

const styles = {
  tile: {
    backgroundColor: ProMateGreen,
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    minHeight: 200,
    ':hover': {
      backgroundColor: ProMateGreenBorder,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileText: {
    textAlign: 'center',
    paddingTop: '5px',
    color: 'white',
  },
  disabled_tile: {
    backgroundColor: "#f1f1f1",
    borderColor: ProMateGreenBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    height: '25vw',
    padding: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  disabled_tileText: {
    textAlign: 'center',
    paddingTop: 5,
    color: 'black',
  },
  cursor_not_allowed: {
    cursor: "not-allowed"
  },
  tileIcon: {
    fontSize: 65,
    color: '#ffffff',
    paddingBottom: 4
  },
  disabledTileIcon: {
    fontSize: 65,
    color: '#999999',
    paddingBottom: 4
  },
};