import { authenticate as auth } from '../../api/auth';
import { authencateUser } from '../../components/demoUsers';

export const authenticate = credentials => dispatch => {
  return auth(credentials).then(response => {
    if (!response.error) {
      dispatch({ type: 'LOGIN', authData: response.authData });
      dispatch({ type: 'SET_LOGGED_IN_USER', user: response.authData });
    }
    return response;
  });
}

export const preparingCall = () => dispatch => dispatch({ type: 'CALL_PREPARING' });

export const inCall = () => dispatch => dispatch({ type: 'CALL_STARTED' });

export const outCall = () => dispatch => dispatch({ type: 'CALL_ENDED' });

export const logOut = () => dispatch => dispatch({ type: 'LOGOUT' });