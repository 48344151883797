import { combineReducers } from 'redux';
import { FETCH_ALL_ITEMS } from '../actions/AllItems';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ALL_ITEMS: {
			const newState = {};
			action.AllItems.forEach(item => {
				const { ItemNo } = item;
				const key = ItemNo;
				newState[key] = item
			});
			return newState;
		}
		// case FETCH_ITEM: {
		// 	const item = action.item;
		// 	const { ItemNo, LotNo, LocCode } = item;
		// 	const key = ItemNo + '-' + LotNo + '-' +LocCode;
		// 	state[key] = item;
		// 	return state;
		// }
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_ALL_ITEMS: {
			return action.AllItems.map(item => {
				const { ItemNo } = item;
				return ItemNo;
			});
		}
		// case FETCH_ITEM: {
		//   const newState = [...state]

		// 	const item = action.item
		// 	const { ItemNo, LotNo, LocCode } = item;
		// 	const key = ItemNo + '-' + LotNo + '-' +LocCode;
		// 	if (!newState.includes(key)) newState.push(key)
		// 	return newState;
		// }
		default:
			return state;
	}
};

// export const brands = (state = [], action) => {
//   switch (action.type) {
//     case FETCH_ALL_ITEMS:
//       const brands = [];
//       action.allItems.forEach(item => item.Brand && !brands.includes(item.Brand) && brands.push(item.Brand));
//       return brands;
//     default:
//       return state;
//   }
// };

// export const category1s = (state = [], action) => {
//   switch (action.type) {
//     case FETCH_ALL_ITEMS:
//       const category1s = [];
//       action.allItems.forEach(item => item.SubCategoryCode1 && !category1s.includes(item.SubCategoryCode1) && category1s.push(item.SubCategoryCode1));
//       return category1s;
//     default:
//       return state;
//   }
// };


// export const category2s = (state = [], action) => {
//   switch (action.type) {
//     case FETCH_ALL_ITEMS:
//       const category2s = [];
//       action.allItems.forEach(item => item.SubCategoryCode2 && !category2s.includes(item.SubCategoryCode2) && category2s.push(item.SubCategoryCode2));
//       return category2s;
//     default:
//       return state;
//   }
// };

// export const category3s = (state = [], action) => {
//   switch (action.type) {
//     case FETCH_ALL_ITEMS:
//       const category3s = [];
//       action.allItems.forEach(item => item.SubCategoryCode3 && !category3s.includes(item.SubCategoryCode3) && category3s.push(item.SubCategoryCode3));
//       return category3s;
//     default:
//       return state;
//   }
// };

// export const category4s = (state = [], action) => {
//   switch (action.type) {
//     case FETCH_ALL_ITEMS:
//       const category4s = [];
//       action.allItems.forEach(item => item.SubCategoryCode4 && !category4s.includes(item.SubCategoryCode4) && category4s.push(item.SubCategoryCode4));
//       return category4s;
//     default:
//       return state;
//   }
// };


const allItems = combineReducers({
	byId,
	ids,
});

export default allItems;

export const getAllItems = state => {
	return state.ids.map(id => state.byId[id]).filter(item => !!item)
}

export const getSingleItemFromAllItems = (state, id) => state.byId[id];


// export const getItemByItemNo = (state, itemNo) => {
//   const itemArray = state.ids.map(id => state.byId[id]).filter(item => item.ItemNo === itemNo)
//   if(itemArray.length > 0 ) return itemArray[0] ;
// }


