import { combineReducers } from 'redux';
import { FETCH_ITEMS, FETCH_ITEM } from '../actions/items';

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ITEMS: {
			const newState = {};
			action.items.forEach(item => {
				const { ItemNo, LotNo, LocCode } = item;
				const key = ItemNo + '-' + LotNo + '-' + LocCode;
				newState[key] = item
			});
			return newState;
		}
		case FETCH_ITEM: {
			const item = action.item;
			const { ItemNo, LotNo, LocCode } = item;
			const key = ItemNo + '-' + LotNo + '-' + LocCode;
			state[key] = item;
			return state;
		}
		default:
			return state;
	}
};

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_ITEMS: {
			return action.items.map(item => {
				const { ItemNo, LotNo, LocCode } = item;
				return ItemNo + '-' + LotNo + '-' + LocCode;
			});
		}
		case FETCH_ITEM: {
			const newState = [...state]

			const item = action.item
			const { ItemNo, LotNo, LocCode } = item;
			const key = ItemNo + '-' + LotNo + '-' + LocCode;
			if (!newState.includes(key)) newState.push(key)
			return newState;
		}
		default:
			return state;
	}
};

const inventory = combineReducers({
	byId,
	ids,
});

export default inventory;

export const getItems = state => {
	return state.ids.map(id => state.byId[id]).filter(item => !!item)
}

export const getItem = (state, id) => state.byId[id];


export const getItemByItemNo = (state, itemNo) => {
	const itemArray = state.ids.map(id => state.byId[id]).filter(item => item.ItemNo === itemNo)
	if (itemArray.length > 0) return itemArray[0];
}