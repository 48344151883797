import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

class CustomerHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'ACTIVE':
        return 'green';
      case 'BLOCKED':
        return 'red';
      default:
        return 'orange';
    }
  }



  render() {
    const { customerId, customer } = this.props;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {customerId && <tr>
              <td>Customer Id #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{customerId}</td>
            </tr>}
            <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment().format(dateFormatView)}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={this.getStatusColor('UNVERIFIED')}>{customerId === "new" ? 'UNVERIFIED' : customer.status}</Tag></td>
            </tr>
          </Col>

        </Row>
      </Card>
    )
  }
}

export default CustomerHeader;