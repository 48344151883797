import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper'
import { Card, Button, Radio } from "antd";
import withRouter from "react-router/es/withRouter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

class Pad extends Component {

  componentDidMount = () => {
    this.signaturePad = undefined;
  }

  clear = () => {
    this.signaturePad.clear()
  }

  isSignatureEnabled = () => {
    const { orderConfirmationType = "Customer" } = this.props;
    return orderConfirmationType === "Customer";
  }
  render() {
    const { onConfirmOrder, orderConfirmationType, onConfirmationTypeChange, next, prev, current } = this.props;
    const enableSignature = this.isSignatureEnabled();
    if (this.signaturePad) {
      this.signaturePad.penColor = 'rgb(66, 133, 244)';
    }

    return (
      <div>

        {enableSignature && <SignaturePad ref={ref => this.signaturePad = ref} />}

        <div className="steps-action">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', float: 'right' }}>
            {current < 2 - 1 && (
              <Button style={{ marginRight: 8 }} type="primary" onClick={() => {
                this.clear()
                next()
              }}>
                Next
              </Button>
            )}
            {current === 2 - 1 && (
              <CallStatusValidateButton style={{ marginRight: 8 }} type="primary" onClick={() => onConfirmOrder(enableSignature ? this.signaturePad.toDataURL("image/png") : null, enableSignature ? this.signaturePad.isEmpty() : null)}>
                Confirm
              </CallStatusValidateButton>
            )}
            {current > 0 && (
              <Button style={{ marginRight: 8 }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {enableSignature && <Button style={{ marginRight: 8 }} onClick={() => this.props.history.goBack(-1)}>Cancel</Button>}
            {enableSignature && <Button style={{ marginRight: 8 }} onClick={this.clear}>Clear</Button>}
          </div>
        </div>


      </div>
    )
  }
}

const styles = {
  radioButton: {
    textAlign: 'center',
    width: '32.2vw'
  }
}

export default withRouter(Pad);