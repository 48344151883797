import { combineReducers } from 'redux';
import { FETCH_SALES_DISCOUNT_REASON } from '../actions/salesDiscountReasons';


export const fetchingDiscountReasons = (state = [], action) => {
	switch (action.type) {

		case FETCH_SALES_DISCOUNT_REASON:
			return action.salesDiscountReasons;
		default:
			return state;
	}
};

const salesDiscountReasons = combineReducers({
	fetchingDiscountReasons,
});

export default salesDiscountReasons;

export const getSalesDiscountReason = state => {
	return state.fetchingDiscountReasons;
}