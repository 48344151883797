import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { dateFormatView } from "../../const/Formats";
import { getLoggedInUser } from "../../redux/reducers";
import { SALES_INVOICES } from "../../const/Permissions";

class InvoiceCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history, loggedInUser: { permissions = [] } } = this.props;
    const { No, OrderDate, SellToCusNo, SellToCusName, Status, Amount, PayMethodCode, ageOfInvoice = 0, AmountIncluVAT, CreatedUserName } = order;
    return (
      <Row onClick={() => (permissions.includes(SALES_INVOICES.VIEW) || permissions.includes(SALES_INVOICES.UPDATE)) && history.push("/sales/Invoice/" + encodeURIComponent(No))}>

        <Row style={styles.alignRow}>
          <Col xs={5} sm={4} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.orderNo}>{No}</span>
          </Col>

          <Col xs={5} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
          </Col>

          <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <span >{PayMethodCode}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right', paddingRight: 45 }}>
            <span >{ageOfInvoice}</span>
          </Col>

          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={{ wordBreak: 'break-all' }}>{SellToCusNo}</span>
          </Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{SellToCusName}</span>
          </Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{CreatedUserName}</span>
          </Col>

          <Col xs={7} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={Amount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}

              decimalScale={2} />
          </Col>

          <Col xs={7} sm={6} md={3} lg={3} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={AmountIncluVAT}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={5} sm={5} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={9} sm={9} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{SellToCusName}</span>
          </Col>
          <Col xs={10} sm={10} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{CreatedUserName}</span>
          </Col>

        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={5} sm={4} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{PayMethodCode}</span>
          </Col>

          <Col xs={5} sm={5} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{ageOfInvoice}</span>
          </Col>

        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 5
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(InvoiceCard));