import React, { Component } from "react";
import { Col, Row, Tag } from "antd";

class OrderCardHeader extends Component {
	render() {

		return (
			<Row>
				<Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea' }}>
					<Col xs={0} sm={6} md={3} lg={3} style={{ textAlign: 'left' }}>Order No</Col>
					<Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>Status</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Order Date</Col>
					<Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>Order By</Col>
					<Col xs={0} sm={3} md={2} lg={2} style={{ textAlign: 'left' }}>DB Code</Col>
					<Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>DB Name</Col>
					<Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>DB Status</Col>
					<Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>Teritory</Col>
					<Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'left' }}>PO Age</Col>
					<Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>

					{/* mobile view */}
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Details</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'Center' }}>Order Date</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>Amounts</Col>
				</Row>
				{/* <Row style={styles.lowerRow}>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<span style={styles.orderDate}>{moment(orderDate).format(dateFormatView)}</span>
					</Col>
					<Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{sellToCustomerCode}</p>
					</Col>
				</Row>
				<Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
					</Col>
				</Row> */}
			</Row>
		)
	}
}

const styles = {
	orderNo: {
		fontSize: 16,
		fontWeight: 600
	},
	alignRow: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: 35,
		paddingLeft: 20
	},
	orderDate: {
		fontSize: 12,
		fontWeight: 700
	},
	customerCode: {
		fontSize: 10,
		fontWeight: 700,
	},
	customerCodeBottom: {
		fontSize: 10,
		fontWeight: 700,
	},
	status: {
		fontSize: 10,
		fontWeight: 700,
		marginRight: 0
	},
	price: {
		fontSize: 18,
		fontWeight: 600,
	},
	icon: {
		fontSize: 10,
		marginRight: 5
	},
	lowerRow: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 5,
		marginTop: 2
	},
	bottomColumnStyles: {
		textAlign: 'left',
		color: '#808080',
		fontSize: 12
	}

}
export default OrderCardHeader;