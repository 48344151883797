import React, { Component } from "react";
import { Col, Form, Input, Row, Table, TreeSelect, Select, DatePicker, Button } from "antd";
import SalesRepresentativeCard from "./SalesRepCard";
import CurrencyFormat from "react-currency-format";
import SalesRepCardHeader from "./SalesRepCardHeader";
import { dateFormat, dateTimeFormatView } from "../../../const/Formats";
import { routeNames } from "../../Routes/RouteDemoData";
import { names } from "../../Customers/CustomerDemoData";
import moment from 'moment';
const { Option } = Select;
const { Search } = Input;
const { TreeNode } = TreeSelect;

class SalesRepresentativeTable extends Component {

  renderColumns = () => {
    return [{
      render: customer => <SalesRepresentativeCard customer={customer} />
    }]
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 25;
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { authData: { userId, roleCode } } = this.props;
    const { handleInputTextChange, handleSearchClick } = this.props;
    return (
      <div className="promate-table-header">
        <Form style={{ marginBottom: 20, marginRight: 15 }}>
          <Row gutter={5}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>

              <Col lg={5} md={5} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <TreeSelect
                  showSearch
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  defaultValue={roleCode === "SALES_REPRESENTATIVE" ? "Janaka" : (roleCode === "DISTRIBUTOR") ? "distributor" : "admin"}
                  allowClear
                  treeDefaultExpandAll
                >
                  <TreeNode value="admin" title="Promate Admin">
                    <TreeNode value="distributor" title="Distributor">
                      <TreeNode value="Janaka" title="Janaka" />
                      <TreeNode value="Nimal" title="Nimal" />
                    </TreeNode>
                    <TreeNode value="accountant" title="Promate Accountant">
                      <TreeNode value="Kamal" title={"Kamal"} />
                    </TreeNode>
                  </TreeNode>
                </TreeSelect>
              </Col>

              {/* <Col lg={4} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <DatePicker
                  format={dateTimeFormat}
                  //value={filter.from}
                  placeholder="From"
                  //onChange={(date, dateString) => handleInputDateChange("from", date)}
                  style={{ width: '100%' }}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                />
              </Col>

              <Col lg={4} md={24} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <DatePicker
                  format={dateTimeFormat}
                 // value={filter.to}
                  placeholder="To"
                 // onChange={(date, dateString) => handleInputDateChange("to", date)}
                  style={{ width: '100%' }}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                />
              </Col> */}

              <Col lg={5} md={5} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Select placeholder={"Route"}
                // onChange={(value) => handleInputTextChange(value)}
                >
                  {routeNames.map(route =>
                    <Option value={route}>{route}</Option>
                  )}
                </Select>
              </Col>


              <Col lg={5} md={5} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Select placeholder={"Outlet"}
                // onChange={(value) => handleInputTextChange(value)}
                >
                  {names.map(name =>
                    <Option value={name}>{name}</Option>
                  )}
                </Select>
              </Col>

              <Col lg={6} md={6} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Search placeholder="Search Text" onChange={(e) => handleInputTextChange(e.target.value)} />
              </Col>

              <Col lg={3} md={3} sm={24} xs={24} style={{ marginBottom: 5 }}>
                <Button type="primary" block onClick={handleSearchClick}>Search</Button>
              </Col>
            </Col>
          </Row>
        </Form>
        <SalesRepCardHeader />
      </div>
    )
  }

  renderFooter = noOfRecords => {
    const { outstandingTotal } = this.props;
    return (
      <div style={{
        backgroundColor: '#eceaea',
        height: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: -10,
        padding: 10
      }}>
        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
          <Col span={12}>{noOfRecords > 0 ? 'Total ' + noOfRecords : 'No'} Sales Representative{noOfRecords !== 1 && 's'}</Col>
          <Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
            <CurrencyFormat
              value={outstandingTotal}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              decimalScale={2} />
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { customers } = this.props
    return (
      <Col>
        <Table
          rowKey={'id'}
          rowClassName={'orderRow'}
          showHeader={false}
          bodyStyle={{ minHeight: this.getTableHeight(customers) }}
          columns={this.renderColumns()}
          dataSource={customers}
          size={'small'}
          pagination={false}
          scroll={{ y: this.getScrollAreaHeight() }}
          title={() => this.renderHeader()}
          footer={() => this.renderFooter(customers.length)}
        />
      </Col>
    )
  }
}

export default SalesRepresentativeTable;