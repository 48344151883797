import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_WAREHOUSE_LOCATIONS = 'FETCH_WAREHOUSE_LOCATIONS';

export const fetchWarehouseLocations = filter => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {

    var { data, error } = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.USER_WISE_LOCATON).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    dispatch({ type: FETCH_WAREHOUSE_LOCATIONS, locations: data });

  } else {
    return fromApi.get('UserWiseLocation', filter).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_WAREHOUSE_LOCATIONS, locations: data });
      return response;
    }).catch(error => {
      return { error }
    })
  };
}


