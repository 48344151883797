import { combineReducers } from 'redux';
import { FETCH_ALL_ITEMS } from '../actions/AllItems';

const filterReducer = (state = [], action, filterField) => {
	switch (action.type) {
		case FETCH_ALL_ITEMS:
			const options = action.AllItems.map(item => item[filterField]).filter(option => !!option);
			const uniqueOptions = [...new Set(options)];
			return uniqueOptions;
		default:
			return state;
	}
};

const itemFiltersAllItems = combineReducers({
	Brand: (state, action) => filterReducer(state, action, 'Brand'),
	SubCategoryCode1: (state, action) => filterReducer(state, action, 'SubCategoryCode1'),
	SubCategoryCode2: (state, action) => filterReducer(state, action, 'SubCategoryCode2'),
	SubCategoryCode3: (state, action) => filterReducer(state, action, 'SubCategoryCode3'),
	SubCategoryCode4: (state, action) => filterReducer(state, action, 'SubCategoryCode4')
});

export default itemFiltersAllItems;

export const getFilterOptions = (state, filterField) => {
	return state[filterField];
}