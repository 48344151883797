import React, { Component } from "react";
import { Col, Row, Tag } from "antd";

class CallCard extends Component {
  getStatusColor = status => {
    if (status === "STARTED") return 'green';
    else return 'red';
  }

  render() {

    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, backgroundColor: '#eceaea', padding: 18 }}>
          <Col xs={8} sm={8} md={2} lg={2} style={{ textAlign: 'left' }}>ID</Col>

          <Col xs={0} sm={0} md={2} lg={2}>Status</Col>

          <Col xs={0} sm={0} md={4} lg={5} style={{ textAlign: 'left' }}>Customer </Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Customer Code</Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>Customer Name</Col>

          <Col xs={0} sm={0} md={4} lg={4} style={{ textAlign: 'left' }}>Call History</Col>
          <Col xs={0} sm={0} md={3} lg={4} style={{ textAlign: 'left' }}>Salesperson</Col>
          <Col xs={0} sm={0} md={4} lg={3} style={{ textAlign: 'left' }}>Transactions</Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>S.P Name</Col>
          <Col xs={0} sm={0} md={1} lg={1} style={{ textAlign: 'left' }}>Productive</Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'center' }}>Productive</Col>
          <Col xs={8} sm={8} md={4} lg={3} style={{ textAlign: 'right' }}>Sales Value</Col>

        </Row>
      </Row >
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },

}
export default CallCard;