import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, confirmOrder } from "../../../redux/actions/orders";
import { getOrder } from "../../../redux/reducers";
import { Spin, Modal } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';
import { currentLocation } from '../../../utils/Location';
import { generateDemoStockTransfers, getDemoTransfer } from '../dummyStockTransfer'
import uuid from 'uuid/v1';

const { confirm } = Modal;

export const defaultStockTransfer = {
  salesLines: [],
  status: "Open",
  sessionId: uuid(),
  stockTransferId: "new",
  fromWarehouseCode: undefined,
  fromLocationCode: undefined,
  toWarehouseCode: undefined,
  toLocationCode: undefined,
  transferDate: moment(),
  transferdBy: undefined,
  totalDiscountAmount: 0,
  totalAmount: 54231,
  amountIncludingVat: 562349,
}

class Container extends Component {
  state = {
    loading: false,
    orderConfirmationType: "Distributor",
    stockTransfer: { ...defaultStockTransfer }
  }

  componentDidMount() {
    const { transferId } = this.props.match.params
    const newTransfer = transferId === 'new';

    if (newTransfer) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      this.setState({ stockTransfer: { ...defaultStockTransfer } })
    } else {
      // this.setState({ loading: true })
      // this.props.fetchOrder(orderId).then(() => {
      //   this.setState({ loading: false })
      // })
      this.setState({ stockTransfer: getDemoTransfer(transferId) })
    }
  }

  // componentWillReceiveProps = nextProps => {
  //   const { order, match } = nextProps;
  //   const { orderId } = match.params
  //   const newOrder = orderId === 'new';

  //   if (newOrder) {
  //     this.setState({ order: { ...defaultOrder } })
  //   } else {
  //     this.setState({ order: { ...order, status: order.status } })
  //   }
  // }

  calculateTotalAmount = () => {
    const { order = {} } = this.props;
    const { salesLines = [] } = order;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  confirmOrder = (signatureImageUrl, isEmptyUrl) => {
    const { confirmOrder, toastManager, history, match } = this.props;
    const { orderConfirmationType } = this.state;
    const { orderId } = match.params

    if (!orderConfirmationType) {
      toastManager.add("You can't confirm the order without confirmation type.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const updateOrderData = { orderNo: orderId, status: "CONFIRMED", links: [], signedBy: orderConfirmationType }

    this.setState({ loading: true })

    if (orderConfirmationType === "OTPO") {
      confirmOrder(updateOrderData).then(response => {
        setTimeout(() => {
          this.setState({ loading: false })
          toastManager.add('Successfully Confirmed Stock Transfer.', { autoDismiss: true, appearance: 'success' });
          history.goBack();
        }, 2000)
        // this.setState({ loading: false })
        // if (!response.error) {
        //   toastManager.add('Successfully update order.', { autoDismiss: true, appearance: 'success' });
        //   history.goBack()
        // } else {
        //   toastManager.add('Failed to update order. ' + response.error, { autoDismiss: true, appearance: 'error' });
        // }
      })
    } else {
      if (isEmptyUrl) {
        this.setState({ loading: false })
        toastManager.add("You can't confirm the Stock transfer without providing the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const imageFile = this.getImageFile(signatureImageUrl);

      setTimeout(() => {
        this.setState({ loading: false })
        toastManager.add('Successfully Confirmed stock transfer.', { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }, 2000)

      // upload(imageFile, "orders/" + orderId, 'signature-'+new Date().getTime()).then(response => {
      //   if (response.error) {
      //     toastManager.add("Could not upload the customer signature.", { autoDismiss: true, appearance: 'error' });
      //     return;
      //   }

      //   updateOrderData.links = [ response.path ];

      //   confirmOrder(updateOrderData).then(response => {
      //     this.setState({ loading: false })
      //     if (!response.error) {
      //       toastManager.add('Successfully update order.', { autoDismiss: true, appearance: 'success' });
      //       history.goBack()
      //     } else {
      //       toastManager.add('Failed to update order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      //     }
      //   })
      // });
    }
  }

  showConfirmOrderConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    confirm({
      title: 'Are you sure ?',
      content: 'After confirm the stock transfer you cannot update this stock transfer.',
      onOk() {
        context.confirmOrder(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  onChangeOrderConfirmationType = type => {
    this.setState({ orderConfirmationType: type });
  }

  navigateToInventory = () => this.props.history.push({ pathname: '/inventory', state: { order: this.state.order } })

  render() {
    const { loading,/* order,*/ orderConfirmationType } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading Stock transfer"}>
        {/* <OrderHeader order={order} />
        <SalesLineSummary order={order} />
        <OrderFooter totals={this.calculateTotalAmount()} /> */}
        <Pad orderConfirmationType={orderConfirmationType} onConfirmationTypeChange={this.onChangeOrderConfirmationType} onConfirmOrder={(signatureImageUrl, isEmptyUrl) => this.showConfirmOrderConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId } = ownProps.match.params

  return { order: getOrder(state, orderId) };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, confirmOrder })(Container)));