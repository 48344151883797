import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaMapMarker, FaPhoneSquare } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';

class SalesRepresentativeCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'UNVERIFIED':
        return 'orange';
      case 'ACTIVE':
        return 'green';
      case 'BLOCKED':
        return 'red';
      default:
        return '';
    }
  }

  render() {
    const { customer, history } = this.props;
    const { id, name, address, status, outstanding, contactNumber } = customer;
    const { match } = this.props;
    const url = match.url
    return (
      <Row onClick={() => history.push(url + "/" + id)}>
        <Row style={styles.alignRow}>
          <Col xs={6} sm={5} md={2} lg={2}>
            <span>{id}</span>
          </Col>
          <Col xs={12} sm={10} md={6} lg={6}><span style={styles.name}>{name}</span></Col>
          <Col xs={0} sm={0} md={6} lg={6}><span>{address}</span></Col>
          <Col xs={0} sm={0} md={3} lg={3}><span>{contactNumber}</span></Col>
          <Col xs={0} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}><Tag style={styles.status} color={this.getStatusColor(status)}>{status || 'Active'}</Tag></Col>
          <Col xs={6} sm={5} md={4} lg={4} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={outstanding}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={6} sm={0} md={0} lg={0}><Tag style={styles.statusBottom} color={this.getStatusColor(status)}>{status || 'Active'}</Tag></Col>
          <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'left', color: '#808080', fontSize: 12 }}><FaPhoneSquare style={styles.icon} /><span>{contactNumber}</span></Col>
          <Col xs={18} sm={19} md={0} lg={0} style={{ color: '#808080', fontSize: 12 }}><FaMapMarker style={styles.icon} /><span>{address}</span></Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 5
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  }

}
export default withRouter(SalesRepresentativeCard);