import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchStockTakesHeaders, addStockTakeHeader, updateStockTakeHeader, deleteStockTakeHeader } from "../../../redux/actions/stockTakeHeaders";
import { fetchStockTakeLines } from "../../../redux/actions/stockTakeLines";
import { fetchInventory } from "../../../redux/actions/inventory";
import { fetchDistributors } from "../../../redux/actions/distributors";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import StockHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import StockFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import { getAuthData, getStockTakesHeadersByNo, DOC_TYPE, getDistributor, getLocations, getStockTakeLinesByDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser } from '../../../redux/reducers';
import { generateReport } from '../../../api/reports';
import Api from '../../../api/consts';
import { STOCK_TAKE } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchWarehouseLocations } from '../../../redux/actions/warehouses';
import { fetchSalesReturnReasons } from '../../../redux/actions/salesReturnReasons';
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';

export const defaultTake = {
  SessionID: uuid(),
  StockTemplateName: undefined,
  StockBatchName: undefined,
  No: undefined,
  DocDate: moment(new Date()).format(dateFormatUpdate),
  TakenBy: undefined,
  DistributorCode: undefined,
  DistributorName: undefined,
  Status: "Open",
}

const toastMessage = {

}

class Container extends Component {
  state = {
    loading: false,
    stockTake: { ...defaultTake },
    locCodeError: undefined,
    returnReasonError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { stockTakeId } = this.props.match.params
    const { distributorId } = this.props
    const newTake = stockTakeId === 'new';

    this.props.fetchWarehouseLocations({ UserCode: this.props.authData.userId });
    this.props.fetchDistributors({ UserCode: this.props.authData.userId });
    if (!newTake) {
      this.setState({ loading: true });
      this.props.fetchStockTakeLines({ DocNo: decodeURIComponent(stockTakeId), NoZeroQty: "yes" });
      this.props.fetchStockTakesHeaders({ No: decodeURIComponent(stockTakeId) }).then(({ data, error }) => {
        if (!error) {
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
    } else {
      this.setData(this.props);
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.stockTake && !nextProps.stockTake) {
      // The order is deleted.
      window.history.back();
    } else if (nextProps && nextProps.stockTake) {
      this.setState({ stockTake: { ...nextProps.stockTake } });
    } else {
      this.setData(this.props);
    }
  }

  setData = props => {
    const { distributor } = props;

    this.setState({
      stockTake: {
        ...this.state.stockTake,
        DistributorCode: distributor ? distributor.Code : '',
        DistributorName: distributor ? distributor.Name : '',
      }
    }, this.setDeviceMapLocation)
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  submitStockTakeHeader = () => {
    const { match, toastManager } = this.props;
    const title = 'Stock Take';

    if (!locationEnabled) {
      toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) return;

    const newStockTakeHeader = this.createNewStockTakeHeaderRequest();

    this.setState({ loading: true })
    this.sendCreateStockTakeHeaderRequest(newStockTakeHeader).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        this.props.fetchStockTakeLines({ DocNo: response.data.No, NoZeroQty: "yes" });
        toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
        this.props.history.replace("/distributors/" + match.params.distributorId + "/stockTake/" + encodeURIComponent(response.data.No));
      } else {
        toastManager.add('Could not create the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  validateForm = () => {
    const { docType } = this.props.match.params;
    const { stockTake } = this.state;
    const { LocCode } = stockTake;

    let locCodeError = undefined;
    if (!this.isValidValue(LocCode)) locCodeError = 'Location is required.'

    this.setState({ locCodeError });

    return !locCodeError;
  }

  isValidValue = value => value && value.trim().length > 0;

  createNewStockTakeHeaderRequest = () => {
    const { stockTake } = this.state;
    const { authData: { userId }, match } = this.props;
    return {
      ...stockTake,
      StockTemplateName: 'Stock Take',
      StockBatchName: 'Stock Take',
      No: 'New',
      DocDate: moment(new Date()).format(dateFormatUpdate),
      UserCode: userId
    }
  }

  sendCreateStockTakeHeaderRequest = stockTakeHader => {
    return this.props.addStockTakeHeader(stockTakeHader);
  }

  sendUpdateSalesHeaderRequest = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    let title = 'Stock Take';

    this.setState({ loading: true })
    this.props.updateStockTakeHeader({ ...this.state.stockTake, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not update the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  shareOrder = () => {
    this.setState({ sharing: true });

    const { stockTake, toastManager, match } = this.props;
    const reportRequest = {
      No: stockTake.No
    };

    generateReport('stockTake', reportRequest).then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventoryItemListView = () => {
    const { match, toastManager } = this.props;
    const { stockTakeId, docType } = match.params;

    let title = 'Stock Take';

    if (stockTakeId === 'new') {

      if (!this.validateForm()) return;

      const newStockTakeHeader = this.createNewStockTakeHeaderRequest();

      this.setState({ loading: true })
      this.sendCreateStockTakeHeaderRequest(newStockTakeHeader).then(response => {
        if (!response.error) {
          this.props.fetchStockTakeLines({ DocNo: response.data.No, NoZeroQty: "yes" });
          toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
          const stockTake = { orderNo: encodeURIComponent(response.data.No), ...response.data };
          this.props.history.replace({ pathname: "/distributors/" + match.params.distributorId + "/stockTake/" + encodeURIComponent(response.data.No) });

          this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "stockTakes", order: stockTake, id: response.data.No } });

        } else {
          this.setState({ loading: false })
          toastManager.add('Could not create the stock take. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    } else {
      const stockTake = this.state.stockTake;
      this.setState({ loading: true });
      setTimeout(() => {
        this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "stockTakes", order: stockTake, id: stockTakeId } });
      }, 100);
    }
  }

  navigateToConfirmOrder = () => {
    const { stockTakeId } = this.props.match.params

    this.props.history.push({ pathname: '/stockTake/' + stockTakeId + '/confirm', state: { order: this.state.stockTake } })
  }

  // generateInvoice = () => {
  //   const { orderId } = this.props.match.params
  //   this.props.history.push({ pathname: '/salesOrders/' + orderId + '/salesInvoices/new', state: { order: this.state.order } })
  // }



  onDeleteOrder = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    let title = 'Stock Take';

    this.setState({ loading: true })
    this.props.deleteStockTakeHeader({ ...this.state.stockTake, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' deleted.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not delete the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onApproved = () => {
    const { updateStockTakeHeader, toastManager, authData: { userId } } = this.props;
    const updateOrderData = { ...this.state.stockTake, Status: 'Approved', UserCode: userId };

    updateStockTakeHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully  updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not updated the ' + 'stock take' + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  onApprovedAndAdjust = () => {
    const { updateStockTakeHeader, toastManager, authData: { userId } } = this.props;
    const updateOrderData = { ...this.state.stockTake, Status: '	Approved with Adj', UserCode: userId };

    updateStockTakeHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not updated the ' + 'stock take' + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }


  onReject = () => {
    const { updateStockTakeHeader, toastManager, authData: { userId } } = this.props;
    const updateOrderData = { ...this.state.stockTake, Status: 'Open', UserCode: userId };

    updateStockTakeHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully  updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not updated the ' + 'stock take' + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }


  onInputChange = (field, value, errorKey) => {
    const { stockTake } = this.state;

    this.setState({
      stockTake: { ...stockTake, [field]: value },
      [errorKey]: undefined
    })
  }

  renderActions = () => {
    const { match, authData: { roleCode }, stockTake, stockTakeLines, loggedInUser: { permissions = [] }, location, distributor } = this.props;
    const { Status, CustStatus } = stockTake || {};
    const { stockTakeId } = match.params
    const newTake = stockTakeId === 'new';
    const isLocationOwner = location && location.WarehouseOwner;
    const enableCreate = permissions.includes(STOCK_TAKE.CREATE)
    const enableUpdate = permissions.includes(STOCK_TAKE.UPDATE)
    const enableDelete = permissions.includes(STOCK_TAKE.DELETE)
    const enableConfirm = permissions.includes(STOCK_TAKE.CONFIRM)
    const enablePrint = permissions.includes(STOCK_TAKE.PRINT)
    const enableApprove = permissions.includes(STOCK_TAKE.APPROVE)
    const enableReject = permissions.includes(STOCK_TAKE.REJECT)

    const isBlocked = CustStatus && CustStatus === 'Blocked';


    if (newTake && enableCreate && !isBlocked) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={this.submitStockTakeHeader}
          >
            <Button type="primary" >Create</Button>
          </Popconfirm>
        </Col></Row>
    } else {
      return <div>
        {
          (Status !== '' && Status !== 'Open') && enablePrint &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button
              loading={this.state.sharing}
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
              onClick={this.shareOrder}
            >Print & Share</Button>
          </Col>
        }

        {
          stockTakeLines && stockTakeLines.length > 0 && Status === 'Open' && enableConfirm && !isBlocked &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button type="primary" onClick={this.navigateToConfirmOrder}>Confirm</Button>
          </Col>
        }

        {
          (enableDelete) && !isBlocked && Status === 'Open' &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }

        {/* {
          (enableUpdate) && !isBlocked && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button type="primary" onClick={this.sendUpdateSalesHeaderRequest}>Update</Button>
          </Col>
        } */}

        {
          (enableApprove) && !isBlocked && Status === 'Confirmed' &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"

              cancelText="No"
              onConfirm={this.onApproved}
            >
              <Button type="primary">Approve</Button>
            </Popconfirm>
          </Col>
        }

        {
          (enableReject) && !isBlocked && Status === 'Confirmed' &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"

              cancelText="No"
              onConfirm={this.onReject}
            >
              <Button type="primary">Reject</Button>
            </Popconfirm>
          </Col>
        }

      </div >
    }
  }

  render() {
    const { distributor, match, stockTakeLines, customerId } = this.props;
    const { stockTakeId } = match.params
    const newTake = stockTakeId === 'new';
    const { loading, stockTake, locCodeError, payMethodCodeError, payTermCodeError, returnReasonError } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <StockHeader
            customerId={customerId}
            stockTake={stockTake}
            stockTakeLines={stockTakeLines}
            distributor={distributor}
            newTake={newTake}
            onInputChange={this.onInputChange}
            error={{ locCodeError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToInventoryItemListView}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToInventoryItemListView}
              />
            </Col>
          </div>
          <StockFooter stockTake={stockTake} onInputChange={this.onInputChange} />
          <div style={{ float: 'right', margin: 15 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakeId, distributorId } = ownProps.match.params
  const newTake = stockTakeId === 'new';
  const decodeId = decodeURIComponent(stockTakeId);
  const stockTake = getStockTakesHeadersByNo(state, decodeId);
  const distributor = getDistributor(state, distributorId);
  return {
    stockTake,
    authData: getAuthData(state),
    locations: getLocations(state),
    distributor,
    stockTakeLines: getStockTakeLinesByDocNo(state, decodeId),
    loggedInUser: getLoggedInUser(state),
    distributorId,
    location: getLocations(state).filter(location => stockTake && location.LocCode === stockTake.LocCode && location.WarehouseOwner === 'Yes')[0]
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchDistributors,
  fetchInventory,
  fetchStockTakesHeaders,
  addStockTakeHeader,
  updateStockTakeHeader,
  deleteStockTakeHeader,
  fetchStockTakeLines,
  fetchWarehouseLocations,
})(Container)));