import { Col, Row, Select, Typography } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { ADD_CUSTOMER_ROUTE_TARGET } from "../../../../../const/Permissions";
import { fetchRoutes } from '../../../../../redux/actions/routes';
import { fetchAllCustomerVisitTargetsByFilter, fetchAllMonths, fetchAllRouteTargetsByFilter, fetchAllYears, updateFilter } from '../../../../../redux/actions/targetScheduler';
import { fetchTerritories } from "../../../../../redux/actions/territories";
import { fetchSalesUserHierarchy } from '../../../../../redux/actions/users';
import { getAllCustomerVisitTargets, getAllMonths, getAllYears, getAuthData, getFilter, getFilteredRouteTargets, getRoutesByUser, getSalesUserHierarchy, getTerritories } from '../../../../../redux/reducers';
import { StyledButton } from '../../../common/Buttons';
import { getURLParams, withThousandSeperator } from "../../../common/helpers";
import UserHierachy from "../../../common/UserHierachy";

const { Text } = Typography;
const { Option } = Select;

class Header extends Component {
    componentDidMount = async () => {
        const { updateFilter, toastManager, fetchSalesUserHierarchy, authData, fetchAllRouteTargetsByFilter, pagination, setPagination, filter, setFilter, fetchTerritories, fetchAllMonths, fetchAllYears, fetchRoutes, salesUserHierarchy, fetchAllCustomerVisitTargetsByFilter, setTableData, setLoading } = this.props;
        await fetchSalesUserHierarchy(authData);
        await fetchAllMonths();
        await fetchAllYears();

        const urlParams = await getURLParams(this.props);
        if (urlParams) {
            const routes = await fetchRoutes({ UserTagFilterOne: urlParams.UserTag }, 0);
            const territories = await fetchTerritories({ UserTagFilterOne: urlParams.UserTag });

            await updateFilter({
                UserTag: urlParams.UserTag,
                RouteCode: urlParams.RouteCode ?? routes.records[0]?.RouteCode,
                TerrCode: urlParams.TerrCode ?? territories.records[0]?.TerrCode,
                Month: urlParams.Month,
                Year: urlParams.Year
            }, this.props);

            const results = await fetchAllCustomerVisitTargetsByFilter({
                UserTag: urlParams.UserTag,
                RouteCode: urlParams.RouteCode ?? routes.records[0]?.RouteCode,
                Year: urlParams.Year,
                Month: urlParams.Month
            }, 0);
            await fetchAllRouteTargetsByFilter({
                UserTag: urlParams.UserTag,
                TerrCode: urlParams.TerrCode ?? territories.records[0]?.TerrCode,
                RouteCode: urlParams.RouteCode ?? routes.records[0]?.RouteCode,
                Year: urlParams.Year,
                Month: urlParams.Month
            });
            setTableData(results);
            setPagination({ ...pagination, total: results.recordTotal });
            setLoading(false);
        } else {
            toastManager.add("User details not found.", { autoDismiss: true, appearance: 'error' });
        }
    }

    handleChange = async (name, value) => {
        await this.props.updateFilter({
            [name]: value
        }, this.props);
    };

    handleSearch = async () => {
        const { fetchAllRouteTargetsByFilter, fetchAllCustomerVisitTargetsByFilter, setTableData, setLoading, filter, pagination, setPagination } = this.props;
        setLoading(true);
        const results = await fetchAllCustomerVisitTargetsByFilter(filter, 0);
        await fetchAllRouteTargetsByFilter(filter);
        setTableData(results);
        setPagination({ ...pagination, total: results.recordTotal, current: 1 });
        setLoading(false);
    }

    render() {
        const { years, months, routes, territories, filter } = this.props;
        // const hierarchy = this.props.salesUserHierarchy[Object.keys(this.props.salesUserHierarchy)[0]];
        const PlannedTarget = this.props.tableData.records?.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue['PlannedTarget'] == '' ? 0 : currentValue['PlannedTarget'])), 0);
        const LastMonthTgt = this.props.routeTargets.records.length > 0 ? this.props.routeTargets.records[0]['PlannedTgt'] : 0.00;
        const BalanceTarget = withThousandSeperator(Number(LastMonthTgt) - Number(PlannedTarget));

        return (
            <>
                <UserHierachy onUpdate={(value) => { }} />
                <Row style={{ padding: "5px 12px 0px 12px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={8} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10 }}>Year:</Text>
                        <Select
                            value={filter.Year}
                            style={{ width: "100%", ...styles.text }}
                            onChange={(value) => this.handleChange('Year', value)}
                        >
                            {years?.records?.map((year, index) =>
                                (<Option key={`${index}-${year.Code}`} value={year.Code} style={{ ...styles.text }}>{year.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={8} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10 }}>Month:</Text>
                        <Select
                            style={{ width: "100%", ...styles.text }}
                            value={filter.Month}
                            onChange={(value) => this.handleChange('Month', value)}
                        >
                            {months?.records?.map((month, index) =>
                                (<Option key={`${index}-${month.Code}`} value={month.Code} style={{ ...styles.text }}>{month.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    {/* <Col xs={24} sm={8} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text }}>{hierarchy?.positionType}: </Text>
                        <Text style={{ ...styles.text }}>{hierarchy?.user.name}</Text>
                    </Col> */}
                    <Col xs={24} sm={8} lg={12} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10, width: 130 }}>Route Name:</Text>
                        <Select
                            style={{ width: "100%", ...styles.text }}
                            value={filter.RouteCode}
                            onChange={(value) => this.handleChange('RouteCode', value)}
                        >
                            {routes.map((route, index) =>
                                (<Option key={`${index}-${route.RouteCode}`} value={route.RouteCode} style={{ ...styles.text }}>{route.RouteName}</Option>)
                            )}
                        </Select>
                    </Col>

                    <Col xs={24} sm={8} lg={3}></Col>
                </Row>
                <Row style={{ padding: "0px 12px 0px 12px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10 }}>Terrritory: </Text>
                        <Select

                            style={{ width: "100%", ...styles.text }}
                            value={filter.TerrCode ?? (territories?.length > 0 && territories[0]?.TerrCode)}
                            onChange={(value) => this.handleChange('TerrCode', value)}
                        >
                            {territories?.map((territory, index) =>
                                (<Option key={`${index}-${territory.TerrCode}`} value={territory.TerrCode} style={{ ...styles.text }}>{territory.TerrName}</Option>)
                            )}
                        </Select>
                    </Col>

                    <Col xs={24} sm={8} lg={4} style={styles.container}>
                        <Text style={{ ...styles.text }}>Route Target: </Text>
                        <Text style={{ ...styles.text }}>{withThousandSeperator(LastMonthTgt)}</Text>
                    </Col>
                    <Col xs={24} sm={8} lg={4} style={styles.container}>
                        <Text style={{ ...styles.text }}>Balance Target: </Text>
                        <Text style={{ ...styles.text, color: "red" }}>{BalanceTarget}</Text>
                    </Col>
                    <Col xs={24} sm={24} lg={6} style={{ display: "flex", justifyContent: "flex-start", gap: 5 }}>
                        <StyledButton color="#1890ff" label="Search" width={100} onClick={() => this.handleSearch()} />
                        {this.props.permissions.includes(ADD_CUSTOMER_ROUTE_TARGET.UPDATE) && (<StyledButton color="#1890ff" label="Save All" width={100} onClick={this.props.handleAllUpdate} />)}
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    years: getAllYears(state),
    months: getAllMonths(state),
    routes: getRoutesByUser(state),
    territories: getTerritories(state),
    customerVisitTargets: getAllCustomerVisitTargets(state),
    routeTargets: getFilteredRouteTargets(state),
    filter: getFilter(state)
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchRoutes,
    fetchTerritories,
    fetchAllCustomerVisitTargetsByFilter,
    fetchAllRouteTargetsByFilter,
    updateFilter
}

export default withRouter(withToastManager(connect(mapStateToProps, mapDispatchToProps)(Header)));

const styles = {
    text: {
        color: '#000000'
    },
    container: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    }
}