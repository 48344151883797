import * as fromApi from '../../api/invoices';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_BY_CUSTOMER = 'FETCH_INVOICES_BY_CUSTOMER';
export const FETCH_INVOICE = 'FETCH_INVOICE';

export const fetchInvoicesByCustomer = filter => dispatch => {
  return fromApi.getAll(filter).then(response => {
    const { invoices, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_INVOICES_BY_CUSTOMER, customerId: filter.customerId, invoices });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchInvoices = filter => dispatch => {
  return fromApi.getAll(filter).then(response => {
    const { invoices, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_INVOICES, invoices });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchInvoice = invoiceNo => dispatch => {
  return fromApi.get(invoiceNo).then(response => {
    const { invoice, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_INVOICE, invoice });
    return response;
  }).catch(error => {
    return { error }
  })
};