import moment from 'moment';
import QueryString from 'query-string';
import { isValidDateTimeString, parseMoment, toDateTimeString } from './Formats';

export const getSearchKey = () => JSON.stringify(QueryString.parse(window.location.search));

export const redirectToUrlState = (baseUrl, props, filter, page = 1) => {
  const searchParams = encodeUrlSearchParams(filter, page);
  //console.log('redirectToUrl', window.location.search, '?' + searchParams);
  if (window.location.search !== '?' + searchParams) {
    props.history.replace(baseUrl + '?' + searchParams);
    //console.log('redirectToUrl', true);
    return true;
  } else {
    //console.log('redirectToUrl', false);
    return false;
  }
}

export const shouldFilterUpdate = (currentProps, nextProps) => {
  const { topPosition, flatPositions } = currentProps;
  const { topPosition: nextTopPosition, flatPositions: nextFlatPositions } = nextProps;

  if (!topPosition) return false;
  if (!flatPositions || Object.values(flatPositions).length === 0) return false;

  const topPositionChanged = JSON.stringify(topPosition) === JSON.stringify(nextTopPosition);
  const flatPositionsChanged = JSON.stringify(flatPositions) === JSON.stringify(nextFlatPositions);

  return topPositionChanged || flatPositionsChanged;
}

export const decodeUrlSearchParams = () => {
  const urlSearchParams = QueryString.parse(window.location.search);
  normalizeUrlParams(urlSearchParams);

  const { page = 1, ...rest } = urlSearchParams;

  const filter = {
    ...rest
  };

  if (filter.UserHierarchy) {
    filter.UserHierarchy = urlSearchParams.UserHierarchy.map(value => ({ value }));
  }

  return [filter, parseInt(page)];
}

export const handleUrlSearchParams = (baseUrl, currentProps, nextProps, currentSearchKey, onSearchChanged, initFilter = {}) => {
  if (!shouldFilterUpdate(currentProps, nextProps)) return;
  const [filter, page] = decodeUrlSearchParams();

  if (!filter || Object.values(filter).length === 0) {
    const { history, topPosition } = nextProps;
    redirectToUrlState(baseUrl, currentProps, { UserHierarchy: [{ value: topPosition.positionCode }], ...initFilter })
    return;
  }

  const searchKey = getSearchKey();
  if (searchKey !== currentSearchKey) {
    onSearchChanged(filter, page, searchKey, nextProps);
  }
}

export const encodeUrlSearchParams = (filter, page) => {
  let searchParams = '';

  const { UserHierarchy, UserTag, ...rest } = filter;

  if (filter.UserHierarchy) {
    filter.UserHierarchy.forEach(position => searchParams += 'UserHierarchy=' + position.value + '&');
  }

  Object.keys(rest).forEach(key => {
    let value = rest[key];
    if (value) {
      if (moment.isMoment(value)) {
        value = toDateTimeString(value);
      }
      searchParams += key + '=' + encodeURIComponent(value) + '&';
    }
  });

  searchParams += 'page=' + page;

  return searchParams;
}

const normalizeUrlParams = params => {
  const { UserHierarchy } = params;
  if (UserHierarchy && !Array.isArray(UserHierarchy)) params.UserHierarchy = [UserHierarchy];

  Object.keys(params).forEach(key => {
    const value = decodeURIComponent(params[key]);
    if (isValidDateTimeString(value)) {
      params[key] = parseMoment(value);
    }
  });
}