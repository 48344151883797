let demoPurchasingOrders = []
const names = ["Andrew Stephen", "Simon Cowel", "Mitchell Richedson", "Daren Iyan", "Brayan Adems", "Nicoles Stephen", "Theron M Goodman", "Darren Lara", "Elizabeth Mendella"];
const returnCategories = ["FACTORY_DEFECTS", "SEASONAL_SALES", "CUSTOMER_DISCONTINUATION", "TRASPORTATION_AND_HANDLING"];
export const generateDemoOrders = () => {
  demoPurchasingOrders = []
  demoPurchasingOrders.push(defaultOrder)
  var defaultDiscountPercentage = getRandomNumber(0, 69);
  var grossAmount = getRandomNumber(700, 2500);
  var discountAmount = (grossAmount * defaultDiscountPercentage) / 100
  var totalAmount = grossAmount - discountAmount
  for (let i = 0; i < 25; i++) {
    demoPurchasingOrders.push({
      orderNo: "RN" + i,
      orderType: "Type " + i,
      sellToCustomerCode: "Merchent " + i,
      sellToCustomerName: "Merchent Name " + i + 1,
      billToCustomerCode: "CUS" + randoNumber(),
      billToCustomerName: names[getRandomNumber(0, names.length)],
      locationCode: "Location Code " + randoNumber(),
      orderDate: randomDate(new Date(2012, 0, 1), new Date()),
      salesLines: getSalesLines("RN" + i, defaultDiscountPercentage),
      salespersonCode: "SALE" + randoNumber(),
      totalWithoutDiscount: grossAmount,
      totalDiscountAmount: discountAmount,
      totalAmount: totalAmount,
      amountIncludingVat: randoNumber(),
      discountPercentage: defaultDiscountPercentage,
      status: i === 2 ? "Open" : randomStatus(),
      sessionId: randoNumber(),
      returnCategory: returnCategories[getRandomNumber(0, returnCategories.length)],
    })
  }
  return demoPurchasingOrders;
}
const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
const randoNumber = () => {
  return Math.floor((Math.random() * 50000) + 1);
}
const randomStatus = () => {
  const statuses = ["Open", "Close", "Confirmed", "Customer Confirmed"];
  return statuses[Math.floor((Math.random() * 4) + 0)];
}
const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from);
const getSalesLines = (orderNo, discountPercentage) => {
  let salesLines = [];
  const itemLength = orderNo === "RN2" ? 1 : 5;
  for (let i = 0; i < itemLength; i++) {
    var unitPrice = getRandomNumber(700, 2500);
    var discountAmount = (unitPrice * discountPercentage) / 100
    var totalAmount = unitPrice - discountAmount
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      orderNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: discountPercentage,
      discountAmount: discountAmount,
      amount: totalAmount,
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }
  return salesLines;
}
export const getDemoOrders = () => {
  return demoPurchasingOrders;
}
export const getDemoOrder = id => {
  const demoOrder = demoPurchasingOrders.find(order => order.orderNo === id);
  return demoOrder;
}
export const getSalesLineItem = (orderNo) => {
  let salesLines = [];
  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      orderNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: getRandomNumber(0, 65),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }
  return salesLines[Math.floor((Math.random() * 3) + 0)];
}
export const generateSalesLines = (orderNo, percentage, lineCount) => {
  let salesLines = [];
  for (let i = 0; i < lineCount; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      orderNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: percentage,
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }
  return salesLines;
}
const defaultOrder = {
  orderNo: "new",
  orderType: "Type 3",
  sellToCustomerCode: "Merchent 1",
  sellToCustomerName: "Merchent 2",
  billToCustomerCode: "CUS 5",
  billToCustomerName: "Mitchell Richedson",
  locationCode: "Location Code 454",
  orderDate: new Date(2012, 0, 1),
  salesLines: getSalesLines("new", 10).slice(0, 1),
  salespersonCode: "SALE 12",
  discountPercentage: 10,
  totalAmount: 54231,
  totalWithoutDiscount: 1500,
  totalDiscountAmount: 150,
  totalAmount: 1350,
  amountIncludingVat: 562349,
  status: "Open",
  returnCategory: "",
  sessionId: 123465446
}