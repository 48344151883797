import { Col, Row, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { ROUTE_TARGET } from '../../../../const/Permissions';
import { RouteTargetStatus } from '../../../../const/status';
import { updateRouteTarget } from '../../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAuthData, getFilteredRouteTargets, getSalesUserHierarchy } from "../../../../redux/reducers";
import { StyledButton } from '../../common/Buttons';
import { trimDecimals, withOutThousandSeperator, withThousandSeperator } from '../../common/helpers';

const { Text } = Typography;

export class DataTable extends Component {
    state = {
        pagination: {
            pageSize: 20
        }
    };
    columns = [
        {
            title: 'Territory',
            width: 90,
            dataIndex: 'TerrName',
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
        },
        {
            title: 'Route Code',
            width: 70,
            dataIndex: 'RouteCode',
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
            // fixed: 'left',
        },
        {
            title: 'Route Name',
            dataIndex: 'RouteName',
            width: 350,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : record}</Text>
            // fixed: 'left',
        },
        {
            title: 'Total Outlets',
            dataIndex: 'TotOutlets',
            width: 55,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
            // fixed: 'left',
        },
        {
            title: 'Productive Outlets',
            dataIndex: 'PordOutlets',
            width: 70,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
            // fixed: 'left',
        },
        // {
        //     title: 'Monthly Target',
        //     dataIndex: 'MonthlyTarget',
        //     width: 100,
        //     // fixed: 'left',
        // },
        // {
        //     title: 'Monthly Achievement',
        //     dataIndex: 'MonthlyAchievement',
        //     key: '5',
        //     width: 100,
        //     // fixed: 'left',
        // },
        // {
        //     title: 'Monthly Returns',
        //     dataIndex: 'MonthlyReturns',
        //     key: '6',
        //     width: 100,
        //     // fixed: 'left',
        // },
        {
            title: 'Route Target',
            dataIndex: 'PlannedTgt',
            key: '7',
            width: 80,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
            // fixed: 'left',
        },
        {
            title: 'Route Achievement',
            dataIndex: 'RouteAchievement',
            width: 85,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
            // fixed: 'center',
        },
        {
            title: 'Route Returns',
            dataIndex: 'RouteReturns',
            // fixed: 'right',
            width: 80,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            title: 'Net Sales',
            // dataIndex: 'RouteContri',
            // fixed: 'right',
            width: 80,
            render: (record, row) => {
                const netSales = Number(withOutThousandSeperator(row.RouteAchievement ?? 0)) - Number(withOutThousandSeperator(row.RouteReturns ?? 0));
                return <Text style={{ fontSize: 12 }}>{trimDecimals((netSales))}</Text>
            }
        },
        {
            title: 'TGT Vs ACH %',
            dataIndex: 'TgtVsAchPre',
            // fixed: 'right',
            width: 70,
            render: (record, row) => {
                const netSales = Number(withOutThousandSeperator(row.RouteAchievement ?? 0)) - Number(withOutThousandSeperator(row.RouteReturns ?? 0));
                let tgtVsAch = 0;
                if(withOutThousandSeperator(row.PlannedTgt)) {
                    tgtVsAch = (netSales / Number(withOutThousandSeperator(row.PlannedTgt))) * 100;
                }
                return <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(tgtVsAch)}</Text>
            }
        },
        {
            title: 'Route Contribution %',
            dataIndex: 'RouteContri',
            // fixed: 'right',
            width: 80,
            render: (record) => <Text style={{ fontSize: 12 }}>{record == "" ? "-" : trimDecimals(record)}</Text>
        },
        {
            // title: 'Status',
            key: 'approve',
            // fixed: 'right',
            width: 100,
            render: (record) => {
                return (
                    <>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            {this.props.permissions.includes(ROUTE_TARGET.APPROVE) && ((record.Approved === RouteTargetStatus.OPEN) || (record.Approved === RouteTargetStatus.REJECTED)) && (<StyledButton color="#0f8a86" icon={<FaCheck />} width={40} onClick={() => this.onApprove(record)} />)}
                            {this.props.permissions.includes(ROUTE_TARGET.REJECT) && ((record.Approved === RouteTargetStatus.OPEN) || (record.Approved === RouteTargetStatus.APPROVED)) && (<StyledButton color="#a60d33" icon={<FaTimes />} width={40} onClick={() => this.onReject(record)} />)}
                        </div>
                    </>
                );
            },
        }
    ];
    renderFooter = () => {
        const { responseMetadata } = this.props.routeTargets;
        return (
            <div style={{ background: "#e1e1e1", padding: "5px" }}>
                <Row>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Records: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpRouteTotal !== "" ? trimDecimals(responseMetadata?.GrpRouteTotal) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Outlets: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpTotalOutlets !== "" ? trimDecimals(responseMetadata?.GrpTotalOutlets) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Productive Outlets: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpProdOutlets !== "" ? trimDecimals(responseMetadata?.GrpProdOutlets) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Monthly Target: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpMonthlyTarget !== "" ? trimDecimals(responseMetadata?.GrpMonthlyTarget) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Monthly Achievement: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpMonthAchievement !== "" ? trimDecimals(responseMetadata?.GrpMonthAchievement) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Monthly Returns: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpMonthReturns !== "" ? trimDecimals(responseMetadata?.GrpMonthReturns) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Route Target: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpPlannedTgt !== "" ? trimDecimals(responseMetadata?.GrpPlannedTgt) : 0}</span></span>
                    </Col>
                    {/* <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Route Achievement: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpMonthAchievement !== "" ? responseMetadata?.GrpMonthAchievement : 0.00}</span></span>
                    </Col> */}
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Target vs Achievement: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpTgtVsAchPre !== "" ? trimDecimals(responseMetadata?.GrpTgtVsAchPre) : 0}</span></span>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={{ padding: "5px 0" }}>
                        <span style={{ color: "black", fontSize: 12 }}>Total Route Returns: <span style={{ fontWeight: "bold", fontSize: 12 }}>{responseMetadata?.GrpMonthReturns !== "" ? trimDecimals(responseMetadata?.GrpMonthReturns) : 0}</span></span>
                    </Col>
                </Row>
            </div>
        );
    }

    onSelectChange = (selectedRowKeys) => {
        const { setSelectedRowKeys } = this.props;
        setSelectedRowKeys(selectedRowKeys);
    }

    onApprove = async (record) => {
        const { toastManager, setLoading, updateRouteTarget } = this.props;
        setLoading(true);
        if (record.Approved === "Approved") {
            toastManager.add("Route targets already approved.", { autoDismiss: true, appearance: 'info' });
        } else {
            const updatedData = await this.props.updateRouteTarget({ ...record, Approved: "Approved" });
            if (!updatedData.error) {
                toastManager.add('Planned target was successfully approved.', { autoDismiss: true, appearance: 'success' });
            }
        }
        setLoading(false);
    }

    onReject = async (record) => {
        const { toastManager, setLoading, updateRouteTarget } = this.props;
        setLoading(true);
        if (record.Approved === "Rejected") {
            toastManager.add("Route targets already rejected.", { autoDismiss: true, appearance: 'info' });
        } else {
            const updatedData = await this.props.updateRouteTarget({ ...record, Approved: "Rejected" });
            if (!updatedData.error) {
                toastManager.add('Planned target was successfully rejected.', { autoDismiss: true, appearance: 'success' });
            }
        }
        setLoading(false);
    }

    render() {
        const { loading, selectedRowKeys, routeTargets } = this.props;
        return (
            <div style={{ padding: 10, marginTop: 5 }}>
                <Table
                    size='small'
                    columns={this.columns}
                    rowKey={record => record.RouteCode}
                    dataSource={routeTargets.records}
                    pagination={this.state.pagination}
                    loading={loading}
                    footer={() => this.renderFooter()}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: this.onSelectChange
                    }}
                    scroll={{ x: 1500 }}
                // expandedRowRender={record => <p style={{ margin: 0 }}>{record.territory}</p>}
                // onChange={this.handleTableChange}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    routeTargets: getFilteredRouteTargets(state),
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    updateRouteTarget
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(DataTable));