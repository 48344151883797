import React, { Component } from "react";
import { Col, Row } from "antd";

class PaymentCardHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Unverified':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {

    return (
      <Row>
        <Row style={{ ...styles.alignRow, fontWeight: 'bold', height: 50, marginRight: 15, backgroundColor: '#eceaea', padding: 10 }}>
          <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'left' }}>Payment No</Col>
          <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Status</Col>
          <Col xs={6} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Date</Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'center' }}>Supplier Code</Col>
          <Col xs={0} sm={0} md={6} lg={6} style={{ textAlign: 'center' }}>Supplier Name</Col>
          <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>Payment Type</Col>
          <Col xs={9} sm={5} md={3} lg={3} style={{ textAlign: 'right' }}>Amount</Col>
        </Row>
        {/* <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={6} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={styles.paymentType}>{paymentType}</span>
          </Col>
          <Col xs={9} sm={0} md={0} lg={0}  style={{ textAlign: 'Right' }}>
            <FaUser style={styles.icon} /><span>{sellToCustomerCode}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<FaUser style={styles.icon} /><span>{sellToCustomerName}</span>
					</Col>
				</Row> */}
      </Row>
    )
  }
}

export default PaymentCardHeader;

const styles = {
  paymentNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  collectionDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  paymentType: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}