import React, { Component } from 'react';
import { Card, Tag, Divider, Col, Icon, Row } from 'antd';

class Details extends Component {

  render() {
    const { location = {} } = this.props;
    return (
      <div>
        <Card className={'locationInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={8} md={8} sm={24} xs={24}>
              {location.locationNo && <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>ID</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{location.locationNo}</td>
              </tr>}
              {location.name && <tr>
                <td style={{ width: 25 }}><Icon type="idcard" /></td>
                <td>Name</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{location.name}</td>
              </tr>}
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
            {/* <Col lg={8} md={8} sm={24} xs={24}>
              {location.contactNumber && <tr>
                <td style={{ width: 25 }}><Icon type="phone" /></td>
                <td>Contact No</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{location.contactNumber}</td>
              </tr>}
              {location.email && <tr>
                <td style={{ width: 25 }}><Icon type="mail" /></td>
                <td>Email</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{location.email}</td>
              </tr>}
              {location.address && <tr>
                <td style={{ width: 25 }}><Icon type="pushpin" /></td>
                <td>Address</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{location.address}</td>
              </tr>}
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col> */}
            <Col lg={8} md={8} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25 }}><Icon type="info-circle" /></td>
                <td><p>Status</p></td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td><Tag color={getStatusColor(location.status)}>{location.status}</Tag></td>
              </tr>
              {/* <tr>
                <td style={{ width: 25 }}><Icon type="minus-square" /></td>
                <td>Outstanding</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>{format(location.outstanding, currencyFormat)}</td>
              </tr> */}
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const getStatusColor = status => {
  switch (status) {
    case 'UNBLOCK':
      return 'green';
    case 'BLOCK':
      return 'red';
    default:
      return 'orange';
  }
}

export default Details;