import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchAllItems } from '../../../redux/actions/AllItems';
import { fetchLotWiseItems } from '../../../redux/actions/lotwiseItems';
import { fetchPurchaseLines, updatePurchaseLine, deletePurchaseLine } from '../../../redux/actions/purchaseLines';
import { getAuthData, getSingleItemFromAllItems, getPurchaseLine, DOC_TYPE_ID_BY_DEFINITION, getLotWiseItem } from "../../../redux/reducers";
import Details from "./Details";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';

class Container extends Component {
  state = {
    purchaseLine: undefined
  };

  componentDidMount() {
    this.loadSalesLines();
    this.loadInventoryItem(this.props);
  }

  loadSalesLines = () => {
    const { docType, orderId } = this.props.match.params;
    this.props.fetchPurchaseLines({ DocNo: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
  }

  componentWillReceiveProps = nextProps => {
    this.loadInventoryItem(nextProps);
  }

  inventoryItemRequestSent = false;
  loadInventoryItem = props => {
    const { purchaseLine, fetchAllItems, fetchLotWiseItems } = props;
    const { docType } = this.props.match.params;
    if (this.inventoryItemRequestSent || !purchaseLine) return;

    this.setState({ purchaseLine });
    this.inventoryItemRequestSent = true;
    const { CreatedUserID, No, LocCode, LotNo } = purchaseLine;
    if (docType === 'ReturnOrder') {
      fetchLotWiseItems({ UserCode: CreatedUserID, LotNo: LotNo, ItemNo: No, AllItems: 'yes' })
    } else if (docType === 'Order') {
      fetchAllItems({ UserCode: CreatedUserID, ItemNo: No, PurchItems: 'yes' });
    }
    //fetchAllItems({ UserCode: CreatedUserID, ItemNo: No, PurchItems: 'yes' });
  }

  handleUpdateOrder = () => this.handleRequest(this.props.updatePurchaseLine, 'Line updated successfully.');

  handleRemoveSalesLine = () => this.handleRequest(this.props.deletePurchaseLine, 'Line removed successfully.');

  handleRequest = (requestCall, successMessage) => {
    const { authData, deletePurchaseLine, history, toastManager } = this.props;
    const { docType } = this.props.match.params;
    this.setState({ loading: true });
    let salesLineObject = undefined

    if (docType === 'ReturnOrder') {
      salesLineObject = { ...this.state.purchaseLine, UserCode: authData.userId, ReturnInvDocNo: this.state.purchaseLine.ReturnInvDocNo, ReturnInvLineNo: this.state.purchaseLine.ReturnInvLineNo }
    } else {
      salesLineObject = { ...this.state.purchaseLine, UserCode: authData.userId }
    }

    requestCall(salesLineObject).then(({ error }) => {
      if (error) toastManager.add(error, { autoDismiss: true, appearance: 'error' });
      else {
        toastManager.add(successMessage, { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleResetSalesLine = () => this.setState({ purchaseLine: { ...this.props.purchaseLine } });

  handleUpdateNumberField = (key, value) => {
    if (value !== "" && !this.isValidQty(value)) return;

    if (key === 'DisPercentage') {
      if (value < 0) value *= -1;
      if (value > 100) value = 100;
    }

    this.setState({ purchaseLine: { ...this.state.purchaseLine, [key]: '' + value } })
  }

  isValidQty = value => {
    return parseFloat(value) !== 'NaN';
  }

  render() {
    const { match, resource, order, inventoryItem, purchaseLine: salesLineDatabase } = this.props;
    const { purchaseLine, loading } = this.state;
    const { docType } = this.props.match.params;
    //console.log("loading", loading)
    return (
      <Spin spinning={loading || !purchaseLine || !inventoryItem} tip={"Loading item"}>
        {(inventoryItem && purchaseLine) ?
          <Details
            inventoryItem={inventoryItem}
            onUpdateNumberField={this.handleUpdateNumberField}
            salesLineItem={purchaseLine}
            salesLineItemDatabase={salesLineDatabase}
            onUpdateOrder={this.handleUpdateOrder}
            onRemoveSalesLine={this.handleRemoveSalesLine}
            onResetSalesLine={this.handleResetSalesLine}
            resource={resource}
            order={order}
            newOrderItem={match && match.params.lineId === 'new'}
            docType={docType}
          /> :
          <div style={{ width: '100%', height: 200 }} />
        }
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { docType, orderId, lineId } = ownProps.match.params;

  const purchaseLine = getPurchaseLine(state, docType, decodeURIComponent(orderId), lineId);
  //console.log("purchaseLine", purchaseLine)
  let inventoryItem;
  if (purchaseLine) {
    const { No, LotNo } = purchaseLine;
    let itemId = "";
    if (docType === 'ReturnOrder') {
      itemId = No + '-' + LotNo;
      inventoryItem = itemId && getLotWiseItem(state, itemId);
    } else if (docType === 'Order') {
      itemId = No;
      inventoryItem = itemId && getSingleItemFromAllItems(state, itemId);
    }
    // itemId = No;
    // inventoryItem = itemId && getSingleItemFromAllItems(state, itemId);
  }

  return { authData: getAuthData(state), inventoryItem, purchaseLine };
};


export default withRouter(
  withToastManager(
    connect(
      mapStateToProps,
      {
        fetchAllItems,
        fetchPurchaseLines,
        updatePurchaseLine,
        deletePurchaseLine,
        fetchLotWiseItems
      }
    )(Container)
  )
);