import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from "antd";
import { ProMateGreen } from "../../const/Theme";
import ItemTable from "./ItemTable";
import { defaultCombinedFilter } from '../../redux/reducers/inventory';
import { updateCombinedFilter } from '../../redux/actions/inventory';
import PromatePageHeader from '../common/PromatePageHeader';

const defaultState = {
  brand: undefined,
  category1: undefined,
  category2: undefined,
  productType: undefined,
  textFilter: undefined,
};

class Inventory extends Component {
  state = { ...defaultState };

  componentWillUnmount = () => {
    this.props.updateCombinedFilter(defaultCombinedFilter);
  }

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Items'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <ItemTable
              multiSelect={false}
            />
          </Col>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default connect(null, { updateCombinedFilter })(Inventory);