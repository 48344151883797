import React, { Component } from 'react';
import { connect } from "react-redux";
import { updateTransferHeader } from "../../../redux/actions/transferHeaders";
import { addAttachment } from '../../../redux/actions/attachments';
import { getTransferHeader, getAuthData } from '../../../redux/reducers';
import { Spin, Modal } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';
import { currentLocation } from '../../../utils/Location';

const { confirm } = Modal;

const defaultOrder = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  orderDate: moment(),
  orderNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "PENDING",
  signedBy: undefined
}

class Container extends Component {
  state = {
    loading: false,
    orderConfirmationType: undefined,
    order: { ...defaultOrder }
  }


  confirmTransfer = (signatureImageUrl, isEmptyUrl) => {
    const { updateTransferHeader, toastManager, history, match, transfer, authData, addAttachment } = this.props;
    const { orderConfirmationType } = this.state;
    const { transferId } = match.params

    // if (!orderConfirmationType) {
    //   toastManager.add("You can't confirm the transfer without confirmation type.", { autoDismiss: true, appearance: 'error' });
    //   return;
    // }

    const updateOrderData = { ...transfer, OrderStatus: '1', UserCode: authData.userId };

    this.setState({ loading: true });

    const update = () => {
      updateTransferHeader(updateOrderData).then(response => {
        this.setState({ loading: false })
        if (!response.error) {
          toastManager.add('Transfer confirmed.', { autoDismiss: true, appearance: 'success' });
          this.props.history.replace('/stockTransfers');
        } else {
          toastManager.add('Could not confirm the transfer. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    }

    if (isEmptyUrl) {
      this.setState({ loading: false })
      toastManager.add("You can't confirm the transfer without providing the signature.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const imageFile = this.getImageFile(signatureImageUrl);

    upload(imageFile, "transfers/" + transferId, 'signature-' + new Date().getTime()).then(response => {
      if (response.error) {
        toastManager.add("Could not upload the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const link = {
        AttachmentName: 'TransferAttachment',
        DocNo: transfer.DocNo,
        AttachmentURL: response.path,
        UserCode: authData.userId
      };

      addAttachment('TransferAttachment', link).then(response => {
        if (response.error) {
          toastManager.add("Could not link the signature.", { autoDismiss: true, appearance: 'error' });
          return;
        }

        update();
      });
    });

    // if (orderConfirmationType === "OTPO") {
    //   update();
    // } else {
    //   if (isEmptyUrl) {
    //     this.setState({ loading: false })
    //     toastManager.add("You can't confirm the transfer without providing the signature.", { autoDismiss: true, appearance: 'error' });
    //     return;
    //   }

    //   const imageFile = this.getImageFile(signatureImageUrl);

    //   upload(imageFile, "transfers/" + transferId, 'signature-'+new Date().getTime()).then(response => {
    //     if (response.error) {
    //       toastManager.add("Could not upload the signature.", { autoDismiss: true, appearance: 'error' });
    //       return;
    //     }

    //     const link = {
    //       AttachmentName: 'TransferAttachment',
    //       DocNo: transfer.DocNo,
    //       AttachmentURL: response.path,
    //       UserCode: authData.userId
    //     };

    //     addAttachment('TransferAttachment', link).then(response => {
    //       if (response.error) {
    //         toastManager.add("Could not link the signature.", { autoDismiss: true, appearance: 'error' });
    //         return;
    //       }

    //       update();
    //     });
    //   });
    // }
  }

  showConfirmOrderConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    confirm({
      title: 'Are you sure ?',
      content: 'After confirm you cannot update this transfer.',
      onOk() {
        context.confirmTransfer(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  onChangeOrderConfirmationType = type => {
    this.setState({ orderConfirmationType: type });
  }

  render() {
    const { loading,/* order,*/ orderConfirmationType } = this.state;

    return (
      <Spin spinning={loading} tip={"loading..."}>
        <Pad orderConfirmationType={orderConfirmationType} onConfirmationTypeChange={this.onChangeOrderConfirmationType} onConfirmOrder={(signatureImageUrl, isEmptyUrl) => this.showConfirmOrderConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const decodeId = decodeURIComponent(ownProps.match.params.transferId);
  return {
    transfer: getTransferHeader(state, decodeId),
    authData: getAuthData(state),
    transferId: decodeId
  };
};


export default withRouter(withToastManager(connect(mapStateToProps, { updateTransferHeader, addAttachment: addAttachment })(Container)));