import React, { Component } from 'react';
import { PageHeader } from "antd";
import Container from './Container';
import styles from '../styles'
import PromatePageHeader from '../../common/PromatePageHeader';

class CollectionScreen extends Component {

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Setoff Credit Notes'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>

          <Container />
        </PromatePageHeader>
      </div>
    )
  }
}

export default CollectionScreen;