import { combineReducers } from 'redux';
import { FETCH_VEND_DETAILS } from '../actions/vendorDetails';
import { getOfflineData } from '../../localStorage';


export const  byVendorNo = (state = [], action) => {
	switch (action.type) {
	  case FETCH_VEND_DETAILS:
		const newState = {};
		action.vendors.forEach(vendor => newState[vendor.No] = vendor);
		return newState;
	  default:
		return state;
	}
  };

export const vendorNos = (state = [], action) => {
	switch (action.type) {
	  case FETCH_VEND_DETAILS:
		const vendorNumbers = action.vendors.map(vendor => vendor.No);
		return vendorNumbers;
	  default:
		return state;
	}
  };



const vendor = combineReducers({
	byVendorNo,
	vendorNos,
});

export default vendor;

export const getVendorDetails = state => {
	// return state.vendorNos.map(code => state.byLocationCode[code])
	return state.vendorNos.map(code => state.byVendorNo[code]);
}