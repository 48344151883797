import React, { Component } from 'react';
import { Button } from 'antd';
import Webcam from "react-webcam";
import { showNotification } from '../../const/Notifications';

export default class ImageCapture extends Component {

  state = {
    cameraReady: false
  };

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageData = this.webcam.getScreenshot();
    const imageFile = new File([this.createBlob(imageData)], "collectionAttachment.jpg", { type: "image/jpeg", });
    this.props.onCaptureImage(imageData, imageFile);
  };

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  handleCameraError = () => {
    showNotification('error', 'Camera Error', 'Could not access your camera. Please try Select Image option.');
  }

  render() {
    const videoConstraints = {
      width: 235,
      height: 231,
      facingMode: { exact: 'environment' },
    };

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Webcam
            audio={false}
            height={500}
            ref={this.setRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={() => this.setState({ cameraReady: true })}
            onUserMediaError={() => this.handleCameraError()} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <Button.Group>
            <Button disabled={!this.state.cameraReady} style={{ fontSize: 13 }} icon="camera" onClick={this.capture}>Capture</Button>
            <Button style={{ fontSize: 13 }} icon="logout" onClick={this.props.onClickExit}>Exit</Button>
          </Button.Group>
        </div>
      </div>
    );
  }
}
