import React, { Component } from 'react';
import { Col } from "antd";

export default class FormItem extends Component {

    render() {
        const { label, error = false, lg = 24, md = 24, sm = 24, xs = 24, style = { marginBottom: 10 } } = this.props;

        return (
            <Col lg={lg} md={md} sm={sm} xs={xs} style={style}>
                <div style={{ fontWeight: 500 }}>{label}</div>
                {this.props.children}
                {error ? <p style={{ color: 'red', marginBottom: 5 }}>{error}</p> : <p style={{ color: 'red', marginBottom: 5 }}>&nbsp;</p>}
            </Col>
        )
    }
}