import React, { Component } from 'react';
import { Row, Card, Col, Tag, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

class InvoiceHeader extends Component {

  render() {
    const { invoice } = this.props;
    const { invoiceNo, orderDate, status, sellToCustomerName, sellToCustomerCode, billToCustomerCode, billToCustomerName, links } = invoice;

    return (
      <Card style={{ borderBottom: 0 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {invoiceNo && <tr>
              <td>Invoice #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{invoiceNo.replace(/---/g, '/')}</td>
            </tr>}
            {orderDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(orderDate).format(dateFormatView)}</td>
            </tr>}
            {status && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>
                {<Tag color="green">{status}</Tag>}
                {links && links.length > 0 && <Tag color="orange">Signed</Tag>}
              </td>
            </tr>}
            {sellToCustomerCode && <tr>
              <td>Merchant #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerCode}</td>
            </tr>}
            {sellToCustomerName && <tr>
              <td>Merchant Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerName}</td>
            </tr>}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {billToCustomerCode && <tr>
              <td>Customer #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerCode}</td>
            </tr>}
            {billToCustomerName && <tr>
              <td>Customer Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerName}</td>
            </tr>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default InvoiceHeader;