import React, { Component } from 'react';
import { Table, Button } from "antd";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import CallStatusValidateButton from "../../common/CallStatusValidateButton";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class SalesLineTable extends Component {

  renderColumns = () => {

    return [{
      title: 'No',
      width: 30,
      align: 'right',
      render: (unitPrice, object, index) => (index + 1)
    }, {
      title: 'Code',
      dataIndex: 'itemNo',
      key: 'itemNo',
      width: 60,
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    }, {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      fixed: 'right',
      align: 'right',
      width: 60
    }, {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 80,
      fixed: 'right',
      align: 'right',
      render: unitPrice => format(unitPrice, currencyFormat)
    }, {
      title: 'Discount',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      width: 100,
      fixed: 'right',
      align: 'right',
      render: (discountAmount, rowData) => `${format(discountAmount, currencyFormat)} (${rowData.discountPercentage}%)`
    }, {
      title: 'Total',
      dataIndex: 'lineAmount',
      key: 'lineAmount',
      width: 80,
      fixed: 'right',
      align: 'right',
      render: total => format(total, currencyFormat)
    }]
  }

  renderFooter = () => {
    return <div style={{ display: 'flex', justifyContent: 'flex-end' }}><CallStatusValidateButton onClick={this.props.OnClickAddItem} type="primary">Add Item</CallStatusValidateButton></div>
  }


  render() {
    const { height = 500, order, history } = this.props;
    const tableHeight = height - 30;

    return (
      <Table
        rowKey={'lineNo'}
        columns={this.renderColumns()}
        dataSource={order.salesLines}
        size={'small'}
        pagination={false}
        scroll={{ x: 800, y: tableHeight }}
        onRowClick={rowData => (!order.links || order.links.length === 0) && history.push({ pathname: "/order/" + order.orderNo + "/salesLines/" + rowData.lineNo, state: { itemId: rowData.inventoryItemId, order } })}
        footer={(!order.links || order.links.length === 0) && this.renderFooter} />
    )
  }
}

export default withRouter(SalesLineTable);