import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

export const MyMapComponent = compose(
  withProps(props => ({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA1jH8rTDXJGYBzWej7UL9_raVmS2bje8w&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: props.height }} />,
    mapElement: <div style={{ height: `100%` }} />
  })),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={11} defaultCenter={props.location} onClick={props.onMapClick} >
    {props.isMarkerShown && <Marker position={props.location} />
    }
  </GoogleMap>
));