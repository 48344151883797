import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spin } from "antd";
import { fetchSalesHeaders } from "../../redux/actions/salesHeaders";
import { getSalesHeadersByType, getSalesHeadersByTypeAndCustomer, getAuthData } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import OrderTable from "./OrderTable";
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import PromatePageHeader from '../common/PromatePageHeader';
import { DOC_TYPE } from "../../redux/reducers";

class Orders extends Component {
  state = {
    loading: false,
    filter: {
      DocType: DOC_TYPE.SALES_ORDER,
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    const { match, authData: { userId } } = this.props;
    const { customerId } = match.params

    if (customerId)
      this.setState({
        filter: {
          ...this.state.filter,
          UserCode: userId,
          BillToCusNo: customerId
        }
      }, this.fetchData);
    else
      this.setState({
        filter: {
          ...this.state.filter,
          UserCode: userId
        }
      }, this.fetchData);
  }

  fetchData = () => {
    const { filter } = this.state;

    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchSalesHeaders(filter).then((res) => {
      this.hideLoadingMessage()
      this.setState({ loading: false })
    })
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing orders.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } });

  render() {
    const { orders } = this.props;
    const { loading, filter } = this.state;
    return (
      <div>
        <PromatePageHeader
          title={'Orders'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Spin spinning={loading && orders.length === 0} tip={"Loading Orders"}>
            <OrderTable
              orders={orders}
              filter={filter}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.fetchData} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params

  const orders = customerId ? getSalesHeadersByTypeAndCustomer(state, DOC_TYPE.SALES_ORDER, customerId) : getSalesHeadersByType(state, DOC_TYPE.SALES_ORDER);
  return { orders, authData: getAuthData(state) };
};

export default connect(mapStateToProps, { fetchSalesHeaders, getAuthData })(Orders);