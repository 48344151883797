import React, { Component } from 'react';
import moment from 'moment';
import SyncReport from "../Files/Pdf/SyncReport";
import { PDFExport } from '@progress/kendo-react-pdf';
import { withToastManager } from 'react-toast-notifications';

import {
  changeNetworkMode, loadOfflineCustomers, loadOfflineCustomerCategories,
  loadOfflinePaymentTerms, loadOfflinePaymentMethods, loadOfflineDistributors, loadSalesHeaders,
  loadOfflineStartCallReasons, loadOfflineCallEndStatuses, loadOfflineCustomersDropdown, loadOfflineActiveCall,
  clearLocalStorage, startCall, endCall, createCustomer, loadWarehouseLocations, loadItems, loadSalesLines, syncCustomer,
  createPaymentMethod, createPaymentTerm, createSalesHeader, createSalesLine, updateSalesHeader, confirmSalesHeader,
  uploadFile, addSalesAttachment, loadOfflineTripSchedules, loadOfflineDiscountReasons, loadOfflineSalesInvoices,
  loadOfflineSalesReturnReasons, loadOfflineInventoryItems, loadOfflineUsersHierarchy
} from '../../redux/actions/networkMode';
import { connect } from 'react-redux';
import { Modal, Switch } from 'antd';
import { getAuthData, getNetworkModeDetector, getTopPosition, getFlatPositions } from '../../redux/reducers';
import Loader from "react-loader";
import styled, { css } from "styled-components";
import { getOfflineData } from '../../localStorage';
import { deleteIndexDB, createObjectStoreIndexDB, readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects';
import { isValidTimeForWork, saveSwitchNetworkModeTime } from '../common/TimeStamp';
import { exportJson, getConsoleDetails } from '../common/FileExport';
import { sync } from '../common/SyncDetails';


const { confirm } = Modal;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

class OfflineButtonComponent extends Component {
  state = {
    loading: false,
    syncData: {
      callsData: [],
      customerCreationData: [],
      salesOrders: {
        salesHeaders: [],
        salesLines: [],
        signatures: []
      },
      salesReturnOrders: {
        salesHeaders: [],
        salesLines: [],
        signatures: []
      }
    }
  };

  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
  }


  sleep = (miliseconds) => {
    return new Promise(resolve => setTimeout(resolve, miliseconds))
  }

  async buttonClick() {
    const { toastManager } = this.props;

    const res = await isValidTimeForWork();
    if (!res.isValid) {
      if (this.props.isSystemOffline) {
        toastManager.add(`Your offline details are not sync to the system. Because working time isn't between ${res.end}H - ${res.start}H`, { autoDismiss: true, appearance: 'error' });
        this.props.changeNetworkMode(false);
        this.setState({ loading: false });
        await saveSwitchNetworkModeTime('offline_session_end_timestamp');
        await this.exportPdf();
        await exportJson(toastManager);
        return;
      } else {
        toastManager.add(`You cannot switch to offline mode between ${res.end}H - ${res.start}H`, { autoDismiss: true, appearance: 'error' });
        return;
      }
    }

    if (this.props.isSystemOffline) {
      this.retriesCount = 0;
      console.log("system going online... data syncing...")
      this.setState({ loading: true });
      await saveSwitchNetworkModeTime('offline_session_end_timestamp');
      await this.syncOfflineData();
    } else {
      console.log("system going offline.. data storing to indexDB...");
      this.setState({ loading: true });
      await saveSwitchNetworkModeTime('offline_session_start_timestamp');

      (async () => {
        deleteIndexDB()
        clearLocalStorage()
        await createObjectStoreIndexDB()
        await this.props.loadOfflineCustomers()
        await this.props.loadOfflineCustomerCategories()
        await this.props.loadOfflinePaymentTerms()
        await this.props.loadOfflinePaymentMethods()
        await this.props.loadOfflineDistributors(this.props.authData)
        await this.props.loadOfflineDiscountReasons()
        insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS, {
          data: []
        })
        await this.props.loadWarehouseLocations()
        await this.props.loadOfflineStartCallReasons()
        await this.props.loadOfflineCallEndStatuses()
        await this.props.loadItems(this.props.authData)
        await this.props.loadOfflineTripSchedules()
        insertDataIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE, {
          data: {
            records: []
          }
        })

        await this.props.loadOfflineSalesInvoices();
        await this.props.loadOfflineSalesReturnReasons();
        await this.props.loadOfflineInventoryItems();

        // Create empty object for sales return headers
        await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS, {
          data: []
        });
        // Create empty object for sales return lines
        await insertDataIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES, {
          data: []
        });
        // Create empty object for sales return signatures
        await insertDataIndexDB(TABLES.IMAGES, OBJECT_KEYS.SALES_RETURN_SIGNATURES, {
          data: []
        });

        await this.props.loadOfflineUsersHierarchy();

        this.props.changeNetworkMode(true)
        this.setState({ loading: false })
      })()
    }
  }

  showNetworkFailModel = (context, props) => {
    confirm({
      title: "Data does not sync successfully!",
      content: "Please check your internet connection and try again.",
      maskClosable: false,
      onOk() {
        context.showModal = false
      },
      onCancel() {
        context.showModal = false
      },
    });
  }

  showConfirm = (context, props) => {
    let tripSchedulesCount = getOfflineData("state").tripSchedules.ids.length;
    context.showModal = true
    if (this.props.ongoingCall) {
      confirm({
        title: "Ongoing Call!",
        content: "There is an active call. Please end the call before switch to the mode!",
        maskClosable: false,
        onOk() {
          context.showModal = false
          props.history.push('/calls/' + props.ongoingCall.No)
        },
        onCancel() {
          context.showModal = false
        },
      });
    } else if (tripSchedulesCount == 0) {
      confirm({
        title: "No scheduled trips !",
        content: "Please select Trip schedules for today and try again.",
        maskClosable: false,
        onOk() {
          context.showModal = false
          props.history.push("/tripScheduler");
        },
        onCancel() {
          context.showModal = false
        },
      });
    } else {
      confirm({
        title: window.navigator.onLine ? !this.props.isSystemOffline ? 'Do you want to change system to offline mode?' : 'Do you want to change system to online mode?' : "No internet",
        content: window.navigator.onLine ?
          (
            !this.props.isSystemOffline
              ? 'Currently system is at online mode. Are you sure change system to offline mode? You need good internet connection until fetch relavant data'
              : 'Currently system is at offline mode. Are you sure change system to online mode? You need good internet connection until pull relavant data'
          )
          : 'Please check your internet connection!',
        maskClosable: false,
        onOk() {
          context.showModal = false;
          if (window.navigator.onLine) {
            context.buttonClick();
          }
        },
        onCancel() {
          context.showModal = false
        },
      });
    }
  }

  syncOfflineData = async () => {
    const {
      startCall,
      endCall,
      createCustomer,
      createPaymentMethod,
      createPaymentTerm,
      createSalesHeader,
      createSalesLine,
      uploadFile,
      addSalesAttachment,
      confirmSalesHeader,
    } = this.props;

    await sync({
      authData: this.props.authData,
      api: {
        startCall,
        endCall,
        createCustomer,
        createPaymentMethod,
        createPaymentTerm,
        createSalesHeader,
        createSalesLine,
        uploadFile,
        addSalesAttachment,
        confirmSalesHeader
      },
      onSuccess: async (log) => {
        await this.exportPdf();
        await exportJson(this.props.toastManager, log);
        this.props.changeNetworkMode(false)
        this.setState({ loading: false });
      },
      onCatch: async (error, log) => {
        console.log(error);
        const consoleError = getConsoleDetails();
        await exportJson(this.props.toastManager, { ...log, "console_details": consoleError });
        this.props.changeNetworkMode(false);
        this.setState({ loading: false });
      },
      onError: async (log) => {
        const consoleError = getConsoleDetails();
        await exportJson(this.props.toastManager, { ...log, "console_details": consoleError });
        this.showNetworkFailModel(this, this.props);
        this.setState({ loading: false });
      }
    });
  }

  async exportPdf() {
    let res = await this.getDetailsFromIndexDB();
    this.setState({ syncData: res }, () => {
      if (res) {
        this.pdfExportComponent.current.save();
      }
    });

  }

  getDetailsFromIndexDB = async () => {

    const callsData = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const customerCreationData = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const salesHeaders = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const salesLines = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const salesOrderSignatures = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SIGNATURES).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const salesReturnHeaders = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const salesReturnLines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });
    const salesReturnSignatures = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SALES_RETURN_SIGNATURES).then((res) => {
      return res;
    }).catch((err) => { console.log(err); });

    return {
      callsData: (callsData && callsData.records != undefined) ? callsData.records : [],
      customerCreationData: (customerCreationData && customerCreationData.data != undefined) ? customerCreationData.data : [],
      salesOrders: {
        salesHeaders: (salesHeaders && salesHeaders.data != undefined) ? salesHeaders.data : [],
        salesLines: (salesLines && salesLines.data.records != undefined) ? salesLines.data.records : [],
        signatures: (salesOrderSignatures && salesOrderSignatures.data != undefined) ? salesOrderSignatures.data : []
      },
      salesReturnOrders: {
        salesHeaders: (salesReturnHeaders && salesReturnHeaders.data != undefined) ? salesReturnHeaders.data : [],
        salesLines: (salesReturnLines && salesReturnLines.data != undefined) ? salesReturnLines.data : [],
        signatures: (salesReturnSignatures && salesReturnSignatures.data != undefined) ? salesReturnSignatures.data : []
      }
    };
  }

  render() {

    const { filter, call, ongoingCall } = this.props;
    // const {Status } = call;
    // const isCallOpen = Status && Status === 'Open';

    return (
      <div>
        <div style={{ position: 'absolute', top: '-100vh', left: '-100vw' }}>
          <PDFExport
            fileName={moment(new Date()).format('YYYY-MM-DD(h-mma)')}
            ref={this.pdfExportComponent}
            paperSize="A4"
            scale={0.8}
            forcePageBreak=".page-break"
          >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 10px 5px 20px', borderBottom: '1px solid gray' }}>
              <span>Session Start at: {localStorage.getItem('offline_session_start_timestamp') ?? '-'}</span>
              <span>Session End at: {localStorage.getItem('offline_session_end_timestamp') ?? '-'}</span>
            </div>
            <SyncReport data={this.state.syncData} />
          </PDFExport>
        </div>
        <DarkBackground disappear={this.state.loading}>
          <Loader
            loaded={false}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#a5d8ff"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </DarkBackground>
        <Switch
          className='react-switch'
          checked={this.props.isSystemOffline}
          onClick={() => this.showConfirm(this, this.props)}
          style={{ float: 'right' }}
        />
      </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    authData: getAuthData(state),
    isSystemOffline: getNetworkModeDetector(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
  };
};

export default withToastManager(connect(mapStateToProps, {
  loadOfflineStartCallReasons, loadOfflineCallEndStatuses, changeNetworkMode,
  loadOfflineCustomers, loadOfflineCustomerCategories, loadOfflinePaymentTerms,
  loadOfflinePaymentMethods, loadOfflineDistributors, loadSalesHeaders, loadOfflineCustomersDropdown,
  loadOfflineActiveCall, clearLocalStorage, startCall, endCall, createCustomer, loadWarehouseLocations, loadItems,
  loadSalesLines, syncCustomer, createPaymentMethod, createPaymentTerm, createSalesHeader, createSalesLine, updateSalesHeader,
  uploadFile, addSalesAttachment, confirmSalesHeader, loadOfflineTripSchedules, loadOfflineDiscountReasons, loadOfflineSalesInvoices,
  loadOfflineSalesReturnReasons, loadOfflineInventoryItems, loadOfflineUsersHierarchy
})(OfflineButtonComponent));
