import React, { Component } from 'react';
import { Table, Row, Input, Col, Button } from "antd";
import { connect } from "react-redux";
import { fetchSalesHeaders } from "../../redux/actions/salesHeaders";
import { addTransferLine, fetchForDoc, updateTransferLine, deleteTransferLine } from "../../redux/actions/transferLines";
import { fetchItems } from "../../redux/actions/items";
import { getItems, getAuthData, getTransferLineMapForDoc, getTotalTranferQtyForDoc } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import SelectWarehouseLocation from '../common/SelectWarehouseLocation'
import { FaCheck, FaTimes, FaSave, FaUndo } from "react-icons/fa";
import uuid from "uuid/v1";
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { withToastManager } from 'react-toast-notifications';
import { formatGeoLocation } from "../../utils/Formats";
import SelectCategoryTree, { filterItemsByCategory } from '../common/SelectCategoryTree';

const { Search } = Input;

class ItemTable extends Component {
  state = {
    LocCode: undefined,
    text: undefined,
    transferLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false,
    itemFilter: []
  }

  getColumns = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '62%',
        render: (Description, item) => item.LotNo + ' - ' + item.ItemNo + ' - ' + Description
      },
      {
        title: 'Uom',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '8%'
      },
      {
        title: 'From Quantity',
        dataIndex: 'Inventory',
        key: 'Inventory',
        align: 'right',
        width: '8%'
      },
      {
        title: 'Transfer Quantity',
        dataIndex: 'Quntity',
        key: 'Quntity',
        width: '8%',
        align: 'right',
        render: (quntity, item) => this.renderQuntity(item),
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'right',
        width: 25,
        render: (status, item) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: 75,
        render: (action, item) => this.renderActions(item),
      }
    ];

  renderQuntity = item => {
    const { transferLine } = this.state;
    const transferLineItem = this.getExistingStockTransferLine(item);
    const key1 = transferLineItem && (transferLineItem.ItemNo + transferLineItem.LotNo);
    const key2 = item.ItemNo + item.LotNo;

    let lineQty = 0;

    if (key1 === key2) lineQty = transferLineItem.Quantity;

    if (transferLine) {
      const key3 = transferLine.ItemNo + transferLine.LotNo;

      if (key3 === key2) lineQty = transferLine.Quantity;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={lineQty}
        className="ant-input arrowless"
        style={{ width: 70, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onChange={(e) => this.handleQtyChange(e.target.value, item)} />
    )
  }

  isDisableRowActions = item => {
    const { transferLine } = this.state;

    if (!transferLine) return false;

    const key1 = item.ItemNo + item.LotNo;
    const key2 = transferLine.ItemNo + transferLine.LotNo;

    return key1 !== key2;
  }

  renderStatus = item => {
    const transferLine = this.getExistingStockTransferLine(item);

    if (transferLine) {
      if (transferLine.added) {
        return (
          <FaCheck color='#07e607' />
        )
      } else {
        return (
          <FaTimes color='red' />
        )
      }
    } else return null;
  }

  getRowClassName = item => {
    const salesLine = this.getExistingStockTransferLine(item);

    if (salesLine) {
      if (salesLine.added) {
        return 'sales-header-selected-item-row'
      } else {
        return ''
      }
    } else return '';
  }

  getExistingStockTransferLine = item => {
    const { transferLineMap } = this.props;
    const { ItemNo, LotNo } = item || {};
    const key = ItemNo + LotNo;

    return transferLineMap[key];
  }

  renderActions = (item) => {
    const { transferLine } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disabled={this.isDisableRowActions(item)} type='primary' size={'small'} onClick={this.handleSaveItem} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        <Button disabled={this.isDisableRowActions(item)} size={'small'} onClick={this.handleResetItem} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
      </div>
    )
  }

  componentDidMount() {
    const { header, fetchForDoc } = this.props;
    fetchForDoc({ DocNo: header.DocNo });
    this.setState({ FromLocCode: header.FromLocCode, ToLocCode: header.ToLocCode }, this.loadItems)
    this.setDeviceMapLocation();
  }

  loadItems = item => {
    const { authData, fetchItems } = this.props;

    const filter = {
      LocCode: this.state.FromLocCode,
      UserCode: authData.userId,
    }

    if (item) {
      filter.ItemNo = item.ItemNo;
      filter.LotNo = item.LotNo;
    }

    this.setState({ loadingItems: true });
    fetchItems(filter).then(() => {
      this.setState({ loadingItems: false });
    });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  handleSaveItem = () => {
    const { header, addTransferLine, authData, toastManager, updateTransferLine, deleteTransferLine } = this.props;
    const { transferLine, MapLocation } = this.state;
    const exitingTransferLine = this.getExistingStockTransferLine(transferLine);

    if (!locationEnabled) {
      toastManager.add('You can not update sales lines without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    const salesLineItem = {
      LineSessionID: uuid(),
      DocNo: header.DocNo,
      ItemNo: transferLine.ItemNo,
      MapLocation,
      Quantity: transferLine.Quantity,
      UserCode: authData.userId,
      LotNo: transferLine.LotNo
    }

    if (!exitingTransferLine) this.handleAction(addTransferLine, salesLineItem); //Add new sales line
    else {
      const updatedTransferLine = {
        ...salesLineItem,
        LineNo: exitingTransferLine.LineNo
      }

      if (salesLineItem.Quantity === '0') this.handleAction(deleteTransferLine, updatedTransferLine); //Remove sales line
      else this.handleAction(updateTransferLine, updatedTransferLine); //Update sales line
    }
  }

  handleAction = (action, salesLineItem) => {
    const { toastManager } = this.props;

    this.setState({ loading: true });
    action(salesLineItem).then(response => {
      this.setState({ loading: false });
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        this.loadItems({ ...this.state.transferLine });
        this.setState({ transferLine: undefined });
      }
    })
  }

  handleResetItem = () => this.setState({ transferLine: undefined });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  handleChange = (field, value, error) => {
    this.setState({ [field]: value })
  }

  handleCategoryFilterChange = value => {
    this.setState({ itemFilter: value });
  }

  renderFromLocationList = () => {
    return (
      <SelectWarehouseLocation
        disabled={true}
        defaultValue={this.state.FromLocCode}
        onChange={this.handleChange} style={{ width: '100%' }} />
    )
  }

  renderToLocationList = () => {
    return (
      <SelectWarehouseLocation
        disabled={true}
        defaultValue={this.state.ToLocCode}
        onChange={this.handleChange} style={{ width: '100%' }} />
    )
  }

  renderTitle = () => {
    return (
      <Row className="promate-table-header" gutter={5} style={{ paddingBottom: 10 }}>
        <Col xs={0} sm={0} md={2} lg={1} xl={1} style={{ height: 32, display: 'flex', alignItems: 'center', fontWeight: 'bold', wordBreak: 'break-all' }}>From</Col>
        <Col xs={0} sm={0} md={6} lg={4} xl={4}>
          {this.renderFromLocationList()}
        </Col>
        <Col xs={0} sm={0} md={2} lg={1} xl={1} style={{ height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>To</Col>
        <Col xs={0} sm={0} md={6} lg={4} xl={4}>
          {this.renderToLocationList()}
        </Col>
        <Col xs={0} sm={0} md={8} lg={14} xl={14}>
          <Search
            name='filterText'
            placeholder='Input search text'
            size='default'
            color={'black'}
            onChange={e => this.handleChange('text', e.target.value, '')} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={0} style={{ marginTop: 10 }}>
          <Search
            placeholder='Input search text'
            size='default'
            color={'black'}
            onChange={e => this.handleChange('text', e.target.value, '')} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginTop: 5 }}>
          <SelectCategoryTree itemFilter={this.state.itemFilter} onChange={this.handleCategoryFilterChange} />
        </Col>
      </Row>
    )
  }

  renderFooter = () => {

    return (
      <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: -15 }}>
        <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ fontSize: "medium" }}>Total Transfer Quantity</div>
          <div style={{ fontSize: "medium" }}>{this.props.totalTransferQty}</div>
        </Col>
        <Col lg={2} md={2} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <Button block type='primary' onClick={() => this.props.history.goBack()}>Done</Button>
        </Col>
      </Row>
    )
  }

  getFilteredItems = () => {
    const { items = [] } = this.props;
    const { text, itemFilter } = this.state;

    let filteredItems = items;

    filteredItems = items.filter(item => {
      const { Description } = item;
      const descriptionFilter = text ? this.stringCompare(Description, text) : true;

      return descriptionFilter;
    });

    filteredItems = filterItemsByCategory(filteredItems, itemFilter);

    return filteredItems
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  handleQtyChange = (value, item) => {
    this.setState({
      transferLine: {
        ...item,
        Quantity: value
      }
    });
  }

  render() {
    const items = this.getFilteredItems();
    const { loading, loadingItems } = this.state;

    return (
      <div>
        <Table
          rowKey={'id'}
          rowClassName={(item, index) => this.getRowClassName(item)}
          columns={this.getColumns()}
          bodyStyle={{ minHeight: this.getTableHeight(items) }}
          dataSource={items}
          size={'small'}
          scroll={{ y: this.getScrollAreaHeight() }}
          pagination={false}
          title={this.renderTitle}
          footer={this.renderFooter}
          loading={loading || (items.length === 0 && loadingItems)} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { header } = ownProps;
  const items = getItems(state);
  const transferLineMap = getTransferLineMapForDoc(state, header.DocNo);

  return {
    authData: getAuthData(state),
    items,
    transferLineMap,
    totalTransferQty: getTotalTranferQtyForDoc(state, header.DocNo)
  };
};

export default withToastManager(withRouter(connect(mapStateToProps,
  {
    fetchItems,
    addTransferLine,
    fetchForDoc,
    updateTransferLine,
    deleteTransferLine,
    fetchSalesHeaders,
    getTotalTranferQtyForDoc
  })(ItemTable)));