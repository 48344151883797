import { Col, Row, Select, Typography } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { CUSTOMER_FREQUENCY } from "../../../../const/Permissions";
import { fetchRoutes } from '../../../../redux/actions/routes';
import { fetchAllCustomerFrequenciesByFilter, fetchAllMonths, fetchAllYears, updateFilter } from '../../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAllMonths, getAllYears, getAuthData, getFilter, getRoutesByUser, getSalesUserHierarchy } from "../../../../redux/reducers";
import { StyledButton } from '../../common/Buttons';
import { getURLParams } from "../../common/helpers";
import UserHierachy from '../../common/UserHierachy';

const { Text } = Typography;
const { Option } = Select;
export class Header extends Component {
    componentDidMount = async () => {
        const { loading, updateFilter, fetchSalesUserHierarchy, authData, toastManager, fetchAllMonths, fetchAllYears, fetchRoutes, fetchAllCustomerFrequenciesByFilter, setLoading, setTableData, setFilter } = this.props;

        setLoading(true);
        await fetchSalesUserHierarchy(authData);
        await fetchAllMonths();
        await fetchAllYears();

        const urlParams = await getURLParams(this.props);

        if (urlParams.UserTag && urlParams.UserTag !== "") {
            const routes = await fetchRoutes({ UserTagFilterOne: urlParams.UserTag }, 0);
            if (routes.records.length == 0) {
                setTableData({ records: [] });
                toastManager.add("Selected user doesn't have any routes for fetch customer frequencies.", { autoDismiss: true, appearance: 'info' });
            } else {

                const updatedFilter = await updateFilter({
                    Year: urlParams.Year,
                    Month: urlParams.Month,
                    UserTag: urlParams.UserTag,
                    RouteCode: routes.records[0].RouteCode
                }, this.props);

                const results = await fetchAllCustomerFrequenciesByFilter({
                    Year: updatedFilter.Year,
                    Month: updatedFilter.Month,
                    UserTag: updatedFilter.UserTag,
                    RouteCode: updatedFilter.RouteCode
                });
                setTableData(results);
                setLoading(false);

            }
        } else {
            toastManager.add("Failed to fetch customer frequencies.", { autoDismiss: true, appearance: 'error' });
        }
        setLoading(false);
    }

    handleChange = async (name, value) => {
        const { updateFilter } = this.props;
        await updateFilter({ [name]: value }, this.props);
    };

    handleSearch = async () => {
        const { fetchAllCustomerFrequenciesByFilter, setLoading, setTableData, filter, toastManager } = this.props;
        setLoading(true);
        if (filter.UserTag) {
            if (filter.RouteCode) {
                const results = await fetchAllCustomerFrequenciesByFilter({
                    Year: filter.Year,
                    Month: filter.Month,
                    UserTag: filter.UserTag,
                    RouteCode: filter.RouteCode
                });
                setTableData(results);
            } else {
                setTableData({ records: [] });
                toastManager.add("Selected user doesn't have any route for search customer frequencies.", { autoDismiss: true, appearance: 'error' });
            }
        } else {
            setTableData({ records: [] });
            toastManager.add("Failed to search customer frequencies.", { autoDismiss: true, appearance: 'error' });
        }
        setLoading(false);
    }

    updateChangeUserHierarchy = async (value) => {
        const { fetchRoutes, updateFilter, toastManager, setTableData, setLoading, loading } = this.props;
        setLoading(true);
        const routes = await fetchRoutes({ UserTagFilterOne: value }, 0);
        if (routes.records.length == 0) {
            setTableData({ records: [] });
            toastManager.add("Selected user doesn't have any routes for fetch customer frequencies.", { autoDismiss: true, appearance: 'info' });
        } else {
            await updateFilter({ RouteCode: routes.records[0].RouteCode }, this.props);
        }
        setLoading(false);
    }

    render() {
        const { salesUserHierarchy } = this.props;
        const hierarchy = salesUserHierarchy[Object.keys(salesUserHierarchy)[0]];

        return (
            <>
                <UserHierachy onUpdate={this.updateChangeUserHierarchy} />
                <Row style={{ padding: "0px 12px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={12} lg={4} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Year:</Text>
                        <Select
                            value={this.props.filter.Year}
                            style={{ width: "100%", ...styles.text }}
                            onChange={(value) => this.handleChange('Year', value)}
                        >
                            {this.props?.years?.records.map((year, index) =>
                                (<Option key={`${index}-${year.Code}`} value={year.Code} style={{ ...styles.text }}>{year.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} lg={4} style={styles.container}>
                        <Text style={{ marginRight: 10, ...styles.text }}>Month:</Text>
                        <Select
                            style={{ width: "100%", ...styles.text }}
                            value={this.props.filter.Month}
                            onChange={(value) => this.handleChange('Month', value)}
                        >
                            {this.props.months?.records.map((month, index) =>
                                (<Option key={`${index}-${month.Code}`} value={month.Code} style={{ ...styles.text }}>{month.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    {
                        this.props.filter.RouteCode && (
                            <Col sm={24} md={24} lg={10} style={styles.container}>
                                <Text style={{ marginRight: 10, ...styles.text }}>Route: </Text>
                                <Select
                                    style={{ width: "100%", ...styles.text }}
                                    value={this.props.filter.RouteCode}
                                    onChange={(value) => this.handleChange('RouteCode', value)}
                                >
                                    {this.props.routes.map((route, index) =>
                                        (<Option key={`${index}-${route.RouteCode}`} value={route.RouteCode}>{route.RouteName}</Option>)
                                    )}
                                </Select>
                            </Col>
                        )
                    }

                    <Col sm={24} md={24} lg={6} style={styles.container}>
                        <StyledButton color="#106bc7" label="Search" onClick={() => this.handleSearch()} />
                        {
                            this.props.permissions.includes(CUSTOMER_FREQUENCY.UPDATE) && (
                                <StyledButton color="#0b5c22" label="Save All" onClick={() => this.props.handleAllUpdate()} />
                            )
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    months: getAllMonths(state),
    years: getAllYears(state),
    routes: getRoutesByUser(state),
    filter: getFilter(state)
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchRoutes,
    fetchAllCustomerFrequenciesByFilter,
    updateFilter
}

export default withRouter(withToastManager(connect(mapStateToProps, mapDispatchToProps)(Header)));

const styles = {
    text: {
        color: '#000000'
    },
    container: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        gap: 5,
        justifyContent: "flex-start",
    }
}