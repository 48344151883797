'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pick = require('lodash/pick');

var _pick2 = _interopRequireDefault(_pick);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var elementResizeDetectorMaker = require("element-resize-detector");

var imgAttrs = ["align", "alt", "crossorigin", "height", "hspace", "ismap", "longdesc", "sizes", "src", "srcset", "usemap", "vspace", "width"];

var calculateScale = function calculateScale(resizeMode, containerWidth, containerHeight, imgWidth, imgHeight) {

  var containerImgWidthRatio = containerWidth / imgWidth;
  var containerImgHeightRatio = containerHeight / imgHeight;

  switch (resizeMode) {
    case 'cover':
      return {
        scaleX: Math.max(containerImgWidthRatio, containerImgHeightRatio),
        scaleY: Math.max(containerImgWidthRatio, containerImgHeightRatio)
      };
    case 'stretch':
      return {
        scaleX: containerImgWidthRatio,
        scaleY: containerImgHeightRatio
      };
    case 'repeat':
      return {
        scaleX: 1,
        scaleY: 1
      };
    case 'contain':
    default:
      return {
        scaleX: Math.min(containerImgWidthRatio, containerImgHeightRatio),
        scaleY: Math.min(containerImgWidthRatio, containerImgHeightRatio)
      };
  }
};

var convertPercentToDecimal = function convertPercentToDecimal(num) {
  if (num.indexOf('%') !== -1) return parseFloat(num) / 100.0;
  return num;
};

var mapFocalPointProps = function mapFocalPointProps(key) {
  switch (key) {
    case 'center':
      return { focalPointX: .5, focalPointY: .5 };
    case 'left':
      return { focalPointX: 0, focalPointY: .5 };
    case 'right':
      return { focalPointX: 1, focalPointY: .5 };
    case 'top':
      return { focalPointX: 0.5, focalPointY: 0 };
    case 'bottom':
      return { focalPointX: 0.5, focalPointY: 1 };
  }

  var _key$split = key.split(' '),
    _key$split2 = _slicedToArray(_key$split, 2),
    focalX = _key$split2[0],
    focalY = _key$split2[1];

  if (focalX && focalY) {
    return {
      focalPointX: convertPercentToDecimal(focalX),
      focalPointY: convertPercentToDecimal(focalY)
    };
  }

  // Else return center
  return { focalPointX: .5, focalPointY: .5 };
};

var BetterImg = function (_Component) {
  _inherits(BetterImg, _Component);

  function BetterImg() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BetterImg);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BetterImg.__proto__ || Object.getPrototypeOf(BetterImg)).call.apply(_ref, [this].concat(args))), _this), _this.erd = null, _this.state = {
      imgWidth: 0,
      imgHeight: 0,
      imgLoaded: false,
      imgClicked: false,
      currentZoomScale: _this.props.scale,
      focalPoint: _this.props.focalPoint,
      containerWidth: undefined,
      containerHeight: undefined
    }, _this.handleOnLoad = function (e) {
      var imgWidth = e.target.offsetWidth;
      var imgHeight = e.target.offsetHeight;

      // Set Component Height to props.height (if set), else use imgHeight
      _this.setState({
        imgWidth: imgWidth,
        imgHeight: imgHeight,
        imgLoaded: true,
        containerHeight: _this.props.height || imgHeight
      });

      //Fire handleUpdateSize once to trigger calculation
      _this.handleUpdateSize();
    }, _this.handleUpdateSize = function (element) {
      // Set Component Width to props.width (if set), or containerWidth
      var el = element || _this.container;
      var containerWidth = el.offsetWidth;
      _this.setState({
        containerWidth: containerWidth
      });
    }, _this.handleClick = function (e) {
      e.stopPropagation();
      var clickToZoom = _this.props.clickToZoom;

      if (clickToZoom) {
        // console.log('BetterImg', e.nativeEvent.clientX, e.nativeEvent.clientY);
        // console.log('Parent', this.container.getBoundingClientRect());
        var _this$container$getBo = _this.container.getBoundingClientRect(),
          left = _this$container$getBo.left,
          top = _this$container$getBo.top;

        var pointX = e.nativeEvent.clientX - left;
        var pointY = e.nativeEvent.clientY - top;
        // console.log('pointX,Y = ', pointX, pointY);
        var containerWidth = _this.props.width || _this.state.containerWidth;
        var containerHeight = _this.state.containerHeight;

        if (!_this.state.isZoomedIn) {
          _this.setState({
            isZoomedIn: true,
            imgClicked: true,
            currentZoomScale: _this.props.zoomInScale,
            focalPoint: pointX / containerWidth + ' ' + pointY / containerHeight
          });
        } else {
          _this.setState({
            isZoomedIn: false,
            imgClicked: true,
            currentZoomScale: _this.props.scale,
            focalPoint: 'center'
          });
        }
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BetterImg, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (!this.erd) this.erd = elementResizeDetectorMaker();
      this.erd.listenTo(this.container, this.handleUpdateSize);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.erd.removeListener(this.container, this.handleUpdateSize);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      // If no resizeMode is provided, be normal <img />
      if (!this.props.resizeMode) {
        var _imgProps = (0, _pick2.default)(this.props, imgAttrs);
        return _react2.default.createElement('img', _imgProps);
      }

      // If props.width is provide, use it, else use containerWidth for calculation
      var containerWidth = this.props.width || this.state.containerWidth;
      var containerHeight = this.state.containerHeight;

      var _props = this.props,
        src = _props.src,
        resizeMode = _props.resizeMode;
      var _state = this.state,
        imgWidth = _state.imgWidth,
        imgHeight = _state.imgHeight;


      var wrapperStyles = {
        overflow: 'hidden',
        position: 'relative',
        // If props.width is provide, use it, else no need to control container's width
        width: this.props.width ? this.props.width : undefined,
        height: containerHeight
      };

      // Calculate Scale

      var _calculateScale = calculateScale(resizeMode, containerWidth, containerHeight, imgWidth, imgHeight),
        scaleX = _calculateScale.scaleX,
        scaleY = _calculateScale.scaleY;

      if (resizeMode === 'cover' && this.state.currentZoomScale) {
        scaleX = this.state.currentZoomScale * scaleX;
        scaleY = this.state.currentZoomScale * scaleY;
      }

      // Focal Point (cover only)
      var focalPointX = .5;
      var focalPointY = .5;
      if (resizeMode === 'cover') {
        var focalPoint = mapFocalPointProps(this.state.focalPoint);
        focalPointX = focalPoint.focalPointX;
        focalPointY = focalPoint.focalPointY;
      }

      // Calculate X,Y
      var x = focalPointX * (containerWidth - scaleX * imgWidth);
      var y = focalPointY * (containerHeight - scaleY * imgHeight);

      var imgStyle = {
        WebkitTransform: 'translate3d(' + x + 'px, ' + y + 'px, 0) scale(' + scaleX + ', ' + scaleY + ')',
        transform: 'translate3d(' + x + 'px, ' + y + 'px, 0) scale(' + scaleX + ', ' + scaleY + ')',
        transformOrigin: '0% 0%'
      };
      if (this.props.clickToZoom && this.state.imgClicked) imgStyle.transition = 'all .3s ease-out';

      var containerProps = {};
      if (this.props.clickToZoom) {
        containerProps.onClick = this.handleClick;
      }
      var imgProps = (0, _pick2.default)(this.props, imgAttrs);
      return _react2.default.createElement(
        'div',
        _extends({
          className: 'better-img',
          style: wrapperStyles,
          ref: function ref(container) {
            return _this2.container = container;
          }
        }, containerProps),
        _react2.default.createElement('img', _extends({}, imgProps, {
          src: src,
          style: imgStyle,
          onLoad: this.handleOnLoad
        }))
      );
    }
  }]);

  return BetterImg;
}(_react.Component);


BetterImg.defaultProps = {
  scale: 1,
  focalPoint: 'center',
  zoomInScale: 2,
  clickToZoom: false
};
;

exports.default = BetterImg;