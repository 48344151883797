import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../../common/PromatePageHeader';

class LocationView extends Component {
  state = {
    editLocation: false
  }

  render() {
    const { locationId } = this.props.match.params
    const { editLocation } = this.state
    const newLocation = locationId === 'new';

    return (
      <div>
        <PromatePageHeader
          title={newLocation ? 'New Location' : 'Location Info'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}
          extra={(!newLocation && !editLocation) ? [
            <Button style={{
              backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute',
              top: 30,
              right: 25
            }} onClick={this.editLocation}>
              Edit
            </Button>
          ] : []}>
          <Container
            locationId={locationId}
            editLocation={editLocation}
            newLocation={newLocation} />
        </PromatePageHeader>
      </div>
    )
  }

  editLocation = () => {
    const { warehouseId, locationId } = this.props.match.params
    this.setState({ editLocation: true });

    this.props.history.push("/warehouses/" + warehouseId + "/locations/" + locationId + "/edit");
  }
}


const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default LocationView;