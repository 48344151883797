import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Button, Card, Tag, Divider, Col, Icon, Row } from 'antd';
import { format } from "currency-formatter";
import moment from 'moment';
import { dateFormatView } from "../../../const/Formats";
import { getCustomerCategories, getCustomerSubcategories, getCustomerPaymentMethodsByCustomerId, getCustomerPaymentTermsByCustomerId, getPaymentMethods, getPaymentTerms, getRoute, getAuthData, getHierarchyBranch, getCustomer, getLoggedInUser } from "../../../redux/reducers";
import BetterImg from '../../common/BetterImage';
import LocationMap from '../../common/LocationMap';
import Api from '../../../api/consts';
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { fetchRoute } from '../../../redux/actions/routes';
import { fetchCustomer, fetchCustomerCategories, fetchCustomerSubcategories } from '../../../redux/actions/customers';
import PromatePageHeader from '../../common/PromatePageHeader';
import { CUSTOMERS } from '../../../const/Permissions';
import { ProMateGreen } from '../../../const/Theme';
import Actions from './Actions';
import { addOrder } from "../../../redux/actions/orders";
import { withToastManager } from 'react-toast-notifications';
import { defaultOrder } from '../../Orders/OrderScreen/Container';
import { fetchDistributors } from '../../../redux/actions/distributors';
import { fetchPaymentMethods, fetchPaymentMethodsForCustomer } from '../../../redux/actions/paymentMethods';
import { fetchPaymentTerms, fetchPaymentTermsForCustomer } from '../../../redux/actions/paymentTerms';
import uuid from 'uuid';
import { FaPencilAlt } from 'react-icons/fa';

const currencyFormat = {
  decimalDigits: 2,
  decimalSeparator: '.'
}

class Details extends Component {
  state = {
    showMap: false
  };

  componentDidMount = () => {
    const { fetchSalesUserHierarchy, authData, customer, fetchRoute, fetchCustomer, match, fetchPaymentMethodsForCustomer, fetchPaymentTermsForCustomer, fetchCustomerCategories, fetchCustomerSubcategories, fetchPaymentMethods, fetchPaymentTerms } = this.props;
    //console.log(match.params.customerId)

    fetchCustomer(match.params.customerId);
    fetchSalesUserHierarchy(authData);
    fetchPaymentMethodsForCustomer({ CustNo: match.params.customerId });
    fetchPaymentTermsForCustomer({ CustNo: match.params.customerId });
    fetchCustomerCategories();
    fetchCustomerSubcategories();
    fetchPaymentMethods();
    fetchPaymentTerms();
    if (customer && customer.No) fetchRoute(customer.RouteCode);
  }

  componentWillReceiveProps = nextProps => {
    const { customer: thisCustomer, fetchRoute } = this.props;
    const { customer: nextCustomer } = nextProps;

    if (thisCustomer) return;
    else if (nextCustomer) fetchRoute(nextCustomer.RouteCode);
  }

  getCategoryNameByCode = code => {
    const { customerCategories } = this.props;
    const category = customerCategories && customerCategories.length > 0 && customerCategories.find(category => category.Code === code);

    return category ? category.Description : '';
  }

  getSubcategoryNameByCode = code => {
    const { customerSubcategories } = this.props;
    const category = customerSubcategories && customerSubcategories.length > 0 && customerSubcategories.find(category => category.Code === code);
    return category ? category.Description : '';
  }

  onMapViewSelect = selected => this.setState({ showMap: selected });

  renderHierarchyPosition = (title, position = {}) => {
    const { user } = position;
    let value = '';
    if (user) {
      if (user.name) value = user.name + ' ';
      if (user.code) value += '(' + user.code + ')';

      return (
        <tr>
          <td style={{ width: 25 }}><Icon type="apartment" /></td>
          <td>{title}</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{value}</td>
        </tr>
      );
    } else {
      return null;
    }
  }

  renderInforColumns = () => {
    const { customer, paymentTerms, paymentMethods, customerPaymentTerms, customerPaymentMethods, hierarchyBranch, route } = this.props;
    const paymentTermDescriptions = paymentTerms.filter(term => customerPaymentTerms.includes(term.No)).map(term => term.Description);
    const paymentMethodDescriptions = paymentMethods.filter(method => customerPaymentMethods.includes(method.No)).map(method => method.Description);
    const imageSection = [
      <Col lg={10} md={10} sm={24} xs={24}>
        <tr>
          <td colSpan={4}>
            {customer.ProfileImageURL && <BetterImg
              src={Api.files.download.url(customer.ProfileImageURL)}
              width={'100%'}
              resizeMode="cover" />}
          </td>
        </tr>
      </Col>,
      <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>
    ]

    const nonImageSection = [
      <Col lg={8} md={8} sm={24} xs={24}>
        {customer.No && <tr>
          <td style={{ width: 25 }}><Icon type="number" /></td>
          <td style={{ width: 80 }}>Code</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.No}</td>
        </tr>}
        {customer.Name && <tr>
          <td style={{ width: 25 }}><Icon type="shop" /></td>
          <td>Outlet Name</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.Name}</td>
        </tr>}
        <tr>
          <td style={{ width: 25 }}><Icon type="branches" /></td>
          <td>Route</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{route ? route.RouteName + ' ' : ''}({customer.RouteCode})</td>
        </tr>
        {customer.NIC && <tr>
          <td style={{ width: 25 }}><Icon type="idcard" /></td>
          <td>NIC/Passport</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.NIC}</td>
        </tr>}
        {customer.ContactPerson && <tr>
          <td style={{ width: 25 }}><Icon type="user" /></td>
          <td>Contact P.</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.ContactPerson}</td>
        </tr>}
        {customer.PhoneNo && <tr>
          <td style={{ width: 25 }}><Icon type="phone" /></td>
          <td style={{ width: 80 }}>Contact No</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.PhoneNo}</td>
        </tr>}
        {customer.FaxNo && <tr>
          <td style={{ width: 25 }}><Icon type="printer" /></td>
          <td>POSM</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.FaxNo}</td>
        </tr>}
        {customer.EMail && <tr>
          <td style={{ width: 25 }}><Icon type="mail" /></td>
          <td>Email</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.EMail}</td>
        </tr>}
        {customer.Add && <tr>
          <td style={{ width: 25 }}><Icon type="pushpin" /></td>
          <td>Address</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.Add}</td>
        </tr>}
        {customer.createdBy && <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td>Created By</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.createdBy}</td>
        </tr>}
        <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td style={{ width: 80 }}><p>Status</p></td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td><Tag color={getStatusColor(customer.Status)}>{customer.Status}</Tag></td>
        </tr>
      </Col>,
      <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>,
      <Col lg={6} md={6} sm={24} xs={24}>
        {/* <tr>
          <td style={{ width: 25 }}><Icon type="minus-square" /></td>
          <td>Outstanding</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{format(customer.OutstandAmount, currencyFormat)}</td>
        </tr> */}
        <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td>Category</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{this.getCategoryNameByCode(customer.OutletCategoryCode)}</td>
        </tr>
        <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td>Subategory</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{this.getSubcategoryNameByCode(customer.OutletSubCategoryCode)}</td>
        </tr>
        {paymentTermDescriptions.length > 0 && <tr>
          <td style={{ width: 25 }}><Icon type="pie-chart" /></td>
          <td>Pay. Terms</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{paymentTermDescriptions.map(term => <Tag style={styles.tag}>{term}</Tag>)}</td>
        </tr>}
        {paymentMethodDescriptions.length > 0 && <tr>
          <td style={{ width: 25 }}><Icon type="file-protect" /></td>
          <td>Pay. Methods</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{paymentMethodDescriptions.map(method => <Tag style={styles.tag}>{method}</Tag>)}</td>
        </tr>}
        <tr>
          <td style={{ width: 25 }}><Icon type="pay-circle" /></td>
          <td>Type</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{customer.PaymentType}</td>
        </tr>
        {this.renderHierarchyPosition('DGM', hierarchyBranch['SALES-DGM'])}
        {this.renderHierarchyPosition('RSM', hierarchyBranch['RSM'])}
        {this.renderHierarchyPosition('ASM', hierarchyBranch['ASM'])}
        {this.renderHierarchyPosition('DIST', hierarchyBranch['DIST-OWNER'])}
        {this.renderHierarchyPosition('CSR', hierarchyBranch['CSR'])}
      </Col>,
    ];

    const finalSection = customer.ProfileImageURL ? imageSection.concat(nonImageSection) : nonImageSection;
    return finalSection;
  }

  renderMap = () => {
    const { customer, route } = this.props;
    const routes = !!route ? [route] : [];
    return (
      <div style={styles.mapContainer}>
        <LocationMap customers={[customer]} routes={routes} height={300} />
      </div>
    );
  }

  renderHeaderSwitcher = () => {
    return (
      <div style={styles.alignRight}>
        <Button.Group>
          <Button icon='menu' size='small' onClick={() => this.onMapViewSelect(false)}>Info</Button>
          <Button icon='environment' size='small' onClick={() => this.onMapViewSelect(true)}>Map</Button>
        </Button.Group>
      </div>
    );
  }

  editCustomer = () => {
    const { customerId } = this.props.match.params;
    this.props.history.push("/customers/" + customerId + "/edit");
  }

  submitOrder = () => {
    const { addOrder, toastManager, customer } = this.props;
    const order = { ...defaultOrder, sellToCustomerCode: customer.id, sellToCustomerName: customer.name }

    this.setState({ loading: true })
    addOrder(order).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
        defaultOrder.sessionId = uuid();
        this.props.history.replace('/orders/' + response.order.orderNo)
      } else {
        toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  render() {
    const { customer, loggedInUser: { permissions = [] } } = this.props;
    const { showMap } = this.state;

    if (!customer) return null;

    return (
      <PromatePageHeader
        title={'Customer Info'}
        style={styles.pageHeader}
        onBack={() => this.props.history.goBack()}
        extra={permissions.includes(CUSTOMERS.UPDATE) ? [
          <Button style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb} onClick={this.editCustomer}>
            {
              this.context.screenType === 'MOBILE' ? (
                <FaPencilAlt color={'white'} size={22} />
              ) : (<span>Edit</span>)
            }
          </Button>
        ] : []}>
        <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            {showMap ? this.renderMap() : this.renderInforColumns()}
          </Row>

          <Row gutter={10}>
            {this.renderHeaderSwitcher()}
          </Row>
        </Card>

        {customer.blockedReasons && customer.blockedReasons.length > 0 && <Card className={'customerInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Blcoked Reasons</td>
                <td></td>
              </tr>

              {customer.blockedReasons.map((reason, index) => [
                <tr>
                  <td style={{ width: 25 }}>{index + 1}.</td>
                  <td><b>{reason.type} - {reason.reasonDescription}</b> </td>
                  <td textAlign='right'><i> {moment(reason.time).format(dateFormatView + 'hh:mm a')}</i></td>
                </tr>,
                <tr>
                  <td style={{ width: 25 }}></td>
                  <td colSpan={2}>{reason.narration}</td>
                  <td></td>
                </tr>,
                <tr>
                  <td style={{ width: 25, height: 15 }}></td>
                  <td></td>
                  <td></td>
                </tr>
              ])}
            </Col>
          </Row>
        </Card>}

        <Actions customer={customer} submitOrder={this.submitOrder} />
      </PromatePageHeader>
    )
  }
}

const getStatusColor = status => {
  if (status) return 'red'
  else return 'green'
}

const mapStateToProps = (state, ownProps) => {
  const customer = getCustomer(state, ownProps.match.params.customerId);

  return {
    customer,
    authData: getAuthData(state),
    customerCategories: getCustomerCategories(state),
    customerSubcategories: getCustomerSubcategories(state),
    paymentTerms: getPaymentTerms(state),
    paymentMethods: getPaymentMethods(state),
    customerPaymentTerms: customer ? getCustomerPaymentTermsByCustomerId(state, customer.No) : [],
    customerPaymentMethods: customer ? getCustomerPaymentMethodsByCustomerId(state, customer.No) : [],
    route: customer ? getRoute(state, customer.RouteCode) : undefined,
    hierarchyBranch: customer ? getHierarchyBranch(state, customer.UserTag) : {},
    loggedInUser: getLoggedInUser(state)
  };
}

Details.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

export default withToastManager(connect(mapStateToProps, { fetchSalesUserHierarchy, fetchRoute, fetchCustomer, fetchCustomerCategories, fetchCustomerSubcategories, fetchPaymentMethodsForCustomer, fetchPaymentTermsForCustomer, fetchPaymentMethods, fetchPaymentTerms })(Details));

const styles = {
  tag: {
    marginTop: 5
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  mapContainer: {
    marginBottom: 10
  },
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};