import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

class OrderHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  renderActions = () => {
    const { order, newOrder, onSubmitOrder, onClickConfirmOrder, onShareOrder, onDeleteOrder } = this.props;
    const { salesLines, status } = order;

    if (newOrder) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitOrder}
        >
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (order.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteOrder}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       order.salesLines && order.salesLines.length > 0 &&
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (order.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       order.links && order.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareOrder()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareOrder()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  render() {
    const { order } = this.props;
    const { orderNo, orderDate, status, sellToCustomerName, sellToCustomerCode, billToCustomerCode, billToCustomerName } = order;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {orderNo && <tr>
              <td>Order #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{orderNo ? orderNo.replace(/---/g, '/') : ''}</td>
            </tr>}
            {orderDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(orderDate).format(dateFormatView)}</td>
            </tr>}
            {status && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>
                {<Tag color={this.getStatusColor(status)}>{status}</Tag>}
              </td>
            </tr>}
            {sellToCustomerCode && <tr>
              <td>Distributor #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerCode}</td>
            </tr>}
            {sellToCustomerName && <tr>
              <td>Distributor Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerName}</td>
            </tr>}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {billToCustomerCode && <tr>
              <td>Supplier #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerCode}</td>
            </tr>}
            {billToCustomerName && <tr>
              <td>Supplier Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerName}</td>
            </tr>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default OrderHeader;