import React, { Component } from 'react';
import { Col, Tag, Row } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import html2canvas from "html2canvas";
import { format } from "currency-formatter";
import Api from '../../../api/consts';
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class OrderExport extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  generateSalesLines = salesLines => {
    const salesLineRows = [];
    let lineNumber = 0;
    salesLines.forEach(salesLine => {
      lineNumber = lineNumber + 1;
      salesLineRows.push(
        <Row style={{ padding: '2px 10px' }}>
          <Col span={2} style={{ fontSize: 10 }}>{lineNumber}</Col>
          <Col span={3} style={{ fontSize: 10 }}>{salesLine.itemNo}</Col>
          <Col span={9} style={{ fontSize: 10 }}>{salesLine.description}</Col>
          <Col span={2} style={{ fontSize: 10, textAlign: 'right' }}>
            <CurrencyFormat
              value={salesLine.unitPrice}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
          <Col span={2} style={{ fontSize: 10, textAlign: 'right' }}>{salesLine.quantity}</Col>
          <Col span={3} style={{ fontSize: 10, textAlign: 'right' }}>
            <CurrencyFormat
              value={salesLine.discountAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
          <Col span={3} style={{ fontSize: 10, textAlign: 'right' }}>
            <CurrencyFormat
              value={salesLine.lineAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>
        </Row>
      )
    })
    return salesLineRows;
  }

  printOrder = orderNo => {
    html2canvas(document.querySelector("#orderPrintDiv"), { width: 685 }).then((canvas) => {

      var a = document.createElement('a');
      a.href = canvas.toDataURL("image/jpeg");
      a.download = orderNo + '.jpeg';
      a.click();
    })
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const buttonArea = document.getElementsByClassName('actionButtons');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    const buttonAreaHeight = (buttonArea && buttonArea[0]) ? buttonArea[0].clientHeight : 72;
    return window.innerHeight - (headerHeight + buttonAreaHeight);
  }

  render() {
    const { order } = this.props;
    const {
      orderNo,
      orderDate,
      status,
      sellToCustomerName,
      sellToCustomerCode,
      billToCustomerCode,
      billToCustomerName,
      salesLines,
      totalAmount,
      totalDiscountAmount,
      amountIncludingVat } = order;

    return (
      <Row>
        <Row>
          <div id={'orderPrintDiv'} style={{ padding: 25 }}>
            <Row>
              <img src={'/logo.png'} style={{ width: 150 }} alt='Logo' />
            </Row>
            <Row style={{ lineHeight: '25px', width: '635px', borderRadius: 5, marginTop: 10 }}>
              <Col span={12}>
                {orderNo && <tr>
                  <td>Order</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{orderNo ? orderNo : ''}</td>
                </tr>}
                {orderDate && <tr>
                  <td>Date</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{moment(orderDate).format(dateFormatView)}</td>
                </tr>}
                {status && <tr>
                  <td>Status</td>
                  <td style={{ width: 30, textAlign: 'center' }}>:</td>
                  <td>
                    {<Tag color={this.getStatusColor(status)}>{status}</Tag>}
                  </td>
                </tr>}

                <tr>
                  <td style={{ height: 20 }}></td>
                </tr>

                {sellToCustomerCode && <tr>
                  <td colSpan={3}><b>Merchant</b></td>
                </tr>}
                {sellToCustomerCode && <tr>
                  <td colSpan={3}>{sellToCustomerCode}</td>
                </tr>}
                {sellToCustomerName && <tr>
                  <td colSpan={3}>{sellToCustomerName}</td>
                </tr>}
              </Col>

              <Col span={12}>
                {billToCustomerCode && <tr>
                  <td><b>Customer</b></td>
                </tr>}
                {billToCustomerCode && <tr>
                  <td>{billToCustomerCode}</td>
                </tr>}
                {billToCustomerName && <tr>
                  <td>{billToCustomerName}</td>
                </tr>}
              </Col>
            </Row>

            <Row style={{ lineHeight: '25px', width: '635px', border: '1px solid #efefef', borderRadius: 5, marginTop: 10 }}>
              <Row style={{ padding: '2px 10px', fontWeight: 600 }}>
                <Col span={2} style={{ fontSize: 12 }}>No</Col>
                <Col span={3} style={{ fontSize: 12 }}>Code</Col>
                <Col span={9} style={{ fontSize: 12 }}>Description</Col>
                <Col span={2} style={{ fontSize: 12, textAlign: 'right' }}>Price</Col>
                <Col span={2} style={{ fontSize: 12, textAlign: 'right' }}>Qty</Col>
                <Col span={3} style={{ fontSize: 12, textAlign: 'right' }}>Discount</Col>
                <Col span={3} style={{ fontSize: 12, textAlign: 'right' }}>Line Total</Col>
              </Row>
              {salesLines && salesLines.length > 0 && this.generateSalesLines(salesLines)}
            </Row>

            <Row style={{ lineHeight: '25px', width: '635px' }}>
              <Col span={12}>
                <Row style={{ lineHeight: '25px', width: '312px', border: '1px solid #efefef', borderRadius: 5, marginTop: 10, padding: '2px 10px' }}>
                  <img style={{ height: '128px', width: '312px' }} src={Api.files.download.url(order.links[0])} alt='Signature' />
                </Row>
              </Col>

              <Col span={12}>
                <Row style={{ lineHeight: '25px', width: '312px', borderRadius: 5, marginTop: 10, marginLeft: 4 }}>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <h4>Gross Amount</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <h4>{format(totalAmount, currencyFormat)}</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <h4>Discount</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <h4>{format(totalDiscountAmount, currencyFormat)}</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <h4>Tax</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <h4>{format((amountIncludingVat - (totalAmount + totalDiscountAmount)), currencyFormat)}</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <h4>Net Amount</h4>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <h4>{format(amountIncludingVat, currencyFormat)}</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div id={'test'} />
          </div>
        </Row>
      </Row>
    )
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default OrderExport;