import React, { Component } from 'react';
import { Form, Row, Col } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import { EnglishTextArea as TextArea } from '../../common/EnglishInput';
import AllocatedDetails from '../../Receipt/component/AllocatedDetails';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class OrderFooter extends Component {

  render() {
    const { enableDeallocate, onDataAllocated } = this.props;
    const { No, Amount, AmountIncluVAT, DisAmount, DisPersentage, Remarks, AvgDisPersentage, SettledAmount, PDCAmount, UnconfirmedNonPDC, RemAmount, NetRemAmount } = this.props.order;
    const inludeVatAmount = AmountIncluVAT && AmountIncluVAT.replace(/,/g, '');
    const discount = DisAmount && DisAmount.replace(/,/g, '');
    const grossAmount = parseFloat(inludeVatAmount) + parseFloat(discount);

    return (
      <div className='promate-form-header'>
        <Form>
          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Col span={24}>
              <Col lg={12} md={12} sm={0} xs={0}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <p>{Remarks}</p>
                </FormItem>
                <Col xs={0} sm={0} md={24} lg={24}>
                  <AllocatedDetails docType={"2"} docNo={No} isMobileView={false} enableDeallocate={enableDeallocate} onDataAllocated={onDataAllocated} />
                </Col>

              </Col>
              <Col lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Gross Amount</div>
                  <div style={{ fontSize: "large" }}>{format(grossAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Discount ({AvgDisPersentage}%) </div>
                  <div style={{ fontSize: "large" }}>{format(DisAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>Net Amount</div>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>{format(AmountIncluVAT, currencyFormat)}</div>
                </Col>
                <br></br>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Settled Amount</div>
                  <div style={{ fontSize: "large" }}>{format(SettledAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>Remaining Outstanding</div>
                  <div style={{ fontSize: "large" }}>{format(RemAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large" }}>PDC Balance</div>
                  <div style={{ fontSize: "large" }}>{format(PDCAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>Net Outstanding</div>
                  <div style={{ fontSize: "large", fontWeight: 800 }}>{format(NetRemAmount, currencyFormat)}</div>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <div style={{ fontSize: "medium", color: 'dodgerblue' }}>Other Payments(Posting Pending)</div>
                  <div style={{ fontSize: "medium", color: 'dodgerblue' }}>{format(UnconfirmedNonPDC, currencyFormat)}</div>
                </Col>

              </Col>
              <Col lg={0} md={0} sm={24} xs={24}>
                <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
                  <p>{Remarks}</p>
                </FormItem>
                <Col lg={0} md={0} sm={24} xs={24}>
                  <AllocatedDetails docType={"2"} docNo={No} isMobileView={true} enableDeallocate={enableDeallocate} onDataAllocated={onDataAllocated} />
                </Col>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default OrderFooter;