import React, { Component } from 'react'
import { connect } from 'react-redux'
import PromatePageHeader from '../../common/PromatePageHeader';
import { withRouter } from "react-router-dom";
import { ProMateGreen } from '../../../const/Theme';
import RouteAchievementReport from './components/RouteAchievementReport';
import { Col, Row } from 'antd';
import RouteWiseDailyReport from './components/RouteWiseDailyReport';
import CustomerWiseVisitReport from './components/CustomerWiseVisitReport';
import RouteWiseProductiveReport from './components/RouteWiseProductiveReport';
import CustomerWiseDiscountReport from './components/CustomerWiseDiscountReport';
import DailyCategoryReport from './components/DailyCategoryReport';


export class GenerateReport extends Component {
  render() {
    return (
      <PromatePageHeader
        title="Generate Report"
        style={styles.pageHeader}
        onBack={() => this.props.history.goBack()}
        showCallButton={false}
      >
        <div style={{ padding: "16px" }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <RouteWiseProductiveReport />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <DailyCategoryReport />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <RouteAchievementReport />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <RouteWiseDailyReport />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <CustomerWiseDiscountReport />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <CustomerWiseVisitReport />
            </Col>
          </Row>
        </div>
      </PromatePageHeader>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateReport))

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  }
}