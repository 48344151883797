import React, { Component } from 'react';
import { Button, Upload, Icon, message } from 'antd';
import BetterImg from '../common/BetterImage';

const defaultImagePath = "/placeholder-image.png";

export default class ImageDisplayContainer extends Component {

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!', 10);
    }
    const isInLimit = file.size / 1024 / 1024 < 2;
    if (!isInLimit) {
      message.error('Image must be smaller than 2MB!', 10);
    }
    return isJpgOrPng && isInLimit;
  }

  onSelectImage = info => {
    this.props.onSelectImage(info.file.originFileObj)
  }

  render() {
    const { fileUrl, onClickTakePicture, customerSigned } = this.props

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid #dddddd', overflow: 'hidden' }}>
          <div>
            <BetterImg
              src={fileUrl ? fileUrl : defaultImagePath}
              height={500}
              resizeMode="cover"
            />
          </div>
        </div>
        {!customerSigned && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <Upload
            name={'file'}
            showUploadList={false}
            beforeUpload={this.beforeUpload}
            onChange={this.onSelectImage}>
            <Button><Icon type="upload" /> Attach Image </Button>
          </Upload>
          {/* <Button style={{ marginLeft: 5 }} type="primary" icon="camera" onClick={onClickTakePicture}>Take a photo</Button> */}
        </div>}
      </div>
    );
  }
}
