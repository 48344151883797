import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Spin, Col, Timeline, Comment, Card, Button } from "antd";
import { fetchCustomers } from "../../../redux/actions/customers";
import { fetchStartCallReasons, addCall, fetchEndCallStatuses, endCall, fetchCall, fetchActiveCall } from "../../../redux/actions/calls";
import { fetchRoutes } from "../../../redux/actions/routes";
import Details from "./Details";
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid/v1';
import moment from 'moment';
import CallForm from './Form';
import { getAuthData, getCall, getStartCallReasons, getCallEndStatuses, getLoggedInUser, isFetchingCall, getCallLog, getDropdownCustomers, getNetworkModeDetector } from '../../../redux/reducers';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import { inCall } from "../../../redux/actions/auth";
import { GeoMap } from "../../common/GeoMap";
import { CALLS } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchDropdownCustomers } from '../../../redux/actions/customers';
import queryString from 'query-string';
import { dateTimeFormatFilterUpdate, dateTimeFormatView } from "../../../const/Formats";
import { isValidTimeForWork } from '../../common/TimeStamp';

const defaultCall = {
  SessionID: undefined,
  Status: undefined,
  CustNo: undefined,
  CustName: undefined,
  UserCode: undefined,
  UserName: undefined,
  RouteCode: undefined,
  StartReasonCode: undefined,
  StartReasonDescrip: undefined,
  Remarks: undefined,
  StartDateTime: moment(new Date()).format(dateTimeFormatFilterUpdate),
  StartMapLoc: undefined
}

class Container extends Component {
  state = {
    loading: false,
    call: undefined,
    expenseByArr: undefined,
    descriptionError: undefined,
    contactNoError: undefined,
    addressError: undefined,
    faxNoError: undefined,
    emailError: undefined,
    contactPersonError: undefined,
    showBlockReasonDialog: false,
    visible: false,
    blockedReason: ""
  }

  async componentDidMount() {
    const { callId, fetchStartCallReasons, authData, fetchEndCallStatuses, fetchCall, fetchDropdownCustomers, customerParamId } = this.props;

    fetchRoutes({ UserCode: authData.userId });
    fetchStartCallReasons();
    fetchEndCallStatuses();
    await fetchDropdownCustomers({ UserCode: authData.userId });

    if (callId !== "new") {
      fetchCall(callId);
      this.loadCall(this.props);
    } else {
      const customer = this.getCustomerById(customerParamId);
      if (customer) {
        customer && this.setState({ call: { ...defaultCall, CustNo: customerParamId, CustName: customer.Name } }, () => {
          this.setDeviceMapLocation();
        });
      } else {
        this.setState({ call: { ...defaultCall, CustNo: customerParamId } }, () => {
          this.setDeviceMapLocation();
        });
      }

      const startReason = this.getStartReasonById("CS-0001");
      this.setState({ call: { ...this.state.call, StartReasonCode: startReason.Code, StartReasonDescrip: startReason.Description }, startReasonCodeError: undefined });
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.callId !== nextProps.callId) {
      this.setState({ call: { ...nextProps.call } }, this.setDeviceMapLocation)
    }

    this.loadCall(nextProps);
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  setDeviceMapLocation = () => {
    const { call } = this.state;

    if (!call.No && !call.StartMapLoc) {
      this.removeLocationListener = addListener(this.handleNewStartLocation);
    }

    if (call.No && (!call.EndMapLoc || (call.EndMapLoc && call.EndMapLoc === ''))) {
      this.removeLocationListener = addListener(this.handleNewEndLocation);
    }
  }

  handleNewStartLocation = (location, error) => {
    if (!this.state.call.StartMapLoc) {
      removeListener(this.handleNewStartLocation);
      if (!!location) {
        this.setState({
          call: {
            ...this.state.call,
            StartMapLoc: formatGeoLocation(location.latitude, location.longitude)
          },
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  handleNewEndLocation = (location, error) => {
    const { call } = this.state;
    if (!call.EndMapLoc || (call.EndMapLoc && call.EndMapLoc === '')) {
      removeListener(this.handleNewEndLocation);
      if (!!location) {
        this.setState({
          call: {
            ...this.state.call,
            EndMapLoc: formatGeoLocation(location.latitude, location.longitude)
          },
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  loadCall = newProps => {
    const propCallId = this.props.call ? this.props.call.No : undefined;
    const stateCallId = this.state.call ? this.state.call.No : undefined;

    if (propCallId !== stateCallId) {
      this.setState({ call: { ...this.props.call } }, () => {
        this.setDeviceMapLocation();
      });
    }
  }

  onInputChange = (field, value, errorKey) => {
    switch (field) {
      case 'CustNo': {
        const customer = this.getCustomerById(value);
        customer && this.setState({ call: { ...this.state.call, [field]: value, CustName: customer.Name, RouteCode: customer.RouteCode }, [errorKey]: undefined });
        break;
      }
      case 'StartReasonCode': {
        const startReason = this.getStartReasonById(value);
        startReason && this.setState({ call: { ...this.state.call, [field]: value, StartReasonDescrip: startReason.Description }, [errorKey]: undefined });
        break;
      }
      case 'EndStatusCode': {
        const endStatus = this.getCallEndStatusById(value);
        endStatus && this.setState({ call: { ...this.state.call, [field]: value, EndStatusDescrip: endStatus.Description }, [errorKey]: undefined });
        break;
      }
      default:
        this.setState({ call: { ...this.state.call, [field]: value }, [errorKey]: undefined });
    }
  }

  checkCustomerRequirement = () => {
    const { call } = this.state;
    if (!call) return true;

    const startReason = this.getStartReasonById(call.StartReasonCode);
    return !startReason || !startReason.CallType || startReason.CallType !== 'Customer Create';
  }

  getCustomerById = id => {
    const { customers } = this.props;

    return customers.find(customer => customer.No === id);
  }

  getStartReasonById = id => {
    const { startCallReasons } = this.props;

    return startCallReasons.find(reason => reason.Code === id);
  }

  getCallEndStatusById = id => {
    const { callEndStatuses } = this.props;

    return callEndStatuses.find(status => status.Code === id);
  }

  onSaveCall = async () => {
    const { toastManager, fetchActiveCall, authData } = this.props;
    if (!locationEnabled) {
      toastManager.add('You cannot start/end a call without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) return;

    if (!this.props.isSystemOffline) {
      const res = await isValidTimeForWork();
      if (!res.isValid && this.props.callId == 'new') {
        toastManager.add(`You cannot start/end a call between ${res.end}H - ${res.start}H`, { autoDismiss: true, appearance: 'error' });
        return;
      }
    }

    if (this.props.callId !== 'new') {
      const { call } = this.state;
      const { endCall } = this.props;

      const updatedCall = {
        ...call,
        SessionID: uuid(),
        Status: 'Close',
        StartDateTime: moment(call.StartDateTime).format(dateTimeFormatFilterUpdate),
        EndDateTime: moment().utc().format(dateTimeFormatFilterUpdate),
      }

      this.setState({ loading: true });
      endCall(updatedCall).then(response => {
        fetchActiveCall(authData.userId);
        if (!response.error) {
          this.setState({ loading: false });
          toastManager.add('Call ended.', { autoDismiss: true, appearance: 'success' });
          this.props.history.goBack();
        } else {
          this.setState({ loading: false });
          toastManager.add('Failed to end call', { autoDismiss: true, appearance: 'error' });
        }
      })
    } else {
      const { call } = this.state;
      const { authData: { userId, displayName }, addCall, } = this.props;

      const customer = this.getCustomerById(call.CustNo);

      const newCall = {
        ...call,
        SessionID: uuid(),
        Status: 'Open',
        UserCode: userId,
        UserName: displayName,
        StartDateTime: moment().utc().format(dateTimeFormatFilterUpdate),
        RouteCode: customer ? customer.RouteCode : undefined
      }

      this.setState({ loading: true });

      addCall(newCall, this.props).then(response => {
        fetchActiveCall(authData.userId);
        this.setState({ loading: false });
        if (response.error == "offline") {
          this.props.history.goBack();
        } else if (!response.error) {
          // toastManager.add('Call started.', { autoDismiss: true, appearance: 'success' });
          // this.props.history.goBack();
        } else if (response.error) {
          toastManager.add(response?.error, { autoDismiss: true, appearance: 'error' });
        } else {
          toastManager.add('Failed to start call', { autoDismiss: true, appearance: 'error' });
        }
      })

    }
  }

  validateForm = () => {
    const { call } = this.state;
    const { No, StartReasonCode, CustNo, EndStatusCode } = call;

    const customerRequired = this.checkCustomerRequirement();

    let startReasonCodeError = undefined;
    let custNoError = undefined;
    let endStatusCodeError = undefined;

    if (!this.isValidValue(StartReasonCode)) startReasonCodeError = 'Start reason is required.'
    if (customerRequired && !this.isValidValue(CustNo)) custNoError = 'Customer is required.'
    if (No && !this.isValidValue(EndStatusCode)) endStatusCodeError = 'End reason is required.'

    this.setState({ startReasonCodeError, custNoError, endStatusCodeError })

    return !startReasonCodeError && !custNoError && !endStatusCodeError;
  }

  isValidValue = value => value && value.trim().length > 0;

  onClickTransaction = entry => {
    const { DocuNo, TransactionType } = entry;
    switch (TransactionType) {
      case 'Customer':
        this.props.history.push({ pathname: '/customers/' + DocuNo });
        break;
    }
  }

  renderLogEntry = entry =>
    <Timeline.Item
      onClick={() => this.onClickTransaction(entry)}
      style={{ cursor: 'pointer' }}>
      <Comment
        author={<a><h3>{entry.EventType} {entry.TransactionType}</h3></a>}
        content={
          <div>
            <p>
              {entry.EventDescrip}<br />
              {entry.CustName}<br />
              {entry.CustCode}
            </p>
          </div>
        }
        datetime={moment(entry.CreatedDateTime).add(330, 'minutes').format(dateTimeFormatView)}
      />
    </Timeline.Item>

  renderActions = () => {
    const { callId, call, onConfirmDelete, loggedInUser: { permissions = [] } } = this.props;
    const newCallId = callId ? callId : "new";
    const enableCreate = permissions.includes(CALLS.CREATE);
    const enableUpdate = permissions.includes(CALLS.UPDATE);

    if (newCallId === "new") {
      if (enableCreate) {
        return (<Col style={{ float: 'right', marginRight: 10, marginTop: 10, marginBottom: 10 }}>
          {<Button type="primary" onClick={this.onSaveCall}>Start Call</Button>}
        </Col>
        )
      }
    } else {
      return (
        <Col style={{ float: 'right', marginRight: 10, marginTop: 10, marginBottom: 10 }}>
          {call.Status == "Open" && enableUpdate && <Button type="danger" onClick={this.onSaveCall}>End Call</Button>}
        </Col>)
    }
  }

  render() {
    const { fetchingCall, log } = this.props;
    const { call, loading, startReasonCodeError, custNoError, endStatusCodeError } = this.state;
    const customerRequired = this.checkCustomerRequirement();

    if (!call) {
      if (fetchingCall)
        return (
          <Spin spinning={true} tip={"Loading..."}>
            <div style={{ height: '100px', width: '100%' }} />
          </Spin>
        );
      else
        return (
          <div style={{
            height: '100px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            Cannot find the call.
          </div>
        );
    }

    return (
      <Spin spinning={loading} tip={"Loading..."}>

        <Row>
          {call && this.props.callId != "new" && <Details
            callId={this.props.callId}
            call={call}
            onSaveCall={this.onSaveCall}
            onConfirmDelete={this.onConfirmDelete} />}
          <div>
            <CallForm
              callId={this.props.callId}
              startReasonCodeError={startReasonCodeError}
              custNoError={custNoError}
              endStatusCodeError={endStatusCodeError}
              onInputChange={this.onInputChange}

              call={call}
              customerRequired={customerRequired}
            />
            {this.renderActions()}
          </div>
        </Row>
        {this.props.callId != "new" && log && log.length > 0 && <Card title={"Call Log"}>
          <Row gutter={15}>
            <Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                {log.map(entries => this.renderLogEntry(entries))}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <GeoMap
                  isMarkerShown={true}
                  editable={false}
                  location={{ lat: 6.9271, lng: 79.8612 }}
                  height={400} />
              </Col>
            </Col>
          </Row>
        </Card>}

      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { callId } = ownProps
  let customerParamId = undefined;
  if (ownProps.location && ownProps.location.search) {
    const params = queryString.parse(ownProps.location.search);
    customerParamId = params.customer_id;
  }
  const call = getCall(state, callId);
  const authData = getAuthData(state);
  const customers = getDropdownCustomers(state);
  const startCallReasons = getStartCallReasons(state);
  const callEndStatuses = getCallEndStatuses(state);
  const loggedInUser = getLoggedInUser(state);
  const fetchingCall = isFetchingCall(state, callId);
  const log = getCallLog(state, callId);

  return { call, authData, customers, startCallReasons, callEndStatuses, loggedInUser, fetchingCall, log, customerParamId, isSystemOffline: getNetworkModeDetector(state), };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchCustomers,
  fetchStartCallReasons,
  fetchRoutes,
  addCall,
  inCall,
  fetchEndCallStatuses,
  endCall,
  fetchCall,
  fetchActiveCall,
  fetchDropdownCustomers
})(Container)));