import { ACTION_TYPES } from '../reducers/failedOrders';
import { getFailedSalesHeaders, getFailedSalesLines } from '../../api/local'

export const fetchFailedSalesHeadersFromLocalAPI = (filter) => async dispatch => {
    dispatch({ type: ACTION_TYPES.START_FETCH_FAILED_HEADERS, headers: [] });
    return getFailedSalesHeaders(filter).then(response => {
        dispatch({ type: ACTION_TYPES.FETCH_FAILED_HEADERS, headers: response });
        return response;
    }).catch(error => {
        return { error }
    });
}

export const fetchFailedSalesLinesFromLocalAPI = (headerNo) => async dispatch => {
    dispatch({ type: ACTION_TYPES.START_FETCH_FAILED_LINES, lines: [] });
    return getFailedSalesLines(headerNo).then(response => {
        dispatch({ type: ACTION_TYPES.FETCH_FAILED_LINES, lines: response });
        return response;
    }).catch(error => {
        return { error }
    });
}