import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from './redux/reducers';
import { loadState, saveState } from './localStorage';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

export const cleanState = state => {
  if (state) {
    state = { auth: state.auth };
  }
  return state;
}

const configureStore = () => {
  const middlewares = [thunk];
  if (process.env.NODE_ENV !== 'production') {
    // middlewares.push();
    // middlewares.push(logger);
  }

  const store = createStore(
    reducers,
    cleanState(loadState()),
    compose(
      composeWithDevTools(applyMiddleware(...middlewares))
    )
  );

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
};

export default configureStore();
