import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getCustomerCategoryByCustomerId, getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import { RECEIPTS, SALES_ORDERS } from "../../const/Permissions";

class OrderCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
      case 'Completed':
        return 'green';
      case 'Reversed':
        return 'red';
      default:
        return 'orange';
    }
  }

  getCustStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { receipt, history, customerCategory, loggedInUser: { permissions = [] } } = this.props;
    const { No, DocDate, CusCode, CusName, CustStatus, PayAmount, CollectType, CSRUserName, OutletCategoryCode, RouteName, OrderStatus } = receipt;
    const customerStatus = CustStatus === "" ? 'Active' : CustStatus
    return (
      <Row onClick={() => (permissions.includes(RECEIPTS.VIEW) || permissions.includes(RECEIPTS.UPDATE)) && history.push(this.props.match.url + "/" + encodeURIComponent(No))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={2} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.receiptNo}>{No}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.receiptDate}>{moment(DocDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span>{CollectType}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}>{CusCode}</p>
          </Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span>{CusName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getCustStatusColor(customerStatus)}>{customerStatus}</Tag>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span>{OutletCategoryCode}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span>{RouteName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag>
          </Col>
          <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>
            <span>{CSRUserName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={PayAmount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col>

          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>
            <span style={{ overflowWrap: 'break-word' }}>{No}</span>
          </Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'center', ...styles.normalBold }}>
            <span>{moment(DocDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'right' }}>
            <Tag style={{ ...styles.status }} color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{CusName}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(customerStatus)}>{customerStatus}</Tag>
          </Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>
            <span>{CollectType}</span>
          </Col>
          <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={PayAmount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={{ ...styles.price, ...styles.bigBold }}
              decimalScale={2} />
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  largeBold: {
    fontSize: 18,
    fontWeight: 700
  },
  bigBold: {
    fontSize: 16,
    fontWeight: 700
  },
  normalBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  receiptNo: {
    fontSize: 13,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10
  },
  receiptDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: -10
  },
  price: {
    fontSize: 18,
    fontWeight: 600
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const { receipt } = ownProps;

  const customerCategory = getCustomerCategoryByCustomerId(state, receipt.CusCode);
  const loggedInUser = getLoggedInUser(state);

  return { customerCategory, loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(OrderCard));