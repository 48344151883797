import * as fromApi from '../../api/generic';

export const FETCH_ALL_ITEMS = 'FETCH_ALL_ITEMS';

export const fetchAllItems = (filter) => dispatch => {
  return fromApi.get('Item', filter).then(response => {
    const { data, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_ALL_ITEMS, AllItems: data });

    return response;
  }).catch(error => {
    return { error }
  })
};