import React, { Component } from 'react';
import { connect } from "react-redux";
import queryString from 'query-string';
import { fetchPurchaseHeaders, addPurchaseHeader, updatePurchaseHeader, deletePurchaseHeader, fetchPurchaseCreditMemoReasons, fetchPurchaseRetrunReasons } from "../../../redux/actions/purchasingHeaders";
import { fetchPurchaseLines } from "../../../redux/actions/purchaseLines";
import { fetchInventory } from "../../../redux/actions/inventory";
import { fetchDistributors } from "../../../redux/actions/distributors";
import { fetchPaymentMethods, fetchPaymentMethodsForCustomer } from "../../../redux/actions/paymentMethods";
import { fetchPaymentTerms, fetchPaymentTermsForCustomer } from "../../../redux/actions/paymentTerms";
import { Row, Spin, Col, Button, Popconfirm } from "antd";
import OrderHeader from './Header';
import ItemTable from "./ItemTable";
import ItemTableMobile from "./ItemTableMobile";
import OrderFooter from './Footer'
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import uuid from 'uuid/v1';
import { addListener, removeListener, locationEnabled } from '../../../utils/Location';
import { getAuthData, getPurchaseHeadersByTypeAndNo, DOC_TYPE, getCustomer, getLocations, getPurchaseLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getLoggedInUser, getDistributor, getTerritories } from '../../../redux/reducers';
import CallStatusValidateButton from '../../common/CallStatusValidateButton';
import { generateReport } from '../../../api/reports';
import Api from '../../../api/consts';
import { CREDIT_NOTES, PURCHASING_ORDERS, PURCHASE_RETURNS } from "../../../const/Permissions";
import { formatGeoLocation } from "../../../utils/Formats";
import { fetchWarehouseLocations } from '../../../redux/actions/warehouses';
import { fetchTerritories } from '../../../redux/actions/territories';
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';
import { fetchPurchasingInvoiceHeaders } from '../../../redux/actions/purchasingInvoiceHeaders';

export const defaultOrder = {
  SessionID: uuid(),
  DocType: undefined,
  SellToCusNo: undefined,
  SellToCusName: undefined,
  BillToCusNo: undefined,
  BillToCusName: undefined,
  LocCode: undefined,
  VendorNo: undefined,
  //OrderDate: undefined,
  OrderStatus: "Open",
  PayMethodCode: undefined,
  PayTermCode: undefined,
  DistributorCode: undefined,
  DistributorName: undefined,
  MapLocation: undefined,
  OrderDate: moment(new Date()).format(dateFormatUpdate),
  Amount: 0.0,
  AmountIncluVAT: 0.0,
  DisPersentage: 0.0,
  DisAmount: 0.0,
  TerritoryCode: undefined,
  TerritoryName: undefined

}

class Container extends Component {
  state = {
    loading: false,
    order: { ...defaultOrder },
    locCodeError: undefined,
    vendorNoError: undefined,
    returnReasonError: undefined,
    sharing: false
  }

  componentDidMount() {
    const { orderId, docType, distributorId } = this.props.match.params
    //const { distributorId, authData } = this.props
    const newOrder = orderId === 'new';

    //this.props.fetchPaymentMethods();
    this.props.fetchPaymentTerms();
    this.props.fetchWarehouseLocations({ UserCode: distributorId });
    this.props.fetchTerritories({ UserCode: distributorId });
    this.props.fetchPurchaseRetrunReasons();
    this.props.fetchDistributors(this.props.authData);
    //this.props.fetchSalesCreditMemoReasons();
    if (!newOrder) {
      this.setState({ loading: true });
      this.props.fetchPurchaseLines({ DocNo: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
      this.props.fetchPurchaseHeaders({ No: decodeURIComponent(orderId), DocType: DOC_TYPE_ID_BY_DEFINITION[docType] }).then(({ data, error }) => {
        if (!error) {
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
    } else {
      this.setData(this.props);
    }
  }

  resetToDefault(props) {
    const { distributorId } = this.props.match.params
    this.props.fetchDistributors(this.props.authData);
    //this.props.fetchPaymentMethods();
    this.props.fetchPaymentTerms();
    this.props.fetchWarehouseLocations({ UserCode: distributorId });
    this.props.fetchTerritories({ UserCode: distributorId });
    this.props.fetchPurchaseRetrunReasons();

    this.setState({
      order: {
        ...defaultOrder
      }
    }, this.setDeviceMapLocation)

  }

  componentWillReceiveProps = nextProps => {
    const { orderId, docType, invoiceNo } = nextProps.match.params;
    if (this.props.order && !nextProps.order) {
      if (orderId === "new" && docType === "ReturnOrder" && invoiceNo !== undefined) {
        this.resetToDefault(nextProps);
      } else {
        window.history.back();
      }
      // The order is deleted.
    } else if (nextProps && nextProps.order) {
      this.setState({ order: { ...nextProps.order } });
    } else {
      this.setData(this.props);
    }
  }

  setData = data => {
    const { distributor } = data;

    if (!distributor) return;

    this.setState({
      order: {
        ...this.state.order,
        //SellToCusNo: customer.No,
        //SellToCusName: customer.Name + ' ' + customer.Name2
      }
    }, this.setDeviceMapLocation)
  }

  setDeviceMapLocation = () => {
    if (!this.state.MapLocation) {
      this.removeLocationListener = addListener(this.handleNewLocation);
    }
  }

  handleNewLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  submitPurchaseHeader = () => {
    const { match, toastManager } = this.props;
    const { docType = '' } = match.params;
    let title = 'Order';
    switch (docType) {
      case 'ReturnOrder':
        title = 'Purchase Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Purchase Order';
        break;
    }

    if (!locationEnabled) {
      toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    if (!this.validateForm()) return;

    const newPurchaseHeader = this.createNewPurchaseHeaderRequest();

    this.setState({ loading: true })
    this.sendCreatePurchaseHeaderRequest(newPurchaseHeader).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
        this.props.history.replace("/distributors/" + match.params.distributorId + "/purchasing/" + docType + "/" + encodeURIComponent(response.data.No));
      } else {
        toastManager.add('Could not create the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  validateForm = () => {
    const { docType } = this.props.match.params;
    const { order } = this.state;
    const { LocCode, DocReasonCode, PayMethodCode, PayTermCode, TerritoryCode, VendorNo ,SellToCusNo } = order;

    let locCodeError = undefined;
    let vendorNoError = undefined;
    let payMethodCodeError = undefined;
    let payTermCodeError = undefined;
    let returnReasonError = undefined;
    let territoryCodeError = undefined;

    if (!this.isValidValue(LocCode)) locCodeError = 'Location is required.';
    if (!this.isValidValue(SellToCusNo)) vendorNoError = 'Vendor is required.';
    if (!this.isValidValue(TerritoryCode)) territoryCodeError = 'Territory is required.'
    if (docType === 'Order') {
      //if (!this.isValidValue(PayMethodCode)) payMethodCodeError = 'Payment method is required.';
      //if (!this.isValidValue(PayTermCode)) payTermCodeError = 'Payment term is required.';
    }
    if (docType === 'CreditMemo' && !this.isValidValue(DocReasonCode)) returnReasonError = 'Type is required.';
    if (docType === 'ReturnOrder' && !this.isValidValue(DocReasonCode)) returnReasonError = 'Return Type is required.';
    this.setState({ locCodeError, payMethodCodeError, payTermCodeError, returnReasonError, territoryCodeError,vendorNoError })

    if (docType === 'CreditMemo') {
      return !payMethodCodeError && !payTermCodeError && !returnReasonError;
    } else {
      return !locCodeError && !payMethodCodeError && !payTermCodeError && !returnReasonError && !territoryCodeError && !vendorNoError;
    }

  }

  isValidValue = value => value && value.trim().length > 0;

  createNewPurchaseHeaderRequest = () => {
    const { order } = this.state;
    const { authData: { userId }, match, distributor } = this.props;
    const { docType } = match.params;

    return {
      ...order,
      SessionID: uuid(),
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      OrderDate: moment(new Date()).format(dateFormatUpdate), //TODO
      MapLocation: this.state.MapLocation,
      UserCode: userId,
      DistributorCode: distributor.Code,
      PayTermCode: distributor.PayTermCode
    }
  }

  sendCreatePurchaseHeaderRequest = purchaseHeader => {
    return this.props.addPurchaseHeader(purchaseHeader);
  }

  sendUpdatePurchaseHeaderRequest = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    const { docType } = match.params;
    let title = 'Order';
    switch (docType) {
      case 'ReturnOrder':
        title = 'Purchase Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Purchase Order';
        break;
    }

    this.setState({ loading: true })
    this.props.updatePurchaseHeader({ ...this.state.order, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' updated.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not update the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  // TODO
  shareOrder = () => {
    this.setState({ sharing: true });

    const { order, toastManager, match } = this.props;
    const { docType } = match.params;
    const reportRequest = {
      DocType: DOC_TYPE_ID_BY_DEFINITION[docType],
      No: order.No
    };

    generateReport('purchase', reportRequest).then(response => {
      if (!response.error) {
        window.open(Api.files.download.url(response.url), '_blank');
      } else {
        toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    }).finally(() => this.setState({ sharing: false }));
  }

  getScrollAreaHeight = () => {
    const header = document.getElementsByClassName('ant-page-header');
    const headerHeight = (header && header[0]) ? header[0].clientHeight : 115;
    return window.innerHeight - (headerHeight);
  }

  navigateToInventoryItemListView = () => {
    const { match, toastManager } = this.props;
    const { orderId, docType, invoiceNo } = match.params;
    let title = 'Purcahse Order';
    switch (docType) {
      case 'ReturnOrder':
        title = 'Purchase Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Purcahse  Order';
        break;
    }

    if (orderId === 'new') {
      if (!locationEnabled) {
        toastManager.add('You can not continue this transaction without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
        return;
      }

      if (!this.validateForm()) return;

      const newPurcahseHeader = this.createNewPurchaseHeaderRequest();

      this.setState({ loading: true })
      this.sendCreatePurchaseHeaderRequest(newPurcahseHeader).then(response => {
        if (!response.error) {
          toastManager.add('Successfully created a new ' + title + '.', { autoDismiss: true, appearance: 'success' });
          const order = { orderNo: encodeURIComponent(response.data.No), discountPercentage: this.state.order.discountPercentage, ...response.data };

          if (invoiceNo && docType === 'ReturnOrder') {
            this.props.history.replace({ pathname: "/distributors/" + match.params.distributorId + "/purchasing/" + docType + "/" + encodeURIComponent(response.data.No) + "/invoice/" + invoiceNo });
            this.props.history.push({ pathname: "/purchasing/" + docType + "/" + encodeURIComponent(response.data.No) + "/purchaseLines/new", state: { order: order, id: response.data.No, invoiceNo: decodeURIComponent(invoiceNo) } });
          } else {
            this.props.history.replace({ pathname: "/distributors/" + match.params.distributorId + "/purchasing/" + docType + "/" + encodeURIComponent(response.data.No) });
            if (docType === 'ReturnOrder') {
              this.props.history.push({ pathname: '/inventoryLotWiseItems', state: { resource: "purchaseReturn", order: order, id: response.data.No } });
            } else if (docType === 'Order') {
              this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "purchasingOrders", order: order, id: response.data.No } });
            }
          }
        } else {
          this.setState({ loading: false })
          toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
        }
      });
    } else {
      const order = this.state.order;
      this.setState({ loading: true });
      setTimeout(() => {
        if (invoiceNo && docType === 'ReturnOrder') {
          this.props.history.push({ pathname: "/purchasing/" + docType + "/" + encodeURIComponent(orderId) + "/purchaseLines/new", state: { order: order, id: orderId, invoiceNo: decodeURIComponent(invoiceNo) } });
        }
        else {
          if (docType === 'ReturnOrder') {
            this.props.history.push({ pathname: '/inventoryLotWiseItems', state: { resource: "purchaseReturn", order: order, id: orderId } });
          } else if (docType === 'Order') {
            this.props.history.push({ pathname: '/inventoryItemList', state: { resource: "purchasingOrders", order: order, id: orderId } });
          }
        }
      }, 100);
    }
  }

  navigateToConfirmOrder = () => {
    const { orderId, docType } = this.props.match.params
    this.props.history.push({ pathname: '/purchasing/' + docType + '/' + orderId + '/confirm', state: { order: this.state.order } })
  }


  onDeleteOrder = () => {
    const { toastManager, match, authData: { userId } } = this.props;
    const { docType } = match.params;
    let title = 'Order';
    switch (docType) {
      case 'ReturnOrder':
        title = 'Purchase Return';
        break;
      case 'CreditMemo':
        title = 'Credit Note';
        break;
      case 'Order':
      default:
        title = 'Purchase Order';
        break;
    }
    this.setState({ loading: true })
    this.props.deletePurchaseHeader({ ...this.state.order, UserCode: userId }).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully ' + title + ' deleted.', { autoDismiss: true, appearance: 'success' });
      } else {
        toastManager.add('Could not delete the ' + title + '.' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }


  onReceiveOrder = () => {
    const { updatePurchaseHeader, toastManager, order, authData: { userId } } = this.props;
    const updateOrderData = { ...order, OrderStatus: 'Received', UserCode: userId };

    updatePurchaseHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Invoice generated.', { autoDismiss: true, appearance: 'success' });
        //window.history.back();
      } else {
        toastManager.add('Could not generate the invoice. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onReceiveAndReturnOrder = () => {
    const { updatePurchaseHeader, toastManager, order, authData: { userId }, match, fetchPurchasingInvoiceHeaders } = this.props;
    const updateOrderData = { ...order, OrderStatus: 'Received', UserCode: userId };
    updatePurchaseHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Invoice generated.', { autoDismiss: true, appearance: 'success' });
        fetchPurchasingInvoiceHeaders({ docType: "Invoice", UserCode: userId, OrderNo: response.No }).then(res => {
          if (!res.error) {
            if (res.data && res.data.length > 0) {
              this.props.history.push("/distributors/" + order.DistributorCode + "/purchasing/" + 'ReturnOrder' + "/new/invoice/" + encodeURIComponent(res.data[0].No));
            }
          }
        });
      } else {
        toastManager.add('Could not generate the invoice. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onConfirmApproved = () => {
    const { updatePurchaseHeader, toastManager, order, authData: { userId } } = this.props;
    const updateOrderData = { ...order, OrderStatus: '1', UserCode: userId };

    updatePurchaseHeader(updateOrderData).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Invoice generated.', { autoDismiss: true, appearance: 'success' });
        window.history.back();
      } else {
        toastManager.add('Could not generate the invoice. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    });
  }

  onInputChange = (field, value, errorKey) => {  
    const { order } = this.state;
    this.setState({
      order: { ...order, [field]: value },
      [errorKey]: undefined
    }, () => this.handleResetPayTermCode(field, value))
  }

  handleResetPayTermCode = (field, value) => {
    // const { order } = this.state;

    // if (field === 'PayMethodCode' && value === 'CASH') {
    //   this.setState({ order: { ...order, PayTermCode: undefined } })
    // }
  }

  getGenerateButtonLable = () => {
    const { match } = this.props;
    const { docType } = match.params
    let label = 'Generate Invoice';
    if (docType === 'ReturnOrder') {
      label = 'Generate Ret. Invoice';
    } else if (docType === 'CreditMemo') {
      label = 'Confirm';
    }

    return label;
  }

  getPermission = (docType, type, permissions) => {
    let enabled = false;
    if (type === 'CREATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.CREATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(PURCHASE_RETURNS.CREATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CREATE)
      }
    }
    else if (type === 'UPDATE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.UPDATE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(PURCHASE_RETURNS.UPDATE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.UPDATE)
      }
    }
    else if (type === 'DELETE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.DELETE)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(PURCHASE_RETURNS.DELETE)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.DELETE)
      }
    } else if (type === 'CONFIRM') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.CONFIRM)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(PURCHASE_RETURNS.CONFIRM)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.CONFIRM)
      }
    }
    else if (type === 'PRINT') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.PRINT)
      } else if (docType === 'ReturnOrder') {
        enabled = permissions.includes(PURCHASE_RETURNS.PRINT)
      } else if (docType === 'CreditMemo') {
        enabled = permissions.includes(CREDIT_NOTES.PRINT)
      }
    }
    else if (type === 'RECEIVE') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.RECEIVE)
      }
    }

    else if (type === 'RECEIVE_AND_RETURN') {
      if (docType === 'Order') {
        enabled = permissions.includes(PURCHASING_ORDERS.RECEIVE_AND_RETURN)
      }
    }

    return enabled;
  }

  renderActions = () => {
    const { match, authData: { roleCode }, order, purchaseLines, loggedInUser: { permissions = [] }, location, distributor } = this.props;
    const { OrderStatus } = order || {};
    const { orderId, docType, invoiceNo } = match.params
    const newOrder = orderId === 'new';
    const isLocationOwner = location && location.WarehouseOwner;
    const enableCreate = this.getPermission(docType, 'CREATE', permissions);
    const enableUpdate = this.getPermission(docType, 'UPDATE', permissions);
    const enableDelete = this.getPermission(docType, 'DELETE', permissions);
    const enableConfirm = this.getPermission(docType, 'CONFIRM', permissions);
    const enableGenerateInvoice = this.getPermission(docType, "GENERATE_INVOICE", permissions);
    const enablePrint = this.getPermission(docType, "PRINT", permissions);
    const enableReceive = this.getPermission(docType, "RECEIVE", permissions);
    const enableReceiveAndReturn = this.getPermission(docType, "RECEIVE_AND_RETURN", permissions);

    if (newOrder && enableCreate) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* create button hide when come with invoice no */}
        {!invoiceNo && <Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={this.submitPurchaseHeader}
          >
            <Button type="primary" >Create</Button>
          </Popconfirm>
        </Col>}
      </Row>
    } else {
      return <div>
        {
          (OrderStatus !== '' && OrderStatus !== 'Open') && enablePrint &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button
              loading={this.state.sharing}
              type="secondary"
              style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
              onClick={this.shareOrder}
            >Print & Share</Button>
          </Col>
        }

        {
          purchaseLines && purchaseLines.length > 0 && OrderStatus === 'Open' && enableConfirm &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button type="primary" onClick={this.navigateToConfirmOrder}>Confirm</Button>
          </Col>
        }

        {/* {
          Status === 'Customer Confirmed' && purchaseLines && purchaseLines.length > 0 &&
          enableGenerateInvoice &&
          isLocationOwner &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"

              cancelText="No"
              onConfirm={this.onConfirmApproved}
            >
              <CallStatusValidateButton type="primary">{this.getGenerateButtonLable()}</CallStatusValidateButton>
            </Popconfirm>
          </Col>
        } */}

        {
          (enableDelete) && OrderStatus === 'Open' && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }

        {
          (enableUpdate) && OrderStatus === 'Open' && <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Button type="primary" onClick={this.sendUpdatePurchaseHeaderRequest}>Update</Button>
          </Col>
        }
        {

          OrderStatus === 'Dispatched' && enableReceiveAndReturn &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onReceiveAndReturnOrder}>
              <Button type="primary">{"Receive & Return"}</Button>
            </Popconfirm>
          </Col>
        }
        {
          OrderStatus === 'Dispatched'
          &&
          enableReceive &&
          <Col style={{ marginTop: 10, marginLeft: 10, float: 'right' }}>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.onReceiveOrder}>
              <Button type="primary">Receive</Button>
            </Popconfirm>
          </Col>
        }
      </div >
    }
  }

  render() {
    const { match, purchaseLines, distributorId, distributor, territories } = this.props;
    const { orderId, docType } = match.params
    const newOrder = orderId === 'new';
    const { loading, order, locCodeError, payMethodCodeError, payTermCodeError, returnReasonError, territoryCodeError, vendorNoError } = this.state;

    if (!order) return null;

    return (
      <Spin spinning={loading} tip={"Loading..."}>
        <Row style={{ height: this.getScrollAreaHeight() }}>
          <OrderHeader
            distributorId={distributorId}
            order={order}
            purchaseLines={purchaseLines}
            distributor={distributor}
            newOrder={newOrder}
            onSubmitOrder={this.submitOrder}
            // onShareOrder={this.shareOrder}
            // onClickConfirmOrder={this.navigateToConfirmOrder}
            // onDeleteOrder={this.onDeleteOrder}
            onInputChange={this.onInputChange}
            error={{ locCodeError, payMethodCodeError, payTermCodeError, returnReasonError, territoryCodeError, vendorNoError }} />
          <div style={{ margin: 5 }}>
            <Col xs={0} sm={0} md={24} lg={24}>
              <ItemTable
                OnClickAddItem={this.navigateToInventoryItemListView}
                order={order}
              />
            </Col>
            <Col xs={24} sm={24} md={0} lg={0}>
              <ItemTableMobile
                OnClickAddItem={this.navigateToInventoryItemListView}
                order={order}
              />
            </Col>
          </div>
          <OrderFooter order={order} onInputChange={this.onInputChange} docType={docType} />
          <div style={{ float: 'right', margin: 15 }}>
            {this.renderActions()}
          </div>
          <div id={'printContainer'} style={{ display: 'none' }}>
            {/* <OrderExport order={order} /> */}
          </div>
        </Row>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId, docType, distributorId } = ownProps.match.params
  const newOrder = orderId === 'new';
  const decodeId = decodeURIComponent(orderId);
  const order = !newOrder ? getPurchaseHeadersByTypeAndNo(state, docType, decodeId) : undefined;
  const purchaseLines = !newOrder ? getPurchaseLinesByDocTypeAndDocNo(state, docType, decodeId) : []
  const distributor = getDistributor(state, distributorId);

  // let invoiceNo;
  // if (ownProps.location && ownProps.location.search) {
  //   const params = queryString.parse(ownProps.location.search);
  //   invoiceNo = params.invoiceNo;
  //   console.log("invoiceNo", invoiceNo)
  // }

  return {
    order,
    authData: getAuthData(state),
    locations: getLocations(state),
    distributor,
    purchaseLines: purchaseLines,
    loggedInUser: getLoggedInUser(state),
    distributorId: distributorId,
    location: getLocations(state).filter(location => order && location.LocCode === order.LocCode && location.WarehouseOwner === 'Yes')[0]
  };
};

export default withRouter(withToastManager(connect(mapStateToProps, {
  fetchDistributors,
  fetchInventory,
  fetchPurchaseHeaders,
  addPurchaseHeader,
  updatePurchaseHeader,
  deletePurchaseHeader,
  fetchPurchaseLines,
  fetchPaymentMethods,
  fetchPaymentTerms,
  fetchWarehouseLocations,
  fetchPurchaseRetrunReasons,
  fetchPurchaseCreditMemoReasons,
  fetchTerritories,
  fetchPurchasingInvoiceHeaders
})(Container)));