import React, { Component } from 'react';
import { Form, Card, Row, Col } from "antd";
import { format } from "currency-formatter";
import FormItem from '../../common/FormItem';
import { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class OrderFooter extends Component {

  render() {
    const {/* totalAmount, totalDiscountAmount,*/ amountIncludingVat } = this.props.order;

    return (
      <Card>
        <Form>
          <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
            <TextArea rows={4} placeholder={"Type here"} >
            </TextArea>
          </FormItem>
          <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {/* <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Gross Amount</div>
              <div style={{ fontSize: "large" }}>{format(totalAmount, currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large", marginRight: 10 }}>Discount</div>
              <div style={{ fontSize: "large" }}>{format(totalDiscountAmount, currencyFormat)}</div>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large", marginRight: 10 }}>Tax</div>
              <div style={{ fontSize: "large" }}>{format((amountIncludingVat - (totalAmount + totalDiscountAmount)), currencyFormat)}</div>
            </Col> */}
            <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontSize: "large" }}>Net Amount</div>
              <div style={{ fontSize: "large" }}>{format(amountIncludingVat, currencyFormat)}</div>
            </Col>
          </Row>
        </Form>
      </Card>
    )
  }
}
export default OrderFooter;