import React, { Component } from 'react';
import { Card, Col, Row, Tag, Input, Select } from "antd";
import moment from "moment";
import { format } from "currency-formatter";
import { dateFormatView } from '../../../const/Formats';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const { Option } = Select;

class Header extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Pending':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const {
      customers,
      customer = {},
      totalCustomerInvoiceAmount,
      totalCustomerReceiptAmount,
      totalCustomerCreditAmount,
      totalCustomerInvoiceOutstandingAmount,
      totalCustomerReceiptOutstandingAmount,
      totalCustomerCreditOutstandingAmount } = this.props;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <tr>
              <td>Settlement Id #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>new</td>
            </tr>
            <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment().format(dateFormatView)}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={this.getStatusColor('Pending')}>{"Pending"}</Tag></td>
            </tr>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <div>
              <tr>
                <td>Customer ID</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td>
                  <Select
                    value={customer && customer.id}
                    showSearch
                    placeholder={'Press Enter to match ID'}
                    dropdownMatchSelectWidth={false}
                    style={{ width: 190 }}
                    onChange={(value, { key }) => this.props.onSelectCustomerId(value)}>
                    {customers && customers.map(customer => {
                      return <Option key={customer.id} value={customer.id}>{customer.id}</Option>
                    })}
                  </Select>
                </td>
              </tr>
              {customer.name && <tr>
                <td>Customer Name</td>
                <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                <td style={{ width: '60%' }}>{customer.name}</td>
              </tr>}
            </div>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            {customer.address && <tr>
              <td>Address</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{customer.address}</td>
            </tr>}
            {customer.contactNumber && <tr>
              <td>Contact No</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{customer.contactNumber}</td>
            </tr>}
          </Col>
          {customer.id && <Col lg={6} md={6} sm={24} xs={24}>
            <div style={{ fontWeight: "bold", borderBottom: '1px solid #e0d9d9' }}>Transaction Summary</div>
            <div>
              {customer.id && <tr style={{ width: 100, height: 25 }}>
                <td style={{ width: 100 }}></td>
                <td style={{ width: 130, fontWeight: "bold", textAlign: 'right' }}>Total</td>
                <td style={{ width: 130, fontWeight: "bold", textAlign: 'right' }}>Outstanding</td>
              </tr>}
              {customer.id && <tr>
                <td style={{ textAlign: 'left' }}>Invoices</td>
                <td style={{ textAlign: 'right' }}>{format(totalCustomerInvoiceAmount, currencyFormat)}</td>
                <td style={{ textAlign: 'right' }}>{format(totalCustomerInvoiceOutstandingAmount, currencyFormat)}</td>
              </tr>}
              {customer.id && <tr>
                <td style={{ textAlign: 'left' }}>Credit Notes</td>
                <td style={{ textAlign: 'right' }}>{format(totalCustomerCreditAmount, currencyFormat)}</td>
                <td style={{ textAlign: 'right' }}>{format(totalCustomerCreditOutstandingAmount, currencyFormat)}</td>
              </tr>}
              {customer.id && <tr>
                <td style={{ textAlign: 'left' }}>Receipts</td>
                <td style={{ textAlign: 'right' }}>{format(totalCustomerReceiptAmount, currencyFormat)}</td>
                <td style={{ textAlign: 'right' }}>{format(totalCustomerReceiptOutstandingAmount, currencyFormat)}</td>
              </tr>}
            </div>
          </Col>}
        </Row>
      </Card>
    )
  }
}

export default Header;