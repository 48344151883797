import { Col, Row, Tag } from "antd";
import moment from "moment";
import React, { Component } from "react";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { dateFormatView } from "../../const/Formats";
import { SALES_ORDERS } from "../../const/Permissions";
import { getCustomerCategoryByCustomerId, getCustomerSubcategoryByCustomerId, getLoggedInUser } from "../../redux/reducers";

class OrderCard extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { order, history, customerCategory, customerSubcategory, loggedInUser: { permissions = [], roleCode }, handleOnChange } = this.props;
    const { No, OrderDate, BillToCusNo, BillToCusName, Status, UserCode, AmountIncluVAT, CreatedUserName, SOType, DiscountReasonCode, isChecked } = order;
    return (
      <Row >
        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {roleCode != 'CSR' && <Col style={{ position: 'relative' }}>
            <Row style={styles.checkBox}>
              <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
                <input
                  style={styles.checkBoxMobile}
                  type="checkbox"
                  checked={isChecked}
                  onChange={(event) => handleOnChange(event, No)}
                />
              </Col>
              <Col xs={0} sm={0} md={0} lg={8} style={{ textAlign: 'left' }}>
                <input
                  style={styles.checkBoxDekstop}
                  type="checkbox"
                  checked={isChecked}
                  onChange={(event) => handleOnChange(event, No)}
                />
              </Col>
              <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
              </Col>
              <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
              </Col>
            </Row>
          </Col>}
          <Col style={{ width: '100%', marginLeft: "0px" }}>
            <Row style={styles.alignRow} onClick={(value) => { permissions.includes(SALES_ORDERS.VIEW) && history.push("/sales/Order/" + encodeURIComponent(No)) }}>
              {roleCode != 'CSR' && <Col xs={0} sm={0} md={3} lg={1} style={{ textAlign: 'left' }}>
              </Col>}
              <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'left' }}>
                <span style={styles.orderNoDesktop}>{No}</span>
              </Col>
              <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
                <span style={styles.orderNoMobile}>{No}</span>
              </Col>
              <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>
                <span>{SOType}</span>
              </Col>
              <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'center' }}>
                <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
              </Col>
              <Col xs={0} sm={5} md={3} lg={3} style={{ textAlign: 'center' }}>
                <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
              </Col>
              <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>
                <p style={{ ...styles.customerCode, margin: 5 }}>{BillToCusNo}</p>
              </Col>
              <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>
                <span>{BillToCusName}</span>
              </Col>
              <Col xs={8} sm={5} md={4} lg={3} style={{ textAlign: 'left', marginLeft: 5 }}>
                <span>{customerCategory && customerCategory.Description} {customerSubcategory && ('(' + customerSubcategory.Description + ')')}</span>
              </Col>
              <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>
                <span>{CreatedUserName}</span>
              </Col>
              <Col xs={0} sm={0} md={3} lg={2} style={{ textAlign: 'left' }}>
                <span>{DiscountReasonCode}</span>
              </Col>
              {roleCode === 'CSR' && <Col xs={8} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
                <CurrencyFormat
                  value={AmountIncluVAT || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  isNumericString={true}
                  fixedDecimalScale={true}
                  style={styles.price}
                  decimalScale={2} />
              </Col>}
              {roleCode != 'CSR' && <Col xs={8} sm={4} md={3} lg={2} style={{ textAlign: 'right' }}>
                <CurrencyFormat
                  value={AmountIncluVAT || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  isNumericString={true}
                  fixedDecimalScale={true}
                  style={styles.price}
                  decimalScale={2} />
              </Col>}
            </Row>
          </Col>
        </Row>
        <Row style={styles.lowerRow} onClick={() => permissions.includes(SALES_ORDERS.VIEW) && history.push("/sales/Order/" + encodeURIComponent(No))}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(Status)}>{Status}</Tag>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right', marginLeft: 5 }}>
            <span style={styles.orderDate}>{moment(OrderDate).format(dateFormatView)}</span>
          </Col>
          {/* <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
						<p style={{ ...styles.customerCode, margin: 5 }}><FaIdCard style={styles.icon} />{billToCustomerCode}</p>
					</Col> */}
          {/* <Col xs={4} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{SOType}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{DiscountReason}</span>
          </Col> */}
        </Row>
        <Row style={styles.lowerRow} onClick={() => permissions.includes(SALES_ORDERS.VIEW) && history.push("/sales/Order/" + encodeURIComponent(No))}>
          <Col xs={0} sm={5} md={0} lg={0} style={styles.bottomColumnStyles}>
            <span>{BillToCusNo}</span>
          </Col>

          <Col xs={8} sm={0} md={0} lg={0} style={{ marginLeft: 5, textAlign: 'left', color: '#808080', fontSize: 12 }}>
            <span>{BillToCusNo}</span>
          </Col>
          <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{BillToCusName}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{BillToCusName}</span>
          </Col>
          <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span>{CreatedUserName}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <span>{CreatedUserName}</span>
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  orderNoDesktop: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderNoMobile: {
    fontSize: 11,
    fontWeight: 700
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    // marginLeft: 10,
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 9,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 15,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    // marginLeft: 10,
    marginRight: 10,
    top: 10,
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  },
  checkBox: {
    display: 'flex',
    position: 'absolute',
    zIndex: 999,
  },
  checkBoxMobile: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: 20,
    width: 20,
    top: 30,
  },
  checkBoxDekstop: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: -10,
    height: 20,
    width: 20,
    zIndex: 999,
  },
}

const mapStateToProps = (state, ownProps) => {
  const { order } = ownProps;

  const customerCategory = getCustomerCategoryByCustomerId(state, order.BillToCusNo);
  const customerSubcategory = getCustomerSubcategoryByCustomerId(state, order.BillToCusNo);
  const loggedInUser = getLoggedInUser(state);

  return { customerCategory, customerSubcategory, loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(OrderCard));