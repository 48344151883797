import React, { Component } from 'react';
import { Table, Button } from "antd";
import { connect } from "react-redux";
import { getSalesLinesByDocTypeAndDocNo, getLoggedInUser, isFetchingStockTakeLines, getStockTakeLinesByDocNo, getStockTakesHeadersByNo } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import { STOCK_TAKE } from "../../../const/Permissions";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTableMobile extends Component {
  getColumnsDefault = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderLeftColumn(item)
      },
      {
        title: '',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '40%',
        render: (Description, item) => this.renderRightColumn(item)
      }
    ];

  getColumnsCreditNotes = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        render: (Description, item) => (item.LotNo ? item.LotNo + ' - ' : '') + item.No + ' - ' + Description
      },
      {
        title: 'Amount',
        dataIndex: 'LineAmount',
        key: 'LineAmount',
        align: 'right',
        width: 100,
        render: LineAmount => format(LineAmount, currencyFormat)
      }
    ];

  getColumns = () => {
    if (this.props.match.params.docType === 'CreditMemo') {
      return this.getColumnsCreditNotes();
    } else {
      return this.getColumnsDefault();
    }
  }

  renderLeftColumn = item => {
    const { loggedInUser: { permissions = [] } } = this.props;
    const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);

    return (
      <div>
        <div>{item.LotNo}</div>
        {/* <div>{item.ItemNo}</div> */}
        <div>{item.Description}</div>
        {visibleSytemData && <div>{item.LineReason}</div>}
      </div>
    )
  }

  renderRightColumn = item => {
    const { loggedInUser: { permissions = [] } } = this.props;
    const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);
    const visiblePhysicalData = permissions.includes(STOCK_TAKE.VISIBLE_PHYSICAL_DATA);

    return (
      <div>
        {visibleSytemData && <div>{item.SystemQty}</div>}
        <div>{format(item.UnitCost, currencyFormat)}</div>
        {visiblePhysicalData && <div>{item.PhysicalQty}</div>}
        {visibleSytemData && <div>{item.DeferanceQty}</div>}
        {visibleSytemData && <div>{format(item.DeferanceValue, currencyFormat)}</div>}

        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 7 }}>{format(item.DisPercentage, currencyFormat)}%</div>
          <div style={{ fontWeight: 'bold' }}>{format(item.DisAmount, currencyFormat)}</div>
        </div> */}

      </div>
    )
  }


  renderFooter = () => {
    const { stockTakeLines = [], loggedInUser: { permissions = [] }, stockTake } = this.props;
    const enableUpdate = permissions.includes(STOCK_TAKE.UPDATE)
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {stockTakeLines.length}</p>
        {(enableUpdate) && (stockTakeLines && stockTakeLines.length > 0) && (stockTake && stockTake.Status === 'Open') && <Button onClick={this.props.OnClickAddItem} type="primary">Add/Update Items</Button>}
      </div>
    )
  }

  render() {
    const { height = 100, history, stockTakeLines = [], match, loggedInUser: { permissions = [] }, isFetchingStockTakeLines, stockTake } = this.props;
    const { stockTakeId } = match.params;
    const decodeId = decodeURIComponent(stockTakeId);
    const enableUpdate = permissions.includes(STOCK_TAKE.UPDATE)
    const showItemInfo = stockTake && stockTake.Status === 'Open';

    return (
      <div>
        <Table
          loading={isFetchingStockTakeLines}
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={stockTakeLines}
          size={'small'}
          onRowClick={item => {
            enableUpdate && history.push({ pathname: '/stockTake/' + stockTakeId + '/lines/' + item.LineNo });
          }}
          pagination
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakeId } = ownProps.match.params;
  const decodeId = decodeURIComponent(stockTakeId);

  return {
    stockTakeLines: getStockTakeLinesByDocNo(state, decodeId),
    loggedInUser: getLoggedInUser(state),
    isFetchingStockTakeLines: isFetchingStockTakeLines(state),
    stockTake: getStockTakesHeadersByNo(state, decodeId)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTableMobile));