import React, { Component } from 'react';
import { Table, Button } from "antd";
import { connect } from "react-redux";
import { getStockTakeLinesByDocNo, getLoggedInUser, isFetchingStockTakeLines, getStockTakesHeadersByNo } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import { STOCK_TAKE } from "../../../const/Permissions";
import TableFooter from "../../common/TableFooter";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTable extends Component {

  state = {
    currentPage: 1
  }

  componentDidMount() {
    this.setState({ currentPage: this.props.location.state && this.props.location.state.currentPage ? Number(this.props.location.state.currentPage) : 1 })
  }

  getColumnsDefault = () =>
    [
      {
        title: 'No',
        align: 'center',
        width: 35,
        dataIndex: 'id',
        key: 'id',
        //render: (unitPrice, object, index) => (index + 1)
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '45%',
        render: (Description, item) => (item.LotNo ? item.LotNo + ' ' : '') + Description
      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitCost',
        key: 'UnitCost',
        align: 'right',
        width: '8%',
        render: unitPrice => format(unitPrice, currencyFormat)
      },
      {
        title: 'System  Quantity',
        dataIndex: 'SystemQty',
        key: 'SystemQty',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Physical Quantity',
        dataIndex: 'PhysicalQty',
        key: 'PhysicalQty',
        width: '8%',
        align: 'right',
      },
      {
        title: 'Difference Qty',
        dataIndex: 'DeferanceQty',
        key: 'DeferanceQty',
        width: '8%',
        align: 'right',
      },
      {
        title: 'Difference Value',
        dataIndex: 'DeferanceValue',
        key: 'DeferanceValue',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Line Reason',
        dataIndex: 'LineReason',
        key: 'LineReason',
        align: 'right',
        width: '8%'
      }
    ];

  getColumnsPhysicalQty = () =>
    [
      {
        title: 'No',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 35,
        //render: (unitPrice, object, index) => (index + 1)
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '69%',
        render: (Description, item) => (item.LotNo ? item.LotNo + ' - ' : '') + ' - ' + Description
      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitCost',
        key: 'UnitCost',
        align: 'right',
        width: '8%',
        render: unitPrice => format(unitPrice, currencyFormat)
      },

      {
        title: 'Physical Quantity',
        dataIndex: 'PhysicalQty',
        key: 'PhysicalQty',
        width: '8%',
        align: 'right',
      },

    ];

  getColumnsSystemQty = () =>
    [{
      title: 'No',
      align: 'center',
      width: 35,
      dataIndex: 'id',
      key: 'id',
      //render: (unitPrice, object, index) => (index + 1)
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
      width: '53%',
      render: (Description, item) => (item.LotNo ? item.LotNo + ' - ' : '') + item.ItemNo + ' - ' + Description
    },
    {
      title: 'Unit Price',
      dataIndex: 'UnitCost',
      key: 'UnitCost',
      align: 'right',
      width: '8%',
      render: unitPrice => format(unitPrice, currencyFormat)
    },
    {
      title: 'System  Quantity',
      dataIndex: 'SystemQty',
      key: 'SystemQty',
      width: '8%',
      align: 'right'
    },

    {
      title: 'Difference Qty',
      dataIndex: 'DeferanceQty',
      key: 'DeferanceQty',
      width: '8%',
      align: 'right',
    },
    {
      title: 'Difference Value',
      dataIndex: 'DeferanceValue',
      key: 'DeferanceValue',
      width: '8%',
      align: 'right'
    },
    {
      title: 'Line Reason',
      dataIndex: 'LineReason',
      key: 'LineReason',
      align: 'right',
      width: '8%'
    }
    ];

  getColumns = () => {
    const { loggedInUser: { permissions = [] } } = this.props;
    const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);
    const visiblePhysicalData = permissions.includes(STOCK_TAKE.VISIBLE_PHYSICAL_DATA);

    if (visibleSytemData && visiblePhysicalData) {
      return this.getColumnsDefault();
    } else if (visibleSytemData) {
      return this.getColumnsSystemQty();
    } else if (visiblePhysicalData) {
      return this.getColumnsPhysicalQty();
    } else {
      return [];
    }
  }

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }


  renderFooter = () => {
    const { stockTakeLines = [], loggedInUser: { permissions = [] }, match, stockTake } = this.props;
    const enableUpdate = permissions.includes(STOCK_TAKE.UPDATE);
    const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);
    const visibleSPhysicalData = permissions.includes(STOCK_TAKE.VISIBLE_PHYSICAL_DATA);

    const { currentPage } = this.state;

    return (
      <>
        <TableFooter
          noOfRecords={stockTakeLines.length}
          currentPage={currentPage}
          onPaginationChange={(page) => {
            // localStorage.setItem('stockTakePageNo', page);
            this.setState({ currentPage: page });
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>
          {/* <p>No of Items: {stockTakeLines.length}</p> */}
          {/* && (stockTakeLines && stockTakeLines.length > 0) */}
          {(enableUpdate) && (stockTake && stockTake.Status === 'Open') && <Button onClick={this.props.OnClickAddItem} type="primary">Add/Update Items</Button>}
        </div>

      </>

    )
  }

  generatePaginatedLines(stockTakeLines) {
    const { currentPage } = this.state;
    let currentPagestockTakeLines = stockTakeLines.filter((line, index) => index < currentPage * 15);
    if (currentPage > 1) {
      currentPagestockTakeLines = currentPagestockTakeLines.filter((line, index) => index + 1 > (currentPage - 1) * 15);
    }
    return currentPagestockTakeLines;
  }

  render() {
    const { height = 100, history, stockTakeLines = [], match, loggedInUser: { permissions = [] }, OnClickAddItem, isFetchingStockTakeLines, stockTake, location } = this.props;
    const { stockTakeId } = match.params;
    const decodeId = decodeURIComponent(stockTakeId);
    const enableUpdate = permissions.includes(STOCK_TAKE.UPDATE)
    const showItemInfo = stockTake && stockTake.Status === 'open';

    const paginatedLines = this.generatePaginatedLines(stockTakeLines);

    return (
      <div>
        <Table
          loading={isFetchingStockTakeLines}
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={paginatedLines}
          size={'small'}
          onRowClick={item => {
            enableUpdate && history.push({ pathname: '/stockTake/' + stockTakeId + '/lines/' + item.LineNo, state: { currentPage: this.state.currentPage, prevPath: location.pathname } });
          }}
          pagination={false}
          footer={this.renderFooter} />
      </div >
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakeId } = ownProps.match.params;
  const decodeId = decodeURIComponent(stockTakeId);

  const stockTakeLines = getStockTakeLinesByDocNo(state, decodeId).map((line, index) => ({ ...line, id: index + 1 }));

  return {
    stockTakeLines,
    loggedInUser: getLoggedInUser(state),
    isFetchingStockTakeLines: isFetchingStockTakeLines(state),
    stockTake: getStockTakesHeadersByNo(state, decodeId)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTable));