import moment from 'moment';
import React, { Component } from 'react';
import { connect } from "react-redux";
import AdvanceSearchFilters from "../../../components/common/AdvanceSearchFilters";
import { ProMateGreen } from "../../../const/Theme";
import { fetchCustomerCategories, fetchCustomers } from "../../../redux/actions/customers";
import { fetchFailedSalesHeadersFromLocalAPI } from '../../../redux/actions/failedOrdedrs';
import { getAuthData } from '../../../redux/reducers';
import PromatePageHeader from '../../common/PromatePageHeader';
import DataTable from './components/DataTable';

class OtherFailedSalesOrders extends Component {
    state = {
        filter: {
            from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
            to: moment(new Date()).endOf("day")
        },
    };

    componentDidMount() {
        const { fetchCustomerCategories, fetchCustomers, authData: { userId } } = this.props;
        fetchCustomerCategories();
        fetchCustomers({ UserCode: userId });
    }

    getFilterFieldPropertiesSalesOrders = () => {
        return {
            FROM_DATE_PICKER: { lg: 4, md: 24, sm: 24, xs: 24 },
            TO_DATE_PICKER: { lg: 4, md: 24, sm: 24, xs: 24 },
            SEARCH_BUTTON: { lg: 2, md: 24, sm: 24, xs: 24 },
        };
    }

    handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

    handleSearchClick = () => {
        const { fetchFailedSalesHeadersFromLocalAPI } = this.props;
        const { filter: { from, to } } = this.state;
        fetchFailedSalesHeadersFromLocalAPI({ from: from.format('MM/DD/YY'), to: to.format('MM/DD/YY') });
    }
    render() {
        return (
            <>
                <PromatePageHeader
                    title="Failed Orders"
                    style={styles.pageHeader}
                    onBack={() => this.props.history.goBack()}
                    showCallButton={false}
                />
                <div style={{ padding: "10px 10px 0px 10px" }}>
                    <AdvanceSearchFilters
                        {...this.getFilterFieldPropertiesSalesOrders()}
                        handleInputDateChange={this.handleInputDateChange}
                        handleSearchClick={this.handleSearchClick}
                        filter={this.state.filter}
                    />
                </div>
                <DataTable filter={this.state.filter} />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    authData: getAuthData(state),
})

const mapDispatchToProps = {
    fetchCustomerCategories,
    fetchCustomers,
    fetchFailedSalesHeadersFromLocalAPI
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherFailedSalesOrders);

const styles = {
    pageHeader: {
        backgroundColor: ProMateGreen,
        height: 80,
    },
    text: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}
