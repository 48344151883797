import * as fromApi from '../../api/inventory';
import * as fromGenericApi from '../../api/generic';

export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_COMBINED_FILTER = 'UPDATE_COMBINED_FILTER';
export const FETCH_INVENTORY_ITEM = 'FETCH_INVENTORY_ITEM';
export const UPDATE_INVENTORY_PAGE_SIZE = 'UPDATE_INVENTORY_PAGE_SIZE';

export const FETCH_WAREHOUSE_LOCATIONS = 'FETCH_WAREHOUSE_LOCATIONS';

export const fetchInventory = () => dispatch => {
  return fromApi.getAll().then(response => {
    dispatch({ type: 'FETCH_ITEMS', items: response.items });
    dispatch({ type: FETCH_INVENTORY, items: response.items });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateFilter = (type, filter) => dispatch => {
  dispatch({
    type: UPDATE_FILTER,
    filterType: type,
    filter,
  });
};

export const updateCombinedFilter = filter => dispatch => {
  dispatch({
    type: UPDATE_COMBINED_FILTER,
    filter,
  });
};

export const fetchInventoryItem = id => dispatch => {
  return fromApi.get(id).then(response => {
    const { item, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_INVENTORY_ITEM, item });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const updatePageSize = size => dispatch => {
  dispatch({
    type: UPDATE_INVENTORY_PAGE_SIZE,
    pageSize: size,
  });
};

export const fetchWarehouseLocations = filter => dispatch => {
  return fromGenericApi.get(filter).then(response => {
    const { data, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_WAREHOUSE_LOCATIONS, locations: data });
    return response;
  }).catch(error => {
    return { error }
  })
};