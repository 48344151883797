import React, { Component } from 'react';
import { PageHeader, Spin, Button } from "antd";
import { ProMateGreen } from "../../const/Theme";
import { fetchOrdersByCustomer, fetchOrdersForDateRange } from "../../redux/actions/orders";
import { getOrders, getOrdersByCustomer } from "../../redux/reducers";
import { connect } from "react-redux";
import StockTransferTable from "./StockTransferTable"
import moment from 'moment';
import { showLoadingMessage } from '../common/Message';
import { FaWarehouse } from 'react-icons/fa';
import { generateDemoStockTransfers, getDemoOrders } from './dummyStockTransfer'
import { getAuthData } from '../../redux/reducers';
import PromatePageHeader from '../common/PromatePageHeader';
import { FaPlus } from 'react-icons/fa';
class StockTransfer extends Component {
  state = {
    loading: false,
    filter: {
      text: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day")
    }
  }

  componentDidMount() {
    generateDemoStockTransfers()
    const { customerId } = this.props.match.params

    if (customerId) {
      this.fetchAllByCustomer(customerId);
    } else {
      this.fetchAllByDateRange();
    }
  }

  fetchAllByDateRange = () => {
    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchOrdersForDateRange(this.state.filter).then(() => {
      this.setState({ loading: false })
      this.hideLoadingMessage();
    })
  }

  fetchAllByCustomer = customerId => {
    this.showLoadingMessage();
    this.setState({ loading: true })
    this.props.fetchOrdersByCustomer(customerId).then((res) => {
      this.hideLoadingMessage()
      this.setState({ loading: false })
    })
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Stock Transfers.');
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } });

  render() {
    // const { orders } = this.props;
    const stockTransfers = generateDemoStockTransfers()

    const { loading, filter } = this.state;
    return (
      <div>
        <PromatePageHeader
          title={'Stock Transfers'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={this.showNewButton() && [
            <Button style={window.innerWidth < 768 ? styles.addBtnMobile : styles.addBtnWeb} onClick={this.onClickNew}>
              {
                window.innerWidth < 768 ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>

          <Spin spinning={loading && stockTransfers.length === 0} tip={"Loading Stock Transfers"}>
            <StockTransferTable
              stockTransfers={stockTransfers}
              filter={filter}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.fetchAllByDateRange}
              authData={this.props.authData} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { match } = this.props;
    const url = match.url
    //console.log(url)
    this.props.history.push(url + "/new");
    //this.props.history.push("/collections/new");
  }

  showNewButton = () => {
    const { match } = this.props;
    const { authData: { userId, roleCode } } = this.props;

    const insideCustomer = match.path.includes("customer");
    const insideDistributor = match.path.includes("distributors");
    const insideSalesRep = match.path.includes("salesRepresentatives");

    //console.log(insideCustomer, insideDistributor, insideSalesRep, roleCode)
    if (roleCode === "ADMIN" || roleCode === "ADMIN_ACCOUNTANT") {
      if (!insideDistributor) {
        return true;
      } else {
        return false;
      }
    } else if (roleCode === "DISTRIBUTOR") {
      return true
    }
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params

  const orders = customerId ? getOrdersByCustomer(state, customerId) : getOrders(state);
  return { orders: sortListByOrderDate(orders), authData: getAuthData(state) };
};

const sortListByOrderDate = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (order1, order2) {
    let order1_orderDate = parseInt(order1.orderDate);
    let order2_orderDate = parseInt(order2.orderDate);

    if (order1_orderDate < order2_orderDate) return 1;
    if (order1_orderDate > order2_orderDate) return -1
    return 0
  }) : []
  return sortedList;
}

export default connect(mapStateToProps, { fetchOrdersForDateRange, fetchOrdersByCustomer })(StockTransfer);