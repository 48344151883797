import { combineReducers } from 'redux';


export const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_TRIP_SCHEDULES':
      const newState = { ...state };
      action.schedules.forEach(schedule => newState[schedule.EntryNo] = schedule);
      return newState;
    case 'FETCH_TRIP_SCHEDULE': {
      const newState = { ...state };
      newState[action.schedule.EntryNo] = action.schedule;
      return newState;
    }
    case 'DELETE_TRIP_SCHEDULE': {
      const newState = { ...state };
      delete newState[action.schedule.EntryNo];
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TRIP_SCHEDULES': {
      return action.schedules.map(header => header.EntryNo);
    }
    case 'FETCH_TRIP_SCHEDULE': {
      const { EntryNo } = action.schedule;
      const newState = [];
      if (!newState.includes(EntryNo)) newState.push(EntryNo);
      return newState;
    }
    case 'DELETE_TRIP_SCHEDULE': {
      const newState = [];
      return newState.filter(id => id !== action.schedule.EntryNo);
    }
    default:
      return state;
  }
};

export const bySearchKey = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_TRIP_SCHEDULES':
      if (!action.searchKey) return state;

      return {
        ...state,
        [action.searchKey]: action.schedules.map(schedule => schedule.EntryNo)
      };

    case 'DELETE_TRIP_SCHEDULE': {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        const ids = newState[key];
        newState[key] = ids.filter(id => id !== action.schedule.EntryNo)
      });
      return newState;
    }
    default:
      return state;
  }
}

export const total = (state = 0, action) => {
  switch (action.type) {
    case 'FETCH_TRIP_SCHEDULES':
      return action.total;
    default:
      return state;
  }
}

export const fetchingTripSchedulars = (state = false, action) => {
  switch (action.type) {
    case 'START_FETCH_TRIP_SCHEDULES':
      return true;

    case 'FINISH_FETCH_TRIP_SCHEDULES':
      return false;

    default:
      return state;
  }
};

const schedules = combineReducers({
  byId,
  ids,
  total,
  fetchingTripSchedulars,
  bySearchKey
});

export default schedules;

export const getAll = state => {
  return state.ids.map(id => state.byId[id]).sort(sortDecByStartDate);
}

export const get = (state, no) => state.byId[no];

const sortDecByStartDate = (s1, s2) => {
  return new Date(s2.OrderDate) - new Date(s1.OrderDate);
}

export const getTotal = state => state.total;

export const isFetchingTripSchedulars = state => state.fetchingTripSchedulars;

export const getBySearchKey = (state, key) => {
  const ids = state.bySearchKey[key] || [];
  return ids.map(id => state.byId[id]);
}


