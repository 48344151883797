import React, { Component } from 'react';
import { Table, Row, Input, Col, Button } from "antd";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchSalesHeaders } from "../../redux/actions/salesHeaders";
import { addSalesLine, fetchSalesLines, updateSalesLine, deleteSalesLine } from "../../redux/actions/salesLines";
import { fetchItems } from "../../redux/actions/items";
import { fetchGlAccounts } from '../../redux/actions/glAccounts';
import { getItems, getAuthData, getSalesLineMapByDocTypeAndDocNo, getSalesLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getSalesHeadersByTypeAndNo, getAllGlAccounts } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import SelectWarehouseLocation from '../common/SelectWarehouseLocation'
import { FaCheck, FaTimes, FaSave, FaUndo } from "react-icons/fa";
import { format } from "currency-formatter";
import uuid from "uuid/v1";
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { withToastManager } from 'react-toast-notifications';
import { formatGeoLocation } from "../../utils/Formats";
import Grid from 'antd/lib/card/Grid';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const { Search } = Input;

class ItemTable extends Component {
  state = {
    LocCode: undefined,
    text: undefined,
    salesLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false
  }

  getColumns = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        render: (_, item) => item.No + ' - ' + item.Name
      }
    ];

  renderQuntity = item => {
    const { salesLine } = this.state;
    const salesLineItem = this.getExistingOrderSalesLine(item);
    const key1 = salesLineItem && salesLineItem.No;
    const key2 = item.No;

    let lineQty = 0;

    if (key1 === key2) lineQty = salesLineItem.Quantity;

    if (salesLine) {
      const key3 = salesLine.No;

      if (key3 === key2) lineQty = salesLine.Quantity;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={lineQty}
        className="ant-input"
        style={{ width: 50, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onChange={(e) => this.handleQtyChange(e.target.value, item, salesLineItem)} />
    )
  }

  renderUnitPrice = item => {
    const { salesLine } = this.state;
    const salesLineItem = this.getExistingOrderSalesLine(item);
    const key1 = salesLineItem && salesLineItem.No;
    const key2 = item.No;

    let unitPrice = 0;

    if (key1 === key2) unitPrice = salesLineItem.UnitPrice;

    if (salesLine) {
      const key3 = salesLine.No;

      if (key3 === key2) unitPrice = salesLine.UnitPrice;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={unitPrice}
        className="ant-input"
        style={{ width: 100, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onChange={(e) => this.handleUnitPriceChange(e.target.value, item, salesLineItem)} />
    )
  }

  renderLineTotal = item => {
    const { salesLine } = this.state;
    const salesLineItem = this.getExistingOrderSalesLine(item);
    const key1 = salesLineItem && salesLineItem.No;
    const key2 = item.No;

    let lineAmount = 0;

    if (key1 === key2) lineAmount = salesLineItem.LineAmount && salesLineItem.LineAmount.replace(/,/g, '');

    if (salesLine) {
      const key3 = salesLine.No;

      // if (key3 === key2)  lineAmount = (salesLine.Quantity * item.UnitPrice) - (salesLine.Quantity * item.UnitPrice * salesLine.DisPercentage /100)
      if (key3 === key2) lineAmount = undefined;
    }

    return lineAmount !== undefined ? format(lineAmount, currencyFormat) : 'N/A';
  }

  renderLineDiscount = item => {
    const { salesLine } = this.state;
    const salesLineItem = this.getExistingOrderSalesLine(item);
    const key1 = salesLineItem && salesLineItem.No;
    const key2 = item.No;

    let discountAmount = 0;

    if (key1 === key2) discountAmount = salesLineItem.DisAmount;

    if (salesLine) {
      const key3 = salesLine.No;

      // if (key3 === key2)  discountAmount = salesLine.Quantity * item.UnitPrice * salesLine.DisPercentage /100;
      if (key3 === key2) discountAmount = undefined;
    }
    return discountAmount !== undefined ? format(discountAmount, currencyFormat) : 'N/A';
  }

  renderLineDiscountPercentage = item => {
    const { salesHeaderDetails } = this.props;
    const { salesLine } = this.state;
    const salesLineItem = this.getExistingOrderSalesLine(item);
    const baseDiscount = salesHeaderDetails ? salesHeaderDetails.DisPersentage : 0.00;
    const key1 = salesLineItem && salesLineItem.No;
    const key2 = item.No;

    let discountPercentage = 0;

    if (key1 === key2) {
      discountPercentage = salesLineItem.DisPercentage;
    }

    if (salesLine) {
      const key3 = salesLine.No;

      if (key3 === key2) discountPercentage = salesLine.DisPercentage;
    } else if (!salesLineItem) {
      discountPercentage = baseDiscount;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={discountPercentage}
        className="ant-input"
        style={{ width: 70, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 189) && e.preventDefault()}
        onChange={(e) => this.handleDiscountPercentageChange(e.target.value, item, salesLineItem)} />
    )
  }

  isDisableRowActions = item => {
    const { salesLine } = this.state;

    if (!salesLine) return false;

    const key1 = item.No;
    const key2 = salesLine.No;
    return key1 !== key2;
  }

  renderStatus = item => {
    const salesLine = this.getExistingOrderSalesLine(item);

    if (salesLine) {
      if (salesLine.added) {
        return (
          <FaCheck color='#07e607' />
        )
      } else {
        return (
          <FaTimes color='red' />
        )
      }
    } else return null;
  }

  getRowClassName = item => {
    const salesLine = this.getExistingOrderSalesLine(item);

    if (salesLine) {
      if (salesLine.added) {
        return 'sales-header-selected-item-row'
      } else {
        return ''
      }
    } else return '';
  }

  getExistingOrderSalesLine = item => this.props.salesLineMap[item.No];

  renderActions = (item) => {
    const { salesLine } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disabled={this.isDisableRowActions(item)} type='primary' size={'small'} onClick={this.handleSaveItem} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        <Button disabled={this.isDisableRowActions(item)} size={'small'} onClick={this.handleResetItem} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
      </div>
    )
  }

  componentDidMount() {
    const { fetchSalesLines, fetchSalesHeaders, match } = this.props;
    let { docType, orderId } = match.params;
    orderId = decodeURIComponent(orderId);
    fetchSalesHeaders({ No: orderId, DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
    fetchSalesLines({ DocNo: orderId, DocType: DOC_TYPE_ID_BY_DEFINITION[docType] });
    this.loadItems();
    this.setDeviceMapLocation();
  }

  loadItems = () => {
    this.setState({ loadingItems: true });
    this.props.fetchGlAccounts().then(() => {
      this.setState({ loadingItems: false });
    });
  }

  loadSaleHeader = () => {
    const { salesHeaderDetails, fetchSalesHeaders } = this.props;

    fetchSalesHeaders({ No: salesHeaderDetails.No, DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeaderDetails.DocType] });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  handleSaveItem = () => {
    const { salesHeaderDetails, addSalesLine, authData, toastManager, updateSalesLine, deleteSalesLine } = this.props;
    const { salesLine, MapLocation } = this.state;
    const exitingSalesLine = this.getExistingOrderSalesLine(salesLine);

    if (!locationEnabled) {
      toastManager.add('You can not update sales lines without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    const salesLineItem = {
      LineSessionID: uuid(),
      DocType: salesHeaderDetails.DocType,
      DocNo: salesHeaderDetails.No,
      Type: '1',
      No: salesLine.No,
      MapLocation,
      Quantity: salesLine.Quantity,
      UnitPrice: salesLine.UnitPrice,
      UserCode: authData.userId
    }

    if (!exitingSalesLine) this.handleAction(addSalesLine, salesLineItem); //Add new sales line
    else {
      const updatedSalesLine = {
        ...salesLineItem,
        LineNo: exitingSalesLine.LineNo
      }

      if (salesLineItem.Quantity === '0') this.handleAction(deleteSalesLine, updatedSalesLine); //Remove sales line
      else this.handleAction(updateSalesLine, updatedSalesLine); //Update sales line
    }
  }

  handleAction = (action, salesLineItem) => {
    const { toastManager } = this.props;

    this.setState({ loading: true });
    action(salesLineItem).then(response => {
      this.setState({ loading: false });
      if (response.error) {
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        this.loadItems({ ...this.state.salesLine });
        this.loadSaleHeader();
        this.setState({ salesLine: undefined });
      }
    })
  }

  handleResetItem = () => this.setState({ salesLine: undefined });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  handleChange = (field, value, error) => {
    this.setState({ [field]: value })
  }

  renderTitle = () => {
    return (
      <Row className="promate-table-header" gutter={5} style={{ paddingBottom: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Search
            placeholder='Input search text'
            size='default'
            color={'black'}
            onChange={e => this.handleChange('text', e.target.value, '')} />
        </Col>
      </Row>
    )
  }

  renderFooter = () => {
    const { salesHeaderDetails } = this.props;
    const { AmountIncluVAT, DisAmount, DisPersentage, AvgDisPersentage } = salesHeaderDetails;
    const inludeVatAmount = AmountIncluVAT.replace(/,/g, '');
    const discount = DisAmount.replace(/,/g, '');
    const grossAmount = parseFloat(inludeVatAmount) + parseFloat(discount);

    return (
      <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ fontSize: "medium" }}>Gross Amount</div>
          <div style={{ fontSize: "medium" }}>{format(grossAmount, currencyFormat)}</div>
        </Col>
        <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ fontSize: "medium" }}>Discount ({AvgDisPersentage}%) </div>
          <div style={{ fontSize: "medium" }}>{format(DisAmount, currencyFormat)}</div>
        </Col>
        <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ fontSize: "medium" }}>Net Amount</div>
          <div style={{ fontSize: "medium" }}>{format(AmountIncluVAT, currencyFormat)}</div>
        </Col>
        <Col lg={2} md={2} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <Button block type='primary' onClick={() => this.props.history.goBack()}>Done</Button>
        </Col>
      </Row>
    )
  }

  getFilteredItems = () => {
    const { items = [] } = this.props;
    const { text } = this.state;

    let filteredItems = items;

    filteredItems = items.filter(item => {
      const { Description, Name } = item;
      const descriptionFilter = text ? this.stringCompare(Description || Name, text) : true;

      return descriptionFilter;
    })

    return filteredItems
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  handleQtyChange = (value, item, salesLineItem) => {
    const { salesLine } = this.state;
    const { salesHeaderDetails } = this.props;
    const baseDiscount = salesHeaderDetails ? salesHeaderDetails.DisPersentage : 0.00;
    this.setState({
      salesLine: {
        ...salesLine,
        ...item,
        DisPercentage: salesLine && salesLine.DisPercentage ? salesLine.DisPercentage : salesLineItem ? salesLineItem.DisPercentage : baseDiscount,
        Quantity: value
      }
    });
  }

  handleUnitPriceChange = (value, item, salesLineItem) => {
    const { salesLine } = this.state;
    const { salesHeaderDetails } = this.props;
    const baseDiscount = salesHeaderDetails ? salesHeaderDetails.DisPersentage : 0.00;
    this.setState({
      salesLine: {
        ...salesLine,
        ...item,
        DisPercentage: salesLine && salesLine.DisPercentage ? salesLine.DisPercentage : salesLineItem ? salesLineItem.DisPercentage : baseDiscount,
        UnitPrice: value
      }
    });
  }

  handleDiscountPercentageChange = (value, item, salesLineItem) => {
    const { salesLine } = this.state;
    this.setState({
      salesLine: {
        ...item,
        DisPercentage: value,
        Quantity: salesLine && salesLine.Quantity ? salesLine.Quantity : salesLineItem ? salesLineItem.Quantity : 0
      }
    });
  }

  render() {
    const { salesHeaderDetails, history, match } = this.props;
    const { orderId } = match.params;
    const items = this.getFilteredItems();
    const { loading, loadingItems } = this.state;

    return (
      <div>
        <Table
          rowKey={'id'}
          // rowClassName={(item, index) => this.getRowClassName(item)}
          columns={this.getColumns()}
          bodyStyle={{ minHeight: this.getTableHeight(items) }}
          dataSource={items}
          size={'small'}
          scroll={{ y: this.getScrollAreaHeight() }}
          pagination={false}
          title={this.renderTitle}
          loading={loading || (items.length === 0 && loadingItems)}
          onRowClick={item => history.replace('/sales/CreditMemo/' + orderId + '/salesLines/new/items/' + item.No)} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let { docType, orderId } = ownProps.match.params;
  orderId = decodeURIComponent(orderId);
  const items = getAllGlAccounts(state);

  const originalSalesLineMap = getSalesLineMapByDocTypeAndDocNo(state, docType, orderId);
  const salesLineMap = {};
  Object.keys(originalSalesLineMap).forEach(key => {
    const newKey = key.split('--')[0]; // Only item no
    salesLineMap[newKey] = originalSalesLineMap[key];
  });

  return {
    authData: getAuthData(state),
    items,
    salesHeaderDetails: getSalesHeadersByTypeAndNo(state, docType, orderId),
    salesLineMap,
    salesLines: getSalesLinesByDocTypeAndDocNo(state, docType, orderId)
  };
};

ItemTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withToastManager(withRouter(connect(mapStateToProps, { fetchGlAccounts, addSalesLine, fetchSalesLines, updateSalesLine, deleteSalesLine, fetchSalesHeaders })(ItemTable)));