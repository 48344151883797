import React, { Component } from 'react';
import { Table, Select, Checkbox, Row, Button } from "antd";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { fetchInventory, updatePageSize } from "../../redux/actions/inventory";
import { getInventory, getInventoryPageSize, getItems, getLocations, getAuthData, getSalesLineMapByDocTypeAndDocNo, getSalesLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getSalesHeadersByTypeAndNo } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { generateInventoryItems } from './InventoryDemoData'
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { formatGeoLocation } from "../../utils/Formats";
import { fetchSalesHeaders } from "../../redux/actions/salesHeaders";
import { fetchItems } from "../../redux/actions/items";
import { fetchWarehouseLocations } from '../../redux/actions/warehouses';
import ItemCard from "./ItemCard";
import ItemCardHeader from "./ItemCardHeader";
import { fetchDistributor } from '../../redux/actions/distributors';
import { withToastManager } from 'react-toast-notifications';
import { generateGeneric, generateReport, generate } from '../../api/reports';
import Api from '../../api/consts';

var columnArrayWithSelect =
  [
    {
      title: 'Select',
      dataIndex: 'Description',
      key: 'select2',
      width: 20,
      align: 'right',
      render: (index, object) => renderCheckBox(object, index),
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
    },
    {
      title: 'Uom',
      dataIndex: 'UOM',
      key: 'UOM',
      align: 'right',
      width: 60
    },
    {
      title: 'Quantity In Hand',
      dataIndex: 'Inventory',
      key: 'Inventory',
      width: 100,
      align: 'right'
    },
    {
      title: 'Quantity',
      dataIndex: 'Quntity',
      key: 'Quntity',
      width: 100,
      fixed: 'right',
      align: 'right',
      render: quntity => renderQuntity(quntity),
    }
  ];

var columnArrayForInvnertry =
  [
    {
      title: 'Lot No',
      dataIndex: 'LotNo',
      key: 'LotNo',
    },
    {
      title: 'Loc Code',
      dataIndex: 'LocCode',
      key: 'LocCode',
    },

    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
    },
    {
      title: 'Uom',
      dataIndex: 'UOM',
      key: 'UOM',
      align: 'right',

    },

    {
      title: 'Quantity',
      dataIndex: 'Inventory',
      key: 'Inventory',
      align: 'right'
    },
    {
      title: 'Unit Price',
      dataIndex: 'UnitPrice',
      key: 'UnitPrice',
      align: 'right',
      render: (UnitPrice) =>
        <span style={{ marginRight: 20 }}>{UnitPrice}</span>
    }

  ];

var columnArrayWithoutSelect =
  [
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
    },
    {
      title: 'Uom',
      dataIndex: 'UOM',
      key: 'UOM',
      align: 'right',
      width: 60
    },
  ];

var columnArrayWithoutForSale =
  [
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
    },
    {
      title: 'Uom',
      dataIndex: 'UOM',
      key: 'UOM',
      align: 'right',
      width: 60
    },

  ];

const renderCheckBox = (object, index) => {
  return (
    <Checkbox></Checkbox>
  )
}

const renderQuntity = (quntity) => {
  return (
    <input
      className="ant-input"
      style={{ width: '100%' }}
      onFocus={(event) => event.target.select()} />
  )
}

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTable extends Component {

  state = {
    LocCode: undefined,
    text: undefined,
    salesLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false,
    documentType: 0,
    isLoadingStockValuationReport: false,
  }

  componentDidMount() {
    const { salesHeader, fetchSalesHeaders, authData } = this.props;
    salesHeader && fetchSalesHeaders({ No: salesHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType.replaceAll(' ', '')] });
    this.setState({ LocCode: salesHeader ? salesHeader.LocCode : undefined }, this.loadItems);
    this.setDeviceMapLocation();
    this.setState({ loading: true });
    this.props.fetchWarehouseLocations({ UserCode: authData.userId }).then(() => {
      this.setState({ loading: false });
    });
    //this.props.fetchInventory();
  }

  loadItems = item => {
    const { authData, fetchItems, salesHeader } = this.props;
    //console.log(this.state.LocCode)
    const filter = {
      LocCode: this.state.LocCode,
      UserCode: authData.userId,
      //AllItems: salesHeader && DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType] === 'ReturnOrder' ? 'Yes' :'No'
      AllItems: this.state.LocCode ? 'No' : 'Yes'
    }

    if (item) {
      filter.ItemNo = item.ItemNo;
      filter.LotNo = item.LotNo;
    }

    this.setState({ loadingItems: true, loading: true });
    fetchItems(filter).then(() => {
      this.setState({ loadingItems: false, loading: false });
    });
  }

  loadSaleHeader = () => {
    const { salesHeader, fetchSalesHeaders } = this.props;

    fetchSalesHeaders({ No: salesHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[salesHeader.DocType.replaceAll(' ', '')] });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  renderColumns = () => {
    //console.log(this.props.location.state)
    const { multiSelect, location } = this.props;
    if (multiSelect) {
      return columnArrayWithSelect;
    } else {
      if (location.state) {
        const { resource } = location.state;
        if (resource === "salesOrders") {
          return columnArrayWithoutForSale;
        }
      }
    }

  }

  renderInventryColumn = () => {
    return [{
      render: item => <ItemCard item={item} />
    }]
  }

  handleChange = (value) => {
    this.setState({ warehouseId: value })
  }

  renderWarehousesList = () => {
    const { location, multiSelect } = this.props;
    const { Option } = Select;
    //console.log(location.state);
    if (location.state === undefined || multiSelect === true) {
      return (
        <Select defaultValue="Warehouse 1" style={{ margin: 10 }} onChange={this.handleChange}>
          <Option value="Warehouse 1">Warehouse 1</Option>
          <Option value="Warehouse 2">Warehouse 2</Option>
        </Select>
      )
    }
  }

  handleInputChange = (type, value) => {
    this.setState({ LocCode: value }, () => {
      this.loadItems();
    })

  }

  getDocumentType = (e) => {
    if (e.currentTarget.value == 0) {
      this.setState({ documentType: e.currentTarget.value });
    } else if (e.currentTarget.value == 1) {
      this.setState({ documentType: e.currentTarget.value });
    }
  }

  generateStockValuationReport = () => {
    const { authData: { userId, displayName }, toastManager } = this.props;
    const { LocCode, documentType } = this.state;

    if (LocCode != undefined) {
      const reportRequest = {
        DistributorCode: userId,
        DistributorName: displayName,
        LocCode: LocCode,
        ExcelReport: documentType,
        UserCode: userId,
      };
      this.setState({ isLoadingStockValuationReport: true });

      generateGeneric(reportRequest, 'stockValuation').then(response => {
        if (!response.error) {
          setTimeout(() => {
            window.open(Api.files.download.url(response.url), '_blank');
            this.setState({ isLoadingStockValuationReport: false });
          }, 10000);
        } else {
          toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
          this.setState({ isLoadingStockValuationReport: false });

        }
      });

    } else {
      toastManager.add('Could not generate the report. Please select Location', { autoDismiss: true, appearance: 'error' });
    }
  }

  renderLocations = () => {
    const { location, multiSelect, locations, authData: { roleCode } } = this.props;
    const { Option } = Select;
    return (
      <div style={{ marginTop: 8, marginBottom: 5, marginLeft: 15, marginRight: 15 }}>
        <Select
          loading={this.state.loading}
          placeholder={'Select Location'}
          allowClear={true}
          style={{ width: 300 }}
          onChange={value => this.handleInputChange('Location', value)}
          value={this.state.LocCode}>
          {locations.map(location =>
            <Option value={location.LocCode}>{location.LocDescription}</Option>
          )}
        </Select>

        {roleCode != "CSR" && <input
          style={{ marginTop: 8, marginBottom: 5, marginLeft: 15, marginRight: 15 }}
          type='radio'
          name='documentType'
          checked={this.state.documentType == 0}
          value={0}
          onChange={this.getDocumentType}
        />}
        {roleCode != "CSR" && <label style={{ marginLeft: -9 }}>PDF</label>}
        {roleCode != "CSR" && <input
          style={{ marginTop: 8, marginBottom: 5, marginLeft: 15, marginRight: 15 }}
          type='radio'
          name='documentType'
          value={1}
          onChange={this.getDocumentType}
        />}
        {roleCode != "CSR" && <label style={{ marginLeft: -9 }}>Excel</label>}
        {roleCode != "CSR" && <Button
          type='primary'
          style={{ marginTop: 8, marginBottom: 5, marginLeft: 15, marginRight: 15 }}
          onClick={this.generateStockValuationReport}
          disabled={this.state.isLoadingStockValuationReport}
        >
          {
            this.state.isLoadingStockValuationReport ? 'Generating...' : ' Generate Stock Valuation Report'
          }
        </Button>}

      </div>
    )
  }

  renderHeader = () => {
    return (
      <div>
        <ItemCardHeader />
      </div>
    )
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  render() {
    const { height, location, history, pageSize, updatePageSize, match, multiSelect, items } = this.props;
    const { loading, loadingItems } = this.state
    //const { order = {} } = location
    //console.log(location)
    //console.log(items[0])
    const tableHeight = height - 120;
    //const items = generateInventoryItems()

    const noneZeroItems = [...items].filter((item) => item.Inventory != 0);

    if (location.state != undefined) {
      const { order = {} } = location.state
      const { resource, id } = location && location.state
      return (
        <div style={{ height }}>
          <Table
            rowKey={'id'}
            columns={this.renderColumns()}
            dataSource={noneZeroItems}
            size={'small'}
            pagination={false}
            scroll={{ y: tableHeight }}
            loading={loading || (noneZeroItems.length === 0 && loadingItems)}
            onRowClick={rowData => !multiSelect && order && history.replace({ pathname: "/sales/" + order.DocType.replaceAll(' ', '') + "/" + encodeURIComponent(id) + "/salesLines/new", state: { itemId: rowData.ItemNo, order, resource, id: id, LotNo: rowData.LotNo } })}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
              pageSize,
              onShowSizeChange: (_, size) => updatePageSize(size),
            }} />
        </div>
      )
    }
    else {
      return (
        <div style={{ height }}>
          {this.renderLocations()}
          <Table
            rowKey={'id'}
            columns={this.renderInventryColumn()}
            dataSource={noneZeroItems}
            size={'small'}
            showHeader={false}
            title={() => this.renderHeader()}
            loading={loading || (noneZeroItems.length === 0 && loadingItems)}
            scroll={{ y: tableHeight }}
            pagination={false}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
              pageSize,
              onShowSizeChange: (_, size) => updatePageSize(size),
            }} />
        </div>)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { salesHeader } = ownProps;
  //const items = getItems(state);
  const items = getInventory(state)
  const salesLineMap = salesHeader && getSalesLineMapByDocTypeAndDocNo(state, salesHeader.DocType.replaceAll(' ', ''), salesHeader.No);

  return {
    authData: getAuthData(state),
    items,
    salesHeaderDetails: salesHeader && getSalesHeadersByTypeAndNo(state, salesHeader.DocType.replaceAll(' ', ''), salesHeader.No),
    salesLineMap,
    salesLines: salesHeader && getSalesLinesByDocTypeAndDocNo(state, salesHeader.DocType.replaceAll(' ', ''), salesHeader.No),
    pageSize: getInventoryPageSize(state),
    locations: getLocations(state),
  };
};

ItemTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(withToastManager(connect(mapStateToProps, { fetchWarehouseLocations, fetchInventory, updatePageSize, fetchItems, fetchSalesHeaders, fetchDistributor })(ItemTable)));