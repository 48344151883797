import * as fromApi from '../../api/generic';
import { getOfflineData } from '../../localStorage';

export const FETCH_TERRITORIES = 'FETCH_TERRITORIES';
export const START_FETCH_TERRITORIES = 'START_FETCH_TERRITORIES';
export const FINISH_FETCH_TERRITORIES = 'FINISH_FETCH_TERRITORIES';

let lastSearchKey;
export const fetchTerritories = (filter, page = 0, searchKey = '') => async dispatch => {

  if (getOfflineData('state').isSystemOffline) { } else {
    lastSearchKey = searchKey;
    dispatch({ type: START_FETCH_TERRITORIES });
    return fromApi.get('Territory', filter).then(response => {
      const { data, error, recordTotal } = response;

      if (error) return { error }

      dispatch({ type: FETCH_TERRITORIES, territories: data, total: recordTotal ? recordTotal : 0, searchKey, filter });

      return response;

    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey)
        dispatch({ type: FINISH_FETCH_TERRITORIES });
    })
  }
}
