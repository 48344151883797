import * as fileDownload from 'js-file-download';
import moment from 'moment';
import uuid from 'uuid/v4';
import { OBJECT_KEYS, TABLES } from '../../const/IndexDBObjects';
import { readObjectFromIndexDB } from '../../indexDB';


export const exportJson = async (toastManager, logData = {}) => {
    try {
        const data = await getDetailsFromIndexDB();
        if (Object.hasOwn(data, 'images') && Object.hasOwn(data.images, 'data')) {
            data.images.data = [];
        }
        const token = uuid();
        localStorage.setItem('filetoken', token);
        fileDownload(
            JSON.stringify({
                ...data,
                logData,
                token,
                offline_session_start: localStorage.getItem('offline_session_start_timestamp') ?? '-',
                offline_session_end: localStorage.getItem('offline_session_end_timestamp') ?? '-'
            }),
            `${moment(new Date()).format('YYYY-MM-DD(h-mma)')}.json`);
        toastManager.add("Latest Data file successfully exported", { autoDismiss: true, appearance: 'success' });
    } catch (error) {
        toastManager.add("File export was failed", { autoDismiss: true, appearance: 'error' });
    }
}

export const getDetailsFromIndexDB = async () => {
    let offlineCallsResults = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
        return res;
    }).catch((err) => { console.log(err); });

    let customers = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
        return res;
    }).catch((err) => { console.log(err); });

    customers.data = customers.data.filter(function (customer) {
        return customer.callId != null;
    });

    let salesHeaders = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_HEADERS).then((res) => {
        return res;
    }).catch((err) => { console.log(err); })

    let salesLines = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.OFFLINE_SALES_LINE).then((res) => {
        return res;
    }).catch((err) => { console.log(err); })

    let images = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SIGNATURES).then((res) => {
        return res;
    }).catch((err) => { console.log(err); })

    let salesReturnHeaders = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_HEADERS).then((res) => {
        return res;
    }).catch((err) => { console.log(err); })

    let salesReturnLines = await readObjectFromIndexDB(TABLES.SALES_INVOICES, OBJECT_KEYS.OFFLINE_SALES_RETURN_LINES).then((res) => {
        return res;
    }).catch((err) => { console.log(err); })

    let salesReturnSignatures = await readObjectFromIndexDB(TABLES.IMAGES, OBJECT_KEYS.SALES_RETURN_SIGNATURES).then((res) => {
        return res;
    }).catch((err) => { console.log(err); });

    return {
        offlineCallsResults,
        customers,
        salesHeaders,
        salesLines,
        images,
        salesReturnHeaders,
        salesReturnLines,
        salesReturnSignatures
    }
}

export const getConsoleDetails = () => {
    const consoleMessages = [];
    const originalConsoleError = console.error;
    console.error = (...args) => {
        originalConsoleError(...args);
        consoleMessages.push({ type: 'error', data: args });
    };
    return consoleMessages;
}