import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider } from "antd";
import moment from "moment";
import { dateFormatView } from '../../../const/Formats';

class PaymentHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Unverified':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  renderActions = customerSigned => {
    const { newPaymentId, onSavePayment, onConfirmPayment, onDeletePayment } = this.props;

    if (newPaymentId === "new") {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        {/* <Popconfirm
          title="Are you sure ?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSaveCollection}
        >
          <Button type="primary">Create</Button>
        </Popconfirm> */}
        <Button type="primary" onClick={onSavePayment}>Create</Button>
      </Col></Row>
    } else {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!customerSigned && <div style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={onDeletePayment}
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Col>
          <Col>
            {/* <Popconfirm
            title="Are you sure ?"
            okText="Update"
            cancelText="No"
            onConfirm={onSaveCollection}
          >
            <Button type="primary">Update</Button>
          </Popconfirm> */}
            <Button type="primary" onClick={onSavePayment}>Update</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onConfirmPayment}>Confirm</Button>
          </Col></div>}
        {customerSigned && <Col>
          <Button
            type="secondary"
            style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}>Print & Share</Button>
        </Col>}
      </Row>
    }
  }

  render() {
    const { newPaymentId, payment = {} } = this.props;
    const { paymentDate, sellToCustomerCode, sellToCustomerName, status, billToCustomerCode, billToCustomerName } = payment;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {newPaymentId && <tr>
              <td>Payment #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{newPaymentId}</td>
            </tr>}
            {paymentDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(paymentDate).format(dateFormatView)}</td>
            </tr>}
            {status && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td><Tag color={this.getStatusColor(status)}>{status}</Tag></td>
            </tr>}
          </Col>
          {(sellToCustomerCode || sellToCustomerName) && <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>}
          <Col lg={8} md={8} sm={24} xs={24}>
            {sellToCustomerCode && <tr>
              <td>Supplier Code #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerCode}</td>
            </tr>}
            {sellToCustomerName && <tr>
              <td>Supplier Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerName}</td>
            </tr>}
          </Col>

          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {billToCustomerCode && <tr>
              <td>Distributor Code #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerCode}</td>
            </tr>}
            {billToCustomerName && <tr>
              <td>Distributor Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerName}</td>
            </tr>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default PaymentHeader;