import React, { Component } from "react";
import { Col, Form, Row, Table, Button } from "antd";
import ExpenseCategoryCard from "./ExpenseCategoryCard";
import CurrencyFormat from "react-currency-format";
import ExpenseCategoryCardHeader from "./ExpenseCategoryCardHeader";
import Input from '../../../components/common/EnglishInput';

class ExpenseCategoryTable extends Component {

	renderColumns = () => {
		return [{
			render: expense => <ExpenseCategoryCard expense={expense} />
		}]
	}

	getScrollAreaHeight = () => {
		const networkHeader = document.getElementsByClassName('server-connection-lost');
		const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

		const promateTableHeader = document.getElementsByClassName('promate-table-header');
		const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

		const promatePageHeader = document.getElementsByClassName('promate-page-header');
		const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

		const tableFooter = document.getElementsByClassName('ant-table-footer');
		const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

		return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 25;
	}

	getTableHeight = customers => {
		if (customers.length > 0) {
			return this.getScrollAreaHeight();
		} else {
			return this.getScrollAreaHeight() - 167;
		}
	}

	renderHeader = () => {
		const { handleInputTextChange, handleSearchClick } = this.props;

		return (
			<div className="promate-table-header">
				<Form>
					<Row gutter={5} style={{ marginBottom: 20, marginRight: 10 }}>
						<Col lg={24} md={24} sm={24} xs={24}>
							<Col lg={21} md={21} sm={24} xs={24} style={{ marginBottom: 5, marginTop: 5 }}>
								<Input placeholder="Search Text"
									onChange={value => handleInputTextChange(value)} />
							</Col>

							<Col lg={3} md={3} sm={24} xs={24} style={{ marginBottom: 5, marginTop: 5 }}>
								<Button type="primary" block onClick={handleSearchClick}>Search</Button>
							</Col>
						</Col>
					</Row>
				</Form>
				<ExpenseCategoryCardHeader />
			</div>
		)
	}

	renderFooter = noOfRecords => {
		const { outstandingTotal } = this.props;
		return (
			<div
				style={{
					backgroundColor: '#eceaea',
					height: 50,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginLeft: -10,
					marginRight: 5
				}}>
				<Row style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginLeft: 5, marginRight: 5 }}>
					<Col span={12}>{noOfRecords > 0 ? 'Total ' + noOfRecords : 'No'} Categorie{noOfRecords !== 1 && 's'}</Col>
					<Col span={12} style={{ textAlign: 'right', fontSize: 15, fontWeight: 600 }}>
						<CurrencyFormat
							value={outstandingTotal}
							displayType={'text'}
							thousandSeparator={true}
							isNumericString={true}
							fixedDecimalScale={true}
							decimalScale={2} />
					</Col>
				</Row>
			</div>
		)
	}

	render() {
		const { expenses } = this.props
		return (
			<Col>
				<Table
					rowKey={'id'}
					rowClassName={'orderRow'}
					showHeader={false}
					bodyStyle={{ minHeight: this.getTableHeight(expenses) }}
					columns={this.renderColumns()}
					dataSource={expenses}
					size={'small'}
					pagination={false}
					scroll={{ y: this.getScrollAreaHeight() }}
					title={() => this.renderHeader()}
					footer={() => this.renderFooter(expenses.length)}
				/>
			</Col>
		)
	}
}

export default ExpenseCategoryTable;