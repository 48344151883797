import * as fromApi from '../../api/customers';
import * as fromApiGeneric from '../../api/generic';
import { o } from '../../api/odata';
import { fetchOData, fetchAllBatches, PAGE_SIZE } from './fetchOData';
import { getOfflineData, saveOfflineData, saveOfflineCustomer } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_CATEGORIES = 'FETCH_CUSTOMER_CATEGORIES';
export const FETCH_CUSTOMER_SUBCATEGORIES = 'FETCH_CUSTOMER_SUBCATEGORIES';
export const START_FETCH_CUSTOMERS = 'START_FETCH_CUSTOMERS';
export const FINISH_FETCH_CUSTOMERS = 'FINISH_FETCH_CUSTOMERS';
export const START_FETCH_CUSTOMER_CATEGORIES = 'START_FETCH_CUSTOMER_CATEGORIES';
export const FINISH_FETCH_CUSTOMER_CATEGORIES = 'FINISH_FETCH_CUSTOMER_CATEGORIES';
export const START_FETCH_CUSTOMER_SUBCATEGORIES = 'START_FETCH_CUSTOMER_SUBCATEGORIES';
export const FINISH_FETCH_CUSTOMER_SUBCATEGORIES = 'FINISH_FETCH_CUSTOMER_SUBCATEGORIES';
export const FETCH_MAP_CUSTOMERS = 'FETCH_MAP_CUSTOMERS';
export const START_FETCH_MAP_CUSTOMERS = 'START_FETCH_MAP_CUSTOMERS';
export const FINISH_FETCH_MAP_CUSTOMERS = 'FINISH_FETCH_MAP_CUSTOMERS';
export const FETCH_DROPDOWN_CUSTOMERS = 'FETCH_DROPDOWN_CUSTOMERS';
export const START_FETCH_DROPDOWN_CUSTOMERS = 'START_FETCH_DROPDOWN_CUSTOMERS';
export const FINISH_FETCH_DROPDOWN_CUSTOMERS = 'FINISH_FETCH_DROPDOWN_CUSTOMERS';
export const SET_ACTIVE_SEARCH_KEY = 'SET_ACTIVE_SEARCH_KEY';

let lastRequestId = undefined;

// export const fetchCustomers = (filter, page, searchKey = '') => dispatch => {
//   cleanFilter(filter);
//   return fetchOData({
//     resourceName: 'CustomerView',
//     filter,
//     page,
//     beforeRequest: requestId => {
//       lastRequestId = requestId;
//       dispatch({ type: SET_ACTIVE_SEARCH_KEY, searchKey });
//       dispatch({ type: FETCH_CUSTOMERS, customers: [], total: 0 });
//       dispatch({ type: START_FETCH_CUSTOMERS });
//       dispatch({ type: START_FETCH_MAP_CUSTOMERS });
//       // setTimeout(() => fetchCustomerOutstanding(filter, dispatch, requestId), 100);
//       setTimeout(() => fetchMapCustomers(filter, dispatch, requestId), 100);
//     },
//     onSuccess: (value, count, requestId) => {
//       dispatch({ type: FETCH_CUSTOMERS, customers: value, total: count, searchKey });
//     },
//     onError: () => {
//       dispatch({ type: FINISH_FETCH_MAP_CUSTOMERS });
//     },
//     onFinish: () => {
//       dispatch({ type: FINISH_FETCH_CUSTOMERS });
//     }
//   });
// };


let lastSearchKey = undefined;
export const fetchCustomers = (filter, page, searchKey = '') => async dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: SET_ACTIVE_SEARCH_KEY, searchKey });
  dispatch({ type: START_FETCH_CUSTOMERS });
  dispatch({ type: START_FETCH_MAP_CUSTOMERS });
  setTimeout(() => fetchMapCustomers(filter, dispatch), 100);
  let pageIndex = PAGE_SIZE * page;

  if (getOfflineData('state').isSystemOffline) {
    let response;
    var { data, error, recordTotal } = response = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    var filterCutomerData = data.filter(function (el) {
      return (filter.text ? el.Name.toLowerCase().includes(filter.text.toLowerCase()) : el)
        && (filter.text2 ? el.Add.toLowerCase().includes(filter.text2.toLowerCase()) : el)
        && (filter.RouteCode ? el.RouteCode.includes(filter.RouteCode) : el)
        && (filter.OutletCategoryCode ? el.OutletCategoryCode.includes(filter.OutletCategoryCode) : el)
        && (filter.PaymentType ? el.PaymentType.includes(filter.PaymentType) : el)
        && (filter.StatusFilter ? el.StatusFilter.includes(filter.StatusFilter) : el)
    });

    data = filterCutomerData

    if (error) return { error }

    if (data && data.length === 1) dispatch({ type: FETCH_CUSTOMER, customer: data[0], filter });

    const userHierarchyObjectKeys = Object.keys(filter);

    recordTotal = data.length;
    if (userHierarchyObjectKeys[0] === "UserCode") {
      dispatch({ type: FETCH_CUSTOMERS, customers: data, total: recordTotal ? recordTotal : 0, searchKey, filter });
    }

    dispatch({ type: FETCH_CUSTOMERS, customers: getOfflineData('state').isSystemOffline ? data.slice(pageIndex - PAGE_SIZE, pageIndex) : data, total: recordTotal ? recordTotal : 0, searchKey, filter });
    dispatch({ type: FINISH_FETCH_MAP_CUSTOMERS });
    dispatch({ type: FINISH_FETCH_CUSTOMERS });

    return filterCutomerData;
  } else {
    return fromApiGeneric.get('Customer', filter, page).then(response => {

      const { data, error, recordTotal } = response;

      if (error) return { error }

      if (data && data.length === 1) dispatch({ type: FETCH_CUSTOMER, customer: data[0], filter });

      dispatch({ type: FETCH_CUSTOMERS, customers: data, total: recordTotal ? recordTotal : 0, searchKey, filter });

      return response;

    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey) {
        dispatch({ type: FINISH_FETCH_MAP_CUSTOMERS });
        dispatch({ type: FINISH_FETCH_CUSTOMERS });
      }
    })
  }
}

// I have change GetCustomersmap endpoint to Customers for apply  filters for map
const fetchMapCustomers = async (filter, dispatch) => {
  if (getOfflineData('state').isSystemOffline) {
    dispatch({ type: START_FETCH_MAP_CUSTOMERS });
    var { data, error, recordTotal } = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res;
    }).catch((err) => { alert(err); })
    dispatch({ type: FETCH_MAP_CUSTOMERS, customers: data });
    //console.log(data);
    dispatch({ type: FINISH_FETCH_MAP_CUSTOMERS });
    //var { data, error, recordTotal } = readObjectFromIndexDB(TABLES.CUSTOMERS,OBJECT_KEYS.OFFLINE_CUSTOMER_DATA)

  } else {
    dispatch({ type: START_FETCH_MAP_CUSTOMERS });
    return fromApiGeneric.get('Customer', filter).then(response => {
      if (getOfflineData('state').isSystemOffline) {
        var { data, error, recordTotal } = response = getOfflineData('customerData');
      } else {
        var { data, error, recordTotal } = response;
      }
      if (error) return { error }
      dispatch({ type: FETCH_MAP_CUSTOMERS, customers: data });
      return response;

    }).catch(error => {
      return { error }
    }).finally(() => {
      dispatch({ type: FINISH_FETCH_MAP_CUSTOMERS });
    })
  }

}

//   fetchAllBatches({
//     requestId,
//     resource: 'CustomerView',
//     queryOptions,
//     beforeRequest: requestId => {
//       dispatch({ type: START_FETCH_MAP_CUSTOMERS });
//     },
//     filterFunction: customer => filterFunction(customer, filter),
//     onSuccess: (customers, requestId) => {
//       if (lastRequestId === requestId) {
//         dispatch({ type: FETCH_MAP_CUSTOMERS, customers });
//       }
//     },
//     onError: (error, requestId) => {
//       if (lastRequestId === requestId) dispatch({ type: FETCH_MAP_CUSTOMERS, customers: [] });
//     },
//     onFinish: requestId => {
//       if (lastRequestId === requestId) dispatch({ type: FINISH_FETCH_MAP_CUSTOMERS });
//     }
//   });
// };

const cleanFilter = filter => {
  if (filter.UserTag)
    filter.UserTag = filter.UserTag.filter(tag => tag.startsWith('CS'));
  filter.UserCode = undefined;
}


export const fetchDropdownCustomers = filter => async dispatch => {
  dispatch({ type: START_FETCH_DROPDOWN_CUSTOMERS });
  if (getOfflineData('state').isSystemOffline) {

    var customers = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res;
    }).catch((err) => { alert(err); })
    var customers = customers.data;

    dispatch({ type: FETCH_DROPDOWN_CUSTOMERS, customers });
    dispatch({ type: FINISH_FETCH_DROPDOWN_CUSTOMERS });

  } else {
    return fromApi.getAll(filter).then(response => {
      var { customers, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_DROPDOWN_CUSTOMERS, customers });

      return response;
    }).catch(error => {
      dispatch({ type: FETCH_DROPDOWN_CUSTOMERS, customers: [] });
      return { error }
    }).finally(() => {
      dispatch({ type: FINISH_FETCH_DROPDOWN_CUSTOMERS });
    });
  }

};



// export const fetchDropdownCustomers = filter => dispatch => {
//   dispatch({ type: START_FETCH_DROPDOWN_CUSTOMERS });
//   return fromApi.getAll(filter).then(response => {


//     if (getOfflineData('state').isSystemOffline) {
//       var { customers, error } = getOfflineData('customers');
//     } else {
//       var { customers, error } = response;
//     }


//     if (error) return { error }

//     dispatch({ type: FETCH_DROPDOWN_CUSTOMERS, customers });

//     return response;
//   }).catch(error => {
//     dispatch({ type: FETCH_DROPDOWN_CUSTOMERS, customers: [] });
//     return { error }
//   }).finally(() => {
//     dispatch({ type: FINISH_FETCH_DROPDOWN_CUSTOMERS });
//   });
// };

const filterFunction = (customer, filter) => {
  return (filter.UserTags.length === 0 || filter.UserTags.includes(customer.UserTag)) &&
    (!filter.RouteCode || customer.RouteCode === filter.RouteCode) &&
    (!filter.OutletCategoryCode || customer.OutletCategoryCode === filter.OutletCategoryCode) &&
    (!filter.OutletSubCategoryCode || customer.OutletSubCategoryCode === filter.OutletSubCategoryCode) &&
    (!filter.likeName || (!!customer.Name && customer.Name.toLowerCase().includes(filter.likeName.toLowerCase())));
}

// export const fetchCustomer = customerId => dispatch => {
//   return fetchOData({
//     resourceName: 'CustomerView',
//     filter: { No: customerId },
//     page: 1,
//     pageSize: 1,
//     uniqueIdentified: 'FETCH_CUSTOMER',
//     onSuccess: value => {
//       dispatch({ type: FETCH_CUSTOMER, customer: value[0] });
//     }
//   });
// };

export const fetchCustomer = customerId => dispatch => {

  return fromApi.get(customerId).then(response => {
    const { customers, error } = response;

    if (error) return { error }

    if (customers.length > 0) dispatch({ type: FETCH_CUSTOMER, customer: customers[0] });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    dispatch({ type: FINISH_FETCH_CUSTOMERS });
  })
};

async function aggregatePaymentOptions(oldCustomerObj, newCustomer) {
  var paymentTerms = getOfflineData('customerOfflinePaymentTerms');
  var paymentMethods = getOfflineData('customerOfflinePaymentMethods');
  /*
  var paymentTerms = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_TERMS).then((res)=>{
    return res;
  }).catch((err) => { console.log(err);})

  var paymentMethods = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_OFFLINE_PAYMENT_METHODS).then((res)=>{
    return res;
  }).catch((err) => { console.log(err);})
  */

  let customerOfflinePaymentTerms = paymentTerms.data.filter(function (el) {
    return el.CustNo === newCustomer.No
  }
  );

  let customerOfflinePaymentMethods = paymentMethods.data.filter(function (el) {
    return el.CustNo === newCustomer.No
  }
  );

  let pTerms = {
    'data': customerOfflinePaymentTerms,
    'recordTotal': customerOfflinePaymentTerms.length,
    'records': customerOfflinePaymentTerms
  }

  let pMethods = {
    'data': customerOfflinePaymentMethods,
    'recordTotal': customerOfflinePaymentMethods.length,
    'records': customerOfflinePaymentMethods
  }

  newCustomer.paymentTerms = pTerms;
  newCustomer.paymentMethods = pMethods;
  oldCustomerObj.data.push(newCustomer)
  oldCustomerObj.recordTotal = oldCustomerObj.data.length

  insertDataIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA, oldCustomerObj)

}

export const addCustomer = customer => async dispatch => {
  var newCustomer = customer;
  var cuNo = (Math.round((new Date()).getTime() / 1000)).toString();
  newCustomer.Name2 = "";
  newCustomer.No = cuNo;
  newCustomer.NoCus = cuNo;
  newCustomer.Status = "Active";
  newCustomer.StatusFilter = "";
  newCustomer.Sync = false;

  if (getOfflineData('state').isSystemOffline) {
    let oldCustomerObj = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.OFFLINE_CUSTOMER_DATA).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    var response = await readObjectFromIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })

    var callResponse = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })

    let currentActiveCall = callResponse.records[callResponse.records.length - 1]
    if (currentActiveCall.StartReasonDescrip == "Customer Creation") {
      //saveOfflineCustomer(newCustomer);
      if (typeof (response) != "undefined") {
        response.data.push(newCustomer)
        await insertDataIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_DATA, response)
      } else {
        let response = { "data": [customer] }
        await insertDataIndexDB(TABLES.CUSTOMERS, OBJECT_KEYS.CUSTOMER_DATA, response)
      }

      dispatch({ type: FETCH_CUSTOMER, customer });

      setTimeout(
        () => aggregatePaymentOptions(oldCustomerObj, newCustomer),
        2000
      );

      var error = "offline";
      if (error) return { error }
    } else {
      return { ...response, error: "There is an active call for " + currentActiveCall.StartReasonDescrip + ". Please close it and create Customer creation call." };
    }

  } else {
    return fromApi.add(customer).then(response => {

      const { customer, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_CUSTOMER, customer });

      return response;
    }).catch(error => {
      return { error }
    })
  }

};

export const fetchCustomerCategories = () => async dispatch => {
  dispatch({ type: START_FETCH_CUSTOMER_CATEGORIES });

  if (getOfflineData('state').isSystemOffline) {

    const { customerCategories, error } = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.CUSTOMER_CATEGORIES).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    dispatch({ type: FETCH_CUSTOMER_CATEGORIES, customerCategories });

    dispatch({ type: FINISH_FETCH_CUSTOMER_CATEGORIES });

  } else {
    return fromApi.getCustomerCategories().then(response => {

      const { customerCategories, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_CUSTOMER_CATEGORIES, customerCategories });
      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
      dispatch({ type: FINISH_FETCH_CUSTOMER_CATEGORIES });
    });
  }
};

export const fetchCustomerSubcategories = () => dispatch => {
  dispatch({ type: START_FETCH_CUSTOMER_SUBCATEGORIES });
  return o('OutletSubCategoryView').query()
    .then((response, x, y) => {
      dispatch({ type: FETCH_CUSTOMER_SUBCATEGORIES, subcategories: response.value });
      return { subcategories: response.values };
    }).catch(error => {
      return { error };
    }).finally(() => {
      dispatch({ type: FINISH_FETCH_CUSTOMER_SUBCATEGORIES });
    });
  // dispatch({ type: START_FETCH_CUSTOMER_CATEGORIES });
  // return fromApi.getCustomerCategories().then(response => {
  //   const { customerCategories, error } = response;

  //   if (error) return { error }

  //   dispatch({ type: FETCH_CUSTOMER_CATEGORIES, customerCategories });
  //   return response;
  // }).catch(error => {
  //   return { error }
  // }).finally(() => {
  //   dispatch({ type: FINISH_FETCH_CUSTOMER_CATEGORIES });
  // });
};

export const updateCustomer = customer => dispatch => {
  return fromApi.update(customer).then(response => {
    const { customer, error } = response;

    if (error) return { error }

    dispatch({ type: FETCH_CUSTOMER, customer });

    return response;
  }).catch(error => {
    return { error }
  })
};
