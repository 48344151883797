import * as fromApi from '../../api/generic';

export const addTransferLine = transferLine => dispatch => {
  return fromApi.add('TransferLine', transferLine).then(response => {
    const { data, error } = response;

    if (error) {
      dispatch({ type: 'FAILED_TO_ADD_TRANSFER_LINE', transferLines: [{ ...transferLine, added: false }] });
      return { error }
    }

    dispatch({ type: 'FETCH_TRANSFER_LINE_FOR_DOC', transferLines: [{ ...data, added: true }] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchForDoc = DocNo => dispatch => {
  return fromApi.get('TransferLine', { DocNo }).then(response => {
    const { data, error } = response;
    if (!error) {
      const transferLines = data.map(item => ({ ...item, added: true }))
      dispatch({ type: 'FETCH_TRANSFER_LINES_FOR_DOC', DocNo, transferLines });
    }

    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateTransferLine = transferLine => dispatch => {
  return fromApi.update('TransferLine', transferLine).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: 'FETCH_TRANSFER_LINE_FOR_DOC', transferLines: [{ ...data, added: true }] });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteTransferLine = transferLine => dispatch => {
  return fromApi.deleteObject('TransferLine', transferLine).then(response => {
    const { error } = response;

    if (error) return { error };

    dispatch({ type: 'REMOVE_TRANSFER_LINE', transferLines: [transferLine] });
    return response;
  }).catch(error => {
    return { error }
  })
};