import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Tag } from "antd";
import { FaMapMarker, FaPhoneSquare } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from '../../../redux/reducers';
import { CUSTOMERS } from "../../../const/Permissions";

class CustomerCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      case 'Blocked':
        return 'red';
      default:
        return '';
    }
  }


  handleCardClick = () => {
    const { customer, history } = this.props;
    const { No } = customer;
    const { match } = this.props;
    const url = match.url

    if (!!No)
      history.push(url + "/" + No)
  }

  render() {
    const { customer, loggedInUser: { permissions = [] } } = this.props;
    const { No, Name, Name2, Add, PhoneNo, ContactPerson, OutletCategoryCode, OutletSubCategoryCode, Status, OutstandAmount } = customer;
    const status = Status;
    return (
      <Row onClick={permissions.includes(CUSTOMERS.VIEW) && this.handleCardClick}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={5} md={2} lg={2}>
            <span style={{ wordBreak: 'break-all' }}>{No}</span>
          </Col>
          <Col xs={0} sm={10} md={4} lg={4}><span style={styles.name}>{Name + ' ' + Name2}</span></Col>
          <Col xs={0} sm={0} md={5} lg={5}><span>{Add}</span></Col>
          <Col xs={0} sm={0} md={3} lg={3}><span  >{ContactPerson}</span></Col>
          <Col xs={0} sm={0} md={3} lg={3}><span>{PhoneNo}</span></Col>
          <Col xs={0} sm={0} md={3} lg={3}><span>{OutletCategoryCode}</span></Col>
          <Col xs={0} sm={0} md={2} lg={2}><span>{OutletSubCategoryCode}</span></Col>
          <Col xs={0} sm={4} md={3} lg={2} style={{ textAlign: 'center' }}><Tag style={styles.status} color={this.getStatusColor(status)}>{status}</Tag></Col>

          {/* <Col xs={0} sm={5} md={2} lg={2} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={OutstandAmount}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </Col> */}
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={12} sm={0} md={0} lg={0}>
            <Col xs={24} sm={0} md={0} lg={0}><span>{No}</span></Col>
            <Col xs={24} sm={0} md={0} lg={0}><span style={styles.name}>{Name + ' ' + Name2}</span></Col>
            <Col xs={24} sm={0} md={0} lg={0} style={{ color: '#808080', fontSize: 12 }}><FaMapMarker style={styles.icon} /><span>{Add}</span></Col>
          </Col>
          <Col xs={12} sm={0} md={0} lg={0}>
            {/* <Col xs={24} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
              <CurrencyFormat
                value={OutstandAmount}
                displayType={'text'}
                thousandSeparator={true}
                isNumericString={true}
                fixedDecimalScale={true}
                style={styles.price}
                decimalScale={2} />
            </Col> */}

            <Col xs={24} sm={0} md={0} lg={0}><span style={{ float: 'right', color: '#808080', fontSize: 12 }}>{ContactPerson}</span></Col>
            <Col xs={24} sm={0} md={0} lg={0}><span style={{ float: 'right', color: '#808080', fontSize: 12 }}>{PhoneNo}</span></Col>
            <Col xs={24} sm={0} md={0} lg={0}><span style={{ float: 'right', color: '#808080', fontSize: 10 }}>{OutletCategoryCode}</span></Col>
            <Col xs={24} sm={0} md={0} lg={0}><span style={{ float: 'right', color: '#808080', fontSize: 10 }}>{OutletSubCategoryCode}</span></Col>
            <Col xs={24} sm={0} md={0} lg={0}><Tag style={{ float: 'right', marginRight: 0 }} color={this.getStatusColor(status)}>{status}</Tag></Col>
          </Col>

          <Col xs={0} sm={5} md={0} lg={0} style={{ textAlign: 'left', color: '#808080', fontSize: 12 }}><FaPhoneSquare style={styles.icon} /><span>{PhoneNo}</span></Col>
          <Col xs={0} sm={10} md={0} lg={0} style={{ color: '#808080', fontSize: 12 }}><FaMapMarker style={styles.icon} /><span>{Add}</span></Col>
          <Col xs={0} sm={4} md={0} lg={0}><span>{OutletCategoryCode}{OutletSubCategoryCode ? ', ' + OutletSubCategoryCode : ''}</span></Col>

        </Row>

      </Row>
    )
  }
}

const styles = {
  code: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  name: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  statusBottom: {
    fontSize: 8,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: '#808080'
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    paddingLeft: 10,
    paddingRight: 10
  }

}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) }
}

export default withRouter(connect(mapStateToProps, {})(CustomerCard));