import React, { Component } from 'react';
import { Card, Col, Row, PageHeader } from 'antd';
import { ProMateGreen } from "../../../const/Theme";
import { MyMapComponent } from '../GeoMap/GeoMap';
import FormItem from '../../common/FormItem';
import PromatePageHeader from '../../common/PromatePageHeader';

class LocationHistory extends Component {
  state = {
    isMarkerShown: false,
    locationId: 0
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    const { locationId } = this.state;
    setTimeout(() => {
      this.setState({ isMarkerShown: true, locationId: liveLocations.length - 1 === locationId ? 0 : locationId + 1 }, this.delayedShowMarker)
    }, 3000)
  }

  handleMapClick = (event) => {
    console.log("PARAMS ", event.latLng.lat(), event.latLng.lng())
  }

  getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Live Warehouse Location Map'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Card>
            <Row gutter={5}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <FormItem >
                  <MyMapComponent
                    isMarkerShown={this.state.isMarkerShown}
                    location={liveLocations[this.state.locationId]}
                    editable={false}
                    height={600} />
                </FormItem>
              </Col>
            </Row>
          </Card>
        </PromatePageHeader>
      </div>
    )
  }
}

export default LocationHistory;

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

const liveLocations = [
  { lat: 6.9271, lng: 79.8612 },
  { lat: 6.8511, lng: 79.9212 },
  { lat: 6.8412, lng: 79.9654 },
  { lat: 6.9061, lng: 79.9696 },
  { lat: 6.9291, lng: 79.9828 },
  { lat: 6.9518, lng: 79.9133 },
  { lat: 6.9010, lng: 79.8549 },
  { lat: 6.9122, lng: 79.8829 }
]