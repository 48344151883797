import React, { Component } from 'react';
import { PageHeader, Button } from "antd";
import { ProMateGreen } from "../../../const/Theme";
import Container from './Container';
import PromatePageHeader from '../../common/PromatePageHeader';

class RouteView extends Component {
  state = {
    isEdit: false
  }

  render() {
    const { routeId } = this.props.match.params
    const newRoute = routeId === 'new';

    const { match } = this.props;
    const path = match.path
    const isEdit = path.includes("edit");

    return (
      <div>
        <PromatePageHeader
          title={newRoute ? 'New Route' : 'Route Info'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Container routeId={routeId} />
        </PromatePageHeader>
      </div>
    )
  }

}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

export default RouteView;