import { Col, Row, Select, Typography } from "antd";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withToastManager } from 'react-toast-notifications';
import { fetchAllMonths, fetchAllRouteTargetsByFilter, fetchAllYears, fetchMonthlyTarget, updateFilter } from '../../../../../redux/actions/targetScheduler';
import { fetchTerritories } from "../../../../../redux/actions/territories";
import { fetchSalesUserHierarchy } from '../../../../../redux/actions/users';
import { getAllMonths, getAllYears, getAuthData, getFilter, getFilteredRouteTargets, getMonthlyTarget, getSalesUserHierarchy, getTerritories } from "../../../../../redux/reducers";
import { StyledButton } from '../../../common/Buttons';
import { getURLParams, withOutThousandSeperator, withThousandSeperator } from "../../../common/helpers";
import UserHierachy from "../../../common/UserHierachy";

const { Text } = Typography;
const { Option } = Select;

class Header extends Component {
    state = {};
    componentDidMount = async () => {
        const { updateFilter, toastManager, fetchTerritories, fetchAllRouteTargetsByFilter, fetchMonthlyTarget, fetchSalesUserHierarchy, authData, fetchAllMonths, fetchAllYears } = this.props;
        const months = await fetchAllMonths();
        await fetchAllYears();

        const urlParams = await getURLParams(this.props);
        if (urlParams) {
            const data = await fetchAllRouteTargetsByFilter({
                Year: urlParams.Year,
                Month: urlParams.Month,
                UserTag: urlParams.UserTag
            });
            await updateFilter({
                Year: urlParams.Year,
                Month: urlParams.Month,
                UserTag: urlParams.UserTag
            }, this.props);

            // Find Month numeric value
            const monthNamesArray = months?.records.map((month) => (month.Code));

            if (data?.records?.length > 0) {
                const res = await fetchTerritories({ UserTagFilterOne: urlParams.UserTag });
                await updateFilter({ TerrCode: String(res?.records[0]?.TerrCode ?? "") }, this.props);
                await fetchMonthlyTarget({
                    Year: String(urlParams.Year),
                    Month: String(monthNamesArray.indexOf(urlParams.Month) + 1),
                    TerrCode: String(res?.records[0]?.TerrCode ?? "")
                });
                this.props.setTableData(data);
                this.props.setLoading(false);
            } else {
                this.props.setTableData({ records: [] });
                this.props.setLoading(false);
            }
        } else {
            toastManager.add("User details not found.", { autoDismiss: true, appearance: 'error' });
        }
    }

    handleChange = async (name, value) => {
        await this.props.updateFilter({ [name]: value }, this.props);
    };

    handleSearch = async () => {
        const { fetchAllRouteTargetsByFilter, fetchMonthlyTarget, filter } = this.props;
        this.props.setLoading(true);
        const data = await fetchAllRouteTargetsByFilter(
            {
                Year: filter.Year,
                Month: filter.Month,
                UserTag: filter.UserTag
            }
        );

        // Find Month numeric value
        const monthNamesArray = this.props.months?.records.map((month) => (month.Code));

        await fetchMonthlyTarget({
            Year: String(filter.Year),
            Month: String(monthNamesArray.indexOf(filter.Month) + 1),
            TerrCode: String(filter.TerrCode)
        });

        this.props.setTableData(data);
        this.props.setLoading(false);
    }

    render() {
        const { routeTargets, monthlyTarget, filter, territories, months, years } = this.props;

        let balancedTarget = routeTargets.records.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue['PlannedTgt'] == '' ? 0 : currentValue['PlannedTgt'])), 0);
        let finalMonthlyTarget = withOutThousandSeperator(monthlyTarget.records[0]?.TargetAmt) - balancedTarget;

        return (
            <>
                <UserHierachy onUpdate={(value) => { }} />
                <Row style={{ padding: "0px 12px 0px 12px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10 }}>Year:</Text>
                        <Select
                            value={filter.Year}
                            style={{ width: "100%", ...styles.text }}
                            onChange={(value) => this.handleChange('Year', value)}
                        >
                            {years.records.map((year, index) =>
                                (<Option key={`${index}-${year.Code}`} value={year.Code} style={{ ...styles.text }}>{year.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10 }}>Month:</Text>
                        <Select

                            style={{ width: "100%", ...styles.text }}
                            value={filter.Month}
                            onChange={(value) => this.handleChange('Month', value)}
                        >
                            {months.records.map((month, index) =>
                                (<Option key={`${index}-${month.Code}`} value={month.Code} style={{ ...styles.text }}>{month.Description}</Option>)
                            )}
                        </Select>
                    </Col>
                    {/* <Col xs={24} sm={8} lg={4} style={styles.container}>
                        <Text style={{ ...styles.text }}>{hierarchy?.positionType ?? ''}: </Text>
                        <Text style={{ ...styles.text }}>{hierarchy?.user?.name ?? ''}</Text>
                    </Col> */}
                    <Col xs={24} sm={12} lg={6} style={styles.container}>
                        <Text style={{ ...styles.text, marginRight: 10 }}>Terrritory: </Text>
                        <Select

                            style={{ width: "100%", ...styles.text }}
                            value={filter.TerrCode}
                            onChange={(value) => this.handleChange('TerrCode', value)}
                        >
                            {territories.map((territory, index) =>
                                (<Option key={`${index}-${territory.TerrCode}`} value={territory.TerrCode} style={{ ...styles.text }}>{territory.TerrName}</Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24} sm={8} lg={2} style={{ marginTop: 4 }}>
                        <StyledButton color="#1890ff" label="Search" width={100} onClick={() => this.handleSearch()} />
                    </Col>
                    {/* <Col xs={24} sm={8} lg={2} style={styles.container}>
                        <Text style={{ ...styles.text }}>Terrritory: </Text>
                        <Text style={{ ...styles.text }}>{this.props.routeTargets.records[0]?.TerrName}</Text>
                    </Col> */}
                    <Col xs={24} sm={8} lg={4}></Col>
                </Row>
                <Row style={{ padding: "0px 12px 0px 12px" }} gutter={[5, 5]} justify="center" align="middle">
                    <Col xs={24} sm={8} lg={5} style={styles.container}>
                        <Text style={{ ...styles.text }}>Monthly Target: </Text>
                        <Text style={{ ...styles.text }}>{this.props.monthlyTarget.records[0]?.TargetAmt ?? '0.00'}</Text>
                    </Col>
                    <Col xs={24} sm={8} lg={5} style={styles.container}>
                        <Text style={{ ...styles.text }}>Balance Target: </Text>
                        <Text style={{ ...styles.text, color: "red" }}>{withThousandSeperator(finalMonthlyTarget)}</Text>
                    </Col>
                    <Col xs={24} sm={8} lg={14}></Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    salesUserHierarchy: getSalesUserHierarchy(state),
    authData: getAuthData(state),
    months: getAllMonths(state),
    years: getAllYears(state),
    monthlyTarget: getMonthlyTarget(state),
    routeTargets: getFilteredRouteTargets(state),
    filter: getFilter(state),
    territories: getTerritories(state)
})

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchMonthlyTarget,
    fetchAllRouteTargetsByFilter,
    fetchTerritories,
    updateFilter
}

export default withToastManager(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));

const styles = {
    text: {
        color: '#000000',
    },
    container: {
        height: "100%",
        padding: "5px 5px",
        display: "flex",
        alignItems: "center",
        gap: 5,
        justifyContent: "flex-start",
    }
}