const ITEM_COUNT = 100;
let data = [];

export const WAREHOUSE_TYPES = ["MOBILE", "STATIC"];
export const warehouseLocations = ["LOCATION001", "LOCATION002", "LOCATION003"];
export const paymentTypes = ["CASH", "CHEQUE", "FRIMI"];
export const statuses = ["BLOCK", "UNBLOCK"];
export const blockedReasons = [{ description: "Block Reson 1", type: "Type 1" }, { description: "Block Reson 2", type: "Type 2" }, { description: "Block Reson 3", type: "Type 1" }];
const addresses = [
  "184 Hausman Street, Wolcott, West Virginia, 3513",
  "231 Coleman Street, Twilight, Alaska, 1682",
  "243 Rockaway Avenue, Carbonville, Oregon, 2499",
  "925 Hamilton Avenue, Neahkahnie, Washington, 3323",
  "685 Coventry Road, Kirk, Delaware, 4046",
  "955 Mermaid Avenue, Levant, Colorado, 4088",
  "780 National Drive, Machias, Ohio, 5077"
]

export const generateDemoWarehouses = () => {
  data = [];
  for (let i = 0; i < ITEM_COUNT; i++) {
    data.push(generateDemoWarehouse(i));
  }
}

const generateDemoWarehouse = index => {

  const item = {
    warehouseNo: "warehouse" + index,
    name: "Promate Warehouse " + index,
    type: WAREHOUSE_TYPES[getRandomNumber(0, WAREHOUSE_TYPES.length)],
    status: statuses[getRandomNumber(0, statuses.length)],
    warehouseLocations: warehouseLocations,
    distributor: "distributor" + index,
    blockedReasons,
    location: [{ lat: 0, lng: 0 }],
    address: addresses[getRandomNumber(0, addresses.length)]

  }

  return item;
}

const getRandomNumber = (from, to) => Math.floor((Math.random() * to) + from)

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const getDemoWarehouses = () => {
  return data;
}

export const getDemoWarehouse = id => {
  return data.find(item => item.warehouseNo === id);
}