let demoPurchasingCreditNotes = []

export const generateDemoCreditNotes = () => {

  demoPurchasingCreditNotes = [];
  demoPurchasingCreditNotes.push(defaultOrder)
  demoPurchasingCreditNotes.push({ ...defaultOrder2, salesLines: getSalesLines(defaultOrder2.creditNoteNo) })

  for (let i = 1; i < 25; i++) {
    demoPurchasingCreditNotes.push({
      creditNoteNo: "CN " + i,
      creditNoteType: "Type " + i,
      sellToCustomerCode: "Customer " + i,
      sellToCustomerName: "Customer " + i + 1,
      billToCustomerCode: "Customer Code " + randoNumber(),
      billToCustomerName: "Customer Name " + i,
      locationCode: "Location Code " + randoNumber(),
      creditNoteDate: randomDate(new Date(2012, 0, 1), new Date()),
      salesLines: getSalesLines("CN " + i),
      salespersonCode: "Sales Person " + randoNumber(),
      totalDiscountAmount: 0,
      totalAmount: randoNumber(),
      amountIncludingVat: randoNumber(),
      status: randomStatus(),
      sessionId: randoNumber(),
      balanceToSettle: randoNumber(1000)
    })
  }

  return demoPurchasingCreditNotes;
}

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const randoNumber = (range = 5000) => {
  return Math.floor((Math.random() * range) + 1);
}

const randomStatus = () => {
  const statuses = ["Open", "Close", "Confirmed"];
  return statuses[Math.floor((Math.random() * 3) + 0)];
}

const getSalesLines = creditNoteNo => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      creditNoteNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: randoNumber(),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines;
}

export const getDemoCreditNotes = () => {
  generateDemoCreditNotes();
  return demoPurchasingCreditNotes;
}

export const getDemoCreditNote = id => {
  const demoCreditNote = demoPurchasingCreditNotes.find(creditNote => creditNote.creditNoteNo === id);
  return demoCreditNote;
}

export const getDemoCreditNotesByCount = (count = 5) => {
  return demoPurchasingCreditNotes.slice(0, count);
}

export const getSalesLineItem = creditNoteNo => {
  let salesLines = [];

  for (let i = 0; i < 5; i++) {
    salesLines.push({
      itemNo: "item " + randoNumber(),
      description: "Item Description",
      creditNoteNo,
      lineNo: "Line " + i,
      unitPrice: randoNumber(),
      locationCode: randoNumber(),
      mapLocation: randoNumber(),
      lineSessionId: randoNumber(),
      quantity: 1,
      lineAmount: randoNumber(),
      discountPercentage: randoNumber(),
      discountAmount: randoNumber(),
      amount: randoNumber(),
      lineIncludeVatAmount: randoNumber(),
      inventoryItemId: "Inventory Item Id " + i,
    })
  }

  return salesLines[Math.floor((Math.random() * 3) + 0)];
}

const defaultOrder = {

  invoiceNo: "new",
  orderType: "Type 3",
  creditNoteNo: "new",
  creditNoteType: "Type ",
  sellToCustomerCode: "Customer 012 ",
  sellToCustomerName: "Customer Name ",
  billToCustomerCode: "Distributor Code " + randoNumber(),
  billToCustomerName: "Distributor Name ",
  locationCode: "Location Code " + randoNumber(),
  creditNoteDate: randomDate(new Date(2012, 0, 1), new Date()),
  salesLines: getSalesLines("new").slice(0, 1),
  salespersonCode: "Sales Person " + randoNumber(),
  totalDiscountAmount: 0,
  totalAmount: randoNumber(),
  amountIncludingVat: randoNumber(),
  status: "Open",
  sessionId: randoNumber(),
  balanceToSettle: randoNumber(1000)
}

const defaultOrder2 = {

  invoiceNo: "CN 0",
  orderType: "Type 3",
  creditNoteNo: "CN 0",
  creditNoteType: "Type ",
  sellToCustomerCode: "Customer 012 ",
  sellToCustomerName: "Customer Name ",
  billToCustomerCode: "Distributor Code " + randoNumber(),
  billToCustomerName: "Distributor Name ",
  locationCode: "Location Code " + randoNumber(),
  creditNoteDate: randomDate(new Date(2012, 0, 1), new Date()),
  salespersonCode: "Sales Person " + randoNumber(),
  totalDiscountAmount: 0,
  totalAmount: randoNumber(),
  amountIncludingVat: randoNumber(),
  status: "Open",
  sessionId: randoNumber(),
  balanceToSettle: randoNumber(1000)
}