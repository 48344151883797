import React, { Component } from 'react';
import { Card, Divider, Col, Button, Row, Form, Table, Popconfirm } from 'antd';
import { format } from "currency-formatter";
import { STOCK_TAKE } from '../../../const/Permissions';
import { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class Details extends Component {

  state = {
    quantityError: "",
    selectedRowKeys: [],
  }


  validateQuantity = (value) => {
    if (value > 30) {
      this.setState({ quantityError: "error" })
    } else {
      this.setState({ quantityError: "" })
      this.props.onUpdateOrderQty(value)
    }
  }


  onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  hasDocLineChanged = () => {
    const { docLine, docLineItemDatabase } = this.props;
    return docLine.PhysicalQty !== docLineItemDatabase.PhysicalQty ||
      docLine.LineReason !== docLineItemDatabase.LineReason;
  }

  getChangeAwareValue = key => {
    if (this.hasDocLineChanged()) return 'N/A';
    else return this.props.docLine[key];
  }

  renderRightAlignedTableRow = (title, value) => this.renderTableRow(title, value, 'right')

  renderEditableTableRow = (title, key, error, enabled = true) => {
    const { docLine, onUpdateNumberField } = this.props;
    const value = parseFloat((docLine[key] || '0').replaceAll(',', ''));
    return this.renderTableRow(
      title,
      <Form.Item
        validateStatus={error}
        style={{ marginTop: -8 }}>
        <input
          className="ant-input"
          value={value}
          style={{ textAlign: 'right', }}
          type="number"
          min="0"
          disabled={!enabled}
          onChange={event => onUpdateNumberField(key, event.target.value)}
          onFocus={event => event.target.select()} />
      </Form.Item>
    );
  }

  renderEditableTableRowText = (title, key, error, enabled = true) => {
    const { docLine, onUpdateTextField } = this.props;
    const value = docLine[key] || '';
    return this.renderTableRowCustom(
      title,
      <Form.Item
        validateStatus={error}
        style={{ marginTop: -8 }}>
        <TextArea
          className="ant-input"
          value={value}
          rows={6}
          disabled={!enabled}
          //style={{ textAlign: 'right' }}
          onChange={(value, e) => onUpdateTextField(key, value)}
        //onFocus={event => event.target.select()
        />
      </Form.Item>
    );
  }

  renderTableRow = (title, value, alignment = 'left') =>
    <tr style={{ display: 'flex' }}>
      <td ></td>
      <td style={{ width: 100, verticalAlign: 'center' }}>{title}</td>
      <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
      <td style={{ width: window.innerWidth < 768 ? window.innerWidth - 105 : undefined, textAlign: alignment }}>{value}</td>
    </tr>

  renderTableRowCustom = (title, value, alignment = 'left') =>
    <tr style={{ display: 'flex' }}>
      <td ></td>
      <td style={{ width: 100, verticalAlign: 'center' }}>{title}</td>
      <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
      <td style={{ width: window.innerWidth < 768 ? window.innerWidth - 105 : undefined, textAlign: alignment, width: '70%' }}>{value}</td>
    </tr>

  render() {
    const { inventoryItem, docLine, onUpdateOrderQty, onUpdateOrder, newOrderItem, resource, onResetLine, loggedInUser: { permissions = [] }, stockTakeHeader } = this.props;
    const { quantityError, differenceQuantityError } = this.state;
    const visibleSytemData = permissions.includes(STOCK_TAKE.VISIBLE_SYSTEM_DATA);
    const visiblePhysicalData = permissions.includes(STOCK_TAKE.VISIBLE_PHYSICAL_DATA);
    const { Status } = stockTakeHeader;

    const enablePhysicalDataColumn = Status === 'Open';
    //const enableDifferenceQtyDataColumn = Status === 'Confirmed';
    const enableReasonDataColumn = Status === 'Open';

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {inventoryItem.LotNo && this.renderTableRow('CODE', inventoryItem.LotNo)}
            {inventoryItem.Description && this.renderTableRow('Description', inventoryItem.Description)}

          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {inventoryItem.Brand && this.renderTableRow('Brand', inventoryItem.Brand)}
            {inventoryItem.Type && this.renderTableRow('Product type', inventoryItem.Type)}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>

            {this.renderRightAlignedTableRow('Unit Price', docLine.UnitCost)}
            {visibleSytemData && this.renderRightAlignedTableRow('System Qty', docLine.SystemQty)}
            {visiblePhysicalData && this.renderEditableTableRow('Physical Qty', 'PhysicalQty', quantityError, enablePhysicalDataColumn)}
            {visibleSytemData && this.renderRightAlignedTableRow('DeferanceQty', this.getChangeAwareValue('DeferanceQty'))}
            {visibleSytemData && this.renderRightAlignedTableRow('DeferanceValue ', this.getChangeAwareValue('DeferanceValue'))}
            {visibleSytemData && this.renderEditableTableRowText('Reason', 'LineReason', null, enableReasonDataColumn)}

          </Col>
        </Row>

        <Row gutter={15} style={{ display: 'flex', float: 'right', marginTop: 15 }}>
          {this.hasDocLineChanged() && <Col>
            <Button
              type="primary"
              onClick={onResetLine}>
              Reset item
            </Button>
          </Col>}

          <Col>
            <Button
              type="primary"
              onClick={onUpdateOrder}>{newOrderItem ? 'Add' : 'Update item'}
            </Button>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default Details;