import * as fromApi from '../../api/calls';
import * as fromGenericApi from '../../api/generic';
import { getOfflineData, saveOfflineCall, saveOfflineData, saveOfflineCallBackup } from '../../localStorage';
import { readObjectFromIndexDB, insertDataIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const FETCH_CALLS = 'FETCH_CALLS';
export const START_FETCH_CALLS = 'START_FETCH_CALLS';
export const FINISH_FETCH_CALLS = 'FINISH_FETCH_CALLS';
export const FETCH_SINGLE_CALL = 'FETCH_SINGLE_CALL';
export const START_FETCH_SINGLE_CALL = 'START_FETCH_SINGLE_CALL';
export const FINISH_FETCH_SINGLE_CALL = 'FINISH_FETCH_SINGLE_CALL';
export const FETCH_START_CALL_REASONS = 'FETCH_START_CALL_REASONS';
export const FETCH_END_CALL_STATUSES = 'FETCH_END_CALL_STATUSES';
export const FETCH_CALL_LOG = 'FETCH_CALL_LOG';
export const START_FETCH_CALL_REGISTER = 'START_FETCH_CALL_REGISTER';
export const FINISH_FETCH_CALL_REGISTER = 'FINISH_FETCH_CALL_REGISTER';
export const FETCH_ACTIVE_CALL = 'FETCH_ACTIVE_CALL';
export const FETCH_SALES_VALUE = 'FETCH_SALES_VALUE';
export const START_FETCH_SALES_VALUE = 'START_FETCH_SALES_VALUE';
export const FINISH_FETCH_SALES_VALUE = 'FINISH_FETCH_SALES_VALUE';

let lastSearchKey;
export const fetchCalls = (filter, page = 1, searchKey = '') => async dispatch => {
  lastSearchKey = searchKey;
  dispatch({ type: START_FETCH_CALLS });

  if (getOfflineData('state').isSystemOffline) {

    const response = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })

    if (typeof response === 'undefined') {
      records = [];
      recordTotal = 0;
    } else {
      var { records, error, recordTotal, responseMetadata } = response;
    }
    records = records.reverse();
    var callsObj = {
      records: records,
      recordTotal: recordTotal,
      records: records,
      responseMetadata: {
        TotalSalesValue: 0,
      }
    };
    recordTotal = records.length;
    dispatch({ type: FETCH_CALLS, calls: records, searchKey, total: recordTotal, metadata: { TotalSalesValue: 0 } });
    dispatch({ type: FINISH_FETCH_CALLS });
    return callsObj;
  } else {
    return fromGenericApi.get('CallRegister', filter, page).then(response => {
      const { data, error, recordTotal, responseMetadata } = response;

      if (error) return { error }

      dispatch({ type: FETCH_CALLS, calls: data, searchKey, total: recordTotal, metadata: responseMetadata });
      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
      if (lastSearchKey === searchKey)
        dispatch({ type: FINISH_FETCH_CALLS });
    });
  }
};

export const fetchCall = No => async dispatch => {
  dispatch({ type: START_FETCH_SINGLE_CALL, No });

  if (getOfflineData('state').isSystemOffline) {
    await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
      var call = res.records.find(function (call) {
        return call.No == No;
      });
      dispatch({ type: FETCH_SINGLE_CALL, call: call });
      fetchEventLog(No, dispatch);
      return res;
    })
      .catch((err) => { alert(err); })
      .finally(() => {
        dispatch({ type: FINISH_FETCH_SINGLE_CALL, No });
      })
  } else {
    const filter = { No };
    return fromGenericApi.get('CallRegister', filter).then(response => {
      var { data, error, recordTotal, responseMetadata } = response;
      if (error) return { error }

      if (data.length > 0) {
        dispatch({ type: FETCH_SINGLE_CALL, call: data[data.length - 1] });
        fetchEventLog(No, dispatch);
      }
      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
      dispatch({ type: FINISH_FETCH_SINGLE_CALL, No });
    });
  }
};

// export const fetchActiveCall = UserCode => dispatch => {
//   const filter = { UserCode, EndStatusCode: '' };

//   return fetchOData({
//     resourceName: 'CallRegisterView',
//     filter,
//     page: 1,
//     pageSize: 1,
//     uniqueIdentified: 'ACTIVE_CALL',
//     onSuccess: value => {
//       dispatch({ type: FETCH_ACTIVE_CALL, call: value[0] });
//     }
//   });
// }

export const fetchActiveCall = UserCode => async dispatch => {

  const filter = { UserCode, StatusFilter: 'Open' };
  if (getOfflineData('state').isSystemOffline) {
    var callList = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA);
    callList = callList.records;
    var data = callList.filter(callList => callList.Status == 'Open' && callList.SyncStart == false);
    if (data.length > 0) {
      dispatch({ type: FETCH_ACTIVE_CALL, call: data[data.length - 1] });
    } else {
      dispatch({ type: FETCH_ACTIVE_CALL, call: undefined });
    }
    var error = "offline";
    if (data.length > 0) {
      dispatch({ type: FETCH_ACTIVE_CALL, call: data[0] });
    } else {
      dispatch({ type: FETCH_ACTIVE_CALL, call: undefined });
    }

    return { data: data }

  } else {
    return fromGenericApi.get('CallRegister', filter).then(response => {
      var { data, error, recordTotal, responseMetadata } = response;

      if (error) return { error }

      if (data.length > 0) {
        dispatch({ type: FETCH_ACTIVE_CALL, call: data[0] });
      } else {
        dispatch({ type: FETCH_ACTIVE_CALL, call: undefined });
      }
      return response;
    }).catch(error => {
      return { error }
    }).finally(() => {
    });
  }
}

// return fromGenericApi.get('CallRegister', filter).then(response => {

//   if (getOfflineData('state').isSystemOffline) {
//     var callList = getOfflineData('offlineCallData').data;
//     var data = callList.filter(callList => callList.Status == 'Open');
//     if (data.length > 0) {
//       dispatch({ type: FETCH_ACTIVE_CALL, call: data[data.length - 1] });
//     } else {
//       dispatch({ type: FETCH_ACTIVE_CALL, call: undefined });
//     }
//     var error = "offline";
//   } else {
//     var { data, error, recordTotal, responseMetadata } = response;
//   }

//   if (error) return { error }

//   if (data.length > 0) {
//     dispatch({ type: FETCH_ACTIVE_CALL, call: data[0] });
//   } else {
//     dispatch({ type: FETCH_ACTIVE_CALL, call: undefined });
//   }
//   return response;
// }).catch(error => {
//   return { error }
// }).finally(() => {
// });


const fetchEventLog = (CallRegID, dispatch) => {
  dispatch({ type: START_FETCH_CALL_REGISTER, CallRegID });

  const filter = { CallRegID };
  return fromGenericApi.get('EventLogRegister', filter).then(response => {
    const { data, error, recordTotal, responseMetadata } = response;

    if (error) return { error }

    dispatch({ type: FETCH_CALL_LOG, log: data, CallRegID });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    dispatch({ type: FINISH_FETCH_CALL_REGISTER, CallRegID });
  });
};

export const fetchStartCallReasons = () => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    const { callStartReasons, error } = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.CALL_START_REASONS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })
    dispatch({ type: FETCH_START_CALL_REASONS, callStartReasons });
    console.log("callStartReasons");
    console.log(callStartReasons);

  } else {
    return fromApi.getStartCallReasons().then(response => {
      const { callStartReasons, error } = response;
      // if (getOfflineData('state').isSystemOffline) {
      //   var { callStartReasons, error } = getOfflineData('callStartReasons');
      // } else {
      //   var { callStartReasons, error } = response;
      // }

      if (error) return { error }

      dispatch({ type: FETCH_START_CALL_REASONS, callStartReasons });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const addCall = (call, props) => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    const response = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA).then((res) => {
      return res;
    }).catch((err) => { console.log(err); })
    console.log(response);
    return new Promise((resolve, reject) => {
      var CallNo = Math.round((new Date()).getTime() / 1000);
      call.No = CallNo
      call.CallNo = CallNo
      call.SyncStart = false;
      call.SyncEnd = false;

      if (typeof response != 'undefined') {
        var lastCall = response.records[response.records.length - 1];
        if (lastCall.Status == 'Open') {
          var No = lastCall.No;
          props.toastManager.add('There is a open call. Please close it before create new call.', { autoDismiss: true, appearance: 'error' });
          props.history.push('/calls/' + No);
        } else {
          response.records.push(call)
          insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA, response);
          props.toastManager.add('Call started.', { autoDismiss: true, appearance: 'success' });
          props.history.goBack();
        }
      } else {

        let arr = { "records": [call] }
        insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA, arr);
        props.toastManager.add('Call started.', { autoDismiss: true, appearance: 'success' });
        props.history.goBack();
      }

      dispatch({ type: FETCH_SINGLE_CALL, call });
      resolve(call);
      // if (name === 'Dave') {
      //    resolve("Promise resolved successfully");
      // }
      // else {
      //    reject(Error("Promise rejected"));
      // }
    });

  } else {
    return fromApi.add(call).then(response => {
      const { call, error } = response;
      if (error) return { error }
      dispatch({ type: FETCH_SINGLE_CALL, call });
      props.toastManager.add('Call started.', { autoDismiss: true, appearance: 'success' });
      props.history.goBack();
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const fetchEndCallStatuses = () => async dispatch => {
  if (getOfflineData('state').isSystemOffline) {
    const { callEndStatuses, error } = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.CALL_END_REASONS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })

    dispatch({ type: FETCH_END_CALL_STATUSES, callEndStatuses });
    return callEndStatuses;
  } else {
    return fromApi.getCallEndStatuses().then(response => {

      // if (getOfflineData('state').isSystemOffline) {
      //   var { callEndStatuses, error } = getOfflineData('callEndStatuses');
      // } else {
      //   var { callEndStatuses, error } = response;
      // }
      const { callEndStatuses, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_END_CALL_STATUSES, callEndStatuses });
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

export const endCall = updatedCall => async dispatch => {
  console.log(updatedCall);

  var call = updatedCall;

  if (getOfflineData('state').isSystemOffline) {
    var data = await readObjectFromIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA);
    data = data.records;
    return new Promise((resolve, reject) => {

      var newArr = data.map(obj => {
        if (obj.No === updatedCall.No) {
          return { ...obj, Status: 'Close', EndDateTime: updatedCall.EndDateTime, EndStatusCode: updatedCall.EndStatusCode, EndStatusDescrip: updatedCall.EndStatusDescrip, EndMapLoc: updatedCall.EndMapLoc };
        }
        return obj;
      });
      let arr = { "records": newArr }
      insertDataIndexDB(TABLES.CALLS, OBJECT_KEYS.OFFLINE_CALL_DATA, arr)
      dispatch({ type: FETCH_SINGLE_CALL, call });
      resolve(newArr);
    });
  } else {
    return fromApi.update(updatedCall).then(response => {
      const { call, error } = response;
      console.log(response);

      if (error) return { error }

      dispatch({ type: FETCH_SINGLE_CALL, call });
      console.log("call ending");
      console.log(call)
      return response;
    }).catch(error => {
      return { error }
    })
  }
};

