import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { fetchDistributors } from "../../redux/actions/distributors";
import { fetchDistributorPaymentHeaders } from "../../redux/actions/distributorPaymentHeaders";
import { fetchPaymentTypes } from '../../redux/actions/paymentHeaders';
import { getDistributorPaymentHeadersBySearchKey, getAuthData, getLoggedInUser, getTopPosition, getFlatPositions, getDistributorPaymentHeaderTotal, getDistributorPaymentHeaderMetadata, isFetchingDistributorPaymentHeaders, isCallInProgress, getInProgressCall } from "../../redux/reducers";
import { ProMateGreen } from "../../const/Theme";
import DistributorPaymentTable from "./DistributorPaymentTable";
import moment from 'moment';
import PromatePageHeader from '../common/PromatePageHeader';
import { withToastManager } from 'react-toast-notifications';
import { DISTRIBUTOR_PAYMENTS } from "../../const/Permissions";
import { FaPlus } from 'react-icons/fa';
import { getSearchKey, handleUrlSearchParams, redirectToUrlState } from '../../utils/Search';
import { updateFilterUserHierarchy } from '../common/SelectSalesUserHierarchy';
import { dateTimeFormatFilterUpdate, dateTimeFormatFilterView } from '../../const/Formats';

class DistributorPayments extends Component {
  baseUrl = window.location.pathname;
  state = {
    loading: false,
    filter: {
      DocType: undefined,
      UserCode: undefined,
      text: undefined,
      text_2: undefined,
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf("day"),
      to: moment(new Date()).endOf("day"),
      from2: undefined,
      to2: undefined,
      from3: undefined,
      to3: undefined,
      StatusFilter: undefined,
    }
  }

  componentDidMount() {
    const { match, authData: { userId }, fetchDistributors, fetchPaymentTypes } = this.props;
    const { distributorId } = match.params

    fetchPaymentTypes();
    fetchDistributors({ UserCode: userId });

    this.setState({
      filter: {
        ...this.state.filter,
        UserCode: userId,
        CusCode: distributorId
      }
    }, () => this.handleUrlSearchParams(this.props));
  }

  componentWillReceiveProps = nextProps => {
    this.handleUrlSearchParams(nextProps);
  }

  handleUrlSearchParams = nextProps => {
    const { distributorId } = this.props.match.params;
    handleUrlSearchParams(this.baseUrl, this.props, nextProps, this.state.currentSearchKey, (filter, currentPage, currentSearchKey) => {
      this.setState({ filter, currentPage, currentSearchKey }, () => {
        this.loadDistributorPayments(nextProps);
      });
    }, { ...this.state.filter, CustCode: distributorId });
  }

  loadDistributorPayments = props => {
    const { topPosition, flatPositions } = props;
    const { currentPage, currentSearchKey, filter: { UserHierarchy } } = this.state;
    const filter = updateFilterUserHierarchy(UserHierarchy, this.state.filter, flatPositions, topPosition);
    filter.UserCode = this.props.authData.userId;
    this.setState({ filter });
    this.fetchDistributorPayments(filter, currentPage, currentSearchKey);
  }

  handleSearchClick = () => {
    const redirected = this.redirectToUrlState();
    if (!redirected) {
      this.reloadResults();
    }
  }

  handlePaginationChange = page => this.redirectToUrlState(page);

  redirectToUrlState = (page = 1) => redirectToUrlState(this.baseUrl, this.props, this.state.filter, page);

  fetchDistributorPaymentPage = page => {
    const { filter, currentSearchKey } = this.state;
    this.setState({ currentPage: page });
    this.fetchDistributorPayments(filter, page, currentSearchKey);
  }

  reloadResults = () => {
    const { filter, currentPage, currentSearchKey } = this.state;
    this.fetchDistributorPayments(filter, currentPage, currentSearchKey);
  }

  fetchDistributorPayments = (filter, page, searchKey) => {
    const updatedFilter = {
      ...filter,
      ReceivingDateFilter: moment(filter.from).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to).add('minutes', -330).format(dateTimeFormatFilterUpdate),
      from: undefined,
      to: undefined,
      PostingDateFilter: filter.from2 && filter.to2 ? moment(filter.from2).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to2).add('minutes', -330).format(dateTimeFormatFilterUpdate) : undefined,
      from2: undefined,
      to2: undefined,
      DocDateFilter: filter.from3 && filter.to3 ? moment(filter.from3).add('minutes', -330).format(dateTimeFormatFilterUpdate) + '..' + moment(filter.to3).add('minutes', -330).format(dateTimeFormatFilterUpdate) : undefined,
      from3: undefined,
      to3: undefined,
      CustNo: undefined,
      CusCode: filter.CustNo,
      UserCode: undefined,
      text: undefined,
      CollectionNo: filter.text ? '*' + filter.text + '*' : undefined,
      text_2: undefined,
      PayNo: filter.text_2 ? '*' + filter.text_2 + '*' : undefined
    }

    this.props.fetchDistributorPaymentHeaders(updatedFilter, page, searchKey);
  }

  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } });

  handleInputTextChange = (filter, value) => {
    const { topPosition, flatPositions } = this.props;
    let newFilter = { ...this.state.filter, [filter]: value };

    switch (filter) {
      case 'UserHierarchy':
        newFilter = updateFilterUserHierarchy(value, { ...this.state.filter }, flatPositions, topPosition);
        break;
      case 'RouteCode':
        newFilter.CustNo = undefined;
        break;
      case 'StatusFilter':
        newFilter.StatusFilter = value;
        break;
      case 'ExpCategory':
        newFilter.ExpCategory = value;
        break;
      default:
        break;
    }

    this.setState({ filter: newFilter });
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  render() {
    const { filter, currentPage } = this.state;
    const { loading, loggedInUser: { permissions = [], roleCode }, match, distributorPayments, total, totalAmount, topPosition } = this.props;
    const { ongoingCall, inCall } = this.props;
    const { distributorId } = match.params;

    console.log("Payments", distributorPayments)

    return (
      <div>
        <PromatePageHeader
          title={'Distributor Payments'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()} extra={permissions.includes(DISTRIBUTOR_PAYMENTS.CREATE) && [
            <Button onClick={this.onClickNew} style={this.context.screenType === 'MOBILE' ? styles.addBtnMobile : styles.addBtnWeb}>
              {
                this.context.screenType === 'MOBILE' ? (
                  <FaPlus color={'white'} size={25} />
                ) : (<span>New</span>)
              }
            </Button>,
          ]}>

          <Spin spinning={(loading && distributorPayments.length === 0) || !topPosition} tip={"Loading..."}>
            <DistributorPaymentTable
              distributorPayments={distributorPayments}
              filter={filter}
              currentPage={currentPage}
              handleInputDateChange={this.handleInputDateChange}
              handleInputTextChange={this.handleInputTextChange}
              handleSearchClick={this.handleSearchClick}
              authData={this.props.authData}
              onPaginationChange={this.handlePaginationChange}
              total={total}
              totalAmount={totalAmount} />
          </Spin>
        </PromatePageHeader>
      </div>
    )
  }

  onClickNew = () => {
    const { ongoingCall, inCall, match } = this.props;
    const { distributorId } = match.params

    this.props.history.push(this.props.match.url + "/new");
  }
}

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  callValidationBtn: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    zIndex: 10000,
    position: 'absolute',
    top: 30
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};

DistributorPayments.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { distributorId } = ownProps.match.params
  const searchKey = getSearchKey();
  const totalAmount = getDistributorPaymentHeaderMetadata(state, searchKey) === "" ? "0.00" : getDistributorPaymentHeaderMetadata(state, searchKey)

  return {
    distributorPayments: getDistributorPaymentHeadersBySearchKey(state, searchKey),
    authData: getAuthData(state),
    loggedInUser: getLoggedInUser(state),
    topPosition: getTopPosition(state),
    flatPositions: getFlatPositions(state),
    total: getDistributorPaymentHeaderTotal(state, searchKey),
    totalAmount: totalAmount,
    loading: isFetchingDistributorPaymentHeaders(state)
  };
};

export default withToastManager(connect(mapStateToProps, { fetchDistributorPaymentHeaders, fetchDistributors, fetchPaymentTypes })(DistributorPayments));