import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuthData } from '../../redux/reducers';
import { Modal, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { isCallInProgress } from '../../redux/reducers'

const { confirm } = Modal;

class CallStatusValidateButton extends Component {

  showConfirm = (context, props) => {
    const { authData, match } = props;
    context.showModal = true
    const { customerId } = match.params;
    const urlSerch = customerId ? '?customer_id=' + customerId : '';
    confirm({
      title: 'Do you want to start a call?',
      content: 'Currently there is no call in progress. In order to continue this transaction you have to start a new call.',
      maskClosable: false,
      onOk() {
        context.showModal = false
        props.history.push("/salesRepresentatives/" + authData.userId + "/calls/new" + urlSerch);
      },
      onCancel() {
        context.showModal = false
      },
    });
  }

  render() {
    const { authData: { roleCode }, onClick, inCall, ...rest } = this.props;
    return <Button onClick={!inCall && roleCode === 'CSR' && !this.showModal ? () => this.showConfirm(this, this.props) : onClick} {...rest} />
  }
}

const mapStateToProps = state => ({ authData: getAuthData(state), inCall: isCallInProgress(state) })

export default withRouter(connect(mapStateToProps, {})(CallStatusValidateButton));