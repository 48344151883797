import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Table, Tag } from "antd";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import AdvanceSearchFilters from "../../components/common/AdvanceSearchFilters";
import ExpenseCard from "./ExpenseCard";
import ExpenseCardHeader from "./ExpenseCardHeader";
import CurrencyFormat from "react-currency-format";
import _ from 'lodash'
import { EXPENSES } from "../../const/Permissions";
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import moment from 'moment';

class ExpenseTable extends Component {

  componentDidMount = () => {
    window.addEventListener('locationchange', this.onUrlChange);
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 70;
  }

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  getTableHeight = expenses => {
    if (expenses && expenses.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { filter, handleInputDateChange, handleInputTextChange, handleSearchClick } = this.props;

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={entireColumn}
          FROM_DATE_PICKER={oneSixthOfColumn}
          TO_DATE_PICKER={oneSixthOfColumn}
          SELECT_EXPENSES_CATEGORY={oneSixthOfColumn}
          SELECT_EXPENSES_STATUS={oneSixthOfColumn}
          SEARCH_TEXT={{ ...(oneSixthOfColumn), placeholder: 'Doc No' }}
          SEARCH_BUTTON={oneSixthOfColumn}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInputDateChange={handleInputDateChange}
          filter={filter}
        />
        <ExpenseCardHeader />
      </div>
    )
  }

  renderFooter = () => {
    const { onPaginationChange, total, currentPage, metaData } = this.props;
    const { TotalAmount } = metaData;

    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={onPaginationChange}
        total={TotalAmount}
        noOfRecordLabel={'expenses'}
        style={{ paddingRight: 33 }}
      />
    );
  }

  renderColumns = () => {
    return [{
      render: expense => <ExpenseCard expense={expense} />
    }]
  }

  render() {
    const { expenses, loggedInUser: { permissions = [] }, history } = this.props;
    return (
      <Col>
        <Table
          rowKey={'expenseNo'}
          rowClassName={'table-row-dark'}
          onRowClick={(data) => (permissions.includes(EXPENSES.VIEW) || permissions.includes(EXPENSES.UPDATE)) && history.push("/expenses/" + encodeURIComponent(data.CollectionNo))}
          dataSource={expenses}
          showHeader={false}
          columns={this.renderColumns()}
          size={'small'}
          bodyStyle={{ minHeight: this.getTableHeight(expenses) }}
          pagination={false}
          scroll={{ y: this.getScrollAreaHeight() }}
          scroll={this.context.screenType === 'DESKTOP' ? { y: this.getScrollAreaHeight() } : undefined}
          title={() => this.renderHeader()}
          summary={() => {
            return (<div>apple</div>)
          }}
          footer={() => this.renderFooter()}
        />
      </Col>
    )
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderRow: {
    backgroundColor: 'black'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  orderDateTitle: {
    fontSize: 12
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%'
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}

ExpenseTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(ExpenseTable));