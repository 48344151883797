import React, { Component } from 'react';
import { Table, Button } from "antd";
import { withRouter } from 'react-router-dom';
import Input from '../../common/EnglishInput';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class StockTransferTable extends Component {

  renderColumns = () => {

    return [{
      title: 'No',
      width: 30,
      align: 'right',
      render: (unitPrice, object, index) => (index + 1)
    }, {
      title: 'Code',
      dataIndex: 'itemNo',
      key: 'itemNo',
      width: 60,
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    }, {
      title: 'From Location Quantity',
      dataIndex: 'fromQuantity',
      key: 'fromQuantity',
      fixed: 'right',
      align: 'right',
      width: 60
    }, {
      title: 'Transfer Quantity',
      dataIndex: 'transferQuantity',
      key: 'transferQuantity',
      width: 80,
      fixed: 'right',
      align: 'right',
      render: (transferQuantity, object, index) => this.renderTransferQuantity(transferQuantity, object, index),
    }]
  }

  renderTransferQuantity = (transferQuantity, object, index) => {
    const { onInputChange } = this.props;
    return (
      <Input
        className="ant-input"
        value={transferQuantity}
        type="text"
        style={{ width: '100%' }}
        onChange={value => onInputChange("transferQuantity", value, object, index, "quantityError")}
        onFocus={(event) => event.target.select()} />
    )


  }

  renderFooter = () => {
    return <div style={{ display: 'flex', justifyContent: 'flex-end' }}><Button onClick={this.props.OnClickAddItem} type="primary">Add Items</Button></div>
  }


  render() {
    const { height = 500, order, history } = this.props;
    const tableHeight = height - 30;

    return (
      <Table
        rowKey={'lineNo'}
        columns={this.renderColumns()}
        dataSource={order.salesLines}
        size={'small'}
        pagination={false}
        scroll={{ x: 800, y: tableHeight }}
        // onRowClick={rowData => (!order.links || order.links.length === 0) && history.push({ pathname: "/salesOrders/" + order.orderNo + "/salesLines/"+rowData.lineNo, state: { itemId: 65, order } })}
        // footer={(!order.links || order.links.length === 0) && this.renderFooter} />
        footer={(order.status === 'Open') && this.renderFooter} />
    )
  }
}

export default withRouter(StockTransferTable);