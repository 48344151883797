import { Button, Row, Select, Spin } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import Api from '../../../../api/consts';
import { generateCustomerWiseVisitReport } from '../../../../api/reports';
import { ProMateGreen } from '../../../../const/Theme';
import { fetchRoutes } from '../../../../redux/actions/routes';
import { fetchAllMonths, fetchAllYears } from '../../../../redux/actions/targetScheduler';
import { fetchSalesUserHierarchy } from '../../../../redux/actions/users';
import { getAllMonths, getAllYears, getAuthData, getRoutesByUser } from '../../../../redux/reducers';
export class CustomerWiseVisitReport extends Component {
    constructor(props) {
        super(props);
        this.treeData = [];
    }

    state = {
        loading: false,
        generating: false,
        filter: {
            UserTag: undefined,
            RouteCode: undefined,
            Month: String(moment().format("MMM")).toUpperCase(),
            Year: moment().format("YYYY"),
            VisitStatus: undefined,
            ProdStatus: undefined
        },
        CSRUsers: [],
        status: [
            'All',
            "Yes",
            "No"
        ],
        visitStatus: ['All', 'Yes', 'No'],
        prodStatus: [],
    }
    async componentDidMount() {
        const { fetchSalesUserHierarchy, authData, fetchAllMonths, fetchAllYears, fetchRoutes } = this.props;

        this.setState({ loading: true });

        let results = await fetchSalesUserHierarchy(authData);
        await fetchAllMonths();
        await fetchAllYears();

        results = results.records.map((result) => {
            return JSON.parse(result.jSONSalesHichyStruct);
        });
        for (const record of results) {
            this.generateTreeObject(record);
        }
        const routes = await fetchRoutes({ UserTagFilterOne: this.treeData.length > 0 ? this.treeData[0].value : undefined }, 0);
        this.setState({
            loading: false,
            CSRUsers: this.treeData,
            filter: {
                ...this.state.filter,
                UserTag: this.treeData.length > 0 ? this.treeData[0].value : undefined,
                RouteCode: (Array.isArray(routes.data) && routes.data.length > 0) ? routes.data[0]['RouteCode'] : undefined
            }
        });
    }

    handleSelectChange(type, value) {
        if (type === 'VisitStatus') {
            let finalResult = [];
            switch (value) {
                case 'All':
                    finalResult = ["All"]
                    break;
                case 'Yes':
                    finalResult = ["Yes", "No"]
                    break;
                case 'No':
                    finalResult = ["No"]
                    break;
            }
            this.setState({
                prodStatus: finalResult,
                filter: {
                    ...this.state.filter,
                    [type]: value,
                    ProdStatus: undefined,
                }
            });
        } else {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [type]: value
                }
            });
        }
    }

    generateTreeObject = (input) => {
        const arr = Object.values(input)
        return arr.map(item => {
            const { user } = item;
            if (user?.role === "CSR") {
                this.treeData.push(
                    {
                        title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                        key: item.positionCode,
                        value: item.positionCode,
                        role: user?.role,
                        code: user.code,
                        name: user?.name
                    }
                );
            }
            return {
                title: `${item.positionCode} - ${user?.name} (${user?.role})`,
                key: item.positionCode,
                value: item.positionCode,
                children: this.generateTreeObject(item.descendantStructure)
            }
        })
    }

    generateReport = async () => {
        const { filter: { UserTag, RouteCode, Month, Year, VisitStatus, ProdStatus } } = this.state;
        const { toastManager } = this.props;

        if (UserTag && VisitStatus && ProdStatus) {
            try {
                this.setState({
                    generating: true
                });

                const response = await generateCustomerWiseVisitReport({
                    UserTag,
                    RouteCode,
                    Month,
                    Year,
                    VisitStatus,
                    ProdStatus,
                });

                if (!response.error) {
                    if (response.url !== "DBReports/null") {
                        setTimeout(() => {
                            this.setState({ generating: false });
                            window.open(Api.files.download.url(response.url), '_blank');
                        }, 30000);
                    } else {
                        toastManager.add('Report details not found report. ', { autoDismiss: true, appearance: 'info' });
                        this.setState({ generating: false });

                    }
                } else {
                    toastManager.add('Could not generate the report. ' + response.error, { autoDismiss: true, appearance: 'error' });
                    this.setState({ generating: false });
                }
            } catch (error) {
                toastManager.add('Could not generate the report.', { autoDismiss: true, appearance: 'error' });
                this.setState({ generating: false });
            }
        } else {
            toastManager.add('Please fill required details before generate report. ', { autoDismiss: true, appearance: 'error' });
        }
    }

    handleUpdateUserHierarchy = async (UserTag) => {
        const { fetchRoutes } = this.props;
        this.setState({ loading: true })
        const routes = await fetchRoutes({ UserTagFilterOne: UserTag }, 0);
        this.setState({
            loading: false,
            CSRUsers: this.treeData,
            filter: {
                ...this.state.filter,
                RouteCode: (Array.isArray(routes.data) && routes.data.length > 0) ? routes.data[0]['RouteCode'] : undefined
            }
        });
    }

    render() {
        const { Option } = Select;
        const { years, months, routes } = this.props;
        const { filter: { UserTag, Month, Year, RouteCode, VisitStatus, ProdStatus }, CSRUsers, loading, generating, prodStatus, visitStatus } = this.state;
        return (
            <Spin spinning={loading} tip={"Loading Details"}>
                <div style={{ width: "100%", border: `1px solid ${ProMateGreen}`, height: "320px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ padding: "10px 0" }}>
                        <h3>
                            <b>Customer Wise Visit Report</b>
                        </h3>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%", padding: "0px 15px" }}>
                        <div style={{ display: "flex", gap: 10 }}>
                            <div style={{ width: "100%" }}>
                                <Select
                                    value={Year}
                                    style={{ width: "100%" }}
                                    onChange={(value) => this.handleSelectChange('Year', value)}
                                >
                                    {years?.records.map((year, index) =>
                                        (<Option key={index + year.Code} value={year.Code} >{year.Description}</Option>)
                                    )}
                                </Select>
                            </div>
                            <div style={{ width: "100%" }}>
                                <Select
                                    value={Month}
                                    style={{ width: "100%" }}
                                    onChange={(value) => this.handleSelectChange('Month', value)}
                                >
                                    {months?.records.map((month, index) =>
                                        (<Option key={index + month.Code} value={month.Code} >{month.Description}</Option>)
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Select
                                showSearch
                                placeholder={'Select CSR User'}
                                style={{ width: '100%' }}
                                onChange={value => [this.handleSelectChange('UserTag', value), this.handleUpdateUserHierarchy(value)]}
                                value={UserTag}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {CSRUsers.map((user, index) =>
                                    <Option key={index} value={user.value}>{user.title}</Option>
                                )}
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Select
                                showSearch
                                placeholder={'Select Route'}
                                value={RouteCode}
                                style={{ width: "100%" }}
                                onChange={(value) => this.handleSelectChange('RouteCode', value)}
                            >
                                {routes?.map((route, index) =>
                                    (<Option key={index + route.RouteCode} value={route.RouteCode} >{route.RouteName}</Option>)
                                )}
                            </Select>
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                            <div style={{ width: "100%" }}>
                                <Select
                                    value={VisitStatus}
                                    style={{ width: "100%" }}
                                    placeholder="Visit Status"
                                    onChange={(value) => this.handleSelectChange('VisitStatus', value)}
                                >
                                    {visitStatus.map((status, index) =>
                                        (<Option key={index + status + index} value={status} >{status}</Option>)
                                    )}
                                </Select>
                            </div>
                            <div style={{ width: "100%" }}>
                                <Select
                                    value={ProdStatus}
                                    style={{ width: "100%" }}
                                    placeholder="Prod Status"
                                    onChange={(value) => this.handleSelectChange('ProdStatus', value)}
                                >
                                    {prodStatus.map((status, index) =>
                                        (<Option key={index + status} value={status} >{status}</Option>)
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", bottom: 0, left: 0, right: 0 }}>
                            <Button style={{ marginBottom: 20 }} type='primary' onClick={() => this.generateReport()} disabled={generating}>
                                {generating ? 'Generating...' : 'Generate Report'}
                            </Button>
                        </Row>
                    </div>
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    const authData = getAuthData(state);
    const years = getAllYears(state);
    const months = getAllMonths(state);
    const routes = getRoutesByUser(state);

    return { authData, years, months, routes }
}

const mapDispatchToProps = {
    fetchSalesUserHierarchy,
    fetchAllMonths,
    fetchAllYears,
    fetchRoutes
}

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(CustomerWiseVisitReport))