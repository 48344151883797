import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Row, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from '../../redux/reducers';
import { dateFormatView } from '../../const/Formats';
import { STOCK_TRANSFERS } from '../../const/Permissions';
import { stockTransferStatuses } from '../../redux/reducers/transferHeaders';

class StockTransferCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { stockTransfer, history, loggedInUser: { permissions = [] } } = this.props;
    const { DocNo, PostingDate, FromLocCode, FromLocDescrip, ToLocCode, ToLocDescrip, Status, CreatedUserName } = stockTransfer;
    const mappedStatus = stockTransferStatuses[Status];

    return (
      <Row onClick={() => permissions.includes(STOCK_TRANSFERS.VIEW) && history.push('/stockTransfers/' + encodeURIComponent(DocNo))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={6} md={6} lg={4} style={{ textAlign: 'left' }}>
            <span style={styles.docNo}>{DocNo}</span>
          </Col>
          <Col xs={0} sm={4} md={4} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.date}>{moment(PostingDate).format(dateFormatView)}</span>
          </Col>

          <Col xs={0} sm={0} md={0} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(mappedStatus)}>{mappedStatus}</Tag>
          </Col>

          <Col xs={0} sm={0} md={0} lg={6} style={{ textAlign: 'left' }}>
            <span>{FromLocCode} - {FromLocDescrip}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={6} style={{ textAlign: 'left' }}>
            <span>{ToLocCode} - {ToLocDescrip}</span>
          </Col>

          <Col xs={0} sm={0} md={0} lg={4} style={{ textAlign: 'left' }}>
            <span>{CreatedUserName}</span>
          </Col>

          <Col xs={0} sm={7} md={7} lg={0} style={{ textAlign: 'left' }}>
            <span>{FromLocCode}</span>
          </Col>
          <Col xs={0} sm={7} md={7} lg={0} style={{ textAlign: 'left' }}>
            <span>{ToLocCode}</span>
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{FromLocCode}</span>
          </Col>

          {/* mobileview first row */}
          <Col xs={10} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 700 }}>{DocNo}</span>
          </Col>

          <Col xs={7} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 700 }}>{moment(PostingDate).format(dateFormatView)}</span>
          </Col>

          <Col xs={7} sm={0} md={0} lg={0} style={{ textAlign: 'right' }}>
            <Tag style={styles.statusMobile} color={this.getStatusColor(mappedStatus)}>{mappedStatus}</Tag>
          </Col>


        </Row>

        <Row style={styles.lowerRow}>
          <Col xs={0} sm={6} md={6} lg={0} style={{ textAlign: 'left' }}>
            <Tag style={styles.status} color={this.getStatusColor(mappedStatus)}>{mappedStatus}</Tag>
          </Col>
          <Col xs={0} sm={4} md={4} lg={0} style={{ textAlign: 'left' }}>
            {CreatedUserName}
          </Col>
          <Col xs={0} sm={7} md={7} lg={0} style={{ textAlign: 'left' }}>
            {FromLocDescrip}
          </Col>
          <Col xs={0} sm={7} md={7} lg={0} style={{ textAlign: 'left' }}>
            {ToLocDescrip}
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            {'< ' + FromLocCode}
          </Col>
          <Col xs={16} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            {FromLocDescrip}
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            {'> ' + ToLocCode}
          </Col>
          <Col xs={16} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            {ToLocDescrip}
          </Col>
        </Row>
      </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(StockTransferCard));

const styles = {
  docNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10
  },
  date: {
    fontSize: 12,
    fontWeight: 700
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
  },
  statusMobile: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: -5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 10

  }
}