import * as fromApi from '../../api/generic';
import * as fromApiCommon from '../../api/commonFetch';
import { getOfflineData } from '../../localStorage';
import { readObjectFromIndexDB } from '../../indexDB';
import { TABLES, OBJECT_KEYS } from '../../const/IndexDBObjects'

export const START_FETCH_SALES_ORDER_TYPES = 'START_FETCH_SALES_ORDER_TYPES';
export const FETCH_SALES_ORDER_TYPES = 'FETCH_SALES_ORDER_TYPES';
export const FINISH_FETCH_SALES_ORDER_TYPES = 'FINISH_FETCH_SALES_ORDER_TYPES';
export const FETCH_SALES_DISCOUNT_REASON = 'FETCH_SALES_DISCOUNT_REASON';

// export const fetchSalesOrderTypes = () => dispatch => {
//   dispatch({ type: START_FETCH_SALES_ORDER_TYPES });

//   // if (getOfflineData('state').isSystemOffline) {

//   //   const { customerCategories, error } = await readObjectFromIndexDB(TABLES.COMMON, OBJECT_KEYS.CUSTOMER_CATEGORIES).then((res) => {
//   //     return res;
//   //   }).catch((err) => { alert(err); })

//   //   dispatch({ type: FETCH_CUSTOMER_CATEGORIES, customerCategories });

//   //   dispatch({ type: FINISH_FETCH_CUSTOMER_CATEGORIES });

//   // } else {
//   return fromApiCommon.getSalesOrderType().then(response => {

//     const { salesOrderTypes, error } = response;

//     if (error) return { error }

//     dispatch({ type: FETCH_SALES_ORDER_TYPES, salesOrderTypes });
//     return response;
//   }).catch(error => {
//     return { error }
//   }).finally(() => {
//     dispatch({ type: FINISH_FETCH_SALES_ORDER_TYPES });
//   });
//   //}
// };

export const fetchSalesDiscountReason = (filter = {}, page = 0) => async dispatch => {

  if (getOfflineData('state').isSystemOffline) {
    const data = await readObjectFromIndexDB(TABLES.SALES_ORDERS, OBJECT_KEYS.DISCOUNT_REASONS).then((res) => {
      return res;
    }).catch((err) => { alert(err); })
    dispatch({ type: FETCH_SALES_DISCOUNT_REASON, salesDiscountReasons: data });

  } else {
    return fromApi.get('SalesDiscountReasons', filter, page).then(response => {
      const { data, error } = response;

      if (error) return { error }

      dispatch({ type: FETCH_SALES_DISCOUNT_REASON, salesDiscountReasons: data });
      return response;
    }).catch(error => {
      return { error }
    });
  }
};

