import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchTerritories } from '../../redux/actions/territories'
import { getAuthData, isFetchingTerritories, getTerritories } from "../../redux/reducers";

const { Option } = Select;

class SelectTerritory extends Component {
  state = { loading: false }

  // handleFetchTerritories = props => {
  //   const { filter = {}, fetchTerritories, setDefaultTerritoryOption, authData } = props;
  //   if (filter.UserCode) {
  //     const filter = { UserCode: authData.userId };
  //     this.setState({ loading: true });
  //     fetchTerritories(filter).then(response => {
  //       if (response.territories && response.territories.length === 1) {
  //         setDefaultTerritoryOption(response.territories[0])
  //       }
  //       this.setState({ loading: false })
  //     });
  //   }
  // }

  setDefaultFilter = () => {
    const { territories, onChange, value } = this.props;
    if (territories.length === 1 && value !== territories[0].TerrCode) {
      setTimeout(() => {
        onChange('TerritoryCode', territories[0].TerrCode, true);
      }, 0);
    }
  }

  render() {
    //this.setDefaultFilter();

    const {
      territories,
      onChange,
      showSearch = true,
      placeholder = 'Select Territory',
      allowClear = true,
      value,
      disabled = false,
      isFetchingTerritories,
      style = { width: '100%' }
    } = this.props

    return (
      <Select
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={(value) => onChange('TerritoryCode', value)}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={style}
        loading={isFetchingTerritories}
      >
        {territories.map(trr =>
          <Option value={trr.TerrCode}>{trr.TerrName}</Option>
        )}
      </Select>
    )
  }
}

const mapStateToProps = state => {
  const authData = getAuthData(state);
  return {
    authData,
    territories: getTerritories(state),// Load filtered territories by hearachy filter
    isFetchingTerritories: isFetchingTerritories(state)
  };
};

export default connect(mapStateToProps, { fetchTerritories })(SelectTerritory);