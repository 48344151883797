import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Row, Tag } from "antd";
import { FaIdCard } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";
import { withRouter } from 'react-router-dom';
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import { DISTRIBUTOR_PAYMENTS } from "../../const/Permissions";

class PaymentCard extends Component {
  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Released':
        return 'orange';
      case 'Posted':
        return 'blue';
      case 'Reversed':
        return 'red';
      case 'Returned':
        return 'red';
      case 'Customer Confirmed':
        return 'green';
      case 'On Hold':
        return 'red';
      case 'Rejected':
        return 'red';
      default:
        return 'orange';
    }
  }

  getDbStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  render() {
    const { distributorPayment, history, customerCategory, loggedInUser: { permissions = [] } } = this.props;
    const { CollectionNo, ReceivingDate, CollectType, PayNo, DocDate, PostingDate, CusCode, CusName, CustStatus, OrderStatus, Amount, BankAccount, BankBranchCode, BankCode, CollectDateTime, DocDateFilter, PaymentGroup, Remarks, ReturnedDate, UserCode } = distributorPayment;

    return (
      <Row onClick={() => permissions.includes(DISTRIBUTOR_PAYMENTS.VIEW) && history.push(this.props.match.url + "/" + encodeURIComponent(CollectionNo))}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{CollectionNo}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{moment(ReceivingDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{CollectType}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{PayNo}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{moment(DocDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{PostingDate && moment(PostingDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'left' }}>
            <p style={styles.distributorPaymentNo}>{CusCode}</p>
          </Col>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.distributorPaymentNo}>{CusName}</span>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getDbStatusColor(CustStatus)}>{CustStatus}</Tag>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={Amount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              style={styles.price}
              decimalScale={2} />
          </Col>

          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>
            <span style={{ overflowWrap: 'break-word' }}>{CollectionNo}</span>
          </Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'center', ...styles.normalBold }}>
            <span>{moment(ReceivingDate).format(dateFormatView)}</span>
          </Col>
          <Col xs={8} sm={8} md={0} lg={0} style={{ textAlign: 'right' }}>
            <Tag style={{ ...styles.status }} color={this.getStatusColor(OrderStatus)}>{OrderStatus}</Tag>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={24} sm={24} md={0} lg={0} style={{ textAlign: 'left' }}>
            <span>{CusName}</span>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>
            <span>{CollectType}</span>
          </Col>
          <Col xs={6} sm={6} md={0} lg={0} style={{ textAlign: 'left', ...styles.normalBold }}>
            <span>{PayNo}</span>
          </Col>
          <Col xs={12} sm={12} md={0} lg={0} style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={Amount || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={{ ...styles.price, ...styles.bigBold }}
              decimalScale={2} />
          </Col>
        </Row>
      </Row>
    )
  }
}

const styles = {
  largeBold: {
    fontSize: 18,
    fontWeight: 700
  },
  bigBold: {
    fontSize: 16,
    fontWeight: 700
  },
  normalBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  distributorPaymentNo: {
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10
  },
  distributorPaymentDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: -10
  },
  price: {
    fontSize: 18,
    fontWeight: 600
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

const mapStateToProps = (state, ownProps) => {
  const loggedInUser = getLoggedInUser(state);
  return { loggedInUser };
}

export default withRouter(connect(mapStateToProps, {})(PaymentCard));