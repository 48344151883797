import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Select, Input, AutoComplete, Button, Icon } from "antd";
import { getCustomer, getCustomersBySearchKey, getStartCallReasons, getCallEndStatuses, getDropdownCustomers, isFetchingDropdownCustomers } from '../../../redux/reducers';
import FormItem from '../../common/FormItem';
import { withRouter } from 'react-router-dom';
import CallHeader from './Header';
import { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';
import { getSearchKey } from '../../../utils/Search';

const Option = AutoComplete.Option;

class CallForm extends Component {

  render() {
    const {
      call,
      customers = [],
      fetchingCustomers,
      startCallReasons = [],
      callEndStatuses = [],
      callId,
      onInputChange,
      startReasonCodeError,
      custNoError,
      endStatusCodeError,
      customerRequired,
      customerParamId
    } = this.props;


    const { CustNo, StartReasonCode, Remarks, Status, EndStatusCode } = call;
    const isCallOpen = Status && Status === 'Open';
    return (
      <div>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {call && this.props.callId === "new" && <CallHeader callId={callId} call={call} />}

            <Col style={{ marginBottom: 10 }}>
              <FormItem label={"Start Reason"} error={startReasonCodeError} lg={24} md={24} sm={24} xs={24}>
                <Select
                  disabled={Status}
                  placeholder={"Select Reason to start call"}
                  value={StartReasonCode}
                  onChange={value => onInputChange("StartReasonCode", value, "startReasonCodeError")}
                  style={{ width: '100%' }}>
                  {startCallReasons.map(reason => <Option key={reason.Code} value={reason.Code}>{reason.Description}</Option>)}
                </Select>
              </FormItem>
              {customerRequired && <FormItem label={"Customer"} error={custNoError} lg={24} md={24} sm={24} xs={24}>
                <Select
                  showSearch
                  optionFilterProp='Name'
                  disabled={Status || (customers.length === 0 && fetchingCustomers)}
                  placeholder={fetchingCustomers ? 'Loading customers...' : "Search customers"}
                  value={CustNo}
                  onChange={value => onInputChange("CustNo", value, "custNoError")}
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLocaleLowerCase())}>
                  {customers.sort((c1, c2) => c1.Name.toLowerCase() < c2.Name.toLowerCase() ? -1 : 1).map(customer => <Option key={customer.No} value={customer.No}>{customer.Name}</Option>)}
                </Select>
              </FormItem>}
              <FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24}>
                <TextArea
                  disabled={Status && !isCallOpen}
                  rows={7}
                  placeholder={"Type here"}
                  value={Remarks}
                  onChange={value => onInputChange("Remarks", value, "")}
                >
                </TextArea>
              </FormItem>
              {(this.props.callId !== "new") && <FormItem label={"Call End Status"} error={endStatusCodeError} lg={24} md={24} sm={24} xs={24}>
                <Select
                  disabled={!isCallOpen}
                  placeholder={"Select call end status"}
                  value={EndStatusCode}
                  onChange={value => onInputChange("EndStatusCode", value, "endStatusCodeError")}
                  style={{ width: '100%' }}>
                  {callEndStatuses.map(status => <Option key={status.Code} value={status.Code}>{status.Description}</Option>)}
                </Select>
              </FormItem>}
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const customers = getDropdownCustomers(state);
  const fetchingCustomers = isFetchingDropdownCustomers(state);
  const startCallReasons = getStartCallReasons(state);
  const callEndStatuses = getCallEndStatuses(state);

  return { customers, fetchingCustomers, startCallReasons, callEndStatuses };
};


export default withRouter(connect(mapStateToProps, {})(CallForm));