import React, { Component } from 'react';
import { Card, Col, Comment, Icon, Row, Timeline, Input, PageHeader } from 'antd';
import { ProMateGreen } from "../../../const/Theme";
import moment from 'moment'
import PromatePageHeader from '../../common/PromatePageHeader';

const { Search } = Input

class TransactionHistory extends Component {
  state = {
    filterText: undefined
  }

  renderComment = transaction =>
    <Timeline.Item>
      <Comment
        author={<h3>{transaction.transaction}</h3>}
        content={
          <div>
            <p>{transaction.customer}</p>
            <p>{transaction.address}</p>
          </div>}
        datetime={moment(transaction.time).format()}
      />
    </Timeline.Item>

  filterLocations = location => {
    const { filterText } = this.state
    const { company, address } = location;

    if (!filterText) return true

    return this.isMatch(filterText, company) || this.isMatch(filterText, address)
  }

  isMatch = (filterText, value) => value.toLowerCase().includes(filterText.toLowerCase())

  render() {
    return (
      <div>
        <PromatePageHeader
          title={'Transaction History'}
          style={styles.pageHeader}
          onBack={() => this.props.history.goBack()}>
          <Card>
            <Row gutter={5} style={{ marginBottom: 20 }}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Search
                    placeholder="Input search text"
                    onSearch={value => this.setState({ filterText: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Col>
            </Row>

            <Timeline>
              {locations.filter(this.filterLocations).map(location => this.renderComment(location))}
            </Timeline>
          </Card>
        </PromatePageHeader>
      </div>
    )
  }
}

export default TransactionHistory;

const styles = {
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
};

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const locations = [
  {
    "transaction": "Create a new collection",
    "customer": "Elisa Carson",
    "company": "ZANITY",
    "phone": "+1 (935) 433-2418",
    "address": "256 Flatbush Avenue, Dupuyer, Tennessee, 1455",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Update Sales order",
    "customer": "Chapman Byrd",
    "company": "MOMENTIA",
    "phone": "+1 (839) 439-3847",
    "address": "320 Atkins Avenue, Barrelville, Alabama, 8162",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create new sales order",
    "customer": "Carole Schultz",
    "company": "NUTRALAB",
    "phone": "+1 (984) 445-2609",
    "address": "275 Garnet Street, Stagecoach, Oregon, 8867",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create a new sales order",
    "customer": "Reynolds Hoffman",
    "company": "UPDAT",
    "phone": "+1 (945) 490-3485",
    "address": "149 Wallabout Street, Edneyville, Michigan, 4849",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Update sales order",
    "customer": "Deloris Holden",
    "company": "ASSISTIX",
    "phone": "+1 (825) 550-2084",
    "address": "928 Chester Avenue, Edgewater, Washington, 3002",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Confirmed collection",
    "customer": "Velez Gates",
    "company": "WEBIOTIC",
    "phone": "+1 (994) 526-3395",
    "address": "135 Montague Terrace, Bowden, Pennsylvania, 6753",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Confirmed sales order",
    "customer": "Neal Abbott",
    "company": "GAPTEC",
    "phone": "+1 (902) 585-3432",
    "address": "911 Oceanic Avenue, Kiskimere, Kentucky, 6310",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create a new collection",
    "customer": "Deleon Potter",
    "company": "VISUALIX",
    "phone": "+1 (908) 519-2035",
    "address": "906 Forest Place, Marbury, Minnesota, 3110",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create a new collection",
    "customer": "Maldonado Kent",
    "company": "TECHTRIX",
    "phone": "+1 (980) 524-3480",
    "address": "223 Cumberland Street, Advance, Maryland, 7662",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create a new collection",
    "customer": "Deena Beard",
    "company": "BULLJUICE",
    "phone": "+1 (838) 474-2197",
    "address": "176 Seaview Court, Warsaw, Alabama, 6914",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create a new collection",
    "customer": "Alexandra Savage",
    "company": "EXTRO",
    "phone": "+1 (813) 456-3063",
    "address": "705 Dewitt Avenue, Hollins, Georgia, 3118",
    time: randomDate(new Date(2012, 0, 1), new Date())
  },
  {
    "transaction": "Create a new collection",
    "customer": "Pena Wallace",
    "company": "ZOID",
    "phone": "+1 (893) 437-3163",
    "address": "239 Cherry Street, Darlington, New York, 5380",
    time: randomDate(new Date(2012, 0, 1), new Date())
  }
]