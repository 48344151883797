import * as fromApi from '../../api/generic';

export const FETCH_STOCKTAKE_LINES = 'FETCH_STOCKTAKE_LINES';
export const FETCH_STOCKTAKE_LINE = 'FETCH_STOCKTAKE_LINE';
export const FAILED_TO_ADD_STOCKTAKE_LINE = 'FAILED_TO_ADD_STOCKTAKE_LINE';
export const REMOVE_STOCKTAKE_LINE = 'REMOVE_STOCKTAKE_LINE';
export const START_FETCH_STOCKTAKE_LINES = 'START_FETCH_STOCKTAKE_LINES';
export const FINISH_FETCH_STOCKTAKE_LINES = 'FINISH_FETCH_STOCKTAKE_LINES';

// export const addStockTakeLine = stockTakeLine => dispatch => {
//     return fromApi.add('StockTakeLine', stockTakeLine).then(response => {
//       const { data, error } = response;

//       if (error) { 
//         dispatch({ type: FAILED_TO_ADD_STOCKTAKE_LINE, stockTakeLine: { ...stockTakeLine, added: false } });
//         return { error } 
//       }

//       dispatch({ type: FETCH_STOCKTAKE_LINE, stockTakeLine: { ...data, added: true } });
//       return response;
//     }).catch(error => {
//       return { error }
//     })
//   };

export const fetchStockTakeLines = filter => dispatch => {
  //const updatedFilter = { ...filter, NoZeroQty: "Yes" }
  dispatch({ type: START_FETCH_STOCKTAKE_LINES });
  return fromApi.get('StockTakeLine', filter).then(response => {
    const { data, error } = response;

    if (error) return { error };

    const stockTakeLines = data.map(item => ({ ...item, added: true }))
    dispatch({ type: FETCH_STOCKTAKE_LINES, stockTakeLines: stockTakeLines });

    return response;
  }).catch(error => {
    return { error }
  }).finally(() => {
    dispatch({ type: FINISH_FETCH_STOCKTAKE_LINES });
  });
};

export const updateStockTakeLine = stockTakeLine => dispatch => {
  return fromApi.update('StockTakeLine', stockTakeLine).then(response => {
    const { data, error } = response;

    if (error) return { error };

    dispatch({ type: FETCH_STOCKTAKE_LINE, stockTakeLine: { ...data, added: true } });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteStockTakeLine = stockTakeLine => dispatch => {
  return fromApi.deleteObject('StockTakeLine', stockTakeLine).then(response => {
    const { error } = response;

    if (error) return { error };

    dispatch({ type: REMOVE_STOCKTAKE_LINE, stockTakeLine });
    return response;
  }).catch(error => {
    return { error }
  })
};