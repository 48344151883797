import React, { Component } from 'react';
import { Table, Button, Tag } from "antd";
import { connect } from "react-redux";
import { getAuthData, getSalesHeadersByTypeAndCustomer, getPaymentLineMapForDoc, getOutstandingInvoiceHeadersByCustomer } from "../../../redux/reducers";
import { FaCheck, FaSave, FaUndo, FaTrash } from "react-icons/fa";
import { format } from "currency-formatter";
import { withRouter } from "react-router-dom";
import { dateFormatView } from '../../../const/Formats';
import moment from "moment";
const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTable extends Component {
  state = {
    LocCode: undefined,
    text: undefined,
    invoiceData: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false
  }

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }

  getColumns = () =>
    [
      {
        title: 'Invoice Details',
        dataIndex: 'Description',
        key: 'description',
        width: '55%',
        render: (Description, item) => this.renderLeftColumn(item)
      },
      {
        title: '',
        dataIndex: 'UOM',
        key: 'UOM',
        align: 'right',
        width: '30%',
        render: (Description, item) => this.renderRightColumn(item)
      }
    ];

  renderLeftColumn = invoice => {
    return (
      <div style={{ height: 90 }}>
        <div>{invoice.No}</div>
        <div>{moment(invoice.OrderDate).format(dateFormatView)}</div>
        <div>{invoice.InvAge} days</div>
        {/* <Tag height={32} color={this.getStatusColor(invoice.OrderStatus)}>{invoice.OrderStatus}</Tag> */}
      </div>
    )
  }

  renderRightColumn = invoice => {
    const { PDCAmount, UnconfirmedNonPDC, RemAmount } = invoice;
    const pdcAmount = parseFloat(PDCAmount.replaceAll(',', ''));
    const unconfirmedNonPdc = parseFloat(UnconfirmedNonPDC.replaceAll(',', ''));
    const remAmount = parseFloat(RemAmount.replaceAll(',', ''));
    const postingPending = pdcAmount + unconfirmedNonPdc;

    return (
      <div style={{ height: 110, width: 130 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 11 }} >AMT </div>
          <div style={{ fontWeight: 'bold' }}> {format(invoice.Amount, currencyFormat)}</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 11 }} >SET</div>
          <div style={{ fontWeight: 'bold' }}>{format(invoice.SettledAmount, currencyFormat)}</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 11 }} >PP</div>
          <div style={{ fontWeight: 'bold' }}>{format(postingPending, currencyFormat)}</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 11 }} >BTS</div>
          <div style={{ fontWeight: 'bold' }}>{format(remAmount - postingPending, currencyFormat)}</div>
        </div>

        <div>
          {this.renderPayment(invoice)}
        </div>
        {/* <div>{this.renderActions(invoice)}</div> */}
      </div>
    )
  }

  renderPayment = invoice => {
    return (
      <div>
        <input
          id={invoice.No}
          type='number'
          defaultValue={0}
          value={invoice.AmontToApply}
          min={0}
          className="ant-input"
          style={{ width: '60%', textAlign: 'right' }}
          onFocus={(event) => event.target.select()}
          onKeyDown={e => (e.keyCode === 69 || e.keyCode === 189) && e.preventDefault()}
          onChange={(e) => this.props.onAmountChange(e.target.value, invoice)} />
        {this.displayError(invoice)}
      </div>
    )
  }

  displayError = (record) => {
    if (record.paymentAmountError !== undefined) {
      return <p style={{ color: 'red', marginBottom: -10 }}>{record.paymentAmountError}</p>
    } else
      return null;
  }

  handleQtyChange = (value, invoice) => {
    this.setState({
      invoiceData: {
        ...invoice,
        AmontToApply: value
      }
    });
  }

  isDisableRowActions = item => {
    const { invoiceData } = this.state;

    if (!invoiceData) return false;

    const key1 = item.No;
    const key2 = invoiceData.No;

    return key1 !== key2;
  }

  // renderStatus = invoice => {
  //   const { paymentLineMap = {} } = this.props;
  //   const selectedKeys = paymentLineMap[invoice.No];

  //   if (selectedKeys) {
  //     return (
  //       <FaCheck color='#07e607'/>
  //     )
  //   } else {
  //     return null
  //   } 
  // }

  renderActions = invoice => {
    // const { paymentLineMap = {} } = this.props;
    const { invoiceData } = this.state;
    const data = invoiceData ? invoiceData : { ...invoice, AmontToApply: invoice.AmountIncluVAT };
    // const allocated = paymentLineMap[invoice.No];

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
        <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>{this.renderStatus(invoice)}</div>
        <Button disabled={this.isDisableRowActions(invoice)} type='primary' size={'small'} onClick={() => this.props.handleOnClickSelectInvoices(data, 'SAVE')} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        {/* <Button disabled={(!allocated || this.isDisableRowActions(invoice))} type='danger' size={'small'} onClick={() => this.props.handleOnClickSelectInvoices(data,  'REMOVE')} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaTrash /></Button> */}
        <Button disabled={this.isDisableRowActions(invoice)} size={'small'} onClick={this.handleResetItem} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
      </div>
    )
  }

  handleResetItem = () => this.setState({ invoiceData: undefined });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 850;
    }
  }

  renderFooter = () => {
    const { invoices = [] } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {invoices.length}</p>
        <p style={{ fontWeight: 'bold' }}>{format(this.getOutstandingTotals(invoices), currencyFormat)}</p>
      </div>
    )
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  render() {
    const items = [];
    const { loading, loadingItems } = this.state;
    let { invoices = [], pagination } = this.props;
    pagination = invoices.length > 5 ? pagination : false
    if (invoices.length > 0) {
      return (
        <div>
          <Table
            rowKey={'id'}
            showHeader={false}
            columns={this.getColumns()}
            bodyStyle={{ minHeight: this.getTableHeight(items) }}
            dataSource={invoices}
            size={'small'}
            scroll={{ y: this.getScrollAreaHeight() }}
            pagination={pagination || false}
            title={() => <h3>Outstanding Invoices</h3>}
            loading={loading || (items.length === 0 && loadingItems)} />
        </div>
      )
    } else return null;
  }
}


export default withRouter((ItemTable));