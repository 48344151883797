import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Table, Tag } from "antd";
import { withRouter } from "react-router-dom";
import TableFooter from "../common/TableFooter";
import AdvanceSearchFilters from "../../components/common/AdvanceSearchFilters";
import OrderCard from "./OrderCard";
import OrderCardHeader from "./OrderCardHeader";
import InvoiceCard from "./InvoiceCard";
import InvoiceCardHeader from "./InvoiceCardHeader";
import CurrencyFormat from "react-currency-format";
import _ from 'lodash'
import { SALES_INVOICES } from "../../const/Permissions";
import { getLoggedInUser } from "../../redux/reducers";
import { dateFormatView } from "../../const/Formats";
import moment from 'moment';

class OrderTable extends Component {

  componentDidMount = () => {
    window.addEventListener('locationchange', this.onUrlChange);
  }

  componentWillUnmount = () => {
    window.removeEventListener('locationchange', this.onUrlChange);
  }

  onUrlChange = () => this.setState({ pathname: window.location.pathname });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight - 30 : 20;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 70;
  }

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      default:
        return 'orange';
    }
  }


  getCustomerStatusColor = status => {
    switch (status) {
      case 'Active':
        return 'green';
      default:
        return 'orange';
    }
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderHeader = () => {
    const { handleInputTextChange, handleInputDateChange, handleSearchClick, filter, match, setDefaultCustomerOption, setDefaultRouteOption, handleInvoiceBatchPrint, isBatchPrinting } = this.props;
    const { customerId, docType } = match.params

    const entireColumn = { lg: 24, md: 24, sm: 24, xs: 24 };
    const oneForthOfColumn = { lg: 6, md: 24, sm: 24, xs: 24 };
    const fiveBars = { lg: 5, md: 24, sm: 24, xs: 24 };
    const oneSixthOfColumn = { lg: 4, md: 24, sm: 24, xs: 24 };
    const oneEighthOfColumn = { lg: 3, md: 24, sm: 24, xs: 24 };
    const oneTwelwthOfColumn = { lg: 2, md: 24, sm: 24, xs: 24 };
    const hidden = { lg: 0, md: 0, sm: 0, xs: 0 };

    return (
      <div className="promate-table-header">
        <AdvanceSearchFilters
          SALES_HIERARCHY={entireColumn}
          SELECT_ROUTE={customerId ? hidden : fiveBars}
          SELECT_OUTLET={customerId ? hidden : fiveBars}
          SELECT_CATEGORY={customerId ? hidden : fiveBars}
          SELECT_SUBCATEGORY={customerId ? hidden : fiveBars}
          SELECT_PAYMENT_METHOD={customerId ? oneEighthOfColumn : oneEighthOfColumn}
          FROM_DATE_PICKER={customerId ? oneSixthOfColumn : oneSixthOfColumn}
          TO_DATE_PICKER={customerId ? oneSixthOfColumn : oneSixthOfColumn}
          SELECT_CUSTOMER_STATUS={{ ...(customerId ? hidden : oneSixthOfColumn), placeholder: 'Customer Status', key: 'CustStatus' }}
          SEARCH_TEXT={{ ...(customerId ? oneEighthOfColumn : oneEighthOfColumn), placeholder: 'Invoice No' }}
          SELECT_ORDER_TYPE={customerId ? oneSixthOfColumn : oneEighthOfColumn}
          SELECT_DISCOUNT_REASON={customerId ? oneSixthOfColumn : oneEighthOfColumn}
          SEARCH_BUTTON={customerId ? oneTwelwthOfColumn : oneTwelwthOfColumn}
          INVOICE_BATCH_PRINT={customerId ? oneTwelwthOfColumn : oneTwelwthOfColumn}
          handleInputChange={handleInputTextChange}
          handleSearchClick={handleSearchClick}
          handleInvoiceBatchPrint={handleInvoiceBatchPrint}
          handleInputDateChange={handleInputDateChange}
          setDefaultCustomerOption={setDefaultCustomerOption}
          setDefaultRouteOption={setDefaultRouteOption}
          filter={filter}
          isBatchPrinting={isBatchPrinting}
          statusOptions={['Confirmed']}
        />
      </div>
    )
  }

  getOrderTotals = orders => {
    let total = 0;
    orders.forEach(order => {
      let AmountIncluVAT = order.AmountIncluVAT.replace(/,/g, "");
      total = total + parseFloat(AmountIncluVAT);
    })
    return total;
  }

  renderFooter = () => {
    const { total, totalAmount, currentPage, onPaginationChange, orders: filteredOrders, metaData, selectedInvoices, setSelectedInvoices, finalSalesInvoices } = this.props;
    const { TotOrgAmount, TotAvgDisPersentage, TotalDisAmount, TotalAmount, TotSettledAmount, TotRemAmount, TotPDCAmount, TotNetRemAmount } = metaData;
    // console.log("********************************");
    // console.log(filteredOrders);
    // console.log("********************************");

    return (
      <TableFooter
        noOfRecords={total}
        currentPage={currentPage}
        onPaginationChange={(page) => {
          onPaginationChange(page);
          setSelectedInvoices(filteredOrders);
        }}
        totals={[TotOrgAmount, TotalDisAmount, TotAvgDisPersentage, TotalAmount, TotSettledAmount, TotRemAmount, TotPDCAmount, TotNetRemAmount]}
        widths={[116, 116, 116, 116, 116, 116, 116, 116]}
        total={TotNetRemAmount}
        docType={'salesInvoice'}
      />
    );
  }

  renderCustomerStatus = (CustStatus) => {
    const customerStatus = (!CustStatus || (CustStatus && CustStatus.length === 0)) ? 'Active' : CustStatus;

    return (
      <Tag style={styles.status} color={this.getCustomerStatusColor(customerStatus)}>{customerStatus}</Tag>
    );
  }

  renderColumns = () => {
    return [{
      render: order => order === 0 ? <InvoiceCardHeader style={{ backgroundColor: '#eceaea' }} /> : (
        order === 1 ? this.renderFooter() : <InvoiceCard order={order} />
      )
    }]
  }

  selectRow = (selectedInvoice) => {
    const { loggedInUser: { permissions = [] }, history } = this.props;
    if (permissions.includes(SALES_INVOICES.VIEW) || permissions.includes(SALES_INVOICES.UPDATE)) {
      history.push("/salesInvoices/" + encodeURIComponent(selectedInvoice.No));
    }
  }

  render() {
    const { orders: filteredOrders, loggedInUser: { permissions = [], roleCode }, history, selectedSalesInvoices, onSelectedSalesInvoicesChange, selectedInvoices } = this.props;
    const sourceData = filteredOrders.length === 0 ? [] : [0, ...filteredOrders, 1]
    const rowSelection = {
      selectedRowKeys: selectedInvoices,
      onChange: onSelectedSalesInvoicesChange,
    };
    const columns = [
      // {
      //   title: <input
      //     type="checkbox"
      //     style={{ width: 20, height: 20 }}
      //   />,
      //   name: 'gg',
      //   align: 'left',
      //   width: 50,
      //   render: text =>
      //     <input
      //       type="checkbox"
      //       style={{ width: 20, height: 20, position: "relative", zIndex: 999, alignItems: "center" }}
      //     />,
      // },
      {
        title: 'Invoice No',
        dataIndex: 'No',
        key: 'no',
        align: 'left',
        width: 150,
        render: text => <span style={{ ...styles.orderNo, width: 200 }}>{text}</span>,

      },
      {
        title: 'Invoice Date',
        dataIndex: 'PostingDate',
        key: 'PostingDate',
        width: 120,
        align: 'center',
        render: date => <span>{moment(date).format(dateFormatView)}</span>
      },
      {
        title: 'Order Type',
        dataIndex: 'SOType',
        key: 'SOType',
        align: 'center',
        width: 120,
      },
      {
        title: 'Payment method',
        dataIndex: 'PayMethodCode',
        key: 'PayMethodCode',
        align: 'center',
        width: 120,
      },
      {
        title: 'Customer Name',
        dataIndex: 'SellToCusName',
        key: 'SellToCusName',
        align: 'center',
        width: 150,
      },
      {
        title: 'Category',
        dataIndex: 'OutletCategoryCode',
        key: 'OutletCategoryCode',
        align: 'center',
        width: 150,
      },
      {
        title: 'Customer Status',
        dataIndex: 'CustStatus',
        key: 'CustStatus',
        align: 'center',
        width: 120,
        render: text => this.renderCustomerStatus(text)
      },
      {
        title: 'Sales Person',
        dataIndex: 'CSRUserName',
        key: 'CSRUserName',
        align: 'center',
        width: 150,
      },
      {
        title: 'Invoice Age',
        dataIndex: 'InvAge',
        key: 'InvAge',
        align: 'center',
        width: 150,
      },
      {
        title: 'Discount Reason',
        dataIndex: 'DiscountReasonCode',
        key: 'DiscountReasonCode',
        align: 'center',
        width: 150,
      },
      {
        title: 'Original Amount',
        dataIndex: 'OrgAmount',
        key: 'OrgAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text => <div style={{ width: 100 }}>
          <CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
        </div>
      },
      {
        title: 'Discount Value',
        dataIndex: 'DisAmount',
        key: 'DisAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text => <div style={{ width: 100 }}><CurrencyFormat
          value={text || 0}
          displayType={'text'}
          thousandSeparator={true}
          isNumericString={true}
          fixedDecimalScale={true}
          style={styles.price}
          decimalScale={2} />
        </div>
      },
      {
        title: 'Discount %',
        dataIndex: 'AvgDisPersentage',
        key: 'AvgDisPersentage',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}> <CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>
      },
      {
        title: 'Invoiced Amount',
        dataIndex: 'Amount',
        key: 'Amount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}> <CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>
      },
      {
        title: 'Settled Amount',
        dataIndex: 'SettledAmount',
        key: 'SettledAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}><CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>
      },
      {
        title: 'Remaining Outstanding',
        dataIndex: 'RemAmount',
        key: 'RemAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}>
            <CurrencyFormat
              value={text || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </div>
      },
      {
        title: 'PDC Balance',
        dataIndex: 'PDCAmount',
        key: 'PDCAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}>
            <CurrencyFormat
              value={text || 0}
              displayType={'text'}
              thousandSeparator={true}
              isNumericString={true}
              fixedDecimalScale={true}
              style={styles.price}
              decimalScale={2} />
          </div>
      },
      {
        title: 'Net Outstanding',
        dataIndex: 'NetRemAmount',
        key: 'NetRemAmount',
        align: 'right',
        fixed: 'right',
        width: 116,
        render: text =>
          <div style={{ width: 100 }}><CurrencyFormat
            value={text || 0}
            displayType={'text'}
            thousandSeparator={true}
            isNumericString={true}
            fixedDecimalScale={true}
            style={styles.price}
            decimalScale={2} />
          </div>,
        footer: (data) => {
          return <div>Summary</div>
        },

      }
    ];

    if (window.innerWidth < 768) {
      return (
        <Table
          columns={this.renderColumns()}
          rowKey={'orderNo'}
          rowClassName={'orderRow'}
          size={'small'}
          bodyStyle={{ minHeight: this.getTableHeight(filteredOrders), overflowY: 'scroll' }}
          pagination={false}
          showHeader={false}
          title={() => this.renderHeader()}
          dataSource={sourceData} />
      )
    }

    if (roleCode == "CSR") {

      return (
        <Col>
          <Table
            rowKey={'orderNo'}
            rowClassName={'table-row-dark'}
            onRowClick={(data) => (permissions.includes(SALES_INVOICES.VIEW) || permissions.includes(SALES_INVOICES.UPDATE)) && history.push("/salesInvoices/" + encodeURIComponent(data.No))}
            dataSource={filteredOrders}
            columns={columns}
            size={'small'}
            scroll={{ y: this.getScrollAreaHeight(), x: 2050 }}
            bodyStyle={{ minHeight: this.getTableHeight(filteredOrders), overflowY: 'scroll' }}
            pagination={false}
            title={() => this.renderHeader()}

            summary={() => {
              return (<div>apple</div>)
            }}
            footer={() => this.renderFooter()} />
        </Col>
      )
    } else {

      return (
        <Col>
          <Table
            rowKey={'orderNo'}
            rowClassName={'table-row-dark'}
            onRow={(invoice) => ({
              onClick: () => {
                this.selectRow(invoice);
              },
            })}
            rowSelection={rowSelection}
            dataSource={filteredOrders}
            columns={columns}
            size={'small'}
            scroll={{ y: this.getScrollAreaHeight(), x: 2050 }}
            bodyStyle={{ minHeight: this.getTableHeight(filteredOrders), overflowY: 'scroll' }}
            pagination={false}
            title={() => this.renderHeader()}

            summary={() => {
              return (<div>apple</div>)
            }}
            footer={() => this.renderFooter()} />
        </Col>
      )
    }
  }
}

const styles = {
  orderNo: {
    fontSize: 16,
    fontWeight: 600,
    wordBreak: 'break-all'
  },
  orderRow: {
    backgroundColor: 'black'
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
  },
  orderDate: {
    fontSize: 12,
    fontWeight: 700
  },
  orderDateTitle: {
    fontSize: 12
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  status: {
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%'
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }

}

OrderTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return { loggedInUser: getLoggedInUser(state) };
}

export default withRouter(connect(mapStateToProps, {})(OrderTable));