import React, { Component } from 'react';
import { Row, Card, Col, Tag, Button, Popconfirm, Divider, Select } from "antd";
import moment from "moment";
import FormItem from '../../common/FormItem';
import { dateFormatView } from '../../../const/Formats';
const { Option } = Select;
class OrderHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  renderActions = () => {
    const { order, newOrder, onSubmitOrder, onClickConfirmOrder, onShareOrder, onDeleteOrder } = this.props;
    const { salesLines, status } = order;

    if (newOrder) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitOrder}
        >
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      // if (order.status === 'Open') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Popconfirm
      //           title="Are you sure?"
      //           okText="Yes"
      //           cancelText="No"
      //           onConfirm={onDeleteOrder}
      //         >
      //           <Button type="danger">Delete</Button>
      //         </Popconfirm>
      //       </Col>
      //     }
      //     {
      //       order.salesLines && order.salesLines.length > 0 &&
      //       (!order.links || order.links.length === 0) && <Col>
      //         <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else if (order.status === 'Confirmed') {
      //   return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     {
      //       order.links && order.links.length > 0 && <Col>
      //         <Button
      //           type="secondary"
      //           style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
      //           onClick={() => onShareOrder()}
      //         >Share</Button>
      //       </Col>
      //     }
      //   </Row>
      // } else {
      //   return false;
      // }
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteOrder}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          salesLines && salesLines.length > 0 &&
          status === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmOrder}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareOrder()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  render() {
    const { order, discountPercentageError, onInputChange } = this.props;
    const { discountPercentage, orderNo, orderDate, status, sellToCustomerName, sellToCustomerCode, billToCustomerCode, billToCustomerName, creditPeriod = 0 } = order;

    return (
      <Card style={{ margin: 12 }}>
        <Row gutter={10}>
          <Col lg={8} md={8} sm={24} xs={24}>
            {orderNo && <tr>
              <td>ID #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{orderNo ? orderNo.replace(/---/g, '/') : ''}</td>
            </tr>}
            {orderDate && <tr>
              <td>Date</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{moment(orderDate).format(dateFormatView)}</td>
            </tr>}
            {status && <tr>
              <td>Status</td>
              <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
              <td>
                {<Tag color={this.getStatusColor(status)}>{status}</Tag>}
              </td>
            </tr>}
            {sellToCustomerCode && <tr>
              <td>Merchant #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerCode}</td>
            </tr>}
            {sellToCustomerName && <tr>
              <td>Merchant Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{sellToCustomerName}</td>
            </tr>}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            {billToCustomerCode && <tr>
              <td>Customer #</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerCode}</td>
            </tr>}
            {billToCustomerName && <tr>
              <td>Customer Name</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>{billToCustomerName}</td>
            </tr>}
            <tr>
              <td>Return Category</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <Select defaultValue="FACTORY_DEFECTS" style={{ width: 240 }}>
                  <Option value="FACTORY_DEFECTS">Factory defects</Option>
                  <Option value="SEASONAL_SALES">Seasonal Sales</Option>
                  <Option value="CUSTOMER_DISCONTINUATION">Customer Discontinuation</Option>
                  <Option value="TRASPORTATION_AND_HANDLING">Transportation and handling</Option>
                </Select>
              </td>
            </tr>

            {/* <tr style={{ justifyContent: 'center' }}>
              <td>Discount %</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <input
                  className="ant-input"
                  value={discountPercentage}
                  style={{ width: '100%' }}
                  type="number"
                  min="0"
                  disabled={status === "Confirmed" ? "disabled" : ""}
                  onChange={(e) => onInputChange("discountPercentage", e.target.value, "discountPercentageError")}
                  onFocus={(event) => event.target.select()} />
              </td>
            </tr> */}
            {/* <tr style={{ justifyContent: 'center' }}>
              <td>Payment Type</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <Select defaultValue="CASH" style={{ width: '100%' }} onChange={(value) => onInputChange("paymentType", value, "paymentTypeError")}   disabled={status === "Confirmed" ? "disabled" : ""} >
                  <Option value="CASH">CASH</Option>
                  <Option value="PDC">PDC</Option>
                  <Option value="CREDIT(Bill to Bill)">CREDIT(Bill to Bill)</Option>
                </Select>
              </td>
            </tr> */}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={8} md={8} sm={24} xs={24}>

            {/* <tr style={{ justifyContent: 'center' }}>
              <td>Credit Period</td>
              <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
              <td>
                <input
                  className="ant-input"
                  value={creditPeriod}
                  style={{ width: '100%' }}
                  type="number"
                  min="0"
                  disabled={status === "Confirmed" ? "disabled" : ""}
                  onChange={(e) => onInputChange("creditPeriod", e.target.value, "creditPeriodError")}
                  onFocus={(event) => event.target.select()} />
              </td>
            </tr> */}

          </Col>



        </Row>
      </Card>
    )
  }
}

export default OrderHeader;