import React, { Component } from 'react';
import { Table } from "antd";
import { connect } from "react-redux";
import { getPurchaseInvoiceLinesByDocTypeAndDocNo, getLoggedInUser } from "../../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { format } from "currency-formatter";
import { PURCHASING_INVOICES } from "../../../const/Permissions";

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTable extends Component {
  getColumns = () =>
    [
      {
        title: 'No',
        align: 'center',
        width: 55,
        render: (unitPrice, object, index) => (index + 1)
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '50%',
        render: (Description, item) => item.LotNo + ' - ' + item.No + ' - ' + Description
      },
      {
        title: 'Order Qty',
        dataIndex: 'Quantity',
        key: 'Quantity',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Invoiced Qty',
        dataIndex: 'InvoicedQty',
        key: 'InvoicedQty',
        width: '8%',
        align: 'right'
      },
      {
        title: 'Unit Price',
        dataIndex: 'UnitPrice',
        key: 'UnitPrice',
        align: 'right',
        width: '8%',
        render: unitPrice => format(unitPrice, currencyFormat)
      },
      {
        title: 'Discount %',
        dataIndex: 'DisPercentage',
        key: 'DisPercentage',
        width: '8%',
        align: 'right',
        render: DisPercentage => format(DisPercentage, currencyFormat)
      },
      {
        title: 'Discount',
        dataIndex: 'DisAmount',
        key: 'DisAmount',
        width: '8%',
        align: 'right',
        render: DisAmount => format(DisAmount, currencyFormat)
      },
      {
        title: 'Total',
        dataIndex: 'LineAmount',
        key: 'LineAmount',
        align: 'right',
        width: '8%',
        render: LineAmount => format(LineAmount, currencyFormat)
      }
    ];

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const tableFooter = document.getElementsByClassName('ant-table-footer');
    const tableFooterHeight = tableFooter && tableFooter[0] && tableFooter[0].clientHeight;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  renderFooter = () => {
    const { purchasingLines = [], loggedInUser: { permissions = [] } } = this.props;
    const enableUpdate = permissions.includes(PURCHASING_INVOICES.UPDATE) || permissions.includes(PURCHASING_INVOICES.UPDATE);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>No of Items: {purchasingLines.length}</p>
      </div>
    )
  }

  render() {
    const { height = 100, history, purchasingLines = [], match, loggedInUser: { permissions = [] } } = this.props;
    const { invoiceNo } = match.params;
    const decodeId = decodeURIComponent(invoiceNo);
    const enableUpdate = permissions.includes(PURCHASING_INVOICES.UPDATE) || permissions.includes(PURCHASING_INVOICES.UPDATE);

    return (
      <div>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={purchasingLines}
          size={'small'}
          pagination={false}
          footer={this.renderFooter} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { invoiceNo, docType } = ownProps.match.params;
  const decodeId = decodeURIComponent(invoiceNo);
  return {
    purchasingLines: getPurchaseInvoiceLinesByDocTypeAndDocNo(state, '', decodeId),
    loggedInUser: getLoggedInUser(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ItemTable));