import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Button, Card, Tag, Divider, Col, Icon, Row } from 'antd';
import moment from "moment";
import { dateFormatView, dateTimeFormatView } from "../../../const/Formats";
import { getRoute, getAuthData, getHierarchyBranch, getDistributor, getLoggedInUser } from "../../../redux/reducers";
import LOcationMap from '../../common/LocationMap';
import { fetchSalesUserHierarchy } from '../../../redux/actions/users';
import { fetchRoute } from '../../../redux/actions/routes';
import { fetchDistributor } from '../../../redux/actions/distributors';
import PromatePageHeader from '../../common/PromatePageHeader';
import { ProMateGreen } from '../../../const/Theme';
import Actions from './Actions';
import { withToastManager } from 'react-toast-notifications';
import { defaultOrder } from '../../Orders/OrderScreen/Container';
import uuid from 'uuid';

const currencyFormat = {
  decimalDigits: 2,
  decimalSeparator: '.'
}

class Details extends Component {
  state = {
    showMap: false
  };

  componentDidMount = () => {
    const { fetchSalesUserHierarchy, authData, distributor, fetchRoute, fetchDistributor, match, fetchPaymentMethodsForDistributor, fetchPaymentTermsForDistributor, fetchDistributorCategories, fetchDistributorSubcategories, fetchPaymentMethods, fetchPaymentTerms } = this.props;
    //console.log(match.params.distributorId)

    fetchDistributor(match.params.distributorId);
    fetchSalesUserHierarchy(authData);
    if (distributor && distributor.No) fetchRoute(distributor.RouteCode);
  }

  componentWillReceiveProps = nextProps => {
    const { distributor: thisDistributor, fetchRoute } = this.props;
    const { distributor: nextDistributor } = nextProps;

    if (thisDistributor) return;
    else if (nextDistributor) fetchRoute(nextDistributor.RouteCode);
  }

  onMapViewSelect = selected => this.setState({ showMap: selected });

  renderHierarchyPosition = (title, position = {}) => {
    const { user } = position;
    let value = '';
    if (user) {
      if (user.name) value = user.name + ' ';
      if (user.code) value += '(' + user.code + ')';

      return (
        <tr>
          <td style={{ width: 25 }}><Icon type="apartment" /></td>
          <td>{title}</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{value}</td>
        </tr>
      );
    } else {
      return null;
    }
  }

  renderInforColumns = () => {
    const { distributor, hierarchyBranch, route } = this.props;

    return [
      <Col lg={8} md={8} sm={24} xs={24}>
        {distributor.Code && <tr>
          <td style={{ width: 25 }}><Icon type="number" /></td>
          <td style={{ width: 80 }}>Code</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.Code}</td>
        </tr>}
        {distributor.Name && <tr>
          <td style={{ width: 25 }}><Icon type="shop" /></td>
          <td>Name</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.Name}</td>
        </tr>}
        {distributor.ContPhoneNo && <tr>
          <td style={{ width: 25 }}><Icon type="phone" /></td>
          <td style={{ width: 80 }}>Contact No</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.ContPhoneNo}</td>
        </tr>}
        {distributor.ContPerson && <tr>
          <td style={{ width: 25 }}><Icon type="user" /></td>
          <td>Contact P.</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.ContPerson}</td>
        </tr>}
        {distributor.EMail && <tr>
          <td style={{ width: 25 }}><Icon type="mail" /></td>
          <td>Email</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td style={{ wordBreak: 'break-all' }}>{distributor.EMail}</td>
        </tr>}
        {distributor.Add && <tr>
          <td style={{ width: 25 }}><Icon type="pushpin" /></td>
          <td>Address</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.Add}</td>
        </tr>}
        {distributor.PayTermCode && <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td>Payment Term</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.PayTermCode}</td>
        </tr>}
        <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td style={{ width: 80 }}><p>Status</p></td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td><Tag color={getStatusColor(distributor.Status)}>{distributor.Status}</Tag></td>
        </tr>
        {distributor.Remarks && <tr>
          <td style={{ width: 25 }}><Icon type="info-circle" /></td>
          <td>Blocked Reason</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.Remarks}</td>
        </tr>}
      </Col>,
      <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>,
      <Col lg={6} md={6} sm={24} xs={24}>
        {distributor.CreditLimit && <tr>
          <td>Credit Limit</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.CreditLimit}</td>
        </tr>}
        {distributor.CreditUsage && <tr>
          <td>Credit Usage</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.CreditUsage}</td>
        </tr>}
        {distributor.CreditBalance && <tr>
          <td>Credit Balance</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.CreditBalance}</td>
        </tr>}
        {distributor.BankGuarantee && <tr>
          <td>Bank Guarantee</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.BankGuarantee}</td>
        </tr>}
        {distributor.BankGuaranteeExp && <tr>
          <td>Bank Guarantee Expiration</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{moment(distributor.BankGuaranteeExp).format(dateFormatView)}</td>
        </tr>}
      </Col>,
      <Col lg={0} md={0} sm={24} xs={24}><Divider style={{ marginTop: 5, marginBottom: 5 }} /></Col>,
      <Col lg={6} md={6} sm={24} xs={24}>
        {distributor.DGMName && <tr>
          <td>DGM</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.DGMName}</td>
        </tr>}
        {distributor.RSMName && <tr>
          <td>RSM</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.RSMName}</td>
        </tr>}
        {distributor.ASMName && <tr>
          <td>ASM</td>
          <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
          <td>{distributor.ASMName}</td>
        </tr>}
      </Col>
    ];
  }

  renderMap = () => {
    const { distributor, route } = this.props;
    const routes = !!route ? [route] : [];
    return (
      <div style={styles.mapContainer}>
        <LOcationMap distributors={[distributor]} routes={routes} height={300} />
      </div>
    );
  }

  renderHeaderSwitcher = () => {
    return (
      <div style={styles.alignRight}>
        <Button.Group>
          <Button icon='menu' size='small' onClick={() => this.onMapViewSelect(false)}>Info</Button>
          <Button icon='environment' size='small' onClick={() => this.onMapViewSelect(true)}>Map</Button>
        </Button.Group>
      </div>
    );
  }

  editDistributor = () => {
    const { distributorId } = this.props.match.params;
    this.props.history.push("/distributors/" + distributorId + "/edit");
  }

  submitOrder = () => {
    const { addOrder, toastManager, distributor } = this.props;
    const order = { ...defaultOrder, sellToDistributorCode: distributor.id, sellToDistributorName: distributor.name }

    this.setState({ loading: true })
    addOrder(order).then(response => {
      this.setState({ loading: false })
      if (!response.error) {
        toastManager.add('Successfully created a new order.', { autoDismiss: true, appearance: 'success' });
        defaultOrder.sessionId = uuid();
        this.props.history.replace('/orders/' + response.order.orderNo)
      } else {
        toastManager.add('Could not create the order. ' + response.error, { autoDismiss: true, appearance: 'error' });
      }
    })
  }

  render() {
    const { distributor, loggedInUser: { permissions = [] } } = this.props;
    const { showMap } = this.state;

    if (!distributor) return null;

    return (
      <PromatePageHeader
        title={'Distributor Info'}
        style={styles.pageHeader}
        onBack={() => this.props.history.goBack()}>
        <Card className={'distributorInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            {showMap ? this.renderMap() : this.renderInforColumns()}
          </Row>

          <Row gutter={10}>
            {this.renderHeaderSwitcher()}
          </Row>
        </Card>

        {distributor.Remarks && distributor.Remarks.length > 0 && <Card className={'distributorInfoCard'} style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Blcoked Reasons</td>
                <td></td>
              </tr>

              {distributor.blockedReasons.map((reason, index) => [
                <tr>
                  <td style={{ width: 25 }}>{index + 1}.</td>
                  <td><b>{reason.type} - {reason.reasonDescription}</b> </td>
                  <td textAlign='right'><i> {moment(reason.time).format(dateFormatView + 'hh:mm a')}</i></td>
                </tr>,
                <tr>
                  <td style={{ width: 25 }}></td>
                  <td colSpan={2}>{reason.narration}</td>
                  <td></td>
                </tr>,
                <tr>
                  <td style={{ width: 25, height: 15 }}></td>
                  <td></td>
                  <td></td>
                </tr>
              ])}
            </Col>
          </Row>
        </Card>}

        <Actions distributor={distributor} submitOrder={this.submitOrder} />
      </PromatePageHeader>
    )
  }
}

const getStatusColor = status => {
  switch (status) {
    case 'Active':
      return 'green';
    case 'Blocked':
      return 'red';
    default:
      return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const distributor = getDistributor(state, ownProps.match.params.distributorId);

  return {
    distributor,
    authData: getAuthData(state),
    route: distributor ? getRoute(state, distributor.RouteCode) : undefined,
    hierarchyBranch: distributor ? getHierarchyBranch(state, distributor.UserTag) : {},
    loggedInUser: getLoggedInUser(state)
  };
}

Details.contextTypes = {
  screenType: PropTypes.object.isRequired,
}

export default withToastManager(connect(mapStateToProps, { fetchSalesUserHierarchy, fetchRoute, fetchDistributor })(Details));

const styles = {
  tag: {
    marginTop: 5
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  mapContainer: {
    marginBottom: 10
  },
  pageHeader: {
    backgroundColor: ProMateGreen,
    height: 80,
  },
  addBtnMobile: {
    backgroundColor: ProMateGreen,
    //zIndeaddBtnMobilex: 10000,
    //position: 'absolute',
    //top: 33,
    //right: 65,
    border: 0,
    marginRight: 10,
    padding: 0
  },
  addBtnWeb: {
    backgroundColor: '#eda01e',
    color: '#FFFFFF',
    // zIndex: 10000,
    // position: 'absolute',
    // top: 20,
    // right: 180,
    marginLeft: 15,
    height: 40
  }
};