import Api from './consts';
import fetch from './commonFetch';

export const getAll = filter => {
  const { url, method } = Api.invoices.getAll;

  return fetch(url(), { method, body: JSON.stringify(filter) }, 'invoices');
}

export const get = id => {
  const { url, method } = Api.invoices.get;

  return fetch(url(id), { method }, 'invoice');
}