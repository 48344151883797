import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchOrder, addOrder, confirmOrder } from "../../../redux/actions/orders";
import { getOrder } from "../../../redux/reducers";
import { Spin, Modal } from "antd";
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import withRouter from "react-router/es/withRouter";
import Pad from "./SignaturePad";
import { upload } from '../../../api/files';
import { currentLocation } from '../../../utils/Location';
import { getDemoCreditNote } from '../dummyCreditNotes'

const { confirm } = Modal;

const defaultCreditNote = {
  billToCustomerCode: "",
  billToCustomerName: "",
  links: [],
  locationCode: "",
  mapLocation: currentLocation,
  creditNoteDate: moment(),
  creditNoteNo: "new",
  salesLines: [],
  salesPersonCode: "",
  sellToCustomerCode: undefined,
  sellToCustomerName: undefined,
  status: "PENDING",
  signedBy: undefined
}

class Container extends Component {
  state = {
    loading: false,
    creditNoteConfirmationType: undefined,
    creditNote: { ...defaultCreditNote }
  }

  componentDidMount() {
    const { creditNoteId } = this.props.match.params
    const newCreditNote = creditNoteId === 'new';

    if (newCreditNote) {
      const state = this.props.location.state;
      const customer = state && state.customer;
      this.setState({ creditNote: { ...defaultCreditNote, sellToCustomerCode: customer.id, sellToCustomerName: customer.name } })
    } else {
      // this.setState({ loading: true })
      // this.props.fetchOrder(creditNoteId).then(() => {
      //   this.setState({ loading: false })
      // })
      this.setState({ creditNote: getDemoCreditNote(creditNoteId) })
    }
  }

  // componentWillReceiveProps = nextProps => {
  //   const { creditNote, match } = nextProps;
  //   const { creditNoteId } = match.params
  //   const newOrder = creditNoteId === 'new';

  //   if (newOrder) {
  //     this.setState({ creditNote: { ...defaultOrder } })
  //   } else {
  //     this.setState({ creditNote: { ...creditNote, status: creditNote.status } })
  //   }
  // }

  calculateTotalAmount = () => {
    const { creditNote = {} } = this.props;
    const { salesLines = [] } = creditNote;
    let total = 0;

    for (let i = 0; i < salesLines.length; i++) {
      total = salesLines[i].lineIncludeVatAmount + total;
    }

    return total;
  }

  confirmCreditNote = (signatureImageUrl, isEmptyUrl) => {
    const { confirmCreditNote, toastManager, history, match } = this.props;
    const { creditNoteConfirmationType } = this.state;
    const { creditNoteId } = match.params

    if (!creditNoteConfirmationType) {
      toastManager.add("You can't confirm the credit note without confirmation type.", { autoDismiss: true, appearance: 'error' });
      return;
    }

    const updateCreditNoteData = { creditNoteNo: creditNoteId, status: "CONFIRMED", links: [], signedBy: creditNoteConfirmationType }

    this.setState({ loading: true })

    if (creditNoteConfirmationType === "OTPO") {
      confirmCreditNote(updateCreditNoteData).then(response => {
        setTimeout(() => {
          this.setState({ loading: false })
          toastManager.add('Successfully update credit note.', { autoDismiss: true, appearance: 'success' });
          history.goBack();
        }, 2000)
        // this.setState({ loading: false })
        // if (!response.error) {
        //   toastManager.add('Successfully update creditNote.', { autoDismiss: true, appearance: 'success' });
        //   history.goBack()
        // } else {
        //   toastManager.add('Failed to update creditNote. ' + response.error, { autoDismiss: true, appearance: 'error' });
        // }
      })
    } else {
      if (isEmptyUrl) {
        this.setState({ loading: false })
        toastManager.add("You can't confirm the credit note without providing the signature.", { autoDismiss: true, appearance: 'error' });
        return;
      }

      const imageFile = this.getImageFile(signatureImageUrl);

      setTimeout(() => {
        this.setState({ loading: false })
        toastManager.add('Successfully update credit note.', { autoDismiss: true, appearance: 'success' });
        history.goBack();
      }, 2000)

      // upload(imageFile, "creditNotes/" + creditNoteId, 'signature-'+new Date().getTime()).then(response => {
      //   if (response.error) {
      //     toastManager.add("Could not upload the customer signature.", { autoDismiss: true, appearance: 'error' });
      //     return;
      //   }

      //   updateOrderData.links = [ response.path ];

      //   confirmOrder(updateOrderData).then(response => {
      //     this.setState({ loading: false })
      //     if (!response.error) {
      //       toastManager.add('Successfully update creditNote.', { autoDismiss: true, appearance: 'success' });
      //       history.goBack()
      //     } else {
      //       toastManager.add('Failed to update creditNote. ' + response.error, { autoDismiss: true, appearance: 'error' });
      //     }
      //   })
      // });
    }
  }

  showConfirmCreditNoteConfirmationModal = (signatureImageUrl, isEmptyUrl, context) => {
    confirm({
      title: 'Are you sure ?',
      content: 'After confirm the credit note you cannot update this credit note.',
      onOk() {
        context.confirmCreditNote(signatureImageUrl, isEmptyUrl)
      },
    });
  }

  getImageFile = imageUrl => {
    const blob = this.createBlob(imageUrl);
    return new File([blob], "signature.png", { type: "image/png" });
  }

  createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  onChangeCreditNoteConfirmationType = type => {
    this.setState({ creditNoteConfirmationType: type });
  }

  navigateToInventory = () => this.props.history.push({ pathname: '/inventory', state: { creditNote: this.state.creditNote } })

  render() {
    const { loading,/* creditNote,*/ creditNoteConfirmationType } = this.state;

    return (
      <Spin spinning={loading} tip={"Loading Credit Note"}>
        {/* <CreditNoteHeader creditNote={creditNote} />
        <SalesLineSummary creditNote={creditNote} />
        <CreditNoteFooter totals={this.calculateTotalAmount()} /> */}
        <Pad orderConfirmationType={creditNoteConfirmationType} onConfirmationTypeChange={this.onChangeCreditNoteConfirmationType} onConfirmOrder={(signatureImageUrl, isEmptyUrl) => this.showConfirmCreditNoteConfirmationModal(signatureImageUrl, isEmptyUrl, this)} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { creditNoteId } = ownProps.match.params

  return { creditNote: getDemoCreditNote(creditNoteId) };
};


export default withRouter(withToastManager(connect(mapStateToProps, { fetchOrder, addOrder, confirmOrder })(Container)));