import React, { Component } from 'react';
import { Table, Select, Checkbox, Row } from "antd";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { updatePageSize } from "../../redux/actions/inventory";
import { getInventoryPageSize, getAuthData, DOC_TYPE_ID_BY_DEFINITION, getLotWiseItems } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import { generateInventoryItems } from './InventoryDemoData'
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { formatGeoLocation } from "../../utils/Formats";
import { fetchLotWiseItems } from "../../redux/actions/lotwiseItems";
import ItemCard from "./ItemCard";
import ItemCardHeader from "./ItemCardHeader";

var columnArrayWithSelect =
  [
    {
      title: 'Select',
      dataIndex: 'Description',
      key: 'select2',
      width: 20,
      align: 'right',
      render: (index, object) => renderCheckBox(object, index),
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
    },
    {
      title: 'Uom',
      dataIndex: 'UOM',
      key: 'UOM',
      align: 'right',
      width: 60
    },
    {
      title: 'Quntity In Hand',
      dataIndex: 'Inventory',
      key: 'Inventory',
      width: 100,
      align: 'right'
    },
    {
      title: 'Quntity',
      dataIndex: 'Quntity',
      key: 'Quntity',
      width: 100,
      fixed: 'right',
      align: 'right',
      render: quntity => renderQuntity(quntity),
    }
  ];

var columnArrayWithoutForSale =
  [
    {
      title: 'CODE',
      dataIndex: 'LotNo',
      key: 'LotNo',
      width: '30%',
    },

    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'description',
    },
    {
      title: 'Uom',
      dataIndex: 'UOM',
      key: 'UOM',
      align: 'right',
      width: 60
    },
  ];

const renderCheckBox = (object, index) => {
  return (
    <Checkbox></Checkbox>
  )
}

const renderQuntity = (quntity) => {
  return (
    <input
      className="ant-input"
      style={{ width: '100%' }}
      onFocus={(event) => event.target.select()} />
  )
}

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ItemTable extends Component {

  state = {
    LocCode: undefined,
    text: undefined,
    salesLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false,
  }

  componentDidMount() {
    this.loadItems();
    this.setDeviceMapLocation();
    this.setState({ loading: true });
  }

  loadItems = item => {
    const { authData, fetchLotWiseItems } = this.props;

    const filter = {
      UserCode: authData.userId,
      AllItems: 'Yes'
    }

    if (item) {
      filter.ItemNo = item.ItemNo;
      filter.LotNo = item.LotNo;
    }

    this.setState({ loadingItems: true, loading: true });
    fetchLotWiseItems(filter).then(() => {
      this.setState({ loadingItems: false, loading: false });
    });
  }


  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  renderColumns = () => {
    //console.log(this.props.location.state)
    const { multiSelect, location } = this.props;
    if (multiSelect) {
      return columnArrayWithSelect;
    } else {
      if (location.state) {
        const { resource } = location.state;
        if (resource === "purchaseReturn") {
          return columnArrayWithoutForSale;
        } else if (resource === "salesReturns") {
          return columnArrayWithoutForSale;
        }
      }
    }

  }

  renderInventryColumn = () => {
    return [{
      render: item => <ItemCard item={item} />
    }]
  }

  handleChange = (value) => {
    this.setState({ warehouseId: value })
  }

  renderHeader = () => {
    return (
      <div>
        <ItemCardHeader />
      </div>
    )
  }

  getTableHeight = customers => {
    if (customers.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  handleRowClick = (rowData) => {
    const { location, history, match, multiSelect, items } = this.props;
    const { order = {}, resource, id } = location.state
    if (resource === "salesReturns") {
      !multiSelect && order && history.replace({ pathname: "/sales/" + order.DocType.replaceAll(' ', '') + "/" + encodeURIComponent(id) + "/salesLines/new", state: { itemId: rowData.ItemNo, order, resource, id: id, LotNo: rowData.LotNo } });
    } else if (resource === "purchaseReturn") {
      !multiSelect && order && history.replace({ pathname: "/purchasing/" + order.DocType.replaceAll(' ', '') + "/" + encodeURIComponent(id) + "/purchaseLines/new", state: { itemId: rowData.ItemNo, order, resource, id: id, LotNo: rowData.LotNo } });
    }
  }

  render() {
    const { height, location, history, pageSize, updatePageSize, match, multiSelect, items } = this.props;
    const { loading, loadingItems } = this.state
    //const { order = {} } = location

    const tableHeight = height - 120;
    //const items = generateInventoryItems()
    if (location.state != undefined) {
      const { resource, id } = location && location.state
      return (
        <div style={{ height }}>
          <Table
            rowKey={'id'}
            columns={this.renderColumns()}
            dataSource={items}
            size={'small'}
            pagination={false}
            scroll={{ y: tableHeight }}
            loading={loading || (items.length === 0 && loadingItems)}
            onRowClick={rowData => this.handleRowClick(rowData)}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
              pageSize,
              onShowSizeChange: (_, size) => updatePageSize(size),
            }} />
        </div>
      )
    }
    else {

      return (
        <div style={{ height }}>
          <Table
            rowKey={'id'}
            columns={this.renderInventryColumn()}
            dataSource={items}
            size={'small'}
            showHeader={false}
            title={() => this.renderHeader()}
            loading={loading || (items.length === 0 && loadingItems)}
            scroll={{ y: tableHeight }}
            pagination={false}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
              pageSize,
              onShowSizeChange: (_, size) => updatePageSize(size),
            }} />
        </div>)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  //const items = getItems(state);
  const items = getLotWiseItems(state);
  return {
    authData: getAuthData(state),
    items,
    pageSize: getInventoryPageSize(state)
  };
};

ItemTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, { fetchLotWiseItems, updatePageSize })(ItemTable));