import React, { Component } from 'react';
import { Row, Card, Col, Select, Input, Button } from "antd";
import FormItem from '../../common/FormItem';
import CustomerRaw from './CustomerRaw';

const { Option } = Select;
const { TextArea } = Input

class AddCustomersContainer extends Component {

  render() {
    const {
      customers,
      addCustomersToRoute
    } = this.props;


    return (
      <Card className={'customerInfoCard'} title="Add Customers" style={{ margin: 5 }} extra={
        <Button style={{
          backgroundColor: '#eda01e', color: '#FFFFFF', zIndex: 10000, position: 'absolute', top: 10,
          right: 25
        }} onClick={addCustomersToRoute}>
          Add
        </Button>
      } >
        {/* <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col style={{ marginBottom: 10 }}>
              <FormItem label={"Salespersons"} lg={24} md={24} sm={24} xs={24}>
                <Select
                  placeholder={"Select Salesperson"}
                  value={this.state.selectedSalePerson}
                  onChange={this.onSelectSalesPerson}
                  style={{ width: '100%' }}>
                  {salesPersonsList.map(warehouse => <Option key={warehouse} value={warehouse}>{warehouse}</Option>)}
                </Select>
              </FormItem>
            </Col>
          </Col>
        </Row> */}

        <Row >

          {/* <tr>
              <td style={{ width: 25, height: 30 }}></td>
              <td>Warehouses</td>
              <td></td>
            </tr> */}
          {(customers && customers.length > 0) && customers.map((customer, index) => {
            return (
              <div key={index}>
                <CustomerRaw customer={customer} onChangeCheckBox={this.onChangeCheckBox} />

              </div>)
          })}

        </Row>
      </Card>
    )
  }
}

export default AddCustomersContainer;
