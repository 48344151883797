import * as fromApi from '../../api/collections';

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_BY_CUSTOMER = 'FETCH_COLLECTIONS_BY_CUSTOMER';
export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';

export const fetchCollectionsByCustomer = filter => dispatch => {
  return fromApi.getAll(filter).then(response => {
    const { collections, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTIONS_BY_CUSTOMER, customerId: filter.customerId, collections });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchCollections = filter => dispatch => {
  return fromApi.getAll(filter).then(response => {
    const { collections, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTIONS, collections });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const addCollection = collection => dispatch => {
  return fromApi.add(collection).then(response => {

    const { collection, error } = response;
    const customerId = collection.sellToCustomerCode;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTION, collection });
    dispatch({ type: FETCH_COLLECTIONS_BY_CUSTOMER, customerId, collections: [collection] });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const updateCollection = collection => dispatch => {
  return fromApi.update(collection).then(response => {
    const { collection, error } = response;
    const customerId = collection.sellToCustomerCode;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTION, collection });
    dispatch({ type: FETCH_COLLECTIONS_BY_CUSTOMER, customerId, collections: [collection] });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const fetchCollection = collectionId => dispatch => {
  return fromApi.get(collectionId).then(response => {
    const { collection, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTION, collection });
    return response;
  }).catch(error => {
    return { error }
  })
};

export const deleteCollection = collection => dispatch => {
  return fromApi.deleteCollection(collection).then(response => {
    const { error } = response;
    if (error) return { error }

    dispatch({ type: DELETE_COLLECTION, collection });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const addAttachments = collection => dispatch => {
  return fromApi.addAttachments(collection).then(response => {
    const { collection, error } = response;
    const customerId = collection.sellToCustomerCode;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTION, collection });
    dispatch({ type: FETCH_COLLECTIONS_BY_CUSTOMER, customerId, collections: [collection] });

    return response;
  }).catch(error => {
    return { error }
  })
};

export const confirmCollection = collection => dispatch => {
  return fromApi.confirm(collection).then(response => {
    const { collection, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_COLLECTION, collection });

    return response;
  }).catch(error => {
    return { error }
  })
};