import React, { Component } from 'react';
import { Table, Row, Input, Col, Button } from "antd";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchPurchaseHeaders } from "../../redux/actions/purchasingHeaders";
import { addPurchaseLine, fetchPurchaseLines, updatePurchaseLine, deletePurchaseLine } from "../../redux/actions/purchaseLines";
import { fetchAllItems } from "../../redux/actions/AllItems";
import { getAllItems, getAuthData, getPurchaseLineMapByDocTypeAndDocNo, getPurchaseLinesByDocTypeAndDocNo, DOC_TYPE_ID_BY_DEFINITION, getPurchaseHeadersByTypeAndNo, getItemFilterOptions } from "../../redux/reducers";
import { withRouter } from 'react-router-dom';
import SelectWarehouseLocation from '../common/SelectWarehouseLocation'
import { FaCheck, FaTimes, FaSave, FaUndo } from "react-icons/fa";
import { format } from "currency-formatter";
import uuid from "uuid/v1";
import { locationEnabled, addListener, removeListener } from '../../utils/Location';
import { withToastManager } from 'react-toast-notifications';
import { formatGeoLocation } from "../../utils/Formats";
import SelectCategoryTree, { filterItemsByCategory } from '../common/SelectCategoryTree';

const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

const { Search } = Input;

class PurchaseOrderItemTable extends Component {
  state = {
    LocCode: undefined,
    text: undefined,
    purchaseLine: undefined,
    MapLocation: undefined,
    loading: false,
    loadingItems: false,
    itemFilter: []
  }

  getColumns = () =>
    [
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'description',
        width: '30%',
        render: (Description, item) => item.LotNo + ' - ' + Description
      },
      // {
      //   title: 'For Sale',
      //   dataIndex: 'Inventory',
      //   key: 'Inventory',
      //   width: '10%',
      //   align: 'right',
      // },
      {
        title: 'Unit Price',
        dataIndex: 'UnitPrice',
        key: 'UnitPrice',
        width: '10%',
        align: 'right',
      },
      {
        title: 'Qty',
        dataIndex: 'Quntity',
        key: 'Quntity',
        align: 'right',
        width: '15%',
        render: (quntity, item) => this.renderQuntity(item),
      },
      {
        title: 'Discount %',
        dataIndex: 'DisPercentage',
        key: 'DisPercentage',
        align: 'right',
        width: '15%',
        render: (DisPercentage, item) => DisPercentage ? DisPercentage : 0
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        width: '10%',
        render: (UnitPrice, item) => this.renderLineTotal(item)
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'right',
        width: '5%',
        render: (status, item) => <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: '15%',
        render: (action, item) => <div style={{ marginRight: 10 }}>
          {this.renderActions(item)}
        </div>
      }
    ];

  getMobileColumns = () =>
    [
      {
        title: <div style={{ width: window.innerWidth - 150 }}>
          <table>
            <tr>
              <td style={{ textAlign: 'right', width: '20%' }}>Price</td>
              <td style={{ textAlign: 'right', width: '30%' }}>Qty</td>
              <td style={{ textAlign: 'right', width: '30%' }}>Dis %</td>
              <td style={{ textAlign: 'right', width: '20%' }}>Total</td>
            </tr>
          </table>
        </div>,
        dataIndex: 'Description',
        key: 'description',
        width: '75%',
        render: (Description, item) => <div style={{ width: window.innerWidth - 150 }}>
          <div style={{ height: 40 }}>{item.LotNo + ' - ' + Description}</div>
          <table>
            <tr>
              <td style={{ textAlign: 'right', width: '20%' }}>{item.UnitPrice}</td>
              <td style={{ textAlign: 'right', width: '30%' }}>{this.renderQuntity(item)}</td>
              <td style={{ textAlign: 'right', width: '30%' }}>{item.DisPercentage ? item.DisPercentage : 0}</td>
              <td style={{ textAlign: 'right', width: '20%' }}>{this.renderLineTotal(item)}</td>
            </tr>
          </table>
        </div>
      },
      {
        title: <div style={{ marginRight: 10 }}>For Sale</div>,
        dataIndex: 'Inventory',
        key: 'Inventory',
        align: 'right',
        width: 150,
        render: (Description, item) => <div style={{}}>
          <div style={{ height: 40, marginTop: -7 }}>{item.Inventory}</div>
          <table>
            <tr>
              <td style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{this.renderStatus(item)}</div>
              </td>
              <td style={{ textAlign: 'right' }}>{this.renderActions(item)}</td>
            </tr>
          </table>
        </div>
      }
    ];

  renderQuntity = item => {
    const { purchaseLine } = this.state;
    const purchaseLineItem = this.getExistingOrderPurchaseLine(item);
    const key1 = purchaseLineItem && (purchaseLineItem.No);
    const key2 = item.ItemNo;

    let lineQty = '0';

    if (key1 === key2) lineQty = '' + purchaseLineItem.Quantity;

    if (purchaseLine) {
      const key3 = purchaseLine.ItemNo;

      if (key3 === key2) lineQty = '' + purchaseLine.Quantity;
    }

    return (
      <input
        disabled={this.isDisableRowActions(item)}
        type='number'
        value={parseFloat(lineQty.replaceAll(',', ''))}
        className="ant-input arrowless"
        min='0'
        style={{ width: 70, textAlign: 'right' }}
        onFocus={(event) => event.target.select()}
        onChange={(e) => this.handleQtyChange(e.target.value, item, purchaseLineItem)} />
    )
  }

  renderLineTotal = item => {
    const { purchaseLine } = this.state;
    const purchaseLineItem = this.getExistingOrderPurchaseLine(item);
    const key1 = purchaseLineItem && (purchaseLineItem.No);
    const key2 = item.ItemNo;

    let lineAmount = 0;

    if (key1 === key2) lineAmount = purchaseLineItem.LineAmount && purchaseLineItem.LineAmount.replace(/,/g, '');

    if (purchaseLine) {
      const key3 = purchaseLine.ItemNo;

      // if (key3 === key2)  lineAmount = (purchaseLine.Quantity * item.UnitPrice) - (purchaseLine.Quantity * item.UnitPrice * purchaseLine.DisPercentage /100)
      if (key3 === key2) lineAmount = undefined;
    }

    return lineAmount !== undefined ? format(lineAmount, currencyFormat) : 'N/A';
  }



  isDisableRowActions = item => {
    const { purchaseLine } = this.state;

    if (!purchaseLine) return false;

    const key1 = item.ItemNo;
    const key2 = purchaseLine.ItemNo;
    return key1 !== key2;
  }

  renderStatus = item => {
    const purchaseLine = this.getExistingOrderPurchaseLine(item);

    if (purchaseLine) {
      if (purchaseLine.added) {
        return (
          <FaCheck color='#07e607' />
        )
      } else {
        return (
          <FaTimes color='red' />
        )
      }
    } else return null;
  }

  getRowClassName = item => {
    const purchaseLine = this.getExistingOrderPurchaseLine(item);

    if (purchaseLine) {
      if (purchaseLine.added) {
        return 'sales-header-selected-item-row'
      } else {
        return ''
      }
    } else return '';
  }

  getExistingOrderPurchaseLine = item => {
    const { purchaseLineMap } = this.props;
    const { ItemNo, LotNo, LocCode } = item;
    const key = ItemNo;

    return purchaseLineMap[key];
  }

  renderActions = (item) => {
    const { purchaseLine } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disabled={this.isDisableRowActions(item)} type='primary' size={'small'} onClick={this.handleSaveItem} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}><FaSave /></Button>
        <Button disabled={this.isDisableRowActions(item)} size={'small'} onClick={this.handleResetItem} style={{ display: 'flex', alignItems: 'center' }}><FaUndo /></Button>
      </div>
    )
  }

  componentDidMount() {
    const { purchaseHeader, fetchPurchaseLines, fetchPurchaseHeaders } = this.props;
    fetchPurchaseHeaders({ No: purchaseHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[purchaseHeader.DocType] });
    fetchPurchaseLines({ DocNo: purchaseHeader.No, DocType: purchaseHeader.DocType });
    this.setState({ LocCode: purchaseHeader.LocCode }, this.loadItems);
    this.setDeviceMapLocation();
  }

  loadItems = item => {
    const { authData, fetchAllItems, purchaseHeader } = this.props;
    const filter = {
      //LocCode: this.state.LocCode,
      VendorNo:purchaseHeader.SellToCusNo ?? '',
      PurchItems: "Yes",
      UserCode: authData.userId,
    }

    if (item) {
      filter.ItemNo = item.ItemNo;
      filter.LotNo = item.LotNo;
    }

    this.setState({ loadingItems: true });
    fetchAllItems(filter).then(() => {
      this.setState({ loadingItems: false });
    });
  }

  loadSaleHeader = () => {
    const { purchaseHeader, fetchPurchaseHeaders } = this.props;

    fetchPurchaseHeaders({ No: purchaseHeader.No, DocType: DOC_TYPE_ID_BY_DEFINITION[purchaseHeader.DocType] });
  }

  setDeviceMapLocation = () => {
    const { MapLocation } = this.state;

    if (!MapLocation) {
      this.removeLocationListener = addListener(this.handleNewMapLocation);
    }
  }

  handleNewMapLocation = (location, error) => {
    if (!this.state.MapLocation) {
      removeListener(this.handleNewMapLocation);
      if (!!location) {
        this.setState({
          MapLocation: formatGeoLocation(location.latitude, location.longitude),
          locationAccuracy: location.accuracy
        });
      } else if (error) {
        this.props.toastManager.add("We could not retrive device location. " + error.message, { autoDismiss: false, appearance: 'error' });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.removeLocationListener) this.removeLocationListener();
  }

  handleSaveItem = () => {
    const { purchaseHeader, addPurchaseLine, authData, toastManager, updatePurchaseLine, deletePurchaseLine } = this.props;
    const { purchaseLine, MapLocation } = this.state;

    if (!purchaseLine) return;

    const exitingPurchaseLine = this.getExistingOrderPurchaseLine(purchaseLine);

    if (!locationEnabled) {
      toastManager.add('You can not update sales lines without enabling location service from your browser.', { autoDismiss: true, appearance: 'error' });
      return;
    }

    const purchaseLineItem = {
      LineSessionID: uuid(),
      DocType: purchaseHeader.DocType,
      DocNo: purchaseHeader.No,
      Type: '2',
      No: purchaseLine.ItemNo,
      MapLocation,
      Quantity: purchaseLine.Quantity,
      UnitPrice: purchaseLine.UnitPrice,
      UserCode: authData.userId,
      LotNo: purchaseLine.LotNo,
      //MarketPrice: purchaseLine.MarketPrice,
      //DisPercentage: purchaseLine.DisPercentage
    }

    if (!exitingPurchaseLine) this.handleAction(addPurchaseLine, purchaseLineItem); //Add new sales line
    else {
      const updatedPurchseLine = {
        ...purchaseLineItem,
        LineNo: exitingPurchaseLine.LineNo
      }

      if (purchaseLineItem.Quantity === '0') this.handleAction(deletePurchaseLine, updatedPurchseLine); //Remove sales line
      else this.handleAction(updatePurchaseLine, updatedPurchseLine); //Update sales line
    }
  }

  handleAction = (action, purchaseLineItem) => {
    //console.log(purchaseLineItem)
    const { toastManager } = this.props;

    this.setState({ loading: true });
    action(purchaseLineItem).then(response => {
      this.setState({ loading: false });
      if (response.error) {
        this.setState({ loading: false });
        toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
      } else {
        this.loadItems({ ...this.state.purchaseLine });
        this.loadSaleHeader();
        this.setState({ purchaseLine: undefined });
      }
    })
  }

  handleResetItem = () => this.setState({ purchaseLine: undefined });

  getScrollAreaHeight = () => {
    const networkHeader = document.getElementsByClassName('server-connection-lost');
    const networkHeaderHeight = networkHeader && networkHeader[0] ? networkHeader[0].clientHeight : 0;

    const promateTableHeader = document.getElementsByClassName('promate-table-header');
    const promateTableHeaderHeight = promateTableHeader && promateTableHeader[0] && promateTableHeader[0].clientHeight;

    const promatePageHeader = document.getElementsByClassName('promate-page-header');
    const promatePageHeaderHeight = promatePageHeader && promatePageHeader[0] && promatePageHeader[0].clientHeight;

    const inEditMode = !!this.state.purchaseLine;
    const summaryAvailable = !!this.props.purchaseHeaderDetails;
    const tableFooterHeight = !inEditMode && summaryAvailable ? 135 : 0;

    return window.innerHeight - (networkHeaderHeight + promateTableHeaderHeight + promatePageHeaderHeight + tableFooterHeight) - 58;
  }

  getTableHeight = items => {
    if (items.length > 0) {
      return this.getScrollAreaHeight();
    } else {
      return this.getScrollAreaHeight() - 167;
    }
  }

  handleChange = (field, value, error) => {
    this.setState({ [field]: value })
  }

  renderLocationList = () => {
    const { purchaseHeader = {} } = this.props;

    return (
      <SelectWarehouseLocation
        disabled={true}
        defaultValue={this.state.LocCode}
        onChange={this.handleChange} style={{ width: '100%' }} />
    )
  }

  handleCategoryFilterChange = value => {
    this.setState({ itemFilter: value });
  };

  renderTitle = () => {
    return (
      <Row className="promate-table-header" gutter={5} style={{ paddingBottom: 10 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ marginBottom: 5 }}>
          <SelectCategoryTree inventoryType="AllItems" itemFilter={this.state.itemFilter} onChange={this.handleCategoryFilterChange} />
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Search
            name='filterText'
            placeholder='Input search text'
            size='default'
            color={'black'}
            onChange={e => this.handleChange('text', e.target.value, '')} />
        </Col>
      </Row>
    )
  }

  renderFooter = () => {
    const { purchaseHeaderDetails } = this.props;
    const { AmountIncluVAT, DisAmount, DisPersentage, AvgDisPersentage } = purchaseHeaderDetails;
    const inludeVatAmount = AmountIncluVAT.replace(/,/g, '');
    const discount = DisAmount ? DisAmount.replace(/,/g, '') : 0.00;
    const grossAmount = parseFloat(inludeVatAmount) + parseFloat(discount);

    const inEditMode = !!this.state.purchaseLine;
    if (inEditMode) {
      return null;
    } else {
      return (
        <Row gutter={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "medium" }}>Gross Amount</div>
            <div style={{ fontSize: "medium" }}>{format(grossAmount, currencyFormat)}</div>
          </Col>
          <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "medium" }}>Discount ({AvgDisPersentage}%) </div>
            <div style={{ fontSize: "medium" }}>{format(DisAmount, currencyFormat)}</div>
          </Col>
          <Col lg={10} md={10} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontSize: "medium" }}>Net Amount</div>
            <div style={{ fontSize: "medium" }}>{format(AmountIncluVAT, currencyFormat)}</div>
          </Col>
          <Col lg={2} md={2} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <Button block type='primary' onClick={() => this.props.history.goBack()}>Done</Button>
          </Col>
        </Row>
      );
    }
  }

  getFilteredItems = () => {
    const { items = [] } = this.props;
    const { text, itemFilter } = this.state;

    let filteredItems = items;

    filteredItems = items.filter(item => {
      const { Description } = item;
      const descriptionFilter = text ? this.stringCompare(Description, text) : true;

      return descriptionFilter;
    });

    filteredItems = filterItemsByCategory(filteredItems, itemFilter);

    return filteredItems
  }

  stringCompare = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }

  handleQtyChange = (value, item, purchaseLineItem) => {
    value = value.replaceAll('-', '');
    const { purchaseLine } = this.state;
    const { purchaseHeaderDetails } = this.props;
    const baseDiscount = purchaseHeaderDetails ? purchaseHeaderDetails.DisPersentage : 0.00;
    this.setState({
      purchaseLine: {
        ...item,
        //DisPercentage: purchaseLine && purchaseLine.DisPercentage ? purchaseLine.DisPercentage : purchaseLineItem ? purchaseLineItem.DisPercentage : baseDiscount,
        Quantity: value
      }
    });
  }

  handleDiscountPercentageChange = (value, item, purchaseLineItem) => {
    value = value.replaceAll('-', '');
    const { purchaseLine } = this.state;
    this.setState({
      purchaseLine: {
        ...item,
        DisPercentage: value,
        Quantity: purchaseLine && purchaseLine.Quantity ? purchaseLine.Quantity : purchaseLineItem ? purchaseLineItem.Quantity : 0
      }
    });
  }

  render() {
    const { purchaseHeaderDetails } = this.props;
    const items = this.getFilteredItems();
    const { loading, loadingItems } = this.state;

    return (
      <div>
        <Table
          rowKey={'id'}
          rowClassName={(item, index) => this.getRowClassName(item)}
          className='hovering-scroller'
          columns={this.context.screenType === 'MOBILE' ? this.getMobileColumns() : this.getColumns()}
          bodyStyle={{ minHeight: this.getTableHeight(items) }}
          dataSource={items}
          size={'small'}
          scroll={{ y: this.getScrollAreaHeight() }}
          pagination={false}
          title={this.renderTitle}
          footer={purchaseHeaderDetails && this.renderFooter}
          loading={loading || (items.length === 0 && loadingItems)} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { purchaseHeader } = ownProps;
  const items = getAllItems(state);
  const purchaseLineMap = getPurchaseLineMapByDocTypeAndDocNo(state, purchaseHeader.DocType, purchaseHeader.No);
  //console.log("purchaseLineMap", purchaseLineMap)
  return {
    authData: getAuthData(state),
    items,
    purchaseHeaderDetails: getPurchaseHeadersByTypeAndNo(state, purchaseHeader.DocType, purchaseHeader.No),
    purchaseLineMap,
    purchaseLines: getPurchaseLinesByDocTypeAndDocNo(state, purchaseHeader.DocType, purchaseHeader.No)
    // billToCusNo: state.billToCusNo
  };
};

PurchaseOrderItemTable.contextTypes = {
  screenType: PropTypes.object.isRequired
}

export default withToastManager(withRouter(connect(mapStateToProps, { fetchAllItems, addPurchaseLine, fetchPurchaseLines, updatePurchaseLine, deletePurchaseLine, fetchPurchaseHeaders })(PurchaseOrderItemTable)));