import store from '../store';
import { getAuthData } from '../redux/reducers';
import jwt from 'jsonwebtoken';



const commonFetch = (url, data, responseType) => {
  setHeaders(data);
  return doFetch(url, data, responseType);
};

export const localFetch = async (url, body, responseType) => {
  const jwtSecret = process.env.REACT_APP_JWT_SECRET;
  const { auth } = JSON.parse(localStorage.getItem('state'));
  var token = jwt.sign({
    userId: auth.authData.userId
  }, jwtSecret, { expiresIn: 10 });
  return fetch(url(), {
    ...body, headers: {
      'x-auth-token': token,
      'Accept': 'application/json',
      'Content-Type': "application/json"
    }
  })
    .then(response => response.status === 500 ? [] : response.json())
    .then(responseJson => {
      return responseJson;
    }).catch(error => {
      console.error(error);
      return [];
      // return { error: 'An error occured while connecting to the local server.' };
    });
}

export const uploadFile = (url, data, responseType) => {
  setHeaders(data, true);
  return doFetch(url, data, responseType);
};

export const downloadFile = (url, data, responseType) => {
  setHeaders(data);
  return fetch(url, data)
    .then(response => {
      return response.blob();
    })
    .catch(error => {
      return { error };
    });
};

const doFetch = (url, data, responseType) =>
  fetch(url, data)
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        if (responseType) {
          return {
            ...responseJson.data,
            records: responseJson.data.records || responseJson.data,
            recordTotal: responseJson.data.recordTotal || undefined,
            [responseType]: responseJson.data.records || responseJson.data
          };
        } else {
          return { response: responseJson.data };
        }
      } else {
        return { error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });

const setHeaders = (data, fileUpload = false) => {
  const authData = getAuthData(store.getState());

  const headers = data.headers || {};
  headers['Accept'] = 'application/json';
  headers['Auth-Token'] = authData.authToken;

  if (!fileUpload) headers['Content-Type'] = 'application/json';

  data.headers = headers;
}

export const getSalesOrderType = () => {
  const types = [{
    Code: "Retail Sales",
    Description: "Retail Sales"
  },
  {
    Code: "Bulk Sales",
    Description: "Bulk Sales"
  }]
  return types
}
export default commonFetch;