import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tag, Button, Popconfirm, Divider, Select, DatePicker } from "antd";
import FormItem from '../../common/FormItem';
import Input, { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';
import moment from "moment";
import { getExpensesCategories } from '../../../redux/reducers';
import { withRouter } from "react-router-dom";
import { dateFormatView, dateFormatUpdate } from '../../../const/Formats';
import { format } from "currency-formatter";

const { Option } = Select;
const disableEdit = false;
const currencyFormat = {
  code: 'lkr',
  symbol: '',
  decimalDigits: 2,
  decimalSeparator: '.'
}

class ExpenseHeader extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Confirmed':
        return 'orange';
      default:
        return '';
    }
  }

  renderActions = () => {
    const { expense, newExpense, onSubmitExpense, onClickConfirmExpense, onShareExpense, onDeleteExpense } = this.props;
    const { Status } = expense;

    if (newExpense) {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}><Col>
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={onSubmitExpense}>
          <Button type="primary">Create</Button>
        </Popconfirm>
      </Col></Row>
    } else {
      return <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          Status === 'Open' && <Col>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDeleteExpense}>
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </Col>
        }
        {
          Status === 'Open' && <Col>
            <Button type="primary" onClick={onClickConfirmExpense}>Confirm</Button>
          </Col>
        }
        <Row gutter={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            Status === 'Confirmed' && <Col>
              <Button
                type="secondary"
                style={{ backgroundColor: '#eda01e', color: '#FFFFFF' }}
                onClick={() => onShareExpense()}
              >Print & Share</Button>
            </Col>
          }
        </Row>
      </Row>
    }
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() < Date.now();
  }

  renderExpenseCategorySelect = () => {
    const { expense, onInputChange, match, expenseCategories, error } = this.props;
    const { ExpCategory } = expense;

    let title = 'Expense';
    let placeholder = 'Expense Category';
    let options = expenseCategories;

    return (
      <tr style={{ justifyContent: 'center' }}>
        {<FormItem label={placeholder} error={error.expCategoryError}>
          <Select
            placeholder={placeholder}
            value={ExpCategory ? ExpCategory : undefined}
            style={{ width: 200 }}
            onChange={(value) => this.props.onInputChange("ExpCategory", value, "expCategoryError")}>
            {options.map(option => <Option value={option.Code}>{option.Description}</Option>)}
          </Select>
        </FormItem>}
      </tr>
    );
  }

  renderDesktopHeader = () => {
    const { expense, onInputChange, error, match, expenseCategories, distributor, loggedInUser } = this.props;
    const { CollectionNo, DocDate, PayNo, CusCode, CusName, Status, ExpCategory, ExpDescription, ExpenseDate, Description, Amount, UserCode, CreatedUserName, Remarks } = expense;

    // expCategoryError, docNoError, expDateError, expAmountError

    const date = DocDate;
    let expenseDate = ExpenseDate && moment(ExpenseDate).add('minutes', +330);

    const dbCode = CusCode ? CusCode : CusCode;
    const dbName = CusName ? CusName : CusName;
    const expStatus = Status ? Status : "Open";
    const UserName = CreatedUserName ? CreatedUserName : loggedInUser.username;

    const ExpAmount = parseFloat((format(Amount, currencyFormat) || '0.00').replaceAll(',', ''));

    return (
      <div>
        <Col lg={8} md={8} sm={24} xs={24}>
          {CollectionNo && <tr>
            <td style={{ width: 120 }}>No</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{CollectionNo}</td>
          </tr>}
          {date && <tr>
            <td>Date</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{moment(date).format(dateFormatView)}</td>
          </tr>}
          {dbCode && <tr>
            <td style={{ width: 120 }}>DB Code</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{dbCode}</td>
          </tr>}
          {dbName && <tr>
            <td style={{ width: 120 }}>DB Name</td>
            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
            <td>{dbName}</td>
          </tr>}
          {expStatus && <tr>
            <td>Status</td>
            <td style={{ width: 30, textAlign: 'center' }}><p>:</p></td>
            <td>
              {<Tag color={this.getStatusColor(expStatus)}>{expStatus}</Tag>}
            </td>
          </tr>}
          {UserName && <tr>
            <td>Created User</td>
            <td style={{ width: 25, textAlign: 'center' }}>:</td>
            <td>{UserName}</td>
          </tr>}
        </Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          {this.renderExpenseCategorySelect()}
          {<FormItem label={"Doc No"} lg={12} md={12} sm={24} xs={24}>
            <input
              disabled={disableEdit}
              className="ant-input"
              value={PayNo}
              style={{ width: '100%' }}
              type="text"
              onChange={(e) => this.props.onInputChange("PayNo", e.target.value)} />
          </FormItem>}
          {<FormItem label={"Description"} lg={24} md={24} sm={24} xs={24} >
            <TextArea value={Description} rows={4} placeholder={"Type here"} onChange={(value, e) => this.props.onInputChange('Description', value, '')} />
          </FormItem>}
        </Col>
        <Col lg={8} md={8} sm={24} xs={24}>
          {<FormItem label={"Expense Date"} error={error.expDateError}>
            <DatePicker
              //disabledDate={(current) => this.disabledDate(current)}
              disabled={disableEdit}
              allowClear={false}
              format={dateFormatView}
              showToday={false}
              style={{ width: '100%' }}
              value={expenseDate}
              onChange={(date, dateString) => this.props.onInputChange("ExpenseDate", date.add('minutes', -330).format(dateFormatUpdate), "expDateError")} />
          </FormItem>}
          {<FormItem label={"Amount"} error={error.expAmountError} lg={12} md={12} sm={24} xs={24}>
            <input
              disabled={disableEdit}
              className="ant-input"
              value={ExpAmount}
              style={{ width: '100%', textAlign: 'end' }}
              type="number"
              min="0"
              onChange={(e) => onInputChange("Amount", e.target.value, "expAmountError")}
              onFocus={(event) => event.target.select()} />
          </FormItem>}
          {<FormItem label={"Remarks"} lg={24} md={24} sm={24} xs={24} >
            <TextArea value={Remarks} rows={4} placeholder={"Type here"} onChange={(value, e) => this.props.onInputChange('Remarks', value, '')} />
          </FormItem>}
        </Col>
      </div>
    )
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderDesktopHeader()}
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { expense, distributorId } = ownProps;
  return {
    expenseCategories: getExpensesCategories(state)
  };
};

export default withRouter(connect(mapStateToProps, {})(ExpenseHeader));