import React, { Component } from 'react';
import { Button, Icon, Row, Card, Col, Select, Popconfirm } from "antd";
import FormItem from '../../common/FormItem';
import { MyMapComponent } from '../GeoMap/GeoMap';
import moment from 'moment';
import { dateFormatView } from "../../../const/Formats";
import Input, { EnglishTextArea as TextArea } from '../../../components/common/EnglishInput';

const { Option } = Select;

class WarehouseForm extends Component {

  render() {
    const {
      onSelectWarehouseTypeChange,
      warehouse = {},
      onInputChange,
      warehouseNameError,
      onCreateWarehouse,
      onUpdateWarehouse,
      onDeleteWarehouse,
      onRemoveReason,
      onBloackCustomer,
      location = { lat: 6.9271, lng: 79.8612 },
      isMarkerShown,
      onMapClick } = this.props;
    const { warehouseNo, type, name, blockedReasons = [], status } = warehouse;
    const blockedReasonsExist = status === 'BLOCK' && blockedReasons.length > 0;

    return (
      <Card>
        <Row gutter={10}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Warehouse Name</div>
              <Input value={name} onChange={value => onInputChange("name", value, "warehouseNameError")} />
              {warehouseNameError && <div style={{ fontWeight: 500, color: 'red' }}>{warehouseNameError}</div>}
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Warehouse Type</div>
              <Select value={type} onChange={onSelectWarehouseTypeChange} style={{ width: '100%' }}>
                {warehouseTypes.map(type => <Option key={type.key} value={type.value}>{type.name}</Option>)}
              </Select>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Address</div>
              <TextArea rows={5} />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Routes</div>
              <Select mode="multiple" style={{ width: '100%' }}>
                {routes.map(type => <Option key={type.key} value={type.value}>{type.name}</Option>)}
              </Select>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Sales Persons</div>
              <Select mode="multiple" style={{ width: '100%' }}>
                {salesPersons.map(type => <Option key={type.key} value={type.value}>{type.name}</Option>)}
              </Select>
            </Col>
            {blockedReasonsExist &&
              <Col lg={24} md={24} sm={24} xs={24}>
                <tr>
                  <td style={{ width: 25, height: 30 }}></td>
                  <td><div style={{ fontWeight: 500 }}>Block Reasons</div></td>
                  <td></td>
                </tr>
                {blockedReasons.map((reason, index) => {
                  return (
                    <div key={index}>
                      <tr >
                        <td style={{ width: 25 }}>{index + 1}.</td>
                        <td><b>{reason.type} - {reason.description}</b> </td>
                        <td style={{ width: 250, textAlign: 'center' }}><i> {moment(reason.time).format(dateFormatView + 'hh:mm a')}</i></td>
                        <td>
                          <Icon type="delete" theme="filled" style={{ fontSize: '14px', color: 'red' }} onClick={() => onRemoveReason(index)} />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: 25 }}></td>
                        <td colSpan={2}>{reason.narration}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: 25, height: 15 }}></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </div>
                  )
                })}
              </Col>
            }
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 15 }}>
              <div style={{ fontWeight: 500 }}>Click Map to set warehouse location</div>
              <FormItem>
                <MyMapComponent
                  isMarkerShown={isMarkerShown}
                  location={location}
                  height={400}
                  editable={true}
                  onMapClick={onMapClick} />
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {warehouseNo === "new" ? <Col lg={4} md={4} sm={24} xs={24}>
              <Popconfirm
                title="Are you sure ?"
                okText="Yes"
                cancelText="No"
                onConfirm={onCreateWarehouse}
              >
                <Button block type="primary">Create</Button>
              </Popconfirm>
            </Col>
              :
              <Col lg={8} md={8} sm={24} xs={24}>
                <Col lg={8} md={8} sm={24} xs={24} style={{ marginBottom: 10 }}>
                  <Button block type="primary" onClick={onUpdateWarehouse}>Update</Button>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24} style={{ marginBottom: 10 }}>
                  <Button block type="primary" onClick={onBloackCustomer}>Block</Button>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Popconfirm
                    title="Are you sure ?"
                    okText="Ok"
                    cancelText="No"
                    onConfirm={onDeleteWarehouse}
                  >
                    <Button block type="danger">Delete</Button>
                  </Popconfirm>
                </Col>
              </Col>}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default WarehouseForm;

const warehouseTypes = [
  {
    name: 'Mobile',
    value: 'MOBILE',
    key: 'MOBILE'
  },
  {
    name: 'Static',
    value: 'STATIC',
    key: 'STATIC'
  }
]

const routes = [
  {
    name: 'Route 1',
    value: 'Route 1'
  },
  {
    name: 'Route 2',
    value: 'Route 2'
  },
  {
    name: 'Route 3',
    value: 'Route 3'
  },
  {
    name: 'Route 4',
    value: 'Route 4'
  },
  {
    name: 'Route 5',
    value: 'Route 5'
  },
  {
    name: 'Route 6',
    value: 'Route 6'
  }
]

const salesPersons = [
  {
    name: 'Sales Person 1',
    value: 'Sales Person 1'
  },
  {
    name: 'Sales Person 2',
    value: 'Sales Person 2'
  },
  {
    name: 'Sales Person 3',
    value: 'Sales Person 3'
  },
  {
    name: 'Sales Person 4',
    value: 'Sales Person 4'
  },
  {
    name: 'Sales Person 5',
    value: 'Sales Person 5'
  },
  {
    name: 'Sales Person 6',
    value: 'Sales Person 6'
  },
]