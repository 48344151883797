import React, { Component } from "react";
import { Col, Row, Tag } from "antd";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

class WarehouseCard extends Component {

  getStatusColor = status => {
    switch (status) {
      case 'UNBLOCK':
        return 'green';
      case 'BLOCK':
        return 'red';
      default:
        return 'orange';
    }
  }

  render() {
    const { warehouse, navigateToWarehouseView } = this.props;
    const { warehouseNo, name, type, status, address, distributor } = warehouse;

    return (
      <Row onClick={navigateToWarehouseView}>
        <Row style={styles.alignRow}>
          <Col xs={0} sm={0} md={3} lg={3} style={{ textAlign: 'left' }}>
            <span style={styles.warehouseNo}>#{warehouseNo}</span>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={{ ...styles.customerCode, margin: 5 }}>{name}</p>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
            <p style={styles.paymentType}>{type}</p>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} style={{ textAlign: 'center' }}>
            <p>{distributor}</p>
          </Col>
          <Col xs={0} sm={0} md={8} lg={8} style={{ textAlign: 'left' }}>
            <p>{address}</p>
          </Col>
        </Row>
        {/* <Row style={styles.lowerRow}>
          <Col xs={8} sm={0} md={0} lg={0} style={styles.bottomColumnStyles}>
            <span>{name}</span>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'center' }}>
            <Tag style={styles.status} color={this.getStatusColor(status)} >{status}</Tag>
          </Col>
          <Col xs={8} sm={0} md={0} lg={0} style={{ textAlign: 'left' }}>
            <p style={styles.paymentType}>{type}</p>
          </Col>
        </Row>
        <Row style={styles.lowerRow}>
					<Col xs={24} sm={24} md={0} lg={0} style={styles.bottomColumnStyles}>
						<span>{name}</span>
					</Col>
				</Row> */}
      </Row>
    )
  }
}

export default WarehouseCard;

const styles = {
  warehouseNo: {
    fontSize: 16,
    fontWeight: 600
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center'
  },
  collectionDate: {
    fontSize: 12,
    fontWeight: 700
  },
  customerCode: {
    fontSize: 10,
    fontWeight: 700,
  },
  customerCodeBottom: {
    fontSize: 10,
    fontWeight: 700,
  },
  paymentType: {
    fontSize: 10,
    fontWeight: 700,
    marginRight: 0
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {
    fontSize: 10,
    marginRight: 5
  },
  lowerRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5
  },
  bottomColumnStyles: {
    textAlign: 'left',
    color: '#808080',
    fontSize: 12
  }
}