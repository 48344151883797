import { combineReducers } from 'redux';
import { FETCH_PAYMENT_METHODS, FETCH_CUSTOMER_PAYMENT_METHODS, REMOVE_CUSTOMER_PAYMENT_METHOD } from '../actions/paymentMethods';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS: {
      const newState = { ...state };
      action.paymentMethods.forEach(method => {
        newState[method.No] = method;
      });
      return newState;
    }

    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS: {
      const newState = [...state]
      action.paymentMethods.forEach(method => {
        if (!newState.includes(method.No)) newState.push(method.No)
      })

      return newState;
    }

    default:
      return state;
  }
};

export const paymentMethodIdsByCustomer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_PAYMENT_METHODS: {
      const newState = { ...state };
      const ids = newState[action.customerNo] || [];
      action.paymentOptions.forEach(paymentOption => {
        if (!ids.includes(paymentOption.Code)) ids.push(paymentOption.Code)
      });
      newState[action.customerNo] = ids;
      return newState;
    }
    case REMOVE_CUSTOMER_PAYMENT_METHOD: {
      const newState = { ...state };
      const ids = newState[action.paymentOption.CustNo] || [];
      newState[action.paymentOption.CustNo] = ids.filter(id => id !== action.paymentOption.Code);

      return newState;
    }

    default:
      return state;
  }
};

const paymentMethods = combineReducers({
  byId,
  ids,
  paymentMethodIdsByCustomer
});

export const getAll = state => state && state.ids && state.byId && state.ids.map(id => state.byId[id]) || [];

export const getCustomerPaymentMethodsByCustomerId = (state, customerId) => {
  const ids = state.paymentMethodIdsByCustomer[customerId] || [];

  return ids;
}

export const getCustomerPaymentMethodsDataByCustomerId = (state, customerId) => {
  const ids = state.paymentMethodIdsByCustomer[customerId] || [];

  return ids && ids.length > 0 ? ids.map(id => state.byId[id]) : [];
}

export default paymentMethods;